// transforms array of module names into single string, where the modules are KebabCase and have appended to them 'Module_{position}'
// example: ['about-us', 'kununu'] => 'AboutUsModule_1, KununuModule_2'
export const modulesOrder = (str: string, index: number) => {
  const camelCase = str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
  return `${camelCase.charAt(0).toUpperCase()}${camelCase.slice(
    1
  )}Module_${index}`;
};
