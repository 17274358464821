import type { Dispatch, SetStateAction } from 'react';

import type { CommboxErrorMessageType } from '../types';

export type IsPublishAtValidType = (props: {
  publishAt?: string;
  setErrorMessage: Dispatch<SetStateAction<CommboxErrorMessageType>>;
}) => boolean;

export const isPublishAtValid: IsPublishAtValidType = ({
  publishAt,
  setErrorMessage,
}) => {
  // if publishAt === null it means it's published now
  if (!publishAt) return true;
  const publishAtDate = new Date(publishAt);
  const now = new Date();
  const isValidDate = publishAtDate.getTime() > now.getTime();
  if (!isValidDate) setErrorMessage('INVALID_DATE');

  return isValidDate;
};
