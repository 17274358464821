import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconComment } from '@xing-com/icons';

import * as Styled from './customer-feedback-button.styles';

const FEEDBACK_LINKS: Record<string, string> = {
  main: 'https://easy-feedback.de/umfrage/1252082/9XA9g6',
  about_us: 'https://easy-feedback.de/umfrage/1253997/jxvLlq',
  contacts: 'https://easy-feedback.de/umfrage/1254137/o8YK94',
  news: 'https://easy-feedback.de/umfrage/1252095/f20Nl4',
  jobs: 'https://easy-feedback.de/umfrage/1254392/oaX7pF',
  employees: 'https://easy-feedback.de/umfrage/1254393/L496tE',
  groups: 'https://easy-feedback.de/umfrage/1365727/l7U113',
};

type CustomerFeedBackButtonProps = {
  module: string;
};
export const CustomerFeedBackButton: FC<CustomerFeedBackButtonProps> = ({
  module,
}) => {
  if (!FEEDBACK_LINKS[module]) return null;
  return (
    <Styled.ButtonWrapper>
      <Styled.Button
        variant="secondary"
        size="small"
        icon={IconComment}
        href={FEEDBACK_LINKS[module]}
        // @ts-expect-error FIXME: SC6
        target="_blank"
      >
        <FormattedMessage id="EP_FEEDBACK_BUTTON" />
      </Styled.Button>
    </Styled.ButtonWrapper>
  );
};
