import styled, { createGlobalStyle } from 'styled-components';

import { EditButton } from '@xing-com/crate-companies-entity-pages-common';
import { Dialog as InnerDialog } from '@xing-com/dialog';
import {
  scale030,
  spaceL,
  spaceS,
  xdlColorBackground,
  xdlColorHover,
  xdlPaletteGrayCool08,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const Dialog = styled(InnerDialog)`
  text-align: center;
`;

export const CoverEditButton = styled(EditButton)`
  display: none;

  @media (min-width: 1113px) {
    display: flex;
  }
`;

export const ListWrapper = styled.div`
  min-width: 150px;
  cursor: pointer;

  @media (prefered-color-scheme: light) {
    color: ${xdlPaletteGrayCool08};
  }

  > div {
    padding: ${spaceS} ${spaceL};
  }
`;

export const ListItem = styled.div`
  border-bottom: 0;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${xdlColorHover};
  }
`;

export const Link = styled(BodyCopy)`
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  padding: ${scale030};
`;

export const GlobalEditMenuStyles = createGlobalStyle`
    .mobileGuidelineContainer {
        width: 62.5%;
        left: 18.75%;
        z-index: 100;
        height: 100%;
        border: 1px solid #000;
        pointer-events: none;
        position: absolute;
        top: 0;

        &::after {
            content: '';
            position: absolute;
            left: -1px;
            right: -1px;
            top: -1px;
            bottom: -1px;
            z-index: 200;
            border: 1px dashed ${xdlColorBackground};
        }
    }
`;
