import type { FC } from 'react';

import { SkeletonBody } from '@xing-com/skeleton';

import * as Styled from './jobs-skeleton.styles';

const Card: FC = () => (
  <Styled.Card>
    <Styled.CompanyName amountRows={1} />
    <Styled.Title amountRows={2} />
    <span>
      <Styled.Description amountRows={1} />
    </span>
    <Styled.Date>
      <SkeletonBody amountRows={1} />
    </Styled.Date>
  </Styled.Card>
);

export const JobsSkeleton: FC = () => (
  <Styled.CardWrapper>
    <Card />
    <Card />
    <Card />
  </Styled.CardWrapper>
);
