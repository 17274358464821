import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import type { Employee } from '@xing-com/crate-common-graphql-types';
import {
  redirectToLogin,
  redirectToRegister,
} from '@xing-com/crate-companies-entity-pages-common';

import { LIMIT_EMPLOYEES_LIST_LOGGED_OUT } from '../utils';
import EmployeesCardHorizontal from './employees-card-horizontal';
import * as Styled from './employees-fencing-view.styles';
import Icon from './images/icon-fencing.png';

type FencingPopupProps = {
  total?: number;
};
const FencingPopup = ({ total }: FencingPopupProps) => {
  const intl = useIntl();

  return (
    <Styled.FencingPopupWrapper>
      <Styled.FencingPlaceholderOverlay />
      <Styled.FencingPlaceholder>
        <Styled.FencingPlaceholderPopup>
          <Styled.FencingImage src={Icon} alt="Fencing" />
          <Styled.FencingPopupPanel>
            <Styled.FencingText noMargin size={'xlarge'}>
              <FormattedMessage id="EP_EMPLOYEE_FENCED" values={{ total }} />
            </Styled.FencingText>

            <Styled.FencingButtonPanel>
              <Button
                variant={'primary'}
                size={'large'}
                onClick={() => redirectToRegister()}
              >
                <FormattedMessage id="EP_EMPLOYEES_BUTTON_REGISTER_NOW" />
              </Button>
              <Styled.FencingSignInButton
                onClick={() => redirectToLogin('employeesEntityPage')}
              >
                {`${intl.formatMessage({
                  id: 'EP_EMPLOYEES_OR',
                })} ${intl.formatMessage({ id: 'EP_EMPLOYEES_SIGNIN' })}`}
              </Styled.FencingSignInButton>
            </Styled.FencingButtonPanel>
          </Styled.FencingPopupPanel>
        </Styled.FencingPlaceholderPopup>
      </Styled.FencingPlaceholder>
    </Styled.FencingPopupWrapper>
  );
};

type EmployeesFencingViewProps = {
  employees: Employee[];
  total?: number;
};
const EmployeesFencingView: FC<EmployeesFencingViewProps> = ({
  employees,
  total = 0,
}) => (
  <Styled.FencingContainer>
    {employees.map(
      ({
        networkRelationship,
        sharedContacts,
        contactDistance,
        profileDetails,
      }) => {
        if (!profileDetails || !networkRelationship?.relationship) return null;

        const distance = Number.parseInt(String(contactDistance?.distance));
        const sharedContactNumber = sharedContacts?.sharedContacts?.length;
        const ocupationTitle = profileDetails?.occupations?.[0]?.subline ?? '';
        return (
          <EmployeesCardHorizontal
            key={profileDetails.id}
            id={profileDetails.id}
            displayName={profileDetails.displayName}
            pageName={profileDetails.pageName}
            profileUrl={
              profileDetails?.clickReasonProfileUrl?.profileUrl ??
              `/profile/${profileDetails.pageName}`
            }
            occupationTitle={ocupationTitle}
            sharedContacts={sharedContactNumber}
            relationship={networkRelationship?.relationship}
            contactDistance={distance}
            url={profileDetails?.profileImage?.[0]?.url}
            loggedOut
          />
        );
      }
    )}

    {total >= LIMIT_EMPLOYEES_LIST_LOGGED_OUT && <FencingPopup total={total} />}
  </Styled.FencingContainer>
);

export default EmployeesFencingView;
