import styled, { css } from 'styled-components';

import { TextButton } from '@xing-com/button';
import { Dimmer as XdlDimmer } from '@xing-com/dimmer';
import { zIndexElevatedLayer5 } from '@xing-com/layout-tokens';
import {
  cornerRadiusXL,
  motionEasingEnter,
  motionEasingLeave,
  motionTimeM,
  scale005,
  scale070,
  scale190,
  scale360,
  spaceL,
  spaceM,
  spaceXL,
  xdlColorBackground,
  xdlColorBorderSoft,
  xdlColorError,
  xdlColorModalBorder,
} from '@xing-com/tokens';
import { Headline as XdlHeadline } from '@xing-com/typography';

type FooterTransientProps = {
  $stacked?: boolean;
};

type ActionTransientProps = {
  $cancel?: boolean;
  $destructive?: boolean;
  $onlyOneButton?: boolean;
  $stacked?: boolean;
};

export const dimmerDuration = motionTimeM;
export const dimmerTransitionName = 'dimmerFade';
export const dialogDuration = motionTimeM;
export const dialogTransitionName = 'dialogFade';

// *** Base ****
const baseAction = css`
  flex-basis: auto;
  flex-grow: 1;
  height: ${scale190};
  min-width: 50%;
  padding-bottom: ${spaceM};
  padding-top: ${spaceM};
`;

const baseFooter = css`
  border-top: 1px solid ${xdlColorBorderSoft};
  display: flex;
`;

// *** Variants ****
export const variants = {
  stacked: css`
    flex-direction: column-reverse;
  `,
};

// *** Components ***
export const Dimmer = styled(XdlDimmer)`
  overflow-y: hidden;

  /* Dimmer Animation */
  &.${dimmerTransitionName}-enter {
    opacity: 0;
  }

  &.${dimmerTransitionName}-appear {
    opacity: 0;
  }

  &.${dimmerTransitionName}-enter-active {
    opacity: 60%;
    transition-duration: ${dimmerDuration};
    transition-property: opacity;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${dimmerTransitionName}-appear-active {
    opacity: 60%;
    transition-duration: ${dimmerDuration};
    transition-property: opacity;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${dimmerTransitionName}-enter-done {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-appear-done {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-exit {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-exit-active {
    opacity: 0;
    transition-duration: ${dimmerDuration};
    transition-property: opacity;
    transition-timing-function: ${motionEasingLeave};
  }

  &.${dimmerTransitionName}-exit-done {
    opacity: 0;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: ${spaceXL};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${zIndexElevatedLayer5};
`;

export const Dialog = styled.div`
  background: ${xdlColorBackground};
  border-color: ${xdlColorModalBorder};
  border-radius: ${cornerRadiusXL};
  border-style: solid;
  border-width: ${scale005};
  display: flex;
  flex-direction: column;
  margin: 0;
  max-height: 500px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transform: translateY(${scale070});
  width: ${scale360};

  /* Dialog Animation */
  .${dialogTransitionName}-enter & {
    opacity: 0;
    transform: translateY(${scale070});
  }

  .${dialogTransitionName}-enter-active & {
    opacity: 1;
    transform: translateY(0);
    transition-duration: ${dialogDuration};
    transition-property: transform, opacity;
    transition-timing-function: ${motionEasingEnter};
  }

  .${dialogTransitionName}-enter-done & {
    opacity: 1;
    transform: translateY(0);
    transition-duration: ${dialogDuration};
    transition-property: transform, opacity;
    transition-timing-function: ${motionEasingEnter};
  }

  .${dialogTransitionName}-exit & {
    opacity: 1;
    transform: translateY(0);
  }

  .${dialogTransitionName}-exit-active & {
    opacity: 0;
    transform: translateY(${scale070});
    transition-duration: ${dialogDuration};
    transition-property: transform, opacity;
    transition-timing-function: ${motionEasingLeave};
  }

  .${dialogTransitionName}-exit-done & {
    opacity: 0;
    transform: translateY(${scale070});
    transition-duration: ${dialogDuration};
    transition-property: transform, opacity;
    transition-timing-function: ${motionEasingLeave};
  }
`;

export const Content = styled.div`
  padding: ${spaceXL};
  text-align: center;
`;

export const Footer = styled.div<FooterTransientProps>`
  ${baseFooter}
  ${({ $stacked }) => $stacked && variants.stacked}
`;

export const Headline = styled(XdlHeadline)`
  margin-bottom: ${spaceL};
`;

export const Action = styled(TextButton)<ActionTransientProps>`
  ${baseAction};

  &:first-of-type {
    border-right: 1px solid ${xdlColorBorderSoft};
  }

  ${({ $stacked }) =>
    $stacked &&
    css`
      &:first-of-type {
        border-right: 0;
      }

      &:last-of-type {
        border-bottom: 1px solid ${xdlColorBorderSoft};
        border-right: 0;
      }
    `};

  ${({ $onlyOneButton }) =>
    $onlyOneButton &&
    css`
      &:first-of-type {
        border-right: 0;
      }
    `};

  ${({ $destructive }) =>
    $destructive &&
    css`
      color: ${xdlColorError};
    `};
`;
