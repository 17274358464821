import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { TextButton } from '@xing-com/button';
import { IconStatistics } from '@xing-com/icons';

import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';
import { trackAction } from '../../tracking';
import { CommboxView } from '../../types';

export const CommboxPollsButton: FC = () => {
  const { setCurrentView } = useCommboxContext();
  const { enableLeftToolbar } = useCommboxPermissions();
  const { formatMessage } = useIntl();

  return (
    <TextButton
      disabled={!enableLeftToolbar}
      aria-label={formatMessage({ id: 'COMMBOX_POLLS_ICON' })}
      data-testid="polls-icon"
      icon={IconStatistics}
      onClick={() => {
        setCurrentView(CommboxView.POLL_FORM);
        trackAction({
          PropTrackAction: 'social_share_commbox_poll_creation_open',
          PropFormList: 'social_share_commbox_poll_form_open',
        });
      }}
    />
  );
};
