import {
  PROP_MODULES,
  ENTITY_PAGE_EDITING_OPEN,
  ENTITY_PAGE_EDITING_SAVE,
  PROP_CONTEXT_DIMENSION1,
  PROP_CONTEXT_DIMENSION3,
  PROP_CONTEXT_DIMENSION4,
  PROP_ITEM_ID,
  PROP_TRACK_ACTION,
  PROP_ACTION_ORIGIN,
} from '../constants';
import { trackAction } from '../tracker';

type trackEditType = {
  focusType: string;
  module: string;
  part: string;
  itemId: number | string;
  isCreate?: boolean;
  status?: string;
  isFreePage?: boolean;
};
type TrackEditOpeningContextData = {
  PropModules: string;
  EventEntityPageEditingOpen: number;
  PropContextDimension1?: string;
  PropContextDimension3: string;
  PropContextDimension4: string;
  PropItemId: string;
};
export const trackEditOpening = ({
  focusType,
  module,
  part,
  itemId,
  isCreate = false,
  status = 'posted',
  isFreePage,
}: trackEditType) => {
  const pageType = focusType.toLowerCase();
  const id = itemId.toString().split('.')[0];

  const contextData: TrackEditOpeningContextData = {
    [PROP_MODULES]: `${module}_${part}_EditMode`,
    [ENTITY_PAGE_EDITING_OPEN]: 1,
    [PROP_CONTEXT_DIMENSION3]: 'entity_page_editor',
    [PROP_CONTEXT_DIMENSION4]: `entity_page_${
      isCreate ? 'create' : 'edit'
    }_${status}_${pageType}`,
    [PROP_ITEM_ID]: `${pageType}_${id}`,
  };

  if (isFreePage) contextData[PROP_CONTEXT_DIMENSION1] = 'entity_page_free';

  trackAction(contextData, 'EventEntityPageEditingOpen');
};

type TrackEditSavingContextData = {
  PropModules: string;
  EventEntityPageEditingSave: number;
  PropContextDimension1?: string;
  PropContextDimension3: string;
  PropContextDimension4: string;
  PropItemId: string;
};
export const trackEditSaving = ({
  focusType,
  module,
  part,
  itemId,
  isCreate = false,
  status = 'posted',
  isFreePage,
}: trackEditType) => {
  if (!module || !part) {
    return;
  }

  const pageType = focusType.toLowerCase();
  const id = itemId.toString().split('.')[0];

  const contextData: TrackEditSavingContextData = {
    [PROP_MODULES]: `${module}_${part}_EditMode`,
    [ENTITY_PAGE_EDITING_SAVE]: 1,
    [PROP_CONTEXT_DIMENSION3]: 'entity_page_editor',
    [PROP_CONTEXT_DIMENSION4]: `entity_page_${
      isCreate ? 'create' : 'edit'
    }_${status}_${pageType}`,
    [PROP_ITEM_ID]: `${pageType}_${id}`,
  };

  if (isFreePage) contextData[PROP_CONTEXT_DIMENSION1] = 'entity_page_free';

  trackAction(contextData, 'EventEntityPageEditingSave');
};

type TrackDeleteType = {
  itemId: string;
  isFreePage: boolean;
  focusType: string;
};
type TrackDeleteContextData = {
  PropTrackAction: string;
  PropContextDimension1?: string;
  PropContextDimension3: string;
  PropContextDimension4: string;
  PropItemId: string;
};
export const trackDelete = ({
  itemId,
  isFreePage,
  focusType,
}: TrackDeleteType) => {
  const pageType = focusType.toLowerCase();
  const id = itemId.toString().split('.')[0];

  const contextData: TrackDeleteContextData = {
    [PROP_TRACK_ACTION]: 'entity_page_companies_upsell_page_cover_image_delete',
    [PROP_CONTEXT_DIMENSION3]: 'entity_page_editor',
    [PROP_CONTEXT_DIMENSION4]: 'entity_page_edit_posted_company',
    [PROP_ITEM_ID]: `${pageType}_${id}`,
  };

  if (isFreePage) contextData[PROP_CONTEXT_DIMENSION1] = 'entity_page_free';

  trackAction(contextData);
};

type TrackUpsellBannerForwardType = {
  itemId: string;
  focusType: string;
};
export const trackUpsellBannerForward = ({
  itemId,
  focusType,
}: TrackUpsellBannerForwardType) => {
  const pageType = focusType.toLowerCase();
  const id = itemId.toString().split('.')[0];

  const contextData = {
    [PROP_TRACK_ACTION]:
      'entity_page_companies_upsell_page_cover_image_overview',
    [PROP_CONTEXT_DIMENSION1]: 'entity_page_free',
    [PROP_CONTEXT_DIMENSION3]: 'entity_page_editor',
    [PROP_CONTEXT_DIMENSION4]: 'entity_page_edit_posted_company',
    [PROP_ITEM_ID]: `${pageType}_${id}`,
  };

  trackAction(contextData);
};

type TrackEmployerBrandingFlagType = {
  draft?: boolean;
  itemId: string;
  focusType: string;
};
export const trackEmployerBrandingFlag = ({
  draft,
  itemId,
  focusType,
}: TrackEmployerBrandingFlagType) => {
  const pageType = focusType.toLowerCase();
  const id = itemId.toString().split('.')[0];

  const contextData = {
    [PROP_TRACK_ACTION]: 'entity_page_companies_upsell_page_cover_image_badge',
    [PROP_ACTION_ORIGIN]: `entity_page_companies_upsell_page_cover_image_${
      draft ? 'draft' : 'none'
    }`,
    [PROP_CONTEXT_DIMENSION1]: 'entity_page_free',
    [PROP_CONTEXT_DIMENSION3]: 'entity_page_editor',
    [PROP_CONTEXT_DIMENSION4]: 'entity_page_edit_posted_company',
    [PROP_ITEM_ID]: `${pageType}_${id}`,
  };

  trackAction(contextData);
};
