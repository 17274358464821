import type { FC } from 'react';

import { ContentSwitcher } from '@xing-com/content-switcher';
import type {
  ContentSwitcherProps,
  ContentSwitcherDataProps,
} from '@xing-com/content-switcher';

import { Styled } from './subpage-navigation.styles';

type SubpageNavigationProps = {
  noPadding: boolean;
  data: ContentSwitcherDataProps[];
} & ContentSwitcherProps;
export const SubpageNavigation: FC<SubpageNavigationProps> = ({
  noPadding,
  data,
  ...props
}) => (
  <Styled.StyledContainer $noPadding={noPadding}>
    <ContentSwitcher data={data} {...props} />
  </Styled.StyledContainer>
);
