import type { FC } from 'react';

import { IconMore } from '@xing-com/icons';
import { trackClickHeaderMoreAction } from '@xing-com/crate-companies-entity-pages-common/src/tracking'; // eslint-disable-line
import { usePopOver } from '@xing-com/pop-over';
import { ShareTabOmView } from '@xing-com/share';

import type { HeaderProps } from '../../../types/types';
import * as Styled from './header-more-actions.styles';
import MenuContainer from './menu-container';

type HeaderMoreActionsProps = Pick<
  HeaderProps,
  'globalId' | 'pageUrl' | 'ctaType'
> & {
  isEditor: boolean;
  smallOnWide?: boolean;
};
const HeaderMoreActions: FC<HeaderMoreActionsProps> = ({
  isEditor,
  globalId,
  pageUrl,
  smallOnWide,
  ctaType,
}) => {
  const popOver = usePopOver();

  const moreButtonClick = (isEditor: boolean) => {
    popOver.handleShow();
    trackClickHeaderMoreAction({ isEditor });
  };

  return (
    <Styled.Wrapper>
      <Styled.Button
        variant={'tertiary'}
        data-testid={'MODAL_TOGGLE'}
        onClick={() => moreButtonClick(isEditor)}
        innerRef={popOver.triggerRef}
        size={'medium'}
        sizeWide={smallOnWide ? 'small' : undefined}
        aria-label="More"
      >
        <IconMore width={16} height={16} />
      </Styled.Button>
      <ShareTabOmView
        url={pageUrl}
        propChannel="wbm/EntityPages"
        entryPoint="entity_page_social_share"
        interactionTargetUrn={globalId}
        trigger={(trigger: any) => (
          <MenuContainer
            sharePage={trigger}
            popOver={popOver}
            ctaType={ctaType}
          />
        )}
      />
    </Styled.Wrapper>
  );
};

export default HeaderMoreActions;
