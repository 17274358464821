import { useLocation } from '@reach/router';
import type { FC } from 'react';
import { useState } from 'react';

import { EditHeader } from '@xing-com/crate-companies-entity-pages-common';

import { SummaryEdit } from './summary-edit';

export const SummaryEditContainer: FC = () => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const type = urlSearchParams.get('type');

  const [activeIndex, setActiveIndex] = useState(type === 'industries' ? 1 : 0);

  return (
    <>
      <EditHeader
        titleKey="EP_EDIT_ABOUTUS_SUMMARY"
        contentSwitcher={{
          activeIndex,
          setActiveIndex,
          contentKeyList: [
            'EP_EDIT_ABOUTUS_NAV_FACTS',
            'EP_EDIT_ABOUTUS_NAV_INDUSTRIES',
          ],
        }}
      />
      <SummaryEdit activeIndex={activeIndex} />
    </>
  );
};
