import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { ProfileInfoAdapter as ProfileInfo } from '@xing-com/crate-companies-entity-pages-common';
import { mediaSmallOnly } from '@xing-com/layout-tokens';
import { FormField as InternalFormField } from '@xing-com/text-field';
import {
  spaceXS,
  spaceS,
  spaceM,
  spaceL,
  xdlColorTextInvert,
  xdlColorSelected,
  xdlColorBorderSoft,
  xdlColorBackground,
  xdlColorInfoSoft,
} from '@xing-com/tokens';
import { BodyCopy, Meta } from '@xing-com/typography';

export const ListContainer = styled.div`
  width: 100%;
`;

export const InputGuidance = styled.div`
  margin-bottom: ${spaceM};
`;

export const DefaultItemActionButton = styled(Button)`
  margin-right: ${spaceL};

  &:last-child {
    margin-right: 0;
  }

  @media ${mediaSmallOnly} {
    margin-right: ${spaceM};

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const DeletedButton = styled(DefaultItemActionButton)`
  margin-right: 0;
  /* alignment with the other item buttons */
  padding-right: 0;
`;

export const DefaultUserButton = styled(DefaultItemActionButton)<{
  activeEnableButton?: boolean;
}>`
  ${({ activeEnableButton }) => `
  ${
    !activeEnableButton &&
    `
    background-color: ${xdlColorSelected};
    border: none;
    color: ${xdlColorTextInvert};

  &:hover {
    border: none;
  }

  &:disabled4 {
    background-color: ${xdlColorSelected};
    border: none;
    color: ${xdlColorTextInvert};
  }
    `
  }`}
`;

export const DragButton = styled(DefaultItemActionButton)`
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  user-select: none;

  &:hover {
    background-color: inherit;
  }

  &:active {
    cursor: grabbing;
    background-color: inherit;
    outline: none;
    color: inherit;
  }
`;

export const EditWrapper = styled.div`
  display: flex;
  align-content: center;
`;

export const EditText = styled(BodyCopy)`
  margin-left: ${spaceS};
`;

export const FormAndActionsWrapper = styled.div`
  display: flex;

  @media ${mediaSmallOnly} {
    flex-direction: column;
  }
`;

export const FormField = styled(InternalFormField)`
  width: 100%;
`;

export const EditActionsWrapper = styled.div`
  margin-left: ${spaceL};
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-top: ${spaceS};

  @media ${mediaSmallOnly} {
    justify-content: flex-start;
    margin-left: 0;
  }
`;

export const LoadableSVGs = styled.div`
  position: absolute;
  z-index: -100;
`;

export const SortableWrapper = styled.div`
  .ghost {
    visibility: hidden;
  }

  .sortableFallback {
    background-color: white;
    box-shadow: 0 4px 16px 3px rgba(33, 42, 54, 0.3);
  }
`;

type TagProps = {
  isBeingEdited?: boolean;
  isInactive?: boolean;
  isDeleted?: boolean;
};

export const Tag = styled.div<TagProps>`
  padding: ${spaceL};
  border-top: 1px solid ${xdlColorBorderSoft};
  display: block;

  ${({ isBeingEdited }) =>
    isBeingEdited &&
    css`
      background-color: ${xdlColorBackground};

      .editText {
        margin-bottom: ${spaceS};
      }
    `}

  ${({ isInactive }) =>
    isInactive &&
    css`
      opacity: 0.5;
    `}

   ${({ isDeleted }) =>
    isDeleted &&
    css`
      background-color: ${xdlColorInfoSoft};
    `}
`;

export const ProfileInfoActionsWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const ContactsProfileInfo = styled(ProfileInfo)<{
  isDisabled?: boolean;
  itemChoosed?: boolean;
}>`
  flex-grow: 1;
  overflow: hidden;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}

  ${({ itemChoosed }) =>
    itemChoosed &&
    css`
      & svg {
        display: block;
      }
    `}
`;

export const ItemsActionWrapper = styled.div`
  margin-left: ${spaceL};
  display: flex;
  flex-shrink: 0;
  min-width: 128px;
  justify-content: flex-end;

  @media ${mediaSmallOnly} {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const OwnerDisclaimerWrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${spaceL};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}
`;

export const OwnerMeta = styled(Meta)`
  margin-left: ${spaceXS};
  margin-bottom: 0;

  & a {
    color: inherit;
    font-weight: 700;
  }
`;
