/* eslint-disable complexity */
const validate = (values: any, intl: any) => {
  const errors: { [type: string]: any } = {};

  for (const [type, value] of Object.entries(values)) {
    const error = validation(type, value, intl);
    if (error) {
      errors[type] = error;
    }
  }
  return errors;
};

const validation = (type: string, value: any, intl: any) => {
  // Sorry your input seems to be a bit long.
  const MAX_CHARACTERS_ERROR = 'EP_FIELD_MAX_CHARACTERS_ERROR';

  switch (type) {
    case 'imprint': {
      if (!value) {
        return null;
      }

      // Input needs to consist of a website structure ("www." is not necessary, but .de/.com ...)
      // Characters: 0 or 255
      if (value.length > 1000) {
        return intl.formatMessage(
          {
            id: MAX_CHARACTERS_ERROR,
          },
          {
            value: 1000,
          }
        );
      }

      return null;
    }
    case 'foundingYear': {
      // Characters: 4 or 0
      if (!value) {
        return null;
      }

      const currentYear = new Date().getFullYear();
      if (value < 1000 || value > currentYear) {
        return intl.formatMessage({ id: 'EP_META_YEAR_LABEL_ERROR' });
      }

      return null;
    }
    case 'websiteUrl': {
      if (!value) {
        return null;
      }

      // Input needs to consist of a website structure ("www." is not necessary, but .de/.com ...)
      // Characters: 0 or 255
      if (value.length > 255) {
        return intl.formatMessage(
          {
            id: MAX_CHARACTERS_ERROR,
          },
          {
            value: 255,
          }
        );
      }

      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator

      return value.length > 3 && value.length <= 255 && !!pattern.test(value)
        ? null
        : intl.formatMessage({ id: 'EP_META_WEBSITE_ERROR' });
    }
    default: {
      return null;
    }
  }
};

export default validate;
