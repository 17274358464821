import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useEffect } from 'react';

import {
  usePageContext,
  LAZY_LOADING_TYPE_SCROLL,
} from '@xing-com/crate-companies-entity-pages-common';
import { Column, Row } from '@xing-com/xing-grid';

import { EntityPageModuleDocument } from '../../graphql/queries/news-sub-module-query.gql-types';
import ModuleError from '../module-error/module-error';
import { NewsContainer } from '../news-container/news-container';
import { NewsItem } from '../news-item/news-item';
import * as Styled from './news-detail.style';

export const NewsDetail: FC = () => {
  const { pageContext } = usePageContext() ?? {};
  const { pageId } = pageContext ?? {};
  const {
    data = {},
    loading,
    error,
    refetch,
  } = useQuery(EntityPageModuleDocument, {
    variables: { id: pageId ?? '', moduleType: 'news' },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    // onError: () => <ModuleError refetch={refetch} />,
  });

  // scroll to top only on the first render
  useEffect(() => {
    if (typeof window !== 'undefined') window.scrollTo(0, 0);
  }, []);

  if (error) {
    return <ModuleError refetch={refetch} />;
  }

  return (
    <Styled.Wrapper>
      {loading && (
        <Row>
          <Column sizeDesktop={10} offsetDesktop={1} size={12}>
            <NewsItem.Skeleton smallActor={true} />
          </Column>
        </Row>
      )}
      {!loading && (
        <NewsContainer
          moduleProperties={data?.entityPageModule?.properties}
          loadingSkeletonCount={3}
          showHeadline={false}
          showSubpageLinks={false}
          isDetailPage={true}
          lazyLoadingType={LAZY_LOADING_TYPE_SCROLL}
        />
      )}
    </Styled.Wrapper>
  );
};
