/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CoverImageFragment = {
  __typename?: 'ScaledImage';
  reference?: string | null;
  url?: string | null;
};

export type EntityPageCoverImageQueryVariables = Types.Exact<{
  id: Types.Scalars['SlugOrID']['input'];
  dimensions?: Types.InputMaybe<
    Array<Types.ScaledImageDimension> | Types.ScaledImageDimension
  >;
}>;

export type EntityPageCoverImageQuery = {
  __typename?: 'Query';
  entityPageEX?:
    | {
        __typename?: 'EntityPage';
        id: string;
        globalId: string;
        coverImage?: Array<{
          __typename?: 'ScaledImage';
          reference?: string | null;
          url?: string | null;
        }> | null;
      }
    | { __typename?: 'EntityPageError'; errorType: string; errorCode: number }
    | { __typename?: 'EntityPageMoved' }
    | null;
};

export const CoverImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ScaledImage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoverImageFragment, unknown>;
export const EntityPageCoverImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EntityPageCoverImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dimensions' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ScaledImageDimension' },
              },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityPageEX' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coverImage' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'dimensions' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dimensions' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CoverImage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ScaledImage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EntityPageCoverImageQuery,
  EntityPageCoverImageQueryVariables
>;
