import styled from 'styled-components';

import { Button as InternalButton } from '@xing-com/button';
import { spaceM } from '@xing-com/tokens';

export const Button = styled(InternalButton)`
  margin: 0 ${spaceM} ${spaceM} 0;
  max-width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  flex-shrink: 0;

  & > div {
    max-width: 100%;
  }
`;

export const TruncatedText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
