import type { FC } from 'react';
import { useEffect } from 'react';

import { CommboxFormContextProvider } from '../../contexts/commbox-form-context';
import { getPostTarget } from '../../helpers/commbox';
import { useCommboxContext } from '../../hooks/use-commbox';
import { trackEvent } from '../../tracking';
import { CommboxBody } from '../commbox-body/commbox-body';
import { CommboxFormFooter } from '../commbox-footer/commbox-footer';
import { CommboxHeader } from '../commbox-header/commbox-header';
import { CommboxStatusBanners } from '../commbox-status-banners/commbox-status-banners';

export const CommboxForm: FC = () => {
  const { application, currentView, shouldOpenFilePicker } =
    useCommboxContext();

  useEffect(() => {
    const postTarget = getPostTarget(application);

    trackEvent('EventPostOpen', {
      EventPostOpen: 1,
      PropActionOrigin: 'social_share_audience_public',
      PropInteractionType: `social_commbox_share|startpage|${postTarget}_post`,
      ...(shouldOpenFilePicker && {
        PropTrackAction: 'social_share_commbox_image_source_open',
      }),
    });
  }, []);

  return (
    <CommboxFormContextProvider>
      {currentView === 'commbox' && (
        <section data-testid="commbox-form">
          <CommboxHeader />
          <CommboxBody />
          <CommboxFormFooter />
          <CommboxStatusBanners />
        </section>
      )}
    </CommboxFormContextProvider>
  );
};
