import type React from 'react';
import type { FC } from 'react';
import { useState } from 'react';

import {
  TruncatedText,
  useEditContext,
  usePageContext,
} from '@xing-com/crate-companies-entity-pages-common';

import { CONTENT_FEED_TYPE_GENERIC, PUBLISHED } from '../../config/constants';
import type { EntityPageFeedCollectionFragment } from '../../graphql/fragments/entity-page-feed-collection-fragment.gql-types';
import { NewsItemActions } from '../news-item-actions/news-item-actions';
import { NewsItemActor } from '../news-item-actor/news-item-actor';
import { NewsItemAttachment } from '../news-item-attachment/news-item-attachment';
import { NewsItemSocial } from '../news-item-social/news-item-social';
import type { NewsProps } from '../news/news';
import { NewsItemSkeleton } from './news-item.skeleton';
import * as Styled from './news-item.styles';

type NewsItemProps = Pick<
  NewsProps,
  'actor' | 'feedQueryVars' | 'isDetailPage' | 'isFollowingPage'
> & {
  isPaidPage: boolean;
  feedUpdate: EntityPageFeedCollectionFragment;
  showActor: boolean;
  videoPlaying: React.RefObject<any>;
  setLazyLoadingIsAllowed: (isAllowed: boolean) => void;
  onEditButtonClick: () => void;
  onItemActionCompleted?: () => void;
};
export const NewsItem: FC<NewsItemProps> & {
  Skeleton: typeof NewsItemSkeleton;
} = ({
  actor,
  feedQueryVars,
  isDetailPage = false,
  isFollowingPage = false,
  isPaidPage,
  feedUpdate,
  showActor = true,
  videoPlaying,
  setLazyLoadingIsAllowed,
  onEditButtonClick,
  onItemActionCompleted = () => undefined,
}) => {
  const { pageContext } = usePageContext() ?? {};
  const { isEditing } = useEditContext();
  const { pageSlug, globalId: pageGlobalId } = pageContext ?? {};
  const [isItemLoading, setIsItemLoading] = useState(false);
  const item = feedUpdate.object;
  const pageId = feedQueryVars.id;

  const type = item?.__typename;

  if (type === 'EntityPageContentFeedPlainTextPostingPost') {
    return null;
  }

  const { isPinned, metadata, publishedAt, globalId } = feedUpdate;

  const textTruncationLimit = 500;
  const attachment =
    item?.__typename === 'EntityPageContentFeedPostingPost'
      ? item.attachment
      : {};
  const attachmentType = attachment?.__typename || '';

  const isPinnedOldArticle = type !== CONTENT_FEED_TYPE_GENERIC && isPinned;
  const publicationState =
    item?.__typename === 'EntityPageContentFeedPostingPost'
      ? item.publicationState
      : undefined;
  const createdAt =
    item?.__typename === 'EntityPageContentFeedPostingPost'
      ? item.createdAt
      : undefined;

  const date =
    publicationState === PUBLISHED || publicationState === undefined
      ? publishedAt
      : createdAt;

  const xbmUrl =
    isEditing && pageSlug
      ? `${window.location.origin}/brandmanager/${pageSlug}/news-reach?sc_o=entity_page_news_module_more_statistics`
      : '';

  const xamUrl =
    isEditing &&
    publicationState === PUBLISHED &&
    (attachmentType === 'PostingsImageAttachment' ||
      attachmentType === 'PostingsLinkAttachment')
      ? `${window.location.origin}/xam/aac?entity_urn=${pageGlobalId}&asset_urn=${globalId}&sc_o=entity_page_news_module_promote`
      : '';

  const comment =
    item?.__typename === 'EntityPageContentFeedArticlesArticlePost'
      ? undefined
      : item?.comment;

  const Comment = showActor ? Styled.Comment : Styled.NoActorComment;

  return (
    <Styled.Item isItemLoading={isItemLoading}>
      <>
        <Styled.ItemActor isNoActor={!!isEditing && !showActor}>
          <>
            <NewsItemActor
              actionType={type}
              actor={actor}
              // @ts-expect-error TODO: fix this type
              date={date}
              isFollowingPage={isFollowingPage}
              isPinned={isPinned}
              pageId={pageId}
              publicationState={publicationState}
              showActor={showActor}
            />
            {isEditing && type && (
              <NewsItemActions
                activityId={globalId ?? ''}
                articleTypename={type}
                isPinned={isPinned || isPinnedOldArticle}
                isPaidPage={isPaidPage}
                pageId={pageId}
                itemId={
                  item?.__typename === 'EntityPageContentFeedPostingPost' ||
                  item?.__typename === 'EntityPageContentFeedCompanyArticlePost'
                    ? item?.id
                    : undefined
                }
                publicationState={publicationState}
                setIsItemLoading={setIsItemLoading}
                setLazyLoadingIsAllowed={setLazyLoadingIsAllowed}
                xamUrl={xamUrl}
                onEditButtonClick={onEditButtonClick}
                onItemActionCompleted={() => {
                  setIsItemLoading(false);
                  onItemActionCompleted();
                }}
              />
            )}
          </>
        </Styled.ItemActor>

        {comment && (
          <Comment>
            <TruncatedText limit={textTruncationLimit}>{comment}</TruncatedText>
          </Comment>
        )}

        <NewsItemAttachment
          actorId={globalId ?? ''}
          isPinned={isPinned}
          item={item}
          videoPlaying={videoPlaying}
        />

        {globalId && metadata && (
          <NewsItemSocial
            basePath={pageContext?.basePath ?? ''}
            authorUrn={pageGlobalId}
            isDetailPage={isDetailPage}
            metadata={metadata}
            pageSlug={actor.slug}
            activityGlobalId={globalId}
            xbmUrl={xbmUrl}
          />
        )}
      </>
    </Styled.Item>
  );
};

NewsItem.Skeleton = NewsItemSkeleton;
