import { useField } from 'formik';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { Option } from '@xing-com/text-field';

import * as Styled from './pages-field.styles';

type OptionItemType = {
  value?: string | null;
  text?: string | null;
};

type PagesFieldProps = {
  name: string;
  optionList?: Array<OptionItemType>;
  defaultValue?: number;
  required?: boolean;
  shouldTrim?: boolean;
  copyPrefix?: string;
  maxAllowedChars?: number;
  className?: string;
  type?: string;
};
export const PagesField: FC<PagesFieldProps> = ({
  name,
  optionList,
  defaultValue,
  required,
  shouldTrim,
  copyPrefix = 'EP_EDIT_',
  maxAllowedChars,
  className,
  ...props
}) => {
  const { $t } = useIntl();
  const [field, meta] = useField(name);

  if (optionList) {
    return (
      <Styled.Select
        {...field}
        value={field?.value || defaultValue || ''}
        error={!!meta?.error}
        placeholder={$t({
          id: copyPrefix + name.toUpperCase(),
        })}
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        medium
        {...props}
      >
        {optionList.map((item, index) =>
          item.value ? (
            <Option key={'pageFieldOption' + name + index} value={item.value}>
              {item.text}
            </Option>
          ) : null
        )}
      </Styled.Select>
    );
  }

  return (
    <Styled.FormField
      {...field}
      helperText={
        meta?.error
          ? meta?.error
          : maxAllowedChars
            ? $t(
                { id: 'EP_INPUT_CHARS_LEFT' },
                { charactersNumber: maxAllowedChars - field?.value?.length }
              )
            : !required
              ? $t({
                  id: 'EP_OPTIONAL_FIELD_HINT',
                })
              : undefined
      }
      error={!!meta?.error}
      label={$t({
        id: copyPrefix + name.toUpperCase(),
      })}
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      medium
      data-testid={'PAGES_FIELD_' + name}
      {...props}
    />
  );
};
