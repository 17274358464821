import styled from 'styled-components';

import { Divider } from '@xing-com/divider';
import { spaceL, spaceXL } from '@xing-com/tokens';

export const FooterDivider = styled(Divider)`
  margin-top: ${spaceXL};
`;

export const Footer = styled.section`
  display: flex;
  margin-top: ${spaceL};
`;

export const LeftToolbar = styled.span`
  display: flex;
  align-items: center;
  margin-right: auto;
  & > * {
    margin-right: ${spaceL};
  }
`;

export const RightToolbar = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
  & > * {
    margin-left: ${spaceL};
  }
`;
