import styled, { css } from 'styled-components';

import { TextButton } from '@xing-com/button';
import { spaceL, spaceM, spaceXL, xdlColorBorderSoft } from '@xing-com/tokens';

export const ActionsContainer = styled.div<{ $noMarginTop?: boolean }>`
  display: flex;
  justify-content: flex-end;
  padding-top: ${spaceL};
  border-top: 1px solid ${xdlColorBorderSoft};
  margin-top: ${spaceXL};
  margin-bottom: ${spaceXL};

  ${({ $noMarginTop }) =>
    $noMarginTop &&
    css`
      margin-top: 0;
    `}
`;

export const DiscardButton = styled(TextButton)`
  margin-right: ${spaceM};
`;
