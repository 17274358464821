import type { ApolloError } from '@apollo/client';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useState } from 'react';
import { useIntl } from 'react-intl';

import type {
  ArticlesError,
  EntityPagesMutationError,
} from '@xing-com/crate-common-graphql-types';

import { ErrorMessage } from '../../components/error-message/error-message';

export type ErrorType =
  | ApolloError
  | string
  | null
  | EntityPagesMutationError
  | ArticlesError;

export type ShowErrorProps = {
  message?: string | null;
  error?: ErrorType | null;
};

export type ErrorContextValues = {
  message: string;
  show: boolean;
  showError: ({ message, error }: ShowErrorProps) => void;
};
export const ErrorContext = createContext<ErrorContextValues | undefined>(
  undefined
);

type ErrorParams = {
  message: string;
  error?: ErrorType;
};

type ErrorStateProps = {
  show: boolean;
  message: string;
  error?: ErrorType;
  closeTimer?: void | null | ReturnType<typeof setTimeout>;
};

export const ErrorContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { $t } = useIntl();
  const [errorState, setErrorState] = useState<ErrorStateProps>({
    show: false,
    message: '',
    error: undefined,
    closeTimer: null,
  });

  const showError: any = ({ message, error }: ErrorParams) => {
    setErrorState({
      show: Boolean(message),
      message: message ? $t({ id: message, defaultMessage: message }) : '',
      error,
      closeTimer: setTimeout(() => hideError(), 3000),
    });
  };

  const hideError = () => {
    setErrorState({
      show: false,
      message: '',
      error: undefined,
      closeTimer: errorState.closeTimer
        ? clearTimeout(errorState.closeTimer)
        : null,
    });
  };

  return (
    <ErrorContext.Provider
      value={{
        ...errorState,
        showError,
      }}
    >
      <ErrorMessage onClick={hideError} />
      {children}
    </ErrorContext.Provider>
  );
};
