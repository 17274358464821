import styled from 'styled-components';

import { Card as InternalCard } from '@xing-com/card';
import {
  mediaConfinedNavlessOnly,
  mediaSmallOnly,
} from '@xing-com/layout-tokens';
import { ProfileImage as InternalProfileImage } from '@xing-com/profile-image';
import { spaceL, spaceXS, spaceXXL, xdlColorBorder } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Card = styled(InternalCard)`
  width: 100%;
  height: 100%;
  display: block;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  @media ${mediaSmallOnly} {
    width: 100%;
  }

  @media ${mediaConfinedNavlessOnly} {
    width: 100%;
  }
`;

export const Headline = styled(BodyCopy)`
  margin-top: ${spaceL};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Body = styled(BodyCopy)`
  margin: 0;
`;

export const KununuImg = styled.img`
  margin-left: ${spaceXS};
`;

export const ProfileImage = styled(InternalProfileImage)`
  position: absolute;
  left: ${spaceL};
  bottom: ${spaceL};
`;

export const SkeletonProfileImage = styled(InternalProfileImage)`
  position: absolute;
  left: ${spaceL};
  bottom: ${spaceL};
  height: 92px;
  width: 92px;
`;

export const SkeletonHeadline = styled.div`
  margin: ${spaceL} 0;
`;

export const UpsellLayout = styled.div`
  margin-bottom: ${spaceXXL};
`;

export const ProfileBackground = styled.div`
  background-color: ${xdlColorBorder};
  width: 100%;
  height: 139px;
  border-radius: 4px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;
