import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { ContentSwitcher } from '@xing-com/content-switcher';

import * as Styled from './navbar.styles';

type NavBarProps = {
  activeIndex: number;
  onPageChange: (page: number) => void;
};
export const NavBar: FC<NavBarProps> = ({ activeIndex, onPageChange }) => {
  const { formatMessage } = useIntl();

  return (
    <Styled.ContentSwitcher>
      <ContentSwitcher
        activeItemIndex={activeIndex}
        variant="filled"
        size="small"
        data={[
          {
            copy: formatMessage({
              id: 'EP_MANAGE_CONTENT',
              defaultMessage: 'EP_MANAGE_CONTENT',
            }),
            onClick: () => {
              if (activeIndex !== 0) {
                onPageChange(0);
              }
            },
          },
          {
            copy: formatMessage({
              id: 'EP_ADD_IMAGE',
              defaultMessage: 'EP_ADD_IMAGE',
            }),
            onClick: () => {
              if (activeIndex !== 1) {
                onPageChange(1);
              }
            },
            // @ts-expect-error TODO: fix this type
            'data-testid': 'GALLERY_IMAGE_UPLOAD',
          },
          {
            copy: formatMessage({
              id: 'EP_ADD_VIDEO',
              defaultMessage: 'EP_ADD_VIDEO',
            }),
            onClick: () => {
              if (activeIndex !== 2) {
                onPageChange(2);
              }
            },
          },
        ]}
      />
    </Styled.ContentSwitcher>
  );
};
