import type { FC } from 'react';
import { useIntl } from 'react-intl';

import * as Types from '@xing-com/crate-common-graphql-types';
import type { MetaDataType } from '@xing-com/crate-companies-entity-pages-common';
import { MetaData } from '@xing-com/crate-companies-entity-pages-common';

export type MetaDataContainerProps = {
  title?: string;
  image?: string;
  url?: string;
  focusType?: Types.Maybe<Types.EntityPageFocusType>;
  setDefault?: boolean;
};
export const MetaDataContainer: FC<MetaDataContainerProps> = ({
  title,
  image,
  url,
  focusType,
  setDefault,
}) => {
  const { formatMessage } = useIntl();

  if (setDefault) {
    return <MetaData setDefault={setDefault} title={'XING'} />;
  }

  const metaData: MetaDataType = {
    title: title
      ? formatMessage({ id: 'EP_META_PAGE_TITLE' }, { pageTitle: title })
      : 'XING',
    url,
    image,
  };

  // adds default description per page focus
  switch (focusType) {
    case Types.EntityPageFocusType.TopicPage:
      metaData.description = formatMessage(
        { id: 'EP_META_DESCRIPTION_TOPIC_PAGES' },
        { pageTitle: title }
      );
      break;
    case Types.EntityPageFocusType.Publisher:
      metaData.description = formatMessage(
        { id: 'EP_META_DESCRIPTION_PUBLISHER_PAGES' },
        { pageTitle: title }
      );
      break;
    case Types.EntityPageFocusType.Company:
      metaData.description = formatMessage(
        { id: 'EP_META_COMPANY_DESCRIPTION' },
        { pageTitle: title }
      );
      break;
    default:
      metaData.description = formatMessage(
        { id: 'EP_META_DESCRIPTION_DEFAULT' },
        { pageTitle: title }
      );
      break;
  }

  return <MetaData setDefault={true} {...metaData} />;
};

export default MetaDataContainer;
