import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { spaceL, spaceXXL } from '@xing-com/tokens';

const space24 = '96px';

export const Wrapper = styled.div`
  min-height: ${space24};
  padding: ${spaceL} 0 ${spaceXXL} 0;

  @media ${mediaSmallOrTiny} {
    min-height: 0;
  }
`;
