import { Node } from 'slate';

export const getEditorTitle = (value: any) =>
  [...value]
    .filter((block) => block?.type === 'title')
    .map((block) => Node.string({ ...block }));

export const getEditorBody = (value: any) =>
  [...value]
    .filter((block) => block && block?.type !== 'title')
    .map((block) => Node.string({ ...block }));

export const isEditorBodyEmpty = (value: any) =>
  getEditorBody(value).filter((block) => block.length > 4).length === 0;
