import type { FC } from 'react';

import { Button } from '@xing-com/button';
import type * as Types from '@xing-com/crate-common-graphql-types';
import { URL_SOCIAL_BAR } from '@xing-com/crate-companies-entity-pages-common';
import { IconMore } from '@xing-com/icons';
import { usePopOver } from '@xing-com/pop-over';
import { SocialBar } from '@xing-com/social-bar';

import { newsPath } from '../../utils';
import { NewsItemMenu } from '../news-item-menu/news-item-menu';
import * as Styled from './news-item-social.style';

type NewsItemSocialProps = {
  basePath: string;
  isDetailPage: boolean;
  activityGlobalId: any;
  authorUrn: any;
  externalUrl?: any;
  metadata: Types.EntityPageContentFeedMetadata;
  pageSlug: string;
  xbmUrl: string;
};
export const NewsItemSocial: FC<NewsItemSocialProps> = ({
  basePath,
  pageSlug,
  activityGlobalId,
  metadata,
  authorUrn,
  isDetailPage,
  externalUrl = null,
  xbmUrl,
}) => {
  const activityId = activityGlobalId.split(':').pop();

  // include back_to parameter to allow startpage to link back to current page
  const detailUrl = `/discover/detail-activities/${activityId}?back_to=${encodeURIComponent(
    `${isDetailPage ? newsPath(basePath, pageSlug) : basePath + '/' + pageSlug}`
  )}`;
  if (!externalUrl && typeof window !== 'undefined') {
    externalUrl = window.location.origin + detailUrl;
  }

  const popOver = usePopOver();
  const flyOut = (
    <Styled.Actions>
      <Button
        data-testid="flyout-anchor"
        size={'medium'}
        icon={IconMore}
        onClick={popOver.handleShow}
        innerRef={popOver.triggerRef}
        aria-label="More"
      />
    </Styled.Actions>
  );

  const handleCommentClick = () => window.location.assign(detailUrl);

  return (
    <Styled.Wrapper>
      <SocialBar
        urn={activityGlobalId}
        onCommentClick={handleCommentClick}
        url={externalUrl}
        hasShare={metadata.isRecommendable}
        hasReaction={metadata.isLikeable}
        hasComment={metadata.isCommentable}
        shareZenViewTo={URL_SOCIAL_BAR}
        propChannel="wbm/EntityPages"
        entryPoint="entity_page_social_share"
        rightBar={flyOut}
      />

      <NewsItemMenu
        activityGlobalId={activityGlobalId}
        authorUrn={authorUrn}
        popOver={popOver}
        xbmUrl={xbmUrl}
      />
    </Styled.Wrapper>
  );
};
