/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * Scalar for exposing dates or date times. `Date` should always be used when you're API is exposing
   * dates or date times. It's an [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) `String`
   * based on the `Zulu` timezone. This is is basically the same format our API gateway `xws` uses.
   * Valid values are for example `2007-12-24T18:21Z` or `2008-02-01T09:00:22Z`.
   * Also partial dates complaint with [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) like `2020-02`.
   */
  Date: { input: string; output: string };
  /** DEPRECATED: Use custom errors instead. Raw JSON of a domain error */
  DomainError: { input: any; output: any };
  /** Scalar to signal that this field contains an email address. The contained type is just a `String` */
  Email: { input: any; output: any };
  /** A marker type for an expression that is evaluated. The context is responsible for the evaluation, it does nothing by itself. */
  Expression: { input: any; output: any };
  /**
   * Used to create a globally unique id by combining the natural identifiers of an object together with type information.
   *
   * A `GlobalID` is expected to be an URN. If it's served by the backend, the URN is
   * expected to follow the conventions outlined by the [URN guidelines](https://new-work.atlassian.net/wiki/spaces/SOLAR/pages/45912687/Internal+URNs). In both
   * cases the naming portions of the URN are expected to be in "snake_case".
   */
  GlobalID: { input: string; output: string };
  /**
   * An `HTML` escaped `String`. Fields with this type will be automatically escape any `HTML` entities in the content,
   * before it is returned to the caller.
   *
   * > Please note that the content of this scalar will be escaped only on the web channel.
   * > For mobile requests the content will be forwarded unescaped.
   */
  HTMLEscaped: { input: any; output: any };
  /** Raw JSON scalar */
  JSON: { input: any; output: any };
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: number; output: number };
  /** Scalar to signal that this field returns [markdown](https://daringfireball.net/projects/markdown/syntax) flavoured `String`. */
  Markdown: { input: any; output: any };
  /**
   * An [E.164](https://en.wikipedia.org/wiki/E.164) compliant `String` made popular by
   * Googles [libphonenumber](https://github.com/googlei18n/libphonenumber),
   * examples are `+49403018` or `+491607812165`. There are a variety of libraries out there which can
   * produce an E.164 compliant `Strings`. For `Ruby on Rails` based applications,
   * you can use [phonelib](https://github.com/daddyz/phonelib).
   */
  PhoneNumberE164: { input: any; output: any };
  /**
   * ***Scrambles*** (signs) all `IDs` in a `URL` (for web) that has a `https` or `http` scheme
   * Used to create an URL that has obfuscated ids to prevent misuse.
   *
   * ***Please note*** that this kind of Scrambling differs from the Scrambling that is used by the
   * `xing-scrambling` gem https://source.xing.com/gems/xing-scrambling
   *
   *
   * Example
   *
   *  https://www.xing.com/chats/1298071111.d055e2
   *
   * find more testcases in the FieldScramblingSpec.scala
   */
  ScrambledURL: { input: any; output: any };
  /**
   * **scrambled** for both web and mobile requests (in the `ID` case)
   *
   * Sometimes you want to support an external alias for an `ID`.
   * Some of our internal APIs accept either an `ID` or a "slug-like" identifier.
   * One example of this is the `page name` of the profile team. This scalar is primarily intended to be used with
   * field arguments and should be used, when you want to expose such APIs.
   *
   * > Please note that the `SlugOrID` scalar doesn't consider blank strings as valid values.
   * > `""` or `"   "` as input values will result in query analysis errors.
   */
  SlugOrID: { input: string; output: string };
  /**
   * An [RFC 3986][rfc_3986], [RFC 3987][rfc_3987], and [RFC 6570][rfc_6570] (level 4) compliant URL `String`.
   *
   * Examples are
   *
   * * `https://www.xing.com`
   * * `http://api.xing.com/v1/some/api`
   * * `foo://example.com:8042/over/there?name=ferret#nose`
   *
   * [rfc_3986]: https://www.ietf.org/rfc/rfc3986.txt
   * [rfc_3987]: https://www.ietf.org/rfc/rfc3987.txt
   * [rfc_6570]: https://www.ietf.org/rfc/rfc6570.txt
   */
  URL: { input: string; output: string };
  /**
   * An [RFC 3986][rfc_3986], [RFC 3987][rfc_3987], and [RFC 6570][rfc_6570] (level 4) compliant URN `String`.
   *
   * Examples are
   *
   * * `urn:oasis:names:specification:docbook:dtd:xml:4.1.2`
   * * `urn:x-xing:communities:group:1234`
   *
   * See [general concept](https://new-work.atlassian.net/wiki/spaces/SOLAR/pages/45912687/Internal+URNs)
   * and the [internal URN catalog](https://new-work.atlassian.net/wiki/spaces/SOLAR/pages/45933515/Internal+URN+Catalog)
   *
   * [rfc_3986]: https://www.ietf.org/rfc/rfc3986.txt
   * [rfc_3987]: https://www.ietf.org/rfc/rfc3987.txt
   * [rfc_6570]: https://www.ietf.org/rfc/rfc6570.txt
   */
  URN: { input: string; output: string };
  /**
   * A __universally unique identifier (UUID)__ is a 128-bit number used to identify information in computer systems.
   * The term __globally unique identifier (GUID)__ is also used.
   *
   * In its canonical textual representation, the sixteen octets of a `UUID` are represented as 32 hexadecimal (base 16) digits, i
   * displayed in five groups separated by hyphens, in the form `8-4-4-4-12` for a total of 36 characters (32 alphanumeric characters and four hyphens).
   *
   * For example:
   *
   * - `123e4567-e89b-12d3-a456-426655440000`
   * - `xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx`
   *
   * See [here](https://en.wikipedia.org/wiki/Universally_unique_identifier) for more information
   */
  UUID: { input: string; output: string };
  /**
   * Scalar to represent an authorization token for a specific upload. The content of the token is an opague string.
   * It is intended to be used to authenticate with the upload service in order to upload data to the platform.
   */
  UploadAuthToken: { input: any; output: any };
  /**
   * Scalar to represent an id for an upload. The content of the token is an opague string.
   * This enables to reference and download an uploaded file at the internal upload API.
   */
  UploadId: { input: any; output: any };
};

export type AboutCompany = {
  __typename?: 'AboutCompany';
  /** #paginated #logged-out #soft-logged-in #rest #virtual */
  affiliates?: Maybe<CompanyAffiliatesConnection>;
  companySizeId?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  companySizeRange?: Maybe<CompanySizeRange>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  industry?: Maybe<Industry>;
  /** #logged-out #soft-logged-in */
  kununuData?: Maybe<CompanyKununuData>;
};

export type AboutCompanyAffiliatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AboutEntity = {
  __typename?: 'AboutEntity';
  /** #logged-out #soft-logged-in #rest #virtual */
  aboutArticle?: Maybe<ContentServiceContent>;
  /** #logged-out #soft-logged-in */
  aboutArticleReference?: Maybe<Scalars['GlobalID']['output']>;
  /** #logged-out #soft-logged-in */
  aboutHeadline?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  aboutSummary?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  articleReferences: Array<Scalars['JSON']['output']>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   * @deprecated Moved to the about us facts query. WILL BE REMOVED ...
   */
  companyData?: Maybe<AboutCompany>;
  /** #logged-out #soft-logged-in #rest #virtual */
  documents?: Maybe<EntityDocumentResult>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Moved to the about us facts query. WILL BE REMOVED ...
   */
  foundingYear?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in #rest #virtual */
  groupRulesArticle?: Maybe<ContentServiceContent>;
  /** #logged-out #soft-logged-in */
  groupRulesArticleReference?: Maybe<Scalars['GlobalID']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Moved to the about us facts query. WILL BE REMOVED ...
   */
  imprint?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest #virtual */
  media?: Maybe<EntityMediaResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   * @deprecated This attribute will be removed in favour of using the `entityPageModule` query to fetch module properties information. WILL BE REMOVED ...
   */
  properties?: Maybe<LegacyAboutUsProperties>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Moved to the about us facts query. WILL BE REMOVED ...
   */
  websiteUrl?: Maybe<Scalars['URL']['output']>;
};

export type AboutEntityAboutArticleArgs = {
  supportedBlockTypes?: Array<ArticleSupportedBlockTypes>;
};

export type AboutEntityCompanyDataArgs = {
  companyId?: InputMaybe<Scalars['SlugOrID']['input']>;
};

export type AboutEntityDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AboutEntityGroupRulesArticleArgs = {
  supportedBlockTypes?: Array<ArticleSupportedBlockTypes>;
};

export type AboutEntityMediaArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AboutEntityPropertiesArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AboutEntityMutationInput = {
  aboutArticleReference?: InputMaybe<Scalars['GlobalID']['input']>;
  aboutHeadline?: InputMaybe<Scalars['String']['input']>;
  aboutSummary?: InputMaybe<Scalars['String']['input']>;
  foundingYear?: InputMaybe<Scalars['Int']['input']>;
  imprint?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['URL']['input']>;
};

export type AboutEntityResult = AboutEntity | EntityPageError;

export type AboutUsFactsProperties = {
  __typename?: 'AboutUsFactsProperties';
  displayLinks?: Maybe<Scalars['Boolean']['output']>;
};

export type AboutUsFactsResult =
  | CompanyAboutUsFacts
  | EntityPageError
  | FallbackAboutUsFacts
  | GroupsAboutUsFacts
  | PublisherAboutUsFacts;

export type AboutUsProperties = {
  __typename?: 'AboutUsProperties';
  /** #logged-out #soft-logged-in */
  hasAffiliates: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  hasDocuments: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  hasExtraRules: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  hasGallery: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  hasSubpage: Scalars['Boolean']['output'];
};

/** Available academic titles on the XING ID */
export const enum AcademicTitle {
  /** Austrian only, [Diplom-Ingenieur](https://de.wikipedia.org/wiki/Akademischer_Grad#Zweiter_Zyklus_.E2.80.93_Ebene_2_.28Master-Ebene.29) */
  DiplIng = 'DIPL_ING',
  /** All countries, Doctor */
  Dr = 'DR',
  /** Austrian only, [Ingenieur](https://de.wikipedia.org/wiki/Ingenieur#Standes-_bzw._Qualifikationsbezeichnung_Ingenieur) */
  Ing = 'ING',
  /** Austrian only, [Magister](https://de.wikipedia.org/wiki/Akademischer_Grad#Zweiter_Zyklus_.E2.80.93_Ebene_2_.28Master-Ebene.29) */
  Mag = 'MAG',
  /** No title provided */
  None = 'NONE',
  /** All countries, Professor */
  Prof = 'PROF',
  /** All countries, Professor Doctor */
  ProfDr = 'PROF_DR',
}

export type Accomplishment = {
  __typename?: 'Accomplishment';
  globalId: Scalars['GlobalID']['output'];
  id: Scalars['ID']['output'];
  subtype: AccomplishmentTypeIdentifier;
  url?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  year?: Maybe<Scalars['Int']['output']>;
};

export type AccomplishmentCreateInput = {
  subtype: AccomplishmentTypeIdentifier;
  url?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AccomplishmentCreateResult =
  | Accomplishment
  | AccomplishmentMutationError;

export type AccomplishmentDeleteInput = {
  globalId: Scalars['GlobalID']['input'];
};

export type AccomplishmentDeleteResult =
  | Accomplishment
  | AccomplishmentMutationError;

export type AccomplishmentMutationError = {
  __typename?: 'AccomplishmentMutationError';
  errors?: Maybe<Scalars['JSON']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export const enum AccomplishmentTypeIdentifier {
  Award = 'AWARD',
  Qualification = 'QUALIFICATION',
}

export type AccomplishmentUpdateInput = {
  globalId: Scalars['GlobalID']['input'];
  subtype: AccomplishmentTypeIdentifier;
  url?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AccomplishmentUpdateResult =
  | Accomplishment
  | AccomplishmentMutationError;

export type AccomplishmentsCollection = {
  __typename?: 'AccomplishmentsCollection';
  collection: Array<Maybe<Accomplishment>>;
};

export type AccomplishmentsModule = ModuleInterface & {
  __typename?: 'AccomplishmentsModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** #rest #virtual */
  content?: Maybe<AccomplishmentsCollection>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type AccountDeactivationSettings = {
  __typename?: 'AccountDeactivationSettings';
  deactivation?: Maybe<DeactivationSetting>;
};

export type AccountDeactivationSettingsInput = {
  deactivation: DeactivationSettingInput;
};

export type AccountDeactivationSettingsMutationResult = {
  accountDeactivationSettings?: Maybe<AccountDeactivationSettings>;
  error?: Maybe<AccountDeactivationSettings>;
};

export type AccountDeactivationSettingsMutationResultError =
  AccountDeactivationSettingsMutationResult & {
    __typename?: 'AccountDeactivationSettingsMutationResultError';
    accountDeactivationSettings?: Maybe<AccountDeactivationSettings>;
    error?: Maybe<AccountDeactivationSettings>;
  };

export type AccountDeactivationSettingsMutationResultSuccess =
  AccountDeactivationSettingsMutationResult & {
    __typename?: 'AccountDeactivationSettingsMutationResultSuccess';
    accountDeactivationSettings?: Maybe<AccountDeactivationSettings>;
    error?: Maybe<AccountDeactivationSettings>;
  };

export type AccountDeletionSettings = {
  __typename?: 'AccountDeletionSettings';
  deletion?: Maybe<DeletionSetting>;
};

export type AccountDeletionSettingsInput = {
  deletion: DeletionSettingInput;
};

export type AccountDeletionSettingsMutationResult = {
  accountDeletionSettings?: Maybe<AccountDeletionSettings>;
  error?: Maybe<AccountDeletionSettings>;
};

export type AccountDeletionSettingsMutationResultError =
  AccountDeletionSettingsMutationResult & {
    __typename?: 'AccountDeletionSettingsMutationResultError';
    accountDeletionSettings?: Maybe<AccountDeletionSettings>;
    error?: Maybe<AccountDeletionSettings>;
  };

export type AccountDeletionSettingsMutationResultSuccess =
  AccountDeletionSettingsMutationResult & {
    __typename?: 'AccountDeletionSettingsMutationResultSuccess';
    accountDeletionSettings?: Maybe<AccountDeletionSettings>;
    error?: Maybe<AccountDeletionSettings>;
  };

/** Account related settings */
export type AccountSettings = {
  __typename?: 'AccountSettings';
  /** #soft-logged-in */
  createdAt?: Maybe<TextSetting>;
  email?: Maybe<EmailSetting>;
  /** #soft-logged-in */
  language?: Maybe<SelectSetting>;
  phone?: Maybe<PhoneSetting>;
  /** #soft-logged-in */
  timeZone?: Maybe<SelectSetting>;
  userMayDeleteThemselves?: Maybe<ToggleSetting>;
  userName?: Maybe<TextSetting>;
};

export type AccountSettingsInput = {
  email?: InputMaybe<EmailSettingInput>;
  language?: InputMaybe<SelectSettingInput>;
  phone?: InputMaybe<PhoneSettingInput>;
  timeZone?: InputMaybe<SelectSettingInput>;
  userName?: InputMaybe<TextSettingInput>;
};

export type AccountSettingsMutationResult = {
  __typename?: 'AccountSettingsMutationResult';
  error?: Maybe<AccountSettings>;
  response?: Maybe<AccountSettings>;
};

/** Activities settings (stored in Perl user properties in settings app) */
export type ActivitiesSettings = {
  __typename?: 'ActivitiesSettings';
  /** Other activities: whether activity should be created when having an anniversary */
  anniversary?: Maybe<ToggleSetting>;
  /** Profile changes: whether activity should be created on profile change of photo or business contact details */
  basicData?: Maybe<ToggleSetting>;
  /** Other activities: whether activity should be created when following a company */
  companyProfiles?: Maybe<ToggleSetting>;
  /** Other activities: whether activity should be created when following content page or commenting on klartext article */
  cppFollow?: Maybe<ToggleSetting>;
  /** Other activities: whether activity should be created when attending or organising an event */
  events?: Maybe<ToggleSetting>;
  /** Other activities: whether activity should be created when joining a group or posting in a group */
  groupMemberships?: Maybe<ToggleSetting>;
  /** Other activities: whether activity should be created when adding new contact */
  newContacts?: Maybe<ToggleSetting>;
  /** Profile changes: whether activity should be created on profile change of wants, haves etc */
  personalData?: Maybe<ToggleSetting>;
  /** Other activities: whether activity you like/comment on should be spread via social proof */
  socialReactions?: Maybe<ToggleSetting>;
  /** Profile changes: whether activity should be created on profile change of professional experience */
  workExperience?: Maybe<ToggleSetting>;
};

export type ActivitiesSettingsInput = {
  /** Other activities: whether activity should be created when having an anniversary */
  anniversary?: InputMaybe<ToggleSettingInput>;
  /** Profile changes: whether activity should be created when changing a photo or business contact details */
  basicData?: InputMaybe<ToggleSettingInput>;
  /** Other activities: whether activity should be created when following a company */
  companyProfiles?: InputMaybe<ToggleSettingInput>;
  /** Other activities: whether activity should be created when following content page or commenting on klartext article */
  cppFollow?: InputMaybe<ToggleSettingInput>;
  /** Other activities: whether activity should be created when attending or organising an event */
  events?: InputMaybe<ToggleSettingInput>;
  /** Other activities: whether activity should be created when joining a group or posting in a group */
  groupMemberships?: InputMaybe<ToggleSettingInput>;
  /** Other activities: whether activity should be created when adding new contact */
  newContacts?: InputMaybe<ToggleSettingInput>;
  /** Profile changes: whether activity should be created when changing wants, haves etc */
  personalData?: InputMaybe<ToggleSettingInput>;
  /** Other activities: whether activity you like/comment on should be spread via social proof */
  socialReactions?: InputMaybe<ToggleSettingInput>;
  /** Profile changes: whether activity should be created when changing professional experience */
  workExperience?: InputMaybe<ToggleSettingInput>;
};

export type ActivitiesSettingsMutationResult = {
  __typename?: 'ActivitiesSettingsMutationResult';
  error?: Maybe<ActivitiesSettings>;
  response?: Maybe<ActivitiesSettings>;
};

export type AdAuctionWinners = {
  __typename?: 'AdAuctionWinners';
  /** #logged-out #soft-logged-in */
  collection: Array<WinningAd>;
};

export type AdConversationStarter = ConversationStarter & {
  __typename?: 'AdConversationStarter';
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type AdImage = {
  __typename?: 'AdImage';
  /** #logged-out #soft-logged-in */
  large?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  small: Scalars['URL']['output'];
};

export type AdNegativeFeedbackError = {
  __typename?: 'AdNegativeFeedbackError';
  message?: Maybe<Scalars['String']['output']>;
};

export type AdNegativeFeedbackInput = {
  adId: Scalars['ID']['input'];
  deliveryId: Scalars['ID']['input'];
};

export type AdSender = {
  __typename?: 'AdSender';
  /** #logged-out #soft-logged-in */
  followersCount?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  image: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  jobDescription?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  link: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  name: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  surn: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  type: AdSenderTypes;
  /** #logged-out #soft-logged-in */
  urn: Scalars['GlobalID']['output'];
};

export const enum AdSenderTypes {
  Company = 'company',
  EntityPage = 'entity_page',
  Page = 'page',
  PublisherPage = 'publisher_page',
  User = 'user',
}

export const enum AdTypes {
  EntityPageAd = 'EntityPageAd',
  EventAd = 'EventAd',
  LeadAd = 'LeadAd',
  PostingAd = 'PostingAd',
  VideoAd = 'VideoAd',
  WebsiteAd = 'WebsiteAd',
}

export type AddMessengerChatParticipantsError = {
  __typename?: 'AddMessengerChatParticipantsError';
  message: Scalars['String']['output'];
};

export type AddMessengerChatParticipantsInput = {
  chatId: Scalars['ID']['input'];
  participantUserIds: Array<Scalars['ID']['input']>;
};

export type AddMessengerChatParticipantsResult =
  | AddMessengerChatParticipantsError
  | MessengerChat;

/** A standard container for representing an address */
export type Address = Addressable & {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  country?: Maybe<Country>;
  /** #rest(batched) #virtual */
  province?: Maybe<Province>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

/**
 * Defines the interface for an address. We have a default implementation
 * `Address` that should cover most of the cases. Use this interface only
 * when you want to represent address-like data and composition is not an option.
 */
export type Addressable = {
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  /** @deprecated use addressable.country.provinces instead */
  province?: Maybe<Province>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AdsTrackingError = {
  __typename?: 'AdsTrackingError';
  message?: Maybe<Scalars['String']['output']>;
};

export type AdsTrackingInput = {
  /** Id of the ad */
  id: Scalars['ID']['input'];
  /** Tracking token received from the backend */
  token: Scalars['String']['input'];
};

export type AdsTrackingIntIdError = {
  __typename?: 'AdsTrackingIntIdError';
  message?: Maybe<Scalars['String']['output']>;
};

export type AdsTrackingIntIdInput = {
  /** Id of the ad */
  id: Scalars['Int']['input'];
  /** Tracking token received from the backend */
  token: Scalars['String']['input'];
};

export type AdsTrackingIntIdResult = {
  __typename?: 'AdsTrackingIntIdResult';
  error?: Maybe<AdsTrackingIntIdError>;
};

export type AdsTrackingResult = {
  __typename?: 'AdsTrackingResult';
  error?: Maybe<AdsTrackingError>;
};

export type AgeGroup = Localizable & {
  __typename?: 'AgeGroup';
  id: Scalars['ID']['output'];
  /** The raw text resource key from Phraseapp */
  localizationKey: Scalars['String']['output'];
  /** The value localized based on the `Accept-Language` header */
  localizationValue: Scalars['String']['output'];
};

export type AgeGroupElement = {
  __typename?: 'AgeGroupElement';
  ageGroup?: Maybe<AgeGroup>;
  total: Scalars['Int']['output'];
};

export type AggregatedNotification = {
  /** #rest(batched) #virtual */
  bucketPreview?: Maybe<Array<Maybe<Notification>>>;
  total: Scalars['Int']['output'];
};

/** By default we define an aggregation like a count (Int) and a value (String). The value is the id for applying the filter and the count is the number of documents which match that filter */
export type Aggregation = HasAggregationCount & {
  __typename?: 'Aggregation';
  count: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** At Any Time Phone Preferences Type */
export type AnyTimePhonePreferences = {
  __typename?: 'AnyTimePhonePreferences';
  at: PhonePreferencesOptions;
};

export type AppStats = {
  __typename?: 'AppStats';
  /**
   * #soft-logged-in
   *
   *  number of new activity related notifications
   */
  activities?: Maybe<Scalars['Int']['output']>;
  /** number of birthdays today */
  birthdays?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  number of unseen contact requests
   */
  contactRequests?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  number of new updates on the event market
   */
  events?: Maybe<Scalars['Int']['output']>;
  /**
   * number of new community related updates
   * @deprecated group badge is not available anymore
   */
  groups?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  number of new community related updates (web only)
   * @deprecated group badge is not available anymore
   */
  groupsWeb?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  number of updates on the job market
   */
  jobs?: Maybe<Scalars['Int']['output']>;
  /** number of unseen updates for klartext */
  klartext?: Maybe<Scalars['Int']['output']>;
  /** number of newly acquired contacts */
  latestContacts?: Maybe<Scalars['Int']['output']>;
  /** number of unseen updates for Me Hub (Armstrong) */
  meHub?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  number of new aggregated and single notifications for the new notification center (web only)
   */
  ncWeb?: Maybe<Scalars['Int']['output']>;
  /** number of unseen updates for Network Home (Armstrong) */
  networkHome?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  number of unseen updates in the news section
   */
  news?: Maybe<Scalars['Int']['output']>;
  /** number of unseen updates in the news section (web only) */
  newsFrontPage?: Maybe<Scalars['Int']['output']>;
  /** number of unseen updates in the news section (mobile only) */
  newsMobile?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  number of new notifications in the notification center (web only)
   */
  notificationCenterWeb?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  number of new notifications in the notification center for vision type version (web only)
   */
  notificationCenterWebVisionType?: Maybe<Scalars['Int']['output']>;
  /** number of new premium perks */
  premiumPerksNew?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  number of probusiness related notifications
   */
  probusinessNotifications?: Maybe<Scalars['Int']['output']>;
  /** number of unseen updates for Resonance Hub (Armstrong) */
  resonanceHub?: Maybe<Scalars['Int']['output']>;
  /** number of received contact requests */
  toConfirm?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  number of chats that haven't been marked as read
   */
  unreadChats?: Maybe<Scalars['Int']['output']>;
  /** number of chats that haven't been seen yet */
  unseenChats?: Maybe<Scalars['Int']['output']>;
  /** number of unseen messages for Messenger (Armstrong) */
  unseenMessages?: Maybe<Scalars['Int']['output']>;
  /** number of unseen profile visits */
  visitors?: Maybe<Scalars['Int']['output']>;
};

export type ApplyServiceSettings = {
  __typename?: 'ApplyServiceSettings';
  /** #logged-out #soft-logged-in */
  loggedoutApplicationsEnabled: Scalars['Boolean']['output'];
};

/** Possible block types */
export type ArticleBlock =
  | ArticleBodyImage
  | ArticleEmphasis
  | ArticleH2
  | ArticleH3
  | ArticleH4
  | ArticleOrderedListItem
  | ArticleParagraph
  | ArticleQuote
  | ArticleUnorderedListItem;

export type ArticleBodyImage = {
  __typename?: 'ArticleBodyImage';
  /** #logged-out #soft-logged-in */
  caption?: Maybe<ArticleCaption>;
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in #virtual */
  image?: Maybe<Array<ScaledImage>>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  imageMetadata?: Maybe<OriginalImageMetadata>;
  /** #logged-out #soft-logged-in */
  uuid: Scalars['UUID']['output'];
};

export type ArticleBodyImageImageArgs = {
  dimensions: Array<ScaledImageDimension>;
};

/**
 * Input for the body image block, where `inputType` determines which action will be taken in the backend.
 * If IMAGE_CACHE_UUID is passed, then an Image Cache UUID is expected in `inputValue` and the service will keep the image as is.
 * If it's UPLOAD_ID, then an Upload ID is expected in `inputValue` and the service will upload a new image to Image Cache.
 */
export type ArticleBodyImageInput = {
  caption?: InputMaybe<ArticleTextInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inputType: ArticleMediaInputType;
  inputValue: Scalars['String']['input'];
};

export type ArticleBoldMarkup = ArticleMarkupInterface & {
  __typename?: 'ArticleBoldMarkup';
  /** #logged-out #soft-logged-in */
  end: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  start: Scalars['Int']['output'];
};

export type ArticleCaption = {
  __typename?: 'ArticleCaption';
  /** #logged-out #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type ArticleCover = ArticleCoverImage | ArticleCoverVideo;

export type ArticleCoverImage = {
  __typename?: 'ArticleCoverImage';
  /** #logged-out #soft-logged-in */
  caption?: Maybe<ArticleCaption>;
  /** #logged-out #soft-logged-in #virtual */
  image?: Maybe<Array<ScaledImage>>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  imageMetadata?: Maybe<OriginalImageMetadata>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type ArticleCoverImageImageArgs = {
  dimensions: Array<ScaledImageDimension>;
};

export type ArticleCoverVideo = {
  __typename?: 'ArticleCoverVideo';
  /** #logged-out #soft-logged-in */
  caption?: Maybe<ArticleCaption>;
  /** #logged-out #soft-logged-in */
  ref?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  video?: Maybe<VideoV2>;
};

export type ArticleEmphasis = {
  __typename?: 'ArticleEmphasis';
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export type ArticleH2 = ArticleTextWithMarkup & {
  __typename?: 'ArticleH2';
  /** #logged-out #soft-logged-in */
  markups: Array<ArticleMarkupInterface>;
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export type ArticleH3 = ArticleTextWithMarkup & {
  __typename?: 'ArticleH3';
  /** #logged-out #soft-logged-in */
  markups: Array<ArticleMarkupInterface>;
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export type ArticleH4 = ArticleTextWithMarkup & {
  __typename?: 'ArticleH4';
  /** #logged-out #soft-logged-in */
  markups: Array<ArticleMarkupInterface>;
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export type ArticleHeader = {
  __typename?: 'ArticleHeader';
  /** #logged-out #soft-logged-in */
  cover?: Maybe<ArticleCover>;
  /** #logged-out #soft-logged-in */
  summary?: Maybe<ArticleSummary>;
  /** #logged-out #soft-logged-in */
  title: ArticleTitle;
};

export type ArticleHeaderCoverImageInput = {
  inputType: ArticleMediaInputType;
  inputValue: Scalars['String']['input'];
};

export type ArticleHeaderCoverInput = {
  caption?: InputMaybe<ArticleTextInput>;
  image?: InputMaybe<ArticleHeaderCoverImageInput>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  videoRef?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleItalicMarkup = ArticleMarkupInterface & {
  __typename?: 'ArticleItalicMarkup';
  /** #logged-out #soft-logged-in */
  end: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  start: Scalars['Int']['output'];
};

export type ArticleLinkMarkup = ArticleMarkupInterface & {
  __typename?: 'ArticleLinkMarkup';
  /** #logged-out #soft-logged-in */
  end: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  href: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  start: Scalars['Int']['output'];
};

export type ArticleLinkMarkupInput = {
  end: Scalars['Int']['input'];
  href: Scalars['URL']['input'];
  start: Scalars['Int']['input'];
};

/** Only one of them is allowed */
export type ArticleMarkupInput = {
  bold?: InputMaybe<ArticleSimpleMarkupInput>;
  italic?: InputMaybe<ArticleSimpleMarkupInput>;
  link?: InputMaybe<ArticleLinkMarkupInput>;
  mention?: InputMaybe<ArticleMentionMarkupInput>;
};

/** Article Markup specifies a range of characters in a text to be styled, depending on markup type. */
export type ArticleMarkupInterface = {
  /** #logged-out #soft-logged-in */
  end: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  start: Scalars['Int']['output'];
};

export const enum ArticleMediaInputType {
  ImageCacheUuid = 'IMAGE_CACHE_UUID',
  UploadId = 'UPLOAD_ID',
}

export const enum ArticleMembership {
  NoMembership = 'NO_MEMBERSHIP',
  Premium = 'PREMIUM',
  Projobs = 'PROJOBS',
}

export type ArticleMentionMarkup = ArticleMarkupInterface & {
  __typename?: 'ArticleMentionMarkup';
  /** #logged-out #soft-logged-in */
  end: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  start: Scalars['Int']['output'];
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  /** #logged-out #soft-logged-in */
  userId: Scalars['ID']['output'];
};

export type ArticleMentionMarkupInput = {
  end: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};

export type ArticleOrderedListItem = ArticleTextWithMarkup & {
  __typename?: 'ArticleOrderedListItem';
  /** #logged-out #soft-logged-in */
  markups: Array<ArticleMarkupInterface>;
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export type ArticleParagraph = ArticleTextWithMarkup & {
  __typename?: 'ArticleParagraph';
  /** #logged-out #soft-logged-in */
  markups: Array<ArticleMarkupInterface>;
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export const enum ArticlePublicationState {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED',
}

export type ArticleQuote = {
  __typename?: 'ArticleQuote';
  /** #logged-out #soft-logged-in */
  source?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export type ArticleQuoteInput = {
  source?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

/** SEO related features */
export type ArticleSettings = {
  __typename?: 'ArticleSettings';
  /**
   * #logged-out #soft-logged-in
   *
   *  descriptionTag isnt the same as the article description, its manually updated by SEO Managers
   */
  descriptionTag?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  membership: ArticleMembership;
  /** #logged-out #soft-logged-in */
  noindex: Scalars['Boolean']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  titleTag isnt the same as the article title, its manually updated by SEO Managers
   */
  titleTag?: Maybe<Scalars['String']['output']>;
};

export type ArticleSettingsInput = {
  descriptionTag?: InputMaybe<Scalars['String']['input']>;
  membership?: InputMaybe<ArticleMembership>;
  noindex?: InputMaybe<Scalars['Boolean']['input']>;
  titleTag?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleSimpleMarkupInput = {
  end: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
};

export type ArticleSummary = {
  __typename?: 'ArticleSummary';
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export const enum ArticleSupportedBlockTypes {
  ArticleBodyImage = 'ARTICLE_BODY_IMAGE',
  ArticleEmphasis = 'ARTICLE_EMPHASIS',
  ArticleH2 = 'ARTICLE_H2',
  ArticleH3 = 'ARTICLE_H3',
  ArticleH4 = 'ARTICLE_H4',
  ArticleOrderedListItem = 'ARTICLE_ORDERED_LIST_ITEM',
  ArticleParagraph = 'ARTICLE_PARAGRAPH',
  ArticleQuote = 'ARTICLE_QUOTE',
  ArticleUnorderedListItem = 'ARTICLE_UNORDERED_LIST_ITEM',
}

export type ArticleTextInput = {
  text: Scalars['String']['input'];
};

export type ArticleTextWithMarkup = {
  /** #logged-out #soft-logged-in */
  markups: Array<ArticleMarkupInterface>;
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export type ArticleTitle = {
  __typename?: 'ArticleTitle';
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export type ArticleUnorderedListItem = ArticleTextWithMarkup & {
  __typename?: 'ArticleUnorderedListItem';
  /** #logged-out #soft-logged-in */
  markups: Array<ArticleMarkupInterface>;
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

/** Permissions provided by any service that implements articles */
export type ArticlesAbilitiesInterface = {
  /** true if the current user can edit the article */
  canEdit: Scalars['Boolean']['output'];
};

/** Representation of an article */
export type ArticlesArticle = HasGlobalId & {
  __typename?: 'ArticlesArticle';
  /**
   * #logged-out #soft-logged-in
   *
   *  The article's content
   */
  blocks: Array<ArticleBlock>;
  /** #logged-out #soft-logged-in */
  content?: Maybe<Scalars['JSON']['output']>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  where the article belongs. E.g. a page
   */
  context?: Maybe<ArticlesContextInterface>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Identifier for where the article belongs. E.g. a page, an insider, etc.
   */
  contextGlobalId: Scalars['GlobalID']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Unique identifier for the Article (i.e. URN)
   */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  header: ArticleHeader;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Most recent version of article; used for optimistic locking
   */
  lockVersion: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  publicationState: ArticlePublicationState;
  /**
   * #logged-out #soft-logged-in
   *
   *  The date of publication. null indicates it's a draft.
   */
  publishedAt?: Maybe<Scalars['Date']['output']>;
  /** #expand #logged-out #soft-logged-in */
  settings: ArticleSettings;
  /** #logged-out #soft-logged-in */
  slug: Scalars['SlugOrID']['output'];
  /** #rest(batched) #virtual */
  socialInteractionTarget?: Maybe<SocialInteractionTarget>;
  /**
   * #logged-out #soft-logged-in
   *
   *  The URN to be used when integrating social interactions into this article
   */
  socialInteractionTargetUrn?: Maybe<Scalars['GlobalID']['output']>;
  /** #logged-out #soft-logged-in */
  visitUrl?: Maybe<Scalars['URL']['output']>;
};

export type ArticlesArticleImageSettings = {
  __typename?: 'ArticlesArticleImageSettings';
  downloadUrl: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

/**
 * Enables any service to embed articles.
 * Ensures presence of the minimum context information required by clients.
 */
export type ArticlesContextInterface = {
  abilities: ArticlesAbilitiesInterface;
};

/**
 * Only submit one of the attributes.
 *
 * For `articleBodyImage`: passing the `id` of an existing block will keep its content unchanged and update its position if necessary.
 * Not passing an `id` or passing one that does not belong to the parent article will create a new image block.
 */
export type ArticlesCreateArticleBlocksInput = {
  articleBodyImage?: InputMaybe<ArticleBodyImageInput>;
  articleEmphasis?: InputMaybe<ArticleTextInput>;
  articleH2?: InputMaybe<ArticlesTextWithMarkupsInput>;
  articleH3?: InputMaybe<ArticlesTextWithMarkupsInput>;
  articleH4?: InputMaybe<ArticlesTextWithMarkupsInput>;
  articleOrderedListItem?: InputMaybe<ArticlesTextWithMarkupsInput>;
  articleParagraph?: InputMaybe<ArticlesTextWithMarkupsInput>;
  articleQuote?: InputMaybe<ArticleQuoteInput>;
  articleUnorderedListItem?: InputMaybe<ArticlesTextWithMarkupsInput>;
};

export type ArticlesCreateArticleHeaderInput = {
  cover?: InputMaybe<ArticleHeaderCoverInput>;
  summary?: InputMaybe<ArticleTextInput>;
  title: ArticleTextInput;
};

export type ArticlesCreateArticleImageInput = {
  articleGlobalId: Scalars['GlobalID']['input'];
};

export type ArticlesCreateArticleImageResult =
  | ArticlesArticleImageSettings
  | ArticlesError;

export type ArticlesCreateArticleInput = {
  blocks: Array<ArticlesCreateArticleBlocksInput>;
  content?: InputMaybe<Scalars['JSON']['input']>;
  contextGlobalId: Scalars['GlobalID']['input'];
  header: ArticlesCreateArticleHeaderInput;
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['Date']['input']>;
  supportedBlockTypes: Array<ArticleSupportedBlockTypes>;
};

export type ArticlesDeleteArticleInput = {
  articleGlobalId: Scalars['GlobalID']['input'];
};

export type ArticlesDeleteArticleResult = ArticlesArticle | ArticlesError;

export type ArticlesError = {
  __typename?: 'ArticlesError';
  /** contains all errors that occured */
  details?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ArticlesPublishArticleInput = {
  articleGlobalId: Scalars['GlobalID']['input'];
  supportedBlockTypes: Array<ArticleSupportedBlockTypes>;
};

export type ArticlesPublishArticleResult = {
  __typename?: 'ArticlesPublishArticleResult';
  error?: Maybe<ArticlesError>;
  success?: Maybe<ArticlesArticle>;
};

export type ArticlesSaveArticleResult = {
  __typename?: 'ArticlesSaveArticleResult';
  error?: Maybe<ArticlesError>;
  success?: Maybe<ArticlesArticle>;
};

export type ArticlesTextWithMarkupsInput = {
  markups?: InputMaybe<Array<ArticleMarkupInput>>;
  text: Scalars['String']['input'];
};

export type ArticlesUnpublishArticleInput = {
  articleGlobalId: Scalars['GlobalID']['input'];
  supportedBlockTypes: Array<ArticleSupportedBlockTypes>;
};

export type ArticlesUnpublishArticleResult = ArticlesArticle | ArticlesError;

export type ArticlesUpdateArticleInput = {
  articleGlobalId: Scalars['GlobalID']['input'];
  blocks: Array<ArticlesCreateArticleBlocksInput>;
  content?: InputMaybe<Scalars['JSON']['input']>;
  header: ArticlesCreateArticleHeaderInput;
  lockVersion?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated use `articlesPublishArticle` or `articlesUnpublishArticle` instead */
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['Date']['input']>;
  supportedBlockTypes: Array<ArticleSupportedBlockTypes>;
};

export type ArticlesUpdateArticleSettingsInput = {
  articleGlobalId: Scalars['GlobalID']['input'];
  settings: ArticleSettingsInput;
  supportedBlockTypes: Array<ArticleSupportedBlockTypes>;
};

export type AtsCredentials = {
  __typename?: 'AtsCredentials';
  /** #logged-out #soft-logged-in */
  apiKey?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  atsOptions?: Maybe<Array<Scalars['String']['output']>>;
  /** #logged-out #soft-logged-in */
  companyId?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  contactEmail?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  identifier?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  organizationId?: Maybe<Scalars['Long']['output']>;
  /** #logged-out #soft-logged-in */
  preferredLocale?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  selectedAts?: Maybe<Scalars['String']['output']>;
};

export type AtsCredentialsInput = {
  apiKey?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Long']['input']>;
  preferredLocale?: InputMaybe<Scalars['String']['input']>;
  selectedAts?: InputMaybe<Scalars['String']['input']>;
};

export type AuctionAdPlaceholder = HasOperationalDataTrackingTokens & {
  __typename?: 'AuctionAdPlaceholder';
  channel: Scalars['String']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AuctionAttributes = {
  adCount: Scalars['Int']['input'];
  adTypes?: InputMaybe<Array<AdTypes>>;
  advertisementId?: InputMaybe<DeviceTrackingInput>;
  channel: Scalars['String']['input'];
  scrambledId?: InputMaybe<Scalars['String']['input']>;
};

export type AuctionEventAd = WinningAd & {
  __typename?: 'AuctionEventAd';
  /** #logged-out #soft-logged-in */
  dates: DateRange;
  /** #logged-out #soft-logged-in */
  deliveryId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Field no longer supported
   */
  id: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  image: AdImage;
  /** #logged-out #soft-logged-in */
  linkedEvent?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  location: EventLocation;
  /** #logged-out #soft-logged-in */
  networkParticipants?: Maybe<EventAdNetworkParticipants>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated ODT shutdown
   */
  odtTrackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  sender: AdSender;
  /** #logged-out #soft-logged-in */
  showTicketPrices?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  sid: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  surn: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  targetSurn: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  targetUrl: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  ticketCurrency?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  ticketPriceEnd?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  ticketPriceStart?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  title: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  type: AdTypes;
};

export type AuctionLeadAd = WinningAd & {
  __typename?: 'AuctionLeadAd';
  /** #logged-out #soft-logged-in */
  buttonCopy: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  deliveryId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Field no longer supported
   */
  id: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  image: AdImage;
  /** #logged-out #soft-logged-in */
  leadAdFormId: Scalars['ID']['output'];
  /**
   * #logged-out #soft-logged-in
   * @deprecated ODT shutdown
   */
  odtTrackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  sender: AdSender;
  /** #logged-out #soft-logged-in */
  sid: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  surn: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  targetUrl: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  title: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  type: AdTypes;
};

export type AuctionPageAd = WinningAd & {
  __typename?: 'AuctionPageAd';
  /** #logged-out #soft-logged-in */
  deliveryId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  entityPageAdId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  followerCount?: Maybe<Scalars['Int']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Field no longer supported
   */
  id: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  imageUrl?: Maybe<Scalars['URL']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated ODT shutdown
   */
  odtTrackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  sender: AdSender;
  /** #logged-out #soft-logged-in */
  sid: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  slogan?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  surn: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  type: AdTypes;
  /** #logged-out #soft-logged-in */
  userIsFollowing?: Maybe<Scalars['Boolean']['output']>;
};

export type AuctionPostingAd = WinningAd & {
  __typename?: 'AuctionPostingAd';
  /** #logged-out #soft-logged-in */
  deliveryId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Field no longer supported
   */
  id: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  image?: Maybe<AdImage>;
  /** #logged-out #soft-logged-in */
  linkImageUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  linkTeaserText?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated ODT shutdown
   */
  odtTrackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  postingActivitySurn?: Maybe<Scalars['GlobalID']['output']>;
  /** #logged-out #soft-logged-in */
  sender: AdSender;
  /** #logged-out #soft-logged-in */
  sid: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  siteName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  subtype: PostingAdSubType;
  /** #logged-out #soft-logged-in */
  surn: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  targetUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  type: AdTypes;
};

export type AuctionVideoAd = WinningAd & {
  __typename?: 'AuctionVideoAd';
  /** #logged-out #soft-logged-in */
  deliveryId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Field no longer supported
   */
  id: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  image: AdImage;
  /**
   * #logged-out #soft-logged-in
   * @deprecated ODT shutdown
   */
  odtTrackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  sender: AdSender;
  showed?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  sid: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  siteName: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  surn: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  targetUrl: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  thumbnails?: Maybe<Array<VideoAdImage>>;
  /** #logged-out #soft-logged-in */
  title: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  type: AdTypes;
  /** #logged-out #soft-logged-in */
  videoId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  videoRef: Scalars['SlugOrID']['output'];
};

export type AuctionWebsiteAd = WinningAd & {
  __typename?: 'AuctionWebsiteAd';
  /** #logged-out #soft-logged-in */
  deliveryId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Field no longer supported
   */
  id: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  image: AdImage;
  /**
   * #logged-out #soft-logged-in
   * @deprecated ODT shutdown
   */
  odtTrackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  sender: AdSender;
  showed?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  sid: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  siteName: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  surn: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  targetUrl: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  title: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  type: AdTypes;
};

export type AutocompletionCompanyNameSuggestion = {
  __typename?: 'AutocompletionCompanyNameSuggestion';
  /**
   * #rest(batched) #virtual
   *
   *  Company object
   */
  company?: Maybe<Company>;
  /** Company urn */
  globalID?: Maybe<Scalars['GlobalID']['output']>;
  /** Suggestion text with highlight */
  highlight?: Maybe<Scalars['String']['output']>;
  /** Link to logo image from companies rest api (shrinked to 285x70) */
  logo?: Maybe<Scalars['URL']['output']>;
  /** 0-based position in suggestion list */
  position?: Maybe<Scalars['Int']['output']>;
  /** Suggestion text */
  suggestion?: Maybe<Scalars['String']['output']>;
  /** Link to thumbnail size logo image from companies rest api (shrinked to 80x25) */
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /** Tracking token for use in autocompletionTrack mutation */
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type AutocompletionCompanyNameSuggestions = {
  __typename?: 'AutocompletionCompanyNameSuggestions';
  collection: Array<AutocompletionCompanyNameSuggestion>;
  /** Suggestions service name for use in autocompletionTrack mutation */
  service?: Maybe<Scalars['String']['output']>;
  /** #virtual */
  total: Scalars['Int']['output'];
};

export type AutocompletionJobsSkillSuggestion = {
  __typename?: 'AutocompletionJobsSkillSuggestion';
  /** Unique identifier of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** 0-based position in suggestion list */
  position?: Maybe<Scalars['Int']['output']>;
  /** Suggestion text */
  suggestion?: Maybe<Scalars['String']['output']>;
  /** Tracking token */
  trackingToken?: Maybe<Scalars['String']['output']>;
  /** URN of the entity */
  urn?: Maybe<Scalars['URN']['output']>;
};

export type AutocompletionJobsSkillSuggestions = {
  __typename?: 'AutocompletionJobsSkillSuggestions';
  collection: Array<Maybe<AutocompletionJobsSkillSuggestion>>;
  /** Suggestions service name for use in autocompletionTrack mutation */
  service?: Maybe<Scalars['String']['output']>;
  /** #virtual */
  total: Scalars['Int']['output'];
};

export type AutocompletionLocationsCitySuggestion = {
  __typename?: 'AutocompletionLocationsCitySuggestion';
  /**
   * #logged-out #soft-logged-in
   *
   *  City's province name
   */
  adminArea?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City's administrative district name
   */
  adminArea3?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City's administrative district ID
   */
  adminArea3Id?: Maybe<Scalars['Int']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City's administrative district ID
   */
  adminArea3IdWithScrambling?: Maybe<Scalars['ID']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City's province ID
   */
  adminAreaId?: Maybe<Scalars['Int']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City's province ID
   */
  adminAreaIdWithScrambling?: Maybe<Scalars['ID']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City ID as int
   */
  cityId?: Maybe<Scalars['Int']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City ID
   */
  cityIdWithScrambling?: Maybe<Scalars['ID']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City's country code
   */
  countryCode?: Maybe<CountryCodesAlpha2>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City's country name
   */
  formattedCountry?: Maybe<Scalars['String']['output']>;
  /** Suggestion text with highlight */
  highlight?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City's latitude
   */
  latitude?: Maybe<Scalars['Float']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City's longitude
   */
  longitude?: Maybe<Scalars['Float']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Suggestion text
   */
  name?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  0-based position in suggestion list
   */
  position?: Maybe<Scalars['Int']['output']>;
  /** Tracking token */
  trackingToken?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  City's zip codes
   */
  zipCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AutocompletionLocationsCitySuggestions = {
  __typename?: 'AutocompletionLocationsCitySuggestions';
  /** #logged-out #soft-logged-in */
  collection: Array<Maybe<AutocompletionLocationsCitySuggestion>>;
  /** Suggestions service name for use in autocompletionTrack mutation */
  service?: Maybe<Scalars['String']['output']>;
  /** #virtual #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type AutocompletionProfileFieldOfStudySuggestion = {
  __typename?: 'AutocompletionProfileFieldOfStudySuggestion';
  /** 0-based position in suggestion list */
  position?: Maybe<Scalars['Int']['output']>;
  /** Suggestion text */
  suggestion?: Maybe<Scalars['String']['output']>;
  /** Tracking token for use in autocompletionTrack mutation */
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type AutocompletionProfileFieldOfStudySuggestions = {
  __typename?: 'AutocompletionProfileFieldOfStudySuggestions';
  collection: Array<AutocompletionProfileFieldOfStudySuggestion>;
  /** Suggestions service name for use in autocompletionTrack mutation */
  service?: Maybe<Scalars['String']['output']>;
  /** #virtual */
  total: Scalars['Int']['output'];
};

export type AutocompletionProfileHaveSuggestion = {
  __typename?: 'AutocompletionProfileHaveSuggestion';
  /**
   * #logged-out #soft-logged-in
   *
   *  0-based position in suggestion list
   */
  position?: Maybe<Scalars['Int']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Suggestion text
   */
  suggestion?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Tracking token for use in autocompletionTrack mutation
   */
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type AutocompletionProfileHaveSuggestions = {
  __typename?: 'AutocompletionProfileHaveSuggestions';
  /** #logged-out #soft-logged-in */
  collection: Array<AutocompletionProfileHaveSuggestion>;
  /** Suggestions service name for use in autocompletionTrack mutation */
  service?: Maybe<Scalars['String']['output']>;
  /** #virtual #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type AutocompletionProfileJobRoleSuggestion = {
  __typename?: 'AutocompletionProfileJobRoleSuggestion';
  /**
   * #logged-out #soft-logged-in
   *
   *  Disciplines of the job role
   */
  disciplines?: Maybe<
    Array<Maybe<AutocompletionProfileJobRoleSuggestionDiscipline>>
  >;
  /** Suggestion text with highlight */
  highlight?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Unique identifier of the entity
   */
  id?: Maybe<Scalars['ID']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  0-based position in suggestion list
   */
  position?: Maybe<Scalars['Int']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Suggestion text
   */
  suggestion?: Maybe<Scalars['String']['output']>;
  /** Tracking token for use in autocompletionTrack mutation */
  trackingToken?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  URN of the entity
   */
  urn?: Maybe<Scalars['URN']['output']>;
};

export type AutocompletionProfileJobRoleSuggestionDiscipline = {
  __typename?: 'AutocompletionProfileJobRoleSuggestionDiscipline';
  /** Unique identifier of the entity */
  disciplineId?: Maybe<Scalars['ID']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  /** Name of discipline */
  label?: Maybe<Scalars['String']['output']>;
  /** Localized string to be shown */
  lang?: Maybe<Scalars['String']['output']>;
  relType?: Maybe<Scalars['String']['output']>;
  /** Identifier used by Xing tooling */
  xingId?: Maybe<Scalars['ID']['output']>;
};

export type AutocompletionProfileJobRoleSuggestions = {
  __typename?: 'AutocompletionProfileJobRoleSuggestions';
  /** #logged-out #soft-logged-in */
  collection: Array<AutocompletionProfileJobRoleSuggestion>;
  /** Suggestions service name for use in autocompletionTrack mutation */
  service?: Maybe<Scalars['String']['output']>;
  /** #virtual #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type AutocompletionProfileProfileSuggestion = {
  __typename?: 'AutocompletionProfileProfileSuggestion';
  /** Unique identifier of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** 0-based position in suggestion list */
  position?: Maybe<Scalars['Int']['output']>;
  /** Tracking token */
  trackingToken?: Maybe<Scalars['String']['output']>;
  /**
   * #rest(batched) #virtual
   *
   *  The profile information for the user
   */
  xingId?: Maybe<XingId>;
};

/**
 * Use this if you want only members incremental/autocomplete search. For more info about filters you can use check https://apidoc.xing.io/apidoc/?api=autocompletion%2Frest#/routes/getProfilesProfileSuggestions
 * If you need further support, contact nw-ds-support Slack channel.
 */
export type AutocompletionProfileProfileSuggestions = {
  __typename?: 'AutocompletionProfileProfileSuggestions';
  collection: Array<Maybe<AutocompletionProfileProfileSuggestion>>;
  /** #virtual */
  total: Scalars['Int']['output'];
};

export type AutocompletionProfileUniversitySuggestion = {
  __typename?: 'AutocompletionProfileUniversitySuggestion';
  /** 0-based position in suggestion list */
  position?: Maybe<Scalars['Int']['output']>;
  /** Suggestion text */
  suggestion?: Maybe<Scalars['String']['output']>;
  /** Tracking token for use in autocompletionTrack mutation */
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type AutocompletionProfileUniversitySuggestions = {
  __typename?: 'AutocompletionProfileUniversitySuggestions';
  collection: Array<AutocompletionProfileUniversitySuggestion>;
  /** Suggestions service name for use in autocompletionTrack mutation */
  service?: Maybe<Scalars['String']['output']>;
  /** #virtual */
  total: Scalars['Int']['output'];
};

/** Available Xing ID actions */
export const enum AvailableAction {
  /** Make a contact request */
  AddContact = 'ADD_CONTACT',
  /** Add a ProBusiness Lead */
  AddLead = 'ADD_LEAD',
  /** Block a user. Only available for non contacts */
  BlockUser = 'BLOCK_USER',
  /** Bookmark interesting user. Only available for non contacts */
  BookmarkUser = 'BOOKMARK_USER',
  /** Call a contact. Only available on mobile */
  Call = 'CALL',
  /** Confirm a contact */
  ConfirmContact = 'CONFIRM_CONTACT',
  /** Decline contact request */
  DeclineContactRequest = 'DECLINE_CONTACT_REQUEST',
  /** Delete an exiting contact */
  DeleteContact = 'DELETE_CONTACT',
  /** Edit XING ID. Only available for self */
  EditXingId = 'EDIT_XING_ID',
  /** Follow a contact. Only available for insiders */
  Follow = 'FOLLOW',
  /** Import VCard. Only available on mobile */
  ImportVcard = 'IMPORT_VCARD',
  /** Invite user to a group. Only available for contacts */
  InviteGroup = 'INVITE_GROUP',
  /** Lunch Profile Filling Wizard. Only available for self */
  LaunchWizard = 'LAUNCH_WIZARD',
  /**
   * Show ProJobs settings related to career wishes. Only available for self
   * @deprecated This feature got removed from current Apps
   */
  MyCareerWishes = 'MY_CAREER_WISHES',
  /** Open the insider article collection */
  OpenInsiderCollection = 'OPEN_INSIDER_COLLECTION',
  /** Open preview of LO profile */
  OpenLoPreview = 'OPEN_LO_PREVIEW',
  /** Open recruiter preview of xtm */
  OpenRecruiterPreview = 'OPEN_RECRUITER_PREVIEW',
  /** Open settings page. Only available for self */
  OpenSettings = 'OPEN_SETTINGS',
  /** Open an XTM profile. Only available for contacts with XTM */
  OpenXtm = 'OPEN_XTM',
  /** Show a printable version of the Profile */
  Print = 'PRINT',
  /** Remove a ProBusiness Lead */
  RemoveLead = 'REMOVE_LEAD',
  /** Report contact. Only available for contacts */
  ReportProfile = 'REPORT_PROFILE',
  /** Send a message */
  SendMessage = 'SEND_MESSAGE',
  /** Share a contact. Only available on mobile */
  Share = 'SHARE',
  /**
   * Show ProJobs career wishes to visitors. Only available for others
   * @deprecated This feature got removed from current Apps
   */
  ShowCareerWishes = 'SHOW_CAREER_WISHES',
  /** Show users contact details */
  ShowContactDetails = 'SHOW_CONTACT_DETAILS',
  /** Unblock a user. Only available for non contacts */
  UnblockUser = 'UNBLOCK_USER',
  /** Unbookmark a user. Only available for non contacts */
  UnbookmarkUser = 'UNBOOKMARK_USER',
  /** Unfollow a contact. Only available for insiders */
  Unfollow = 'UNFOLLOW',
}

export type B2BProfileFieldError = {
  __typename?: 'B2BProfileFieldError';
  error?: Maybe<Scalars['String']['output']>;
};

export type B2BProfileSettings = {
  __typename?: 'B2BProfileSettings';
  businessEmail?: Maybe<Scalars['String']['output']>;
  hashedEmail?: Maybe<Scalars['String']['output']>;
  language?: Maybe<LanguageCode>;
};

export type B2BProfileSettingsError = {
  __typename?: 'B2BProfileSettingsError';
  errors?: Maybe<B2BProfileSettingsErrors>;
};

export type B2BProfileSettingsErrors = {
  __typename?: 'B2BProfileSettingsErrors';
  businessEmail?: Maybe<Array<Maybe<B2BProfileFieldError>>>;
  language?: Maybe<Array<Maybe<B2BProfileFieldError>>>;
};

export type B2BProfileSettingsInput = {
  businessEmail?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<LanguageCode>;
};

export type B2BProfileSettingsSuccess = {
  __typename?: 'B2BProfileSettingsSuccess';
  data?: Maybe<B2BProfileSettings>;
};

export type BannerProperty = {
  __typename?: 'BannerProperty';
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type BannerPropertyDisableError = {
  __typename?: 'BannerPropertyDisableError';
  message: Scalars['String']['output'];
};

export type BannerPropertyDisableInput = {
  propertyName?: InputMaybe<BannerPropertyName>;
};

export type BannerPropertyDisableResult =
  | BannerPropertyDisableError
  | BannerPropertyDisableSuccess;

export type BannerPropertyDisableSuccess = {
  __typename?: 'BannerPropertyDisableSuccess';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export const enum BannerPropertyName {
  DisplayInsightsBanner = 'DISPLAY_INSIGHTS_BANNER',
}

/** Deprecated: JobSeeker object for Basic and Premium Users */
export type Basic = JobSeeker & {
  __typename?: 'Basic';
  id: Scalars['ID']['output'];
  /** #paginated #rest #virtual */
  idealEmployers?: Maybe<ProJobsIdealEmployersConnection>;
  isPendingFirstEdit: Scalars['Boolean']['output'];
  salaryExpectation?: Maybe<Salary>;
  seekingStatus: SeekingStatus;
  /** @deprecated The type Basic is deprecated, this field will be removed soon. WILL BE REMOVED ... */
  statusVisibility?: Maybe<StatusVisibility>;
};

/** Deprecated: JobSeeker object for Basic and Premium Users */
export type BasicIdealEmployersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Count of contacts for a specific user */
export type BatchContactsCount = {
  __typename?: 'BatchContactsCount';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
};

/** Personal Details module data of the user */
export type BirthDate = {
  __typename?: 'BirthDate';
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year?: Maybe<Scalars['Int']['output']>;
};

export type BirthDateUpdateInput = {
  day: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type BirthdayConversationStarter = ConversationStarter & {
  __typename?: 'BirthdayConversationStarter';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<PersonalDetails>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type BirthdayNotification = Notification & {
  __typename?: 'BirthdayNotification';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  aggregated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interacted: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<PersonalDetails>;
  opened: Scalars['Boolean']['output'];
  publishedAt: Scalars['Date']['output'];
  seen: Scalars['Boolean']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn: Scalars['URN']['output'];
};

export type BirthdayStack = SignalsStack & {
  __typename?: 'BirthdayStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type BirthdayStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type BlacklistedSkills = {
  __typename?: 'BlacklistedSkills';
  skills: Array<Scalars['ID']['output']>;
  userId: Scalars['ID']['output'];
};

export type BlacklistedSkillsError = {
  __typename?: 'BlacklistedSkillsError';
  error: Scalars['String']['output'];
};

export type BlacklistedSkillsInput = {
  skills: Array<Scalars['ID']['input']>;
};

export type BlacklistedSkillsResult =
  | BlacklistedSkills
  | BlacklistedSkillsError;

/** BlockedCompanies Type */
export type BlockedCompanies = {
  __typename?: 'BlockedCompanies';
  /** #rest(batched) #virtual */
  companies: Array<Maybe<Company>>;
};

export type BlockedEntitiesConnection = HasCursorPagination & {
  __typename?: 'BlockedEntitiesConnection';
  edges: Array<BlockedEntitiesEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type BlockedEntitiesEdge = PaginationEdge & {
  __typename?: 'BlockedEntitiesEdge';
  cursor: Scalars['String']['output'];
  /** #rest(batched) #virtual */
  node: BlockingServiceBlockedEntityObject;
  urn: Scalars['GlobalID']['output'];
};

export type BlockedEntitiesPage = {
  __typename?: 'BlockedEntitiesPage';
  /** #paginated #rest #virtual */
  blockedEntitiesPaginated?: Maybe<BlockedEntitiesConnection>;
};

export type BlockedEntitiesPageBlockedEntitiesPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  entitiesSelection?: InputMaybe<BlockedServiceEntitiesSelection>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export const enum BlockedServiceEntitiesSelection {
  /** Return only non users blocked entities */
  NonUsers = 'NON_USERS',
  /** Return only blocked user entities */
  Users = 'USERS',
}

export type BlockingServiceBlockError = {
  __typename?: 'BlockingServiceBlockError';
  errors?: Maybe<Scalars['JSON']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type BlockingServiceBlockInput = {
  urns?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

export type BlockingServiceBlockResult =
  | BlockingServiceBlockError
  | BlockingServiceBlockSuccess;

export type BlockingServiceBlockSuccess = {
  __typename?: 'BlockingServiceBlockSuccess';
  message?: Maybe<Scalars['String']['output']>;
};

export type BlockingServiceBlockedEntity = {
  __typename?: 'BlockingServiceBlockedEntity';
  entityType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  urn: Scalars['GlobalID']['output'];
};

export type BlockingServiceBlockedEntityObject =
  | BlockingServiceBlockedEntity
  | XingId;

export type BlockingServiceUnblockError = {
  __typename?: 'BlockingServiceUnblockError';
  errors?: Maybe<Scalars['JSON']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type BlockingServiceUnblockInput = {
  urn: Scalars['GlobalID']['input'];
};

export type BlockingServiceUnblockResult =
  | BlockingServiceUnblockError
  | BlockingServiceUnblockSuccess;

export type BlockingServiceUnblockSuccess = {
  __typename?: 'BlockingServiceUnblockSuccess';
  message?: Maybe<Scalars['String']['output']>;
};

export type BpayClientBalance = {
  __typename?: 'BpayClientBalance';
  /** Amount in cents. e.g. 25.17 EUR would be 2517 */
  amountCents?: Maybe<Scalars['Int']['output']>;
  /** ISO-4217 currency codes. e.g. EUR, USD, CHF */
  currency?: Maybe<PaymentCurrency>;
  /** A product group related to the credit/discount amount */
  productGroup?: Maybe<PaymentClientProductGroup>;
};

/** Client credit/discount balance data */
export type BpayClientBalanceData = {
  __typename?: 'BpayClientBalanceData';
  /** An array of credits of the client with product group and currency */
  creditBalance?: Maybe<Array<BpayClientBalance>>;
  /** An array of discounts of the client with product group and currency */
  discountBalance?: Maybe<Array<BpayClientBalance>>;
  /** An array of discounts with expiration date and amount left specified */
  expiringDiscounts?: Maybe<Array<BpayExpiringClientDiscount>>;
  /** Blocked credit means contract of the credit has `blocked_invoice` in `true` */
  hasBlockedCredits: Scalars['Boolean']['output'];
  /** Blocked discount means contract of the discount has `blocked_invoice` in `true` */
  hasBlockedDiscounts: Scalars['Boolean']['output'];
};

export type BpayClientBalanceResult = BpayClientBalanceData | ErrorResponse;

export type BpayCreditCardUrl = {
  __typename?: 'BpayCreditCardUrl';
  url?: Maybe<Scalars['String']['output']>;
};

export type BpayExpiringClientDiscount = {
  __typename?: 'BpayExpiringClientDiscount';
  /** An amount left after discount usages, in cents. e.g. 25.17 EUR would be 2517 */
  amountCentsLeft?: Maybe<Scalars['Int']['output']>;
  /** A discount expiration date */
  expiresAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Link type */
export type BpayLink = {
  __typename?: 'BpayLink';
  text?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Pay method types */
export type BpayPaymentMethodData = {
  clientName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** Payment method Query/Mutation */
export type BpayPaymentMethodDataResult =
  | CreditCardData
  | DirectDebitData
  | ErrorResponse
  | InvoiceData;

export type BpayPaymentMethodInput = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  embeddedBy?: InputMaybe<Scalars['String']['input']>;
  paymethod: BpayPaymentMethodTypeInput;
  sepa?: InputMaybe<BpaySepaPaymentMethodInput>;
  testActionAllowed?: InputMaybe<Scalars['Boolean']['input']>;
};

export const enum BpayPaymentMethodTypeInput {
  Invoice = 'INVOICE',
  Sepa = 'SEPA',
}

export type BpayProductGroupRunningContractsResponse = {
  __typename?: 'BpayProductGroupRunningContractsResponse';
  contractsSummary?: Maybe<Scalars['String']['output']>;
  productGroupLink?: Maybe<BpayLink>;
  runningContractProducts?: Maybe<Array<Maybe<BpayRunningContractsTable>>>;
};

export type BpayProductGroupRunningContractsResult =
  | BpayProductGroupRunningContractsResponse
  | ErrorResponse;

export type BpayRunningContractsTable = {
  __typename?: 'BpayRunningContractsTable';
  amount: PaymentPrice;
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  productName: Scalars['String']['output'];
  renewalDate?: Maybe<Scalars['Date']['output']>;
  startDate: Scalars['Date']['output'];
};

/** Input for updating payment method */
export type BpaySepaPaymentMethodInput = {
  accountHolder: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  tandc: Scalars['Boolean']['input'];
};

/** Types for Xing Sourcing Service Briefing Candidates */
export const enum BriefingCandidateClientStatus {
  Contacted = 'CONTACTED',
  Interested = 'INTERESTED',
  NotContacted = 'NOT_CONTACTED',
  Rejected = 'REJECTED',
  Uninterested = 'UNINTERESTED',
}

export type CandidateSearchAggregations = {
  __typename?: 'CandidateSearchAggregations';
  careerLevel?: Maybe<Array<Maybe<CandidateSearchCareerLevelAggregation>>>;
  city?: Maybe<Array<Maybe<CandidateSearchCityAggregation>>>;
  currentCompany?: Maybe<
    Array<Maybe<CandidateSearchCurrentCompanyAggregation>>
  >;
  currentCompanySize?: Maybe<
    Array<Maybe<CandidateSearchCurrentCompanySizeAggregation>>
  >;
  currentJobRoles?: Maybe<Array<Maybe<CandidateSearchJobRoleAggregation>>>;
  currentTitle?: Maybe<Array<Maybe<CandidateSearchTitleAggregation>>>;
  currentWorkExperienceStartAt?: Maybe<
    Array<Maybe<CandidateSearchCurrentWorkExperienceStartAtAggregation>>
  >;
  employmentType?: Maybe<
    Array<Maybe<CandidateSearchEmploymentTypeAggregation>>
  >;
  jobRoles?: Maybe<Array<Maybe<CandidateSearchJobRoleAggregation>>>;
  jobseekerCareerLevel?: Maybe<
    Array<Maybe<CandidateSearchJobseekerCareerLevelAggregation>>
  >;
  jobseekerCities?: Maybe<
    Array<Maybe<CandidateSearchJobseekerCityAggregation>>
  >;
  jobseekerDiscipline?: Maybe<
    Array<Maybe<CandidateSearchJobseekerDisciplineAggregation>>
  >;
  jobseekerEmployers?: Maybe<
    Array<Maybe<CandidateSearchJobseekerEmployersAggregation>>
  >;
  jobseekerIndustries?: Maybe<
    Array<Maybe<CandidateSearchJobseekerIndustriesAggregation>>
  >;
  jobseekerJobTitle?: Maybe<
    Array<Maybe<CandidateSearchJobseekerJobTitleAggregation>>
  >;
  jobseekerSalary?: Maybe<
    Array<Maybe<CandidateSearchJobseekerSalaryAggregation>>
  >;
  jobseekerStatus?: Maybe<
    Array<Maybe<CandidateSearchJobseekerStatusAggregation>>
  >;
  jobseekerWillingnessToTravel?: Maybe<
    Array<Maybe<CandidateSearchJobseekerWillingnessToTravelAggregation>>
  >;
  jobseekerWorkingHours?: Maybe<
    Array<Maybe<CandidateSearchJobseekerWorkingHoursAggregation>>
  >;
  jobseekerWorkplace?: Maybe<
    Array<Maybe<CandidateSearchJobseekerWorkplaceAggregation>>
  >;
  languages?: Maybe<Array<Maybe<CandidateSearchLanguagesAggregation>>>;
  languagesWithLevel?: Maybe<
    Array<Maybe<CandidateSearchLanguagesWithLevelAggregation>>
  >;
  previousCompany?: Maybe<
    Array<Maybe<CandidateSearchPreviousCompanyAggregation>>
  >;
  previousJobRoles?: Maybe<Array<Maybe<CandidateSearchJobRoleAggregation>>>;
  previousTitle?: Maybe<Array<Maybe<CandidateSearchTitleAggregation>>>;
  skills?: Maybe<Array<Maybe<CandidateSearchSkillsAggregation>>>;
  workExperience?: Maybe<
    Array<Maybe<CandidateSearchWorkExperienceAggregation>>
  >;
  wtcj?: Maybe<Array<Maybe<CandidateSearchWtcjAggregation>>>;
};

/** Available aggregations (facets) for candidate search. It's expecting the number of aggregations to return. For performance reasons it shouldn't be greater than 20. */
export type CandidateSearchAggregationsInput = {
  careerLevel?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['Int']['input']>;
  currentCompany?: InputMaybe<Scalars['Int']['input']>;
  currentCompanySize?: InputMaybe<Scalars['Int']['input']>;
  currentJobRoles?: InputMaybe<Scalars['Int']['input']>;
  currentTitle?: InputMaybe<Scalars['Int']['input']>;
  currentWorkExperienceStartAt?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<Scalars['Int']['input']>;
  jobRoles?: InputMaybe<Scalars['Int']['input']>;
  jobseekerCareerLevel?: InputMaybe<Scalars['Int']['input']>;
  jobseekerCities?: InputMaybe<Scalars['Int']['input']>;
  jobseekerDiscipline?: InputMaybe<Scalars['Int']['input']>;
  jobseekerEmployers?: InputMaybe<Scalars['Int']['input']>;
  jobseekerIndustries?: InputMaybe<Scalars['Int']['input']>;
  jobseekerJobTitle?: InputMaybe<Scalars['Int']['input']>;
  jobseekerSalary?: InputMaybe<Scalars['Int']['input']>;
  jobseekerStatus?: InputMaybe<Scalars['Int']['input']>;
  jobseekerWillingnessToTravel?: InputMaybe<Scalars['Int']['input']>;
  jobseekerWorkingHours?: InputMaybe<Scalars['Int']['input']>;
  jobseekerWorkplace?: InputMaybe<Scalars['Int']['input']>;
  languages?: InputMaybe<Scalars['Int']['input']>;
  languagesWithLevel?: InputMaybe<Scalars['Int']['input']>;
  previousCompany?: InputMaybe<Scalars['Int']['input']>;
  previousJobRoles?: InputMaybe<Scalars['Int']['input']>;
  previousTitle?: InputMaybe<Scalars['Int']['input']>;
  skills?: InputMaybe<Scalars['Int']['input']>;
  workExperience?: InputMaybe<Scalars['Int']['input']>;
  wtcj?: InputMaybe<Scalars['Int']['input']>;
};

export type CandidateSearchCareerLevelAggregation = {
  __typename?: 'CandidateSearchCareerLevelAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  /** #rest(batched) #virtual */
  level?: Maybe<CareerLevel>;
  value?: Maybe<Scalars['ID']['output']>;
};

export type CandidateSearchCityAggregation = {
  __typename?: 'CandidateSearchCityAggregation';
  /** #rest(batched) #virtual */
  city?: Maybe<City>;
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['ID']['output']>;
};

export type CandidateSearchCollection = {
  __typename?: 'CandidateSearchCollection';
  aggs?: Maybe<CandidateSearchAggregations>;
  collection: Array<CandidateSearchResultItem>;
  requestTrackingToken?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  took: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CandidateSearchCurrentCompanyAggregation = {
  __typename?: 'CandidateSearchCurrentCompanyAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearchCurrentCompanySizeAggregation = {
  __typename?: 'CandidateSearchCurrentCompanySizeAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type CandidateSearchCurrentWorkExperienceStartAtAggregation = {
  __typename?: 'CandidateSearchCurrentWorkExperienceStartAtAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearchEmploymentTypeAggregation = {
  __typename?: 'CandidateSearchEmploymentTypeAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Available filters for candidate search. The possible values for each filter are the one listed as values in the corresponding aggregations. */
export type CandidateSearchFiltersInput = {
  /** Career level ids to filter by */
  careerLevel?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter by comma-separated list of city ids */
  city?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter by current company */
  currentCompany?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter by current company size from 1 to 9 */
  currentCompanySize?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Current job role ids to filter by */
  currentJobRolesIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Job title tags to filter by */
  currentTitle?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter by work experience start date */
  currentWorkExperienceStartAt?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Filters results found within the given distance(in kms) from the given point(Example: 0.47,0.52,10). Distance can't be 0. Note that city filter should be unset if distance filter is used. */
  distance?: InputMaybe<Scalars['String']['input']>;
  /** Filter by employment type */
  employmentType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Excluded candidate ids */
  excludedCandidates?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** exclude from search candidates in the projects with the given ids */
  excludedProjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Job role ids to filter by (combined current and previous) */
  jobRolesIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** jobseeker career level values to filter by */
  jobseekerCareerLevel?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** jobseeker cities values to filter by */
  jobseekerCities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** jobseeker discipline values to filter by */
  jobseekerDiscipline?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter by jobseeker employers */
  jobseekerEmployers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter by jobseeker industries */
  jobseekerIndustries?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter by jobseeker job title */
  jobseekerJobTitle?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** jobseeker salary values to filter by */
  jobseekerSalary?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** jobseeker status values to filter by */
  jobseekerStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter by jobseeker willingness to travel */
  jobseekerWillingnessToTravel?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Filter by jobseeker working hours */
  jobseekerWorkingHours?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Filter by jobseeker workplace */
  jobseekerWorkplace?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** languages values to filter by */
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** languages with level values to filter by */
  languagesWithLevel?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Filter by previous company */
  previousCompany?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Previous job role ids to filter by */
  previousJobRolesIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Job title tags to filter by */
  previousTitle?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Skill ids to filter by */
  skills?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Talent pools filtered by the company id of the user, can be more than one comma separated  */
  talentPools?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Work experience values to filter by */
  workExperience?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** WTCJ values to filter by */
  wtcj?: InputMaybe<Array<InputMaybe<TracyWillingnessToChangeJobsClass>>>;
};

export type CandidateSearchHighlight = {
  __typename?: 'CandidateSearchHighlight';
  currentCompany?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  currentTitle?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  education?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  previousCompany?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  previousTitle?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CandidateSearchJobRoleAggregation = {
  __typename?: 'CandidateSearchJobRoleAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  /** #virtual */
  role?: Maybe<JobRoleLabel>;
  value?: Maybe<Scalars['ID']['output']>;
};

export type CandidateSearchJobseekerCareerLevelAggregation = {
  __typename?: 'CandidateSearchJobseekerCareerLevelAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  /** #rest(batched) #virtual */
  level?: Maybe<CareerLevel>;
  value?: Maybe<Scalars['ID']['output']>;
};

export type CandidateSearchJobseekerCityAggregation = {
  __typename?: 'CandidateSearchJobseekerCityAggregation';
  /** #rest(batched) #virtual */
  city?: Maybe<City>;
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['ID']['output']>;
};

export type CandidateSearchJobseekerDisciplineAggregation = {
  __typename?: 'CandidateSearchJobseekerDisciplineAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  /** #rest(batched) #virtual */
  discipline?: Maybe<Discipline>;
  value?: Maybe<Scalars['ID']['output']>;
};

export type CandidateSearchJobseekerEmployersAggregation = {
  __typename?: 'CandidateSearchJobseekerEmployersAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  /** #rest(batched) #virtual */
  label?: Maybe<Company>;
  value?: Maybe<Scalars['ID']['output']>;
};

export type CandidateSearchJobseekerIndustriesAggregation = {
  __typename?: 'CandidateSearchJobseekerIndustriesAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  /** #rest(batched) #virtual */
  label?: Maybe<Industry>;
  value?: Maybe<Scalars['ID']['output']>;
};

export type CandidateSearchJobseekerJobTitleAggregation = {
  __typename?: 'CandidateSearchJobseekerJobTitleAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  /** #virtual */
  role?: Maybe<JobRoleLabel>;
  value?: Maybe<Scalars['ID']['output']>;
};

export type CandidateSearchJobseekerSalaryAggregation = {
  __typename?: 'CandidateSearchJobseekerSalaryAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearchJobseekerStatusAggregation = {
  __typename?: 'CandidateSearchJobseekerStatusAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearchJobseekerWillingnessToTravelAggregation = {
  __typename?: 'CandidateSearchJobseekerWillingnessToTravelAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearchJobseekerWorkingHoursAggregation = {
  __typename?: 'CandidateSearchJobseekerWorkingHoursAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearchJobseekerWorkplaceAggregation = {
  __typename?: 'CandidateSearchJobseekerWorkplaceAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearchLanguagesAggregation = {
  __typename?: 'CandidateSearchLanguagesAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearchLanguagesWithLevelAggregation = {
  __typename?: 'CandidateSearchLanguagesWithLevelAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearchPreviousCompanyAggregation = {
  __typename?: 'CandidateSearchPreviousCompanyAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Available queries for candidate search. The difference between queries and filters is that queries is a free text search with support of boolean operators. */
export type CandidateSearchQueriesInput = {
  keywords?: InputMaybe<Scalars['String']['input']>;
};

export type CandidateSearchResultItem = {
  __typename?: 'CandidateSearchResultItem';
  /**
   * #rest(batched) #virtual
   *
   *  If is possible to send messages to the candidate
   */
  canSendMessageTo?: Maybe<Scalars['Boolean']['output']>;
  /** Highlights of found keywords */
  highlight?: Maybe<CandidateSearchHighlight>;
  /** Unique identifier of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** Identifier of the entry (same as id) */
  itemId?: Maybe<Scalars['ID']['output']>;
  /** 0-based position in suggestion list */
  position?: Maybe<Scalars['Int']['output']>;
  /**
   * #rest(batched) #virtual
   *
   *  The profile information for the candidate
   */
  profile?: Maybe<XingId>;
  /** #rest(batched) #virtual */
  profileAdditionalDetails?: Maybe<ProfileAdditionalDetails>;
  /** score of item in suggestion list */
  score?: Maybe<Scalars['Float']['output']>;
  /** Tracking token */
  trackingToken?: Maybe<Scalars['String']['output']>;
  /**
   * #rest(batched) #virtual
   *
   *  Willingness To Change Job of the candidate
   */
  wtcj?: Maybe<WillingnessToChangeJob>;
  /** Xtm Glances */
  xtmGlances?: Maybe<XtmGlances>;
};

export type CandidateSearchSkillsAggregation = {
  __typename?: 'CandidateSearchSkillsAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  /** #virtual */
  skill?: Maybe<OntologyEntityLabel>;
  value?: Maybe<Scalars['ID']['output']>;
};

export type CandidateSearchTitleAggregation = {
  __typename?: 'CandidateSearchTitleAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  /** #rest(batched) #virtual */
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearchWtcjAggregation = {
  __typename?: 'CandidateSearchWTCJAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CandidateSearchWorkExperienceAggregation = {
  __typename?: 'CandidateSearchWorkExperienceAggregation';
  count?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Candidates = {
  __typename?: 'Candidates';
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  trackingToken?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type CareerHubArticle = {
  __typename?: 'CareerHubArticle';
  /** #logged-out #soft-logged-in */
  actorUrn: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  description: Scalars['String']['output'];
  /**
   * #logged-out #soft-logged-in
   * @deprecated [ACQ-980]Should represent CareerHubArticle.id
   */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  image: CareerHubArticleImage;
  /** #logged-out #soft-logged-in */
  pageLogos: CareerHubArticlePageLogo;
  /**
   * #logged-out #soft-logged-in
   *
   *  Title of the page/author of the article
   */
  pageTitle: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  pageUrl: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  resourceType: CareerHubArticleType;
  /**
   * #logged-out #soft-logged-in
   *
   *  Title of the article
   */
  title: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  url: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  urn: Scalars['GlobalID']['output'];
};

export type CareerHubArticleImage = {
  __typename?: 'CareerHubArticleImage';
  /** #logged-out #soft-logged-in */
  srcSquare?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcSquare2x?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcWide?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcWide2x?: Maybe<Scalars['URL']['output']>;
};

export type CareerHubArticlePageLogo = {
  __typename?: 'CareerHubArticlePageLogo';
  /** #logged-out #soft-logged-in */
  original?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square32?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square48?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square64?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square96?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square128?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square144?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square256?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square384?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square1024?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  web?: Maybe<Scalars['URL']['output']>;
};

export const enum CareerHubArticleType {
  ArticlesArticle = 'ARTICLES_ARTICLE',
  ContentFulltextArticle = 'CONTENT_FULLTEXT_ARTICLE',
  ContentVideoArticle = 'CONTENT_VIDEO_ARTICLE',
}

export type CareerHubGlossaryArticle = {
  __typename?: 'CareerHubGlossaryArticle';
  /** #logged-out #soft-logged-in */
  title: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  url: Scalars['URL']['output'];
};

export type CareerHubGlossaryCollection = {
  __typename?: 'CareerHubGlossaryCollection';
  /**
   * #logged-out #soft-logged-in #expand
   *
   *  Articles inside the index letter
   */
  articles: Array<Maybe<CareerHubGlossaryArticle>>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Index letter
   */
  letter?: Maybe<Scalars['String']['output']>;
};

export type CareerHubGlossaryResponse = {
  __typename?: 'CareerHubGlossaryResponse';
  /** #logged-out #soft-logged-in */
  collection: Array<Maybe<CareerHubGlossaryCollection>>;
};

export type CareerHubTopic = {
  __typename?: 'CareerHubTopic';
  /**
   * #logged-out #soft-logged-in
   *
   *  Articles inside the topic
   */
  articles: Array<Maybe<CareerHubArticle>>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Topic description
   */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  URN of the evergreen article from Articles backend
   */
  evergreenArticleUrn: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  key: Scalars['String']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Topic name
   */
  title: Scalars['String']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Topic tracking key
   */
  trackingKey: Scalars['String']['output'];
};

export type CareerHubTopicPageResponse = {
  __typename?: 'CareerHubTopicPageResponse';
  /** #logged-out #soft-logged-in */
  articles: Array<Maybe<CareerHubArticle>>;
  /** #logged-out #soft-logged-in */
  evergreenArticleUrn: Scalars['GlobalID']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Evergreen title name
   */
  evergreenTitle: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  introduction: Array<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Topic title name
   */
  title: Scalars['String']['output'];
};

export type CareerHubTopicsResponse = {
  __typename?: 'CareerHubTopicsResponse';
  /** #logged-out #soft-logged-in */
  collection: Array<Maybe<CareerHubTopic>>;
};

export type CareerLevel = Localizable & {
  __typename?: 'CareerLevel';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export type CareerLevelElement = {
  __typename?: 'CareerLevelElement';
  /** #rest(batched) #virtual */
  careerLevel?: Maybe<CareerLevel>;
  careerLevelId: Scalars['ID']['output'];
  total: Scalars['Int']['output'];
};

/** CareerLevelPreference Type */
export type CareerLevelPreference = {
  __typename?: 'CareerLevelPreference';
  /** #logged-out #soft-logged-in */
  maximum?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  minimum?: Maybe<Scalars['Int']['output']>;
};

export type CareerLevelPreferenceDataState = {
  __typename?: 'CareerLevelPreferenceDataState';
  maxCareerLevel: JobPreferencesDataStateStatus;
  minCareerLevel: JobPreferencesDataStateStatus;
  state: JobPreferencesDataStateStatus;
};

/** Button that triggers the conversation via Messenger */
export type CareerSettingsConversationStarter = {
  __typename?: 'CareerSettingsConversationStarter';
  messengerContextId?: Maybe<MessengerContextId>;
  title: Scalars['String']['output'];
};

/** Profile Module CareerSettings */
export type CareerSettingsModule = ModuleInterface & {
  __typename?: 'CareerSettingsModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** #rest #virtual */
  conversationStarters?: Maybe<Array<CareerSettingsConversationStarter>>;
  globalId: Scalars['GlobalID']['output'];
  /** #rest #virtual */
  jobSeeker?: Maybe<JobSeeker>;
  order: Scalars['Int']['output'];
  /** #rest #virtual */
  projobsSettingsOther?: Maybe<ProjobsSettingsOther>;
  /** #rest #virtual */
  projobsWishesOther?: Maybe<ProjobsWishes>;
  title: Scalars['String']['output'];
};

export type CategoryAggregation = HasAggregationCount & {
  __typename?: 'CategoryAggregation';
  count: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type ChatsSearch = {
  __typename?: 'ChatsSearch';
  items?: Maybe<Array<Maybe<Item>>>;
  moreItems?: Maybe<Scalars['Boolean']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CircuitBreaker = {
  __typename?: 'CircuitBreaker';
  /** #soft-logged-in */
  status: Scalars['String']['output'];
};

export type City = {
  __typename?: 'City';
  /** #logged-out #soft-logged-in */
  adminArea?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  countryCode?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  formattedCountry?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** #logged-out #soft-logged-in */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
};

export type ClickReason = {
  __typename?: 'ClickReason';
  clickReasonSlug: Scalars['String']['output'];
  code: Scalars['String']['output'];
  profileUrl: Scalars['String']['output'];
};

export type ClickReasonInput = {
  /** ID of any entity to be used by the Click Reason. */
  entityId?: InputMaybe<Scalars['ID']['input']>;
  id: ClickReasonsEnum;
};

export const enum ClickReasonsEnum {
  CrAndroidAroundYouList = 'CR_ANDROID_AROUND_YOU_LIST',
  CrAndroidBirthdayList = 'CR_ANDROID_BIRTHDAY_LIST',
  CrAndroidChatview = 'CR_ANDROID_CHATVIEW',
  CrAndroidClickedInOnlyfy = 'CR_ANDROID_CLICKED_IN_ONLYFY',
  CrAndroidClickedInRecruiterRecommendations = 'CR_ANDROID_CLICKED_IN_RECRUITER_RECOMMENDATIONS',
  CrAndroidCompaniesCompanyContactList = 'CR_ANDROID_COMPANIES_COMPANY_CONTACT_LIST',
  CrAndroidCompaniesContacts = 'CR_ANDROID_COMPANIES_CONTACTS',
  CrAndroidCompaniesEmployeeList = 'CR_ANDROID_COMPANIES_EMPLOYEE_LIST',
  CrAndroidCompaniesFollowersList = 'CR_ANDROID_COMPANIES_FOLLOWERS_LIST',
  CrAndroidContactRequestReceived = 'CR_ANDROID_CONTACT_REQUEST_RECEIVED',
  CrAndroidContactRequestSent = 'CR_ANDROID_CONTACT_REQUEST_SENT',
  CrAndroidConversationStarter = 'CR_ANDROID_CONVERSATION_STARTER',
  CrAndroidDiscoMemberSearch = 'CR_ANDROID_DISCO_MEMBER_SEARCH',
  CrAndroidDiscoSearch = 'CR_ANDROID_DISCO_SEARCH',
  CrAndroidEntitypageDiscoCompanyPage = 'CR_ANDROID_ENTITYPAGE_DISCO_COMPANY_PAGE',
  CrAndroidEventsComment = 'CR_ANDROID_EVENTS_COMMENT',
  CrAndroidEventsContactPerson = 'CR_ANDROID_EVENTS_CONTACT_PERSON',
  CrAndroidEventsGuestList = 'CR_ANDROID_EVENTS_GUEST_LIST',
  CrAndroidEventsLikes = 'CR_ANDROID_EVENTS_LIKES',
  CrAndroidEventsOrganizer = 'CR_ANDROID_EVENTS_ORGANIZER',
  CrAndroidEventsSpeaker = 'CR_ANDROID_EVENTS_SPEAKER',
  CrAndroidExploreDiscoAnniversary = 'CR_ANDROID_EXPLORE_DISCO_ANNIVERSARY',
  CrAndroidExploreDiscoArticlePostShared = 'CR_ANDROID_EXPLORE_DISCO_ARTICLE_POST_SHARED',
  CrAndroidExploreDiscoBusinessCardUpdate = 'CR_ANDROID_EXPLORE_DISCO_BUSINESS_CARD_UPDATE',
  CrAndroidExploreDiscoContactReco = 'CR_ANDROID_EXPLORE_DISCO_CONTACT_RECO',
  CrAndroidExploreDiscoEventAttending = 'CR_ANDROID_EXPLORE_DISCO_EVENT_ATTENDING',
  CrAndroidExploreDiscoEventCreation = 'CR_ANDROID_EXPLORE_DISCO_EVENT_CREATION',
  CrAndroidExploreDiscoEventPostShared = 'CR_ANDROID_EXPLORE_DISCO_EVENT_POST_SHARED',
  CrAndroidExploreDiscoFollowing = 'CR_ANDROID_EXPLORE_DISCO_FOLLOWING',
  CrAndroidExploreDiscoGroupCreation = 'CR_ANDROID_EXPLORE_DISCO_GROUP_CREATION',
  CrAndroidExploreDiscoGroupJoined = 'CR_ANDROID_EXPLORE_DISCO_GROUP_JOINED',
  CrAndroidExploreDiscoHeaderImageUpdate = 'CR_ANDROID_EXPLORE_DISCO_HEADER_IMAGE_UPDATE',
  CrAndroidExploreDiscoInterestingJobs = 'CR_ANDROID_EXPLORE_DISCO_INTERESTING_JOBS',
  CrAndroidExploreDiscoJobPostShared = 'CR_ANDROID_EXPLORE_DISCO_JOB_POST_SHARED',
  CrAndroidExploreDiscoNewContactUpdate = 'CR_ANDROID_EXPLORE_DISCO_NEW_CONTACT_UPDATE',
  CrAndroidExploreDiscoOtherArticlePostComment = 'CR_ANDROID_EXPLORE_DISCO_OTHER_ARTICLE_POST_COMMENT',
  CrAndroidExploreDiscoOtherArticlePostLike = 'CR_ANDROID_EXPLORE_DISCO_OTHER_ARTICLE_POST_LIKE',
  CrAndroidExploreDiscoOtherEventPostComment = 'CR_ANDROID_EXPLORE_DISCO_OTHER_EVENT_POST_COMMENT',
  CrAndroidExploreDiscoOtherEventPostLike = 'CR_ANDROID_EXPLORE_DISCO_OTHER_EVENT_POST_LIKE',
  CrAndroidExploreDiscoOtherJobPostComment = 'CR_ANDROID_EXPLORE_DISCO_OTHER_JOB_POST_COMMENT',
  CrAndroidExploreDiscoOtherJobPostLike = 'CR_ANDROID_EXPLORE_DISCO_OTHER_JOB_POST_LIKE',
  CrAndroidExploreDiscoOtherPostComment = 'CR_ANDROID_EXPLORE_DISCO_OTHER_POST_COMMENT',
  CrAndroidExploreDiscoOtherPostLike = 'CR_ANDROID_EXPLORE_DISCO_OTHER_POST_LIKE',
  CrAndroidExploreDiscoOwnArticlePost = 'CR_ANDROID_EXPLORE_DISCO_OWN_ARTICLE_POST',
  CrAndroidExploreDiscoOwnArticlePostComment = 'CR_ANDROID_EXPLORE_DISCO_OWN_ARTICLE_POST_COMMENT',
  CrAndroidExploreDiscoOwnEventPost = 'CR_ANDROID_EXPLORE_DISCO_OWN_EVENT_POST',
  CrAndroidExploreDiscoOwnEventPostComment = 'CR_ANDROID_EXPLORE_DISCO_OWN_EVENT_POST_COMMENT',
  CrAndroidExploreDiscoOwnJobPost = 'CR_ANDROID_EXPLORE_DISCO_OWN_JOB_POST',
  CrAndroidExploreDiscoOwnJobPostComment = 'CR_ANDROID_EXPLORE_DISCO_OWN_JOB_POST_COMMENT',
  CrAndroidExploreDiscoOwnPost = 'CR_ANDROID_EXPLORE_DISCO_OWN_POST',
  CrAndroidExploreDiscoOwnPostComment = 'CR_ANDROID_EXPLORE_DISCO_OWN_POST_COMMENT',
  CrAndroidExploreDiscoPersonalUpdate = 'CR_ANDROID_EXPLORE_DISCO_PERSONAL_UPDATE',
  CrAndroidExploreDiscoPostShared = 'CR_ANDROID_EXPLORE_DISCO_POST_SHARED',
  CrAndroidExploreDiscoProfilePictUpdate = 'CR_ANDROID_EXPLORE_DISCO_PROFILE_PICT_UPDATE',
  CrAndroidExploreDiscoProfileUpdate = 'CR_ANDROID_EXPLORE_DISCO_PROFILE_UPDATE',
  CrAndroidExploreDiscoSharedContacts = 'CR_ANDROID_EXPLORE_DISCO_SHARED_CONTACTS',
  CrAndroidExploreDiscoWorkExpUpdate = 'CR_ANDROID_EXPLORE_DISCO_WORK_EXP_UPDATE',
  CrAndroidGroupsDiscussions = 'CR_ANDROID_GROUPS_DISCUSSIONS',
  CrAndroidGroupsMembersList = 'CR_ANDROID_GROUPS_MEMBERS_LIST',
  CrAndroidGroupsModerators = 'CR_ANDROID_GROUPS_MODERATORS',
  CrAndroidGroupsSocialProofHeaderMembers = 'CR_ANDROID_GROUPS_SOCIAL_PROOF_HEADER_MEMBERS',
  CrAndroidJobsCompanyContacts = 'CR_ANDROID_JOBS_COMPANY_CONTACTS',
  CrAndroidJobsCompanyContactsCommonContact = 'CR_ANDROID_JOBS_COMPANY_CONTACTS_COMMON_CONTACT',
  CrAndroidJobsCompanyContactsContact = 'CR_ANDROID_JOBS_COMPANY_CONTACTS_CONTACT',
  CrAndroidJobsFutureColleaguesList = 'CR_ANDROID_JOBS_FUTURE_COLLEAGUES_LIST',
  CrAndroidJobsFutureColleaguesModule = 'CR_ANDROID_JOBS_FUTURE_COLLEAGUES_MODULE',
  CrAndroidKlartextApprovedList = 'CR_ANDROID_KLARTEXT_APPROVED_LIST',
  CrAndroidKlartextAuthor = 'CR_ANDROID_KLARTEXT_AUTHOR',
  CrAndroidKlartextComment = 'CR_ANDROID_KLARTEXT_COMMENT',
  CrAndroidKlartextCommentLike = 'CR_ANDROID_KLARTEXT_COMMENT_LIKE',
  CrAndroidMehubContactsList = 'CR_ANDROID_MEHUB_CONTACTS_LIST',
  CrAndroidMehubVomp = 'CR_ANDROID_MEHUB_VOMP',
  CrAndroidMessenger = 'CR_ANDROID_MESSENGER',
  CrAndroidMymk = 'CR_ANDROID_MYMK',
  CrAndroidMymkContacts = 'CR_ANDROID_MYMK_CONTACTS',
  CrAndroidNetupdtDiscoAnniversary = 'CR_ANDROID_NETUPDT_DISCO_ANNIVERSARY',
  CrAndroidNetupdtDiscoBusinessCardUpdate = 'CR_ANDROID_NETUPDT_DISCO_BUSINESS_CARD_UPDATE',
  CrAndroidNetupdtDiscoContactReco = 'CR_ANDROID_NETUPDT_DISCO_CONTACT_RECO',
  CrAndroidNetupdtDiscoEventArticleShared = 'CR_ANDROID_NETUPDT_DISCO_EVENT_ARTICLE_SHARED',
  CrAndroidNetupdtDiscoEventAttending = 'CR_ANDROID_NETUPDT_DISCO_EVENT_ATTENDING',
  CrAndroidNetupdtDiscoEventCreation = 'CR_ANDROID_NETUPDT_DISCO_EVENT_CREATION',
  CrAndroidNetupdtDiscoEventPostShared = 'CR_ANDROID_NETUPDT_DISCO_EVENT_POST_SHARED',
  CrAndroidNetupdtDiscoFollowing = 'CR_ANDROID_NETUPDT_DISCO_FOLLOWING',
  CrAndroidNetupdtDiscoGroupCreation = 'CR_ANDROID_NETUPDT_DISCO_GROUP_CREATION',
  CrAndroidNetupdtDiscoGroupJoined = 'CR_ANDROID_NETUPDT_DISCO_GROUP_JOINED',
  CrAndroidNetupdtDiscoHeaderImgUpdate = 'CR_ANDROID_NETUPDT_DISCO_HEADER_IMG_UPDATE',
  CrAndroidNetupdtDiscoInterestingJobs = 'CR_ANDROID_NETUPDT_DISCO_INTERESTING_JOBS',
  CrAndroidNetupdtDiscoJobPostShared = 'CR_ANDROID_NETUPDT_DISCO_JOB_POST_SHARED',
  CrAndroidNetupdtDiscoNewContactUpdate = 'CR_ANDROID_NETUPDT_DISCO_NEW_CONTACT_UPDATE',
  CrAndroidNetupdtDiscoOtherArticlePostComment = 'CR_ANDROID_NETUPDT_DISCO_OTHER_ARTICLE_POST_COMMENT',
  CrAndroidNetupdtDiscoOtherArticlePostLike = 'CR_ANDROID_NETUPDT_DISCO_OTHER_ARTICLE_POST_LIKE',
  CrAndroidNetupdtDiscoOtherEventPostComment = 'CR_ANDROID_NETUPDT_DISCO_OTHER_EVENT_POST_COMMENT',
  CrAndroidNetupdtDiscoOtherEventPostLike = 'CR_ANDROID_NETUPDT_DISCO_OTHER_EVENT_POST_LIKE',
  CrAndroidNetupdtDiscoOtherJobPostComment = 'CR_ANDROID_NETUPDT_DISCO_OTHER_JOB_POST_COMMENT',
  CrAndroidNetupdtDiscoOtherJobPostLike = 'CR_ANDROID_NETUPDT_DISCO_OTHER_JOB_POST_LIKE',
  CrAndroidNetupdtDiscoOtherPostComment = 'CR_ANDROID_NETUPDT_DISCO_OTHER_POST_COMMENT',
  CrAndroidNetupdtDiscoOtherPostLike = 'CR_ANDROID_NETUPDT_DISCO_OTHER_POST_LIKE',
  CrAndroidNetupdtDiscoOwnArticlePost = 'CR_ANDROID_NETUPDT_DISCO_OWN_ARTICLE_POST',
  CrAndroidNetupdtDiscoOwnArticlePostComment = 'CR_ANDROID_NETUPDT_DISCO_OWN_ARTICLE_POST_COMMENT',
  CrAndroidNetupdtDiscoOwnEventPost = 'CR_ANDROID_NETUPDT_DISCO_OWN_EVENT_POST',
  CrAndroidNetupdtDiscoOwnEventPostComment = 'CR_ANDROID_NETUPDT_DISCO_OWN_EVENT_POST_COMMENT',
  CrAndroidNetupdtDiscoOwnJobPost = 'CR_ANDROID_NETUPDT_DISCO_OWN_JOB_POST',
  CrAndroidNetupdtDiscoOwnJobPostComment = 'CR_ANDROID_NETUPDT_DISCO_OWN_JOB_POST_COMMENT',
  CrAndroidNetupdtDiscoOwnPost = 'CR_ANDROID_NETUPDT_DISCO_OWN_POST',
  CrAndroidNetupdtDiscoOwnPostComment = 'CR_ANDROID_NETUPDT_DISCO_OWN_POST_COMMENT',
  CrAndroidNetupdtDiscoPersonalUpdate = 'CR_ANDROID_NETUPDT_DISCO_PERSONAL_UPDATE',
  CrAndroidNetupdtDiscoPostShared = 'CR_ANDROID_NETUPDT_DISCO_POST_SHARED',
  CrAndroidNetupdtDiscoProfilePictUpdate = 'CR_ANDROID_NETUPDT_DISCO_PROFILE_PICT_UPDATE',
  CrAndroidNetupdtDiscoProfileUpdate = 'CR_ANDROID_NETUPDT_DISCO_PROFILE_UPDATE',
  CrAndroidNetupdtDiscoSharedContacts = 'CR_ANDROID_NETUPDT_DISCO_SHARED_CONTACTS',
  CrAndroidNetupdtDiscoWorkExpUpdate = 'CR_ANDROID_NETUPDT_DISCO_WORK_EXP_UPDATE',
  CrAndroidNetworkHomeMessage = 'CR_ANDROID_NETWORK_HOME_MESSAGE',
  CrAndroidNewsAuthor = 'CR_ANDROID_NEWS_AUTHOR',
  CrAndroidNewsAuthorFollowers = 'CR_ANDROID_NEWS_AUTHOR_FOLLOWERS',
  CrAndroidNewsComment = 'CR_ANDROID_NEWS_COMMENT',
  CrAndroidNewsFollowers = 'CR_ANDROID_NEWS_FOLLOWERS',
  CrAndroidNewsLike = 'CR_ANDROID_NEWS_LIKE',
  CrAndroidOnboardingContactsReco = 'CR_ANDROID_ONBOARDING_CONTACTS_RECO',
  CrAndroidOwnContactList = 'CR_ANDROID_OWN_CONTACT_LIST',
  CrAndroidProbusinessAdvancedSearch = 'CR_ANDROID_PROBUSINESS_ADVANCED_SEARCH',
  CrAndroidProbusinessContacts = 'CR_ANDROID_PROBUSINESS_CONTACTS',
  CrAndroidProbusinessSearch = 'CR_ANDROID_PROBUSINESS_SEARCH',
  CrAndroidProfileContactsList = 'CR_ANDROID_PROFILE_CONTACTS_LIST',
  CrAndroidProfileSharedContacts = 'CR_ANDROID_PROFILE_SHARED_CONTACTS',
  CrAndroidProjobs = 'CR_ANDROID_PROJOBS',
  CrAndroidProjobsRecruiterReco = 'CR_ANDROID_PROJOBS_RECRUITER_RECO',
  CrAndroidProjobsVomp = 'CR_ANDROID_PROJOBS_VOMP',
  CrAndroidPublisherComments = 'CR_ANDROID_PUBLISHER_COMMENTS',
  CrAndroidPublisherContactsModule = 'CR_ANDROID_PUBLISHER_CONTACTS_MODULE',
  CrAndroidPublisherEmployeesModule = 'CR_ANDROID_PUBLISHER_EMPLOYEES_MODULE',
  CrAndroidPublisherEventsModule = 'CR_ANDROID_PUBLISHER_EVENTS_MODULE',
  CrAndroidPublisherLikesComment = 'CR_ANDROID_PUBLISHER_LIKES_COMMENT',
  CrAndroidPublisherLikesPost = 'CR_ANDROID_PUBLISHER_LIKES_POST',
  CrAndroidPublisherSocialProofHeader = 'CR_ANDROID_PUBLISHER_SOCIAL_PROOF_HEADER',
  CrAndroidSocialMentionComment = 'CR_ANDROID_SOCIAL_MENTION_COMMENT',
  CrAndroidSocialMentionPost = 'CR_ANDROID_SOCIAL_MENTION_POST',
  CrAndroidStory = 'CR_ANDROID_STORY',
  CrAndroidStoryMentioning = 'CR_ANDROID_STORY_MENTIONING',
  CrAndroidTalentManager = 'CR_ANDROID_TALENT_MANAGER',
  CrAndroidTalentService = 'CR_ANDROID_TALENT_SERVICE',
  CrAndroidTopicDiscoArticlePostShared = 'CR_ANDROID_TOPIC_DISCO_ARTICLE_POST_SHARED',
  CrAndroidTopicDiscoChannelFollowing = 'CR_ANDROID_TOPIC_DISCO_CHANNEL_FOLLOWING',
  CrAndroidTopicDiscoContactReco = 'CR_ANDROID_TOPIC_DISCO_CONTACT_RECO',
  CrAndroidTopicDiscoEventAttending = 'CR_ANDROID_TOPIC_DISCO_EVENT_ATTENDING',
  CrAndroidTopicDiscoEventCreation = 'CR_ANDROID_TOPIC_DISCO_EVENT_CREATION',
  CrAndroidTopicDiscoEventPostShared = 'CR_ANDROID_TOPIC_DISCO_EVENT_POST_SHARED',
  CrAndroidTopicDiscoFollowing = 'CR_ANDROID_TOPIC_DISCO_FOLLOWING',
  CrAndroidTopicDiscoGroupCreation = 'CR_ANDROID_TOPIC_DISCO_GROUP_CREATION',
  CrAndroidTopicDiscoGroupJoined = 'CR_ANDROID_TOPIC_DISCO_GROUP_JOINED',
  CrAndroidTopicDiscoInterestingJobs = 'CR_ANDROID_TOPIC_DISCO_INTERESTING_JOBS',
  CrAndroidTopicDiscoJobPostShared = 'CR_ANDROID_TOPIC_DISCO_JOB_POST_SHARED',
  CrAndroidTopicDiscoNewContactUpdate = 'CR_ANDROID_TOPIC_DISCO_NEW_CONTACT_UPDATE',
  CrAndroidTopicDiscoOtherArticlePostComment = 'CR_ANDROID_TOPIC_DISCO_OTHER_ARTICLE_POST_COMMENT',
  CrAndroidTopicDiscoOtherArticlePostLike = 'CR_ANDROID_TOPIC_DISCO_OTHER_ARTICLE_POST_LIKE',
  CrAndroidTopicDiscoOtherEventPostComment = 'CR_ANDROID_TOPIC_DISCO_OTHER_EVENT_POST_COMMENT',
  CrAndroidTopicDiscoOtherEventPostLike = 'CR_ANDROID_TOPIC_DISCO_OTHER_EVENT_POST_LIKE',
  CrAndroidTopicDiscoOtherJobPostComment = 'CR_ANDROID_TOPIC_DISCO_OTHER_JOB_POST_COMMENT',
  CrAndroidTopicDiscoOtherJobPostLike = 'CR_ANDROID_TOPIC_DISCO_OTHER_JOB_POST_LIKE',
  CrAndroidTopicDiscoOtherPostComment = 'CR_ANDROID_TOPIC_DISCO_OTHER_POST_COMMENT',
  CrAndroidTopicDiscoOtherPostLike = 'CR_ANDROID_TOPIC_DISCO_OTHER_POST_LIKE',
  CrAndroidTopicDiscoOwnArticlePost = 'CR_ANDROID_TOPIC_DISCO_OWN_ARTICLE_POST',
  CrAndroidTopicDiscoOwnArticlePostComment = 'CR_ANDROID_TOPIC_DISCO_OWN_ARTICLE_POST_COMMENT',
  CrAndroidTopicDiscoOwnEventPost = 'CR_ANDROID_TOPIC_DISCO_OWN_EVENT_POST',
  CrAndroidTopicDiscoOwnEventPostComment = 'CR_ANDROID_TOPIC_DISCO_OWN_EVENT_POST_COMMENT',
  CrAndroidTopicDiscoOwnJobPost = 'CR_ANDROID_TOPIC_DISCO_OWN_JOB_POST',
  CrAndroidTopicDiscoOwnJobPostComment = 'CR_ANDROID_TOPIC_DISCO_OWN_JOB_POST_COMMENT',
  CrAndroidTopicDiscoOwnPost = 'CR_ANDROID_TOPIC_DISCO_OWN_POST',
  CrAndroidTopicDiscoOwnPostComment = 'CR_ANDROID_TOPIC_DISCO_OWN_POST_COMMENT',
  CrAndroidTopicDiscoPostShared = 'CR_ANDROID_TOPIC_DISCO_POST_SHARED',
  CrAndroidTopicDiscoSharedContacts = 'CR_ANDROID_TOPIC_DISCO_SHARED_CONTACTS',
  CrAndroidUpboardingContactsReco = 'CR_ANDROID_UPBOARDING_CONTACTS_RECO',
  CrAndroidVompPage = 'CR_ANDROID_VOMP_PAGE',
  CrAndroidVompPageCommonContacts = 'CR_ANDROID_VOMP_PAGE_COMMON_CONTACTS',
  CrEmailBirthdayList = 'CR_EMAIL_BIRTHDAY_LIST',
  CrEmailContactMessage = 'CR_EMAIL_CONTACT_MESSAGE',
  CrEmailContactRequest = 'CR_EMAIL_CONTACT_REQUEST',
  CrEmailContactUpdate = 'CR_EMAIL_CONTACT_UPDATE',
  CrEmailVomp = 'CR_EMAIL_VOMP',
  CrIosAroundYouList = 'CR_IOS_AROUND_YOU_LIST',
  CrIosBirthdayList = 'CR_IOS_BIRTHDAY_LIST',
  CrIosChatview = 'CR_IOS_CHATVIEW',
  CrIosClickedInOnlyfy = 'CR_IOS_CLICKED_IN_ONLYFY',
  CrIosClickedInRecruiterRecommendations = 'CR_IOS_CLICKED_IN_RECRUITER_RECOMMENDATIONS',
  CrIosCompaniesCompanyContactList = 'CR_IOS_COMPANIES_COMPANY_CONTACT_LIST',
  CrIosCompaniesContacts = 'CR_IOS_COMPANIES_CONTACTS',
  CrIosCompaniesEmployeeList = 'CR_IOS_COMPANIES_EMPLOYEE_LIST',
  CrIosCompaniesFollowersList = 'CR_IOS_COMPANIES_FOLLOWERS_LIST',
  CrIosContactRequestReceived = 'CR_IOS_CONTACT_REQUEST_RECEIVED',
  CrIosContactRequestSent = 'CR_IOS_CONTACT_REQUEST_SENT',
  CrIosConversationStarter = 'CR_IOS_CONVERSATION_STARTER',
  CrIosDiscoMemberSearch = 'CR_IOS_DISCO_MEMBER_SEARCH',
  CrIosDiscoSearch = 'CR_IOS_DISCO_SEARCH',
  CrIosEntitypageDiscoCompanyPage = 'CR_IOS_ENTITYPAGE_DISCO_COMPANY_PAGE',
  CrIosEventsComment = 'CR_IOS_EVENTS_COMMENT',
  CrIosEventsContactPerson = 'CR_IOS_EVENTS_CONTACT_PERSON',
  CrIosEventsGuestList = 'CR_IOS_EVENTS_GUEST_LIST',
  CrIosEventsLikes = 'CR_IOS_EVENTS_LIKES',
  CrIosEventsOrganizer = 'CR_IOS_EVENTS_ORGANIZER',
  CrIosEventsSpeaker = 'CR_IOS_EVENTS_SPEAKER',
  CrIosExploreDiscoAnniversary = 'CR_IOS_EXPLORE_DISCO_ANNIVERSARY',
  CrIosExploreDiscoArticlePostShared = 'CR_IOS_EXPLORE_DISCO_ARTICLE_POST_SHARED',
  CrIosExploreDiscoBusinessCardUpdate = 'CR_IOS_EXPLORE_DISCO_BUSINESS_CARD_UPDATE',
  CrIosExploreDiscoContactReco = 'CR_IOS_EXPLORE_DISCO_CONTACT_RECO',
  CrIosExploreDiscoEventAttending = 'CR_IOS_EXPLORE_DISCO_EVENT_ATTENDING',
  CrIosExploreDiscoEventCreation = 'CR_IOS_EXPLORE_DISCO_EVENT_CREATION',
  CrIosExploreDiscoEventPostShared = 'CR_IOS_EXPLORE_DISCO_EVENT_POST_SHARED',
  CrIosExploreDiscoFollowing = 'CR_IOS_EXPLORE_DISCO_FOLLOWING',
  CrIosExploreDiscoGroupCreation = 'CR_IOS_EXPLORE_DISCO_GROUP_CREATION',
  CrIosExploreDiscoGroupJoined = 'CR_IOS_EXPLORE_DISCO_GROUP_JOINED',
  CrIosExploreDiscoHeaderImageUpdate = 'CR_IOS_EXPLORE_DISCO_HEADER_IMAGE_UPDATE',
  CrIosExploreDiscoInterestingJobs = 'CR_IOS_EXPLORE_DISCO_INTERESTING_JOBS',
  CrIosExploreDiscoJobPostShared = 'CR_IOS_EXPLORE_DISCO_JOB_POST_SHARED',
  CrIosExploreDiscoNewContactUpdate = 'CR_IOS_EXPLORE_DISCO_NEW_CONTACT_UPDATE',
  CrIosExploreDiscoOtherArticlePostComment = 'CR_IOS_EXPLORE_DISCO_OTHER_ARTICLE_POST_COMMENT',
  CrIosExploreDiscoOtherArticlePostLike = 'CR_IOS_EXPLORE_DISCO_OTHER_ARTICLE_POST_LIKE',
  CrIosExploreDiscoOtherEventPostComment = 'CR_IOS_EXPLORE_DISCO_OTHER_EVENT_POST_COMMENT',
  CrIosExploreDiscoOtherEventPostLike = 'CR_IOS_EXPLORE_DISCO_OTHER_EVENT_POST_LIKE',
  CrIosExploreDiscoOtherJobPostComment = 'CR_IOS_EXPLORE_DISCO_OTHER_JOB_POST_COMMENT',
  CrIosExploreDiscoOtherJobPostLike = 'CR_IOS_EXPLORE_DISCO_OTHER_JOB_POST_LIKE',
  CrIosExploreDiscoOtherPostComment = 'CR_IOS_EXPLORE_DISCO_OTHER_POST_COMMENT',
  CrIosExploreDiscoOtherPostLike = 'CR_IOS_EXPLORE_DISCO_OTHER_POST_LIKE',
  CrIosExploreDiscoOwnArticlePost = 'CR_IOS_EXPLORE_DISCO_OWN_ARTICLE_POST',
  CrIosExploreDiscoOwnArticlePostComment = 'CR_IOS_EXPLORE_DISCO_OWN_ARTICLE_POST_COMMENT',
  CrIosExploreDiscoOwnEventPost = 'CR_IOS_EXPLORE_DISCO_OWN_EVENT_POST',
  CrIosExploreDiscoOwnEventPostComment = 'CR_IOS_EXPLORE_DISCO_OWN_EVENT_POST_COMMENT',
  CrIosExploreDiscoOwnJobPost = 'CR_IOS_EXPLORE_DISCO_OWN_JOB_POST',
  CrIosExploreDiscoOwnJobPostComment = 'CR_IOS_EXPLORE_DISCO_OWN_JOB_POST_COMMENT',
  CrIosExploreDiscoOwnPost = 'CR_IOS_EXPLORE_DISCO_OWN_POST',
  CrIosExploreDiscoOwnPostComment = 'CR_IOS_EXPLORE_DISCO_OWN_POST_COMMENT',
  CrIosExploreDiscoPersonalUpdate = 'CR_IOS_EXPLORE_DISCO_PERSONAL_UPDATE',
  CrIosExploreDiscoPostShared = 'CR_IOS_EXPLORE_DISCO_POST_SHARED',
  CrIosExploreDiscoProfilePictUpdate = 'CR_IOS_EXPLORE_DISCO_PROFILE_PICT_UPDATE',
  CrIosExploreDiscoProfileUpdate = 'CR_IOS_EXPLORE_DISCO_PROFILE_UPDATE',
  CrIosExploreDiscoSharedContacts = 'CR_IOS_EXPLORE_DISCO_SHARED_CONTACTS',
  CrIosExploreDiscoWorkExpUpdate = 'CR_IOS_EXPLORE_DISCO_WORK_EXP_UPDATE',
  CrIosGroupsDiscussions = 'CR_IOS_GROUPS_DISCUSSIONS',
  CrIosGroupsMembersList = 'CR_IOS_GROUPS_MEMBERS_LIST',
  CrIosGroupsModerators = 'CR_IOS_GROUPS_MODERATORS',
  CrIosGroupsSocialProofHeaderMembers = 'CR_IOS_GROUPS_SOCIAL_PROOF_HEADER_MEMBERS',
  CrIosJobsCompanyContacts = 'CR_IOS_JOBS_COMPANY_CONTACTS',
  CrIosJobsCompanyContactsCommonContact = 'CR_IOS_JOBS_COMPANY_CONTACTS_COMMON_CONTACT',
  CrIosJobsCompanyContactsContact = 'CR_IOS_JOBS_COMPANY_CONTACTS_CONTACT',
  CrIosJobsFutureColleaguesList = 'CR_IOS_JOBS_FUTURE_COLLEAGUES_LIST',
  CrIosJobsFutureColleaguesModule = 'CR_IOS_JOBS_FUTURE_COLLEAGUES_MODULE',
  CrIosKlartextApprovedList = 'CR_IOS_KLARTEXT_APPROVED_LIST',
  CrIosKlartextAuthor = 'CR_IOS_KLARTEXT_AUTHOR',
  CrIosKlartextComment = 'CR_IOS_KLARTEXT_COMMENT',
  CrIosKlartextCommentLike = 'CR_IOS_KLARTEXT_COMMENT_LIKE',
  CrIosMehubContactsList = 'CR_IOS_MEHUB_CONTACTS_LIST',
  CrIosMehubVomp = 'CR_IOS_MEHUB_VOMP',
  CrIosMessenger = 'CR_IOS_MESSENGER',
  CrIosMymk = 'CR_IOS_MYMK',
  CrIosMymkContacts = 'CR_IOS_MYMK_CONTACTS',
  CrIosNetupdtDiscoAnniversary = 'CR_IOS_NETUPDT_DISCO_ANNIVERSARY',
  CrIosNetupdtDiscoBusinessCardUpdate = 'CR_IOS_NETUPDT_DISCO_BUSINESS_CARD_UPDATE',
  CrIosNetupdtDiscoContactReco = 'CR_IOS_NETUPDT_DISCO_CONTACT_RECO',
  CrIosNetupdtDiscoEventArticleShared = 'CR_IOS_NETUPDT_DISCO_EVENT_ARTICLE_SHARED',
  CrIosNetupdtDiscoEventAttending = 'CR_IOS_NETUPDT_DISCO_EVENT_ATTENDING',
  CrIosNetupdtDiscoEventCreation = 'CR_IOS_NETUPDT_DISCO_EVENT_CREATION',
  CrIosNetupdtDiscoEventPostShared = 'CR_IOS_NETUPDT_DISCO_EVENT_POST_SHARED',
  CrIosNetupdtDiscoFollowing = 'CR_IOS_NETUPDT_DISCO_FOLLOWING',
  CrIosNetupdtDiscoGroupCreation = 'CR_IOS_NETUPDT_DISCO_GROUP_CREATION',
  CrIosNetupdtDiscoGroupJoined = 'CR_IOS_NETUPDT_DISCO_GROUP_JOINED',
  CrIosNetupdtDiscoHeaderImgUpdate = 'CR_IOS_NETUPDT_DISCO_HEADER_IMG_UPDATE',
  CrIosNetupdtDiscoInterestingJobs = 'CR_IOS_NETUPDT_DISCO_INTERESTING_JOBS',
  CrIosNetupdtDiscoJobPostShared = 'CR_IOS_NETUPDT_DISCO_JOB_POST_SHARED',
  CrIosNetupdtDiscoNewContactUpdate = 'CR_IOS_NETUPDT_DISCO_NEW_CONTACT_UPDATE',
  CrIosNetupdtDiscoOtherArticlePostComment = 'CR_IOS_NETUPDT_DISCO_OTHER_ARTICLE_POST_COMMENT',
  CrIosNetupdtDiscoOtherArticlePostLike = 'CR_IOS_NETUPDT_DISCO_OTHER_ARTICLE_POST_LIKE',
  CrIosNetupdtDiscoOtherEventPostComment = 'CR_IOS_NETUPDT_DISCO_OTHER_EVENT_POST_COMMENT',
  CrIosNetupdtDiscoOtherEventPostLike = 'CR_IOS_NETUPDT_DISCO_OTHER_EVENT_POST_LIKE',
  CrIosNetupdtDiscoOtherJobPostComment = 'CR_IOS_NETUPDT_DISCO_OTHER_JOB_POST_COMMENT',
  CrIosNetupdtDiscoOtherJobPostLike = 'CR_IOS_NETUPDT_DISCO_OTHER_JOB_POST_LIKE',
  CrIosNetupdtDiscoOtherPostComment = 'CR_IOS_NETUPDT_DISCO_OTHER_POST_COMMENT',
  CrIosNetupdtDiscoOtherPostLike = 'CR_IOS_NETUPDT_DISCO_OTHER_POST_LIKE',
  CrIosNetupdtDiscoOwnArticlePost = 'CR_IOS_NETUPDT_DISCO_OWN_ARTICLE_POST',
  CrIosNetupdtDiscoOwnArticlePostComment = 'CR_IOS_NETUPDT_DISCO_OWN_ARTICLE_POST_COMMENT',
  CrIosNetupdtDiscoOwnEventPost = 'CR_IOS_NETUPDT_DISCO_OWN_EVENT_POST',
  CrIosNetupdtDiscoOwnEventPostComment = 'CR_IOS_NETUPDT_DISCO_OWN_EVENT_POST_COMMENT',
  CrIosNetupdtDiscoOwnJobPost = 'CR_IOS_NETUPDT_DISCO_OWN_JOB_POST',
  CrIosNetupdtDiscoOwnJobPostComment = 'CR_IOS_NETUPDT_DISCO_OWN_JOB_POST_COMMENT',
  CrIosNetupdtDiscoOwnPost = 'CR_IOS_NETUPDT_DISCO_OWN_POST',
  CrIosNetupdtDiscoOwnPostComment = 'CR_IOS_NETUPDT_DISCO_OWN_POST_COMMENT',
  CrIosNetupdtDiscoPersonalUpdate = 'CR_IOS_NETUPDT_DISCO_PERSONAL_UPDATE',
  CrIosNetupdtDiscoPostShared = 'CR_IOS_NETUPDT_DISCO_POST_SHARED',
  CrIosNetupdtDiscoProfilePictUpdate = 'CR_IOS_NETUPDT_DISCO_PROFILE_PICT_UPDATE',
  CrIosNetupdtDiscoProfileUpdate = 'CR_IOS_NETUPDT_DISCO_PROFILE_UPDATE',
  CrIosNetupdtDiscoSharedContacts = 'CR_IOS_NETUPDT_DISCO_SHARED_CONTACTS',
  CrIosNetupdtDiscoWorkExpUpdate = 'CR_IOS_NETUPDT_DISCO_WORK_EXP_UPDATE',
  CrIosNetworkHomeMessage = 'CR_IOS_NETWORK_HOME_MESSAGE',
  CrIosNewsAuthor = 'CR_IOS_NEWS_AUTHOR',
  CrIosNewsAuthorFollowers = 'CR_IOS_NEWS_AUTHOR_FOLLOWERS',
  CrIosNewsComment = 'CR_IOS_NEWS_COMMENT',
  CrIosNewsFollowers = 'CR_IOS_NEWS_FOLLOWERS',
  CrIosNewsLike = 'CR_IOS_NEWS_LIKE',
  CrIosOnboardingContactsReco = 'CR_IOS_ONBOARDING_CONTACTS_RECO',
  CrIosOwnContactList = 'CR_IOS_OWN_CONTACT_LIST',
  CrIosProbusinessAdvancedSearch = 'CR_IOS_PROBUSINESS_ADVANCED_SEARCH',
  CrIosProbusinessContacts = 'CR_IOS_PROBUSINESS_CONTACTS',
  CrIosProbusinessSearch = 'CR_IOS_PROBUSINESS_SEARCH',
  CrIosProfileContactsList = 'CR_IOS_PROFILE_CONTACTS_LIST',
  CrIosProfileSharedContacts = 'CR_IOS_PROFILE_SHARED_CONTACTS',
  CrIosProjobs = 'CR_IOS_PROJOBS',
  CrIosProjobsRecruiterReco = 'CR_IOS_PROJOBS_RECRUITER_RECO',
  CrIosProjobsVomp = 'CR_IOS_PROJOBS_VOMP',
  CrIosPublisherComments = 'CR_IOS_PUBLISHER_COMMENTS',
  CrIosPublisherContactsModule = 'CR_IOS_PUBLISHER_CONTACTS_MODULE',
  CrIosPublisherEmployeesModule = 'CR_IOS_PUBLISHER_EMPLOYEES_MODULE',
  CrIosPublisherEventsModule = 'CR_IOS_PUBLISHER_EVENTS_MODULE',
  CrIosPublisherLikesComment = 'CR_IOS_PUBLISHER_LIKES_COMMENT',
  CrIosPublisherLikesPost = 'CR_IOS_PUBLISHER_LIKES_POST',
  CrIosPublisherSocialProofHeader = 'CR_IOS_PUBLISHER_SOCIAL_PROOF_HEADER',
  CrIosSocialMentionComment = 'CR_IOS_SOCIAL_MENTION_COMMENT',
  CrIosSocialMentionPost = 'CR_IOS_SOCIAL_MENTION_POST',
  CrIosStory = 'CR_IOS_STORY',
  CrIosStoryMentioning = 'CR_IOS_STORY_MENTIONING',
  CrIosTalentManager = 'CR_IOS_TALENT_MANAGER',
  CrIosTalentService = 'CR_IOS_TALENT_SERVICE',
  CrIosTopicDiscoArticlePostShared = 'CR_IOS_TOPIC_DISCO_ARTICLE_POST_SHARED',
  CrIosTopicDiscoChannelFollowing = 'CR_IOS_TOPIC_DISCO_CHANNEL_FOLLOWING',
  CrIosTopicDiscoContactReco = 'CR_IOS_TOPIC_DISCO_CONTACT_RECO',
  CrIosTopicDiscoEventAttending = 'CR_IOS_TOPIC_DISCO_EVENT_ATTENDING',
  CrIosTopicDiscoEventCreation = 'CR_IOS_TOPIC_DISCO_EVENT_CREATION',
  CrIosTopicDiscoEventPostShared = 'CR_IOS_TOPIC_DISCO_EVENT_POST_SHARED',
  CrIosTopicDiscoFollowing = 'CR_IOS_TOPIC_DISCO_FOLLOWING',
  CrIosTopicDiscoGroupCreation = 'CR_IOS_TOPIC_DISCO_GROUP_CREATION',
  CrIosTopicDiscoGroupJoined = 'CR_IOS_TOPIC_DISCO_GROUP_JOINED',
  CrIosTopicDiscoInterestingJobs = 'CR_IOS_TOPIC_DISCO_INTERESTING_JOBS',
  CrIosTopicDiscoJobPostShared = 'CR_IOS_TOPIC_DISCO_JOB_POST_SHARED',
  CrIosTopicDiscoNewContactUpdate = 'CR_IOS_TOPIC_DISCO_NEW_CONTACT_UPDATE',
  CrIosTopicDiscoOtherArticlePostComment = 'CR_IOS_TOPIC_DISCO_OTHER_ARTICLE_POST_COMMENT',
  CrIosTopicDiscoOtherArticlePostLike = 'CR_IOS_TOPIC_DISCO_OTHER_ARTICLE_POST_LIKE',
  CrIosTopicDiscoOtherEventPostComment = 'CR_IOS_TOPIC_DISCO_OTHER_EVENT_POST_COMMENT',
  CrIosTopicDiscoOtherEventPostLike = 'CR_IOS_TOPIC_DISCO_OTHER_EVENT_POST_LIKE',
  CrIosTopicDiscoOtherJobPostComment = 'CR_IOS_TOPIC_DISCO_OTHER_JOB_POST_COMMENT',
  CrIosTopicDiscoOtherJobPostLike = 'CR_IOS_TOPIC_DISCO_OTHER_JOB_POST_LIKE',
  CrIosTopicDiscoOtherPostComment = 'CR_IOS_TOPIC_DISCO_OTHER_POST_COMMENT',
  CrIosTopicDiscoOtherPostLike = 'CR_IOS_TOPIC_DISCO_OTHER_POST_LIKE',
  CrIosTopicDiscoOwnArticlePost = 'CR_IOS_TOPIC_DISCO_OWN_ARTICLE_POST',
  CrIosTopicDiscoOwnArticlePostComment = 'CR_IOS_TOPIC_DISCO_OWN_ARTICLE_POST_COMMENT',
  CrIosTopicDiscoOwnEventPost = 'CR_IOS_TOPIC_DISCO_OWN_EVENT_POST',
  CrIosTopicDiscoOwnEventPostComment = 'CR_IOS_TOPIC_DISCO_OWN_EVENT_POST_COMMENT',
  CrIosTopicDiscoOwnJobPost = 'CR_IOS_TOPIC_DISCO_OWN_JOB_POST',
  CrIosTopicDiscoOwnJobPostComment = 'CR_IOS_TOPIC_DISCO_OWN_JOB_POST_COMMENT',
  CrIosTopicDiscoOwnPost = 'CR_IOS_TOPIC_DISCO_OWN_POST',
  CrIosTopicDiscoOwnPostComment = 'CR_IOS_TOPIC_DISCO_OWN_POST_COMMENT',
  CrIosTopicDiscoPostShared = 'CR_IOS_TOPIC_DISCO_POST_SHARED',
  CrIosTopicDiscoSharedContacts = 'CR_IOS_TOPIC_DISCO_SHARED_CONTACTS',
  CrIosUpboardingContactsReco = 'CR_IOS_UPBOARDING_CONTACTS_RECO',
  CrIosVompPage = 'CR_IOS_VOMP_PAGE',
  CrIosVompPageCommonContacts = 'CR_IOS_VOMP_PAGE_COMMON_CONTACTS',
  CrUnkown = 'CR_UNKOWN',
  CrWebAroundYouList = 'CR_WEB_AROUND_YOU_LIST',
  CrWebBirthdayList = 'CR_WEB_BIRTHDAY_LIST',
  CrWebChatview = 'CR_WEB_CHATVIEW',
  CrWebClickedInOnlyfy = 'CR_WEB_CLICKED_IN_ONLYFY',
  CrWebClickedInRecruiterRecommendations = 'CR_WEB_CLICKED_IN_RECRUITER_RECOMMENDATIONS',
  CrWebCompaniesCompanyContactList = 'CR_WEB_COMPANIES_COMPANY_CONTACT_LIST',
  CrWebCompaniesContacts = 'CR_WEB_COMPANIES_CONTACTS',
  CrWebCompaniesEmployeeList = 'CR_WEB_COMPANIES_EMPLOYEE_LIST',
  CrWebCompaniesFollowersList = 'CR_WEB_COMPANIES_FOLLOWERS_LIST',
  CrWebContactRequestReceived = 'CR_WEB_CONTACT_REQUEST_RECEIVED',
  CrWebContactRequestSent = 'CR_WEB_CONTACT_REQUEST_SENT',
  CrWebConversationStarter = 'CR_WEB_CONVERSATION_STARTER',
  CrWebDiscoMemberSearch = 'CR_WEB_DISCO_MEMBER_SEARCH',
  CrWebDiscoSearch = 'CR_WEB_DISCO_SEARCH',
  CrWebEntitypageDiscoCompanyPage = 'CR_WEB_ENTITYPAGE_DISCO_COMPANY_PAGE',
  CrWebEventsComment = 'CR_WEB_EVENTS_COMMENT',
  CrWebEventsContactPerson = 'CR_WEB_EVENTS_CONTACT_PERSON',
  CrWebEventsGuestList = 'CR_WEB_EVENTS_GUEST_LIST',
  CrWebEventsLikes = 'CR_WEB_EVENTS_LIKES',
  CrWebEventsOrganizer = 'CR_WEB_EVENTS_ORGANIZER',
  CrWebEventsSpeaker = 'CR_WEB_EVENTS_SPEAKER',
  CrWebExploreDiscoAnniversary = 'CR_WEB_EXPLORE_DISCO_ANNIVERSARY',
  CrWebExploreDiscoArticlePostShared = 'CR_WEB_EXPLORE_DISCO_ARTICLE_POST_SHARED',
  CrWebExploreDiscoBusinessCardUpdate = 'CR_WEB_EXPLORE_DISCO_BUSINESS_CARD_UPDATE',
  CrWebExploreDiscoContactReco = 'CR_WEB_EXPLORE_DISCO_CONTACT_RECO',
  CrWebExploreDiscoEventAttending = 'CR_WEB_EXPLORE_DISCO_EVENT_ATTENDING',
  CrWebExploreDiscoEventCreation = 'CR_WEB_EXPLORE_DISCO_EVENT_CREATION',
  CrWebExploreDiscoEventPostShared = 'CR_WEB_EXPLORE_DISCO_EVENT_POST_SHARED',
  CrWebExploreDiscoFollowing = 'CR_WEB_EXPLORE_DISCO_FOLLOWING',
  CrWebExploreDiscoGroupCreation = 'CR_WEB_EXPLORE_DISCO_GROUP_CREATION',
  CrWebExploreDiscoGroupJoined = 'CR_WEB_EXPLORE_DISCO_GROUP_JOINED',
  CrWebExploreDiscoHeaderImageUpdate = 'CR_WEB_EXPLORE_DISCO_HEADER_IMAGE_UPDATE',
  CrWebExploreDiscoInterestingJobs = 'CR_WEB_EXPLORE_DISCO_INTERESTING_JOBS',
  CrWebExploreDiscoJobPostShared = 'CR_WEB_EXPLORE_DISCO_JOB_POST_SHARED',
  CrWebExploreDiscoNewContactUpdate = 'CR_WEB_EXPLORE_DISCO_NEW_CONTACT_UPDATE',
  CrWebExploreDiscoOtherArticlePostComment = 'CR_WEB_EXPLORE_DISCO_OTHER_ARTICLE_POST_COMMENT',
  CrWebExploreDiscoOtherArticlePostLike = 'CR_WEB_EXPLORE_DISCO_OTHER_ARTICLE_POST_LIKE',
  CrWebExploreDiscoOtherEventPostComment = 'CR_WEB_EXPLORE_DISCO_OTHER_EVENT_POST_COMMENT',
  CrWebExploreDiscoOtherEventPostLike = 'CR_WEB_EXPLORE_DISCO_OTHER_EVENT_POST_LIKE',
  CrWebExploreDiscoOtherJobPostComment = 'CR_WEB_EXPLORE_DISCO_OTHER_JOB_POST_COMMENT',
  CrWebExploreDiscoOtherJobPostLike = 'CR_WEB_EXPLORE_DISCO_OTHER_JOB_POST_LIKE',
  CrWebExploreDiscoOtherPostComment = 'CR_WEB_EXPLORE_DISCO_OTHER_POST_COMMENT',
  CrWebExploreDiscoOtherPostLike = 'CR_WEB_EXPLORE_DISCO_OTHER_POST_LIKE',
  CrWebExploreDiscoOwnArticlePost = 'CR_WEB_EXPLORE_DISCO_OWN_ARTICLE_POST',
  CrWebExploreDiscoOwnArticlePostComment = 'CR_WEB_EXPLORE_DISCO_OWN_ARTICLE_POST_COMMENT',
  CrWebExploreDiscoOwnEventPost = 'CR_WEB_EXPLORE_DISCO_OWN_EVENT_POST',
  CrWebExploreDiscoOwnEventPostComment = 'CR_WEB_EXPLORE_DISCO_OWN_EVENT_POST_COMMENT',
  CrWebExploreDiscoOwnJobPost = 'CR_WEB_EXPLORE_DISCO_OWN_JOB_POST',
  CrWebExploreDiscoOwnJobPostComment = 'CR_WEB_EXPLORE_DISCO_OWN_JOB_POST_COMMENT',
  CrWebExploreDiscoOwnPost = 'CR_WEB_EXPLORE_DISCO_OWN_POST',
  CrWebExploreDiscoOwnPostComment = 'CR_WEB_EXPLORE_DISCO_OWN_POST_COMMENT',
  CrWebExploreDiscoPersonalUpdate = 'CR_WEB_EXPLORE_DISCO_PERSONAL_UPDATE',
  CrWebExploreDiscoPostShared = 'CR_WEB_EXPLORE_DISCO_POST_SHARED',
  CrWebExploreDiscoProfilePictUpdate = 'CR_WEB_EXPLORE_DISCO_PROFILE_PICT_UPDATE',
  CrWebExploreDiscoProfileUpdate = 'CR_WEB_EXPLORE_DISCO_PROFILE_UPDATE',
  CrWebExploreDiscoSharedContacts = 'CR_WEB_EXPLORE_DISCO_SHARED_CONTACTS',
  CrWebExploreDiscoWorkExpUpdate = 'CR_WEB_EXPLORE_DISCO_WORK_EXP_UPDATE',
  CrWebGroupsDiscussions = 'CR_WEB_GROUPS_DISCUSSIONS',
  CrWebGroupsMembersList = 'CR_WEB_GROUPS_MEMBERS_LIST',
  CrWebGroupsModerators = 'CR_WEB_GROUPS_MODERATORS',
  CrWebGroupsSocialProofHeaderMembers = 'CR_WEB_GROUPS_SOCIAL_PROOF_HEADER_MEMBERS',
  CrWebJobsCompanyContacts = 'CR_WEB_JOBS_COMPANY_CONTACTS',
  CrWebJobsCompanyContactsCommonContact = 'CR_WEB_JOBS_COMPANY_CONTACTS_COMMON_CONTACT',
  CrWebJobsCompanyContactsContact = 'CR_WEB_JOBS_COMPANY_CONTACTS_CONTACT',
  CrWebJobsFutureColleaguesList = 'CR_WEB_JOBS_FUTURE_COLLEAGUES_LIST',
  CrWebJobsFutureColleaguesModule = 'CR_WEB_JOBS_FUTURE_COLLEAGUES_MODULE',
  CrWebKlartextApprovedList = 'CR_WEB_KLARTEXT_APPROVED_LIST',
  CrWebKlartextAuthor = 'CR_WEB_KLARTEXT_AUTHOR',
  CrWebKlartextComment = 'CR_WEB_KLARTEXT_COMMENT',
  CrWebKlartextCommentLike = 'CR_WEB_KLARTEXT_COMMENT_LIKE',
  CrWebMehubContactsList = 'CR_WEB_MEHUB_CONTACTS_LIST',
  CrWebMehubVomp = 'CR_WEB_MEHUB_VOMP',
  CrWebMessenger = 'CR_WEB_MESSENGER',
  CrWebMymk = 'CR_WEB_MYMK',
  CrWebMymkContacts = 'CR_WEB_MYMK_CONTACTS',
  CrWebNetupdtDiscoAnniversary = 'CR_WEB_NETUPDT_DISCO_ANNIVERSARY',
  CrWebNetupdtDiscoBusinessCardUpdate = 'CR_WEB_NETUPDT_DISCO_BUSINESS_CARD_UPDATE',
  CrWebNetupdtDiscoContactReco = 'CR_WEB_NETUPDT_DISCO_CONTACT_RECO',
  CrWebNetupdtDiscoEventArticleShared = 'CR_WEB_NETUPDT_DISCO_EVENT_ARTICLE_SHARED',
  CrWebNetupdtDiscoEventAttending = 'CR_WEB_NETUPDT_DISCO_EVENT_ATTENDING',
  CrWebNetupdtDiscoEventCreation = 'CR_WEB_NETUPDT_DISCO_EVENT_CREATION',
  CrWebNetupdtDiscoEventPostShared = 'CR_WEB_NETUPDT_DISCO_EVENT_POST_SHARED',
  CrWebNetupdtDiscoFollowing = 'CR_WEB_NETUPDT_DISCO_FOLLOWING',
  CrWebNetupdtDiscoGroupCreation = 'CR_WEB_NETUPDT_DISCO_GROUP_CREATION',
  CrWebNetupdtDiscoGroupJoined = 'CR_WEB_NETUPDT_DISCO_GROUP_JOINED',
  CrWebNetupdtDiscoHeaderImgUpdate = 'CR_WEB_NETUPDT_DISCO_HEADER_IMG_UPDATE',
  CrWebNetupdtDiscoInterestingJobs = 'CR_WEB_NETUPDT_DISCO_INTERESTING_JOBS',
  CrWebNetupdtDiscoJobPostShared = 'CR_WEB_NETUPDT_DISCO_JOB_POST_SHARED',
  CrWebNetupdtDiscoNewContactUpdate = 'CR_WEB_NETUPDT_DISCO_NEW_CONTACT_UPDATE',
  CrWebNetupdtDiscoOtherArticlePostComment = 'CR_WEB_NETUPDT_DISCO_OTHER_ARTICLE_POST_COMMENT',
  CrWebNetupdtDiscoOtherArticlePostLike = 'CR_WEB_NETUPDT_DISCO_OTHER_ARTICLE_POST_LIKE',
  CrWebNetupdtDiscoOtherEventPostComment = 'CR_WEB_NETUPDT_DISCO_OTHER_EVENT_POST_COMMENT',
  CrWebNetupdtDiscoOtherEventPostLike = 'CR_WEB_NETUPDT_DISCO_OTHER_EVENT_POST_LIKE',
  CrWebNetupdtDiscoOtherJobPostComment = 'CR_WEB_NETUPDT_DISCO_OTHER_JOB_POST_COMMENT',
  CrWebNetupdtDiscoOtherJobPostLike = 'CR_WEB_NETUPDT_DISCO_OTHER_JOB_POST_LIKE',
  CrWebNetupdtDiscoOtherPostComment = 'CR_WEB_NETUPDT_DISCO_OTHER_POST_COMMENT',
  CrWebNetupdtDiscoOtherPostLike = 'CR_WEB_NETUPDT_DISCO_OTHER_POST_LIKE',
  CrWebNetupdtDiscoOwnArticlePost = 'CR_WEB_NETUPDT_DISCO_OWN_ARTICLE_POST',
  CrWebNetupdtDiscoOwnArticlePostComment = 'CR_WEB_NETUPDT_DISCO_OWN_ARTICLE_POST_COMMENT',
  CrWebNetupdtDiscoOwnEventPost = 'CR_WEB_NETUPDT_DISCO_OWN_EVENT_POST',
  CrWebNetupdtDiscoOwnEventPostComment = 'CR_WEB_NETUPDT_DISCO_OWN_EVENT_POST_COMMENT',
  CrWebNetupdtDiscoOwnJobPost = 'CR_WEB_NETUPDT_DISCO_OWN_JOB_POST',
  CrWebNetupdtDiscoOwnJobPostComment = 'CR_WEB_NETUPDT_DISCO_OWN_JOB_POST_COMMENT',
  CrWebNetupdtDiscoOwnPost = 'CR_WEB_NETUPDT_DISCO_OWN_POST',
  CrWebNetupdtDiscoOwnPostComment = 'CR_WEB_NETUPDT_DISCO_OWN_POST_COMMENT',
  CrWebNetupdtDiscoPersonalUpdate = 'CR_WEB_NETUPDT_DISCO_PERSONAL_UPDATE',
  CrWebNetupdtDiscoPostShared = 'CR_WEB_NETUPDT_DISCO_POST_SHARED',
  CrWebNetupdtDiscoProfilePictUpdate = 'CR_WEB_NETUPDT_DISCO_PROFILE_PICT_UPDATE',
  CrWebNetupdtDiscoProfileUpdate = 'CR_WEB_NETUPDT_DISCO_PROFILE_UPDATE',
  CrWebNetupdtDiscoSharedContacts = 'CR_WEB_NETUPDT_DISCO_SHARED_CONTACTS',
  CrWebNetupdtDiscoWorkExpUpdate = 'CR_WEB_NETUPDT_DISCO_WORK_EXP_UPDATE',
  CrWebNetworkHomeMessage = 'CR_WEB_NETWORK_HOME_MESSAGE',
  CrWebNewsAuthor = 'CR_WEB_NEWS_AUTHOR',
  CrWebNewsAuthorFollowers = 'CR_WEB_NEWS_AUTHOR_FOLLOWERS',
  CrWebNewsComment = 'CR_WEB_NEWS_COMMENT',
  CrWebNewsFollowers = 'CR_WEB_NEWS_FOLLOWERS',
  CrWebNewsLike = 'CR_WEB_NEWS_LIKE',
  CrWebOnboardingContactsReco = 'CR_WEB_ONBOARDING_CONTACTS_RECO',
  CrWebOwnContactList = 'CR_WEB_OWN_CONTACT_LIST',
  CrWebProbusinessAdvancedSearch = 'CR_WEB_PROBUSINESS_ADVANCED_SEARCH',
  CrWebProbusinessContacts = 'CR_WEB_PROBUSINESS_CONTACTS',
  CrWebProbusinessSearch = 'CR_WEB_PROBUSINESS_SEARCH',
  CrWebProfileContactsList = 'CR_WEB_PROFILE_CONTACTS_LIST',
  CrWebProfileSharedContacts = 'CR_WEB_PROFILE_SHARED_CONTACTS',
  CrWebProjobs = 'CR_WEB_PROJOBS',
  CrWebProjobsRecruiterReco = 'CR_WEB_PROJOBS_RECRUITER_RECO',
  CrWebProjobsVomp = 'CR_WEB_PROJOBS_VOMP',
  CrWebPublisherComments = 'CR_WEB_PUBLISHER_COMMENTS',
  CrWebPublisherContactsModule = 'CR_WEB_PUBLISHER_CONTACTS_MODULE',
  CrWebPublisherEmployeesModule = 'CR_WEB_PUBLISHER_EMPLOYEES_MODULE',
  CrWebPublisherEventsModule = 'CR_WEB_PUBLISHER_EVENTS_MODULE',
  CrWebPublisherLikesComment = 'CR_WEB_PUBLISHER_LIKES_COMMENT',
  CrWebPublisherLikesPost = 'CR_WEB_PUBLISHER_LIKES_POST',
  CrWebPublisherSocialProofHeader = 'CR_WEB_PUBLISHER_SOCIAL_PROOF_HEADER',
  CrWebSocialMentionComment = 'CR_WEB_SOCIAL_MENTION_COMMENT',
  CrWebSocialMentionPost = 'CR_WEB_SOCIAL_MENTION_POST',
  CrWebStory = 'CR_WEB_STORY',
  CrWebStoryMentioning = 'CR_WEB_STORY_MENTIONING',
  CrWebTalentManager = 'CR_WEB_TALENT_MANAGER',
  CrWebTalentService = 'CR_WEB_TALENT_SERVICE',
  CrWebTopicDiscoArticlePostShared = 'CR_WEB_TOPIC_DISCO_ARTICLE_POST_SHARED',
  CrWebTopicDiscoChannelFollowing = 'CR_WEB_TOPIC_DISCO_CHANNEL_FOLLOWING',
  CrWebTopicDiscoContactReco = 'CR_WEB_TOPIC_DISCO_CONTACT_RECO',
  CrWebTopicDiscoEventAttending = 'CR_WEB_TOPIC_DISCO_EVENT_ATTENDING',
  CrWebTopicDiscoEventCreation = 'CR_WEB_TOPIC_DISCO_EVENT_CREATION',
  CrWebTopicDiscoEventPostShared = 'CR_WEB_TOPIC_DISCO_EVENT_POST_SHARED',
  CrWebTopicDiscoFollowing = 'CR_WEB_TOPIC_DISCO_FOLLOWING',
  CrWebTopicDiscoGroupCreation = 'CR_WEB_TOPIC_DISCO_GROUP_CREATION',
  CrWebTopicDiscoGroupJoined = 'CR_WEB_TOPIC_DISCO_GROUP_JOINED',
  CrWebTopicDiscoInterestingJobs = 'CR_WEB_TOPIC_DISCO_INTERESTING_JOBS',
  CrWebTopicDiscoJobPostShared = 'CR_WEB_TOPIC_DISCO_JOB_POST_SHARED',
  CrWebTopicDiscoNewContactUpdate = 'CR_WEB_TOPIC_DISCO_NEW_CONTACT_UPDATE',
  CrWebTopicDiscoOtherArticlePostComment = 'CR_WEB_TOPIC_DISCO_OTHER_ARTICLE_POST_COMMENT',
  CrWebTopicDiscoOtherArticlePostLike = 'CR_WEB_TOPIC_DISCO_OTHER_ARTICLE_POST_LIKE',
  CrWebTopicDiscoOtherEventPostComment = 'CR_WEB_TOPIC_DISCO_OTHER_EVENT_POST_COMMENT',
  CrWebTopicDiscoOtherEventPostLike = 'CR_WEB_TOPIC_DISCO_OTHER_EVENT_POST_LIKE',
  CrWebTopicDiscoOtherJobPostComment = 'CR_WEB_TOPIC_DISCO_OTHER_JOB_POST_COMMENT',
  CrWebTopicDiscoOtherJobPostLike = 'CR_WEB_TOPIC_DISCO_OTHER_JOB_POST_LIKE',
  CrWebTopicDiscoOtherPostComment = 'CR_WEB_TOPIC_DISCO_OTHER_POST_COMMENT',
  CrWebTopicDiscoOtherPostLike = 'CR_WEB_TOPIC_DISCO_OTHER_POST_LIKE',
  CrWebTopicDiscoOwnArticlePost = 'CR_WEB_TOPIC_DISCO_OWN_ARTICLE_POST',
  CrWebTopicDiscoOwnArticlePostComment = 'CR_WEB_TOPIC_DISCO_OWN_ARTICLE_POST_COMMENT',
  CrWebTopicDiscoOwnEventPost = 'CR_WEB_TOPIC_DISCO_OWN_EVENT_POST',
  CrWebTopicDiscoOwnEventPostComment = 'CR_WEB_TOPIC_DISCO_OWN_EVENT_POST_COMMENT',
  CrWebTopicDiscoOwnJobPost = 'CR_WEB_TOPIC_DISCO_OWN_JOB_POST',
  CrWebTopicDiscoOwnJobPostComment = 'CR_WEB_TOPIC_DISCO_OWN_JOB_POST_COMMENT',
  CrWebTopicDiscoOwnPost = 'CR_WEB_TOPIC_DISCO_OWN_POST',
  CrWebTopicDiscoOwnPostComment = 'CR_WEB_TOPIC_DISCO_OWN_POST_COMMENT',
  CrWebTopicDiscoPostShared = 'CR_WEB_TOPIC_DISCO_POST_SHARED',
  CrWebTopicDiscoSharedContacts = 'CR_WEB_TOPIC_DISCO_SHARED_CONTACTS',
  CrWebUpboardingContactsReco = 'CR_WEB_UPBOARDING_CONTACTS_RECO',
  CrWebVompPage = 'CR_WEB_VOMP_PAGE',
  CrWebVompPageCommonContacts = 'CR_WEB_VOMP_PAGE_COMMON_CONTACTS',
  MatchActivityboxContactlist = 'MATCH_ACTIVITYBOX_CONTACTLIST',
  MatchActivityboxEvents = 'MATCH_ACTIVITYBOX_EVENTS',
  MatchActivityboxMessages = 'MATCH_ACTIVITYBOX_MESSAGES',
  MatchActivityboxNewsletters = 'MATCH_ACTIVITYBOX_NEWSLETTERS',
  MatchAppDesktop = 'MATCH_APP_DESKTOP',
  MatchAppMobile = 'MATCH_APP_MOBILE',
  MatchAppWidget = 'MATCH_APP_WIDGET',
  MatchBiggerSearchTopHave = 'MATCH_BIGGER_SEARCH_TOP_HAVE',
  MatchBirthday = 'MATCH_BIRTHDAY',
  MatchChangedContacts = 'MATCH_CHANGED_CONTACTS',
  MatchCommunitiesClassified = 'MATCH_COMMUNITIES_CLASSIFIED',
  MatchCommunitiesClassifiedComment = 'MATCH_COMMUNITIES_CLASSIFIED_COMMENT',
  MatchCommunitiesClassifiedCommentLiker = 'MATCH_COMMUNITIES_CLASSIFIED_COMMENT_LIKER',
  MatchCommunitiesClassifiedLiker = 'MATCH_COMMUNITIES_CLASSIFIED_LIKER',
  MatchCommunitiesGroupInvitation = 'MATCH_COMMUNITIES_GROUP_INVITATION',
  MatchCommunitiesMembersList = 'MATCH_COMMUNITIES_MEMBERS_LIST',
  MatchCommunitiesPost = 'MATCH_COMMUNITIES_POST',
  MatchCommunitiesPostComment = 'MATCH_COMMUNITIES_POST_COMMENT',
  MatchCommunitiesPostCommentLiker = 'MATCH_COMMUNITIES_POST_COMMENT_LIKER',
  MatchCommunitiesPostLiker = 'MATCH_COMMUNITIES_POST_LIKER',
  MatchCompanyProfile = 'MATCH_COMPANY_PROFILE',
  MatchCompanyProfileFollower = 'MATCH_COMPANY_PROFILE_FOLLOWER',
  MatchCompanyProfileFriendpath = 'MATCH_COMPANY_PROFILE_FRIENDPATH',
  MatchCompanyProfileUpdateLiker = 'MATCH_COMPANY_PROFILE_UPDATE_LIKER',
  MatchCompanyUrl = 'MATCH_COMPANY_URL',
  MatchContactlist = 'MATCH_CONTACTLIST',
  MatchContactlistOf = 'MATCH_CONTACTLIST_OF',
  MatchContactLoggedin = 'MATCH_CONTACT_LOGGEDIN',
  MatchContactRecommendation = 'MATCH_CONTACT_RECOMMENDATION',
  MatchContent = 'MATCH_CONTENT',
  MatchContentArticleInteractionClick = 'MATCH_CONTENT_ARTICLE_INTERACTION_CLICK',
  MatchContentInsiderClick = 'MATCH_CONTENT_INSIDER_CLICK',
  MatchContentInsiderFollowersClick = 'MATCH_CONTENT_INSIDER_FOLLOWERS_CLICK',
  MatchContentKlartext = 'MATCH_CONTENT_KLARTEXT',
  MatchCurrentColleagues = 'MATCH_CURRENT_COLLEAGUES',
  MatchEmails = 'MATCH_EMAILS',
  MatchEventsGuestlist = 'MATCH_EVENTS_GUESTLIST',
  MatchEventsMobileApp = 'MATCH_EVENTS_MOBILE_APP',
  MatchEventsMyContactsGoTo = 'MATCH_EVENTS_MY_CONTACTS_GO_TO',
  MatchEventsSearchResults = 'MATCH_EVENTS_SEARCH_RESULTS',
  MatchEventPage = 'MATCH_EVENT_PAGE',
  MatchEventSearch = 'MATCH_EVENT_SEARCH',
  MatchExcompanyUrl = 'MATCH_EXCOMPANY_URL',
  MatchFoaflist = 'MATCH_FOAFLIST',
  MatchFriendpath = 'MATCH_FRIENDPATH',
  MatchFriendshipStory = 'MATCH_FRIENDSHIP_STORY',
  MatchFuturemeProfession = 'MATCH_FUTUREME_PROFESSION',
  MatchGroupsOfContacts = 'MATCH_GROUPS_OF_CONTACTS',
  MatchGroupSearch = 'MATCH_GROUP_SEARCH',
  MatchGuestbookOf = 'MATCH_GUESTBOOK_OF',
  MatchHavesWantsMatcher = 'MATCH_HAVES_WANTS_MATCHER',
  MatchHomepage = 'MATCH_HOMEPAGE',
  MatchIncrementalSearchList = 'MATCH_INCREMENTAL_SEARCH_LIST',
  MatchInvitation = 'MATCH_INVITATION',
  MatchJobsMatching = 'MATCH_JOBS_MATCHING',
  MatchJobSearch = 'MATCH_JOB_SEARCH',
  MatchKnownUsers = 'MATCH_KNOWN_USERS',
  MatchKnowInCompany = 'MATCH_KNOW_IN_COMPANY',
  MatchLatest = 'MATCH_LATEST',
  MatchLinkRecommended = 'MATCH_LINK_RECOMMENDED',
  MatchLoggedin = 'MATCH_LOGGEDIN',
  MatchMarketplace = 'MATCH_MARKETPLACE',
  MatchMembersAlsoVisited = 'MATCH_MEMBERS_ALSO_VISITED',
  MatchMembersYouMayKnow = 'MATCH_MEMBERS_YOU_MAY_KNOW',
  MatchMessages = 'MATCH_MESSAGES',
  MatchMyprojobsRecruiterClick = 'MATCH_MYPROJOBS_RECRUITER_CLICK',
  MatchNearby = 'MATCH_NEARBY',
  MatchNetworkArticleAuthor = 'MATCH_NETWORK_ARTICLE_AUTHOR',
  MatchNetworkMember = 'MATCH_NETWORK_MEMBER',
  MatchNetworkModerator = 'MATCH_NETWORK_MODERATOR',
  MatchNetworkOverviewAuthor = 'MATCH_NETWORK_OVERVIEW_AUTHOR',
  MatchNetworkStartpageAuthor = 'MATCH_NETWORK_STARTPAGE_AUTHOR',
  MatchOutlookAddinReadProfileClick = 'MATCH_OUTLOOK_ADDIN_READ_PROFILE_CLICK',
  MatchOutlookComparison = 'MATCH_OUTLOOK_COMPARISON',
  MatchOutlookConnector = 'MATCH_OUTLOOK_CONNECTOR',
  MatchPassedBirthday = 'MATCH_PASSED_BIRTHDAY',
  MatchPoll = 'MATCH_POLL',
  MatchProfileChangeAddress = 'MATCH_PROFILE_CHANGE_ADDRESS',
  MatchProfileChangeCompany = 'MATCH_PROFILE_CHANGE_COMPANY',
  MatchProfileChangeJobTitle = 'MATCH_PROFILE_CHANGE_JOB_TITLE',
  MatchProfileOf = 'MATCH_PROFILE_OF',
  MatchProjects = 'MATCH_PROJECTS',
  MatchProjobsProfileClickInXtm = 'MATCH_PROJOBS_PROFILE_CLICK_IN_XTM',
  MatchRandom = 'MATCH_RANDOM',
  MatchRandomFoaf = 'MATCH_RANDOM_FOAF',
  MatchRandomHomepage = 'MATCH_RANDOM_HOMEPAGE',
  MatchReferences = 'MATCH_REFERENCES',
  MatchSameGroups = 'MATCH_SAME_GROUPS',
  MatchSameIndustry = 'MATCH_SAME_INDUSTRY',
  MatchSameInterests = 'MATCH_SAME_INTERESTS',
  MatchSameName = 'MATCH_SAME_NAME',
  MatchSearch = 'MATCH_SEARCH',
  MatchSimilarProfiles = 'MATCH_SIMILAR_PROFILES',
  MatchSuccessStory = 'MATCH_SUCCESS_STORY',
  MatchSuggestionmail = 'MATCH_SUGGESTIONMAIL',
  MatchTalentmanager = 'MATCH_TALENTMANAGER',
  MatchTestimonial = 'MATCH_TESTIMONIAL',
  MatchTopicsPost = 'MATCH_TOPICS_POST',
  MatchTopicsStartpage = 'MATCH_TOPICS_STARTPAGE',
  MatchTopicsTopicpage = 'MATCH_TOPICS_TOPICPAGE',
  MatchVisitedcompanymatcher = 'MATCH_VISITEDCOMPANYMATCHER',
  MatchVisitedcp = 'MATCH_VISITEDCP',
  MatchVisitededumatcher = 'MATCH_VISITEDEDUMATCHER',
  MatchVisitedmycp = 'MATCH_VISITEDMYCP',
  MatchVisitedorgamatcher = 'MATCH_VISITEDORGAMATCHER',
  MatchVisitedMyPortfolio = 'MATCH_VISITED_MY_PORTFOLIO',
  MatchWantsHavesMatcher = 'MATCH_WANTS_HAVES_MATCHER',
  MatchWeeklyStats = 'MATCH_WEEKLY_STATS',
  MatchXasAd = 'MATCH_XAS_AD',
  MatchXbpContactPersonModule = 'MATCH_XBP_CONTACT_PERSON_MODULE',
  MatchXbpVombpList = 'MATCH_XBP_VOMBP_LIST',
  MatchXingBrowserToolbar = 'MATCH_XING_BROWSER_TOOLBAR',
}

/** Client billing address */
export type ClientBillingAddress = {
  __typename?: 'ClientBillingAddress';
  addressAddition?: Maybe<TextSetting>;
  ccEmail?: Maybe<TextSetting>;
  city?: Maybe<TextSetting>;
  country?: Maybe<SelectSetting>;
  email?: Maybe<TextSetting>;
  id?: Maybe<Scalars['ID']['output']>;
  language?: Maybe<SelectSetting>;
  name?: Maybe<TextSetting>;
  postcode?: Maybe<TextSetting>;
  street?: Maybe<TextSetting>;
  taxId?: Maybe<TextSetting>;
};

/** Input for client billing address */
export type ClientBillingAddressInput = {
  addressAddition?: InputMaybe<TextSettingInput>;
  ccEmail?: InputMaybe<TextSettingInput>;
  city: TextSettingInput;
  country: SelectSettingInput;
  email: TextSettingInput;
  language: SelectSettingInput;
  name: TextSettingInput;
  postcode: TextSettingInput;
  street: TextSettingInput;
  taxId?: InputMaybe<TextSettingInput>;
};

/** Client overview for settings */
export type ClientOverview = {
  __typename?: 'ClientOverview';
  clientId?: Maybe<Scalars['ID']['output']>;
  /** URL of the iFrame where the client can select their payment method */
  paymentIframeUrl?: Maybe<Scalars['URL']['output']>;
  /** Preview for the User's payment information */
  paymentInfo?: Maybe<Scalars['String']['output']>;
  /** ProductGroups of the client's available products */
  productGroups?: Maybe<Array<Maybe<PaymentClientProductGroup>>>;
};

/** Client Invoice Detail */
export type ClientPaymentInvoice = {
  __typename?: 'ClientPaymentInvoice';
  /** Price with the ISO currency code and the amount in cents; not decimal */
  amount: PaymentPrice;
  /** Invoice date visible to the customer. */
  date: Scalars['Date']['output'];
  /** URL to download the invoice PDF */
  downloadUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Set to true if the invoice is created from a cancellation */
  isCancellation: Scalars['Boolean']['output'];
  /** Invoice identifier, visible to the customer. */
  number: Scalars['String']['output'];
  /** An array of the product groups of the products related in the invoice */
  productGroups?: Maybe<Array<PaymentClientProductGroup>>;
  /** Invoice Status from HeidelPay */
  status: ClientPaymentInvoiceStatus;
};

export const enum ClientPaymentInvoiceStatus {
  Cancelled = 'CANCELLED',
  Encashment = 'ENCASHMENT',
  FirstReminder = 'FIRST_REMINDER',
  Issued = 'ISSUED',
  Open = 'OPEN',
  Paid = 'PAID',
  SecondReminder = 'SECOND_REMINDER',
}

export type ClientPaymentInvoices = {
  __typename?: 'ClientPaymentInvoices';
  collection?: Maybe<Array<Maybe<ClientPaymentInvoice>>>;
};

export type CollapsedModule = HasNwtTrackingToken &
  HasNewWorkTrackingToken & {
    __typename?: 'CollapsedModule';
    moduleName: Scalars['String']['output'];
    nwTrackingToken?: Maybe<Scalars['String']['output']>;
    /** @deprecated Replaced by HasNewWorkTrackingToken to not confuse with another field with same name */
    trackingToken?: Maybe<Scalars['String']['output']>;
    userId: Scalars['ID']['output'];
  };

export type CollapsedModuleError = {
  __typename?: 'CollapsedModuleError';
  error: Scalars['String']['output'];
};

export type CollapsedModuleResult =
  | CollapsedModuleError
  | CollapsedModuleSuccess;

export type CollapsedModuleSuccess = {
  __typename?: 'CollapsedModuleSuccess';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Represents commonalities collection.
 * Contains information about all commonalities among users.
 */
export type Commonalities = {
  __typename?: 'Commonalities';
  /** @deprecated Groups have been shutdown. This returns null */
  communities?: Maybe<Array<Maybe<Community>>>;
  companies?: Maybe<CommonalitiesCompanies>;
  /** #rest(batched) #virtual */
  contacts?: Maybe<Array<Maybe<XingId>>>;
  education?: Maybe<CommonalitiesEducation>;
  events?: Maybe<CommonalitiesEvents>;
  /** @deprecated Groups have been shutdown. This returns null */
  groups?: Maybe<Array<Maybe<Community>>>;
  industries?: Maybe<CommonalitiesIndustries>;
  jobroles?: Maybe<CommonalitiesJobRoles>;
  skills?: Maybe<CommonalitiesSkills>;
  userB: Scalars['ID']['output'];
};

/**
 * Represents companies.
 * Contains information about companies in common among users.
 */
export type CommonalitiesCompanies = {
  __typename?: 'CommonalitiesCompanies';
  /** #rest(batched) #virtual */
  currentAToCurrentBIds?: Maybe<Array<Maybe<Company>>>;
  /** #rest(batched) #virtual */
  currentAToPastBIds?: Maybe<Array<Maybe<Company>>>;
  /** #rest(batched) #virtual */
  pastAToCurrentBIds?: Maybe<Array<Maybe<Company>>>;
  /** #rest(batched) #virtual */
  pastAToPastBIds?: Maybe<Array<Maybe<Company>>>;
};

/**
 * Represents education.
 * Contains information about education in common among users.
 */
export type CommonalitiesEducation = {
  __typename?: 'CommonalitiesEducation';
  fieldsOfStudy: Array<Scalars['String']['output']>;
  universities: Array<Scalars['String']['output']>;
};

/**
 * Represents events.
 * Contains information about events in common among users.
 */
export type CommonalitiesEvents = {
  __typename?: 'CommonalitiesEvents';
  futureEventIds?: Maybe<Array<Maybe<Event>>>;
  pastEventIds?: Maybe<Array<Maybe<Event>>>;
};

/**
 * Represents industries.
 * Contains information about industries in common among users.
 */
export type CommonalitiesIndustries = {
  __typename?: 'CommonalitiesIndustries';
  /** #rest(batched) #virtual */
  currentAToCurrentBIds?: Maybe<Array<Maybe<Industry>>>;
  /** #rest(batched) #virtual */
  currentAToPastBIds?: Maybe<Array<Maybe<Industry>>>;
  /** #rest(batched) #virtual */
  pastAToCurrentBIds?: Maybe<Array<Maybe<Industry>>>;
  /** #rest(batched) #virtual */
  pastAToPastBIds?: Maybe<Array<Maybe<Industry>>>;
};

/**
 * Represents job roles.
 * Contains information about job roles in common among users.
 */
export type CommonalitiesJobRoles = {
  __typename?: 'CommonalitiesJobRoles';
  currentAToCurrentB: Array<Scalars['String']['output']>;
  currentAToPastB: Array<Scalars['String']['output']>;
  pastAToCurrentB: Array<Scalars['String']['output']>;
  pastAToPastB: Array<Scalars['String']['output']>;
};

/** Profile Module Commonalities */
export type CommonalitiesModule = ModuleInterface & {
  __typename?: 'CommonalitiesModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** #rest #virtual */
  commonalities?: Maybe<Commonalities>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

/**
 * Represents skills.
 * Contains information about skills in common among users.
 */
export type CommonalitiesSkills = {
  __typename?: 'CommonalitiesSkills';
  havesAToHavesB: Array<Scalars['String']['output']>;
  havesAToInterestsB: Array<Scalars['String']['output']>;
  havesAToWantsB: Array<Scalars['String']['output']>;
  interestsAToHavesB: Array<Scalars['String']['output']>;
  interestsAToInterestsB: Array<Scalars['String']['output']>;
  interestsAToWantsB: Array<Scalars['String']['output']>;
  wantsAToHavesB: Array<Scalars['String']['output']>;
  wantsAToInterestsB: Array<Scalars['String']['output']>;
  wantsAToWantsB: Array<Scalars['String']['output']>;
};

export type CommunitiesConnection = HasCursorPagination & {
  __typename?: 'CommunitiesConnection';
  /** #logged-out #soft-logged-in */
  edges?: Maybe<Array<Maybe<CommunitiesEdge>>>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type CommunitiesEdge = PaginationEdge & {
  __typename?: 'CommunitiesEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node: Community;
};

export type Community = {
  __typename?: 'Community';
  /** #logged-out #soft-logged-in */
  canonicalUrl?: Maybe<Scalars['URL']['output']>;
  category?: Maybe<CommunityCategory>;
  /** #rest(batched) #virtual */
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  claim?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  commentCount: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  currentMemberState?: Maybe<CommunityMemberState>;
  /** #logged-out #soft-logged-in */
  hasAboutPage: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  hasImprint: Scalars['Boolean']['output'];
  hasNewContent?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  interactions?: Maybe<Array<Maybe<CommunityInteraction>>>;
  /** #logged-out #soft-logged-in */
  isMeetup: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  lastPostDate?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  links?: Maybe<CommunityLinks>;
  /** #logged-out #soft-logged-in */
  location?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  logo: CommunityLogos;
  marketplace?: Maybe<CommunityMarketplace>;
  meetupFrequency?: Maybe<CommunityMeetupFrequency>;
  /** #logged-out #soft-logged-in */
  memberCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  mode: GroupMode;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  owner?: Maybe<XingId>;
  /** #logged-out #soft-logged-in */
  ownerUrn: Scalars['URN']['output'];
  pendingMembersCount?: Maybe<Scalars['Int']['output']>;
  permissions?: Maybe<CommunityPermissions>;
  /** #logged-out #soft-logged-in */
  postCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  type: CommunityStatus;
  unreadPostsCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export const enum CommunityCategory {
  Franchised = 'FRANCHISED',
  OwnedAndOperated = 'OWNED_AND_OPERATED',
  Partnered = 'PARTNERED',
  RegionalAmbassador = 'REGIONAL_AMBASSADOR',
  Themen = 'THEMEN',
  XpertAmbassador = 'XPERT_AMBASSADOR',
}

export type CommunityInteraction = {
  __typename?: 'CommunityInteraction';
  dialog?: Maybe<CommunityInteractionDialog>;
  method?: Maybe<CommunityInterationMethod>;
  name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CommunityInterationType>;
  url?: Maybe<Scalars['URL']['output']>;
};

export type CommunityInteractionDialog = {
  __typename?: 'CommunityInteractionDialog';
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CommunityInterationDialogType>;
};

export const enum CommunityInterationDialogType {
  Confirmation = 'CONFIRMATION',
  Message = 'MESSAGE',
}

export const enum CommunityInterationMethod {
  Delete = 'DELETE',
  Post = 'POST',
}

export const enum CommunityInterationType {
  Primary = 'PRIMARY',
}

export type CommunityLinks = {
  __typename?: 'CommunityLinks';
  /** #logged-out #soft-logged-in */
  public?: Maybe<Scalars['URL']['output']>;
};

export type CommunityLogoUrls = {
  __typename?: 'CommunityLogoUrls';
  /** #logged-out #soft-logged-in */
  large?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  main?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  medium?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  original?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  size96?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  small?: Maybe<Scalars['URL']['output']>;
};

export type CommunityLogos = {
  __typename?: 'CommunityLogos';
  id?: Maybe<Scalars['ID']['output']>;
  meta?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  urls?: Maybe<CommunityLogoUrls>;
};

export type CommunityMarketplace = {
  __typename?: 'CommunityMarketplace';
  isNew?: Maybe<Scalars['Boolean']['output']>;
  unreadClassifiedsCount?: Maybe<Scalars['Int']['output']>;
};

export const enum CommunityMeetupFrequency {
  FourOrFiveTimes = 'FOUR_OR_FIVE_TIMES',
  Once = 'ONCE',
  SixOrMoreTimes = 'SIX_OR_MORE_TIMES',
  TwoOrThreeTimes = 'TWO_OR_THREE_TIMES',
}

export const enum CommunityMemberState {
  Blocked = 'BLOCKED',
  Kicked = 'KICKED',
  Member = 'MEMBER',
  Moderator = 'MODERATOR',
  Owner = 'OWNER',
  Pending = 'PENDING',
}

export const enum CommunityMigrationState {
  ContentMigrationInProgress = 'CONTENT_MIGRATION_IN_PROGRESS',
  Error = 'ERROR',
  GroupDeleted = 'GROUP_DELETED',
  InProgress = 'IN_PROGRESS',
  MigrationCompleted = 'MIGRATION_COMPLETED',
  Pending = 'PENDING',
}

export type CommunityPermissions = {
  __typename?: 'CommunityPermissions';
  canCreatePost?: Maybe<Scalars['Boolean']['output']>;
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  canJoin?: Maybe<Scalars['Boolean']['output']>;
  canRead?: Maybe<Scalars['Boolean']['output']>;
  canReadForums?: Maybe<Scalars['Boolean']['output']>;
  canReadMembers?: Maybe<Scalars['Boolean']['output']>;
  canUpdate?: Maybe<Scalars['Boolean']['output']>;
};

export const enum CommunityStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export const enum CommunityTileStates {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export const enum CommunityTileTypes {
  Imagetile = 'IMAGETILE',
  Pdftile = 'PDFTILE',
  Texttile = 'TEXTTILE',
  Videotile = 'VIDEOTILE',
}

export type CommunityTopic = {
  __typename?: 'CommunityTopic';
  cityId?: Maybe<Scalars['Int']['output']>;
  filterType?: Maybe<Scalars['String']['output']>;
  filterValue?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images: CommunityTopicImages;
  recentlyAdded: Scalars['Boolean']['output'];
  searchText: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CommunityTopicImages = {
  __typename?: 'CommunityTopicImages';
  large1x?: Maybe<Scalars['URL']['output']>;
  large2x?: Maybe<Scalars['URL']['output']>;
  large3x?: Maybe<Scalars['URL']['output']>;
};

export type CommunityTopics = {
  __typename?: 'CommunityTopics';
  collection?: Maybe<Array<Maybe<CommunityTopic>>>;
};

export type CompactTrackingInput = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  added?: InputMaybe<Array<Scalars['String']['input']>>;
  bookmark?: InputMaybe<Array<Scalars['String']['input']>>;
  consumer: Scalars['String']['input'];
  delete?: InputMaybe<Array<Scalars['String']['input']>>;
  messageSent?: InputMaybe<Array<Scalars['String']['input']>>;
  noteAdded?: InputMaybe<Array<Scalars['String']['input']>>;
  select?: InputMaybe<Array<Scalars['String']['input']>>;
  service: Scalars['String']['input'];
  shared?: InputMaybe<Array<Scalars['String']['input']>>;
  show?: InputMaybe<Array<Scalars['String']['input']>>;
  shown?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['ID']['input'];
  view?: InputMaybe<Array<Scalars['String']['input']>>;
  viewed?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CompaniesArticlesCollection = {
  __typename?: 'CompaniesArticlesCollection';
  /** #logged-out #soft-logged-in */
  collection?: Maybe<Array<Maybe<CompanyArticle>>>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type CompaniesByIndustryCollection = {
  __typename?: 'CompaniesByIndustryCollection';
  /** #logged-out #soft-logged-in */
  collection: Array<Maybe<CompanyByIndustry>>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type CompaniesIndustriesCollection = {
  __typename?: 'CompaniesIndustriesCollection';
  /** #expand #logged-out #soft-logged-in */
  collection: Array<Maybe<CompaniesIndustry>>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export const enum CompaniesIndustriesSortOptions {
  Id = 'ID',
  Total = 'TOTAL',
}

export type CompaniesIndustry = {
  __typename?: 'CompaniesIndustry';
  /** #logged-out #soft-logged-in */
  bgColor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  industry?: Maybe<Industry>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export const enum CompaniesRecentlyClaimedOrder {
  IdDesc = 'ID_DESC',
  Random = 'RANDOM',
}

export type CompaniesSegmentsCollection = {
  __typename?: 'CompaniesSegmentsCollection';
  /** #expand #logged-out #soft-logged-in */
  collection: Array<Maybe<CompaniesIndustry>>;
};

export type Company = {
  __typename?: 'Company';
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Shows the aboutUs description text
   */
  aboutUs?: Maybe<AboutEntityResult>;
  /** #logged-out #soft-logged-in */
  address?: Maybe<CompanyAddress>;
  /** #paginated #logged-out #soft-logged-in #rest #virtual */
  affiliates?: Maybe<CompanyAffiliatesConnection>;
  /** #logged-out #soft-logged-in */
  autogenerated: Scalars['Boolean']['output'];
  b2bClientId?: Maybe<Scalars['Int']['output']>;
  commentsEnabled?: Maybe<Scalars['Boolean']['output']>;
  companyInsights?: Maybe<CompanyInsights>;
  /** #logged-out #soft-logged-in */
  companyName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  companySize?: Maybe<Scalars['String']['output']>;
  companySizeId?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  companySizeRange?: Maybe<CompanySizeRange>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  contractPackageName?: Maybe<CompanyContractPackage>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** #rest #virtual */
  demographics?: Maybe<CompanyDemographics>;
  /** #paginated #logged-out #soft-logged-in #rest #virtual */
  employees?: Maybe<CompanyEmployeesConnection>;
  employeesCount?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  entityPage?: Maybe<EntityPage>;
  /** #logged-out #soft-logged-in */
  entityPageId?: Maybe<Scalars['ID']['output']>;
  /**
   * #rest #virtual
   *
   *  Returns a collection with the feeds of the company.
   * They are splitted in two groups, the rss_feeds and the twitter_feeds.
   * If the `rid` is not an editor for the company, then the backing endpoint will
   * generate a 401 error and the resolution will fail.
   */
  feedsSettings?: Maybe<CompanyFeedsSettings>;
  /** #rest #virtual */
  followers?: Maybe<CompanyFollowersCollection>;
  hasXamAccount?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  idealEmployerEnabled?: Maybe<Scalars['Boolean']['output']>;
  inPublicationWorkflow?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  industry?: Maybe<Industry>;
  isMerged?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  jobsCount?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  kununuData?: Maybe<CompanyKununuData>;
  /** #logged-out #soft-logged-in */
  links?: Maybe<CompanyLinks>;
  /** #logged-out #soft-logged-in */
  logos?: Maybe<CompanyLogos>;
  /** #preview */
  notifyAutoMigrated?: Maybe<Scalars['Boolean']['output']>;
  orgTypeId?: Maybe<Scalars['Int']['output']>;
  /** @deprecated please use `contractPackageName` instead as it comes with an ENUM. Checked on 10.03.2023, still used by unkown Web version */
  packageName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  /**
   * #rest #virtual
   *
   *  Tells if company is a child one
   */
  proJobs?: Maybe<CompanyProJobsData>;
  publicProfile?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  relatedCompanies?: Maybe<CompanyRelatedCompaniesCollection>;
  /** #paginated #logged-out #soft-logged-in #rest #virtual */
  reviews?: Maybe<CompanyKununuReviewConnection>;
  slug?: Maybe<Scalars['String']['output']>;
  /** #rest #virtual */
  updateEditors?: Maybe<CompanyUpdateEditorsCollection>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** #rest #virtual */
  updates?: Maybe<CompanyUpdatesCollection>;
  urn?: Maybe<Scalars['GlobalID']['output']>;
  /** #expand */
  userContext?: Maybe<CompanyUserContext>;
  /** #rest #virtual */
  visitors?: Maybe<CompanyVisitorsCollection>;
};

export type CompanyAffiliatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyDemographicsArgs = {
  kpi?: InputMaybe<CompanyDemographicsKpi>;
};

export type CompanyEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: CompanyEmployeesQueryInput;
};

export type CompanyFollowersArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type CompanyReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyUpdateEditorsArgs = {
  filter?: InputMaybe<CompanyUpdateEditorsFilter>;
};

export type CompanyUpdatesArgs = {
  filter?: InputMaybe<CompanyUpdatesFilter>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  order?: InputMaybe<CompanyUpdateOrder>;
};

export type CompanyVisitorsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type CompanyAboutUsFacts = {
  __typename?: 'CompanyAboutUsFacts';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  companyData?: Maybe<Company>;
  /** #logged-out #soft-logged-in */
  foundingYear?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  imprint?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  properties?: Maybe<AboutUsFactsProperties>;
  /** #logged-out #soft-logged-in */
  websiteUrl?: Maybe<Scalars['URL']['output']>;
};

export type CompanyAddress = Addressable & {
  __typename?: 'CompanyAddress';
  /** #logged-out #soft-logged-in */
  city?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  country?: Maybe<Country>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  province?: Maybe<Province>;
  /** #logged-out #soft-logged-in */
  street?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  zip?: Maybe<Scalars['String']['output']>;
};

export type CompanyAffiliate = {
  __typename?: 'CompanyAffiliate';
  /** #logged-out #soft-logged-in */
  affiliateCategory?: Maybe<CompanyAffiliationCategory>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  affiliateCompany?: Maybe<Company>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated This attribute will be removed in favor of categoryId and localization values. WILL BE REMOVED ...
   */
  category: CompanyAffiliateCategory;
};

export const enum CompanyAffiliateCategory {
  Associate = 'ASSOCIATE',
  Client = 'CLIENT',
  Franchise = 'FRANCHISE',
  JointVenture = 'JOINT_VENTURE',
  Parent = 'PARENT',
  Partner = 'PARTNER',
  RegionalBranch = 'REGIONAL_BRANCH',
  ServiceProvider = 'SERVICE_PROVIDER',
  Subsidiary = 'SUBSIDIARY',
}

export type CompanyAffiliatesConnection = HasCursorPagination & {
  __typename?: 'CompanyAffiliatesConnection';
  /** #logged-out #soft-logged-in */
  edges?: Maybe<Array<Maybe<CompanyAffiliatesEdge>>>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type CompanyAffiliatesEdge = PaginationEdge & {
  __typename?: 'CompanyAffiliatesEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node?: Maybe<CompanyAffiliate>;
};

export type CompanyAffiliatesMutationError = {
  __typename?: 'CompanyAffiliatesMutationError';
  companyId?: Maybe<Scalars['ID']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CompanyAffiliatesMutationErrorCollection = {
  __typename?: 'CompanyAffiliatesMutationErrorCollection';
  collection?: Maybe<Array<Maybe<CompanyAffiliatesMutationError>>>;
};

export type CompanyAffiliatesMutationResult = {
  __typename?: 'CompanyAffiliatesMutationResult';
  error?: Maybe<CompanyAffiliatesMutationErrorCollection>;
};

export type CompanyAffiliationCategories = {
  __typename?: 'CompanyAffiliationCategories';
  collection?: Maybe<Array<Maybe<CompanyAffiliationCategoryItem>>>;
};

export type CompanyAffiliationCategory = {
  __typename?: 'CompanyAffiliationCategory';
  id?: Maybe<Scalars['Int']['output']>;
  localizationKey?: Maybe<Scalars['String']['output']>;
  localizationValue?: Maybe<Scalars['String']['output']>;
};

export type CompanyAffiliationCategoryItem = Localizable & {
  __typename?: 'CompanyAffiliationCategoryItem';
  id?: Maybe<Scalars['Int']['output']>;
  localizationKey: Scalars['String']['output'];
  localizationValue: Scalars['String']['output'];
};

export type CompanyAggregations = {
  __typename?: 'CompanyAggregations';
  industry?: Maybe<Array<Maybe<IndustryAggregation>>>;
  offerStatus?: Maybe<Array<Maybe<OfferStatusAggregation>>>;
  size?: Maybe<Array<Maybe<CompanySizeAggregation>>>;
};

/**
 * Available aggregations for company search. It's expecting the number of aggregation to return in a String format.
 * For performance reasons it should never be greater than 20. 10 is a normal value.
 */
export type CompanyAggregationsInput = {
  industry?: InputMaybe<Scalars['String']['input']>;
  offerStatus?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyAnniversaryStory = {
  __typename?: 'CompanyAnniversaryStory';
  actorData?: Maybe<CompanyAnniversaryStoryActorData>;
  actorUrn: Scalars['String']['output'];
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  fumlImages: CompanyAnniversaryStoryImagesByLanguage;
  id: Scalars['ID']['output'];
  objectUrn: Scalars['String']['output'];
  occupationId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  profile?: Maybe<XingId>;
  startpageImages: CompanyAnniversaryStoryImagesByLanguage;
  urn: Scalars['GlobalID']['output'];
  userId: Scalars['ID']['output'];
  years: Scalars['Int']['output'];
};

export type CompanyAnniversaryStoryActorData = {
  __typename?: 'CompanyAnniversaryStoryActorData';
  companyName?: Maybe<Scalars['String']['output']>;
  companyTitle?: Maybe<Scalars['String']['output']>;
};

export type CompanyAnniversaryStoryImagesByLanguage = {
  __typename?: 'CompanyAnniversaryStoryImagesByLanguage';
  de: CompanyAnniversaryStoryImagesBySize;
  en: CompanyAnniversaryStoryImagesBySize;
  es: CompanyAnniversaryStoryImagesBySize;
  fr: CompanyAnniversaryStoryImagesBySize;
  it: CompanyAnniversaryStoryImagesBySize;
};

export type CompanyAnniversaryStoryImagesBySize = {
  __typename?: 'CompanyAnniversaryStoryImagesBySize';
  imageSquare48: Scalars['String']['output'];
  imageSquare64: Scalars['String']['output'];
  imageSquare96: Scalars['String']['output'];
  imageSquare128: Scalars['String']['output'];
  imageSquare192: Scalars['String']['output'];
  imageSquare1024: Scalars['String']['output'];
};

export type CompanyArticle = {
  __typename?: 'CompanyArticle';
  /** #logged-out #soft-logged-in */
  bgColor?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  details?: Maybe<CompanyArticleDetails>;
  /** #logged-out #soft-logged-in */
  objectUrn?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  readingTime?: Maybe<Scalars['Int']['output']>;
};

export type CompanyArticleDetails = {
  __typename?: 'CompanyArticleDetails';
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  image?: Maybe<CompanyArticleImage>;
  /** #logged-out #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type CompanyArticleImage = {
  __typename?: 'CompanyArticleImage';
  /** #logged-out #soft-logged-in */
  srcSquare?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcSquare2x?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcWide?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcWide2x?: Maybe<Scalars['URL']['output']>;
};

export type CompanyByIndustry = {
  __typename?: 'CompanyByIndustry';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  company?: Maybe<Company>;
};

export type CompanyCityAggregation = {
  __typename?: 'CompanyCityAggregation';
  /** #rest(batched) #virtual */
  city?: Maybe<City>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type CompanyClaimed = {
  __typename?: 'CompanyClaimed';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  company?: Maybe<Company>;
};

export type CompanyClaimedCollection = {
  __typename?: 'CompanyClaimedCollection';
  /** #logged-out #soft-logged-in */
  collection: Array<Maybe<CompanyClaimed>>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type CompanyCollection = {
  __typename?: 'CompanyCollection';
  collection?: Maybe<Array<Maybe<Company>>>;
};

export const enum CompanyContractPackage {
  Basic = 'BASIC',
  Ebp = 'EBP',
  None = 'NONE',
  Plus = 'PLUS',
  Standard = 'STANDARD',
}

export type CompanyCreateAffiliatesInput = {
  affiliates?: InputMaybe<Array<InputMaybe<CompanyCreateAffiliatesInputItem>>>;
  id: Scalars['ID']['input'];
};

export type CompanyCreateAffiliatesInputItem = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  companyId: Scalars['ID']['input'];
};

export type CompanyCreateFeedError = {
  __typename?: 'CompanyCreateFeedError';
  localizedMessage?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CompanyCreateFeedErrorType>;
};

export const enum CompanyCreateFeedErrorType {
  RssFeedNotValid = 'RSS_FEED_NOT_VALID',
  RssUrlInvalid = 'RSS_URL_INVALID',
  TwitterFeedNotValid = 'TWITTER_FEED_NOT_VALID',
  TwitterTagInvalid = 'TWITTER_TAG_INVALID',
}

export type CompanyCreateFeedInput = {
  companyId: Scalars['SlugOrID']['input'];
  feedType: CompanyFeedType;
  source: Scalars['String']['input'];
};

export type CompanyCreateFeedResult = {
  __typename?: 'CompanyCreateFeedResult';
  error?: Maybe<CompanyCreateFeedError>;
  success?: Maybe<CompanyFeed>;
};

export type CompanyDefaultLogos = {
  __typename?: 'CompanyDefaultLogos';
  /** #logged-out #soft-logged-in */
  imagecacheUuid?: Maybe<Scalars['UUID']['output']>;
  /** #logged-out #soft-logged-in */
  logo32px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo48px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo64px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo96px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo128px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo192px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo256px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo512px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo1024px?: Maybe<Scalars['URL']['output']>;
};

export type CompanyDeleteFeedError = {
  __typename?: 'CompanyDeleteFeedError';
  message?: Maybe<Scalars['String']['output']>;
};

export type CompanyDeleteFeedInput = {
  companyId: Scalars['SlugOrID']['input'];
  feedId: Scalars['ID']['input'];
  feedType: CompanyFeedType;
};

export type CompanyDeleteFeedResult = {
  __typename?: 'CompanyDeleteFeedResult';
  error?: Maybe<CompanyDeleteFeedError>;
};

export type CompanyDemographics = {
  __typename?: 'CompanyDemographics';
  byAge?: Maybe<Array<Maybe<AgeGroupElement>>>;
  byCareerLevel?: Maybe<Array<Maybe<CareerLevelElement>>>;
  byTopCompanies?: Maybe<Array<Maybe<TopCompaniesElement>>>;
  byTopIndustries?: Maybe<Array<Maybe<TopIndustriesElement>>>;
};

export const enum CompanyDemographicsKpi {
  Followers = 'FOLLOWERS',
  Visitors = 'VISITORS',
}

export type CompanyEmployeesConnection = HasCursorPagination & {
  __typename?: 'CompanyEmployeesConnection';
  /** #logged-out #soft-logged-in */
  edges?: Maybe<Array<Maybe<CompanyEmployeesEdge>>>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export const enum CompanyEmployeesContactLevel {
  Connection_1 = 'CONNECTION_1',
  Connection_2 = 'CONNECTION_2',
  Connection_2_1 = 'CONNECTION_2_1',
  ConnectionNone = 'CONNECTION_NONE',
}

export type CompanyEmployeesEdge = PaginationEdge & {
  __typename?: 'CompanyEmployeesEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node?: Maybe<Employee>;
};

export type CompanyEmployeesFacetsInput = {
  /**
   * city: Maximum number of distinct values to be returned by this aggregation
   * discipline: Maximum number of distinct values to be returned by this aggregation.
   * lastNameInitial: Maximum number of distinct values to be returned by this aggregation.
   * level: Maximum number of distinct values to be returned by this aggregation.
   */
  city?: InputMaybe<Scalars['Int']['input']>;
  discipline?: InputMaybe<Scalars['Int']['input']>;
  lastNameInitial?: InputMaybe<Scalars['Int']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyEmployeesFiltersInput = {
  cityCode?: InputMaybe<Scalars['ID']['input']>;
  contactLevel?: InputMaybe<CompanyEmployeesContactLevel>;
  disciplineId?: InputMaybe<Scalars['ID']['input']>;
  hasPhoto?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lastNameInitial?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['ID']['input']>;
};

export type CompanyEmployeesNumber = Localizable & {
  __typename?: 'CompanyEmployeesNumber';
  /** #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export type CompanyEmployeesQueryInput = {
  consumer: Scalars['String']['input'];
  facets?: InputMaybe<CompanyEmployeesFacetsInput>;
  filters?: InputMaybe<CompanyEmployeesFiltersInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<CompanyEmployeesSort>;
  timeout?: InputMaybe<Scalars['Int']['input']>;
};

export const enum CompanyEmployeesSort {
  ConnectionDegree = 'CONNECTION_DEGREE',
  LastName = 'LAST_NAME',
}

export type CompanyFeed = {
  id: Scalars['ID']['output'];
  source: Scalars['String']['output'];
};

export const enum CompanyFeedType {
  Rss = 'RSS',
  Twitter = 'TWITTER',
}

export type CompanyFeedsSettings = {
  __typename?: 'CompanyFeedsSettings';
  rssFeeds?: Maybe<Array<Maybe<CompanyRssFeed>>>;
  twitterFeeds?: Maybe<Array<Maybe<CompanyTwitterFeed>>>;
};

/** Available filters for company search. If there is no special indication even if the type is a String it's waiting for the key for filtering (sometimes a string code and others and int). The possible values for each filter are the one listed as values in the corresponding aggregation */
export type CompanyFiltersInput = {
  /** industry should be a valid id for type Industry */
  industry?: InputMaybe<Scalars['String']['input']>;
  /** location can be the id of a city, a province or a country */
  location?: InputMaybe<Scalars['String']['input']>;
  /** This filter companies with or without vacancies */
  offerStatus?: InputMaybe<CompanyOffers>;
  /** size should be a valid id for CompanyEmployeesNumber */
  size?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyFollowState = {
  __typename?: 'CompanyFollowState';
  isFollowing?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Returns a collection with the followers for the parent company.
 *
 * If the `rid` is not an editor for the company, then the backing endpoint will generate a 403 error and the resolution will fail.
 */
export type CompanyFollowersCollection = {
  __typename?: 'CompanyFollowersCollection';
  total: Scalars['Int']['output'];
};

export type CompanyIdAggregation = HasAggregationCount & {
  __typename?: 'CompanyIdAggregation';
  /** #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #soft-logged-in #rest(batched) #virtual */
  label?: Maybe<Company>;
  /** #soft-logged-in */
  value?: Maybe<Scalars['Int']['output']>;
};

export type CompanyInsights = {
  __typename?: 'CompanyInsights';
  followers: CompanyInsightsFollowers;
  idealEmployers: CompanyInsightsIdealEmployers;
  /** #rest #virtual */
  report?: Maybe<CompanyInsightsReport>;
  visitors: CompanyInsightsVisitors;
};

export type CompanyInsightsReportArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  timePeriod: CompanyInsightsTimePeriodInput;
};

export type CompanyInsightsFollowers = {
  __typename?: 'CompanyInsightsFollowers';
  /** #rest #virtual */
  histogram?: Maybe<CompanyInsightsKpiHistogram>;
  /** #rest #virtual */
  totals?: Maybe<CompanyInsightsKpiTotals>;
};

export type CompanyInsightsFollowersHistogramArgs = {
  granularity: CompanyInsightsGranularity;
  timePeriod: CompanyInsightsTimePeriodInput;
};

export type CompanyInsightsFollowersTotalsArgs = {
  timePeriod: CompanyInsightsTimePeriodInput;
};

export const enum CompanyInsightsGranularity {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type CompanyInsightsIdealEmployers = {
  __typename?: 'CompanyInsightsIdealEmployers';
  /** #rest #virtual */
  histogram?: Maybe<CompanyInsightsKpiHistogram>;
  /** #rest #virtual */
  totals?: Maybe<CompanyInsightsKpiTotals>;
};

export type CompanyInsightsIdealEmployersHistogramArgs = {
  granularity: CompanyInsightsGranularity;
  timePeriod: CompanyInsightsTimePeriodInput;
};

export type CompanyInsightsIdealEmployersTotalsArgs = {
  timePeriod: CompanyInsightsTimePeriodInput;
};

export type CompanyInsightsKpiHistogram = {
  __typename?: 'CompanyInsightsKpiHistogram';
  collection: Array<CompanyInsightsKpiHistogramEntry>;
  granularity: CompanyInsightsGranularity;
};

export type CompanyInsightsKpiHistogramEntry = {
  __typename?: 'CompanyInsightsKpiHistogramEntry';
  added: Scalars['Int']['output'];
  removed: Scalars['Int']['output'];
  timestamp: Scalars['Date']['output'];
  total: Scalars['Int']['output'];
};

export type CompanyInsightsKpiTotals = {
  __typename?: 'CompanyInsightsKpiTotals';
  overallTotal: Scalars['Int']['output'];
  periodTotal: Scalars['Int']['output'];
};

export type CompanyInsightsReport = {
  __typename?: 'CompanyInsightsReport';
  zipFile: Scalars['String']['output'];
  zipName: Scalars['String']['output'];
};

export type CompanyInsightsTimePeriodInput = {
  /** An iso8601 encoded UTC datetime, it can't have a + sign */
  from: Scalars['Date']['input'];
  /** An iso8601 encoded UTC datetime, it can't have a + sign */
  to: Scalars['Date']['input'];
};

export type CompanyInsightsVisitorReferer = {
  __typename?: 'CompanyInsightsVisitorReferer';
  collection?: Maybe<Array<CompanyInsightsVisitorRefererValue>>;
  total: Scalars['Int']['output'];
};

export type CompanyInsightsVisitorRefererValue = {
  __typename?: 'CompanyInsightsVisitorRefererValue';
  percentage: Scalars['Float']['output'];
  referer: Scalars['String']['output'];
};

export type CompanyInsightsVisitorReferers = {
  __typename?: 'CompanyInsightsVisitorReferers';
  other: CompanyInsightsVisitorReferer;
  searchEngines: CompanyInsightsVisitorReferer;
  websiteLinks: CompanyInsightsVisitorReferer;
  xing: CompanyInsightsVisitorReferer;
};

export type CompanyInsightsVisitors = {
  __typename?: 'CompanyInsightsVisitors';
  /** #rest #virtual */
  histogram?: Maybe<CompanyInsightsVisitorsHistogram>;
  /** #rest #virtual */
  referers?: Maybe<CompanyInsightsVisitorReferers>;
  /** #rest #virtual */
  totals?: Maybe<CompanyInsightsKpiTotals>;
};

export type CompanyInsightsVisitorsHistogramArgs = {
  granularity: CompanyInsightsGranularity;
  onlyLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  onlyLoggedOut?: InputMaybe<Scalars['Boolean']['input']>;
  timePeriod: CompanyInsightsTimePeriodInput;
};

export type CompanyInsightsVisitorsReferersArgs = {
  onlyLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  onlyLoggedOut?: InputMaybe<Scalars['Boolean']['input']>;
  timePeriod: CompanyInsightsTimePeriodInput;
  topReferersLimit: Scalars['Int']['input'];
};

export type CompanyInsightsVisitorsTotalsArgs = {
  onlyLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  onlyLoggedOut?: InputMaybe<Scalars['Boolean']['input']>;
  timePeriod: CompanyInsightsTimePeriodInput;
};

export type CompanyInsightsVisitorsHistogram = {
  __typename?: 'CompanyInsightsVisitorsHistogram';
  collection: Array<CompanyInsightsVisitorsHistogramEntry>;
  granularity: CompanyInsightsGranularity;
};

export type CompanyInsightsVisitorsHistogramEntry = {
  __typename?: 'CompanyInsightsVisitorsHistogramEntry';
  organic: Scalars['Int']['output'];
  promotions: Scalars['Int']['output'];
  timestamp: Scalars['Date']['output'];
  total: Scalars['Int']['output'];
};

export type CompanyKununuBadge = {
  __typename?: 'CompanyKununuBadge';
  /** #logged-out #soft-logged-in */
  awardUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in #virtual */
  image?: Maybe<Array<ScaledImage>>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
};

export type CompanyKununuBadgeImageArgs = {
  dimensions: Array<ScaledImageDimension>;
};

export type CompanyKununuCulture = {
  __typename?: 'CompanyKununuCulture';
  /** #logged-out #soft-logged-in */
  completedSubmissionsCount?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  cultureCompass?: Maybe<CompanyKununuCultureDimension>;
  /** #logged-out #soft-logged-in */
  dimensions?: Maybe<CompanyKununuCultureDimensions>;
  /** #logged-out #soft-logged-in */
  link?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CompanyKununuCultureDimension = {
  __typename?: 'CompanyKununuCultureDimension';
  /** #logged-out #soft-logged-in */
  industry?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  profile?: Maybe<Scalars['Int']['output']>;
};

export type CompanyKununuCultureDimensions = {
  __typename?: 'CompanyKununuCultureDimensions';
  /** #logged-out #soft-logged-in */
  leadership?: Maybe<CompanyKununuCultureDimension>;
  /** #logged-out #soft-logged-in */
  strategicDirection?: Maybe<CompanyKununuCultureDimension>;
  /** #logged-out #soft-logged-in */
  workingTogether?: Maybe<CompanyKununuCultureDimension>;
  /** #logged-out #soft-logged-in */
  worklifeBalance?: Maybe<CompanyKununuCultureDimension>;
};

export type CompanyKununuData = {
  __typename?: 'CompanyKununuData';
  /** #logged-out #soft-logged-in */
  badges?: Maybe<Array<Maybe<CompanyKununuBadge>>>;
  /** #logged-out #soft-logged-in */
  badgesCount?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  companyProfileUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  culture?: Maybe<CompanyKununuCulture>;
  /** #logged-out #soft-logged-in */
  employerRatingsCount?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  evaluateProfileUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  kununuCommentsUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  mappedBenefits?: Maybe<Array<Maybe<CompanyMappedBenefit>>>;
  /** #logged-out #soft-logged-in */
  profileUuid?: Maybe<Scalars['UUID']['output']>;
  /** #logged-out #soft-logged-in */
  ratingAverage?: Maybe<Scalars['Float']['output']>;
  /** #logged-out #soft-logged-in */
  ratingCount?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  recommendationRate?: Maybe<Scalars['Float']['output']>;
  /** #logged-out #soft-logged-in */
  reviewProfileUrl?: Maybe<Scalars['URL']['output']>;
};

export type CompanyKununuReview = {
  __typename?: 'CompanyKununuReview';
  /** #logged-out #soft-logged-in */
  created?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  jobStatus?: Maybe<CompanyKununuReviewJobStatus>;
  /** #logged-out #soft-logged-in */
  ratingAvg?: Maybe<Scalars['Float']['output']>;
  /** #logged-out #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type CompanyKununuReviewConnection = HasCursorPagination & {
  __typename?: 'CompanyKununuReviewConnection';
  /** #logged-out #soft-logged-in */
  edges: Array<Maybe<CompanyKununuReviewEdge>>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type CompanyKununuReviewEdge = PaginationEdge & {
  __typename?: 'CompanyKununuReviewEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node?: Maybe<CompanyKununuReview>;
};

export const enum CompanyKununuReviewJobStatus {
  Current = 'CURRENT',
  Internship = 'INTERNSHIP',
  NotSpecified = 'NOT_SPECIFIED',
  Previous = 'PREVIOUS',
}

export type CompanyLinks = {
  __typename?: 'CompanyLinks';
  default?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  public?: Maybe<Scalars['URL']['output']>;
};

export type CompanyLogos = {
  __typename?: 'CompanyLogos';
  /** #logged-out #soft-logged-in */
  imagecacheUuid?: Maybe<Scalars['UUID']['output']>;
  /** #logged-out #soft-logged-in */
  logo32px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo48px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo64px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo96px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo128px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo192px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo256px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo512px?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  logo1024px?: Maybe<Scalars['URL']['output']>;
};

export type CompanyMappedBenefit = {
  __typename?: 'CompanyMappedBenefit';
  /** #logged-out #soft-logged-in */
  approvals: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  percentage?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  type: CompanyMappedBenefitType;
};

export const enum CompanyMappedBenefitType {
  Accessibility = 'ACCESSIBILITY',
  Canteen = 'CANTEEN',
  CarPark = 'CAR_PARK',
  Childcare = 'CHILDCARE',
  CompanyCar = 'COMPANY_CAR',
  CompanyDoctor = 'COMPANY_DOCTOR',
  CompanyPension = 'COMPANY_PENSION',
  ConvenientTransportLinks = 'CONVENIENT_TRANSPORT_LINKS',
  DiscountsSpecialOffers = 'DISCOUNTS_SPECIAL_OFFERS',
  DogsWelcome = 'DOGS_WELCOME',
  EventsForEmployees = 'EVENTS_FOR_EMPLOYEES',
  Flexitime = 'FLEXITIME',
  HealthInTheWorkplace = 'HEALTH_IN_THE_WORKPLACE',
  HomeOffice = 'HOME_OFFICE',
  MobileDevice = 'MOBILE_DEVICE',
  PrivateInternetUse = 'PRIVATE_INTERNET_USE',
  ProfitSharing = 'PROFIT_SHARING',
  RestaurantTickets = 'RESTAURANT_TICKETS',
  Training = 'TRAINING',
}

export const enum CompanyMembership {
  /** Only users who currently work in the posting's company. */
  Current = 'CURRENT',
  /** Users who formerly worked in the posting's company, and they no longer work there. */
  Previous = 'PREVIOUS',
}

export const enum CompanyOffers {
  WithoutPostings = 'WITHOUT_POSTINGS',
  WithPostings = 'WITH_POSTINGS',
}

export type CompanyPageCreateError = {
  __typename?: 'CompanyPageCreateError';
  errorCode: Scalars['Int']['output'];
  errorType: Scalars['String']['output'];
  errorsList?: Maybe<Array<Maybe<CompanyPageCreateErrorDetail>>>;
};

export type CompanyPageCreateErrorDetail = Localizable & {
  __typename?: 'CompanyPageCreateErrorDetail';
  field: Scalars['String']['output'];
  localizationKey: Scalars['String']['output'];
  localizationValue: Scalars['String']['output'];
};

export type CompanyPageCreateInput = {
  companyName: Scalars['String']['input'];
  industryId: Scalars['ID']['input'];
  sizeId: Scalars['ID']['input'];
};

export type CompanyPageCreatePayload = {
  __typename?: 'CompanyPageCreatePayload';
  error?: Maybe<CompanyPageCreateError>;
  success?: Maybe<CompanyPageCreateSuccess>;
};

export type CompanyPageCreateSuccess = {
  __typename?: 'CompanyPageCreateSuccess';
  entityPageId?: Maybe<Scalars['ID']['output']>;
};

export type CompanyPreviewMetadata = PreviewMetadata & {
  __typename?: 'CompanyPreviewMetadata';
  sourceActor?: Maybe<LinkPreviewSourceActor>;
  urn: Scalars['GlobalID']['output'];
};

export type CompanyProJobsData = {
  __typename?: 'CompanyProJobsData';
  idealEmployer?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyProfileInsight = {
  __typename?: 'CompanyProfileInsight';
  employeesRange: Scalars['String']['output'];
  industry?: Maybe<IndustryInsight>;
  kununuRatingAverage?: Maybe<Scalars['Float']['output']>;
  kununuRatingCount?: Maybe<Scalars['Int']['output']>;
  logoUrl: Scalars['URL']['output'];
  name: Scalars['String']['output'];
  publicUrl: Scalars['URL']['output'];
  tag: Scalars['String']['output'];
};

/** Available queries for company search. The difference between queries and filters is that queries is a free text search */
export type CompanyQueriesInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyRecommendation = {
  __typename?: 'CompanyRecommendation';
  /** #rest(batched) #virtual */
  company?: Maybe<Company>;
  dismissible?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<LocalizedCompanyRecommendationReason>;
  reasonCode?: Maybe<Scalars['String']['output']>;
  recommendationContacts?: Maybe<RecommendationContactsCollection>;
  recommendationTracking?: Maybe<Scalars['String']['output']>;
};

export type CompanyRecommendationConnection = HasCursorPagination & {
  __typename?: 'CompanyRecommendationConnection';
  edges?: Maybe<Array<Maybe<CompanyRecommendationEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type CompanyRecommendationEdge = PaginationEdge & {
  __typename?: 'CompanyRecommendationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CompanyRecommendation>;
};

export type CompanyRelatedCompaniesCollection = {
  __typename?: 'CompanyRelatedCompaniesCollection';
  /** #logged-out #soft-logged-in */
  collection?: Maybe<Array<Maybe<RelatedCompany>>>;
};

export type CompanyRssFeed = CompanyFeed & {
  __typename?: 'CompanyRssFeed';
  id: Scalars['ID']['output'];
  source: Scalars['String']['output'];
};

export type CompanySearchConnection = HasCursorPagination & {
  __typename?: 'CompanySearchConnection';
  aggs?: Maybe<CompanyAggregations>;
  edges: Array<CompanySearchEdge>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type CompanySearchEdge = PaginationEdge & {
  __typename?: 'CompanySearchEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node?: Maybe<UniversalSearchCompanyResult>;
};

export type CompanySizeAggregation = HasAggregationCount & {
  __typename?: 'CompanySizeAggregation';
  count: Scalars['Int']['output'];
  /** #rest(batched) #virtual */
  label?: Maybe<CompanyEmployeesNumber>;
  value: Scalars['Int']['output'];
};

export type CompanySizeRange = {
  __typename?: 'CompanySizeRange';
  /** #logged-out #soft-logged-in */
  max?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  min: Scalars['Int']['output'];
};

export type CompanyTwitterFeed = CompanyFeed & {
  __typename?: 'CompanyTwitterFeed';
  id: Scalars['ID']['output'];
  source: Scalars['String']['output'];
};

export type CompanyUpdate = {
  __typename?: 'CompanyUpdate';
  activityStreamId?: Maybe<Scalars['ID']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  body_html?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** #rest(batched) #virtual */
  interactions?: Maybe<CompanyUpdateInteractions>;
  links?: Maybe<CompanyUpdateLinks>;
  /** #rest(batched) #virtual */
  poster?: Maybe<XingId>;
  /** #rest(batched) #virtual */
  promotions?: Maybe<CompanyUpdateAds>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  urlDescription?: Maybe<Scalars['URL']['output']>;
  urlProvider?: Maybe<Scalars['URL']['output']>;
  urn: Scalars['GlobalID']['output'];
};

export type CompanyUpdateAd = {
  __typename?: 'CompanyUpdateAd';
  adStatus?: Maybe<Scalars['String']['output']>;
  adType?: Maybe<Scalars['String']['output']>;
  adUrl?: Maybe<Scalars['URL']['output']>;
  campaignId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Deprecated in favour of campaignId attribute */
  customerLightCampaignId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  startAt?: Maybe<Scalars['Date']['output']>;
  stats?: Maybe<CompanyUpdateAdStatistics>;
  /** @deprecated Deprecated in favour of the plain string adStatus attribute */
  status?: Maybe<CompanyUpdateAdStatus>;
};

export type CompanyUpdateAdStatistics = {
  __typename?: 'CompanyUpdateAdStatistics';
  budget?: Maybe<Scalars['String']['output']>;
  clicks?: Maybe<Scalars['Int']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  totalSpend?: Maybe<Scalars['String']['output']>;
};

export const enum CompanyUpdateAdStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Paused = 'PAUSED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Scheduled = 'SCHEDULED',
  TakenDown = 'TAKEN_DOWN',
  TakenDownCompletely = 'TAKEN_DOWN_COMPLETELY',
  Terminated = 'TERMINATED',
}

export type CompanyUpdateAds = {
  __typename?: 'CompanyUpdateAds';
  ads?: Maybe<Array<Maybe<CompanyUpdateAd>>>;
};

/**
 * The update editors of a company, ie: the editors of a company that
 * have posted at least one update in the company profile.
 */
export type CompanyUpdateEditor = {
  __typename?: 'CompanyUpdateEditor';
  /** #rest(batched) #virtual */
  profile?: Maybe<XingId>;
  totalUpdates: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
  userUrn: Scalars['GlobalID']['output'];
};

export type CompanyUpdateEditorsCollection = {
  __typename?: 'CompanyUpdateEditorsCollection';
  collection?: Maybe<Array<Maybe<CompanyUpdateEditor>>>;
};

export type CompanyUpdateEditorsFilter = {
  limitByPerformanceData?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<Scalars['String']['input']>;
  withPromotions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyUpdateInformationError = {
  __typename?: 'CompanyUpdateInformationError';
  localizedMessage?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CompanyUpdateInformationErrorType>;
};

export const enum CompanyUpdateInformationErrorType {
  SlugInvalid = 'SLUG_INVALID',
}

export type CompanyUpdateInformationInput = {
  commentsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  companyId: Scalars['ID']['input'];
  idealEmployerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  industryId?: InputMaybe<Scalars['ID']['input']>;
  publicProfile?: InputMaybe<Scalars['Boolean']['input']>;
  sizeId?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyUpdateInformationPayload = {
  __typename?: 'CompanyUpdateInformationPayload';
  error?: Maybe<CompanyUpdateInformationError>;
  success?: Maybe<Company>;
};

export type CompanyUpdateInteractions = {
  __typename?: 'CompanyUpdateInteractions';
  click?: Maybe<CompanyUpdateInteractionsDetails>;
  comment?: Maybe<CompanyUpdateInteractionsDetails>;
  impression?: Maybe<CompanyUpdateInteractionsDetails>;
  like?: Maybe<CompanyUpdateInteractionsDetails>;
  share?: Maybe<CompanyUpdateInteractionsDetails>;
  view?: Maybe<CompanyUpdateInteractionsDetails>;
};

export type CompanyUpdateInteractionsDetails = {
  __typename?: 'CompanyUpdateInteractionsDetails';
  aggregatedQty?: Maybe<Scalars['Int']['output']>;
};

export type CompanyUpdateLinks = {
  __typename?: 'CompanyUpdateLinks';
  company?: Maybe<Scalars['URL']['output']>;
  image?: Maybe<Scalars['URL']['output']>;
  public?: Maybe<Scalars['URL']['output']>;
  self?: Maybe<Scalars['URL']['output']>;
  thumbnail?: Maybe<Scalars['URL']['output']>;
};

export const enum CompanyUpdateOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

/**
 * Returns a collection with the updates of the company.
 *
 * If the `rid` is not an editor for the company, then the backing endpoint will generate a 403 error and the resolution will fail.
 */
export type CompanyUpdatesCollection = {
  __typename?: 'CompanyUpdatesCollection';
  collection?: Maybe<Array<Maybe<CompanyUpdate>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CompanyUpdatesDeleteError = {
  __typename?: 'CompanyUpdatesDeleteError';
  message?: Maybe<Scalars['String']['output']>;
};

export type CompanyUpdatesDeleteInput = {
  companyId: Scalars['ID']['input'];
  updateId: Scalars['ID']['input'];
};

export type CompanyUpdatesDeleteResult = {
  __typename?: 'CompanyUpdatesDeleteResult';
  error?: Maybe<CompanyUpdatesDeleteError>;
};

export type CompanyUpdatesFilter = {
  editors?: InputMaybe<Scalars['String']['input']>;
  includePages?: InputMaybe<Scalars['Boolean']['input']>;
  limitByPerformanceData?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<Scalars['String']['input']>;
  withPromotions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyUserContext = {
  __typename?: 'CompanyUserContext';
  /** #rest(batched) #virtual */
  followState?: Maybe<CompanyFollowState>;
  userRole?: Maybe<CompanyUserRole>;
};

export const enum CompanyUserRole {
  AdditionalAdmin = 'ADDITIONAL_ADMIN',
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  XingCsAdmin = 'XING_CS_ADMIN',
}

export type CompanyVisitor = {
  __typename?: 'CompanyVisitor';
  /** #rest(batched) #virtual */
  contactDistance?: Maybe<ContactDistance>;
  lastVisitedAt: Scalars['Date']['output'];
  /** #rest(batched) #virtual */
  networkRelationship?: Maybe<NetworkRelationship>;
  /** #rest(batched) #virtual */
  profileDetails?: Maybe<XingId>;
  /** #rest(batched) #virtual */
  sharedContacts?: Maybe<SharedContacts>;
  userId: Scalars['ID']['output'];
  visitCount: Scalars['Int']['output'];
};

/**
 * Returns a collection with the visitors of the parent company.
 *
 * If the `rid` is not an editor for the company, then the backing endpoint will generate a 403 error and the resolution will fail.
 */
export type CompanyVisitorsCollection = {
  __typename?: 'CompanyVisitorsCollection';
  collection?: Maybe<Array<Maybe<CompanyVisitor>>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ComplaintReason = {
  __typename?: 'ComplaintReason';
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  key?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  needsUserComment?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type ComplaintsCreateContentReportError = {
  __typename?: 'ComplaintsCreateContentReportError';
  /** #logged-out #soft-logged-in */
  targetSnapshot?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ComplaintsCreateContentReportErrors = {
  __typename?: 'ComplaintsCreateContentReportErrors';
  /** #logged-out #soft-logged-in */
  errors?: Maybe<ComplaintsCreateContentReportError>;
};

export type ComplaintsCreateContentReportInput = {
  authorUrn?: InputMaybe<Scalars['GlobalID']['input']>;
  targetUrn: Scalars['GlobalID']['input'];
};

export type ComplaintsCreateContentReportResult = {
  __typename?: 'ComplaintsCreateContentReportResult';
  /** #logged-out #soft-logged-in */
  error?: Maybe<ComplaintsCreateContentReportErrors>;
  /** #logged-out #soft-logged-in */
  success?: Maybe<ComplaintsCreateContentReportSuccess>;
};

export type ComplaintsCreateContentReportSuccess = {
  __typename?: 'ComplaintsCreateContentReportSuccess';
  /** #logged-out #soft-logged-in */
  report?: Maybe<ComplaintsReport>;
};

export type ComplaintsCreateProfileReportError = {
  __typename?: 'ComplaintsCreateProfileReportError';
  targetSnapshot?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ComplaintsCreateProfileReportErrors = {
  __typename?: 'ComplaintsCreateProfileReportErrors';
  errors?: Maybe<ComplaintsCreateProfileReportError>;
};

export type ComplaintsCreateProfileReportInput = {
  id: Scalars['ID']['input'];
};

export type ComplaintsCreateProfileReportResult = {
  __typename?: 'ComplaintsCreateProfileReportResult';
  error?: Maybe<ComplaintsCreateProfileReportErrors>;
  success?: Maybe<ComplaintsCreateProfileReportSuccess>;
  /**
   * #rest
   *
   *  XingIdModule of the user added as a lead
   */
  xingIdModule?: Maybe<XingIdModule>;
};

export type ComplaintsCreateProfileReportResultXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

export type ComplaintsCreateProfileReportSuccess = {
  __typename?: 'ComplaintsCreateProfileReportSuccess';
  report?: Maybe<ComplaintsReport>;
};

export type ComplaintsReport = {
  __typename?: 'ComplaintsReport';
  /** #logged-out #soft-logged-in */
  authorUrn?: Maybe<Scalars['GlobalID']['output']>;
  /** #logged-out #soft-logged-in */
  completed?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  loggedInReporter?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  reasonKey?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  reporterUrn?: Maybe<Scalars['GlobalID']['output']>;
  /** #logged-out #soft-logged-in */
  targetUrn?: Maybe<Scalars['GlobalID']['output']>;
  /** #logged-out #soft-logged-in */
  userComment?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  userReverted?: Maybe<Scalars['Boolean']['output']>;
};

export type ComplaintsReportInput = {
  id: Scalars['ID']['input'];
};

export type CompleteReport403Error = {
  __typename?: 'CompleteReport403Error';
  /** #logged-out #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
};

export type CompleteReportResponse = {
  __typename?: 'CompleteReportResponse';
  /** #logged-out #soft-logged-in */
  report?: Maybe<ComplaintsReport>;
};

export type CompleteReportResult =
  | CompleteReport403Error
  | CompleteReportResponse;

export type ConfirmEmailReport403Error = {
  __typename?: 'ConfirmEmailReport403Error';
  /** #logged-out #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
};

export type ConfirmEmailReport404Error = {
  __typename?: 'ConfirmEmailReport404Error';
  /** #logged-out #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type ConfirmEmailReportInput = {
  id: Scalars['ID']['input'];
  reporterEmail: Scalars['String']['input'];
};

export type ConfirmEmailReportResult =
  | ConfirmEmailReport403Error
  | ConfirmEmailReport404Error
  | ConfirmEmailReportSuccess;

export type ConfirmEmailReportSuccess = {
  __typename?: 'ConfirmEmailReportSuccess';
  /** #logged-out #soft-logged-in */
  success: Scalars['Boolean']['output'];
};

export type ConnectionLevelResponse = {
  __typename?: 'ConnectionLevelResponse';
  /** #soft-logged-in */
  lowConnected?: Maybe<Scalars['Boolean']['output']>;
};

export type Contact = {
  email?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  option?: InputMaybe<Scalars['String']['input']>;
  recaptcha?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['String']['input']>;
};

export type ContactDistance = {
  __typename?: 'ContactDistance';
  /**
   * #soft-logged-in
   *
   *  The distance itself, possible values are 0, 1 and 2
   * 0: no relation exists between users
   * 1: the user is a first degree contact (direct contact) to the caller of the query
   * 2: the user is a second degree contact (connected through a common contact) to the caller of the query
   */
  distance: Scalars['Int']['output'];
  /**
   * #soft-logged-in
   *
   *  Id of the user we are querying the distance between them and the current user
   */
  userId: Scalars['ID']['output'];
};

export type ContactError = {
  __typename?: 'ContactError';
  /** #logged-out #soft-logged-in */
  errors?: Maybe<ContactErrorMessages>;
};

export type ContactErrorMessages = {
  __typename?: 'ContactErrorMessages';
  /** #logged-out #soft-logged-in */
  email?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  files?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  option?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  recaptcha?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  subject?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  topic?: Maybe<Scalars['String']['output']>;
};

export type ContactPositiveResponse = {
  __typename?: 'ContactPositiveResponse';
  /** #logged-out #soft-logged-in */
  emailAttached?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactPostResult = ContactError | ContactPositiveResponse;

export type ContactRecommendationSignal = NetworkUpdatesStackItem & {
  __typename?: 'ContactRecommendationSignal';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<PeopleStoryGeneratorMymkRecommendation>;
  /** #rest(batched) #virtual */
  sharedContacts?: Maybe<SharedContacts>;
  stackId: Scalars['ID']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type ContactRecommendationStack = SignalsStack & {
  __typename?: 'ContactRecommendationStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type ContactRecommendationStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactRequestConversationStarter = ConversationStarter & {
  __typename?: 'ContactRequestConversationStarter';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type ContactRequestReceived = {
  __typename?: 'ContactRequestReceived';
  /** #soft-logged-in */
  createdAt: Scalars['Date']['output'];
  /** #soft-logged-in */
  createdAtWithTimezone: Scalars['Date']['output'];
  /** #soft-logged-in */
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  sharedContacts?: Maybe<SharedContacts>;
  /** #soft-logged-in #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type ContactRequestSent = {
  __typename?: 'ContactRequestSent';
  /** #soft-logged-in */
  createdAt: Scalars['Date']['output'];
  /** #soft-logged-in */
  createdAtWithTimezone: Scalars['Date']['output'];
  /** #soft-logged-in */
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  recipientId?: Maybe<Scalars['ID']['output']>;
  /** #soft-logged-in #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type ContactRequestSentEdge = PaginationEdge & {
  __typename?: 'ContactRequestSentEdge';
  cursor: Scalars['String']['output'];
  node: ContactRequestSent;
};

export type ContactRequestStack = SignalsStack & {
  __typename?: 'ContactRequestStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type ContactRequestStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactRequestsConfirmError = {
  __typename?: 'ContactRequestsConfirmError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ContactRequestsConfirmInput = {
  otherId: Scalars['ID']['input'];
  requestAnswer?: InputMaybe<Scalars['String']['input']>;
};

export type ContactRequestsConfirmResult = {
  __typename?: 'ContactRequestsConfirmResult';
  error?: Maybe<ContactRequestsConfirmError>;
};

export type ContactRequestsDeclineError = {
  __typename?: 'ContactRequestsDeclineError';
  message: Scalars['String']['output'];
};

export type ContactRequestsDeclineInput = {
  otherId: Scalars['ID']['input'];
};

export type ContactRequestsDeclineResult = {
  __typename?: 'ContactRequestsDeclineResult';
  error?: Maybe<ContactRequestsDeclineError>;
};

export type ContactRequestsMarkSeenError = {
  __typename?: 'ContactRequestsMarkSeenError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ContactRequestsMarkSeenInput = {
  untilDatetime: Scalars['Date']['input'];
};

export type ContactRequestsMarkSeenResult = {
  __typename?: 'ContactRequestsMarkSeenResult';
  /** #soft-logged-in */
  error?: Maybe<ContactRequestsMarkSeenError>;
};

export type ContactRequestsOneClickToken = {
  __typename?: 'ContactRequestsOneClickToken';
  /** #soft-logged-in */
  tokenPayload?: Maybe<ContactRequestsOneClickTokenPayload>;
};

export type ContactRequestsOneClickTokenPayload = {
  __typename?: 'ContactRequestsOneClickTokenPayload';
  /** #soft-logged-in #rest(batched) #virtual */
  recipient?: Maybe<XingId>;
  /** #soft-logged-in */
  recipientId?: Maybe<Scalars['ID']['output']>;
  /** #soft-logged-in */
  userId?: Maybe<Scalars['ID']['output']>;
};

/**
 * Collections of unfenced and fenced contact requests.
 * If user is premium or if no fenced elements are available fencedCollection will be null, not a collection.
 */
export type ContactRequestsReceived = {
  __typename?: 'ContactRequestsReceived';
  /** #soft-logged-in */
  collection: Array<ContactRequestReceived>;
  /** #soft-logged-in */
  fencedCollection?: Maybe<Array<ContactRequestReceived>>;
  /** #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type ContactRequestsRevokeError = {
  __typename?: 'ContactRequestsRevokeError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ContactRequestsRevokeInput = {
  recipientId: Scalars['ID']['input'];
};

export type ContactRequestsRevokeResult = {
  __typename?: 'ContactRequestsRevokeResult';
  error?: Maybe<ContactRequestsRevokeError>;
};

export type ContactRequestsSendError = {
  __typename?: 'ContactRequestsSendError';
  /** #soft-logged-in */
  errors?: Maybe<ContactRequestsSendErrorErrors>;
  /** #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type ContactRequestsSendErrorErrors = {
  __typename?: 'ContactRequestsSendErrorErrors';
  /** #soft-logged-in */
  reason?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ContactRequestsSendInput = {
  reason?: InputMaybe<Scalars['String']['input']>;
  recipientId: Scalars['ID']['input'];
};

export type ContactRequestsSendMultipleError = {
  __typename?: 'ContactRequestsSendMultipleError';
  /** #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type ContactRequestsSendMultipleInput = {
  recipientIds: Array<Scalars['ID']['input']>;
};

export type ContactRequestsSendMultipleResult = {
  __typename?: 'ContactRequestsSendMultipleResult';
  /** #soft-logged-in */
  error?: Maybe<ContactRequestsSendMultipleError>;
  /** #soft-logged-in */
  success?: Maybe<ContactRequestsSendMultipleSuccess>;
};

export type ContactRequestsSendMultipleSuccess = {
  __typename?: 'ContactRequestsSendMultipleSuccess';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<ContactRequestsSendMultipleSuccessItem>>>;
  /** #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type ContactRequestsSendMultipleSuccessItem = {
  __typename?: 'ContactRequestsSendMultipleSuccessItem';
  /** #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
};

export type ContactRequestsSendResult = {
  __typename?: 'ContactRequestsSendResult';
  /** #soft-logged-in */
  error?: Maybe<ContactRequestsSendError>;
};

export type ContactRequestsSent = {
  __typename?: 'ContactRequestsSent';
  /** #soft-logged-in */
  collection: Array<ContactRequestSent>;
  /** #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type ContactRequestsSentConnection = HasCursorPagination & {
  __typename?: 'ContactRequestsSentConnection';
  edges: Array<Maybe<ContactRequestSentEdge>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ContactRequestsSpamReportError = {
  __typename?: 'ContactRequestsSpamReportError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ContactRequestsSpamReportInput = {
  otherId: Scalars['ID']['input'];
  spamMessage: Scalars['String']['input'];
};

export type ContactRequestsSpamReportResult = {
  __typename?: 'ContactRequestsSpamReportResult';
  error?: Maybe<ContactRequestsSpamReportError>;
  /** #expand */
  success?: Maybe<ContactRequestsSpamReportSuccess>;
};

export type ContactRequestsSpamReportSuccess = {
  __typename?: 'ContactRequestsSpamReportSuccess';
  spammerId?: Maybe<Scalars['ID']['output']>;
};

export type ContactRequestsTokenConfirmError = {
  __typename?: 'ContactRequestsTokenConfirmError';
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type ContactRequestsTokenConfirmInput = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type ContactRequestsTokenConfirmResult = {
  __typename?: 'ContactRequestsTokenConfirmResult';
  /** #logged-out #soft-logged-in */
  error?: Maybe<ContactRequestsTokenConfirmError>;
  /** #expand #logged-out #soft-logged-in */
  success?: Maybe<ContactRequestsTokenConfirmSuccess>;
};

export type ContactRequestsTokenConfirmSuccess = {
  __typename?: 'ContactRequestsTokenConfirmSuccess';
  /** @deprecated Clients should use the messenger API instead in order to create a chat. Checked on 14.11.2023 - still used a lot by Mobile */
  chatId?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  hasReason?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  recipient?: Maybe<XingId>;
  /** #logged-out #soft-logged-in */
  recipientTrackingId?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  sender?: Maybe<XingId>;
};

export type ContactTopicsResponse = {
  __typename?: 'ContactTopicsResponse';
  /** #logged-out #soft-logged-in */
  topics?: Maybe<Array<Maybe<Topics>>>;
  user?: Maybe<User>;
};

export type ContactUsersCatalogueItemInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type ContactWithBirthday = {
  __typename?: 'ContactWithBirthday';
  /** #rest(batched) #virtual */
  personalDetails?: Maybe<PersonalDetails>;
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type ContactWithBirthdayConnection = HasCursorPagination & {
  __typename?: 'ContactWithBirthdayConnection';
  edges?: Maybe<Array<Maybe<ContactWithBirthdayEdge>>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ContactWithBirthdayEdge = PaginationEdge & {
  __typename?: 'ContactWithBirthdayEdge';
  cursor: Scalars['String']['output'];
  node: ContactWithBirthday;
};

/** Which city to filter for */
export type ContactsCityArguments = {
  names: Array<Scalars['String']['input']>;
};

/** Which company to filter for */
export type ContactsCompanyArguments = {
  names: Array<Scalars['String']['input']>;
};

export type ContactsContactConfirmedNotification = Notification & {
  __typename?: 'ContactsContactConfirmedNotification';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  aggregated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interacted: Scalars['Boolean']['output'];
  opened: Scalars['Boolean']['output'];
  publishedAt: Scalars['Date']['output'];
  seen: Scalars['Boolean']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn: Scalars['URN']['output'];
};

export type ContactsContactRecommendationNotification = Notification & {
  __typename?: 'ContactsContactRecommendationNotification';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  aggregated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interacted: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<PeopleStoryGeneratorMymkRecommendation>;
  opened: Scalars['Boolean']['output'];
  publishedAt: Scalars['Date']['output'];
  seen: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  sharedContacts?: Maybe<SharedContacts>;
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn: Scalars['URN']['output'];
};

export type ContactsContactRequestNotification = Notification & {
  __typename?: 'ContactsContactRequestNotification';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  aggregated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interacted: Scalars['Boolean']['output'];
  opened: Scalars['Boolean']['output'];
  publishedAt: Scalars['Date']['output'];
  seen: Scalars['Boolean']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn: Scalars['URN']['output'];
};

/** Count of active contact requests */
export type ContactsCount = {
  __typename?: 'ContactsCount';
  /** #soft-logged-in */
  count: Scalars['Int']['output'];
};

export type ContactsDeleteError = {
  __typename?: 'ContactsDeleteError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ContactsDeleteInput = {
  friendId: Scalars['ID']['input'];
};

export type ContactsDeleteResult = {
  __typename?: 'ContactsDeleteResult';
  error?: Maybe<ContactsDeleteError>;
};

/** Filters for contacts */
export type ContactsFilters = {
  city?: InputMaybe<ContactsCityArguments>;
  company?: InputMaybe<ContactsCompanyArguments>;
  match?: InputMaybe<ContactsMatchArguments>;
  tagged?: InputMaybe<ContactsTaggedArguments>;
};

export type ContactsJobUpdateNotification = Notification & {
  __typename?: 'ContactsJobUpdateNotification';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  aggregated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interacted: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<ProfileWorkExperienceUpdate>;
  opened: Scalars['Boolean']['output'];
  publishedAt: Scalars['Date']['output'];
  seen: Scalars['Boolean']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn: Scalars['URN']['output'];
};

/** Contact element in list of contacts */
export type ContactsListContact = {
  __typename?: 'ContactsListContact';
  /** #soft-logged-in */
  contactCreatedAt?: Maybe<Scalars['Date']['output']>;
  /** #soft-logged-in */
  id: Scalars['ID']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  normalizedInitialOfFirstName?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  normalizedInitialOfLastName?: Maybe<Scalars['String']['output']>;
  tagList?: Maybe<Array<NetworkTag>>;
  /** #soft-logged-in */
  urn?: Maybe<Scalars['URN']['output']>;
  /** #soft-logged-in #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type ContactsListContactEdge = PaginationEdge & {
  __typename?: 'ContactsListContactEdge';
  cursor: Scalars['String']['output'];
  node: ContactsListContact;
};

/** Ordering of the contacts list */
export const enum ContactsListOrderBy {
  ContactCreatedAt = 'CONTACT_CREATED_AT',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
}

/** Search functionality */
export type ContactsMatchArguments = {
  field: ContactsMatchField;
  value: Scalars['String']['input'];
};

/** Which field to search in */
export const enum ContactsMatchField {
  Name = 'NAME',
}

/** Contacts Module */
export type ContactsModule = ModuleInterface & {
  __typename?: 'ContactsModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

/** List of contacts, can be filtered */
export type ContactsNetwork = {
  __typename?: 'ContactsNetwork';
  /** #soft-logged-in */
  collection?: Maybe<Array<ContactsListContact>>;
  /** #soft-logged-in */
  total?: Maybe<Scalars['Int']['output']>;
};

export type ContactsNetworkByCursorConnection = HasCursorPagination & {
  __typename?: 'ContactsNetworkByCursorConnection';
  edges?: Maybe<Array<Maybe<ContactsListContactEdge>>>;
  /** #soft-logged-in */
  pageInfo: PageInfo;
  /** #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type ContactsRecruiterRecommendationNotification = Notification & {
  __typename?: 'ContactsRecruiterRecommendationNotification';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  aggregated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interacted: Scalars['Boolean']['output'];
  opened: Scalars['Boolean']['output'];
  publishedAt: Scalars['Date']['output'];
  seen: Scalars['Boolean']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn: Scalars['URN']['output'];
};

/** Count of same city contact */
export type ContactsSameCityCount = {
  __typename?: 'ContactsSameCityCount';
  total: Scalars['Int']['output'];
};

export type ContactsSearchAggregationsInput = {
  city?: InputMaybe<Scalars['Int']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
};

export type ContactsSearchConnection = HasCursorPagination & {
  __typename?: 'ContactsSearchConnection';
  edges: Array<Maybe<ContactsSearchEdge>>;
  pageInfo: PageInfo;
  /** #virtual */
  total: Scalars['Int']['output'];
};

export type ContactsSearchEdge = PaginationEdge & {
  __typename?: 'ContactsSearchEdge';
  cursor: Scalars['String']['output'];
  node: ContactsSearchResult;
};

export type ContactsSearchFiltersInput = {
  /**
   * Available filters for contact search
   * city: Filter results which match the city
   * company: filter results by the user current company
   * title: filter results by the user current job title
   */
  city?: InputMaybe<Scalars['Int']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
};

export type ContactsSearchResult = {
  __typename?: 'ContactsSearchResult';
  /** Unique identifier of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  trackingToken?: Maybe<Scalars['String']['output']>;
  /**
   * #rest(batched) #virtual
   *
   *  The profile information for the user
   */
  xingId?: Maybe<XingId>;
};

/** Which tags to filter for */
export type ContactsTaggedArguments = {
  ids: Array<Scalars['ID']['input']>;
};

export type ContentArticle = ContentArticleInterface &
  HasGlobalId & {
    __typename?: 'ContentArticle';
    abilities: ContentArticleAbilities;
    description?: Maybe<Scalars['String']['output']>;
    editUrl: Scalars['URL']['output'];
    /** Unique identifier for a ContentArticle (i.e. URN) */
    globalId: Scalars['GlobalID']['output'];
    hasVideo: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    image: ContentArticleImage;
    interactions: ContentArticleInteractions;
    introductoryText?: Maybe<Scalars['String']['output']>;
    /** @deprecated moved to `resourceFeatures`. Will be removed after NDIST-518 */
    isCommentable: Scalars['Boolean']['output'];
    isExternal: Scalars['Boolean']['output'];
    isFeatured: Scalars['Boolean']['output'];
    /** @deprecated moved to `isPremium`. Will be removed after NDIST-515, NDIST-517 and NDIST-518 */
    isNewsPlus: Scalars['Boolean']['output'];
    isPremium: Scalars['Boolean']['output'];
    isSponsored: Scalars['Boolean']['output'];
    metadata: ContentArticleMetadata;
    /** URN of the object which this article refers to (if any). This is NOT the URN of the ContentArticle! */
    objectUrn?: Maybe<Scalars['GlobalID']['output']>;
    /**
     * #rest(batched) #virtual
     *
     *  This only resolves pages for contentInsiderArticles for now and returns null for othe page types
     */
    page?: Maybe<ContentPageInterface>;
    pageGlobalId?: Maybe<Scalars['GlobalID']['output']>;
    pageId: Scalars['ID']['output'];
    publishedAt?: Maybe<Scalars['Date']['output']>;
    reportingData: ContentArticleReportingData;
    resourceFeatures: ContentArticleFeatures;
    resourceType: ContentResourceType;
    shareUrl: Scalars['URL']['output'];
    /** @deprecated `social` was renamed to `interactions`. Will be removed after NDIST-517, NDIST-518 */
    social: ContentArticleSocial;
    source: Scalars['String']['output'];
    status: ContentArticleStatus;
    title?: Maybe<Scalars['String']['output']>;
    url: Scalars['URL']['output'];
  };

export type ContentArticleAbilities = {
  __typename?: 'ContentArticleAbilities';
  /** #logged-out #soft-logged-in */
  canDeleteArticle: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canEditArticle: Scalars['Boolean']['output'];
};

export type ContentArticleDeletionResult = ContentDeletionResultInteface & {
  __typename?: 'ContentArticleDeletionResult';
  error?: Maybe<ContentError>;
  /** #rest #virtual */
  featuredArticles?: Maybe<ContentArticlesResponse>;
  /** #rest #virtual */
  page?: Maybe<ContentInsiderPageInterface>;
};

export type ContentArticleEdge = PaginationEdge & {
  __typename?: 'ContentArticleEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: ContentArticleInterface;
};

export type ContentArticleFeatures = ContentBaseArticleFeatures & {
  __typename?: 'ContentArticleFeatures';
  areCommentsDisplayedExternally: Scalars['Boolean']['output'];
  isCommentable: Scalars['Boolean']['output'];
  isStarable: Scalars['Boolean']['output'];
};

export type ContentArticleImage = {
  __typename?: 'ContentArticleImage';
  srcSquare?: Maybe<Scalars['URL']['output']>;
  srcSquare2x?: Maybe<Scalars['URL']['output']>;
  srcWide?: Maybe<Scalars['URL']['output']>;
  srcWide2x?: Maybe<Scalars['URL']['output']>;
};

export type ContentArticleInteractions = ContentBaseArticleInteractions & {
  __typename?: 'ContentArticleInteractions';
  isBookmarked: Scalars['Boolean']['output'];
  isCommented: Scalars['Boolean']['output'];
  isStarred: Scalars['Boolean']['output'];
};

export type ContentArticleInterface = {
  abilities: ContentArticleAbilities;
  description?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for a ContentArticle (i.e. URN) */
  globalId: Scalars['GlobalID']['output'];
  hasVideo: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image: ContentArticleImage;
  interactions: ContentBaseArticleInteractions;
  introductoryText?: Maybe<Scalars['String']['output']>;
  /** @deprecated moved to `resourceFeatures`. Will be removed after NDIST-518 */
  isCommentable: Scalars['Boolean']['output'];
  isExternal: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  /** @deprecated moved to `isPremium`. Will be removed after NDIST-515, NDIST-517 and NDIST-518 */
  isNewsPlus: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
  isSponsored: Scalars['Boolean']['output'];
  metadata: ContentBaseArticleMetadata;
  /** URN of the object which this article refers to (if any). This is NOT the URN of the ContentArticle! */
  objectUrn?: Maybe<Scalars['GlobalID']['output']>;
  /**
   * #rest(batched) #virtual
   *
   *  This only resolves pages for contentInsiderArticles for now and returns null for othe page types
   */
  page?: Maybe<ContentPageInterface>;
  pageGlobalId?: Maybe<Scalars['GlobalID']['output']>;
  pageId: Scalars['ID']['output'];
  publishedAt?: Maybe<Scalars['Date']['output']>;
  reportingData: ContentArticleReportingData;
  resourceFeatures: ContentBaseArticleFeatures;
  resourceType: ContentResourceType;
  shareUrl: Scalars['URL']['output'];
  /** @deprecated `social` was renamed to `interactions`. Will be removed after NDIST-517, NDIST-518 */
  social: ContentBaseArticleSocial;
  source: Scalars['String']['output'];
  status: ContentArticleStatus;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['URL']['output'];
};

export type ContentArticleMetadata = ContentBaseArticleMetadata & {
  __typename?: 'ContentArticleMetadata';
  /** clicksCount should be null for legacy articles, unless its a new article, in that case it wont be null */
  clicksCount?: Maybe<Scalars['Int']['output']>;
  commentsCount: Scalars['Int']['output'];
  readCount: Scalars['Int']['output'];
  starsCount: Scalars['Int']['output'];
};

export type ContentArticleMutationResult = {
  __typename?: 'ContentArticleMutationResult';
  error?: Maybe<ContentError>;
  /** #rest #virtual */
  featuredArticles?: Maybe<ContentArticlesResponse>;
  /** #rest #virtual */
  page?: Maybe<ContentInsiderPageInterface>;
  success?: Maybe<ContentArticleInterface>;
};

export type ContentArticlePurchase = ContentPurchaseInterface &
  HasDerivedGlobalId & {
    __typename?: 'ContentArticlePurchase';
    /** Globally unique id for this instance based on typename and id */
    globalId: Scalars['GlobalID']['output'];
    id: Scalars['ID']['output'];
    priceCents: Scalars['Int']['output'];
    teaser: ContentArticle;
  };

export type ContentArticleReportingData = {
  __typename?: 'ContentArticleReportingData';
  authorGlobalId?: Maybe<Scalars['GlobalID']['output']>;
  targetGlobalId: Scalars['GlobalID']['output'];
};

export type ContentArticleSocial = ContentBaseArticleSocial & {
  __typename?: 'ContentArticleSocial';
  isBookmarked: Scalars['Boolean']['output'];
  isCommented: Scalars['Boolean']['output'];
  isStarred: Scalars['Boolean']['output'];
};

export const enum ContentArticleStatus {
  Disabled = 'DISABLED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED',
}

export type ContentArticlesConnection = HasCursorPagination & {
  __typename?: 'ContentArticlesConnection';
  edges: Array<Maybe<ContentArticleEdge>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ContentArticlesResponse = {
  __typename?: 'ContentArticlesResponse';
  collection: Array<Maybe<ContentArticleInterface>>;
  total: Scalars['Int']['output'];
};

export type ContentBaseArticleFeatures = {
  areCommentsDisplayedExternally: Scalars['Boolean']['output'];
  isCommentable: Scalars['Boolean']['output'];
};

export type ContentBaseArticleInteractions = {
  isBookmarked: Scalars['Boolean']['output'];
  isCommented: Scalars['Boolean']['output'];
};

export type ContentBaseArticleMetadata = {
  /** clicksCount should be null for legacy articles, unless its a new article, in that case it wont be null */
  clicksCount?: Maybe<Scalars['Int']['output']>;
  commentsCount: Scalars['Int']['output'];
  readCount: Scalars['Int']['output'];
};

export type ContentBaseArticleSocial = {
  isBookmarked: Scalars['Boolean']['output'];
  isCommented: Scalars['Boolean']['output'];
};

export type ContentBookmarkEdge = PaginationEdge & {
  __typename?: 'ContentBookmarkEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: ContentBookmarkable;
};

export type ContentBookmarkInput = {
  bookmarkableGlobalId: Scalars['GlobalID']['input'];
};

export type ContentBookmarkable = ContentArticle | ContentKlartextKlarticle;

export type ContentBookmarkableMutationResult = {
  __typename?: 'ContentBookmarkableMutationResult';
  error?: Maybe<ContentError>;
  /** @deprecated use success field instead. Will be removed after NDIST-515 */
  result?: Maybe<ContentArticleInterface>;
  success?: Maybe<ContentArticleInterface>;
};

export type ContentBookmarksConnection = HasCursorPagination & {
  __typename?: 'ContentBookmarksConnection';
  edges: Array<Maybe<ContentBookmarkEdge>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ContentBundleArticle = {
  __typename?: 'ContentBundleArticle';
  isFree: Scalars['Boolean']['output'];
  isPurchased: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  url: Scalars['URL']['output'];
};

export type ContentBundlePurchase = ContentPurchaseInterface &
  HasDerivedGlobalId & {
    __typename?: 'ContentBundlePurchase';
    articles?: Maybe<Array<Maybe<ContentBundleArticle>>>;
    /** Globally unique id for this instance based on typename and id */
    globalId: Scalars['GlobalID']['output'];
    id: Scalars['ID']['output'];
    priceCents: Scalars['Int']['output'];
    purchasedCount: Scalars['Int']['output'];
    teaser: ContentPage;
    totalCount: Scalars['Int']['output'];
  };

export type ContentCancelSubscriptionResult = {
  __typename?: 'ContentCancelSubscriptionResult';
  error?: Maybe<ContentError>;
  /** @deprecated use success field instead. */
  result?: Maybe<ContentSubscriptionPurchase>;
  success?: Maybe<ContentSubscriptionPurchase>;
};

export type ContentCategory = {
  __typename?: 'ContentCategory';
  name: Scalars['String']['output'];
};

export type ContentCategoryCollection = {
  __typename?: 'ContentCategoryCollection';
  collection: Array<ContentCategory>;
  total: Scalars['Int']['output'];
};

/** Represents a channel for content which can be news items, jobs, ... etc, the naming "Channel" is still WIP */
export type ContentChannel = {
  __typename?: 'ContentChannel';
  followersCount: Scalars['Int']['output'];
  globalId: Scalars['GlobalID']['output'];
  id: Scalars['ID']['output'];
  image: ContentChannelImage;
  sourcesCount: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  viewerFollowState?: Maybe<ContentChannelFollowState>;
};

export type ContentChannelCollection = {
  __typename?: 'ContentChannelCollection';
  collection: Array<ContentChannel>;
};

/** Since following channels comes also with email subscription, this is presented by three states */
export const enum ContentChannelFollowState {
  /** User is following the channel but not subscribed to it's newsletter */
  FollowedWithoutEmail = 'FOLLOWED_WITHOUT_EMAIL',
  /** User is following the channel and subscribed to it's newsletter */
  FollowedWithEmail = 'FOLLOWED_WITH_EMAIL',
  /** User is not following the channel and not subscribed to it's newsletter */
  Unfollowed = 'UNFOLLOWED',
}

export type ContentChannelImage = {
  __typename?: 'ContentChannelImage';
  size68x68: Scalars['URL']['output'];
  size136x136: Scalars['URL']['output'];
};

export type ContentComment = HasGlobalId & {
  __typename?: 'ContentComment';
  abilities?: Maybe<ContentCommentAbilities>;
  /** #rest(batched) #virtual */
  author?: Maybe<XingId>;
  authorId: Scalars['ID']['output'];
  /** #rest #virtual */
  commentable?: Maybe<ContentArticleInterface>;
  createdAt: Scalars['Date']['output'];
  /** Globally unique id for this instance */
  globalId: Scalars['GlobalID']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type ContentCommentAbilities = {
  __typename?: 'ContentCommentAbilities';
  canHideComment?: Maybe<Scalars['Boolean']['output']>;
};

export type ContentCommentCreateInput = {
  commentableGlobalId: Scalars['GlobalID']['input'];
  message: Scalars['String']['input'];
};

export type ContentCommentEdge = PaginationEdge & {
  __typename?: 'ContentCommentEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: ContentComment;
};

export type ContentCommentHideInput = {
  commentGlobalId: Scalars['GlobalID']['input'];
};

export type ContentCommentMutationResult = {
  __typename?: 'ContentCommentMutationResult';
  error?: Maybe<ContentError>;
  /** @deprecated use success field instead. WILL BE REMOVED ... */
  result?: Maybe<ContentComment>;
  success?: Maybe<ContentComment>;
};

export type ContentCommentsConnection = HasCursorPagination & {
  __typename?: 'ContentCommentsConnection';
  /** #rest #virtual */
  commentable?: Maybe<ContentArticleInterface>;
  edges?: Maybe<Array<Maybe<ContentCommentEdge>>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ContentDaypass365Purchase = ContentPurchaseInterface &
  ContentSubscriptionPurchaseInterface &
  HasDerivedGlobalId & {
    __typename?: 'ContentDaypass365Purchase';
    expiresAt?: Maybe<Scalars['Date']['output']>;
    /** Globally unique id for this instance based on typename and id */
    globalId: Scalars['GlobalID']['output'];
    id: Scalars['ID']['output'];
    priceCents: Scalars['Int']['output'];
    stub?: Maybe<Scalars['String']['output']>;
    teaser: ContentPage;
  };

export type ContentDaypassPurchase = ContentPurchaseInterface &
  ContentSubscriptionPurchaseInterface &
  HasDerivedGlobalId & {
    __typename?: 'ContentDaypassPurchase';
    expiresAt?: Maybe<Scalars['Date']['output']>;
    /** Globally unique id for this instance based on typename and id */
    globalId: Scalars['GlobalID']['output'];
    id: Scalars['ID']['output'];
    priceCents: Scalars['Int']['output'];
    stub?: Maybe<Scalars['String']['output']>;
    teaser: ContentPage;
  };

export type ContentDeleteArticleInput = {
  globalId?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type ContentDeletionResultInteface = {
  error?: Maybe<ContentError>;
};

export type ContentDisableArticleInput = {
  globalId?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type ContentEnableArticleInput = {
  globalId?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type ContentError = {
  __typename?: 'ContentError';
  description?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ContentFeatureArticleInput = {
  globalId?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type ContentFollower = ContentSubscriberInterface & {
  __typename?: 'ContentFollower';
  followedAt?: Maybe<Scalars['Date']['output']>;
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type ContentFollowerEdge = PaginationEdge & {
  __typename?: 'ContentFollowerEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: ContentFollower;
};

/** Profile Module ContentInsider */
export type ContentInsiderModule = ContentInsiderPageInterface &
  ContentPageInterface &
  ModuleInterface & {
    __typename?: 'ContentInsiderModule';
    active?: Maybe<Scalars['Boolean']['output']>;
    /**
     * #logged-out #soft-logged-in
     *
     *  Count of page articles
     * @deprecated was moved to metadata.
     */
    articleCount: Scalars['Int']['output'];
    /** #paginated #rest #virtual */
    articles?: Maybe<ContentArticlesConnection>;
    /**
     * #logged-out #soft-logged-in
     *
     *  Page description
     */
    description?: Maybe<Scalars['String']['output']>;
    /** #rest #virtual */
    featuredArticles?: Maybe<ContentArticlesResponse>;
    /**
     * #logged-out #soft-logged-in
     * @deprecated renamed to abilities.
     */
    features: ContentPageAbilities;
    /**
     * #logged-out #soft-logged-in
     *
     *  Count of page followers
     * @deprecated was moved to metadata.
     */
    followersCount: Scalars['Int']['output'];
    /** #paginated #rest #virtual */
    followersWithinContacts?: Maybe<ContentInsiderPageFollowersWithinContactsConnection>;
    /** #logged-out #soft-logged-in */
    globalId: Scalars['GlobalID']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Page Id
     */
    id: Scalars['ID']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Page specific data when displayed in Armstrong
     */
    inArmstrong?: Maybe<ContentPageInArmstrong>;
    interactions?: Maybe<ContentPageInteractions>;
    /**
     * #logged-out #soft-logged-in
     *
     *  Does the current user follow the page
     * @deprecated was moved to interactions.
     */
    isFollowed: Scalars['Boolean']['output'];
    /** #logged-out #soft-logged-in */
    metadata: ContentPageMetadata;
    /** #logged-out #soft-logged-in */
    order: Scalars['Int']['output'];
    /**
     * #logged-out #soft-logged-in
     * @deprecated This should not be needed and will be removed as soon as it isn't anymore. Will be removed after NDIST-515
     */
    subscriberId: Scalars['ID']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Brief Page description
     */
    tagline?: Maybe<Scalars['String']['output']>;
    /** #logged-out #soft-logged-in */
    title: Scalars['String']['output'];
    /**
     * #rest #virtual
     *
     *  Either featured article or latest article
     * @deprecated Use topArticles(limit: 1) instead.
     */
    topArticle?: Maybe<ContentArticleInterface>;
    /** #rest #virtual */
    topArticles?: Maybe<ContentArticlesResponse>;
    /** #logged-out #soft-logged-in */
    topics: ContentTopicCollection;
    /** #logged-out #soft-logged-in */
    url?: Maybe<Scalars['URL']['output']>;
    /**
     * #logged-out #soft-logged-in
     *
     *  User id of insider
     */
    userId: Scalars['ID']['output'];
    /** #logged-out #soft-logged-in #rest(batched) #virtual */
    xingId?: Maybe<XingId>;
  };

/** Profile Module ContentInsider */
export type ContentInsiderModuleArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Profile Module ContentInsider */
export type ContentInsiderModuleFollowersWithinContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Profile Module ContentInsider */
export type ContentInsiderModuleTopArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentInsiderPage = ArticlesContextInterface &
  ContentInsiderPageInterface &
  ContentPageInterface &
  HasGlobalId & {
    __typename?: 'ContentInsiderPage';
    /**
     * #logged-out #soft-logged-in
     *
     *  Abilities of current user for page
     */
    abilities: ContentPageAbilities;
    /**
     * #logged-out #soft-logged-in
     *
     *  Count of page articles
     * @deprecated was moved to metadata.
     */
    articleCount: Scalars['Int']['output'];
    /** #paginated #rest #virtual */
    articles?: Maybe<ContentArticlesConnection>;
    /**
     * #logged-out #soft-logged-in
     *
     *  Page description
     */
    description?: Maybe<Scalars['String']['output']>;
    /** #rest #virtual */
    featuredArticles?: Maybe<ContentArticlesResponse>;
    /**
     * #logged-out #soft-logged-in
     * @deprecated renamed to abilities.
     */
    features: ContentPageAbilities;
    /**
     * #logged-out #soft-logged-in
     *
     *  Count of page followers
     * @deprecated was moved to metadata.
     */
    followersCount: Scalars['Int']['output'];
    /** #paginated #rest #virtual */
    followersWithinContacts?: Maybe<ContentInsiderPageFollowersWithinContactsConnection>;
    /** #logged-out #soft-logged-in */
    globalId: Scalars['GlobalID']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Page Id
     */
    id: Scalars['ID']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Page specific data when displayed in Armstrong
     */
    inArmstrong?: Maybe<ContentPageInArmstrong>;
    interactions?: Maybe<ContentPageInteractions>;
    /**
     * #logged-out #soft-logged-in
     *
     *  Does the current user follow the page
     * @deprecated was moved to interactions.
     */
    isFollowed: Scalars['Boolean']['output'];
    /** #logged-out #soft-logged-in */
    metadata: ContentPageMetadata;
    /**
     * #logged-out #soft-logged-in
     * @deprecated This should not be needed and will be removed as soon as it isn't anymore. Will be removed after NDIST-515
     */
    subscriberId: Scalars['ID']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Brief Page description
     */
    tagline?: Maybe<Scalars['String']['output']>;
    /** #logged-out #soft-logged-in */
    title: Scalars['String']['output'];
    /**
     * #rest #virtual
     *
     *  Either featured article or latest article
     * @deprecated Use topArticles(limit: 1) instead.
     */
    topArticle?: Maybe<ContentArticleInterface>;
    /**
     * #rest #virtual
     *
     *  Featured article or latest articles
     */
    topArticles?: Maybe<ContentArticlesResponse>;
    /** #logged-out #soft-logged-in */
    topics: ContentTopicCollection;
    /** #logged-out #soft-logged-in */
    url?: Maybe<Scalars['URL']['output']>;
    /**
     * #logged-out #soft-logged-in
     *
     *  User id of insider
     */
    userId: Scalars['ID']['output'];
    /** #logged-out #soft-logged-in #rest(batched) #virtual */
    xingId?: Maybe<XingId>;
  };

export type ContentInsiderPageArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentInsiderPageFollowersWithinContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentInsiderPageTopArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentInsiderPageFollowersConnection =
  ContentInsiderPageFollowersInterface &
    HasCursorPagination & {
      __typename?: 'ContentInsiderPageFollowersConnection';
      edges: Array<Maybe<ContentFollowerEdge>>;
      newFollowersCount: Scalars['Int']['output'];
      /** When did the owner last check her followers */
      newFollowersLastCheckedAt?: Maybe<Scalars['Date']['output']>;
      pageGlobalId: Scalars['URN']['output'];
      /** #virtual */
      pageInfo: PageInfo;
      total: Scalars['Int']['output'];
      userId: Scalars['ID']['output'];
    };

export type ContentInsiderPageFollowersInterface = {
  newFollowersCount: Scalars['Int']['output'];
  /** When did the owner last check her followers */
  newFollowersLastCheckedAt?: Maybe<Scalars['Date']['output']>;
  pageGlobalId: Scalars['URN']['output'];
  userId: Scalars['ID']['output'];
};

export type ContentInsiderPageFollowersWithinContactsConnection =
  HasCursorPagination & {
    __typename?: 'ContentInsiderPageFollowersWithinContactsConnection';
    edges: Array<Maybe<ContentFollowerEdge>>;
    pageId: Scalars['ID']['output'];
    /** #virtual */
    pageInfo: PageInfo;
    total: Scalars['Int']['output'];
    userId?: Maybe<Scalars['ID']['output']>;
  };

export type ContentInsiderPageInterface = {
  /** #paginated #rest #virtual */
  articles?: Maybe<ContentArticlesConnection>;
  /** #rest #virtual */
  featuredArticles?: Maybe<ContentArticlesResponse>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated renamed to abilities.
   */
  features: ContentPageAbilities;
  /** #paginated #rest #virtual */
  followersWithinContacts?: Maybe<ContentInsiderPageFollowersWithinContactsConnection>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated This should not be needed and will be removed as soon as it isn't anymore. Will be removed after NDIST-515
   */
  subscriberId: Scalars['ID']['output'];
  /**
   * #rest #virtual
   *
   *  Either featured article or latest article
   * @deprecated Use topArticles(limit: 1) instead.
   */
  topArticle?: Maybe<ContentArticleInterface>;
  /**
   * #rest #virtual
   *
   *  Featured article or latest articles
   */
  topArticles?: Maybe<ContentArticlesResponse>;
  /** #logged-out #soft-logged-in */
  topics: ContentTopicCollection;
  /**
   * #logged-out #soft-logged-in
   *
   *  User id of insider
   */
  userId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type ContentInsiderPageInterfaceArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentInsiderPageInterfaceFollowersWithinContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentInsiderPageInterfaceTopArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentInsiderPageLastFollowersSeen =
  ContentInsiderPageFollowersInterface & {
    __typename?: 'ContentInsiderPageLastFollowersSeen';
    newFollowersCount: Scalars['Int']['output'];
    /** When did the owner last check her followers */
    newFollowersLastCheckedAt?: Maybe<Scalars['Date']['output']>;
    pageGlobalId: Scalars['URN']['output'];
    stub?: Maybe<Scalars['String']['output']>;
    userId: Scalars['ID']['output'];
  };

export type ContentInsiderPageLastFollowersSeenInput = {
  userId: Scalars['SlugOrID']['input'];
};

export type ContentInsiderPageLastFollowersSeenResult = {
  __typename?: 'ContentInsiderPageLastFollowersSeenResult';
  error?: Maybe<ContentError>;
  /** @deprecated use success field instead. Still used, to be removed after NDIST-515 */
  result?: Maybe<ContentInsiderPageLastFollowersSeen>;
  success?: Maybe<ContentInsiderPageLastFollowersSeen>;
};

export type ContentKlartextAgreeError = {
  __typename?: 'ContentKlartextAgreeError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ContentKlartextAgreeResult = {
  __typename?: 'ContentKlartextAgreeResult';
  error?: Maybe<ContentKlartextAgreeError>;
  success?: Maybe<ContentKlartextKlarticle>;
};

export type ContentKlartextFeatures = ContentBaseArticleFeatures & {
  __typename?: 'ContentKlartextFeatures';
  areCommentsDisplayedExternally: Scalars['Boolean']['output'];
  isAgreeable: Scalars['Boolean']['output'];
  isCommentable: Scalars['Boolean']['output'];
};

export type ContentKlartextInteractions = ContentBaseArticleInteractions & {
  __typename?: 'ContentKlartextInteractions';
  isAgreed?: Maybe<Scalars['Boolean']['output']>;
  isBookmarked: Scalars['Boolean']['output'];
  isCommented: Scalars['Boolean']['output'];
};

export type ContentKlartextKlarticle = ContentArticleInterface &
  HasGlobalId & {
    __typename?: 'ContentKlartextKlarticle';
    abilities: ContentArticleAbilities;
    description?: Maybe<Scalars['String']['output']>;
    /** Unique identifier for a ContentArticle (i.e. URN) */
    globalId: Scalars['GlobalID']['output'];
    hasVideo: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    image: ContentArticleImage;
    interactions: ContentKlartextInteractions;
    introductoryText?: Maybe<Scalars['String']['output']>;
    /** @deprecated moved to `resourceFeatures`. Will be removed after NDIST-518 */
    isCommentable: Scalars['Boolean']['output'];
    isExternal: Scalars['Boolean']['output'];
    isFeatured: Scalars['Boolean']['output'];
    /** @deprecated moved to `isPremium`. Will be removed after NDIST-515, NDIST-517 and NDIST-518 */
    isNewsPlus: Scalars['Boolean']['output'];
    isPremium: Scalars['Boolean']['output'];
    isSponsored: Scalars['Boolean']['output'];
    metadata: ContentKlartextMetadata;
    /** URN of the object which this article refers to (if any). This is NOT the URN of the ContentArticle! */
    objectUrn?: Maybe<Scalars['GlobalID']['output']>;
    /**
     * #rest(batched) #virtual
     *
     *  This only resolves pages for contentInsiderArticles for now and returns null for othe page types
     */
    page?: Maybe<ContentPageInterface>;
    pageGlobalId?: Maybe<Scalars['GlobalID']['output']>;
    pageId: Scalars['ID']['output'];
    publishedAt?: Maybe<Scalars['Date']['output']>;
    reportingData: ContentArticleReportingData;
    resourceFeatures: ContentKlartextFeatures;
    resourceType: ContentResourceType;
    shareUrl: Scalars['URL']['output'];
    /** @deprecated `social` was renamed to `interactions`. Will be removed after NDIST-517, NDIST-518 */
    social: ContentKlartextSocial;
    source: Scalars['String']['output'];
    status: ContentArticleStatus;
    title?: Maybe<Scalars['String']['output']>;
    url: Scalars['URL']['output'];
  };

export type ContentKlartextMetadata = ContentBaseArticleMetadata & {
  __typename?: 'ContentKlartextMetadata';
  agreesCount?: Maybe<Scalars['Int']['output']>;
  /** clicksCount should be null for legacy articles, unless its a new article, in that case it wont be null */
  clicksCount?: Maybe<Scalars['Int']['output']>;
  commentsCount: Scalars['Int']['output'];
  readCount: Scalars['Int']['output'];
};

export type ContentKlartextSocial = ContentBaseArticleSocial & {
  __typename?: 'ContentKlartextSocial';
  isAgreed?: Maybe<Scalars['Boolean']['output']>;
  isBookmarked: Scalars['Boolean']['output'];
  isCommented: Scalars['Boolean']['output'];
};

export type ContentKlartextUnagreeResult = {
  __typename?: 'ContentKlartextUnagreeResult';
  error?: Maybe<ContentKlartextAgreeError>;
  success?: Maybe<ContentKlartextKlarticle>;
};

export type ContentManagedInsiderPage = ContentInsiderPageInterface &
  ContentPageInterface &
  HasGlobalId & {
    __typename?: 'ContentManagedInsiderPage';
    /**
     * #logged-out #soft-logged-in
     *
     *  Page manager abilities of current user for page
     */
    abilities: ContentManagedPageAbilities;
    /** #logged-out #soft-logged-in */
    areTermsAndConditionsAccepted: Scalars['Boolean']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Count of page articles
     * @deprecated was moved to metadata.
     */
    articleCount: Scalars['Int']['output'];
    /** #paginated #rest #virtual */
    articles?: Maybe<ContentArticlesConnection>;
    /**
     * #logged-out #soft-logged-in
     *
     *  Page description
     */
    description?: Maybe<Scalars['String']['output']>;
    /** #rest #virtual */
    featuredArticles?: Maybe<ContentArticlesResponse>;
    /**
     * #logged-out #soft-logged-in
     * @deprecated renamed to abilities.
     */
    features: ContentPageAbilities;
    /**
     * #logged-out #soft-logged-in
     *
     *  Count of page followers
     * @deprecated was moved to metadata.
     */
    followersCount: Scalars['Int']['output'];
    /** #paginated #rest #virtual */
    followersWithinContacts?: Maybe<ContentInsiderPageFollowersWithinContactsConnection>;
    /** #logged-out #soft-logged-in */
    globalId: Scalars['GlobalID']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Page Id
     */
    id: Scalars['ID']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Page specific data when displayed in Armstrong
     */
    inArmstrong?: Maybe<ContentPageInArmstrong>;
    interactions?: Maybe<ContentPageInteractions>;
    /**
     * #logged-out #soft-logged-in
     *
     *  Does the current user follow the page
     * @deprecated was moved to interactions.
     */
    isFollowed: Scalars['Boolean']['output'];
    /** #logged-out #soft-logged-in */
    metadata: ContentManagedPageMetadata;
    /**
     * #logged-out #soft-logged-in
     * @deprecated This should not be needed and will be removed as soon as it isn't anymore. Will be removed after NDIST-515
     */
    subscriberId: Scalars['ID']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Brief Page description
     */
    tagline?: Maybe<Scalars['String']['output']>;
    /** #logged-out #soft-logged-in */
    title: Scalars['String']['output'];
    /**
     * #rest #virtual
     *
     *  Either featured article or latest article
     * @deprecated Use topArticles(limit: 1) instead.
     */
    topArticle?: Maybe<ContentArticleInterface>;
    /**
     * #rest #virtual
     *
     *  Featured article or latest articles
     */
    topArticles?: Maybe<ContentArticlesResponse>;
    /** #logged-out #soft-logged-in */
    topics: ContentTopicCollection;
    /** #logged-out #soft-logged-in */
    url?: Maybe<Scalars['URL']['output']>;
    /**
     * #logged-out #soft-logged-in
     *
     *  User id of insider
     */
    userId: Scalars['ID']['output'];
    /** #logged-out #soft-logged-in #rest(batched) #virtual */
    xingId?: Maybe<XingId>;
  };

export type ContentManagedInsiderPageArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentManagedInsiderPageFollowersWithinContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentManagedInsiderPageTopArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentManagedPageAbilities = ContentPageAbilitiesInterface & {
  __typename?: 'ContentManagedPageAbilities';
  /** #logged-out #soft-logged-in */
  canActivatePage: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canCreateVideoArticle: Scalars['Boolean']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  true if a user can edit this page's articles. Required by the articles service
   */
  canEdit: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canEditPage: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canFollowPage: Scalars['Boolean']['output'];
};

export type ContentManagedPageMetadata = ContentPageMetadataInterface & {
  __typename?: 'ContentManagedPageMetadata';
  /** #logged-out #soft-logged-in */
  articleClicksCount: Scalars['Int']['output'];
  /**
   * #logged-out #soft-logged-in
   * @deprecated renamed to publishedArticlesCount.
   */
  articleCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  commentsCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  disabledArticlesCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  draftArticlesCount: Scalars['Int']['output'];
  /** #rest(batched) #virtual */
  firstFourFollowersWithinContactsBatched?: Maybe<ContentPageFollowersWithinContactsBatched>;
  /** #logged-out #soft-logged-in */
  followersCount: Scalars['Int']['output'];
  /** #paginated #rest #virtual */
  followersWithinContacts?: Maybe<ContentPageFollowersWithinContactsConnection>;
  /** #logged-out #soft-logged-in */
  publishedArticlesCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  scheduledArticlesCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  unreviewedCommentsCount: Scalars['Int']['output'];
};

export type ContentManagedPageMetadataFollowersWithinContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentPage = ArticlesContextInterface &
  ContentPageInterface &
  HasGlobalId & {
    __typename?: 'ContentPage';
    /** #logged-out #soft-logged-in */
    abilities: ContentPageAbilities;
    /**
     * #logged-out #soft-logged-in
     *
     *  Count of page articles
     * @deprecated was moved to metadata.
     */
    articleCount: Scalars['Int']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Page description
     */
    description?: Maybe<Scalars['String']['output']>;
    /**
     * #logged-out #soft-logged-in #rest(batched) #virtual
     *
     *  Allows access to the hosting Entity Page of a Content Page
     */
    entityPage?: Maybe<EntityPage>;
    /** #logged-out #soft-logged-in */
    entityPageId?: Maybe<Scalars['ID']['output']>;
    /** @deprecated renamed to abilities. */
    features?: Maybe<ContentPageAbilities>;
    /**
     * #logged-out #soft-logged-in
     *
     *  Count of page followers
     * @deprecated was moved to metadata.
     */
    followersCount: Scalars['Int']['output'];
    /** #logged-out #soft-logged-in */
    globalId: Scalars['GlobalID']['output'];
    /** #logged-out #soft-logged-in */
    headerImage?: Maybe<ContentPageHeaderImage>;
    /**
     * #logged-out #soft-logged-in
     *
     *  Page Id
     */
    id: Scalars['ID']['output'];
    /**
     * #logged-out #soft-logged-in
     *
     *  Page specific data when displayed in Armstrong
     */
    inArmstrong?: Maybe<ContentPageInArmstrong>;
    interactions?: Maybe<ContentPageInteractions>;
    /**
     * #logged-out #soft-logged-in
     *
     *  Does the current user follow the page
     * @deprecated was moved to interactions.
     */
    isFollowed: Scalars['Boolean']['output'];
    /** #logged-out #soft-logged-in */
    logoImage?: Maybe<ContentPageLogoImage>;
    /** #logged-out #soft-logged-in */
    metadata?: Maybe<ContentPageMetadataInterface>;
    /**
     * #logged-out #soft-logged-in
     *
     *  Brief Page description
     */
    tagline?: Maybe<Scalars['String']['output']>;
    /** #logged-out #soft-logged-in */
    title: Scalars['String']['output'];
    /** #logged-out #soft-logged-in */
    url?: Maybe<Scalars['URL']['output']>;
  };

export type ContentPageAbilities = ArticlesAbilitiesInterface &
  ContentPageAbilitiesInterface & {
    __typename?: 'ContentPageAbilities';
    /**
     * #logged-out #soft-logged-in
     *
     *  true if a user can edit this page's articles. Required by the articles service
     */
    canEdit: Scalars['Boolean']['output'];
    /** #logged-out #soft-logged-in */
    canEditPage: Scalars['Boolean']['output'];
    /** #logged-out #soft-logged-in */
    canFollowPage: Scalars['Boolean']['output'];
    stub?: Maybe<Scalars['String']['output']>;
  };

export type ContentPageAbilitiesInterface = {
  /**
   * #logged-out #soft-logged-in
   *
   *  true if a user can edit this page's articles. Required by the articles service
   */
  canEdit: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canEditPage: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canFollowPage: Scalars['Boolean']['output'];
};

export type ContentPageEdge = PaginationEdge & {
  __typename?: 'ContentPageEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node: ContentPageInterface;
};

export type ContentPageFollowInput = {
  pageId: Scalars['ID']['input'];
};

export type ContentPageFollowersWithinContactsBatched = {
  __typename?: 'ContentPageFollowersWithinContactsBatched';
  followers: Array<ContentFollower>;
  pageUrn: Scalars['GlobalID']['output'];
  total: Scalars['Int']['output'];
};

export type ContentPageFollowersWithinContactsConnection =
  HasCursorPagination & {
    __typename?: 'ContentPageFollowersWithinContactsConnection';
    edges: Array<Maybe<ContentFollowerEdge>>;
    /** #virtual */
    pageInfo: PageInfo;
    total: Scalars['Int']['output'];
  };

export type ContentPageHeaderImage = {
  __typename?: 'ContentPageHeaderImage';
  /** #logged-out #soft-logged-in */
  banner984?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  banner1968?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  bannerLarge984?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  bannerLarge1968?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  bannerSmall479?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  bannerSmall958?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  iphone?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  mobile?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  original?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  small?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square230?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square364?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square728?: Maybe<Scalars['URL']['output']>;
};

export type ContentPageInArmstrong = {
  __typename?: 'ContentPageInArmstrong';
  /**
   * #logged-out #soft-logged-in
   *
   *  Header of the page when displayed in Armstrong, note it's not available for all types of pages
   */
  header?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Sub-header of the page when displayed in Armstrong, note it's not available for all types of pages
   */
  subHeader?: Maybe<Scalars['String']['output']>;
};

export type ContentPageInteractions = {
  __typename?: 'ContentPageInteractions';
  hasFollowedInSession?: Maybe<Scalars['Boolean']['output']>;
  isFollowed: Scalars['Boolean']['output'];
};

export type ContentPageInterface = {
  /**
   * #logged-out #soft-logged-in
   *
   *  Count of page articles
   * @deprecated was moved to metadata.
   */
  articleCount: Scalars['Int']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Page description
   */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Count of page followers
   * @deprecated was moved to metadata.
   */
  followersCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Page Id
   */
  id: Scalars['ID']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Page specific data when displayed in Armstrong
   */
  inArmstrong?: Maybe<ContentPageInArmstrong>;
  interactions?: Maybe<ContentPageInteractions>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Does the current user follow the page
   * @deprecated was moved to interactions.
   */
  isFollowed: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  metadata?: Maybe<ContentPageMetadataInterface>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Brief Page description
   */
  tagline?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  title: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type ContentPageList = {
  __typename?: 'ContentPageList';
  collection: Array<Maybe<ContentPageInterface>>;
  total: Scalars['Int']['output'];
};

export type ContentPageLogoImage = {
  __typename?: 'ContentPageLogoImage';
  /** #logged-out #soft-logged-in */
  original?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square32?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square48?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square64?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square96?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square128?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square144?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square192?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square256?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square384?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square1024?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  web?: Maybe<Scalars['URL']['output']>;
};

export type ContentPageMetadata = ContentPageMetadataInterface & {
  __typename?: 'ContentPageMetadata';
  /**
   * #logged-out #soft-logged-in
   * @deprecated renamed to publishedArticlesCount.
   */
  articleCount: Scalars['Int']['output'];
  /** #rest(batched) #virtual */
  firstFourFollowersWithinContactsBatched?: Maybe<ContentPageFollowersWithinContactsBatched>;
  /** #logged-out #soft-logged-in */
  followersCount: Scalars['Int']['output'];
  /** #paginated #rest #virtual */
  followersWithinContacts?: Maybe<ContentPageFollowersWithinContactsConnection>;
  /** #logged-out #soft-logged-in */
  publishedArticlesCount: Scalars['Int']['output'];
  stub?: Maybe<Scalars['String']['output']>;
};

export type ContentPageMetadataFollowersWithinContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentPageMetadataInterface = {
  /**
   * #logged-out #soft-logged-in
   * @deprecated renamed to publishedArticlesCount.
   */
  articleCount: Scalars['Int']['output'];
  /** #rest(batched) #virtual */
  firstFourFollowersWithinContactsBatched?: Maybe<ContentPageFollowersWithinContactsBatched>;
  /** #logged-out #soft-logged-in */
  followersCount: Scalars['Int']['output'];
  /** #paginated #rest #virtual */
  followersWithinContacts?: Maybe<ContentPageFollowersWithinContactsConnection>;
  /** #logged-out #soft-logged-in */
  publishedArticlesCount: Scalars['Int']['output'];
};

export type ContentPageMetadataInterfaceFollowersWithinContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentPageMutationResult = {
  __typename?: 'ContentPageMutationResult';
  error?: Maybe<ContentError>;
  /** @deprecated use success field instead. */
  result?: Maybe<ContentPageInterface>;
  success?: Maybe<ContentPageInterface>;
};

export type ContentPageRecommendation = Recommendation & {
  __typename?: 'ContentPageRecommendation';
  itemId: Scalars['ID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #rest(batched) #virtual */
  page?: Maybe<ContentPageInterface>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type ContentPageRecommendationEdge = PaginationEdge & {
  __typename?: 'ContentPageRecommendationEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: ContentPageRecommendation;
};

export type ContentPageRecommenderConnection = HasCursorPagination &
  RecommenderResponse & {
    __typename?: 'ContentPageRecommenderConnection';
    edges: Array<Maybe<ContentPageRecommendationEdge>>;
    /** #virtual */
    pageInfo: PageInfo;
    requestTrackingToken: Scalars['String']['output'];
    service: Scalars['String']['output'];
    total: Scalars['Int']['output'];
  };

/** Specifies the field to sort by */
export const enum ContentPageSortField {
  CreatedAt = 'created_at',
  DateFollowed = 'date_followed',
  PageFollowsCount = 'page_follows_count',
  Title = 'title',
}

export const enum ContentPageType {
  Industry = 'industry',
  Insider = 'insider',
  PressReview = 'press_review',
  Publisher = 'publisher',
  Topic = 'topic',
}

export type ContentPageUnfollowInput = {
  pageId: Scalars['ID']['input'];
};

export type ContentPagesConnection = HasCursorPagination & {
  __typename?: 'ContentPagesConnection';
  /** #logged-out #soft-logged-in */
  edges: Array<Maybe<ContentPageEdge>>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type ContentPagesRecommended = {
  __typename?: 'ContentPagesRecommended';
  /** #logged-out #soft-logged-in */
  key: ReducedContentPageType;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  pages: Array<ContentPageInterface>;
  /** #logged-out #soft-logged-in */
  totalPages: Scalars['Int']['output'];
};

export type ContentPagesRecommendedCollection = {
  __typename?: 'ContentPagesRecommendedCollection';
  /** #logged-out #soft-logged-in */
  collection: Array<ContentPagesRecommended>;
};

export type ContentPurchaseInterface = {
  id: Scalars['ID']['output'];
  priceCents: Scalars['Int']['output'];
};

export type ContentPurchaseList = {
  __typename?: 'ContentPurchaseList';
  collection?: Maybe<Array<Maybe<ContentPurchaseInterface>>>;
};

export const enum ContentPurchaseType {
  All = 'ALL',
  Article = 'ARTICLE',
  Bundle = 'BUNDLE',
  Daypass = 'DAYPASS',
  Daypass365 = 'DAYPASS365',
  Subscription = 'SUBSCRIPTION',
}

export const enum ContentResourceType {
  ArticlesArticle = 'ARTICLES_ARTICLE',
  ContentFulltextArticle = 'CONTENT_FULLTEXT_ARTICLE',
  ContentKlarticle = 'CONTENT_KLARTICLE',
  ContentLinkShareArticle = 'CONTENT_LINK_SHARE_ARTICLE',
  ContentPageArticle = 'CONTENT_PAGE_ARTICLE',
  ContentVideoArticle = 'CONTENT_VIDEO_ARTICLE',
}

/** Representation of a generic piece of content */
export type ContentServiceContent = HasGlobalId & {
  __typename?: 'ContentServiceContent';
  /** #logged-out #soft-logged-in */
  blocks: Array<ArticleBlock>;
  /** #logged-out #soft-logged-in */
  content?: Maybe<Scalars['JSON']['output']>;
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  header?: Maybe<ArticleHeader>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Most recent version of the content; used for optimistic locking
   */
  lockVersion: Scalars['Int']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  URN to be used when integrating social interactions with this content
   */
  socialInteractionTargetUrn?: Maybe<Scalars['GlobalID']['output']>;
};

export type ContentServiceCreateContentInput = {
  blocks: Array<ArticlesCreateArticleBlocksInput>;
  content?: InputMaybe<Scalars['JSON']['input']>;
  contextGlobalId: Scalars['GlobalID']['input'];
  header?: InputMaybe<ArticlesCreateArticleHeaderInput>;
  supportedBlockTypes: Array<ArticleSupportedBlockTypes>;
};

export type ContentServiceError = {
  __typename?: 'ContentServiceError';
  /** contains all errors that occured */
  details?: Maybe<Array<Scalars['String']['output']>>;
  /** explains what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ContentServiceSaveContentResult = {
  __typename?: 'ContentServiceSaveContentResult';
  error?: Maybe<ContentServiceError>;
  success?: Maybe<ContentServiceContent>;
};

export type ContentServiceUpdateContentInput = {
  blocks: Array<ArticlesCreateArticleBlocksInput>;
  content?: InputMaybe<Scalars['JSON']['input']>;
  contentGlobalId: Scalars['GlobalID']['input'];
  header?: InputMaybe<ArticlesCreateArticleHeaderInput>;
  lockVersion: Scalars['Int']['input'];
  supportedBlockTypes: Array<ArticleSupportedBlockTypes>;
};

/** Specifies the sort direction (asc/desc) */
export const enum ContentSortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type ContentStarInput = {
  starableGlobalId: Scalars['GlobalID']['input'];
};

export type ContentStarableMutationResult = {
  __typename?: 'ContentStarableMutationResult';
  error?: Maybe<ContentError>;
  /** @deprecated use success field instead. Will be removed after NDIST-515 */
  result?: Maybe<ContentArticle>;
  success?: Maybe<ContentArticle>;
};

export type ContentStarableStarrersConnection = HasCursorPagination & {
  __typename?: 'ContentStarableStarrersConnection';
  /** XingIds of starrers in my contacts */
  edges: Array<ContentStarrerEdge>;
  /** #virtual */
  pageInfo: PageInfo;
  starableGlobalId: Scalars['GlobalID']['output'];
  total: Scalars['Int']['output'];
};

export type ContentStarrer = ContentSubscriberInterface & {
  __typename?: 'ContentStarrer';
  stub?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type ContentStarrerEdge = PaginationEdge & {
  __typename?: 'ContentStarrerEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: ContentStarrer;
};

export type ContentSubscriberInterface = {
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type ContentSubscriptionPurchase = ContentPurchaseInterface &
  ContentSubscriptionPurchaseInterface &
  HasDerivedGlobalId & {
    __typename?: 'ContentSubscriptionPurchase';
    canceledAt?: Maybe<Scalars['Date']['output']>;
    expiresAt?: Maybe<Scalars['Date']['output']>;
    /** Globally unique id for this instance based on typename and id */
    globalId: Scalars['GlobalID']['output'];
    id: Scalars['ID']['output'];
    priceCents: Scalars['Int']['output'];
    renewalDate?: Maybe<Scalars['Date']['output']>;
    teaser: ContentPage;
  };

export type ContentSubscriptionPurchaseInterface = {
  expiresAt?: Maybe<Scalars['Date']['output']>;
  teaser: ContentPage;
};

export type ContentTopic = {
  __typename?: 'ContentTopic';
  /** #logged-out #soft-logged-in */
  name: Scalars['String']['output'];
};

export type ContentTopicCollection = {
  __typename?: 'ContentTopicCollection';
  /** #logged-out #soft-logged-in */
  collection: Array<ContentTopic>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type ContentTopicInput = {
  name: Scalars['String']['input'];
};

export type ContentUnbookmarkInput = {
  bookmarkableGlobalId: Scalars['GlobalID']['input'];
};

export type ContentUnfeatureArticleInput = {
  globalId?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type ContentUnstarInput = {
  starableGlobalId: Scalars['GlobalID']['input'];
};

export type ContentUpdateEmailSubscriptionInput = {
  hasEmail?: InputMaybe<Scalars['Boolean']['input']>;
  pageId: Scalars['ID']['input'];
};

export type ContentUpdateEmailSubscriptionPayload = {
  error?: Maybe<ContentError>;
  response?: Maybe<EmailSubscription>;
};

export type ContentUpdateEmailSubscriptionPayloadError =
  ContentUpdateEmailSubscriptionPayload & {
    __typename?: 'ContentUpdateEmailSubscriptionPayloadError';
    error?: Maybe<ContentError>;
    response?: Maybe<EmailSubscription>;
  };

export type ContentUpdateEmailSubscriptionPayloadSuccess =
  ContentUpdateEmailSubscriptionPayload & {
    __typename?: 'ContentUpdateEmailSubscriptionPayloadSuccess';
    error?: Maybe<ContentError>;
    response?: Maybe<EmailSubscription>;
  };

export type ContentUpdatePageInput = {
  acceptTermsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  globalId: Scalars['GlobalID']['input'];
  topics?: InputMaybe<Array<InputMaybe<ContentTopicInput>>>;
};

export type ConversationStarter = {
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type ConversationStarterEdge = PaginationEdge & {
  __typename?: 'ConversationStarterEdge';
  cursor: Scalars['String']['output'];
  node: ConversationStarter;
};

export type ConversationStartersConnection = HasCursorPagination & {
  __typename?: 'ConversationStartersConnection';
  edges: Array<Maybe<ConversationStarterEdge>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export const enum ConversionType {
  ApplicationManagementAtsPaid = 'APPLICATION_MANAGEMENT_ATS_PAID',
  ApplicationManagementAtsUnpaid = 'APPLICATION_MANAGEMENT_ATS_UNPAID',
  EmailType = 'EMAIL_TYPE',
  NotConverted = 'NOT_CONVERTED',
  UrlTypeWithDescriptionEmail = 'URL_TYPE_WITH_DESCRIPTION_EMAIL',
}

export type ConvertedReplySettingsJob = {
  __typename?: 'ConvertedReplySettingsJob';
  /** #logged-out #soft-logged-in */
  conversionType: ConversionType;
  /** #logged-out #soft-logged-in */
  converted: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['Long']['output'];
  /** #logged-out #soft-logged-in */
  replyEmail?: Maybe<Scalars['String']['output']>;
};

/** Settings stored in user properties */
export type CoreSettings = {
  __typename?: 'CoreSettings';
  /** I'd like to receive an e-mail about my bookmarked jobs. */
  bookmarkReminderEmail?: Maybe<ToggleSetting>;
  /** User data should be deleted and not inherited after death */
  deleteUserDataAfterDecease?: Maybe<ToggleSetting>;
  /** When there are updates to my Jobs account */
  marketPlaceExpirationMsg?: Maybe<ToggleSetting>;
  /** Whether user wants to recieve baller league newsletter or not */
  marketingNlBallerLeague?: Maybe<ToggleSetting>;
  /** Events you may be interested in */
  marketingNlEvents?: Maybe<ToggleSetting>;
  /** For organisers: Event tips and offers on XING */
  marketingNlEventsOffers?: Maybe<ToggleSetting>;
  /** Freelance offers you may be interested in */
  marketingNlFreelancerOffers?: Maybe<ToggleSetting>;
  /** Job search and career details */
  marketingNlJobCareer?: Maybe<ToggleSetting>;
  /** Local events you may be interested in */
  marketingNlLocalEvents?: Maybe<ToggleSetting>;
  /** For group moderators: Groups information and tips on XING */
  marketingNlModerator?: Maybe<ToggleSetting>;
  /** For new members: Getting started on XING (in the first few weeks after joining) */
  marketingNlNewMembers?: Maybe<ToggleSetting>;
  /** Information about finding employers and the new world of work */
  marketingNlNewWork?: Maybe<ToggleSetting>;
  /** For XING Premium and ProBusiness: Information about new features and special offers */
  marketingNlPremiumInfos?: Maybe<ToggleSetting>;
  /** Premium membership and ProBusiness add-on details and offers */
  marketingNlPremiumOffers?: Maybe<ToggleSetting>;
  /** Details and offers for ProCoach and Executives members */
  marketingNlProcoachInfos?: Maybe<ToggleSetting>;
  /** Details and offers about specific XING memberships and add-ons (e.g. ProCoach or Executive) */
  marketingNlProcoachOffers?: Maybe<ToggleSetting>;
  /** For ProCoach members: Details and offers */
  marketingNlProjobsInfos?: Maybe<ToggleSetting>;
  /** ProJobs add-on details and offers */
  marketingNlProjobsOffers?: Maybe<ToggleSetting>;
  /** For employers and recruiters: Tips and information */
  marketingNlRecruiter?: Maybe<ToggleSetting>;
  /** For employers and recruiters: Invitation to XING recruiting events */
  marketingNlRecruitingEvents?: Maybe<ToggleSetting>;
  /** For advertisers: XING marketing and advertising updates */
  marketingNlSalesAdvertising?: Maybe<ToggleSetting>;
  /** We value your opinion: XING surveys */
  marketingNlSurveys?: Maybe<ToggleSetting>;
  /** I'd like to receive useful information about creating ads on XING. */
  marketingNlXasDraftReminder?: Maybe<ToggleSetting>;
  /** Updates and handy hints about XING features */
  marketingNlXingFeatures?: Maybe<ToggleSetting>;
  /** Information about XING partner offers */
  marketingNlXingPartners?: Maybe<ToggleSetting>;
  /** Information about XING special offers and prize draws */
  marketingNlXingPromotions?: Maybe<ToggleSetting>;
  /** XING recommendations (e.g. interesting groups, jobs and Industry News) */
  marketingNlXingRecos?: Maybe<ToggleSetting>;
  /** When someone I've invited joins XING */
  notifyInviteComplete?: Maybe<ToggleSetting>;
  /** I'd like to receive regular contact suggestions regarding interesting members (recommended). */
  notifyMemberRecommendations?: Maybe<ToggleSetting>;
  /** When someone comments on something I posted, commented on or liked (updates and topics) */
  notifyOnActivityComment?: Maybe<ToggleSetting>;
  /** When someone likes something I posted */
  notifyOnActivityLike?: Maybe<ToggleSetting>;
  /** I'd like to receive a daily e-mail informing me of my contacts' birthdays (never miss a birthday again)! */
  notifyOnBirthdays?: Maybe<ToggleSetting>;
  /** When I receive new contact requests */
  notifyOnContactRequest?: Maybe<ToggleSetting>;
  /** When a contact of mine has a new position or employer */
  notifyOnContactsWorkexperienceChange?: Maybe<ToggleSetting>;
  /** When someone sends me a XING message */
  notifyOnPrivateMessage?: Maybe<ToggleSetting>;
  /** When someone mentions me */
  notifyOnSocialMention?: Maybe<ToggleSetting>;
  /** When someone shares something I posted */
  notifyOnSocialShare?: Maybe<ToggleSetting>;
  /** When I have not replied to a message */
  notifyOnUnansweredMessage?: Maybe<ToggleSetting>;
  /** If I have any pending contact requests */
  notifyUnconfirmedContactReminder?: Maybe<ToggleSetting>;
  /** I'm interested in receiving information and offers by post. */
  printMailing?: Maybe<ToggleSetting>;
  /** ProJobs only: I'd like receive e-mail notifications if recruiters visit my profile (recommended). */
  projobsNewsletter?: Maybe<ToggleSetting>;
  /** I'd like to receive invitations to apply for a job. */
  receiveInvitationsJobs?: Maybe<ToggleSetting>;
  /** I'd like to receive an e-mail about similar job recos. */
  similarJobRecoEmail?: Maybe<ToggleSetting>;
  /** Whether user should be tracked by external partners */
  trackingThirdpartyExternal?: Maybe<ToggleSetting>;
  /** Whether user should be tracked by external marketing partners */
  trackingThirdpartyMarketing?: Maybe<ToggleSetting>;
  /** Whether user should be tracked by external statistical partners */
  trackingThirdpartyStatistical?: Maybe<ToggleSetting>;
  /** I'd like to receive a weekly newsletter with my personal statistics */
  weeklyNewsletter?: Maybe<ToggleSetting>;
  /** I'd like to receive a weekly e-mail with personal job recommendations if I state in my profile that I'm interested in career opportunities (recommended). */
  weeklyRecoJobs?: Maybe<ToggleSetting>;
};

export type CoreSettingsInput = {
  /** I'd like to receive an e-mail about my bookmarked jobs. */
  bookmarkReminderEmail?: InputMaybe<ToggleSettingInput>;
  /** User data should be deleted and not inherited after death */
  deleteUserDataAfterDecease?: InputMaybe<ToggleSettingInput>;
  /** When there are updates to my Jobs account */
  marketPlaceExpirationMsg?: InputMaybe<ToggleSettingInput>;
  /** Whether user wants to recieve baller league newsletter or not */
  marketingNlBallerLeague?: InputMaybe<ToggleSettingInput>;
  /** Events you may be interested in */
  marketingNlEvents?: InputMaybe<ToggleSettingInput>;
  /** For organisers: Event tips and offers on XING */
  marketingNlEventsOffers?: InputMaybe<ToggleSettingInput>;
  /** Freelance offers you may be interested in */
  marketingNlFreelancerOffers?: InputMaybe<ToggleSettingInput>;
  /** Job search and career details */
  marketingNlJobCareer?: InputMaybe<ToggleSettingInput>;
  /** Local events you may be interested in */
  marketingNlLocalEvents?: InputMaybe<ToggleSettingInput>;
  /** For group moderators: Groups information and tips on XING */
  marketingNlModerator?: InputMaybe<ToggleSettingInput>;
  /** For new members: Getting started on XING (in the first few weeks after joining) */
  marketingNlNewMembers?: InputMaybe<ToggleSettingInput>;
  /** Information about finding employers and the new world of work */
  marketingNlNewWork?: InputMaybe<ToggleSettingInput>;
  /** For XING Premium and ProBusiness: Information about new features and special offers */
  marketingNlPremiumInfos?: InputMaybe<ToggleSettingInput>;
  /** Premium membership and ProBusiness add-on details and offers */
  marketingNlPremiumOffers?: InputMaybe<ToggleSettingInput>;
  /** Details and offers for ProCoach and Executives members */
  marketingNlProcoachInfos?: InputMaybe<ToggleSettingInput>;
  /** Details and offers about specific XING memberships and add-ons (e.g. ProCoach or Executive) */
  marketingNlProcoachOffers?: InputMaybe<ToggleSettingInput>;
  /** For ProCoach members: Details and offers */
  marketingNlProjobsInfos?: InputMaybe<ToggleSettingInput>;
  /** ProJobs add-on details and offers */
  marketingNlProjobsOffers?: InputMaybe<ToggleSettingInput>;
  /** For employers and recruiters: Tips and information */
  marketingNlRecruiter?: InputMaybe<ToggleSettingInput>;
  /** For employers and recruiters: Invitation to XING recruiting events */
  marketingNlRecruitingEvents?: InputMaybe<ToggleSettingInput>;
  /** For advertisers: XING marketing and advertising updates */
  marketingNlSalesAdvertising?: InputMaybe<ToggleSettingInput>;
  /** We value your opinion: XING surveys */
  marketingNlSurveys?: InputMaybe<ToggleSettingInput>;
  /** I'd like to receive useful information about creating ads on XING. */
  marketingNlXasDraftReminder?: InputMaybe<ToggleSettingInput>;
  /** Updates and handy hints about XING features */
  marketingNlXingFeatures?: InputMaybe<ToggleSettingInput>;
  /** Information about XING partner offers */
  marketingNlXingPartners?: InputMaybe<ToggleSettingInput>;
  /** Information about XING special offers and prize draws */
  marketingNlXingPromotions?: InputMaybe<ToggleSettingInput>;
  /** XING recommendations (e.g. interesting groups, jobs and Industry News) */
  marketingNlXingRecos?: InputMaybe<ToggleSettingInput>;
  /** When someone I've invited joins XING */
  notifyInviteComplete?: InputMaybe<ToggleSettingInput>;
  /** I'd like to receive regular contact suggestions regarding interesting members (recommended). */
  notifyMemberRecommendations?: InputMaybe<ToggleSettingInput>;
  /** When someone comments on something I posted, commented on or liked (updates and topics) */
  notifyOnActivityComment?: InputMaybe<ToggleSettingInput>;
  /** When someone likes something I posted */
  notifyOnActivityLike?: InputMaybe<ToggleSettingInput>;
  /** I'd like to receive a daily e-mail informing me of my contacts' birthdays (never miss a birthday again)! */
  notifyOnBirthdays?: InputMaybe<ToggleSettingInput>;
  /** When I receive new contact requests */
  notifyOnContactRequest?: InputMaybe<ToggleSettingInput>;
  /** When a contact of mine has a new position or employer */
  notifyOnContactsWorkexperienceChange?: InputMaybe<ToggleSettingInput>;
  /** When someone sends me a XING message */
  notifyOnPrivateMessage?: InputMaybe<ToggleSettingInput>;
  /** When someone mentions me */
  notifyOnSocialMention?: InputMaybe<ToggleSettingInput>;
  /** When someone shares something I posted */
  notifyOnSocialShare?: InputMaybe<ToggleSettingInput>;
  /** When I have not replied to a message */
  notifyOnUnansweredMessage?: InputMaybe<ToggleSettingInput>;
  /** If I have any pending contact requests */
  notifyUnconfirmedContactReminder?: InputMaybe<ToggleSettingInput>;
  /** I'm interested in receiving information and offers by post. */
  printMailing?: InputMaybe<ToggleSettingInput>;
  /** ProJobs only: I'd like receive e-mail notifications if recruiters visit my profile (recommended). */
  projobsNewsletter?: InputMaybe<ToggleSettingInput>;
  /** I'd like to receive invitations to apply for a job. */
  receiveInvitationsJobs?: InputMaybe<ToggleSettingInput>;
  /** I'd like to receive an e-mail about similar job recos. */
  similarJobRecoEmail?: InputMaybe<ToggleSettingInput>;
  /** Whether user should be tracked by external partners */
  trackingThirdpartyExternal?: InputMaybe<ToggleSettingInput>;
  /** Whether user should be tracked by external marketing partners */
  trackingThirdpartyMarketing?: InputMaybe<ToggleSettingInput>;
  /** Whether user should be tracked by external statistical partners */
  trackingThirdpartyStatistical?: InputMaybe<ToggleSettingInput>;
  /** I'd like to receive a weekly newsletter with my personal statistics */
  weeklyNewsletter?: InputMaybe<ToggleSettingInput>;
  /** I'd like to receive a weekly e-mail with personal job recommendations if I state in my profile that I'm interested in career opportunities (recommended). */
  weeklyRecoJobs?: InputMaybe<ToggleSettingInput>;
};

export type CoreSettingsMutationResult = {
  __typename?: 'CoreSettingsMutationResult';
  error?: Maybe<CoreSettings>;
  response?: Maybe<CoreSettings>;
};

export type Country = Localizable & {
  __typename?: 'Country';
  /** #logged-out #soft-logged-in */
  countryCode: CountryCodesAlpha2;
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  provinces: Array<Province>;
};

export type CountryAggregation = HasAggregationCount & {
  __typename?: 'CountryAggregation';
  /** #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #soft-logged-in #rest(batched) #virtual */
  label?: Maybe<Country>;
  /** #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

/** [ISO-3166-1](https://en.wikipedia.org/wiki/ISO_3166-1) Alpha 2 country codes */
export const enum CountryCodesAlpha2 {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Netherlands Antilles - Transitionally reserved (deprecated) - https://en.wikipedia.org/wiki/ISO_3166-2:AN */
  An = 'AN',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei Darussalam */
  Bn = 'BN',
  /** Bolivia, Plurinational State of */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo, the Democratic Republic of the */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cape Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia, Federated States of */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran, Islamic Republic of */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Korea, Democratic People's Republic of */
  Kp = 'KP',
  /** Korea, Republic of */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Lao People's Democratic Republic */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova, Republic of */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** Macedonia, the Former Yugoslav Republic of */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russian Federation */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syrian Arab Republic */
  Sy = 'SY',
  /** Swaziland */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan, Province of China */
  Tw = 'TW',
  /** Tanzania, United Republic of */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See (Vatican City State) */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela, Bolivarian Republic of */
  Ve = 'VE',
  /** Virgin Islands, British */
  Vg = 'VG',
  /** Virgin Islands, U.S. */
  Vi = 'VI',
  /** Viet Nam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Kosovo */
  Xk = 'XK',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
}

export const enum CountryFilter {
  All = 'ALL',
  Other = 'OTHER',
  Top = 'TOP',
}

export type CoverImageUpdate = {
  __typename?: 'CoverImageUpdate';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  imageUuid: Scalars['UUID']['output'];
  /** #rest(batched) #virtual */
  profile?: Maybe<XingId>;
  url: Scalars['URL']['output'];
  urn: Scalars['GlobalID']['output'];
};

export type CreateCvUploadParsedCvResult = CvUploadError | CvUploadParsedCv;

export type CreateMessengerAttachmentConfirmation = {
  __typename?: 'CreateMessengerAttachmentConfirmation';
  message: Scalars['String']['output'];
};

export type CreateMessengerAttachmentMessageInput = {
  chatId: Scalars['ID']['input'];
  /** A unique identifier generated by the client application in order to avoid creating duplicates in retry mechanisms */
  clientId: Scalars['UUID']['input'];
  /** The context_id that controls things like QuickMessages */
  contextId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The upload_id received when uploading the image to the upload server.
   * @deprecated use `uploadToken` obtained from `messengerUploadRequest` MSNG-12
   */
  uploadId?: InputMaybe<Scalars['String']['input']>;
  /** The upload_id received when uploading the image to the messenger bypassed upload server. */
  uploadToken?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMessengerAttachmentMessageResult =
  | CreateMessengerAttachmentConfirmation
  | CreateMessengerMessageError;

export type CreateMessengerChat = {
  contextId?: InputMaybe<Scalars['String']['input']>;
  participants: Array<Scalars['ID']['input']>;
};

export type CreateMessengerChatBySlugOrId = {
  contextId?: InputMaybe<Scalars['String']['input']>;
  participants: Array<Scalars['SlugOrID']['input']>;
};

export type CreateMessengerChatError = {
  __typename?: 'CreateMessengerChatError';
  message: Scalars['String']['output'];
};

export type CreateMessengerChatResult = {
  __typename?: 'CreateMessengerChatResult';
  error?: Maybe<CreateMessengerChatError>;
  success?: Maybe<MessengerChat>;
};

export type CreateMessengerImageConfirmation = {
  __typename?: 'CreateMessengerImageConfirmation';
  message: Scalars['String']['output'];
};

export type CreateMessengerImageMessageInput = {
  chatId: Scalars['ID']['input'];
  /** A unique identifier generated by the client application in order to avoid creating duplicates in retry mechanisms */
  clientId: Scalars['UUID']['input'];
  /** The context_id that controls things like QuickMessages */
  contextId?: InputMaybe<Scalars['String']['input']>;
  /** The initiator_urn contains the urn of the object that was clicked to reach the messenger. */
  initiatorUrn?: InputMaybe<Scalars['URN']['input']>;
  /**
   * The upload_id received when uploading the image to the upload server.
   * @deprecated use `uploadToken` obtained from `messengerUploadRequest` MSNG-12
   */
  uploadId?: InputMaybe<Scalars['String']['input']>;
  /** The upload_id received when uploading the image to the messenger bypassed upload server. */
  uploadToken?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMessengerImageMessageResult =
  | CreateMessengerImageConfirmation
  | CreateMessengerMessageError;

export type CreateMessengerMessageError = {
  __typename?: 'CreateMessengerMessageError';
  message: Scalars['String']['output'];
};

export type CreateMessengerMessageInput = {
  /** The text body of the message */
  body: Scalars['String']['input'];
  chatId: Scalars['ID']['input'];
  /** A unique identifier generated by the client application in order to avoid creating duplicates in retry mechanisms */
  clientId: Scalars['UUID']['input'];
  /** The context_id that controls things like QuickMessages */
  contextId?: InputMaybe<Scalars['String']['input']>;
  /** The initiator_urn contains the urn of the object that was clicked to reach the messenger. */
  initiatorUrn?: InputMaybe<Scalars['URN']['input']>;
  /** The reference_urn used to create the system message when a user replies to stories, etc. */
  referenceUrn?: InputMaybe<Scalars['URN']['input']>;
  /** A reply reference */
  replyId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMessengerMessageResult = {
  __typename?: 'CreateMessengerMessageResult';
  error?: Maybe<CreateMessengerMessageError>;
  success?: Maybe<MessengerMessage>;
};

export type CreateMessengerMessageTemplateError = {
  __typename?: 'CreateMessengerMessageTemplateError';
  message: Scalars['String']['output'];
};

export type CreateMessengerMessageTemplateInput = {
  body: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateMessengerMessageTemplateResult =
  | CreateMessengerMessageTemplateError
  | CreateMessengerMessageTemplateSuccess;

export type CreateMessengerMessageTemplateSuccess = {
  __typename?: 'CreateMessengerMessageTemplateSuccess';
  success: MessengerMessageTemplatesInterface;
};

export type CreateMessengerOneClickSystemReplyMessageSuccess = {
  __typename?: 'CreateMessengerOneClickSystemReplyMessageSuccess';
  chatId: Scalars['ID']['output'];
  replyId: Scalars['String']['output'];
};

export type CreateMessengerSystemReplyMessageError = {
  __typename?: 'CreateMessengerSystemReplyMessageError';
  message: Scalars['String']['output'];
};

export type CreateMessengerSystemReplyMessageInput = {
  chatId: Scalars['ID']['input'];
  /** A unique identifier generated by the client application in order to avoid creating duplicates in retry mechanisms */
  clientId: Scalars['UUID']['input'];
  /** The context_id that controls things like QuickMessages */
  contextId?: InputMaybe<Scalars['String']['input']>;
  /** The list of items missing */
  missingInfoItems?: InputMaybe<Array<InputMaybe<MissingInfoItem>>>;
  /** A reply reference */
  replyId: Scalars['String']['input'];
};

export type CreateMessengerSystemReplyMessageResult =
  | CreateMessengerSystemReplyMessageError
  | CreateMessengerSystemReplyMessageSuccess;

export type CreateMessengerSystemReplyMessageSuccess = {
  __typename?: 'CreateMessengerSystemReplyMessageSuccess';
  success?: Maybe<MessengerMessage>;
};

export type CreateUserError = {
  __typename?: 'CreateUserError';
  /** #logged-out #soft-logged-in */
  error?: Maybe<Array<Maybe<CreateUserErrorFields>>>;
};

export type CreateUserErrorFields = {
  __typename?: 'CreateUserErrorFields';
  /** #logged-out #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  field?: Maybe<Scalars['String']['output']>;
};

export type CreateUserInput = {
  signupMinireg?: InputMaybe<CreateUserInputFields>;
};

export type CreateUserInputFields = {
  cvSource?: InputMaybe<Scalars['GlobalID']['input']>;
  cvUploadId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  parsedCvId?: InputMaybe<Scalars['ID']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  signupChannel?: InputMaybe<Scalars['String']['input']>;
  tandcCheck?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserMutationResult = CreateUserError | CreateUserSuccess;

export type CreateUserSuccess = {
  __typename?: 'CreateUserSuccess';
  /** #logged-out #soft-logged-in */
  success?: Maybe<CreateUserSuccessFields>;
};

export type CreateUserSuccessFields = {
  __typename?: 'CreateUserSuccessFields';
  /** #logged-out #soft-logged-in */
  hashedUserId: Scalars['String']['output'];
};

/** Input types for cover letter creation */
export type CreateVitaCoverLetterInput = {
  addressTo?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Array<VitaCoverLetterContentInput>>;
  educationExperience?: InputMaybe<
    Array<InputMaybe<CreateVitaDocumentCvEducationExperienceInput>>
  >;
  fileAttachments?: InputMaybe<CreateVitaDocumentFileAttachmentsInput>;
  jobDetails?: InputMaybe<VitaJobDetailsInput>;
  language?: InputMaybe<VitaDocumentLanguage>;
  origin?: InputMaybe<VitaDocumentOrigin>;
  personalInformation?: InputMaybe<CreateVitaDocumentCvPersonalInformationInput>;
  professionalExperience?: InputMaybe<
    Array<InputMaybe<CreateVitaDocumentCvProfessionalExperienceInput>>
  >;
  signature?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Array<InputMaybe<CreateVitaDoucmentCvSkillInput>>>;
  theme?: InputMaybe<CreateVitaDocumentThemeInput>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVitaDocumentCvEducationExperienceInput = {
  courseOfStudy: Scalars['String']['input'];
  degree?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  institution?: InputMaybe<Scalars['String']['input']>;
  isStillStudy: Scalars['Boolean']['input'];
  startDate: Scalars['Date']['input'];
};

export type CreateVitaDocumentCvInput = {
  aboutMe?: InputMaybe<Scalars['String']['input']>;
  educationExperience?: InputMaybe<
    Array<InputMaybe<CreateVitaDocumentCvEducationExperienceInput>>
  >;
  personalInformation?: InputMaybe<CreateVitaDocumentCvPersonalInformationInput>;
  preferences?: InputMaybe<CreateVitaDocumentCvPreferencesInput>;
  professionalExperience?: InputMaybe<
    Array<InputMaybe<CreateVitaDocumentCvProfessionalExperienceInput>>
  >;
  skills?: InputMaybe<Array<InputMaybe<CreateVitaDoucmentCvSkillInput>>>;
};

export type CreateVitaDocumentCvPersonalInformationInput = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  birthplace?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  occupation?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVitaDocumentCvPreferencesInput = {
  homeOffice?: InputMaybe<Scalars['Boolean']['input']>;
  locations?: InputMaybe<
    Array<InputMaybe<CreateVitaDocumentCvPreferencesLocationInput>>
  >;
  otherSeekingReason?: InputMaybe<Scalars['String']['input']>;
  seekingReason?: InputMaybe<VitaSeekingReason>;
  seekingStatus: SeekingStatus;
  targetPosition?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVitaDocumentCvPreferencesLocationInput = {
  cityId: Scalars['Int']['input'];
  cityIdWithScrambling?: InputMaybe<Scalars['String']['input']>;
  cityName: Scalars['String']['input'];
};

export type CreateVitaDocumentCvProfessionalExperienceDescriptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVitaDocumentCvProfessionalExperienceDisciplineInput = {
  id: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVitaDocumentCvProfessionalExperienceIndustryInput = {
  id: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVitaDocumentCvProfessionalExperienceInput = {
  company: Scalars['String']['input'];
  descriptions?: InputMaybe<
    Array<
      InputMaybe<CreateVitaDocumentCvProfessionalExperienceDescriptionInput>
    >
  >;
  discipline: CreateVitaDocumentCvProfessionalExperienceDisciplineInput;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  industry: CreateVitaDocumentCvProfessionalExperienceIndustryInput;
  isCurrentEmployer: Scalars['Boolean']['input'];
  jobLevel: CreateVitaDocumentCvProfessionalExperienceJobLevelInput;
  occupation: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};

export type CreateVitaDocumentCvProfessionalExperienceJobLevelInput = {
  id: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVitaDocumentError = {
  __typename?: 'CreateVitaDocumentError';
  /** #logged-out #soft-logged-in */
  message: Scalars['String']['output'];
};

export type CreateVitaDocumentFileAttachmentInput = {
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVitaDocumentFileAttachmentsInput = {
  profileImage?: InputMaybe<CreateVitaDocumentFileAttachmentInput>;
};

export type CreateVitaDocumentImportInput = {
  aboutMe?: InputMaybe<VitaProfileAboutMeInput>;
  educationExperience?: InputMaybe<VitaProfileEducationCollectionInput>;
  personalInformation?: InputMaybe<VitaImportDocumentPersonalInformationInput>;
  professionalExperience?: InputMaybe<VitaProfileWorkExperienceCollectionInput>;
  skills?: InputMaybe<VitaProfileSkillsInput>;
};

export type CreateVitaDocumentInput = {
  cv?: InputMaybe<CreateVitaDocumentCvInput>;
  fileAttachments?: InputMaybe<CreateVitaDocumentFileAttachmentsInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  language?: InputMaybe<VitaDocumentLanguage>;
  origin?: InputMaybe<VitaDocumentOrigin>;
  theme?: InputMaybe<CreateVitaDocumentThemeInput>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVitaDocumentResult =
  | CreateVitaDocumentError
  | VitaCoverLetter
  | VitaDocument;

export type CreateVitaDocumentThemeInput = {
  color?: InputMaybe<VitaDocumentThemeColor>;
  template?: InputMaybe<VitaDocumentThemeTemplate>;
};

export type CreateVitaDoucmentCvSkillInput = {
  skill?: InputMaybe<Scalars['String']['input']>;
};

/**
 * INPUTS to create vita document from Profile imports - ONLY
 * Is used to pass in data: the response from `vitaProfileImport` viewer
 *
 * Inputs are vita specific and describes equivalent types from Profiles, AccountSettings
 * and XingIdContactDetails.
 */
export type CreateVitaProfileImportInput = {
  data?: InputMaybe<CreateVitaDocumentImportInput>;
  language?: InputMaybe<VitaDocumentLanguage>;
  origin?: InputMaybe<VitaDocumentOrigin>;
  theme?: InputMaybe<CreateVitaDocumentThemeInput>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWebPushSubscriptionError = {
  __typename?: 'CreateWebPushSubscriptionError';
  /** #logged-out #soft-logged-in */
  message: Scalars['String']['output'];
};

export type CreateWebPushSubscriptionInput = {
  jobId: Scalars['ID']['input'];
  locale: Scalars['String']['input'];
  notificationEnv: Scalars['String']['input'];
  visitorId: Scalars['String']['input'];
};

export type CreateWebPushSubscriptionResult =
  | CreateWebPushSubscriptionError
  | WebPushSubscription;

export type CreateWorkExperienceInput = {
  beginDate: ProfileInputYearAndMonth;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  disciplineId?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<ProfileInputYearAndMonth>;
  industryId: Scalars['ID']['input'];
  jobTitle: Scalars['String']['input'];
  levelId?: InputMaybe<Scalars['ID']['input']>;
  profileId: Scalars['ID']['input'];
  statusId: Scalars['ID']['input'];
};

export type CreditCardData = BpayPaymentMethodData & {
  __typename?: 'CreditCardData';
  brand?: Maybe<Scalars['String']['output']>;
  clientName: Scalars['String']['output'];
  expiryDate?: Maybe<Scalars['String']['output']>;
  holder?: Maybe<Scalars['String']['output']>;
  maskedNumber?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type CreditCardUrl = {
  __typename?: 'CreditCardUrl';
  /** #soft-logged-in */
  errors?: Maybe<Array<Maybe<PaymentsAttributeMessage>>>;
  /**
   * #soft-logged-in
   *
   *  url for creditcard payment flow / pop-up
   */
  url?: Maybe<Scalars['String']['output']>;
};

export type CulturalAssessmentProposalSignal = NetworkUpdatesStackItem & {
  __typename?: 'CulturalAssessmentProposalSignal';
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest #virtual */
  object?: Maybe<MoveonCompanyCulturePreferencesAssessmentDataResult>;
  stackId: Scalars['ID']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type CulturalAssessmentProposalStack = SignalsStack & {
  __typename?: 'CulturalAssessmentProposalStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type CulturalAssessmentProposalStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents [ISO-4217](https://en.wikipedia.org/wiki/ISO_4217#Active_codes) currency codes. */
export const enum Currency {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Xxx = 'XXX',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL',
}

export type CurrentCompaniesInsight = {
  __typename?: 'CurrentCompaniesInsight';
  candidatesCount: Scalars['Int']['output'];
  companies: Array<Maybe<CurrentCompanyInsight>>;
  xtmUrl: Scalars['URL']['output'];
};

export type CurrentCompanyInsight = {
  __typename?: 'CurrentCompanyInsight';
  avatars?: Maybe<Array<Maybe<Scalars['URL']['output']>>>;
  candidatesCount: Scalars['Int']['output'];
  company: CompanyProfileInsight;
  destinations?: Maybe<Array<Maybe<CompanyProfileInsight>>>;
  growth?: Maybe<Scalars['Float']['output']>;
  xtmUrl: Scalars['URL']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  agencyId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CvUploadAddress = {
  __typename?: 'CvUploadAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CvUploadCode>;
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<CvUploadCode>;
  street?: Maybe<Scalars['String']['output']>;
  subRegion?: Maybe<CvUploadCode>;
};

export type CvUploadCode = {
  __typename?: 'CvUploadCode';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export type CvUploadCourse = {
  __typename?: 'CvUploadCourse';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CvUploadCode>;
  courseName?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  institute?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type CvUploadCurrentWorkField = {
  __typename?: 'CvUploadCurrentWorkField';
  experienceLevelCode?: Maybe<Scalars['String']['output']>;
  experienceLevelDescription?: Maybe<Scalars['String']['output']>;
  workFieldCode?: Maybe<Scalars['String']['output']>;
  workFieldDescription?: Maybe<Scalars['String']['output']>;
};

export type CvUploadDegree = {
  __typename?: 'CvUploadDegree';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CvUploadCode>;
  degreeName?: Maybe<Scalars['String']['output']>;
  diplomaCode?: Maybe<Scalars['String']['output']>;
  diplomaDescription?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  gradePointAverage?: Maybe<Scalars['String']['output']>;
  inernationalDescription?: Maybe<Scalars['String']['output']>;
  institute?: Maybe<Scalars['String']['output']>;
  internationalCode?: Maybe<Scalars['String']['output']>;
  localCode?: Maybe<Scalars['String']['output']>;
  localDescription?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type CvUploadDriversLicense = {
  __typename?: 'CvUploadDriversLicense';
  name?: Maybe<Scalars['String']['output']>;
};

export type CvUploadEducationHistory = {
  __typename?: 'CvUploadEducationHistory';
  courses?: Maybe<Array<Maybe<CvUploadCourse>>>;
  degrees?: Maybe<Array<Maybe<CvUploadDegree>>>;
};

export type CvUploadEmail = {
  __typename?: 'CvUploadEmail';
  /** #logged-out #soft-logged-in */
  email?: Maybe<Scalars['String']['output']>;
};

export type CvUploadEmploymentHistory = {
  __typename?: 'CvUploadEmploymentHistory';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CvUploadCode>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  iSCO2008Code?: Maybe<Scalars['String']['output']>;
  iSCO2008Description?: Maybe<Scalars['String']['output']>;
  isCurrentExperience?: Maybe<Scalars['String']['output']>;
  isLatestExperience?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  months?: Maybe<Scalars['String']['output']>;
  oNET2010Code?: Maybe<Scalars['String']['output']>;
  oNET2010Description?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  professionClassCode?: Maybe<Scalars['String']['output']>;
  professionClassDescription?: Maybe<Scalars['String']['output']>;
  professionCode?: Maybe<Scalars['String']['output']>;
  professionDescription?: Maybe<Scalars['String']['output']>;
  professionGroupCode?: Maybe<Scalars['String']['output']>;
  professionGroupDescription?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  years?: Maybe<Scalars['String']['output']>;
};

export type CvUploadError = {
  __typename?: 'CvUploadError';
  description?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CvUploadHighestDegree = {
  __typename?: 'CvUploadHighestDegree';
  endDate?: Maybe<Scalars['String']['output']>;
  internationalCode?: Maybe<Scalars['String']['output']>;
  internationalDescription?: Maybe<Scalars['String']['output']>;
  localCode?: Maybe<Scalars['String']['output']>;
  localDescription?: Maybe<Scalars['String']['output']>;
};

export type CvUploadHobby = {
  __typename?: 'CvUploadHobby';
  hobby?: Maybe<Scalars['String']['output']>;
};

export type CvUploadLanguageSkill = {
  __typename?: 'CvUploadLanguageSkill';
  lastUsed?: Maybe<Scalars['String']['output']>;
  levelCode?: Maybe<Scalars['String']['output']>;
  levelDescription?: Maybe<Scalars['String']['output']>;
  skillCode?: Maybe<Scalars['String']['output']>;
  skillDescription?: Maybe<Scalars['String']['output']>;
  years?: Maybe<Scalars['String']['output']>;
};

export type CvUploadOther = {
  __typename?: 'CvUploadOther';
  hobbies?: Maybe<Array<Maybe<CvUploadHobby>>>;
  profilePicture?: Maybe<CvUploadProfilePicture>;
};

export type CvUploadParsedCv = {
  __typename?: 'CvUploadParsedCv';
  /** A string identifying the conceptual user flow from which a parsed_cv originates. While one job-posting could be the source (See above), there might be different used-flows that lead to the creation of a parsed_cv. */
  flowIdentifier?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this parsed_cv originates from */
  source?: Maybe<Scalars['URN']['output']>;
  /** #logged-out #soft-logged-in */
  textkernelPayload?: Maybe<CvUploadParsedCvTextkernelPayload>;
};

export type CvUploadParsedCvTextkernelPayload = {
  __typename?: 'CvUploadParsedCvTextkernelPayload';
  educationHistory?: Maybe<CvUploadEducationHistory>;
  employmentHistory?: Maybe<Array<Maybe<CvUploadEmploymentHistory>>>;
  file?: Maybe<Scalars['String']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  other?: Maybe<CvUploadOther>;
  /** #logged-out #soft-logged-in */
  personal?: Maybe<CvUploadPersonal>;
  skills?: Maybe<CvUploadSkills>;
  summary?: Maybe<CvUploadSummary>;
};

export type CvUploadPersonal = {
  __typename?: 'CvUploadPersonal';
  address?: Maybe<CvUploadAddress>;
  birthDate?: Maybe<Scalars['String']['output']>;
  completeName?: Maybe<Scalars['String']['output']>;
  driversLicenses?: Maybe<Array<Maybe<CvUploadDriversLicense>>>;
  /** #logged-out #soft-logged-in */
  emails?: Maybe<Array<Maybe<CvUploadEmail>>>;
  /** #logged-out #soft-logged-in */
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<CvUploadCode>;
  initials?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  lastName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  middleName?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<CvUploadCode>;
  personalUrls?: Maybe<Array<Maybe<CvUploadPersonalUrl>>>;
  phones?: Maybe<Array<Maybe<CvUploadPhone>>>;
};

export type CvUploadPersonalUrl = {
  __typename?: 'CvUploadPersonalUrl';
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type CvUploadPhone = {
  __typename?: 'CvUploadPhone';
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CvUploadProfilePicture = {
  __typename?: 'CvUploadProfilePicture';
  base64Content?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
};

export type CvUploadSkill = {
  __typename?: 'CvUploadSkill';
  lastUsed?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  skill?: Maybe<Scalars['String']['output']>;
  skillCode?: Maybe<Scalars['String']['output']>;
  skillDescription?: Maybe<Scalars['String']['output']>;
  years?: Maybe<Scalars['String']['output']>;
};

export type CvUploadSkills = {
  __typename?: 'CvUploadSkills';
  computerSkills?: Maybe<Array<Maybe<CvUploadSkill>>>;
  languageSkills?: Maybe<Array<Maybe<CvUploadLanguageSkill>>>;
  otherSkills?: Maybe<Array<Maybe<CvUploadSkill>>>;
  softSkills?: Maybe<Array<Maybe<CvUploadSkill>>>;
};

export type CvUploadSummary = {
  __typename?: 'CvUploadSummary';
  currentEmployer?: Maybe<Scalars['String']['output']>;
  currentJob?: Maybe<Scalars['String']['output']>;
  currentWorkField?: Maybe<CvUploadCurrentWorkField>;
  extraCurricularSection?: Maybe<Scalars['String']['output']>;
  highestDegree?: Maybe<CvUploadHighestDegree>;
  summaryAmbitionSection?: Maybe<Scalars['String']['output']>;
  totalExperienceMonths?: Maybe<Scalars['String']['output']>;
  totalExperienceYears?: Maybe<Scalars['String']['output']>;
};

export type DataScienceCompactTrackingInput = {
  messages: Array<CompactTrackingInput>;
  source: TrackingSource;
};

export type DataScienceFullTrackingInput = {
  messages: Array<FullTrackingInput>;
  source: TrackingSource;
};

export type DataSharingExclusionInput = {
  id: Scalars['ID']['input'];
  type: DataSharingExclusionType;
};

export type DataSharingExclusionMutationError = {
  __typename?: 'DataSharingExclusionMutationError';
  error?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type DataSharingExclusionMutationResult = {
  __typename?: 'DataSharingExclusionMutationResult';
  error?: Maybe<DataSharingExclusionMutationError>;
  response?: Maybe<DataSharingVisibilityExclusion>;
};

export const enum DataSharingExclusionType {
  BusinessData = 'BUSINESS_DATA',
  DateOfBirth = 'DATE_OF_BIRTH',
  PrivateData = 'PRIVATE_DATA',
}

export type DataSharingSettings = {
  __typename?: 'DataSharingSettings';
  /**
   * #rest #virtual
   *
   *  Blacklist of contacts for visibility of birth date
   */
  birthDateVisibilityExclusions?: Maybe<DataSharingVisibilityExclusions>;
  /** User setting for visibility of business profile data for other users */
  businessDataVisibility?: Maybe<SelectSetting>;
  /**
   * #rest #virtual
   *
   *  Blacklist of contacts for visibility of business profile data
   */
  businessDataVisibilityExclusions?: Maybe<DataSharingVisibilityExclusions>;
  /** User setting for visibility of birth date for other users */
  dateOfBirthVisibility?: Maybe<SelectSetting>;
  /** User setting for visibility of private profile data for other users */
  privateDataVisibility?: Maybe<SelectSetting>;
  /**
   * #rest #virtual
   *
   *  Blacklist of contacts for visibility of private profile data
   */
  privateDataVisibilityExclusions?: Maybe<DataSharingVisibilityExclusions>;
  /** User setting for visibility of year of birth (= age) for other users */
  yearOfBirthVisibility?: Maybe<ToggleSetting>;
};

export type DataSharingSettingsInput = {
  businessDataVisibility?: InputMaybe<SelectSettingInput>;
  dateOfBirthVisibility?: InputMaybe<SelectSettingInput>;
  privateDataVisibility?: InputMaybe<SelectSettingInput>;
  yearOfBirthVisibility?: InputMaybe<ToggleSettingInput>;
};

export type DataSharingSettingsMutationResult = {
  __typename?: 'DataSharingSettingsMutationResult';
  error?: Maybe<DataSharingSettings>;
  response?: Maybe<DataSharingSettings>;
};

export type DataSharingVisibilityExclusion = {
  __typename?: 'DataSharingVisibilityExclusion';
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type DataSharingVisibilityExclusions = {
  __typename?: 'DataSharingVisibilityExclusions';
  collection?: Maybe<Array<DataSharingVisibilityExclusion>>;
};

export type DateRange = {
  __typename?: 'DateRange';
  /** #logged-out #soft-logged-in */
  from: Scalars['Date']['output'];
  /** #logged-out #soft-logged-in */
  to?: Maybe<Scalars['Date']['output']>;
};

export type DeactivationSetting = {
  __typename?: 'DeactivationSetting';
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type DeactivationSettingInput = {
  passwordToken: Scalars['String']['input'];
  /** when the deactivated account should be automatically reactivated */
  reactivateAfter: Scalars['Date']['input'];
};

export type DeleteCareerLevelPreferenceError = {
  __typename?: 'DeleteCareerLevelPreferenceError';
  /**
   * #logged-out #soft-logged-in
   *
   *  tells what went wrong
   */
  message?: Maybe<Scalars['String']['output']>;
};

/** Result type for deleting career level preference that returns 200 on success */
export type DeleteCareerLevelPreferenceResult =
  | DeleteCareerLevelPreferenceError
  | DeleteCareerLevelPreferenceSuccess;

export type DeleteCareerLevelPreferenceSuccess = {
  __typename?: 'DeleteCareerLevelPreferenceSuccess';
  /** #logged-out #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

export type DeleteCompanyRecommendationError = {
  __typename?: 'DeleteCompanyRecommendationError';
  message?: Maybe<Scalars['String']['output']>;
};

export type DeleteCompanyRecommendationInput = {
  companyId: Scalars['ID']['input'];
};

export type DeleteCompanyRecommendationResult = {
  __typename?: 'DeleteCompanyRecommendationResult';
  error?: Maybe<DeleteCompanyRecommendationError>;
};

export type DeleteLocationsPreferenceError = {
  __typename?: 'DeleteLocationsPreferenceError';
  /**
   * #logged-out #soft-logged-in
   *
   *  tells what went wrong
   */
  message?: Maybe<Scalars['String']['output']>;
};

/** Result type for deleting locations preference that returns 200 on success */
export type DeleteLocationsPreferenceResult =
  | DeleteLocationsPreferenceError
  | DeleteLocationsPreferenceSuccess;

export type DeleteLocationsPreferenceSuccess = {
  __typename?: 'DeleteLocationsPreferenceSuccess';
  /** #logged-out #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

export type DeleteLoginSessionError = {
  __typename?: 'DeleteLoginSessionError';
  message?: Maybe<Scalars['String']['output']>;
};

export type DeleteLoginSessionInput = {
  sessionId: Scalars['ID']['input'];
};

export type DeleteLoginSessionPayload = {
  __typename?: 'DeleteLoginSessionPayload';
  error?: Maybe<DeleteLoginSessionError>;
};

export type DeleteMessengerChatInput = {
  id: Scalars['ID']['input'];
};

export type DeleteMessengerChatParticipantError = {
  __typename?: 'DeleteMessengerChatParticipantError';
  message: Scalars['String']['output'];
};

export type DeleteMessengerChatParticipantInput = {
  chatId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type DeleteMessengerChatParticipantResult =
  | DeleteMessengerChatParticipantError
  | DeleteMessengerChatParticipantSuccess;

export type DeleteMessengerChatParticipantSuccess = {
  __typename?: 'DeleteMessengerChatParticipantSuccess';
  success: Scalars['Boolean']['output'];
};

export type DeleteMessengerChatResult = {
  __typename?: 'DeleteMessengerChatResult';
  error?: Maybe<DeleteMessengerChatResultError>;
};

export type DeleteMessengerChatResultError = {
  __typename?: 'DeleteMessengerChatResultError';
  message: Scalars['String']['output'];
};

export type DeleteMessengerMessageTemplateError = {
  __typename?: 'DeleteMessengerMessageTemplateError';
  message: Scalars['String']['output'];
};

export type DeleteMessengerMessageTemplateInput = {
  id: Scalars['ID']['input'];
};

export type DeleteMessengerMessageTemplateResult =
  | DeleteMessengerMessageTemplateError
  | DeleteMessengerMessageTemplateSuccess;

export type DeleteMessengerMessageTemplateSuccess = {
  __typename?: 'DeleteMessengerMessageTemplateSuccess';
  success: Scalars['Boolean']['output'];
};

export type DeletePendingEmailPayload = {
  __typename?: 'DeletePendingEmailPayload';
  error?: Maybe<Scalars['String']['output']>;
};

export type DeletePendingPhonePayload = {
  __typename?: 'DeletePendingPhonePayload';
  error?: Maybe<Scalars['String']['output']>;
};

export type DeleteSalaryExpectationsPreferenceError = {
  __typename?: 'DeleteSalaryExpectationsPreferenceError';
  /**
   * #logged-out #soft-logged-in
   *
   *  tells what went wrong
   */
  message?: Maybe<Scalars['String']['output']>;
};

/** Result type for deleting salary expectations preference that returns 200 on success */
export type DeleteSalaryExpectationsPreferenceResult =
  | DeleteSalaryExpectationsPreferenceError
  | DeleteSalaryExpectationsPreferenceSuccess;

export type DeleteSalaryExpectationsPreferenceSuccess = {
  __typename?: 'DeleteSalaryExpectationsPreferenceSuccess';
  /** #logged-out #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

export type DeleteScheduledMessageInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserConnectionsResult = {
  __typename?: 'DeleteUserConnectionsResult';
  error?: Maybe<ErrorResponse>;
};

export type DeleteXwsSessionError = {
  __typename?: 'DeleteXwsSessionError';
  message?: Maybe<Scalars['String']['output']>;
};

export type DeleteXwsSessionInput = {
  id: Scalars['ID']['input'];
};

export type DeleteXwsSessionPayload = {
  __typename?: 'DeleteXwsSessionPayload';
  error?: Maybe<DeleteXwsSessionError>;
};

export type DeletedUserConnectionData = {
  __typename?: 'DeletedUserConnectionData';
  deleted: Scalars['Boolean']['output'];
};

export type DeletionReasonSettingInput = {
  id: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type DeletionReasons = {
  __typename?: 'DeletionReasons';
  id: Scalars['ID']['output'];
  isTextField: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  orderId: Scalars['ID']['output'];
  placeHolder?: Maybe<Scalars['String']['output']>;
};

export type DeletionSetting = {
  __typename?: 'DeletionSetting';
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type DeletionSettingInput = {
  /** optionally, user can give his email upon deletion to facilitate the communication with user care */
  goodbyeEmail?: InputMaybe<Scalars['Email']['input']>;
  /** user needs to confirm the action using password. see createPasswordProtectedSettingToken in login.graphql */
  passwordToken: Scalars['String']['input'];
  /**
   * reason the user is closing their account
   * @deprecated Please use reasons instead
   */
  reason?: InputMaybe<Scalars['String']['input']>;
  /** list of reasons the user is closing their account */
  reasons?: InputMaybe<Array<InputMaybe<DeletionReasonSettingInput>>>;
  /** To prevent accidental deletion of the wrong user, we also pass the id of the user to delete manually */
  userId: Scalars['ID']['input'];
};

export type DeviceTrackingInput = {
  /** Represents unique mobile device id, such as IMEI but not limited to. */
  id: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type DirectConection = {
  __typename?: 'DirectConection';
  /** #logged-out #soft-logged-in */
  recruiterInsights?: Maybe<NetworkRecruiterInsights>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type DirectConnectionsCollection = {
  __typename?: 'DirectConnectionsCollection';
  /** #logged-out #soft-logged-in */
  collection?: Maybe<Array<Maybe<DirectConection>>>;
};

export type DirectDebitData = BpayPaymentMethodData & {
  __typename?: 'DirectDebitData';
  clientName: Scalars['String']['output'];
  holder?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  maskedIban?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type DirectDebitDataError = {
  __typename?: 'DirectDebitDataError';
  accountHolder?: Maybe<TextSetting>;
  iban?: Maybe<TextSetting>;
  tandc?: Maybe<ToggleSetting>;
};

/** Discipline localized */
export type Discipline = Localizable & {
  __typename?: 'Discipline';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

/** DisciplinesPreference Type */
export type DisciplinesPreference = {
  __typename?: 'DisciplinesPreference';
  disciplineIds: Array<Maybe<Scalars['ID']['output']>>;
};

export type DiscoActivitiesEdge = PaginationEdge & {
  __typename?: 'DiscoActivitiesEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node?: Maybe<DiscoActivity>;
};

export type DiscoActivity = HasNewWorkTrackingToken &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoActivity';
    activityId: Scalars['ID']['output'];
    activityType: Scalars['String']['output'];
    /** #rest(batched) #virtual */
    actor?: Maybe<DiscoActor>;
    contextId?: Maybe<Scalars['ID']['output']>;
    contextUrn?: Maybe<Scalars['GlobalID']['output']>;
    createdAt: Scalars['Date']['output'];
    /** @deprecated [DISCO-2204] Deprecated, use `preHeader` instead. */
    discoSocialPreheader?: Maybe<DiscoItemPreheader>;
    /** #rest(batched) #virtual */
    interactionTarget?: Maybe<SocialInteractionTarget>;
    message?: Maybe<Scalars['String']['output']>;
    metaHeadline?: Maybe<DiscoMetaHeadline>;
    nwTrackingToken?: Maybe<Scalars['String']['output']>;
    /** #rest #virtual */
    object?: Maybe<DiscoObject>;
    /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    permissions?: Maybe<DiscoActivityPermissions>;
    preHeader?: Maybe<DiscoItemGenericPreHeader>;
    /** @deprecated WILL BE REMOVED ... Use permissions.repotable */
    reportable?: Maybe<Scalars['Boolean']['output']>;
    reshare?: Maybe<Scalars['Boolean']['output']>;
    share?: Maybe<Scalars['Boolean']['output']>;
    shareableUrl?: Maybe<Scalars['String']['output']>;
    /** #rest #virtual */
    socialProofComment?: Maybe<SocialCommentResult>;
    /** #rest(batched) #virtual */
    socialShare?: Maybe<SocialShareResult>;
    targetUrn?: Maybe<Scalars['GlobalID']['output']>;
  };

export type DiscoActivityCollection = {
  __typename?: 'DiscoActivityCollection';
  collection?: Maybe<Array<Maybe<DiscoActivity>>>;
};

export type DiscoActivityConnection = HasCursorPagination & {
  __typename?: 'DiscoActivityConnection';
  edges?: Maybe<Array<Maybe<DiscoActivitiesEdge>>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type DiscoActivityPermissions = {
  __typename?: 'DiscoActivityPermissions';
  deletable?: Maybe<Scalars['Boolean']['output']>;
  editable?: Maybe<Scalars['Boolean']['output']>;
  reportable?: Maybe<Scalars['Boolean']['output']>;
};

/** This union enlists the actor types supported by disco-modules */
export type DiscoActor =
  | Community
  | Company
  | ContentInsiderPage
  | ContentPage
  | EntityPage
  | GroupsGroup
  | XingId;

export type DiscoBasicHeader = DiscoModuleInfo & {
  __typename?: 'DiscoBasicHeader';
  description?: Maybe<Scalars['String']['output']>;
  routingUrn?: Maybe<Scalars['GlobalID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topicId?: Maybe<Scalars['ID']['output']>;
};

export type DiscoCompaniesInTopicModule = DiscoModule & {
  __typename?: 'DiscoCompaniesInTopicModule';
  dataSource: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  info?: Maybe<DiscoModuleInfo>;
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #paginated #rest #virtual */
  paginatedItems?: Maybe<DiscoItemsConnection>;
  renderingType: DiscoModuleRenderingType;
  urn: Scalars['GlobalID']['output'];
  x1Limit: Scalars['Int']['output'];
};

export type DiscoCompaniesInTopicModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoCompaniesModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoCompaniesModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoCompaniesModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoCompanyRecommendation = Recommendation & {
  __typename?: 'DiscoCompanyRecommendation';
  /** #rest(batched) #virtual */
  company?: Maybe<Company>;
  itemId: Scalars['ID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type DiscoDeleteActivityError = {
  __typename?: 'DiscoDeleteActivityError';
  message?: Maybe<Scalars['String']['output']>;
};

export type DiscoDeleteActivityInput = {
  id: Scalars['ID']['input'];
};

export type DiscoDeleteActivityResult =
  | DiscoDeleteActivityError
  | DiscoDeleteActivitySuccess;

export type DiscoDeleteActivitySuccess = {
  __typename?: 'DiscoDeleteActivitySuccess';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DiscoEntityStreamModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoEntityStreamModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoEntityStreamModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * It provides `DiscoModules` for Disco Entity Page Stream Page
 * based on their `rendering_types` and supports pagination. Use `first`, `first & after`, `last & before`
 * for forward/backward pagination.
 */
export type DiscoEntityStreamPage = {
  __typename?: 'DiscoEntityStreamPage';
  /** #paginated #rest #virtual */
  discoModulesPaginated?: Maybe<DiscoModulesConnection>;
};

/**
 * It provides `DiscoModules` for Disco Entity Page Stream Page
 * based on their `rendering_types` and supports pagination. Use `first`, `first & after`, `last & before`
 * for forward/backward pagination.
 */
export type DiscoEntityStreamPageDiscoModulesPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  renderingTypes: Array<DiscoModuleRenderingType>;
};

export type DiscoEventRecoModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoEventRecoModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /**
     * #paginated
     * @deprecated Product not supported anymore
     */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoEventRecoModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** List of supported `features` clients can ask for. */
export const enum DiscoFeature {
  DiscoJobRecoType = 'DISCO_JOB_RECO_TYPE',
  JobRecoModule = 'JOB_RECO_MODULE',
  MultipleModulesOnInsights = 'MULTIPLE_MODULES_ON_INSIGHTS',
  MultipleModulesOnNu = 'Multiple_Modules_on_NU',
}

export type DiscoFollowerHeader = DiscoModuleInfo & {
  __typename?: 'DiscoFollowerHeader';
  description?: Maybe<Scalars['String']['output']>;
  routingUrn?: Maybe<Scalars['GlobalID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topicId?: Maybe<Scalars['ID']['output']>;
};

/** **DiscoFromYourContactsModule** Returns activities which your contacts interacted with. */
export type DiscoFromYourContactsModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoFromYourContactsModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /**
     * #paginated
     * @deprecated [GC-1738] Product not supported anymore
     */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

/** **DiscoFromYourContactsModule** Returns activities which your contacts interacted with. */
export type DiscoFromYourContactsModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoHighlightsForYouModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoHighlightsForYouModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /**
     * #paginated
     * @deprecated [GC-1738] Product not supported anymore
     */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoHighlightsForYouModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoIndustryPageRecommendation = Recommendation & {
  __typename?: 'DiscoIndustryPageRecommendation';
  /** #rest(batched) #virtual */
  discoPage?: Maybe<ContentPage>;
  itemId: Scalars['ID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type DiscoInsiderPageRecommendation = Recommendation & {
  __typename?: 'DiscoInsiderPageRecommendation';
  /** #rest(batched) #virtual */
  discoPage?: Maybe<ContentInsiderPage>;
  itemId: Scalars['ID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type DiscoInsidersInTopicModule = DiscoModule & {
  __typename?: 'DiscoInsidersInTopicModule';
  dataSource: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  info?: Maybe<DiscoModuleInfo>;
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * #paginated
   * @deprecated [GC-1738] Product not supported anymore
   */
  paginatedItems?: Maybe<DiscoItemsConnection>;
  renderingType: DiscoModuleRenderingType;
  urn: Scalars['GlobalID']['output'];
  x1Limit: Scalars['Int']['output'];
};

export type DiscoInsidersInTopicModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoInterestingInTopicModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoInterestingInTopicModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /**
     * #paginated
     * @deprecated [GC-1738] Product not supported anymore
     */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoInterestingInTopicModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** List of supported items for `discoModules`. */
export type DiscoItem =
  | AuctionAdPlaceholder
  | DiscoActivity
  | DiscoCompanyRecommendation
  | DiscoIndustryPageRecommendation
  | DiscoInsiderPageRecommendation
  | DiscoJobRecommendation
  | DiscoNewsArticleRecommendation
  | DiscoProfileVisit
  | DiscoPublicPostRecommendation
  | DiscoPublisherPageRecommendation
  | DiscoTopicInfo
  | EventRecommendation
  | InsightsNavigationItem
  | JobRecommendation
  | MymkRecommendation
  | PersonMakeFriend;

export type DiscoItemGenericPreHeader =
  | DiscoPreHeaderFollowedTopic
  | DiscoPreHeaderStaticHeader
  | DiscoPreHeaderUserCommenter
  | DiscoPreHeaderUserLiker;

export type DiscoItemPreheader = UserCommenter | UserLiker;

/** `DiscoItemsConnection` is used to map `disco-modules` with pagination */
export type DiscoItemsConnection = HasCursorPagination & {
  __typename?: 'DiscoItemsConnection';
  /** @deprecated [DISCO-2371][GC-1723] Will be removed after 01.10.2022. Use DiscoNetworkUpdatePage.discoModulesPaginated.adPositions */
  adPositions?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  edges?: Maybe<Array<Maybe<DiscoItemsEdge>>>;
  pageInfo: PageInfo;
  requestTrackingToken?: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type DiscoItemsEdge = PaginationEdge & {
  __typename?: 'DiscoItemsEdge';
  cursor: Scalars['String']['output'];
  node: DiscoItem;
};

export type DiscoJobRecoModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoJobRecoModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoJobRecoModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoJobRecommendation = Recommendation & {
  __typename?: 'DiscoJobRecommendation';
  /** #rest(batched) #virtual */
  interactionTarget?: Maybe<SocialInteractionTarget>;
  itemId: Scalars['ID']['output'];
  /** ITJFM (Is This Job For Me) score */
  itjfmScore?: Maybe<IsThisJobForMeScore>;
  /** #rest(batched) #virtual */
  job?: Maybe<JobResult>;
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  preHeader?: Maybe<DiscoItemGenericPreHeader>;
  reason: Array<Scalars['String']['output']>;
  reportable?: Maybe<Scalars['Boolean']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type DiscoLogging = {
  __typename?: 'DiscoLogging';
  status?: Maybe<Scalars['String']['output']>;
};

export type DiscoLoggingInput = {
  payload?: InputMaybe<Array<InputMaybe<DiscoLoggingPayload>>>;
  platform?: InputMaybe<DiscoPlatform>;
};

export type DiscoLoggingPayload = {
  consumer?: InputMaybe<Scalars['String']['input']>;
  disco_module: Scalars['String']['input'];
  disco_section: Scalars['String']['input'];
  item_urn?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  nullable_field?: InputMaybe<Scalars['String']['input']>;
  reason: DiscoLoggingReason;
  unsupported_type?: InputMaybe<Scalars['String']['input']>;
};

export const enum DiscoLoggingReason {
  NullableField = 'NULLABLE_FIELD',
  NullableModule = 'NULLABLE_MODULE',
  UnsupportedType = 'UNSUPPORTED_TYPE',
}

export type DiscoMeFeedModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoMeFeedModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoMeFeedModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * It provides `DiscoModules` for Disco Me Feed Page
 * based on their `rendering_types` and supports pagination. Use `first`, `first & after`, `last & before`
 * for forward/backward pagination.
 */
export type DiscoMeFeedPage = {
  __typename?: 'DiscoMeFeedPage';
  /** #paginated #rest #virtual */
  discoModulesPaginated?: Maybe<DiscoModulesConnection>;
};

/**
 * It provides `DiscoModules` for Disco Me Feed Page
 * based on their `rendering_types` and supports pagination. Use `first`, `first & after`, `last & before`
 * for forward/backward pagination.
 */
export type DiscoMeFeedPageDiscoModulesPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  renderingTypes: Array<DiscoModuleRenderingType>;
};

export type DiscoMetaHeadline = {
  __typename?: 'DiscoMetaHeadline';
  routingUrn?: Maybe<Scalars['GlobalID']['output']>;
  text: Scalars['String']['output'];
};

/**
 * **DiscoModule** interface describes the list of supported `rendering_types`.
 * `rendering_types` required modules types. `info` inforation about the module.
 * `items` is a collection with data for different modules.
 */
export type DiscoModule = {
  id?: Maybe<Scalars['String']['output']>;
  info?: Maybe<DiscoModuleInfo>;
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #paginated */
  paginatedItems?: Maybe<DiscoItemsConnection>;
  renderingType: DiscoModuleRenderingType;
  urn: Scalars['GlobalID']['output'];
  x1Limit: Scalars['Int']['output'];
};

/**
 * **DiscoModule** interface describes the list of supported `rendering_types`.
 * `rendering_types` required modules types. `info` inforation about the module.
 * `items` is a collection with data for different modules.
 */
export type DiscoModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** **DiscoModuleInfo** consists of the title and the description of `discoModule` */
export type DiscoModuleInfo = {
  description?: Maybe<Scalars['String']['output']>;
  routingUrn?: Maybe<Scalars['GlobalID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topicId?: Maybe<Scalars['ID']['output']>;
};

/** List of supported `rendering_types`. */
export const enum DiscoModuleRenderingType {
  TypeA = 'Type_A',
  TypeB = 'Type_B',
  TypeC = 'Type_C',
}

export type DiscoModulesConnection = HasCursorPagination & {
  __typename?: 'DiscoModulesConnection';
  adPositions?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  adTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  edges?: Maybe<Array<Maybe<DiscoModulesEdge>>>;
  pageInfo: PageInfo;
  requestTrackingToken?: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type DiscoModulesEdge = PaginationEdge & {
  __typename?: 'DiscoModulesEdge';
  cursor: Scalars['String']['output'];
  node: DiscoModule;
};

export type DiscoMymkRecoModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoMymkRecoModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoMymkRecoModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoNetworkNewsModule = DiscoModule &
  HasNewWorkTrackingToken &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoNetworkNewsModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    nwTrackingToken?: Maybe<Scalars['String']['output']>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoNetworkNewsModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * It provides `DiscoModules` for Disco Network Update Page
 * based on their `rendering_types` and supports pagination. Use `first`, `first & after`, `last & before`
 * for forward/backward pagination.
 */
export type DiscoNetworkUpdatePage = {
  __typename?: 'DiscoNetworkUpdatePage';
  /** #paginated #rest #virtual */
  discoModulesPaginated?: Maybe<DiscoModulesConnection>;
};

/**
 * It provides `DiscoModules` for Disco Network Update Page
 * based on their `rendering_types` and supports pagination. Use `first`, `first & after`, `last & before`
 * for forward/backward pagination.
 */
export type DiscoNetworkUpdatePageDiscoModulesPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  renderingTypes: Array<DiscoModuleRenderingType>;
};

export type DiscoNetworkUpdateWidget = {
  __typename?: 'DiscoNetworkUpdateWidget';
  items?: Maybe<DiscoActivityCollection>;
  /** #paginated */
  paginatedItems?: Maybe<DiscoActivityConnection>;
};

export type DiscoNetworkUpdateWidgetPaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoNewsArticleRecommendation = Recommendation & {
  __typename?: 'DiscoNewsArticleRecommendation';
  activityId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  contentArticle?: Maybe<ContentArticle>;
  /** @deprecated [DISCO-2204] Deprecated, use `preHeader` instead. */
  discoSocialPreheader?: Maybe<DiscoItemPreheader>;
  /** #rest(batched) #virtual */
  interactionTarget?: Maybe<SocialInteractionTarget>;
  itemId: Scalars['ID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  preHeader?: Maybe<DiscoItemGenericPreHeader>;
  reason: Array<Scalars['String']['output']>;
  reportable?: Maybe<Scalars['Boolean']['output']>;
  score: Scalars['Float']['output'];
  /** @deprecated [DISCO-2888] Feedy-styled detailed view url will be gone in Jan 2023 */
  shareableUrl?: Maybe<Scalars['String']['output']>;
  targetUrn?: Maybe<Scalars['GlobalID']['output']>;
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type DiscoNewsArticlesModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoNewsArticlesModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoNewsArticlesModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** **DiscoNewsInsidersModule** can return DiscoInsiderPageRecommendation. */
export type DiscoNewsInsidersModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoNewsInsidersModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

/** **DiscoNewsInsidersModule** can return DiscoInsiderPageRecommendation. */
export type DiscoNewsInsidersModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * **DiscoNewsPublisherModule** can return DiscoIndustryPageRecommendation,
 * DiscoInsiderPageRecommendation and DiscoPublisherPageRecommendation depending on what
 * is passed in pageTypes field e.g. insider, industry, press_review, topic and publisher.
 */
export type DiscoNewsPublisherModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoNewsPublisherModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /**
     * #paginated #rest #virtual
     * @deprecated use field `paginatedItems`. WILL BE REMOVED: 1 Dec 2022
     */
    pages?: Maybe<DiscoItemsConnection>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

/**
 * **DiscoNewsPublisherModule** can return DiscoIndustryPageRecommendation,
 * DiscoInsiderPageRecommendation and DiscoPublisherPageRecommendation depending on what
 * is passed in pageTypes field e.g. insider, industry, press_review, topic and publisher.
 */
export type DiscoNewsPublisherModulePagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pageTypes?: InputMaybe<Array<InputMaybe<ContentPageType>>>;
};

/**
 * **DiscoNewsPublisherModule** can return DiscoIndustryPageRecommendation,
 * DiscoInsiderPageRecommendation and DiscoPublisherPageRecommendation depending on what
 * is passed in pageTypes field e.g. insider, industry, press_review, topic and publisher.
 */
export type DiscoNewsPublisherModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** **DiscoNewsPublishersTopicsModule** can return DiscoIndustryPageRecommendation and DiscoPublisherPageRecommendation. */
export type DiscoNewsPublishersTopicsModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoNewsPublishersTopicsModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

/** **DiscoNewsPublishersTopicsModule** can return DiscoIndustryPageRecommendation and DiscoPublisherPageRecommendation. */
export type DiscoNewsPublishersTopicsModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** This union enlists the object types supported by disco-modules */
export type DiscoObject =
  | ArticlesArticle
  | CompanyAnniversaryStory
  | ContentArticle
  | CoverImageUpdate
  | DiscoSharedObject
  | DiscoXingIdObject
  | EntityPage
  | Event
  | PostingsPosting
  | ProfileHavesUpdate
  | ProfileUpdatePhoto
  | ProfileUpdateStatus
  | ProfileWorkExperienceUpdate
  | SocialExternalLinkResult
  | VisibleJob;

export type DiscoPersonMakeFriendModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoPersonMakeFriendModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoPersonMakeFriendModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export const enum DiscoPlatform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Unknown = 'UNKNOWN',
  Web = 'WEB',
}

export type DiscoPreHeaderFollowedTopic = {
  __typename?: 'DiscoPreHeaderFollowedTopic';
  /** @deprecated [DISCO-2647] Product not supported anymore */
  discoTopicMetadata?: Maybe<DiscoTopicMetadata>;
  preHeaderType: Scalars['String']['output'];
  routingUrn?: Maybe<Scalars['GlobalID']['output']>;
  topicId: Scalars['ID']['output'];
};

export type DiscoPreHeaderStaticHeader = {
  __typename?: 'DiscoPreHeaderStaticHeader';
  headerText: Scalars['String']['output'];
  preHeaderType: Scalars['String']['output'];
  routingUrn?: Maybe<Scalars['GlobalID']['output']>;
};

export type DiscoPreHeaderUserCommenter = {
  __typename?: 'DiscoPreHeaderUserCommenter';
  preHeaderType: Scalars['String']['output'];
  /** #rest(batched) #virtual */
  profile?: Maybe<XingId>;
  routingUrn?: Maybe<Scalars['GlobalID']['output']>;
  userId: Scalars['ID']['output'];
};

export type DiscoPreHeaderUserLiker = {
  __typename?: 'DiscoPreHeaderUserLiker';
  preHeaderType: Scalars['String']['output'];
  /** #rest(batched) #virtual */
  profile?: Maybe<XingId>;
  routingUrn?: Maybe<Scalars['GlobalID']['output']>;
  userId: Scalars['ID']['output'];
};

export type DiscoProfileCompletion = HasOperationalDataTrackingTokens & {
  __typename?: 'DiscoProfileCompletion';
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  showBanner: Scalars['Boolean']['output'];
  targetUrl: Scalars['URL']['output'];
};

/**
 * Represents a visit of a profile.
 * Contains information about the user, reason and time of the visit
 */
export type DiscoProfileVisit = {
  __typename?: 'DiscoProfileVisit';
  /** ID of the profile visit. */
  id: Scalars['ID']['output'];
  /** New VOMP message which contains click reason text resources and/or prioritized commonality text resource */
  label?: Maybe<Scalars['String']['output']>;
  /** Number of times the visitor visited the profile. */
  numberOfVisits?: Maybe<Scalars['Int']['output']>;
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Additional payload of a profile visit containing information about recruiter status, company name and job title */
  payload: ProfileVisitPayload;
  /**
   * #rest(batched) #virtual
   *
   *  Fenced XingId representation of the profile visitor
   */
  profileVisitor?: Maybe<ProfileVisitor>;
  /**
   * Click reason (translation language complies with the first two letters of an Accept-Language request header,
   * which in Rails/Rack is internally represented as HTTP_ACCEPT_LANGUAGE and must be one of the valid languages
   * of a XING platform, falling back to German otherwise)
   */
  reasonHtml?: Maybe<Scalars['String']['output']>;
  /** ISO 8601 Timestamp when the profile was visited. */
  viewedAt?: Maybe<Scalars['Date']['output']>;
  /** ID of the user who visited the profile. 0 for logged-out visits. */
  visitorId?: Maybe<Scalars['ID']['output']>;
};

export type DiscoPublicPostRecommendation = Recommendation & {
  __typename?: 'DiscoPublicPostRecommendation';
  activityId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  actor?: Maybe<DiscoActor>;
  /** @deprecated [DISCO-2204] Deprecated, use `preHeader` instead. */
  discoSocialPreheader?: Maybe<DiscoItemPreheader>;
  /** #rest(batched) #virtual */
  interactionTarget?: Maybe<SocialInteractionTarget>;
  itemId: Scalars['ID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  /** #rest(batched) #virtual */
  posting?: Maybe<PostingsPosting>;
  preHeader?: Maybe<DiscoItemGenericPreHeader>;
  reason: Array<Scalars['String']['output']>;
  reportable?: Maybe<Scalars['Boolean']['output']>;
  score: Scalars['Float']['output'];
  /** @deprecated [DISCO-2888] Feedy-styled detailed view url will be gone in Jan 2023 */
  shareableUrl?: Maybe<Scalars['String']['output']>;
  targetUrn?: Maybe<Scalars['GlobalID']['output']>;
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type DiscoPublisherPageRecommendation = Recommendation & {
  __typename?: 'DiscoPublisherPageRecommendation';
  /** #rest(batched) #virtual */
  discoPage?: Maybe<ContentPage>;
  itemId: Scalars['ID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type DiscoPublishersInTopicModule = DiscoModule & {
  __typename?: 'DiscoPublishersInTopicModule';
  dataSource: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  info?: Maybe<DiscoModuleInfo>;
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #paginated #rest #virtual */
  paginatedItems?: Maybe<DiscoItemsConnection>;
  renderingType: DiscoModuleRenderingType;
  urn: Scalars['GlobalID']['output'];
  x1Limit: Scalars['Int']['output'];
};

export type DiscoPublishersInTopicModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Disco representation of shared object from activities.. */
export type DiscoSharedObject = {
  __typename?: 'DiscoSharedObject';
  activityId?: Maybe<Scalars['ID']['output']>;
  /** #rest(batched) #virtual */
  actor?: Maybe<DiscoActor>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** #rest(batched) #virtual */
  interactionTarget?: Maybe<SocialInteractionTarget>;
  /** #rest #virtual */
  object?: Maybe<DiscoObject>;
  /** @deprecated renamed to activityId */
  originActivityId?: Maybe<Scalars['ID']['output']>;
  reshare?: Maybe<Scalars['Boolean']['output']>;
  share?: Maybe<Scalars['Boolean']['output']>;
  shareableUrl?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  socialShare?: Maybe<SocialShareResult>;
};

export type DiscoSimilarToTopicModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoSimilarToTopicModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /**
     * #paginated
     * @deprecated [GC-1738] Product not supported anymore
     */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoSimilarToTopicModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoTopicInfo = HasOperationalDataTrackingTokens & {
  __typename?: 'DiscoTopicInfo';
  /** @deprecated [DISCO-2647] Product not supported anymore */
  discoTopicMetadata?: Maybe<DiscoTopicMetadata>;
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  topicId: Scalars['ID']['output'];
  urn: Scalars['GlobalID']['output'];
};

export type DiscoTopicMetadata = {
  __typename?: 'DiscoTopicMetadata';
  abTestName?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  /** #paginated #rest #virtual */
  followers?: Maybe<FollowersConnection>;
  /** Topic metadata information in the user language */
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  urn: Scalars['GlobalID']['output'];
  variant?: Maybe<Scalars['String']['output']>;
};

export type DiscoTopicMetadataFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoTrendingObjectsModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoTrendingObjectsModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /**
     * #paginated
     * @deprecated [GC-1738] Product not supported anymore
     */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoTrendingObjectsModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoVompUpsellModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'DiscoVompUpsellModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated #rest #virtual */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type DiscoVompUpsellModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * It is a proxy object which has the data of user (XingId) and shared contacts of
 * the requested user with the logged-in user. Used for users:user|share|users:user
 * activity type use case.
 */
export type DiscoXingIdObject = {
  __typename?: 'DiscoXingIdObject';
  /** #rest(batched) #virtual */
  sharedContacts?: Maybe<SharedContacts>;
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
};

export type DismissCompanyAutoNotificationError = {
  __typename?: 'DismissCompanyAutoNotificationError';
  message?: Maybe<Scalars['String']['output']>;
};

export type DismissCompanyAutoNotificationInput = {
  companyId: Scalars['SlugOrID']['input'];
};

export type DismissCompanyAutoNotificationResult = {
  __typename?: 'DismissCompanyAutoNotificationResult';
  error?: Maybe<DismissCompanyAutoNotificationError>;
};

/** Display AD Module */
export type DisplayAdModule = ModuleInterface & {
  __typename?: 'DisplayAdModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

/** Available legacy Display Flags */
export const enum DisplayFlag {
  /** A user with ambassador role */
  Ambassador = 'AMBASSADOR',
  /** Default user role and membership */
  Basic = 'BASIC',
  /** A user with executive membership */
  Executive = 'EXECUTIVE',
  /** A user with insider role */
  Insider = 'INSIDER',
  /** A user with moderator role */
  Moderator = 'MODERATOR',
  /** A user with Premium moderator membership */
  ModeratorPremium = 'MODERATOR_PREMIUM',
  /** A user with premium membership */
  Premium = 'PREMIUM',
  /** A user with ProCoach membership */
  Procoach = 'PROCOACH',
}

export type DreamJobDiscipline = {
  __typename?: 'DreamJobDiscipline';
  id?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DreamJobIndusty = {
  __typename?: 'DreamJobIndusty';
  id?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DreamJobItem = OccupiedJob;

export type DreamJobSimilarMembers = {
  __typename?: 'DreamJobSimilarMembers';
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type DreamJobSkills = {
  __typename?: 'DreamJobSkills';
  skill?: Maybe<Scalars['String']['output']>;
};

export type DreamJobsCollection = {
  __typename?: 'DreamJobsCollection';
  collection?: Maybe<Array<Maybe<DreamJobItem>>>;
  total?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type EasyApplication = {
  __typename?: 'EasyApplication';
  /**
   * #soft-logged-in
   * @deprecated Field no longer supported
   */
  easyApplyCallback?: Maybe<Scalars['URL']['output']>;
};

export type EcommCompanyDetails = {
  __typename?: 'EcommCompanyDetails';
  /** #rest(batched) #virtual */
  company?: Maybe<Company>;
  companyId: Scalars['ID']['output'];
  countryCode: Scalars['String']['output'];
  industryId: Scalars['ID']['output'];
};

export type EcommCompanyDetailsMutationInput = {
  companyId: Scalars['ID']['input'];
  countryCode: Scalars['String']['input'];
  industryId: Scalars['ID']['input'];
};

export type EcommConfirmOrder = {
  __typename?: 'EcommConfirmOrder';
  isConfirmed: Scalars['Boolean']['output'];
};

export type EcommContactDetails = {
  __typename?: 'EcommContactDetails';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  postcode: Scalars['Int']['output'];
  vatId?: Maybe<Scalars['String']['output']>;
};

export type EcommContactDetailsMutationInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  postcode: Scalars['Int']['input'];
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export const enum EcommCurrency {
  Chf = 'CHF',
  Eur = 'EUR',
}

export type EcommOrder = {
  __typename?: 'EcommOrder';
  contractTerm: Scalars['Int']['output'];
  priceId: Scalars['ID']['output'];
  /** #rest #virtual */
  product?: Maybe<EcommProduct>;
  productId: Scalars['ID']['output'];
  productVariationId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  totalAmountInCents: Scalars['Int']['output'];
};

export type EcommOrderMutationInput = {
  priceId: Scalars['String']['input'];
  productId: Scalars['String']['input'];
  productVariationId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type EcommPaymentStatus = {
  __typename?: 'EcommPaymentStatus';
  isPaymentSet: Scalars['Boolean']['output'];
};

export type EcommPreviousClient = {
  __typename?: 'EcommPreviousClient';
  address: Scalars['String']['output'];
  b2bClientId: Scalars['ID']['output'];
  city: Scalars['String']['output'];
  clientName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  postcode: Scalars['Int']['output'];
  vatId?: Maybe<Scalars['String']['output']>;
};

export type EcommPrice = {
  __typename?: 'EcommPrice';
  /** #logged-out #soft-logged-in */
  currency: EcommCurrency;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  productVariationId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  valueInCents: Scalars['Int']['output'];
};

export type EcommProduct = {
  __typename?: 'EcommProduct';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  paymentsProductIdentifier: EcommProductIdentifier;
  /** #logged-out #soft-logged-in */
  productVariations: Array<EcommProductVariation>;
  /** #logged-out #soft-logged-in */
  recruiterType: EcommRecruiterType;
};

export const enum EcommProductIdentifier {
  XtmPro = 'XTM_PRO',
  XtmProStaffing = 'XTM_PRO_STAFFING',
  XtmStandard = 'XTM_STANDARD',
  XtmStandardStaffing = 'XTM_STANDARD_STAFFING',
}

export type EcommProductVariation = {
  __typename?: 'EcommProductVariation';
  /** #logged-out #soft-logged-in */
  companySize: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  prices: Array<EcommPrice>;
  /** #logged-out #soft-logged-in */
  productId: Scalars['ID']['output'];
};

export const enum EcommRecruiterType {
  Corporate = 'CORPORATE',
  Staffing = 'STAFFING',
}

export type EmailApplication = {
  __typename?: 'EmailApplication';
  /** #soft-logged-in */
  applyEmail?: Maybe<Scalars['Email']['output']>;
};

export const enum EmailFrequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
}

/** Active and pending email addresses */
export type EmailSetting = HasGlobalId & {
  __typename?: 'EmailSetting';
  /** current email address of the user */
  active?: Maybe<Scalars['Email']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Globally unique id for this instance */
  globalId: Scalars['GlobalID']['output'];
  /** new email the user has submitted, not verified yet */
  pending?: Maybe<Scalars['Email']['output']>;
};

/** Used to set new email address */
export type EmailSettingInput = {
  /** token to confirm new email address, received via email */
  activationCode?: InputMaybe<Scalars['String']['input']>;
  passwordToken?: InputMaybe<Scalars['String']['input']>;
  /** new email address, will need to be verified */
  pending?: InputMaybe<Scalars['Email']['input']>;
};

export type EmailSubscription = {
  __typename?: 'EmailSubscription';
  hasEmail?: Maybe<Scalars['Boolean']['output']>;
  pageId: Scalars['ID']['output'];
  pageTitle?: Maybe<Scalars['String']['output']>;
};

export type EmailSubscriptions = {
  __typename?: 'EmailSubscriptions';
  collection?: Maybe<Array<Maybe<EmailSubscription>>>;
};

/** Embedding applications */
export const enum EmbeddedBy {
  Ebp = 'EBP',
}

export type Employee = {
  __typename?: 'Employee';
  /** #rest(batched) #virtual */
  contactDistance?: Maybe<ContactDistance>;
  /** #rest(batched) #virtual */
  networkRelationship?: Maybe<NetworkRelationship>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  profileDetails?: Maybe<XingId>;
  /** #rest(batched) #virtual */
  sharedContacts?: Maybe<SharedContacts>;
};

/** EmployersPreference Type */
export type EmployersPreference = {
  __typename?: 'EmployersPreference';
  /** #rest(batched) #virtual */
  companies: Array<Maybe<Company>>;
};

/** Employment type localized */
export type EmploymentType = Localizable & {
  __typename?: 'EmploymentType';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export type Empty = {
  __typename?: 'Empty';
  stub?: Maybe<Scalars['String']['output']>;
};

export type EmptyOrErrorResponse = Empty | SearchAlertErrorResponse;

export type EntityCustomer = {
  __typename?: 'EntityCustomer';
  /** #rest(batched) #virtual */
  customer?: Maybe<Customer>;
  customerId: Scalars['Int']['output'];
  entityType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  urn: Scalars['GlobalID']['output'];
};

export type EntityCustomers = {
  __typename?: 'EntityCustomers';
  collection: Array<Maybe<EntityCustomer>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type EntityDocument = {
  __typename?: 'EntityDocument';
  /** #logged-out #soft-logged-in */
  description: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  documentUrl: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  filename: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  position: Scalars['Int']['output'];
};

export type EntityDocumentConnection = HasCursorPagination & {
  __typename?: 'EntityDocumentConnection';
  /** #logged-out #soft-logged-in */
  edges?: Maybe<Array<Maybe<EntityDocumentEdge>>>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type EntityDocumentEdge = PaginationEdge & {
  __typename?: 'EntityDocumentEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node: EntityDocument;
};

export type EntityDocumentResult = EntityDocumentConnection | EntityPageError;

export type EntityExternalVideo = {
  __typename?: 'EntityExternalVideo';
  /** #logged-out #soft-logged-in */
  externalLink: Scalars['URL']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  The signed URL of the scaled image
   */
  url?: Maybe<Scalars['URL']['output']>;
};

export type EntityMedia = {
  __typename?: 'EntityMedia';
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  enabled: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  media?: Maybe<EntityPageMedia>;
  /** #logged-out #soft-logged-in */
  position: Scalars['Int']['output'];
};

export type EntityMediaMediaArgs = {
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxWidth?: InputMaybe<Scalars['Int']['input']>;
};

export type EntityMediaConnection = HasCursorPagination & {
  __typename?: 'EntityMediaConnection';
  /** #logged-out #soft-logged-in */
  edges?: Maybe<Array<Maybe<EntityMediaEdge>>>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type EntityMediaEdge = PaginationEdge & {
  __typename?: 'EntityMediaEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node: EntityMedia;
};

export type EntityMediaGalleryItemInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type EntityMediaItemResult = EntityMedia | EntityPageError;

export type EntityMediaResult = EntityMediaConnection | EntityPageError;

export const enum EntityMediaType {
  Image = 'IMAGE',
}

/** Entity Page representation */
export type EntityPage = HasGlobalId & {
  __typename?: 'EntityPage';
  /**
   * #rest(batched) #virtual
   *
   *  Entity page entity customer relation on AdManager
   */
  adManagerEntityCustomers?: Maybe<EntityCustomer>;
  /** #expand #logged-out #soft-logged-in */
  context?: Maybe<EntityPageContext>;
  /** #expand #logged-out #soft-logged-in */
  contract: EntityPageContract;
  /** #logged-out #soft-logged-in #virtual */
  coverImage?: Maybe<Array<ScaledImage>>;
  /** #logged-out #soft-logged-in */
  externalIds?: Maybe<Scalars['JSON']['output']>;
  /** #logged-out #soft-logged-in */
  focusType?: Maybe<EntityPageFocusType>;
  /**
   * #paginated #logged-out #soft-logged-in #rest #virtual
   *
   *  The list of the entity page followers
   * @deprecated The list of followers will now be accessible on the HeaderModule's content. Checked on 13.03.2023 still used by Unknown Web version and old mobile vertions a lot
   */
  followers?: Maybe<FollowersConnection>;
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /**
   * #expand
   *
   *  Entity page insights
   */
  insights?: Maybe<EntityPageInsights>;
  /** #logged-out #soft-logged-in */
  links: EntityPageLinks;
  /** #logged-out #soft-logged-in #virtual */
  logoImage?: Maybe<Array<ScaledImage>>;
  /** #logged-out #soft-logged-in #rest #virtual */
  modules?: Maybe<EntityPageModules>;
  /** #logged-out #soft-logged-in #rest #virtual */
  newsFeed?: Maybe<EntityPageContentFeedCollection>;
  /** #logged-out #soft-logged-in #virtual */
  originalCoverImage?: Maybe<Array<ScaledImage>>;
  /** #logged-out #soft-logged-in #virtual */
  originalLogoImage?: Maybe<Array<ScaledImage>>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   * @deprecated EntityPages no longer store information about the entity's owner. Use permissions for access control instead of roles
   */
  owner?: Maybe<XingId>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Properties that can be used to control the rendering of the entity page
   */
  properties: EntityPageProperties;
  /** #logged-out #soft-logged-in */
  publicationStatus: EntityPagePublicationStatus;
  /** #logged-out #soft-logged-in */
  slogan?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  slug: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  title: Scalars['String']['output'];
  /** #expand */
  userPageContext?: Maybe<EntityPageUserContext>;
  visibility?: Maybe<EntityPageVisibility>;
  /**
   * #rest #virtual
   *
   *  The list of the entity page visitors
   */
  visitors?: Maybe<EntityPageVisitorsCollection>;
};

/** Entity Page representation */
export type EntityPageCoverImageArgs = {
  dimensions: Array<ScaledImageDimension>;
};

/** Entity Page representation */
export type EntityPageFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Entity Page representation */
export type EntityPageLogoImageArgs = {
  dimensions: Array<ScaledImageDimension>;
};

/** Entity Page representation */
export type EntityPageNewsFeedArgs = {
  filter?: InputMaybe<PostingPublicationState>;
  includePinned?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['String']['input']>;
  supportedObjectTypes?: Array<EntityPageSupportedContentObjectTypes>;
  until?: InputMaybe<Scalars['String']['input']>;
};

/** Entity Page representation */
export type EntityPageOriginalCoverImageArgs = {
  dimensions: Array<ScaledImageDimension>;
};

/** Entity Page representation */
export type EntityPageOriginalLogoImageArgs = {
  dimensions: Array<ScaledImageDimension>;
};

/** Entity Page representation */
export type EntityPageVisitorsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type EntityPageAboutContentMutationInput = {
  aboutArticleReference?: InputMaybe<Scalars['GlobalID']['input']>;
  aboutHeadline?: InputMaybe<Scalars['String']['input']>;
  aboutSummary?: InputMaybe<Scalars['String']['input']>;
  foundingYear?: InputMaybe<Scalars['Int']['input']>;
  groupRulesArticleReference?: InputMaybe<Scalars['GlobalID']['input']>;
  imprint?: InputMaybe<Scalars['String']['input']>;
  pageId: Scalars['SlugOrID']['input'];
  websiteUrl?: InputMaybe<Scalars['URL']['input']>;
};

/** AboutUs module, includes module properties */
export type EntityPageAboutUsModule = EntityPageModule & {
  __typename?: 'EntityPageAboutUsModule';
  /** #logged-out #soft-logged-in */
  enabled: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  globalId?: Maybe<Scalars['GlobalID']['output']>;
  permissions?: Maybe<EntityPageModulePermissions>;
  /** #logged-out #soft-logged-in */
  properties: AboutUsProperties;
  /** #logged-out #soft-logged-in */
  type: Scalars['String']['output'];
};

export type EntityPageCompanyHeaderContent = {
  __typename?: 'EntityPageCompanyHeaderContent';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  company?: Maybe<Company>;
  /** #paginated #logged-out #soft-logged-in #rest #virtual */
  followers?: Maybe<FollowersConnection>;
};

export type EntityPageCompanyHeaderContentFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type EntityPageConfigInput = {
  about?: InputMaybe<AboutEntityMutationInput>;
  contractType?: InputMaybe<EntityPageContractType>;
  focusType?: InputMaybe<EntityPageFocusType>;
  pageId: Scalars['SlugOrID']['input'];
  slogan?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<EntityPageVisibility>;
};

export type EntityPageConfigUpdateResult = {
  __typename?: 'EntityPageConfigUpdateResult';
  /** #rest #virtual */
  entityPage?: Maybe<EntityPage>;
  error?: Maybe<EntityPagesMutationError>;
};

export type EntityPageContactUser = {
  __typename?: 'EntityPageContactUser';
  /** #logged-out #soft-logged-in */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  label?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  position?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

/** Result type for ContactUser mutations that returns the new entity representation on success */
export type EntityPageContactUserMutationResult = {
  __typename?: 'EntityPageContactUserMutationResult';
  error?: Maybe<EntityPagesMutationError>;
  success?: Maybe<EntityPageContactUser>;
};

export type EntityPageContactUsers = {
  __typename?: 'EntityPageContactUsers';
  /** #logged-out #soft-logged-in */
  collection: Array<EntityPageContactUser>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type EntityPageContacts = {
  __typename?: 'EntityPageContacts';
  /** #logged-out #soft-logged-in #rest #virtual */
  contactUsers?: Maybe<EntityPageContactUsers>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Locations where this entity is present (only one may be highlighted)
   * @deprecated Removed from the ContactsModule. Please use the dedicated entityPageLocations query. Checked on 23.02.2023 - still used a bit on Web and old Mobile vertions
   */
  locations?: Maybe<EntityPageLocations>;
};

export type EntityPageContactsContactUsersArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type EntityPageContactsLocationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type EntityPageContentFeedActor =
  | EntityPage
  | EntityPageContentPageActor;

export type EntityPageContentFeedArticlePost = {
  __typename?: 'EntityPageContentFeedArticlePost';
  /** #logged-out #soft-logged-in */
  comment?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  image?: Maybe<EntityPageContentFeedUpdateImage>;
  /** #logged-out #soft-logged-in */
  permalink?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  provider?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  video?: Maybe<EntityPageContentVideo>;
};

export type EntityPageContentFeedArticlesArticlePost = {
  __typename?: 'EntityPageContentFeedArticlesArticlePost';
  /** #logged-out #soft-logged-in */
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in #virtual */
  images?: Maybe<Array<ScaledImage>>;
  /** #logged-out #soft-logged-in */
  summary?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  visitUrl?: Maybe<Scalars['URL']['output']>;
};

export type EntityPageContentFeedArticlesArticlePostImagesArgs = {
  dimensions: Array<ScaledImageDimension>;
};

export type EntityPageContentFeedCollection = {
  __typename?: 'EntityPageContentFeedCollection';
  /** #logged-out #soft-logged-in */
  collection?: Maybe<Array<EntityPageContentFeedUpdate>>;
  metadata?: Maybe<EntityPageContentFeedCollectionMetadata>;
};

export type EntityPageContentFeedCollectionMetadata = {
  __typename?: 'EntityPageContentFeedCollectionMetadata';
  draftTotal: Scalars['Int']['output'];
  hiddenTotal: Scalars['Int']['output'];
  scheduledTotal: Scalars['Int']['output'];
};

export type EntityPageContentFeedCompanyArticlePost = {
  __typename?: 'EntityPageContentFeedCompanyArticlePost';
  /** #logged-out #soft-logged-in */
  comment?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  image with the original image size. We probably don't need it, since we can get any size we want from image cache
   */
  imageUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in #virtual */
  images?: Maybe<Array<ScaledImage>>;
  /** #logged-out #soft-logged-in */
  permalink?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  provider?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  video?: Maybe<EntityPageContentVideo>;
};

export type EntityPageContentFeedCompanyArticlePostImagesArgs = {
  dimensions: Array<ScaledImageDimension>;
};

export type EntityPageContentFeedMetadata = {
  __typename?: 'EntityPageContentFeedMetadata';
  /** #logged-out #soft-logged-in */
  commentsCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  isCommentable: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  isLikeable: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  isRecommendable: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  likesCount: Scalars['Int']['output'];
};

export type EntityPageContentFeedObject =
  | EntityPageContentFeedArticlePost
  | EntityPageContentFeedArticlesArticlePost
  | EntityPageContentFeedCompanyArticlePost
  | EntityPageContentFeedPlainTextPostingPost
  | EntityPageContentFeedPostingPost;

export type EntityPageContentFeedPlainTextPostingPost = {
  __typename?: 'EntityPageContentFeedPlainTextPostingPost';
  /** #logged-out #soft-logged-in */
  comment?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
};

export type EntityPageContentFeedPostingPost = {
  __typename?: 'EntityPageContentFeedPostingPost';
  /** #logged-out #soft-logged-in */
  attachment?: Maybe<PostingsAttachment>;
  /** #logged-out #soft-logged-in */
  comment?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  createdAt: Scalars['Date']['output'];
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  publicationState: PostingPublicationState;
  /**
   * When a posting will be published.
   * Only set for postings that have not been published yet
   * but are scheduled for publication.
   * For published postings query `EntityPageContentFeedUpdate.publishedAt` instead
   */
  publishAt?: Maybe<Scalars['Date']['output']>;
};

export type EntityPageContentFeedUpdate = {
  __typename?: 'EntityPageContentFeedUpdate';
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   * @deprecated Please only use the `EntityPage` type as the actor; EntityPageContentPageActor is deprecated. Checked 28.03.2023 still used by unknown Web version
   */
  actor?: Maybe<EntityPageContentFeedActor>;
  /**
   * #logged-out #soft-logged-in
   *
   *  a missing globalId indicates an unpublished posting
   */
  globalId?: Maybe<Scalars['GlobalID']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  limited to one per stream; pinned indicates that the record is hoisted to the top of the feed instead of its original position
   */
  isPinned: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  metadata?: Maybe<EntityPageContentFeedMetadata>;
  /** #logged-out #soft-logged-in */
  object?: Maybe<EntityPageContentFeedObject>;
  /** #logged-out #soft-logged-in */
  publishedAt?: Maybe<Scalars['Date']['output']>;
  /** #rest(batched) #virtual */
  socialProof?: Maybe<XingId>;
};

export type EntityPageContentFeedUpdateImage = {
  __typename?: 'EntityPageContentFeedUpdateImage';
  /** #logged-out #soft-logged-in */
  srcWidth347?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcWidth516?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcWidth776?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcWidth1232?: Maybe<Scalars['URL']['output']>;
};

export type EntityPageContentPageActor = {
  __typename?: 'EntityPageContentPageActor';
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  logo?: Maybe<EntityPageContentPageActorLogo>;
  /** #logged-out #soft-logged-in */
  permalink?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  title: Scalars['String']['output'];
};

export type EntityPageContentPageActorLogo = {
  __typename?: 'EntityPageContentPageActorLogo';
  /** #logged-out #soft-logged-in */
  original?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square32?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square48?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square64?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square96?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square128?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square144?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square192?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square256?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square384?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  square1024?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  web?: Maybe<Scalars['URL']['output']>;
};

export type EntityPageContentVideo = {
  __typename?: 'EntityPageContentVideo';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  links?: Maybe<EntityPageContentVideoLinks>;
  /** #logged-out #soft-logged-in */
  urn: Scalars['GlobalID']['output'];
};

export type EntityPageContentVideoLinks = {
  __typename?: 'EntityPageContentVideoLinks';
  /** #logged-out #soft-logged-in */
  iframeUrl?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  thumbnailsUrls?: Maybe<Array<Scalars['String']['output']>>;
};

export type EntityPageContext = {
  __typename?: 'EntityPageContext';
  /** #logged-out #soft-logged-in */
  companyId?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  cppId?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  groupPageId?: Maybe<Scalars['ID']['output']>;
};

export type EntityPageContract = {
  __typename?: 'EntityPageContract';
  /** #logged-out #soft-logged-in */
  type: EntityPageContractType;
};

export const enum EntityPageContractType {
  Free = 'FREE',
  None = 'NONE',
  Paid = 'PAID',
  PaidPlus = 'PAID_PLUS',
}

export const enum EntityPageCoverMediaType {
  Image = 'IMAGE',
}

export type EntityPageCreateContactUserInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  pageId: Scalars['SlugOrID']['input'];
  userId: Scalars['ID']['input'];
};

export type EntityPageCreateDocumentMutationInput = {
  description: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  pageId: Scalars['SlugOrID']['input'];
  uploadReference: Scalars['UploadId']['input'];
};

export type EntityPageCreateLocationInput = {
  address: Scalars['String']['input'];
  addressSuffix?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  cityId?: InputMaybe<Scalars['ID']['input']>;
  countryIsoCode: CountryCodesAlpha2;
  email?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  faxNumberCountryCode?: InputMaybe<Scalars['Int']['input']>;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  pageId: Scalars['SlugOrID']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberCountryCode?: InputMaybe<Scalars['Int']['input']>;
  postcode: Scalars['String']['input'];
  /** @deprecated This field will be removed since it's not used anymore. WILL BE REMOVED ... */
  province?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EntityPageCreateMediaMutationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  mediaReference?: InputMaybe<Scalars['UUID']['input']>;
  mediaType: EntityMediaType;
  pageId: Scalars['SlugOrID']['input'];
  uploadId?: InputMaybe<Scalars['UploadId']['input']>;
};

export type EntityPageCreateVideoMediaMutationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  pageId: Scalars['SlugOrID']['input'];
  videoData: VideoDataInput;
};

export type EntityPageDeleteContactUserMutationInput = {
  contactId: Scalars['ID']['input'];
  pageId: Scalars['SlugOrID']['input'];
};

export type EntityPageDeleteCoverMutationInput = {
  entityPageId: Scalars['SlugOrID']['input'];
};

export type EntityPageDeleteDocumentMutationInput = {
  documentId: Scalars['ID']['input'];
  pageId: Scalars['SlugOrID']['input'];
};

export type EntityPageDeleteInput = {
  pageId: Scalars['SlugOrID']['input'];
};

export type EntityPageDeleteLocationMutationInput = {
  locationId: Scalars['ID']['input'];
  pageId: Scalars['SlugOrID']['input'];
};

export type EntityPageDeleteMediaMutationInput = {
  mediaId: Scalars['ID']['input'];
  pageId: Scalars['SlugOrID']['input'];
};

export type EntityPageDemographics = {
  __typename?: 'EntityPageDemographics';
  byAge?: Maybe<Array<Maybe<AgeGroupElement>>>;
  byCareerLevel?: Maybe<Array<Maybe<CareerLevelElement>>>;
  byTopCompanies?: Maybe<Array<Maybe<TopCompaniesElement>>>;
  byTopIndustries?: Maybe<Array<Maybe<TopIndustriesElement>>>;
};

export const enum EntityPageDemographicsKpi {
  Followers = 'FOLLOWERS',
  Visitors = 'VISITORS',
}

/** Result type for Document mutations that returns the new entity representation on success */
export type EntityPageDocumentMutationResult = {
  __typename?: 'EntityPageDocumentMutationResult';
  error?: Maybe<EntityPagesMutationError>;
  success?: Maybe<EntityDocument>;
};

/** Representation of an error when making a request to entity pages */
export type EntityPageError = {
  __typename?: 'EntityPageError';
  /** #logged-out #soft-logged-in */
  errorCode: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  errorType: Scalars['String']['output'];
};

export type EntityPageFallbackHeaderContent = {
  __typename?: 'EntityPageFallbackHeaderContent';
  /** #logged-out #soft-logged-in */
  message: Scalars['String']['output'];
};

export const enum EntityPageFocusType {
  AmbassadorPage = 'AMBASSADOR_PAGE',
  Company = 'COMPANY',
  GroupPage = 'GROUP_PAGE',
  IndustryPage = 'INDUSTRY_PAGE',
  Publisher = 'PUBLISHER',
  TopicPage = 'TOPIC_PAGE',
}

/** Entity page followers graphics representation */
export type EntityPageFollowers = {
  __typename?: 'EntityPageFollowers';
  /** #rest #virtual */
  histogram?: Maybe<EntityPageInsightsKpiHistogram>;
  /** #rest #virtual */
  totals?: Maybe<EntityPageInsightsKpiTotals>;
  /** #rest #virtual */
  totalsWithUnfollows?: Maybe<EntityPageInsightsFollowersTotals>;
};

/** Entity page followers graphics representation */
export type EntityPageFollowersHistogramArgs = {
  granularity: EntityPageInsightsGranularity;
  timePeriod: EntityPageInsightsTimePeriodInput;
};

/** Entity page followers graphics representation */
export type EntityPageFollowersTotalsArgs = {
  timePeriod: EntityPageInsightsTimePeriodInput;
};

/** Entity page followers graphics representation */
export type EntityPageFollowersTotalsWithUnfollowsArgs = {
  timePeriod: EntityPageInsightsTimePeriodInput;
};

export type EntityPageFollowersMutationInput = {
  followedId: Scalars['ID']['input'];
};

/** Generic module that just has a +type+ field */
export type EntityPageGenericModule = EntityPageModule & {
  __typename?: 'EntityPageGenericModule';
  /** #logged-out #soft-logged-in */
  enabled: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  globalId?: Maybe<Scalars['GlobalID']['output']>;
  permissions?: Maybe<EntityPageModulePermissions>;
  /** #logged-out #soft-logged-in */
  properties?: Maybe<Scalars['JSON']['output']>;
  /** #logged-out #soft-logged-in */
  type: Scalars['String']['output'];
};

export type EntityPageGroupPageHeaderContent = {
  __typename?: 'EntityPageGroupPageHeaderContent';
  /** #logged-out #soft-logged-in */
  group?: Maybe<EntityPageGroupPageHeaderData>;
};

export type EntityPageGroupPageHeaderData = {
  __typename?: 'EntityPageGroupPageHeaderData';
  /**
   * #logged-out #soft-logged-in
   * @deprecated Groups are going to be shutdown at the end of 2022. This field will stop working on 01/10/2022.
   */
  group?: Maybe<GroupsGroup>;
  /**
   * #paginated #logged-out #soft-logged-in
   * @deprecated Groups are going to be shutdown at the end of 2022. This field will stop working on 01/10/2022.
   */
  groupsMemberships?: Maybe<GroupsMembershipsConnection>;
};

export type EntityPageGroupPageHeaderDataGroupsMembershipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type EntityPageHeaderContent =
  | EntityPageCompanyHeaderContent
  | EntityPageFallbackHeaderContent
  | EntityPageGroupPageHeaderContent
  | EntityPagePublisherHeaderContent
  | EntityPageTopicPageHeaderContent;

/** Header module, includes header specific data */
export type EntityPageHeaderModule = EntityPageModule & {
  __typename?: 'EntityPageHeaderModule';
  /** #logged-out #soft-logged-in */
  content?: Maybe<EntityPageHeaderContent>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Deprecated in favour of 'content'. WILL BE REMOVED ...
   */
  data?: Maybe<EntityPageHeaderModuleData>;
  /** #logged-out #soft-logged-in */
  enabled: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  globalId?: Maybe<Scalars['GlobalID']['output']>;
  permissions?: Maybe<EntityPageModulePermissions>;
  /** #logged-out #soft-logged-in */
  properties?: Maybe<Scalars['JSON']['output']>;
  /** #logged-out #soft-logged-in */
  type: Scalars['String']['output'];
};

export type EntityPageHeaderModuleData = {
  __typename?: 'EntityPageHeaderModuleData';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  company?: Maybe<Company>;
};

export type EntityPageInsights = {
  __typename?: 'EntityPageInsights';
  /** #rest #virtual */
  demographics?: Maybe<EntityPageDemographics>;
  /** #expand */
  followers?: Maybe<EntityPageFollowers>;
  /** #expand */
  visitors?: Maybe<EntityPageVisitors>;
};

export type EntityPageInsightsDemographicsArgs = {
  kpi?: InputMaybe<EntityPageDemographicsKpi>;
};

export type EntityPageInsightsFollowersTotals = {
  __typename?: 'EntityPageInsightsFollowersTotals';
  overallTotal?: Maybe<Scalars['Int']['output']>;
  overallTotalUnfollows?: Maybe<Scalars['Int']['output']>;
  periodTotal?: Maybe<Scalars['Int']['output']>;
  periodTotalUnfollows?: Maybe<Scalars['Int']['output']>;
};

export const enum EntityPageInsightsGranularity {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type EntityPageInsightsKpiHistogram = {
  __typename?: 'EntityPageInsightsKpiHistogram';
  collection: Array<EntityPageInsightsKpiHistogramEntry>;
  granularity: EntityPageInsightsGranularity;
};

export type EntityPageInsightsKpiHistogramEntry = {
  __typename?: 'EntityPageInsightsKpiHistogramEntry';
  added: Scalars['Int']['output'];
  removed: Scalars['Int']['output'];
  timestamp: Scalars['Date']['output'];
  total: Scalars['Int']['output'];
};

export type EntityPageInsightsKpiTotals = {
  __typename?: 'EntityPageInsightsKpiTotals';
  overallTotal: Scalars['Int']['output'];
  periodTotal: Scalars['Int']['output'];
};

export type EntityPageInsightsTimePeriodInput = {
  /** An iso8601 encoded UTC datetime, it can't have a + sign */
  from: Scalars['Date']['input'];
  /** An iso8601 encoded UTC datetime, it can't have a + sign */
  to: Scalars['Date']['input'];
};

export type EntityPageLinks = {
  __typename?: 'EntityPageLinks';
  /** #logged-out #soft-logged-in */
  self: Scalars['URL']['output'];
};

export type EntityPageLocation = {
  __typename?: 'EntityPageLocation';
  /** #logged-out #soft-logged-in */
  address: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  addressSuffix?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  city: Scalars['String']['output'];
  /** #logged-out #soft-logged-in #expand */
  coordinates?: Maybe<GpsCoordinates>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  country?: Maybe<Country>;
  /** #logged-out #soft-logged-in */
  email?: Maybe<Scalars['Email']['output']>;
  /** #logged-out #soft-logged-in */
  faxNumber?: Maybe<EntityPagePhoneNumber>;
  /** #logged-out #soft-logged-in */
  highlighted: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  label: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  phoneNumber?: Maybe<EntityPagePhoneNumber>;
  /** #logged-out #soft-logged-in */
  postcode: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  websiteURL?: Maybe<Scalars['URL']['output']>;
};

/** Result type for Locations mutations that returns the new entity representation on success */
export type EntityPageLocationMutationResult = {
  __typename?: 'EntityPageLocationMutationResult';
  error?: Maybe<EntityPagesMutationError>;
  success?: Maybe<EntityPageLocation>;
};

export type EntityPageLocations = {
  __typename?: 'EntityPageLocations';
  /** #logged-out #soft-logged-in */
  collection: Array<EntityPageLocation>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type EntityPageMedia = EntityExternalVideo | EntityVideo | ScaledImage;

/** Result type for Media mutations that returns the new entity representation on success */
export type EntityPageMediaMutationResult = {
  __typename?: 'EntityPageMediaMutationResult';
  error?: Maybe<EntityPagesMutationError>;
  success?: Maybe<EntityMedia>;
};

export type EntityPageModule = {
  /** #logged-out #soft-logged-in */
  enabled: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  globalId?: Maybe<Scalars['GlobalID']['output']>;
  permissions?: Maybe<EntityPageModulePermissions>;
  /** #logged-out #soft-logged-in */
  type: Scalars['String']['output'];
};

export type EntityPageModuleConfig = {
  enabled: Scalars['Boolean']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
};

export type EntityPageModuleConfigInput = {
  collection: Array<EntityPageModuleConfig>;
  pageId: Scalars['ID']['input'];
};

export type EntityPageModuleConfigUpdateResult = {
  __typename?: 'EntityPageModuleConfigUpdateResult';
  error?: Maybe<EntityPagesMutationError>;
  /** #rest #virtual */
  modules?: Maybe<EntityPageModules>;
};

/** Racpac permissions of the module, already resolved for the current user */
export type EntityPageModulePermissions = {
  __typename?: 'EntityPageModulePermissions';
  canRead: Scalars['Boolean']['output'];
};

export type EntityPageModules = {
  __typename?: 'EntityPageModules';
  /** #logged-out #soft-logged-in */
  collection: Array<Maybe<EntityPageModule>>;
};

/** Used when the slug requested is not the current one for the entity page */
export type EntityPageMoved = {
  __typename?: 'EntityPageMoved';
  /** #logged-out #soft-logged-in */
  links: EntityPageLinks;
  /** #logged-out #soft-logged-in */
  slug: Scalars['String']['output'];
};

/** Basic result type for mutations that don't return data on success */
export type EntityPageMutationResult = {
  __typename?: 'EntityPageMutationResult';
  error?: Maybe<EntityPagesMutationError>;
};

export const enum EntityPageParentPage {
  CompaniesLandingPage = 'COMPANIES_LANDING_PAGE',
  GroupPagesLandingPage = 'GROUP_PAGES_LANDING_PAGE',
  NewsLandingPage = 'NEWS_LANDING_PAGE',
}

/** Racpac permissions of the entity page, already resolved for the current user */
export type EntityPagePermissions = {
  __typename?: 'EntityPagePermissions';
  /**
   * Admin permissions include changing a page's title. Only available for selected
   * New Work employees, such as Customer Success.
   */
  canAdmin: Scalars['Boolean']['output'];
  /**
   * Grants permission to edit the vast majority of things that can be edited on a
   * page. Notable exception: The page title.
   */
  canEdit: Scalars['Boolean']['output'];
};

export type EntityPagePhoneNumber = {
  __typename?: 'EntityPagePhoneNumber';
  /**
   * #logged-out #soft-logged-in
   *
   *  Numeric country prefix (without leading `0` or `+`)
   */
  countryCode: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  number: Scalars['String']['output'];
};

export type EntityPageProperties = {
  __typename?: 'EntityPageProperties';
  /** #logged-out #soft-logged-in */
  condensedHeader: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  parentPage: EntityPageParentPage;
};

export const enum EntityPagePublicationStatus {
  Created = 'CREATED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type EntityPagePublicationStatusInput = {
  pageId: Scalars['SlugOrID']['input'];
  publicationStatus: EntityPagePublicationStatus;
};

export type EntityPagePublisherHeaderContent = {
  __typename?: 'EntityPagePublisherHeaderContent';
  /** #paginated #logged-out #soft-logged-in #rest #virtual */
  followers?: Maybe<FollowersConnection>;
  /** #logged-out #soft-logged-in */
  publisher: Scalars['String']['output'];
};

export type EntityPagePublisherHeaderContentFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type EntityPageResult = EntityPage | EntityPageError | EntityPageMoved;

export type EntityPageSocialProof = {
  __typename?: 'EntityPageSocialProof';
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type EntityPageSocialProofConnection = HasCursorPagination & {
  __typename?: 'EntityPageSocialProofConnection';
  edges: Array<Maybe<EntityPageSocialProofEdge>>;
  /** #virtual */
  pageInfo: PageInfo;
  proofType?: Maybe<EntityPageSocialProofType>;
  total: Scalars['Int']['output'];
};

export type EntityPageSocialProofEdge = PaginationEdge & {
  __typename?: 'EntityPageSocialProofEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node?: Maybe<EntityPageSocialProof>;
};

export const enum EntityPageSocialProofType {
  Employees = 'EMPLOYEES',
  Followers = 'FOLLOWERS',
  Members = 'MEMBERS',
}

export const enum EntityPageSupportedContentObjectTypes {
  ArticlesArticle = 'ARTICLES_ARTICLE',
  ArticlePosting = 'ARTICLE_POSTING',
  CompanyPageArticle = 'COMPANY_PAGE_ARTICLE',
  ContentPageArticle = 'CONTENT_PAGE_ARTICLE',
  ImagePosting = 'IMAGE_POSTING',
  LinkSharePosting = 'LINK_SHARE_POSTING',
  PlainTextPosting = 'PLAIN_TEXT_POSTING',
  VideoPosting = 'VIDEO_POSTING',
}

export type EntityPageTopicPageHeaderContent = {
  __typename?: 'EntityPageTopicPageHeaderContent';
  /** #paginated #logged-out #soft-logged-in #rest #virtual */
  followers?: Maybe<FollowersConnection>;
  /** #logged-out #soft-logged-in */
  publisher: Scalars['String']['output'];
};

export type EntityPageTopicPageHeaderContentFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type EntityPageTrackVisitorInput = {
  adId?: InputMaybe<Scalars['String']['input']>;
  appVersion?: InputMaybe<Scalars['String']['input']>;
  /** This IP field is currently resolved using the ctx.clientId directive, so values added manually will be ignored */
  forwardedFor?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<EntityPageTrackVisitorPlatform>;
  platformVersion?: InputMaybe<Scalars['String']['input']>;
  referer?: InputMaybe<Scalars['String']['input']>;
  /** This IP field is currently resolved using the ctx.clientId directive, so values added manually will be ignored */
  remoteAddr?: InputMaybe<Scalars['String']['input']>;
  requestUri?: InputMaybe<Scalars['String']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  visitorId?: InputMaybe<Scalars['String']['input']>;
};

export type EntityPageTrackVisitorMutationInput = {
  pageId: Scalars['SlugOrID']['input'];
  trackingData: EntityPageTrackVisitorInput;
};

export const enum EntityPageTrackVisitorPlatform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

export type EntityPageUpdateContactUserInput = {
  contactId: Scalars['ID']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  pageId: Scalars['SlugOrID']['input'];
};

export type EntityPageUpdateContactUsersCatalogueMutationInput = {
  contacts: Array<ContactUsersCatalogueItemInput>;
  pageId: Scalars['SlugOrID']['input'];
};

export type EntityPageUpdateCoverMutationInput = {
  coverMediaReference?: InputMaybe<Scalars['UUID']['input']>;
  coverMediaType: EntityPageCoverMediaType;
  coverMediaUploadId?: InputMaybe<Scalars['UploadId']['input']>;
  entityPageId: Scalars['SlugOrID']['input'];
  originalCoverMediaReference?: InputMaybe<Scalars['UUID']['input']>;
  originalCoverMediaUploadId?: InputMaybe<Scalars['UploadId']['input']>;
};

export type EntityPageUpdateDocumentMutationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  pageId: Scalars['SlugOrID']['input'];
};

export type EntityPageUpdateLocationInput = {
  address: Scalars['String']['input'];
  addressSuffix?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  cityId?: InputMaybe<Scalars['ID']['input']>;
  countryIsoCode: CountryCodesAlpha2;
  email?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  faxNumberCountryCode?: InputMaybe<Scalars['Int']['input']>;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  locationId: Scalars['ID']['input'];
  longitude?: InputMaybe<Scalars['Float']['input']>;
  pageId: Scalars['SlugOrID']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberCountryCode?: InputMaybe<Scalars['Int']['input']>;
  postcode: Scalars['String']['input'];
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EntityPageUpdateLogoMutationInput = {
  entityPageId: Scalars['SlugOrID']['input'];
  logoUploadId?: InputMaybe<Scalars['UploadId']['input']>;
  logoUuid?: InputMaybe<Scalars['UUID']['input']>;
  originalLogoUploadId?: InputMaybe<Scalars['UploadId']['input']>;
  originalLogoUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type EntityPageUpdateMediaGalleryMutationInput = {
  mediaItems: Array<EntityMediaGalleryItemInput>;
  pageId: Scalars['SlugOrID']['input'];
};

export type EntityPageUpdateMediaMutationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  mediaId: Scalars['ID']['input'];
  pageId: Scalars['SlugOrID']['input'];
};

export type EntityPageUpdateVideoMediaMutationInput = {
  mediaId: Scalars['ID']['input'];
  pageId: Scalars['SlugOrID']['input'];
  videoData?: InputMaybe<VideoUpdateDataInput>;
};

export type EntityPageUserContext = {
  __typename?: 'EntityPageUserContext';
  /**
   * #rest(batched) #virtual
   * @deprecated This attribute was moved to userInteractions, as we're now using fragments to distinguish between follow and join action. WILL BE REMOVED ...
   */
  followState?: Maybe<FollowContext>;
  /** @deprecated Groups are going to be shutdown at the end of 2022. This field only returns static `false` values. */
  groupPermissions?: Maybe<GroupsAllowedActionsOnGroup>;
  permissions: EntityPagePermissions;
  /**
   * #paginated #rest #virtual
   *
   *  The social proof for the entity page
   */
  socialProof?: Maybe<EntityPageSocialProofConnection>;
  userInteractions?: Maybe<EntityPageUserInteractionContext>;
  /** @deprecated Entity Pages no longer store information about the user's role for the page's entity. Use permissions for access control instead of roles */
  userRole?: Maybe<EntityPageUserRole>;
};

export type EntityPageUserContextSocialProofArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type EntityPageUserInteractionContext =
  | EntityPageUserInteractionFallback
  | EntityPageUserInteractionFollow
  | EntityPageUserInteractionJoin;

export type EntityPageUserInteractionFallback = {
  __typename?: 'EntityPageUserInteractionFallback';
  /** #logged-out #soft-logged-in */
  message: Scalars['String']['output'];
};

export type EntityPageUserInteractionFollow = {
  __typename?: 'EntityPageUserInteractionFollow';
  /** #rest(batched) #virtual */
  followState?: Maybe<FollowContext>;
};

export type EntityPageUserInteractionJoin = {
  __typename?: 'EntityPageUserInteractionJoin';
  /**
   * #paginated
   * @deprecated Groups are going to be shutdown at the end of 2022. This field will stop working on 01/10/2022.
   */
  groupsMemberships?: Maybe<GroupsMembershipsConnection>;
};

export type EntityPageUserInteractionJoinGroupsMembershipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export const enum EntityPageUserRole {
  Administrator = 'ADMINISTRATOR',
  Editor = 'EDITOR',
  Member = 'MEMBER',
  Owner = 'OWNER',
}

/** Result type for Video Media mutations that returns the signed url to be used to upload the video */
export type EntityPageVideoMediaMutationResult = {
  __typename?: 'EntityPageVideoMediaMutationResult';
  error?: Maybe<EntityPagesMutationError>;
  success?: Maybe<EntityVideoUploadResponse>;
};

export const enum EntityPageVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type EntityPageVisitor = {
  __typename?: 'EntityPageVisitor';
  /** #rest(batched) #virtual */
  contactDistance?: Maybe<ContactDistance>;
  lastVisitedAt: Scalars['Date']['output'];
  /** #rest(batched) #virtual */
  networkRelationship?: Maybe<NetworkRelationship>;
  /** #rest(batched) #virtual */
  profileDetails?: Maybe<XingId>;
  /** #rest(batched) #virtual */
  sharedContacts?: Maybe<SharedContacts>;
  userId: Scalars['ID']['output'];
  visitCount: Scalars['Int']['output'];
};

export type EntityPageVisitors = {
  __typename?: 'EntityPageVisitors';
  /** #rest #virtual */
  histogram?: Maybe<EntityPageVisitorsInsightsKpiHistogram>;
  /** #rest #virtual */
  referers?: Maybe<EntityPagesVisitorReferers>;
  /** #rest #virtual */
  totals?: Maybe<EntityPageInsightsKpiTotals>;
};

export type EntityPageVisitorsHistogramArgs = {
  granularity: EntityPageInsightsGranularity;
  onlyFollowers?: InputMaybe<Scalars['Boolean']['input']>;
  onlyLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  onlyLoggedOut?: InputMaybe<Scalars['Boolean']['input']>;
  timePeriod: EntityPageInsightsTimePeriodInput;
};

export type EntityPageVisitorsReferersArgs = {
  onlyLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  onlyLoggedOut?: InputMaybe<Scalars['Boolean']['input']>;
  timePeriod: EntityPageInsightsTimePeriodInput;
  topReferersLimit: Scalars['Int']['input'];
};

export type EntityPageVisitorsTotalsArgs = {
  onlyFollowers?: InputMaybe<Scalars['Boolean']['input']>;
  onlyLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  onlyLoggedOut?: InputMaybe<Scalars['Boolean']['input']>;
  timePeriod: EntityPageInsightsTimePeriodInput;
};

/**
 * Returns a collection with the visitors of the entity page.
 *
 * If the `rid` is not an editor for the entity page, then the backing endpoint will generate a 403 error and the resolution will fail.
 */
export type EntityPageVisitorsCollection = {
  __typename?: 'EntityPageVisitorsCollection';
  collection: Array<Maybe<EntityPageVisitor>>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type EntityPageVisitorsInsightsKpiHistogram = {
  __typename?: 'EntityPageVisitorsInsightsKpiHistogram';
  collection: Array<EntityPageVisitorsInsightsKpiHistogramEntry>;
  granularity: EntityPageInsightsGranularity;
};

export type EntityPageVisitorsInsightsKpiHistogramEntry = {
  __typename?: 'EntityPageVisitorsInsightsKpiHistogramEntry';
  organic: Scalars['Int']['output'];
  promotions: Scalars['Int']['output'];
  timestamp: Scalars['Date']['output'];
  total: Scalars['Int']['output'];
};

export type EntityPagesMutationError = {
  __typename?: 'EntityPagesMutationError';
  /** #logged-out #soft-logged-in */
  errorCode: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  errorType: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  errors?: Maybe<Scalars['JSON']['output']>;
};

export type EntityPagesVisitorReferer = {
  __typename?: 'EntityPagesVisitorReferer';
  collection: Array<EntityPagesVisitorRefererValue>;
  total: Scalars['Int']['output'];
};

export type EntityPagesVisitorRefererValue = {
  __typename?: 'EntityPagesVisitorRefererValue';
  percentage: Scalars['Float']['output'];
  referer: Scalars['String']['output'];
};

export type EntityPagesVisitorReferers = {
  __typename?: 'EntityPagesVisitorReferers';
  other: EntityPagesVisitorReferer;
  searchEngines: EntityPagesVisitorReferer;
  websiteLinks: EntityPagesVisitorReferer;
  xing: EntityPagesVisitorReferer;
};

export type EntityTypeAggregation = HasAggregationCount & {
  __typename?: 'EntityTypeAggregation';
  count: Scalars['Int']['output'];
  value: EntityTypeValue;
};

export const enum EntityTypeValue {
  Article = 'ARTICLE',
  Insider = 'INSIDER',
  Page = 'PAGE',
}

export type EntityVideo = {
  __typename?: 'EntityVideo';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  videoDetailsV2?: Maybe<VideoV2>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Dropped in favour of videoReferenceV2. WILL BE REMOVED ...
   */
  videoReference: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  videoReferenceV2: Scalars['SlugOrID']['output'];
};

export type EntityVideoUploadResponse = VideoUploadResponse & {
  __typename?: 'EntityVideoUploadResponse';
  id: Scalars['ID']['output'];
  videoDetails?: Maybe<VideoUploadDetails>;
};

export type ErecruitingSourcingServiceArchiveResult = {
  __typename?: 'ErecruitingSourcingServiceArchiveResult';
  error?: Maybe<ErecruitingSourcingServiceMutationError>;
};

export type ErecruitingSourcingServiceArchiveStatusInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  briefingId: Scalars['ID']['input'];
};

export type ErecruitingSourcingServiceBadRequestError = {
  __typename?: 'ErecruitingSourcingServiceBadRequestError';
  errors?: Maybe<Array<Maybe<ErecruitingSourcingServiceValidationError>>>;
  message?: Maybe<Scalars['String']['output']>;
};

/**
 * Note: Though types like Discipline, Industry exist in X1
 *       the usage here is merely for persisting & reading the actual value
 *       and not for rendering the associated resolvers.
 *
 *       i.e. They are to be used by the Position Details Form which
 *       has it's own backend for frontend service that provides this
 *       data for autocompleters in the form.
 */
export type ErecruitingSourcingServiceBriefing = {
  __typename?: 'ErecruitingSourcingServiceBriefing';
  archived?: Maybe<Scalars['Boolean']['output']>;
  businessEmail: Scalars['Email']['output'];
  businessPhoneNumber: Scalars['String']['output'];
  /** #rest(batched) #virtual */
  careerLevel?: Maybe<CareerLevel>;
  careerLevelId: Scalars['Int']['output'];
  /** #rest(batched) #virtual */
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']['output']>;
  companyName: Scalars['String']['output'];
  companyNotes?: Maybe<Scalars['String']['output']>;
  courseOfStudy?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** #rest(batched) #virtual */
  creator?: Maybe<XingId>;
  creatorId: Scalars['ID']['output'];
  currencyCode?: Maybe<Currency>;
  degree?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  discipline?: Maybe<Discipline>;
  disciplineId: Scalars['Int']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  education?: Maybe<Scalars['String']['output']>;
  fullRemote?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  industry?: Maybe<Industry>;
  industryId: Scalars['Int']['output'];
  isShared?: Maybe<Scalars['Boolean']['output']>;
  jobDescription: Scalars['String']['output'];
  /** #rest(batched) #virtual */
  jobLocation?: Maybe<City>;
  jobLocationCity?: Maybe<Scalars['String']['output']>;
  jobLocationId: Scalars['Int']['output'];
  jobPostCode?: Maybe<Scalars['String']['output']>;
  jobTitle: Scalars['String']['output'];
  jobType: Scalars['String']['output'];
  jobroleId?: Maybe<Scalars['Int']['output']>;
  leadershipResponsibility?: Maybe<Scalars['String']['output']>;
  linkedRecordId?: Maybe<Scalars['Int']['output']>;
  linkedRecordSource?: Maybe<Scalars['URL']['output']>;
  linkedRecordType?: Maybe<Scalars['String']['output']>;
  positionCountryCode?: Maybe<Scalars['String']['output']>;
  positionLocationCities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  positionLocationCoordinates?: Maybe<
    Array<Maybe<ErecruitingSourcingServiceBriefingCoordinates>>
  >;
  positionLocationIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  positionRadius?: Maybe<Scalars['Int']['output']>;
  previousJobroleId?: Maybe<Scalars['Int']['output']>;
  previousPosition?: Maybe<Scalars['String']['output']>;
  /** @deprecated Field no longer supported */
  productType?: Maybe<ErecruitingSourcingServiceProductType>;
  productTypeNew?: Maybe<ErecruitingSourcingServiceProductTypeLowCase>;
  /** #rest(batched) #virtual */
  referenceProfile?: Maybe<XingId>;
  referenceProfileId?: Maybe<Scalars['ID']['output']>;
  /** #rest #virtual */
  reports?: Maybe<ErecruitingSourcingServiceBriefingReportsCollectionResponse>;
  requiredLanguages?: Maybe<Array<Maybe<ErecruitingSourcingServiceLanguage>>>;
  requiredSkills?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  salaryRange?: Maybe<ErecruitingSourcingServiceSalaryRange>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  workExperienceId?: Maybe<Scalars['Int']['output']>;
  workflowStatus?: Maybe<Scalars['String']['output']>;
  xtmProjectId?: Maybe<Scalars['ID']['output']>;
};

export type ErecruitingSourcingServiceBriefingCandidate = {
  __typename?: 'ErecruitingSourcingServiceBriefingCandidate';
  /** #rest #virtual */
  briefing?: Maybe<ErecruitingSourcingServiceBriefingResponse>;
  briefingId: Scalars['ID']['output'];
  candidateId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  candidateNotes?: Maybe<ErecruitingSourcingServiceCandidateNote>;
  clientStatus?: Maybe<BriefingCandidateClientStatus>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** #rest(batched) #virtual */
  creator: XingId;
  creatorId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  urn: Scalars['GlobalID']['output'];
  /** #rest(batched) #virtual */
  user: XingId;
  userId: Scalars['ID']['output'];
};

export const enum ErecruitingSourcingServiceBriefingCandidateLastDaysActive {
  All = 'ALL',
  LastMonth = 'LAST_MONTH',
  LastThreeMonths = 'LAST_THREE_MONTHS',
  LastTwoMonths = 'LAST_TWO_MONTHS',
  LastYear = 'LAST_YEAR',
}

export type ErecruitingSourcingServiceBriefingCandidateListResponse = {
  __typename?: 'ErecruitingSourcingServiceBriefingCandidateListResponse';
  collection: Array<ErecruitingSourcingServiceBriefingCandidate>;
  total: Scalars['Int']['output'];
};

export type ErecruitingSourcingServiceBriefingCandidateMutationResult = {
  __typename?: 'ErecruitingSourcingServiceBriefingCandidateMutationResult';
  error?: Maybe<Array<Maybe<ErecruitingSourcingServiceFieldMutationError>>>;
  success?: Maybe<ErecruitingSourcingServiceBriefingCandidateResponse>;
};

export type ErecruitingSourcingServiceBriefingCandidateResponse = {
  __typename?: 'ErecruitingSourcingServiceBriefingCandidateResponse';
  briefingCandidate?: Maybe<ErecruitingSourcingServiceBriefingCandidate>;
  isFirstCandidate: Scalars['Boolean']['output'];
};

export type ErecruitingSourcingServiceBriefingCandidateUpdateResult = {
  __typename?: 'ErecruitingSourcingServiceBriefingCandidateUpdateResult';
  error?: Maybe<ErecruitingSourcingServiceFieldMutationError>;
};

export type ErecruitingSourcingServiceBriefingCoordinates = {
  __typename?: 'ErecruitingSourcingServiceBriefingCoordinates';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type ErecruitingSourcingServiceBriefingCoordinatesInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type ErecruitingSourcingServiceBriefingListResponse = {
  __typename?: 'ErecruitingSourcingServiceBriefingListResponse';
  collection: Array<ErecruitingSourcingServiceBriefing>;
  total: Scalars['Int']['output'];
};

export type ErecruitingSourcingServiceBriefingMetadata = {
  __typename?: 'ErecruitingSourcingServiceBriefingMetadata';
  additionalInfoSortOrder?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  candidatesLastActiveDays?: Maybe<Scalars['Int']['output']>;
  candidatesSortOrder?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type ErecruitingSourcingServiceBriefingMetadataFieldsInput = {
  additionalInfoSortOrder?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  candidatesLastActiveDays?: InputMaybe<ErecruitingSourcingServiceBriefingCandidateLastDaysActive>;
  candidatesSortOrder?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ErecruitingSourcingServiceBriefingMetadataInput = {
  briefingId: Scalars['ID']['input'];
  briefingMetadata?: InputMaybe<ErecruitingSourcingServiceBriefingMetadataFieldsInput>;
};

export type ErecruitingSourcingServiceBriefingMetadataResponse = {
  __typename?: 'ErecruitingSourcingServiceBriefingMetadataResponse';
  briefingMetadata: ErecruitingSourcingServiceBriefingMetadata;
};

export type ErecruitingSourcingServiceBriefingMetadataUpdateResult = {
  __typename?: 'ErecruitingSourcingServiceBriefingMetadataUpdateResult';
  error?: Maybe<Array<Maybe<ErecruitingSourcingServiceFieldMutationError>>>;
  success?: Maybe<ErecruitingSourcingServiceBriefingMetadataResponse>;
};

/** Types for XTS Briefing Reports */
export type ErecruitingSourcingServiceBriefingReport = {
  __typename?: 'ErecruitingSourcingServiceBriefingReport';
  briefingId: Scalars['ID']['output'];
  consultantNotes?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  nextStepsNotes?: Maybe<Scalars['String']['output']>;
  projectId: Scalars['ID']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  reportDateEnd?: Maybe<Scalars['Date']['output']>;
  reportDateStart?: Maybe<Scalars['Date']['output']>;
  resentAt?: Maybe<Array<Maybe<Scalars['Date']['output']>>>;
  sentAt?: Maybe<Scalars['Date']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ErecruitingSourcingServiceBriefingReportCreateResult =
  | ErecruitingSourcingServiceBadRequestError
  | ErecruitingSourcingServiceBriefingReportResponse
  | ErecruitingSourcingServiceMutationError;

export type ErecruitingSourcingServiceBriefingReportResponse = {
  __typename?: 'ErecruitingSourcingServiceBriefingReportResponse';
  briefingReport: ErecruitingSourcingServiceBriefingReport;
};

export type ErecruitingSourcingServiceBriefingReportsCollectionResponse = {
  __typename?: 'ErecruitingSourcingServiceBriefingReportsCollectionResponse';
  collection: Array<Maybe<ErecruitingSourcingServiceBriefingReport>>;
  total: Scalars['Int']['output'];
};

export type ErecruitingSourcingServiceBriefingResponse = {
  __typename?: 'ErecruitingSourcingServiceBriefingResponse';
  briefing?: Maybe<ErecruitingSourcingServiceBriefing>;
};

/** Types for Xing Sourcing Service Candidate Notes */
export type ErecruitingSourcingServiceCandidateNote = {
  __typename?: 'ErecruitingSourcingServiceCandidateNote';
  /** #rest #virtual */
  briefing?: Maybe<ErecruitingSourcingServiceBriefingResponse>;
  candidateId: Scalars['ID']['output'];
  candidateIdentityId: Scalars['ID']['output'];
  clientNotes?: Maybe<Scalars['String']['output']>;
  consultantNotes?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** #rest(batched) #virtual */
  creator?: Maybe<XingId>;
  creatorId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  noticePeriodId?: Maybe<Scalars['Int']['output']>;
  noticePeriodLocalizationKey?: Maybe<Scalars['String']['output']>;
  salaryExpectationId?: Maybe<Scalars['Int']['output']>;
  salaryExpectationLocalizationKey?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  urn: Scalars['GlobalID']['output'];
  willingnessToRelocate?: Maybe<Scalars['Boolean']['output']>;
  willingnessToTravelId?: Maybe<Scalars['Int']['output']>;
  willingnessToTravelLocalizationKey?: Maybe<Scalars['String']['output']>;
};

export type ErecruitingSourcingServiceCandidateNoteListResponse = {
  __typename?: 'ErecruitingSourcingServiceCandidateNoteListResponse';
  collection: Array<Maybe<ErecruitingSourcingServiceCandidateNote>>;
  total: Scalars['Int']['output'];
};

export type ErecruitingSourcingServiceCandidateNoteMutationResult = {
  __typename?: 'ErecruitingSourcingServiceCandidateNoteMutationResult';
  error?: Maybe<Array<Maybe<ErecruitingSourcingServiceFieldMutationError>>>;
  success?: Maybe<ErecruitingSourcingServiceCandidateNoteResponse>;
};

export type ErecruitingSourcingServiceCandidateNoteResponse = {
  __typename?: 'ErecruitingSourcingServiceCandidateNoteResponse';
  candidateNote?: Maybe<ErecruitingSourcingServiceCandidateNote>;
};

export type ErecruitingSourcingServiceCandidateRecommendationDiscardMutationResult =
  {
    __typename?: 'ErecruitingSourcingServiceCandidateRecommendationDiscardMutationResult';
    error?: Maybe<ErecruitingSourcingServiceMutationError>;
  };

export type ErecruitingSourcingServiceCandidateRecommendationsDiscardInput = {
  candidateUserId: Scalars['ID']['input'];
  xtmProjectId: Scalars['ID']['input'];
};

export type ErecruitingSourcingServiceCandidateRecommendationsEntry = {
  __typename?: 'ErecruitingSourcingServiceCandidateRecommendationsEntry';
  highlights?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  originalRecoQuality?: Maybe<Scalars['String']['output']>;
  originalScore?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  /** #rest(batched) #virtual */
  profile: XingId;
  recoQuality?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

export type ErecruitingSourcingServiceCandidateRecommendationsOrderInput = {
  candidateIds: Array<InputMaybe<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type ErecruitingSourcingServiceCandidateRecommendationsOrderResponse = {
  __typename?: 'ErecruitingSourcingServiceCandidateRecommendationsOrderResponse';
  entries: Array<Maybe<Scalars['ID']['output']>>;
};

export type ErecruitingSourcingServiceCandidateRecommendationsResponse = {
  __typename?: 'ErecruitingSourcingServiceCandidateRecommendationsResponse';
  currentPage?: Maybe<Scalars['Int']['output']>;
  entries?: Maybe<
    Array<Maybe<ErecruitingSourcingServiceCandidateRecommendationsEntry>>
  >;
  totalPages?: Maybe<Scalars['Int']['output']>;
  totalResults?: Maybe<Scalars['Int']['output']>;
};

export type ErecruitingSourcingServiceChangeBriefingOwnerError = {
  __typename?: 'ErecruitingSourcingServiceChangeBriefingOwnerError';
  error?: Maybe<ErecruitingSourcingServiceMutationError>;
};

export type ErecruitingSourcingServiceChangeBriefingOwnerInput = {
  briefingId: Scalars['ID']['input'];
  creatorId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['Email']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ErecruitingSourcingServiceCreateBriefingCandidateInput = {
  briefingId: Scalars['Int']['input'];
  briefingStatusId?: InputMaybe<Scalars['Int']['input']>;
  candidateId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type ErecruitingSourcingServiceCreateBriefingInput = {
  businessEmail: Scalars['Email']['input'];
  businessPhoneNumber: Scalars['String']['input'];
  careerLevelId: Scalars['Int']['input'];
  companyId?: InputMaybe<Scalars['ID']['input']>;
  companyName: Scalars['String']['input'];
  companyNotes?: InputMaybe<Scalars['String']['input']>;
  courseOfStudy?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Currency>;
  degree?: InputMaybe<Scalars['String']['input']>;
  disciplineId: Scalars['Int']['input'];
  duration?: InputMaybe<Scalars['Int']['input']>;
  education?: InputMaybe<Scalars['String']['input']>;
  fullRemote?: InputMaybe<Scalars['Boolean']['input']>;
  industryId: Scalars['Int']['input'];
  jobDescription: Scalars['String']['input'];
  jobLocationCity?: InputMaybe<Scalars['String']['input']>;
  jobLocationId: Scalars['Int']['input'];
  jobPostCode?: InputMaybe<Scalars['String']['input']>;
  jobTitle: Scalars['String']['input'];
  jobType: Scalars['String']['input'];
  jobroleId?: InputMaybe<Scalars['Int']['input']>;
  leadershipResponsibility?: InputMaybe<Scalars['String']['input']>;
  linkedRecordId?: InputMaybe<Scalars['Int']['input']>;
  linkedRecordSource?: InputMaybe<Scalars['URL']['input']>;
  linkedRecordType?: InputMaybe<Scalars['String']['input']>;
  positionCountryCode?: InputMaybe<Scalars['String']['input']>;
  positionLocationCities?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  positionLocationCoordinates?: InputMaybe<
    Array<InputMaybe<ErecruitingSourcingServiceBriefingCoordinatesInput>>
  >;
  positionLocationIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  positionRadius?: InputMaybe<Scalars['Int']['input']>;
  previousJobroleId?: InputMaybe<Scalars['Int']['input']>;
  previousPosition?: InputMaybe<Scalars['String']['input']>;
  productType?: InputMaybe<ErecruitingSourcingServiceProductType>;
  referenceProfileId?: InputMaybe<Scalars['ID']['input']>;
  requiredLanguages?: InputMaybe<
    Array<InputMaybe<ErecruitingSourcingServiceLanguageInput>>
  >;
  requiredSkills?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salaryRange?: InputMaybe<ErecruitingSourcingServiceSalaryRangeInput>;
  workExperienceId?: InputMaybe<Scalars['Int']['input']>;
};

export type ErecruitingSourcingServiceCreateBriefingReportInput = {
  briefingId: Scalars['ID']['input'];
  consultantNotes?: InputMaybe<Scalars['String']['input']>;
  nextStepsNotes: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  reportDateEnd: Scalars['Date']['input'];
  reportDateStart: Scalars['Date']['input'];
  sentAt?: InputMaybe<Scalars['Date']['input']>;
};

export type ErecruitingSourcingServiceCreateCandidateNoteInput = {
  briefingId: Scalars['Int']['input'];
  candidateId: Scalars['Int']['input'];
  candidateIdentityId: Scalars['Int']['input'];
  consultantNotes: Scalars['String']['input'];
  noticePeriodId?: InputMaybe<Scalars['Int']['input']>;
  salaryExpectationId?: InputMaybe<Scalars['Int']['input']>;
  willingnessToRelocate?: InputMaybe<Scalars['Boolean']['input']>;
  willingnessToTravelId?: InputMaybe<Scalars['Int']['input']>;
};

export type ErecruitingSourcingServiceCreateClientCandidateNoteInput = {
  briefingId: Scalars['ID']['input'];
  candidateId: Scalars['ID']['input'];
  candidateIdentityId: Scalars['ID']['input'];
  clientNotes: Scalars['String']['input'];
};

export type ErecruitingSourcingServiceDeleteBriefingCandidateInput = {
  briefingId: Scalars['Int']['input'];
  candidateId: Scalars['Int']['input'];
};

export type ErecruitingSourcingServiceDeleteBriefingCandidateMutationResult = {
  __typename?: 'ErecruitingSourcingServiceDeleteBriefingCandidateMutationResult';
  error?: Maybe<ErecruitingSourcingServiceMutationError>;
};

export type ErecruitingSourcingServiceDeleteMessageReminderInput = {
  id: Scalars['ID']['input'];
};

export type ErecruitingSourcingServiceE2EUsersDeleteError = {
  __typename?: 'ErecruitingSourcingServiceE2EUsersDeleteError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

/** Result type for deleting a document from user that returns 200 on success */
export type ErecruitingSourcingServiceE2EUsersDeleteResult =
  | ErecruitingSourcingServiceE2EUsersDeleteError
  | ErecruitingSourcingServiceE2EUsersDeleteSuccess;

export type ErecruitingSourcingServiceE2EUsersDeleteSuccess = {
  __typename?: 'ErecruitingSourcingServiceE2EUsersDeleteSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ErecruitingSourcingServiceFieldMutationError = {
  __typename?: 'ErecruitingSourcingServiceFieldMutationError';
  errors?: Maybe<Array<Maybe<ErecruitingSourcingServiceMutationError>>>;
  field?: Maybe<Scalars['String']['output']>;
};

export type ErecruitingSourcingServiceLanguage = {
  __typename?: 'ErecruitingSourcingServiceLanguage';
  code: Scalars['String']['output'];
  levelId?: Maybe<Scalars['Int']['output']>;
};

export type ErecruitingSourcingServiceLanguageInput = {
  code: Scalars['String']['input'];
  levelId?: InputMaybe<Scalars['Int']['input']>;
};

export type ErecruitingSourcingServiceMessageReminder = {
  __typename?: 'ErecruitingSourcingServiceMessageReminder';
  briefingId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  candidate?: Maybe<XingId>;
  chatId: Scalars['ID']['output'];
  consultantId: Scalars['ID']['output'];
  firstReminder?: Maybe<ErecruitingSourcingServiceMessageReminderPartial>;
  projectId: Scalars['ID']['output'];
  secondReminder?: Maybe<ErecruitingSourcingServiceMessageReminderPartial>;
};

export type ErecruitingSourcingServiceMessageReminderMetadataMutationResult = {
  __typename?: 'ErecruitingSourcingServiceMessageReminderMetadataMutationResult';
  error?: Maybe<ErecruitingSourcingServiceMutationError>;
  success?: Maybe<ErecruitingSourcingServiceMessageReminderMetadataResponse>;
};

/** Types for Xing Sourcing Service Message Reminders */
export type ErecruitingSourcingServiceMessageReminderMetadataResponse = {
  __typename?: 'ErecruitingSourcingServiceMessageReminderMetadataResponse';
  briefingId: Scalars['ID']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** #rest #virtual */
  firstReminder?: Maybe<XtmMessageTemplateResponse>;
  projectId: Scalars['ID']['output'];
  /** #rest #virtual */
  secondReminder?: Maybe<XtmMessageTemplateResponse>;
  tone?: Maybe<ErecruitingSourcingServiceMessageTone>;
};

export type ErecruitingSourcingServiceMessageReminderPartial = {
  __typename?: 'ErecruitingSourcingServiceMessageReminderPartial';
  createdAt?: Maybe<Scalars['Date']['output']>;
  expiresAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Field no longer supported */
  sent?: Maybe<Scalars['Boolean']['output']>;
  sentAt?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<ErecruitingSourcingServiceMessageReminderStatus>;
  type?: Maybe<ErecruitingSourcingServiceMessageReminderType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

/** Types for Xing Sourcing Service Message Reminders */
export const enum ErecruitingSourcingServiceMessageReminderStatus {
  Failed = 'FAILED',
  MissingTemplate = 'MISSING_TEMPLATE',
  Pending = 'PENDING',
  Sent = 'SENT',
}

export const enum ErecruitingSourcingServiceMessageReminderType {
  FirstReminder = 'FIRST_REMINDER',
  SecondReminder = 'SECOND_REMINDER',
}

export type ErecruitingSourcingServiceMessageRemindersListResponse = {
  __typename?: 'ErecruitingSourcingServiceMessageRemindersListResponse';
  collection: Array<Maybe<ErecruitingSourcingServiceMessageReminder>>;
  total: Scalars['Int']['output'];
};

export const enum ErecruitingSourcingServiceMessageTone {
  Formal = 'FORMAL',
  Informal = 'INFORMAL',
}

export type ErecruitingSourcingServiceMutationError = Localizable & {
  __typename?: 'ErecruitingSourcingServiceMutationError';
  localizationKey: Scalars['String']['output'];
  localizationValue: Scalars['String']['output'];
};

export type ErecruitingSourcingServiceMutationResult = {
  __typename?: 'ErecruitingSourcingServiceMutationResult';
  error?: Maybe<Array<Maybe<ErecruitingSourcingServiceFieldMutationError>>>;
  success?: Maybe<ErecruitingSourcingServiceBriefingResponse>;
};

export type ErecruitingSourcingServiceNoticePeriod = {
  __typename?: 'ErecruitingSourcingServiceNoticePeriod';
  id: Scalars['Int']['output'];
  localizationKey: Scalars['String']['output'];
};

export type ErecruitingSourcingServiceNoticePeriodsListResponse = {
  __typename?: 'ErecruitingSourcingServiceNoticePeriodsListResponse';
  collection: Array<Maybe<ErecruitingSourcingServiceNoticePeriod>>;
  total: Scalars['Int']['output'];
};

export const enum ErecruitingSourcingServiceProductType {
  Longlist = 'LONGLIST',
  Shortlist = 'SHORTLIST',
}

export const enum ErecruitingSourcingServiceProductTypeLowCase {
  Longlist = 'longlist',
  Shortlist = 'shortlist',
}

export type ErecruitingSourcingServiceSalaryExpectation = {
  __typename?: 'ErecruitingSourcingServiceSalaryExpectation';
  id: Scalars['Int']['output'];
  localizationKey: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type ErecruitingSourcingServiceSalaryExpectationsListResponse = {
  __typename?: 'ErecruitingSourcingServiceSalaryExpectationsListResponse';
  collection: Array<Maybe<ErecruitingSourcingServiceSalaryExpectation>>;
  total: Scalars['Int']['output'];
};

/** Types for Xing Sourcing Service Briefings */
export type ErecruitingSourcingServiceSalaryRange = {
  __typename?: 'ErecruitingSourcingServiceSalaryRange';
  maximum?: Maybe<Scalars['Int']['output']>;
  minimum?: Maybe<Scalars['Int']['output']>;
};

export type ErecruitingSourcingServiceSalaryRangeInput = {
  maximum?: InputMaybe<Scalars['Int']['input']>;
  minimum?: InputMaybe<Scalars['Int']['input']>;
};

export type ErecruitingSourcingServiceShareBriefingInput = {
  briefingId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ErecruitingSourcingServiceShareResult = {
  __typename?: 'ErecruitingSourcingServiceShareResult';
  error?: Maybe<Array<Maybe<ErecruitingSourcingServiceFieldMutationError>>>;
};

export type ErecruitingSourcingServiceUpdateBriefingCandidateStatusInput = {
  briefingId: Scalars['Int']['input'];
  candidateStatus: BriefingCandidateClientStatus;
  userId: Scalars['Int']['input'];
};

export type ErecruitingSourcingServiceUpdateCandidateNoteInput = {
  briefingId: Scalars['Int']['input'];
  candidateId: Scalars['Int']['input'];
  candidateIdentityId?: InputMaybe<Scalars['Int']['input']>;
  consultantNotes?: InputMaybe<Scalars['String']['input']>;
  noticePeriodId?: InputMaybe<Scalars['Int']['input']>;
  salaryExpectationId?: InputMaybe<Scalars['Int']['input']>;
  willingnessToRelocate?: InputMaybe<Scalars['Boolean']['input']>;
  willingnessToTravelId?: InputMaybe<Scalars['Int']['input']>;
};

export type ErecruitingSourcingServiceUpdateClientCandidateNoteInput = {
  candidateNoteId: Scalars['ID']['input'];
  clientNotes?: InputMaybe<Scalars['String']['input']>;
};

export type ErecruitingSourcingServiceUpdateMessageReminderMetadataInput = {
  briefingId: Scalars['ID']['input'];
  enableReminderMessages: Scalars['Boolean']['input'];
  firstTemplateId?: InputMaybe<Scalars['ID']['input']>;
  reminderMessagesTone?: InputMaybe<ErecruitingSourcingServiceMessageTone>;
  secondTemplateId?: InputMaybe<Scalars['ID']['input']>;
};

export type ErecruitingSourcingServiceValidationError = {
  __typename?: 'ErecruitingSourcingServiceValidationError';
  message?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type ErecruitingSourcingServiceWillingnessToTravel = {
  __typename?: 'ErecruitingSourcingServiceWillingnessToTravel';
  id: Scalars['Int']['output'];
  localizationKey: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type ErecruitingSourcingServiceWillingnessToTravelListResponse = {
  __typename?: 'ErecruitingSourcingServiceWillingnessToTravelListResponse';
  collection: Array<Maybe<ErecruitingSourcingServiceWillingnessToTravel>>;
  total: Scalars['Int']['output'];
};

export type ErecruitingSourcingServiceXtmConversations = {
  __typename?: 'ErecruitingSourcingServiceXTMConversations';
  /** #rest #virtual */
  responseRate?: Maybe<ErecruitingSourcingServiceXtmConversationsResponseRate>;
  /** #rest #virtual */
  started?: Maybe<ErecruitingSourcingServiceXtmConversationsStarted>;
};

export type ErecruitingSourcingServiceXtmConversationsResponseRate = {
  __typename?: 'ErecruitingSourcingServiceXTMConversationsResponseRate';
  total?: Maybe<Scalars['Int']['output']>;
};

export type ErecruitingSourcingServiceXtmConversationsStarted = {
  __typename?: 'ErecruitingSourcingServiceXTMConversationsStarted';
  histogram?: Maybe<Array<Maybe<ErecruitingSourcingServiceXtmHistogramResult>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ErecruitingSourcingServiceXtmHistogramResult = {
  __typename?: 'ErecruitingSourcingServiceXTMHistogramResult';
  timestamp?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ErecruitingSourcingServiceXtmProject = {
  __typename?: 'ErecruitingSourcingServiceXTMProject';
  candidatesPreview?: Maybe<ErecruitingSourcingServiceXtmProjectCandidates>;
  /** #rest(batched) #virtual */
  consultant?: Maybe<XingId>;
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

/** Types for XTM Projects (accessed specifically by XSS) */
export type ErecruitingSourcingServiceXtmProjectCandidate = {
  __typename?: 'ErecruitingSourcingServiceXTMProjectCandidate';
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  profile?: Maybe<XingId>;
};

export type ErecruitingSourcingServiceXtmProjectCandidates = {
  __typename?: 'ErecruitingSourcingServiceXTMProjectCandidates';
  collection: Array<Maybe<ErecruitingSourcingServiceXtmProjectCandidate>>;
  total: Scalars['Int']['output'];
};

export type ErecruitingSourcingServiceXtmProjectResponse = {
  __typename?: 'ErecruitingSourcingServiceXTMProjectResponse';
  /** #expand */
  project?: Maybe<ErecruitingSourcingServiceXtmProject>;
};

export type ErecruitingSourcingServiceXtmReportDetails = {
  __typename?: 'ErecruitingSourcingServiceXTMReportDetails';
  conversations?: Maybe<ErecruitingSourcingServiceXtmConversations>;
  /** #rest #virtual */
  statistics?: Maybe<ErecruitingSourcingServiceXtmReportDetailsStatistics>;
};

export type ErecruitingSourcingServiceXtmReportDetailsConversationsArgs = {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type ErecruitingSourcingServiceXtmReportDetailsStatisticsArgs = {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type ErecruitingSourcingServiceXtmReportDetailsStatistics = {
  __typename?: 'ErecruitingSourcingServiceXTMReportDetailsStatistics';
  candidates?: Maybe<ErecruitingSourcingServiceXtmReportDetailsStatisticsCandidates>;
  candidatesPerStatus?: Maybe<
    Array<Maybe<ErecruitingSourcingServiceXtmStatusResult>>
  >;
  reviews?: Maybe<ErecruitingSourcingServiceXtmReportDetailsStatisticsReviews>;
};

export type ErecruitingSourcingServiceXtmReportDetailsStatisticsCandidates = {
  __typename?: 'ErecruitingSourcingServiceXTMReportDetailsStatisticsCandidates';
  histogram?: Maybe<Array<Maybe<ErecruitingSourcingServiceXtmHistogramResult>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ErecruitingSourcingServiceXtmReportDetailsStatisticsReviews = {
  __typename?: 'ErecruitingSourcingServiceXTMReportDetailsStatisticsReviews';
  candidateCount?: Maybe<Scalars['Int']['output']>;
  histogram?: Maybe<Array<Maybe<ErecruitingSourcingServiceXtmHistogramResult>>>;
  pendingCount?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ErecruitingSourcingServiceXtmStatusResult = {
  __typename?: 'ErecruitingSourcingServiceXTMStatusResult';
  /** #rest(batched) #virtual */
  candidates?: Maybe<Array<Maybe<XingId>>>;
  count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ErrorResponse = {
  __typename?: 'ErrorResponse';
  message: Scalars['String']['output'];
};

export type Event = {
  __typename?: 'Event';
  /** #logged-out #soft-logged-in */
  agenda?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  agendaWithoutHtml?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  bookmarked?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  callToAction?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  callToActionLink?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  canBeShared?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  category?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  creator?: Maybe<XingId>;
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  endsAt?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  endsAtTimeGiven?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Events is being shutdown on the 31-12-2022. Do not remove calls before this day. */
  eventLatestMessage?: Maybe<EventUserMessage>;
  /** #logged-out #soft-logged-in */
  eventPlus: Scalars['Boolean']['output'];
  /** #soft-logged-in */
  externalLink?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  htmlDescription?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  language?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** #soft-logged-in */
  linkToExternalPage?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  linkToTicketingIframe?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  linkedEvent: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  links: EventLinks;
  /** #logged-out #soft-logged-in */
  locationCity?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  locationCountry?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  locationName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  locationPostalCode?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  locationRegion?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  locationStreet?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** #logged-out #soft-logged-in */
  maxParticipants?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  meetup?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  online?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Events is being shutdown on the 31-12-2022. Do not remove calls before this day.
   */
  organizer?: Maybe<EventOrganizer>;
  /** #logged-out #soft-logged-in */
  organizerType: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  participantsBatched?: Maybe<EventBatchedParticipants>;
  /** #logged-out #soft-logged-in */
  partnerType: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  registrationDeadlineAt?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  registrationDeadlineAtTimeGiven?: Maybe<Scalars['Boolean']['output']>;
  reportable?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   * @deprecated Events is being shutdown on the 31-12-2022. Do not remove calls before this day.
   */
  rsvp?: Maybe<EventRsvp>;
  /** #logged-out #soft-logged-in */
  seatsAvailable?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  showTicketPrices?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  slug: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  startsAt: Scalars['Date']['output'];
  /** #logged-out #soft-logged-in */
  startsAtTimeGiven: Scalars['Boolean']['output'];
  targetUrn?: Maybe<Scalars['GlobalID']['output']>;
  /** #logged-out #soft-logged-in */
  ticketCurrency?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  ticketPriceEnd?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  ticketPriceStart?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  ticketSoldAmount?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   * @deprecated Events is being shutdown on the 31-12-2022. Do not remove calls before this day.
   */
  tickets?: Maybe<EventTickets>;
  /** #logged-out #soft-logged-in */
  timeZone?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  title: Scalars['String']['output'];
  /** #soft-logged-in */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  urn: Scalars['GlobalID']['output'];
  /** #soft-logged-in */
  visibility?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  wideBannerUploaded: Scalars['Boolean']['output'];
};

export type EventParticipantsBatchedArgs = {
  input: EventParticipantsBatchedQueryInput;
};

export type EventAdNetworkParticipants = {
  __typename?: 'EventAdNetworkParticipants';
  /** #logged-out #soft-logged-in */
  collection: Array<Maybe<EventAdParticipant>>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type EventAdParticipant = {
  __typename?: 'EventAdParticipant';
  /** #logged-out #soft-logged-in */
  fullName: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  photoUrl: Scalars['URL']['output'];
};

export type EventBatchedParticipants = {
  __typename?: 'EventBatchedParticipants';
  /** #logged-out #soft-logged-in */
  collection: Array<EventParticipant>;
  /** #logged-out #soft-logged-in */
  total?: Maybe<Scalars['Int']['output']>;
};

export const enum EventContextFilter {
  MyEvents = 'MY_EVENTS',
  Organized = 'ORGANIZED',
  ProfileVisible = 'PROFILE_VISIBLE',
  SaidMaybe = 'SAID_MAYBE',
  SaidYes = 'SAID_YES',
}

export const enum EventDraftFilter {
  /** Returns both draft and active events */
  All = 'ALL',
  /** Returns only active events. This is default option on backend if this filter is omitted */
  ExcludeDraftEvents = 'EXCLUDE_DRAFT_EVENTS',
  /** Returns only draft events */
  OnlyDraftEvents = 'ONLY_DRAFT_EVENTS',
}

export type EventGroupContainer = {
  __typename?: 'EventGroupContainer';
  /** @deprecated Groups are going to be shutdown at the end of 2022. This field will stop working on 01/01/2023. */
  group?: Maybe<Community>;
  groupId: Scalars['ID']['output'];
};

/** Deprecated: Renamed to EventUserMessageReason */
export const enum EventInvitationReason {
  Group = 'GROUP',
  PastAttendees = 'PAST_ATTENDEES',
  Visitor = 'VISITOR',
}

export type EventInvitationSignal = NetworkUpdatesStackItem & {
  __typename?: 'EventInvitationSignal';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  object?: Maybe<EventUserInvitation>;
  stackId: Scalars['ID']['output'];
  target?: Maybe<Event>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type EventInvitationStack = SignalsStack & {
  __typename?: 'EventInvitationStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type EventInvitationStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type EventLinks = {
  __typename?: 'EventLinks';
  /** #logged-out #soft-logged-in */
  image: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  imageSquare48: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  imageSquare96: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  imageSquare128: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  imageSquare192: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  public: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  wideBanner: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  wideBannerSmall: Scalars['URL']['output'];
};

export type EventLocation = {
  __typename?: 'EventLocation';
  /** #logged-out #soft-logged-in */
  city?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  country?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  online: Scalars['Boolean']['output'];
};

export type EventMyEventsQueryInput = {
  contextFilter?: InputMaybe<EventContextFilter>;
  draftFilter?: InputMaybe<EventDraftFilter>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  ticketingFilter?: InputMaybe<EventTicketingFilter>;
  timeFilter?: InputMaybe<EventTimeFilter>;
};

export const enum EventNoRsvpReason {
  DeadlinePassed = 'DEADLINE_PASSED',
  PastEvent = 'PAST_EVENT',
  SoldOut = 'SOLD_OUT',
}

export type EventOrganizer =
  | Community
  | Company
  | EventOrganizerName
  | GroupsGroup
  | XingId;

export type EventOrganizerName = {
  __typename?: 'EventOrganizerName';
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
};

/**
 * Query for the participants of an event.
 * The value of participations determines if you query for users who said yes or maybe or both.
 * The contactsCount field returns the info how many of your contacts are joining the event.
 * Please note that you can only query for contactsCount if you set participations to YES only.
 */
export type EventParticipant = {
  __typename?: 'EventParticipant';
  /** #soft-logged-in #rest(batched) #virtual */
  contactDistance?: Maybe<ContactDistance>;
  /** #soft-logged-in #rest(batched) #virtual */
  networkRelationship?: Maybe<NetworkRelationship>;
  new?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  participation?: Maybe<EventParticipationKind>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  user?: Maybe<XingId>;
};

export type EventParticipants = {
  __typename?: 'EventParticipants';
  /** #logged-out #soft-logged-in */
  collection: Array<EventParticipant>;
  /** #soft-logged-in */
  contactsCount?: Maybe<Scalars['Int']['output']>;
  /** only returned for organizer */
  participantsCount?: Maybe<ParticipantsCount>;
  /** #logged-out #soft-logged-in */
  total?: Maybe<Scalars['Int']['output']>;
};

export type EventParticipantsBatchedQueryInput = {
  participation: EventParticipationKind;
};

export const enum EventParticipantsOrder {
  RegistrationDate = 'REGISTRATION_DATE',
}

export type EventParticipantsQueryInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  order?: InputMaybe<EventParticipantsOrder>;
  participations?: InputMaybe<Array<EventParticipationKind>>;
};

export const enum EventParticipationKind {
  Maybe = 'MAYBE',
  No = 'NO',
  Unanswered = 'UNANSWERED',
  Yes = 'YES',
}

export type EventRecommendation = Recommendation & {
  __typename?: 'EventRecommendation';
  event?: Maybe<Event>;
  itemId: Scalars['ID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type EventRsvp = {
  __typename?: 'EventRsvp';
  /** #soft-logged-in */
  noRsvpReason?: Maybe<EventNoRsvpReason>;
  /** #soft-logged-in */
  options?: Maybe<EventRsvpOptions>;
};

export const enum EventRsvpOptionState {
  Available = 'AVAILABLE',
  Selected = 'SELECTED',
  Unavailable = 'UNAVAILABLE',
}

export type EventRsvpOptions = {
  __typename?: 'EventRsvpOptions';
  /** #soft-logged-in */
  buyTicket?: Maybe<EventRsvpOptionState>;
  /** #soft-logged-in */
  maybe?: Maybe<EventRsvpOptionState>;
  /** #soft-logged-in */
  no?: Maybe<EventRsvpOptionState>;
  /** #soft-logged-in */
  yes?: Maybe<EventRsvpOptionState>;
};

/** Settings related to events */
export type EventSettings = {
  __typename?: 'EventSettings';
  /** Get contact invitation */
  contactInvitation?: Maybe<ToggleSetting>;
  /** Get event changed mail */
  eventChangedMail?: Maybe<ToggleSetting>;
  /** Get insights mail */
  insightsMail?: Maybe<ToggleSetting>;
  /** Get new comment mail */
  newCommentMail?: Maybe<ToggleSetting>;
  /** Get new visitors of my event mail */
  newVomesMail?: Maybe<ToggleSetting>;
  /** Get mail about next steps */
  nextStepsMail?: Maybe<ToggleSetting>;
  /** Get past attendees invitation */
  pastAttendeesInvitation?: Maybe<ToggleSetting>;
  /** Get people to meet mail */
  peopleToMeetMail?: Maybe<ToggleSetting>;
  /** Get RSVP change mail */
  rsvpChangeMail?: Maybe<ToggleSetting>;
  /** Get visitors of my event invitation */
  vomeInvitation?: Maybe<ToggleSetting>;
};

export type EventTicket = {
  __typename?: 'EventTicket';
  /** #soft-logged-in */
  displayId?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  firstName?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  lastName?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  qrCodeUrl?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  ticketCategory?: Maybe<Scalars['String']['output']>;
};

export const enum EventTicketingFilter {
  /** Returns both ticketing and non-ticketing events. This is default option on backend if this filter is omitted */
  All = 'ALL',
  /** Returns only non-ticketing events */
  ExcludeTicketingEvents = 'EXCLUDE_TICKETING_EVENTS',
  /** Returns only ticketing events */
  OnlyTicketingEvents = 'ONLY_TICKETING_EVENTS',
}

export type EventTickets = {
  __typename?: 'EventTickets';
  /** #expand #soft-logged-in */
  tickets: Array<EventTicket>;
};

export const enum EventTimeFilter {
  Past = 'PAST',
  Upcoming = 'UPCOMING',
}

export type EventUserEvent = {
  __typename?: 'EventUserEvent';
  event?: Maybe<Event>;
  eventId: Scalars['ID']['output'];
};

export type EventUserEvents = {
  __typename?: 'EventUserEvents';
  collection: Array<EventUserEvent>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type EventUserInvitation = {
  __typename?: 'EventUserInvitation';
  collection: Array<Invitation>;
};

export type EventUserInvitations = {
  __typename?: 'EventUserInvitations';
  collection: Array<Invitation>;
  total: Scalars['Int']['output'];
  unreadCount: Scalars['Int']['output'];
};

export type EventUserInvitationsQueryInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  timeFilter: EventTimeFilter;
};

export type EventUserMessage = {
  blocked?: Maybe<Scalars['Boolean']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  event?: Maybe<Event>;
  eventId: Scalars['ID']['output'];
  group?: Maybe<EventGroupContainer>;
  id: Scalars['ID']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<EventUserMessageReason>;
  /** #rest(batched) #virtual */
  sender?: Maybe<XingId>;
  senderId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  source?: Maybe<Source>;
  subject?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export type EventUserMessageCancellation = EventUserMessage & {
  __typename?: 'EventUserMessageCancellation';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  event?: Maybe<Event>;
  eventId: Scalars['ID']['output'];
  group?: Maybe<EventGroupContainer>;
  id: Scalars['ID']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<EventUserMessageReason>;
  /** #rest(batched) #virtual */
  sender?: Maybe<XingId>;
  senderId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  source?: Maybe<Source>;
  subject?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export type EventUserMessageDisinvite = EventUserMessage & {
  __typename?: 'EventUserMessageDisinvite';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  event?: Maybe<Event>;
  eventId: Scalars['ID']['output'];
  group?: Maybe<EventGroupContainer>;
  id: Scalars['ID']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<EventUserMessageReason>;
  /** #rest(batched) #virtual */
  sender?: Maybe<XingId>;
  senderId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  source?: Maybe<Source>;
  subject?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export type EventUserMessageGuestlist = EventUserMessage & {
  __typename?: 'EventUserMessageGuestlist';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  event?: Maybe<Event>;
  eventId: Scalars['ID']['output'];
  group?: Maybe<EventGroupContainer>;
  id: Scalars['ID']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<EventUserMessageReason>;
  /** #rest(batched) #virtual */
  sender?: Maybe<XingId>;
  senderId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  source?: Maybe<Source>;
  subject?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export type EventUserMessageInvitation = EventUserMessage & {
  __typename?: 'EventUserMessageInvitation';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  event?: Maybe<Event>;
  eventId: Scalars['ID']['output'];
  group?: Maybe<EventGroupContainer>;
  id: Scalars['ID']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<EventUserMessageReason>;
  /** #rest(batched) #virtual */
  sender?: Maybe<XingId>;
  senderId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  source?: Maybe<Source>;
  subject?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export type EventUserMessageModification = EventUserMessage & {
  __typename?: 'EventUserMessageModification';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  event?: Maybe<Event>;
  eventId: Scalars['ID']['output'];
  group?: Maybe<EventGroupContainer>;
  id: Scalars['ID']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<EventUserMessageReason>;
  /** #rest(batched) #virtual */
  sender?: Maybe<XingId>;
  senderId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  source?: Maybe<Source>;
  subject?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export const enum EventUserMessageReason {
  Group = 'GROUP',
  PastAttendees = 'PAST_ATTENDEES',
  Visitor = 'VISITOR',
}

export type EventUserMessageRescheduling = EventUserMessage & {
  __typename?: 'EventUserMessageRescheduling';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  event?: Maybe<Event>;
  eventId: Scalars['ID']['output'];
  group?: Maybe<EventGroupContainer>;
  id: Scalars['ID']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<EventUserMessageReason>;
  /** #rest(batched) #virtual */
  sender?: Maybe<XingId>;
  senderId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  source?: Maybe<Source>;
  subject?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export type Events = {
  __typename?: 'Events';
  /** #logged-out #soft-logged-in */
  collection: Array<EventsItem>;
  /** #logged-out #soft-logged-in */
  total?: Maybe<Scalars['Int']['output']>;
};

export type EventsGroupsGroupCounters = {
  __typename?: 'EventsGroupsGroupCounters';
  /** #logged-out #soft-logged-in */
  pastEvents: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  upcomingEvents: Scalars['Int']['output'];
};

export type EventsItem = {
  __typename?: 'EventsItem';
  /** #logged-out #soft-logged-in */
  event?: Maybe<Event>;
};

export type ExposeMatch = {
  __typename?: 'ExposeMatch';
  label: Scalars['String']['output'];
  match: ExposeMatchType;
  type: Scalars['String']['output'];
};

export const enum ExposeMatchType {
  No = 'NO',
  Uprise = 'UPRISE',
  Yes = 'YES',
}

export type ExternalUrlDescription = {
  __typename?: 'ExternalUrlDescription';
  /** #logged-out #soft-logged-in */
  htmlContent: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  proxyUrl: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  url: Scalars['URL']['output'];
};

/** VOMP External Type */
export type ExternalVomp = VompVisit & {
  __typename?: 'ExternalVomp';
  type: Scalars['String']['output'];
  viewedAt: Scalars['Date']['output'];
  visitorId: Scalars['ID']['output'];
};

export type ExternalVompError = {
  __typename?: 'ExternalVompError';
  /** Contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ExternalVompInput = {
  visit: ExternalVompVisit;
  visitee: ExternalVompVisitee;
  visitor: ExternalVompVisitor;
};

/** Result type for external VOMP creation that returns 204 on success */
export type ExternalVompResult = {
  __typename?: 'ExternalVompResult';
  error?: Maybe<ExternalVompError>;
  success?: Maybe<ExternalVompSuccess>;
};

export type ExternalVompSuccess = {
  __typename?: 'ExternalVompSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ExternalVompVisit = {
  platform: VompPlatformEnum;
  referer: Scalars['String']['input'];
};

export type ExternalVompVisitee = {
  pageName: Scalars['String']['input'];
};

export type ExternalVompVisitor = {
  ipAddress: Scalars['String']['input'];
};

export type ExtraData = {
  __typename?: 'ExtraData';
  subtitle?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /**
   * #soft-logged-in
   *
   *  This ExtraData is the result data as we get from the datasource (ElasticSearch for incremental results) without request entity info though others X1 APIS.
   * Ideally it's better to get entity data from REST APIS (for consistency) but as it's much slower you can use directly this. All entity types are treated like a unified result type with title, subtitle and thumbnail. For any other information you need extend the result to call entities X1 APIS
   */
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['URL']['output']>;
};

export type FabiBadRequest = {
  __typename?: 'FabiBadRequest';
  /** #logged-out #soft-logged-in */
  message: Scalars['String']['output'];
};

export type FabiGeneratedQuery = {
  __typename?: 'FabiGeneratedQuery';
  /** #logged-out #soft-logged-in */
  query: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  trackingToken: Scalars['String']['output'];
};

export const enum FabiJobSearchContentType {
  Markdown = 'MARKDOWN',
}

export type FabiJobSearchInput = {
  consumer: Scalars['String']['input'];
  queryText: Scalars['String']['input'];
  supportedContentTypes: Array<FabiJobSearchContentType>;
  supportedResponseStyles: Array<FabiResponseStyle>;
};

export type FabiJobSearchMessage = {
  __typename?: 'FabiJobSearchMessage';
  /** #logged-out #soft-logged-in */
  content: FabiJobSearchMessageContent;
  /** #logged-out #soft-logged-in */
  includeFeedback: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  providerId: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  trackingToken: Scalars['String']['output'];
};

export type FabiJobSearchMessageContent = FabiMarkdown;

export type FabiJobSearchQueryGenerationInput = {
  consumer: Scalars['String']['input'];
  useCase: FabiJobSearchQueryGenerationUseCase;
  userQuery?: InputMaybe<Scalars['String']['input']>;
};

export type FabiJobSearchQueryGenerationResponse =
  | FabiBadRequest
  | FabiJobSearchQueryGenerationResult;

export type FabiJobSearchQueryGenerationResult = {
  __typename?: 'FabiJobSearchQueryGenerationResult';
  /** #logged-out #soft-logged-in */
  enoughProfileData: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  queries: Array<Maybe<FabiGeneratedQuery>>;
  /** #logged-out #soft-logged-in */
  requestTrackingToken: Scalars['String']['output'];
};

export const enum FabiJobSearchQueryGenerationUseCase {
  SearchWithProfile = 'SEARCH_WITH_PROFILE',
}

export type FabiJobSearchResponse = FabiBadRequest | FabiJobSearchResult;

export type FabiJobSearchResult = {
  __typename?: 'FabiJobSearchResult';
  /** #logged-out #soft-logged-in */
  messages: Array<FabiJobSearchMessage>;
  /** #logged-out #soft-logged-in */
  queryText: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  requestTrackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  responseStyle: FabiResponseStyle;
};

export type FabiMarkdown = {
  __typename?: 'FabiMarkdown';
  /** #logged-out #soft-logged-in */
  markdown: Scalars['String']['output'];
};

export const enum FabiResponseStyle {
  Loud = 'LOUD',
  Quiet = 'QUIET',
}

export type FallbackAboutUsFacts = {
  __typename?: 'FallbackAboutUsFacts';
  /** #logged-out #soft-logged-in */
  message: Scalars['String']['output'];
};

export type FallbackAddress = {
  __typename?: 'FallbackAddress';
  city?: Maybe<Scalars['String']['output']>;
};

export type FallbackCompany = {
  __typename?: 'FallbackCompany';
  address?: Maybe<FallbackAddress>;
  companyName?: Maybe<Scalars['String']['output']>;
};

/** Platform features for the requesting user */
export type Feature = {
  __typename?: 'Feature';
  /**
   * #soft-logged-in
   *
   *  Flag to show if the viewer has coach profile
   */
  hasCoachProfile?: Maybe<Scalars['Boolean']['output']>;
  /** Flag to show whether the viewer has the 2018 settings already */
  hasNewSettings?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer has ProJobs membership
   */
  hasProJobsMembership?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer is an admaster
   */
  isAdmasterUser?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer is a basic user
   */
  isBasic?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag to show if the viewer is a Benefits Plus user
   */
  isBenefitsPlus?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Indicates that the user can access the Xing BrandManager (XBM)
   */
  isBrandManagerEditor?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer is a brand page collaborator
   */
  isBrandPageCollaborator?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag to use campus normal position
   */
  isCampusNormalPosition?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag to use campus prominent position
   */
  isCampusProminentPosition?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer has xtm user rights. Use this feature to identify an XTM user membership
   */
  isCraUser?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer is an executive
   */
  isExecutive?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer is a jobs poster
   */
  isJobsPoster?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer is a premium user
   */
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag to show if the viewer is a Pro Freelancer user
   */
  isProFreelancer?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer is a sales user
   */
  isSales?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer is a seat manager admin
   */
  isSeatManagerAdmin?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer is a Xing employee
   */
  isXingEmployee?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag shows if the viewer has xtp user rights. Use this feature to identify an XTP user membership
   */
  isXtpUser?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag to show JobSeeker boneyard upsell hint
   */
  showJobSeekerBoneyardUpsellHint?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag to show upsell hint for premium members
   */
  showPremiumBoneyardUpsellHint?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag to show ProBusiness navigation
   */
  showProbusinessInNavigation?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Flag to show the upsell hint for paying premium and branded startpage premium users
   */
  showUpsellHint?: Maybe<Scalars['Boolean']['output']>;
};

export type FeatureSwitchesExperiment = {
  __typename?: 'FeatureSwitchesExperiment';
  /** #logged-out #soft-logged-in */
  name: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  variant: Scalars['String']['output'];
};

export type FeatureSwitchesExperimentsCollection = {
  __typename?: 'FeatureSwitchesExperimentsCollection';
  /** #logged-out #soft-logged-in */
  collection: Array<FeatureSwitchesExperiment>;
};

export type FeatureSwitchesExperimentsForceVariantErrorResult = {
  __typename?: 'FeatureSwitchesExperimentsForceVariantErrorResult';
  message?: Maybe<Scalars['String']['output']>;
};

export type FeatureSwitchesExperimentsForceVariantInput = {
  name: Scalars['String']['input'];
  variant: Scalars['String']['input'];
};

export type FeatureSwitchesExperimentsForceVariantResult = {
  __typename?: 'FeatureSwitchesExperimentsForceVariantResult';
  error?: Maybe<FeatureSwitchesExperimentsForceVariantErrorResult>;
  success?: Maybe<FeatureSwitchesExperimentsForceVariantSuccessResult>;
};

export type FeatureSwitchesExperimentsForceVariantSuccessResult = {
  __typename?: 'FeatureSwitchesExperimentsForceVariantSuccessResult';
  experiment: FeatureSwitchesExperiment;
};

export type FeatureSwitchesNewNotificationCenterMutationError = {
  __typename?: 'FeatureSwitchesNewNotificationCenterMutationError';
  error?: Maybe<NewNotificationCenterMutationError>;
};

export type FeatureSwitchesNewNotificationCenterMutationResult =
  | FeatureSwitchesNewNotificationCenterMutationError
  | FeatureSwitchesNewNotificationCenterMutationSuccess;

export type FeatureSwitchesNewNotificationCenterMutationSuccess = {
  __typename?: 'FeatureSwitchesNewNotificationCenterMutationSuccess';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type FeatureSwitchesVerticalNavigationMutationError = {
  __typename?: 'FeatureSwitchesVerticalNavigationMutationError';
  error?: Maybe<VerticalNavigationMutationError>;
};

export type FeatureSwitchesVerticalNavigationMutationResult =
  | FeatureSwitchesVerticalNavigationMutationError
  | FeatureSwitchesVerticalNavigationMutationSuccess;

export type FeatureSwitchesVerticalNavigationMutationSuccess = {
  __typename?: 'FeatureSwitchesVerticalNavigationMutationSuccess';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type FeedyPreviewMetadata = PreviewMetadata & {
  __typename?: 'FeedyPreviewMetadata';
  objectUrn: Scalars['GlobalID']['output'];
  sourceActor?: Maybe<LinkPreviewSourceActor>;
};

/** Fenced representation of a user at XING */
export type FencedProfileVisitor = {
  __typename?: 'FencedProfileVisitor';
  /**
   * #rest(batched) #virtual
   *
   *  Request Click Reason data by its ID.
   */
  clickReasonProfileUrl?: Maybe<ClickReason>;
  /**
   * #rest(batched) #virtual
   *
   *  Request Click Reason data by its ID from a specific Entity.
   * SourceEntityId is basically the ID of the entity where the profile URL was seen and clicked.
   * For instance, if you see a profile link in a group, the sourceEntityId will be the group ID.
   */
  clickReasonProfileUrlFromEntity?: Maybe<ClickReason>;
  profileImage?: Maybe<Array<Maybe<ProfileImage>>>;
  /** #rest(batched) #virtual */
  profileOccupation?: Maybe<FencedProfileVisitorOccupation>;
  /** #rest #virtual */
  userFlags?: Maybe<UserFlags>;
};

/** Fenced representation of a user at XING */
export type FencedProfileVisitorClickReasonProfileUrlArgs = {
  clickReasonId: ClickReasonsEnum;
};

/** Fenced representation of a user at XING */
export type FencedProfileVisitorClickReasonProfileUrlFromEntityArgs = {
  clickReasonId: ClickReasonsEnum;
  sourceEntityId: Scalars['SlugOrID']['input'];
};

/** Fenced representation of a user at XING */
export type FencedProfileVisitorProfileImageArgs = {
  size: Array<ProfileImageSize>;
};

/** Fenced representation of the user occupation */
export type FencedProfileVisitorOccupation = {
  __typename?: 'FencedProfileVisitorOccupation';
  occupationTitle?: Maybe<Scalars['String']['output']>;
};

/** Fenced Qualified VOMP's visitor */
export type FencedQualifiedVompVisitor = {
  __typename?: 'FencedQualifiedVompVisitor';
  /** #logged-out #soft-logged-in */
  profileImage?: Maybe<Array<Maybe<ProfileImage>>>;
};

/** Fenced Qualified VOMP's visitor */
export type FencedQualifiedVompVisitorProfileImageArgs = {
  size: Array<ProfileImageSize>;
};

/** VOMP Fenced Type */
export type FencedVomp = VompVisit & {
  __typename?: 'FencedVomp';
  /** #rest(batched) #virtual */
  actor?: Maybe<FencedProfileVisitor>;
  type: Scalars['String']['output'];
  viewedAt: Scalars['Date']['output'];
  visitorId: Scalars['ID']['output'];
};

export type FetchComplaintsReportResult = {
  __typename?: 'FetchComplaintsReportResult';
  /** #logged-out #soft-logged-in */
  report?: Maybe<ComplaintsReport>;
};

export type FollowContext = {
  __typename?: 'FollowContext';
  hasFollowedInSession?: Maybe<Scalars['Boolean']['output']>;
  isFollowing: Scalars['Boolean']['output'];
};

export type FollowedCompanies = {
  __typename?: 'FollowedCompanies';
  /** #rest(batched) #virtual */
  company?: Maybe<Company>;
};

export type FollowedCompaniesConnection = HasCursorPagination & {
  __typename?: 'FollowedCompaniesConnection';
  edges?: Maybe<Array<Maybe<FollowedCompaniesEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type FollowedCompaniesEdge = PaginationEdge & {
  __typename?: 'FollowedCompaniesEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<FollowedCompanies>;
};

/** Specifies the field to sort by */
export const enum FollowedCompaniesSortField {
  CreatedAt = 'created_at',
  FollowersCount = 'followers_count',
  Name = 'name',
}

/** Specifies the sort direction (asc/desc) */
export const enum FollowedCompaniesSortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

/** Denotes a follower of an entity */
export type Follower = {
  __typename?: 'Follower';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  profile?: Maybe<XingId>;
  /** #logged-out #soft-logged-in */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  userId?: Maybe<Scalars['ID']['output']>;
};

export type FollowersConnection = HasCursorPagination & {
  __typename?: 'FollowersConnection';
  /** #logged-out #soft-logged-in */
  edges: Array<Maybe<FollowersEdge>>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type FollowersEdge = PaginationEdge & {
  __typename?: 'FollowersEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node?: Maybe<Follower>;
};

export type FollowersFollowError = {
  __typename?: 'FollowersFollowError';
  message?: Maybe<Scalars['String']['output']>;
};

export type FollowersFollowingCollection = {
  __typename?: 'FollowersFollowingCollection';
  collection?: Maybe<Array<Maybe<Follower>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export const enum FollowersFollowingNamespace {
  Companies = 'COMPANIES',
  Disco = 'DISCO',
  Entitypages = 'ENTITYPAGES',
}

export const enum FollowersFollowingOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export const enum FollowersFollowingSort {
  UpdatedAt = 'UPDATED_AT',
  UserId = 'USER_ID',
}

export const enum FollowersFollowingType {
  Company = 'COMPANY',
  Page = 'PAGE',
  Topic = 'TOPIC',
}

export type FollowersMutationInput = {
  followedUrn: Scalars['GlobalID']['input'];
};

export type FollowersMutationResult = {
  __typename?: 'FollowersMutationResult';
  error?: Maybe<FollowersFollowError>;
};

export type Form = {
  __typename?: 'Form';
  /** #logged-out #soft-logged-in */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  type?: Maybe<Scalars['String']['output']>;
};

export type FormExternalDocuments = {
  __typename?: 'FormExternalDocuments';
  /** #logged-out #soft-logged-in */
  externalDocuments: Array<Maybe<JobApplicationFormExternalDoc>>;
};

export type FormSettings = {
  __typename?: 'FormSettings';
  /** #logged-out #soft-logged-in */
  disableAdditionalComments: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  maxAdditionalDocuments: Scalars['Int']['output'];
};

export type FullTrackingInput = {
  consumer: Scalars['String']['input'];
  interactionType: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  receivedTimestamp?: InputMaybe<Scalars['String']['input']>;
  service: Scalars['String']['input'];
  timestamp?: InputMaybe<Scalars['String']['input']>;
  trackingToken: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** This type represents a GPS coordinate pair that enforces both members to be present */
export type GpsCoordinates = {
  __typename?: 'GPSCoordinates';
  /** #logged-out #soft-logged-in */
  latitude: Scalars['Float']['output'];
  /** #logged-out #soft-logged-in */
  longitude: Scalars['Float']['output'];
};

/** Available genders. The `NONE` value indicates the user has not specified their gender. */
export const enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  None = 'NONE',
  Other = 'OTHER',
}

export type GenericLocalizable = Localizable & {
  __typename?: 'GenericLocalizable';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export const enum GetWillingnessToTravelEnum {
  Frequent = 'FREQUENT',
  None = 'NONE',
  Occasional = 'OCCASIONAL',
}

export type GroupAggregations = {
  __typename?: 'GroupAggregations';
  category?: Maybe<Array<Maybe<Aggregation>>>;
  memberCount?: Maybe<Array<Maybe<Aggregation>>>;
  mode?: Maybe<Array<Maybe<Aggregation>>>;
};

export type GroupAggregationsInput = {
  /**
   * category: Maximum number of distinct values to be returned by this aggregation
   * memberCount: Maximum number of distinct values to be returned by this aggregation
   * mode: Maximum number of distinct values to be returned by this aggregation.
   */
  category?: InputMaybe<Scalars['String']['input']>;
  memberCount?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
};

/** Deprecated: Renamed to EventGroupContainer */
export type GroupContainer = {
  __typename?: 'GroupContainer';
  /** @deprecated Groups are going to be shutdown at the end of 2022. This field will stop working on 01/01/2023. */
  group?: Maybe<Community>;
  groupId: Scalars['ID']['output'];
};

export type GroupFiltersInput = {
  /**
   * Available filters for group search
   * category: Filter results which match ANY of the given values(comma separated).
   *           Example of categories used:
   *             'offizielle_xing_regional_ambassador_gruppen', 'offizielle_xing_xpert_ambassador_gruppen',
   *             'offizielle_xing_themen_gruppen'
   * approved: true or false. True by default
   * deleted: true or false. False by default
   * memberCount: Defines range(comma separated) for member_count for filtering results.
   *              Supported formats are:
   *                123/456: values larger than 123 but smaller than 456
   *                /456: values smaller than 456
   *                123/: values larger than 123
   *                Date ranges are also supported, using the format yyyy-MM-ddThh:mm:ssZ
   *                Example values: '0/200,201/500,501/1000,1001/5000,5001'
   * xing_internal: Filter results which match ANY of the given values(comma separated)
   * mode: Local if the group is a meetup, Online if it is a normal group
   */
  approved?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['String']['input']>;
  memberCount?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  xingInternal?: InputMaybe<Scalars['String']['input']>;
};

export const enum GroupMode {
  Local = 'LOCAL',
  Online = 'ONLINE',
}

export type GroupSearchConnection = HasCursorPagination & {
  __typename?: 'GroupSearchConnection';
  aggs?: Maybe<GroupAggregations>;
  edges: Array<GroupSearchEdge>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type GroupSearchEdge = PaginationEdge & {
  __typename?: 'GroupSearchEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node?: Maybe<UniversalSearchGroupResult>;
};

/** Settings of the user which apply to a single individual group */
export type GroupSetting = {
  __typename?: 'GroupSetting';
  /** User wants to be receive event invitations */
  eventInvitations: Scalars['Boolean']['output'];
  groupId: Scalars['ID']['output'];
  groupName: Scalars['String']['output'];
  /** Group has classifieds */
  hasMarketplace: Scalars['Boolean']['output'];
  /** User wants to receive the notifications about moderator news */
  moderatorNews: Scalars['Boolean']['output'];
  /** User wants to receive the notifications about new classifieds */
  newClassifieds?: Maybe<Scalars['Boolean']['output']>;
  /** User wants to receive the notifications about new posts */
  newPosts: Scalars['Boolean']['output'];
  /** Shows membership of the group */
  visible: Scalars['Boolean']['output'];
};

export type GroupsAboutUsFacts = {
  __typename?: 'GroupsAboutUsFacts';
  /** #logged-out #soft-logged-in */
  groupData?: Maybe<GroupsAboutUsFactsData>;
  /** #logged-out #soft-logged-in */
  imprint?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  properties?: Maybe<AboutUsFactsProperties>;
};

export type GroupsAboutUsFactsData = {
  __typename?: 'GroupsAboutUsFactsData';
  /**
   * #logged-out #soft-logged-in
   * @deprecated Groups are going to be shutdown at the end of 2022. This field will stop working on 01/10/2022.
   */
  group?: Maybe<GroupsGroup>;
};

export type GroupsAllowedActionsOnGroup = {
  __typename?: 'GroupsAllowedActionsOnGroup';
  canCreatePost?: Maybe<Scalars['Boolean']['output']>;
  canJoin?: Maybe<Scalars['Boolean']['output']>;
  canLeave?: Maybe<Scalars['Boolean']['output']>;
  canModerate?: Maybe<Scalars['Boolean']['output']>;
};

export type GroupsAllowedActionsOnMembership = {
  __typename?: 'GroupsAllowedActionsOnMembership';
  canApprove?: Maybe<Scalars['Boolean']['output']>;
  canBlock?: Maybe<Scalars['Boolean']['output']>;
  canExclude?: Maybe<Scalars['Boolean']['output']>;
  canSetModerator?: Maybe<Scalars['Boolean']['output']>;
  canUnblock?: Maybe<Scalars['Boolean']['output']>;
  canUnsetModerator?: Maybe<Scalars['Boolean']['output']>;
};

export type GroupsCategoriesConnection = HasCursorPagination & {
  __typename?: 'GroupsCategoriesConnection';
  /** #logged-out #soft-logged-in */
  edges: Array<Maybe<GroupsCategoryEdge>>;
  /** #virtual #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type GroupsCategory = {
  __typename?: 'GroupsCategory';
  /** #logged-out #soft-logged-in */
  createdAt: Scalars['Date']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in #virtual */
  ontologyEntity?: Maybe<OntologyEntityLabel>;
  /** #logged-out #soft-logged-in */
  ontologyEntityId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  ontologyEntityUrn: Scalars['URN']['output'];
  /** #logged-out #soft-logged-in */
  updatedAt: Scalars['Date']['output'];
};

export type GroupsCategoryEdge = PaginationEdge & {
  __typename?: 'GroupsCategoryEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node: GroupsCategory;
};

export type GroupsGroup = {
  __typename?: 'GroupsGroup';
  /** #logged-out #soft-logged-in #rest #virtual */
  aboutUs?: Maybe<AboutEntityResult>;
  /** @deprecated Groups are going to be shutdown at the end of 2022. This field only returns static `false` values. */
  allowedActions?: Maybe<GroupsAllowedActionsOnGroup>;
  /** #paginated #logged-out #soft-logged-in */
  categories?: Maybe<GroupsCategoriesConnection>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  city?: Maybe<City>;
  /** #logged-out #soft-logged-in */
  cityId?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  createdAt: Scalars['Date']['output'];
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  entityPage?: Maybe<EntityPage>;
  /** #logged-out #soft-logged-in */
  entityPageId?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  eventFrequency?: Maybe<GroupsGroupEventFrequency>;
  /** #logged-out #soft-logged-in */
  events?: Maybe<Events>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Events is being shutdown on the 31-12-2022. Do not remove calls before this day
   */
  eventsCounter?: Maybe<EventsGroupsGroupCounters>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  membersCount?: Maybe<Scalars['Int']['output']>;
  /** #paginated #logged-out #soft-logged-in */
  memberships?: Maybe<GroupsMembershipsConnection>;
  /** #logged-out #soft-logged-in */
  state: GroupsGroupState;
  /** #logged-out #soft-logged-in */
  updatedAt: Scalars['Date']['output'];
  /** #logged-out #soft-logged-in */
  visibility: GroupsGroupVisibility;
};

export type GroupsGroupCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ontologyEntityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ontologyEntityUrns?: InputMaybe<Array<InputMaybe<Scalars['URN']['input']>>>;
};

export type GroupsGroupEventsArgs = {
  input: GroupsGroupEventsQueryInput;
};

export type GroupsGroupMembershipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  roles?: InputMaybe<Array<InputMaybe<GroupsMembershipRole>>>;
  states?: InputMaybe<Array<InputMaybe<GroupsMembershipState>>>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export const enum GroupsGroupEventFrequency {
  FourOrFiveTimes = 'FOUR_OR_FIVE_TIMES',
  Once = 'ONCE',
  SixOrMoreTimes = 'SIX_OR_MORE_TIMES',
  TwoOrThreeTimes = 'TWO_OR_THREE_TIMES',
}

export const enum GroupsGroupEventsOrderBy {
  Id = 'ID',
  StartsAt = 'STARTS_AT',
}

export type GroupsGroupEventsQueryInput = {
  descending?: InputMaybe<Scalars['Int']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<GroupsGroupEventsOrderBy>;
  timeFilter?: InputMaybe<EventTimeFilter>;
};

export const enum GroupsGroupState {
  Approved = 'APPROVED',
  Incomplete = 'INCOMPLETE',
  PendingApproval = 'PENDING_APPROVAL',
}

export const enum GroupsGroupVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type GroupsMembership = {
  __typename?: 'GroupsMembership';
  /** @deprecated Groups are going to be shutdown at the end of 2022. This field only returns static `false` values. */
  allowedActions?: Maybe<GroupsAllowedActionsOnMembership>;
  /** #rest(batched) #virtual */
  contactDistance?: Maybe<ContactDistance>;
  /** #logged-out #soft-logged-in */
  createdAt: Scalars['Date']['output'];
  /** #logged-out #soft-logged-in */
  group?: Maybe<GroupsGroup>;
  /** #logged-out #soft-logged-in */
  groupId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  membershipReason?: Maybe<GroupsMembershipReason>;
  /** #rest(batched) #virtual */
  networkRelationship?: Maybe<NetworkRelationship>;
  /** #logged-out #soft-logged-in */
  role: GroupsMembershipRole;
  /** #rest(batched) #virtual */
  sharedContacts?: Maybe<SharedContacts>;
  /** #logged-out #soft-logged-in */
  state: GroupsMembershipState;
  /** #logged-out #soft-logged-in */
  updatedAt: Scalars['Date']['output'];
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  user?: Maybe<XingId>;
  /** #logged-out #soft-logged-in */
  userId: Scalars['ID']['output'];
};

export type GroupsMembershipEdge = PaginationEdge & {
  __typename?: 'GroupsMembershipEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node: GroupsMembership;
};

export type GroupsMembershipReason = {
  __typename?: 'GroupsMembershipReason';
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
};

export const enum GroupsMembershipRole {
  Member = 'MEMBER',
  Moderator = 'MODERATOR',
  Owner = 'OWNER',
}

export const enum GroupsMembershipState {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  Excluded = 'EXCLUDED',
  Pending = 'PENDING',
}

export type GroupsMembershipsConnection = HasCursorPagination & {
  __typename?: 'GroupsMembershipsConnection';
  /** #logged-out #soft-logged-in */
  edges: Array<Maybe<GroupsMembershipEdge>>;
  /** #virtual #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

/** Settings related to groups */
export type GroupsSettings = {
  __typename?: 'GroupsSettings';
  /** Container for the individual group settings */
  collection?: Maybe<Array<Maybe<GroupSetting>>>;
  /** Shows membership by default when joining a new group */
  defaultVisibility?: Maybe<ToggleSetting>;
  /** Allows other users to invite to join a group */
  wantInvitations?: Maybe<ToggleSetting>;
};

export type HasAggregationCount = {
  count: Scalars['Int']['output'];
};

/**
 * Defines common fields for cursor pagination. Cursor pagination types must implement this interface
 * and define an `edges` field.
 * Check [Schema Design Principles (Pagination)](https://one.nwse.io/docs/how-to/develop/design-a-schema#pagination)
 * for more info.
 */
export type HasCursorPagination = {
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

/**
 * Provides a single non-null field called `globalId` that
 * collapses type information and identity into a single scalar value.
 *
 * `GlobalID`s can be used in different scenarios:
 *
 * - For one thing they enable collecting objects of different types in a Hash-like structure
 * (like a client side cache for example).
 * - They can also be used as identifiers for `GraphQL` field arguments if type information
 * needs to be transported to the backend in addition to the normal `ID`
 *
 * `New Work One` provides two interface: `HasDerivedGlobalId` and `HasGlobalId`. The
 * difference between the two is the following:
 *
 * - `HasGlobalId` simply provides the field definition, but requests it as a normal field
 * from the internal API.
 * - `HasDerivedGlobalId` provides the field definition and an implementation which
 * requests the `id` from the internal API and combines it with the name of the parent GraphQL type.
 *
 * Independent of the interface, a `GlobalID` is expected to be an URN. If it's served by
 * the backend, the URN is expected to follow the conventions outlined by
 * the [URN guidelines](https://new-work.atlassian.net/wiki/spaces/SOLAR/pages/45912687/Internal+URNs).
 * In both cases cases the naming portions of the URN are expected to be in "snake_case".
 *
 * In the mobile channel `New Work One` will automatically take care of scrambling the
 * numeric portions of the URN. In that case the `urn:` prefix is also changed to `surn:` to
 * match the expectations of existing clients.
 */
export type HasDerivedGlobalId = {
  /** Globally unique id for this instance based on typename and id */
  globalId: Scalars['GlobalID']['output'];
};

/**
 * Provides a single non-null field called `globalId` that
 * collapses type information and identity into a single scalar value.
 *
 * `GlobalID`s can be used in different scenarios:
 *
 * - For one thing they enable collecting objects of different types in a Hash-like structure
 * (like a client side cache for example).
 * - They can also be used as identifiers for `GraphQL` field arguments if type information
 * needs to be transported to the backend in addition to the normal `ID`
 *
 * `New Work One` provides two interface: `HasDerivedGlobalId` and `HasGlobalId`. The
 * difference between the two is the following:
 *
 * - `HasGlobalId` simply provides the field definition, but requests it as a normal field
 * from the internal API.
 * - `HasDerivedGlobalId` provides the field definition and an implementation which
 * requests the `id` from the internal API and combines it with the name of the parent GraphQL type.
 *
 * Independent of the interface, a `GlobalID` is expected to be an URN. If it's served by
 * the backend, the URN is expected to follow the conventions outlined by
 * the [URN guidelines](https://new-work.atlassian.net/wiki/spaces/SOLAR/pages/45912687/Internal+URNs).
 * In both cases the naming portions of the URN are expected to be in "snake_case".
 *
 * In the mobile channel `New Work One` will automatically take care of scrambling the
 * numeric portions of the URN. In that case the `urn:` prefix is also changed to `surn:` to
 * match the expectations of existing clients.
 */
export type HasGlobalId = {
  /** Globally unique id for this instance */
  globalId: Scalars['GlobalID']['output'];
};

export type HasNwtTrackingToken = {
  /** @deprecated Replaced by HasNewWorkTrackingToken to not confuse with another field with same name */
  trackingToken?: Maybe<Scalars['String']['output']>;
};

/**
 * To be used for adding New work tracking field at result level
 * See https://source.xing.com/pages/data-solutions/nw-tracking/ for more information
 */
export type HasNewWorkTrackingToken = {
  nwTrackingToken?: Maybe<Scalars['String']['output']>;
};

/**
 * To be used for adding Operational Data tracking field at result level
 * See https://operational-tracking.xing.io/ for implementation details
 */
export type HasOperationalDataTrackingTokens = {
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type HasSeenResponse = {
  __typename?: 'HasSeenResponse';
  seen?: Maybe<Scalars['Boolean']['output']>;
};

export const enum HasSeenSource {
  Xtm = 'XTM',
}

export type HasTrackingToken = {
  /**
   * #soft-logged-in
   *
   *  Tracking token generated by predictive search service to track responses. Used for DS tracking
   */
  trackingToken: Scalars['String']['output'];
};

/** Header image for XING ID */
export type HeaderImage = Referenceable &
  UniformResourceLocatable & {
    __typename?: 'HeaderImage';
    /** #logged-out #soft-logged-in */
    reference: Scalars['String']['output'];
    /** #logged-out #soft-logged-in */
    url: Scalars['URL']['output'];
  };

/** Highlight parameters */
export type HighlightInput = {
  /** Enable or disable highlighting */
  enable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specifies how the highlighted entries should be encoded(possible values are default and *html) */
  encoder?: InputMaybe<Scalars['String']['input']>;
  /** Specifies how the highlighted entries should be sorted(currently only score is supported) */
  order?: InputMaybe<Scalars['String']['input']>;
  /** Defines right bounday element of a highlight snippet */
  postTag?: InputMaybe<Scalars['String']['input']>;
  /** Defines left boundary element of a highlight snippet */
  preTag?: InputMaybe<Scalars['String']['input']>;
};

export type HighlightResult = {
  __typename?: 'HighlightResult';
  /** #soft-logged-in */
  awards?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  birthName?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  city?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  country?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  currentCompany?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  currentCompanyNotes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  currentIndustries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  currentNotes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  currentTitle?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  education?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  haves?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  interests?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  languages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  organizations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  portfolioTilesPlainContent?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  portfolioTilesTitle?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  previousCompany?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  previousCompanyNotes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  previousIndustries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  previousTitle?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  province?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  qualifications?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  wants?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  webProfiles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  zipCode?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type HighlightResultCompany = {
  __typename?: 'HighlightResultCompany';
  description?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type HighlightResultMessengerMessage = {
  __typename?: 'HighlightResultMessengerMessage';
  body?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type HiringCities = {
  __typename?: 'HiringCities';
  /** #logged-out #soft-logged-in */
  collection?: Maybe<Array<Maybe<HiringCity>>>;
};

export type HiringCity = {
  __typename?: 'HiringCity';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  city?: Maybe<City>;
};

export type HiringCompanies = {
  __typename?: 'HiringCompanies';
  /** #logged-out #soft-logged-in */
  collection?: Maybe<Array<Maybe<HiringCompany>>>;
};

export type HiringCompany = {
  __typename?: 'HiringCompany';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  company?: Maybe<Company>;
};

export type HiringDiscipline = {
  __typename?: 'HiringDiscipline';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  discipline?: Maybe<Discipline>;
  /** #logged-out #soft-logged-in */
  isTop?: Maybe<Scalars['Boolean']['output']>;
};

export type HiringDisciplines = {
  __typename?: 'HiringDisciplines';
  /** #logged-out #soft-logged-in */
  collection?: Maybe<Array<Maybe<HiringDiscipline>>>;
};

export type HiringEntityInput = {
  entityId?: InputMaybe<Scalars['ID']['input']>;
};

export type HiringHighlightsModule = ModuleInterface & {
  __typename?: 'HiringHighlightsModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** #rest #virtual */
  content?: Maybe<HiringProfile>;
  /** #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  title: Scalars['String']['output'];
};

export type HiringJobPosting = {
  __typename?: 'HiringJobPosting';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  job?: Maybe<JobResult>;
};

export type HiringJobRole = {
  __typename?: 'HiringJobRole';
  /** #logged-out #soft-logged-in #virtual */
  jobRole?: Maybe<OntologyEntityLabel>;
  /** #logged-out #soft-logged-in */
  label?: Maybe<Scalars['String']['output']>;
};

export type HiringJobRolesInput = {
  entityId?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type HiringProfile = {
  __typename?: 'HiringProfile';
  /** #logged-out #soft-logged-in */
  hiring?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  hiringCities?: Maybe<Array<Maybe<HiringCity>>>;
  /** #logged-out #soft-logged-in */
  hiringCompanies?: Maybe<Array<Maybe<HiringCompany>>>;
  /** #logged-out #soft-logged-in */
  hiringDisciplines?: Maybe<Array<Maybe<HiringDiscipline>>>;
  /** #logged-out #soft-logged-in */
  hiringJobPostings?: Maybe<Array<Maybe<HiringJobPosting>>>;
  /** #logged-out #soft-logged-in */
  hiringJobRoles?: Maybe<Array<Maybe<HiringJobRole>>>;
  /** #logged-out #soft-logged-in */
  hiringQuestionTimestamp?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  hiringSkills?: Maybe<Array<Maybe<HiringSkill>>>;
  /** #logged-out #soft-logged-in */
  hiringSpotlightSummary?: Maybe<HiringSpotlightSummary>;
  showHiringQuestion?: Maybe<Scalars['Boolean']['output']>;
};

export type HiringSkill = {
  __typename?: 'HiringSkill';
  /** #logged-out #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

export type HiringSkillsInput = {
  value: Scalars['String']['input'];
};

export type HiringSpotLightSummaryInput = {
  value: Scalars['String']['input'];
};

export type HiringSpotlightSummary = {
  __typename?: 'HiringSpotlightSummary';
  value?: Maybe<Scalars['String']['output']>;
};

export type HtmlDescription = {
  __typename?: 'HtmlDescription';
  /** #logged-out #soft-logged-in */
  content?: Maybe<Scalars['String']['output']>;
};

export type IdentifyCityQueryInput = {
  /** name of the city to be identified if available */
  cityName?: InputMaybe<Scalars['String']['input']>;
  /** country code (recommended) or country name */
  country?: InputMaybe<Scalars['String']['input']>;
  /** if set to true then administrative areas are also allowed in response */
  includeAdminArea?: InputMaybe<Scalars['Boolean']['input']>;
  /** if set to true then the alternate names of the results will be retrieved */
  includeAlternateNames?: InputMaybe<Scalars['Boolean']['input']>;
  /** Two-letter lowercase format (ISO 639-1 Code) */
  language: Scalars['String']['input'];
  /** if set, limits the number of results returned */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** if set to true then the alternate names of the country and admin area are retrieved */
  otherAlternateNames?: InputMaybe<Scalars['Boolean']['input']>;
  /** postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** google maps unstructured query */
  query?: InputMaybe<Scalars['String']['input']>;
  /** administrative area 1, in case of structured query */
  region?: InputMaybe<Scalars['String']['input']>;
  /** relax Google query (= remove fields from query) empty Google results in order to resolve a city ID at the risk of not resolving a ROOFTOP address */
  relax?: InputMaybe<Scalars['Boolean']['input']>;
  /** if set to true then only local ES index will be used for resolution. Otherwise it will also use GoogleAPIs (which costs money for each call). Your consumer needs to be added to a whitelist. */
  resolveLocal?: InputMaybe<Scalars['Boolean']['input']>;
  /** street name (it will need google api to geolocate) */
  streetName?: InputMaybe<Scalars['String']['input']>;
};

export type ImageOptions = {
  height: Scalars['Int']['input'];
  reference: Scalars['String']['input'];
  width: Scalars['Int']['input'];
};

/** Industries Type */
export type IndustriesPreference = {
  __typename?: 'IndustriesPreference';
  /** #rest(batched) #virtual */
  industries: Array<Maybe<Industry>>;
  openToOtherIndustries?: Maybe<Scalars['Boolean']['output']>;
};

/** Industry localized */
export type Industry = Localizable & {
  __typename?: 'Industry';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  segments: Array<IndustrySegment>;
  /** #logged-out #soft-logged-in */
  seoName?: Maybe<Scalars['String']['output']>;
};

export type IndustryAggregation = HasAggregationCount & {
  __typename?: 'IndustryAggregation';
  /** #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #soft-logged-in #rest(batched) #virtual */
  label?: Maybe<Industry>;
  /** #soft-logged-in */
  value?: Maybe<Scalars['Int']['output']>;
};

export type IndustryInsight = {
  __typename?: 'IndustryInsight';
  id?: Maybe<Scalars['ID']['output']>;
  translationKey?: Maybe<Scalars['String']['output']>;
};

/** Industry Segment localized */
export type IndustrySegment = Localizable & {
  __typename?: 'IndustrySegment';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  seoName?: Maybe<Scalars['String']['output']>;
};

export type Insights = {
  __typename?: 'Insights';
  /**
   * #rest #virtual
   *
   *  Insights about current companies matching the filters
   *
   * ```
   * query CurrentCompaniesInsight {
   *   recruiterInsights {
   *     insights(projectId: "5669", locationId: "2643123", jobLabel: "Manager") {
   *       currentCompaniesInsight {
   *         ...
   *       }
   *     }
   *   }
   * }
   * ```
   */
  currentCompaniesInsight?: Maybe<CurrentCompaniesInsight>;
  /**
   * #rest #virtual
   *
   *  Jobseeker status insights
   *
   * ```
   * query JobSeekerStatusInsight {
   *   recruiterInsights {
   *     insights(projectId: "5669", locationId: "2643123", jobLabel: "Manager") {
   *       jobSeekerStatusInsight {
   *         ...
   *       }
   *     }
   *   }
   * }
   * ```
   */
  jobSeekerStatusInsight?: Maybe<JobSeekerStatusInsight>;
  /**
   * #rest #virtual
   *
   *  Salary expectation data
   *
   * ```
   * query SalaryExpectations {
   *   recruiterInsights {
   *     insights(projectId: "5669", locationId: "2643123", jobLabel: "Manager") {
   *       salaryExpectationInsight {
   *         medianSalaryExpectation
   *         medianSalaryExpectationCandidates
   *         xtmUrl
   *         stats {
   *           amount
   *           label
   *         }
   *       }
   *     }
   *   }
   * }
   * ```
   */
  salaryExpectationInsight?: Maybe<SalaryExpectationInsight>;
  /**
   * #rest #virtual
   *
   *  Time in current position insights
   *
   * ```
   * query TimeInCurrentPosition {
   *   recruiterInsights {
   *     insights(projectId: "5669", locationId: "2643123", jobLabel: "Manager") {
   *       timeInCurrentPositionInsight {
   *         ...
   *       }
   *     }
   *   }
   * }
   * ```
   */
  timeInCurrentPositionInsight?: Maybe<TimeInCurrentPositionInsight>;
};

export type InsightsCurrentCompaniesInsightArgs = {
  destinations?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InsightsArticle = {
  __typename?: 'InsightsArticle';
  /** #logged-out #soft-logged-in */
  bgColor?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  category?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  details?: Maybe<InsightsArticleDetails>;
  /** #logged-out #soft-logged-in */
  objectUrn?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  readingTime?: Maybe<Scalars['Int']['output']>;
};

export type InsightsArticleDetails = {
  __typename?: 'InsightsArticleDetails';
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  image?: Maybe<InsightsArticleImage>;
  /** #logged-out #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type InsightsArticleImage = {
  __typename?: 'InsightsArticleImage';
  /** #logged-out #soft-logged-in */
  srcSquare?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcSquare2x?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcWide?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  srcWide2x?: Maybe<Scalars['URL']['output']>;
};

export type InsightsArticlesCollection = {
  __typename?: 'InsightsArticlesCollection';
  /** #logged-out #soft-logged-in */
  articles?: Maybe<Array<Maybe<InsightsArticle>>>;
};

export type InsightsNavigationItem = {
  __typename?: 'InsightsNavigationItem';
  headline: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  navigationType: InsightsNavigationType;
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  subline: Scalars['String']['output'];
  url?: Maybe<Scalars['URL']['output']>;
  urn: Scalars['URN']['output'];
};

export type InsightsNavigationModule = DiscoModule &
  HasOperationalDataTrackingTokens & {
    __typename?: 'InsightsNavigationModule';
    id?: Maybe<Scalars['String']['output']>;
    info?: Maybe<DiscoModuleInfo>;
    opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** #paginated */
    paginatedItems?: Maybe<DiscoItemsConnection>;
    renderingType: DiscoModuleRenderingType;
    urn: Scalars['GlobalID']['output'];
    x1Limit: Scalars['Int']['output'];
  };

export type InsightsNavigationModulePaginatedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<InputMaybe<DiscoFeature>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** List of supported navigationType. */
export const enum InsightsNavigationType {
  CareerHub = 'CAREER_HUB',
  Companies = 'COMPANIES',
  Industries = 'INDUSTRIES',
  Insiders = 'INSIDERS',
  JobAndCareer = 'JOB_AND_CAREER',
}

/** VOMP Internal Type */
export type InternalVomp = VompVisit & {
  __typename?: 'InternalVomp';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  type: Scalars['String']['output'];
  viewedAt: Scalars['Date']['output'];
  visitorId: Scalars['ID']['output'];
};

export type InternalVompError = {
  __typename?: 'InternalVompError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type InternalVompInput = {
  visit: InternalVompVisit;
  visitee: InternalVompVisitee;
  visitor?: InputMaybe<InternalVompVisitor>;
};

/** "Result type for internal VOMP creation that returns 204 on success" */
export type InternalVompResult = {
  __typename?: 'InternalVompResult';
  error?: Maybe<InternalVompError>;
  success?: Maybe<InternalVompSuccess>;
};

export type InternalVompSuccess = {
  __typename?: 'InternalVompSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type InternalVompVisit = {
  clickReason: VompClickReason;
  platform: VompPlatformEnum;
  referer?: InputMaybe<Scalars['String']['input']>;
};

export type InternalVompVisitee = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pageName?: InputMaybe<Scalars['String']['input']>;
};

export type InternalVompVisitor = {
  ipAddress?: InputMaybe<Scalars['String']['input']>;
};

/** Deprecated: Renamed to EventUserMessage */
export type Invitation = {
  __typename?: 'Invitation';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  event?: Maybe<Event>;
  eventId: Scalars['ID']['output'];
  group?: Maybe<GroupContainer>;
  id: Scalars['ID']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<EventInvitationReason>;
  /** #rest(batched) #virtual */
  sender?: Maybe<XingId>;
  senderId: Scalars['ID']['output'];
  /**
   * #rest(batched) #virtual
   * @deprecated Events is being shutdown on the 31-12-2022. Do not remove calls before this day
   */
  source?: Maybe<Source>;
  subject?: Maybe<Scalars['String']['output']>;
  urn: Scalars['GlobalID']['output'];
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export type InvoiceData = BpayPaymentMethodData & {
  __typename?: 'InvoiceData';
  clientName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type IsThisJobForMeScore = {
  __typename?: 'IsThisJobForMeScore';
  estimatedRating: Scalars['Int']['output'];
  rating?: Maybe<Scalars['Int']['output']>;
  scoreRaw: Scalars['Float']['output'];
  scoreRounded: Scalars['Float']['output'];
};

export type Item = {
  __typename?: 'Item';
  /** #rest(batched) #virtual */
  chat?: Maybe<MessengerChat>;
};

/** Generic type that is used to resolve the aggregations from search-proxy */
export type JobAggregationBenefit = {
  __typename?: 'JobAggregationBenefit';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  benefit?: Maybe<KununuBenefit>;
  /** #logged-out #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
};

export type JobAggregationCareerLevels = {
  __typename?: 'JobAggregationCareerLevels';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  careerLevel?: Maybe<CareerLevel>;
  /** #logged-out #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
};

export type JobAggregationCity = {
  __typename?: 'JobAggregationCity';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  city?: Maybe<City>;
  /** #logged-out #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
};

export type JobAggregationCountry = {
  __typename?: 'JobAggregationCountry';
  /** #logged-out #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  country?: Maybe<Country>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
};

export type JobAggregationDiscipline = {
  __typename?: 'JobAggregationDiscipline';
  /** #logged-out #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  discipline?: Maybe<Discipline>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
};

export type JobAggregationEmploymentType = {
  __typename?: 'JobAggregationEmploymentType';
  /** #logged-out #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  employmentType?: Maybe<EmploymentType>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
};

export type JobAggregationIndustry = {
  __typename?: 'JobAggregationIndustry';
  /** #logged-out #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  industry?: Maybe<Industry>;
};

export type JobAggregationRemoteOption = {
  __typename?: 'JobAggregationRemoteOption';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in #rest #virtual */
  remoteOption?: Maybe<JobRemoteOption>;
};

/** Type that groups all the different aggregations that can be returned by search-proxy */
export type JobAggregationResult = {
  __typename?: 'JobAggregationResult';
  /** #logged-out #soft-logged-in */
  benefits?: Maybe<Array<Maybe<JobAggregationBenefit>>>;
  /** #logged-out #soft-logged-in */
  benefitsEmployeePerk?: Maybe<Array<Maybe<JobAggregationBenefit>>>;
  /** #logged-out #soft-logged-in */
  benefitsWorkingCulture?: Maybe<Array<Maybe<JobAggregationBenefit>>>;
  /** #logged-out #soft-logged-in */
  careerLevels?: Maybe<Array<Maybe<JobAggregationCareerLevels>>>;
  /** #logged-out #soft-logged-in */
  cities?: Maybe<Array<Maybe<JobAggregationCity>>>;
  /** #logged-out #soft-logged-in */
  countries?: Maybe<Array<Maybe<JobAggregationCountry>>>;
  /** #logged-out #soft-logged-in */
  disciplines?: Maybe<Array<Maybe<JobAggregationDiscipline>>>;
  /** #logged-out #soft-logged-in */
  employmentTypes?: Maybe<Array<Maybe<JobAggregationEmploymentType>>>;
  /** #logged-out #soft-logged-in */
  industries?: Maybe<Array<Maybe<JobAggregationIndustry>>>;
  /** #logged-out #soft-logged-in */
  remoteOptions?: Maybe<Array<Maybe<JobAggregationRemoteOption>>>;
  /** #logged-out #soft-logged-in */
  roles?: Maybe<Array<Maybe<JobAggregationRole>>>;
};

export type JobAggregationRole = {
  __typename?: 'JobAggregationRole';
  /** #logged-out #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in #virtual */
  role?: Maybe<JobRoleLabel>;
};

export type JobApplication =
  | EasyApplication
  | EmailApplication
  | JobXingApplication
  | MessageApplication
  | UrlApplication;

export type JobApplicationCvDataInput = {
  education: JobApplicationExperienceInput;
  sectorExperience: Scalars['String']['input'];
  workExperience?: InputMaybe<JobApplicationExperienceInput>;
};

export type JobApplicationCvLessInput = {
  competencies?: InputMaybe<
    Array<InputMaybe<JobApplicationCvLessQuestionnaireInput>>
  >;
  skills?: InputMaybe<
    Array<InputMaybe<JobApplicationCvLessQuestionnaireInput>>
  >;
};

export type JobApplicationCvLessQuestionnaireInput = {
  checked: Scalars['Boolean']['input'];
  item: Scalars['String']['input'];
};

export type JobApplicationError = {
  __typename?: 'JobApplicationError';
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type JobApplicationExperienceInput = {
  center: Scalars['String']['input'];
  from: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  to?: InputMaybe<Scalars['String']['input']>;
};

export type JobApplicationForm = {
  __typename?: 'JobApplicationForm';
  /**
   * #logged-out #soft-logged-in
   * @deprecated Field no longer supported
   */
  externalDocuments: Array<Maybe<JobApplicationFormExternalDoc>>;
  /** @deprecated Field no longer supported */
  sections?: Maybe<Array<Maybe<JobApplicationFormSection>>>;
};

export type JobApplicationFormExternalDoc = {
  __typename?: 'JobApplicationFormExternalDoc';
  /** #logged-out #soft-logged-in */
  type: JobApplicationFormExternalDocType;
  /** #logged-out #soft-logged-in */
  url: Scalars['String']['output'];
};

export const enum JobApplicationFormExternalDocType {
  PrivacyPolicy = 'PRIVACY_POLICY',
  TermsAndConditions = 'TERMS_AND_CONDITIONS',
}

export type JobApplicationFormField = {
  __typename?: 'JobApplicationFormField';
  content?: Maybe<JobApplicationFormFieldContent>;
  id: Scalars['ID']['output'];
  label?: Maybe<JobApplicationFormTranslationField>;
  name: Scalars['String']['output'];
  placeholder?: Maybe<JobApplicationFormTranslationField>;
  type: JobApplicationFormFieldType;
};

export type JobApplicationFormFieldContent = {
  __typename?: 'JobApplicationFormFieldContent';
  fileTypes?: Maybe<Array<Scalars['String']['output']>>;
  maxFileSize?: Maybe<Scalars['Int']['output']>;
  maxFiles?: Maybe<Scalars['Int']['output']>;
  maxLength?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
};

export type JobApplicationFormFieldInput = {
  label?: InputMaybe<JobApplicationFormFieldLabel>;
  value: Array<Scalars['String']['input']>;
};

export const enum JobApplicationFormFieldLabel {
  Attachments = 'ATTACHMENTS',
  Comments = 'COMMENTS',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Phone = 'PHONE',
  UserCv = 'USER_CV',
}

export const enum JobApplicationFormFieldType {
  Email = 'EMAIL',
  Files = 'FILES',
  Phone = 'PHONE',
  Text = 'TEXT',
}

export type JobApplicationFormSection = {
  __typename?: 'JobApplicationFormSection';
  fields: Array<Maybe<JobApplicationFormField>>;
  label: JobApplicationFormTranslationField;
};

export type JobApplicationFormTranslationField = Localizable & {
  __typename?: 'JobApplicationFormTranslationField';
  localizationKey: Scalars['String']['output'];
  localizationValue: Scalars['String']['output'];
};

export type JobApplicationMutationInput = {
  cvData?: InputMaybe<JobApplicationCvDataInput>;
  formData: Array<InputMaybe<JobApplicationFormFieldInput>>;
  jobId: Scalars['ID']['input'];
  platform: JobInteractionPlatform;
  questionnaire?: InputMaybe<JobApplicationCvLessInput>;
};

export type JobApplicationMutationResult = {
  __typename?: 'JobApplicationMutationResult';
  /** #logged-out #soft-logged-in */
  error?: Maybe<JobApplicationError>;
};

export type JobAppliedExtractedFilter = {
  __typename?: 'JobAppliedExtractedFilter';
  /** #logged-out #soft-logged-in */
  disabled?: Maybe<Array<Maybe<JobExtractedFilterDisabledCollection>>>;
  /** #logged-out #soft-logged-in */
  enabled?: Maybe<Array<Maybe<JobExtractedFilterEnabledCollection>>>;
};

export type JobAppliedFilterResult = {
  __typename?: 'JobAppliedFilterResult';
  /** #logged-out #soft-logged-in */
  extractedFilters?: Maybe<JobAppliedExtractedFilter>;
  /** #logged-out #soft-logged-in */
  filterCollection?: Maybe<Array<Maybe<JobFilterCollection>>>;
  /** #logged-out #soft-logged-in */
  location?: Maybe<JobSearchQueryLocation>;
};

export type JobBanner = {
  __typename?: 'JobBanner';
  /** #logged-out #soft-logged-in */
  image936x200?: Maybe<Scalars['URL']['output']>;
};

export type JobBooking = {
  __typename?: 'JobBooking';
  availability: Scalars['Boolean']['output'];
  editBookingUrl?: Maybe<Scalars['URL']['output']>;
};

export type JobBookingResult = {
  __typename?: 'JobBookingResult';
  bookingUrl?: Maybe<Scalars['URL']['output']>;
};

export type JobBookingmutationInput = {
  jobId: Scalars['ID']['input'];
};

export type JobBookmark = {
  __typename?: 'JobBookmark';
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  job?: Maybe<JobResult>;
  /** #logged-out #soft-logged-in */
  state: JobBookmarkState;
  /** #logged-out #soft-logged-in */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type JobBookmarkEdge = PaginationEdge & {
  __typename?: 'JobBookmarkEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node: JobBookmark;
};

export type JobBookmarkMutationInput = {
  jobId: Scalars['ID']['input'];
  source: JobInteractionPlatform;
};

export type JobBookmarkMutationResult = {
  __typename?: 'JobBookmarkMutationResult';
  /** #logged-out #soft-logged-in */
  error?: Maybe<JobDefaultError>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  job?: Maybe<JobResult>;
};

export const enum JobBookmarkState {
  Applied = 'APPLIED',
  InterviewSet = 'INTERVIEW_SET',
  NotBookmarked = 'NOT_BOOKMARKED',
  Saved = 'SAVED',
}

export type JobBookmarkUpsertMutationInput = {
  jobId: Scalars['ID']['input'];
  source: JobInteractionPlatform;
  state: JobBookmarkState;
};

export type JobBookmarkUpsertMutationResult = JobBookmark | JobDefaultError;

export type JobBookmarksConnection = HasCursorPagination & {
  __typename?: 'JobBookmarksConnection';
  /** #logged-out #soft-logged-in */
  edges?: Maybe<Array<Maybe<JobBookmarkEdge>>>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type JobCompanyInfo = {
  __typename?: 'JobCompanyInfo';
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   *
   *  Company is resolved by companies team with their info
   */
  company?: Maybe<Company>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Contains the company name stored in jobs DB and overrides the name of company, if it exists
   */
  companyNameOverride: Scalars['String']['output'];
};

export type JobContactRole = Localizable & {
  __typename?: 'JobContactRole';
  /** #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export type JobCreateReportInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['ID']['input'];
  reason: JobReportReason;
  wrongFields?: InputMaybe<Array<InputMaybe<JobReportableField>>>;
};

export type JobCreateReportResult = {
  __typename?: 'JobCreateReportResult';
  error?: Maybe<JobDefaultError>;
  /** #rest(batched) #virtual */
  job?: Maybe<JobResult>;
};

export type JobDefaultError = {
  __typename?: 'JobDefaultError';
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type JobDeleteVisitError = {
  __typename?: 'JobDeleteVisitError';
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type JobDeleteVisitInput = {
  jobVisitId: Scalars['ID']['input'];
};

export type JobDescription =
  | ExternalUrlDescription
  | HtmlDescription
  | PdfDescription
  | TemplateData;

export type JobDetailsConnection = HasCursorPagination & {
  __typename?: 'JobDetailsConnection';
  /** #soft-logged-in */
  edges: Array<JobDetailsEdge>;
  /** #soft-logged-in */
  pageInfo: PageInfo;
  /** #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type JobDetailsContact = JobDetailsContactInterface & {
  __typename?: 'JobDetailsContact';
  /** #soft-logged-in */
  position: Scalars['Int']['output'];
  /** #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #soft-logged-in #rest(batched) #virtual */
  user?: Maybe<XingId>;
};

export type JobDetailsContactInterface = {
  /** #soft-logged-in */
  position: Scalars['Int']['output'];
  /** #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #soft-logged-in #rest(batched) #virtual */
  user?: Maybe<XingId>;
};

export type JobDetailsEdge = PaginationEdge & {
  __typename?: 'JobDetailsEdge';
  /** #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #soft-logged-in */
  node?: Maybe<JobDetailsContact>;
};

export type JobDetailsFutureColleagueOccupation = {
  __typename?: 'JobDetailsFutureColleagueOccupation';
  /** #soft-logged-in */
  companyInfo: JobCompanyInfo;
  /** #soft-logged-in */
  role?: Maybe<Scalars['String']['output']>;
};

export type JobDetailsFutureColleaguesConnection = HasCursorPagination & {
  __typename?: 'JobDetailsFutureColleaguesConnection';
  /** #soft-logged-in */
  edges: Array<JobDetailsFutureColleaguesEdge>;
  /** #soft-logged-in */
  pageInfo: PageInfo;
  /** #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type JobDetailsFutureColleaguesContact = JobDetailsContactInterface & {
  __typename?: 'JobDetailsFutureColleaguesContact';
  /** #soft-logged-in */
  currentOccupation: JobDetailsFutureColleagueOccupation;
  /** #soft-logged-in */
  position: Scalars['Int']['output'];
  /** #soft-logged-in */
  previousOccupation?: Maybe<JobDetailsFutureColleagueOccupation>;
  /** #soft-logged-in */
  school?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #soft-logged-in #rest(batched) #virtual */
  user?: Maybe<XingId>;
};

export type JobDetailsFutureColleaguesEdge = PaginationEdge & {
  __typename?: 'JobDetailsFutureColleaguesEdge';
  /** #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #soft-logged-in */
  node?: Maybe<JobDetailsFutureColleaguesContact>;
};

export type JobDetailsSecondDegreeConnection = HasCursorPagination & {
  __typename?: 'JobDetailsSecondDegreeConnection';
  /** #soft-logged-in */
  edges: Array<JobDetailsSecondDegreeEdge>;
  /** #soft-logged-in */
  pageInfo: PageInfo;
  /** #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type JobDetailsSecondDegreeContact = JobDetailsContactInterface & {
  __typename?: 'JobDetailsSecondDegreeContact';
  /** #soft-logged-in */
  connectedBy: Array<JobDetailsContact>;
  /** #soft-logged-in */
  position: Scalars['Int']['output'];
  /** #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #soft-logged-in #rest(batched) #virtual */
  user?: Maybe<XingId>;
};

export type JobDetailsSecondDegreeEdge = PaginationEdge & {
  __typename?: 'JobDetailsSecondDegreeEdge';
  /** #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #soft-logged-in */
  node?: Maybe<JobDetailsSecondDegreeContact>;
};

export type JobExtractedFilterDisabledCollection =
  | JobFilterCareerLevel
  | JobFilterDisabledSalary
  | JobFilterEmploymentType
  | JobFilterLocation
  | JobFilterRemoteOption;

export type JobExtractedFilterEnabledCollection =
  | JobFilterCareerLevel
  | JobFilterEmploymentType
  | JobFilterLocation
  | JobFilterRemoteOption
  | JobFilterSalary;

export type JobFeatures = {
  __typename?: 'JobFeatures';
  manageUrl?: Maybe<Scalars['URL']['output']>;
  /**
   * #soft-logged-in
   * @deprecated All jobs are now reportable and this field is hard-coded to `true`. Please stop using the field.
   */
  report: Scalars['Boolean']['output'];
  /** #soft-logged-in */
  showInterest: Scalars['Boolean']['output'];
  upsellUrl?: Maybe<Scalars['URL']['output']>;
};

export type JobFilterBenefit = {
  __typename?: 'JobFilterBenefit';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  benefit?: Maybe<KununuBenefit>;
  /** #logged-out #soft-logged-in */
  entityId: Scalars['ID']['output'];
};

export type JobFilterBenefitEmployeePerk = {
  __typename?: 'JobFilterBenefitEmployeePerk';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  benefitEmployeePerk?: Maybe<KununuBenefit>;
  /** #logged-out #soft-logged-in */
  entityId: Scalars['ID']['output'];
};

export type JobFilterBenefitWorkingCulture = {
  __typename?: 'JobFilterBenefitWorkingCulture';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  benefitWorkingCulture?: Maybe<KununuBenefit>;
  /** #logged-out #soft-logged-in */
  entityId: Scalars['ID']['output'];
};

export type JobFilterCareerLevel = {
  __typename?: 'JobFilterCareerLevel';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  careerLevel?: Maybe<CareerLevel>;
  /** #logged-out #soft-logged-in */
  entityId: Scalars['ID']['output'];
};

export type JobFilterCity = {
  __typename?: 'JobFilterCity';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  city?: Maybe<City>;
  /** #logged-out #soft-logged-in */
  entityId: Scalars['ID']['output'];
};

export type JobFilterCollection =
  | JobFilterBenefit
  | JobFilterBenefitEmployeePerk
  | JobFilterBenefitWorkingCulture
  | JobFilterCareerLevel
  | JobFilterCity
  | JobFilterCompany
  | JobFilterCountry
  | JobFilterDiscipline
  | JobFilterEmploymentType
  | JobFilterIndustry
  | JobFilterProjob
  | JobFilterPublishToCompany
  | JobFilterRemote
  | JobFilterRemoteOption
  | JobFilterSalary;

export type JobFilterCompany = {
  __typename?: 'JobFilterCompany';
  /** #logged-out #soft-logged-in */
  company?: Maybe<Company>;
  /** #logged-out #soft-logged-in */
  entityId: Scalars['ID']['output'];
};

export type JobFilterCountry = {
  __typename?: 'JobFilterCountry';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  country?: Maybe<Country>;
  /** #logged-out #soft-logged-in */
  entityId: Scalars['ID']['output'];
};

export type JobFilterDisabledSalary = {
  __typename?: 'JobFilterDisabledSalary';
  /** #logged-out #soft-logged-in */
  salary?: Maybe<Scalars['Boolean']['output']>;
};

export type JobFilterDiscipline = {
  __typename?: 'JobFilterDiscipline';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  discipline?: Maybe<Discipline>;
  /** #logged-out #soft-logged-in */
  entityId: Scalars['ID']['output'];
};

export type JobFilterEmploymentType = {
  __typename?: 'JobFilterEmploymentType';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  employmentType?: Maybe<EmploymentType>;
  /** #logged-out #soft-logged-in */
  entityId: Scalars['ID']['output'];
};

export type JobFilterIndustry = {
  __typename?: 'JobFilterIndustry';
  /** #logged-out #soft-logged-in */
  entityId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  industry?: Maybe<Industry>;
};

export type JobFilterLocation = {
  __typename?: 'JobFilterLocation';
  /** #logged-out #soft-logged-in */
  location?: Maybe<Location>;
};

export type JobFilterProjob = {
  __typename?: 'JobFilterProjob';
  /** #logged-out #soft-logged-in */
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type JobFilterPublishToCompany = {
  __typename?: 'JobFilterPublishToCompany';
  /** #logged-out #soft-logged-in */
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type JobFilterRemote = {
  __typename?: 'JobFilterRemote';
  /** #logged-out #soft-logged-in */
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type JobFilterRemoteOption = {
  __typename?: 'JobFilterRemoteOption';
  /** #logged-out #soft-logged-in */
  entityId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in #rest #virtual */
  remoteOption?: Maybe<JobRemoteOption>;
};

export type JobFilterSalary = {
  __typename?: 'JobFilterSalary';
  /** #logged-out #soft-logged-in */
  max?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  min?: Maybe<Scalars['Int']['output']>;
};

export type JobHighlight = {
  __typename?: 'JobHighlight';
  /** #logged-out #soft-logged-in */
  localization: JobHighlightLocalizable;
  /** #logged-out #soft-logged-in */
  type: Scalars['String']['output'];
};

export type JobHighlightLocalizable = Localizable & {
  __typename?: 'JobHighlightLocalizable';
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export type JobHiringContact = {
  __typename?: 'JobHiringContact';
  /** #soft-logged-in #rest(batched) #virtual */
  role?: Maybe<JobContactRole>;
  /** #soft-logged-in #rest(batched) #virtual */
  user?: Maybe<XingId>;
};

export const enum JobInteractionPlatform {
  Android = 'ANDROID',
  Ios = 'IOS',
  /** @deprecated Replaced by more specific values: ios/android. WILL BE REMOVED ... */
  Mobile = 'MOBILE',
  Web = 'WEB',
}

export type JobInterest = {
  __typename?: 'JobInterest';
  /**
   * #soft-logged-in
   * @deprecated Interest feature is deprecated
   */
  showedInterest: Scalars['Boolean']['output'];
};

/** Item that represents the response from search proxy */
export type JobItemResult = {
  __typename?: 'JobItemResult';
  /** #logged-out #soft-logged-in */
  descriptionHighlight?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  jobDetail?: Maybe<JobResult>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  matchingHighlights?: Maybe<JobMatchingHighlights>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  matchingHighlightsV2?: Maybe<JobMatchingHighlightsV2>;
  /** #logged-out #soft-logged-in */
  position: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  postingId: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  trackingToken: Scalars['String']['output'];
};

export type JobKeyfact = JobKeyfactEmploymentType | JobKeyfactSalary;

export type JobKeyfactEmploymentType = {
  __typename?: 'JobKeyfactEmploymentType';
  /** #logged-out #soft-logged-in */
  localization: EmploymentType;
  /** #logged-out #soft-logged-in */
  value: Scalars['String']['output'];
};

export type JobKeyfactSalary = {
  __typename?: 'JobKeyfactSalary';
  /** #logged-out #soft-logged-in */
  value: JobSalary;
};

export type JobLocation = {
  __typename?: 'JobLocation';
  /** #logged-out #soft-logged-in */
  city?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  cityId?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  country?: Maybe<Country>;
  /** #logged-out #soft-logged-in */
  region?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  street?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type JobMatchingHighlights = {
  __typename?: 'JobMatchingHighlights';
  /** #logged-out #soft-logged-in */
  highlight?: Maybe<JobHighlight>;
  /** #logged-out #soft-logged-in */
  matchingFacts: Array<JobKeyfact>;
  /** #logged-out #soft-logged-in */
  nonMatchingFacts: Array<JobKeyfact>;
  /** #logged-out #soft-logged-in */
  token: Scalars['String']['output'];
};

export const enum JobMatchingHighlightsContext {
  Postings = 'postings',
  Recommendations = 'recommendations',
  Search = 'search',
}

export type JobMatchingHighlightsJobHighlightV2 = {
  __typename?: 'JobMatchingHighlightsJobHighlightV2';
  /** #logged-out #soft-logged-in */
  localization: JobMatchingHighlightsLocalizableV2;
  /** #logged-out #soft-logged-in */
  localizationA11y?: Maybe<JobMatchingHighlightsLocalizableV2>;
  /** #logged-out #soft-logged-in */
  type: Scalars['String']['output'];
};

export type JobMatchingHighlightsJobKeyfactIntV2 =
  JobMatchingHighlightsJobKeyfactV2 & {
    __typename?: 'JobMatchingHighlightsJobKeyfactIntV2';
    /** #logged-out #soft-logged-in */
    localization: JobMatchingHighlightsLocalizableV2;
    /** #logged-out #soft-logged-in */
    localizationA11y?: Maybe<JobMatchingHighlightsLocalizableV2>;
    /** #logged-out #soft-logged-in */
    type: Scalars['String']['output'];
    /** #logged-out #soft-logged-in */
    value: Scalars['Int']['output'];
  };

export type JobMatchingHighlightsJobKeyfactSalaryV2 =
  JobMatchingHighlightsJobKeyfactV2 & {
    __typename?: 'JobMatchingHighlightsJobKeyfactSalaryV2';
    /** #logged-out #soft-logged-in */
    localization: JobMatchingHighlightsLocalizableV2;
    /** #logged-out #soft-logged-in */
    localizationA11y?: Maybe<JobMatchingHighlightsLocalizableV2>;
    /** #logged-out #soft-logged-in */
    type: Scalars['String']['output'];
    /** #logged-out #soft-logged-in */
    value: JobSalary;
  };

export type JobMatchingHighlightsJobKeyfactStringV2 =
  JobMatchingHighlightsJobKeyfactV2 & {
    __typename?: 'JobMatchingHighlightsJobKeyfactStringV2';
    /** #logged-out #soft-logged-in */
    localization: JobMatchingHighlightsLocalizableV2;
    /** #logged-out #soft-logged-in */
    localizationA11y?: Maybe<JobMatchingHighlightsLocalizableV2>;
    /** #logged-out #soft-logged-in */
    type: Scalars['String']['output'];
    /** #logged-out #soft-logged-in */
    value: Scalars['String']['output'];
  };

export const enum JobMatchingHighlightsJobKeyfactType {
  JobKeyfactCareerLevel = 'JOB_KEYFACT_CAREER_LEVEL',
  JobKeyfactEmploymentType = 'JOB_KEYFACT_EMPLOYMENT_TYPE',
  JobKeyfactPreferredDiscipline = 'JOB_KEYFACT_PREFERRED_DISCIPLINE',
  JobKeyfactPreferredEmployer = 'JOB_KEYFACT_PREFERRED_EMPLOYER',
  JobKeyfactPreferredIndustry = 'JOB_KEYFACT_PREFERRED_INDUSTRY',
  JobKeyfactSalary = 'JOB_KEYFACT_SALARY',
}

export type JobMatchingHighlightsJobKeyfactV2 = {
  /** #logged-out #soft-logged-in */
  localization: JobMatchingHighlightsLocalizableV2;
  /** #logged-out #soft-logged-in */
  localizationA11y?: Maybe<JobMatchingHighlightsLocalizableV2>;
  /** #logged-out #soft-logged-in */
  type: Scalars['String']['output'];
};

export type JobMatchingHighlightsLocalizableV2 = {
  __typename?: 'JobMatchingHighlightsLocalizableV2';
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export type JobMatchingHighlightsV2 = {
  __typename?: 'JobMatchingHighlightsV2';
  /** #logged-out #soft-logged-in */
  highlight?: Maybe<JobMatchingHighlightsJobHighlightV2>;
  /** #logged-out #soft-logged-in */
  matchingFacts: Array<JobMatchingHighlightsJobKeyfactV2>;
  /** #logged-out #soft-logged-in */
  nonMatchingFacts: Array<JobMatchingHighlightsJobKeyfactV2>;
  /** #logged-out #soft-logged-in */
  token: Scalars['String']['output'];
};

export type JobPostingJobRoleMatches = {
  __typename?: 'JobPostingJobRoleMatches';
  matchedJobRoles?: Maybe<Array<Maybe<OntologyLocalizedJobRole>>>;
  notMatchedJobRoles?: Maybe<Array<Maybe<OntologyLocalizedJobRole>>>;
};

export type JobPostingSkillMatches = {
  __typename?: 'JobPostingSkillMatches';
  matchedSkills?: Maybe<Array<Maybe<OntologyLocalizedJobPostingSkill>>>;
  notMatchedSkills?: Maybe<Array<Maybe<OntologyLocalizedJobPostingSkill>>>;
};

/** Job Preference User Flag Type */
export type JobPreferenceUserFlags = {
  __typename?: 'JobPreferenceUserFlags';
  serpBannerHidden: Scalars['Boolean']['output'];
};

export type JobPreferencesDataState = {
  __typename?: 'JobPreferencesDataState';
  careerLevel: CareerLevelPreferenceDataState;
  disciplines: JobPreferencesDataStateStatus;
  idealEmployers: JobPreferencesDataStateStatus;
  industries: JobPreferencesDataStateStatus;
  jobTitles: JobTitlesPreferenceDataState;
  locations: LocationsPreferenceDataState;
  salaryExpectations: JobPreferencesDataStateStatus;
  workingHours: JobPreferencesDataStateStatus;
  workplaces: JobPreferencesDataStateStatus;
};

export type JobPreferencesDataStateInfo = {
  __typename?: 'JobPreferencesDataStateInfo';
  dataStates: JobPreferencesDataState;
  filledPreferences: Scalars['Int']['output'];
  totalPreferences: Scalars['Int']['output'];
};

export const enum JobPreferencesDataStateStatus {
  Empty = 'EMPTY',
  Filled = 'FILLED',
  Undefined = 'UNDEFINED',
}

/** JobPreferencesJobSeekerStatus Type */
export type JobPreferencesJobSeekerStatus = {
  __typename?: 'JobPreferencesJobSeekerStatus';
  status?: Maybe<PreferenceSeekingStatus>;
};

export type JobPreviewMetadata = PreviewMetadata & {
  __typename?: 'JobPreviewMetadata';
  sourceActor?: Maybe<LinkPreviewSourceActor>;
  urn: Scalars['GlobalID']['output'];
};

export type JobRecentSearch = {
  __typename?: 'JobRecentSearch';
  id?: Maybe<Scalars['SlugOrID']['output']>;
  /** #rest #virtual */
  jobSearchResult?: Maybe<JobSearchResult>;
  /** #rest(batched) #virtual */
  searchQuery: JobSearchQuery;
  searchText: Scalars['String']['output'];
};

export type JobRecentSearchCollection = {
  __typename?: 'JobRecentSearchCollection';
  searches?: Maybe<Array<JobRecentSearch>>;
};

export type JobRecentSearchCollectionJs = {
  __typename?: 'JobRecentSearchCollectionJS';
  searches?: Maybe<Array<JobSearchQuery>>;
  userInfo: UserInfoJs;
};

export type JobRecoDeclineRecommendationError = {
  __typename?: 'JobRecoDeclineRecommendationError';
  error?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type JobRecoDeclineRecommendationResult = {
  __typename?: 'JobRecoDeclineRecommendationResult';
  error?: Maybe<JobRecoDeclineRecommendationError>;
  success?: Maybe<JobRecoDeclineRecommendationSuccess>;
};

export type JobRecoDeclineRecommendationSuccess = {
  __typename?: 'JobRecoDeclineRecommendationSuccess';
  message?: Maybe<Scalars['String']['output']>;
};

export type JobRecoFeedBackResult = {
  __typename?: 'JobRecoFeedBackResult';
  /** #soft-logged-in */
  error?: Maybe<JobRecoFeedbackError>;
  /** #soft-logged-in */
  success?: Maybe<JobRecoFeedBackSuccess>;
};

export type JobRecoFeedBackSuccess = {
  __typename?: 'JobRecoFeedBackSuccess';
  /** #soft-logged-in */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type JobRecoFeedbackError = {
  __typename?: 'JobRecoFeedbackError';
  /** #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type JobRecoFeedbackInput = {
  consumer: Scalars['String']['input'];
  itemId: Scalars['Int']['input'];
  rating: Scalars['Int']['input'];
  topic: Scalars['String']['input'];
};

export type JobRecoOntologySetting = {
  id: Scalars['ID']['output'];
  isPositive: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export type JobRecoOntologySettingDiscipline = JobRecoOntologySetting & {
  __typename?: 'JobRecoOntologySettingDiscipline';
  id: Scalars['ID']['output'];
  isPositive: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export type JobRecoOntologySettingJobrole = JobRecoOntologySetting & {
  __typename?: 'JobRecoOntologySettingJobrole';
  id: Scalars['ID']['output'];
  isPositive: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export type JobRecoOntologySettingSkill = JobRecoOntologySetting & {
  __typename?: 'JobRecoOntologySettingSkill';
  id: Scalars['ID']['output'];
  isPositive: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export type JobRecoUserSettings = {
  __typename?: 'JobRecoUserSettings';
  disciplines: Array<JobRecoOntologySettingDiscipline>;
  jobroles: Array<JobRecoOntologySettingJobrole>;
  maxCareerLevel?: Maybe<Scalars['Int']['output']>;
  maxDistance?: Maybe<Scalars['Int']['output']>;
  minCareerLevel?: Maybe<Scalars['Int']['output']>;
  skills: Array<JobRecoOntologySettingSkill>;
};

export type JobRecommendation = Recommendation & {
  __typename?: 'JobRecommendation';
  /** #rest(batched) #virtual */
  interactionTarget?: Maybe<SocialInteractionTarget>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['ID']['output'];
  /** ITJFM (Is This Job For Me) score */
  itjfmScore?: Maybe<IsThisJobForMeScore>;
  /** #rest(batched) #virtual */
  job?: Maybe<JobResult>;
  /** #rest(batched) #virtual */
  matchingHighlights?: Maybe<JobMatchingHighlights>;
  /** #rest(batched) #virtual */
  matchingHighlightsV2?: Maybe<JobMatchingHighlightsV2>;
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type JobRecommendationAggregatedNotification = AggregatedNotification &
  Notification & {
    __typename?: 'JobRecommendationAggregatedNotification';
    aggregated: Scalars['Boolean']['output'];
    /** #rest(batched) #virtual */
    bucketPreview?: Maybe<Array<Maybe<Notification>>>;
    id: Scalars['ID']['output'];
    interacted: Scalars['Boolean']['output'];
    opened: Scalars['Boolean']['output'];
    publishedAt: Scalars['Date']['output'];
    seen: Scalars['Boolean']['output'];
    total: Scalars['Int']['output'];
    trackingToken?: Maybe<Scalars['String']['output']>;
    urn: Scalars['URN']['output'];
  };

export type JobRecommendationEdge = PaginationEdge & {
  __typename?: 'JobRecommendationEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: JobRecommendation;
};

export type JobRecommendationNotification = Notification & {
  __typename?: 'JobRecommendationNotification';
  aggregated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interacted: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<JobResult>;
  opened: Scalars['Boolean']['output'];
  publishedAt: Scalars['Date']['output'];
  seen: Scalars['Boolean']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn: Scalars['URN']['output'];
};

export type JobRecommendationSignal = ConversationStarter & {
  __typename?: 'JobRecommendationSignal';
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<JobResult>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type JobRecommendationStack = SignalsStack & {
  __typename?: 'JobRecommendationStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type JobRecommendationStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type JobRecommendationUserTopicEdge = PaginationEdge & {
  __typename?: 'JobRecommendationUserTopicEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: JobRecommendationsUserTopic;
};

export type JobRecommendationsCategories = RecommenderResponse & {
  __typename?: 'JobRecommendationsCategories';
  categories: JobRecommendationsCategoryList;
  collection: Array<JobRecommendation>;
  requestTrackingToken: Scalars['String']['output'];
  service: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export type JobRecommendationsCategoryList = {
  __typename?: 'JobRecommendationsCategoryList';
  collaborative: JobRecommendationsCategoryRecos;
  general: JobRecommendationsCategoryRecos;
  mlt: JobRecommendationsCategoryRecos;
  new: JobRecommendationsCategoryRecos;
  profile: JobRecommendationsCategoryRecos;
  suggestedOrder: Array<Scalars['String']['output']>;
};

export type JobRecommendationsCategoryRecos = {
  __typename?: 'JobRecommendationsCategoryRecos';
  items: Array<Scalars['ID']['output']>;
  total: Scalars['Int']['output'];
};

export type JobRecommendationsSimilarJob = {
  __typename?: 'JobRecommendationsSimilarJob';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  job?: Maybe<JobResult>;
  /** #logged-out #soft-logged-in */
  position: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  trackingToken: Scalars['String']['output'];
};

export type JobRecommendationsSimilarJobs = RecommenderResponse & {
  __typename?: 'JobRecommendationsSimilarJobs';
  /** #logged-out #soft-logged-in */
  collection: Array<JobRecommendationsSimilarJob>;
  /** #logged-out #soft-logged-in */
  requestTrackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  service: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type JobRecommendationsUserTopic = {
  __typename?: 'JobRecommendationsUserTopic';
  itemId: Scalars['Int']['output'];
  labels?: Maybe<JobRecommendationsUserTopicLabels>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  trustworthiness?: Maybe<Scalars['Float']['output']>;
  urn: Scalars['String']['output'];
};

export type JobRecommendationsUserTopicLabels = {
  __typename?: 'JobRecommendationsUserTopicLabels';
  de?: Maybe<Scalars['String']['output']>;
  en?: Maybe<Scalars['String']['output']>;
  es?: Maybe<Scalars['String']['output']>;
  fr?: Maybe<Scalars['String']['output']>;
  it?: Maybe<Scalars['String']['output']>;
  nl?: Maybe<Scalars['String']['output']>;
  np?: Maybe<Scalars['String']['output']>;
  pl?: Maybe<Scalars['String']['output']>;
  pt?: Maybe<Scalars['String']['output']>;
  ru?: Maybe<Scalars['String']['output']>;
  tr?: Maybe<Scalars['String']['output']>;
  un?: Maybe<Scalars['String']['output']>;
  zh?: Maybe<Scalars['String']['output']>;
};

export type JobRecommendationsUserTopicsConnection = HasCursorPagination &
  RecommenderResponse & {
    __typename?: 'JobRecommendationsUserTopicsConnection';
    edges: Array<JobRecommendationUserTopicEdge>;
    /** #virtual */
    pageInfo: PageInfo;
    requestTrackingToken: Scalars['String']['output'];
    service: Scalars['String']['output'];
    total: Scalars['Int']['output'];
  };

export type JobRecommenderConnection = HasCursorPagination &
  RecommenderResponse & {
    __typename?: 'JobRecommenderConnection';
    edges: Array<JobRecommendationEdge>;
    /** #virtual */
    pageInfo: PageInfo;
    requestTrackingToken: Scalars['String']['output'];
    service: Scalars['String']['output'];
    total: Scalars['Int']['output'];
  };

export type JobRegisterVisitInput = {
  jobId: Scalars['ID']['input'];
  originalUrl?: InputMaybe<Scalars['String']['input']>;
  referer?: InputMaybe<Scalars['String']['input']>;
};

export type JobRegisterVisitResult = {
  __typename?: 'JobRegisterVisitResult';
  /** #logged-out #soft-logged-in */
  error?: Maybe<JobDefaultError>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  job?: Maybe<JobResult>;
};

/** Remote Option localized */
export type JobRemoteOption = Localizable & {
  __typename?: 'JobRemoteOption';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export const enum JobRemoteOptionValue {
  FullRemote = 'FULL_REMOTE',
  NonRemote = 'NON_REMOTE',
  PartlyRemote = 'PARTLY_REMOTE',
}

export const enum JobReportReason {
  IllegalContent = 'ILLEGAL_CONTENT',
  NoJobAd = 'NO_JOB_AD',
  WrongInformation = 'WRONG_INFORMATION',
}

export const enum JobReportableField {
  CareerLevel = 'CAREER_LEVEL',
  CompanyName = 'COMPANY_NAME',
  EmploymentType = 'EMPLOYMENT_TYPE',
  Location = 'LOCATION',
  Other = 'OTHER',
}

export type JobResult = UnauthorizedJob | VisibleJob;

/** JobRole localized */
export type JobRole = {
  __typename?: 'JobRole';
  /** The name localized based on the `Accept-Language` header */
  name: Scalars['String']['output'];
};

export type JobRoleLabel = {
  __typename?: 'JobRoleLabel';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  label: Scalars['String']['output'];
};

export type JobRoles = Recommendation & {
  __typename?: 'JobRoles';
  itemId: Scalars['ID']['output'];
  localizedLabel: Scalars['String']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type JobRolesCollection = {
  __typename?: 'JobRolesCollection';
  collection: Array<Maybe<JobRoles>>;
};

export type JobSalary =
  | Salary
  | SalaryEstimate
  | SalaryRange
  | SalaryUnauthorized;

export type JobSearchAggregationsFilter = {
  benefit?: InputMaybe<JobSearchQueryAggregationsFilterInput>;
  benefitEmployeePerk?: InputMaybe<JobSearchQueryAggregationsFilterInput>;
  benefitWorkingCulture?: InputMaybe<JobSearchQueryAggregationsFilterInput>;
  city?: InputMaybe<JobSearchQueryAggregationsFilterInput>;
  country?: InputMaybe<JobSearchQueryAggregationsFilterInput>;
  discipline?: InputMaybe<JobSearchQueryAggregationsFilterInput>;
  employmentType?: InputMaybe<JobSearchQueryAggregationsFilterInput>;
  industry?: InputMaybe<JobSearchQueryAggregationsFilterInput>;
  level?: InputMaybe<JobSearchQueryAggregationsFilterInput>;
  remoteOption?: InputMaybe<JobSearchQueryAggregationsFilterInput>;
  role?: InputMaybe<JobSearchQueryAggregationsFilterInput>;
};

export type JobSearchAlertAggregatedNotification = AggregatedNotification &
  Notification & {
    __typename?: 'JobSearchAlertAggregatedNotification';
    aggregated: Scalars['Boolean']['output'];
    /** #rest(batched) #virtual */
    bucketPreview?: Maybe<Array<Maybe<Notification>>>;
    id: Scalars['ID']['output'];
    interacted: Scalars['Boolean']['output'];
    opened: Scalars['Boolean']['output'];
    publishedAt: Scalars['Date']['output'];
    seen: Scalars['Boolean']['output'];
    /** #rest(batched) #virtual */
    target?: Maybe<SearchAlert>;
    total: Scalars['Int']['output'];
    trackingToken?: Maybe<Scalars['String']['output']>;
    urn: Scalars['URN']['output'];
  };

export type JobSearchAlertNewIdResponse =
  | JobSearchAlertNewIdSuccessResponse
  | SearchAlertErrorResponse;

export type JobSearchAlertNewIdSuccessResponse = {
  __typename?: 'JobSearchAlertNewIdSuccessResponse';
  id: Scalars['ID']['output'];
};

export type JobSearchAlertNotification = Notification & {
  __typename?: 'JobSearchAlertNotification';
  aggregated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interacted: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<JobResult>;
  opened: Scalars['Boolean']['output'];
  publishedAt: Scalars['Date']['output'];
  seen: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  target?: Maybe<SearchAlert>;
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn: Scalars['URN']['output'];
};

export type JobSearchAlertSignal = NetworkUpdatesStackItem & {
  __typename?: 'JobSearchAlertSignal';
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<JobResult>;
  stackId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  target?: Maybe<SearchAlert>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type JobSearchAlertStack = SignalsStack & {
  __typename?: 'JobSearchAlertStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type JobSearchAlertStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export const enum JobSearchAlertsDomainError {
  AlertDoesNotBelongToUser = 'ALERT_DOES_NOT_BELONG_TO_USER',
  AlertExistsAlready = 'ALERT_EXISTS_ALREADY',
  AlertLimitReached = 'ALERT_LIMIT_REACHED',
  AlertNotFound = 'ALERT_NOT_FOUND',
  NoDomainError = 'NO_DOMAIN_ERROR',
  Unknown = 'UNKNOWN',
  UserNotFound = 'USER_NOT_FOUND',
}

/** Job SearchQuery Inspiration */
export type JobSearchInspiration = {
  __typename?: 'JobSearchInspiration';
  /** #logged-out #soft-logged-in #rest #virtual */
  description?: Maybe<GenericLocalizable>;
  /** #logged-out #soft-logged-in */
  icon: JobSearchInspirationIcon;
  /** #logged-out #soft-logged-in */
  searchQueryGuid: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  theme: JobSearchInspirationTheme;
  /** #logged-out #soft-logged-in #rest #virtual */
  title?: Maybe<GenericLocalizable>;
  /** #logged-out #soft-logged-in */
  type: Scalars['String']['output'];
};

export type JobSearchInspirationErrorResponse = {
  __typename?: 'JobSearchInspirationErrorResponse';
  /** #logged-out #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  path?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  status?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  timestamp?: Maybe<Scalars['Date']['output']>;
};

export const enum JobSearchInspirationIcon {
  Clock = 'CLOCK',
  DashedClock = 'DASHED_CLOCK',
  Dog = 'DOG',
  Heart = 'HEART',
  Home = 'HOME',
  Industries = 'INDUSTRIES',
  Public = 'PUBLIC',
  Rocket = 'ROCKET',
  Salary = 'SALARY',
  Stroller = 'STROLLER',
  Sync = 'SYNC',
}

export type JobSearchInspirationResponse =
  | JobSearchInspirationErrorResponse
  | JobSearchInspirationSuccessResponse;

/** Job Search Inspiration */
export type JobSearchInspirationSuccessResponse = {
  __typename?: 'JobSearchInspirationSuccessResponse';
  /** #logged-out #soft-logged-in */
  collection: Array<JobSearchInspiration>;
};

export const enum JobSearchInspirationTheme {
  Theme_1 = 'THEME_1',
  Theme_2 = 'THEME_2',
  Theme_3 = 'THEME_3',
}

export type JobSearchNeedsRefinementInput = {
  queryId: Scalars['SlugOrID']['input'];
};

export type JobSearchNeedsRefinementResponse =
  | JobSearchNeedsRefinementSuccessResponse
  | SearchAlertErrorResponse;

export type JobSearchNeedsRefinementSuccessResponse = {
  __typename?: 'JobSearchNeedsRefinementSuccessResponse';
  needsRefinement: Scalars['Boolean']['output'];
  suggestions: Array<JobSearchRefinementSuggestion>;
};

/** Search query result */
export type JobSearchQuery = {
  __typename?: 'JobSearchQuery';
  /** #logged-out #soft-logged-in */
  body?: Maybe<JobSearchQueryBody>;
  /** #logged-out #soft-logged-in */
  guid: Scalars['SlugOrID']['output'];
  /** #logged-out #soft-logged-in */
  searchMode: SearchMode;
};

export type JobSearchQueryAggregationsFilterInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** Main fields in job search query */
export type JobSearchQueryBody = {
  __typename?: 'JobSearchQueryBody';
  /** #logged-out #soft-logged-in */
  extractedFilter?: Maybe<JobSearchQueryExtractedFilter>;
  /** #logged-out #soft-logged-in */
  filter?: Maybe<JobSearchQueryFilter>;
  /** #logged-out #soft-logged-in */
  filterCollection?: Maybe<Array<Maybe<JobFilterCollection>>>;
  /** #logged-out #soft-logged-in */
  keywords?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  location?: Maybe<JobSearchQueryLocation>;
};

export type JobSearchQueryExtractedFilter = {
  __typename?: 'JobSearchQueryExtractedFilter';
  /** #logged-out #soft-logged-in */
  disabled?: Maybe<JobSearchQueryExtractedFilterDisabled>;
};

export type JobSearchQueryExtractedFilterDisabled = {
  __typename?: 'JobSearchQueryExtractedFilterDisabled';
  /** #logged-out #soft-logged-in */
  careerLevel?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  employmentType?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  location?: Maybe<JobSearchQueryLocationsByName>;
  /** #logged-out #soft-logged-in */
  remoteOption?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  salary?: Maybe<Scalars['Boolean']['output']>;
};

export type JobSearchQueryExtractedFilterDisabledInput = {
  careerLevel?: InputMaybe<JobSearchQueryIdentifiableInput>;
  employmentType?: InputMaybe<JobSearchQueryIdentifiableInput>;
  location?: InputMaybe<JobSearchQueryLocationsByNameInput>;
  remoteOption?: InputMaybe<JobSearchQueryIdentifiableInput>;
  salary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JobSearchQueryExtractedFilterInput = {
  disabled?: InputMaybe<JobSearchQueryExtractedFilterDisabledInput>;
};

export type JobSearchQueryFilter = {
  __typename?: 'JobSearchQueryFilter';
  /** #logged-out #soft-logged-in */
  benefit?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  benefitEmployeePerk?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  benefitWorkingCulture?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  careerLevel?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  city?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  company?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  country?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  discipline?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  employmentType?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  industry?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  projob?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  publishToCompany?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  remote?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  remoteOption?: Maybe<JobSearchQueryIdentifiable>;
  /** #logged-out #soft-logged-in */
  salary?: Maybe<JobSearchQuerySalary>;
};

export type JobSearchQueryFilterInput = {
  benefit?: InputMaybe<JobSearchQueryIdentifiableInput>;
  benefitEmployeePerk?: InputMaybe<JobSearchQueryIdentifiableInput>;
  benefitWorkingCulture?: InputMaybe<JobSearchQueryIdentifiableInput>;
  careerLevel?: InputMaybe<JobSearchQueryIdentifiableInput>;
  city?: InputMaybe<JobSearchQueryIdentifiableInput>;
  company?: InputMaybe<JobSearchQueryIdentifiableInput>;
  country?: InputMaybe<JobSearchQueryIdentifiableInput>;
  discipline?: InputMaybe<JobSearchQueryIdentifiableInput>;
  employmentType?: InputMaybe<JobSearchQueryIdentifiableInput>;
  industry?: InputMaybe<JobSearchQueryIdentifiableInput>;
  projob?: InputMaybe<Scalars['Boolean']['input']>;
  publishToCompany?: InputMaybe<Scalars['Boolean']['input']>;
  remote?: InputMaybe<Scalars['Boolean']['input']>;
  remoteOption?: InputMaybe<JobSearchQueryIdentifiableInput>;
  salary?: InputMaybe<JobSearchQuerySalaryInput>;
};

export type JobSearchQueryIdentifiable = {
  __typename?: 'JobSearchQueryIdentifiable';
  /** #logged-out #soft-logged-in */
  id?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type JobSearchQueryIdentifiableInput = {
  id: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type JobSearchQueryInput = {
  extractedFilter?: InputMaybe<JobSearchQueryExtractedFilterInput>;
  filter?: InputMaybe<JobSearchQueryFilterInput>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<JobSearchQueryLocationInput>;
};

/** Location params used in a job search query */
export type JobSearchQueryLocation = {
  __typename?: 'JobSearchQueryLocation';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  city?: Maybe<City>;
  /** #logged-out #soft-logged-in */
  cityId?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  radius?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type JobSearchQueryLocationInput = {
  cityId?: InputMaybe<Scalars['ID']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type JobSearchQueryLocationsByName = {
  __typename?: 'JobSearchQueryLocationsByName';
  /** #logged-out #soft-logged-in */
  name?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type JobSearchQueryLocationsByNameInput = {
  name: Array<InputMaybe<Scalars['String']['input']>>;
};

export type JobSearchQuerySalary = {
  __typename?: 'JobSearchQuerySalary';
  /** #logged-out #soft-logged-in */
  max?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  min?: Maybe<Scalars['Int']['output']>;
};

export type JobSearchQuerySalaryInput = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export const enum JobSearchRefinementSuggestion {
  Filters = 'FILTERS',
  Location = 'LOCATION',
}

/** Job search count result */
export type JobSearchResult = {
  __typename?: 'JobSearchResult';
  /** #logged-out #soft-logged-in */
  aggregations?: Maybe<JobAggregationResult>;
  /** #logged-out #soft-logged-in */
  appliedFilters?: Maybe<JobAppliedFilterResult>;
  /** #logged-out #soft-logged-in */
  collection: Array<Maybe<JobItemResult>>;
  /** #logged-out #soft-logged-in */
  searchQuery: JobSearchQuery;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

/** JobSeeker is a User seeking for Jobs */
export type JobSeeker = {
  id: Scalars['ID']['output'];
  /** #paginated #rest #virtual */
  idealEmployers?: Maybe<ProJobsIdealEmployersConnection>;
  isPendingFirstEdit: Scalars['Boolean']['output'];
  salaryExpectation?: Maybe<Salary>;
  seekingStatus: SeekingStatus;
};

/** JobSeeker is a User seeking for Jobs */
export type JobSeekerIdealEmployersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Public JobSeeker's data of non-ProJobs users */
export type JobSeekerBasicOthers = JobSeeker & {
  __typename?: 'JobSeekerBasicOthers';
  id: Scalars['ID']['output'];
  /** #paginated #rest #virtual */
  idealEmployers?: Maybe<ProJobsIdealEmployersConnection>;
  isPendingFirstEdit: Scalars['Boolean']['output'];
  salaryExpectation?: Maybe<Salary>;
  seekingStatus: SeekingStatus;
};

/** Public JobSeeker's data of non-ProJobs users */
export type JobSeekerBasicOthersIdealEmployersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Private (only for user herself) JobSeeker's data of non-ProJobs users */
export type JobSeekerBasicSelf = JobSeeker &
  JobSeekerVisibilityFields & {
    __typename?: 'JobSeekerBasicSelf';
    id: Scalars['ID']['output'];
    /** #paginated #rest #virtual */
    idealEmployers?: Maybe<ProJobsIdealEmployersConnection>;
    isPendingFirstEdit: Scalars['Boolean']['output'];
    salaryExpectation?: Maybe<Salary>;
    seekingStatus: SeekingStatus;
    visibility?: Maybe<JobSeekerVisibility>;
  };

/** Private (only for user herself) JobSeeker's data of non-ProJobs users */
export type JobSeekerBasicSelfIdealEmployersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** JobSeeker Guidance Type */
export type JobSeekerGuidance = {
  __typename?: 'JobSeekerGuidance';
  collection: Array<JobSeekerGuidanceSection>;
  header: Scalars['String']['output'];
};

export type JobSeekerGuidanceAction =
  | JobSeekerGuidanceActionNative
  | JobSeekerGuidanceActionWeb;

export type JobSeekerGuidanceActionNative = {
  __typename?: 'JobSeekerGuidanceActionNative';
  body: Scalars['String']['output'];
  fenced: Scalars['Boolean']['output'];
  header: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  trackingId: Scalars['String']['output'];
  urn: Scalars['String']['output'];
  value: JobSeekerGuidanceActionValue;
};

export type JobSeekerGuidanceActionValue = {
  __typename?: 'JobSeekerGuidanceActionValue';
  completed: Scalars['Boolean']['output'];
};

/** Types of JobSeekerGuidanceAction */
export type JobSeekerGuidanceActionWeb = {
  __typename?: 'JobSeekerGuidanceActionWeb';
  body: Scalars['String']['output'];
  fenced: Scalars['Boolean']['output'];
  header: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  trackingId: Scalars['String']['output'];
  url: Scalars['String']['output'];
  value: JobSeekerGuidanceActionValue;
};

export type JobSeekerGuidanceSection = {
  __typename?: 'JobSeekerGuidanceSection';
  collection: Array<JobSeekerGuidanceAction>;
  header: Scalars['String']['output'];
};

export type JobSeekerGuidanceUpdateActionError = {
  __typename?: 'JobSeekerGuidanceUpdateActionError';
  /** contains all errors that occurred */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type JobSeekerGuidanceUpdateActionInput = {
  actionId: Scalars['ID']['input'];
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Result type for updating the JobSeeker Guidance Action */
export type JobSeekerGuidanceUpdateActionResult =
  | JobSeekerGuidanceUpdateActionError
  | JobSeekerGuidanceUpdateActionSuccess;

export type JobSeekerGuidanceUpdateActionSuccess = {
  __typename?: 'JobSeekerGuidanceUpdateActionSuccess';
  actionId: Scalars['ID']['output'];
  completed: Scalars['Boolean']['output'];
  userId: Scalars['ID']['output'];
};

export const enum JobSeekerPlatform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

/** Fields only for ProJobs users */
export type JobSeekerProJobsFields = {
  /** #rest(batched) #virtual */
  discipline?: Maybe<Discipline>;
  /** #rest #virtual */
  projobsWishes?: Maybe<ProjobsWishes>;
  targetPositions?: Maybe<Array<Maybe<JobRole>>>;
  willingnessToTravelPercentage?: Maybe<Scalars['Int']['output']>;
};

/** Public JobSeeker's data of ProJobs users */
export type JobSeekerProJobsOthers = JobSeeker &
  JobSeekerProJobsFields & {
    __typename?: 'JobSeekerProJobsOthers';
    /** #rest(batched) #virtual */
    discipline?: Maybe<Discipline>;
    id: Scalars['ID']['output'];
    /** #paginated #rest #virtual */
    idealEmployers?: Maybe<ProJobsIdealEmployersConnection>;
    isPendingFirstEdit: Scalars['Boolean']['output'];
    /** #rest #virtual */
    projobsWishes?: Maybe<ProjobsWishes>;
    salaryExpectation?: Maybe<Salary>;
    seekingStatus: SeekingStatus;
    targetPositions?: Maybe<Array<Maybe<JobRole>>>;
    willingnessToTravelPercentage?: Maybe<Scalars['Int']['output']>;
  };

/** Public JobSeeker's data of ProJobs users */
export type JobSeekerProJobsOthersIdealEmployersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Private (only for user herself) JobSeeker's data of ProJobs users */
export type JobSeekerProJobsSelf = JobSeeker &
  JobSeekerProJobsFields &
  JobSeekerVisibilityFields & {
    __typename?: 'JobSeekerProJobsSelf';
    /** #rest(batched) #virtual */
    discipline?: Maybe<Discipline>;
    id: Scalars['ID']['output'];
    /** #paginated #rest #virtual */
    idealEmployers?: Maybe<ProJobsIdealEmployersConnection>;
    isPendingFirstEdit: Scalars['Boolean']['output'];
    /** #rest #virtual */
    projobsWishes?: Maybe<ProjobsWishes>;
    salaryExpectation?: Maybe<Salary>;
    seekingStatus: SeekingStatus;
    targetPositions?: Maybe<Array<Maybe<JobRole>>>;
    visibility?: Maybe<JobSeekerVisibility>;
    willingnessToTravelPercentage?: Maybe<Scalars['Int']['output']>;
  };

/** Private (only for user herself) JobSeeker's data of ProJobs users */
export type JobSeekerProJobsSelfIdealEmployersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type JobSeekerStatus = {
  __typename?: 'JobSeekerStatus';
  count: Scalars['Int']['output'];
  xtmUrl: Scalars['URL']['output'];
};

export type JobSeekerStatusInsight = {
  __typename?: 'JobSeekerStatusInsight';
  interested: JobSeekerStatus;
  notSeeking: JobSeekerStatus;
  seeking: JobSeekerStatus;
  xtmUrl: Scalars['URL']['output'];
};

/** JobSeeker visibility to data */
export type JobSeekerVisibility = {
  __typename?: 'JobSeekerVisibility';
  displayMessage: Scalars['String']['output'];
};

/** JobSeekerVisibility */
export type JobSeekerVisibilityFields = {
  visibility?: Maybe<JobSeekerVisibility>;
};

export const enum JobSeekerWillingnessToTravel {
  Frequent = 'FREQUENT',
  None = 'NONE',
  Occasional = 'OCCASIONAL',
}

export type JobSeekerWorkingHours = {
  __typename?: 'JobSeekerWorkingHours';
  fullTime: Scalars['Boolean']['output'];
  partTime: Scalars['Boolean']['output'];
};

export type JobSummary = {
  __typename?: 'JobSummary';
  content: JobSummaryContent;
  trackingToken: Scalars['String']['output'];
};

export type JobSummaryContent = {
  __typename?: 'JobSummaryContent';
  introduction: Scalars['String']['output'];
  keyResponsibilities: Array<Scalars['String']['output']>;
  qualifications: Array<Scalars['String']['output']>;
};

export type JobTitleItem = {
  __typename?: 'JobTitleItem';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

/** JobTitleSuggestionsPreference Type */
export type JobTitleSuggestionsPreference = {
  __typename?: 'JobTitleSuggestionsPreference';
  jobTitleSuggestions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** JobTitlesPreference Type */
export type JobTitlesPreference = {
  __typename?: 'JobTitlesPreference';
  targetPositions: Array<Maybe<Scalars['String']['output']>>;
  unpreferredPositions: Array<Maybe<JobTitleItem>>;
};

export type JobTitlesPreferenceDataState = {
  __typename?: 'JobTitlesPreferenceDataState';
  excluded: JobPreferencesDataStateStatus;
  state: JobPreferencesDataStateStatus;
  target: JobPreferencesDataStateStatus;
};

export type JobTitlesUnpreferredPositionsInput = {
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
};

export type JobUserApplication = JobUserXingApplication;

export type JobUserApplyIntentionInput = {
  jobId: Scalars['ID']['input'];
  platform: JobInteractionPlatform;
};

export type JobUserApplyIntentionResult = {
  __typename?: 'JobUserApplyIntentionResult';
  /** #logged-out #soft-logged-in */
  error?: Maybe<JobDefaultError>;
};

export type JobUserInteractions = {
  __typename?: 'JobUserInteractions';
  /** #rest #virtual */
  application?: Maybe<JobUserApplication>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  bookmark?: Maybe<JobBookmark>;
  /**
   * #soft-logged-in
   * @deprecated Interest feature is deprecated
   */
  interest?: Maybe<JobInterest>;
};

export type JobUserScore = {
  __typename?: 'JobUserScore';
  estimatedRating?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
  scoreRounded?: Maybe<Scalars['Float']['output']>;
};

export type JobUserXingApplication = {
  __typename?: 'JobUserXingApplication';
  createdAt: Scalars['Date']['output'];
  status: JobUserXingApplicationStatus;
};

export const enum JobUserXingApplicationStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Undelivered = 'UNDELIVERED',
}

export type JobVideo = {
  __typename?: 'JobVideo';
  /** #logged-out #soft-logged-in */
  url: Scalars['URL']['output'];
};

export type JobVisit = {
  __typename?: 'JobVisit';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  job?: Maybe<JobResult>;
  /** #logged-out #soft-logged-in */
  lastViewedAt?: Maybe<Scalars['Date']['output']>;
};

export type JobVisitEdge = PaginationEdge & {
  __typename?: 'JobVisitEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node: JobVisit;
};

export type JobVisitsConnection = HasCursorPagination & {
  __typename?: 'JobVisitsConnection';
  /** #logged-out #soft-logged-in */
  edges?: Maybe<Array<Maybe<JobVisitEdge>>>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

/** JobWishesPreference Type */
export type JobWishesPreference = {
  __typename?: 'JobWishesPreference';
  /** #rest(batched) #virtual */
  cities: Array<Maybe<City>>;
  /** #rest(batched) #virtual */
  idealEmployers: Array<Maybe<Company>>;
  /** #rest(batched) #virtual */
  industries: Array<Maybe<Industry>>;
  maxCareerLevel?: Maybe<Scalars['Int']['output']>;
  maxDistance?: Maybe<Scalars['Int']['output']>;
  minCareerLevel?: Maybe<Scalars['Int']['output']>;
  openToOtherIndustries: Scalars['Boolean']['output'];
  salaryExpectations?: Maybe<Scalars['Int']['output']>;
  seekingStatus: PreferenceSeekingStatus;
  statusVisibility?: Maybe<StatusVisibility>;
  /** @deprecated This field is not longer used neither populated. Will be removed soon */
  willingnessToMove: Scalars['Boolean']['output'];
  willingnessToTravel?: Maybe<WillingnessToTravelPreference>;
  workingHours?: Maybe<WorkingHoursPreference>;
};

/** Profile Module for the entry point to the Job Wishes Preferences */
export type JobWishesPreferenceModule = ModuleInterface & {
  __typename?: 'JobWishesPreferenceModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** #rest #virtual */
  data?: Maybe<JobWishesPreference>;
  /** #rest #virtual */
  dataStates?: Maybe<JobPreferencesDataState>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type JobXingApplication = {
  __typename?: 'JobXingApplication';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  applyServiceSettings?: Maybe<ApplyServiceSettings>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   * @deprecated Field no longer supported
   */
  form?: Maybe<JobApplicationForm>;
  /** #logged-out #soft-logged-in #rest #virtual */
  formExternalDocuments?: Maybe<FormExternalDocuments>;
  /** #logged-out #soft-logged-in #rest #virtual */
  formSettings?: Maybe<FormSettings>;
  /** #logged-out #soft-logged-in #rest #virtual */
  replySettings?: Maybe<ConvertedReplySettingsJob>;
};

export const enum JobsB2bApplyOptions {
  InstantApply = 'INSTANT_APPLY',
  OnlineForm = 'ONLINE_FORM',
}

export const enum JobsB2bCurrency {
  Chf = 'CHF',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
}

export const enum JobsB2bInterval {
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY',
}

export type JobsB2bJob = {
  __typename?: 'JobsB2bJob';
  data?: Maybe<JobsB2bJobData>;
};

export type JobsB2bJobData = {
  __typename?: 'JobsB2bJobData';
  accountId: Scalars['String']['output'];
  careerLevelId: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  creatorId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  disciplineId: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  industryId: Scalars['Int']['output'];
  jobLocation: JobsB2bJobLocation;
  jobRoleId?: Maybe<Scalars['Int']['output']>;
  jobSalary?: Maybe<JobsB2bJobSalary>;
  jobTypeCode: Scalars['String']['output'];
  lastPublishedAt?: Maybe<Scalars['Date']['output']>;
  remoteType?: Maybe<JobsB2bRemote>;
  skills?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  workExperienceId?: Maybe<Scalars['Int']['output']>;
  xingJobAds?: Maybe<Array<Maybe<JobsB2bXingJobAd>>>;
};

export type JobsB2bJobError = {
  __typename?: 'JobsB2bJobError';
  error?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['Int']['output']>;
};

export type JobsB2bJobLocation = {
  __typename?: 'JobsB2bJobLocation';
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type JobsB2bJobMetricsDetails = {
  __typename?: 'JobsB2bJobMetricsDetails';
  active?: Maybe<Scalars['Boolean']['output']>;
  applyOption?: Maybe<JobsB2bApplyOptions>;
  data?: Maybe<JobsB2bJobMetricsDetailsData>;
  /** #rest #virtual */
  job?: Maybe<JobsB2bJob>;
};

export type JobsB2bJobMetricsDetailsData = {
  __typename?: 'JobsB2bJobMetricsDetailsData';
  applyIntentions?: Maybe<Scalars['Int']['output']>;
  bookmarks?: Maybe<Scalars['Int']['output']>;
  externalApplicationClicks?: Maybe<Scalars['Int']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  instantApplications?: Maybe<Scalars['Int']['output']>;
  instantApplyClicks?: Maybe<Scalars['Int']['output']>;
  uniqueVisitors?: Maybe<Scalars['Int']['output']>;
  visits?: Maybe<Scalars['Int']['output']>;
};

export type JobsB2bJobMetricsError = {
  __typename?: 'JobsB2bJobMetricsError';
  error?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['Int']['output']>;
};

export type JobsB2bJobMetricsResult =
  | JobsB2bJobMetricsDetails
  | JobsB2bJobMetricsError;

export type JobsB2bJobResult = JobsB2bJob | JobsB2bJobError;

export type JobsB2bJobSalary = {
  __typename?: 'JobsB2bJobSalary';
  currency: JobsB2bCurrency;
  interval: JobsB2bInterval;
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export const enum JobsB2bRemote {
  FullyRemote = 'FULLY_REMOTE',
  NonRemote = 'NON_REMOTE',
  PartiallyRemote = 'PARTIALLY_REMOTE',
}

export type JobsB2bXingJobAd = {
  __typename?: 'JobsB2bXingJobAd';
  applyOption?: Maybe<JobsB2bApplyOptions>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyProfileUrl?: Maybe<Scalars['String']['output']>;
  contactPersonXingId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  onlineApplicationFormUrl?: Maybe<Scalars['String']['output']>;
};

export type JobsDeleteRecommendationsResult = {
  __typename?: 'JobsDeleteRecommendationsResult';
  error?: Maybe<JobDefaultError>;
};

export type JobsLinkBoxJobRole = {
  __typename?: 'JobsLinkBoxJobRole';
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  seoName?: Maybe<Scalars['String']['output']>;
};

export type JobsLinkBoxJobRoleInput = {
  /** Job role SEO name */
  jobRole: Scalars['String']['input'];
};

export type JobsLinkBoxLocation = {
  __typename?: 'JobsLinkBoxLocation';
  /** #logged-out #soft-logged-in */
  jobCount?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  location?: Maybe<JobsLinkBoxLocationLocation>;
  /** #logged-out #soft-logged-in */
  seoName?: Maybe<Scalars['String']['output']>;
};

export type JobsLinkBoxLocationLocation = {
  __typename?: 'JobsLinkBoxLocationLocation';
  /** #logged-out #soft-logged-in */
  cityId?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
};

export type JobsLinkBoxSkill = {
  __typename?: 'JobsLinkBoxSkill';
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  seoName?: Maybe<Scalars['String']['output']>;
};

export type JobsLinkBoxSkillInput = {
  /** Skill SEO name */
  skill: Scalars['String']['input'];
};

export type JobsLinkBoxTopLocationsNearbyInput = {
  /** Nearby city SEO name */
  city: Scalars['String']['input'];
};

export type JobsMarkRecommendationsAsSeenInput = {
  jobIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type JobsMarkRecommendationsAsSeenResult = {
  __typename?: 'JobsMarkRecommendationsAsSeenResult';
  /** #soft-logged-in */
  error?: Maybe<JobDefaultError>;
};

export type KununuBenefit = Localizable & {
  __typename?: 'KununuBenefit';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export type LoSearchAlertConfirmationInput = {
  token: Scalars['String']['input'];
};

export type LoSearchAlertMessage = {
  __typename?: 'LOSearchAlertMessage';
  /** #logged-out #soft-logged-in */
  body?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  level?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
};

export type LoSearchAlertResponse = {
  /** #logged-out #soft-logged-in */
  code?: Maybe<LoSearchAlertStatusCode>;
  /** #logged-out #soft-logged-in */
  message: LoSearchAlertMessage;
  /** #logged-out #soft-logged-in */
  query: JobSearchQueryBody;
};

export const enum LoSearchAlertStatusCode {
  Conflict = 'CONFLICT',
  NotFound = 'NOT_FOUND',
  Ok = 'OK',
}

export type LoSearchAlertUnsubscribeInput = {
  token: Scalars['String']['input'];
};

export type LoSearchConflictAlertResponse = LoSearchAlertResponse & {
  __typename?: 'LOSearchConflictAlertResponse';
  /** #logged-out #soft-logged-in */
  code?: Maybe<LoSearchAlertStatusCode>;
  /** #logged-out #soft-logged-in */
  message: LoSearchAlertMessage;
  /** #logged-out #soft-logged-in */
  query: JobSearchQueryBody;
};

export type LoSearchNotFoundAlertResponse = LoSearchAlertResponse & {
  __typename?: 'LOSearchNotFoundAlertResponse';
  /** #logged-out #soft-logged-in */
  code?: Maybe<LoSearchAlertStatusCode>;
  /** #logged-out #soft-logged-in */
  message: LoSearchAlertMessage;
  /** #logged-out #soft-logged-in */
  query: JobSearchQueryBody;
};

export type LoSearchOkAlertResponse = LoSearchAlertResponse & {
  __typename?: 'LOSearchOkAlertResponse';
  /** #logged-out #soft-logged-in */
  code?: Maybe<LoSearchAlertStatusCode>;
  /** #logged-out #soft-logged-in */
  message: LoSearchAlertMessage;
  /** #logged-out #soft-logged-in */
  query: JobSearchQueryBody;
};

export const enum LanguageCode {
  De = 'de',
  En = 'en',
}

export type LeadAdForm = {
  __typename?: 'LeadAdForm';
  actionCopy: Scalars['String']['output'];
  buttonCopy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  formSections?: Maybe<Array<LeadAdFormSection>>;
  headline: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: Scalars['URL']['output'];
  /** @deprecated ODT shutdown */
  odtTrackingToken?: Maybe<Scalars['String']['output']>;
  sender: LeadAdFormSender;
  thanksButtonCopy?: Maybe<Scalars['String']['output']>;
  thanksButtonUrl?: Maybe<Scalars['URL']['output']>;
  /** @deprecated clean code, use thanksTitle and thanksText instead. */
  thanksPage: LeadAdFormThanksPage;
  thanksText?: Maybe<Scalars['String']['output']>;
  thanksTitle: Scalars['String']['output'];
  urn: Scalars['GlobalID']['output'];
};

export type LeadAdFormField = {
  __typename?: 'LeadAdFormField';
  id: Scalars['ID']['output'];
  linkTextCopy?: Maybe<Scalars['String']['output']>;
  linkUrl?: Maybe<Scalars['URL']['output']>;
  name: Scalars['String']['output'];
  optional?: Maybe<Scalars['Boolean']['output']>;
  prefilledValue?: Maybe<Scalars['String']['output']>;
  type: LeadAdFormFields;
};

export const enum LeadAdFormFields {
  Address = 'address',
  Boolean = 'boolean',
  Date = 'date',
  Email = 'email',
  Information = 'information',
  Numeric = 'numeric',
  PhoneNumber = 'phone_number',
  Text = 'text',
}

export type LeadAdFormSection = {
  __typename?: 'LeadAdFormSection';
  fields?: Maybe<Array<LeadAdFormField>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: LeadAdFormSections;
};

export const enum LeadAdFormSections {
  Address = 'address',
  CustomFields = 'custom_fields',
  TermsAndConditions = 'terms_and_conditions',
}

export type LeadAdFormSender = {
  __typename?: 'LeadAdFormSender';
  image: Scalars['URL']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: AdSenderTypes;
  urn: Scalars['GlobalID']['output'];
};

export type LeadAdFormSubmissionAttribute = {
  formFieldId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type LeadAdFormSubmissionCreateError = {
  __typename?: 'LeadAdFormSubmissionCreateError';
  errors?: Maybe<Scalars['JSON']['output']>;
};

export type LeadAdFormSubmissionCreateInput = {
  id: Scalars['ID']['input'];
  leadAdFormSubmissionAttributes?: InputMaybe<
    Array<LeadAdFormSubmissionAttribute>
  >;
  leadAdFormSubmissionTermsAndConditionsAttributes?: InputMaybe<
    Array<LeadAdFormSubmissionTermsAndConditionsAttribute>
  >;
};

export type LeadAdFormSubmissionCreateResult =
  | LeadAdFormSubmissionCreateError
  | LeadAdFormSubmissionCreateSuccess;

export type LeadAdFormSubmissionCreateSuccess = {
  __typename?: 'LeadAdFormSubmissionCreateSuccess';
  status?: Maybe<Scalars['String']['output']>;
};

export type LeadAdFormSubmissionTermsAndConditionsAttribute = {
  accepted: Scalars['Boolean']['input'];
  termsAndConditionsFieldId: Scalars['ID']['input'];
};

export type LeadAdFormThanksPage = {
  __typename?: 'LeadAdFormThanksPage';
  thanksButtonCopy: Scalars['String']['output'];
  thanksText?: Maybe<Scalars['String']['output']>;
  thanksTitle: Scalars['String']['output'];
};

/**
 * LegacyAboutUsProperties and the resolvers were created to keep compatibility with the old module properties
 * while using the new endpoint to fetch a single module
 * They should be deleted as soon we're able to remove the "properties" attribute from the AboutEntity type
 */
export type LegacyAboutUsProperties = {
  __typename?: 'LegacyAboutUsProperties';
  /** #logged-out #soft-logged-in */
  hasAffiliates: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  hasDocuments: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  hasGallery: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  hasSubpage: Scalars['Boolean']['output'];
};

/** Legal Imprint Module */
export type LegalImprintModule = ModuleInterface & {
  __typename?: 'LegalImprintModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type LegalInformationInput = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

/** Legal Information Module */
export type LegalInformationModule = ModuleInterface & {
  __typename?: 'LegalInformationModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** #rest #virtual */
  content?: Maybe<Scalars['String']['output']>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type LegalInformationUpdateError = {
  __typename?: 'LegalInformationUpdateError';
  errors?: Maybe<LegalInformationUpdateErrors>;
  message?: Maybe<Scalars['String']['output']>;
};

export type LegalInformationUpdateErrors = {
  __typename?: 'LegalInformationUpdateErrors';
  content?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Legal page */
export type LegalPage = {
  __typename?: 'LegalPage';
  /**
   * #logged-out #soft-logged-in
   *
   *  Group name of the legal page for example 'terms_and_conditions' or 'legal_notices'
   */
  groupName: Scalars['String']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Name of the legal page
   */
  name?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Slug of the legal page for example 'talentmanager'
   */
  slug: Scalars['String']['output'];
};

/** Legal page content */
export type LegalPageContent = {
  __typename?: 'LegalPageContent';
  /**
   * #logged-out #soft-logged-in
   *
   *  Content of the legal page
   */
  content?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Flag for hiding the table of content
   */
  hideToc: Scalars['Boolean']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  iso8601 encoded UTC datetime when the content was published
   */
  liveDate: Scalars['Date']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Name of the legal page
   */
  name?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Flag for showing the nested counter list
   */
  nestedCounterList: Scalars['Boolean']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Table of contents for the content
   */
  tableOfContents?: Maybe<Array<Maybe<TableOfContent>>>;
};

/** List of all legal pages for the user. */
export type LegalPages = {
  __typename?: 'LegalPages';
  /** #logged-out #soft-logged-in */
  collection?: Maybe<Array<Maybe<LegalPage>>>;
};

export type LevelAggregation = HasAggregationCount & {
  __typename?: 'LevelAggregation';
  /** #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #soft-logged-in #rest(batched) #virtual */
  label?: Maybe<CareerLevel>;
  /** #soft-logged-in */
  value?: Maybe<Scalars['Int']['output']>;
};

export type LinkPreview = {
  __typename?: 'LinkPreview';
  cachedImageUrl?: Maybe<Scalars['URL']['output']>;
  canonicalUrl: Scalars['URL']['output'];
  description?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['URL']['output']>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  metadata: PreviewMetadata;
  sourceDomain?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uniqueId: Scalars['String']['output'];
  url: Scalars['URL']['output'];
};

export type LinkPreviewError = {
  __typename?: 'LinkPreviewError';
  message?: Maybe<Scalars['String']['output']>;
};

export type LinkPreviewMetadata = PreviewMetadata & {
  __typename?: 'LinkPreviewMetadata';
  sourceActor?: Maybe<LinkPreviewSourceActor>;
};

export type LinkPreviewResult = {
  __typename?: 'LinkPreviewResult';
  error?: Maybe<LinkPreviewError>;
  success?: Maybe<LinkPreview>;
};

export type LinkPreviewSourceActor = {
  __typename?: 'LinkPreviewSourceActor';
  image?: Maybe<Scalars['URL']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type LlmGatewayAboutMeSuggestion = {
  __typename?: 'LlmGatewayAboutMeSuggestion';
  /** #logged-out #soft-logged-in */
  aboutMe?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  language?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type LlmGatewayCoverLetterJobId = {
  __typename?: 'LlmGatewayCoverLetterJobId';
  /** #logged-out #soft-logged-in */
  jobId?: Maybe<Scalars['String']['output']>;
};

export type LlmGatewayCoverLetterSuggestion = {
  __typename?: 'LlmGatewayCoverLetterSuggestion';
  /** #logged-out #soft-logged-in */
  content?: Maybe<Array<Maybe<VitaCoverLetterContent>>>;
  /** #logged-out #soft-logged-in */
  jobId?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  language?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  progress?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  stagesOutput?: Maybe<Array<Maybe<LlmGatewayStages>>>;
  /** #logged-out #soft-logged-in */
  status?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type LlmGatewayCvLessApplyQuestions = {
  __typename?: 'LlmGatewayCvLessApplyQuestions';
  /** #logged-out #soft-logged-in */
  competencies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #logged-out #soft-logged-in */
  jobId?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  skills?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #logged-out #soft-logged-in */
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type LlmGatewayCvLessApplyQuestionsInput = {
  jobId: Scalars['Int']['input'];
};

export type LlmGatewayError = {
  __typename?: 'LlmGatewayError';
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type LlmGatewayGenerateAboutMeResult =
  | LlmGatewayAboutMeSuggestion
  | LlmGatewayError;

export type LlmGatewayGenerateCoverLetterResult =
  | LlmGatewayCoverLetterSuggestion
  | LlmGatewayError;

export type LlmGatewayGenerateCvLessApplyQuestionsResult =
  | LlmGatewayCvLessApplyQuestions
  | LlmGatewayError;

export type LlmGatewayInteractionTrackingInput = {
  consumer: Scalars['String']['input'];
  interactionType: LlmGatewayInteractionType;
  trackingToken: Scalars['String']['input'];
  userInput?: InputMaybe<Scalars['String']['input']>;
};

export type LlmGatewayInteractionTrackingResult = {
  __typename?: 'LlmGatewayInteractionTrackingResult';
  /** #logged-out #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
};

export const enum LlmGatewayInteractionType {
  Dislike = 'DISLIKE',
  Like = 'LIKE',
  Submit = 'SUBMIT',
}

export type LlmGatewayStages = {
  __typename?: 'LlmGatewayStages';
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  output?: Maybe<Scalars['String']['output']>;
};

export type LlmGatewayTriggerCoverLetterResult =
  | LlmGatewayCoverLetterJobId
  | LlmGatewayError;

export type LoPreviewCommunity = {
  __typename?: 'LoPreviewCommunity';
  /** #logged-out #soft-logged-in */
  commentCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  links?: Maybe<CommunityLinks>;
  /** #logged-out #soft-logged-in */
  logo: CommunityLogos;
  /** #logged-out #soft-logged-in */
  memberCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  postCount: Scalars['Int']['output'];
};

export type LoPreviewGroupMembershipsCollection = {
  __typename?: 'LoPreviewGroupMembershipsCollection';
  /**
   * #logged-out #soft-logged-in
   * @deprecated Due to groups shutdown, will return empty collection after 1.12.2022
   */
  collection?: Maybe<Array<Maybe<LoPreviewCommunity>>>;
};

export type LoPreviewProfileWorkExperienceCollection = {
  __typename?: 'LoPreviewProfileWorkExperienceCollection';
  collection?: Maybe<Array<Maybe<LoPreviewWorkexperience>>>;
};

export type LoPreviewWorkexperience = {
  __typename?: 'LoPreviewWorkexperience';
  /** #logged-out #soft-logged-in */
  beginDate?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  company?: Maybe<Company>;
  /** #logged-out #soft-logged-in */
  companyName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  companyNotes?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  companyUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  currentCompany?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  discipline?: Maybe<Discipline>;
  /** #logged-out #soft-logged-in */
  duration?: Maybe<ProfileWorkExperienceDuration>;
  /** #logged-out #soft-logged-in */
  endDate?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  localizedTimeString?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  normalizedName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  orgTypeId?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  primaryJob?: Maybe<Scalars['Boolean']['output']>;
  /** #rest(batched) #virtual */
  workExperienceStatus?: Maybe<WorkExperienceStatus>;
};

export type LoProfile = {
  __typename?: 'LoProfile';
  /** #paginated #logged-out #soft-logged-in #rest #virtual */
  peopleUserBag?: Maybe<PeopleUserBagConnection>;
  /** #logged-out #soft-logged-in #rest #virtual */
  profileAboutMe?: Maybe<ProfileAboutMe>;
  /** #logged-out #soft-logged-in #rest #virtual */
  profileEducation?: Maybe<ProfileEducationCollection>;
  /** #logged-out #soft-logged-in #rest #virtual */
  profileInterests?: Maybe<ProfileInterests>;
  /** #logged-out #soft-logged-in #rest #virtual */
  profileLanguageSkills?: Maybe<LoggedOutProfileLanguageCollection>;
  /** #logged-out #soft-logged-in #rest #virtual */
  profileModuleStoreItems?: Maybe<Array<Maybe<ProfileModuleStoreItem>>>;
  /** #logged-out #soft-logged-in #rest #virtual */
  profilePageMeta?: Maybe<ProfilePageMetaOrError>;
  /** #logged-out #soft-logged-in */
  profilePublicGroupMemberships?: Maybe<LoPreviewGroupMembershipsCollection>;
  /** #logged-out #soft-logged-in #rest #virtual */
  profileSkills?: Maybe<ProfileSkills>;
  /** #logged-out #soft-logged-in #rest #virtual */
  profileStructuredData?: Maybe<ProfileStructuredData>;
  /** #logged-out #soft-logged-in #rest #virtual */
  profileWants?: Maybe<ProfileWants>;
  /** #logged-out #soft-logged-in #rest #virtual */
  profileWantsCollection?: Maybe<ProfileWants2>;
  /** #logged-out #soft-logged-in #rest #virtual */
  profileWorkExperience?: Maybe<LoPreviewProfileWorkExperienceCollection>;
  /** #logged-out #soft-logged-in #rest #virtual */
  similarProfiles?: Maybe<RelatedProfileCollection>;
  /** #rest #virtual */
  xingId?: Maybe<XingIdOrError>;
};

export type LoProfilePeopleUserBagArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Interface for localizable object types.
 *
 * New Work One needs to support a mixed model,
 * because localization strategies differ between
 * Hops and Malt and the native mobile apps.
 *
 * While the former would like to do localization
 * mostly on the client side, the latter prefers
 * flexible localization on the backend (based on the `Accept-Language` header).
 * We're using `GraphQL` to allow both:
 *
 * * If you're interested in only the translation key, you'd request `localizationKey`.
 * * If you want to have the localized value, please request `localizationValue`.
 *
 * The locales for this are all coming from the `website` project in `Phraseapp`.
 * You can find them by searching for the `all_platforms` tag.
 */
export type Localizable = {
  /** The raw text resource key from Phraseapp */
  localizationKey: Scalars['String']['output'];
  /** The value localized based on the `Accept-Language` header */
  localizationValue: Scalars['String']['output'];
};

export type LocalizedCompanyRecommendationReason = Localizable & {
  __typename?: 'LocalizedCompanyRecommendationReason';
  localizationKey: Scalars['String']['output'];
  localizationValue: Scalars['String']['output'];
};

export type Location = {
  __typename?: 'Location';
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  radius?: Maybe<Scalars['Int']['output']>;
};

/** LocationsPreference Type */
export type LocationsPreference = {
  __typename?: 'LocationsPreference';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  cities: Array<Maybe<City>>;
  /** #logged-out #soft-logged-in */
  maxDistance?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  willingnessToTravel?: Maybe<GetWillingnessToTravelEnum>;
};

export type LocationsPreferenceDataState = {
  __typename?: 'LocationsPreferenceDataState';
  cities: JobPreferencesDataStateStatus;
  maxDistance: JobPreferencesDataStateStatus;
  state: JobPreferencesDataStateStatus;
  willingnessToTravel: JobPreferencesDataStateStatus;
};

export type LoggedInProfileLanguage = {
  __typename?: 'LoggedInProfileLanguage';
  language?: Maybe<Scalars['String']['output']>;
  skillLevelId?: Maybe<Scalars['Int']['output']>;
};

export type LoggedInProfileLanguageCollection = {
  __typename?: 'LoggedInProfileLanguageCollection';
  languageSkills?: Maybe<LoggedInProfileLanguageSkills>;
};

export type LoggedInProfileLanguageSkills = {
  __typename?: 'LoggedInProfileLanguageSkills';
  additionalLanguages?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Maybe<LoggedInProfileLanguage>>>;
};

export type LoggedOutProfileLanguageCollection = {
  __typename?: 'LoggedOutProfileLanguageCollection';
  /** #logged-out #soft-logged-in */
  collection?: Maybe<Array<Maybe<LoggedOutProfileLanguageSkills>>>;
};

export type LoggedOutProfileLanguageSkills = {
  __typename?: 'LoggedOutProfileLanguageSkills';
  /** #logged-out #soft-logged-in */
  displayLanguage?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  language?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  skillLevelId?: Maybe<Scalars['Int']['output']>;
};

/** Reason why the security issue was raised */
export const enum LoginSecurityIssueReason {
  /** EIDI project detected compromised credentials */
  Eidi = 'EIDI',
  /** email has been bounced */
  EmailBounced = 'EMAIL_BOUNCED',
  /** user has not logged in since some years ago */
  LongInactive = 'LONG_INACTIVE',
  /** manually created, for testing purposes mostly */
  Manual = 'MANUAL',
  /** reminder to set up the TFA */
  NoTwoFactorAuth = 'NO_TWO_FACTOR_AUTH',
  /** password version is deprecated */
  OldVersion = 'OLD_VERSION',
}

/** Web sessions */
export type LoginSession = {
  __typename?: 'LoginSession';
  active?: Maybe<Scalars['Boolean']['output']>;
  browser?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isp?: Maybe<Scalars['String']['output']>;
  lastAccess?: Maybe<Scalars['Date']['output']>;
  location?: Maybe<Scalars['String']['output']>;
};

/** Login related settings, currently support only password */
export type LoginSettings = {
  __typename?: 'LoginSettings';
  password?: Maybe<PasswordSetting>;
};

export type LoginSettingsError = {
  __typename?: 'LoginSettingsError';
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * Login related settings which need to be updated.
 * Currently supports only setting the password.
 */
export type LoginSettingsInput = {
  password?: InputMaybe<PasswordSettingInput>;
};

export type LoginSettingsMutationResult = {
  __typename?: 'LoginSettingsMutationResult';
  error?: Maybe<LoginSettings>;
  response?: Maybe<LoginSettings>;
};

/** Current user login state */
export const enum LoginState {
  LoggedIn = 'LOGGED_IN',
  LoggedOut = 'LOGGED_OUT',
  SoftLoggedIn = 'SOFT_LOGGED_IN',
}

/** Pending security issue of a user (e.g. password compromised, tfa recommended, email bounced) */
export type LoginUserSecurityIssue = {
  __typename?: 'LoginUserSecurityIssue';
  /** Unique ID of the security issue */
  id: Scalars['ID']['output'];
  /** Reason why the issue was raised */
  reason: LoginSecurityIssueReason;
};

/** List of pending security issues for a user (e.g. password compromised, tfa recommended, email bounced) */
export type LoginUserSecurityIssues = {
  __typename?: 'LoginUserSecurityIssues';
  collection: Array<LoginUserSecurityIssue>;
};

export type LoginUserSecurityIssuesAcknowledgeMutationError = {
  __typename?: 'LoginUserSecurityIssuesAcknowledgeMutationError';
  message?: Maybe<Scalars['String']['output']>;
};

export type LoginUserSecurityIssuesAcknowledgeMutationInput = {
  /** Unique ID of the security issue */
  id: Scalars['ID']['input'];
  /** should the issue be resolved (primary action clicked) */
  resolve: Scalars['Boolean']['input'];
};

export type LoginUserSecurityIssuesAcknowledgeMutationResult = {
  __typename?: 'LoginUserSecurityIssuesAcknowledgeMutationResult';
  error?: Maybe<LoginUserSecurityIssuesAcknowledgeMutationError>;
};

export type ManagedCompanies = {
  __typename?: 'ManagedCompanies';
  /** #rest(batched) #virtual */
  company?: Maybe<Company>;
};

export type ManagedCompaniesConnection = HasCursorPagination & {
  __typename?: 'ManagedCompaniesConnection';
  edges?: Maybe<Array<Maybe<ManagedCompaniesEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ManagedCompaniesEdge = PaginationEdge & {
  __typename?: 'ManagedCompaniesEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ManagedCompanies>;
};

export type MarkAllChatsAsReadError = {
  __typename?: 'MarkAllChatsAsReadError';
  message?: Maybe<Scalars['String']['output']>;
};

export type MarkAllChatsAsReadPopupAsShownError = {
  __typename?: 'MarkAllChatsAsReadPopupAsShownError';
  message?: Maybe<Scalars['String']['output']>;
};

export type MarkAllChatsAsReadPopupAsShownResult =
  | MarkAllChatsAsReadPopupAsShownError
  | MarkAllChatsAsReadPopupAsShownSuccess;

export type MarkAllChatsAsReadPopupAsShownSuccess = {
  __typename?: 'MarkAllChatsAsReadPopupAsShownSuccess';
  success: Scalars['Boolean']['output'];
};

export type MarkAllChatsAsReadResult =
  | MarkAllChatsAsReadError
  | MarkAllChatsAsReadSuccess;

export type MarkAllChatsAsReadSuccess = {
  __typename?: 'MarkAllChatsAsReadSuccess';
  success: Scalars['Boolean']['output'];
};

export type MarkAsReadMessengerChatError = {
  __typename?: 'MarkAsReadMessengerChatError';
  message?: Maybe<Scalars['String']['output']>;
};

export type MarkAsReadMessengerChatInput = {
  id: Scalars['ID']['input'];
};

export type MarkAsReadMessengerChatResult = {
  __typename?: 'MarkAsReadMessengerChatResult';
  error?: Maybe<MarkAsReadMessengerChatError>;
  success?: Maybe<MessengerChat>;
};

export type MarkAsSeenInput = {
  source: HasSeenSource;
  urn: Scalars['String']['input'];
};

export type MarkAsSeenMutationResult = {
  __typename?: 'MarkAsSeenMutationResult';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MarkAsUnreadMessengerChatInput = {
  id: Scalars['ID']['input'];
};

export type MarkAsUnreadMessengerChatResult = {
  __typename?: 'MarkAsUnreadMessengerChatResult';
  error?: Maybe<MarkAsUnreadMessengerChatResultError>;
  success?: Maybe<MessengerChat>;
};

export type MarkAsUnreadMessengerChatResultError = {
  __typename?: 'MarkAsUnreadMessengerChatResultError';
  message: Scalars['String']['output'];
};

export type MeetingRoomAddAttendeeInput = {
  sessionId: Scalars['String']['input'];
};

export type MeetingRoomAddAttendeeResult = {
  __typename?: 'MeetingRoomAddAttendeeResult';
  error?: Maybe<MeetingRoomAddAttendeeResultError>;
  success?: Maybe<MeetingRoomAttendee>;
};

export type MeetingRoomAddAttendeeResultError = {
  __typename?: 'MeetingRoomAddAttendeeResultError';
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type MeetingRoomAttendee = {
  __typename?: 'MeetingRoomAttendee';
  token: Scalars['String']['output'];
  /** #rest(batched) #virtual */
  user: XingId;
};

export type MemberAggregations = {
  __typename?: 'MemberAggregations';
  /** #soft-logged-in */
  city?: Maybe<Array<Maybe<MemberSearchAggregation>>>;
  /** #soft-logged-in */
  companyId?: Maybe<Array<Maybe<CompanyIdAggregation>>>;
  /** #soft-logged-in */
  companySize?: Maybe<Array<Maybe<MemberSearchAggregation>>>;
  /** #soft-logged-in */
  country?: Maybe<Array<Maybe<CountryAggregation>>>;
  /** #soft-logged-in */
  currentStart?: Maybe<Array<Maybe<MemberSearchAggregation>>>;
  /** #soft-logged-in */
  currentStatusCode?: Maybe<Array<Maybe<MemberSearchAggregation>>>;
  /** #soft-logged-in */
  executive?: Maybe<Array<Maybe<MemberSearchAggregation>>>;
  /** #soft-logged-in */
  industry?: Maybe<Array<Maybe<IndustryAggregation>>>;
  /** #soft-logged-in */
  language?: Maybe<Array<Maybe<MemberSearchAggregation>>>;
  /** #soft-logged-in */
  level?: Maybe<Array<Maybe<LevelAggregation>>>;
  /** #soft-logged-in */
  public?: Maybe<Array<Maybe<MemberSearchAggregation>>>;
  /** #soft-logged-in */
  title?: Maybe<Array<Maybe<MemberSearchAggregation>>>;
  /** #soft-logged-in */
  zipCode?: Maybe<Array<Maybe<MemberSearchAggregation>>>;
};

/**
 * Aggregations/facets for members search. Maximum number of distinct values to be returned by this aggregation
 * Usual one is 10, as calculating aggregations is a really costly operation do not require more than 20 as general rule.
 */
export type MemberAggregationsInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companySize?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  currentStart?: InputMaybe<Scalars['String']['input']>;
  currentStatusCode?: InputMaybe<Scalars['String']['input']>;
  executive?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Available filters for members search.
 * In general filters results which match ANY of the given values (comma separated except special
 * cases documented individually).
 */
export type MemberFiltersInput = {
  active?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companySize?: InputMaybe<Scalars['String']['input']>;
  /**
   * CompletedAt: Defines range(comma separated) for completed_at for filtering results.
   * Supported formats are:
   * 123/456: values larger than 123 but smaller than 456
   * /456: values smaller than 456
   * 123/: values larger than 123
   * Date ranges are also supported, using the format yyyy-MM-ddThh:mm:ssZ
   */
  completedAt?: InputMaybe<Scalars['String']['input']>;
  /**
   * ConnectionLevel:
   * 1- 1st degree connections (direct connections)
   * 2 - 2nd degree connections(connections of a direct connection who are not a 1st degree connection)
   * 3 - 3rd+ degree connections(all other profiles)
   */
  connectionLevel?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  currentStart?: InputMaybe<Scalars['String']['input']>;
  currentStatusCode?: InputMaybe<Scalars['String']['input']>;
  discipline?: InputMaybe<Scalars['String']['input']>;
  executive?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  /**
   * ContactLevel:
   * Filters results based on its connection level from the requesting user. Possible values are:
   * non: non connections
   * 1: direct connections
   * 2: direct connections and connections of connections
   * 2-1: non direct connections(only connections of connections)
   */
  level?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type MemberPredictiveSearchItem = PredictiveSearchItem & {
  __typename?: 'MemberPredictiveSearchItem';
  /** #soft-logged-in */
  data: Scalars['String']['output'];
  /** #soft-logged-in */
  payload?: Maybe<ExtraData>;
  /** #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

/** Free text search for the following fields */
export type MemberQueriesInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  education?: InputMaybe<Scalars['String']['input']>;
  employer?: InputMaybe<Scalars['String']['input']>;
  haves?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  interests?: InputMaybe<Scalars['String']['input']>;
  jobrole?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<Scalars['String']['input']>;
  previousCompany?: InputMaybe<Scalars['String']['input']>;
  previousTitle?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  wants?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type MemberSearchAggregation = HasAggregationCount & {
  __typename?: 'MemberSearchAggregation';
  /** #soft-logged-in */
  count: Scalars['Int']['output'];
  /** #soft-logged-in */
  value: Scalars['String']['output'];
};

export type MembersSearchConnection = HasCursorPagination & {
  __typename?: 'MembersSearchConnection';
  /** #soft-logged-in */
  aggs?: Maybe<MemberAggregations>;
  /** #soft-logged-in */
  edges: Array<MembersSearchEdge>;
  /** #soft-logged-in */
  pageInfo: PageInfo;
  /** #soft-logged-in */
  service: Scalars['String']['output'];
  /** #soft-logged-in */
  total: Scalars['Int']['output'];
  /** #soft-logged-in */
  totalShown: Scalars['Int']['output'];
  /** #soft-logged-in */
  zeroSearch: Scalars['Boolean']['output'];
};

export type MembersSearchEdge = PaginationEdge & {
  __typename?: 'MembersSearchEdge';
  /** #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #soft-logged-in */
  node?: Maybe<MembersSearchResult>;
};

export type MembersSearchFencedItem = MembersSearchItem & {
  __typename?: 'MembersSearchFencedItem';
  /** #soft-logged-in #rest(batched) #virtual */
  fencedXingId?: Maybe<MembersSearchFencedXingId>;
  /** #soft-logged-in */
  position: Scalars['Int']['output'];
  /** #soft-logged-in */
  score?: Maybe<Scalars['Float']['output']>;
  /** #soft-logged-in */
  showType: Scalars['String']['output'];
  /** #soft-logged-in */
  trackingToken: Scalars['String']['output'];
};

export type MembersSearchFencedXingId = {
  __typename?: 'MembersSearchFencedXingId';
  /** #soft-logged-in */
  profileImage?: Maybe<Array<Maybe<ProfileImage>>>;
};

export type MembersSearchFencedXingIdProfileImageArgs = {
  size: Array<ProfileImageSize>;
};

export type MembersSearchHitReason = {
  __typename?: 'MembersSearchHitReason';
  /** #soft-logged-in */
  reason: Scalars['String']['output'];
  /** #soft-logged-in */
  title: Scalars['String']['output'];
};

export type MembersSearchItem = {
  /** #soft-logged-in */
  position: Scalars['Int']['output'];
  /** #soft-logged-in */
  score?: Maybe<Scalars['Float']['output']>;
  /** #soft-logged-in */
  showType: Scalars['String']['output'];
  /** #soft-logged-in */
  trackingToken: Scalars['String']['output'];
};

/** Available params for members search */
export type MembersSearchQueryInput = {
  aggregations?: InputMaybe<MemberAggregationsInput>;
  /** Request consumer, used for tracking and analisis purposes */
  consumer: Scalars['String']['input'];
  filters?: InputMaybe<MemberFiltersInput>;
  highlight?: InputMaybe<HighlightInput>;
  /** Free text search for keywords */
  keywords?: InputMaybe<Scalars['String']['input']>;
  queries?: InputMaybe<MemberQueriesInput>;
  /** Choses the criteria by which the collection should be sorted. Available options are: relevance, newest */
  sort?: InputMaybe<Scalars['String']['input']>;
  /** Timeout for Elasticsearch call */
  timeout?: InputMaybe<Scalars['Int']['input']>;
};

export type MembersSearchResult =
  | MembersSearchFencedItem
  | MembersSearchUnfencedItem;

export type MembersSearchUnfencedItem = MembersSearchItem & {
  __typename?: 'MembersSearchUnfencedItem';
  /** #soft-logged-in #rest(batched) #virtual */
  contactDistance?: Maybe<ContactDistance>;
  /** #rest(batched) #virtual */
  contactsCount?: Maybe<BatchContactsCount>;
  /** #soft-logged-in */
  contains?: Maybe<Array<Scalars['String']['output']>>;
  /** #soft-logged-in */
  highlight?: Maybe<HighlightResult>;
  /** #soft-logged-in */
  hitReasons?: Maybe<Array<MembersSearchHitReason>>;
  /** #soft-logged-in */
  missing?: Maybe<Array<Scalars['String']['output']>>;
  /** #soft-logged-in #rest(batched) #virtual */
  networkRelationship?: Maybe<NetworkRelationship>;
  /** #soft-logged-in */
  position: Scalars['Int']['output'];
  /** #soft-logged-in #rest(batched) #virtual */
  profileSkills?: Maybe<ProfileSkills>;
  /** #soft-logged-in */
  score?: Maybe<Scalars['Float']['output']>;
  /** #rest(batched) #virtual */
  sharedContacts?: Maybe<SharedContacts>;
  /** #soft-logged-in */
  showAddContact: Scalars['Boolean']['output'];
  /** #soft-logged-in */
  showType: Scalars['String']['output'];
  /** #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #soft-logged-in #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type MessageApplication = {
  __typename?: 'MessageApplication';
  /** #soft-logged-in */
  contactUserId?: Maybe<Scalars['ID']['output']>;
};

export type MessageConversationStarter = ConversationStarter & {
  __typename?: 'MessageConversationStarter';
  /** #rest(batched) #virtual */
  actor?: Maybe<MessengerParticipant>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<MessengerMessage>;
  /** #rest(batched) #virtual */
  target?: Maybe<MessengerChat>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type MessageEdge = PaginationEdge & {
  __typename?: 'MessageEdge';
  cursor: Scalars['String']['output'];
  node: MessengerMessage;
};

/** It defines the supported messages' types. */
export const enum MessageType {
  Attachment = 'ATTACHMENT',
  AutoDeclined = 'AUTO_DECLINED',
  ChatUpdated = 'CHAT_UPDATED',
  ContactConfirmed = 'CONTACT_CONFIRMED',
  ContactRequestCreated = 'CONTACT_REQUEST_CREATED',
  ContactRequestDeclined = 'CONTACT_REQUEST_DECLINED',
  Html = 'HTML',
  Image = 'IMAGE',
  Object = 'OBJECT',
  ParticipantAdded = 'PARTICIPANT_ADDED',
  ParticipantBirthday = 'PARTICIPANT_BIRTHDAY',
  ParticipantJoined = 'PARTICIPANT_JOINED',
  ParticipantKicked = 'PARTICIPANT_KICKED',
  ParticipantLeft = 'PARTICIPANT_LEFT',
  PositionShare = 'POSITION_SHARE',
  Preview = 'PREVIEW',
  ScheduledMessageCreated = 'SCHEDULED_MESSAGE_CREATED',
  ScheduledMessageDeleted = 'SCHEDULED_MESSAGE_DELETED',
  Secret = 'SECRET',
  StoryReference = 'STORY_REFERENCE',
  SystemReply = 'SYSTEM_REPLY',
  Text = 'TEXT',
  TextLocalised = 'TEXT_LOCALISED',
}

export type MessagesSearch = {
  __typename?: 'MessagesSearch';
  items?: Maybe<Array<Maybe<SearchResultMessage>>>;
  moreItems?: Maybe<Scalars['Boolean']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type MessengerAttachment = {
  __typename?: 'MessengerAttachment';
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MessengerAttachmentMessagePayload = {
  __typename?: 'MessengerAttachmentMessagePayload';
  attachments?: Maybe<Array<Maybe<MessengerAttachment>>>;
};

export type MessengerAutoDeclinedMessagePayload = {
  __typename?: 'MessengerAutoDeclinedMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

export type MessengerBanner = {
  __typename?: 'MessengerBanner';
  show: Scalars['Boolean']['output'];
};

export const enum MessengerBannerAction {
  Shown = 'SHOWN',
  ShowLater = 'SHOW_LATER',
}

export const enum MessengerBannerType {
  CollectFeedback = 'COLLECT_FEEDBACK',
  CollectFeedbackIndividualRms = 'COLLECT_FEEDBACK_INDIVIDUAL_RMS',
  CollectPreferences = 'COLLECT_PREFERENCES',
  NudgeTalentResponse = 'NUDGE_TALENT_RESPONSE',
}

export type MessengerBotReplyQuickAction = MessengerQuickActionInterface & {
  __typename?: 'MessengerBotReplyQuickAction';
  body: Scalars['String']['output'];
  replyId: Scalars['String']['output'];
  textResourceId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type MessengerChat = {
  __typename?: 'MessengerChat';
  /** #rest(batched) #virtual */
  abilities?: Maybe<MessengerChatAbilities>;
  creatorId?: Maybe<Scalars['ID']['output']>;
  deleted: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  /** @deprecated use abilities.canCreateMessage instead. */
  forbidSendingMessages: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  imageUrls: MessengerImage;
  /** #rest(batched) #virtual */
  lastMessage?: Maybe<MessengerMessage>;
  layout: MessengerChatLayout;
  listedAt: Scalars['Date']['output'];
  maxAllowedChatParticipants: Scalars['Int']['output'];
  messengerModerators?: Maybe<Array<Maybe<ResolvedParticipantUserUrn>>>;
  messengerParticipants?: Maybe<Array<Maybe<ResolvedParticipantUserUrn>>>;
  /** @deprecated use participants.id instead. WILL BE REMOVED ... */
  participantUserIds: Array<Maybe<Scalars['ID']['output']>>;
  /**
   * #rest(batched) #virtual
   * @deprecated Lacks support for Messenger's system users. Use messengerParticipants instead.
   */
  participants?: Maybe<Array<Maybe<XingId>>>;
  preview: MessengerChatPreview;
  subtitle: Scalars['String']['output'];
  topic: Scalars['String']['output'];
  touchedAt: Scalars['Date']['output'];
  type: MessengerChatType;
  unreadMessagesCount?: Maybe<Scalars['Int']['output']>;
};

export type MessengerChatAbilities = {
  __typename?: 'MessengerChatAbilities';
  canAddChatParticipants: Scalars['Boolean']['output'];
  canCreateAttachmentMessage: Scalars['Boolean']['output'];
  canCreateImageMessage: Scalars['Boolean']['output'];
  canCreateMessage: Scalars['Boolean']['output'];
  canDeleteChat: Scalars['Boolean']['output'];
  canDeleteChatParticipants: Scalars['Boolean']['output'];
  canEditChatTopic: Scalars['Boolean']['output'];
  canOpenChatDetails: Scalars['Boolean']['output'];
  canOpenSendCv: Scalars['Boolean']['output'];
  /** @deprecated this ability is not needed as this chat is no longer used */
  canOpenXingGuideSettings: Scalars['Boolean']['output'];
  canSeeComposeView: Scalars['Boolean']['output'];
  canUseMessageTemplates: Scalars['Boolean']['output'];
};

export type MessengerChatEdge = PaginationEdge & {
  __typename?: 'MessengerChatEdge';
  cursor: Scalars['String']['output'];
  node: MessengerChat;
};

export const enum MessengerChatLayout {
  Group = 'GROUP',
  OneOnOne = 'ONE_ON_ONE',
}

export type MessengerChatMessagesConnection = HasCursorPagination & {
  __typename?: 'MessengerChatMessagesConnection';
  edges: Array<Maybe<MessageEdge>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type MessengerChatPreview = {
  __typename?: 'MessengerChatPreview';
  sender: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export const enum MessengerChatType {
  BroadcastChat = 'BROADCAST_CHAT',
  GroupChat = 'GROUP_CHAT',
  KickedChat = 'KICKED_CHAT',
  LegacyChat = 'LEGACY_CHAT',
  OneOnOneChat = 'ONE_ON_ONE_CHAT',
  SecretOneOnOneChat = 'SECRET_ONE_ON_ONE_CHAT',
  SystemChat = 'SYSTEM_CHAT',
  XbpChat = 'XBP_CHAT',
  /** @deprecated this chat is no longer used */
  XingGuideChat = 'XING_GUIDE_CHAT',
  XtmChat = 'XTM_CHAT',
}

/** This type is just a stub. Real implementation will come later. */
export type MessengerChatUpdatedSystemMessagePayload = {
  __typename?: 'MessengerChatUpdatedSystemMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

export type MessengerChatsConnection = HasCursorPagination & {
  __typename?: 'MessengerChatsConnection';
  edges: Array<Maybe<MessengerChatEdge>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export const enum MessengerChatsFilterTypes {
  All = 'ALL',
  Recruiter = 'RECRUITER',
  Unread = 'UNREAD',
}

export type MessengerContact = {
  __typename?: 'MessengerContact';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  occupationOrg: Scalars['String']['output'];
  occupationTitle: Scalars['String']['output'];
  pageName: Scalars['String']['output'];
  photoUrls: MessengerImage;
};

/** This type is just a stub. Real implementation will come later. */
export type MessengerContactConfirmedSystemMessagePayload = {
  __typename?: 'MessengerContactConfirmedSystemMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

/** This type is just a stub. Real implementation will come later. */
export type MessengerContactRequestCreatedSystemMessagePayload = {
  __typename?: 'MessengerContactRequestCreatedSystemMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

/** This type is just a stub. Real implementation will come later. */
export type MessengerContactRequestDeclinedSystemMessagePayload = {
  __typename?: 'MessengerContactRequestDeclinedSystemMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

export type MessengerContactsConnection = HasCursorPagination & {
  __typename?: 'MessengerContactsConnection';
  edges?: Maybe<Array<Maybe<MessengerContactsEdge>>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type MessengerContactsEdge = PaginationEdge & {
  __typename?: 'MessengerContactsEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node?: Maybe<MessengerContact>;
};

/** It defines in which context a user might have gotten into the chat. */
export const enum MessengerContextId {
  Birthday = 'BIRTHDAY',
  ChnjCongrats = 'CHNJ_CONGRATS',
  ChnjGoodbye = 'CHNJ_GOODBYE',
  ChnjMeet = 'CHNJ_MEET',
  ChnjWelcome = 'CHNJ_WELCOME',
  ConversationStarter = 'CONVERSATION_STARTER',
  CoworkerMeet = 'COWORKER_MEET',
  CoworkerWelcome = 'COWORKER_WELCOME',
  Profile = 'PROFILE',
  RecruiterInterest = 'RECRUITER_INTEREST',
  RecruiterOfferjob = 'RECRUITER_OFFERJOB',
}

export type MessengerControls = {
  __typename?: 'MessengerControls';
  showMarkAllChatsAsReadPopup: Scalars['Boolean']['output'];
};

export type MessengerDeclineReasonQuickAction =
  MessengerQuickActionInterface & {
    __typename?: 'MessengerDeclineReasonQuickAction';
    body: Scalars['String']['output'];
    replyId: Scalars['String']['output'];
    textResourceId?: Maybe<Scalars['String']['output']>;
    type: Scalars['String']['output'];
  };

export type MessengerExecuteOneClickActionError = {
  __typename?: 'MessengerExecuteOneClickActionError';
  errors?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type MessengerExecuteOneClickActionInput = {
  /** The token provided by the backend holding the encoded action and params */
  token: Scalars['String']['input'];
};

export type MessengerExecuteOneClickActionResult =
  | MessengerExecuteOneClickActionError
  | MessengerExecuteOneClickActionSuccess;

export type MessengerExecuteOneClickActionSuccess = {
  __typename?: 'MessengerExecuteOneClickActionSuccess';
  result: MessengerExecuteOneClickActionSuccessPayload;
};

export type MessengerExecuteOneClickActionSuccessPayload =
  | CreateMessengerOneClickSystemReplyMessageSuccess
  | MessengerOneClickActionForwardToChat;

/** This type is just a stub. Real implementation will come later. */
export type MessengerHtmlMessagePayload = {
  __typename?: 'MessengerHTMLMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

export type MessengerImage = {
  __typename?: 'MessengerImage';
  size64x64: Scalars['URL']['output'];
  size96x96: Scalars['URL']['output'];
  size128x128: Scalars['URL']['output'];
  size192x192: Scalars['URL']['output'];
  size256x256: Scalars['URL']['output'];
};

export type MessengerImageMessagePayload = {
  __typename?: 'MessengerImageMessagePayload';
  /** @deprecated Moved to inside the originalImage field. Please use it instead. */
  filesize?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Moved to inside the originalImage field. Please use it instead. */
  height?: Maybe<Scalars['Int']['output']>;
  /**
   * #virtual
   * @deprecated Renamed. Use the scaledImages field please.
   */
  images?: Maybe<Array<ScaledImage>>;
  originalImage?: Maybe<MessengerOriginalImage>;
  /** #virtual */
  scaledImages?: Maybe<Array<ScaledImage>>;
  /** @deprecated Not needed anymore. Use the images field please. */
  uuid?: Maybe<Scalars['UUID']['output']>;
  /** @deprecated Moved to inside the originalImage field. Please use it instead. */
  width?: Maybe<Scalars['Int']['output']>;
};

export type MessengerImageMessagePayloadImagesArgs = {
  dimensions: Array<ScaledImageDimension>;
};

export type MessengerImageMessagePayloadScaledImagesArgs = {
  dimensions: Array<ScaledImageDimension>;
};

export type MessengerJobExpose = {
  __typename?: 'MessengerJobExpose';
  /** #rest(batched) #virtual */
  job: VisibleJob;
  matches: Array<ExposeMatch>;
};

export type MessengerMessage = {
  __typename?: 'MessengerMessage';
  author?: Maybe<ResolvedParticipantUserUrn>;
  chatId: Scalars['ID']['output'];
  /** A unique identifier generated by the client application in order to avoid creating duplicates in retry mechanisms */
  clientId?: Maybe<Scalars['UUID']['output']>;
  contextId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  fallbackBody?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initiatorUrn?: Maybe<Scalars['URN']['output']>;
  isMyMessage?: Maybe<Scalars['Boolean']['output']>;
  messageStatus?: Maybe<Scalars['String']['output']>;
  payload: MessengerMessagePayload;
  preview?: Maybe<MessengerMessagePreview>;
  read: Scalars['Boolean']['output'];
  referenceUrn?: Maybe<Scalars['URN']['output']>;
  replyId?: Maybe<Scalars['String']['output']>;
  /**
   * #rest(batched) #virtual
   * @deprecated Lacks support for Messenger's system users. Use author instead.
   */
  sender?: Maybe<XingId>;
  type: MessageType;
  urn: Scalars['GlobalID']['output'];
  userMessageStatusId?: Maybe<Scalars['ID']['output']>;
};

export type MessengerMessageObject =
  | MessengerJobExpose
  | UnauthorizedJob
  | VisibleJob
  | XingId;

export type MessengerMessagePayload =
  | MessengerAttachmentMessagePayload
  | MessengerAutoDeclinedMessagePayload
  | MessengerChatUpdatedSystemMessagePayload
  | MessengerContactConfirmedSystemMessagePayload
  | MessengerContactRequestCreatedSystemMessagePayload
  | MessengerContactRequestDeclinedSystemMessagePayload
  | MessengerHtmlMessagePayload
  | MessengerImageMessagePayload
  | MessengerObjectMessagePayload
  | MessengerParticipantAddedSystemMessagePayload
  | MessengerParticipantBirthdaySystemMessagePayload
  | MessengerParticipantJoinedSystemMessagePayload
  | MessengerParticipantKickedSystemMessagePayload
  | MessengerParticipantLeftSystemMessagePayload
  | MessengerPositionShareMessagePayload
  | MessengerPreviewMessagePayload
  | MessengerSecretMessagePayload
  | MessengerSystemReplyMessagePayload
  | MessengerTextLocalisedSystemMessagePayload
  | MessengerTextMessagePayload;

export type MessengerMessagePreview = {
  __typename?: 'MessengerMessagePreview';
  sender?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type MessengerMessageSearchConnection = HasCursorPagination & {
  __typename?: 'MessengerMessageSearchConnection';
  edges?: Maybe<Array<MessengerMessageSearchEdge>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type MessengerMessageSearchEdge = PaginationEdge & {
  __typename?: 'MessengerMessageSearchEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node?: Maybe<MessengerMessageSearchResult>;
};

export type MessengerMessageSearchFiltersInput = {
  lastSeen?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MessengerMessageSearchQueryInput = {
  /** Request consumer, used for tracking and analysis purposes */
  consumer: Scalars['String']['input'];
  filters?: InputMaybe<MessengerMessageSearchFiltersInput>;
  /** Params expected for highlight input are documented in HighlightInput field */
  highlight?: InputMaybe<HighlightInput>;
  /** Free text search for keywords */
  keywords?: InputMaybe<Scalars['String']['input']>;
  /** timeout for Elasticsearch call */
  timeout?: InputMaybe<Scalars['Int']['input']>;
};

export type MessengerMessageSearchResult = {
  __typename?: 'MessengerMessageSearchResult';
  highlight?: Maybe<HighlightResultMessengerMessage>;
  /** #rest(batched) #virtual */
  message?: Maybe<MessengerMessage>;
  position: Scalars['Int']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  trackingToken: Scalars['String']['output'];
};

export type MessengerMessageTemplates = {
  __typename?: 'MessengerMessageTemplates';
  /** #virtual */
  maxAllowedMessageTemplates: Scalars['Int']['output'];
  /** #expand */
  templates?: Maybe<Array<Maybe<MessengerMessageTemplatesInterface>>>;
  /** #virtual */
  total: Scalars['Int']['output'];
};

export type MessengerMessageTemplatesInterface = {
  body: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  /** used only for tracking purposes */
  topic: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type MessengerMessageTextTemplate =
  MessengerMessageTemplatesInterface & {
    __typename?: 'MessengerMessageTextTemplate';
    body: Scalars['String']['output'];
    createdAt?: Maybe<Scalars['Date']['output']>;
    id: Scalars['ID']['output'];
    title: Scalars['String']['output'];
    /** used only for tracking purposes */
    topic: Scalars['String']['output'];
    type: Scalars['String']['output'];
  };

export type MessengerMessageXingTemplate =
  MessengerMessageTemplatesInterface & {
    __typename?: 'MessengerMessageXingTemplate';
    body: Scalars['String']['output'];
    createdAt?: Maybe<Scalars['Date']['output']>;
    id: Scalars['ID']['output'];
    title: Scalars['String']['output'];
    /** used only for tracking purposes */
    topic: Scalars['String']['output'];
    type: Scalars['String']['output'];
  };

/** This type represents the actionable chat object message type. It resolves a union for object and actions. */
export type MessengerObjectMessagePayload = {
  __typename?: 'MessengerObjectMessagePayload';
  /** #rest #virtual */
  actions?: Maybe<Array<Maybe<ObjectActions>>>;
  /** #rest(batched) #virtual */
  object?: Maybe<MessengerMessageObject>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type MessengerOneClickActionForwardToChat = {
  __typename?: 'MessengerOneClickActionForwardToChat';
  chatId: Scalars['ID']['output'];
};

export type MessengerOpenProfileAction = ObjectActions & {
  __typename?: 'MessengerOpenProfileAction';
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type MessengerOriginalImage = {
  __typename?: 'MessengerOriginalImage';
  filesize?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type MessengerParticipant = MessengerUser | XingId;

/** This type is just a stub. Real implementation will come later. */
export type MessengerParticipantAddedSystemMessagePayload = {
  __typename?: 'MessengerParticipantAddedSystemMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

/** This type is just a stub. Real implementation will come later. */
export type MessengerParticipantBirthdaySystemMessagePayload = {
  __typename?: 'MessengerParticipantBirthdaySystemMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

export type MessengerParticipantJoinedSystemMessagePayload = {
  __typename?: 'MessengerParticipantJoinedSystemMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

/** This type is just a stub. Real implementation will come later. */
export type MessengerParticipantKickedSystemMessagePayload = {
  __typename?: 'MessengerParticipantKickedSystemMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

/** This type is just a stub. Real implementation will come later. */
export type MessengerParticipantLeftSystemMessagePayload = {
  __typename?: 'MessengerParticipantLeftSystemMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

/** This type is just a stub. Real implementation will come later. */
export type MessengerPositionShareMessagePayload = {
  __typename?: 'MessengerPositionShareMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

/** Link preview message type payload. */
export type MessengerPreviewMessagePayload = {
  __typename?: 'MessengerPreviewMessagePayload';
  /** @deprecated Use the fallbackBody field in the MessengerMessage instead. */
  body?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  scalableImageUrl?: Maybe<Scalars['String']['output']>;
  sourceDomain?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  trackingToken?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type MessengerQuickAction = MessengerQuickActionInterface & {
  __typename?: 'MessengerQuickAction';
  body: Scalars['String']['output'];
  textResourceId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type MessengerQuickActionInterface = {
  body: Scalars['String']['output'];
  textResourceId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type MessengerQuickActions = {
  __typename?: 'MessengerQuickActions';
  actions?: Maybe<Array<Maybe<MessengerQuickActionInterface>>>;
  actionsReason?: Maybe<Scalars['String']['output']>;
};

/** This type is just a stub. Real implementation will come later. */
export type MessengerSecretMessagePayload = {
  __typename?: 'MessengerSecretMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

/** Messenger related settings */
export type MessengerSettings = {
  __typename?: 'MessengerSettings';
  /** enable the auto decline for recruiter messages older than 10 days */
  autoDecline?: Maybe<ToggleSetting>;
  /** who can send you messages: [all_members, direct_contacts_only, contacts_of_your_contacts] */
  distanceForPm?: Maybe<SelectSetting>;
  /** enable your contacts to see if you have read their messages: true/false */
  readReceipt?: Maybe<ToggleSetting>;
};

export type MessengerSettingsInput = {
  autoDecline?: InputMaybe<ToggleSettingInput>;
  distanceForPm?: InputMaybe<SelectSettingInput>;
  readReceipt?: InputMaybe<ToggleSettingInput>;
};

export type MessengerSettingsMutationResult = {
  __typename?: 'MessengerSettingsMutationResult';
  error?: Maybe<MessengerSettings>;
  response?: Maybe<MessengerSettings>;
};

export type MessengerSystemReplyMessagePayload = {
  __typename?: 'MessengerSystemReplyMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

export type MessengerSystemReplyQuickAction = MessengerQuickActionInterface & {
  __typename?: 'MessengerSystemReplyQuickAction';
  body: Scalars['String']['output'];
  replyId: Scalars['String']['output'];
  textResourceId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

/** This type is just a stub. Real implementation will come later. */
export type MessengerTextLocalisedSystemMessagePayload = {
  __typename?: 'MessengerTextLocalisedSystemMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
};

export type MessengerTextMessagePayload = {
  __typename?: 'MessengerTextMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type MessengerUploadRequestInput = {
  /** The application that this upload is intended for */
  application: UploadApplication;
  /** The chat intended for this file */
  chatId: Scalars['ID']['input'];
  /** The file name */
  fileName?: InputMaybe<Scalars['String']['input']>;
  /** The size of the uploaded file in bytes */
  fileSize: Scalars['Long']['input'];
  /** The MIME type of the file */
  fileType?: InputMaybe<Scalars['String']['input']>;
};

export type MessengerUploadResult = Upload | UploadRequestError;

export type MessengerUser = {
  __typename?: 'MessengerUser';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  profileImage?: Maybe<Array<Maybe<ProfileImage>>>;
};

export type MessengerUserProfileImageArgs = {
  size: Array<ProfileImageSize>;
};

export type MissingFieldsCollection = {
  __typename?: 'MissingFieldsCollection';
  collection?: Maybe<Array<Maybe<NeffiCardDataSectionField>>>;
};

export const enum MissingInfoItem {
  ApplicationProcess = 'APPLICATION_PROCESS',
  CompanyCulture = 'COMPANY_CULTURE',
  CompanyName = 'COMPANY_NAME',
  CompanySize = 'COMPANY_SIZE',
  Discipline = 'DISCIPLINE',
  EmployeeRating = 'EMPLOYEE_RATING',
  EmploymentType = 'EMPLOYMENT_TYPE',
  Industry = 'INDUSTRY',
  JobTitle = 'JOB_TITLE',
  Location = 'LOCATION',
  NeededSkills = 'NEEDED_SKILLS',
  RemoteOptions = 'REMOTE_OPTIONS',
  SalaryRange = 'SALARY_RANGE',
}

export type MobileOfferProduct = {
  __typename?: 'MobileOfferProduct';
  highlighted: Scalars['Boolean']['output'];
  productId: Scalars['String']['output'];
};

export type ModuleInterface = {
  active?: Maybe<Scalars['Boolean']['output']>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type ModuleSettingsInput = {
  collapsed: Scalars['Boolean']['input'];
  moduleName: Scalars['String']['input'];
};

export type MostVisitedCompaniesCollection = {
  __typename?: 'MostVisitedCompaniesCollection';
  /** #expand #logged-out #soft-logged-in */
  collection: Array<Maybe<MostVisitedCompany>>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export const enum MostVisitedCompaniesOrder {
  Random = 'RANDOM',
  VisitsCountDesc = 'VISITS_COUNT_DESC',
}

export type MostVisitedCompany = {
  __typename?: 'MostVisitedCompany';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  company?: Maybe<Company>;
};

export type MoveonArticle = {
  __typename?: 'MoveonArticle';
  articleId: Scalars['Int']['output'];
  /** #rest(batched) #virtual */
  content?: Maybe<ContentArticle>;
  /** #rest(batched) #virtual */
  socialInteraction?: Maybe<SocialInteractionTarget>;
};

export type MoveonArticleRecommendation = {
  __typename?: 'MoveonArticleRecommendation';
  articleId: Scalars['Int']['output'];
};

export type MoveonArticleRecommendations = {
  __typename?: 'MoveonArticleRecommendations';
  collection: Array<MoveonArticleRecommendation>;
  total: Scalars['Int']['output'];
};

export type MoveonArticlesResult = {
  __typename?: 'MoveonArticlesResult';
  articles?: Maybe<Array<Maybe<MoveonArticle>>>;
};

export const enum MoveonAssessmentProcessingStatus {
  Completed = 'COMPLETED',
  Processing = 'PROCESSING',
  Queued = 'QUEUED',
}

export type MoveonCompanyCulturePreferencesAssessmentDataResult =
  | MoveonCompanyCulturePreferencesDataResultFailure
  | MoveonCompanyCulturePreferencesDataResultSuccess;

export type MoveonCompanyCulturePreferencesDataResultFailure = {
  __typename?: 'MoveonCompanyCulturePreferencesDataResultFailure';
  errors?: Maybe<Array<Maybe<MoveonCompanyCulturePreferencesResultError>>>;
};

export type MoveonCompanyCulturePreferencesDataResultSuccess = {
  __typename?: 'MoveonCompanyCulturePreferencesDataResultSuccess';
  assessmentId: Scalars['Int']['output'];
  companyRecommendationsFeedback?: Maybe<MoveonCompanyRecommendationsFeedback>;
  completedAt?: Maybe<Scalars['Date']['output']>;
  /** #rest #virtual */
  dashboard?: Maybe<MoveonDashboard>;
  negativeFactors?: Maybe<Array<Maybe<MoveonKununuFactor>>>;
  positiveFactors?: Maybe<Array<Maybe<MoveonKununuFactor>>>;
  processingStatus: MoveonAssessmentProcessingStatus;
  topNegativeFactors?: Maybe<Array<Maybe<MoveonKununuFactor>>>;
  topPositiveFactors?: Maybe<Array<Maybe<MoveonKununuFactor>>>;
  userId: Scalars['Int']['output'];
};

export type MoveonCompanyCulturePreferencesResultError = {
  __typename?: 'MoveonCompanyCulturePreferencesResultError';
  detail?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type MoveonCompanyRecommendation = {
  __typename?: 'MoveonCompanyRecommendation';
  /** #rest(batched) #virtual */
  company?: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  matchRating: MoveonCompanyRecommendationMatchRating;
  numberOfSubmissions: Scalars['Int']['output'];
  score: Scalars['Float']['output'];
  topPositiveFactorsMatch?: Maybe<Array<Maybe<MoveonFactorMatchingCompany>>>;
};

export type MoveonCompanyRecommendationEdge = PaginationEdge & {
  __typename?: 'MoveonCompanyRecommendationEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node?: Maybe<MoveonCompanyRecommendation>;
};

export const enum MoveonCompanyRecommendationMatchRating {
  Excellent = 'EXCELLENT',
  Good = 'GOOD',
  Ok = 'OK',
}

export type MoveonCompanyRecommendations = {
  __typename?: 'MoveonCompanyRecommendations';
  collection?: Maybe<Array<Maybe<MoveonCompanyRecommendation>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type MoveonCompanyRecommendationsConnection = HasCursorPagination & {
  __typename?: 'MoveonCompanyRecommendationsConnection';
  edges: Array<Maybe<MoveonCompanyRecommendationEdge>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export const enum MoveonCompanyRecommendationsFeedback {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE',
  Unknown = 'UNKNOWN',
}

export type MoveonCompanyRecommendationsFeedbackInput = {
  feedback?: InputMaybe<MoveonCompanyRecommendationsFeedback>;
};

export type MoveonCompanyRecommendationsJobSearch = {
  __typename?: 'MoveonCompanyRecommendationsJobSearch';
  companyIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** #rest #virtual */
  jobSearchResult?: Maybe<JobSearchResult>;
};

export type MoveonCreateAssessmentInput = {
  negativeFactors: Array<Scalars['String']['input']>;
  positiveFactors: Array<Scalars['String']['input']>;
  topNegativeFactors: Array<Scalars['String']['input']>;
  topPositiveFactors: Array<Scalars['String']['input']>;
};

export type MoveonCreateAssessmentResult =
  | MoveonCreateAssessmentResultFailure
  | MoveonCreateAssessmentResultSuccess;

export type MoveonCreateAssessmentResultError = {
  __typename?: 'MoveonCreateAssessmentResultError';
  detail?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type MoveonCreateAssessmentResultFailure = {
  __typename?: 'MoveonCreateAssessmentResultFailure';
  errors?: Maybe<Array<Maybe<MoveonCreateAssessmentResultError>>>;
};

export type MoveonCreateAssessmentResultSuccess = {
  __typename?: 'MoveonCreateAssessmentResultSuccess';
  id: Scalars['Int']['output'];
};

export type MoveonDashboard = {
  __typename?: 'MoveonDashboard';
  /** @deprecated It will be replaced with query out of viewer */
  articleRecommendations?: Maybe<MoveonArticleRecommendations>;
  /** @deprecated It will be replaced with paginated resolver */
  companyRecommendations?: Maybe<MoveonCompanyRecommendations>;
  dimensionTendencyScores?: Maybe<MoveonDimensionScores>;
  tendencyCategory?: Maybe<MoveonUserTendencyCategory>;
  tendencyScore?: Maybe<Scalars['Float']['output']>;
};

export type MoveonDimensionScores = {
  __typename?: 'MoveonDimensionScores';
  leadership: Scalars['Float']['output'];
  strategicDirection: Scalars['Float']['output'];
  workLife: Scalars['Float']['output'];
  workingTogether: Scalars['Float']['output'];
};

export type MoveonFactorMatchingCompany = {
  __typename?: 'MoveonFactorMatchingCompany';
  key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type MoveonKununuFactor = {
  __typename?: 'MoveonKununuFactor';
  cultureId: Scalars['Int']['output'];
  cultureTranslationCode: Scalars['String']['output'];
  dimensionId: Scalars['Int']['output'];
  dimensionTranslationCode: Scalars['String']['output'];
  factorId: Scalars['Int']['output'];
  factorTranslationCode: Scalars['String']['output'];
  traditional: Scalars['Boolean']['output'];
};

export type MoveonUpdatedAssessmentResult = {
  __typename?: 'MoveonUpdatedAssessmentResult';
  assessmentId: Scalars['Int']['output'];
  companyRecommendationsFeedback?: Maybe<MoveonCompanyRecommendationsFeedback>;
};

export const enum MoveonUserTendencyCategory {
  Modern = 'MODERN',
  Neutral = 'NEUTRAL',
  Traditional = 'TRADITIONAL',
}

/** The root mutation type */
export type Mutation = {
  __typename?: 'Mutation';
  /**
   * #rest #virtual
   *
   *  Creates a new Accomplishment (Award or Qualification)
   */
  accomplishmentCreate?: Maybe<AccomplishmentCreateResult>;
  /**
   * #rest #virtual
   *
   *  Deletes an Accomplishment (Award or Qualification)
   */
  accomplishmentDelete?: Maybe<AccomplishmentMutationError>;
  /**
   * #rest #virtual
   *
   *  Updates an Accomplishment (Award or Qualification)
   */
  accomplishmentUpdate?: Maybe<AccomplishmentUpdateResult>;
  /**
   * #rest #virtual
   *
   *  Activate two-factor authentication
   */
  activateTwoFaSettings?: Maybe<TwoFaSettingsActivationResult>;
  /**
   * #rest #virtual
   *
   *  Marks ad as not interesting to user.
   */
  adNegativeFeedbackCreate?: Maybe<AdNegativeFeedbackError>;
  /**
   * #rest #virtual
   *
   *  Adds exclusions for contact data sharing settings.
   */
  addDataSharingExclusion?: Maybe<DataSharingExclusionMutationResult>;
  /**
   * #rest #virtual
   *
   *  Add the specified participants to a chat
   */
  addMessengerChatParticipants?: Maybe<AddMessengerChatParticipantsResult>;
  /**
   * #rest #virtual
   *
   *  This mutation tracks a click for an ad
   */
  adsTrackClick?: Maybe<AdsTrackingResult>;
  /**
   * #rest #virtual
   *
   *  This mutation tracks an impression for an ad
   */
  adsTrackImpression?: Maybe<AdsTrackingResult>;
  /**
   * #rest #virtual
   *
   *  Create an article
   */
  articlesCreateArticle?: Maybe<ArticlesSaveArticleResult>;
  /**
   * #rest #virtual
   *
   *  Create an article
   */
  articlesCreateArticleImage?: Maybe<ArticlesCreateArticleImageResult>;
  /**
   * #rest #virtual
   *
   *  Delete an article
   */
  articlesDeleteArticle?: Maybe<ArticlesDeleteArticleResult>;
  /**
   * #rest #virtual
   *
   *  Publish an article
   */
  articlesPublishArticle?: Maybe<ArticlesPublishArticleResult>;
  /**
   * #rest #virtual
   *
   *  Unpublish an article
   */
  articlesUnpublishArticle?: Maybe<ArticlesUnpublishArticleResult>;
  /**
   * #rest #virtual
   *
   *  Update article
   */
  articlesUpdateArticle?: Maybe<ArticlesSaveArticleResult>;
  /**
   * #rest #virtual
   *
   *  Update SEO settings, bypassing the locking mechanism
   */
  articlesUpdateArticleSettings?: Maybe<ArticlesSaveArticleResult>;
  /** Set Insights banner property value as viewed */
  bannerPropertyDisable?: Maybe<BannerPropertyDisableResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the list of companies the user does not want to see their preferences
   */
  blockedCompanies?: Maybe<UpdateBlockedCompaniesResult>;
  /**
   * #rest #virtual
   *
   *  Blocks an entity by urn
   */
  blockingServiceBlock?: Maybe<BlockingServiceBlockResult>;
  /**
   * #rest #virtual
   *
   *  Unblocks an entity by urn
   */
  blockingServiceUnblock?: Maybe<BlockingServiceUnblockResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Triggers updating the user/visitor career level preference
   */
  careerLevelPreference?: Maybe<UpdateCareerLevelPreferenceResult>;
  /**
   * #rest #virtual
   *
   *  Change owner of a Xing TalentService (XTS) briefing
   */
  changeErecruitingSourcingServiceBriefingOwner?: Maybe<ErecruitingSourcingServiceChangeBriefingOwnerError>;
  /**
   * #rest #virtual
   *
   *  This mutation adds a collection of company affiliates to the current company
   */
  companyCreateAffiliates?: Maybe<CompanyAffiliatesMutationResult>;
  /**
   * #rest #virtual
   *
   *  Creates a feed of the company. Current user must be editor of the company.
   */
  companyCreateFeed?: Maybe<CompanyCreateFeedResult>;
  /**
   * #rest #virtual
   *
   *  Deletes a feed of the company.
   * Current user must be editor of the company, and feed with given id and type
   * must belong to editor's company
   */
  companyDeleteFeed?: Maybe<CompanyDeleteFeedResult>;
  /**
   * #rest #virtual
   *
   *  This mutation adds the current user as follower to the specified company
   */
  companyFollow?: Maybe<FollowersMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create a new company page, both on pages and companies and return the entity page id.
   */
  companyPageCreate?: Maybe<CompanyPageCreatePayload>;
  /**
   * #rest #virtual
   *
   *  This mutation removes the current user as follower to the specified company
   */
  companyUnfollow?: Maybe<FollowersMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update company information
   */
  companyUpdateInformation?: Maybe<CompanyUpdateInformationPayload>;
  /**
   * #rest #virtual
   *
   *  Deletes an article of the company.
   * Current user must be editor of the company
   */
  companyUpdatesDelete?: Maybe<CompanyUpdatesDeleteResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Send a complaint report
   */
  complaintsCreateContentReport?: Maybe<ComplaintsCreateContentReportResult>;
  /**
   * #rest #virtual
   *
   *  Send a profile report
   */
  complaintsCreateProfileReport?: Maybe<ComplaintsCreateProfileReportResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Complete a complaint report
   */
  completeComplaintReport?: Maybe<CompleteReportResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Confirm email for unauthenticated report
   */
  confirmEmailReport?: Maybe<ConfirmEmailReportResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Confirms a token belonging to a logged out search alert.
   */
  confirmLOSearchAlert?: Maybe<LoSearchAlertResponse>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Send contact form to support team
   */
  contactSupport?: Maybe<ContactPostResult>;
  /**
   * #rest #virtual
   *
   *  Bookmark something bookmarkable
   */
  contentBookmarkableBookmark?: Maybe<ContentBookmarkableMutationResult>;
  /**
   * #rest #virtual
   *
   *  Unbookmark something bookmarkable
   */
  contentBookmarkableUnbookmark?: Maybe<ContentBookmarkableMutationResult>;
  /**
   * #rest #virtual
   *
   *  Cancel content subscription
   */
  contentCancelSubscription?: Maybe<ContentCancelSubscriptionResult>;
  /**
   * #rest #virtual
   *
   *  Create a new comment for commentable
   */
  contentCommentCreate?: Maybe<ContentCommentMutationResult>;
  /**
   * #rest #virtual
   *
   *  Hide a comment
   */
  contentCommentHide?: Maybe<ContentCommentMutationResult>;
  /**
   * #rest #virtual
   *
   *  Delete an article
   */
  contentDeleteArticle?: Maybe<ContentArticleDeletionResult>;
  /**
   * #rest #virtual
   *
   *  Disable an article
   */
  contentDisableArticle?: Maybe<ContentArticleMutationResult>;
  /**
   * #rest #virtual
   *
   *  Enable an article
   */
  contentEnableArticle?: Maybe<ContentArticleMutationResult>;
  /**
   * #rest #virtual
   *
   *  Feature an article
   */
  contentFeatureArticle?: Maybe<ContentArticleMutationResult>;
  /**
   * #rest #virtual
   *
   *  Set lastFollowersSeenAt to the current time
   */
  contentInsiderPageLastFollowersSeen?: Maybe<ContentInsiderPageLastFollowersSeenResult>;
  /**
   * #rest #virtual
   *
   *  Agree with Klartext resource
   */
  contentKlartextAgree?: Maybe<ContentKlartextAgreeResult>;
  /**
   * #rest #virtual
   *
   *  Unagree with Klartext resource
   */
  contentKlartextUnagree?: Maybe<ContentKlartextUnagreeResult>;
  /**
   * #rest #virtual
   *
   *  Follow page with current user
   */
  contentPageFollow?: Maybe<ContentPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Unfollow page with current user
   */
  contentPageUnfollow?: Maybe<ContentPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Creates generic content
   */
  contentServiceCreateContent?: Maybe<ContentServiceSaveContentResult>;
  /**
   * #rest #virtual
   *
   *  Updates generic content
   */
  contentServiceUpdateContent?: Maybe<ContentServiceSaveContentResult>;
  /**
   * #rest #virtual
   *
   *  Star something starable
   */
  contentStarableStar?: Maybe<ContentStarableMutationResult>;
  /**
   * #rest #virtual
   *
   *  Unstar something starable
   */
  contentStarableUnstar?: Maybe<ContentStarableMutationResult>;
  /**
   * #rest #virtual
   *
   *  Unfeature an article
   */
  contentUnfeatureArticle?: Maybe<ContentArticleMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update email subscription
   */
  contentUpdateEmailSubscription?: Maybe<ContentUpdateEmailSubscriptionPayload>;
  /**
   * #rest #virtual
   *
   *  Update page description
   */
  contentUpdatePage?: Maybe<ContentPageMutationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Create a parsed cv for a given upload id
   */
  createCvUploadParsedCv?: Maybe<CreateCvUploadParsedCvResult>;
  /**
   * #rest #virtual
   *
   *  Create a parsed CV for a given user ID
   */
  createCvUploadParsedCvByUserId?: Maybe<CreateCvUploadParsedCvResult>;
  /**
   * #rest #virtual
   *
   *  Mutation to creating document from imported data
   * The response - VitaDocument type
   */
  createDocumentFromProfileImport?: Maybe<CreateVitaDocumentResult>;
  /**
   * #rest #virtual
   *
   *  Create a Xing Sourcing Service (XSS) briefing
   */
  createErecruitingSourcingServiceBriefing?: Maybe<ErecruitingSourcingServiceMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create a Xing TalentService (XTS) Briefing Candidate
   */
  createErecruitingSourcingServiceBriefingCandidate?: Maybe<ErecruitingSourcingServiceBriefingCandidateMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create a XTS briefing report
   */
  createErecruitingSourcingServiceBriefingReport?: Maybe<ErecruitingSourcingServiceBriefingReportCreateResult>;
  /**
   * #rest #virtual
   *
   *  Create a Xing Talent Service (XTS) Candidate Note By Consultant
   */
  createErecruitingSourcingServiceCandidateNote?: Maybe<ErecruitingSourcingServiceCandidateNoteMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create a Xing Talent Service (XTS) Candidate Note By Client
   */
  createErecruitingSourcingServiceClientCandidateNote?: Maybe<ErecruitingSourcingServiceCandidateNoteMutationResult>;
  /**
   * #rest #virtual
   *
   *  Creates an attachment message in a chat
   */
  createMessengerAttachmentMessage?: Maybe<CreateMessengerAttachmentMessageResult>;
  /**
   * #rest #virtual
   *
   *  Creates a chat
   */
  createMessengerChat?: Maybe<CreateMessengerChatResult>;
  /**
   * #rest #virtual
   *
   *  Creates a chat by Slug or ID
   */
  createMessengerChatBySlugOrID?: Maybe<CreateMessengerChatResult>;
  /**
   * #rest #virtual
   *
   *  Creates an image message in a chat
   */
  createMessengerImageMessage?: Maybe<CreateMessengerImageMessageResult>;
  /**
   * #rest #virtual
   *
   *  Creates a message in a chat
   */
  createMessengerMessage?: Maybe<CreateMessengerMessageResult>;
  /**
   * #rest #virtual
   *
   *  Create a template
   */
  createMessengerMessageTemplate?: Maybe<CreateMessengerMessageTemplateResult>;
  /**
   * #rest #virtual
   *
   *  Creates a system reply message in a chat
   */
  createMessengerSystemReplyMessage?: Maybe<CreateMessengerSystemReplyMessageResult>;
  /**
   * #rest #virtual
   *
   *  Create password protected token
   */
  createPasswordProtectedSettingToken?: Maybe<PasswordProtectedSettingTokenMutationResult>;
  /**
   * #rest #virtual
   *
   *  Creates client for Payment & Billing
   */
  createPayBilClient?: Maybe<PayBilClientMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create payment method
   */
  createPaymentAccount?: Maybe<PaymentAccountMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create a search alert. You must have the query id from job-searches.
   */
  createSearchAlert?: Maybe<SearchAlertCreationResult>;
  /**
   * #rest #virtual
   *
   *  Create a search alert using a query.
   */
  createSearchAlertByQuery?: Maybe<SearchAlertCreationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Create a search alert for LO.
   */
  createSearchAlertForLO?: Maybe<SearchAlertForLoCreationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Mutation for creating users.
   */
  createUser?: Maybe<CreateUserMutationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Create a vita cover letter
   */
  createVitaCoverLetter?: Maybe<CreateVitaDocumentResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Create a vita document
   */
  createVitaDocument?: Maybe<CreateVitaDocumentResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Create a web push subscription
   */
  createWebPushSubscription?: Maybe<CreateWebPushSubscriptionResult>;
  /**
   * #rest #virtual
   *
   *  Create a workExperience for the profile
   */
  createWorkExperience?: Maybe<WorkExperienceError>;
  /**
   * #rest #virtual
   *
   *  Send Data Science tracking compact format
   * @deprecated use odt tracking instead https://operational-tracking.xing.io/
   */
  dataScienceTrackingCompactFormat?: Maybe<TrackingResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Send Data Science tracking full format
   * @deprecated use odt tracking instead https://operational-tracking.xing.io/
   */
  dataScienceTrackingFullFormat?: Maybe<TrackingResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Triggers deleting the user/visitor career level preference
   */
  deleteCareerLevelPreference?: Maybe<DeleteCareerLevelPreferenceResult>;
  /**
   * #rest #virtual
   *
   *  Remove a Company from the CompanyRecommendations list
   */
  deleteCompanyRecommendation?: Maybe<DeleteCompanyRecommendationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Delete a parsed CV
   */
  deleteCvUploadParsedCv?: Maybe<CvUploadError>;
  /**
   * #rest #virtual
   *
   *  Removes exclusions for contact data sharing settings.
   */
  deleteDataSharingExclusion?: Maybe<DataSharingExclusionMutationResult>;
  /**
   * #rest #virtual
   *
   *  Delete a Xing TalentService (XTS) Briefing Candidate
   */
  deleteErecruitingSourcingServiceBriefingCandidate?: Maybe<ErecruitingSourcingServiceDeleteBriefingCandidateMutationResult>;
  /**
   * #rest #virtual
   *
   *  Delete a Xing TalentService (XTS) message reminder
   */
  deleteErecruitingSourcingServicemessageReminder?: Maybe<ErecruitingSourcingServiceDeleteBriefingCandidateMutationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Triggers deleting the user/visitor locations preference
   */
  deleteLocationsPreference?: Maybe<DeleteLocationsPreferenceResult>;
  /**
   * #rest #virtual
   *
   *  Delete login session (Web)
   */
  deleteLoginSession?: Maybe<DeleteLoginSessionPayload>;
  /**
   * #rest #virtual
   *
   *  Deletes a chat for user
   */
  deleteMessengerChat?: Maybe<DeleteMessengerChatResult>;
  /**
   * #rest #virtual
   *
   *  Deletes the specified participant from a chat
   */
  deleteMessengerChatParticipant?: Maybe<DeleteMessengerChatParticipantResult>;
  /**
   * #rest #virtual
   *
   *  Delete a template
   */
  deleteMessengerMessageTemplate?: Maybe<DeleteMessengerMessageTemplateResult>;
  /**
   * #rest #virtual
   *
   *  Delete pending email, in case user wants to abort email change procedure
   */
  deletePendingEmail?: Maybe<DeletePendingEmailPayload>;
  /**
   * #rest #virtual
   *
   *  Delete pending phone, in case user wants to abort phone change procedure
   */
  deletePendingPhone?: Maybe<DeletePendingPhonePayload>;
  /**
   * #rest #virtual
   *
   *  Delete the permissions between a User and a Resource
   */
  deleteResourceUser?: Maybe<TeamPermissionsDeleteMutationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Triggers deleting the user/visitor salary expectations preference
   */
  deleteSalaryExpectationsPreference?: Maybe<DeleteSalaryExpectationsPreferenceResult>;
  /**
   * #rest #virtual
   *
   *  This mutation deletes a scheduled message
   */
  deleteScheduledMessage?: Maybe<ScheduledMessageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Delete a search alert using its id.
   */
  deleteSearchAlert?: Maybe<EmptyOrErrorResponse>;
  /**
   * #rest #virtual
   *
   *  Warning: XWS sessions are app scoped, deleting them will log the user out on all devices
   */
  deleteXwsSession?: Maybe<DeleteXwsSessionPayload>;
  /**
   * #rest #virtual
   *
   *  Discards a candidate from the long list recommendation (XTM)
   */
  discardErecruitingSourcingServiceCandidateRecommendation?: Maybe<ErecruitingSourcingServiceCandidateRecommendationDiscardMutationResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the user's disciplines preference
   */
  disciplinesPreference?: Maybe<UpdateDisciplinesPreferenceResult>;
  /**
   * #rest #virtual
   *
   *  Delete Activity
   */
  discoDeleteActivity?: Maybe<DiscoDeleteActivityResult>;
  /**
   * #rest #virtual
   *
   *  Logging discarded items
   */
  discoLogging?: Maybe<DiscoLogging>;
  /**
   * #rest #virtual
   *
   *  Dismisses the company -> entity page auto-migration notification
   * for the provided user and company
   */
  dismissCompanyAutoMigrationNotification?: Maybe<DismissCompanyAutoNotificationResult>;
  /**
   * Dummy mutation field
   * @deprecated Makes GraphiQL happy
   */
  dummy?: Maybe<Scalars['String']['output']>;
  /**
   * #rest #virtual
   *
   *  Save Order Company Details
   */
  ecommCompanyDetails?: Maybe<EcommCompanyDetails>;
  /**
   * #rest #virtual
   *
   *  Confirm Order
   */
  ecommConfirmOrder?: Maybe<EcommConfirmOrder>;
  /**
   * #rest #virtual
   *
   *  Save Order Contact Details
   */
  ecommContactDetails?: Maybe<EcommContactDetails>;
  /**
   * #rest #virtual
   *
   *  Create or Update Ecomm Order
   */
  ecommOrder?: Maybe<EcommOrder>;
  /**
   * #rest #virtual
   *
   *  Set paymentStatus
   */
  ecommPaymentStatus?: Maybe<EcommPaymentStatus>;
  /**
   * #rest #virtual
   *
   *  Delete User Connections
   */
  ecommUserConnectionsData?: Maybe<DeletedUserConnectionData>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the user's prefered employers
   */
  employersPreference?: Maybe<UpdateEmployersPreferenceResult>;
  /**
   * #rest #virtual
   *
   *  Create new Contact User
   */
  entityPageCreateContactUser?: Maybe<EntityPageContactUserMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create new Entity Document
   */
  entityPageCreateDocument?: Maybe<EntityPageDocumentMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create new Location
   */
  entityPageCreateLocation?: Maybe<EntityPageLocationMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create new Entity Media
   */
  entityPageCreateMedia?: Maybe<EntityPageMediaMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create new Entity Video Media
   */
  entityPageCreateVideoMedia?: Maybe<EntityPageVideoMediaMutationResult>;
  /**
   * #rest #virtual
   *
   *  Delete a given Entity Page
   */
  entityPageDelete?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Delete a Contact User
   */
  entityPageDeleteContactUser?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Delete Page Cover
   */
  entityPageDeleteCover?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Delete an Entity Document
   */
  entityPageDeleteDocument?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Delete Location
   */
  entityPageDeleteLocation?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Delete an Entity Media
   */
  entityPageDeleteMedia?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Follow a given Entity Page
   */
  entityPageFollow?: Maybe<FollowersMutationResult>;
  /**
   * #rest #virtual
   *
   *  Hard delete a given Entity Page with status created/draft
   */
  entityPageHardDelete?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update Entity Page Module configuration
   */
  entityPageModuleUpdateConfiguration?: Maybe<EntityPageModuleConfigUpdateResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Update Entity Page Visitor tracking
   */
  entityPageTrackVisitor?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  UnFollow a given Entity Page
   */
  entityPageUnfollow?: Maybe<FollowersMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update AboutUs content V2
   */
  entityPageUpdateAboutContent?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update Entity Page configuration
   */
  entityPageUpdateConfiguration?: Maybe<EntityPageConfigUpdateResult>;
  /**
   * #rest #virtual
   *
   *  Update Contact User
   */
  entityPageUpdateContactUser?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update Contact Users Catalogue
   */
  entityPageUpdateContactUsersCatalogue?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update Page Cover
   */
  entityPageUpdateCover?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update Entity Document
   */
  entityPageUpdateDocument?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update Location
   */
  entityPageUpdateLocation?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update Page Logo
   */
  entityPageUpdateLogo?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update Entity Media
   */
  entityPageUpdateMedia?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update Entity Video Media Gallery
   */
  entityPageUpdateMediaGallery?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update Entity Page publication status
   */
  entityPageUpdatePublicationStatus?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update Entity Media Video
   */
  entityPageUpdateVideoMedia?: Maybe<EntityPageMutationResult>;
  /**
   * #rest #virtual
   *
   *  Triggers deleting all data for a fake user
   */
  erecruitingSourcingServiceDeleteE2EUser?: Maybe<ErecruitingSourcingServiceE2EUsersDeleteResult>;
  /**
   * #rest #virtual
   *
   *  Update a Xing Sourcing Service (XSS) Briefing Candidate Status
   */
  erecruitingSourcingServiceUpdateBriefingCandidate?: Maybe<ErecruitingSourcingServiceBriefingCandidateUpdateResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Triggers creation for an external VOMP
   */
  externalProfileVisit?: Maybe<ExternalVompResult>;
  /**
   * #rest #virtual
   *
   *  This mutation disables New Notification Center for the current user
   */
  featureSwitchesDisableNewNotificationCenter?: Maybe<FeatureSwitchesNewNotificationCenterMutationResult>;
  /**
   * #rest #virtual
   *
   *  This mutation disables Vertical Navigation for the current user
   */
  featureSwitchesDisableVerticalNavigation?: Maybe<FeatureSwitchesVerticalNavigationMutationResult>;
  /**
   * #rest #virtual
   *
   *  This mutation enables New Notification Center for the current user
   */
  featureSwitchesEnableNewNotificationCenter?: Maybe<FeatureSwitchesNewNotificationCenterMutationResult>;
  /**
   * #rest #virtual
   *
   *  This mutation enables Vertical Navigation for the current user
   */
  featureSwitchesEnableVerticalNavigation?: Maybe<FeatureSwitchesVerticalNavigationMutationResult>;
  /**
   * #rest #virtual
   *
   *  Force variant for current user and given experiment name
   */
  featureSwitchesExperimentsForceVariant?: Maybe<FeatureSwitchesExperimentsForceVariantResult>;
  /**
   * #rest #virtual
   *
   *  Follow a given generic entity
   */
  followersFollow?: Maybe<FollowersMutationResult>;
  /**
   * #rest #virtual
   *
   *  Unfollow a given generic entity
   */
  followersUnfollow?: Maybe<FollowersMutationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Retrieve suggestion from LLM Service
   */
  generateVitaAboutMeSuggestion?: Maybe<LlmGatewayGenerateAboutMeResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Retrieve cover_letter suggestion from LLM Service
   */
  generateVitaCoverLetterSuggestion?: Maybe<LlmGatewayGenerateCoverLetterResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the user's industries preference
   */
  industriesPreference?: Maybe<UpdateIndustriesPreferenceResult>;
  /**
   * #rest #virtual
   *
   *  Triggers creation for an internal VOMP
   */
  internalProfileVisit?: Maybe<InternalVompResult>;
  /**
   * #rest #virtual
   *
   *  This mutation submits a job booking request
   */
  jobBookingRequest?: Maybe<JobBookingResult>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Create a job apply intention based on the current user and job
   *
   * ```
   * mutation JobCreateApplyIntention {
   *   jobCreateApplyIntention(input: { jobId: 1, platform: WEB }) {
   *     error {
   *       message
   *     }
   *   }
   * }
   * ```
   * @deprecated job-user-interest is deprecated. We now rely on NWT for these events.
   */
  jobCreateApplyIntention?: Maybe<JobUserApplyIntentionResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Creates a job bookmark based on the current user and the jobId
   *
   * ```
   * mutation JobCreateBookmark {
   *   jobCreateBookmark(input: { jobId: 1, source: "WEB" }) {
   *     error {
   *       message
   *     }
   *   }
   * }
   * ```
   * @deprecated Use jobUpsertBookmark with desired bookmark state
   */
  jobCreateBookmark?: Maybe<JobBookmarkMutationResult>;
  /**
   * #rest #virtual
   *
   *  Creates a job report based on the current user and the jobId
   *
   * ```
   * mutation JobCreateReport {
   *   jobCreateReport(input: { jobId: "2.24d1f6", reason: NO_JOB_AD, wrongFields: [LOCATION, COMPANY_NAME], comment: "Nice comment"}) {
   *     job {
   *       __typename
   *       ...on VisibleJob {
   *         id
   *         title
   *       }
   *     }
   *     error {
   *       message
   *     }
   *   }
   * }
   * ```
   */
  jobCreateReport?: Maybe<JobCreateReportResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Deletes a job bookmark based on the current user and the jobId
   *
   * ```
   * mutation JobDeleteBookmark {
   *   jobDeleteBookmark(input: { jobId: 1, source: "MOBILE"}) {
   *     error {
   *       message
   *     }
   *   }
   * }
   * ```
   */
  jobDeleteBookmark?: Maybe<JobBookmarkMutationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Deletes a job visit
   */
  jobDeleteVisit?: Maybe<JobDeleteVisitError>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the job preferences user's flags
   */
  jobPreferenceUserFlags?: Maybe<UpdateJobPreferenceUserFlagsResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the user's job seeker status
   */
  jobPreferencesJobSeekerStatus?: Maybe<UpdateJobPreferencesJobSeekerStatusResult>;
  /**
   * #rest #virtual
   *
   *  Decline job recommendation
   */
  jobRecoDeclineRecommendation?: Maybe<JobRecoDeclineRecommendationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Registers a job visit
   */
  jobRegisterVisit?: Maybe<JobRegisterVisitResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating JobSeeker Guidance Action
   */
  jobSeekerGuidanceUpdateAction?: Maybe<JobSeekerGuidanceUpdateActionResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This mutation submits a job application for the current user to a certain job
   */
  jobSubmitJobApplication?: Maybe<JobApplicationMutationResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the user's target positions
   */
  jobTitlesTargetPositions?: Maybe<UpdateJobTitlesTargetPositionsPreferenceResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the user's unpreferred positions
   */
  jobTitlesUnpreferredPositions?: Maybe<UpdateJobTitlesUnpreferredPositionsPreferenceResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Creates or updates a bookmark for the input jobId
   *
   * ```
   * mutation JobUpsertBookmark {
   *   jobUpsertBookmark(input: { jobId: 1, source: "WEB", state: "INTERVIEW_SET" }) {
   *     error {
   *       message
   *     }
   *   }
   * }
   * ```
   */
  jobUpsertBookmark?: Maybe<JobBookmarkUpsertMutationResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the user's ideal employers
   */
  jobWishesIdealEmployers?: Maybe<UpdateJobWishesIdealEmployersResult>;
  /**
   * #rest #virtual
   *
   *  Triggers an update on user job seeker job wishes
   */
  jobWishesPreference?: Maybe<PreferencesJobWishesResult>;
  /**
   * #rest #virtual
   *
   *  Delete all push recommendations based on the current user.
   *
   * ```
   * mutation JobsDeleteRecommendations {
   *   jobsDeleteRecommendations {
   *     error {
   *       message
   *     }
   *   }
   * }
   * ```
   */
  jobsDeleteRecommendations?: Maybe<JobsDeleteRecommendationsResult>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Mark jobs push recommendations as seen based on the current user.
   *
   * ```
   * mutation JobsMarkRecommendationsAsSeen {
   *   jobsMarkRecommendationsAsSeen(input: { jobIds: [1, 2, 3] } ) {
   *     error {
   *       message
   *     }
   *   }
   * }
   * ```
   */
  jobsMarkRecommendationsAsSeen?: Maybe<JobsMarkRecommendationsAsSeenResult>;
  /**
   * #rest #virtual
   *
   *  This mutation creates a FormSubmission for a given LeadAdForm
   */
  leadAdFormSubmissionCreate?: Maybe<LeadAdFormSubmissionCreateResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Send llm-gateway interaction tracking
   */
  llmGatewayInteractionTracking?: Maybe<LlmGatewayInteractionTrackingResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Triggers updating the user's locations preference
   */
  locationsPreference?: Maybe<UpdateLocationsPreferenceResult>;
  /**
   * #rest #virtual
   *
   *  This mutation acknowledges a security issue, typically when a notification is dismissed by the user
   */
  loginUserSecurityIssuesAcknowledge?: Maybe<LoginUserSecurityIssuesAcknowledgeMutationResult>;
  /**
   * #rest #virtual
   *
   *  Marks all chats as read
   */
  markAllChatsAsRead?: Maybe<MarkAllChatsAsReadResult>;
  /**
   * #rest #virtual
   *
   *  Marks the 'Mark-all-as-read' popup as shown
   */
  markAllChatsAsReadPopupAsShown?: Maybe<MarkAllChatsAsReadPopupAsShownResult>;
  /**
   * #rest #virtual
   *
   *  Marks a chat as read
   */
  markAsReadMessengerChat?: Maybe<MarkAsReadMessengerChatResult>;
  /**
   * #rest #virtual
   *
   *  Mark object as seen
   */
  markAsSeen?: Maybe<MarkAsSeenMutationResult>;
  /**
   * #rest #virtual
   *
   *  Mark a chat as unread
   */
  markAsUnreadMessengerChat?: Maybe<MarkAsUnreadMessengerChatResult>;
  /**
   * #preview #rest #virtual
   *
   *  Add attendee to meeting room (hackweek project)
   */
  meetingRoomAddAttendee?: Maybe<MeetingRoomAddAttendeeResult>;
  /**
   * #rest #virtual
   *
   *  Executes a one-click-action
   */
  messengerExecuteOneClickAction?: Maybe<MessengerExecuteOneClickActionResult>;
  /**
   * #rest #virtual
   *
   *  Request to create a file upload at the upload service. On success a representation of
   * the upload is returned containing all necessary information to push data to the upload
   * and process it later in the backend.
   *
   * We are bypasing original `UploadRequestResolver` bacause we are signing the upload id
   * so it cannot be used outside the intended chat by the originating user
   */
  messengerUploadRequest?: Maybe<MessengerUploadResult>;
  /**
   * #rest #virtual
   *
   *  Sends moveOn company recommendations feedback
   */
  moveonCompanyRecommendationsFeedbackInput?: Maybe<MoveonUpdatedAssessmentResult>;
  /**
   * #rest #virtual
   *
   *  Creates an assessment
   */
  moveonCreateAssessmentInput?: Maybe<MoveonCreateAssessmentResult>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Decline MYMK recommendation
   */
  mymkDeclineRecommendation?: Maybe<MymkDeclineRecommendationResult>;
  /**
   * #rest #virtual
   *
   *  Assign a tag to a contact
   */
  networkAssignTag?: Maybe<NetworkTagAssignResult>;
  /**
   * #rest #virtual
   *
   *  Blocks a user
   */
  networkBlockedUsersBlock?: Maybe<NetworkBlockedUsersBlockResult>;
  /**
   * #rest #virtual
   *
   *  Unblocks a user
   */
  networkBlockedUsersUnblock?: Maybe<NetworkBlockedUsersUnblockResult>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Persists a client setting for network apps
   */
  networkClientSettingSet?: Maybe<NetworkClientSettingsSetResult>;
  /**
   * #rest #virtual
   *
   *  Confirm a received contact request
   */
  networkContactRequestsConfirm?: Maybe<ContactRequestsConfirmResult>;
  /**
   * #rest #virtual
   *
   *  Decline a received contact request
   */
  networkContactRequestsDecline?: Maybe<ContactRequestsDeclineResult>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Mark received contact requests up until given date as seen
   */
  networkContactRequestsMarkSeen?: Maybe<ContactRequestsMarkSeenResult>;
  /**
   * #rest #virtual
   *
   *  Revoke a sent contact request
   */
  networkContactRequestsRevoke?: Maybe<ContactRequestsRevokeResult>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Send a contact request
   */
  networkContactRequestsSend?: Maybe<ContactRequestsSendResult>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Send multiple contact requests
   */
  networkContactRequestsSendMultiple?: Maybe<ContactRequestsSendMultipleResult>;
  /**
   * #rest #virtual
   *
   *  Report a contact request as spam
   */
  networkContactRequestsSpamReport?: Maybe<ContactRequestsSpamReportResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Confirm a received contact request instantly with a token
   */
  networkContactRequestsTokenConfirm?: Maybe<ContactRequestsTokenConfirmResult>;
  /**
   * #rest #virtual
   *
   *  Delete a contact
   */
  networkContactsDelete?: Maybe<ContactsDeleteResult>;
  /**
   * #rest #virtual
   *
   *  Create a new custom tag for this user to tag contacts with
   */
  networkCreateTag?: Maybe<NetworkTagCreateResult>;
  /**
   * #rest #virtual
   *
   *  Delete a custom tag of this user
   */
  networkDeleteTag?: Maybe<NetworkTagDeleteResult>;
  /**
   * #rest #virtual
   *
   *  Set note for the contact
   */
  networkSetNote?: Maybe<SetNotePayload>;
  /**
   * #rest #virtual
   *
   *  Unassign a tag from a contact
   */
  networkUnassignTag?: Maybe<NetworkTagUnassignResult>;
  /**
   * #rest #virtual
   *
   *  Forget the proximity id for the requesting user immediately.
   */
  networkUserProximityIdForget?: Maybe<NetworkUserProximityIdSetResult>;
  /**
   * #rest #virtual
   *
   *  Set the proximity id for the requesting user for 7 days.
   */
  networkUserProximityIdSet?: Maybe<NetworkUserProximityIdSetResult>;
  /**
   * #rest #virtual
   *
   *  Mark as opened the notification
   */
  notificationMarkAsOpened?: Maybe<NotificationMarkAsOpenedResult>;
  /**
   * #rest #virtual
   *
   *  Adds information for badges which site section has been seen by a user, so
   * server can manage that user's badge counters.
   */
  notificationsBadgesSeen?: Maybe<NotificationsBadgesSeenResponse>;
  /**
   * #rest #virtual
   *
   *  Mark all notifications as opened
   */
  notificationsMarkAllAsOpened?: Maybe<NotificationsMarkAllAsOpenedResult>;
  /**
   * #rest #virtual
   *
   *  Creates a journey
   */
  onboardingCreateJourney?: Maybe<OnboardingJourneyNextScreenResponseType>;
  /**
   * #rest #virtual
   *
   *  Updates a journey (for QA and Dev) only available on Xingbox and Preview
   */
  onboardingDeveloperUpdateJourney?: Maybe<OnboardingJourneyNextScreenResponseType>;
  /**
   * #rest #virtual
   *
   *  Resumes a journey (if eligible)
   */
  onboardingResumeJourney?: Maybe<OnboardingJourneyType>;
  /**
   * #rest #virtual
   *
   *  Updates a journey
   */
  onboardingUpdateJourney?: Maybe<OnboardingJourneyNextScreenResponseType>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Save organization user details
   */
  organizerInfoSave?: Maybe<AtsCredentials>;
  /**
   * #rest #virtual
   *
   *  Parse the template with the recipient's data
   */
  parseMessageTemplate?: Maybe<ParseMessageTemplateMutationResult>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Creates partner code for users
   */
  partnerCreateCode?: Maybe<PartnerCreateCodeMutationResult>;
  /**
   * #rest #virtual
   *
   *  Deletes all user connections between current user and its client
   */
  paymentsDeleteUserConnections?: Maybe<DeleteUserConnectionsResult>;
  /**
   * #rest #virtual
   *
   *  Blacklist skills
   */
  postBlacklistedSkills?: Maybe<BlacklistedSkillsResult>;
  /**
   * #rest #virtual
   *
   *  Collapses or expands a module
   */
  postModuleSettings?: Maybe<CollapsedModuleResult>;
  /**
   * #rest #virtual
   *
   *  Post salary insights
   */
  postSalaryInsights?: Maybe<SalaryInsightsResult>;
  /**
   * #rest #virtual
   *
   *  creates a posting
   */
  postingsCreatePosting?: Maybe<PostingsPostingMutationResult>;
  /**
   * #rest #virtual
   *
   *  creates an RSS importer
   */
  postingsCreateRssImporter?: Maybe<PostingsRssImporterMutationResult>;
  /** creates a story */
  postingsCreateStory?: Maybe<StoryCreateMutationResult>;
  /**
   * Creates an empty Story Group
   * Required for the creation of video stories
   */
  postingsCreateStoryGroup?: Maybe<StoryGroupMutationResult>;
  /**
   * #rest #virtual
   *
   *  creates a twitter importer
   */
  postingsCreateTwitterImporter?: Maybe<PostingsTwitterImporterMutationResult>;
  /**
   * #rest #virtual
   *
   *  creates a video record for usage in a posting
   */
  postingsCreateVideo?: Maybe<PostingsVideoCreateMutationResult>;
  /**
   * #rest #virtual
   *
   *  deletes any mentions of the requesting user from a posting
   */
  postingsDeleteMyMentions?: Maybe<PostingsPostingMutationResult>;
  /**
   * #rest #virtual
   *
   *  deletes a posting
   */
  postingsDeletePosting?: Maybe<PostingsPostingDeleteResult>;
  /**
   * #rest #virtual
   *
   *  deletes an RSS importer
   */
  postingsDeleteRssImporter?: Maybe<PostingsPostingDeleteResult>;
  /** deletes a story */
  postingsDeleteStory?: Maybe<StoryDeleteMutationResult>;
  /**
   * #rest #virtual
   *
   *  deletes a twitter importer
   */
  postingsDeleteTwitterImporter?: Maybe<PostingsPostingDeleteResult>;
  /**
   * #rest #virtual
   *
   *  hide a posting
   */
  postingsHidePosting?: Maybe<PostingsPostingMutationResult>;
  /**
   * #rest #virtual
   *
   *  Removes currently pinned activity (if one exists) and pins a new one for given entity page
   * @deprecated Use postingsPinActivityByTarget instead
   */
  postingsPinActivity?: Maybe<PostingsPinActivityResult>;
  /**
   * #rest #virtual
   *
   *  Removes currently pinned activity (if one exists) and pins a new one for given targetGlobalId
   */
  postingsPinActivityByTarget?: Maybe<PostingsPinActivityResult>;
  /**
   * Populates an empty Story Group
   * Required for the creation of video stories
   */
  postingsPopulateStoryGroup?: Maybe<StoryGroupMutationResult>;
  /**
   * #rest #virtual
   *
   *  publishes a posting
   */
  postingsPublishPosting?: Maybe<PostingsPostingMutationResult>;
  /**
   * #rest #virtual
   *
   *  unhide a posting
   */
  postingsUnhidePosting?: Maybe<PostingsPostingMutationResult>;
  /**
   * #rest #virtual
   *
   *  Removes pinned actviity for the given entity page
   * @deprecated Use postingsUnpinActivityByTarget instead
   */
  postingsUnpinActivity?: Maybe<PostingsUnpinActivityResult>;
  /**
   * #rest #virtual
   *
   *  Removes pinned actviity for the given targetGlobalId
   */
  postingsUnpinActivityByTarget?: Maybe<PostingsUnpinActivityResult>;
  /**
   * #rest #virtual
   *
   *  update the custom fields for a link share
   */
  postingsUpdateLinkShare?: Maybe<PostingsAttachmentMutationResult>;
  /**
   * #rest #virtual
   *
   *  updates a posting
   */
  postingsUpdatePosting?: Maybe<PostingsPostingMutationResult>;
  /**
   * #rest #virtual
   *
   *  creates a poll vote
   */
  postingsVoteForPollOption?: Maybe<PostingsVoteForPollOptionResult>;
  /**
   * #rest #virtual
   *
   *  Triggers an update on user job seeker status
   */
  preferenceSettings?: Maybe<PreferencesSettingsResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the user's blocked companies
   * @deprecated Use blockedCompanies instead for queries and mutations
   */
  preferenceSettingsBlockedCompanies?: Maybe<UpdatePreferencesSettingsBlockedCompaniesResult>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Creates partner code for users
   * @deprecated Replaced by partnerCreateCode mutation
   */
  premiumPartnerCreateCode?: Maybe<PremiumPartnerCreateCodeMutationResult>;
  /**
   * #rest #virtual
   *
   *  Mark a company as ideal employer
   */
  proJobsMarkAsIdealEmployer?: Maybe<ProJobsIdealEmployerMutationResult>;
  /**
   * #rest #virtual
   *
   *  Unmark a company as ideal employer
   */
  proJobsUnmarkAsIdealEmployer?: Maybe<ProJobsIdealEmployerMutationResult>;
  /**
   * #rest #virtual
   *
   *  Creates a Lead
   */
  probusinessCreateLead?: Maybe<ProBusinessCreateLeadResult>;
  /**
   * #rest #virtual
   *
   *  Removes a Lead
   */
  probusinessDeleteLead?: Maybe<ProBusinessDeleteLeadResult>;
  /**
   * #rest #virtual
   *
   *  Update about me description
   */
  profileAboutMeUpdate?: Maybe<ProfileAboutMeUpdateResult>;
  /**
   * #rest #virtual
   *
   *  Delete profile academic title update
   */
  profileAcademicTitleUpdateDelete?: Maybe<ProfileUpdateDeleteResult>;
  /**
   * #rest #virtual
   *
   *  Delete profile business contact update
   */
  profileBusinessContactUpdateDelete?: Maybe<ProfileUpdateDeleteResult>;
  /**
   * #rest #virtual
   *
   *  Delete profile haves update
   */
  profileHavesUpdateDelete?: Maybe<ProfileUpdateDeleteResult>;
  /**
   * #rest #virtual
   *
   *  Update user interests
   */
  profileInterestsUpdate?: Maybe<ProfileInterestsUpdateResult>;
  /**
   * #rest #virtual
   *
   *  Update user wants
   */
  profileLanguagesUpdate?: Maybe<ProfileLanguagesUpdateError>;
  /**
   * #rest #virtual
   *
   *  Update profile birthName or birthDate
   */
  profilePersonalDetailsUpdate?: Maybe<PersonalDetailsUpdateResult>;
  /**
   * #rest #virtual
   *
   *  Add a skill to the user
   */
  profileSkillsAdd?: Maybe<ProfileSkillsUpdateResult>;
  /**
   * #rest #virtual
   *
   *  Update user skills
   */
  profileSkillsUpdate?: Maybe<ProfileSkillsUpdateResult>;
  /**
   * #rest #virtual
   *
   *  Create a new entry in Timeline module on Profiles, it returns the timeline module in case of success, except for the order and title of the module so requesting them will lead to errors
   */
  profileTimelineAddEntry?: Maybe<ProfileTimelineEntryPayload>;
  /**
   * #rest #virtual
   *
   *  Delete an entry in the Timeline module on Profiles. Will return the timeline when successful
   */
  profileTimelineDeleteEntry?: Maybe<ProfileTimelineDeleteEntryPayload>;
  /**
   * #rest #virtual
   *
   *  Edit an entry in the Timeline module on Profiles. Will return the timeline when successful
   */
  profileTimelineEditEntry?: Maybe<ProfileTimelineEntryPayload>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Create new profile visit for a user
   */
  profileVisit?: Maybe<NewProfileVisitResult>;
  /**
   * Update user wants
   * @deprecated The wants will be removed in favor of job-preferences: PROFILE-10484.
   */
  profileWantsUpdate?: Maybe<ProfileWantsUpdateResult>;
  /**
   * #rest #virtual
   *
   *  Delete profile wants update
   */
  profileWantsUpdateDelete?: Maybe<ProfileUpdateDeleteResult>;
  /**
   * #rest #virtual
   *
   *  Delete profile work experience update
   */
  profileWorkExperienceUpdateDelete?: Maybe<ProfileUpdateDeleteResult>;
  /**
   * #rest #virtual
   *
   *  Triggers adding a user to the allowlist
   */
  projobsAddAllowlistUser?: Maybe<ProjobsAddAllowlistUserResult>;
  /**
   * #rest #virtual
   *
   *  Triggers adding companies to the blocked_companies
   */
  projobsAddBlockedCompanies?: Maybe<ProjobsAddBlockedCompaniesResult>;
  /**
   * #rest #virtual
   *
   *  Triggers adding a user to the blocklist
   */
  projobsAddBlocklistUser?: Maybe<ProjobsAddBlocklistUserResult>;
  /**
   * #rest #virtual
   *
   *  Triggers adding a company to the target companies
   * @deprecated Will be removed at the end of Q4
   */
  projobsAddIdealEmployers?: Maybe<ProjobsAddIdealEmployersResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating avoid
   * @deprecated Will be removed at the end of Q4
   */
  projobsAvoid?: Maybe<ProjobsAvoidResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating cities
   * @deprecated Will be removed at the end of Q4
   */
  projobsCities?: Maybe<ProjobsCitiesResult>;
  /**
   * #rest #virtual
   *
   *  Triggers deleting a document from user
   */
  projobsDeleteDocument?: Maybe<ProjobsDeleteDocumentResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating discipline
   * @deprecated Will be removed at the end of Q4
   */
  projobsDiscipline?: Maybe<ProjobsDisciplineResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updaing industries
   * @deprecated Will be removed at the end of Q4
   */
  projobsIndustries?: Maybe<ProjobsIndustriesResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating positions
   * @deprecated Will be removed at the end of Q4
   */
  projobsPositions?: Maybe<ProjobsPositionsResult>;
  /**
   * #rest #virtual
   *
   *  Triggers removing a user from allowlist
   */
  projobsRemoveAllowlistUser?: Maybe<ProjobsRemoveAllowlistUserResult>;
  /**
   * #rest #virtual
   *
   *  Triggers removing company from the blocked_companies
   */
  projobsRemoveBlockedCompany?: Maybe<ProjobsRemoveBlockedCompanyResult>;
  /**
   * #rest #virtual
   *
   *  Triggers removing a user from blocklist
   */
  projobsRemoveBlocklistUser?: Maybe<ProjobsRemoveBlocklistUserResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating salary expectation
   * @deprecated Will be removed at the end of Q4
   */
  projobsSalaryExpectation?: Maybe<ProjobsSalaryExpectationResult>;
  /**
   * #rest #virtual
   *
   *  Triggers an update on user job seeker status
   */
  projobsSettingSeekingStatus?: Maybe<ProjobsSeekingStatusResult>;
  /**
   * #rest #virtual
   *
   *  Triggers tracking for a notification interaction
   */
  projobsSettingVisibility?: Maybe<ProjobsVisibilityResult>;
  /**
   * #rest #virtual
   *
   *  Triggers an update on job seeker contact options
   * @deprecated Will be removed at the end of Q4
   */
  projobsUpdateContactOptions?: Maybe<ProjobsContactOptionsResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating document title
   */
  projobsUpdateDocument?: Maybe<ProjobsUpdateDocumentResult>;
  /**
   * #rest #virtual
   *
   *  Uploads documents to the user's projobs
   */
  projobsUploadDocument?: Maybe<ProjobsUploadDocumentResult>;
  /**
   * #rest #virtual
   *
   *  Triggers update user's willingness to travel
   * @deprecated Will be removed at the end of Q4
   */
  projobsWillingnessToTravel?: Maybe<ProjobsWillingnessToTravelResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating working hours
   * @deprecated Will be removed at the end of Q4
   */
  projobsWorkingHours?: Maybe<ProjobsWorkingHoursResult>;
  /**
   * #rest #virtual
   *
   *  Create purchase campaign
   */
  purchaseCreateCampaign?: Maybe<PurchaseCreateCampaignResult>;
  /**
   * #rest #virtual
   *
   *  Create a campaign group
   */
  purchaseCreateCampaignGroup?: Maybe<PurchaseCreateCampaignGroupResult>;
  /**
   * #rest #virtual
   *
   *  used to delete a campaign group
   */
  purchaseDeleteCampaignGroup?: Maybe<PurchaseDeleteCampaignGroupResult>;
  /**
   * #rest #virtual
   *
   *  allow enable/disable a platform campaign
   */
  purchaseEnablePlatform?: Maybe<PurchaseEnablePlatformResult>;
  /**
   * #rest #virtual
   *
   *  allow enable/disable a platform group
   */
  purchasePublishCampaign?: Maybe<PurchasePublishCampaignResult>;
  /**
   * #rest #virtual
   *
   *  used to enable/disable a campaign group
   */
  purchaseRestoreCampaignGroupResolver?: Maybe<PurchaseRestoreCampaignGroupResult>;
  /**
   * #rest #virtual
   *
   *  Update purchase campaign
   */
  purchaseUpdateCampaign?: Maybe<PurchaseUpdateCampaignResult>;
  /**
   * #rest #virtual
   *
   *  Updates a campaign group
   */
  purchaseUpdateCampaignGroup?: Maybe<PurchaseUpdateCampaignGroupResult>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Make a purchase web order
   */
  purchaseWebOrder?: Maybe<PurchaseWebOrder>;
  /**
   * #rest #virtual
   *
   *  Regenerate backup code for two-factor authentication
   */
  regenerateBackupCodeTwoFaSettings?: Maybe<TwoFaSettingsBackupCodeRegenerationResult>;
  /**
   * #rest #virtual
   *
   *  Regenerate payment invoice
   */
  regenerateInvoices?: Maybe<RegenerateInvoicesMutationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Trigger the vita cover letter rendering process
   */
  requestVitaCoverLetterRendering?: Maybe<RequestVitaDocumentRenderingResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Trigger the vita document rendering process via token
   */
  requestVitaDocumentTokenRendering?: Maybe<RequestVitaDocumentRenderingResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Revert a complaint report
   */
  revertComplaintReport?: Maybe<RevertReportResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Triggers updating the user/visitor salary expectations preference
   */
  salaryExpectationsPreference?: Maybe<UpdateSalaryExpectationsPreferenceResult>;
  /**
   * #rest #virtual
   *
   *  This mutation saves User feedback about outdated fields
   */
  saveUserFeedbackOnOutdatedFields?: Maybe<UserFeebackOnOutdatedFieldsResponse>;
  /**
   * #rest #virtual
   *
   *  Assign seat mutation
   */
  seatManagerCreateAssignment?: Maybe<SeatManagerCreateAssignmentResponse>;
  /**
   * #rest #virtual
   *
   *  Delete seat assignment mutation
   */
  seatManagerDeleteAssignment?: Maybe<SeatManagerDeleteAssignmentResponse>;
  /**
   * #rest #virtual
   *
   *  Share a Xing Sourcing Service (XSS) briefing
   */
  shareErecruitingSourcingServiceBriefing?: Maybe<ErecruitingSourcingServiceShareResult>;
  /** Delete a signal */
  signalDelete?: Maybe<SignalDeleteResult>;
  /** Mark a signal interacted */
  signalMarkInteracted?: Maybe<SignalMarkInteractedResult>;
  /** Mark a signals stack seen */
  signalsStackMarkSeen?: Maybe<SignalsStackMarkSeenResult>;
  /**
   * #rest #virtual
   *
   *  Creates a new comment
   */
  socialCreateComment?: Maybe<SocialCommentMutationResult>;
  /**
   * #rest #virtual
   *
   *  Creates a new external link
   */
  socialCreateExternalLink?: Maybe<SocialExternalLinkMutationResult>;
  /**
   * #rest #virtual
   *
   *  Creates a new reaction
   */
  socialCreateReaction?: Maybe<SocialReactionMutationResult>;
  /**
   * #rest #virtual
   *
   *  Deletes an existing comment
   */
  socialDeleteComment?: Maybe<SocialCommentMutationResult>;
  /**
   * #rest #virtual
   *
   *  Deletes mentions of the user within the comment
   */
  socialDeleteCommentMentions?: Maybe<SocialCommentMutationResult>;
  /**
   * #rest #virtual
   *
   *  Deletes an existing reaction
   */
  socialDeleteReaction?: Maybe<SocialReactionMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create a new share to groups
   */
  socialShareToGroup?: Maybe<SocialShareMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create a new share to messenger
   */
  socialShareToMessenger?: Maybe<SocialShareMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create a new share to startpage
   */
  socialShareToStartpage?: Maybe<SocialShareMutationResult>;
  /**
   * #rest #virtual
   *
   *  Updates an existing comment
   */
  socialUpdateComment?: Maybe<SocialCommentMutationResult>;
  /**
   * #rest #virtual
   *
   *  Updates an existing reaction
   */
  socialUpdateReaction?: Maybe<SocialReactionMutationResult>;
  /**
   * #rest #virtual
   *
   *  Start data inquiry for the user
   */
  startUserDataInquiry?: Maybe<StartUserDataInquiryResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Submit review for a rejected or accepted report
   */
  submitReview?: Maybe<SubmitReviewPostResult>;
  /**
   * #rest #virtual
   *
   *  This mutation tracks a click for an ad
   */
  trackAdClickByIntId?: Maybe<AdsTrackingIntIdResult>;
  /**
   * #rest #virtual
   *
   *  This mutation tracks an impression for an ad
   */
  trackAdImpressionByIntId?: Maybe<AdsTrackingIntIdResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Trigger cover_letter suggestion generation from LLM Service
   */
  triggerVitaCoverLetterSuggestion?: Maybe<LlmGatewayTriggerCoverLetterResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Unsubscribe a token belonging to a logged out search alert.
   */
  unsubscribeLOSearchAlert?: Maybe<LoSearchAlertResponse>;
  /**
   * #rest #virtual
   *
   *  Update account deactivation settings
   */
  updateAccountDeactivationSettings?: Maybe<AccountDeactivationSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Delete account
   */
  updateAccountDeletionSettings?: Maybe<AccountDeletionSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update account settings
   */
  updateAccountSettings?: Maybe<AccountSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update activities settings
   */
  updateActivitiesSettings?: Maybe<ActivitiesSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update b2b profile settings
   */
  updateB2BProfileSettings?: Maybe<UpdateB2BProfileSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Edit payment method
   */
  updateBpayPaymentMethod?: Maybe<UpdateBpayPaymentMethodResult>;
  /**
   * #rest #virtual
   *
   *  Updates client billing address
   */
  updateClientBillingAddress?: Maybe<UpdateClientBillingAddressResult>;
  /**
   * #rest #virtual
   *
   *  Update core settings
   */
  updateCoreSettings?: Maybe<CoreSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Sets new settings related to contact data sharing.
   */
  updateDataSharingSettings?: Maybe<DataSharingSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Archive/unarchive a Xing TalentService (XTS) briefing
   */
  updateErecruitingSourcingServiceBriefingArchiveStatus?: Maybe<ErecruitingSourcingServiceArchiveResult>;
  /**
   * #rest #virtual
   *
   *  Updates metadata attached to a briefing
   */
  updateErecruitingSourcingServiceBriefingMetadata?: Maybe<ErecruitingSourcingServiceBriefingMetadataUpdateResult>;
  /**
   * #rest #virtual
   *
   *  Update a Xing Talent Service (XTS) Candidate Note By Consultant
   */
  updateErecruitingSourcingServiceCandidateNote?: Maybe<ErecruitingSourcingServiceCandidateNoteMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update a Xing Talent Service (XTS) Candidate Note By Client
   */
  updateErecruitingSourcingServiceClientCandidateNote?: Maybe<ErecruitingSourcingServiceCandidateNoteMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update or create a Xing TalentService (XTS) Message Reminder Metadata
   */
  updateErecruitingSourcingServiceMessageRemindersMetadata?: Maybe<ErecruitingSourcingServiceMessageReminderMetadataMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create or update the hiring profile of a user
   */
  updateHiringProfile?: Maybe<UpdateHiringProfileResult>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  User feedback on a job recommendation
   */
  updateJobRecoFeedback?: Maybe<JobRecoFeedBackResult>;
  /**
   * #rest #virtual
   *
   *  Update legal information
   */
  updateLegalInformation?: Maybe<LegalInformationUpdateError>;
  /**
   * #rest #virtual
   *
   *  Update login settings
   */
  updateLoginSettings?: Maybe<LoginSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Mark messenger banner as shown
   */
  updateMessengerBanner?: Maybe<UpdateMessengerBannerResult>;
  /**
   * #rest #virtual
   *
   *  Update a template
   */
  updateMessengerMessageTemplate?: Maybe<UpdateMessengerMessageTemplateResult>;
  /**
   * #rest #virtual
   *
   *  Update messenger settings
   */
  updateMessengerSettings?: Maybe<MessengerSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update privacy settings
   */
  updateNetworkSettings?: Maybe<NetworkSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update a users notification settings
   */
  updateNotificationSettings?: Maybe<NotificationSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Updates client for Payment & Billing
   */
  updatePayBilClient?: Maybe<PayBilClientMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update payment account
   */
  updatePaymentAccount?: Maybe<PaymentAccountMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update payment method
   */
  updatePaymentMethod?: Maybe<PaymentAccountMutationResult>;
  /**
   * #rest #virtual
   *
   *  changes the PremiumSwitch settings
   */
  updatePremiumSwitchSettings?: Maybe<PremiumSwitchSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update profile module store items
   */
  updateProfileModuleStoreItems?: Maybe<UpdateProfileModuleStoreItemsResult>;
  /**
   * #rest #virtual
   *
   *  Update profile settings
   */
  updateProfileSettings?: Maybe<ProfileSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update visibility settings for Profile-Loggedout and LO content
   */
  updateProfilesLoggedoutSettings?: Maybe<ProfilesLoggedoutSettingsMutationResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Update a report
   */
  updateReport?: Maybe<UpdateReportPutResult>;
  /**
   * #rest #virtual
   *
   *  Update the permissions between a User and a Resource
   */
  updateResourceUser?: Maybe<TeamPermissionsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Updates the search alert settings for the current user. If they don't exist, they are created.
   */
  updateSearchAlertSettings?: Maybe<Empty>;
  /**
   * #rest #virtual
   *
   *  changes the social settings
   */
  updateSocialSettings?: Maybe<SocialSettingsMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update two-factor authentication settings
   */
  updateTwoFaSettings?: Maybe<TwoFaSettingsUpdateResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Update a web push subscription
   */
  updateWebPushSubscription?: Maybe<UpdateWebPushSubscriptionResult>;
  /**
   * #rest #virtual
   *
   *  Update a XTM project
   */
  updateXTMProject?: Maybe<XtmUpdateProjectResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Request to create a file upload at the upload service. On success a representation of
   * the upload is returned containing all necessary information to push data to the upload
   * and process it later in the backend.
   */
  uploadRequest?: Maybe<UploadRequestPayload>;
  /**
   * #rest #virtual
   *
   *  User accepts counter offer instead of cancelling
   */
  upsellCancellationAcceptCounterOffer?: Maybe<UpsellCancellationAcceptCounterOfferResult>;
  /**
   * #rest #virtual
   *
   *  Request cancellation of web contracts
   */
  upsellCancellationRequest?: Maybe<UpsellCancellationRequestResult>;
  /**
   * #rest #virtual
   *
   *  User data inquriy temporary download URL
   */
  userDataInquiryTemporaryDownloadUrl?: Maybe<UserDataInquiryTemporaryDownloadUrlResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This mutation generates a reminder to a user to continue an unfinished application
   */
  userRemindJobApplication?: Maybe<UserRemindApplicationError>;
  /**
   * #rest #virtual
   *
   *  Create video
   */
  videoCreate?: Maybe<VideoMutationResult>;
  /**
   * #rest #virtual
   *
   *  Create custom thumbnail
   */
  videoCustomThumbnailCreate?: Maybe<VideoEmptyResult>;
  /**
   * #rest #virtual
   *
   *  Delete custom thumbnail
   */
  videoCustomThumbnailDelete?: Maybe<VideoEmptyResult>;
  /**
   * #rest #virtual
   *
   *  Update custom thumbnail
   */
  videoCustomThumbnailUpdate?: Maybe<VideoEmptyResult>;
  /**
   * #rest #virtual
   *
   *  Delete video
   */
  videoDelete?: Maybe<VideoEmptyResult>;
  /**
   * #rest #virtual
   *
   *  Create video image attachment
   */
  videoImageAttachmentCreate?: Maybe<VideoImageAttachmentCreationResult>;
  /**
   * #rest #virtual
   *
   *  Create video resource
   */
  videoResourceCreate?: Maybe<VideoResourceCreationResult>;
  /**
   * #rest #virtual
   *
   *  Start video encoding
   */
  videoStartEncoding?: Maybe<VideoEmptyResult>;
  /**
   * #rest #virtual
   *
   *  Create subtitle
   */
  videoSubtitleCreate?: Maybe<VideoEmptyResult>;
  /**
   * #rest #virtual
   *
   *  Delete subtitle
   */
  videoSubtitleDelete?: Maybe<VideoEmptyResult>;
  /**
   * #rest #virtual
   *
   *  Update subtitle
   */
  videoSubtitleUpdate?: Maybe<VideoEmptyResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Creates a video tracking event
   */
  videoTracking?: Maybe<VideoTrackingMutationResult>;
  /**
   * #rest #virtual
   *
   *  Update video
   */
  videoUpdate?: Maybe<VideoMutationResult>;
  /**
   * #rest #virtual
   *
   *  Visit a search alert using its id.
   */
  visitSearchAlert?: Maybe<EmptyOrErrorResponse>;
  /**
   * #rest #virtual
   *
   *  Reset the number of new visits counter
   */
  visitsView?: Maybe<VisitsViewResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the user's working hours preference
   */
  workingHoursPreference?: Maybe<UpdateWorkingHoursPreferenceResult>;
  /**
   * #rest #virtual
   *
   *  Triggers updating the user's workplace preference
   */
  workplacePreference?: Maybe<UpdateWorkplacePreferenceResult>;
  /**
   * #rest #virtual
   *
   *  Add the user as a contact from profile
   */
  xingIdAddContact?: Maybe<XingIdAddContactPayload>;
  /**
   * #rest #virtual
   *
   *  Confirm contact request from profile
   */
  xingIdConfirmContact?: Maybe<XingIdConfirmContactPayload>;
  /**
   * #rest #virtual
   *
   *  Decline contact request
   */
  xingIdDeclineContactRequest?: Maybe<XingIdDeclineContactRequestPayload>;
  /**
   * #rest #virtual
   *
   *  Delete contact
   */
  xingIdDeleteContact?: Maybe<XingIdDeleteContactPayload>;
  /**
   * #rest #virtual
   *
   *  Delete profile header image
   */
  xingIdDeleteHeaderImage?: Maybe<XingIdDeleteHeaderImagePayload>;
  /**
   * #rest #virtual
   *
   *  Delete profile picture
   */
  xingIdDeleteProfileImage?: Maybe<XingIdDeleteProfileImagePayload>;
  /**
   * #rest #virtual
   *
   *  Follow the user
   */
  xingIdFollow?: Maybe<XingIdFollowPayload>;
  /**
   * #rest #virtual
   *
   *  Update occupations order
   */
  xingIdReorderOccupations?: Maybe<XingIdReorderOccupationsPayload>;
  /**
   * #rest #virtual
   *
   *  Unfollow the user
   */
  xingIdUnfollow?: Maybe<XingIdUnfollowPayload>;
  /**
   * #rest #virtual
   *
   *  Update contact details
   */
  xingIdUpdateContactDetails?: Maybe<XingIdUpdateContactDetailsPayload>;
  /**
   * #rest #virtual
   *
   *  Update profile header image
   */
  xingIdUpdateHeaderImage?: Maybe<XingIdUpdateHeaderImagePayload>;
  /**
   * #rest #virtual
   *
   *  Update profile picture
   */
  xingIdUpdateProfileImage?: Maybe<XingIdUpdateProfileImagePayload>;
  /**
   * #rest #virtual
   *
   *  Update the personal status shown for example on the business card
   */
  xingIdUpdateStatus?: Maybe<XingIdUpdateStatusPayload>;
  /**
   * #rest #virtual
   *
   *  Check the limitatons when creating a Xing Talent Manager (XTM) project campaign
   */
  xtmProjectCampaignLimitations?: Maybe<XtmProjectCampaignLimitationsResult>;
  /**
   * #rest #virtual
   *
   *  Create or update a Xing Talent Manager (XTM) project campaign
   */
  xtmProjectCreateUpdateCampaign?: Maybe<XtmProjectCreateUpdateCampaignResult>;
  /**
   * #rest #virtual
   *
   *  Send a Xing Talent Manager (XTM) project campaign
   */
  xtmProjectSendCampaign?: Maybe<XtmProjectSendCampaignResult>;
};

/** The root mutation type */
export type MutationAccomplishmentCreateArgs = {
  input: AccomplishmentCreateInput;
};

/** The root mutation type */
export type MutationAccomplishmentDeleteArgs = {
  input: AccomplishmentDeleteInput;
};

/** The root mutation type */
export type MutationAccomplishmentUpdateArgs = {
  input: AccomplishmentUpdateInput;
};

/** The root mutation type */
export type MutationActivateTwoFaSettingsArgs = {
  input: TwoFaSettingsActivationInput;
};

/** The root mutation type */
export type MutationAdNegativeFeedbackCreateArgs = {
  input: AdNegativeFeedbackInput;
};

/** The root mutation type */
export type MutationAddDataSharingExclusionArgs = {
  input: DataSharingExclusionInput;
};

/** The root mutation type */
export type MutationAddMessengerChatParticipantsArgs = {
  input: AddMessengerChatParticipantsInput;
};

/** The root mutation type */
export type MutationAdsTrackClickArgs = {
  input: AdsTrackingInput;
};

/** The root mutation type */
export type MutationAdsTrackImpressionArgs = {
  input: AdsTrackingInput;
};

/** The root mutation type */
export type MutationArticlesCreateArticleArgs = {
  input: ArticlesCreateArticleInput;
};

/** The root mutation type */
export type MutationArticlesCreateArticleImageArgs = {
  input: ArticlesCreateArticleImageInput;
};

/** The root mutation type */
export type MutationArticlesDeleteArticleArgs = {
  input: ArticlesDeleteArticleInput;
};

/** The root mutation type */
export type MutationArticlesPublishArticleArgs = {
  input: ArticlesPublishArticleInput;
};

/** The root mutation type */
export type MutationArticlesUnpublishArticleArgs = {
  input: ArticlesUnpublishArticleInput;
};

/** The root mutation type */
export type MutationArticlesUpdateArticleArgs = {
  input: ArticlesUpdateArticleInput;
};

/** The root mutation type */
export type MutationArticlesUpdateArticleSettingsArgs = {
  input: ArticlesUpdateArticleSettingsInput;
};

/** The root mutation type */
export type MutationBannerPropertyDisableArgs = {
  input: BannerPropertyDisableInput;
};

/** The root mutation type */
export type MutationBlockedCompaniesArgs = {
  input: UpdateBlockedCompaniesInput;
};

/** The root mutation type */
export type MutationBlockingServiceBlockArgs = {
  input: BlockingServiceBlockInput;
};

/** The root mutation type */
export type MutationBlockingServiceUnblockArgs = {
  input: BlockingServiceUnblockInput;
};

/** The root mutation type */
export type MutationCareerLevelPreferenceArgs = {
  input: UpdateCareerLevelPreferenceInput;
};

/** The root mutation type */
export type MutationChangeErecruitingSourcingServiceBriefingOwnerArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceChangeBriefingOwnerInput>;
};

/** The root mutation type */
export type MutationCompanyCreateAffiliatesArgs = {
  input: CompanyCreateAffiliatesInput;
};

/** The root mutation type */
export type MutationCompanyCreateFeedArgs = {
  input: CompanyCreateFeedInput;
};

/** The root mutation type */
export type MutationCompanyDeleteFeedArgs = {
  input: CompanyDeleteFeedInput;
};

/** The root mutation type */
export type MutationCompanyFollowArgs = {
  input: EntityPageFollowersMutationInput;
};

/** The root mutation type */
export type MutationCompanyPageCreateArgs = {
  input: CompanyPageCreateInput;
};

/** The root mutation type */
export type MutationCompanyUnfollowArgs = {
  input: EntityPageFollowersMutationInput;
};

/** The root mutation type */
export type MutationCompanyUpdateInformationArgs = {
  input: CompanyUpdateInformationInput;
};

/** The root mutation type */
export type MutationCompanyUpdatesDeleteArgs = {
  input: CompanyUpdatesDeleteInput;
};

/** The root mutation type */
export type MutationComplaintsCreateContentReportArgs = {
  input: ComplaintsCreateContentReportInput;
};

/** The root mutation type */
export type MutationComplaintsCreateProfileReportArgs = {
  input: ComplaintsCreateProfileReportInput;
};

/** The root mutation type */
export type MutationCompleteComplaintReportArgs = {
  input: ComplaintsReportInput;
};

/** The root mutation type */
export type MutationConfirmEmailReportArgs = {
  input: ConfirmEmailReportInput;
};

/** The root mutation type */
export type MutationConfirmLoSearchAlertArgs = {
  input: LoSearchAlertConfirmationInput;
};

/** The root mutation type */
export type MutationContactSupportArgs = {
  input?: InputMaybe<Contact>;
};

/** The root mutation type */
export type MutationContentBookmarkableBookmarkArgs = {
  input?: InputMaybe<ContentBookmarkInput>;
};

/** The root mutation type */
export type MutationContentBookmarkableUnbookmarkArgs = {
  input?: InputMaybe<ContentUnbookmarkInput>;
};

/** The root mutation type */
export type MutationContentCancelSubscriptionArgs = {
  id: Scalars['ID']['input'];
};

/** The root mutation type */
export type MutationContentCommentCreateArgs = {
  input?: InputMaybe<ContentCommentCreateInput>;
};

/** The root mutation type */
export type MutationContentCommentHideArgs = {
  input?: InputMaybe<ContentCommentHideInput>;
};

/** The root mutation type */
export type MutationContentDeleteArticleArgs = {
  input?: InputMaybe<ContentDeleteArticleInput>;
};

/** The root mutation type */
export type MutationContentDisableArticleArgs = {
  input?: InputMaybe<ContentDisableArticleInput>;
};

/** The root mutation type */
export type MutationContentEnableArticleArgs = {
  input?: InputMaybe<ContentEnableArticleInput>;
};

/** The root mutation type */
export type MutationContentFeatureArticleArgs = {
  input?: InputMaybe<ContentFeatureArticleInput>;
};

/** The root mutation type */
export type MutationContentInsiderPageLastFollowersSeenArgs = {
  input?: InputMaybe<ContentInsiderPageLastFollowersSeenInput>;
};

/** The root mutation type */
export type MutationContentKlartextAgreeArgs = {
  klarticleGlobalId: Scalars['GlobalID']['input'];
};

/** The root mutation type */
export type MutationContentKlartextUnagreeArgs = {
  klarticleGlobalId: Scalars['GlobalID']['input'];
};

/** The root mutation type */
export type MutationContentPageFollowArgs = {
  input?: InputMaybe<ContentPageFollowInput>;
};

/** The root mutation type */
export type MutationContentPageUnfollowArgs = {
  input?: InputMaybe<ContentPageUnfollowInput>;
};

/** The root mutation type */
export type MutationContentServiceCreateContentArgs = {
  input: ContentServiceCreateContentInput;
};

/** The root mutation type */
export type MutationContentServiceUpdateContentArgs = {
  input: ContentServiceUpdateContentInput;
};

/** The root mutation type */
export type MutationContentStarableStarArgs = {
  input?: InputMaybe<ContentStarInput>;
};

/** The root mutation type */
export type MutationContentStarableUnstarArgs = {
  input?: InputMaybe<ContentUnstarInput>;
};

/** The root mutation type */
export type MutationContentUnfeatureArticleArgs = {
  input?: InputMaybe<ContentUnfeatureArticleInput>;
};

/** The root mutation type */
export type MutationContentUpdateEmailSubscriptionArgs = {
  input: ContentUpdateEmailSubscriptionInput;
};

/** The root mutation type */
export type MutationContentUpdatePageArgs = {
  input?: InputMaybe<ContentUpdatePageInput>;
};

/** The root mutation type */
export type MutationCreateCvUploadParsedCvArgs = {
  input?: InputMaybe<CreateCvUploadParsedCvInput>;
};

/** The root mutation type */
export type MutationCreateCvUploadParsedCvByUserIdArgs = {
  input?: InputMaybe<CreateCvUploadParsedCvByUserIdInput>;
};

/** The root mutation type */
export type MutationCreateDocumentFromProfileImportArgs = {
  input: CreateVitaProfileImportInput;
};

/** The root mutation type */
export type MutationCreateErecruitingSourcingServiceBriefingArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceCreateBriefingInput>;
};

/** The root mutation type */
export type MutationCreateErecruitingSourcingServiceBriefingCandidateArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceCreateBriefingCandidateInput>;
};

/** The root mutation type */
export type MutationCreateErecruitingSourcingServiceBriefingReportArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceCreateBriefingReportInput>;
};

/** The root mutation type */
export type MutationCreateErecruitingSourcingServiceCandidateNoteArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceCreateCandidateNoteInput>;
};

/** The root mutation type */
export type MutationCreateErecruitingSourcingServiceClientCandidateNoteArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceCreateClientCandidateNoteInput>;
};

/** The root mutation type */
export type MutationCreateMessengerAttachmentMessageArgs = {
  message: CreateMessengerAttachmentMessageInput;
};

/** The root mutation type */
export type MutationCreateMessengerChatArgs = {
  chat: CreateMessengerChat;
};

/** The root mutation type */
export type MutationCreateMessengerChatBySlugOrIdArgs = {
  chat: CreateMessengerChatBySlugOrId;
};

/** The root mutation type */
export type MutationCreateMessengerImageMessageArgs = {
  message: CreateMessengerImageMessageInput;
};

/** The root mutation type */
export type MutationCreateMessengerMessageArgs = {
  message: CreateMessengerMessageInput;
};

/** The root mutation type */
export type MutationCreateMessengerMessageTemplateArgs = {
  input: CreateMessengerMessageTemplateInput;
};

/** The root mutation type */
export type MutationCreateMessengerSystemReplyMessageArgs = {
  message: CreateMessengerSystemReplyMessageInput;
};

/** The root mutation type */
export type MutationCreatePasswordProtectedSettingTokenArgs = {
  input: PasswordProtectedSettingTokenInput;
};

/** The root mutation type */
export type MutationCreatePayBilClientArgs = {
  input: PayBilClientMutationInput;
};

/** The root mutation type */
export type MutationCreatePaymentAccountArgs = {
  input?: InputMaybe<PaymentAccountInput>;
};

/** The root mutation type */
export type MutationCreateSearchAlertArgs = {
  input: SearchAlertCreationInput;
};

/** The root mutation type */
export type MutationCreateSearchAlertByQueryArgs = {
  input: JobSearchQueryInput;
};

/** The root mutation type */
export type MutationCreateSearchAlertForLoArgs = {
  input: SearchAlertForLoCreationInput;
};

/** The root mutation type */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

/** The root mutation type */
export type MutationCreateVitaCoverLetterArgs = {
  input: CreateVitaCoverLetterInput;
};

/** The root mutation type */
export type MutationCreateVitaDocumentArgs = {
  input: CreateVitaDocumentInput;
};

/** The root mutation type */
export type MutationCreateWebPushSubscriptionArgs = {
  input: CreateWebPushSubscriptionInput;
};

/** The root mutation type */
export type MutationCreateWorkExperienceArgs = {
  input: CreateWorkExperienceInput;
};

/** The root mutation type */
export type MutationDataScienceTrackingCompactFormatArgs = {
  input: DataScienceCompactTrackingInput;
};

/** The root mutation type */
export type MutationDataScienceTrackingFullFormatArgs = {
  input: DataScienceFullTrackingInput;
};

/** The root mutation type */
export type MutationDeleteCompanyRecommendationArgs = {
  input: DeleteCompanyRecommendationInput;
};

/** The root mutation type */
export type MutationDeleteCvUploadParsedCvArgs = {
  input?: InputMaybe<DeleteCvUploadParsedCvInput>;
};

/** The root mutation type */
export type MutationDeleteDataSharingExclusionArgs = {
  input: DataSharingExclusionInput;
};

/** The root mutation type */
export type MutationDeleteErecruitingSourcingServiceBriefingCandidateArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceDeleteBriefingCandidateInput>;
};

/** The root mutation type */
export type MutationDeleteErecruitingSourcingServicemessageReminderArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceDeleteMessageReminderInput>;
};

/** The root mutation type */
export type MutationDeleteLoginSessionArgs = {
  input: DeleteLoginSessionInput;
};

/** The root mutation type */
export type MutationDeleteMessengerChatArgs = {
  input: DeleteMessengerChatInput;
};

/** The root mutation type */
export type MutationDeleteMessengerChatParticipantArgs = {
  input: DeleteMessengerChatParticipantInput;
};

/** The root mutation type */
export type MutationDeleteMessengerMessageTemplateArgs = {
  input: DeleteMessengerMessageTemplateInput;
};

/** The root mutation type */
export type MutationDeleteResourceUserArgs = {
  input: TeamPermissionsDeleteResourceUserInput;
};

/** The root mutation type */
export type MutationDeleteScheduledMessageArgs = {
  input: DeleteScheduledMessageInput;
};

/** The root mutation type */
export type MutationDeleteSearchAlertArgs = {
  input: SearchAlertId;
};

/** The root mutation type */
export type MutationDeleteXwsSessionArgs = {
  input: DeleteXwsSessionInput;
};

/** The root mutation type */
export type MutationDiscardErecruitingSourcingServiceCandidateRecommendationArgs =
  {
    input?: InputMaybe<ErecruitingSourcingServiceCandidateRecommendationsDiscardInput>;
  };

/** The root mutation type */
export type MutationDisciplinesPreferenceArgs = {
  input: UpdateDisciplinesPreferenceInput;
};

/** The root mutation type */
export type MutationDiscoDeleteActivityArgs = {
  input: DiscoDeleteActivityInput;
};

/** The root mutation type */
export type MutationDiscoLoggingArgs = {
  input: DiscoLoggingInput;
};

/** The root mutation type */
export type MutationDismissCompanyAutoMigrationNotificationArgs = {
  input: DismissCompanyAutoNotificationInput;
};

/** The root mutation type */
export type MutationEcommCompanyDetailsArgs = {
  input: EcommCompanyDetailsMutationInput;
};

/** The root mutation type */
export type MutationEcommContactDetailsArgs = {
  input: EcommContactDetailsMutationInput;
};

/** The root mutation type */
export type MutationEcommOrderArgs = {
  input: EcommOrderMutationInput;
};

/** The root mutation type */
export type MutationEmployersPreferenceArgs = {
  input: UpdateEmployersPreferenceInput;
};

/** The root mutation type */
export type MutationEntityPageCreateContactUserArgs = {
  input: EntityPageCreateContactUserInput;
};

/** The root mutation type */
export type MutationEntityPageCreateDocumentArgs = {
  input: EntityPageCreateDocumentMutationInput;
};

/** The root mutation type */
export type MutationEntityPageCreateLocationArgs = {
  input: EntityPageCreateLocationInput;
};

/** The root mutation type */
export type MutationEntityPageCreateMediaArgs = {
  input: EntityPageCreateMediaMutationInput;
};

/** The root mutation type */
export type MutationEntityPageCreateVideoMediaArgs = {
  input: EntityPageCreateVideoMediaMutationInput;
};

/** The root mutation type */
export type MutationEntityPageDeleteArgs = {
  input: EntityPageDeleteInput;
};

/** The root mutation type */
export type MutationEntityPageDeleteContactUserArgs = {
  input: EntityPageDeleteContactUserMutationInput;
};

/** The root mutation type */
export type MutationEntityPageDeleteCoverArgs = {
  input: EntityPageDeleteCoverMutationInput;
};

/** The root mutation type */
export type MutationEntityPageDeleteDocumentArgs = {
  input: EntityPageDeleteDocumentMutationInput;
};

/** The root mutation type */
export type MutationEntityPageDeleteLocationArgs = {
  input: EntityPageDeleteLocationMutationInput;
};

/** The root mutation type */
export type MutationEntityPageDeleteMediaArgs = {
  input: EntityPageDeleteMediaMutationInput;
};

/** The root mutation type */
export type MutationEntityPageFollowArgs = {
  input: EntityPageFollowersMutationInput;
};

/** The root mutation type */
export type MutationEntityPageHardDeleteArgs = {
  input: EntityPageDeleteInput;
};

/** The root mutation type */
export type MutationEntityPageModuleUpdateConfigurationArgs = {
  input: EntityPageModuleConfigInput;
};

/** The root mutation type */
export type MutationEntityPageTrackVisitorArgs = {
  input: EntityPageTrackVisitorMutationInput;
};

/** The root mutation type */
export type MutationEntityPageUnfollowArgs = {
  input: EntityPageFollowersMutationInput;
};

/** The root mutation type */
export type MutationEntityPageUpdateAboutContentArgs = {
  input: EntityPageAboutContentMutationInput;
};

/** The root mutation type */
export type MutationEntityPageUpdateConfigurationArgs = {
  input: EntityPageConfigInput;
};

/** The root mutation type */
export type MutationEntityPageUpdateContactUserArgs = {
  input: EntityPageUpdateContactUserInput;
};

/** The root mutation type */
export type MutationEntityPageUpdateContactUsersCatalogueArgs = {
  input: EntityPageUpdateContactUsersCatalogueMutationInput;
};

/** The root mutation type */
export type MutationEntityPageUpdateCoverArgs = {
  input: EntityPageUpdateCoverMutationInput;
};

/** The root mutation type */
export type MutationEntityPageUpdateDocumentArgs = {
  input: EntityPageUpdateDocumentMutationInput;
};

/** The root mutation type */
export type MutationEntityPageUpdateLocationArgs = {
  input: EntityPageUpdateLocationInput;
};

/** The root mutation type */
export type MutationEntityPageUpdateLogoArgs = {
  input: EntityPageUpdateLogoMutationInput;
};

/** The root mutation type */
export type MutationEntityPageUpdateMediaArgs = {
  input: EntityPageUpdateMediaMutationInput;
};

/** The root mutation type */
export type MutationEntityPageUpdateMediaGalleryArgs = {
  input: EntityPageUpdateMediaGalleryMutationInput;
};

/** The root mutation type */
export type MutationEntityPageUpdatePublicationStatusArgs = {
  input: EntityPagePublicationStatusInput;
};

/** The root mutation type */
export type MutationEntityPageUpdateVideoMediaArgs = {
  input: EntityPageUpdateVideoMediaMutationInput;
};

/** The root mutation type */
export type MutationErecruitingSourcingServiceUpdateBriefingCandidateArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceUpdateBriefingCandidateStatusInput>;
};

/** The root mutation type */
export type MutationExternalProfileVisitArgs = {
  input: ExternalVompInput;
};

/** The root mutation type */
export type MutationFeatureSwitchesExperimentsForceVariantArgs = {
  input: FeatureSwitchesExperimentsForceVariantInput;
};

/** The root mutation type */
export type MutationFollowersFollowArgs = {
  input: FollowersMutationInput;
};

/** The root mutation type */
export type MutationFollowersUnfollowArgs = {
  input: FollowersMutationInput;
};

/** The root mutation type */
export type MutationGenerateVitaAboutMeSuggestionArgs = {
  input: CreateVitaDocumentInput;
};

/** The root mutation type */
export type MutationGenerateVitaCoverLetterSuggestionArgs = {
  input: CreateVitaCoverLetterInput;
};

/** The root mutation type */
export type MutationIndustriesPreferenceArgs = {
  input: UpdateIndustriesPreferenceInput;
};

/** The root mutation type */
export type MutationInternalProfileVisitArgs = {
  input: InternalVompInput;
};

/** The root mutation type */
export type MutationJobBookingRequestArgs = {
  input: JobBookingmutationInput;
};

/** The root mutation type */
export type MutationJobCreateApplyIntentionArgs = {
  input: JobUserApplyIntentionInput;
};

/** The root mutation type */
export type MutationJobCreateBookmarkArgs = {
  input: JobBookmarkMutationInput;
};

/** The root mutation type */
export type MutationJobCreateReportArgs = {
  input: JobCreateReportInput;
};

/** The root mutation type */
export type MutationJobDeleteBookmarkArgs = {
  input: JobBookmarkMutationInput;
};

/** The root mutation type */
export type MutationJobDeleteVisitArgs = {
  input: JobDeleteVisitInput;
};

/** The root mutation type */
export type MutationJobPreferenceUserFlagsArgs = {
  input: UpdateJobPreferenceUserFlagsInput;
};

/** The root mutation type */
export type MutationJobPreferencesJobSeekerStatusArgs = {
  input: UpdateJobPreferencesJobSeekerStatusInput;
};

/** The root mutation type */
export type MutationJobRecoDeclineRecommendationArgs = {
  recommendationId?: InputMaybe<Scalars['ID']['input']>;
};

/** The root mutation type */
export type MutationJobRegisterVisitArgs = {
  input: JobRegisterVisitInput;
};

/** The root mutation type */
export type MutationJobSeekerGuidanceUpdateActionArgs = {
  input: JobSeekerGuidanceUpdateActionInput;
};

/** The root mutation type */
export type MutationJobSubmitJobApplicationArgs = {
  input: JobApplicationMutationInput;
};

/** The root mutation type */
export type MutationJobTitlesTargetPositionsArgs = {
  input: UpdateJobTitlesTargetPositionsPreferenceInput;
};

/** The root mutation type */
export type MutationJobTitlesUnpreferredPositionsArgs = {
  input: UpdateJobTitlesUnpreferredPositionsPreferenceInput;
};

/** The root mutation type */
export type MutationJobUpsertBookmarkArgs = {
  input: JobBookmarkUpsertMutationInput;
};

/** The root mutation type */
export type MutationJobWishesIdealEmployersArgs = {
  input: UpdateJobWishesIdealEmployersInput;
};

/** The root mutation type */
export type MutationJobWishesPreferenceArgs = {
  input: PreferencesJobWishesInput;
};

/** The root mutation type */
export type MutationJobsMarkRecommendationsAsSeenArgs = {
  input: JobsMarkRecommendationsAsSeenInput;
};

/** The root mutation type */
export type MutationLeadAdFormSubmissionCreateArgs = {
  input: LeadAdFormSubmissionCreateInput;
};

/** The root mutation type */
export type MutationLlmGatewayInteractionTrackingArgs = {
  input?: InputMaybe<LlmGatewayInteractionTrackingInput>;
};

/** The root mutation type */
export type MutationLocationsPreferenceArgs = {
  input: UpdateLocationsPreferenceInput;
};

/** The root mutation type */
export type MutationLoginUserSecurityIssuesAcknowledgeArgs = {
  input: LoginUserSecurityIssuesAcknowledgeMutationInput;
};

/** The root mutation type */
export type MutationMarkAsReadMessengerChatArgs = {
  input: MarkAsReadMessengerChatInput;
};

/** The root mutation type */
export type MutationMarkAsSeenArgs = {
  input?: InputMaybe<MarkAsSeenInput>;
};

/** The root mutation type */
export type MutationMarkAsUnreadMessengerChatArgs = {
  input: MarkAsUnreadMessengerChatInput;
};

/** The root mutation type */
export type MutationMeetingRoomAddAttendeeArgs = {
  input: MeetingRoomAddAttendeeInput;
};

/** The root mutation type */
export type MutationMessengerExecuteOneClickActionArgs = {
  input: MessengerExecuteOneClickActionInput;
};

/** The root mutation type */
export type MutationMessengerUploadRequestArgs = {
  input: MessengerUploadRequestInput;
};

/** The root mutation type */
export type MutationMoveonCompanyRecommendationsFeedbackInputArgs = {
  input: MoveonCompanyRecommendationsFeedbackInput;
};

/** The root mutation type */
export type MutationMoveonCreateAssessmentInputArgs = {
  input: MoveonCreateAssessmentInput;
};

/** The root mutation type */
export type MutationMymkDeclineRecommendationArgs = {
  recommendationId?: InputMaybe<Scalars['ID']['input']>;
};

/** The root mutation type */
export type MutationNetworkAssignTagArgs = {
  input: NetworkTaggingInput;
};

/** The root mutation type */
export type MutationNetworkBlockedUsersBlockArgs = {
  input: NetworkBlockedUsersBlockInput;
};

/** The root mutation type */
export type MutationNetworkBlockedUsersUnblockArgs = {
  input: NetworkBlockedUsersUnblockInput;
};

/** The root mutation type */
export type MutationNetworkClientSettingSetArgs = {
  input: NetworkClientSettingsInput;
};

/** The root mutation type */
export type MutationNetworkContactRequestsConfirmArgs = {
  input: ContactRequestsConfirmInput;
};

/** The root mutation type */
export type MutationNetworkContactRequestsDeclineArgs = {
  input: ContactRequestsDeclineInput;
};

/** The root mutation type */
export type MutationNetworkContactRequestsMarkSeenArgs = {
  input: ContactRequestsMarkSeenInput;
};

/** The root mutation type */
export type MutationNetworkContactRequestsRevokeArgs = {
  input: ContactRequestsRevokeInput;
};

/** The root mutation type */
export type MutationNetworkContactRequestsSendArgs = {
  input: ContactRequestsSendInput;
};

/** The root mutation type */
export type MutationNetworkContactRequestsSendMultipleArgs = {
  input: ContactRequestsSendMultipleInput;
};

/** The root mutation type */
export type MutationNetworkContactRequestsSpamReportArgs = {
  input: ContactRequestsSpamReportInput;
};

/** The root mutation type */
export type MutationNetworkContactRequestsTokenConfirmArgs = {
  input: ContactRequestsTokenConfirmInput;
};

/** The root mutation type */
export type MutationNetworkContactsDeleteArgs = {
  input: ContactsDeleteInput;
};

/** The root mutation type */
export type MutationNetworkCreateTagArgs = {
  input: NetworkCreateTagInput;
};

/** The root mutation type */
export type MutationNetworkDeleteTagArgs = {
  input: NetworkDeleteTagInput;
};

/** The root mutation type */
export type MutationNetworkSetNoteArgs = {
  input: NoteInputType;
};

/** The root mutation type */
export type MutationNetworkUnassignTagArgs = {
  input: NetworkTaggingInput;
};

/** The root mutation type */
export type MutationNetworkUserProximityIdSetArgs = {
  input: NetworkUserProximityIdSetInput;
};

/** The root mutation type */
export type MutationNotificationMarkAsOpenedArgs = {
  input: NotificationMarkAsOpenedInput;
};

/** The root mutation type */
export type MutationNotificationsBadgesSeenArgs = {
  input: NotificationsBadgeNamesInput;
};

/** The root mutation type */
export type MutationOnboardingDeveloperUpdateJourneyArgs = {
  input: OnboardingDeveloperJourneyInput;
};

/** The root mutation type */
export type MutationOnboardingUpdateJourneyArgs = {
  input?: InputMaybe<OnboardingJourneyMetadataInput>;
};

/** The root mutation type */
export type MutationOrganizerInfoSaveArgs = {
  input: AtsCredentialsInput;
};

/** The root mutation type */
export type MutationParseMessageTemplateArgs = {
  input: ParseMessageInput;
};

/** The root mutation type */
export type MutationPartnerCreateCodeArgs = {
  input: PartnerCreateCodeMutationInput;
};

/** The root mutation type */
export type MutationPostBlacklistedSkillsArgs = {
  input: BlacklistedSkillsInput;
};

/** The root mutation type */
export type MutationPostModuleSettingsArgs = {
  input: ModuleSettingsInput;
};

/** The root mutation type */
export type MutationPostSalaryInsightsArgs = {
  input: SalaryInsightsInput;
};

/** The root mutation type */
export type MutationPostingsCreatePostingArgs = {
  input: PostingsCreatePostingInput;
};

/** The root mutation type */
export type MutationPostingsCreateRssImporterArgs = {
  input: PostingsCreateRssImporterInput;
};

/** The root mutation type */
export type MutationPostingsCreateStoryArgs = {
  input: PostingsCreateStoryInput;
};

/** The root mutation type */
export type MutationPostingsCreateStoryGroupArgs = {
  input: PostingsCreateStoryGroupInput;
};

/** The root mutation type */
export type MutationPostingsCreateTwitterImporterArgs = {
  input: PostingsCreateTwitterImporterInput;
};

/** The root mutation type */
export type MutationPostingsCreateVideoArgs = {
  input: PostingsVideoCreateInput;
};

/** The root mutation type */
export type MutationPostingsDeleteMyMentionsArgs = {
  input: PostingsDeleteMyMentionsInput;
};

/** The root mutation type */
export type MutationPostingsDeletePostingArgs = {
  input: PostingsDeletePostingInput;
};

/** The root mutation type */
export type MutationPostingsDeleteRssImporterArgs = {
  input?: InputMaybe<PostingsDeleteImporterInput>;
};

/** The root mutation type */
export type MutationPostingsDeleteStoryArgs = {
  input: PostingsDeleteStoryInput;
};

/** The root mutation type */
export type MutationPostingsDeleteTwitterImporterArgs = {
  input?: InputMaybe<PostingsDeleteImporterInput>;
};

/** The root mutation type */
export type MutationPostingsHidePostingArgs = {
  input: PostingsToggleHiddenPostingInput;
};

/** The root mutation type */
export type MutationPostingsPinActivityArgs = {
  input: PostingsPinActivityInput;
};

/** The root mutation type */
export type MutationPostingsPinActivityByTargetArgs = {
  input: PostingsPinActivityByTargetInput;
};

/** The root mutation type */
export type MutationPostingsPopulateStoryGroupArgs = {
  input: PostingsPopulateStoryGroupInput;
};

/** The root mutation type */
export type MutationPostingsPublishPostingArgs = {
  input: PostingsPublishPostingInput;
};

/** The root mutation type */
export type MutationPostingsUnhidePostingArgs = {
  input: PostingsToggleHiddenPostingInput;
};

/** The root mutation type */
export type MutationPostingsUnpinActivityArgs = {
  input: PostingsUnpinActivityInput;
};

/** The root mutation type */
export type MutationPostingsUnpinActivityByTargetArgs = {
  input: PostingsUnpinActivityByTargetInput;
};

/** The root mutation type */
export type MutationPostingsUpdateLinkShareArgs = {
  input: PostingsUpdateLinkShareInput;
};

/** The root mutation type */
export type MutationPostingsUpdatePostingArgs = {
  input: PostingsUpdatePostingInput;
};

/** The root mutation type */
export type MutationPostingsVoteForPollOptionArgs = {
  input: PostingsVoteForPollOptionInput;
};

/** The root mutation type */
export type MutationPreferenceSettingsArgs = {
  input: PreferencesSettingsInput;
};

/** The root mutation type */
export type MutationPreferenceSettingsBlockedCompaniesArgs = {
  input: UpdatePreferencesSettingsBlockedCompaniesInput;
};

/** The root mutation type */
export type MutationPremiumPartnerCreateCodeArgs = {
  input: PremiumPartnerCreateCodeMutationInput;
};

/** The root mutation type */
export type MutationProJobsMarkAsIdealEmployerArgs = {
  input?: InputMaybe<ProJobsIdealEmployerMutationInput>;
};

/** The root mutation type */
export type MutationProJobsUnmarkAsIdealEmployerArgs = {
  input?: InputMaybe<ProJobsIdealEmployerMutationInput>;
};

/** The root mutation type */
export type MutationProbusinessCreateLeadArgs = {
  input: ProbusinessCreateLeadInput;
};

/** The root mutation type */
export type MutationProbusinessDeleteLeadArgs = {
  input?: InputMaybe<ProbusinessDeleteLeadInput>;
};

/** The root mutation type */
export type MutationProfileAboutMeUpdateArgs = {
  input: ProfileAboutMeUpdateInput;
};

/** The root mutation type */
export type MutationProfileAcademicTitleUpdateDeleteArgs = {
  input: ProfileUpdateDeleteInput;
};

/** The root mutation type */
export type MutationProfileBusinessContactUpdateDeleteArgs = {
  input: ProfileUpdateDeleteInput;
};

/** The root mutation type */
export type MutationProfileHavesUpdateDeleteArgs = {
  input: ProfileUpdateDeleteInput;
};

/** The root mutation type */
export type MutationProfileInterestsUpdateArgs = {
  input: ProfileInterestsUpdateInput;
};

/** The root mutation type */
export type MutationProfileLanguagesUpdateArgs = {
  input: ProfileLanguagesUpdateInput;
};

/** The root mutation type */
export type MutationProfilePersonalDetailsUpdateArgs = {
  input: PersonalDetailsUpdateInput;
};

/** The root mutation type */
export type MutationProfileSkillsAddArgs = {
  input: ProfileSkillsAddInput;
};

/** The root mutation type */
export type MutationProfileSkillsUpdateArgs = {
  input: ProfileSkillsUpdateInput;
};

/** The root mutation type */
export type MutationProfileTimelineAddEntryArgs = {
  input: ProfileTimelineAddEntryInput;
};

/** The root mutation type */
export type MutationProfileTimelineDeleteEntryArgs = {
  input: ProfileTimelineDeleteEntryInput;
};

/** The root mutation type */
export type MutationProfileTimelineEditEntryArgs = {
  input: ProfileTimelineEditEntryInput;
};

/** The root mutation type */
export type MutationProfileVisitArgs = {
  input?: InputMaybe<ProfileVisitInput>;
};

/** The root mutation type */
export type MutationProfileWantsUpdateArgs = {
  input: ProfileWantsUpdateInput;
};

/** The root mutation type */
export type MutationProfileWantsUpdateDeleteArgs = {
  input: ProfileUpdateDeleteInput;
};

/** The root mutation type */
export type MutationProfileWorkExperienceUpdateDeleteArgs = {
  input: ProfileUpdateDeleteInput;
};

/** The root mutation type */
export type MutationProjobsAddAllowlistUserArgs = {
  input: ProjobsAddAllowlistUserInput;
};

/** The root mutation type */
export type MutationProjobsAddBlockedCompaniesArgs = {
  input: ProjobsAddBlockedCompaniesInput;
};

/** The root mutation type */
export type MutationProjobsAddBlocklistUserArgs = {
  input: ProjobsAddBlocklistUserInput;
};

/** The root mutation type */
export type MutationProjobsAddIdealEmployersArgs = {
  input: ProjobsAddIdealEmployersInput;
};

/** The root mutation type */
export type MutationProjobsAvoidArgs = {
  input: ProjobsAvoidInput;
};

/** The root mutation type */
export type MutationProjobsCitiesArgs = {
  input: ProjobsCitiesInput;
};

/** The root mutation type */
export type MutationProjobsDeleteDocumentArgs = {
  input: ProjobsDeleteDocumentInput;
};

/** The root mutation type */
export type MutationProjobsDisciplineArgs = {
  input: ProjobsDisciplineInput;
};

/** The root mutation type */
export type MutationProjobsIndustriesArgs = {
  input: ProjobsIndustriesInput;
};

/** The root mutation type */
export type MutationProjobsPositionsArgs = {
  input: ProjobsPositionsInput;
};

/** The root mutation type */
export type MutationProjobsRemoveAllowlistUserArgs = {
  input: ProjobsRemoveAllowlistUserInput;
};

/** The root mutation type */
export type MutationProjobsRemoveBlockedCompanyArgs = {
  input: ProjobsRemoveBlockedCompanyInput;
};

/** The root mutation type */
export type MutationProjobsRemoveBlocklistUserArgs = {
  input: ProjobsRemoveBlocklistUserInput;
};

/** The root mutation type */
export type MutationProjobsSalaryExpectationArgs = {
  input: ProjobsSalaryExpectationInput;
};

/** The root mutation type */
export type MutationProjobsSettingSeekingStatusArgs = {
  input: ProjobsSeekingStatusInput;
};

/** The root mutation type */
export type MutationProjobsSettingVisibilityArgs = {
  input: ProjobsVisibilityInput;
};

/** The root mutation type */
export type MutationProjobsUpdateContactOptionsArgs = {
  input: ProjobsContactOptionsInput;
};

/** The root mutation type */
export type MutationProjobsUpdateDocumentArgs = {
  input: ProjobsUpdateDocumentInput;
};

/** The root mutation type */
export type MutationProjobsUploadDocumentArgs = {
  input: ProjobsUploadDocumentInput;
};

/** The root mutation type */
export type MutationProjobsWillingnessToTravelArgs = {
  input: ProjobsWillingnessToTravelInput;
};

/** The root mutation type */
export type MutationProjobsWorkingHoursArgs = {
  input: ProjobsWorkingHoursInput;
};

/** The root mutation type */
export type MutationPurchaseCreateCampaignArgs = {
  input: PurchaseCreateCampaignInput;
};

/** The root mutation type */
export type MutationPurchaseCreateCampaignGroupArgs = {
  input: PurchaseCreateCampaignGroupInput;
};

/** The root mutation type */
export type MutationPurchaseDeleteCampaignGroupArgs = {
  input: PurchaseDeleteCampaignGroupInput;
};

/** The root mutation type */
export type MutationPurchaseEnablePlatformArgs = {
  input: PurchaseEnablePlatformInput;
};

/** The root mutation type */
export type MutationPurchasePublishCampaignArgs = {
  input: PurchasePublishCampaignInput;
};

/** The root mutation type */
export type MutationPurchaseRestoreCampaignGroupResolverArgs = {
  input: PurchaseRestoreCampaignGroup;
};

/** The root mutation type */
export type MutationPurchaseUpdateCampaignArgs = {
  input: PurchaseUpdateCampaignInput;
};

/** The root mutation type */
export type MutationPurchaseUpdateCampaignGroupArgs = {
  input: PurchaseUpdateCampaignGroupInput;
};

/** The root mutation type */
export type MutationPurchaseWebOrderArgs = {
  input: WebOrderInput;
};

/** The root mutation type */
export type MutationRegenerateBackupCodeTwoFaSettingsArgs = {
  input: TwoFaSettingsBackupCodeRegenerationInput;
};

/** The root mutation type */
export type MutationRegenerateInvoicesArgs = {
  input: RegenerateInvoicesInput;
};

/** The root mutation type */
export type MutationRequestVitaCoverLetterRenderingArgs = {
  input: RequestVitaCoverLetterRenderingInput;
};

/** The root mutation type */
export type MutationRequestVitaDocumentTokenRenderingArgs = {
  input: RequestVitaDocumentRenderingInput;
};

/** The root mutation type */
export type MutationRevertComplaintReportArgs = {
  input: ComplaintsReportInput;
};

/** The root mutation type */
export type MutationSalaryExpectationsPreferenceArgs = {
  input: UpdateSalaryExpectationsPreferenceInput;
};

/** The root mutation type */
export type MutationSaveUserFeedbackOnOutdatedFieldsArgs = {
  input: UserFeedbackRequest;
};

/** The root mutation type */
export type MutationSeatManagerCreateAssignmentArgs = {
  input: SeatAssignmentInput;
};

/** The root mutation type */
export type MutationSeatManagerDeleteAssignmentArgs = {
  input: SeatAssignmentInput;
};

/** The root mutation type */
export type MutationShareErecruitingSourcingServiceBriefingArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceShareBriefingInput>;
};

/** The root mutation type */
export type MutationSignalDeleteArgs = {
  input: SignalDeleteInput;
};

/** The root mutation type */
export type MutationSignalMarkInteractedArgs = {
  input: SignalMarkInteractedInput;
};

/** The root mutation type */
export type MutationSignalsStackMarkSeenArgs = {
  input: SignalsStackMarkSeenInput;
};

/** The root mutation type */
export type MutationSocialCreateCommentArgs = {
  input: SocialCreateCommentInput;
};

/** The root mutation type */
export type MutationSocialCreateExternalLinkArgs = {
  input: SocialCreateExternalLinkInput;
};

/** The root mutation type */
export type MutationSocialCreateReactionArgs = {
  input: SocialCreateReactionInput;
};

/** The root mutation type */
export type MutationSocialDeleteCommentArgs = {
  input: SocialDeleteCommentInput;
};

/** The root mutation type */
export type MutationSocialDeleteCommentMentionsArgs = {
  input: SocialDeleteCommentMentionsInput;
};

/** The root mutation type */
export type MutationSocialDeleteReactionArgs = {
  input: SocialDeleteReactionInput;
};

/** The root mutation type */
export type MutationSocialShareToGroupArgs = {
  input: SocialShareToGroupInput;
};

/** The root mutation type */
export type MutationSocialShareToMessengerArgs = {
  input: SocialShareToMessengerInput;
};

/** The root mutation type */
export type MutationSocialShareToStartpageArgs = {
  input: SocialShareToStartpageInput;
};

/** The root mutation type */
export type MutationSocialUpdateCommentArgs = {
  input: SocialUpdateCommentInput;
};

/** The root mutation type */
export type MutationSocialUpdateReactionArgs = {
  input: SocialUpdateReactionInput;
};

/** The root mutation type */
export type MutationStartUserDataInquiryArgs = {
  input: UserDataInquiryInput;
};

/** The root mutation type */
export type MutationSubmitReviewArgs = {
  input?: InputMaybe<SubmitReviewInput>;
};

/** The root mutation type */
export type MutationTrackAdClickByIntIdArgs = {
  input: AdsTrackingIntIdInput;
};

/** The root mutation type */
export type MutationTrackAdImpressionByIntIdArgs = {
  input: AdsTrackingIntIdInput;
};

/** The root mutation type */
export type MutationTriggerVitaCoverLetterSuggestionArgs = {
  input: CreateVitaCoverLetterInput;
};

/** The root mutation type */
export type MutationUnsubscribeLoSearchAlertArgs = {
  input: LoSearchAlertUnsubscribeInput;
};

/** The root mutation type */
export type MutationUpdateAccountDeactivationSettingsArgs = {
  input: AccountDeactivationSettingsInput;
};

/** The root mutation type */
export type MutationUpdateAccountDeletionSettingsArgs = {
  input: AccountDeletionSettingsInput;
};

/** The root mutation type */
export type MutationUpdateAccountSettingsArgs = {
  input: AccountSettingsInput;
};

/** The root mutation type */
export type MutationUpdateActivitiesSettingsArgs = {
  input: ActivitiesSettingsInput;
};

/** The root mutation type */
export type MutationUpdateB2BProfileSettingsArgs = {
  input?: InputMaybe<B2BProfileSettingsInput>;
};

/** The root mutation type */
export type MutationUpdateBpayPaymentMethodArgs = {
  input: BpayPaymentMethodInput;
};

/** The root mutation type */
export type MutationUpdateClientBillingAddressArgs = {
  input: ClientBillingAddressInput;
  productGroup: PaymentClientProductGroup;
};

/** The root mutation type */
export type MutationUpdateCoreSettingsArgs = {
  input: CoreSettingsInput;
};

/** The root mutation type */
export type MutationUpdateDataSharingSettingsArgs = {
  input: DataSharingSettingsInput;
};

/** The root mutation type */
export type MutationUpdateErecruitingSourcingServiceBriefingArchiveStatusArgs =
  {
    input?: InputMaybe<ErecruitingSourcingServiceArchiveStatusInput>;
  };

/** The root mutation type */
export type MutationUpdateErecruitingSourcingServiceBriefingMetadataArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceBriefingMetadataInput>;
};

/** The root mutation type */
export type MutationUpdateErecruitingSourcingServiceCandidateNoteArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceUpdateCandidateNoteInput>;
};

/** The root mutation type */
export type MutationUpdateErecruitingSourcingServiceClientCandidateNoteArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceUpdateClientCandidateNoteInput>;
};

/** The root mutation type */
export type MutationUpdateErecruitingSourcingServiceMessageRemindersMetadataArgs =
  {
    input?: InputMaybe<ErecruitingSourcingServiceUpdateMessageReminderMetadataInput>;
  };

/** The root mutation type */
export type MutationUpdateHiringProfileArgs = {
  input?: InputMaybe<UpdateHiringProfileInput>;
};

/** The root mutation type */
export type MutationUpdateJobRecoFeedbackArgs = {
  input: JobRecoFeedbackInput;
};

/** The root mutation type */
export type MutationUpdateLegalInformationArgs = {
  input: LegalInformationInput;
};

/** The root mutation type */
export type MutationUpdateLoginSettingsArgs = {
  input: LoginSettingsInput;
};

/** The root mutation type */
export type MutationUpdateMessengerBannerArgs = {
  input: UpdateMessengerBannerInput;
};

/** The root mutation type */
export type MutationUpdateMessengerMessageTemplateArgs = {
  input: UpdateMessengerMessageTemplateInput;
};

/** The root mutation type */
export type MutationUpdateMessengerSettingsArgs = {
  input: MessengerSettingsInput;
};

/** The root mutation type */
export type MutationUpdateNetworkSettingsArgs = {
  input: NetworkSettingsInput;
};

/** The root mutation type */
export type MutationUpdateNotificationSettingsArgs = {
  input: NotificationSettingsCollectionInput;
};

/** The root mutation type */
export type MutationUpdatePayBilClientArgs = {
  input: PayBilClientMutationInput;
};

/** The root mutation type */
export type MutationUpdatePaymentAccountArgs = {
  input?: InputMaybe<PaymentAccountInput>;
};

/** The root mutation type */
export type MutationUpdatePaymentMethodArgs = {
  input?: InputMaybe<PaymentAccountInput>;
};

/** The root mutation type */
export type MutationUpdatePremiumSwitchSettingsArgs = {
  input: PremiumSwitchSettingsInput;
};

/** The root mutation type */
export type MutationUpdateProfileModuleStoreItemsArgs = {
  input: Array<UpdateProfileModuleStoreItemsInput>;
};

/** The root mutation type */
export type MutationUpdateProfileSettingsArgs = {
  input: ProfileSettingsInput;
};

/** The root mutation type */
export type MutationUpdateProfilesLoggedoutSettingsArgs = {
  input: ProfilesLoggedoutSettingsInput;
};

/** The root mutation type */
export type MutationUpdateReportArgs = {
  input?: InputMaybe<UpdateReportInput>;
};

/** The root mutation type */
export type MutationUpdateResourceUserArgs = {
  input: TeamPermissionsUpdateResourceUserInput;
};

/** The root mutation type */
export type MutationUpdateSearchAlertSettingsArgs = {
  input: UserSettingsUpdateInput;
};

/** The root mutation type */
export type MutationUpdateSocialSettingsArgs = {
  input: SocialSettingsInput;
};

/** The root mutation type */
export type MutationUpdateTwoFaSettingsArgs = {
  input: TwoFaSettingsUpdateInput;
};

/** The root mutation type */
export type MutationUpdateWebPushSubscriptionArgs = {
  input: UpdateWebPushSubscriptionInput;
};

/** The root mutation type */
export type MutationUpdateXtmProjectArgs = {
  input?: InputMaybe<XtmUpdateProjectInput>;
};

/** The root mutation type */
export type MutationUploadRequestArgs = {
  input: UploadRequestInput;
};

/** The root mutation type */
export type MutationUpsellCancellationAcceptCounterOfferArgs = {
  input?: InputMaybe<UpsellCancellationAcceptCounterOfferApplyBonus>;
};

/** The root mutation type */
export type MutationUpsellCancellationRequestArgs = {
  input: UpsellCancellationRequestInput;
};

/** The root mutation type */
export type MutationUserDataInquiryTemporaryDownloadUrlArgs = {
  input: UserDataInquiryInput;
};

/** The root mutation type */
export type MutationUserRemindJobApplicationArgs = {
  input: UserRemindMutationInput;
};

/** The root mutation type */
export type MutationVideoCreateArgs = {
  input: VideoCreationInput;
};

/** The root mutation type */
export type MutationVideoCustomThumbnailCreateArgs = {
  input: VideoCustomThumbnailCreationInput;
};

/** The root mutation type */
export type MutationVideoCustomThumbnailDeleteArgs = {
  input: VideoCustomThumbnailDeletionInput;
};

/** The root mutation type */
export type MutationVideoCustomThumbnailUpdateArgs = {
  input: VideoCustomThumbnailUpdateInput;
};

/** The root mutation type */
export type MutationVideoDeleteArgs = {
  input: VideoDeletionInput;
};

/** The root mutation type */
export type MutationVideoImageAttachmentCreateArgs = {
  input: VideoImageAttachmentCreationInput;
};

/** The root mutation type */
export type MutationVideoResourceCreateArgs = {
  input: VideoResourceCreationInput;
};

/** The root mutation type */
export type MutationVideoStartEncodingArgs = {
  input: VideoStartEncodingInput;
};

/** The root mutation type */
export type MutationVideoSubtitleCreateArgs = {
  input: VideoSubtitleCreationInput;
};

/** The root mutation type */
export type MutationVideoSubtitleDeleteArgs = {
  input: VideoSubtitleDeletionInput;
};

/** The root mutation type */
export type MutationVideoSubtitleUpdateArgs = {
  input: VideoSubtitleUpdateInput;
};

/** The root mutation type */
export type MutationVideoTrackingArgs = {
  input: VideoTrackingEventInput;
};

/** The root mutation type */
export type MutationVideoUpdateArgs = {
  input: VideoUpdateInput;
};

/** The root mutation type */
export type MutationVisitSearchAlertArgs = {
  input: SearchAlertId;
};

/** The root mutation type */
export type MutationVisitsViewArgs = {
  input: VisitsViewInput;
};

/** The root mutation type */
export type MutationWorkingHoursPreferenceArgs = {
  input: UpdateWorkingHoursPreferenceInput;
};

/** The root mutation type */
export type MutationWorkplacePreferenceArgs = {
  input: UpdateWorkplacePreferenceInput;
};

/** The root mutation type */
export type MutationXingIdAddContactArgs = {
  input: XingIdAddContactInput;
};

/** The root mutation type */
export type MutationXingIdConfirmContactArgs = {
  input: XingIdConfirmContactInput;
};

/** The root mutation type */
export type MutationXingIdDeclineContactRequestArgs = {
  input: XingIdDeclineContactRequestInput;
};

/** The root mutation type */
export type MutationXingIdDeleteContactArgs = {
  input: XingIdDeleteContactInput;
};

/** The root mutation type */
export type MutationXingIdFollowArgs = {
  input: XingIdFollowInput;
};

/** The root mutation type */
export type MutationXingIdReorderOccupationsArgs = {
  input: XingIdReorderOccupationsInput;
};

/** The root mutation type */
export type MutationXingIdUnfollowArgs = {
  input: XingIdUnfollowInput;
};

/** The root mutation type */
export type MutationXingIdUpdateContactDetailsArgs = {
  input: XingIdUpdateContactDetailsInput;
};

/** The root mutation type */
export type MutationXingIdUpdateHeaderImageArgs = {
  input: XingIdUpdateHeaderImageInput;
};

/** The root mutation type */
export type MutationXingIdUpdateProfileImageArgs = {
  input: XingIdUpdateProfileImageInput;
};

/** The root mutation type */
export type MutationXingIdUpdateStatusArgs = {
  input: XingIdUpdateStatusInput;
};

/** The root mutation type */
export type MutationXtmProjectCampaignLimitationsArgs = {
  input?: InputMaybe<XtmProjectCampaignLimitationsInput>;
};

/** The root mutation type */
export type MutationXtmProjectCreateUpdateCampaignArgs = {
  input?: InputMaybe<XtmProjectCreateUpdateCampaignInput>;
};

/** The root mutation type */
export type MutationXtmProjectSendCampaignArgs = {
  input?: InputMaybe<XtmProjectSendCampaignInput>;
};

/** XING ID Occupations */
export type MyXingIdOccupation = HasDerivedGlobalId & {
  __typename?: 'MyXingIdOccupation';
  /**
   * Original bucket for this occupation. If placed in VISIBLE bucket, this value can be used to sort it back to the correct bucket.
   *
   * Therefore, except when it's placed in VISIBLE bucket, this bucketID will always match its parent Bucket.
   */
  bucketId: XingIdOccupationsBucketType;
  /** Globally unique id for this instance based on typename and id */
  globalId: Scalars['GlobalID']['output'];
  id: Scalars['ID']['output'];
  /** Original position in Bucket. 1 is sorted to the top */
  position?: Maybe<Scalars['Int']['output']>;
  /** URN of the reference */
  referenceUrn?: Maybe<Scalars['GlobalID']['output']>;
  /** Displayable string to represent this Occupation. Plain text */
  summary: Scalars['String']['output'];
};

/** A Bucket of Occupations */
export type MyXingIdOccupationBucket = Localizable & {
  __typename?: 'MyXingIdOccupationBucket';
  id: XingIdOccupationsBucketType;
  /** True if an upsell indicator should be shown */
  isUpsellRequiredForOccupations: Scalars['Boolean']['output'];
  /** The raw text resource key from Phraseapp */
  localizationKey: Scalars['String']['output'];
  /** The value localized based on the `Accept-Language` header */
  localizationValue: Scalars['String']['output'];
  /** Amount of elements which can be added to this bucket. Null equals unlimited elements */
  maxElements?: Maybe<Scalars['Int']['output']>;
  /** List of occupations placed in this bucket */
  occupations: Array<MyXingIdOccupation>;
};

export type MymkBucket = {
  __typename?: 'MymkBucket';
  name: Scalars['String']['output'];
  recommendations: Array<MymkCombinedRecommendation>;
};

export type MymkBucketCollectionsRecommenderConnection = HasCursorPagination &
  RecommenderResponse & {
    __typename?: 'MymkBucketCollectionsRecommenderConnection';
    buckets?: Maybe<MymkBuckets>;
    edges: Array<MymkCombinedRecommendationEdge>;
    /** #virtual */
    pageInfo: PageInfo;
    requestTrackingToken: Scalars['String']['output'];
    service: Scalars['String']['output'];
    total: Scalars['Int']['output'];
  };

export type MymkBuckets = {
  __typename?: 'MymkBuckets';
  collection: Array<MymkBucket>;
  total: Scalars['Int']['output'];
};

export type MymkCombinedRecommendation =
  | MymkInviteRecommendation
  | MymkRecommendation;

export type MymkCombinedRecommendationEdge = PaginationEdge & {
  __typename?: 'MymkCombinedRecommendationEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: MymkCombinedRecommendation;
};

export type MymkCombinedRecommenderConnection = HasCursorPagination &
  RecommenderResponse & {
    __typename?: 'MymkCombinedRecommenderConnection';
    edges: Array<MymkCombinedRecommendationEdge>;
    /** #virtual */
    pageInfo: PageInfo;
    requestTrackingToken: Scalars['String']['output'];
    service: Scalars['String']['output'];
    total: Scalars['Int']['output'];
  };

export type MymkDeclineRecommendationError = {
  __typename?: 'MymkDeclineRecommendationError';
  /** #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type MymkDeclineRecommendationResult = {
  __typename?: 'MymkDeclineRecommendationResult';
  /** #soft-logged-in */
  error?: Maybe<MymkDeclineRecommendationError>;
  /** #soft-logged-in */
  success?: Maybe<MymkDeclineRecommendationSuccess>;
};

export type MymkDeclineRecommendationSuccess = {
  __typename?: 'MymkDeclineRecommendationSuccess';
  /** #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type MymkGenderEstimate = {
  __typename?: 'MymkGenderEstimate';
  estimate?: Maybe<Scalars['String']['output']>;
  pFemale: Scalars['Float']['output'];
  pMale: Scalars['Float']['output'];
  support: Scalars['Int']['output'];
};

export type MymkInviteRecommendation = Recommendation & {
  __typename?: 'MymkInviteRecommendation';
  itemId: Scalars['ID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
  /** #rest(batched) #virtual */
  vCard?: Maybe<VCard>;
};

export type MymkRecommendation = Recommendation & {
  __typename?: 'MymkRecommendation';
  /** #soft-logged-in */
  itemId: Scalars['ID']['output'];
  /** #soft-logged-in #rest(batched) #virtual */
  member?: Maybe<XingId>;
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  position: Scalars['Int']['output'];
  /** #soft-logged-in */
  reason: Array<Scalars['String']['output']>;
  /** #soft-logged-in */
  score: Scalars['Float']['output'];
  /** #rest(batched) #virtual */
  sharedContactsMobile?: Maybe<SharedContacts>;
  /** #rest(batched) #virtual */
  sharedContactsWeb?: Maybe<SharedContacts>;
  /** #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #soft-logged-in */
  urn: Scalars['URN']['output'];
};

export type MymkRecommendationEdge = PaginationEdge & {
  __typename?: 'MymkRecommendationEdge';
  /** #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #soft-logged-in */
  node: MymkRecommendation;
};

export type MymkRecommenderConnection = HasCursorPagination &
  RecommenderResponse & {
    __typename?: 'MymkRecommenderConnection';
    /** #soft-logged-in */
    edges: Array<MymkRecommendationEdge>;
    /** #soft-logged-in */
    pageInfo: PageInfo;
    /** #soft-logged-in */
    requestTrackingToken: Scalars['String']['output'];
    /** #soft-logged-in */
    service: Scalars['String']['output'];
    /** #soft-logged-in */
    total: Scalars['Int']['output'];
  };

export type NameGenderSettings = {
  __typename?: 'NameGenderSettings';
  academicTitle?: Maybe<SelectSetting>;
  birthName?: Maybe<TextSetting>;
  firstName?: Maybe<TextSetting>;
  gender?: Maybe<SelectSetting>;
  lastName?: Maybe<TextSetting>;
  pendingChange?: Maybe<PendingNameGenderSettings>;
};

export type NbaCard = NbaProfileCard;

/** Available Card IDs */
export const enum NbaCardId {
  All = 'ALL',
  CreateBirthdayCard = 'CREATE_BIRTHDAY_CARD',
  CreateBusinessAddressCard = 'CREATE_BUSINESS_ADDRESS_CARD',
  CreateBusinessCityCard = 'CREATE_BUSINESS_CITY_CARD',
  CreateBusinessLocationCard = 'CREATE_BUSINESS_LOCATION_CARD',
  CreateCompleteEducationCard = 'CREATE_COMPLETE_EDUCATION_CARD',
  CreateDisciplineCard = 'CREATE_DISCIPLINE_CARD',
  CreateEducationCard = 'CREATE_EDUCATION_CARD',
  CreateHavesCard = 'CREATE_HAVES_CARD',
  CreateMinCareerLevelCard = 'CREATE_MIN_CAREER_LEVEL_CARD',
  CreatePhotoCard = 'CREATE_PHOTO_CARD',
  ImproveHavesCard = 'IMPROVE_HAVES_CARD',
  ImproveJobTitleCard = 'IMPROVE_JOB_TITLE_CARD',
  ImprovePhotoCard = 'IMPROVE_PHOTO_CARD',
  PremiumPictureVoucher = 'PREMIUM_PICTURE_VOUCHER',
  PremiumUpsell = 'PREMIUM_UPSELL',
  SuccessCard = 'SUCCESS_CARD',
  UpdateBusinessCityCard = 'UPDATE_BUSINESS_CITY_CARD',
  UpdateBusinessLocationCard = 'UPDATE_BUSINESS_LOCATION_CARD',
  UpdateCompanyNameCard = 'UPDATE_COMPANY_NAME_CARD',
  UpdateDisciplineCard = 'UPDATE_DISCIPLINE_CARD',
  UpdateHavesCard = 'UPDATE_HAVES_CARD',
  UpdateJobTitleCard = 'UPDATE_JOB_TITLE_CARD',
  UpdateMinCareerLevelCard = 'UPDATE_MIN_CAREER_LEVEL_CARD',
  UpdatePhotoCard = 'UPDATE_PHOTO_CARD',
  WelcomeCard = 'WELCOME_CARD',
}

export type NbaProfileCard = {
  __typename?: 'NbaProfileCard';
  data?: Maybe<NbaProfileCardData>;
  /** ID of the entity related to the card (If any), for example this will be the order of educational experience in FieldOfStudy cards. */
  entityId?: Maybe<Scalars['ID']['output']>;
  /** ID of the Card, e.g. CREATE_BUSINESS_CITY_CARD */
  id: NbaCardId;
  /** Value of profile data that will be updated/improved by this card. Usually is used for Improve cards. */
  value?: Maybe<Scalars['String']['output']>;
};

export type NbaProfileCardData = {
  __typename?: 'NbaProfileCardData';
  /** Token, provided by DS to track suggestions interactions. Only provided if there are suggestions. */
  suggestionTrackingToken: Scalars['String']['output'];
  /** List of suggestions, provided by DS */
  suggestions: Array<NbaProfileSuggestion>;
};

export type NbaProfileSuggestion = {
  __typename?: 'NbaProfileSuggestion';
  /** Suggestion value. Fox example: Ruby */
  entityLabel: Scalars['String']['output'];
  /** Token, provided by DS to track suggestions interactions on specific item. */
  itemTrackingToken: Scalars['String']['output'];
  /** Position of this suggestion in the list returned by DS. */
  positionShown: Scalars['Int']['output'];
};

export type NeffiCard = {
  __typename?: 'NeffiCard';
  neffiData?: Maybe<NeffiCardData>;
};

export type NeffiCardData = {
  __typename?: 'NeffiCardData';
  abTestGroup: Scalars['String']['output'];
  description: Scalars['String']['output'];
  quality: Scalars['String']['output'];
  score: Scalars['Float']['output'];
  sections: Array<NeffiCardDataSection>;
  shouldCelebrate: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type NeffiCardDataSection = {
  __typename?: 'NeffiCardDataSection';
  fields: Array<NeffiCardDataSectionField>;
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
  urn?: Maybe<Scalars['GlobalID']['output']>;
};

export type NeffiCardDataSectionField = {
  __typename?: 'NeffiCardDataSectionField';
  checked: Scalars['Boolean']['output'];
  deeplink: Scalars['String']['output'];
  name: Scalars['String']['output'];
  outdated: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
};

export type NeffiModule = ModuleInterface &
  OutdatableModule & {
    __typename?: 'NeffiModule';
    active?: Maybe<Scalars['Boolean']['output']>;
    globalId: Scalars['GlobalID']['output'];
    lastModified?: Maybe<Scalars['Date']['output']>;
    /**
     * #rest #virtual
     * @deprecated This will be removed soon. neffiWdt is the replacement.
     */
    neffiCard?: Maybe<NeffiCard>;
    /** #rest #virtual */
    neffiWdt?: Maybe<NeffiWdt>;
    order: Scalars['Int']['output'];
    outdated: Scalars['Boolean']['output'];
    outdatedFields?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
  };

export type NeffiOutdatedFields = {
  __typename?: 'NeffiOutdatedFields';
  /** #soft-logged-in */
  lastModified?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  name: Scalars['String']['output'];
};

export type NeffiWdt = {
  __typename?: 'NeffiWdt';
  description: Scalars['String']['output'];
  fields: Array<NeffiWdtField>;
  quality: Scalars['String']['output'];
  score: Scalars['Float']['output'];
  shouldCelebrate: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type NeffiWdtField = {
  __typename?: 'NeffiWdtField';
  checked: Scalars['Boolean']['output'];
  deeplink: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  module?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  outdated: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
};

export type NetworkBlockedUsersBlockError = {
  __typename?: 'NetworkBlockedUsersBlockError';
  message?: Maybe<Scalars['String']['output']>;
};

export type NetworkBlockedUsersBlockInput = {
  id: Scalars['ID']['input'];
};

export type NetworkBlockedUsersBlockResult = {
  __typename?: 'NetworkBlockedUsersBlockResult';
  error?: Maybe<NetworkBlockedUsersBlockError>;
  /**
   * #rest
   *
   *  XingIdModule of the stalker user (the user being a stalker to the viewer)
   */
  xingIdModule?: Maybe<XingIdModule>;
};

export type NetworkBlockedUsersBlockResultXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

export type NetworkBlockedUsersBlockedUser = {
  __typename?: 'NetworkBlockedUsersBlockedUser';
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type NetworkBlockedUsersUnblockError = {
  __typename?: 'NetworkBlockedUsersUnblockError';
  message?: Maybe<Scalars['String']['output']>;
};

export type NetworkBlockedUsersUnblockInput = {
  id: Scalars['ID']['input'];
};

export type NetworkBlockedUsersUnblockResult = {
  __typename?: 'NetworkBlockedUsersUnblockResult';
  error?: Maybe<NetworkBlockedUsersUnblockError>;
  /**
   * #rest
   *
   *  XingIdModule of the stalker user (the user being a stalker to the viewer)
   */
  xingIdModule?: Maybe<XingIdModule>;
};

export type NetworkBlockedUsersUnblockResultXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

/** Persisted client settings for network apps */
export type NetworkClientSetting = {
  __typename?: 'NetworkClientSetting';
  /** #soft-logged-in */
  key: Scalars['String']['output'];
  /** #soft-logged-in */
  value: Scalars['String']['output'];
};

export type NetworkClientSettingError = {
  __typename?: 'NetworkClientSettingError';
  /** #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type NetworkClientSettingsInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type NetworkClientSettingsSetResult = {
  __typename?: 'NetworkClientSettingsSetResult';
  /** #soft-logged-in */
  error?: Maybe<NetworkClientSettingError>;
  /** #soft-logged-in */
  success?: Maybe<Array<NetworkClientSetting>>;
};

/** City of contact */
export type NetworkContactCity = {
  __typename?: 'NetworkContactCity';
  name: Scalars['String']['output'];
};

/** Company of contact */
export type NetworkContactCompany = {
  __typename?: 'NetworkContactCompany';
  name: Scalars['String']['output'];
};

/** List of aggregated companies */
export type NetworkContactsCities = {
  __typename?: 'NetworkContactsCities';
  collection?: Maybe<Array<NetworkContactCity>>;
};

/** List of aggregated companies */
export type NetworkContactsCompanies = {
  __typename?: 'NetworkContactsCompanies';
  collection?: Maybe<Array<NetworkContactCompany>>;
};

export type NetworkCreateTagInput = {
  name: Scalars['String']['input'];
};

export type NetworkDeleteTagInput = {
  tagId: Scalars['ID']['input'];
};

export type NetworkEfficiencyIndicator = {
  __typename?: 'NetworkEfficiencyIndicator';
  /** #soft-logged-in */
  category: Scalars['String']['output'];
  /** #soft-logged-in */
  id: Scalars['Long']['output'];
  /** #soft-logged-in */
  missingFields: Array<Maybe<Scalars['String']['output']>>;
  /** #soft-logged-in */
  outdatedFields: Array<Maybe<NeffiOutdatedFields>>;
  /** #soft-logged-in */
  score: Scalars['Float']['output'];
  /** #soft-logged-in */
  scoreVersion: Scalars['Int']['output'];
  /** #soft-logged-in */
  userTypeSupported: Scalars['Boolean']['output'];
};

export type NetworkHomeAdPlaceholder = {
  __typename?: 'NetworkHomeAdPlaceholder';
  /** @deprecated No longer used by clients */
  createdAt: Scalars['Date']['output'];
  /** @deprecated No longer used by clients */
  id: Scalars['ID']['output'];
};

export type NetworkHomeWebsocketAuthToken = {
  __typename?: 'NetworkHomeWebsocketAuthToken';
  /** #logged-out #soft-logged-in */
  token: Scalars['String']['output'];
};

export type NetworkProximityUser = {
  __typename?: 'NetworkProximityUser';
  proximityId: Scalars['UUID']['output'];
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type NetworkProximityUsers = {
  __typename?: 'NetworkProximityUsers';
  collection: Array<NetworkProximityUser>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type NetworkRecruiterInsights = Localizable & {
  __typename?: 'NetworkRecruiterInsights';
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export type NetworkRelationship = {
  __typename?: 'NetworkRelationship';
  /**
   * #soft-logged-in
   *
   *  The error value when the relationship status couldn't be retrieved
   */
  error?: Maybe<NetworkRelationshipErrorType>;
  hasSentConnectionInSession?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Id of the user. The relationship is verified between this id and userId
   */
  id: Scalars['ID']['output'];
  /**
   * #soft-logged-in
   *
   *  The current permissions for this relationship
   */
  permissions?: Maybe<Array<Maybe<NetworkRelationshipPermissionType>>>;
  /**
   * #soft-logged-in
   *
   *  The relationship itself between this id and userId
   */
  relationship?: Maybe<NetworkRelationshipType>;
};

/** The error values possible for each relationship request */
export const enum NetworkRelationshipErrorType {
  SameId = 'SAME_ID',
  UserBlacklisted = 'USER_BLACKLISTED',
  UserNotFound = 'USER_NOT_FOUND',
}

/** The permission values for each relationship */
export const enum NetworkRelationshipPermissionType {
  ConfirmRequest = 'CONFIRM_REQUEST',
  DeclineRequest = 'DECLINE_REQUEST',
  DeleteContact = 'DELETE_CONTACT',
  DeleteRequest = 'DELETE_REQUEST',
  SendRequest = 'SEND_REQUEST',
}

/**
 * Possible values are:
 * 'none': No relationship between userId and id, userId can send a contact request to id
 * 'contact': userId and id are contacts, the contact can be deleted
 * 'sent': userId has sent contact request to id, the contact request can be deleted
 * 'received': userId received a contact request from id, the contact request can be confirmed or declined
 * 'received_declined': userId received and declined a request from id, the contact request can still be confirmed
 */
export const enum NetworkRelationshipType {
  Contact = 'CONTACT',
  None = 'NONE',
  Received = 'RECEIVED',
  ReceivedDeclined = 'RECEIVED_DECLINED',
  Sent = 'SENT',
}

/** People team, network app related settings */
export type NetworkSettings = {
  __typename?: 'NetworkSettings';
  /** Keep data from addressbook uploads */
  addressbookUploadAllowed?: Maybe<ToggleSetting>;
  /** Who can see your contact list ('0' - nobody, '1' - friends, '2' - friends of friends, '99' - everyone) */
  distanceForContactList?: Maybe<SelectSetting>;
};

export type NetworkSettingsInput = {
  addressbookUploadAllowed?: InputMaybe<ToggleSettingInput>;
  distanceForContactList?: InputMaybe<SelectSettingInput>;
};

export type NetworkSettingsMutationResult = {
  __typename?: 'NetworkSettingsMutationResult';
  error?: Maybe<NetworkSettings>;
  response?: Maybe<NetworkSettings>;
};

/** Tags attached to a contact */
export type NetworkTag = {
  __typename?: 'NetworkTag';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type NetworkTagAssignResult = {
  __typename?: 'NetworkTagAssignResult';
  error?: Maybe<NetworkTaggingError>;
  success?: Maybe<ContactsListContact>;
};

export type NetworkTagCreateResult = {
  __typename?: 'NetworkTagCreateResult';
  error?: Maybe<NetworkTagError>;
  success?: Maybe<NetworkTag>;
};

export type NetworkTagDeleteResult = {
  __typename?: 'NetworkTagDeleteResult';
  error?: Maybe<NetworkTagError>;
  success?: Maybe<NetworkTag>;
};

export type NetworkTagError = {
  __typename?: 'NetworkTagError';
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tag?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type NetworkTagUnassignResult = {
  __typename?: 'NetworkTagUnassignResult';
  error?: Maybe<NetworkTaggingError>;
  success?: Maybe<ContactsListContact>;
};

export type NetworkTaggingError = {
  __typename?: 'NetworkTaggingError';
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  friendship?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  taggings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type NetworkTaggingInput = {
  friendId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};

/** List of tags */
export type NetworkTags = {
  __typename?: 'NetworkTags';
  collection?: Maybe<Array<NetworkTag>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type NetworkUpdate =
  | AdConversationStarter
  | BirthdayStack
  | ContactRecommendationStack
  | ContactRequestStack
  | MessengerChat
  | NetworkHomeAdPlaceholder
  | NewContactStack
  | VompStack
  | WorkExperienceUpdateStack;

export type NetworkUpdatesConnection = HasCursorPagination & {
  __typename?: 'NetworkUpdatesConnection';
  edges: Array<Maybe<NetworkUpdatesEdge>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type NetworkUpdatesEdge = PaginationEdge & {
  __typename?: 'NetworkUpdatesEdge';
  cursor: Scalars['String']['output'];
  node: NetworkUpdate;
};

export const enum NetworkUpdatesFilter {
  All = 'ALL',
  Chats = 'CHATS',
  Updates = 'UPDATES',
}

export type NetworkUpdatesSectionFilterInput = {
  chatsFilter?: InputMaybe<MessengerChatsFilterTypes>;
};

export type NetworkUpdatesStackItem = {
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  stackId: Scalars['ID']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type NetworkUserProximityIdSetError = {
  __typename?: 'NetworkUserProximityIdSetError';
  message?: Maybe<Scalars['String']['output']>;
};

export type NetworkUserProximityIdSetInput = {
  proximityId: Scalars['UUID']['input'];
};

export type NetworkUserProximityIdSetResult = {
  __typename?: 'NetworkUserProximityIdSetResult';
  error?: Maybe<NetworkUserProximityIdSetError>;
  /** #expand */
  success?: Maybe<NetworkUserProximityIdSetSuccess>;
};

export type NetworkUserProximityIdSetSuccess = {
  __typename?: 'NetworkUserProximityIdSetSuccess';
  proximityId?: Maybe<Scalars['UUID']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type NewContactConversationStarter = ConversationStarter & {
  __typename?: 'NewContactConversationStarter';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  /** #rest #virtual */
  commonalities?: Maybe<Commonalities>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  sharedContacts?: Maybe<SharedContacts>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type NewContactStack = SignalsStack & {
  __typename?: 'NewContactStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type NewContactStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type NewGroupMessageConversationStarter = ConversationStarter & {
  __typename?: 'NewGroupMessageConversationStarter';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<MessengerMessage>;
  /** #rest(batched) #virtual */
  target?: Maybe<MessengerChat>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type NewMessageConversationStarter = ConversationStarter & {
  __typename?: 'NewMessageConversationStarter';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<MessengerMessage>;
  /** #rest(batched) #virtual */
  target?: Maybe<MessengerChat>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type NewNotificationCenterMutationError = {
  __typename?: 'NewNotificationCenterMutationError';
  message?: Maybe<Scalars['String']['output']>;
};

export type NewProfileVisitResult =
  | ProfileVisitErrorResponse
  | ProfileVisitSuccessResponse;

export const enum NewVisitType {
  NotVisible = 'NOT_VISIBLE',
  Visible = 'VISIBLE',
}

export type NewsAggregations = {
  __typename?: 'NewsAggregations';
  category?: Maybe<Array<Maybe<CategoryAggregation>>>;
  entityType?: Maybe<Array<Maybe<EntityTypeAggregation>>>;
};

export type NewsAggregationsInput = {
  /** category: Maximum number of distinct values to be returned by this aggregation */
  category?: InputMaybe<Scalars['String']['input']>;
  /** entityType: Maximum number of distinct values to be returned by this aggregation. For now we only have 4 of those. */
  entityType?: InputMaybe<Scalars['String']['input']>;
};

export type NewsArticleSearchItem = UniversalSearchNewsResult & {
  __typename?: 'NewsArticleSearchItem';
  /** #rest #virtual */
  contentArticle?: Maybe<ContentArticle>;
  position: Scalars['Int']['output'];
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn?: Maybe<Scalars['URN']['output']>;
};

/**  Available filters for news search */
export type NewsFiltersInput = {
  /** category: Filter results which match ANY of the given values(comma separated).  */
  category?: InputMaybe<Scalars['String']['input']>;
  /** entity_type: Filter results which match ANY of the given values(comma separated). Values should be in this enum: page|insider|article */
  entityType?: InputMaybe<EntityTypeValue>;
};

export type NewsInsiderSearchItem = UniversalSearchNewsResult & {
  __typename?: 'NewsInsiderSearchItem';
  position: Scalars['Int']['output'];
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn?: Maybe<Scalars['URN']['output']>;
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type NewsPageSearchItem = UniversalSearchNewsResult & {
  __typename?: 'NewsPageSearchItem';
  /** #rest(batched) #virtual */
  contentPage?: Maybe<ContentPage>;
  position: Scalars['Int']['output'];
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn?: Maybe<Scalars['URN']['output']>;
};

export type NewsSearchConnection = HasCursorPagination & {
  __typename?: 'NewsSearchConnection';
  aggs?: Maybe<NewsAggregations>;
  edges: Array<NewsSearchEdge>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type NewsSearchEdge = PaginationEdge & {
  __typename?: 'NewsSearchEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node?: Maybe<UniversalSearchNewsResult>;
};

/** Next Best Action Module */
export type NextBestActionModule = ModuleInterface & {
  __typename?: 'NextBestActionModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** List of cards with suggestions */
  cards: Array<NbaCard>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  /** Generated by backend the tracking token for the whole request. Should be used by frontend to track cards interactions. */
  trackingToken: Scalars['String']['output'];
};

/** note for another userr */
export type Note = {
  __typename?: 'Note';
  content: Scalars['String']['output'];
  otherUserId: Scalars['ID']['output'];
};

export type NoteInputType = {
  content: Scalars['String']['input'];
  otherUserId: Scalars['ID']['input'];
};

/** Note Module */
export type NoteModule = ModuleInterface & {
  __typename?: 'NoteModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  globalId: Scalars['GlobalID']['output'];
  /** #rest #virtual */
  note?: Maybe<Note>;
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type Notification = {
  aggregated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interacted: Scalars['Boolean']['output'];
  opened: Scalars['Boolean']['output'];
  publishedAt: Scalars['Date']['output'];
  seen: Scalars['Boolean']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn: Scalars['URN']['output'];
};

export type NotificationConnection = HasCursorPagination & {
  __typename?: 'NotificationConnection';
  edges: Array<Maybe<NotificationEdge>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type NotificationEdge = PaginationEdge & {
  __typename?: 'NotificationEdge';
  cursor: Scalars['String']['output'];
  node: Notification;
};

export type NotificationMarkAsOpenedInput = {
  id: Scalars['ID']['input'];
};

export type NotificationMarkAsOpenedResult =
  | NotificationMarkAsOpenedResultError
  | NotificationMarkAsOpenedResultSuccess;

export type NotificationMarkAsOpenedResultError = {
  __typename?: 'NotificationMarkAsOpenedResultError';
  message?: Maybe<Scalars['String']['output']>;
};

export type NotificationMarkAsOpenedResultSuccess = {
  __typename?: 'NotificationMarkAsOpenedResultSuccess';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  textResourceKeys?: Maybe<TextResourceKeys>;
  /** A users certain notification setting */
  type: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type NotificationSettingInput = {
  /** Type of notification */
  type: Scalars['String']['input'];
  /** The corresponding boolean value for it */
  value: Scalars['Boolean']['input'];
};

/** Settings related to Notifications */
export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  email?: Maybe<Array<Maybe<NotificationSetting>>>;
  /** A users notification settings, unpaginated and grouped by section */
  push?: Maybe<Array<Maybe<NotificationSetting>>>;
};

export type NotificationSettingsCollection = {
  __typename?: 'NotificationSettingsCollection';
  /** Complete collection of a users notification settings */
  collection?: Maybe<NotificationSettings>;
};

export type NotificationSettingsCollectionInput = {
  collection?: InputMaybe<NotificationSettingsInput>;
};

export type NotificationSettingsInput = {
  /** Input consists of a type and value for a particular email setting. */
  email?: InputMaybe<NotificationSettingInput>;
  /** Input consists of a type and value for a particular push setting. */
  push?: InputMaybe<NotificationSettingInput>;
};

export type NotificationSettingsMutationResult = {
  __typename?: 'NotificationSettingsMutationResult';
  error?: Maybe<NotificationSettingsCollection>;
  response?: Maybe<NotificationSettingsCollection>;
};

export type NotificationsBadgeNamesInput = {
  badgeNames: Array<Scalars['String']['input']>;
};

/** Response is currently ignored by clients. */
export type NotificationsBadgesSeenData = {
  __typename?: 'NotificationsBadgesSeenData';
  response?: Maybe<Scalars['String']['output']>;
};

export type NotificationsBadgesSeenError = {
  __typename?: 'NotificationsBadgesSeenError';
  description?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/**
 * The response should contain information regarding a successful or
 * unsuccessful request. This decision is done internally, and the
 * response is just proxied back to the client.
 */
export type NotificationsBadgesSeenResponse = {
  __typename?: 'NotificationsBadgesSeenResponse';
  error?: Maybe<NotificationsBadgesSeenError>;
  success?: Maybe<NotificationsBadgesSeenData>;
};

export type NotificationsMarkAllAsOpenedResult =
  | NotificationsMarkAllAsOpenedResultError
  | NotificationsMarkAllAsOpenedResultSuccess;

export type NotificationsMarkAllAsOpenedResultError = {
  __typename?: 'NotificationsMarkAllAsOpenedResultError';
  message?: Maybe<Scalars['String']['output']>;
};

export type NotificationsMarkAllAsOpenedResultSuccess = {
  __typename?: 'NotificationsMarkAllAsOpenedResultSuccess';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type NumberOfNewProfileVisits = {
  __typename?: 'NumberOfNewProfileVisits';
  /**
   * #virtual
   *
   *  Total number of new visits
   */
  total: Scalars['Int']['output'];
};

export type ObjectActions = {
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type OccupiedJob = {
  __typename?: 'OccupiedJob';
  /** #rest(batched) #virtual */
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']['output']>;
  experienceMatchedScore?: Maybe<Scalars['Int']['output']>;
  experienceTotal?: Maybe<Scalars['Int']['output']>;
  fallbackCompany?: Maybe<FallbackCompany>;
  id?: Maybe<Scalars['ID']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  preferredDiscipline?: Maybe<DreamJobDiscipline>;
  preferredIndustry?: Maybe<DreamJobIndusty>;
  salary?: Maybe<JobSalary>;
  similarMembers?: Maybe<Array<Maybe<DreamJobSimilarMembers>>>;
  similarMembersCount?: Maybe<Scalars['Int']['output']>;
  skillsMatched?: Maybe<Array<Maybe<DreamJobSkills>>>;
  skillsMatchedCount?: Maybe<Scalars['Int']['output']>;
  skillsNotMatched?: Maybe<Array<Maybe<DreamJobSkills>>>;
  skillsNotMatchedCount?: Maybe<Scalars['Int']['output']>;
  skillsTotal?: Maybe<Scalars['Int']['output']>;
  trackingToken?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['URN']['output']>;
  workType?: Maybe<Scalars['String']['output']>;
};

export type OfferStatusAggregation = HasAggregationCount & {
  __typename?: 'OfferStatusAggregation';
  count: Scalars['Int']['output'];
  value?: Maybe<CompanyOffers>;
};

export type OnboardingDeveloperJourneyInput = {
  metadata: OnboardingJourneyMetadataInput;
  screen: OnboardingScreenType;
};

export const enum OnboardingJourneyFlowType {
  Jobseeker = 'JOBSEEKER',
  Resume = 'RESUME',
}

export type OnboardingJourneyMetadataInput = {
  employee?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated EmployerScreen will be replaced with workExperienceScreen soon */
  employerScreen?: InputMaybe<Scalars['Boolean']['input']>;
  jobPreferencesCareerLevelScreen?: InputMaybe<Scalars['Boolean']['input']>;
  jobPreferencesDisciplineScreen?: InputMaybe<Scalars['Boolean']['input']>;
  jobPreferencesIndustryScreen?: InputMaybe<Scalars['Boolean']['input']>;
  jobPreferencesJobTitlesScreen?: InputMaybe<Scalars['Boolean']['input']>;
  jobPreferencesLocationScreen?: InputMaybe<Scalars['Boolean']['input']>;
  jobPreferencesSalaryScreen?: InputMaybe<Scalars['Boolean']['input']>;
  jobPreferencesSelectionScreen?: InputMaybe<Scalars['Boolean']['input']>;
  jobPreferencesWorkingHoursScreen?: InputMaybe<Scalars['Boolean']['input']>;
  jobseekerStatusScreen?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated OccupationScreen will be replaced with workExperienceScreen soon */
  occupationScreen?: InputMaybe<Scalars['Boolean']['input']>;
  outroScreen?: InputMaybe<Scalars['Boolean']['input']>;
  pictureScreen?: InputMaybe<Scalars['Boolean']['input']>;
  resumeScreen?: InputMaybe<Scalars['Boolean']['input']>;
  skillsScreen?: InputMaybe<Scalars['Boolean']['input']>;
  student?: InputMaybe<Scalars['Boolean']['input']>;
  studiesLocationScreen?: InputMaybe<Scalars['Boolean']['input']>;
  studiesScreen?: InputMaybe<Scalars['Boolean']['input']>;
  timelineEntryScreen?: InputMaybe<Scalars['Boolean']['input']>;
  transitionScreen?: InputMaybe<Scalars['Boolean']['input']>;
  upsellScreen?: InputMaybe<Scalars['Boolean']['input']>;
  welcomeScreen?: InputMaybe<Scalars['Boolean']['input']>;
  workExperienceScreen?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OnboardingJourneyMetadataType = {
  __typename?: 'OnboardingJourneyMetadataType';
  employee?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated EmployerScreen will be replaced with workExperienceScreen soon */
  employerScreen?: Maybe<Scalars['Boolean']['output']>;
  hasJobPreferencesJobTitles?: Maybe<Scalars['Boolean']['output']>;
  hasJobPreferencesLocation?: Maybe<Scalars['Boolean']['output']>;
  jobPreferencesCareerLevelScreen?: Maybe<Scalars['Boolean']['output']>;
  jobPreferencesDisciplineScreen?: Maybe<Scalars['Boolean']['output']>;
  jobPreferencesIndustryScreen?: Maybe<Scalars['Boolean']['output']>;
  jobPreferencesJobTitlesScreen?: Maybe<Scalars['Boolean']['output']>;
  jobPreferencesLocationScreen?: Maybe<Scalars['Boolean']['output']>;
  jobPreferencesSalaryScreen?: Maybe<Scalars['Boolean']['output']>;
  jobPreferencesSelectionScreen?: Maybe<Scalars['Boolean']['output']>;
  jobPreferencesWorkingHoursScreen?: Maybe<Scalars['Boolean']['output']>;
  jobseekerStatusScreen?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated OccupationScreen will be replaced with workExperienceScreen soon */
  occupationScreen?: Maybe<Scalars['Boolean']['output']>;
  outroScreen?: Maybe<Scalars['Boolean']['output']>;
  pictureScreen?: Maybe<Scalars['Boolean']['output']>;
  resumeScreen?: Maybe<Scalars['Boolean']['output']>;
  skillsScreen?: Maybe<Scalars['Boolean']['output']>;
  student?: Maybe<Scalars['Boolean']['output']>;
  studiesLocationScreen?: Maybe<Scalars['Boolean']['output']>;
  studiesScreen?: Maybe<Scalars['Boolean']['output']>;
  timelineEntryScreen?: Maybe<Scalars['Boolean']['output']>;
  transitionScreen?: Maybe<Scalars['Boolean']['output']>;
  upsellScreen?: Maybe<Scalars['Boolean']['output']>;
  welcomeScreen?: Maybe<Scalars['Boolean']['output']>;
  wellDescribedTalent?: Maybe<Scalars['Boolean']['output']>;
  workExperienceScreen?: Maybe<Scalars['Boolean']['output']>;
};

export type OnboardingJourneyNextScreenResponseType = {
  __typename?: 'OnboardingJourneyNextScreenResponseType';
  journey?: Maybe<OnboardingJourneyType>;
  nextScreen?: Maybe<OnboardingNextScreenType>;
};

export type OnboardingJourneyType = {
  __typename?: 'OnboardingJourneyType';
  flow?: Maybe<OnboardingJourneyFlowType>;
  journeyId: Scalars['UUID']['output'];
  metadata?: Maybe<OnboardingJourneyMetadataType>;
  /** #rest #virtual */
  registerRedirectTo?: Maybe<OnboardingRegisterRedirectTo>;
  resumable: Scalars['Boolean']['output'];
  segment?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type OnboardingNextScreenType = {
  __typename?: 'OnboardingNextScreenType';
  name: Scalars['String']['output'];
};

export type OnboardingProfile = {
  __typename?: 'OnboardingProfile';
  completedSimpleProfile: Scalars['Boolean']['output'];
  globalId: Scalars['URN']['output'];
  isWellDescribedTalent: Scalars['Boolean']['output'];
  motive?: Maybe<Scalars['String']['output']>;
  resumeOnboardingInfo?: Maybe<ResumeOnboardingInfo>;
  segment: Scalars['String']['output'];
  showSimpleProfile: Scalars['Boolean']['output'];
  signupChannel: Scalars['String']['output'];
};

export type OnboardingRedirectProfile = {
  __typename?: 'OnboardingRedirectProfile';
  /** #rest(batched) #virtual */
  profile?: Maybe<XingId>;
};

export type OnboardingRegisterRedirectTo = {
  __typename?: 'OnboardingRegisterRedirectTo';
  url?: Maybe<Scalars['String']['output']>;
};

export const enum OnboardingScreenType {
  EmployerScreen = 'EMPLOYER_SCREEN',
  JobPreferencesCareerLevelScreen = 'JOB_PREFERENCES_CAREER_LEVEL_SCREEN',
  JobPreferencesDisciplineScreen = 'JOB_PREFERENCES_DISCIPLINE_SCREEN',
  JobPreferencesIndustryScreen = 'JOB_PREFERENCES_INDUSTRY_SCREEN',
  JobPreferencesJobTitlesScreen = 'JOB_PREFERENCES_JOB_TITLES_SCREEN',
  JobPreferencesLocationScreen = 'JOB_PREFERENCES_LOCATION_SCREEN',
  JobPreferencesSalaryScreen = 'JOB_PREFERENCES_SALARY_SCREEN',
  JobPreferencesSelectionScreen = 'JOB_PREFERENCES_SELECTION_SCREEN',
  JobPreferencesWorkingHoursScreen = 'JOB_PREFERENCES_WORKING_HOURS_SCREEN',
  JobStatusScreen = 'JOB_STATUS_SCREEN',
  OccupationScreen = 'OCCUPATION_SCREEN',
  OutroScreen = 'OUTRO_SCREEN',
  PictureScreen = 'PICTURE_SCREEN',
  RedirectScreen = 'REDIRECT_SCREEN',
  ResumeScreen = 'RESUME_SCREEN',
  SkillsScreen = 'SKILLS_SCREEN',
  StudiesLocationScreen = 'STUDIES_LOCATION_SCREEN',
  StudiesScreen = 'STUDIES_SCREEN',
  TimelineEntryScreen = 'TIMELINE_ENTRY_SCREEN',
  TransitionScreen = 'TRANSITION_SCREEN',
  UpsellScreen = 'UPSELL_SCREEN',
  WelcomeScreen = 'WELCOME_SCREEN',
  WorkExperienceScreen = 'WORK_EXPERIENCE_SCREEN',
}

export type OneOnOneMessageConversationStarter = ConversationStarter & {
  __typename?: 'OneOnOneMessageConversationStarter';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<MessengerMessage>;
  /** #rest(batched) #virtual */
  target?: Maybe<MessengerChat>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type OntologyEntityLabel = {
  __typename?: 'OntologyEntityLabel';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  label: Scalars['String']['output'];
};

export type OntologyEntityLabels = {
  __typename?: 'OntologyEntityLabels';
  de?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  en?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type OntologyEntityMatchingResponseItem = {
  __typename?: 'OntologyEntityMatchingResponseItem';
  entityId?: Maybe<Scalars['ID']['output']>;
  entityType?: Maybe<OntologyEntityType>;
  labels?: Maybe<OntologySeqEntityLabels>;
  links?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  referencedLabel?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
};

export const enum OntologyEntityType {
  AdminArea = 'ADMIN_AREA',
  CareerLevel = 'CAREER_LEVEL',
  City = 'CITY',
  Company = 'COMPANY',
  Country = 'COUNTRY',
  Degree = 'DEGREE',
  Department = 'DEPARTMENT',
  Discipline = 'DISCIPLINE',
  FieldOfStudy = 'FIELD_OF_STUDY',
  Function = 'FUNCTION',
  Ignorable = 'IGNORABLE',
  Industry = 'INDUSTRY',
  Jobrole = 'JOBROLE',
  JobRole = 'JOB_ROLE',
  Municipality = 'MUNICIPALITY',
  School = 'SCHOOL',
  Skill = 'SKILL',
}

export const enum OntologyLabels {
  All = 'ALL',
  Alternative = 'ALTERNATIVE',
  None = 'NONE',
  Primary = 'PRIMARY',
}

export const enum OntologyLanguage {
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  It = 'IT',
  Nl = 'NL',
  Np = 'NP',
  Pl = 'PL',
  Pt = 'PT',
  Ru = 'RU',
  Tr = 'TR',
  Un = 'UN',
  Zh = 'ZH',
}

export type OntologyLocalizedJobPostingSkill = {
  __typename?: 'OntologyLocalizedJobPostingSkill';
  isSoft: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  language: OntologyLanguage;
};

export type OntologyLocalizedJobRole = {
  __typename?: 'OntologyLocalizedJobRole';
  label: Scalars['String']['output'];
  language: OntologyLanguage;
};

export type OntologyResolvedEntitiesInput = {
  entityLimit: Scalars['Int']['input'];
  externalLinks: Array<InputMaybe<Scalars['String']['input']>>;
  labelLanguages: Array<InputMaybe<Scalars['String']['input']>>;
  labels: Scalars['String']['input'];
  text: Scalars['String']['input'];
  xingField: OntologyXingField;
  xingType: OntologyXingType;
};

export type OntologyResolvedEntitiesReferenceCollection = {
  __typename?: 'OntologyResolvedEntitiesReferenceCollection';
  references?: Maybe<Array<Maybe<OntologyResolvedEntitiesReferenceItem>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type OntologyResolvedEntitiesReferenceItem = {
  __typename?: 'OntologyResolvedEntitiesReferenceItem';
  entityMatches?: Maybe<Array<Maybe<OntologyEntityMatchingResponseItem>>>;
  reference?: Maybe<Scalars['String']['output']>;
};

export type OntologySeqEntityLabels = {
  __typename?: 'OntologySeqEntityLabels';
  alternative?: Maybe<OntologyEntityLabels>;
  matchingOnly?: Maybe<OntologyEntityLabels>;
  primary?: Maybe<OntologyEntityLabels>;
};

export type OntologySkill = {
  __typename?: 'OntologySkill';
  entityType?: Maybe<OntologyEntityType>;
  id?: Maybe<Scalars['ID']['output']>;
  labels?: Maybe<Array<Maybe<OntologySkillLabel>>>;
  links?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type OntologySkillLabel = {
  __typename?: 'OntologySkillLabel';
  isMatchingOnly?: Maybe<Scalars['Boolean']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  language?: Maybe<OntologyLanguage>;
};

export type OntologyUserJobPostingProfileMatchResponse = {
  __typename?: 'OntologyUserJobPostingProfileMatchResponse';
  jobPostingId?: Maybe<Scalars['ID']['output']>;
  jobRoles?: Maybe<JobPostingJobRoleMatches>;
  skills?: Maybe<JobPostingSkillMatches>;
};

export type OntologyUserJobPostingSkillsMatchResponse = {
  __typename?: 'OntologyUserJobPostingSkillsMatchResponse';
  jobPostingId?: Maybe<Scalars['ID']['output']>;
  skills?: Maybe<JobPostingSkillMatches>;
};

export const enum OntologyXingField {
  AcademicDegree = 'ACADEMIC_DEGREE',
  Company = 'COMPANY',
  CompanyName = 'COMPANY_NAME',
  CompanyNotes = 'COMPANY_NOTES',
  CompanyTags = 'COMPANY_TAGS',
  Degree = 'DEGREE',
  EnrichedSkills = 'ENRICHED_SKILLS',
  EnrichedTags = 'ENRICHED_TAGS',
  ExcludedKeywords = 'EXCLUDED_KEYWORDS',
  Function = 'FUNCTION',
  Haves = 'HAVES',
  Interests = 'INTERESTS',
  JobTitle = 'JOB_TITLE',
  OccupationOrg = 'OCCUPATION_ORG',
  OccupationTitle = 'OCCUPATION_TITLE',
  Qualifications = 'QUALIFICATIONS',
  ResultsAndAchievements = 'RESULTS_AND_ACHIEVEMENTS',
  SchoolName = 'SCHOOL_NAME',
  SchoolNotes = 'SCHOOL_NOTES',
  Skills = 'SKILLS',
  Subject = 'SUBJECT',
  Tags = 'TAGS',
  TargetCompanies = 'TARGET_COMPANIES',
  TargetPositions = 'TARGET_POSITIONS',
  TasksAndActions = 'TASKS_AND_ACTIONS',
  TopicsAndProjects = 'TOPICS_AND_PROJECTS',
  Wants = 'WANTS',
  Whi = 'WHI',
}

export const enum OntologyXingType {
  Jobboerse = 'JOBBOERSE',
  Posting = 'POSTING',
  Profile = 'PROFILE',
}

export type OpenChatAction = ObjectActions & {
  __typename?: 'OpenChatAction';
  params: OpenChatActionParams;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type OpenChatActionParams = {
  __typename?: 'OpenChatActionParams';
  contextId?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type OpenJobAction = ObjectActions & {
  __typename?: 'OpenJobAction';
  params: OpenJobActionParams;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type OpenJobActionParams = {
  __typename?: 'OpenJobActionParams';
  jobId: Scalars['ID']['output'];
  jobSlug: Scalars['String']['output'];
};

/**
 * Metadata for original image.
 * Returns back metadata from image-cache service by using original_images endpoint and uuid for an original image.
 */
export type OriginalImageMetadata = {
  __typename?: 'OriginalImageMetadata';
  /**
   * #logged-out #soft-logged-in
   *
   *  Content type of the image: ex: `image/jpeg`
   */
  contentType?: Maybe<Scalars['String']['output']>;
  /** Files size of the image */
  fileSize?: Maybe<Scalars['Int']['output']>;
  /** Height of the image */
  height?: Maybe<Scalars['Int']['output']>;
  /** Width of the image */
  width?: Maybe<Scalars['Int']['output']>;
};

export type OutdatableModule = {
  lastModified?: Maybe<Scalars['Date']['output']>;
  outdated: Scalars['Boolean']['output'];
  outdatedFields?: Maybe<Scalars['String']['output']>;
};

/** Information about current cursor pagination page */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** #logged-out #soft-logged-in */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  hasNextPage: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/**
 * Provides common fields for cursor pagination edges. Complies with
 * [Relay Edge Type](https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types) specification.
 */
export type PaginationEdge = {
  /** #virtual */
  cursor: Scalars['String']['output'];
};

/** Types for XTM Message Parser */
export type ParseMessageInput = {
  recipientId?: InputMaybe<Scalars['ID']['input']>;
  templateId: Scalars['ID']['input'];
};

export type ParseMessageTemplateError = {
  __typename?: 'ParseMessageTemplateError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ParseMessageTemplateMutationResult =
  | ParseMessageTemplateError
  | ParseMessageTemplateSuccess;

export type ParseMessageTemplateSuccess = {
  __typename?: 'ParseMessageTemplateSuccess';
  body?: Maybe<Array<Maybe<XtmMessageTemplateParserOutputElement>>>;
  subject?: Maybe<Array<Maybe<XtmMessageTemplateParserOutputElement>>>;
};

export type ParticipantsCount = {
  __typename?: 'ParticipantsCount';
  no: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unanswered: Scalars['Int']['output'];
  yes: Scalars['Int']['output'];
};

export type Partner = {
  __typename?: 'Partner';
  /** #soft-logged-in */
  categoriesWithId?: Maybe<Array<Maybe<PartnersCategory>>>;
  /**
   * #soft-logged-in
   * @deprecated No longer in use by the clients. To be out of use in 31/05/2023
   */
  detailUrl?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  displayName?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  logo?: Maybe<PartnerLogo>;
  /** #soft-logged-in */
  membership?: Maybe<PartnerMemberships>;
  /** #soft-logged-in */
  new?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  offerType?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  partnerName?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  slug?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  teaser?: Maybe<PartnerTeaser>;
  /** #soft-logged-in */
  uplt?: Maybe<Scalars['Int']['output']>;
};

/** Partner Mutations */
export type PartnerCreateCodeMutationInput = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** "Result type for  partner code create mutations that returns the code on success" */
export type PartnerCreateCodeMutationResult = {
  __typename?: 'PartnerCreateCodeMutationResult';
  /** #soft-logged-in */
  error?: Maybe<PartnerMutationError>;
  /** #soft-logged-in */
  success?: Maybe<PartnerDetailsCode>;
};

/** Partner details for a user */
export type PartnerDetails = {
  __typename?: 'PartnerDetails';
  /** #soft-logged-in */
  backgroundImage?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  benefits?: Maybe<PartnerDetailsBenefits>;
  /** #soft-logged-in */
  body?: Maybe<Array<Maybe<PartnerDetailsBody>>>;
  /** #soft-logged-in */
  displayName?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  filter?: Maybe<PartnerDetailsFilter>;
  /** #soft-logged-in */
  footerNotes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  logoImage?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  new?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  partnerId: Scalars['ID']['output'];
  /** #soft-logged-in */
  sideSection?: Maybe<Array<Maybe<PartnerDetailsSideSection>>>;
  /** #soft-logged-in */
  slug?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  uplt?: Maybe<Scalars['Int']['output']>;
};

export type PartnerDetailsBenefit = {
  __typename?: 'PartnerDetailsBenefit';
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PartnerDetailsBenefits = {
  __typename?: 'PartnerDetailsBenefits';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<PartnerDetailsBenefit>>>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
};

export type PartnerDetailsBody =
  | PartnerDetailsBodyHeader
  | PartnerDetailsBodyParagraph
  | PartnerDetailsBodySubheader
  | PartnerDetailsBodyVideo;

/** Types for PartnerDetailsBody */
export type PartnerDetailsBodyHeader = {
  __typename?: 'PartnerDetailsBodyHeader';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PartnerDetailsBodyParagraph = {
  __typename?: 'PartnerDetailsBodyParagraph';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PartnerDetailsBodySubheader = {
  __typename?: 'PartnerDetailsBodySubheader';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PartnerDetailsBodyVideo = {
  __typename?: 'PartnerDetailsBodyVideo';
  /** #soft-logged-in */
  videoId?: Maybe<Scalars['ID']['output']>;
};

export type PartnerDetailsCode = {
  __typename?: 'PartnerDetailsCode';
  /** #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

export type PartnerDetailsFilter = {
  __typename?: 'PartnerDetailsFilter';
  /** #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PartnerDetailsSideSection =
  | PartnerDetailsSideSectionButton
  | PartnerDetailsSideSectionCodebutton
  | PartnerDetailsSideSectionHeader
  | PartnerDetailsSideSectionList
  | PartnerDetailsSideSectionSubheader;

export type PartnerDetailsSideSectionButton = {
  __typename?: 'PartnerDetailsSideSectionButton';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PartnerDetailsSideSectionCodebutton = {
  __typename?: 'PartnerDetailsSideSectionCodebutton';
  /** #soft-logged-in #rest #virtual */
  code?: Maybe<PartnerDetailsCode>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

/** Types for PartnerDetailsSideSection */
export type PartnerDetailsSideSectionHeader = {
  __typename?: 'PartnerDetailsSideSectionHeader';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PartnerDetailsSideSectionList = {
  __typename?: 'PartnerDetailsSideSectionList';
  /** #soft-logged-in */
  listElements?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PartnerDetailsSideSectionSubheader = {
  __typename?: 'PartnerDetailsSideSectionSubheader';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PartnerHighlight = {
  __typename?: 'PartnerHighlight';
  /** #soft-logged-in */
  body?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  displayName?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  highlightImageUrl?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  logoUrl?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  new?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  offerType?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  partnerName?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  slug?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  squareHighlightImageUrl?: Maybe<Scalars['String']['output']>;
};

export type PartnerLogo = {
  __typename?: 'PartnerLogo';
  /** #soft-logged-in */
  imageUrl?: Maybe<Scalars['String']['output']>;
};

/** Memberships available to partners */
export const enum PartnerMemberships {
  /** Premium */
  Premium = 'PREMIUM',
  /** ProJobs */
  Projobs = 'PROJOBS',
}

export type PartnerMutationError = {
  __typename?: 'PartnerMutationError';
  /** #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  errors?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  status?: Maybe<Scalars['Int']['output']>;
};

export type PartnerTeaser = {
  __typename?: 'PartnerTeaser';
  /** #soft-logged-in */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  localizedDescription?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  localizedSmallDescription?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  localizedTitle?: Maybe<Scalars['String']['output']>;
};

export type Partners = {
  __typename?: 'Partners';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<Partner>>>;
};

/** List of Partner's categories */
export type PartnersCategories = {
  __typename?: 'PartnersCategories';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<PartnersCategory>>>;
  /** #soft-logged-in */
  headline?: Maybe<Scalars['String']['output']>;
};

export type PartnersCategory = {
  __typename?: 'PartnersCategory';
  /** #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #soft-logged-in */
  slug?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PasswordProtectedSettingError = {
  __typename?: 'PasswordProtectedSettingError';
  message?: Maybe<Scalars['String']['output']>;
};

export type PasswordProtectedSettingTokenInput = {
  password: Scalars['String']['input'];
};

export type PasswordProtectedSettingTokenMutationResult = {
  error?: Maybe<PasswordProtectedSettingError>;
  passwordToken?: Maybe<Scalars['String']['output']>;
};

export type PasswordProtectedSettingTokenMutationResultError =
  PasswordProtectedSettingTokenMutationResult & {
    __typename?: 'PasswordProtectedSettingTokenMutationResultError';
    error?: Maybe<PasswordProtectedSettingError>;
    passwordToken?: Maybe<Scalars['String']['output']>;
  };

export type PasswordProtectedSettingTokenMutationResultSuccess =
  PasswordProtectedSettingTokenMutationResult & {
    __typename?: 'PasswordProtectedSettingTokenMutationResultSuccess';
    error?: Maybe<PasswordProtectedSettingError>;
    passwordToken?: Maybe<Scalars['String']['output']>;
  };

/** Response for password changing mutation */
export type PasswordSetting = {
  __typename?: 'PasswordSetting';
  activePasswordErrors?: Maybe<Array<Scalars['String']['output']>>;
  /** active password remaining attempts, after exhausting this attempts all active sessions of a user are terminated */
  activePasswordRemainingAttempts: Scalars['Int']['output'];
  newPasswordErrors?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * In order to change the password, both current password and the new
 * password must be provided.
 */
export type PasswordSettingInput = {
  /** current active password, will be verified upon change */
  activePassword: Scalars['String']['input'];
  /** new desired password, should be between 4 and 1024 chars long, must not match first, last names, email or username. */
  newPassword: Scalars['String']['input'];
};

/** Client for Payment & Billing */
export type PayBilClient = {
  __typename?: 'PayBilClient';
  addressAddition?: Maybe<TextSetting>;
  ccEmail?: Maybe<TextSetting>;
  city?: Maybe<TextSetting>;
  country?: Maybe<TextSetting>;
  email?: Maybe<TextSetting>;
  id?: Maybe<Scalars['ID']['output']>;
  language?: Maybe<TextSetting>;
  name?: Maybe<TextSetting>;
  postcode?: Maybe<TextSetting>;
  street?: Maybe<TextSetting>;
  taxId?: Maybe<TextSetting>;
};

/** Input for Payment & Billing client */
export type PayBilClientInput = {
  addressAddition?: InputMaybe<TextSettingInput>;
  addressInfo?: InputMaybe<TextSettingInput>;
  ccEmail?: InputMaybe<TextSettingInput>;
  city?: InputMaybe<TextSettingInput>;
  country?: InputMaybe<SelectSettingInput>;
  email?: InputMaybe<TextSettingInput>;
  language?: InputMaybe<SelectSettingInput>;
  name?: InputMaybe<TextSettingInput>;
  postcode?: InputMaybe<TextSettingInput>;
  street?: InputMaybe<TextSettingInput>;
  taxId?: InputMaybe<TextSettingInput>;
};

export type PayBilClientMutationInput = {
  client: PayBilClientInput;
  clientId?: InputMaybe<Scalars['ID']['input']>;
  embeddedBy?: InputMaybe<Scalars['String']['input']>;
  testActionAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  withoutAuthorizedUser?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PayBilClientMutationInvalidDataError = {
  __typename?: 'PayBilClientMutationInvalidDataError';
  client?: Maybe<PayBilClient>;
};

export type PayBilClientMutationResult =
  | ErrorResponse
  | PayBilClientMutationInvalidDataError
  | PayBilClientMutationSuccess;

export type PayBilClientMutationSuccess = {
  __typename?: 'PayBilClientMutationSuccess';
  client?: Maybe<PayBilClient>;
};

export type PayBilClientResponse = ErrorResponse | PayBilClient;

export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  /**
   * #soft-logged-in
   *
   *  Billing address associated with the payment account
   */
  address?: Maybe<PaymentBillingAddress>;
  /**
   * #soft-logged-in
   *
   *  Email address where user receives invoices and billing-related messages
   */
  emailCc?: Maybe<Scalars['Email']['output']>;
  /** #soft-logged-in */
  id: Scalars['ID']['output'];
  /**
   * #soft-logged-in
   *
   *  ISO-639 language code for invoice language e.g. 'de'
   */
  invoiceLanguage: PaymentInvoiceLanguage;
  /**
   * #soft-logged-in
   *
   *  The payment method data. e.g. credit card type, IBAN
   */
  paymentData?: Maybe<PaymentData>;
  /** A flag whether user has any open payments */
  paymentsDue?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #soft-logged-in
   *
   *  Personal or company tax ID
   */
  taxId?: Maybe<Scalars['String']['output']>;
};

export type PaymentAccountInput = {
  address?: InputMaybe<PaymentBillingAddressInput>;
  emailCc?: InputMaybe<TextSettingInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** ISO-639 language code for invoice language e.g. 'de' */
  invoiceLanguage?: InputMaybe<TextSettingInput>;
  paymentData?: InputMaybe<PaymentDataInput>;
  taxId?: InputMaybe<TextSettingInput>;
};

export type PaymentAccountMutationResult = {
  __typename?: 'PaymentAccountMutationResult';
  error?: Maybe<PaymentsSettingsError>;
  success?: Maybe<PaymentAccount>;
};

export const enum PaymentAccountType {
  CreditCard = 'CREDIT_CARD',
  Invoice = 'INVOICE',
  Paypal = 'PAYPAL',
  Sepa = 'SEPA',
}

export type PaymentAccounts = {
  __typename?: 'PaymentAccounts';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<PaymentAccount>>>;
};

export type PaymentBillingAddress = {
  __typename?: 'PaymentBillingAddress';
  /** #soft-logged-in */
  academicTitle?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  city: Scalars['String']['output'];
  /** #soft-logged-in */
  company?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  country?: Maybe<CountryCodesAlpha2>;
  /** #soft-logged-in */
  firstName: Scalars['String']['output'];
  /** #soft-logged-in */
  gender: Gender;
  /** #soft-logged-in */
  lastName: Scalars['String']['output'];
  /** #soft-logged-in */
  postcode?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  province?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  street: Scalars['String']['output'];
};

export type PaymentBillingAddressInput = {
  academicTitle?: InputMaybe<TextSettingInput>;
  city?: InputMaybe<TextSettingInput>;
  company?: InputMaybe<TextSettingInput>;
  country?: InputMaybe<CountryCodesAlpha2>;
  firstName?: InputMaybe<TextSettingInput>;
  gender?: InputMaybe<TextSettingInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<TextSettingInput>;
  postcode?: InputMaybe<TextSettingInput>;
  province?: InputMaybe<TextSettingInput>;
  street?: InputMaybe<TextSettingInput>;
};

/**
 * Products are divided into product groups.
 * See the source of [business_product.rb](https://source.xing.com/paybil/payment-billing/blob/master/app/models/products/clients/product/business_product.rb#L5)
 */
export const enum PaymentClientProductGroup {
  Ad = 'AD',
  Bp = 'BP',
  Cd = 'CD',
  Ev = 'EV',
  Ex = 'EX',
  Gr = 'GR',
  Ja = 'JA',
  Pb = 'PB',
  Pc = 'PC',
  Pj = 'PJ',
  Pp = 'PP',
  Pr = 'PR',
  Rm = 'RM',
  Tm = 'TM',
  Tp = 'TP',
  Xf = 'XF',
  Xs = 'XS',
}

export const enum PaymentCurrency {
  Chf = 'CHF',
  Eur = 'EUR',
  Unk = 'UNK',
  Usd = 'USD',
}

export type PaymentData = {
  __typename?: 'PaymentData';
  /**
   * #soft-logged-in
   *
   *  PP-only: Unique ID with PayPal for the established payment agreement
   */
  agreementId?: Maybe<Scalars['String']['output']>;
  /**
   * #soft-logged-in
   *
   *  DD-only: SWIFT/BIC number on the direct debit/Sepa account
   */
  bic?: Maybe<Scalars['String']['output']>;
  /**
   * #soft-logged-in
   *
   *  CC-only: brand name
   */
  brand?: Maybe<Scalars['String']['output']>;
  /**
   * #soft-logged-in
   *
   *  CC-only: Expiration month
   */
  expiryMonth?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  CC-only: Expiration year
   */
  expiryYear?: Maybe<Scalars['Int']['output']>;
  /**
   * #soft-logged-in
   *
   *  CC and DD only: Account holder's name on the account
   */
  holder?: Maybe<Scalars['String']['output']>;
  /**
   * #soft-logged-in
   *
   *  DD-only: IBAN on the direct debit/Sepa account
   */
  iban?: Maybe<Scalars['String']['output']>;
  /**
   * #soft-logged-in
   *
   *  CC-only: Masked account number
   */
  number?: Maybe<Scalars['String']['output']>;
  /**
   * #soft-logged-in
   *
   *  Two-character code identifying the account type.
   */
  type?: Maybe<PaymentAccountType>;
};

export type PaymentDataInput = {
  /** DD-only: SWIFT/BIC number on the direct debit/Sepa account */
  bic?: InputMaybe<TextSettingInput>;
  /** DD only: Account holder's name on the account */
  holder?: InputMaybe<TextSettingInput>;
  /** DD-only: IBAN on the direct debit/Sepa account */
  iban?: InputMaybe<TextSettingInput>;
  /** Two-character code identifying the account type. */
  type?: InputMaybe<PaymentAccountType>;
};

export const enum PaymentDurationType {
  Days = 'DAYS',
  Months = 'MONTHS',
  Weeks = 'WEEKS',
}

export type PaymentInvoice = {
  __typename?: 'PaymentInvoice';
  amount: PaymentPrice;
  date: Scalars['Date']['output'];
  downloadUrl: Scalars['String']['output'];
  dunningLevel: Scalars['Int']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCanceled: Scalars['Boolean']['output'];
  isCancellation: Scalars['Boolean']['output'];
  isRegeneratable: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  productGroups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status: PaymentInvoiceStatus;
};

export const enum PaymentInvoiceLanguage {
  De = 'DE',
  En = 'EN',
}

export const enum PaymentInvoiceStatus {
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Collecting = 'COLLECTING',
  Dunning = 'DUNNING',
  Encashment = 'ENCASHMENT',
  EncashmentFailed = 'ENCASHMENT_FAILED',
  FirstReminder = 'FIRST_REMINDER',
  FourthReminder = 'FOURTH_REMINDER',
  Open = 'OPEN',
  Paid = 'PAID',
  Resolved = 'RESOLVED',
  SecondReminder = 'SECOND_REMINDER',
  ThirdReminder = 'THIRD_REMINDER',
}

export type PaymentInvoices = {
  __typename?: 'PaymentInvoices';
  collection?: Maybe<Array<Maybe<PaymentInvoice>>>;
};

export const enum PaymentMobileMerchant {
  Gplay = 'Gplay',
  ITunes = 'iTunes',
}

export type PaymentNotifications = {
  __typename?: 'PaymentNotifications';
  /** Active subscriptions (product groups) that are paid via 'invoice' (Invoice, SEPA, Credit Card, PayPal) */
  activeInvoicedSubscriptions?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >;
  /** User is blocked from using features */
  blocked: Scalars['Boolean']['output'];
  /** If the user has an expired credit card */
  expiredCreditCard: Scalars['Boolean']['output'];
  /** User is in dunning but not yet blocked. Sometimes called 'low dunning' */
  inNonBlockingDunning: Scalars['Boolean']['output'];
  /** Maximum dunning level across all product groups */
  maxDunningLevel: Scalars['Int']['output'];
  /** Active subscriptions (product groups) that aren't paid by 'invoice'. This indicates mobile subscriptions */
  subscriptionsPaidElsewhere?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Price with the ISO currency code and the amount in cents; not decimal */
export type PaymentPrice = {
  __typename?: 'PaymentPrice';
  /** Amount in cents. e.g. 25.17 EUR would be 2517 */
  amountCents?: Maybe<Scalars['Int']['output']>;
  /** ISO-4217 currency codes. e.g. EUR, USD, CHF */
  currency?: Maybe<PaymentCurrency>;
  /** Net amount in cents. e.g. 25.17 EUR would be 2517 */
  netAmountCents?: Maybe<Scalars['Int']['output']>;
};

/**
 * Represents a subscription the user has or had. There will only be one subscription
 * per productGroup such as Premium, News, ProBusiness.
 */
export type PaymentSubscription = {
  __typename?: 'PaymentSubscription';
  /** Name of the product in English or German. Will be in English if user's language is anything but German */
  description: Scalars['String']['output'];
  /** Date when the subscription was ended or canceled */
  endedOn?: Maybe<Scalars['Date']['output']>;
  /** When the current period of the subscription ends. It might renew though! */
  endsOn: Scalars['Date']['output'];
  /** Subscription is currently in a bonus period */
  inBonus: Scalars['Boolean']['output'];
  /** If the subscription is active and providing features */
  isActive: Scalars['Boolean']['output'];
  /** Features from the subscription are blocked, usually due to non-payment */
  isBlocked: Scalars['Boolean']['output'];
  /** If the subscription is canceled */
  isCanceled: Scalars['Boolean']['output'];
  /** If the subscription is endable */
  isEndable: Scalars['Boolean']['output'];
  /** If the subscription is ended */
  isEnded: Scalars['Boolean']['output'];
  /** If the subscription is paused. Features not granted, accounting paused */
  isPaused: Scalars['Boolean']['output'];
  isRunning: Scalars['Boolean']['output'];
  /** Google or Apple if this is a mobile subscription */
  merchant?: Maybe<PaymentMobileMerchant>;
  price?: Maybe<PaymentPrice>;
  productGroup: Scalars['String']['output'];
  renewal?: Maybe<PaymentSubscriptionRenewal>;
  runtime: PaymentSubscriptionRuntime;
  /** A subscription will have one or more statuses such as past, future, cancelled, paused, current. */
  statuses?: Maybe<Array<Maybe<PaymentSubscriptionStatus>>>;
  /** If the contract is paused, the date on which it will automatically unpause */
  unpauseOn?: Maybe<Scalars['Date']['output']>;
  /** If this is a recurring subscription and will renew after the `endsOn` date */
  willRenew: Scalars['Boolean']['output'];
};

export type PaymentSubscriptionRenewal = {
  __typename?: 'PaymentSubscriptionRenewal';
  /** If the renewal is a bonus */
  isBonus: Scalars['Boolean']['output'];
  /** Price after renewal. Can change if the user had an introductory price */
  price?: Maybe<PaymentPrice>;
  /** Runtime of the renewal (next) contract */
  runtime?: Maybe<PaymentSubscriptionRuntime>;
  /** Date of the first day of the new contract period */
  startDate?: Maybe<Scalars['Date']['output']>;
};

/** Duration of a subscription. Typically 3, 6, or 12 months */
export type PaymentSubscriptionRuntime = {
  __typename?: 'PaymentSubscriptionRuntime';
  /** Duration interval. For example a 3 month subscription, this field is 3 */
  duration: Scalars['Int']['output'];
  /** Duration type. Either 'days', 'weeks', or 'months' */
  durationType: PaymentDurationType;
};

/** See the source of [contract_decorator.rb](https://source.xing.com/paybil/payment-billing/blob/master/app/decorators/contract_decorator.rb) */
export const enum PaymentSubscriptionStatus {
  Canceled = 'CANCELED',
  Current = 'CURRENT',
  Ended = 'ENDED',
  Future = 'FUTURE',
  InvoiceCanceled = 'INVOICE_CANCELED',
  Past = 'PAST',
  Paused = 'PAUSED',
  ResumeDisabled = 'RESUME_DISABLED',
  Subscription = 'SUBSCRIPTION',
}

export type PaymentSubscriptions = {
  __typename?: 'PaymentSubscriptions';
  collection?: Maybe<Array<Maybe<PaymentSubscription>>>;
};

export type PaymentsAttributeMessage = {
  __typename?: 'PaymentsAttributeMessage';
  /** #soft-logged-in */
  attribute?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  messages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PaymentsSettingsError = {
  __typename?: 'PaymentsSettingsError';
  errors?: Maybe<Array<Maybe<PaymentsAttributeMessage>>>;
};

export type PaypalUrl = {
  __typename?: 'PaypalUrl';
  /** #soft-logged-in */
  errors?: Maybe<Array<Maybe<PaymentsAttributeMessage>>>;
  /**
   * #soft-logged-in
   *
   *  url for paypal payment flow / pop-up
   */
  url?: Maybe<Scalars['String']['output']>;
};

export type PdfDescription = {
  __typename?: 'PdfDescription';
  /** #logged-out #soft-logged-in */
  htmlContent: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  pdfUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  previewImage?: Maybe<PdfPreviewImage>;
};

export type PdfPreviewImage = {
  __typename?: 'PdfPreviewImage';
  /** #logged-out #soft-logged-in */
  image870x: Scalars['URL']['output'];
};

export type PendingNameGenderSetting = HasGlobalId & {
  __typename?: 'PendingNameGenderSetting';
  /** Globally unique id for this instance */
  globalId: Scalars['GlobalID']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type PendingNameGenderSettingInput = {
  academicTitle?: InputMaybe<SelectSettingInput>;
  birthName?: InputMaybe<TextSettingInput>;
  explanation?: InputMaybe<TextSettingInput>;
  firstName?: InputMaybe<TextSettingInput>;
  gender?: InputMaybe<SelectSettingInput>;
  lastName?: InputMaybe<TextSettingInput>;
};

export type PendingNameGenderSettings = {
  __typename?: 'PendingNameGenderSettings';
  academicTitle?: Maybe<PendingNameGenderSetting>;
  birthName?: Maybe<PendingNameGenderSetting>;
  explanation?: Maybe<PendingNameGenderSetting>;
  firstName?: Maybe<PendingNameGenderSetting>;
  gender?: Maybe<PendingNameGenderSetting>;
  lastName?: Maybe<PendingNameGenderSetting>;
};

export type PeopleBreadcrumbsItem = {
  __typename?: 'PeopleBreadcrumbsItem';
  /** #logged-out #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PeopleByCityChunkPage = {
  __typename?: 'PeopleByCityChunkPage';
  /** #logged-out #soft-logged-in */
  breadcrumbs?: Maybe<Array<Maybe<PeopleBreadcrumbsItem>>>;
  /** #logged-out #soft-logged-in */
  city?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  country?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  letter?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  names?: Maybe<Array<Maybe<PeopleDirectoryItem>>>;
  /** #logged-out #soft-logged-in */
  page?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  toName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PeopleByCityTopLevelPage = {
  __typename?: 'PeopleByCityTopLevelPage';
  /** #logged-out #soft-logged-in */
  breadcrumbs?: Maybe<Array<Maybe<PeopleBreadcrumbsItem>>>;
  /** #logged-out #soft-logged-in */
  city?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  country?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  letter?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  names?: Maybe<Array<Maybe<PeopleDirectoryItem>>>;
  /** #logged-out #soft-logged-in */
  popularNames?: Maybe<Array<Maybe<PeopleUserBagItem>>>;
  /** #logged-out #soft-logged-in */
  toName?: Maybe<Scalars['String']['output']>;
};

export type PeopleByCityUserBagPage = {
  __typename?: 'PeopleByCityUserBagPage';
  /** #logged-out #soft-logged-in */
  breadcrumbs?: Maybe<Array<Maybe<PeopleBreadcrumbsItem>>>;
  /** #logged-out #soft-logged-in */
  city?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  count?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  names?: Maybe<Array<Maybe<PeopleProfileItem>>>;
  /** #logged-out #soft-logged-in */
  page?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PeopleChildItem = {
  __typename?: 'PeopleChildItem';
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  toName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PeopleChunkPage = {
  __typename?: 'PeopleChunkPage';
  /** #logged-out #soft-logged-in */
  breadcrumbs?: Maybe<Array<Maybe<PeopleBreadcrumbsItem>>>;
  /** #logged-out #soft-logged-in */
  cities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #logged-out #soft-logged-in */
  letter?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  names?: Maybe<Array<Maybe<PeopleDirectoryItem>>>;
  /** #logged-out #soft-logged-in */
  page?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  toName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PeopleCitiesTopLevelPage = {
  __typename?: 'PeopleCitiesTopLevelPage';
  /** #logged-out #soft-logged-in */
  cityTopLevelPages?: Maybe<Array<Maybe<PeopleCityTopLevelPage>>>;
};

export type PeopleCityPage = {
  __typename?: 'PeopleCityPage';
  /** #logged-out #soft-logged-in */
  breadcrumbs?: Maybe<Array<Maybe<PeopleBreadcrumbsItem>>>;
  /** #logged-out #soft-logged-in */
  city?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  country?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  letters?: Maybe<Array<Maybe<PeopleLetterForCity>>>;
};

export type PeopleCityTopLevelPage = {
  __typename?: 'PeopleCityTopLevelPage';
  /** #logged-out #soft-logged-in */
  cityName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  exampleNames?: Maybe<Array<Maybe<PeopleUserBagItem>>>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PeopleDirectoryItem =
  | PeopleChildItem
  | PeopleProfileItem
  | PeopleUserBagItem;

export type PeopleLetterForCity = {
  __typename?: 'PeopleLetterForCity';
  /** #logged-out #soft-logged-in */
  letter?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  topUserBags?: Maybe<Array<Maybe<PeopleUserBagItem>>>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PeopleLetterTopLevelPage = {
  __typename?: 'PeopleLetterTopLevelPage';
  /** #logged-out #soft-logged-in */
  exampleNames?: Maybe<Array<Maybe<PeopleUserBagItem>>>;
  /** #logged-out #soft-logged-in */
  letter?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PeopleProfileItem = {
  __typename?: 'PeopleProfileItem';
  /** #logged-out #soft-logged-in */
  city?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  coach?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  occupationTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  photoUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PeopleStartPage = {
  __typename?: 'PeopleStartPage';
  /** #logged-out #soft-logged-in */
  cityTopLevelPages?: Maybe<Array<Maybe<PeopleCityTopLevelPage>>>;
  /** #logged-out #soft-logged-in */
  letterTopLevelPages?: Maybe<Array<Maybe<PeopleLetterTopLevelPage>>>;
  /** #logged-out #soft-logged-in */
  seeMore?: Maybe<Scalars['String']['output']>;
};

export type PeopleStoryGeneratorMymkRecommendation = {
  __typename?: 'PeopleStoryGeneratorMymkRecommendation';
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  reasons: Array<Scalars['String']['output']>;
  trackingToken: Scalars['String']['output'];
  urn: Scalars['GlobalID']['output'];
};

export type PeopleStoryGeneratorProfileUpdate = {
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  profileId?: Maybe<Scalars['ID']['output']>;
};

export type PeopleTopLevelPage = {
  __typename?: 'PeopleTopLevelPage';
  /** #logged-out #soft-logged-in */
  breadcrumbs?: Maybe<Array<Maybe<PeopleBreadcrumbsItem>>>;
  /** #logged-out #soft-logged-in */
  letter?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  names?: Maybe<Array<Maybe<PeopleDirectoryItem>>>;
  /** #logged-out #soft-logged-in */
  popularNames?: Maybe<Array<Maybe<PeopleUserBagItem>>>;
};

export type PeopleUserBagConnection = HasCursorPagination & {
  __typename?: 'PeopleUserBagConnection';
  /** #logged-out #soft-logged-in */
  edges?: Maybe<Array<Maybe<PeopleUserBagConnectionEdge>>>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  userBagUrl?: Maybe<Scalars['URL']['output']>;
};

export type PeopleUserBagConnectionEdge = PaginationEdge & {
  __typename?: 'PeopleUserBagConnectionEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node: RelatedProfile;
};

export type PeopleUserBagItem = {
  __typename?: 'PeopleUserBagItem';
  /** #logged-out #soft-logged-in */
  count?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PeopleUserBagPage = {
  __typename?: 'PeopleUserBagPage';
  /** #logged-out #soft-logged-in */
  breadcrumbs?: Maybe<Array<Maybe<PeopleBreadcrumbsItem>>>;
  /** #logged-out #soft-logged-in */
  cities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #logged-out #soft-logged-in */
  count?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  letter?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  names?: Maybe<Array<Maybe<PeopleProfileItem>>>;
  /** #logged-out #soft-logged-in */
  page?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PersonMakeFriend = {
  __typename?: 'PersonMakeFriend';
  /** #rest(batched) #virtual */
  contact?: Maybe<XingId>;
  /** #rest(batched) #virtual */
  newContact?: Maybe<XingId>;
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  urn: Scalars['GlobalID']['output'];
};

export type PersonalDetails = HasDerivedGlobalId & {
  __typename?: 'PersonalDetails';
  /** Birth date of the user */
  birthDate?: Maybe<BirthDate>;
  /** Birth name of the user */
  birthName?: Maybe<Scalars['String']['output']>;
  /** Globally unique id for this instance based on typename and id */
  globalId: Scalars['GlobalID']['output'];
  /** Id of the user */
  id: Scalars['ID']['output'];
};

export type PersonalDetailsError = {
  __typename?: 'PersonalDetailsError';
  birthDate?: Maybe<Array<Scalars['String']['output']>>;
  birthName?: Maybe<Array<Scalars['String']['output']>>;
};

/** Profile Module Personal Details */
export type PersonalDetailsModule = ModuleInterface & {
  __typename?: 'PersonalDetailsModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  /** #rest(batched) #virtual */
  personalDetails?: Maybe<PersonalDetails>;
  title: Scalars['String']['output'];
};

export type PersonalDetailsUpdateError = {
  __typename?: 'PersonalDetailsUpdateError';
  errors?: Maybe<PersonalDetailsError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PersonalDetailsUpdateInput = {
  /** Birth date of the user */
  birthDate?: InputMaybe<BirthDateUpdateInput>;
  /** Birth name of the user */
  birthName?: InputMaybe<Scalars['String']['input']>;
};

export type PersonalDetailsUpdateResult = {
  __typename?: 'PersonalDetailsUpdateResult';
  error?: Maybe<PersonalDetailsUpdateError>;
  /** #rest(batched) #virtual */
  success?: Maybe<PersonalDetails>;
};

/**
 * "
 * A standard container for telephone numbers based on the [E.164](https://en.wikipedia.org/wiki/E.164) standard.
 *
 * This container offers support for also exposing telephone numbers which are not valid according to the standard via the mandatory raw field. Use this to enable update by the user on next save.
 *
 * Expected behaviour:
 *
 * If the telephone number is present and valid, all fields should be filled.
 * If the telephone number is invalid, only raw is filled while the rest of the fields are set to null.
 * The whole object should be null in the parent in the absence of a telephone number.
 */
export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  /**
   * #logged-out #soft-logged-in
   *
   *  The ISO-3166-1 country code of the telephone number
   */
  countryCode?: Maybe<CountryCodesAlpha2>;
  /**
   * #logged-out #soft-logged-in
   *
   *  The phone number in the internationally formatted E.164 format for display purposes
   */
  internationalFormat?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  The telephone number in the E.164 format
   */
  phoneNumber?: Maybe<Scalars['PhoneNumberE164']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Raw underlying value. Caution this can be potentially not valid E1.164
   */
  raw?: Maybe<Scalars['String']['output']>;
};

export const enum PhonePreferenceDays {
  EveryDay = 'EVERY_DAY',
  Weekend = 'WEEKEND',
  WorkingDays = 'WORKING_DAYS',
}

export type PhonePreferenceTime = Localizable & {
  __typename?: 'PhonePreferenceTime';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

/** Phone Preferences */
export type PhonePreferences =
  | AnyTimePhonePreferences
  | SpecificTimePhonePreferences;

export const enum PhonePreferencesOptions {
  AnyTime = 'ANY_TIME',
  SpecificTime = 'SPECIFIC_TIME',
}

/** Active and pending phone numbers */
export type PhoneSetting = HasGlobalId & {
  __typename?: 'PhoneSetting';
  /** current phone number */
  active?: Maybe<Scalars['PhoneNumberE164']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Globally unique id for this instance */
  globalId: Scalars['GlobalID']['output'];
  /** new phone number the user has submitted, not verified yet */
  pending?: Maybe<Scalars['PhoneNumberE164']['output']>;
};

/**
 * Used to set new phone number, and to verify the change.
 * Only one of the fields should be submitted
 */
export type PhoneSettingInput = {
  /** code to confirm new phone number, received by SMS */
  activationCode?: InputMaybe<Scalars['String']['input']>;
  passwordToken?: InputMaybe<Scalars['String']['input']>;
  /** new phone number, will need to be confirmed using an SMS code */
  pending?: InputMaybe<Scalars['PhoneNumberE164']['input']>;
};

export type PollOptionInput = {
  text: Scalars['String']['input'];
};

/**
 * A posting that can be created in the commbox.
 * Every published posting is made visible by a corresponding startpage story.
 */
export type PostingActor = EntityPage | XingId;

export const enum PostingAdSubType {
  Image = 'IMAGE',
  LinkShare = 'LINK_SHARE',
}

/** indicates whether the posting has been spread by startpage */
export const enum PostingPublicationState {
  /** the posting has not yet been spread */
  Draft = 'DRAFT',
  /** a spread posting is no longer visible */
  Hidden = 'HIDDEN',
  /** the posting has already been spread */
  Published = 'PUBLISHED',
  /** the posting is going to be spread at the time specified in `published_at` */
  Scheduled = 'SCHEDULED',
}

/** When you would like your posting to be published */
export const enum PostingPublicationStrategy {
  /** create posting as a draft, without spreading */
  Later = 'LATER',
  /** create, publish and spread the posting immediately */
  Now = 'NOW',
}

export type PostingsArticleAttachment = PostingsAttachment & {
  __typename?: 'PostingsArticleAttachment';
  /** #logged-out #soft-logged-in #rest #virtual */
  article?: Maybe<ArticlesArticle>;
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
};

export type PostingsArticleAttachmentArticleArgs = {
  supportedBlockTypes?: Array<ArticleSupportedBlockTypes>;
};

export type PostingsAttachment = {
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
};

export type PostingsAttachmentMutationResult = {
  __typename?: 'PostingsAttachmentMutationResult';
  error?: Maybe<PostingsError>;
  success?: Maybe<PostingsAttachment>;
};

export const enum PostingsAvailablePostingTypes {
  ArticlePosting = 'ARTICLE_POSTING',
  ImagePosting = 'IMAGE_POSTING',
  LinkSharePosting = 'LINK_SHARE_POSTING',
  PlainTextPosting = 'PLAIN_TEXT_POSTING',
  PollPosting = 'POLL_POSTING',
  VideoPosting = 'VIDEO_POSTING',
}

export type PostingsBatchStoryInput = {
  /**
   * Defines the story's type.
   * Text and Image stories have an Image medium, whereas video stories have a video.
   * While text and image stories behave near identical, we still need the right type for tracking.
   */
  storyType: StoryType;
  /** see PostingsStory.text */
  text?: InputMaybe<Scalars['String']['input']>;
  /**
   * must be an uploadId of an image
   * Story type image or text requires an uploadId. Story type video ignores this
   */
  uploadId?: InputMaybe<Scalars['UploadId']['input']>;
  /**
   * GlobalID of an uploaded video that has not been processed yet.
   * The owner of the video should be the story's story group.
   * Story type video requires a videoUrn. Story types image and text ignore this
   */
  videoUrn?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type PostingsCreateImageAttachmentInput = {
  /** a hash representing the id of the uploaded image in the upload service */
  uploadId: Scalars['String']['input'];
};

export type PostingsCreateLinkAttachmentInput = {
  customDescription?: InputMaybe<Scalars['String']['input']>;
  /** a hash representing the id of the uploaded image in the upload service */
  customImageUploadId?: InputMaybe<Scalars['String']['input']>;
  customTitle?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['URL']['input'];
};

export type PostingsCreatePollAttachmentInput = {
  /** An integer representing the days from now that the poll is open */
  openDays: Scalars['Int']['input'];
  options: Array<PollOptionInput>;
  question: Scalars['String']['input'];
};

export type PostingsCreatePostingInput = {
  /** on whose behalf this posting shall be posted */
  actorGlobalId: Scalars['GlobalID']['input'];
  /**
   * A list of AGIs that define the audience which should receive this posting
   * https://new-work.atlassian.net/wiki/spaces/RAC/pages/55182221/Actor+Group+Identifiers+-+AGIs
   * If omitted, a default is picked depending on the posting's actor:
   * - `users:user`: the user's network, `["urn:x-xing:contacts:network:<user-id>"]`
   * - `entitypage:page`: its followers `["urn:x-xing:entitypages:page:<user-id>:followers"]`
   */
  audience?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  /** legacy plaintext format */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** new comment format that allows for @mentions; will eventually outmode the `comment` field */
  commentArticleV1?: InputMaybe<Array<ArticlesCreateArticleBlocksInput>>;
  /** upload ids of images to be attached */
  images?: InputMaybe<Array<PostingsCreateImageAttachmentInput>>;
  /** urls of links to be shared */
  links?: InputMaybe<Array<PostingsCreateLinkAttachmentInput>>;
  /** poll data to be created */
  polls?: InputMaybe<Array<PostingsCreatePollAttachmentInput>>;
  publicationStrategy?: InputMaybe<PostingPublicationStrategy>;
  publishAt?: InputMaybe<Scalars['Date']['input']>;
  /**
   * the context to which the posting belongs
   * possible values currently are, but will not necessarily
   * be limited to, discussion module global ids
   */
  targetGlobalId?: InputMaybe<Scalars['GlobalID']['input']>;
  /** pre-uploaded and processed videos to be attached */
  videos?: InputMaybe<Array<PostingsCreateVideoAttachmentInput>>;
  /** who should be able to see this posting */
  visibility?: InputMaybe<PostingsVisibility>;
};

export type PostingsCreateRssImporterInput = {
  /** on whose behalf this importer shall post */
  actorGlobalId: Scalars['GlobalID']['input'];
  /** the feed's URL */
  url: Scalars['URL']['input'];
};

export type PostingsCreateStoryGroupInput = {
  /**
   * The actor on whose behalf the story group is created.
   * Will check whether the current user is allowed to impersonate this actor
   */
  actorGlobalId: Scalars['GlobalID']['input'];
};

export type PostingsCreateStoryInput = {
  /**
   * the actor on whose behalf this is created.
   * A permission check is performed whether the current user is allowed to
   * create stories on behalf of this actor
   */
  actorGlobalId: Scalars['GlobalID']['input'];
  /**
   * A list of AGIs that define the audience which should receive this story
   * https://new-work.atlassian.net/wiki/spaces/RAC/pages/55182221/Actor+Group+Identifiers+-+AGIs
   * If omitted, a default is picked depending on the story's actor:
   * - `users:user`: the user's network, `["urn:x-xing:contacts:network:<user-id>"]`
   * - `entitypage:page`: nil
   */
  audience?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  /**
   * lets the Backend know if this image was uploaded by the users or generated from text by the client app
   * Mostly for tracking and our statistics
   */
  storyType: StoryType;
  /** see PostingsStory.text */
  text?: InputMaybe<Scalars['String']['input']>;
  /** must be an uploadId of an image */
  uploadId: Scalars['UploadId']['input'];
};

export type PostingsCreateTwitterImporterInput = {
  /** on whose behalf this importer shall post */
  actorGlobalId: Scalars['GlobalID']['input'];
  /** the twitter account's handle */
  handle: Scalars['String']['input'];
};

export type PostingsCreateVideoAttachmentInput = {
  /**
   * ID of a previously uploaded and processed video
   * Typically this video should have been uploaded specifically for posting it
   * Caution: The postings service will assume ownership of this video and,
   * should the posting get deleted, also delete the video
   */
  videoId: Scalars['ID']['input'];
};

export type PostingsDeleteImporterInput = {
  /** reference to the importer that is to be deleted */
  globalId: Scalars['GlobalID']['input'];
};

export type PostingsDeleteMyMentionsInput = {
  /** GlobalID of the posting which includes mentions of you that you would like to delete. */
  globalId: Scalars['GlobalID']['input'];
};

export type PostingsDeletePostingInput = {
  /**
   * Actor on whose behalf the posting should be deleted.
   * Defaults to the posting's own actorGlobalId
   */
  actorGlobalId?: InputMaybe<Scalars['GlobalID']['input']>;
  id: Scalars['ID']['input'];
};

export type PostingsDeleteStoryInput = {
  /**
   * Actor on whose behalf the story should be deleted.
   * Defaults to the story's own actorGlobalId
   */
  actorGlobalId?: InputMaybe<Scalars['GlobalID']['input']>;
  id: Scalars['ID']['input'];
};

export type PostingsError = {
  __typename?: 'PostingsError';
  /** contains all errors that occured */
  details?: Maybe<Array<Scalars['String']['output']>>;
  /** what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type PostingsImageAttachment = PostingsAttachment &
  StoryMedium & {
    __typename?: 'PostingsImageAttachment';
    /** #logged-out #soft-logged-in */
    globalId: Scalars['GlobalID']['output'];
    /** #logged-out #soft-logged-in */
    id: Scalars['ID']['output'];
    /** #rest(batched) #virtual */
    imageMetadata?: Maybe<OriginalImageMetadata>;
    /** #logged-out #soft-logged-in #virtual */
    images?: Maybe<Array<ScaledImage>>;
    /** #logged-out #soft-logged-in */
    uuid: Scalars['UUID']['output'];
  };

export type PostingsImageAttachmentImagesArgs = {
  dimensions: Array<ScaledImageDimension>;
};

export type PostingsImporters = {
  __typename?: 'PostingsImporters';
  /** #expand */
  rssImporters: Array<PostingsRssFeed>;
  /** #expand */
  twitterImporters: Array<PostingsTwitterTimeline>;
};

export type PostingsLinkAttachment = PostingsAttachment & {
  __typename?: 'PostingsLinkAttachment';
  /** #logged-out #soft-logged-in */
  customDescription?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #virtual */
  customImages?: Maybe<Array<ScaledImage>>;
  /** #logged-out #soft-logged-in */
  customTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in #rest #virtual */
  link?: Maybe<SocialExternalLinkResult>;
  /** #logged-out #soft-logged-in */
  url: Scalars['URL']['output'];
};

export type PostingsLinkAttachmentCustomImagesArgs = {
  dimensions: Array<ScaledImageDimension>;
};

export type PostingsPaginatedCollectionMetadata = {
  __typename?: 'PostingsPaginatedCollectionMetadata';
  hiddenTotal?: Maybe<Scalars['Int']['output']>;
  publishedTotal?: Maybe<Scalars['Int']['output']>;
};

export type PostingsPaginatedPostingConnection = HasCursorPagination & {
  __typename?: 'PostingsPaginatedPostingConnection';
  /** #logged-out #soft-logged-in */
  edges: Array<PostingsPaginatedPostingEdge>;
  metadata?: Maybe<PostingsPaginatedCollectionMetadata>;
  /** #logged-out #soft-logged-in */
  pageInfo: PageInfo;
  /** #logged-out #soft-logged-in #rest #virtual */
  pinnedPostings?: Maybe<PostingsPostingCollection>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type PostingsPaginatedPostingConnectionPinnedPostingsArgs = {
  supportedObjectTypes: Array<PostingsAvailablePostingTypes>;
};

export type PostingsPaginatedPostingEdge = PaginationEdge & {
  __typename?: 'PostingsPaginatedPostingEdge';
  /** #logged-out #soft-logged-in */
  cursor: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  node: PostingsPosting;
};

export type PostingsPinActivityByTargetInput = {
  activityId: Scalars['ID']['input'];
  targetGlobalId: Scalars['GlobalID']['input'];
};

export type PostingsPinActivityInput = {
  activityId: Scalars['ID']['input'];
  entityPageId: Scalars['ID']['input'];
};

export type PostingsPinActivityResult = {
  __typename?: 'PostingsPinActivityResult';
  error?: Maybe<PostingsError>;
  success?: Maybe<PostingsPinnedActivity>;
};

export type PostingsPinnedActivity = {
  __typename?: 'PostingsPinnedActivity';
  activityId: Scalars['ID']['output'];
  entityPageId: Scalars['ID']['output'];
  targetGlobalId?: Maybe<Scalars['GlobalID']['output']>;
};

export type PostingsPollAttachment = PostingsAttachment & {
  __typename?: 'PostingsPollAttachment';
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  isClosed: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  isCreator: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  options: Array<PostingsPollOption>;
  /** #logged-out #soft-logged-in */
  participantsIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #logged-out #soft-logged-in */
  question: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  secondsLeft: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  votedOption?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  votes: Scalars['Int']['output'];
};

export type PostingsPollOption = {
  __typename?: 'PostingsPollOption';
  /** #logged-out #soft-logged-in */
  id: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  percentage?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export type PostingsPopulateStoryGroupInput = {
  /**
   * A list of AGIs that define the audience which should receive this story
   * https://new-work.atlassian.net/wiki/spaces/RAC/pages/55182221/Actor+Group+Identifiers+-+AGIs
   * If omitted, a default is picked depending on the story's actor:
   * - `users:user`: the user's network, `["urn:x-xing:contacts:network:<user-id>"]`
   * - `entitypage:page`: nil
   */
  audience?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  stories: Array<PostingsBatchStoryInput>;
  /** ID of the story group that you'd like to populate */
  storyGroupGlobalId: Scalars['GlobalID']['input'];
};

export type PostingsPosting = HasOperationalDataTrackingTokens & {
  __typename?: 'PostingsPosting';
  actions?: Maybe<PostingsPostingActions>;
  /**
   * #logged-out #soft-logged-in
   *
   *  see activityId
   */
  activityGlobalId?: Maybe<Scalars['GlobalID']['output']>;
  /** a posting's activity is created when the posting gets published */
  activityId?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  actor?: Maybe<PostingActor>;
  /**
   * #logged-out #soft-logged-in
   *
   *  on whose behalf this posting has been posted. E.g. a page or the user themself. Becomes the actor of the corresponding startpage story
   */
  actorGlobalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  attachments: Array<PostingsAttachment>;
  /**
   * #logged-out #soft-logged-in
   *
   *  A comment on the posted content. Sometimes the only content of a posting.
   */
  comment: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  commentArticleV1?: Maybe<Array<ArticleBlock>>;
  /** #logged-out #soft-logged-in */
  createdAt: Scalars['Date']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  whether the posting's comment/commentArticleV1 property has been changed
   */
  edited: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /**
   * #rest(batched) #virtual
   *
   *  the social interaction target of the posting (to query mention permissions)
   */
  interactionTarget?: Maybe<SocialInteractionTarget>;
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #logged-out #soft-logged-in */
  publicationState: PostingPublicationState;
  /**
   * #logged-out #soft-logged-in
   *
   *  When the posting has been - or is going to be - published
   * For published postings this lies in the past
   * For scheduled postings it is in the future
   * For draft postings it is null
   */
  publishedAt?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  targetGlobalId?: Maybe<Scalars['GlobalID']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  who should be able to see this posting
   */
  visibility: PostingsVisibility;
};

export type PostingsPostingActions = {
  __typename?: 'PostingsPostingActions';
  canDelete: Scalars['Boolean']['output'];
  canEdit: Scalars['Boolean']['output'];
  canHide: Scalars['Boolean']['output'];
  canPin: Scalars['Boolean']['output'];
};

export type PostingsPostingCollection = {
  __typename?: 'PostingsPostingCollection';
  /** #logged-out #soft-logged-in */
  collection: Array<PostingsPosting>;
};

export type PostingsPostingDeleteResult = {
  __typename?: 'PostingsPostingDeleteResult';
  error?: Maybe<PostingsError>;
};

export type PostingsPostingMutationResult = {
  __typename?: 'PostingsPostingMutationResult';
  error?: Maybe<PostingsError>;
  success?: Maybe<PostingsPosting>;
};

export type PostingsPublishPostingInput = {
  /**
   * Actor on whose behalf the posting should be published.
   * Defaults to the posting's own actorGlobalId
   */
  actorGlobalId?: InputMaybe<Scalars['GlobalID']['input']>;
  id: Scalars['ID']['input'];
};

export type PostingsRssFeed = {
  __typename?: 'PostingsRssFeed';
  globalId: Scalars['GlobalID']['output'];
  url: Scalars['URL']['output'];
};

export type PostingsRssImporterMutationResult = {
  __typename?: 'PostingsRssImporterMutationResult';
  error?: Maybe<PostingsError>;
  success?: Maybe<PostingsRssFeed>;
};

/**
 * Stories consist of an image, or a text that is rendered as an image.
 * They are shared to contacts privately. Interaction like commenting happens
 * privately as well, via private messages. Stories are subject to ephemerality,
 * i.e. they are deleted after a while.
 */
export type PostingsStory = HasOperationalDataTrackingTokens & {
  __typename?: 'PostingsStory';
  activityGlobalId?: Maybe<Scalars['GlobalID']['output']>;
  /** #rest(batched) #virtual */
  actor?: Maybe<StoryActor>;
  actorGlobalId: Scalars['GlobalID']['output'];
  /** @deprecated use postedAt instead. It's the same date, except it can be null when required. WILL BE REMOVED ... */
  createdAt: Scalars['Date']['output'];
  expiresAt?: Maybe<Scalars['Date']['output']>;
  globalID: Scalars['GlobalID']['output'];
  /** Indicates whether the user has already seen this story */
  hasSeen: Scalars['Boolean']['output'];
  /**
   * #virtual
   *
   *  the image makes up the content of a story
   * @deprecated Please use medium instead.
   */
  images?: Maybe<Array<ScaledImage>>;
  interactions: StoryInteractions;
  medium: StoryMedium;
  /**
   * Token used to join Delivery and Tracking events used in Operational Tracking
   * https://operational-tracking.xing.io/tracking_token/
   */
  metadata?: Maybe<PostingsStoryMetadata>;
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** When the story was posted. `null` for demo stories. */
  postedAt?: Maybe<Scalars['Date']['output']>;
  storyType: StoryType;
  /** A text that describes or adds information to the story's medium */
  text?: Maybe<Scalars['String']['output']>;
};

/**
 * Stories consist of an image, or a text that is rendered as an image.
 * They are shared to contacts privately. Interaction like commenting happens
 * privately as well, via private messages. Stories are subject to ephemerality,
 * i.e. they are deleted after a while.
 */
export type PostingsStoryImagesArgs = {
  dimensions: Array<ScaledImageDimension>;
};

export type PostingsStoryMetadata = {
  __typename?: 'PostingsStoryMetadata';
  /** Unique viewers of this story. `null` indicates missing permission to access this field. */
  uniqueUserViews?: Maybe<Scalars['Int']['output']>;
};

/**
 * not much of a promise yet, but can be extended to support polling until the
 * story has been processed
 */
export type PostingsStoryPromise = HasOperationalDataTrackingTokens & {
  __typename?: 'PostingsStoryPromise';
  actorGlobalId: Scalars['GlobalID']['output'];
  createdAt: Scalars['Date']['output'];
  expiresAt?: Maybe<Scalars['Date']['output']>;
  globalId: Scalars['GlobalID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  storyType: StoryType;
  text?: Maybe<Scalars['String']['output']>;
};

export type PostingsToggleHiddenPostingInput = {
  /**
   * Actor on whose behalf the posting should be hidden or unhidden.
   * Defaults to the posting's own actorGlobalId
   */
  actorGlobalId?: InputMaybe<Scalars['GlobalID']['input']>;
  id: Scalars['ID']['input'];
};

export type PostingsTwitterImporterMutationResult = {
  __typename?: 'PostingsTwitterImporterMutationResult';
  error?: Maybe<PostingsError>;
  success?: Maybe<PostingsTwitterTimeline>;
};

export type PostingsTwitterTimeline = {
  __typename?: 'PostingsTwitterTimeline';
  globalId: Scalars['GlobalID']['output'];
  handle: Scalars['String']['output'];
};

export type PostingsUnpinActivityByTargetInput = {
  targetGlobalId: Scalars['GlobalID']['input'];
};

export type PostingsUnpinActivityInput = {
  entityPageId: Scalars['ID']['input'];
};

export type PostingsUnpinActivityResult = {
  __typename?: 'PostingsUnpinActivityResult';
  error?: Maybe<PostingsError>;
};

export type PostingsUpdateLinkShareInput = {
  customDescription?: InputMaybe<Scalars['String']['input']>;
  /** this upload ID is provided by the uploadRequest mutation */
  customImageUploadId?: InputMaybe<Scalars['String']['input']>;
  customTitle?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type PostingsUpdatePostingInput = {
  /**
   * Actor on whose behalf the posting should be updated.
   * Defaults to the posting's own actorGlobalId
   */
  actorGlobalId?: InputMaybe<Scalars['GlobalID']['input']>;
  /** legacy plaintext format */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** new comment format that allows for @mentions; will eventually outmode the `comment` */
  commentArticleV1?: InputMaybe<Array<ArticlesCreateArticleBlocksInput>>;
  id: Scalars['ID']['input'];
  /**
   * Providing this reschedules a scheduled posting. It can also schedule a draft posting.
   * Does not affect postings that have already been published.
   */
  publishAt?: InputMaybe<Scalars['Date']['input']>;
};

export type PostingsVideoAttachment = PostingsAttachment &
  StoryMedium & {
    __typename?: 'PostingsVideoAttachment';
    /** #logged-out #soft-logged-in */
    globalId: Scalars['GlobalID']['output'];
    /** #logged-out #soft-logged-in */
    id: Scalars['ID']['output'];
    /**
     * #logged-out #soft-logged-in #rest(batched) #virtual
     * @deprecated Please use videoV2 instead.
     */
    video?: Maybe<Video>;
    /** #logged-out #soft-logged-in #rest(batched) #virtual */
    videoV2?: Maybe<VideoV2>;
  };

export type PostingsVideoCreateInput = {
  actorGlobalId: Scalars['GlobalID']['input'];
  fileName: Scalars['String']['input'];
  ownerUrn: Scalars['String']['input'];
};

export type PostingsVideoCreateMutationResult = {
  __typename?: 'PostingsVideoCreateMutationResult';
  error?: Maybe<PostingsError>;
  success?: Maybe<PostingsVideoUploadResponse>;
};

export type PostingsVideoUploadResponse = VideoUploadResponse & {
  __typename?: 'PostingsVideoUploadResponse';
  videoDetails: VideoUploadDetails;
};

export const enum PostingsVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type PostingsVoteForPollOptionInput = {
  /** pollOptionId of the poll that you would like to vote. */
  pollOptionId: Scalars['Int']['input'];
};

export type PostingsVoteForPollOptionResult =
  | PostingsError
  | PostingsPollAttachment;

export type PotentialCandidate = {
  __typename?: 'PotentialCandidate';
  interactions: Array<Maybe<PotentialCandidatesInteraction>>;
  lastInteraction?: Maybe<Scalars['Date']['output']>;
  /** #rest(batched) #virtual */
  profileAdditionalDetails?: Maybe<XjmProfileAdditionalDetails>;
  /** #rest(batched) #virtual */
  profileDetails?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export type PotentialCandidatesCollection = {
  __typename?: 'PotentialCandidatesCollection';
  collection: Array<Maybe<PotentialCandidate>>;
  total: Scalars['Int']['output'];
};

export const enum PotentialCandidatesInteraction {
  Apply = 'APPLY',
  Interest = 'INTEREST',
  Recommend = 'RECOMMEND',
  Visit = 'VISIT',
}

export const enum PotentialCandidatesOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Potential Candidates requested by XJM */
export type PotentialCandidatesTotals = {
  __typename?: 'PotentialCandidatesTotals';
  apply?: Maybe<Scalars['Int']['output']>;
  interest?: Maybe<Scalars['Int']['output']>;
  recommend?: Maybe<Scalars['Int']['output']>;
  visit?: Maybe<Scalars['Int']['output']>;
};

export type PredictiveSearch = HasTrackingToken & {
  __typename?: 'PredictiveSearch';
  /** #soft-logged-in */
  sections: Array<PredictiveSearchSection>;
  /** #soft-logged-in */
  service: Scalars['String']['output'];
  /**
   * #soft-logged-in
   *
   *  Tracking token generated by predictive search service to track responses. Used for DS tracking
   */
  trackingToken: Scalars['String']['output'];
};

export type PredictiveSearchItem = {
  /** #soft-logged-in */
  data: Scalars['String']['output'];
  /** #soft-logged-in */
  payload?: Maybe<ExtraData>;
  /** #soft-logged-in */
  trackingToken: Scalars['String']['output'];
};

export type PredictiveSearchSection = {
  __typename?: 'PredictiveSearchSection';
  /** #soft-logged-in */
  items?: Maybe<Array<PredictiveSearchItem>>;
  /** #soft-logged-in */
  link?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  sectionType?: Maybe<PredictiveSearchSectionType>;
  /** #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
};

export const enum PredictiveSearchSectionType {
  IncrementalSearch = 'INCREMENTAL_SEARCH',
}

/** Jobs Seeking status of an User */
export const enum PreferenceSeekingStatus {
  Interested = 'INTERESTED',
  NotSeeking = 'NOT_SEEKING',
  Seeking = 'SEEKING',
}

export type PreferencesJobWishesError = {
  __typename?: 'PreferencesJobWishesError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type PreferencesJobWishesInput = {
  cities?: InputMaybe<Array<InputMaybe<PreferencesLocationInput>>>;
  discipline?: InputMaybe<Scalars['ID']['input']>;
  fullTime?: InputMaybe<Scalars['Boolean']['input']>;
  industries?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCareerLevel?: InputMaybe<Scalars['Int']['input']>;
  maxDistance?: InputMaybe<Scalars['Int']['input']>;
  minCareerLevel?: InputMaybe<Scalars['Int']['input']>;
  openToOtherIndustries?: InputMaybe<Scalars['Boolean']['input']>;
  partTime?: InputMaybe<Scalars['Boolean']['input']>;
  salaryExpectations?: InputMaybe<Scalars['Int']['input']>;
  willingnessToTravel?: InputMaybe<WillingnessToTravelPreferenceEnum>;
};

/** "Result type for user preference job wishes status update that returns 204 on success" */
export type PreferencesJobWishesResult =
  | PreferencesJobWishesError
  | PreferencesJobWishesSuccess;

export type PreferencesJobWishesSuccess = {
  __typename?: 'PreferencesJobWishesSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type PreferencesLocationInput = {
  cityId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Preferences Settings Type */
export type PreferencesSettings = {
  __typename?: 'PreferencesSettings';
  /**
   * #rest(batched) #virtual
   * @deprecated Use blockedCompanies instead for queries and mutations
   */
  blockedCompanies?: Maybe<Array<Maybe<Company>>>;
  seekingStatus: PreferenceSeekingStatus;
};

export type PreferencesSettingsError = {
  __typename?: 'PreferencesSettingsError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type PreferencesSettingsInput = {
  seekingStatus?: InputMaybe<PreferenceSeekingStatus>;
};

/** "Result type for user preference seeking status update that returns 204 on success" */
export type PreferencesSettingsResult =
  | PreferencesSettingsError
  | PreferencesSettingsSuccess;

export type PreferencesSettingsSuccess = {
  __typename?: 'PreferencesSettingsSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type PremiumAction = {
  __typename?: 'PremiumAction';
  /** #soft-logged-in */
  androidPath?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  iosPath?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  trackingId?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  webPath?: Maybe<Scalars['String']['output']>;
};

export type PremiumContact = {
  __typename?: 'PremiumContact';
  /** #soft-logged-in #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type PremiumContactEdge = PaginationEdge & {
  __typename?: 'PremiumContactEdge';
  cursor: Scalars['String']['output'];
  node: PremiumContact;
};

export type PremiumContactsCursorConnection = HasCursorPagination & {
  __typename?: 'PremiumContactsCursorConnection';
  edges?: Maybe<Array<Maybe<PremiumContactEdge>>>;
  /** #soft-logged-in */
  pageInfo: PageInfo;
  /** #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type PremiumEngagementMutationError = {
  __typename?: 'PremiumEngagementMutationError';
  /** #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  errors?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  status?: Maybe<Scalars['Int']['output']>;
};

export type PremiumFeaturesOverview = {
  __typename?: 'PremiumFeaturesOverview';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<PremiumFeaturesOverviewArea>>>;
};

export type PremiumFeaturesOverviewArea = {
  __typename?: 'PremiumFeaturesOverviewArea';
  /** #soft-logged-in */
  groups?: Maybe<Array<Maybe<PremiumFeaturesOverviewGroup>>>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  trackingId?: Maybe<Scalars['String']['output']>;
};

export type PremiumFeaturesOverviewFeatureUrl = {
  __typename?: 'PremiumFeaturesOverviewFeatureUrl';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PremiumFeaturesOverviewGroup = {
  __typename?: 'PremiumFeaturesOverviewGroup';
  /** #soft-logged-in */
  darkImageUrl?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  featureUrl?: Maybe<PremiumFeaturesOverviewFeatureUrl>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  imageUrl?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  items?: Maybe<Array<Maybe<PremiumFeaturesOverviewItem>>>;
  /** #soft-logged-in */
  subheader?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  tipsAndTricks?: Maybe<Array<Maybe<PremiumFeaturesOverviewTipsAndTricks>>>;
  /** #soft-logged-in */
  trackingId?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  urn?: Maybe<Scalars['GlobalID']['output']>;
};

export type PremiumFeaturesOverviewItem = {
  __typename?: 'PremiumFeaturesOverviewItem';
  /** #soft-logged-in */
  specialText?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  values?: Maybe<Array<Maybe<PremiumFeaturesOverviewItemValue>>>;
  /** #soft-logged-in */
  webOnly?: Maybe<Scalars['Boolean']['output']>;
};

export type PremiumFeaturesOverviewItemValue = {
  __typename?: 'PremiumFeaturesOverviewItemValue';
  /** #soft-logged-in */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  maxUsage?: Maybe<Scalars['Int']['output']>;
};

/** Available products for Premium Features Overview */
export const enum PremiumFeaturesOverviewProducts {
  Premium = 'PREMIUM',
  Probusiness = 'PROBUSINESS',
  Projobs = 'PROJOBS',
}

export type PremiumFeaturesOverviewTipsAndTricks = {
  __typename?: 'PremiumFeaturesOverviewTipsAndTricks';
  /** #soft-logged-in */
  bottom?: Maybe<PremiumFeaturesOverviewTipsAndTricksSection>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  top?: Maybe<PremiumFeaturesOverviewTipsAndTricksSection>;
  /** #soft-logged-in */
  videoId?: Maybe<Scalars['Int']['output']>;
};

export type PremiumFeaturesOverviewTipsAndTricksAction = {
  __typename?: 'PremiumFeaturesOverviewTipsAndTricksAction';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  trackingId?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PremiumFeaturesOverviewTipsAndTricksSection = {
  __typename?: 'PremiumFeaturesOverviewTipsAndTricksSection';
  /** #soft-logged-in */
  actions?: Maybe<Array<Maybe<PremiumFeaturesOverviewTipsAndTricksAction>>>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
};

export type PremiumFeaturesVompNotification = Notification & {
  __typename?: 'PremiumFeaturesVompNotification';
  /** #rest(batched) #virtual */
  actor?: Maybe<QualifiedVompVisitor>;
  aggregated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interacted: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<QualifiedVomp>;
  opened: Scalars['Boolean']['output'];
  publishedAt: Scalars['Date']['output'];
  seen: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  target?: Maybe<QualifiedVompVisitee>;
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn: Scalars['URN']['output'];
};

export type PremiumNews = {
  __typename?: 'PremiumNews';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<PremiumNewsItem>>>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  reassuranceFlag?: Maybe<PremiumReassuranceFlag>;
  /** #soft-logged-in */
  subheader?: Maybe<Scalars['String']['output']>;
};

export type PremiumNewsItem = {
  __typename?: 'PremiumNewsItem';
  /** #soft-logged-in */
  action?: Maybe<PremiumAction>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  imageUrl?: Maybe<Scalars['URL']['output']>;
};

export type PremiumPartner = {
  __typename?: 'PremiumPartner';
  /** #soft-logged-in */
  categoriesWithId?: Maybe<Array<Maybe<PremiumPartnersCategory>>>;
  /** #soft-logged-in */
  detailUrl?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  logo?: Maybe<PremiumPartnerLogo>;
  /** #soft-logged-in */
  new?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  offerType?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  partnerName?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  teaser?: Maybe<PremiumPartnerTeaser>;
  /** #soft-logged-in */
  uplt?: Maybe<Scalars['Int']['output']>;
};

export type PremiumPartnerCreateCodeMutationInput = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** "Result type for Premium partner code create mutations that returns the code on success" */
export type PremiumPartnerCreateCodeMutationResult = {
  __typename?: 'PremiumPartnerCreateCodeMutationResult';
  /** #soft-logged-in */
  error?: Maybe<PremiumEngagementMutationError>;
  /** #soft-logged-in */
  success?: Maybe<PremiumPartnerDetailsCode>;
};

export type PremiumPartnerDetails = {
  __typename?: 'PremiumPartnerDetails';
  /** #soft-logged-in */
  backgroundImage?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  benefits?: Maybe<PremiumPartnerDetailsBenefits>;
  /** #soft-logged-in */
  body?: Maybe<Array<Maybe<PremiumPartnerDetailsBody>>>;
  /** #soft-logged-in */
  filter?: Maybe<PremiumPartnerDetailsFilter>;
  /** #soft-logged-in */
  footerNotes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  logoImage?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  partnerId: Scalars['ID']['output'];
  /** #soft-logged-in */
  sideSection?: Maybe<Array<Maybe<PremiumPartnerDetailsSideSection>>>;
  /** #soft-logged-in */
  uplt?: Maybe<Scalars['Int']['output']>;
};

export type PremiumPartnerDetailsBenefit = {
  __typename?: 'PremiumPartnerDetailsBenefit';
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnerDetailsBenefits = {
  __typename?: 'PremiumPartnerDetailsBenefits';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<PremiumPartnerDetailsBenefit>>>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnerDetailsBody =
  | PremiumPartnerDetailsBodyHeader
  | PremiumPartnerDetailsBodyParagraph
  | PremiumPartnerDetailsBodySubheader
  | PremiumPartnerDetailsBodyVideo;

/** Types for PremiumPartnerDetailsBody */
export type PremiumPartnerDetailsBodyHeader = {
  __typename?: 'PremiumPartnerDetailsBodyHeader';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnerDetailsBodyParagraph = {
  __typename?: 'PremiumPartnerDetailsBodyParagraph';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnerDetailsBodySubheader = {
  __typename?: 'PremiumPartnerDetailsBodySubheader';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnerDetailsBodyVideo = {
  __typename?: 'PremiumPartnerDetailsBodyVideo';
  /** #soft-logged-in */
  videoId?: Maybe<Scalars['ID']['output']>;
};

export type PremiumPartnerDetailsCode = {
  __typename?: 'PremiumPartnerDetailsCode';
  /** #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnerDetailsFilter = {
  __typename?: 'PremiumPartnerDetailsFilter';
  /** #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnerDetailsSideSection =
  | PremiumPartnerDetailsSideSectionButton
  | PremiumPartnerDetailsSideSectionCodebutton
  | PremiumPartnerDetailsSideSectionHeader
  | PremiumPartnerDetailsSideSectionList
  | PremiumPartnerDetailsSideSectionSubheader;

export type PremiumPartnerDetailsSideSectionButton = {
  __typename?: 'PremiumPartnerDetailsSideSectionButton';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PremiumPartnerDetailsSideSectionCodebutton = {
  __typename?: 'PremiumPartnerDetailsSideSectionCodebutton';
  /** #soft-logged-in #rest #virtual */
  code?: Maybe<PremiumPartnerDetailsCode>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

/** Types for PremiumPartnerDetailsSideSection */
export type PremiumPartnerDetailsSideSectionHeader = {
  __typename?: 'PremiumPartnerDetailsSideSectionHeader';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnerDetailsSideSectionList = {
  __typename?: 'PremiumPartnerDetailsSideSectionList';
  /** #soft-logged-in */
  listElements?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PremiumPartnerDetailsSideSectionSubheader = {
  __typename?: 'PremiumPartnerDetailsSideSectionSubheader';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnerHighlight = {
  __typename?: 'PremiumPartnerHighlight';
  /** #soft-logged-in */
  body?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  highlightImageUrl?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  logoUrl?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  new?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  offerType?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  partnerName?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnerLogo = {
  __typename?: 'PremiumPartnerLogo';
  /** #soft-logged-in */
  imageUrl?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnerTeaser = {
  __typename?: 'PremiumPartnerTeaser';
  /** #soft-logged-in */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  localizedDescription?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  localizedTitle?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartners = {
  __typename?: 'PremiumPartners';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<PremiumPartner>>>;
};

export type PremiumPartnersCategories = {
  __typename?: 'PremiumPartnersCategories';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<PremiumPartnersCategory>>>;
  /** #soft-logged-in */
  headline?: Maybe<Scalars['String']['output']>;
};

export type PremiumPartnersCategory = {
  __typename?: 'PremiumPartnersCategory';
  /** #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #soft-logged-in */
  slug?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export const enum PremiumPlatform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

/** Premium reassurance flag */
export type PremiumReassuranceFlag = {
  __typename?: 'PremiumReassuranceFlag';
  /** #soft-logged-in */
  action?: Maybe<PremiumReassuranceFlagAction>;
  /** #soft-logged-in */
  body: Array<Scalars['String']['output']>;
  /** #soft-logged-in */
  header: Scalars['String']['output'];
};

export type PremiumReassuranceFlagAction = {
  __typename?: 'PremiumReassuranceFlagAction';
  /** #soft-logged-in */
  text: Scalars['String']['output'];
  /** #soft-logged-in */
  trackingId?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  url?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  urn?: Maybe<Scalars['GlobalID']['output']>;
};

export type PremiumSelfAssessmentStatus = {
  __typename?: 'PremiumSelfAssessmentStatus';
  /** #soft-logged-in */
  state?: Maybe<PremiumSelfAssessmentStatusState>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

/** Possible states of PremiumSelfAssessmentStatus */
export const enum PremiumSelfAssessmentStatusState {
  /** The user has finished the self assessment test */
  Finished = 'FINISHED',
  /** The user has not started the self assessment test */
  NotStarted = 'NOT_STARTED',
  /** The user has started and not finished the self assessment test */
  Started = 'STARTED',
}

export type PremiumSelfDevelopment = {
  __typename?: 'PremiumSelfDevelopment';
  /** #soft-logged-in #rest #virtual */
  courseCategories?: Maybe<PremiumSelfDevelopmentCourseCategories>;
  /** #soft-logged-in #rest #virtual */
  courses?: Maybe<PremiumSelfDevelopmentCourses>;
  /** #rest #virtual */
  testStatus?: Maybe<PremiumSelfAssessmentStatus>;
};

export type PremiumSelfDevelopmentCoursesArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PremiumSelfDevelopmentCourseFilter>;
};

export type PremiumSelfDevelopmentCourse = {
  __typename?: 'PremiumSelfDevelopmentCourse';
  /** #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  duration?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #soft-logged-in */
  imageUrl?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  level?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  new?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  orderedCategories?: Maybe<Array<Maybe<PremiumSelfDevelopmentOrderCategory>>>;
  /** #soft-logged-in */
  partner?: Maybe<PremiumSelfDevelopmentCoursePartner>;
  /** #soft-logged-in */
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
};

export type PremiumSelfDevelopmentCourseCategories = {
  __typename?: 'PremiumSelfDevelopmentCourseCategories';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<PremiumSelfDevelopmentCourseCategory>>>;
};

export type PremiumSelfDevelopmentCourseCategory = {
  __typename?: 'PremiumSelfDevelopmentCourseCategory';
  /** #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #soft-logged-in */
  imageUrl?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  slug?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
};

export const enum PremiumSelfDevelopmentCourseFilter {
  Popular = 'POPULAR',
}

export type PremiumSelfDevelopmentCoursePartner = {
  __typename?: 'PremiumSelfDevelopmentCoursePartner';
  /** #soft-logged-in */
  logoUrl?: Maybe<Scalars['URL']['output']>;
  /** #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  specialText?: Maybe<Scalars['String']['output']>;
};

export type PremiumSelfDevelopmentCourses = {
  __typename?: 'PremiumSelfDevelopmentCourses';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<PremiumSelfDevelopmentCourse>>>;
};

export type PremiumSelfDevelopmentOrderCategory = {
  __typename?: 'PremiumSelfDevelopmentOrderCategory';
  /** #soft-logged-in */
  categoryId?: Maybe<Scalars['ID']['output']>;
  /** #soft-logged-in */
  position?: Maybe<Scalars['Int']['output']>;
};

/** Return all the data for the premium startpage */
export type PremiumStartpage = {
  __typename?: 'PremiumStartpage';
  /** #soft-logged-in #rest #virtual */
  premiumStartpageInfo?: Maybe<PremiumStartpageInfo>;
};

export type PremiumStartpageInfo = {
  __typename?: 'PremiumStartpageInfo';
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  revokeBonusMonths?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  status?: Maybe<PremiumStatus>;
  /** #soft-logged-in */
  subheader?: Maybe<Scalars['String']['output']>;
};

/**  Premium Statistics Type  */
export type PremiumStatistics = {
  __typename?: 'PremiumStatistics';
  searchAppearances?: Maybe<Scalars['Int']['output']>;
  startpageAppearances?: Maybe<Scalars['Int']['output']>;
};

export type PremiumStatus = {
  __typename?: 'PremiumStatus';
  /** #soft-logged-in */
  action?: Maybe<PremiumAction>;
  /** #soft-logged-in */
  memberSince?: Maybe<XingMemberSince>;
  /** #soft-logged-in */
  premiumMemberSince?: Maybe<XingMemberSince>;
};

export type PremiumSwitch = HasGlobalId & {
  __typename?: 'PremiumSwitch';
  errors?: Maybe<PremiumSwitchSettingsError>;
  globalId: Scalars['GlobalID']['output'];
  /** A list of the memberships set by the PremiumSwitch */
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Represents if the PremiumFeature is enable or not */
export type PremiumSwitchFeature = {
  __typename?: 'PremiumSwitchFeature';
  value: Scalars['String']['output'];
};

/**
 * Settings for overriding a subscription for testing/QA purposes
 * only available to Xing employees and Ambassadors
 */
export type PremiumSwitchSettings = {
  __typename?: 'PremiumSwitchSettings';
  /**
   * #rest #virtual
   *
   *  Feature flag to define if user can have the PremiumSwitch as a Xing employee
   */
  hasPremiumSwitch?: Maybe<PremiumSwitchFeature>;
  /**
   * #rest #virtual
   *
   *  Feature flag to define if user can have the PremiumSwitch as a Xing ambassador
   */
  hasPremiumSwitchExternal?: Maybe<PremiumSwitchFeature>;
  /**
   * #rest #virtual
   *
   *  Settings field holding PremiumSwitch selected value
   */
  premiumSwitch?: Maybe<PremiumSwitch>;
};

export type PremiumSwitchSettingsError = {
  __typename?: 'PremiumSwitchSettingsError';
  message?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PremiumSwitchSettingsInput = {
  premiumSwitch?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PremiumSwitchSettingsMutationResult = {
  __typename?: 'PremiumSwitchSettingsMutationResult';
  error?: Maybe<PremiumSwitchSettings>;
  response?: Maybe<PremiumSwitchSettings>;
};

export type PremiumVisibility = {
  __typename?: 'PremiumVisibility';
  /** #soft-logged-in */
  collection?: Maybe<Array<Maybe<PremiumVisibilityItem>>>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  reassuranceFlag?: Maybe<PremiumReassuranceFlag>;
  /** #soft-logged-in */
  subheader?: Maybe<Scalars['String']['output']>;
};

export type PremiumVisibilityItem = {
  __typename?: 'PremiumVisibilityItem';
  /** #soft-logged-in */
  action?: Maybe<PremiumAction>;
  /** #soft-logged-in */
  header?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  value?: Maybe<Scalars['Int']['output']>;
};

export type PreviewMetadata = {
  sourceActor?: Maybe<LinkPreviewSourceActor>;
};

export type ProBusinessCreateLeadResult = XingIdActionPayloadInterface & {
  __typename?: 'ProBusinessCreateLeadResult';
  error?: Maybe<ProbusinessCreateLeadError>;
  /**
   * #rest
   *
   *  XingIdModule of the user added as a lead
   */
  xingIdModule?: Maybe<XingIdModule>;
};

export type ProBusinessCreateLeadResultXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

export type ProBusinessDeleteLeadResult = XingIdActionPayloadInterface & {
  __typename?: 'ProBusinessDeleteLeadResult';
  error?: Maybe<ProbusinessDeleteLeadError>;
  /**
   * #rest
   *
   *  XingIdModule of the user removed as a lead
   */
  xingIdModule?: Maybe<XingIdModule>;
};

export type ProBusinessDeleteLeadResultXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

export type ProJobsIdealEmployer = {
  __typename?: 'ProJobsIdealEmployer';
  /** #rest(batched) #virtual */
  company: Company;
  createdAt: Scalars['Date']['output'];
};

export type ProJobsIdealEmployerMutationError = {
  __typename?: 'ProJobsIdealEmployerMutationError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ProJobsIdealEmployerMutationInput = {
  companyId: Scalars['ID']['input'];
};

export type ProJobsIdealEmployerMutationResult = {
  __typename?: 'ProJobsIdealEmployerMutationResult';
  error?: Maybe<ProJobsIdealEmployerMutationError>;
};

export type ProJobsIdealEmployersConnection = HasCursorPagination & {
  __typename?: 'ProJobsIdealEmployersConnection';
  edges: Array<Maybe<ProJobsIdealEmployersEdge>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ProJobsIdealEmployersEdge = PaginationEdge & {
  __typename?: 'ProJobsIdealEmployersEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: ProJobsIdealEmployer;
};

/** ProJobs JobSeeker WorkExperience. 2.0 */
export type ProJobsV2WorkExperience = {
  __typename?: 'ProJobsV2WorkExperience';
  budgetAmount?: Maybe<Scalars['Int']['output']>;
  formattedBudgetAmount?: Maybe<Scalars['String']['output']>;
  formattedHasBudgetResponsibility: Scalars['String']['output'];
  formattedHasRevenueResponsibility: Scalars['String']['output'];
  formattedResponsibility?: Maybe<Scalars['String']['output']>;
  formattedRevenueAmount?: Maybe<Scalars['String']['output']>;
  hasBudgetResponsibility: Scalars['Boolean']['output'];
  hasRevenueResponsibility: Scalars['Boolean']['output'];
  responsibility?: Maybe<Scalars['String']['output']>;
  responsibilityTeamSize?: Maybe<Scalars['String']['output']>;
  revenueAmount?: Maybe<Scalars['Int']['output']>;
};

/** ProJobs JobSeeker WorkExperience. */
export type ProJobsWorkExperience = {
  __typename?: 'ProJobsWorkExperience';
  budget: Scalars['Boolean']['output'];
  budgetAmount?: Maybe<Scalars['Int']['output']>;
  formattedBudgetAmount?: Maybe<Scalars['String']['output']>;
  formattedResponsibility?: Maybe<Scalars['String']['output']>;
  formattedResponsibilityTeamSize?: Maybe<Scalars['String']['output']>;
  formattedRevenueAmount?: Maybe<Scalars['String']['output']>;
  responsibility: Scalars['String']['output'];
  responsibilityTeamSize?: Maybe<Scalars['String']['output']>;
  resultsAndAchievements?: Maybe<Scalars['String']['output']>;
  revenue: Scalars['Boolean']['output'];
  revenueAmount?: Maybe<Scalars['Int']['output']>;
  tasksAndActions?: Maybe<Scalars['String']['output']>;
  topicsAndProjects?: Maybe<Scalars['String']['output']>;
};

export type ProbusinessCreateLeadError = {
  __typename?: 'ProbusinessCreateLeadError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ProbusinessCreateLeadInput = {
  /** id of a user who is being added as a lead */
  id: Scalars['ID']['input'];
};

export type ProbusinessDeleteLeadError = {
  __typename?: 'ProbusinessDeleteLeadError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ProbusinessDeleteLeadInput = {
  /** id of a user who is being removed as a lead */
  id: Scalars['ID']['input'];
};

export const enum ProductType {
  Premium = 'premium',
  Projobs = 'projobs',
}

export type ProfileAboutMe = {
  __typename?: 'ProfileAboutMe';
  /** #logged-out #soft-logged-in */
  globalId?: Maybe<Scalars['GlobalID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  intro?: Maybe<Scalars['String']['output']>;
};

/** Profile module that allow users to express themselves in creative ways */
export type ProfileAboutMeModule = ModuleInterface & {
  __typename?: 'ProfileAboutMeModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** #rest #virtual */
  content?: Maybe<ProfileAboutMe>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type ProfileAboutMeUpdateError = {
  __typename?: 'ProfileAboutMeUpdateError';
  errors?: Maybe<ProfileAboutMeUpdateFieldErrors>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ProfileAboutMeUpdateFieldErrors = {
  __typename?: 'ProfileAboutMeUpdateFieldErrors';
  intro?: Maybe<Scalars['String']['output']>;
};

export type ProfileAboutMeUpdateInput = {
  /** Intro/about me description of a user */
  intro: Scalars['String']['input'];
};

export type ProfileAboutMeUpdateResult = {
  __typename?: 'ProfileAboutMeUpdateResult';
  error?: Maybe<ProfileAboutMeUpdateError>;
  success?: Maybe<ProfileAboutMe>;
};

export type ProfileAcademicTitleUpdate = PeopleStoryGeneratorProfileUpdate & {
  __typename?: 'ProfileAcademicTitleUpdate';
  academicTitle?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  previousAcademicTitle?: Maybe<Scalars['String']['output']>;
  profileId?: Maybe<Scalars['ID']['output']>;
};

export type ProfileAdditionalDetails = {
  __typename?: 'ProfileAdditionalDetails';
  education?: Maybe<Array<Maybe<ProfileEducation>>>;
  skills?: Maybe<Scalars['String']['output']>;
  workExperience?: Maybe<Array<Maybe<ProfileWorkExperience>>>;
};

export type ProfileBusinessContactUpdate = PeopleStoryGeneratorProfileUpdate & {
  __typename?: 'ProfileBusinessContactUpdate';
  businessCity?: Maybe<Scalars['String']['output']>;
  businessCountry?: Maybe<Scalars['String']['output']>;
  businessEmail?: Maybe<Scalars['String']['output']>;
  businessMobile?: Maybe<Scalars['String']['output']>;
  businessPhone?: Maybe<Scalars['String']['output']>;
  businessStreet?: Maybe<Scalars['String']['output']>;
  businessZip?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  previousBusinessCity?: Maybe<Scalars['String']['output']>;
  previousBusinessCountry?: Maybe<Scalars['String']['output']>;
  previousBusinessEmail?: Maybe<Scalars['String']['output']>;
  previousBusinessMobile?: Maybe<Scalars['String']['output']>;
  previousBusinessPhone?: Maybe<Scalars['String']['output']>;
  previousBusinessStreet?: Maybe<Scalars['String']['output']>;
  previousBusinessZip?: Maybe<Scalars['String']['output']>;
  profileId?: Maybe<Scalars['ID']['output']>;
};

/** A simple type for company objects stored locally by the profile-backend app */
export type ProfileCompany = {
  __typename?: 'ProfileCompany';
  /**
   * #rest(batched) #virtual
   *
   *  The company that is linked to the professional entry, in case it exists then the `companyName` and `industry` of this company are the up-to-date info; clients should use them instead of the `name` and `industry` in the parent type
   */
  company?: Maybe<Company>;
  /**
   * #rest(batched) #virtual
   *
   *  The user provided industry
   */
  industry?: Maybe<Industry>;
  /** The user provided Company name */
  name: Scalars['String']['output'];
};

/** Contains all the information about the other user's contact */
export type ProfileContact = {
  __typename?: 'ProfileContact';
  /**
   * #soft-logged-in
   *
   *  Id of the contact
   */
  id?: Maybe<Scalars['ID']['output']>;
  /**
   * #soft-logged-in
   *
   *  Initial of first name (used for grouping)
   */
  normalizedInitialOfFirstName?: Maybe<Scalars['String']['output']>;
  /**
   * #soft-logged-in
   *
   *  Initial of last name (used for grouping)
   */
  normalizedInitialOfLastName?: Maybe<Scalars['String']['output']>;
  /**
   * #soft-logged-in #rest(batched) #virtual
   *
   *  XingId representation of the information about the contact
   */
  xingId?: Maybe<XingId>;
};

/** List of contacts of a profile */
export type ProfileContacts = {
  __typename?: 'ProfileContacts';
  /** #soft-logged-in */
  collection?: Maybe<Array<ProfileContact>>;
  /** #virtual #soft-logged-in */
  total?: Maybe<Scalars['Int']['output']>;
};

export type ProfileEducation = {
  __typename?: 'ProfileEducation';
  /** #logged-out #soft-logged-in */
  beginDate?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  degree?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  endDate?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  only filled with data loggedout
   */
  localizedTimeString?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  primarySchool?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  schoolName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  schoolNotes?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  subject?: Maybe<Scalars['String']['output']>;
};

export type ProfileEducationCollection = {
  __typename?: 'ProfileEducationCollection';
  /** #logged-out #soft-logged-in */
  education?: Maybe<Array<Maybe<ProfileEducation>>>;
};

/** A simple type for educational institution, following the basic interface of the common Company type */
export type ProfileEducationalInstitution = {
  __typename?: 'ProfileEducationalInstitution';
  name: Scalars['String']['output'];
};

export type ProfileEngagementContactHasNewJobContext =
  ProfileEngagementContextInterface & {
    __typename?: 'ProfileEngagementContactHasNewJobContext';
    /** What actions are available in this context */
    actions?: Maybe<ProfileEngagementContextsActions>;
    /** The localised timespan of the new job, can be something like `Since June 2018` or `March 2015 - October 2016`  */
    jobTimeSpan?: Maybe<Scalars['Markdown']['output']>;
    /** The new job title of the user */
    jobTitle: Scalars['String']['output'];
    /** Order of the context, to be used in the case there are multiple contexts available */
    order: Scalars['Int']['output'];
    /** The new organization of the user */
    organization: ProfileEngagementOrganization;
    /** Localised title of the context */
    title: Scalars['String']['output'];
  };

export type ProfileEngagementContextInterface = {
  /** Order of the context */
  order: Scalars['Int']['output'];
};

export type ProfileEngagementContexts = {
  __typename?: 'ProfileEngagementContexts';
  /** The profile has a new job, only for contacts */
  contactHasNewJob?: Maybe<ProfileEngagementContactHasNewJobContext>;
  /** The profile is your new coworker, only for non-contacts */
  newCoworker?: Maybe<ProfileEngagementNewCoworkerContext>;
};

export type ProfileEngagementContextsActions = {
  __typename?: 'ProfileEngagementContextsActions';
  /** Sending a message action */
  sendMessage?: Maybe<ProfileEngagementSendMessageAction>;
};

export type ProfileEngagementConversationStarter = {
  __typename?: 'ProfileEngagementConversationStarter';
  messengerContextId: MessengerContextId;
  /** The localized representation of a conversation starter */
  title: Scalars['String']['output'];
};

/** Profile module to trigger engagement between users */
export type ProfileEngagementModule = ModuleInterface & {
  __typename?: 'ProfileEngagementModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #expand
   *
   *  Different contexts that can be available on a profile, a context is a signal that something is currently associated with the profile (this profile just got a new job, this profile is on parental leave, etc.), it can also be related to the visitor of the profile (contacts, etc.)
   */
  contexts: ProfileEngagementContexts;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type ProfileEngagementNewCoworkerContext =
  ProfileEngagementContextInterface & {
    __typename?: 'ProfileEngagementNewCoworkerContext';
    /** What actions are available in this context */
    actions?: Maybe<ProfileEngagementContextsActions>;
    /** Localised title of the context (for example: `started as Relationship & Content Manager`) */
    description: Scalars['String']['output'];
    /** The profile's gender for choosing which icon to show */
    gender: Gender;
    /** The localised timespan of the new job (for example: `Since June 2018 with XING SE`) */
    jobTimeSpan: Scalars['String']['output'];
    /** Order of the context, to be used in the case there are multiple contexts available */
    order: Scalars['Int']['output'];
    /** Localised title of the context (`Your new colleague`) */
    title: Scalars['String']['output'];
  };

/** A representation of the organization in the context of Profile Engagement module */
export type ProfileEngagementOrganization = {
  __typename?: 'ProfileEngagementOrganization';
  name: Scalars['String']['output'];
};

export type ProfileEngagementSendMessageAction = {
  __typename?: 'ProfileEngagementSendMessageAction';
  /** Array of quick messages that can act as a conversation starter */
  conversationStarters?: Maybe<
    Array<Maybe<ProfileEngagementConversationStarter>>
  >;
};

export type ProfileHavesUpdate = PeopleStoryGeneratorProfileUpdate & {
  __typename?: 'ProfileHavesUpdate';
  createdAt?: Maybe<Scalars['Date']['output']>;
  haves: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  newHaves: Array<Scalars['String']['output']>;
  previousHaves?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  profileId?: Maybe<Scalars['ID']['output']>;
  urn: Scalars['GlobalID']['output'];
};

/** Profile Picture */
export type ProfileImage = UniformResourceLocatable & {
  __typename?: 'ProfileImage';
  /** #logged-out #soft-logged-in */
  size: ProfileImageSize;
  /** #logged-out #soft-logged-in */
  url: Scalars['URL']['output'];
};

export const enum ProfileImageSize {
  /** Original dimensions */
  Original = 'ORIGINAL',
  /** 32x32 pixels */
  Square_32 = 'SQUARE_32',
  /** 48x48 pixels */
  Square_48 = 'SQUARE_48',
  /** 64x64 pixels */
  Square_64 = 'SQUARE_64',
  /** 96x96 pixels */
  Square_96 = 'SQUARE_96',
  /** 128x128 pixels */
  Square_128 = 'SQUARE_128',
  /** 192x192 pixels */
  Square_192 = 'SQUARE_192',
  /** 256x256 pixels */
  Square_256 = 'SQUARE_256',
  /** 1024x1024 pixels */
  Square_1024 = 'SQUARE_1024',
}

export type ProfileInputYearAndMonth = {
  month?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ProfileInterests = {
  __typename?: 'ProfileInterests';
  /** #logged-out #soft-logged-in */
  interests?: Maybe<Scalars['String']['output']>;
};

export type ProfileInterests2 = HasGlobalId & {
  __typename?: 'ProfileInterests2';
  /** #soft-logged-in #logged-out */
  globalId: Scalars['GlobalID']['output'];
  /** #soft-logged-in #logged-out */
  interests?: Maybe<Array<Scalars['String']['output']>>;
};

/** Interests Autocomplete Suggestions queries */
export type ProfileInterestsAutocompleteSuggestions = {
  __typename?: 'ProfileInterestsAutocompleteSuggestions';
  collection: Array<ProfileInterestsSuggestion>;
  /** Suggestions service name for use in autocompletionTrack mutation */
  service: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

/** Profile module that allow users to see/add interests */
export type ProfileInterestsModule = ModuleInterface & {
  __typename?: 'ProfileInterestsModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in #rest #virtual */
  content?: Maybe<ProfileInterests2>;
  /** #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #rest #virtual */
  recommendations?: Maybe<ProfileInterestsModuleRecommendations>;
  /** #soft-logged-in */
  title: Scalars['String']['output'];
};

export type ProfileInterestsModuleRecommendation = {
  __typename?: 'ProfileInterestsModuleRecommendation';
  label: Scalars['String']['output'];
};

export type ProfileInterestsModuleRecommendations = {
  __typename?: 'ProfileInterestsModuleRecommendations';
  collection?: Maybe<Array<ProfileInterestsModuleRecommendation>>;
  total: Scalars['Int']['output'];
};

export type ProfileInterestsSuggestion = {
  __typename?: 'ProfileInterestsSuggestion';
  /** 0-based position in suggestion list */
  position: Scalars['Int']['output'];
  /** Suggestion text */
  suggestion: Scalars['String']['output'];
  /** Interest performance */
  totalPerformance?: Maybe<Scalars['Int']['output']>;
  /** Tracking token for use in autocompletionTrack mutation */
  trackingToken: Scalars['String']['output'];
  /** Interest performance trend */
  trend?: Maybe<Scalars['Int']['output']>;
};

export type ProfileInterestsUpdateError = {
  __typename?: 'ProfileInterestsUpdateError';
  description?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<ProfileInterestsUpdateFieldsError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ProfileInterestsUpdateFieldsError = {
  __typename?: 'ProfileInterestsUpdateFieldsError';
  interests?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProfileInterestsUpdateInput = {
  /** Interests of a user, like ['interest-1', 'interest-2']  */
  interests: Array<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type ProfileInterestsUpdateResult =
  | ProfileInterests2
  | ProfileInterestsUpdateError;

export type ProfileKnownLanguage = {
  __typename?: 'ProfileKnownLanguage';
  identifier: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type ProfileKnownLanguages = {
  __typename?: 'ProfileKnownLanguages';
  collection?: Maybe<Array<Maybe<ProfileKnownLanguage>>>;
};

export type ProfileLanguage = {
  __typename?: 'ProfileLanguage';
  identifier?: Maybe<Scalars['String']['output']>;
  skillLevelId?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ProfileLanguageCollection =
  | LoggedInProfileLanguageCollection
  | LoggedOutProfileLanguageCollection;

export type ProfileLanguageInput = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  skillLevelId?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileLanguages = HasGlobalId & {
  __typename?: 'ProfileLanguages';
  collection?: Maybe<Array<Maybe<ProfileLanguage>>>;
  /** #soft-logged-in #logged-out */
  globalId: Scalars['GlobalID']['output'];
};

export type ProfileLanguagesModule = ModuleInterface & {
  __typename?: 'ProfileLanguagesModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** #rest #virtual */
  content?: Maybe<ProfileLanguages>;
  /** #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #rest #virtual */
  knownLanguages?: Maybe<ProfileKnownLanguages>;
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  title: Scalars['String']['output'];
};

export type ProfileLanguagesUpdateError = {
  __typename?: 'ProfileLanguagesUpdateError';
  description?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<ProfileLanguagesUpdateFieldsError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ProfileLanguagesUpdateFieldsError = {
  __typename?: 'ProfileLanguagesUpdateFieldsError';
  language?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProfileLanguagesUpdateInput = {
  /** Languages of a user */
  collection?: InputMaybe<Array<InputMaybe<ProfileLanguageInput>>>;
  userId: Scalars['ID']['input'];
};

export type ProfileLegalInformation = {
  __typename?: 'ProfileLegalInformation';
  /** #logged-out #soft-logged-in */
  content?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  displayContent?: Maybe<Scalars['String']['output']>;
};

export const enum ProfileModuleIdentifier {
  /** About me module */
  AboutMeModule = 'ABOUT_ME_MODULE',
  /** Accomplishments */
  AccomplishmentsModule = 'ACCOMPLISHMENTS_MODULE',
  /** Career settings module */
  CareerSettingsModule = 'CAREER_SETTINGS_MODULE',
  /** Contacts module */
  ContactsModule = 'CONTACTS_MODULE',
  /** Content insider module */
  ContentInsiderModule = 'CONTENT_INSIDER_MODULE',
  /** DisplayAd module */
  DisplayAdModule = 'DISPLAY_AD_MODULE',
  /** Engagement module */
  EngagementModule = 'ENGAGEMENT_MODULE',
  /** Hiring Highlights */
  HiringHighlightsModule = 'HIRING_HIGHLIGHTS_MODULE',
  /** Interests module */
  InterestsModule = 'INTERESTS_MODULE',
  /** Languages module */
  LanguagesModule = 'LANGUAGES_MODULE',
  /** Legal imprint module */
  LegalImprintModule = 'LEGAL_IMPRINT_MODULE',
  /** Next best action module */
  NextBestActionModule = 'NEXT_BEST_ACTION_MODULE',
  /** Note module */
  NoteModule = 'NOTE_MODULE',
  /** Patterns showcase module */
  PatternsShowcaseModule = 'PATTERNS_SHOWCASE_MODULE',
  /** Personal details module */
  PersonalDetailsModule = 'PERSONAL_DETAILS_MODULE',
  /** Skills module */
  SkillsModule = 'SKILLS_MODULE',
  /** Timeline module */
  TimelineModule = 'TIMELINE_MODULE',
  /** Vomp module */
  VompModule = 'VOMP_MODULE',
  /** Wants module */
  WantsModule = 'WANTS_MODULE',
  /** Xing ID module */
  XingIdModule = 'XING_ID_MODULE',
}

export type ProfileModuleStoreItem = {
  __typename?: 'ProfileModuleStoreItem';
  /** #soft-logged-in #logged-out */
  isActive: Scalars['Boolean']['output'];
  /** #soft-logged-in #logged-out */
  isVisibleOnAllClients: Scalars['Boolean']['output'];
  /** #soft-logged-in #logged-out */
  moduleIdentifier: ProfileModuleIdentifier;
  /** #soft-logged-in #logged-out */
  moduleTitle: Scalars['String']['output'];
};

export type ProfileModules = HasGlobalId & {
  __typename?: 'ProfileModules';
  aboutMeModule?: Maybe<ProfileAboutMeModule>;
  /** #preview */
  accomplishmentsModule?: Maybe<AccomplishmentsModule>;
  careerSettingsModule?: Maybe<CareerSettingsModule>;
  commonalitiesModule?: Maybe<CommonalitiesModule>;
  contactsModule?: Maybe<ContactsModule>;
  /** #rest */
  contentInsiderModule?: Maybe<ContentInsiderModule>;
  displayAdModule?: Maybe<DisplayAdModule>;
  /** @deprecated Module is removed */
  engagementModule?: Maybe<ProfileEngagementModule>;
  /** #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  hiringHighlightsModule?: Maybe<HiringHighlightsModule>;
  /** #soft-logged-in */
  interestsModule?: Maybe<ProfileInterestsModule>;
  jobWishesPreferenceModule?: Maybe<JobWishesPreferenceModule>;
  /** #soft-logged-in */
  languagesModule?: Maybe<ProfileLanguagesModule>;
  /** @deprecated Module is renamed to legalInformationModule */
  legalImprintModule?: Maybe<LegalImprintModule>;
  legalInformationModule?: Maybe<LegalInformationModule>;
  neffiModule?: Maybe<NeffiModule>;
  /**
   * #rest
   * @deprecated Will be removed. Jira ticket: PROFILE-10408
   */
  nextBestActionModule?: Maybe<NextBestActionModule>;
  noteModule?: Maybe<NoteModule>;
  personalDetailsModule?: Maybe<PersonalDetailsModule>;
  projobsUpsellBannerModule?: Maybe<ProjobsUpsellBannerModule>;
  /** #soft-logged-in */
  skillsModule?: Maybe<ProfileSkillsModule>;
  statsModule?: Maybe<ProfileStatsModule>;
  /** #rest */
  timelineModule?: Maybe<ProfileTimelineModule>;
  vompModule?: Maybe<VompModule>;
  /**
   * #soft-logged-in
   * @deprecated The wants will be removed in favor of job-preferences: PROFILE-10484.
   */
  wantsModule?: Maybe<ProfileWantsModule>;
  /** #soft-logged-in #rest */
  xingIdModule?: Maybe<XingIdModule>;
};

export type ProfileModulesNextBestActionModuleArgs = {
  cards: Array<NbaCardId>;
};

export type ProfileModulesXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

export type ProfileOccupation = {
  __typename?: 'ProfileOccupation';
  /**
   * #logged-out #soft-logged-in
   * @deprecated Use occupations[0].headline instead. Checked on 14.11.2023 - still used a lot by Web and Mobile
   */
  occupationOrg?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Use occupations[0].subline instead. Checked on 14.11.2023 - still used a lot by Web and Mobile
   */
  occupationTitle?: Maybe<Scalars['String']['output']>;
};

/** The type of an occupation, like student or employee */
export type ProfileOccupationType = Localizable & {
  __typename?: 'ProfileOccupationType';
  identifier: ProfileOccupationTypeIdentifier;
  /** The raw text resource key from Phraseapp */
  localizationKey: Scalars['String']['output'];
  /** The value localized based on the `Accept-Language` header */
  localizationValue: Scalars['String']['output'];
};

/** Categories of occupation types */
export type ProfileOccupationTypeCategory = Localizable & {
  __typename?: 'ProfileOccupationTypeCategory';
  identifier?: Maybe<ProfileOccupationTypeCategoryIdentifier>;
  /** The raw text resource key from Phraseapp */
  localizationKey: Scalars['String']['output'];
  /** The value localized based on the `Accept-Language` header */
  localizationValue: Scalars['String']['output'];
  occupationTypes?: Maybe<Array<ProfileOccupationType>>;
};

export const enum ProfileOccupationTypeCategoryIdentifier {
  Educational = 'EDUCATIONAL',
  EntryLevel = 'ENTRY_LEVEL',
  Other = 'OTHER',
  Professional = 'PROFESSIONAL',
}

export const enum ProfileOccupationTypeIdentifier {
  Apprenticeship = 'APPRENTICESHIP',
  BoardMember = 'BOARD_MEMBER',
  CivilServant = 'CIVIL_SERVANT',
  Employee = 'EMPLOYEE',
  Freelancer = 'FREELANCER',
  FreelancerSelfEmployed = 'FREELANCER_SELF_EMPLOYED',
  Intern = 'INTERN',
  Owner = 'OWNER',
  ParentalLeave = 'PARENTAL_LEAVE',
  Partner = 'PARTNER',
  PartnerShareholder = 'PARTNER_SHAREHOLDER',
  SelfEmployed = 'SELF_EMPLOYED',
  Shareholder = 'SHAREHOLDER',
  UniversityStudent = 'UNIVERSITY_STUDENT',
  Volunteer = 'VOLUNTEER',
}

export type ProfilePageMeta = {
  __typename?: 'ProfilePageMeta';
  /** #logged-out #soft-logged-in */
  indexable?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  pageCanonical?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  pageDescription?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  pageTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  qualityIndex?: Maybe<Scalars['Float']['output']>;
  /** #logged-out #soft-logged-in */
  qualityVariant?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  scrambledProfileUrl?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  socialMediaDescription?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  socialMediaImage?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  socialMediaTitle?: Maybe<Scalars['String']['output']>;
};

export type ProfilePageMetaOrError = ProfilePageMeta | ResponseError;

/** Something like (`on parental leave` etc.), including a global id */
export type ProfilePersonalStatus = {
  __typename?: 'ProfilePersonalStatus';
  bucketIdentifier?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  identifier?: Maybe<ProfilePersonalStatuses>;
  localizationKey?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  localizationValue?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  variants: Array<Maybe<ProfilePersonalStatusVariant>>;
};

/** Something like the category of a status */
export type ProfilePersonalStatusBucket = Localizable & {
  __typename?: 'ProfilePersonalStatusBucket';
  identifier?: Maybe<Scalars['String']['output']>;
  /** The raw text resource key from Phraseapp */
  localizationKey: Scalars['String']['output'];
  /** The value localized based on the `Accept-Language` header */
  localizationValue: Scalars['String']['output'];
};

/** Collection of ProfilePersonalStatusBucket */
export type ProfilePersonalStatusBuckets = {
  __typename?: 'ProfilePersonalStatusBuckets';
  collection: Array<Maybe<ProfilePersonalStatusBucket>>;
};

/** Collection of statuses that are available to the viewer */
export type ProfilePersonalStatusOptions = {
  __typename?: 'ProfilePersonalStatusOptions';
  /** #rest #virtual */
  buckets?: Maybe<ProfilePersonalStatusBuckets>;
  collection: Array<Maybe<ProfilePersonalStatus>>;
};

/** A different localization variant of a status. E.g. Vacationing (smiley) */
export type ProfilePersonalStatusVariant = Localizable & {
  __typename?: 'ProfilePersonalStatusVariant';
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

/** All possible status identifiers */
export const enum ProfilePersonalStatuses {
  /** The freelancer is available for bookings. */
  Available = 'AVAILABLE',
  /** The freelancer is partially available. */
  AvailablePartially = 'AVAILABLE_PARTIALLY',
  /** The freelancer will be available for bookings soon. */
  AvailableSoon = 'AVAILABLE_SOON',
  /** The freelancer is currently booked. */
  Booked = 'BOOKED',
  /** The user has a custom status. */
  Custom = 'CUSTOM',
  /** The student is having exams */
  ExamPeriod = 'EXAM_PERIOD',
  /** The student writes his final thesis. */
  FinalThesis = 'FINAL_THESIS',
  /** Fine and healthy */
  FineAndHealthy = 'FINE_AND_HEALTHY',
  /** The employee is doing further education */
  FurtherEducation = 'FURTHER_EDUCATION',
  /** The employee is on holiday. */
  Holiday = 'HOLIDAY',
  /** The employee is looking for a new team member. */
  LookingForNewTeamMember = 'LOOKING_FOR_NEW_TEAM_MEMBER',
  /** Looking for volunteers */
  LookingForVolunteers = 'LOOKING_FOR_VOLUNTEERS',
  /** The freelancer is not interested in bookings. */
  NotInterested = 'NOT_INTERESTED',
  /** Open for projects */
  OpenForProjects = 'OPEN_FOR_PROJECTS',
  /** The employee is on parental leave. */
  ParentalLeave = 'PARENTAL_LEAVE',
  /** The student is doing a practical semester. */
  PracticalSemester = 'PRACTICAL_SEMESTER',
  /** The employee does a sabbatical. */
  Sabbatical = 'SABBATICAL',
  /** The student is studying abroad. */
  SemesterAbroad = 'SEMESTER_ABROAD',
  /** The student paused his studies for one semester. */
  SemesterOff = 'SEMESTER_OFF',
  /** Staying at home */
  StayingAtHome = 'STAYING_AT_HOME',
  /** The employee is on a training course. */
  TrainingCourse = 'TRAINING_COURSE',
  /** The employee travels around. */
  Travelling = 'TRAVELLING',
  /** Working from home */
  WorkingFromHome = 'WORKING_FROM_HOME',
  /** Would appreciate social contacts */
  WouldAppreciateSocialContacts = 'WOULD_APPRECIATE_SOCIAL_CONTACTS',
  /** Would like to help */
  WouldLikeToHelp = 'WOULD_LIKE_TO_HELP',
}

export type ProfilePreviewMetadata = PreviewMetadata & {
  __typename?: 'ProfilePreviewMetadata';
  sourceActor?: Maybe<LinkPreviewSourceActor>;
  userId: Scalars['ID']['output'];
};

export type ProfileQualification = {
  __typename?: 'ProfileQualification';
  /** Qualification description */
  description: Scalars['String']['output'];
  /** Qualification id */
  id: Scalars['ID']['output'];
};

export type ProfileQualificationCollection = {
  __typename?: 'ProfileQualificationCollection';
  qualificationList?: Maybe<Array<ProfileQualification>>;
};

/** Settings related to profiles pages */
export type ProfileSettings = {
  __typename?: 'ProfileSettings';
  activityTabVisibility?: Maybe<SelectSetting>;
  /** Settings regarding the default tab of profile */
  defaultTab?: Maybe<SelectSetting>;
  nameGender?: Maybe<NameGenderSettings>;
  portfolioTabVisibility?: Maybe<SelectSetting>;
};

export type ProfileSettingsInput = {
  activityTabVisibility?: InputMaybe<SelectSettingInput>;
  defaultTab?: InputMaybe<SelectSettingInput>;
  nameGender?: InputMaybe<PendingNameGenderSettingInput>;
  portfolioTabVisibility?: InputMaybe<SelectSettingInput>;
};

export type ProfileSettingsMutationResult = {
  __typename?: 'ProfileSettingsMutationResult';
  error?: Maybe<ProfileSettings>;
  response?: Maybe<ProfileSettings>;
};

export type ProfileSkill = {
  __typename?: 'ProfileSkill';
  category: Scalars['String']['output'];
  isTop: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type ProfileSkillInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  isTop: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

export type ProfileSkills = HasGlobalId & {
  __typename?: 'ProfileSkills';
  collection?: Maybe<Array<Maybe<ProfileSkill>>>;
  /** #soft-logged-in #logged-out */
  globalId: Scalars['GlobalID']['output'];
  /**
   * #soft-logged-in #logged-out
   * @deprecated use collection field instead
   */
  skills?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * #soft-logged-in #logged-out
   * @deprecated use collection field instead
   */
  topSkills?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProfileSkillsAddInput = {
  shareWithContacts?: InputMaybe<Scalars['Boolean']['input']>;
  skill: ProfileSkillInput;
};

/** Skills Autocomplete Suggestions queries */
export type ProfileSkillsAutocompleteSuggestions = {
  __typename?: 'ProfileSkillsAutocompleteSuggestions';
  collection: Array<ProfileSkillsSuggestion>;
  /** Suggestions service name for use in autocompletionTrack mutation */
  service: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

/** Profile module that allow users to see/add skills */
export type ProfileSkillsModule = ModuleInterface &
  OutdatableModule & {
    __typename?: 'ProfileSkillsModule';
    active?: Maybe<Scalars['Boolean']['output']>;
    /** #soft-logged-in #rest #virtual */
    content?: Maybe<ProfileSkills>;
    /** #soft-logged-in */
    globalId: Scalars['GlobalID']['output'];
    /** #soft-logged-in */
    lastModified?: Maybe<Scalars['Date']['output']>;
    /** #soft-logged-in */
    order: Scalars['Int']['output'];
    /** #soft-logged-in */
    outdated: Scalars['Boolean']['output'];
    /** #soft-logged-in */
    outdatedFields?: Maybe<Scalars['String']['output']>;
    /** #soft-logged-in */
    title: Scalars['String']['output'];
  };

export type ProfileSkillsModuleRecommendation = {
  __typename?: 'ProfileSkillsModuleRecommendation';
  /** #rest #virtual */
  userRecommendedSkills?: Maybe<ProfileSkillsModuleRecommendedSkills>;
};

export type ProfileSkillsModuleRecommendationUserRecommendedSkillsArgs = {
  consumer?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Skills recommendations queries */
export type ProfileSkillsModuleRecommendedSkill = {
  __typename?: 'ProfileSkillsModuleRecommendedSkill';
  label: Scalars['String']['output'];
  score: Scalars['Float']['output'];
  totalPerformance?: Maybe<Scalars['Int']['output']>;
  trackingToken?: Maybe<Scalars['String']['output']>;
  trend?: Maybe<Scalars['Int']['output']>;
};

export type ProfileSkillsModuleRecommendedSkills = {
  __typename?: 'ProfileSkillsModuleRecommendedSkills';
  collection?: Maybe<Array<ProfileSkillsModuleRecommendedSkill>>;
};

/** Skills recommendations queries */
export type ProfileSkillsModuleSkillPerformance = {
  __typename?: 'ProfileSkillsModuleSkillPerformance';
  isNewSkill?: Maybe<Scalars['Boolean']['output']>;
  isTopSkill: Scalars['Boolean']['output'];
  isVisible: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  /** @deprecated renamed to label. WILL BE REMOVED ... */
  skill: Scalars['String']['output'];
  totalPerformance?: Maybe<Scalars['Int']['output']>;
  trend?: Maybe<Scalars['Int']['output']>;
};

export type ProfileSkillsModuleSkillsPerformace = HasGlobalId & {
  __typename?: 'ProfileSkillsModuleSkillsPerformace';
  globalId: Scalars['GlobalID']['output'];
  skills?: Maybe<Array<ProfileSkillsModuleSkillPerformance>>;
  totalPerformance?: Maybe<Scalars['Int']['output']>;
};

export type ProfileSkillsModuleSkillsPerformaceWithCurrentSkills = {
  __typename?: 'ProfileSkillsModuleSkillsPerformaceWithCurrentSkills';
  changePercentage?: Maybe<Scalars['Int']['output']>;
  currentTotalPerformance?: Maybe<Scalars['Int']['output']>;
  globalId: Scalars['GlobalID']['output'];
  savedTotalPerformance: Scalars['Int']['output'];
  skills?: Maybe<Array<ProfileSkillsModuleSkillPerformance>>;
};

/** Skills module visibility */
export type ProfileSkillsModuleVisibility = {
  __typename?: 'ProfileSkillsModuleVisibility';
  enabled: Scalars['Boolean']['output'];
};

export type ProfileSkillsSuggestion = {
  __typename?: 'ProfileSkillsSuggestion';
  /** Skill category, either 'soft' or 'hard' */
  category?: Maybe<Scalars['String']['output']>;
  /** 0-based position in suggestion list */
  position: Scalars['Int']['output'];
  /** Suggestion text */
  suggestion: Scalars['String']['output'];
  /** Skill performance */
  totalPerformance?: Maybe<Scalars['Int']['output']>;
  /** Tracking token for use in autocompletionTrack mutation */
  trackingToken: Scalars['String']['output'];
  /** Skill performance trend */
  trend?: Maybe<Scalars['Int']['output']>;
};

export type ProfileSkillsUpdateError = {
  __typename?: 'ProfileSkillsUpdateError';
  description?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<ProfileSkillsUpdateFieldsError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ProfileSkillsUpdateFieldsError = {
  __typename?: 'ProfileSkillsUpdateFieldsError';
  haves?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProfileSkillsUpdateInput = {
  collection?: InputMaybe<Array<InputMaybe<ProfileSkillInput>>>;
  /** Collection of skills, like [{value: 'top-skill-1', isTop: true, category: 'hard'}, {value: 'skill-1', isTop: false, category: 'soft'}]  */
  shareWithContacts?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Skills of a user, like ['skill-1', 'skill-2']
   * @deprecated use collection field instead with isTop set to false
   */
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Top Skills of a user, like ['top-skill-1', 'top-skill-2']
   * @deprecated use collection field instead with isTop set to true
   */
  topSkills?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProfileSkillsUpdateResult = {
  __typename?: 'ProfileSkillsUpdateResult';
  error?: Maybe<ProfileSkillsUpdateError>;
  /** #rest #virtual */
  profileSkills?: Maybe<ProfileSkills>;
};

export type ProfileStats = HasDerivedGlobalId & {
  __typename?: 'ProfileStats';
  createdAt: Scalars['String']['output'];
  /** Globally unique id for this instance based on typename and id */
  globalId: Scalars['GlobalID']['output'];
  totalHits: Scalars['Int']['output'];
};

export type ProfileStatsModule = ModuleInterface & {
  __typename?: 'ProfileStatsModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** #rest #virtual */
  content?: Maybe<ProfileStats>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type ProfileStructuredData = {
  __typename?: 'ProfileStructuredData';
  /** #logged-out #soft-logged-in */
  alumniOf?: Maybe<Array<Maybe<ProfileStructuredDataAlumni>>>;
  /** #logged-out #soft-logged-in */
  context?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  familyName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  givenName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  honorificPrefix?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  honorificSuffix?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  image?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  makesOffer?: Maybe<Array<Maybe<ProfileStructuredDataOfferAndSeeks>>>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  sameAs?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  seeks?: Maybe<Array<Maybe<ProfileStructuredDataOfferAndSeeks>>>;
  /** #logged-out #soft-logged-in */
  type?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  workLocation?: Maybe<ProfileStructuredDataWorkLocation>;
  /** #logged-out #soft-logged-in */
  worksFor?: Maybe<Array<Maybe<ProfileStructuredDataWorkExperience>>>;
};

export type ProfileStructuredDataAlumni = {
  __typename?: 'ProfileStructuredDataAlumni';
  /** #logged-out #soft-logged-in */
  alumniOf?: Maybe<ProfileStructuredDataAlumniOrganization>;
  /** #logged-out #soft-logged-in */
  endDate?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  startDate?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  type?: Maybe<Scalars['String']['output']>;
};

export type ProfileStructuredDataAlumniOrganization = {
  __typename?: 'ProfileStructuredDataAlumniOrganization';
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  type?: Maybe<Scalars['String']['output']>;
};

export type ProfileStructuredDataOfferAndSeeks = {
  __typename?: 'ProfileStructuredDataOfferAndSeeks';
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  type?: Maybe<Scalars['String']['output']>;
};

export type ProfileStructuredDataWorkAddress = {
  __typename?: 'ProfileStructuredDataWorkAddress';
  /** #logged-out #soft-logged-in */
  addressCountry?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  addressLocality?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  type?: Maybe<Scalars['String']['output']>;
};

export type ProfileStructuredDataWorkExperience = {
  __typename?: 'ProfileStructuredDataWorkExperience';
  /** #logged-out #soft-logged-in */
  endDate?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  roleName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  startDate?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  type?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  worksFor?: Maybe<ProfileStructuredDataWorkOrganization>;
};

export type ProfileStructuredDataWorkLocation = {
  __typename?: 'ProfileStructuredDataWorkLocation';
  /** #logged-out #soft-logged-in */
  address?: Maybe<ProfileStructuredDataWorkAddress>;
  /** #logged-out #soft-logged-in */
  type?: Maybe<Scalars['String']['output']>;
};

export type ProfileStructuredDataWorkOrganization = {
  __typename?: 'ProfileStructuredDataWorkOrganization';
  /** #logged-out #soft-logged-in */
  location?: Maybe<ProfileStructuredDataWorkAddress>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  type?: Maybe<Scalars['String']['output']>;
};

/** All avaliable actions: ProfileTimelineGlobalAction + ProfileTimelineEntryAction */
export const enum ProfileTimelineAction {
  AddEntry = 'ADD_ENTRY',
  EditEntry = 'EDIT_ENTRY',
}

export type ProfileTimelineAddEntryInput = {
  formInput: ProfileTimelineEntryFormInput;
  occupationType: ProfileOccupationTypeIdentifier;
  occupationTypeCategory: ProfileOccupationTypeCategoryIdentifier;
};

/** The outermost organizational unit. Until now 'Work experience' or 'Education' */
export type ProfileTimelineBucket = Localizable & {
  __typename?: 'ProfileTimelineBucket';
  /** The actual entries */
  entries?: Maybe<Array<Maybe<ProfileTimelineEntry>>>;
  /** The raw text resource key from Phraseapp */
  localizationKey: Scalars['String']['output'];
  /** The value localized based on the `Accept-Language` header */
  localizationValue: Scalars['String']['output'];
};

export type ProfileTimelineCareerLevelField = ProfileTimelineDropdownField &
  ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField & {
    __typename?: 'ProfileTimelineCareerLevelField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    options?: Maybe<Array<ProfileTimelineDropdownValue>>;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

/** Just simple 'sub-entries' */
export type ProfileTimelineCareerStep = {
  __typename?: 'ProfileTimelineCareerStep';
  /** Localized time data as markdown */
  localizedTimeString: Scalars['Markdown']['output'];
  /** A job title, like Senior Buzzword Generator */
  title: Scalars['String']['output'];
};

export type ProfileTimelineCompanyFieldInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileTimelineCompanyIndustryField = ProfileTimelineField &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField & {
    __typename?: 'ProfileTimelineCompanyIndustryField';
    companyIndustryValue?: Maybe<ProfileTimelineCompanyIndustryFieldValue>;
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    /** #rest #virtual */
    options?: Maybe<Array<Industry>>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

export type ProfileTimelineCompanyIndustryFieldInput = {
  /** Id as taken from Query.industries.id */
  industry?: InputMaybe<Scalars['ID']['input']>;
  /** Id as taken from Query.industries.segments.id */
  segment?: InputMaybe<Scalars['ID']['input']>;
};

export type ProfileTimelineCompanyIndustryFieldValue = {
  __typename?: 'ProfileTimelineCompanyIndustryFieldValue';
  /** Id as taken from Query.industries.id */
  industry?: Maybe<Scalars['ID']['output']>;
  /** Id as taken from Query.industries.segments.id */
  segment?: Maybe<Scalars['ID']['output']>;
};

export type ProfileTimelineCompanyNameField = ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField &
  ProfileTimelineMaxLengthField & {
    __typename?: 'ProfileTimelineCompanyNameField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    maxLength?: Maybe<Scalars['Int']['output']>;
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

export type ProfileTimelineCourseOfStudyField = ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField & {
    __typename?: 'ProfileTimelineCourseOfStudyField';
    isAddable: Scalars['Boolean']['output'];
    isMandatory: Scalars['Boolean']['output'];
    name: ProfileTimelineFieldName;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
  };

export type ProfileTimelineCourseOfStudyFieldInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileTimelineDegreeField = ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField & {
    __typename?: 'ProfileTimelineDegreeField';
    isAddable: Scalars['Boolean']['output'];
    isMandatory: Scalars['Boolean']['output'];
    name: ProfileTimelineFieldName;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
  };

export type ProfileTimelineDegreeFieldInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileTimelineDeleteEntryError = {
  __typename?: 'ProfileTimelineDeleteEntryError';
  message: Scalars['String']['output'];
};

export type ProfileTimelineDeleteEntryInput = {
  urn: Scalars['GlobalID']['input'];
};

export type ProfileTimelineDeleteEntryPayload = {
  __typename?: 'ProfileTimelineDeleteEntryPayload';
  error?: Maybe<ProfileTimelineDeleteEntryError>;
  success?: Maybe<ProfileTimelineModule>;
};

export type ProfileTimelineDescriptionField = ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField &
  ProfileTimelineMaxLengthField & {
    __typename?: 'ProfileTimelineDescriptionField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    maxLength?: Maybe<Scalars['Int']['output']>;
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

export type ProfileTimelineDescriptionFieldInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileTimelineDisciplineField = ProfileTimelineDropdownField &
  ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField & {
    __typename?: 'ProfileTimelineDisciplineField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    options?: Maybe<Array<ProfileTimelineDropdownValue>>;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

/** Describes dropdown form field for the Timeline module */
export type ProfileTimelineDropdownField = {
  options?: Maybe<Array<ProfileTimelineDropdownValue>>;
};

/** Dropdown value for the form field on the Timeline module */
export type ProfileTimelineDropdownValue = {
  __typename?: 'ProfileTimelineDropdownValue';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Describes dropdown form field for the Timeline module but using ID for value */
export type ProfileTimelineDropdownWithIdField = {
  optionsWithId: Array<ProfileTimelineDropdownWithIdValue>;
};

/** Dropdown value with scrambled ID for the form field on the Timeline module */
export type ProfileTimelineDropdownWithIdValue = {
  __typename?: 'ProfileTimelineDropdownWithIdValue';
  label: Scalars['String']['output'];
  value: Scalars['ID']['output'];
};

export type ProfileTimelineEditEntryInput = {
  formInput: ProfileTimelineEntryFormInput;
  urn: Scalars['GlobalID']['input'];
};

export type ProfileTimelineEmployeesField = ProfileTimelineDropdownField &
  ProfileTimelineDropdownWithIdField &
  ProfileTimelineField &
  ProfileTimelineFieldIdValue &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField & {
    __typename?: 'ProfileTimelineEmployeesField';
    /** Value stored for the field in the scrambled Id format */
    idValue?: Maybe<Scalars['ID']['output']>;
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    options?: Maybe<Array<ProfileTimelineDropdownValue>>;
    optionsWithId: Array<ProfileTimelineDropdownWithIdValue>;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

export type ProfileTimelineEmploymentField = ProfileTimelineDropdownField &
  ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField & {
    __typename?: 'ProfileTimelineEmploymentField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    options?: Maybe<Array<ProfileTimelineDropdownValue>>;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

/** A ProfileTimelineEntry is the affiliation with a specific organization, like working for a company. It can hold several career steps and has possible actions. */
export type ProfileTimelineEntry = {
  __typename?: 'ProfileTimelineEntry';
  /** Data based on user memberships or roles like projobs or executives */
  additionalData?: Maybe<ProfileTimelineEntryAdditionalData>;
  /**
   * #preview
   *
   *  Simple sub-entries the user can have as part of an entry
   */
  careerSteps: Array<ProfileTimelineCareerStep>;
  /** User entered (future) degree */
  degree?: Maybe<Scalars['String']['output']>;
  /** User entered information that describes the entry in more detail. Company notes for work experiences, and school notes for schools */
  description?: Maybe<Scalars['String']['output']>;
  /** The raw id. Not the URN */
  id?: Maybe<Scalars['ID']['output']>;
  /** Multiple entries can be current, think parallel club membership and employment at a company */
  isCurrent: Scalars['Boolean']['output'];
  /** A simplified localized time string that does not include 'current' aka 'ongoing' or 'until now' and no markdown */
  justLocalizedTimeString: Scalars['String']['output'];
  /** Localized time data as markdown, the date copy part indicating current ("present"/"bis heute") is formatted in **bold** */
  localizedTimeString: Scalars['Markdown']['output'];
  /** The geographical location of this entry. Where is the office? */
  location?: Maybe<ProfileTimelineEntryLocation>;
  /** Something like employee, freelancer, owner */
  occupationType: ProfileOccupationType;
  /**
   * #rest(batched) #virtual
   *
   *  An organization, like the company somebody is working at or a club she is a member of
   */
  organization?: Maybe<ProfileTimelineOrganization>;
  /** An entry title, this can be a job title like Software Engineer, an educational title like Mathematics or it can be null */
  title?: Maybe<Scalars['String']['output']>;
  /** The URN of the entry */
  urn?: Maybe<Scalars['GlobalID']['output']>;
  /** User entered website with more information on the entry */
  website?: Maybe<ProfileTimelineEntryWebsite>;
};

/**
 * Additional Data vowen in from other back ends depending on the entry having different types of additional data.
 * As of now we have the old projobs data integration as `projobsData` and the new integration of the projobs V2
 * work experiences endpoint as `projobsV2WorkExperienceData`
 */
export type ProfileTimelineEntryAdditionalData = {
  __typename?: 'ProfileTimelineEntryAdditionalData';
  /** Old projobs job seeker data */
  projobsData?: Maybe<ProJobsWorkExperience>;
  /**
   * #rest #virtual
   *
   *  New projobs V2 backend data
   */
  projobsV2Data?: Maybe<ProfileTimelineEntryProjobsV2Data>;
};

export type ProfileTimelineEntryDeletability = {
  __typename?: 'ProfileTimelineEntryDeletability';
  isDeletable: Scalars['Boolean']['output'];
  isPotentiallyDeletable: Scalars['Boolean']['output'];
  reason?: Maybe<Scalars['String']['output']>;
};

export type ProfileTimelineEntryError = {
  __typename?: 'ProfileTimelineEntryError';
  description?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<ProfileTimelineEntryFieldErrors>;
  message: Scalars['String']['output'];
};

export type ProfileTimelineEntryFieldErrors = {
  __typename?: 'ProfileTimelineEntryFieldErrors';
  careerLevel?: Maybe<Array<Scalars['String']['output']>>;
  companyIndustry?: Maybe<Array<Scalars['String']['output']>>;
  companyName?: Maybe<Array<Scalars['String']['output']>>;
  courseOfStudy?: Maybe<Array<Scalars['String']['output']>>;
  degree?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Array<Scalars['String']['output']>>;
  discipline?: Maybe<Array<Scalars['String']['output']>>;
  employees?: Maybe<Array<Scalars['String']['output']>>;
  employment?: Maybe<Array<Scalars['String']['output']>>;
  industry?: Maybe<Array<Scalars['String']['output']>>;
  jobTitle?: Maybe<Array<Scalars['String']['output']>>;
  legalForm?: Maybe<Array<Scalars['String']['output']>>;
  location?: Maybe<Array<Scalars['String']['output']>>;
  primaryOccupation?: Maybe<Array<Scalars['String']['output']>>;
  projobsBudget?: Maybe<Array<Scalars['String']['output']>>;
  projobsRevenue?: Maybe<Array<Scalars['String']['output']>>;
  projobsStaffResponsibility?: Maybe<Array<Scalars['String']['output']>>;
  timePeriod?: Maybe<Array<Scalars['String']['output']>>;
  university?: Maybe<Array<Scalars['String']['output']>>;
  website?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProfileTimelineEntryFormInput = {
  careerLevel?: InputMaybe<ProfileTimelineStringFieldInput>;
  /** The industry fields comes as `industry` and `companyIndustry` to work around an old issue */
  companyIndustry?: InputMaybe<ProfileTimelineCompanyIndustryFieldInput>;
  companyName?: InputMaybe<ProfileTimelineCompanyFieldInput>;
  courseOfStudy?: InputMaybe<ProfileTimelineCourseOfStudyFieldInput>;
  degree?: InputMaybe<ProfileTimelineDegreeFieldInput>;
  description?: InputMaybe<ProfileTimelineDescriptionFieldInput>;
  discipline?: InputMaybe<ProfileTimelineStringFieldInput>;
  employees?: InputMaybe<ProfileTimelineStringFieldInput>;
  employment?: InputMaybe<ProfileTimelineStringFieldInput>;
  /** The industry fields comes as `industry` and `companyIndustry` to work around an old issue */
  industry?: InputMaybe<ProfileTimelineCompanyIndustryFieldInput>;
  jobTitle?: InputMaybe<ProfileTimelineStringFieldInput>;
  legalForm?: InputMaybe<ProfileTimelineStringFieldInput>;
  location?: InputMaybe<ProfileTimelineLocationFieldInput>;
  primaryOccupation?: InputMaybe<ProfileTimelinePrimaryOccupationFieldInput>;
  projobsBudget?: InputMaybe<ProfileTimelineProjobsFieldInput>;
  projobsRevenue?: InputMaybe<ProfileTimelineProjobsFieldInput>;
  projobsStaffResponsibility?: InputMaybe<ProfileTimelineStringFieldInput>;
  qualification?: InputMaybe<ProfileTimelineQualificationFieldInput>;
  subject?: InputMaybe<ProfileTimelineSubjectFieldInput>;
  timePeriod?: InputMaybe<ProfileTimelineTimePeriodFieldInput>;
  university?: InputMaybe<ProfileTimelineUniversityFieldInput>;
  vocationalSchool?: InputMaybe<ProfileTimelineVocationalSchoolFieldInput>;
  website?: InputMaybe<ProfileTimelineWebsiteFieldInput>;
};

/** The geographical location of a career step */
export type ProfileTimelineEntryLocation = {
  __typename?: 'ProfileTimelineEntryLocation';
  city?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  country?: Maybe<Country>;
};

export type ProfileTimelineEntryMonthYear = {
  /** Month with January = 1 */
  month?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ProfileTimelineEntryPayload = {
  __typename?: 'ProfileTimelineEntryPayload';
  error?: Maybe<ProfileTimelineEntryError>;
  success?: Maybe<ProfileTimelineModule>;
};

/** Types for the new projobs v2 */
export type ProfileTimelineEntryProjobsV2Data = ProJobsV2WorkExperience;

/** A web site */
export type ProfileTimelineEntryWebsite = {
  __typename?: 'ProfileTimelineEntryWebsite';
  url: Scalars['URL']['output'];
};

/** Describes basic form field for the Timeline module */
export type ProfileTimelineField = {
  /** If the field should be displayed in 'Additional fields' section */
  isAddable: Scalars['Boolean']['output'];
  /** If the field is mandatory */
  isMandatory: Scalars['Boolean']['output'];
  /** Identifier (name) of the form field to be sent to the backend */
  name: ProfileTimelineFieldName;
};

export type ProfileTimelineFieldIdValue = {
  /** Value stored for the field in the scrambled Id format */
  idValue?: Maybe<Scalars['ID']['output']>;
};

/** Supported fields for the forms in the Timeline module */
export const enum ProfileTimelineFieldName {
  CareerLevel = 'CAREER_LEVEL',
  CompanyName = 'COMPANY_NAME',
  CourseOfStudy = 'COURSE_OF_STUDY',
  Degree = 'DEGREE',
  Description = 'DESCRIPTION',
  Discipline = 'DISCIPLINE',
  Employees = 'EMPLOYEES',
  Employment = 'EMPLOYMENT',
  Industry = 'INDUSTRY',
  JobTitle = 'JOB_TITLE',
  LegalForm = 'LEGAL_FORM',
  Location = 'LOCATION',
  PartTime = 'PART_TIME',
  PrimaryOccupation = 'PRIMARY_OCCUPATION',
  ProjobsBudget = 'PROJOBS_BUDGET',
  ProjobsRevenue = 'PROJOBS_REVENUE',
  /** @deprecated supercede by PROJOBS_STAFF_RESPONSIBILITY */
  ProjobsStaff = 'PROJOBS_STAFF',
  ProjobsStaffResponsibility = 'PROJOBS_STAFF_RESPONSIBILITY',
  Qualification = 'QUALIFICATION',
  Subject = 'SUBJECT',
  TimePeriod = 'TIME_PERIOD',
  University = 'UNIVERSITY',
  VocationalSchool = 'VOCATIONAL_SCHOOL',
  Website = 'WEBSITE',
}

export type ProfileTimelineFieldStringValue = {
  /** Value stored for the field in the simple String format */
  stringValue?: Maybe<Scalars['String']['output']>;
};

/** Describes a form for the Timeline module */
export type ProfileTimelineForm = {
  __typename?: 'ProfileTimelineForm';
  components: Array<Maybe<ProfileTimelineFormComponent>>;
  deletability: ProfileTimelineEntryDeletability;
  occupationType: ProfileOccupationType;
  occupationTypeCategory: ProfileOccupationTypeCategory;
};

export type ProfileTimelineFormComponent = {
  /** Text to be displayed next to the field or as separator */
  title: Scalars['String']['output'];
};

export type ProfileTimelineHeader = ProfileTimelineFormComponent & {
  __typename?: 'ProfileTimelineHeader';
  /** Text to be displayed as the header */
  title: Scalars['String']['output'];
};

export type ProfileTimelineJobTitleField = ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField & {
    __typename?: 'ProfileTimelineJobTitleField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

/** Fields that have a label, deprecated: please use ProfileTimelineFormComponent instead */
export type ProfileTimelineLabelableField = {
  /** Text to be displayed next to the field */
  title: Scalars['String']['output'];
};

export type ProfileTimelineLegalFormField = ProfileTimelineDropdownField &
  ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField & {
    __typename?: 'ProfileTimelineLegalFormField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    options?: Maybe<Array<ProfileTimelineDropdownValue>>;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

export type ProfileTimelineLocationField = ProfileTimelineField &
  ProfileTimelineFormComponent & {
    __typename?: 'ProfileTimelineLocationField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    /** Location stored for the field */
    locationValue?: Maybe<ProfileTimelineLocationFieldValue>;
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

export type ProfileTimelineLocationFieldInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  /** Autocomplete item ID */
  locationId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProfileTimelineLocationFieldValue = {
  __typename?: 'ProfileTimelineLocationFieldValue';
  city?: Maybe<Scalars['String']['output']>;
  /** Autocomplete item ID */
  locationId?: Maybe<Scalars['Int']['output']>;
};

export type ProfileTimelineMaxLengthField = {
  maxLength?: Maybe<Scalars['Int']['output']>;
};

/** The Timeline module, with buckets of professional and educational timeline data, as well as global actions */
export type ProfileTimelineModule = HasGlobalId &
  ModuleInterface &
  OutdatableModule & {
    __typename?: 'ProfileTimelineModule';
    active?: Maybe<Scalars['Boolean']['output']>;
    buckets?: Maybe<Array<ProfileTimelineBucket>>;
    globalId: Scalars['GlobalID']['output'];
    lastModified?: Maybe<Scalars['Date']['output']>;
    order: Scalars['Int']['output'];
    outdated: Scalars['Boolean']['output'];
    outdatedFields?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
  };

/** A union of organization types, like Company and EducationalInstitution */
export type ProfileTimelineOrganization =
  | ProfileCompany
  | ProfileEducationalInstitution;

export type ProfileTimelinePrimaryOccupationField = ProfileTimelineField &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField & {
    __typename?: 'ProfileTimelinePrimaryOccupationField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

export type ProfileTimelinePrimaryOccupationFieldInput = {
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProfileTimelineProjobsBudgetField = ProfileTimelineField &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField &
  ProfileTimelineProjobsResponsibilityField & {
    __typename?: 'ProfileTimelineProjobsBudgetField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    projobsValue?: Maybe<ProfileTimelineProjobsValue>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

export type ProfileTimelineProjobsFieldInput = {
  hasResponsibility?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Input for the fact if a user has budget or revenue responsibility and the monetary value as an integer.
 *
 * The value is an object of the following form:
 *
 * ```
 * {
 *   "projobsValue": {
 *     "hasResponsibility": true,
 *     "value": 1000000
 *   }
 * }
 * ```
 */
export type ProfileTimelineProjobsResponsibilityField = {
  projobsValue?: Maybe<ProfileTimelineProjobsValue>;
};

export type ProfileTimelineProjobsRevenueField = ProfileTimelineField &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField &
  ProfileTimelineProjobsResponsibilityField & {
    __typename?: 'ProfileTimelineProjobsRevenueField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    projobsValue?: Maybe<ProfileTimelineProjobsValue>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

export type ProfileTimelineProjobsStaffResponsibilityField =
  ProfileTimelineDropdownField &
    ProfileTimelineField &
    ProfileTimelineFieldStringValue &
    ProfileTimelineFormComponent &
    ProfileTimelineLabelableField & {
      __typename?: 'ProfileTimelineProjobsStaffResponsibilityField';
      /** If the field should be displayed in 'Additional fields' section */
      isAddable: Scalars['Boolean']['output'];
      /** If the field is mandatory */
      isMandatory: Scalars['Boolean']['output'];
      /** Identifier (name) of the form field to be sent to the backend */
      name: ProfileTimelineFieldName;
      options?: Maybe<Array<ProfileTimelineDropdownValue>>;
      /** Value stored for the field in the simple String format */
      stringValue?: Maybe<Scalars['String']['output']>;
      /** Text to be displayed next to the field or as separator */
      title: Scalars['String']['output'];
    };

export type ProfileTimelineProjobsValue = {
  __typename?: 'ProfileTimelineProjobsValue';
  hasResponsibility: Scalars['Boolean']['output'];
  value?: Maybe<Scalars['Int']['output']>;
};

export type ProfileTimelineQualificationField = ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent & {
    __typename?: 'ProfileTimelineQualificationField';
    isAddable: Scalars['Boolean']['output'];
    isMandatory: Scalars['Boolean']['output'];
    name: ProfileTimelineFieldName;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
  };

export type ProfileTimelineQualificationFieldInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileTimelineStringFieldInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileTimelineSubjectField = ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent & {
    __typename?: 'ProfileTimelineSubjectField';
    isAddable: Scalars['Boolean']['output'];
    isMandatory: Scalars['Boolean']['output'];
    name: ProfileTimelineFieldName;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
  };

export type ProfileTimelineSubjectFieldInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileTimelineTimePeriodField = ProfileTimelineField &
  ProfileTimelineFormComponent & {
    __typename?: 'ProfileTimelineTimePeriodField';
    isAddable: Scalars['Boolean']['output'];
    isMandatory: Scalars['Boolean']['output'];
    name: ProfileTimelineFieldName;
    /** Time period stored for the field */
    timePeriodValue?: Maybe<ProfileTimelineTimePeriodFieldValue>;
    /** The title is unused in this type since the sub-fields are labeled individually */
    title: Scalars['String']['output'];
  };

export type ProfileTimelineTimePeriodFieldInput = {
  endDate?: InputMaybe<ProfileTimelineEntryMonthYear>;
  /** Normally, either one field must be provided e.g `endDate` or `isOngoing` */
  isOngoing?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<ProfileTimelineEntryMonthYear>;
};

export type ProfileTimelineTimePeriodFieldMonthYearValue = {
  __typename?: 'ProfileTimelineTimePeriodFieldMonthYearValue';
  /** Month with January = 1 */
  month?: Maybe<Scalars['Int']['output']>;
  year: Scalars['Int']['output'];
};

export type ProfileTimelineTimePeriodFieldValue = {
  __typename?: 'ProfileTimelineTimePeriodFieldValue';
  endDate?: Maybe<ProfileTimelineTimePeriodFieldMonthYearValue>;
  startDate?: Maybe<ProfileTimelineTimePeriodFieldMonthYearValue>;
};

export type ProfileTimelineUniversityField = ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField & {
    __typename?: 'ProfileTimelineUniversityField';
    isAddable: Scalars['Boolean']['output'];
    isMandatory: Scalars['Boolean']['output'];
    name: ProfileTimelineFieldName;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
  };

export type ProfileTimelineUniversityFieldInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileTimelineVocationalSchoolField = ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent & {
    __typename?: 'ProfileTimelineVocationalSchoolField';
    isAddable: Scalars['Boolean']['output'];
    isMandatory: Scalars['Boolean']['output'];
    name: ProfileTimelineFieldName;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
  };

export type ProfileTimelineVocationalSchoolFieldInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileTimelineWebsiteField = ProfileTimelineField &
  ProfileTimelineFieldStringValue &
  ProfileTimelineFormComponent &
  ProfileTimelineLabelableField &
  ProfileTimelineMaxLengthField & {
    __typename?: 'ProfileTimelineWebsiteField';
    /** If the field should be displayed in 'Additional fields' section */
    isAddable: Scalars['Boolean']['output'];
    /** If the field is mandatory */
    isMandatory: Scalars['Boolean']['output'];
    maxLength?: Maybe<Scalars['Int']['output']>;
    /** Identifier (name) of the form field to be sent to the backend */
    name: ProfileTimelineFieldName;
    /** Value stored for the field in the simple String format */
    stringValue?: Maybe<Scalars['String']['output']>;
    /** Text to be displayed next to the field or as separator */
    title: Scalars['String']['output'];
  };

export type ProfileTimelineWebsiteFieldInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileUpdateDeleteError = {
  __typename?: 'ProfileUpdateDeleteError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ProfileUpdateDeleteInput = {
  id: Scalars['ID']['input'];
};

export type ProfileUpdateDeleteResult = {
  __typename?: 'ProfileUpdateDeleteResult';
  error?: Maybe<ProfileUpdateDeleteError>;
  success?: Maybe<ProfileUpdateDeleteSuccess>;
};

export type ProfileUpdateDeleteSuccess = {
  __typename?: 'ProfileUpdateDeleteSuccess';
  message?: Maybe<Scalars['String']['output']>;
};

export type ProfileUpdatePhoto = {
  __typename?: 'ProfileUpdatePhoto';
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  profile?: Maybe<XingId>;
  urn: Scalars['GlobalID']['output'];
};

export type ProfileUpdateStatus = {
  __typename?: 'ProfileUpdateStatus';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  statusText: Scalars['String']['output'];
  text: Scalars['String']['output'];
  urn: Scalars['GlobalID']['output'];
};

/** Settings related to the visbility of the logged-out profile of a user. */
export type ProfileVisibilitySetting = HasGlobalId & {
  __typename?: 'ProfileVisibilitySetting';
  /** Globally unique id for this instance */
  globalId: Scalars['GlobalID']['output'];
  /** Whether the user wishes that his/her profile is available logged-out */
  isLoggedOutVisible: Scalars['Boolean']['output'];
  /** Whether the user wishes for search-engines to index his/her profile */
  isSearchEngineIndexable: Scalars['Boolean']['output'];
};

export type ProfileVisibilitySettingInput = {
  /** Whether the user wishes that his/her profile is available logged-out */
  isLoggedOutVisible: Scalars['Boolean']['input'];
  /** Whether the user wishes for search-engines to index his/her profile */
  isSearchEngineIndexable: Scalars['Boolean']['input'];
};

/**
 * Represents a visit of a profile.
 * Contains information about the user, reason and time of the visit
 */
export type ProfileVisit = {
  __typename?: 'ProfileVisit';
  /**
   * #rest(batched) #virtual
   *
   *  Distance between the user and the profile visitor
   */
  contactDistance?: Maybe<ContactDistance>;
  /** ID of the profile visit. */
  id: Scalars['ID']['output'];
  /** New VOMP message which contains click reason text resources and/or prioritized commonality text resource */
  label?: Maybe<Scalars['String']['output']>;
  /**
   * #rest(batched) #virtual
   *
   *  Relationship between the user and the profile visitor
   */
  networkRelationship?: Maybe<NetworkRelationship>;
  /** Indicates if visit is new or not */
  newVisit?: Maybe<Scalars['Boolean']['output']>;
  /** Number of times the visitor visited the profile. */
  numberOfVisits?: Maybe<Scalars['Int']['output']>;
  /** Additional payload of a profile visit containing information about recruiter status, company name and job title */
  payload: ProfileVisitPayload;
  /**
   * #rest(batched) #virtual
   *
   *  Fenced XingId representation of the profile visitor
   */
  profileVisitor?: Maybe<ProfileVisitor>;
  /**
   * Click reason (translation language complies with the first two letters of an Accept-Language request header,
   *     which in Rails/Rack is internally represented as HTTP_ACCEPT_LANGUAGE and must be one of the valid languages
   *     of a XING platform, falling back to German otherwise)
   */
  reasonHtml?: Maybe<Scalars['String']['output']>;
  /**
   * #rest(batched) #virtual
   *
   *  SharedContacts the contacts that the user shares with this profile visitor
   */
  sharedContacts?: Maybe<SharedContacts>;
  /** ISO 8601 Timestamp when the profile was visited. */
  viewedAt?: Maybe<Scalars['Date']['output']>;
  /**
   * #rest(batched) #virtual
   *
   *  XingId representation of the profile visitor
   * @deprecated deprecated in favor of profileVisitor.
   */
  visitor?: Maybe<XingId>;
  /** ID of the user who visited the profile. 0 for logged-out visits. */
  visitorId?: Maybe<Scalars['ID']['output']>;
};

/** New VOMP error response */
export type ProfileVisitErrorResponse = {
  __typename?: 'ProfileVisitErrorResponse';
  /** #logged-out #soft-logged-in */
  created?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

/** Input parameters for new VOMP */
export type ProfileVisitInput = {
  clickReasonCode?: InputMaybe<Scalars['String']['input']>;
  platform: VompPlatformEnum;
  referer?: InputMaybe<Scalars['String']['input']>;
  remoteIp?: InputMaybe<Scalars['String']['input']>;
  visiteePageName: Scalars['String']['input'];
};

/** Wrapper object for additional payload to a visitor */
export type ProfileVisitPayload = {
  __typename?: 'ProfileVisitPayload';
  visitor?: Maybe<ProfileVisitorPayload>;
};

/** New VOMP success response */
export type ProfileVisitSuccessResponse = {
  __typename?: 'ProfileVisitSuccessResponse';
  /** #logged-out #soft-logged-in */
  created?: Maybe<Scalars['Boolean']['output']>;
};

export type ProfileVisitor = FencedProfileVisitor | XingId;

/**
 * Additional Payload to a Profile Visit.
 * It contains information about the company name, job title and if a user is a recruiter.
 */
export type ProfileVisitorPayload = {
  __typename?: 'ProfileVisitorPayload';
  /** Name of the company the profile visitor is currently working at */
  companyName?: Maybe<Scalars['String']['output']>;
  /** Whether the user is a recruiter */
  isRecruiter?: Maybe<Scalars['Boolean']['output']>;
  /** Job title of the profile visitor */
  jobTitle?: Maybe<Scalars['String']['output']>;
};

export type ProfileVisitorsConnection = HasCursorPagination & {
  __typename?: 'ProfileVisitorsConnection';
  edges: Array<ProfileVisitorsEdge>;
  /** #virtual */
  pageInfo: PageInfo;
  /**
   * #virtual
   *
   *  Total number of visits
   */
  total: Scalars['Int']['output'];
};

export type ProfileVisitorsEdge = PaginationEdge & {
  __typename?: 'ProfileVisitorsEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: ProfileVisit;
};

export type ProfileWants = {
  __typename?: 'ProfileWants';
  /** #logged-out #soft-logged-in */
  wants?: Maybe<Scalars['String']['output']>;
};

export type ProfileWants2 = HasGlobalId & {
  __typename?: 'ProfileWants2';
  /** #soft-logged-in #logged-out */
  globalId: Scalars['GlobalID']['output'];
  /** #soft-logged-in #logged-out */
  wants?: Maybe<Array<Scalars['String']['output']>>;
};

/** Profile module that allow users to see/add wants */
export type ProfileWantsModule = ModuleInterface & {
  __typename?: 'ProfileWantsModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  content?: Maybe<ProfileWants2>;
  /** #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  recommendations?: Maybe<ProfileWantsModuleRecommendations>;
  /** #soft-logged-in */
  title: Scalars['String']['output'];
};

export type ProfileWantsModuleRecommendation = {
  __typename?: 'ProfileWantsModuleRecommendation';
  label: Scalars['String']['output'];
};

export type ProfileWantsModuleRecommendations = {
  __typename?: 'ProfileWantsModuleRecommendations';
  collection?: Maybe<Array<ProfileWantsModuleRecommendation>>;
  total: Scalars['Int']['output'];
};

export type ProfileWantsUpdate = PeopleStoryGeneratorProfileUpdate & {
  __typename?: 'ProfileWantsUpdate';
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  newWants: Array<Scalars['String']['output']>;
  previousWants?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  profileId?: Maybe<Scalars['ID']['output']>;
  urn: Scalars['GlobalID']['output'];
  wants: Array<Scalars['String']['output']>;
};

export type ProfileWantsUpdateError = {
  __typename?: 'ProfileWantsUpdateError';
  description?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<ProfileWantsUpdateFieldsError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ProfileWantsUpdateFieldsError = {
  __typename?: 'ProfileWantsUpdateFieldsError';
  wants?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProfileWantsUpdateInput = {
  userId: Scalars['ID']['input'];
  /** Wants of a user, like ['want-1', 'want-2']  */
  wants: Array<Scalars['String']['input']>;
};

export type ProfileWantsUpdateResult = ProfileWants2 | ProfileWantsUpdateError;

export type ProfileWorkExperience = {
  __typename?: 'ProfileWorkExperience';
  /** #logged-out #soft-logged-in */
  beginDate?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  careerLevel?: Maybe<CareerLevel>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  company?: Maybe<Company>;
  /** #logged-out #soft-logged-in */
  companyName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  companyNotes?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  companyUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  currentCompany?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  discipline?: Maybe<Discipline>;
  /** #logged-out #soft-logged-in */
  duration?: Maybe<ProfileWorkExperienceDuration>;
  /** #logged-out #soft-logged-in */
  endDate?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  localizedTimeString?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  normalizedName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  orgTypeId?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  primaryJob?: Maybe<Scalars['Boolean']['output']>;
  /** #rest(batched) #virtual */
  workExperienceStatus?: Maybe<WorkExperienceStatus>;
};

export type ProfileWorkExperienceCollection = {
  __typename?: 'ProfileWorkExperienceCollection';
  /** #logged-out #soft-logged-in */
  collection?: Maybe<Array<Maybe<ProfileWorkExperience>>>;
};

export type ProfileWorkExperienceDuration = {
  __typename?: 'ProfileWorkExperienceDuration';
  /** #logged-out #soft-logged-in */
  months?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  years?: Maybe<Scalars['Int']['output']>;
};

export type ProfileWorkExperienceUpdate = PeopleStoryGeneratorProfileUpdate & {
  __typename?: 'ProfileWorkExperienceUpdate';
  beginMonth?: Maybe<Scalars['Int']['output']>;
  beginYear?: Maybe<Scalars['Int']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  endMonth?: Maybe<Scalars['Int']['output']>;
  endYear?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  previousBeginMonth?: Maybe<Scalars['Int']['output']>;
  previousBeginYear?: Maybe<Scalars['Int']['output']>;
  previousCompanyName?: Maybe<Scalars['String']['output']>;
  previousEndMonth?: Maybe<Scalars['Int']['output']>;
  previousEndYear?: Maybe<Scalars['Int']['output']>;
  previousJobTitle?: Maybe<Scalars['String']['output']>;
  previousWorkExperienceId?: Maybe<Scalars['ID']['output']>;
  profileId?: Maybe<Scalars['ID']['output']>;
  urn: Scalars['GlobalID']['output'];
  workExperienceId?: Maybe<Scalars['ID']['output']>;
};

export type ProfileYearAndMonth = {
  __typename?: 'ProfileYearAndMonth';
  month?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type ProfilesB2bClientIdPayload = {
  __typename?: 'ProfilesB2bClientIdPayload';
  value?: Maybe<Scalars['ID']['output']>;
};

/** Settings related to logged-out profiles and logged-out content */
export type ProfilesLoggedoutSettings = {
  __typename?: 'ProfilesLoggedoutSettings';
  /** Setting regarding the visibility of postings/comments the user creates */
  arePostsLoggedOutVisible?: Maybe<ToggleSetting>;
  /** Settings regarding the visibility of the profile itself */
  profileVisibility?: Maybe<ProfileVisibilitySetting>;
};

export type ProfilesLoggedoutSettingsInput = {
  /** Setting regarding the visibility of postings/comments the user creates */
  arePostsLoggedOutVisible?: InputMaybe<ToggleSettingInput>;
  /** Settings regarding the visibility of the profile itself */
  profileVisibility?: InputMaybe<ProfileVisibilitySettingInput>;
};

export type ProfilesLoggedoutSettingsMutationResult = {
  __typename?: 'ProfilesLoggedoutSettingsMutationResult';
  error?: Maybe<ProfilesLoggedoutSettings>;
  response?: Maybe<ProfilesLoggedoutSettings>;
};

export type ProjobsAddAllowlistUserError = ProjobsAllowlistError & {
  __typename?: 'ProjobsAddAllowlistUserError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsAddAllowlistUserInput = {
  userIds: Array<Scalars['ID']['input']>;
};

/** Result type for adding a user to the allowlist that returns 204 on success */
export type ProjobsAddAllowlistUserResult =
  | ProjobsAddAllowlistUserError
  | ProjobsAddAllowlistUserSuccess
  | ProjobsUserAlreadyBlockedError;

export type ProjobsAddAllowlistUserSuccess = {
  __typename?: 'ProjobsAddAllowlistUserSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsAddBlockedCompaniesError = ProjobsBlockedCompaniesError & {
  __typename?: 'ProjobsAddBlockedCompaniesError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsAddBlockedCompaniesInput = {
  companyIds: Array<Scalars['ID']['input']>;
};

/** Result type for adding companies to the blocked_companies that returns 204 on success */
export type ProjobsAddBlockedCompaniesResult =
  | ProjobsAddBlockedCompaniesError
  | ProjobsAddBlockedCompaniesSuccess;

export type ProjobsAddBlockedCompaniesSuccess = {
  __typename?: 'ProjobsAddBlockedCompaniesSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsAddBlocklistUserError = ProjobsBlocklistError & {
  __typename?: 'ProjobsAddBlocklistUserError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsAddBlocklistUserInput = {
  userIds: Array<Scalars['ID']['input']>;
};

/** Result type for adding a user to the blocklist that returns 204 on success */
export type ProjobsAddBlocklistUserResult =
  | ProjobsAddBlocklistUserError
  | ProjobsAddBlocklistUserSuccess
  | ProjobsUserAlreadyAllowedError;

export type ProjobsAddBlocklistUserSuccess = {
  __typename?: 'ProjobsAddBlocklistUserSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsAddIdealEmployersError = {
  __typename?: 'ProjobsAddIdealEmployersError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsAddIdealEmployersInput = {
  idealEmployerIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

/** Result type for adding a company to target companies that returns 204 on success */
export type ProjobsAddIdealEmployersResult =
  | ProjobsAddIdealEmployersError
  | ProjobsAddIdealEmployersSuccess;

export type ProjobsAddIdealEmployersSuccess = {
  __typename?: 'ProjobsAddIdealEmployersSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsAllowlistError = {
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsAvoidError = {
  __typename?: 'ProjobsAvoidError';
  /** contains all errors that occurred */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsAvoidInput = {
  avoid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Result type for updating the avoid */
export type ProjobsAvoidResult = ProjobsAvoidError | ProjobsAvoidSuccess;

export type ProjobsAvoidSuccess = {
  __typename?: 'ProjobsAvoidSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsBlockedCompaniesError = {
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsBlockedCompanyError = {
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsBlocklistError = {
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsCitiesError = {
  __typename?: 'ProjobsCitiesError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsCitiesInput = {
  cityIds: Array<InputMaybe<Scalars['ID']['input']>>;
  willingnessToMove: Scalars['Boolean']['input'];
};

/** Result type for updating the descipline */
export type ProjobsCitiesResult = ProjobsCitiesError | ProjobsCitiesSuccess;

export type ProjobsCitiesSuccess = {
  __typename?: 'ProjobsCitiesSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export const enum ProjobsConsumer {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

/** Projobs Contact Options Type */
export type ProjobsContactOptions = {
  __typename?: 'ProjobsContactOptions';
  emailAddress?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phonePreferences: PhonePreferences;
  viaEmail: Scalars['Boolean']['output'];
  viaPhone: Scalars['Boolean']['output'];
  viaXingMessage: Scalars['Boolean']['output'];
};

export type ProjobsContactOptionsError = {
  __typename?: 'ProjobsContactOptionsError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsContactOptionsInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phonePreference?: InputMaybe<PhonePreferencesOptions>;
  phonePreferenceSpecificTime?: InputMaybe<ProjobsPhonePreferenceSpecificTime>;
  viaEmail: Scalars['Boolean']['input'];
  viaPhone: Scalars['Boolean']['input'];
  viaXingMessage: Scalars['Boolean']['input'];
};

/** Result type for projobs contact options update that returns 204 on success" */
export type ProjobsContactOptionsResult =
  | ProjobsContactOptionsError
  | ProjobsContactOptionsSuccess;

export type ProjobsContactOptionsSuccess = {
  __typename?: 'ProjobsContactOptionsSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsDeleteDocumentError = {
  __typename?: 'ProjobsDeleteDocumentError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsDeleteDocumentInput = {
  documentId: Scalars['ID']['input'];
};

/** Result type for deleting a document from user that returns 204 on success */
export type ProjobsDeleteDocumentResult =
  | ProjobsDeleteDocumentError
  | ProjobsDeleteDocumentSuccess;

export type ProjobsDeleteDocumentSuccess = {
  __typename?: 'ProjobsDeleteDocumentSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsDisciplineError = {
  __typename?: 'ProjobsDisciplineError';
  /** contains all errors that occurred */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsDisciplineInput = {
  discipline?: InputMaybe<Scalars['ID']['input']>;
};

/** Result type for updating the discipline */
export type ProjobsDisciplineResult =
  | ProjobsDisciplineError
  | ProjobsDisciplineSuccess;

export type ProjobsDisciplineSuccess = {
  __typename?: 'ProjobsDisciplineSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsDocument = {
  __typename?: 'ProjobsDocument';
  format: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  size: Scalars['String']['output'];
  title: Scalars['String']['output'];
  uploadDate: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Projobs Documents Type */
export type ProjobsDocuments = {
  __typename?: 'ProjobsDocuments';
  collection?: Maybe<Array<Maybe<ProjobsDocument>>>;
};

export type ProjobsIndustriesError = {
  __typename?: 'ProjobsIndustriesError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsIndustriesInput = {
  industries: Array<InputMaybe<Scalars['ID']['input']>>;
  openToOtherIndustries: Scalars['Boolean']['input'];
};

/** Result type for updating the industry */
export type ProjobsIndustriesResult =
  | ProjobsIndustriesError
  | ProjobsIndustriesSuccess;

export type ProjobsIndustriesSuccess = {
  __typename?: 'ProjobsIndustriesSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsPhonePreferenceSpecificTime = {
  days: PhonePreferenceDays;
  timeFrom: Scalars['ID']['input'];
  timeUntil: Scalars['ID']['input'];
};

export type ProjobsPositionsError = {
  __typename?: 'ProjobsPositionsError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsPositionsInput = {
  positions: Array<InputMaybe<Scalars['String']['input']>>;
};

/** Result type for updating the positions */
export type ProjobsPositionsResult =
  | ProjobsPositionsError
  | ProjobsPositionsSuccess;

export type ProjobsPositionsSuccess = {
  __typename?: 'ProjobsPositionsSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsProfileSearchQueryInput = {
  consumer: ProjobsConsumer;
  keywords: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type ProjobsProfileSearchResult = {
  __typename?: 'ProjobsProfileSearchResult';
  position?: Maybe<Scalars['Int']['output']>;
  /** #rest(batched) #virtual */
  profile?: Maybe<XingId>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type ProjobsProfileSearchResults = {
  __typename?: 'ProjobsProfileSearchResults';
  collection: Array<ProjobsProfileSearchResult>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ProjobsRecruiterInsights = {
  __typename?: 'ProjobsRecruiterInsights';
  /** The number of times the user was marked as candidate by recruiters */
  markedAsCandidate?: Maybe<Scalars['Int']['output']>;
};

export type ProjobsRemoveAllowlistUserError = {
  __typename?: 'ProjobsRemoveAllowlistUserError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsRemoveAllowlistUserInput = {
  allowlistUserId: Scalars['ID']['input'];
};

/** Result type for removing a user to the allowlist that returns 204 on success */
export type ProjobsRemoveAllowlistUserResult =
  | ProjobsRemoveAllowlistUserError
  | ProjobsRemoveAllowlistUserSuccess;

export type ProjobsRemoveAllowlistUserSuccess = {
  __typename?: 'ProjobsRemoveAllowlistUserSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsRemoveBlockedCompanyError = ProjobsBlockedCompanyError & {
  __typename?: 'ProjobsRemoveBlockedCompanyError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsRemoveBlockedCompanyInput = {
  blockedCompanyId: Scalars['ID']['input'];
};

/** Result type for removing company from the blocked_companies that returns 204 on success */
export type ProjobsRemoveBlockedCompanyResult =
  | ProjobsRemoveBlockedCompanyError
  | ProjobsRemoveBlockedCompanySuccess;

export type ProjobsRemoveBlockedCompanySuccess = {
  __typename?: 'ProjobsRemoveBlockedCompanySuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsRemoveBlocklistUserError = {
  __typename?: 'ProjobsRemoveBlocklistUserError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsRemoveBlocklistUserInput = {
  blocklistUserId: Scalars['ID']['input'];
};

/** Result type for removing a user to the blocklist that returns 204 on success */
export type ProjobsRemoveBlocklistUserResult =
  | ProjobsRemoveBlocklistUserError
  | ProjobsRemoveBlocklistUserSuccess;

export type ProjobsRemoveBlocklistUserSuccess = {
  __typename?: 'ProjobsRemoveBlocklistUserSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsSalaryExpectationError = {
  __typename?: 'ProjobsSalaryExpectationError';
  /** contains all errors that occurred */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsSalaryExpectationInput = {
  salaryExpectations?: InputMaybe<Scalars['Int']['input']>;
};

/** Result type for updating the salary expectation */
export type ProjobsSalaryExpectationResult =
  | ProjobsSalaryExpectationError
  | ProjobsSalaryExpectationSuccess;

export type ProjobsSalaryExpectationSuccess = {
  __typename?: 'ProjobsSalaryExpectationSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsSeekingStatusError = {
  __typename?: 'ProjobsSeekingStatusError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsSeekingStatusInput = {
  seekingStatus: SeekingStatus;
};

/** "Result type for projobs seeking status update that returns 204 on success" */
export type ProjobsSeekingStatusResult =
  | ProjobsSeekingStatusError
  | ProjobsSeekingStatusSuccess;

export type ProjobsSeekingStatusSuccess = {
  __typename?: 'ProjobsSeekingStatusSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

/** Projobs Settings Type */
export type ProjobsSettings = {
  __typename?: 'ProjobsSettings';
  /** #rest(batched) #virtual */
  allowlist?: Maybe<Array<Maybe<XingId>>>;
  /** #rest(batched) #virtual */
  blockedCompanies?: Maybe<Array<Maybe<Company>>>;
  /** #rest(batched) #virtual */
  blocklist?: Maybe<Array<Maybe<XingId>>>;
  seekingStatus: SeekingStatus;
  visibility: VisibilitySetting;
};

export type ProjobsSettingsOther = {
  __typename?: 'ProjobsSettingsOther';
  seekingStatus: SeekingStatus;
  visibility: VisibilitySetting;
};

export type ProjobsUpdateDocumentError = {
  __typename?: 'ProjobsUpdateDocumentError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsUpdateDocumentInput = {
  documentId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

/** Result type for updating document title */
export type ProjobsUpdateDocumentResult =
  | ProjobsUpdateDocumentError
  | ProjobsUpdateDocumentSuccess;

export type ProjobsUpdateDocumentSuccess = {
  __typename?: 'ProjobsUpdateDocumentSuccess';
  format: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  size: Scalars['String']['output'];
  title: Scalars['String']['output'];
  uploadDate: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ProjobsUploadDocumentError = {
  __typename?: 'ProjobsUploadDocumentError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsUploadDocumentInput = {
  filename: Scalars['String']['input'];
  title: Scalars['String']['input'];
  /** this upload ID is provided by the uploadRequest mutation */
  uploadId: Scalars['String']['input'];
};

/** Result type for uploading documents that can return a ProjobsDocument or an error */
export type ProjobsUploadDocumentResult =
  | ProjobsDocument
  | ProjobsUploadDocumentError;

/** Projobs Upsell Banner */
export type ProjobsUpsellBannerModule = ModuleInterface & {
  __typename?: 'ProjobsUpsellBannerModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  globalId: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type ProjobsUserAlreadyAllowedError = ProjobsBlocklistError & {
  __typename?: 'ProjobsUserAlreadyAllowedError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsUserAlreadyBlockedError = ProjobsAllowlistError & {
  __typename?: 'ProjobsUserAlreadyBlockedError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsVisibilityError = {
  __typename?: 'ProjobsVisibilityError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsVisibilityInput = {
  visibleToColleagues?: InputMaybe<Scalars['Boolean']['input']>;
  visibleToContacts?: InputMaybe<Scalars['Boolean']['input']>;
  visibleToHeadhunters?: InputMaybe<Scalars['Boolean']['input']>;
  visibleToOthers?: InputMaybe<Scalars['Boolean']['input']>;
  visibleToRecruiters?: InputMaybe<Scalars['Boolean']['input']>;
};

/** "Result for projobs visibility settings update that returns 204 on success" */
export type ProjobsVisibilityResult = {
  __typename?: 'ProjobsVisibilityResult';
  error?: Maybe<ProjobsVisibilityError>;
  success?: Maybe<ProjobsVisibilitySuccess>;
};

export type ProjobsVisibilitySuccess = {
  __typename?: 'ProjobsVisibilitySuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProjobsWillingnessToTravelError = {
  __typename?: 'ProjobsWillingnessToTravelError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsWillingnessToTravelInput = {
  willingnessToTravel: JobSeekerWillingnessToTravel;
};

/** Result type for updating willingness to travel that returns 204 on success */
export type ProjobsWillingnessToTravelResult =
  | ProjobsWillingnessToTravelError
  | ProjobsWillingnessToTravelSuccess;

export type ProjobsWillingnessToTravelSuccess = {
  __typename?: 'ProjobsWillingnessToTravelSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

/** Projobs Wishes Type */
export type ProjobsWishes = {
  __typename?: 'ProjobsWishes';
  avoid?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #rest(batched) #virtual */
  cities: Array<Maybe<City>>;
  /** #rest(batched) #virtual */
  discipline?: Maybe<Discipline>;
  /** #rest(batched) #virtual */
  idealEmployers: Array<Maybe<Company>>;
  /** #rest(batched) #virtual */
  industries: Array<Maybe<Industry>>;
  openToOtherIndustries: Scalars['Boolean']['output'];
  positions: Array<Maybe<Scalars['String']['output']>>;
  salaryExpectations?: Maybe<Scalars['Int']['output']>;
  willingnessToMove: Scalars['Boolean']['output'];
  willingnessToTravel?: Maybe<JobSeekerWillingnessToTravel>;
  workingHours?: Maybe<JobSeekerWorkingHours>;
};

export type ProjobsWorkingHoursError = {
  __typename?: 'ProjobsWorkingHoursError';
  /** contains all errors that occured */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjobsWorkingHoursInput = {
  fullTime: Scalars['Boolean']['input'];
  partTime: Scalars['Boolean']['input'];
};

/** Result type for updating the working hours */
export type ProjobsWorkingHoursResult =
  | ProjobsWorkingHoursError
  | ProjobsWorkingHoursSuccess;

export type ProjobsWorkingHoursSuccess = {
  __typename?: 'ProjobsWorkingHoursSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type Province = Localizable & {
  __typename?: 'Province';
  /** #logged-out #soft-logged-in */
  canonicalName: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  countryCode: CountryCodesAlpha2;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export type PublisherAboutUsFacts = {
  __typename?: 'PublisherAboutUsFacts';
  /** #logged-out #soft-logged-in */
  foundingYear?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  imprint?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  properties?: Maybe<AboutUsFactsProperties>;
  /** #logged-out #soft-logged-in */
  websiteUrl?: Maybe<Scalars['URL']['output']>;
};

export const enum PurchasableProduct {
  Premium = 'PREMIUM',
  Projobs = 'PROJOBS',
}

export type PurchaseAbTest = {
  __typename?: 'PurchaseAbTest';
  /** #soft-logged-in */
  abTestGroup: Scalars['String']['output'];
  /** #soft-logged-in */
  isBasic: Scalars['Boolean']['output'];
  /** #soft-logged-in */
  shouldRedirectToUpsell: Scalars['Boolean']['output'];
};

export type PurchaseAdminWebContract = PurchaseContract & {
  __typename?: 'PurchaseAdminWebContract';
  featureSet: PurchaseFeatureSet;
  id: Scalars['ID']['output'];
  initialRuntime: PurchaseRuntime;
  name: Scalars['String']['output'];
  platform: PurchasePlatform;
  renewalRuntimes: Array<Maybe<PurchaseRuntime>>;
};

export type PurchaseBundle = {
  __typename?: 'PurchaseBundle';
  bonusMonths?: Maybe<Scalars['Int']['output']>;
  contracts?: Maybe<Array<Maybe<PurchaseContract>>>;
  id: Scalars['ID']['output'];
};

export type PurchaseBundleInput = {
  bonusMonths?: InputMaybe<Scalars['Int']['input']>;
  contractIds: Array<Scalars['ID']['input']>;
};

export type PurchaseCampaign = {
  __typename?: 'PurchaseCampaign';
  bundles?: Maybe<Array<Maybe<PurchaseBundle>>>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  platform?: Maybe<PurchasePlatform>;
  targetGroup?: Maybe<PurchaseTargetGroup>;
  template?: Maybe<PurchaseTemplate>;
};

export type PurchaseCampaignGroup = {
  __typename?: 'PurchaseCampaignGroup';
  campaigns?: Maybe<PurchaseCampaignPlatforms>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  endsAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permanent: Scalars['Boolean']['output'];
  priority: Scalars['Int']['output'];
  startsAt: Scalars['Date']['output'];
};

export type PurchaseCampaignPlatform = {
  __typename?: 'PurchaseCampaignPlatform';
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type PurchaseCampaignPlatforms = {
  __typename?: 'PurchaseCampaignPlatforms';
  android?: Maybe<PurchaseCampaignPlatform>;
  ios?: Maybe<PurchaseCampaignPlatform>;
  web?: Maybe<PurchaseCampaignPlatform>;
};

export type PurchaseCommonTarget = {
  __typename?: 'PurchaseCommonTarget';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Fields needed to show the purchase contracsts */
export type PurchaseContract = {
  featureSet: PurchaseFeatureSet;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  platform: PurchasePlatform;
};

export type PurchaseCreateCampaignGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  permanent?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  startsAt: Scalars['Date']['input'];
};

export type PurchaseCreateCampaignGroupResult = {
  __typename?: 'PurchaseCreateCampaignGroupResult';
  error?: Maybe<PurchaseErrorResponse>;
  success?: Maybe<PurchaseCreateSuccessResponse>;
};

export type PurchaseCreateCampaignInput = {
  bundles: Array<PurchaseBundleInput>;
  campaignGroupId: Scalars['ID']['input'];
  platform: PurchasePlatform;
  recommended: Scalars['Int']['input'];
  targetGroup: PurchaseTargetGroupInput;
};

export type PurchaseCreateCampaignResult = {
  __typename?: 'PurchaseCreateCampaignResult';
  error?: Maybe<PurchaseErrorResponse>;
  success?: Maybe<PurchaseCreateSuccessResponse>;
};

export type PurchaseCreateSuccessResponse = {
  __typename?: 'PurchaseCreateSuccessResponse';
  id: Scalars['ID']['output'];
};

export type PurchaseDeleteCampaignGroupInput = {
  id: Scalars['ID']['input'];
};

export type PurchaseDeleteCampaignGroupResult = {
  __typename?: 'PurchaseDeleteCampaignGroupResult';
  error?: Maybe<PurchaseErrorResponse>;
};

export type PurchaseEnablePlatformInput = {
  enable: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type PurchaseEnablePlatformResult = {
  __typename?: 'PurchaseEnablePlatformResult';
  error?: Maybe<PurchaseErrorResponse>;
  success?: Maybe<PurchaseCampaignPlatform>;
};

export type PurchaseErrorResponse = {
  __typename?: 'PurchaseErrorResponse';
  errors?: Maybe<Scalars['String']['output']>;
};

/** Fields needed to show the feature set in the Apps */
export type PurchaseFeatureSet = {
  __typename?: 'PurchaseFeatureSet';
  billingCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PurchaseFeaturesTarget = {
  __typename?: 'PurchaseFeaturesTarget';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type PurchaseFeaturesTargetInput = {
  key: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};

export type PurchaseLegacyMobileOffer = {
  __typename?: 'PurchaseLegacyMobileOffer';
  collection?: Maybe<Array<Maybe<MobileOfferProduct>>>;
};

export type PurchaseMobileContract = PurchaseContract & {
  __typename?: 'PurchaseMobileContract';
  appStoreCode: Scalars['String']['output'];
  featureSet: PurchaseFeatureSet;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  platform: PurchasePlatform;
};

/** The mobile Platforms available for purchase */
export const enum PurchaseMobilePlatform {
  Android = 'ANDROID',
  Ios = 'IOS',
}

export type PurchaseMobileProperties = {
  __typename?: 'PurchaseMobileProperties';
  recommended?: Maybe<Scalars['Int']['output']>;
};

export const enum PurchaseOrderType {
  Revoke = 'REVOKE',
  Upsell = 'UPSELL',
}

/** The Platform the user wants to make a purchase from */
export const enum PurchasePlatform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

/** Fields needed to show the mobile offers in the Apps */
export const enum PurchaseProduct {
  Premium = 'PREMIUM',
  Projobs = 'PROJOBS',
}

export type PurchasePublishCampaignInput = {
  enable: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type PurchasePublishCampaignResult = {
  __typename?: 'PurchasePublishCampaignResult';
  error?: Maybe<PurchaseErrorResponse>;
  success?: Maybe<PurchaseCampaignGroup>;
};

export type PurchaseRestoreCampaignGroup = {
  id: Scalars['ID']['input'];
};

export type PurchaseRestoreCampaignGroupResult = {
  __typename?: 'PurchaseRestoreCampaignGroupResult';
  error?: Maybe<PurchaseErrorResponse>;
};

export type PurchaseRuntime = {
  __typename?: 'PurchaseRuntime';
  duration: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  priceCh: Scalars['String']['output'];
  priceEu: Scalars['String']['output'];
  priceUs: Scalars['String']['output'];
};

export type PurchaseTargetGroup = {
  __typename?: 'PurchaseTargetGroup';
  ciProperties?: Maybe<Array<Maybe<PurchaseCommonTarget>>>;
  features?: Maybe<Array<Maybe<PurchaseFeaturesTarget>>>;
  id: Scalars['ID']['output'];
  urlParameters?: Maybe<Array<Maybe<PurchaseCommonTarget>>>;
};

export type PurchaseTargetGroupInput = {
  ciProperties?: InputMaybe<Array<InputMaybe<PurchaseTargetInput>>>;
  features?: InputMaybe<Array<InputMaybe<PurchaseFeaturesTargetInput>>>;
  urlParameters?: InputMaybe<Array<InputMaybe<PurchaseTargetInput>>>;
};

export type PurchaseTargetInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type PurchaseTemplate = {
  __typename?: 'PurchaseTemplate';
  configIdentifier: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  properties?: Maybe<PurchaseMobileProperties>;
};

export type PurchaseUpdateCampaignGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  permanent?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  startsAt?: InputMaybe<Scalars['Date']['input']>;
};

export type PurchaseUpdateCampaignGroupResult = {
  __typename?: 'PurchaseUpdateCampaignGroupResult';
  error?: Maybe<PurchaseErrorResponse>;
  success?: Maybe<PurchaseUpdateSuccessResponse>;
};

export type PurchaseUpdateCampaignInput = {
  bundles: Array<PurchaseBundleInput>;
  id: Scalars['ID']['input'];
  recommended: Scalars['Int']['input'];
  targetGroup: PurchaseTargetGroupInput;
};

export type PurchaseUpdateCampaignResult = {
  __typename?: 'PurchaseUpdateCampaignResult';
  error?: Maybe<PurchaseErrorResponse>;
  success?: Maybe<PurchaseUpdateSuccessResponse>;
};

export type PurchaseUpdateSuccessResponse = {
  __typename?: 'PurchaseUpdateSuccessResponse';
  id: Scalars['ID']['output'];
};

export type PurchaseUpltBoneyard = {
  __typename?: 'PurchaseUpltBoneyard';
  /** #soft-logged-in */
  enabled: Scalars['Boolean']['output'];
  /** #soft-logged-in */
  type: Scalars['String']['output'];
};

export type PurchaseWebCampaignOffer = {
  __typename?: 'PurchaseWebCampaignOffer';
  /** #soft-logged-in #logged-out */
  campaignIdentifier: Scalars['String']['output'];
  /** #soft-logged-in #logged-out */
  campaignType: PurchaseWebCampaignType;
  /** #soft-logged-in #logged-out */
  contracts: Array<PurchaseWebContract>;
  /** #soft-logged-in #logged-out */
  endsAt?: Maybe<Scalars['Date']['output']>;
  /** #soft-logged-in #logged-out */
  id: Scalars['ID']['output'];
  /** #soft-logged-in #logged-out */
  startsAt: Scalars['Date']['output'];
  /** #soft-logged-in #logged-out */
  textValues: PurchaseWebTextValues;
};

export const enum PurchaseWebCampaignType {
  Default = 'DEFAULT',
  Onlinemarketing = 'ONLINEMARKETING',
  Revoke = 'REVOKE',
  Special = 'SPECIAL',
  Student = 'STUDENT',
  Trigger = 'TRIGGER',
  Winback = 'WINBACK',
}

export type PurchaseWebContract = {
  __typename?: 'PurchaseWebContract';
  /** #soft-logged-in #logged-out */
  campaignIdentifier: Scalars['String']['output'];
  /** #soft-logged-in #logged-out */
  duration: Scalars['Int']['output'];
  /** #soft-logged-in #logged-out */
  featureSet: Scalars['String']['output'];
  /** #soft-logged-in #logged-out */
  id: Scalars['ID']['output'];
  /** #soft-logged-in #logged-out */
  initialDuration: Scalars['Int']['output'];
  /** #soft-logged-in #logged-out */
  orderType: PurchaseOrderType;
  /** #soft-logged-in #logged-out */
  price: PurchaseWebPrice;
  /** #soft-logged-in #logged-out */
  renewalDuration: Scalars['Int']['output'];
};

export const enum PurchaseWebCurrency {
  Chf = 'CHF',
  Eur = 'EUR',
  Usd = 'USD',
}

export type PurchaseWebOrder = {
  __typename?: 'PurchaseWebOrder';
  /** #soft-logged-in */
  error?: Maybe<PurchaseWebOrderError>;
  /** #soft-logged-in */
  success?: Maybe<PurchaseWebOrderSuccess>;
};

export type PurchaseWebOrderError = {
  __typename?: 'PurchaseWebOrderError';
  /** #soft-logged-in */
  message: Scalars['String']['output'];
};

export type PurchaseWebOrderSuccess = {
  __typename?: 'PurchaseWebOrderSuccess';
  /** #soft-logged-in */
  contract: PurchaseWebContract;
  /** #soft-logged-in */
  purchaseId: Scalars['String']['output'];
};

export type PurchaseWebPrice = {
  __typename?: 'PurchaseWebPrice';
  /** #soft-logged-in #logged-out */
  currency: PurchaseWebCurrency;
  /** #soft-logged-in #logged-out */
  current?: Maybe<Scalars['Int']['output']>;
  /** #soft-logged-in #logged-out */
  initial: Scalars['Int']['output'];
  /** #soft-logged-in #logged-out */
  renewal: Scalars['Int']['output'];
};

export type PurchaseWebTextValues = {
  __typename?: 'PurchaseWebTextValues';
  /** #soft-logged-in #logged-out */
  benefits: Array<Scalars['String']['output']>;
  /** #soft-logged-in #logged-out */
  heading: Scalars['String']['output'];
  /** #soft-logged-in #logged-out */
  legalNotice: Scalars['String']['output'];
  /** #soft-logged-in #logged-out */
  subheading: Scalars['String']['output'];
};

/** Qualified VOMP */
export type QualifiedVomp = {
  __typename?: 'QualifiedVomp';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  vompType: VompTypeEnum;
};

/** Qualified VOMP's visitee */
export type QualifiedVompVisitee = {
  __typename?: 'QualifiedVompVisitee';
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isBasic: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
};

/** Qualified VOMP's visitor */
export type QualifiedVompVisitor = {
  __typename?: 'QualifiedVompVisitor';
  /** #rest(batched) #virtual */
  contactDistance?: Maybe<ContactDistance>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isRecruiter: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  profileVisitor?: Maybe<QualifiedVompVisitorType>;
};

export type QualifiedVompVisitorType = FencedQualifiedVompVisitor | XingId;

/** The root query type */
export type Query = {
  __typename?: 'Query';
  /**
   * #rest #virtual
   *
   *  List accomplishments of the given user
   */
  accomplishmentsGet?: Maybe<AccomplishmentsCollection>;
  /**
   * #rest #virtual
   *
   *  This query returns a winning ad for an adId without running an auction for preview purposes only.
   */
  adAuctionWinnerAdPreview?: Maybe<WinningAd>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This query triggers Auction of an Ads for a user and returns collections of most profitable Ads to be displayed.
   */
  adAuctionWinners?: Maybe<AdAuctionWinners>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get all EntityPage's modules. When querying for disabled modules, only users with correct permissions might see those.
   */
  allEntityPageModules?: Maybe<EntityPageModules>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   *
   *  Status of the job application service.
   */
  applyServiceSetting?: Maybe<ApplyServiceSettings>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Article by globalId
   */
  articleByGlobalId?: Maybe<ArticlesArticle>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Article by id
   */
  articlesArticle?: Maybe<ArticlesArticle>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Autocomplete jobrole names via AWS
   */
  autocompletionAwsX1ProfileJobRole?: Maybe<AutocompletionProfileJobRoleSuggestions>;
  /**
   * #rest #virtual
   *
   *  Autocomplete company names
   *
   * ```
   * query AutocompletionCompanyName {
   *   autocompletionCompanyName(consumer: "test", text: "XING AG") {
   *     total
   *     service
   *     collection {
   *       suggestion
   *       thumbnail
   *       logo
   *       trackingToken
   *       position
   *     }
   *   }
   * }
   * ```
   */
  autocompletionCompanyName?: Maybe<AutocompletionCompanyNameSuggestions>;
  /**
   * #rest #virtual
   *
   *  Autocomplete jobs skill names
   *
   * ```
   * query AutocompletionJobsSkill {
   *   autocompletionJobsSkill(consumer: "test", text: "Java") {
   *     total
   *     service
   *     collection {
   *       suggestion
   *       trackingToken
   *       position
   *     }
   *   }
   * }
   * ```
   */
  autocompletionJobsSkill?: Maybe<AutocompletionJobsSkillSuggestions>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Autocomplete locations city names
   *
   * ```
   * query AutocompletionLocationsCity {
   *   autocompletionLocationsCity(consumer: "test", text: "Hamburg", language: "de", countries: ["DE"]) {
   *     total
   *     service
   *     collection {
   *       name
   *       trackingToken
   *       position
   *       cityId
   *       countryCode
   *     }
   *   }
   * }
   * ```
   */
  autocompletionLocationsCity?: Maybe<AutocompletionLocationsCitySuggestions>;
  /**
   * #rest #virtual
   *
   *  Autocomplete contacts
   *
   * ```
   * query AutocompletionProfileContact {
   *   autocompletionProfileProfile(consumer: "test", text: "Gregor Gysi") {
   *     total
   *     service
   *     collection {
   *       suggestion
   *       trackingToken
   *       position
   *     }
   *   }
   * }
   * ```
   */
  autocompletionProfileContact?: Maybe<AutocompletionProfileProfileSuggestions>;
  /**
   * #rest #virtual
   *
   *  Autocomplete field of study names
   *
   * ```
   * query AutocompletionProfileFieldOfStudy {
   *   autocompletionProfileFieldOfStudy(consumer: "test", text: "Xilografia") {
   *     total
   *     service
   *     collection {
   *       suggestion
   *       trackingToken
   *       position
   *     }
   *   }
   * }
   * ```
   */
  autocompletionProfileFieldOfStudy?: Maybe<AutocompletionProfileFieldOfStudySuggestions>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Autocomplete have names
   *
   * ```
   * query AutocompletionProfileHave {
   *   autocompletionProfileHave(consumer: "test", text: "Xilografia") {
   *     total
   *     service
   *     collection {
   *       suggestion
   *       trackingToken
   *       position
   *     }
   *   }
   * }
   * ```
   */
  autocompletionProfileHave?: Maybe<AutocompletionProfileHaveSuggestions>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Autocomplete jobrole names
   *
   * ```
   * query AutocompletionProfileJobRole {
   *   autocompletionProfileJobRole(consumer: "test", text: "XPS-Entwicklerin") {
   *     total
   *     service
   *     collection {
   *       suggestion
   *       trackingToken
   *       position
   *     }
   *   }
   * }
   * ```
   */
  autocompletionProfileJobRole?: Maybe<AutocompletionProfileJobRoleSuggestions>;
  /**
   * #rest #virtual
   *
   *  Autocomplete profiles
   *
   * ```
   * query AutocompletionProfileProfile {
   *   autocompletionProfileProfile(consumer: "test", text: "Gregor Gysi") {
   *     total
   *     service
   *     collection {
   *       suggestion
   *       trackingToken
   *       position
   *     }
   *   }
   * }
   * ```
   */
  autocompletionProfileProfile?: Maybe<AutocompletionProfileProfileSuggestions>;
  /**
   * #rest #virtual
   *
   *  Autocomplete university names
   *
   * ```
   * query AutocompletionProfileUniversity {
   *   autocompletionProfileUniversity(consumer: "test", text: "Lu Xun Academy of Fine") {
   *     total
   *     service
   *     collection {
   *       suggestion
   *       trackingToken
   *       position
   *     }
   *   }
   * }
   * ```
   */
  autocompletionProfileUniversity?: Maybe<AutocompletionProfileUniversitySuggestions>;
  /**
   * #rest #virtual
   *
   *  Get an array of disciplines ids for a specific user id
   */
  blockedCompanies?: Maybe<BlockedCompanies>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Career Hub Glossary
   */
  careerHubGlossary?: Maybe<CareerHubGlossaryResponse>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Career Hub Topic page / Evergreen article page
   */
  careerHubTopicPage?: Maybe<CareerHubTopicPageResponse>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Career Hub Topics
   */
  careerHubTopics?: Maybe<CareerHubTopicsResponse>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get the career level preference for a specific user/visitor id
   */
  careerLevelPreference?: Maybe<CareerLevelPreference>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get all career levels
   */
  careerLevels?: Maybe<Array<CareerLevel>>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   *
   *  Get details of a list of Cities
   */
  cities?: Maybe<Array<Maybe<City>>>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   *
   *  Get details of a City
   */
  city?: Maybe<City>;
  /**
   * Community topics
   * @deprecated Groups are going to be shutdown at the end of 2022. This field will stop working on 01/01/2023.
   */
  communityTopics?: Maybe<CommunityTopics>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   *
   *  Companies by ids
   */
  companies?: Maybe<Array<Maybe<Company>>>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Companies grouped by industry
   */
  companiesByIndustry?: Maybe<CompaniesByIndustryCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Companies grouped by industry using slug
   */
  companiesByIndustryWithSlug?: Maybe<CompaniesByIndustryCollection>;
  /**
   * #rest #virtual
   *
   *  Get company by tags
   */
  companiesByTag?: Maybe<CompanyCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Number of Companies grouped by Industries
   */
  companiesIndustries?: Maybe<CompaniesIndustriesCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Companies most recently claimed
   */
  companiesRecentlyClaimed?: Maybe<CompanyClaimedCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Number of Companies grouped by segments
   */
  companiesSegments?: Maybe<CompaniesSegmentsCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Number of Companies grouped by segments using slug
   */
  companiesSegmentsWithSlug?: Maybe<CompaniesSegmentsCollection>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   *
   *  Company by id
   */
  company?: Maybe<Company>;
  /**
   * #rest #virtual
   *
   *  Get company affiliaton categories
   */
  companyAffiliationCategories?: Maybe<CompanyAffiliationCategories>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  List of Company Articles
   */
  companyArticles?: Maybe<CompaniesArticlesCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get company default logos
   */
  companyDefaultLogos?: Maybe<CompanyDefaultLogos>;
  /**
   * #soft-logged-in #rest(batched) #virtual
   *
   *  Get all company employees number by id
   */
  companyEmployeesNumber?: Maybe<CompanyEmployeesNumber>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Get all company employees numbers
   */
  companyEmployeesNumbers?: Maybe<Array<Maybe<CompanyEmployeesNumber>>>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Fetch the list of reasons for a complaints report
   */
  complaintsReasons?: Maybe<Array<ComplaintReason>>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Fetch a complaint report
   */
  complaintsReport?: Maybe<FetchComplaintsReportResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Fetch a complaint report
   */
  complaintsReportLoggedOut?: Maybe<FetchComplaintsReportResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Fetch a review of a report
   */
  complaintsReviewOfReport?: Maybe<ReviewResponse>;
  /**
   * #rest #virtual
   *
   *  Content categories
   */
  contentCategories?: Maybe<ContentCategoryCollection>;
  /**
   * #preview
   *
   *  List of available content channels
   */
  contentChannels?: Maybe<ContentChannelCollection>;
  /**
   * #paginated #rest #virtual
   *
   *  Get comments for commentable
   */
  contentComments?: Maybe<ContentCommentsConnection>;
  /**
   * #paginated #rest #virtual
   *
   *  Get a list of followed pages
   */
  contentFollowedPages?: Maybe<ContentPagesConnection>;
  /**
   * #paginated #rest #virtual
   *
   *  Insider articles
   */
  contentInsiderArticles?: Maybe<ContentArticlesConnection>;
  /**
   * #rest #virtual
   *
   *  Insider featured article
   */
  contentInsiderFeaturedArticles?: Maybe<ContentArticlesResponse>;
  /**
   * #rest #virtual
   *
   *  Get insider page info
   */
  contentInsiderPage?: Maybe<ContentInsiderPage>;
  /**
   * #paginated #rest #virtual
   *
   *  Get followers for your own page
   */
  contentInsiderPageFollowers?: Maybe<ContentInsiderPageFollowersConnection>;
  /**
   * #paginated #rest #virtual
   *
   *  Get common insider followers
   */
  contentInsiderPageFollowersWithinContacts?: Maybe<ContentInsiderPageFollowersWithinContactsConnection>;
  /**
   * #paginated #rest #virtual
   *
   *  Get common page followers
   */
  contentPageFollowersWithinContacts?: Maybe<ContentPageFollowersWithinContactsConnection>;
  /**
   * #paginated #logged-out #soft-logged-in #rest #virtual
   *
   *  Get a list of pages by type, ordered by title
   */
  contentPagesByType?: Maybe<ContentPagesConnection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get recommendeded pages grouped by sources
   */
  contentPagesRecommended?: Maybe<ContentPagesRecommendedCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get content by URN
   */
  contentServiceContentByGlobalId?: Maybe<ContentServiceContent>;
  /**
   * #paginated #rest #virtual
   *
   *  Get insider page infos
   */
  contentStarableStarrers?: Maybe<ContentStarableStarrersConnection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get all countries
   */
  countries?: Maybe<Array<Country>>;
  /**
   * #rest #virtual
   *
   *  Customer
   */
  customer?: Maybe<Customer>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get a parsed cv for a given id
   */
  cvUploadParsedCv?: Maybe<CvUploadParsedCv>;
  /**
   * #rest #virtual
   *
   *  Get a parsed cv for a given user ID
   */
  cvUploadParsedCvByUserId?: Maybe<CvUploadParsedCv>;
  /**
   * #rest #virtual
   *
   *  Query for requesting deletion reasons
   */
  deletionReasons?: Maybe<Array<Maybe<DeletionReasons>>>;
  demoServerRenderedData?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get all disciplines
   */
  disciplines?: Maybe<Array<Discipline>>;
  /**
   * #rest #virtual
   *
   *  Get an array of disciplines ids for a specific user id
   */
  disciplinesPreference?: Maybe<DisciplinesPreference>;
  /**
   * #rest #virtual
   *
   *  Fetch dreamJobs recommendations
   */
  dreamJobsFetch?: Maybe<DreamJobsCollection>;
  /**
   * #rest #virtual
   *
   *  Get Order Company Details
   */
  ecommCompanyDetails?: Maybe<EcommCompanyDetails>;
  /**
   * #rest #virtual
   *
   *  Get Order Contact Details
   */
  ecommContactDetails?: Maybe<EcommContactDetails>;
  /**
   * #rest #virtual
   *
   *  Get Ecomm Order
   */
  ecommOrder?: Maybe<EcommOrder>;
  /**
   * #rest #virtual
   *
   *  Get Order Payment Status
   */
  ecommPaymentStatus?: Maybe<EcommPaymentStatus>;
  /**
   * #rest #virtual
   *
   *  Get Previous Client
   */
  ecommPreviousClient?: Maybe<EcommPreviousClient>;
  /**
   * #rest #virtual
   *
   *  Get Ecomm Product By Id
   */
  ecommProduct?: Maybe<EcommProduct>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get All Ecomm Products
   */
  ecommProducts?: Maybe<Array<EcommProduct>>;
  /**
   * #rest #virtual
   *
   *  Get User Connections
   */
  ecommUserConnectionsData?: Maybe<UserConnectionData>;
  /**
   * #rest #virtual
   *
   *  Get an array of company ids representing employer preferences for a specific user id
   */
  employersPreference?: Maybe<EmployersPreference>;
  /**
   * #rest #virtual
   *
   *  EntityCustomer by urns
   */
  entityCustomerByUrns?: Maybe<EntityCustomers>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get the contact users associated with an entity page
   */
  entityPageContactUsers?: Maybe<EntityPageContactUsers>;
  /**
   * #logged-out #soft-logged-in
   *
   *  DEPRECATED: Get the contacts associated with an entity page
   * @deprecated Since the split with locations, this query became more complex than necessary. Please use the entityPageContactUsers instead. Checked on 23.02.2023 - still used a bit by Web and old Mobile vertions
   */
  entityPageContacts?: Maybe<EntityPageContacts>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  News Feeds for Entity Pages
   */
  entityPageContentFeeds?: Maybe<EntityPageContentFeedCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Fetch an entity page by ID or Slug, with support for error handling and redirection
   */
  entityPageEX?: Maybe<EntityPageResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get the locations associated with an entity page
   */
  entityPageLocations?: Maybe<EntityPageLocations>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get a single entity page module and return the EntityPageGenericModule
   * @deprecated Deprecated in favour of entityPageShowModule, which uses the EntityPageModules type as the response Checked on 22.02.2023: still used on Web.
   */
  entityPageModule?: Maybe<EntityPageGenericModule>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get a single entity page module
   */
  entityPageShowModule?: Maybe<EntityPageModule>;
  /**
   * #rest #virtual
   *
   *  Fetch a single Xing Sourcing Service (XSS) briefing by id
   */
  erecruitingSourcingServiceBriefing?: Maybe<ErecruitingSourcingServiceBriefingResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a list of candidates for a briefing id
   */
  erecruitingSourcingServiceBriefingCandidateList?: Maybe<ErecruitingSourcingServiceBriefingCandidateListResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a list of candidates for a user id
   */
  erecruitingSourcingServiceBriefingCandidateListByUser?: Maybe<ErecruitingSourcingServiceBriefingCandidateListResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a list of Xing Sourcing Service (XSS) briefings by rid
   */
  erecruitingSourcingServiceBriefingList?: Maybe<ErecruitingSourcingServiceBriefingListResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a single Xing Talent Service (XTS) briefing metadata by briefing id
   */
  erecruitingSourcingServiceBriefingMetadata?: Maybe<ErecruitingSourcingServiceBriefingMetadataResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a single XTS briefing report by id
   */
  erecruitingSourcingServiceBriefingReport?: Maybe<ErecruitingSourcingServiceBriefingReportResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a XTS report collection given a briefing
   */
  erecruitingSourcingServiceBriefingReportsCollection?: Maybe<ErecruitingSourcingServiceBriefingReportsCollectionResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a single CandidateNote resource by candidateId
   */
  erecruitingSourcingServiceCandidateNote?: Maybe<ErecruitingSourcingServiceCandidateNoteResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a single CandidateNote resource by userId
   */
  erecruitingSourcingServiceCandidateNoteByUserId?: Maybe<ErecruitingSourcingServiceCandidateNoteResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a list of CandidateNotes for a user id
   */
  erecruitingSourcingServiceCandidateNoteList?: Maybe<ErecruitingSourcingServiceCandidateNoteListResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a list of candidate recommendations
   */
  erecruitingSourcingServiceCandidateRecommendations?: Maybe<ErecruitingSourcingServiceCandidateRecommendationsResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch an array of ordered candidate ids
   */
  erecruitingSourcingServiceCandidateRecommendationsOrder?: Maybe<ErecruitingSourcingServiceCandidateRecommendationsOrderResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch the Message Reminder metadata for a briefing
   */
  erecruitingSourcingServiceMessageReminderMetadata?: Maybe<ErecruitingSourcingServiceMessageReminderMetadataResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a list of Message Reminder for a consultant ID
   */
  erecruitingSourcingServiceMessageRemindersList?: Maybe<ErecruitingSourcingServiceMessageRemindersListResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch the collection of NoticePeriod
   */
  erecruitingSourcingServiceNoticePeriods?: Maybe<ErecruitingSourcingServiceNoticePeriodsListResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch the collection of SalaryExpectations
   */
  erecruitingSourcingServiceSalaryExpectations?: Maybe<ErecruitingSourcingServiceSalaryExpectationsListResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch the collection of WillingnessToTravel
   */
  erecruitingSourcingServiceWillingnessToTravel?: Maybe<ErecruitingSourcingServiceWillingnessToTravelListResponse>;
  /**
   * #rest #virtual
   *
   *  Fetch a single Xing Talent Manager (XTM) project by id
   */
  erecruitingSourcingServiceXTMProject?: Maybe<ErecruitingSourcingServiceXtmProjectResponse>;
  /** XTM project related data for a briefing, proxied by XTS back-end (see XTS-1426 to know more). */
  erecruitingSourcingServiceXTMReportDetails?: Maybe<ErecruitingSourcingServiceXtmReportDetails>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get XING Fabi Assistant suggestions for a job search query
   */
  fabiJobSearch?: Maybe<FabiJobSearchResponse>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Generate job search queries based on user profile
   */
  fabiJobSearchQueryGeneration?: Maybe<FabiJobSearchQueryGenerationResponse>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  List of feature switches experiments for logged out uuid.
   */
  featureSwitchesLoggedOutExperiments?: Maybe<FeatureSwitchesExperimentsCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Fetch a list of followers
   */
  followersFollowingLimitOffset?: Maybe<FollowersFollowingCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Job Application External Documents by Job Id
   */
  formExternalDocument?: Maybe<FormExternalDocuments>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Job Application Form Settings by Job Id
   */
  formSetting?: Maybe<FormSettings>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Returns a list of hero articles for the Logged Out Insights page
   */
  getInsightsHeroArticles?: Maybe<InsightsArticlesCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Returns a list of job search articles for the Logged Out Insights page
   */
  getInsightsJobSearchArticles?: Maybe<InsightsArticlesCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Returns a list of salary articles for the Logged Out Insights page
   */
  getInsightsSalaryArticles?: Maybe<InsightsArticlesCollection>;
  /**
   * #rest #virtual
   *
   *  Identifies a city by city name, region, country, etc. Used for two different situations - if only city name/query and
   * country are provided then a query to an internal database is attempted - if more details are provided (street...)
   * then the query goes to google maps api once a call is made to google, the city/country from the response are matched
   * back to our internal database
   *
   * ```
   * query identifyCity {
   *     identifiedCity(
   *         input: {
   *             language: "en",
   *             cityName: "Madrid (Madrid - Madrid), Spain",
   *             country: "ES"
   *         },
   *         consumer: "test"
   *     ) {
   *         id, name
   *     }
   * }
   * ```
   */
  identifiedCity?: Maybe<Array<Maybe<City>>>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get all industries
   */
  industries?: Maybe<Array<Industry>>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   *
   *  Get industries by ids
   */
  industriesByIds?: Maybe<Array<Maybe<Industry>>>;
  /**
   * #rest #virtual
   *
   *  Get the industries preference for a specific user id
   */
  industriesPreference?: Maybe<IndustriesPreference>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   *
   *  Get industry by id
   */
  industryById?: Maybe<Industry>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get details of a Job by slug
   */
  job?: Maybe<JobResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Job Application Form by ID - All JobApplicationForms are deprecated in favour of FormExternalDocuments
   * @deprecated Field no longer supported
   */
  jobApplicationForm?: Maybe<JobApplicationForm>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   *
   *  Get details of a Job by id
   */
  jobById?: Maybe<JobResult>;
  /**
   * #rest #virtual
   *
   *  Get the job preference user flags for a specific user id
   */
  jobPreferenceUserFlags?: Maybe<JobPreferenceUserFlags>;
  /**
   * #rest #virtual
   *
   *  Get an list of the job preferences data states for a specific user id
   * @deprecated Use jobPreferencesDataStateInfo instead
   */
  jobPreferencesDataState?: Maybe<JobPreferencesDataState>;
  /**
   * #rest #virtual
   *
   *  Get the job preferences data states info for a specific user id
   */
  jobPreferencesDataStateInfo?: Maybe<JobPreferencesDataStateInfo>;
  /**
   * #rest #virtual
   *
   *  Get the job seeker status for a specific user id
   */
  jobPreferencesJobSeekerStatus?: Maybe<JobPreferencesJobSeekerStatus>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Returns the total num of results and the associated search query given a GUID
   * NOTE: Please, bear in mind that the resolver used by this query doesn't
   * use the httpGetBatched directive due to technical needs.
   */
  jobSearch?: Maybe<JobSearchResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Returns the job results based on a query body
   */
  jobSearchByQuery?: Maybe<JobSearchResult>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   *
   *  Getting Job search query from job_searches endpoint
   */
  jobSearchQuery?: Maybe<JobSearchQuery>;
  /**
   * #rest #virtual
   *
   *  Get an array of suggested related job titles from an array of current job titles
   */
  jobTitleSuggestionsPreference?: Maybe<JobTitleSuggestionsPreference>;
  /**
   * #rest #virtual
   *
   *  Get an array of job titles(target positions) and unpreferred positions for a specific user id
   */
  jobTitlesPreference?: Maybe<JobTitlesPreference>;
  /**
   * #rest #virtual
   *
   *  Get preferences wishes for a specific user id
   */
  jobWishesPreference?: Maybe<JobWishesPreference>;
  /**
   * #rest #virtual
   *
   *  Get Job
   */
  jobsB2bJob?: Maybe<JobsB2bJobResult>;
  /**
   * #rest #virtual
   *
   *  Get Job Metrics Details
   */
  jobsB2bJobMetricsDetails?: Maybe<JobsB2bJobMetricsResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This query returns a salary estimate related to a job role based on a job role input.
   */
  jobsLinkBoxJobRoleSalaryEstimate?: Maybe<SalaryEstimate>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This query returns a list of job roles related to a job role based on an seo job role name input.
   */
  jobsLinkBoxJobRolesRelatedJobRoles?: Maybe<Array<Maybe<JobsLinkBoxJobRole>>>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This query returns a list of skills related to a job role based on an seo job role name input.
   */
  jobsLinkBoxJobRolesRelatedSkills?: Maybe<Array<Maybe<JobsLinkBoxSkill>>>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This query returns a list of top locations for a given skill.
   */
  jobsLinkBoxSkillTopLocations?: Maybe<Array<Maybe<JobsLinkBoxLocation>>>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This query returns a list of job roles related to a skill based on an seo skill name input.
   */
  jobsLinkBoxSkillsRelatedJobRoles?: Maybe<Array<Maybe<JobsLinkBoxJobRole>>>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This query returns a list of top locations.
   */
  jobsLinkBoxTopLocations?: Maybe<Array<Maybe<JobsLinkBoxLocation>>>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This query returns a list of nearby top locations based on an seo city name input.
   */
  jobsLinkBoxTopLocationsNearby?: Maybe<Array<Maybe<JobsLinkBoxLocation>>>;
  /**
   * #rest #virtual
   *
   *  LeadAdForm by id
   */
  leadAdForm?: Maybe<LeadAdForm>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Fetch the legal page content by slug.
   */
  legalPageContent?: Maybe<LegalPageContent>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Fetch all public legal pages
   */
  legalPages?: Maybe<LegalPages>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Returns a lists of skills and competencies extracted from a job ad
   */
  llmGatewayCvLessApplyQuestions?: Maybe<LlmGatewayGenerateCvLessApplyQuestionsResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get the locations preference for a specific user/visitor id
   */
  locationsPreference?: Maybe<LocationsPreference>;
  /**
   * #rest #virtual
   *
   *  Fetch security issues of a particular user
   */
  loginUserSecurityIssues?: Maybe<LoginUserSecurityIssues>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  List of most visited Companies
   */
  mostVisitedCompanies?: Maybe<MostVisitedCompaniesCollection>;
  /**
   * #rest #virtual
   *
   *  Get article content and social interaction information
   */
  moveonArticles?: Maybe<MoveonArticlesResult>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Direct connections in network for logged out users.
   */
  networkDirectConnections?: Maybe<DirectConnectionsCollection>;
  /**
   * #logged-out #soft-logged-in
   *
   *  List of top recruiters in the network.
   */
  networkTopRecruiters?: Maybe<TopRecruitersCollection>;
  /**
   * #rest #virtual
   *
   *  Resolve matching ontological entities
   *
   * ```
   * query ResolveProfileHaves {
   *   ontologyResolveEntities(
   *     input: $input
   *   ) {
   *     total
   *     collection {
   *       references {
   *         entityMatches {
   *           entityId
   *           entityType
   *           referencedLabel
   *           score
   *           labels {
   *             primary
   *             alternative
   *             matchingOnly
   *           }
   *           links
   *           }
   *         }
   *       }
   *     }
   *   }
   * }
   * ```
   *
   * ```
   * {
   *   "input": [
   *     {
   *     "text": "java",
   *     "xingType": "PROFILE",
   *     "xingField": "HAVES",
   *     "labels": "none",
   *     "labelLanguages": [],
   *     "entityLimit": 1,
   *     "externalLinks": []
   *     },
   *   ]
   * }
   * ```
   */
  ontologyResolveEntities?: Maybe<
    Array<Maybe<OntologyResolvedEntitiesReferenceCollection>>
  >;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get organization user details
   */
  organizationUser?: Maybe<AtsCredentials>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  AboutUs information for Entity Pages
   */
  pagesAboutUs?: Maybe<AboutEntityResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  AboutUs Facts
   */
  pagesAboutUsFacts?: Maybe<AboutUsFactsResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  AboutUs media for Entity Pages
   */
  pagesAboutUsMedia?: Maybe<EntityMediaResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  AboutUs media for Entity Pages
   */
  pagesAboutUsMediaItem?: Maybe<EntityMediaItemResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Documents for Entity Pages
   */
  pagesDocuments?: Maybe<EntityDocumentResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting data for people directory child page for a given country, city, letter, level and page
   */
  peopleByCityChunkPage?: Maybe<PeopleByCityChunkPage>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting data for people directory top level page for a given letter, city and country
   */
  peopleByCityTopLevelPage?: Maybe<PeopleByCityTopLevelPage>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting data for people directory's people by city user bag page for a given slug, city and country
   */
  peopleByCityUserBagPage?: Maybe<PeopleByCityUserBagPage>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting data for people directory child page for a given letter, level and page
   */
  peopleChunkPage?: Maybe<PeopleChunkPage>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting data for people directory city page for a given city and country
   */
  peopleCityPage?: Maybe<PeopleCityPage>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting data for people directory cities top level
   * page(/people/cities).
   */
  peopleCityTopLevelPage?: Maybe<PeopleCitiesTopLevelPage>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting data for people directory startpage.
   */
  peopleStartPage?: Maybe<PeopleStartPage>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting data for people directory top level page for a given letter
   */
  peopleTopLevelPage?: Maybe<PeopleTopLevelPage>;
  /**
   * #paginated #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting user bags(same name(first & last) profiles) for a user
   */
  peopleUserBag?: Maybe<PeopleUserBagConnection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting data for people directory user bag page for a given slug and page
   */
  peopleUserBagPage?: Maybe<PeopleUserBagPage>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get all phone preference times
   */
  phonePreferenceTimes?: Maybe<Array<PhonePreferenceTime>>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Field used for monitoring purposes. (SRE probe)
   */
  ping?: Maybe<Scalars['String']['output']>;
  /** Logged-in only field used for monitoring purposes. (SRE probe) */
  pingLoggedInOnly?: Maybe<Scalars['String']['output']>;
  /**
   * #rest #virtual
   *
   *  Get an actor's importers
   */
  postingsImporters?: Maybe<PostingsImporters>;
  /**
   * #paginated #logged-out #soft-logged-in #rest #virtual
   *
   *  Provides paginated PostingsPosting along with metadata for a given targetGlobalId
   */
  postingsPostingsForTarget?: Maybe<PostingsPaginatedPostingConnection>;
  /**
   * #rest #virtual
   *
   *  Retrieve the current user preferences
   */
  preferenceSettings?: Maybe<PreferencesSettings>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting about_me of a profile
   * Arguments:
   * 1) userId: Id of a user.
   */
  profileAboutMe?: Maybe<ProfileAboutMe>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  List of contacts of a profile (checked for visibility for current user)
   */
  profileContacts?: Maybe<ProfileContacts>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Count of contacts of a profile
   */
  profileContactsCount?: Maybe<ContactsCount>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for directly requesting the Education of a profile.
   * Arguments:
   * 1) profileId: Profile Id of a user.
   * 2) fenced: Optional argument for loggedOut resolver, by default Loggedout(LO) api responds with unfenced data.
   *            Fencing is a SEO/LO concept where we perform randomisation and re-strict visibility of data with the help of login ctas.
   *            Should be provided as true if you're interested in fenced data.
   */
  profileEducation?: Maybe<ProfileEducationCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting hiring profile of a profile
   */
  profileHiringProfile?: Maybe<HiringProfile>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting interests of a profile
   * Arguments:
   * 1) profileId: Profile Id of a user.
   * 2) fenced: Optional argument for loggedOut resolver, by default Loggedout(LO) api responds with unfenced data.
   *            Fencing is a SEO/LO concept where we perform randomisation and re-strict visibility of data with the help of login ctas.
   *            Should be provided as true if you're interested in fenced data.
   */
  profileInterests?: Maybe<ProfileInterests>;
  /**
   * #rest #virtual
   *
   *  Profile Interests Autocomplete Suggestions
   */
  profileInterestsAutocompleteSuggestions?: Maybe<ProfileInterestsAutocompleteSuggestions>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting LanguageSkills of a profile
   */
  profileLanguageSkills?: Maybe<ProfileLanguageCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for directly requesting the legal information of a profile
   */
  profileLegalInformation?: Maybe<ProfileLegalInformation>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Ordered list of available modules on Profiles platform for loggedout
   */
  profileModuleStoreItems?: Maybe<Array<Maybe<ProfileModuleStoreItem>>>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Profile modules
   */
  profileModules?: Maybe<ProfileModules>;
  /**
   * #rest #virtual
   *
   *  Occupation type categories
   */
  profileOccupationTypeCategories?: Maybe<Array<ProfileOccupationTypeCategory>>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting page meta(contents for seo header tags) of a profile
   */
  profilePageMeta?: Maybe<ProfilePageMeta>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting page meta(contents for seo header tags) of a profile with more detailed http status behaviour
   */
  profilePageMetaWithError?: Maybe<ProfilePageMetaOrError>;
  /**
   * #rest #virtual
   *
   *  Query for requesting qualifications of a profile
   * Arguments:
   * 1) profileId: Profile Id of a user.
   */
  profileQualifications?: Maybe<ProfileQualificationCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This is a loggedout query to get skills directly via loggedout skills api with params slug_or_id
   * Arguments:
   * 1) userId: Id of a user.
   * 2) fenced: Optional argument for loggedOut resolver, by default Loggedout(LO) api responds with unfenced data.
   *            Fencing is a SEO/LO concept where we perform randomisation and re-strict visibility of data with the help of login ctas.
   *            Should be provided as true if you're interested in fenced data.
   */
  profileSkills?: Maybe<ProfileSkills>;
  /**
   * #rest #virtual
   *
   *  Profile Skills Autocomplete Suggestions
   */
  profileSkillsAutocompleteSuggestions?: Maybe<ProfileSkillsAutocompleteSuggestions>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for requesting
   * structured data
   * of a profile
   */
  profileStructuredData?: Maybe<ProfileStructuredData>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Tracking variables for profile
   */
  profileTrackingVariables?: Maybe<Array<Maybe<TrackingVariable>>>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Query for requesting wants of a profile
   * Arguments:
   * 1) profileId: Profile Id of a user.
   * 2) fenced: Optional argument for loggedOut resolver, by default Loggedout(LO) api responds with unfenced data.
   *            Fencing is a SEO/LO concept where we perform randomisation and re-strict visibility of data with the help of login ctas.
   *            Should be provided as true if you're interested in fenced data.
   * @deprecated The wants will be removed in favor of job-preferences: PROFILE-10484.
   */
  profileWants?: Maybe<ProfileWants>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Query for requesting wants as a collection of a profile !!Intermediate solution for transition the structure change!!
   * Arguments:
   * 1) profileId: Profile Id of a user.
   * 2) fenced: Optional argument for loggedOut resolver, by default Loggedout(LO) api responds with unfenced data.
   * Fencing is a SEO/LO concept where we perform randomisation and re-strict visibility of data with the help of login ctas.
   * Should be provided as true if you're interested in fenced data.
   * @deprecated The wants will be removed in favor of job-preferences: PROFILE-10484.
   */
  profileWantsCollection?: Maybe<ProfileWants2>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for directly requesting the WorkExperiences of a profile
   * Arguments:
   *   1) profileId: Profile Id of a user.
   *   2) fenced: Optional argument for loggedOut resolver, by default Loggedout(LO) api responds with unfenced data.
   *              Fencing is a SEO/LO concept where we perform randomisation and re-strict visibility of data with the help of login ctas.
   *              Should be provided as true if you're interested in fenced data.
   */
  profileWorkExperience?: Maybe<ProfileWorkExperienceCollection>;
  /**
   * #rest #virtual
   *
   *  Get projobs wishes for a specific user id
   * @deprecated Will be removed at the end of Q4
   */
  projobsWishesOther?: Maybe<ProjobsWishes>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Get user AB test group
   */
  purchaseAbTest?: Maybe<PurchaseAbTest>;
  /**
   * #rest #virtual
   *
   *  Fetches one campaign by id
   */
  purchaseCampaign?: Maybe<PurchaseCampaign>;
  /**
   * #rest #virtual
   *
   *  Fetches one campaign group by id
   */
  purchaseCampaignGroup?: Maybe<PurchaseCampaignGroup>;
  /**
   * #rest #virtual
   *
   *  Fetches multiple campaign groups
   */
  purchaseCampaignGroups?: Maybe<Array<Maybe<PurchaseCampaignGroup>>>;
  /**
   * #rest #virtual
   *
   *  Gets one campaign for a user by platform
   */
  purchaseCampaignOffer?: Maybe<PurchaseCampaign>;
  /**
   * #rest #virtual
   *
   *  Paginated list of all campaigns
   */
  purchaseCampaigns?: Maybe<Array<Maybe<PurchaseCampaign>>>;
  /**
   * #rest #virtual
   *
   *  Retrieves list of available contracts for a platform
   */
  purchaseContracts?: Maybe<Array<Maybe<PurchaseContract>>>;
  /**
   * #rest #virtual
   *
   *  Purchase feature sets
   */
  purchaseFeatureSets?: Maybe<Array<Maybe<PurchaseFeatureSet>>>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Get web boneyard upsell hint for a user
   */
  purchaseUpltBoneyard?: Maybe<PurchaseUpltBoneyard>;
  /**
   * #soft-logged-in #logged-out #rest #virtual
   *
   *  Get web campaign offer for a user
   */
  purchaseWebCampaignOffer?: Maybe<PurchaseWebCampaignOffer>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Get web campaign offer preview
   */
  purchaseWebCampaignOfferPreview?: Maybe<PurchaseWebCampaignOffer>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Get web contract for a user
   */
  purchaseWebContract?: Maybe<PurchaseWebContract>;
  /** Recruiter Insights and statistics data */
  recruiterInsights?: Maybe<RecruiterInsights>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get the salary expectations preference for a specific user or visitor id
   */
  salaryExpectationsPreference?: Maybe<SalaryExpectationsPreference>;
  /**
   * #rest #virtual
   *
   *  Get client data for a seat manager user
   */
  seatManagerClient?: Maybe<SeatManagerClientResponse>;
  /**
   * #rest #virtual
   *
   *  Get search results for a search query
   */
  seatManagerSearch?: Maybe<SeatManagerSearchResponse>;
  /**
   * #rest #virtual
   *
   *  Get Shadow Profile of current user
   */
  shadowProfile?: Maybe<ShadowProfile>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  This is a loggedout query to get similar profiles directly via loggedout similar profiles api
   */
  similarProfiles?: Maybe<RelatedProfileCollection>;
  /**
   * #rest #virtual
   *
   *  Fetch Skills Module Visibility to hide Haves Module in native apps
   * ```
   * query SkillsModuleVisibility {
   *   skillsModuleVisibility(userId: "1") {
   *     enabled
   *   }
   * }
   * ```
   */
  skillsModuleVisibility?: Maybe<ProfileSkillsModuleVisibility>;
  /** Get a story by id */
  storyById?: Maybe<PostingsStory>;
  /** Stories by global IDs */
  storyCollection?: Maybe<StoryCollection>;
  /**
   * #paginated #rest #virtual
   *
   *  Get a list of a story's users
   */
  storyViewerList?: Maybe<StoryViewerConnection>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Support Contact
   */
  supportContact?: Maybe<SupportContactFormTopics>;
  /**
   * #rest #virtual
   *
   *  Get an array of only the target positions for a specific user id
   */
  targetPositionsPreference?: Maybe<TargetPositionsPreference>;
  /** The team permissions that can be requested between users and resources */
  teamPermissions?: Maybe<TeamPermissionsQuery>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  List of Top Companies
   */
  topCompanies?: Maybe<TopCompaniesCollection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  List of Top Companies By City
   */
  topCompaniesByCity?: Maybe<TopCompaniesByCityCollection>;
  /**
   * #rest #virtual
   *
   *  Get an array of only unpreferred positions for a specific user id
   */
  unpreferredPositionsPreference?: Maybe<UnpreferredPositionsPreference>;
  /**
   * #rest #virtual
   *
   *  Get Android campaign offers for a user.
   */
  upsellAndroidCampaignOffer?: Maybe<UpsellAndroidCampaignOffer>;
  /**
   * #rest #virtual
   *
   *  Check user cancellation status
   */
  upsellCancellationStatus?: Maybe<UpsellCancellationStatus>;
  /**
   * #rest #virtual
   *
   *  Get iOS campaign offers for a user.
   * To make this query accessibile for older iOS versions of the Xing app,
   * we have a default value for the purchasable product
   */
  upsellIosCampaignOffer?: Maybe<UpsellIosCampaignOffer>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  User flags of the user
   */
  userFlags?: Maybe<UserFlags>;
  /**
   * #rest #virtual
   *
   *  Query is going to be deprecated in favour of Viewer.userRecentCvs
   * @deprecated We are going to use the viewer since the info shown is user related
   */
  userRecentCvs?: Maybe<Array<Maybe<UserJobApplicationCv>>>;
  /**
   * #soft-logged-in #rest(batched) #virtual
   *
   *  User flags of multiple users
   */
  usersFlags?: Maybe<Array<Maybe<UserFlags>>>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   *
   *  Fetch video details given the ref
   */
  videoV2?: Maybe<VideoV2>;
  /** #soft-logged-in #logged-out */
  viewer?: Maybe<Viewer>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Retrieving information about the cover_letter rendering status via token
   */
  vitaCoverLetterRenderingStatus?: Maybe<VitaDocumentRenderingStatusResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Retrieving cover_letter suggestion status and data
   */
  vitaCoverLetterSuggestionStatus?: Maybe<LlmGatewayGenerateCoverLetterResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Retrieving a vita document with a prepared secure token
   */
  vitaCoverLetterViaToken?: Maybe<VitaCoverLetter>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Retrieving information about the document rendering status via token
   */
  vitaDocumentRenderingTokenStatus?: Maybe<VitaDocumentRenderingStatusResult>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Retrieving a vita document with a prepared secure token
   */
  vitaDocumentViaToken?: Maybe<VitaDocument>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get all work experience statuses
   */
  workExperienceStatuses?: Maybe<Array<WorkExperienceStatus>>;
  /**
   * #rest #virtual
   *
   *  Get the working hours preference for a specific user id
   */
  workingHoursPreference?: Maybe<WorkingHoursPreference>;
  /**
   * #rest #virtual
   *
   *  Get the workplace preference for a specific user id
   */
  workplacePreference?: Maybe<WorkplacePreference>;
  /**
   * #soft-logged-in #logged-out #rest(batched) #virtual
   *
   *  XingID of selected user
   */
  xingId?: Maybe<XingId>;
  /**
   * #soft-logged-in #logged-out #rest #virtual
   *
   *  Query for getting a single Xing-ID with detailed feedback for common http status codes
   */
  xingIdWithError?: Maybe<XingIdOrError>;
  /**
   * #rest(batched) #virtual
   *
   *  Query for getting xingIds in batch
   */
  xingIds?: Maybe<Array<Maybe<XingId>>>;
  /**
   * #rest #virtual
   *
   *  Check logged in user has an active XTM seat
   * ```
   * query CandidateSearch {
   *   xtmAuthorizedUser {
   *     candidateSearch(
   *       consumer: "test"
   *       queries: {keywords:"Software"}
   *       filters:{workExperience:"1-2,2-5"}
   *       aggregations: {workExperience: 10, city: 10}
   *       limit: 10
   *     ) {
   *       total
   *       aggs {
   *         city {
   *           count city { name latitude longitude }
   *         }
   *         workExperience { count value }
   *       }
   *       collection {
   *         profile { displayName }
   *         profileAdditionalDetails {
   *           workExperience { jobTitle, duration { years months } }
   *         }
   *       }
   *     }
   *   }
   * }
   * ```
   */
  xtmAuthorizedUser?: Maybe<XtmAuthorizedUser>;
  /**
   * #rest #virtual
   *
   *  Fetch a single message templates accessible by the current user by id
   */
  xtmMessageTemplates?: Maybe<XtmMessageTemplateResponse>;
  /** TalentManager pool related data */
  xtmPool?: Maybe<XtmPool>;
  /**
   * #rest #virtual
   *
   *  TalentManager project related data
   */
  xtmProject?: Maybe<XtmProject>;
  /**
   * #rest #virtual
   *
   *  Search profiles
   *
   * ```
   * query XtmSearch {
   *   xtmSearch(consumer: "test", keywords: "Gregor Gysi", currentTitle: "Software", filterDistance: "10,10,10000") {
   *     total
   *     collection {
   *       id
   *       trackingToken
   *       position
   *     }
   *   }
   * }
   * ```
   */
  xtmSearch?: Maybe<XtmSearchResult>;
  /**
   * #rest #virtual
   *
   *  Expose XTM seats stats"
   */
  xtmSeatsStats?: Maybe<XtmSeatsStatsResponse>;
};

/** The root query type */
export type QueryAccomplishmentsGetArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryAdAuctionWinnerAdPreviewArgs = {
  adId: Scalars['Int']['input'];
};

/** The root query type */
export type QueryAdAuctionWinnersArgs = {
  auctionAttributes: AuctionAttributes;
};

/** The root query type */
export type QueryAllEntityPageModulesArgs = {
  id: Scalars['SlugOrID']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The root query type */
export type QueryApplyServiceSettingArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryArticleByGlobalIdArgs = {
  globalId: Scalars['GlobalID']['input'];
  supportedBlockTypes: Array<ArticleSupportedBlockTypes>;
};

/** The root query type */
export type QueryArticlesArticleArgs = {
  id: Scalars['SlugOrID']['input'];
  supportedBlockTypes: Array<ArticleSupportedBlockTypes>;
};

/** The root query type */
export type QueryAutocompletionAwsX1ProfileJobRoleArgs = {
  consumer: Scalars['String']['input'];
  highlight?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryAutocompletionCompanyNameArgs = {
  consumer: Scalars['String']['input'];
  excluded?: InputMaybe<Scalars['Boolean']['input']>;
  highlight?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryAutocompletionJobsSkillArgs = {
  consumer: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryAutocompletionLocationsCityArgs = {
  consumer: Scalars['String']['input'];
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  highlight?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryAutocompletionProfileContactArgs = {
  consumer: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryAutocompletionProfileFieldOfStudyArgs = {
  consumer: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryAutocompletionProfileHaveArgs = {
  consumer: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryAutocompletionProfileJobRoleArgs = {
  consumer: Scalars['String']['input'];
  highlight?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryAutocompletionProfileProfileArgs = {
  consumer: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryAutocompletionProfileUniversityArgs = {
  consumer: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryBlockedCompaniesArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryCareerHubTopicPageArgs = {
  topicKey: Scalars['String']['input'];
};

/** The root query type */
export type QueryCareerLevelPreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryCitiesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

/** The root query type */
export type QueryCityArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryCommunityTopicsArgs = {
  limit: Scalars['Int']['input'];
};

/** The root query type */
export type QueryCompaniesArgs = {
  ids: Array<Scalars['SlugOrID']['input']>;
};

/** The root query type */
export type QueryCompaniesByIndustryArgs = {
  category?: InputMaybe<Scalars['SlugOrID']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryCompaniesByIndustryWithSlugArgs = {
  category?: InputMaybe<Scalars['SlugOrID']['input']>;
  id: Scalars['SlugOrID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryCompaniesByTagArgs = {
  tags?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryCompaniesIndustriesArgs = {
  letter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<CompaniesIndustriesSortOptions>;
};

/** The root query type */
export type QueryCompaniesRecentlyClaimedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<CompaniesRecentlyClaimedOrder>;
};

/** The root query type */
export type QueryCompaniesSegmentsArgs = {
  category?: InputMaybe<Scalars['SlugOrID']['input']>;
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryCompaniesSegmentsWithSlugArgs = {
  category?: InputMaybe<Scalars['SlugOrID']['input']>;
  id: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryCompanyArgs = {
  id: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryCompanyArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryCompanyEmployeesNumberArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryComplaintsReportArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryComplaintsReportLoggedOutArgs = {
  id: Scalars['String']['input'];
};

/** The root query type */
export type QueryComplaintsReviewOfReportArgs = {
  id: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

/** The root query type */
export type QueryContentCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  commentableGlobalId: Scalars['GlobalID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryContentFollowedPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pageType?: InputMaybe<ReducedContentPageType>;
  sortField?: InputMaybe<ContentPageSortField>;
  sortOrder?: InputMaybe<ContentSortOrder>;
};

/** The root query type */
export type QueryContentInsiderArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContentArticleStatus>;
  userId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryContentInsiderFeaturedArticlesArgs = {
  userId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryContentInsiderPageArgs = {
  userId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryContentInsiderPageFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryContentInsiderPageFollowersWithinContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryContentPageFollowersWithinContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pageUrn: Scalars['GlobalID']['input'];
};

/** The root query type */
export type QueryContentPagesByTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pageType: ReducedContentPageType;
};

/** The root query type */
export type QueryContentServiceContentByGlobalIdArgs = {
  globalId: Scalars['GlobalID']['input'];
  supportedBlockTypes: Array<ArticleSupportedBlockTypes>;
};

/** The root query type */
export type QueryContentStarableStarrersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  starableGlobalId: Scalars['GlobalID']['input'];
};

/** The root query type */
export type QueryCountriesArgs = {
  filter?: InputMaybe<CountryFilter>;
};

/** The root query type */
export type QueryCustomerArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryCvUploadParsedCvArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryCvUploadParsedCvByUserIdArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryDisciplinesPreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryDreamJobsFetchArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryEcommProductArgs = {
  productId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryEmployersPreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryEntityCustomerByUrnsArgs = {
  urns: Scalars['GlobalID']['input'];
};

/** The root query type */
export type QueryEntityPageContactUsersArgs = {
  id: Scalars['SlugOrID']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryEntityPageContactsArgs = {
  id: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryEntityPageContentFeedsArgs = {
  filter?: InputMaybe<PostingPublicationState>;
  id: Scalars['ID']['input'];
  includePinned?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['String']['input']>;
  supportedObjectTypes?: Array<EntityPageSupportedContentObjectTypes>;
  until?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryEntityPageExArgs = {
  id: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryEntityPageLocationsArgs = {
  id: Scalars['SlugOrID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryEntityPageModuleArgs = {
  id: Scalars['SlugOrID']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  moduleType: Scalars['String']['input'];
};

/** The root query type */
export type QueryEntityPageShowModuleArgs = {
  id: Scalars['SlugOrID']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  moduleType: Scalars['String']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceBriefingArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceBriefingCandidateListArgs = {
  briefingId: Scalars['ID']['input'];
  createdAtBefore?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryErecruitingSourcingServiceBriefingCandidateListByUserArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceBriefingMetadataArgs = {
  briefingId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceBriefingReportArgs = {
  briefingId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceBriefingReportsCollectionArgs = {
  briefingId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceCandidateNoteArgs = {
  candidateId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceCandidateNoteByUserIdArgs = {
  briefingId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceCandidateNoteListArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceCandidateRecommendationsArgs = {
  xtmProjectId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceCandidateRecommendationsOrderArgs = {
  input?: InputMaybe<ErecruitingSourcingServiceCandidateRecommendationsOrderInput>;
};

/** The root query type */
export type QueryErecruitingSourcingServiceMessageReminderMetadataArgs = {
  briefingId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceMessageRemindersListArgs = {
  briefingId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceXtmProjectArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryErecruitingSourcingServiceXtmReportDetailsArgs = {
  briefingId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryFabiJobSearchArgs = {
  input: FabiJobSearchInput;
};

/** The root query type */
export type QueryFabiJobSearchQueryGenerationArgs = {
  input: FabiJobSearchQueryGenerationInput;
};

/** The root query type */
export type QueryFeatureSwitchesLoggedOutExperimentsArgs = {
  identifier: Scalars['UUID']['input'];
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  platform?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryFollowersFollowingLimitOffsetArgs = {
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  namespace: FollowersFollowingNamespace;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<FollowersFollowingOrder>;
  sort?: InputMaybe<FollowersFollowingSort>;
  type: FollowersFollowingType;
};

/** The root query type */
export type QueryFormExternalDocumentArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryFormSettingArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryIdentifiedCityArgs = {
  consumer: Scalars['String']['input'];
  input: IdentifyCityQueryInput;
};

/** The root query type */
export type QueryIndustriesByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

/** The root query type */
export type QueryIndustriesPreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryIndustryByIdArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** The root query type */
export type QueryJobArgs = {
  enableTemplateData?: InputMaybe<Scalars['Boolean']['input']>;
  slug: Scalars['String']['input'];
  templateDataFormat?: InputMaybe<TemplateDataFormat>;
};

/** The root query type */
export type QueryJobApplicationFormArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryJobByIdArgs = {
  enableTemplateData?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  templateDataFormat?: InputMaybe<TemplateDataFormat>;
};

/** The root query type */
export type QueryJobPreferenceUserFlagsArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryJobPreferencesDataStateArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryJobPreferencesDataStateInfoArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryJobPreferencesJobSeekerStatusArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryJobSearchArgs = {
  consumer: Scalars['String']['input'];
  guid: Scalars['SlugOrID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  returnAggregations?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  splitBenefit?: InputMaybe<Scalars['Boolean']['input']>;
  trackRecent?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The root query type */
export type QueryJobSearchByQueryArgs = {
  aggregationsFilter?: InputMaybe<JobSearchAggregationsFilter>;
  consumer: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query: JobSearchQueryInput;
  returnAggregations?: InputMaybe<Scalars['Boolean']['input']>;
  searchMode?: InputMaybe<SearchMode>;
  sort?: InputMaybe<Scalars['String']['input']>;
  splitBenefit?: InputMaybe<Scalars['Boolean']['input']>;
  tppBoostingVariant?: InputMaybe<Scalars['String']['input']>;
  trackRecent?: InputMaybe<Scalars['Boolean']['input']>;
  visitorId?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryJobSearchQueryArgs = {
  guid: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryJobTitleSuggestionsPreferenceArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryJobTitlesPreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryJobWishesPreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryJobsB2bJobArgs = {
  jobUrn: Scalars['URN']['input'];
};

/** The root query type */
export type QueryJobsB2bJobMetricsDetailsArgs = {
  jobUrn: Scalars['URN']['input'];
};

/** The root query type */
export type QueryJobsLinkBoxJobRoleSalaryEstimateArgs = {
  input: JobsLinkBoxJobRoleInput;
};

/** The root query type */
export type QueryJobsLinkBoxJobRolesRelatedJobRolesArgs = {
  input: JobsLinkBoxJobRoleInput;
};

/** The root query type */
export type QueryJobsLinkBoxJobRolesRelatedSkillsArgs = {
  input: JobsLinkBoxJobRoleInput;
};

/** The root query type */
export type QueryJobsLinkBoxSkillTopLocationsArgs = {
  input?: InputMaybe<JobsLinkBoxSkillInput>;
};

/** The root query type */
export type QueryJobsLinkBoxSkillsRelatedJobRolesArgs = {
  input: JobsLinkBoxSkillInput;
};

/** The root query type */
export type QueryJobsLinkBoxTopLocationsNearbyArgs = {
  input: JobsLinkBoxTopLocationsNearbyInput;
};

/** The root query type */
export type QueryLeadAdFormArgs = {
  id: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryLegalPageContentArgs = {
  groupIdentifier: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

/** The root query type */
export type QueryLlmGatewayCvLessApplyQuestionsArgs = {
  input: LlmGatewayCvLessApplyQuestionsInput;
};

/** The root query type */
export type QueryLocationsPreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryMostVisitedCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<MostVisitedCompaniesOrder>;
};

/** The root query type */
export type QueryOntologyResolveEntitiesArgs = {
  input: Array<OntologyResolvedEntitiesInput>;
};

/** The root query type */
export type QueryOrganizationUserArgs = {
  identifier: Scalars['String']['input'];
  organizationId: Scalars['Long']['input'];
};

/** The root query type */
export type QueryPagesAboutUsArgs = {
  id: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryPagesAboutUsFactsArgs = {
  id: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryPagesAboutUsMediaArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['SlugOrID']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryPagesAboutUsMediaItemArgs = {
  id: Scalars['ID']['input'];
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  pageId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryPagesDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['SlugOrID']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryPeopleByCityChunkPageArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  letter?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageLevel?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryPeopleByCityTopLevelPageArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  letter?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryPeopleByCityUserBagPageArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  jobtitleQuery?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryPeopleChunkPageArgs = {
  letter?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageLevel?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryPeopleCityPageArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryPeopleTopLevelPageArgs = {
  letter?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryPeopleUserBagArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['SlugOrID']['input']>;
};

/** The root query type */
export type QueryPeopleUserBagPageArgs = {
  cityQuery?: InputMaybe<Scalars['String']['input']>;
  jobtitleQuery?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryPostingsImportersArgs = {
  actorGlobalId: Scalars['GlobalID']['input'];
};

/** The root query type */
export type QueryPostingsPostingsForTargetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<PostingPublicationState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeCounts?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  separatePinned?: InputMaybe<Scalars['Boolean']['input']>;
  supportedObjectTypes: Array<PostingsAvailablePostingTypes>;
  targetGlobalId: Scalars['GlobalID']['input'];
};

/** The root query type */
export type QueryPreferenceSettingsArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryProfileAboutMeArgs = {
  userId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryProfileContactsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ContactsListOrderBy>;
  otherUserId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryProfileContactsCountArgs = {
  userId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryProfileEducationArgs = {
  fenced?: InputMaybe<Scalars['Boolean']['input']>;
  profileId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryProfileHiringProfileArgs = {
  id: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryProfileInterestsArgs = {
  fenced?: InputMaybe<Scalars['Boolean']['input']>;
  profileId?: InputMaybe<Scalars['SlugOrID']['input']>;
};

/** The root query type */
export type QueryProfileInterestsAutocompleteSuggestionsArgs = {
  consumer: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryProfileLanguageSkillsArgs = {
  profileId?: InputMaybe<Scalars['SlugOrID']['input']>;
};

/** The root query type */
export type QueryProfileLegalInformationArgs = {
  profileId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryProfileModuleStoreItemsArgs = {
  userId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryProfileModulesArgs = {
  id: Scalars['SlugOrID']['input'];
  includeDeactivatedModules?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The root query type */
export type QueryProfilePageMetaArgs = {
  profileId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryProfilePageMetaWithErrorArgs = {
  profileId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryProfileQualificationsArgs = {
  profileId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryProfileSkillsArgs = {
  fenced?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryProfileSkillsAutocompleteSuggestionsArgs = {
  consumer: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

/** The root query type */
export type QueryProfileStructuredDataArgs = {
  slug: Scalars['String']['input'];
};

/** The root query type */
export type QueryProfileTrackingVariablesArgs = {
  id: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryProfileWantsArgs = {
  fenced?: InputMaybe<Scalars['Boolean']['input']>;
  profileId?: InputMaybe<Scalars['SlugOrID']['input']>;
};

/** The root query type */
export type QueryProfileWantsCollectionArgs = {
  fenced?: InputMaybe<Scalars['Boolean']['input']>;
  profileId?: InputMaybe<Scalars['SlugOrID']['input']>;
};

/** The root query type */
export type QueryProfileWorkExperienceArgs = {
  fenced?: InputMaybe<Scalars['Boolean']['input']>;
  profileId?: InputMaybe<Scalars['SlugOrID']['input']>;
};

/** The root query type */
export type QueryProjobsWishesOtherArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryPurchaseCampaignArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryPurchaseCampaignGroupArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryPurchaseCampaignGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryPurchaseCampaignOfferArgs = {
  platform: Scalars['String']['input'];
};

/** The root query type */
export type QueryPurchaseCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryPurchaseContractsArgs = {
  platform: PurchasePlatform;
};

/** The root query type */
export type QueryPurchaseWebCampaignOfferArgs = {
  currency?: InputMaybe<PurchaseWebCurrency>;
  language?: InputMaybe<Scalars['String']['input']>;
  mid?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<ProductType>;
};

/** The root query type */
export type QueryPurchaseWebCampaignOfferPreviewArgs = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryPurchaseWebContractArgs = {
  contractId: Scalars['ID']['input'];
};

/** The root query type */
export type QuerySalaryExpectationsPreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QuerySeatManagerSearchArgs = {
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QuerySimilarProfilesArgs = {
  profileIdentifier: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QuerySkillsModuleVisibilityArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryStoryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryStoryCollectionArgs = {
  storyGlobalIds: Array<Scalars['GlobalID']['input']>;
};

/** The root query type */
export type QueryStoryViewerListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  storyGlobalId: Scalars['GlobalID']['input'];
};

/** The root query type */
export type QueryTargetPositionsPreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryTopCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryTopCompaniesByCityArgs = {
  cityId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type */
export type QueryUnpreferredPositionsPreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryUpsellAndroidCampaignOfferArgs = {
  purchasableProduct?: InputMaybe<PurchasableProduct>;
};

/** The root query type */
export type QueryUpsellCancellationStatusArgs = {
  product: UpsellProduct;
};

/** The root query type */
export type QueryUpsellIosCampaignOfferArgs = {
  purchasableProduct?: InputMaybe<PurchasableProduct>;
};

/** The root query type */
export type QueryUserFlagsArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryUsersFlagsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

/** The root query type */
export type QueryVideoV2Args = {
  ref?: InputMaybe<Scalars['SlugOrID']['input']>;
};

/** The root query type */
export type QueryVitaCoverLetterRenderingStatusArgs = {
  kind?: InputMaybe<VitaDocumentKind>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryVitaCoverLetterSuggestionStatusArgs = {
  jobId?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryVitaCoverLetterViaTokenArgs = {
  token: Scalars['String']['input'];
};

/** The root query type */
export type QueryVitaDocumentRenderingTokenStatusArgs = {
  kind?: InputMaybe<VitaDocumentKind>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type */
export type QueryVitaDocumentViaTokenArgs = {
  token: Scalars['String']['input'];
};

/** The root query type */
export type QueryWorkingHoursPreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryWorkplacePreferenceArgs = {
  userId: Scalars['ID']['input'];
};

/** The root query type */
export type QueryXingIdArgs = {
  id: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryXingIdWithErrorArgs = {
  id: Scalars['SlugOrID']['input'];
};

/** The root query type */
export type QueryXingIdsArgs = {
  ids: Array<Scalars['SlugOrID']['input']>;
};

/** The root query type */
export type QueryXtmMessageTemplatesArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type */
export type QueryXtmPoolArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** The root query type */
export type QueryXtmProjectArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** The root query type */
export type QueryXtmSearchArgs = {
  consumer: Scalars['String']['input'];
  currentTitle?: InputMaybe<Scalars['String']['input']>;
  filterDistance?: InputMaybe<Scalars['String']['input']>;
  keywords: Scalars['String']['input'];
};

/** The root query type */
export type QueryXtmSeatsStatsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
};

export const enum Range {
  NextTwoWeeks = 'NEXT_TWO_WEEKS',
  Today = 'TODAY',
}

export type ReasonKey = {
  key: Scalars['String']['input'];
};

export type RecentSearch = {
  __typename?: 'RecentSearch';
  items?: Maybe<Array<RecentSearchItem>>;
};

export type RecentSearchItem = {
  __typename?: 'RecentSearchItem';
  /** This RecentSearchItem is the information FE need to show the recent search. Text is mandatory but it's only needed one id or url */
  id?: Maybe<Scalars['String']['output']>;
  searchText: Scalars['String']['output'];
};

export type Recommendation = {
  itemId: Scalars['ID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type RecommendationContacts = {
  __typename?: 'RecommendationContacts';
  /** #rest(batched) #virtual */
  profileDetails?: Maybe<XingId>;
};

export type RecommendationContactsCollection = {
  __typename?: 'RecommendationContactsCollection';
  collection?: Maybe<Array<Maybe<RecommendationContacts>>>;
};

export type RecommenderResponse = {
  requestTrackingToken: Scalars['String']['output'];
  service: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export const enum RecruiterFilter {
  False = 'FALSE',
  True = 'TRUE',
}

export type RecruiterInsights = {
  __typename?: 'RecruiterInsights';
  /**
   * Candidate insights and talent radar statistics
   *
   * ```
   * query RecruiterInsights {
   *   recruiterInsights {
   *     insights(projectId: "5669", locationId: "2643123", jobLabel: "Manager") {
   *       ...
   *     }
   *   }
   * }
   * ```
   */
  insights: Insights;
};

export type RecruiterInsightsInsightsArgs = {
  jobLabel?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type RecruiterMessageNotification = Notification & {
  __typename?: 'RecruiterMessageNotification';
  /** #rest(batched) #virtual */
  actor?: Maybe<MessengerParticipant>;
  aggregated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interacted: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<MessengerMessage>;
  opened: Scalars['Boolean']['output'];
  publishedAt: Scalars['Date']['output'];
  seen: Scalars['Boolean']['output'];
  /** #rest(batched) #virtual */
  target?: Maybe<MessengerChat>;
  trackingToken?: Maybe<Scalars['String']['output']>;
  urn: Scalars['URN']['output'];
};

export type RecruiterMessageSignal = ConversationStarter & {
  __typename?: 'RecruiterMessageSignal';
  /** #rest(batched) #virtual */
  actor?: Maybe<MessengerParticipant>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<MessengerMessage>;
  /** #rest(batched) #virtual */
  target?: Maybe<MessengerChat>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type RecruiterMessageStack = SignalsStack & {
  __typename?: 'RecruiterMessageStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type RecruiterMessageStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RecruiterRecommendation = Recommendation & {
  __typename?: 'RecruiterRecommendation';
  itemId: Scalars['ID']['output'];
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type RecruiterRecommendationEdge = PaginationEdge & {
  __typename?: 'RecruiterRecommendationEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: RecruiterRecommendation;
};

export type RecruiterRecommendationsConnection = HasCursorPagination & {
  __typename?: 'RecruiterRecommendationsConnection';
  edges: Array<Maybe<RecruiterRecommendationEdge>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

/** The most important page types that we want to show in the insights section of Xing */
export const enum ReducedContentPageType {
  Industry = 'industry',
  Insider = 'insider',
  Premium = 'premium',
  PressReview = 'press_review',
  Publisher = 'publisher',
  Topic = 'topic',
}

/**
 * Interface for types with client side references.
 *
 * This originated in the `Lego` project and is used in places where
 * the result returned by the API is mixed with a client-supplied reference.
 *
 * Sometimes APIs don't offer stable identifiers, but client would benefit
 * from having them. For example the image cache API supports multiple
 * image dimensions, but what "large" means can vary from device to device.
 *
 * The idea with `Referenceable` is that the client can specify a tag or identifier
 * which is returned back to him, to give the element semantic meaning. An image
 * dimension object could return reference "large" for example.
 */
export type Referenceable = {
  reference: Scalars['String']['output'];
};

export type RegenerateInvoicesError = {
  __typename?: 'RegenerateInvoicesError';
  errors?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type RegenerateInvoicesInput = {
  invoiceIds: Array<Scalars['ID']['input']>;
};

export type RegenerateInvoicesMutationResult = {
  __typename?: 'RegenerateInvoicesMutationResult';
  error?: Maybe<RegenerateInvoicesError>;
  success?: Maybe<PaymentInvoices>;
};

export type RelatedCompany = {
  __typename?: 'RelatedCompany';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  company?: Maybe<Company>;
};

/** Related Profile type for User Bags and Similar Profiles */
export type RelatedProfile = {
  __typename?: 'RelatedProfile';
  /** #logged-out #soft-logged-in */
  academicTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  businessCity?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  businessCountry?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  firstName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  hasPhoto?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  lastName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  occupationTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  pageName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  photoUrl?: Maybe<Scalars['URL']['output']>;
};

export type RelatedProfileCollection = {
  __typename?: 'RelatedProfileCollection';
  /** #logged-out #soft-logged-in */
  collection: Array<Maybe<RelatedProfile>>;
};

export type RemoveJobFromBookmarksAction = ObjectActions & {
  __typename?: 'RemoveJobFromBookmarksAction';
  params: RemoveJobFromBookmarksActionParams;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type RemoveJobFromBookmarksActionParams = {
  __typename?: 'RemoveJobFromBookmarksActionParams';
  jobId: Scalars['ID']['output'];
};

export type RepliesPage = {
  __typename?: 'RepliesPage';
  nextCount: Scalars['Int']['output'];
  pageInfo: PageInfo;
  previousCount: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RequestVitaCoverLetterRenderingInput = {
  kind?: InputMaybe<VitaDocumentKind>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type RequestVitaDocumentRenderingError = {
  __typename?: 'RequestVitaDocumentRenderingError';
  /** #logged-out #soft-logged-in */
  message: Scalars['String']['output'];
};

export type RequestVitaDocumentRenderingInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  kind?: InputMaybe<VitaDocumentKind>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type RequestVitaDocumentRenderingResult =
  | RequestVitaDocumentRenderingError
  | RequestVitaDocumentRenderingSuccess;

export type RequestVitaDocumentRenderingSuccess = {
  __typename?: 'RequestVitaDocumentRenderingSuccess';
  /** #logged-out #soft-logged-in */
  success: Scalars['String']['output'];
};

export type ResolvedParticipantUserUrn = {
  __typename?: 'ResolvedParticipantUserUrn';
  /** #rest(batched) #virtual */
  participant?: Maybe<MessengerParticipant>;
};

export type ResponseError = {
  __typename?: 'ResponseError';
  /** #logged-out #soft-logged-in */
  errorCode?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type ResumeOnboardingInfo = {
  __typename?: 'ResumeOnboardingInfo';
  resumeOnboardingPath: Scalars['String']['output'];
  shouldResumeOnboarding: Scalars['Boolean']['output'];
};

export type RevertReport403Error = {
  __typename?: 'RevertReport403Error';
  /** #logged-out #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
};

export type RevertReportResponse = {
  __typename?: 'RevertReportResponse';
  /** #logged-out #soft-logged-in */
  report?: Maybe<ComplaintsReport>;
};

export type RevertReportResult = RevertReport403Error | RevertReportResponse;

export type ReviewResponse = {
  __typename?: 'ReviewResponse';
  /** #logged-out #soft-logged-in */
  sentReviewRequest?: Maybe<Scalars['Boolean']['output']>;
};

/** Salary in a given Currency */
export type Salary = {
  __typename?: 'Salary';
  /** #logged-out #soft-logged-in */
  amount?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  currency?: Maybe<Currency>;
};

export const enum SalaryAvgComparison {
  Above = 'ABOVE',
  Below = 'BELOW',
  Equal = 'EQUAL',
  Unknown = 'UNKNOWN',
}

export type SalaryEstimate = {
  __typename?: 'SalaryEstimate';
  /** #logged-out #soft-logged-in */
  currency?: Maybe<Currency>;
  /** #logged-out #soft-logged-in */
  maximum?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  median?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  minimum?: Maybe<Scalars['Int']['output']>;
};

export type SalaryExpectation = {
  __typename?: 'SalaryExpectation';
  amount: Scalars['Int']['output'];
  avatars: Array<Maybe<Scalars['URL']['output']>>;
  label: Scalars['String']['output'];
  xtmUrl: Scalars['URL']['output'];
};

export type SalaryExpectationInsight = {
  __typename?: 'SalaryExpectationInsight';
  medianSalaryExpectation: Scalars['Int']['output'];
  medianSalaryExpectationCandidates: Scalars['Int']['output'];
  stats: Array<Maybe<SalaryExpectation>>;
  xtmUrl: Scalars['URL']['output'];
};

/** SalaryExpectationsPreference Type */
export type SalaryExpectationsPreference = {
  __typename?: 'SalaryExpectationsPreference';
  /** #logged-out #soft-logged-in */
  salaryExpectations?: Maybe<Scalars['Int']['output']>;
};

export type SalaryInsights = HasNwtTrackingToken &
  HasNewWorkTrackingToken & {
    __typename?: 'SalaryInsights';
    currentJobTitle?: Maybe<Scalars['String']['output']>;
    currentSalary?: Maybe<Salary>;
    nwTrackingToken?: Maybe<Scalars['String']['output']>;
    salaryAvg?: Maybe<Salary>;
    salaryAvgComparison?: Maybe<SalaryAvgComparison>;
    salaryAvgJobRoleId?: Maybe<Scalars['Int']['output']>;
    salaryAvgJobTitle?: Maybe<Scalars['String']['output']>;
    /** @deprecated Replaced by HasNewWorkTrackingToken to not confuse with another field with same name */
    trackingToken?: Maybe<Scalars['String']['output']>;
  };

export type SalaryInsightsError = {
  __typename?: 'SalaryInsightsError';
  message?: Maybe<Scalars['String']['output']>;
};

export type SalaryInsightsInput = {
  currentJobTitle?: InputMaybe<Scalars['String']['input']>;
  currentSalary?: InputMaybe<Scalars['Int']['input']>;
};

export type SalaryInsightsResult = SalaryInsights | SalaryInsightsError;

export type SalaryRange = {
  __typename?: 'SalaryRange';
  /** #logged-out #soft-logged-in */
  currency?: Maybe<Currency>;
  /** #logged-out #soft-logged-in */
  maximum?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  minimum?: Maybe<Scalars['Int']['output']>;
};

export type SalaryUnauthorized = {
  __typename?: 'SalaryUnauthorized';
  stub?: Maybe<Scalars['String']['output']>;
};

export type SaveJobToBookmarksAction = ObjectActions & {
  __typename?: 'SaveJobToBookmarksAction';
  params: SaveJobToBookmarksActionParams;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type SaveJobToBookmarksActionParams = {
  __typename?: 'SaveJobToBookmarksActionParams';
  jobId: Scalars['ID']['output'];
};

/**
 * A type for ImageCache scaled images.
 * Creates references to signed image URLs using a shared secret, without having to do a REST call to image-cache.
 */
export type ScaledImage = {
  __typename?: 'ScaledImage';
  /**
   * #logged-out #soft-logged-in
   *
   *  The correlation reference for this image as passed in with `ScaledImageDimension`
   */
  reference?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  The signed URL of the scaled image
   */
  url?: Maybe<Scalars['URL']['output']>;
};

export type ScaledImageDimension = {
  /** Height of the image */
  height: Scalars['Int']['input'];
  /**
   * The reference for this dimension.
   * Allows you to correlate a ScaledImage.
   *
   * See also: `ScaledImage.reference`
   */
  reference: Scalars['String']['input'];
  /** Width of the image */
  width: Scalars['Int']['input'];
};

export type ScheduledMessage = {
  __typename?: 'ScheduledMessage';
  completedAt?: Maybe<Scalars['Date']['output']>;
  contextId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  messageType?: Maybe<MessageType>;
  payload?: Maybe<ScheduledMessagePayload>;
  recipientId?: Maybe<Scalars['ID']['output']>;
  scheduledAt?: Maybe<Scalars['Date']['output']>;
  senderId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<ScheduledMessageStatus>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ScheduledMessageMutationError = {
  __typename?: 'ScheduledMessageMutationError';
  message?: Maybe<Scalars['String']['output']>;
};

export type ScheduledMessageMutationResult = {
  __typename?: 'ScheduledMessageMutationResult';
  error?: Maybe<ScheduledMessageMutationError>;
};

export type ScheduledMessagePayload = {
  __typename?: 'ScheduledMessagePayload';
  body?: Maybe<Scalars['String']['output']>;
  imageUuid?: Maybe<Scalars['UUID']['output']>;
};

export const enum ScheduledMessageStatus {
  Failed = 'FAILED',
  Scheduled = 'SCHEDULED',
  Sent = 'SENT',
}

export type ScheduledMessages = {
  __typename?: 'ScheduledMessages';
  collection?: Maybe<Array<Maybe<ScheduledMessage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchAlert = {
  __typename?: 'SearchAlert';
  createdAt?: Maybe<Scalars['Date']['output']>;
  globalId: Scalars['GlobalID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  newJobCount?: Maybe<Scalars['Long']['output']>;
  /** #rest(batched) #virtual */
  query?: Maybe<JobSearchQuery>;
  queryId: Scalars['SlugOrID']['output'];
  visitedAt?: Maybe<Scalars['Date']['output']>;
};

export type SearchAlertCreationInput = {
  queryId: Scalars['SlugOrID']['input'];
};

export type SearchAlertCreationResult =
  | SearchAlertCreationSuccess
  | SearchAlertErrorResponse;

export type SearchAlertCreationSuccess = {
  __typename?: 'SearchAlertCreationSuccess';
  /** #rest(batched) #virtual */
  alert: SearchAlert;
};

export type SearchAlertErrorResponse = {
  __typename?: 'SearchAlertErrorResponse';
  code?: Maybe<JobSearchAlertsDomainError>;
  description?: Maybe<Scalars['String']['output']>;
  requestId: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Date']['output']>;
};

export type SearchAlertForLo = {
  __typename?: 'SearchAlertForLO';
  /** #logged-out #soft-logged-in */
  email: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  query: SearchAlertQueryForLo;
};

export type SearchAlertForLoCreationAlertInput = {
  email: Scalars['String']['input'];
  query: SearchAlertForLoCreationAlertQueryInput;
};

export type SearchAlertForLoCreationAlertQueryFiltersInput = {
  benefits?: InputMaybe<Array<Scalars['String']['input']>>;
  city?: InputMaybe<Array<Scalars['String']['input']>>;
  country?: InputMaybe<Array<Scalars['String']['input']>>;
  discipline?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  level?: InputMaybe<Array<Scalars['String']['input']>>;
  remoteOption?: InputMaybe<Array<Scalars['String']['input']>>;
  salary?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SearchAlertForLoCreationAlertQueryInput = {
  filters?: InputMaybe<SearchAlertForLoCreationAlertQueryFiltersInput>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  radius?: InputMaybe<Scalars['String']['input']>;
};

export type SearchAlertForLoCreationErrorResponse = {
  __typename?: 'SearchAlertForLOCreationErrorResponse';
  /** #logged-out #soft-logged-in */
  errors?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type SearchAlertForLoCreationInput = {
  alert: SearchAlertForLoCreationAlertInput;
};

export type SearchAlertForLoCreationResult =
  | SearchAlertForLoCreationErrorResponse
  | SearchAlertForLoCreationSuccess;

export type SearchAlertForLoCreationSuccess = {
  __typename?: 'SearchAlertForLOCreationSuccess';
  /** #logged-out #soft-logged-in */
  alert: SearchAlertForLo;
};

export type SearchAlertId = {
  id: Scalars['ID']['input'];
};

export type SearchAlertQueryFiltersForLo = {
  __typename?: 'SearchAlertQueryFiltersForLO';
  /** #logged-out #soft-logged-in */
  benefits?: Maybe<Array<Scalars['String']['output']>>;
  /** #logged-out #soft-logged-in */
  city?: Maybe<Array<Scalars['String']['output']>>;
  /** #logged-out #soft-logged-in */
  country?: Maybe<Array<Scalars['String']['output']>>;
  /** #logged-out #soft-logged-in */
  discipline?: Maybe<Array<Scalars['String']['output']>>;
  /** #logged-out #soft-logged-in */
  industry?: Maybe<Array<Scalars['String']['output']>>;
  /** #logged-out #soft-logged-in */
  level?: Maybe<Array<Scalars['String']['output']>>;
  /** #logged-out #soft-logged-in */
  remoteOption?: Maybe<Array<Scalars['String']['output']>>;
  /** #logged-out #soft-logged-in */
  salary?: Maybe<Array<Scalars['String']['output']>>;
  /** #logged-out #soft-logged-in */
  type?: Maybe<Array<Scalars['String']['output']>>;
};

export type SearchAlertQueryForLo = {
  __typename?: 'SearchAlertQueryForLO';
  /** #logged-out #soft-logged-in */
  filters?: Maybe<SearchAlertQueryFiltersForLo>;
  /** #logged-out #soft-logged-in */
  keywords?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  location?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  radius?: Maybe<Scalars['String']['output']>;
};

export type SearchAlertResponse =
  | SearchAlertErrorResponse
  | SearchAlertResultResponse;

export type SearchAlertResultResponse = {
  __typename?: 'SearchAlertResultResponse';
  collection?: Maybe<Array<Maybe<SearchAlert>>>;
};

export const enum SearchMode {
  Normal = 'NORMAL',
  Semantic = 'SEMANTIC',
}

export type SearchResultMessage = {
  __typename?: 'SearchResultMessage';
  /** #rest(batched) #virtual */
  chat?: Maybe<MessengerChat>;
  highlightedMessageBody?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  message?: Maybe<MessengerMessage>;
  searchId?: Maybe<Scalars['ID']['output']>;
};

export type SeatAssignmentInput = {
  userId: Scalars['ID']['input'];
};

export type SeatManagerClient = {
  __typename?: 'SeatManagerClient';
  hasPremiumSeatsContract: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  premiumSeatsLimit?: Maybe<Scalars['Int']['output']>;
  premiumSeatsLimitUsed?: Maybe<Scalars['Int']['output']>;
  premiumUsers?: Maybe<Array<Maybe<SeatManagerUser>>>;
};

export type SeatManagerClientResponse = SeatManagerClient | SeatManagerError;

export type SeatManagerCreateAssignmentResponse =
  | SeatManagerError
  | SeatManagerMutationResult;

export type SeatManagerDeleteAssignmentResponse =
  | SeatManagerError
  | SeatManagerMutationResult;

export type SeatManagerError = {
  __typename?: 'SeatManagerError';
  message: Scalars['String']['output'];
};

export type SeatManagerMutationResult = {
  __typename?: 'SeatManagerMutationResult';
  hasPremiumSeatsContract: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  premiumSeatsLimit?: Maybe<Scalars['Int']['output']>;
  premiumSeatsLimitUsed?: Maybe<Scalars['Int']['output']>;
  premiumUsers?: Maybe<Array<Maybe<SeatManagerUser>>>;
  successMessage?: Maybe<Scalars['String']['output']>;
};

export type SeatManagerSearchResponse =
  | SeatManagerError
  | SeatManagerSearchResult;

export type SeatManagerSearchResult = {
  __typename?: 'SeatManagerSearchResult';
  results: Array<Maybe<SeatManagerUser>>;
};

export type SeatManagerUser = {
  __typename?: 'SeatManagerUser';
  displayFlag?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  occupationOrg?: Maybe<Scalars['String']['output']>;
  occupationTitle?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  profilePath?: Maybe<Scalars['String']['output']>;
};

/** Jobs Seeking status of an User */
export const enum SeekingStatus {
  Interested = 'INTERESTED',
  NotSeeking = 'NOT_SEEKING',
  Seeking = 'SEEKING',
}

/** Simple selection setting, usually represented as a dropdown */
export type SelectSetting = HasGlobalId & {
  __typename?: 'SelectSetting';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  options?: Maybe<Array<Maybe<SelectSettingOption>>>;
  /** #soft-logged-in */
  value: Scalars['String']['output'];
};

export type SelectSettingInput = {
  value: Scalars['String']['input'];
};

/** An item in the SelectSetting */
export type SelectSettingOption = {
  __typename?: 'SelectSettingOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SendContactRequestAction = ObjectActions & {
  __typename?: 'SendContactRequestAction';
  params: SendContactRequestActionParams;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type SendContactRequestActionParams = {
  __typename?: 'SendContactRequestActionParams';
  recipientId: Scalars['ID']['output'];
};

/** contains the reason why setting a note failed */
export type SetNoteError = {
  __typename?: 'SetNoteError';
  message: Scalars['String']['output'];
};

/** setNotePayload response object interface */
export type SetNotePayload = {
  error?: Maybe<SetNoteError>;
  note?: Maybe<Note>;
};

/** setNotePayload error response object */
export type SetNotePayloadError = SetNotePayload & {
  __typename?: 'SetNotePayloadError';
  error?: Maybe<SetNoteError>;
  note?: Maybe<Note>;
};

/** setNotePayload succesful response object */
export type SetNotePayloadSucces = SetNotePayload & {
  __typename?: 'SetNotePayloadSucces';
  error?: Maybe<SetNoteError>;
  note?: Maybe<Note>;
};

export type ShadowContactDetails = {
  __typename?: 'ShadowContactDetails';
  address?: Maybe<ShadowContactDetailsAddress>;
  globalId: Scalars['GlobalID']['output'];
};

export type ShadowContactDetailsAddress = {
  __typename?: 'ShadowContactDetailsAddress';
  city?: Maybe<Scalars['String']['output']>;
};

export type ShadowProfile = {
  __typename?: 'ShadowProfile';
  academicTitle?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  birthName?: Maybe<Scalars['String']['output']>;
  /** #rest #virtual */
  contactDetails?: Maybe<ShadowContactDetails>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  organizations?: Maybe<Scalars['String']['output']>;
  profileId?: Maybe<Scalars['ID']['output']>;
  /** #rest #virtual */
  skills?: Maybe<Array<Maybe<ShadowSkill>>>;
  urn: Scalars['URN']['output'];
  /** #rest #virtual */
  workExperiences?: Maybe<Array<Maybe<ShadowWorkExperience>>>;
};

export type ShadowSkill = {
  __typename?: 'ShadowSkill';
  category: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ShadowWorkExperience = {
  __typename?: 'ShadowWorkExperience';
  beginDate?: Maybe<ProfileYearAndMonth>;
  companyId?: Maybe<Scalars['ID']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  disciplineId?: Maybe<Scalars['ID']['output']>;
  endDate?: Maybe<ProfileYearAndMonth>;
  globalId: Scalars['GlobalID']['output'];
  industryId?: Maybe<Scalars['ID']['output']>;
  jobTitle: Scalars['String']['output'];
  levelId?: Maybe<Scalars['ID']['output']>;
  statusId?: Maybe<Scalars['ID']['output']>;
};

/** Contains all the information about the shared contact */
export type SharedContact = {
  __typename?: 'SharedContact';
  /** Id of the shared contact */
  id: Scalars['ID']['output'];
  /**
   * #rest(batched) #virtual
   *
   *  XingId representation of the information about the shared contact
   */
  xingId?: Maybe<XingId>;
};

export type SharedContacts = {
  __typename?: 'SharedContacts';
  /** The user id who has shared contacts with the rid */
  otherUserId: Scalars['ID']['output'];
  sharedContacts?: Maybe<Array<Maybe<SharedContact>>>;
  /** The total number of shared contacts between rid and otherUserId */
  total?: Maybe<Scalars['Int']['output']>;
};

export type SharedContactsConnection = HasCursorPagination & {
  __typename?: 'SharedContactsConnection';
  edges: Array<SharedContactsEdge>;
  /** Is false if the other user does not allow viewing its contacts for the current user. */
  isVisible: Scalars['Boolean']['output'];
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type SharedContactsEdge = PaginationEdge & {
  __typename?: 'SharedContactsEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: SharedContact;
};

export type SignalDeleteInput = {
  id: Scalars['ID']['input'];
  stackType: SignalsFilter;
};

export type SignalDeleteResult = {
  __typename?: 'SignalDeleteResult';
  error?: Maybe<SignalDeleteResultError>;
};

export type SignalDeleteResultError = {
  __typename?: 'SignalDeleteResultError';
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type SignalMarkInteractedInput = {
  id: Scalars['ID']['input'];
  stackType: SignalsFilter;
};

export type SignalMarkInteractedResult = {
  __typename?: 'SignalMarkInteractedResult';
  error?: Maybe<SignalMarkInteractedResultError>;
  success?: Maybe<StackSignal>;
};

export type SignalMarkInteractedResultError = {
  __typename?: 'SignalMarkInteractedResultError';
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type SignalStackEdge = PaginationEdge & {
  __typename?: 'SignalStackEdge';
  cursor: Scalars['String']['output'];
  node: Stack;
};

export type SignalsCollectionConnection = HasCursorPagination & {
  __typename?: 'SignalsCollectionConnection';
  edges: Array<Maybe<StackSignalEdge>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export const enum SignalsFilter {
  Birthday = 'BIRTHDAY',
  ContactsRequest = 'CONTACTS_REQUEST',
  ContactRecommendation = 'CONTACT_RECOMMENDATION',
  CulturalAssessmentProposal = 'CULTURAL_ASSESSMENT_PROPOSAL',
  EventInvitation = 'EVENT_INVITATION',
  JobRecommendation = 'JOB_RECOMMENDATION',
  JobSearchAlert = 'JOB_SEARCH_ALERT',
  NewContact = 'NEW_CONTACT',
  RecruiterMessage = 'RECRUITER_MESSAGE',
  SocialComment = 'SOCIAL_COMMENT',
  SocialLike = 'SOCIAL_LIKE',
  SocialMention = 'SOCIAL_MENTION',
  SocialShare = 'SOCIAL_SHARE',
  Vomp = 'VOMP',
  WorkExperienceUpdate = 'WORK_EXPERIENCE_UPDATE',
}

export type SignalsStack = {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type SignalsStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SignalsStackMarkSeenInput = {
  stackType: SignalsFilter;
};

export type SignalsStackMarkSeenResult = {
  __typename?: 'SignalsStackMarkSeenResult';
  error?: Maybe<SignalsStackMarkSeenResultError>;
  success?: Maybe<SignalsStack>;
};

export type SignalsStackMarkSeenResultError = {
  __typename?: 'SignalsStackMarkSeenResultError';
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type SignalsStacksConnection = HasCursorPagination & {
  __typename?: 'SignalsStacksConnection';
  edges: Array<Maybe<SignalStackEdge>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type SimilarCandidates = {
  __typename?: 'SimilarCandidates';
  collection?: Maybe<Array<Maybe<Candidates>>>;
  requestTrackingToken?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  took?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SingleSearchAlertResponse = SearchAlert | SearchAlertErrorResponse;

export type Skill = {
  __typename?: 'Skill';
  category?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  label: Scalars['String']['output'];
};

export type SkillLabels = {
  __typename?: 'SkillLabels';
  de: Scalars['String']['output'];
  en: Scalars['String']['output'];
};

export type SkillRecommendation = Recommendation & {
  __typename?: 'SkillRecommendation';
  category: Scalars['String']['output'];
  itemId: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  labels: SkillLabels;
  /** @deprecated Operational Tracking (ODT) is being replaced by New Work Tracking */
  opTrackingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position: Scalars['Int']['output'];
  reason: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  totalPerformance?: Maybe<Scalars['Long']['output']>;
  trackingToken: Scalars['String']['output'];
  urn: Scalars['URN']['output'];
};

export type SkillRecommendationResponse = RecommenderResponse & {
  __typename?: 'SkillRecommendationResponse';
  collection: Array<SkillRecommendation>;
  requestTrackingToken: Scalars['String']['output'];
  service: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export type SkillsInsights = HasNwtTrackingToken &
  HasNewWorkTrackingToken & {
    __typename?: 'SkillsInsights';
    currentJobTitle?: Maybe<Scalars['String']['output']>;
    minSkills: Scalars['Int']['output'];
    nwTrackingToken?: Maybe<Scalars['String']['output']>;
    topSkills?: Maybe<Array<Maybe<Skill>>>;
    /** @deprecated Replaced by HasNewWorkTrackingToken to not confuse with another field with same name */
    trackingToken?: Maybe<Scalars['String']['output']>;
    userId: Scalars['ID']['output'];
  };

export type SocialActivity = SocialEntity & {
  __typename?: 'SocialActivity';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  urn?: Maybe<Scalars['URN']['output']>;
};

export type SocialComment = SocialEntity & {
  __typename?: 'SocialComment';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  urn?: Maybe<Scalars['URN']['output']>;
};

export type SocialCommentEdge = PaginationEdge & {
  __typename?: 'SocialCommentEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: SocialCommentResult;
};

export type SocialCommentError = {
  __typename?: 'SocialCommentError';
  message?: Maybe<Scalars['String']['output']>;
};

export type SocialCommentMutationResult = {
  __typename?: 'SocialCommentMutationResult';
  error?: Maybe<SocialCommentError>;
  success?: Maybe<SocialCommentResult>;
};

export type SocialCommentPermissions = {
  __typename?: 'SocialCommentPermissions';
  /** #logged-out #soft-logged-in */
  canCreate: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canDelete: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canDeleteMentions: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canUpdate: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canView: Scalars['Boolean']['output'];
};

export type SocialCommentResult = {
  __typename?: 'SocialCommentResult';
  /** the actor picked by the user */
  actorUrn?: Maybe<Scalars['GlobalID']['output']>;
  /** #rest(batched) #virtual */
  commentInteractionTarget?: Maybe<SocialInteractionTarget>;
  /** the date the comment has been created */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** the date the comment was deleted */
  deletedAt?: Maybe<Scalars['Date']['output']>;
  /** depth of the comment */
  depth: Scalars['Int']['output'];
  /**
   * comment in plaintext format (legacy)
   * @deprecated use messagePlaintext instead
   */
  fallbackMessage: Scalars['String']['output'];
  /** comment format (plaintext, slateV1, articleV1) */
  format: Scalars['String']['output'];
  /** id of the comment */
  id: Scalars['ID']['output'];
  /** list of replies to the comment to show */
  initiallyShownReplies?: Maybe<Array<Maybe<SocialCommentResult>>>;
  /** #rest(batched) #virtual */
  interactionTarget?: Maybe<SocialInteractionTarget>;
  /** the urn of the entity where the comment happened */
  interactionTargetUrn: Scalars['GlobalID']['output'];
  /** was the comment edited */
  isEdited?: Maybe<Scalars['Boolean']['output']>;
  /** comment text with json-encoded formatting (plaintext or slateV1) */
  message: Scalars['String']['output'];
  /** comment in article editor format */
  messageArticleV1: Array<ArticleBlock>;
  /** comment in plaintext format */
  messagePlaintext: Scalars['String']['output'];
  /** comment in slate format */
  messageSlateV1: Scalars['String']['output'];
  /** pagination information for replies (only when providing comment ID) */
  repliesPage?: Maybe<RepliesPage>;
  /** the date the comment was last updated */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** urn of the comment */
  urn: Scalars['GlobalID']['output'];
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  /** the user id who created the comment */
  userId: Scalars['ID']['output'];
};

export type SocialCommentSignal = NetworkUpdatesStackItem & {
  __typename?: 'SocialCommentSignal';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<SocialComment>;
  stackId: Scalars['ID']['output'];
  subtype?: Maybe<SocialCommentSubtype>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type SocialCommentStack = SignalsStack & {
  __typename?: 'SocialCommentStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type SocialCommentStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export const enum SocialCommentSubtype {
  CommentToOwnPost = 'COMMENT_TO_OWN_POST',
  CommentToThread = 'COMMENT_TO_THREAD',
  ReplyToOwnComment = 'REPLY_TO_OWN_COMMENT',
  ReplyToOwnPost = 'REPLY_TO_OWN_POST',
  ReplyToThread = 'REPLY_TO_THREAD',
}

export type SocialCommentsConnection = HasCursorPagination & {
  __typename?: 'SocialCommentsConnection';
  edges?: Maybe<Array<Maybe<SocialCommentEdge>>>;
  nextCount: Scalars['Int']['output'];
  /** #virtual */
  pageInfo: PageInfo;
  previousCount: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SocialCreateCommentInput = {
  actorUrn?: InputMaybe<Scalars['GlobalID']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  interactionTargetUrn: Scalars['GlobalID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  messageArticleV1?: InputMaybe<Array<ArticlesCreateArticleBlocksInput>>;
  trackingMetadata?: InputMaybe<SocialTrackingMetadataInput>;
};

export type SocialCreateExternalLinkInput = {
  url: Scalars['URL']['input'];
};

export type SocialCreateReactionInput = {
  interactionTargetUrn: Scalars['GlobalID']['input'];
  reactionType: SocialReactionType;
  trackingMetadata?: InputMaybe<SocialTrackingMetadataInput>;
};

export type SocialDeleteCommentInput = {
  id: Scalars['ID']['input'];
};

export type SocialDeleteCommentMentionsInput = {
  id: Scalars['ID']['input'];
};

export type SocialDeleteReactionInput = {
  interactionTargetUrn: Scalars['GlobalID']['input'];
};

export type SocialEntity = {
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  urn?: Maybe<Scalars['URN']['output']>;
};

export type SocialEvent = SocialEntity & {
  __typename?: 'SocialEvent';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  urn?: Maybe<Scalars['URN']['output']>;
};

export type SocialExternalLinkError = {
  __typename?: 'SocialExternalLinkError';
  message?: Maybe<Scalars['String']['output']>;
};

export type SocialExternalLinkMutationResult = {
  __typename?: 'SocialExternalLinkMutationResult';
  error?: Maybe<SocialExternalLinkError>;
  success?: Maybe<SocialExternalLinkResult>;
};

export type SocialExternalLinkResult = {
  __typename?: 'SocialExternalLinkResult';
  /**
   * #logged-out #soft-logged-in
   *
   *  Same as the imageUrl, but stored in our ImageCache (should be prefered)
   */
  cachedImageUrl?: Maybe<Scalars['URL']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Canonical URL of the link (source of truth in case many URLs have the same content)
   */
  canonicalUrl: Scalars['URL']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  the Date when the link was first created on the platform
   */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Link description as specified by the meta tags or our best-guess
   */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  id of the external link
   */
  id: Scalars['ID']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Preview image as specified by meta tags or the most prominent image on the page
   */
  imageUrl?: Maybe<Scalars['URL']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  True if the URL points to an external host, false if it is XING internal
   */
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Domain of the link (e.g. xing.com)
   */
  sourceDomain?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Link title as specified by the meta tags or our best-guess
   */
  title?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  unique id of the external link (sha256 of the URL)
   */
  uniqueId: Scalars['String']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  the Date when the link was last updated
   */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  URL of the link
   */
  url: Scalars['URL']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  urn of the external link
   */
  urn: Scalars['GlobalID']['output'];
};

export type SocialImagePost = SocialEntity & {
  __typename?: 'SocialImagePost';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  urn?: Maybe<Scalars['URN']['output']>;
};

export type SocialInteractionTarget = {
  __typename?: 'SocialInteractionTarget';
  /** #logged-out #soft-logged-in */
  commentsCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  permissions: SocialPermissions;
  /** #logged-out #soft-logged-in */
  reactionsCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  repliesCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  sharesCount: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  urn: Scalars['GlobalID']['output'];
  userReactionType?: Maybe<SocialReactionType>;
  viewsCount?: Maybe<Scalars['Int']['output']>;
};

export type SocialLikeSignal = NetworkUpdatesStackItem & {
  __typename?: 'SocialLikeSignal';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  stackId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  target?: Maybe<SocialLikeTarget>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type SocialLikeStack = SignalsStack & {
  __typename?: 'SocialLikeStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type SocialLikeStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SocialLikeTarget =
  | SocialActivity
  | SocialComment
  | SocialEvent
  | SocialImagePost
  | SocialLinkSharePost
  | SocialPlainTextPost
  | SocialPollPost
  | SocialVideoPost;

export type SocialLinkSharePost = SocialEntity & {
  __typename?: 'SocialLinkSharePost';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  urn?: Maybe<Scalars['URN']['output']>;
};

export type SocialMentionPermissions = {
  __typename?: 'SocialMentionPermissions';
  /** #logged-out #soft-logged-in */
  canDelete: Scalars['Boolean']['output'];
};

export type SocialMentionSignal = NetworkUpdatesStackItem & {
  __typename?: 'SocialMentionSignal';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  stackId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  target?: Maybe<SocialMentionTarget>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type SocialMentionStack = SignalsStack & {
  __typename?: 'SocialMentionStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type SocialMentionStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SocialMentionTarget =
  | SocialActivity
  | SocialComment
  | SocialEvent
  | SocialImagePost
  | SocialLinkSharePost
  | SocialPlainTextPost
  | SocialPollPost
  | SocialVideoPost;

export type SocialMessengerRecentChats = {
  __typename?: 'SocialMessengerRecentChats';
  collection?: Maybe<Array<Maybe<MessengerChat>>>;
};

export type SocialPermissions = {
  __typename?: 'SocialPermissions';
  /** #logged-out #soft-logged-in */
  comments: SocialCommentPermissions;
  /** #logged-out #soft-logged-in */
  mentions: SocialMentionPermissions;
  /** #logged-out #soft-logged-in */
  reactions: SocialReactionPermissions;
  /** #logged-out #soft-logged-in */
  shares: SocialSharePermissions;
};

export type SocialPlainTextPost = SocialEntity & {
  __typename?: 'SocialPlainTextPost';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  urn?: Maybe<Scalars['URN']['output']>;
};

export type SocialPollPost = SocialEntity & {
  __typename?: 'SocialPollPost';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  urn?: Maybe<Scalars['URN']['output']>;
};

export type SocialProofCollection = {
  __typename?: 'SocialProofCollection';
  reactions?: Maybe<Array<Maybe<SocialProofRecord>>>;
  urn: Scalars['GlobalID']['output'];
};

export type SocialProofRecord = {
  __typename?: 'SocialProofRecord';
  /** #rest(batched) #virtual */
  interactionTarget?: Maybe<SocialInteractionTarget>;
  interactionTargetUrn: Scalars['GlobalID']['output'];
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export type SocialProofResult = {
  __typename?: 'SocialProofResult';
  collection?: Maybe<Array<Maybe<SocialProofRecord>>>;
};

export type SocialReactionEdge = PaginationEdge & {
  __typename?: 'SocialReactionEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: SocialReactionResult;
};

export type SocialReactionError = {
  __typename?: 'SocialReactionError';
  message?: Maybe<Scalars['String']['output']>;
};

export type SocialReactionMutationResult = {
  __typename?: 'SocialReactionMutationResult';
  error?: Maybe<SocialReactionError>;
  success?: Maybe<SocialReactionResult>;
};

export type SocialReactionPermissions = {
  __typename?: 'SocialReactionPermissions';
  /** #logged-out #soft-logged-in */
  canCreate: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canDelete: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canView: Scalars['Boolean']['output'];
};

export type SocialReactionResult = {
  __typename?: 'SocialReactionResult';
  actorUrn?: Maybe<Scalars['GlobalID']['output']>;
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  interactionTarget?: Maybe<SocialInteractionTarget>;
  interactionTargetUrn: Scalars['GlobalID']['output'];
  /** the contact state between the current user and the creator of the reaction */
  isContactWithCurrentUser?: Maybe<Scalars['Boolean']['output']>;
  reactionType: SocialReactionType;
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export const enum SocialReactionType {
  Like = 'LIKE',
}

export type SocialReactionsConnection = HasCursorPagination & {
  __typename?: 'SocialReactionsConnection';
  edges?: Maybe<Array<Maybe<SocialReactionEdge>>>;
  nextCount: Scalars['Int']['output'];
  /** #virtual */
  pageInfo: PageInfo;
  previousCount: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Settings related to Social */
export type SocialSettings = {
  __typename?: 'SocialSettings';
  /** User is mentionable (via @name in comments) */
  mentionable?: Maybe<ToggleSetting>;
};

export type SocialSettingsInput = {
  /** User is mentionable (via @name in comments) */
  mentionable?: InputMaybe<ToggleSettingInput>;
};

export type SocialSettingsMutationResult = {
  __typename?: 'SocialSettingsMutationResult';
  error?: Maybe<SocialSettings>;
  response?: Maybe<SocialSettings>;
};

export type SocialShareEdge = PaginationEdge & {
  __typename?: 'SocialShareEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: SocialShareResult;
};

export type SocialShareError = {
  __typename?: 'SocialShareError';
  message?: Maybe<Scalars['String']['output']>;
};

export type SocialShareMutationResult = {
  __typename?: 'SocialShareMutationResult';
  error?: Maybe<SocialShareError>;
  success?: Maybe<SocialShareResult>;
};

export type SocialSharePermissions = {
  __typename?: 'SocialSharePermissions';
  /** #logged-out #soft-logged-in */
  canCreate: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canDelete: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  canView: Scalars['Boolean']['output'];
};

export type SocialShareResult = {
  __typename?: 'SocialShareResult';
  /** the actor picked by the user */
  actorUrn?: Maybe<Scalars['GlobalID']['output']>;
  /** the date the share has been created */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** #rest(batched) #virtual */
  interactionTarget?: Maybe<SocialInteractionTarget>;
  /** the urn of the entity where the interaction happened */
  interactionTargetUrn?: Maybe<Scalars['GlobalID']['output']>;
  /** the contact state between the current user and the creator of the share */
  isContactWithCurrentUser?: Maybe<Scalars['Boolean']['output']>;
  /** the user-provided message */
  message?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  newInteractionTarget?: Maybe<SocialInteractionTarget>;
  /** the urn of the new object created by the share (unique) */
  newObjectUrn?: Maybe<Scalars['GlobalID']['output']>;
  /** the url of the newly created object as a result of the share */
  redirectUrl?: Maybe<Scalars['URL']['output']>;
  /** the urn of the entity identifying the content that was shared */
  shareableUrn?: Maybe<Scalars['GlobalID']['output']>;
  /** the destination type of the share: one of startpage|groups|messenger */
  targetType?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  /** the user id who created the share */
  userId: Scalars['ID']['output'];
};

export type SocialShareSignal = NetworkUpdatesStackItem & {
  __typename?: 'SocialShareSignal';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  stackId: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  target?: Maybe<SocialShareTarget>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type SocialShareStack = SignalsStack & {
  __typename?: 'SocialShareStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type SocialShareStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SocialShareTarget =
  | SocialActivity
  | SocialComment
  | SocialEvent
  | SocialImagePost
  | SocialLinkSharePost
  | SocialPlainTextPost
  | SocialPollPost
  | SocialVideoPost;

export type SocialShareToGroupInput = {
  forumId: Scalars['ID']['input'];
  interactionTargetUrn: Scalars['GlobalID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  shareableUrn?: InputMaybe<Scalars['GlobalID']['input']>;
  title: Scalars['String']['input'];
  trackingMetadata?: InputMaybe<SocialTrackingMetadataInput>;
  url: Scalars['URL']['input'];
};

export type SocialShareToMessengerInput = {
  interactionTargetUrn: Scalars['GlobalID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  recipient: SocialShareToMessengerRecipient;
  shareableUrn?: InputMaybe<Scalars['GlobalID']['input']>;
  trackingMetadata?: InputMaybe<SocialTrackingMetadataInput>;
  url: Scalars['URL']['input'];
};

export type SocialShareToMessengerRecipient = {
  id: Scalars['ID']['input'];
  type: SocialShareToMessengerRecipientType;
};

export const enum SocialShareToMessengerRecipientType {
  /** A recent chat */
  Chat = 'CHAT',
  /** A new chat participant */
  Participant = 'PARTICIPANT',
}

export type SocialShareToStartpageInput = {
  interactionTargetUrn: Scalars['GlobalID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  shareableUrn?: InputMaybe<Scalars['GlobalID']['input']>;
  trackingMetadata?: InputMaybe<SocialTrackingMetadataInput>;
  verb?: InputMaybe<SocialShareToStartpageVerb>;
  visibility?: InputMaybe<SocialShareToStartpageVisibility>;
};

export const enum SocialShareToStartpageVerb {
  /** An initial share action */
  Post = 'POST',
  /** The default re-share action */
  Share = 'SHARE',
}

export const enum SocialShareToStartpageVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type SocialSharesConnection = HasCursorPagination & {
  __typename?: 'SocialSharesConnection';
  edges?: Maybe<Array<Maybe<SocialShareEdge>>>;
  nextCount: Scalars['Int']['output'];
  /** #virtual */
  pageInfo: PageInfo;
  previousCount: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SocialTrackingMetadataInput = {
  channel?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated not used with operational data tracking */
  deliveryTimestamp?: InputMaybe<Scalars['Date']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated not used with operational data tracking */
  referrerUrl?: InputMaybe<Scalars['URL']['input']>;
  /** @deprecated not used with operational data tracking */
  trackingContext?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated replaced by trackingTokens (SOCIAL-1180). WILL BE REMOVED... */
  trackingID?: InputMaybe<Scalars['String']['input']>;
  trackingTokens?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** @deprecated not used with operational data tracking */
  trackingUUID?: InputMaybe<Scalars['UUID']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type SocialUpdateCommentInput = {
  actorUrn?: InputMaybe<Scalars['GlobalID']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  messageArticleV1?: InputMaybe<Array<ArticlesCreateArticleBlocksInput>>;
  trackingMetadata?: InputMaybe<SocialTrackingMetadataInput>;
};

export type SocialUpdateReactionInput = {
  interactionTargetUrn: Scalars['GlobalID']['input'];
  reactionType: SocialReactionType;
  trackingMetadata?: InputMaybe<SocialTrackingMetadataInput>;
};

export type SocialUserRecord = {
  __typename?: 'SocialUserRecord';
  isMentionable?: Maybe<Scalars['Boolean']['output']>;
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export type SocialUserSearchResult = {
  __typename?: 'SocialUserSearchResult';
  collection?: Maybe<Array<Maybe<SocialUserRecord>>>;
};

export type SocialVideoPost = SocialEntity & {
  __typename?: 'SocialVideoPost';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  urn?: Maybe<Scalars['URN']['output']>;
};

export type Source = Community | GroupsGroup | XingId;

/** At Specific Time Phone Preferences Type */
export type SpecificTimePhonePreferences = {
  __typename?: 'SpecificTimePhonePreferences';
  at: PhonePreferencesOptions;
  days: PhonePreferenceDays;
  /** #rest(batched) #virtual */
  timeFrom?: Maybe<PhonePreferenceTime>;
  /** #rest(batched) #virtual */
  timeUntil?: Maybe<PhonePreferenceTime>;
};

export type Stack =
  | BirthdayStack
  | ContactRecommendationStack
  | ContactRequestStack
  | CulturalAssessmentProposalStack
  | EventInvitationStack
  | JobRecommendationStack
  | JobSearchAlertStack
  | NewContactStack
  | RecruiterMessageStack
  | SocialCommentStack
  | SocialLikeStack
  | SocialMentionStack
  | SocialShareStack
  | VompStack
  | WorkExperienceUpdateStack;

export type StackSignal =
  | BirthdayConversationStarter
  | ContactRecommendationSignal
  | ContactRequestConversationStarter
  | CulturalAssessmentProposalSignal
  | EventInvitationSignal
  | JobRecommendationSignal
  | JobSearchAlertSignal
  | NewContactConversationStarter
  | RecruiterMessageSignal
  | SocialCommentSignal
  | SocialLikeSignal
  | SocialMentionSignal
  | SocialShareSignal
  | VompConversationStarter
  | WorkExperienceUpdateConversationStarter;

export type StackSignalEdge = PaginationEdge & {
  __typename?: 'StackSignalEdge';
  cursor: Scalars['String']['output'];
  node: StackSignal;
};

export type StartUserDataInquiryResult = {
  error?: Maybe<UserDataInquiryError>;
  inquiry?: Maybe<UserDataInquiry>;
};

export type StartUserDataInquiryResultError = StartUserDataInquiryResult & {
  __typename?: 'StartUserDataInquiryResultError';
  error?: Maybe<UserDataInquiryError>;
  inquiry?: Maybe<UserDataInquiry>;
};

export type StartUserDataInquiryResultSuccess = StartUserDataInquiryResult & {
  __typename?: 'StartUserDataInquiryResultSuccess';
  error?: Maybe<UserDataInquiryError>;
  inquiry?: Maybe<UserDataInquiry>;
};

/** Deprecated: It should be replaced to graphVisitor */
export type StatisticsGraph = {
  __typename?: 'StatisticsGraph';
  /** @deprecated the entire StatisticsGraph is deprecated. */
  endDate: Scalars['Date']['output'];
  /** @deprecated the entire StatisticsGraph is deprecated. */
  sectionHeadline: Scalars['String']['output'];
  /** @deprecated the entire StatisticsGraph is deprecated. */
  startDate: Scalars['Date']['output'];
  /**
   * When the user is basic, a description is given
   * @deprecated the entire StatisticsGraph is deprecated.
   */
  upsellDescription?: Maybe<Scalars['String']['output']>;
  /**
   * The array of visits for the specified time frame
   * @deprecated the entire StatisticsGraph is deprecated.
   */
  visitsPerDay: Array<Scalars['Int']['output']>;
  /**
   * The trend percentage representing the visitors increase or decrease on the specified time frame
   * @deprecated the entire StatisticsGraph is deprecated.
   */
  visitsTrend: Scalars['Float']['output'];
  /**
   * The headline for the trend percentage - it also includes the percentage as text
   * @deprecated the entire StatisticsGraph is deprecated.
   */
  visitsTrendHeadline: Scalars['String']['output'];
};

export type StatisticsItem = {
  __typename?: 'StatisticsItem';
  /** The actual number value object for this item */
  items: Array<StatisticsShare>;
  sectionHeadline: Scalars['String']['output'];
  /** If this component is suited for a count or a pie chart representation */
  type: StatisticsType;
  /** Upsell Point used for tracking purposes */
  uplt?: Maybe<Scalars['String']['output']>;
  /** When the user is basic, a description is given */
  upsellDescription?: Maybe<Scalars['String']['output']>;
};

export type StatisticsShare = {
  __typename?: 'StatisticsShare';
  /** Tells the client if the feature should be shown fenced */
  fenced: Scalars['Boolean']['output'];
  /** The value for this item. It can be a percentage or an absolute number depending on the statistics type */
  share: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

/** The statistics component can be suited for percentage representation (PIE) or absolute representation (BAR) */
export const enum StatisticsType {
  Bar = 'BAR',
  Pie = 'PIE',
}

/** Authorization to JobSeeker data of Basic and Premium users */
export const enum StatusVisibility {
  Recruiter = 'RECRUITER',
  RecruiterAndContacts = 'RECRUITER_AND_CONTACTS',
  XingMembers = 'XING_MEMBERS',
}

export type StoryActivity = {
  __typename?: 'StoryActivity';
  activityId?: Maybe<Scalars['ID']['output']>;
  /** The URN of the Story, not the Activity */
  globalId: Scalars['GlobalID']['output'];
  /** Indicates whether the user has already seen the corresponding story */
  hasSeen: Scalars['Boolean']['output'];
  /** Sort key of this story within a `StoryActivityCollection` */
  sortKey: Scalars['Int']['output'];
};

/** A grouping of stories by actor (creator/owner). */
export type StoryActivityCollection = {
  __typename?: 'StoryActivityCollection';
  /** Total number of currently active stories. */
  activeStoryCount: Scalars['Int']['output'];
  /** #rest(batched) #virtual */
  actor?: Maybe<StoryActor>;
  /**
   * All stories in this collection belong to this actor, or should at least be
   * displayed as stories by this actor.
   */
  actorGlobalId: Scalars['GlobalID']['output'];
  /** Whether the requesting user is allowed to create stories on the actor of this collection's behalf */
  isManagedByUser: Scalars['Boolean']['output'];
  /**
   * Timestamp of the most recently created story in the collection
   * @deprecated no one is using it; if you are or want to, please contact the publishing team
   */
  lastCreatedAt: Scalars['Date']['output'];
  /**
   * Allows the server to decide the order that a collection should show up
   * among many collections. For example, a collection with the most recent post
   * would get `sortKey: 1`
   */
  sortKey?: Maybe<Scalars['Int']['output']>;
  storiesActivities?: Maybe<Array<Maybe<StoryActivity>>>;
};

export type StoryActor = EntityPage | StoryDemoActor | XingId;

/** A collection of stories */
export type StoryCollection = {
  __typename?: 'StoryCollection';
  stories?: Maybe<Array<Maybe<PostingsStory>>>;
};

/** A collection of story collections is needed to create the "story row" for a user */
export type StoryCollectionList = {
  __typename?: 'StoryCollectionList';
  collection?: Maybe<Array<Maybe<StoryActivityCollection>>>;
};

export type StoryCreateMutationResult = {
  __typename?: 'StoryCreateMutationResult';
  error?: Maybe<PostingsError>;
  success?: Maybe<PostingsStoryPromise>;
};

export type StoryDeleteMutationResult = {
  __typename?: 'StoryDeleteMutationResult';
  error?: Maybe<PostingsError>;
};

export type StoryDemoActor = {
  __typename?: 'StoryDemoActor';
  /** #virtual */
  logoImage?: Maybe<Array<ScaledImage>>;
  title: Scalars['String']['output'];
};

export type StoryDemoActorLogoImageArgs = {
  dimensions: Array<ScaledImageDimension>;
};

/**
 * Groups Stories for synchronized publication.
 * Only relevant for creation. Once the stories have been created, they can
 * and should be accessed directly, like all other stories.
 */
export type StoryGroup = {
  __typename?: 'StoryGroup';
  actorGlobalId: Scalars['GlobalID']['output'];
  globalId: Scalars['GlobalID']['output'];
  stories?: Maybe<Array<PostingsStoryPromise>>;
  /** Use this as apiKey to create videos for stories in the videoCreate mutation */
  videoApiKey: Scalars['String']['output'];
};

export type StoryGroupMutationResult = {
  __typename?: 'StoryGroupMutationResult';
  error?: Maybe<PostingsError>;
  success?: Maybe<StoryGroup>;
};

export type StoryInteractions = {
  __typename?: 'StoryInteractions';
  messaging: Scalars['Boolean']['output'];
};

export type StoryMedium = {
  globalId: Scalars['GlobalID']['output'];
  id: Scalars['ID']['output'];
};

export const enum StorySupportedActors {
  DemoActor = 'DEMO_ACTOR',
  EntityPage = 'ENTITY_PAGE',
  XingId = 'XING_ID',
}

export const enum StoryType {
  Image = 'IMAGE',
  Text = 'TEXT',
  Video = 'VIDEO',
}

/** Viewer of a story. Acts a wrapper for `XingId` */
export type StoryViewer = {
  __typename?: 'StoryViewer';
  /** #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type StoryViewerConnection = HasCursorPagination & {
  __typename?: 'StoryViewerConnection';
  edges: Array<StoryViewerEdge>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type StoryViewerEdge = PaginationEdge & {
  __typename?: 'StoryViewerEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: StoryViewer;
};

export type Subjects = {
  __typename?: 'Subjects';
  /** #logged-out #soft-logged-in */
  form?: Maybe<Form>;
  /** #logged-out #soft-logged-in */
  label?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
};

export type SubmitReview401Error = {
  __typename?: 'SubmitReview401Error';
  /** #logged-out #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
};

export type SubmitReviewInput = {
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type SubmitReviewPostResult =
  | SubmitReview401Error
  | SubmitReviewResponse;

export type SubmitReviewResponse = {
  __typename?: 'SubmitReviewResponse';
  /** #logged-out #soft-logged-in */
  report?: Maybe<ComplaintsReport>;
};

/** The root subscription type */
export type Subscription = {
  __typename?: 'Subscription';
  /**
   * Dummy subscription field
   * @deprecated Makes GraphiQL happy
   */
  dummy?: Maybe<Scalars['String']['output']>;
};

export type SupportContactFormTopics = {
  __typename?: 'SupportContactFormTopics';
  /** #logged-out #soft-logged-in #rest #virtual */
  topics?: Maybe<ContactTopicsResponse>;
};

export type SupportContactFormTopicsTopicsArgs = {
  subjectName?: InputMaybe<Scalars['String']['input']>;
};

export type TableOfContent = {
  __typename?: 'TableOfContent';
  /**
   * #logged-out #soft-logged-in
   *
   *  Children of the table of content
   */
  children?: Maybe<Array<Maybe<TableOfContentChildren>>>;
  /**
   * #logged-out #soft-logged-in
   *
   *  ID of the table of content
   */
  id?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Title of the table of content
   */
  title?: Maybe<Scalars['String']['output']>;
};

export type TableOfContentChildren = {
  __typename?: 'TableOfContentChildren';
  /**
   * #logged-out #soft-logged-in
   *
   *  Children of the content from children
   */
  children?: Maybe<Array<Maybe<TableOfContentChildren>>>;
  /**
   * #logged-out #soft-logged-in
   *
   *  HTML id of the content from children
   */
  id?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Title of the content from children
   */
  title?: Maybe<Scalars['String']['output']>;
};

/** TargetPositionsPreference Type */
export type TargetPositionsPreference = {
  __typename?: 'TargetPositionsPreference';
  targetPositions: Array<Maybe<Scalars['String']['output']>>;
};

export type TeamPermissionsDeleteMutationResult = {
  __typename?: 'TeamPermissionsDeleteMutationResult';
  error?: Maybe<TeamPermissionsMutationError>;
  success?: Maybe<TeamPermissionsResourceRight>;
};

export type TeamPermissionsDeleteResourceUserInput = {
  resourceId: Scalars['ID']['input'];
  resourceType: TeamPermissionsResourceType;
  /** When present, it assigns the role of the user being deleted to another user. Only implemented for XTM and XTP. */
  transferRoleTo?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type TeamPermissionsMutationError = Localizable & {
  __typename?: 'TeamPermissionsMutationError';
  localizationKey: Scalars['String']['output'];
  localizationValue: Scalars['String']['output'];
};

export type TeamPermissionsMutationResult = {
  __typename?: 'TeamPermissionsMutationResult';
  error?: Maybe<TeamPermissionsMutationError>;
  success?: Maybe<TeamPermissionsResourceRight>;
};

export type TeamPermissionsQuery = {
  __typename?: 'TeamPermissionsQuery';
  /** #rest #virtual */
  resourceRoles?: Maybe<TeamPermissionsRoles>;
  /** #rest #virtual */
  resourceUsers?: Maybe<TeamPermissionsResourceRights>;
  /** #rest #virtual */
  userResource?: Maybe<TeamPermissionsResourceRight>;
  /** #paginated #rest #virtual */
  userResources?: Maybe<TeamPermissionsResourceRightsConnection>;
  /** #paginated #rest #virtual */
  users?: Maybe<TeamPermissionsResourceRightsConnection>;
};

export type TeamPermissionsQueryResourceRolesArgs = {
  resourceType: TeamPermissionsResourceType;
};

export type TeamPermissionsQueryResourceUsersArgs = {
  resourceId: Scalars['ID']['input'];
  resourceType: TeamPermissionsResourceType;
};

export type TeamPermissionsQueryUserResourceArgs = {
  permissions?: InputMaybe<Scalars['String']['input']>;
  resourceId: Scalars['ID']['input'];
  resourceType: TeamPermissionsResourceType;
  types?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type TeamPermissionsQueryUserResourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  permissions?: InputMaybe<Scalars['String']['input']>;
  resourceType: TeamPermissionsResourceType;
  types?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type TeamPermissionsQueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  resourceId: Scalars['ID']['input'];
  resourceType: TeamPermissionsResourceType;
  roles?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type TeamPermissionsResource = Company | EntityPage;

export type TeamPermissionsResourceRight = {
  __typename?: 'TeamPermissionsResourceRight';
  /** Context is an object containg resource specific data. For XTM, XTP it contains information about blocked seats. */
  context?: Maybe<TeamPermissionsResourceRightContext>;
  permissions: Array<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  resource?: Maybe<TeamPermissionsResource>;
  resourceId: Scalars['ID']['output'];
  resourceType: TeamPermissionsResourceType;
  role?: Maybe<TeamPermissionsRole>;
  since: Scalars['Date']['output'];
  /** #rest(batched) #virtual */
  user?: Maybe<XingId>;
  userId: Scalars['ID']['output'];
};

export type TeamPermissionsResourceRightContext =
  TeamPermissionsResourceRightPaymentsApiContext;

export type TeamPermissionsResourceRightPaymentsApiContext = {
  __typename?: 'TeamPermissionsResourceRightPaymentsApiContext';
  /** For XTM, XTP whether seat is blocked */
  blocked?: Maybe<Scalars['Boolean']['output']>;
  /** For XTM, XTP it contains the date when the seat will be unblocked */
  unblockedAt?: Maybe<Scalars['Date']['output']>;
};

export type TeamPermissionsResourceRights = {
  __typename?: 'TeamPermissionsResourceRights';
  collection?: Maybe<Array<Maybe<TeamPermissionsResourceRight>>>;
};

export type TeamPermissionsResourceRightsConnection = HasCursorPagination & {
  __typename?: 'TeamPermissionsResourceRightsConnection';
  edges?: Maybe<Array<Maybe<TeamPermissionsResourceRightsEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type TeamPermissionsResourceRightsEdge = PaginationEdge & {
  __typename?: 'TeamPermissionsResourceRightsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TeamPermissionsResourceRight>;
};

/** Handling team permissions using the following API https://apidoc.xing.io/apidoc/?api=teampermissions/rest#/ */
export const enum TeamPermissionsResourceType {
  Briefings = 'BRIEFINGS',
  Companies = 'COMPANIES',
  Jobmanager = 'JOBMANAGER',
  Pages = 'PAGES',
  Referralmanager = 'REFERRALMANAGER',
  Talentmanager = 'TALENTMANAGER',
  Talentpoolmanager = 'TALENTPOOLMANAGER',
}

export type TeamPermissionsRole = {
  __typename?: 'TeamPermissionsRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TeamPermissionsRoles = {
  __typename?: 'TeamPermissionsRoles';
  collection?: Maybe<Array<Maybe<TeamPermissionsRole>>>;
};

export type TeamPermissionsUpdateResourceUserInput = {
  resourceId: Scalars['ID']['input'];
  resourceType: TeamPermissionsResourceType;
  roleId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type TeamPermissionsViewer = {
  __typename?: 'TeamPermissionsViewer';
  /** #paginated #rest #virtual */
  resources?: Maybe<TeamPermissionsResourceRightsConnection>;
  /** #rest #virtual */
  userResource?: Maybe<TeamPermissionsResourceRight>;
  /** #rest #virtual */
  userResources?: Maybe<TeamPermissionsResourceRights>;
};

export type TeamPermissionsViewerResourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  permissions?: InputMaybe<Scalars['String']['input']>;
  resourceType: TeamPermissionsResourceType;
  types?: InputMaybe<Scalars['String']['input']>;
};

export type TeamPermissionsViewerUserResourceArgs = {
  permissions?: InputMaybe<Scalars['String']['input']>;
  resourceId: Scalars['ID']['input'];
  resourceType: TeamPermissionsResourceType;
};

export type TeamPermissionsViewerUserResourcesArgs = {
  permissions?: InputMaybe<Scalars['String']['input']>;
  resourceType: TeamPermissionsResourceType;
  types?: InputMaybe<Scalars['String']['input']>;
};

export type TemplateData = {
  __typename?: 'TemplateData';
  /** #logged-out #soft-logged-in */
  companyDescriptionContent?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  companyDescriptionTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  contactInfoContent?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  contactInfoTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  footerImage?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  genericDescription?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  headerImage?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  responsibilityContent?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  responsibilityTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  sectionTitleColor?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  skillsContent?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  skillsTitle?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  socialUrls?: Maybe<TemplateDataSocialUrls>;
  /** #logged-out #soft-logged-in */
  videoUrls?: Maybe<TemplateDataVideoUrls>;
  /** #logged-out #soft-logged-in */
  weOfferContent?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  weOfferTitle?: Maybe<Scalars['String']['output']>;
};

export const enum TemplateDataFormat {
  Html = 'HTML',
  Markdown = 'MARKDOWN',
}

export type TemplateDataSocialUrls = {
  __typename?: 'TemplateDataSocialUrls';
  /** #logged-out #soft-logged-in */
  facebook?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  instagram?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  kununu?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  pinterest?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  tiktok?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  twitter?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  website?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  xing?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  youtube?: Maybe<Scalars['String']['output']>;
};

export type TemplateDataVideoUrls = {
  __typename?: 'TemplateDataVideoUrls';
  /** #logged-out #soft-logged-in */
  vimeo?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  youtube?: Maybe<Scalars['String']['output']>;
};

export type TextResourceKeys = {
  __typename?: 'TextResourceKeys';
  description?: Maybe<Scalars['String']['output']>;
  /** A text resource available in notification setting */
  name?: Maybe<Scalars['String']['output']>;
};

/** Simple text setting, usually represented as a input text box */
export type TextSetting = HasGlobalId & {
  __typename?: 'TextSetting';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

export type TextSettingInput = {
  value: Scalars['String']['input'];
};

export const enum TimeFrameFilterOptions {
  NinetyDays = 'NINETY_DAYS',
  SevenDays = 'SEVEN_DAYS',
  ThirtyDays = 'THIRTY_DAYS',
}

export type TimeInCurrentPosition = {
  __typename?: 'TimeInCurrentPosition';
  amount: Scalars['Int']['output'];
  avatars: Array<Maybe<Scalars['URL']['output']>>;
  id: Scalars['ID']['output'];
  xtmUrl: Scalars['URL']['output'];
};

export type TimeInCurrentPositionInsight = {
  __typename?: 'TimeInCurrentPositionInsight';
  medianTimeInCurrentPosition: Scalars['String']['output'];
  medianTimeInCurrentPositionCandidates: Scalars['Int']['output'];
  stats: Array<Maybe<TimeInCurrentPosition>>;
  xtmUrl: Scalars['URL']['output'];
};

/** Simple toggle setting, usually represented as a checkbox */
export type ToggleSetting = HasGlobalId & {
  __typename?: 'ToggleSetting';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Globally unique id for this instance */
  globalId: Scalars['GlobalID']['output'];
  value: Scalars['Boolean']['output'];
};

export type ToggleSettingInput = {
  value: Scalars['Boolean']['input'];
};

export type TopCompaniesByCityCollection = {
  __typename?: 'TopCompaniesByCityCollection';
  /** #expand #logged-out #soft-logged-in */
  collection: Array<Maybe<TopCompanyByCity>>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type TopCompaniesCollection = {
  __typename?: 'TopCompaniesCollection';
  /** #expand #logged-out #soft-logged-in */
  collection: Array<Maybe<TopCompany>>;
  /** #logged-out #soft-logged-in */
  total: Scalars['Int']['output'];
};

export type TopCompaniesElement = {
  __typename?: 'TopCompaniesElement';
  company?: Maybe<Company>;
  total: Scalars['Int']['output'];
};

export type TopCompany = {
  __typename?: 'TopCompany';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  company?: Maybe<Company>;
};

export type TopCompanyByCity = {
  __typename?: 'TopCompanyByCity';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  company?: Maybe<Company>;
};

export type TopIndustriesElement = {
  __typename?: 'TopIndustriesElement';
  /** #rest(batched) #virtual */
  industry?: Maybe<Industry>;
  industryId: Scalars['ID']['output'];
  total: Scalars['Int']['output'];
};

export type TopRecruiter = {
  __typename?: 'TopRecruiter';
  /** #logged-out #soft-logged-in #rest #virtual */
  hiringProfile?: Maybe<HiringProfile>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in #rest #virtual */
  profileWorkExperience?: Maybe<ProfileWorkExperienceCollection>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
};

export type TopRecruitersCollection = {
  __typename?: 'TopRecruitersCollection';
  /** #logged-out #soft-logged-in */
  collection?: Maybe<Array<Maybe<TopRecruiter>>>;
};

export type Topics = {
  __typename?: 'Topics';
  /** #logged-out #soft-logged-in */
  label?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  name?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  subjects?: Maybe<Array<Maybe<Subjects>>>;
};

export type TrackingError = {
  __typename?: 'TrackingError';
  error?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type TrackingResult = {
  __typename?: 'TrackingResult';
  error?: Maybe<TrackingError>;
  success?: Maybe<TrackingSuccess>;
};

export const enum TrackingSource {
  Calendar = 'CALENDAR',
  Itjfm = 'ITJFM',
  Messages = 'MESSAGES',
  SearchKeywords = 'SEARCH_KEYWORDS',
  Stan = 'STAN',
  StreamTrack = 'STREAM_TRACK',
  UserLocation = 'USER_LOCATION',
}

export type TrackingSuccess = {
  __typename?: 'TrackingSuccess';
  message?: Maybe<Scalars['String']['output']>;
};

/** Represents a tracking variable, name/value pair */
export type TrackingVariable = {
  __typename?: 'TrackingVariable';
  /** #soft-logged-in */
  name: Scalars['String']['output'];
  /** #soft-logged-in */
  value: Scalars['String']['output'];
};

export type Tracy = {
  __typename?: 'Tracy';
  profileQualityIndex?: Maybe<TracyProfileQualityIndex>;
  willingnessToChangeJobs?: Maybe<TracyWillingnessToChangeJobs>;
};

export type TracyProfileQualityIndex = {
  __typename?: 'TracyProfileQualityIndex';
  overall?: Maybe<TracyProfileQualityIndexScore>;
};

export type TracyProfileQualityIndexScore = {
  __typename?: 'TracyProfileQualityIndexScore';
  qualityClass?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type TracyWillingnessToChangeJobs = {
  __typename?: 'TracyWillingnessToChangeJobs';
  class?: Maybe<TracyWillingnessToChangeJobsClass>;
  probability?: Maybe<Scalars['Float']['output']>;
  reasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Willingness to change jobs available classes */
export const enum TracyWillingnessToChangeJobsClass {
  /** high */
  High = 'HIGH',
  /** low */
  Low = 'LOW',
  /** medium */
  Medium = 'MEDIUM',
}

export type TwoFaSettings = HasGlobalId & {
  __typename?: 'TwoFaSettings';
  /** Globally unique id for this instance */
  globalId: Scalars['GlobalID']['output'];
  method: TwoFaSettingsMethod;
};

export type TwoFaSettingsActivationInput = {
  /** one-time code either generated by authenticator app, or received using SMS */
  code: Scalars['String']['input'];
};

export type TwoFaSettingsActivationResult = {
  __typename?: 'TwoFaSettingsActivationResult';
  error?: Maybe<LoginSettingsError>;
  success?: Maybe<TwoFaSettingsBackupCode>;
};

export type TwoFaSettingsBackupCode = {
  __typename?: 'TwoFaSettingsBackupCode';
  backupCode: Scalars['String']['output'];
};

export type TwoFaSettingsBackupCodeRegenerationInput = {
  passwordToken: Scalars['String']['input'];
};

export type TwoFaSettingsBackupCodeRegenerationResult = {
  __typename?: 'TwoFaSettingsBackupCodeRegenerationResult';
  error?: Maybe<LoginSettingsError>;
  success?: Maybe<TwoFaSettingsBackupCode>;
};

export const enum TwoFaSettingsMethod {
  Authenticator = 'AUTHENTICATOR',
  None = 'NONE',
  Sms = 'SMS',
}

export type TwoFaSettingsUpdate = {
  __typename?: 'TwoFaSettingsUpdate';
  method: TwoFaSettingsMethod;
  /** Returned to the user in authenticator case so that QR code can be displayed */
  totpUrl?: Maybe<Scalars['String']['output']>;
};

export type TwoFaSettingsUpdateInput = {
  method: TwoFaSettingsMethod;
  passwordToken: Scalars['String']['input'];
};

export type TwoFaSettingsUpdateResult = {
  __typename?: 'TwoFaSettingsUpdateResult';
  error?: Maybe<LoginSettingsError>;
  success?: Maybe<TwoFaSettingsUpdate>;
};

export type UnauthorizedJob = {
  __typename?: 'UnauthorizedJob';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  reason: UnauthorizedJobReason;
};

export const enum UnauthorizedJobReason {
  Private = 'PRIVATE',
  Projobs = 'PROJOBS',
}

/**
 * Represents a type that can be retrieved by a URL.
 *
 * Copied from [GitHub's GraphQL API](https://docs.github.com/en/graphql/reference/interfaces#uniformresourcelocatable)
 */
export type UniformResourceLocatable = {
  /** The full `URL` to this resource */
  url: Scalars['URL']['output'];
};

/** Represents a type that can be represented by a URN. For example urn:x-xing:communities:group:1234 */
export type UniformResourceNameable = {
  /** The `URN` for a resource, e.g. urn:x-xing:communities:group:1234 */
  urn: Scalars['URN']['output'];
};

/** Available params for company search */
export type UniversalSearchCompanyQueryInput = {
  aggregations?: InputMaybe<CompanyAggregationsInput>;
  /** Request consumer, used for tracking and analysis purposes */
  consumer: Scalars['String']['input'];
  filters?: InputMaybe<CompanyFiltersInput>;
  /** Params expected for highlight input are documented in HighlightInput field */
  highlight?: InputMaybe<HighlightInput>;
  /** Free text search for keywords */
  keywords?: InputMaybe<Scalars['String']['input']>;
  queries?: InputMaybe<CompanyQueriesInput>;
  /** timeout for Elasticsearch call */
  timeout?: InputMaybe<Scalars['Int']['input']>;
};

export type UniversalSearchCompanyResult = {
  __typename?: 'UniversalSearchCompanyResult';
  /** #rest(batched) #virtual */
  company?: Maybe<Company>;
  highlight?: Maybe<HighlightResultCompany>;
  position: Scalars['Int']['output'];
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
};

export type UniversalSearchGroupQueryInput = {
  aggregations?: InputMaybe<GroupAggregationsInput>;
  /**
   * Available params for group search
   * keywords: Free text search for keywords
   * timeout: timeout for Elasticsearch call
   */
  consumer: Scalars['String']['input'];
  filters?: InputMaybe<GroupFiltersInput>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  timeout?: InputMaybe<Scalars['Int']['input']>;
};

export type UniversalSearchGroupResult = HasTrackingToken & {
  __typename?: 'UniversalSearchGroupResult';
  /** @deprecated Groups are going to be shutdown at the end of 2022. This field will stop working on 01/01/2023. */
  group?: Maybe<Community>;
  position?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  /**
   * #soft-logged-in
   *
   *  Tracking token generated by predictive search service to track responses. Used for DS tracking
   */
  trackingToken: Scalars['String']['output'];
};

export type UniversalSearchNewsQueryInput = {
  aggregations?: InputMaybe<NewsAggregationsInput>;
  /**
   * Available params for News search
   * keywords: Free text search for keywords
   * timeout: timeout for Elasticsearch call
   */
  consumer: Scalars['String']['input'];
  filters?: InputMaybe<NewsFiltersInput>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  timeout?: InputMaybe<Scalars['Int']['input']>;
};

export type UniversalSearchNewsResult = {
  position: Scalars['Int']['output'];
  score: Scalars['Float']['output'];
  trackingToken: Scalars['String']['output'];
  urn?: Maybe<Scalars['URN']['output']>;
};

/** UnpreferredPositionsPreference Type */
export type UnpreferredPositionsPreference = {
  __typename?: 'UnpreferredPositionsPreference';
  unpreferredPositions: Array<Maybe<JobTitleItem>>;
};

export type UpdateB2BProfileSettingsMutationResult = {
  __typename?: 'UpdateB2BProfileSettingsMutationResult';
  error?: Maybe<B2BProfileSettingsError>;
  success?: Maybe<B2BProfileSettingsSuccess>;
};

export type UpdateBlockedCompaniesError = {
  __typename?: 'UpdateBlockedCompaniesError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateBlockedCompaniesInput = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Result type for updating blocked companies that returns 204 on success */
export type UpdateBlockedCompaniesResult =
  | UpdateBlockedCompaniesError
  | UpdateBlockedCompaniesSuccess;

export type UpdateBlockedCompaniesSuccess = {
  __typename?: 'UpdateBlockedCompaniesSuccess';
  /** #rest(batched) #virtual */
  companies: Array<Maybe<Company>>;
};

export type UpdateBpayPaymentMethodResult =
  | DirectDebitData
  | DirectDebitDataError
  | ErrorResponse
  | InvoiceData;

export type UpdateCareerLevelPreferenceError = {
  __typename?: 'UpdateCareerLevelPreferenceError';
  /**
   * #logged-out #soft-logged-in
   *
   *  tells what went wrong
   */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateCareerLevelPreferenceInput = {
  /** A string identifying the conceptual user flow from which originates, for example, CoolNewCampaignFlow */
  flowIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Max prefered career level */
  maximum?: InputMaybe<Scalars['Int']['input']>;
  /** Min prefered career level */
  minimum?: InputMaybe<Scalars['Int']['input']>;
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this originates from */
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Result type for updating career level preference that returns 200 on success */
export type UpdateCareerLevelPreferenceResult =
  | UpdateCareerLevelPreferenceError
  | UpdateCareerLevelPreferenceSuccess;

export type UpdateCareerLevelPreferenceSuccess = {
  __typename?: 'UpdateCareerLevelPreferenceSuccess';
  /** #logged-out #soft-logged-in */
  maximum?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  minimum?: Maybe<Scalars['Int']['output']>;
};

export type UpdateClientBillingAddressResult = {
  __typename?: 'UpdateClientBillingAddressResult';
  error?: Maybe<ErrorResponse>;
  response?: Maybe<ClientBillingAddress>;
};

export type UpdateDisciplinesPreferenceError = {
  __typename?: 'UpdateDisciplinesPreferenceError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateDisciplinesPreferenceInput = {
  disciplineIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Result type for updating disciplines that returns 204 on success */
export type UpdateDisciplinesPreferenceResult =
  | UpdateDisciplinesPreferenceError
  | UpdateDisciplinesPreferenceSuccess;

export type UpdateDisciplinesPreferenceSuccess = {
  __typename?: 'UpdateDisciplinesPreferenceSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type UpdateEmployersPreferenceError = {
  __typename?: 'UpdateEmployersPreferenceError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateEmployersPreferenceInput = {
  /** List of company ids that user prefers as a potential employer */
  companyIds: Array<InputMaybe<Scalars['ID']['input']>>;
  /** A string identifying the conceptual user flow from which originates, for example, CoolNewCampaignFlow */
  flowIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this originates from */
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Result type for updating preferred employers for 204 success */
export type UpdateEmployersPreferenceResult =
  | UpdateEmployersPreferenceError
  | UpdateEmployersPreferenceSuccess;

/** Result type for updating preferred employers successfully */
export type UpdateEmployersPreferenceSuccess = {
  __typename?: 'UpdateEmployersPreferenceSuccess';
  /** #rest(batched) #virtual */
  companies: Array<Maybe<Company>>;
};

export type UpdateHiringProfileError = {
  __typename?: 'UpdateHiringProfileError';
  errors?: Maybe<UpdateHiringProfileErrors>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateHiringProfileErrors = {
  __typename?: 'UpdateHiringProfileErrors';
  hiringCities?: Maybe<Array<Scalars['String']['output']>>;
  hiringCompanies?: Maybe<Array<Scalars['String']['output']>>;
  hiringDisciplines?: Maybe<Array<Scalars['String']['output']>>;
  hiringJobPostings?: Maybe<Array<Scalars['String']['output']>>;
  hiringJobRoles?: Maybe<Array<Scalars['String']['output']>>;
  hiringQuestionTimestamp?: Maybe<Array<Scalars['String']['output']>>;
  hiringSkills?: Maybe<Array<Scalars['String']['output']>>;
  hiringSpotlightSummary?: Maybe<Array<Scalars['String']['output']>>;
};

export type UpdateHiringProfileInput = {
  hiringCities?: InputMaybe<Array<InputMaybe<HiringEntityInput>>>;
  hiringCompanies?: InputMaybe<Array<InputMaybe<HiringEntityInput>>>;
  hiringDisciplines?: InputMaybe<Array<InputMaybe<HiringEntityInput>>>;
  hiringJobPostings?: InputMaybe<Array<InputMaybe<HiringEntityInput>>>;
  hiringJobRoles?: InputMaybe<Array<InputMaybe<HiringJobRolesInput>>>;
  hiringQuestionTimestamp?: InputMaybe<Scalars['Date']['input']>;
  hiringSkills?: InputMaybe<Array<InputMaybe<HiringSkillsInput>>>;
  hiringSpotlightSummary?: InputMaybe<HiringSpotLightSummaryInput>;
};

export type UpdateHiringProfileResult =
  | HiringProfile
  | UpdateHiringProfileError;

export type UpdateIndustriesPreferenceError = {
  __typename?: 'UpdateIndustriesPreferenceError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateIndustriesPreferenceInput = {
  /** A string identifying the conceptual user flow from which originates, for example, CoolNewCampaignFlow */
  flowIdentifier?: InputMaybe<Scalars['String']['input']>;
  industries?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  openToOtherIndustries?: InputMaybe<Scalars['Boolean']['input']>;
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this originates from */
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Result type for updating industries preference that returns 200 on success */
export type UpdateIndustriesPreferenceResult =
  | UpdateIndustriesPreferenceError
  | UpdateIndustriesPreferenceSuccess;

export type UpdateIndustriesPreferenceSuccess = {
  __typename?: 'UpdateIndustriesPreferenceSuccess';
  /** #rest(batched) #virtual */
  industries: Array<Maybe<Industry>>;
  openToOtherIndustries?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateJobPreferenceUserFlagsError = {
  __typename?: 'UpdateJobPreferenceUserFlagsError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateJobPreferenceUserFlagsInput = {
  serpBannerHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Result type for updating job preference user flags that returns 200 on success */
export type UpdateJobPreferenceUserFlagsResult =
  | UpdateJobPreferenceUserFlagsError
  | UpdateJobPreferenceUserFlagsSuccess;

export type UpdateJobPreferenceUserFlagsSuccess = {
  __typename?: 'UpdateJobPreferenceUserFlagsSuccess';
  serpBannerHidden: Scalars['Boolean']['output'];
};

export type UpdateJobPreferencesJobSeekerStatusError = {
  __typename?: 'UpdateJobPreferencesJobSeekerStatusError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateJobPreferencesJobSeekerStatusInput = {
  status: PreferenceSeekingStatus;
};

/** Result type for updating job seeker status that returns 204 on success */
export type UpdateJobPreferencesJobSeekerStatusResult =
  | UpdateJobPreferencesJobSeekerStatusError
  | UpdateJobPreferencesJobSeekerStatusSuccess;

export type UpdateJobPreferencesJobSeekerStatusSuccess = {
  __typename?: 'UpdateJobPreferencesJobSeekerStatusSuccess';
  status: PreferenceSeekingStatus;
};

export type UpdateJobTitlesTargetPositionsPreferenceError = {
  __typename?: 'UpdateJobTitlesTargetPositionsPreferenceError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateJobTitlesTargetPositionsPreferenceInput = {
  /** A string identifying the conceptual user flow from which originates, for example, CoolNewCampaignFlow */
  flowIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this originates from */
  source?: InputMaybe<Scalars['String']['input']>;
  targetPositions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Result type for updating job titles target positions that returns 204 on success */
export type UpdateJobTitlesTargetPositionsPreferenceResult =
  | UpdateJobTitlesTargetPositionsPreferenceError
  | UpdateJobTitlesTargetPositionsPreferenceSuccess;

export type UpdateJobTitlesTargetPositionsPreferenceSuccess = {
  __typename?: 'UpdateJobTitlesTargetPositionsPreferenceSuccess';
  targetPositions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  value?: Maybe<Scalars['String']['output']>;
};

export type UpdateJobTitlesUnpreferredPositionsPreferenceError = {
  __typename?: 'UpdateJobTitlesUnpreferredPositionsPreferenceError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateJobTitlesUnpreferredPositionsPreferenceInput = {
  /** A string identifying the conceptual user flow from which originates, for example, CoolNewCampaignFlow */
  flowIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this originates from */
  source?: InputMaybe<Scalars['String']['input']>;
  unpreferredPositions?: InputMaybe<
    Array<InputMaybe<JobTitlesUnpreferredPositionsInput>>
  >;
};

/** Result type for updating job titles unpreferred positions that returns 204 on success */
export type UpdateJobTitlesUnpreferredPositionsPreferenceResult =
  | UpdateJobTitlesUnpreferredPositionsPreferenceError
  | UpdateJobTitlesUnpreferredPositionsPreferenceSuccess;

export type UpdateJobTitlesUnpreferredPositionsPreferenceSuccess = {
  __typename?: 'UpdateJobTitlesUnpreferredPositionsPreferenceSuccess';
  unpreferredPositions?: Maybe<Array<Maybe<JobTitleItem>>>;
  value?: Maybe<Scalars['String']['output']>;
};

export type UpdateJobWishesIdealEmployersError = {
  __typename?: 'UpdateJobWishesIdealEmployersError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateJobWishesIdealEmployersInput = {
  idealEmployerIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

/** Result type for updating ideal employers that returns 204 on success */
export type UpdateJobWishesIdealEmployersResult =
  | UpdateJobWishesIdealEmployersError
  | UpdateJobWishesIdealEmployersSuccess;

export type UpdateJobWishesIdealEmployersSuccess = {
  __typename?: 'UpdateJobWishesIdealEmployersSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type UpdateLocationsPreferenceError = {
  __typename?: 'UpdateLocationsPreferenceError';
  /**
   * #logged-out #soft-logged-in
   *
   *  tells what went wrong
   */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateLocationsPreferenceInput = {
  cities: Array<InputMaybe<Scalars['ID']['input']>>;
  /** A string identifying the conceptual user flow from which originates, for example, CoolNewCampaignFlow */
  flowIdentifier?: InputMaybe<Scalars['String']['input']>;
  maxDistance?: InputMaybe<Scalars['Int']['input']>;
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this originates from */
  source?: InputMaybe<Scalars['String']['input']>;
  willingnessToTravelPercentage?: InputMaybe<UpdateWillingnessToTravelEnum>;
};

/** Result type for updating locations preference that returns 200 on success */
export type UpdateLocationsPreferenceResult =
  | UpdateLocationsPreferenceError
  | UpdateLocationsPreferenceSuccess;

export type UpdateLocationsPreferenceSuccess = {
  __typename?: 'UpdateLocationsPreferenceSuccess';
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  cities: Array<Maybe<City>>;
  /** #logged-out #soft-logged-in */
  maxDistance?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  willingnessToTravel?: Maybe<GetWillingnessToTravelEnum>;
};

export type UpdateMessengerBannerError = {
  __typename?: 'UpdateMessengerBannerError';
  message: Scalars['String']['output'];
};

export type UpdateMessengerBannerInput = {
  chatId?: InputMaybe<Scalars['ID']['input']>;
  name: MessengerBannerType;
  userAction: MessengerBannerAction;
};

export type UpdateMessengerBannerResult =
  | UpdateMessengerBannerError
  | UpdateMessengerBannerSuccess;

export type UpdateMessengerBannerSuccess = {
  __typename?: 'UpdateMessengerBannerSuccess';
  success: Scalars['Boolean']['output'];
};

export type UpdateMessengerMessageTemplateError = {
  __typename?: 'UpdateMessengerMessageTemplateError';
  message: Scalars['String']['output'];
};

export type UpdateMessengerMessageTemplateInput = {
  body: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type UpdateMessengerMessageTemplateResult =
  | UpdateMessengerMessageTemplateError
  | UpdateMessengerMessageTemplateSuccess;

export type UpdateMessengerMessageTemplateSuccess = {
  __typename?: 'UpdateMessengerMessageTemplateSuccess';
  success: MessengerMessageTemplatesInterface;
};

export type UpdatePreferencesSettingsBlockedCompaniesError = {
  __typename?: 'UpdatePreferencesSettingsBlockedCompaniesError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdatePreferencesSettingsBlockedCompaniesInput = {
  companyIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

/** Result type for updating blocked companies that returns 204 on success */
export type UpdatePreferencesSettingsBlockedCompaniesResult =
  | UpdatePreferencesSettingsBlockedCompaniesError
  | UpdatePreferencesSettingsBlockedCompaniesSuccess;

export type UpdatePreferencesSettingsBlockedCompaniesSuccess = {
  __typename?: 'UpdatePreferencesSettingsBlockedCompaniesSuccess';
  value?: Maybe<Scalars['String']['output']>;
};

export type UpdateProfileModuleStoreItemsError = {
  __typename?: 'UpdateProfileModuleStoreItemsError';
  messages?: Maybe<Array<Scalars['String']['output']>>;
  moduleIdentifier: ProfileModuleIdentifier;
};

export type UpdateProfileModuleStoreItemsInput = {
  isActive: Scalars['Boolean']['input'];
  moduleIdentifier: ProfileModuleIdentifier;
};

export type UpdateProfileModuleStoreItemsResult = {
  __typename?: 'UpdateProfileModuleStoreItemsResult';
  error?: Maybe<Array<UpdateProfileModuleStoreItemsError>>;
  success?: Maybe<Array<ProfileModuleStoreItem>>;
};

export type UpdateReport400Error = {
  __typename?: 'UpdateReport400Error';
  /** #logged-out #soft-logged-in */
  errors?: Maybe<UpdateReportErrorMessages>;
};

export type UpdateReport403Error = {
  __typename?: 'UpdateReport403Error';
  /** #logged-out #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
};

export type UpdateReportErrorMessages = {
  __typename?: 'UpdateReportErrorMessages';
  /** #logged-out #soft-logged-in */
  reasonKey?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UpdateReportInput = {
  id: Scalars['ID']['input'];
  reasonKey?: InputMaybe<Scalars['String']['input']>;
  reporterEmail?: InputMaybe<Scalars['String']['input']>;
  userComment?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReportPutResult =
  | UpdateReport400Error
  | UpdateReport403Error
  | UpdateReportResponse;

export type UpdateReportResponse = {
  __typename?: 'UpdateReportResponse';
  /** #logged-out #soft-logged-in */
  report?: Maybe<ComplaintsReport>;
};

export type UpdateSalaryExpectationsPreferenceError = {
  __typename?: 'UpdateSalaryExpectationsPreferenceError';
  /**
   * #logged-out #soft-logged-in
   *
   *  tells what went wrong
   */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateSalaryExpectationsPreferenceInput = {
  /** A string identifying the conceptual user flow from which originates, for example, CoolNewCampaignFlow */
  flowIdentifier?: InputMaybe<Scalars['String']['input']>;
  salaryExpectations?: InputMaybe<Scalars['Int']['input']>;
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this originates from */
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Result type for updating salary expectations preference that returns 200 on success */
export type UpdateSalaryExpectationsPreferenceResult =
  | UpdateSalaryExpectationsPreferenceError
  | UpdateSalaryExpectationsPreferenceSuccess;

export type UpdateSalaryExpectationsPreferenceSuccess = {
  __typename?: 'UpdateSalaryExpectationsPreferenceSuccess';
  /** #logged-out #soft-logged-in */
  salaryExpectations?: Maybe<Scalars['Int']['output']>;
};

export type UpdateWebPushSubscriptionError = {
  __typename?: 'UpdateWebPushSubscriptionError';
  /** #logged-out #soft-logged-in */
  message: Scalars['String']['output'];
};

export type UpdateWebPushSubscriptionInput = {
  authDetails: Scalars['String']['input'];
  ellipticCurve: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  notificationEnv: Scalars['String']['input'];
  subscriptionStatus: Scalars['String']['input'];
  url: Scalars['String']['input'];
  visitorId: Scalars['String']['input'];
};

export type UpdateWebPushSubscriptionResult =
  | UpdateWebPushSubscriptionError
  | WebPushSubscription;

export const enum UpdateWillingnessToTravelEnum {
  Frequent = 'FREQUENT',
  None = 'NONE',
  Occasional = 'OCCASIONAL',
}

export type UpdateWorkingHoursPreferenceError = {
  __typename?: 'UpdateWorkingHoursPreferenceError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateWorkingHoursPreferenceInput = {
  /** A string identifying the conceptual user flow from which originates, for example, CoolNewCampaignFlow */
  flowIdentifier?: InputMaybe<Scalars['String']['input']>;
  fullTime: Scalars['Boolean']['input'];
  partTime: Scalars['Boolean']['input'];
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this originates from */
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Result type for updating working hours preference that returns 200 on success */
export type UpdateWorkingHoursPreferenceResult =
  | UpdateWorkingHoursPreferenceError
  | UpdateWorkingHoursPreferenceSuccess;

export type UpdateWorkingHoursPreferenceSuccess = {
  __typename?: 'UpdateWorkingHoursPreferenceSuccess';
  fullTime: Scalars['Boolean']['output'];
  partTime: Scalars['Boolean']['output'];
};

export type UpdateWorkplacePreferenceError = {
  __typename?: 'UpdateWorkplacePreferenceError';
  /** tells what went wrong */
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateWorkplacePreferenceInput = {
  /** A string identifying the conceptual user flow from which originates, for example, CoolNewCampaignFlow */
  flowIdentifier?: InputMaybe<Scalars['String']['input']>;
  homeOffice: Scalars['Boolean']['input'];
  mostlyHome: Scalars['Boolean']['input'];
  office: Scalars['Boolean']['input'];
  partlyHome: Scalars['Boolean']['input'];
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this originates from */
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Result type for updating workplace that returns 200 on success */
export type UpdateWorkplacePreferenceResult =
  | UpdateWorkplacePreferenceError
  | UpdateWorkplacePreferenceSuccess;

export type UpdateWorkplacePreferenceSuccess = {
  __typename?: 'UpdateWorkplacePreferenceSuccess';
  homeOffice: Scalars['Boolean']['output'];
  mostlyHome: Scalars['Boolean']['output'];
  office: Scalars['Boolean']['output'];
  partlyHome: Scalars['Boolean']['output'];
};

/**
 * Represents an upload that has been created at the upload service.
 * It contains the information that a client would need to push data
 * to that upload.
 */
export type Upload = {
  __typename?: 'Upload';
  /**
   * #logged-out #soft-logged-in
   *
   *  An Authorization token for this specific upload.
   * This has to be sent in the `Authorization` HTTP header by the client
   * with every request to the upload service that belongs to this upload.
   */
  authToken?: Maybe<Scalars['UploadAuthToken']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  The id of the upload. This can be used to retrieve the file from the internal API.
   * The client should pass this to whoever is going to process the upload.
   */
  id?: Maybe<Scalars['UploadId']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  The URL that the client can use to upload the file.
   */
  url?: Maybe<Scalars['URL']['output']>;
};

/**
 * The application that an upload is intended for.
 * Usually this is the application as described in the XING project metdata.
 */
export const enum UploadApplication {
  Articles = 'ARTICLES',
  CvUpload = 'CV_UPLOAD',
  EntityPages = 'ENTITY_PAGES',
  Events = 'EVENTS',
  Jobs = 'JOBS',
  Messenger = 'MESSENGER',
  Postings = 'POSTINGS',
  Profile = 'PROFILE',
  Projobs = 'PROJOBS',
  StartPage = 'START_PAGE',
  SupportPages = 'SUPPORT_PAGES',
  VitaLebenslauf = 'VITA_LEBENSLAUF',
}

export type UploadRequestError = {
  __typename?: 'UploadRequestError';
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type UploadRequestInput = {
  /** The application that this upload is intended for */
  application: UploadApplication;
  /** The file name */
  fileName?: InputMaybe<Scalars['String']['input']>;
  /** The size of the uploaded file in bytes */
  fileSize: Scalars['Long']['input'];
  /** The MIME type of the file */
  fileType?: InputMaybe<Scalars['String']['input']>;
};

export type UploadRequestPayload = {
  __typename?: 'UploadRequestPayload';
  /** #logged-out #soft-logged-in */
  error?: Maybe<UploadRequestError>;
  /** #logged-out #soft-logged-in */
  success?: Maybe<Upload>;
};

export type UpsellAndroidCampaignOffer = {
  __typename?: 'UpsellAndroidCampaignOffer';
  campaignId: Scalars['ID']['output'];
  campaignType: Scalars['String']['output'];
  marketingId: Scalars['String']['output'];
  offers: Array<UpsellAndroidOffer>;
};

export type UpsellAndroidOffer = {
  __typename?: 'UpsellAndroidOffer';
  highlighted: Scalars['Boolean']['output'];
  productId: Scalars['String']['output'];
};

export type UpsellCancellationAcceptCounterOfferApplyBonus = {
  applyBonus?: InputMaybe<Scalars['String']['input']>;
};

export type UpsellCancellationAcceptCounterOfferResult =
  | UpsellCancellationAcceptCounterOfferResultSuccess
  | UpsellMutationError;

export type UpsellCancellationAcceptCounterOfferResultSuccess = {
  __typename?: 'UpsellCancellationAcceptCounterOfferResultSuccess';
  message?: Maybe<Scalars['String']['output']>;
};

export type UpsellCancellationRequestInput = {
  isExtraordinary: Scalars['Boolean']['input'];
  product: UpsellProduct;
  reasonIdentifier: Scalars['String']['input'];
  reasonNote: Scalars['String']['input'];
};

export type UpsellCancellationRequestResult =
  | UpsellCancellationRequestSuccess
  | UpsellMutationError;

export type UpsellCancellationRequestSuccess = {
  __typename?: 'UpsellCancellationRequestSuccess';
  endDate?: Maybe<Scalars['Date']['output']>;
  isExtraordinary: Scalars['Boolean']['output'];
  product: UpsellProduct;
  reasonIdentifier: Scalars['String']['output'];
  reasonNote: Scalars['String']['output'];
  remainingProduct?: Maybe<UpsellProduct>;
};

export type UpsellCancellationStatus = {
  __typename?: 'UpsellCancellationStatus';
  endDate?: Maybe<Scalars['Date']['output']>;
  isEligibleForCounterOffer: Scalars['Boolean']['output'];
  product: UpsellProduct;
  remainingProduct?: Maybe<UpsellProduct>;
};

export type UpsellIosCampaignOffer = {
  __typename?: 'UpsellIosCampaignOffer';
  campaignId: Scalars['ID']['output'];
  campaignType: Scalars['String']['output'];
  marketingId: Scalars['String']['output'];
  offers: Array<UpsellIosOffer>;
};

export type UpsellIosOffer = {
  __typename?: 'UpsellIosOffer';
  highlighted: Scalars['Boolean']['output'];
  offerId?: Maybe<Scalars['String']['output']>;
  offerType: UpsellIosOfferType;
  productId: Scalars['String']['output'];
  signatureData?: Maybe<UpsellIosSignatureData>;
};

/** The different types of iOS offers */
export const enum UpsellIosOfferType {
  Intro = 'INTRO',
  Promo = 'PROMO',
  Regular = 'REGULAR',
}

export type UpsellIosSignatureData = {
  __typename?: 'UpsellIosSignatureData';
  base64Signature: Scalars['String']['output'];
  keyId: Scalars['String']['output'];
  nonce: Scalars['String']['output'];
  offerId: Scalars['String']['output'];
  timestamp: Scalars['Long']['output'];
  userName: Scalars['String']['output'];
};

export type UpsellMutationError = {
  __typename?: 'UpsellMutationError';
  errors: Scalars['String']['output'];
};

export const enum UpsellProduct {
  Premium = 'premium',
  Projobs = 'projobs',
}

export type UrlApplication = {
  __typename?: 'UrlApplication';
  /** #logged-out #soft-logged-in */
  applyUrl?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  scrambledApplyUrl?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UserCommenter = {
  __typename?: 'UserCommenter';
  /** #rest(batched) #virtual */
  profile?: Maybe<XingId>;
  socialType?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['GlobalID']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type UserConnectionData = {
  __typename?: 'UserConnectionData';
  clientId: Scalars['Int']['output'];
  clientName: Scalars['String']['output'];
};

export type UserDataInquiry = {
  __typename?: 'UserDataInquiry';
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** Download size in bytes */
  downloadSize?: Maybe<Scalars['Long']['output']>;
  /** Extra bundles that are not included in the users data disclosure */
  extraBundles?: Maybe<Array<UserDataInquiryBundle>>;
  /** Warning or info message */
  notificationMessage?: Maybe<Scalars['String']['output']>;
  notificationType?: Maybe<UserDataInquiryNotificationType>;
  status?: Maybe<UserDataInquiryStatus>;
};

export type UserDataInquiryBundle = {
  __typename?: 'UserDataInquiryBundle';
  /** Translated description including application name and purpose */
  label?: Maybe<Scalars['String']['output']>;
  /** Application identifier */
  name?: Maybe<Scalars['String']['output']>;
  status: UserDataInquiryStatus;
};

export type UserDataInquiryBundleDownloadUrl = {
  __typename?: 'UserDataInquiryBundleDownloadUrl';
  /** Application identifier */
  name?: Maybe<Scalars['String']['output']>;
  /** Temporary public download url */
  url?: Maybe<Scalars['String']['output']>;
};

export type UserDataInquiryError = {
  __typename?: 'UserDataInquiryError';
  errors?: Maybe<UserDataInquiryErrorFields>;
  message: Scalars['String']['output'];
  userFacingMessage?: Maybe<Scalars['String']['output']>;
};

export type UserDataInquiryErrorFields = {
  __typename?: 'UserDataInquiryErrorFields';
  passwordToken?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  userId?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UserDataInquiryInput = {
  passwordToken: Scalars['String']['input'];
};

export const enum UserDataInquiryNotificationType {
  Error = 'ERROR',
  Information = 'INFORMATION',
}

export const enum UserDataInquiryStatus {
  Processing = 'PROCESSING',
  Ready = 'READY',
}

export type UserDataInquiryTemporaryDownloadUrlResult = {
  error?: Maybe<UserDataInquiryError>;
  extraBundles?: Maybe<Array<UserDataInquiryBundleDownloadUrl>>;
  temporaryDownloadUrl?: Maybe<Scalars['String']['output']>;
};

export type UserDataInquiryTemporaryDownloadUrlResultError =
  UserDataInquiryTemporaryDownloadUrlResult & {
    __typename?: 'UserDataInquiryTemporaryDownloadUrlResultError';
    error?: Maybe<UserDataInquiryError>;
    extraBundles?: Maybe<Array<UserDataInquiryBundleDownloadUrl>>;
    temporaryDownloadUrl?: Maybe<Scalars['String']['output']>;
  };

export type UserDataInquiryTemporaryDownloadUrlResultSuccess =
  UserDataInquiryTemporaryDownloadUrlResult & {
    __typename?: 'UserDataInquiryTemporaryDownloadUrlResultSuccess';
    error?: Maybe<UserDataInquiryError>;
    extraBundles?: Maybe<Array<UserDataInquiryBundleDownloadUrl>>;
    temporaryDownloadUrl?: Maybe<Scalars['String']['output']>;
  };

export type UserEntityPages = {
  __typename?: 'UserEntityPages';
  collection: Array<Maybe<EntityPage>>;
};

export type UserFeebackOnOutdatedFieldsResponse = {
  __typename?: 'UserFeebackOnOutdatedFieldsResponse';
  message?: Maybe<Scalars['String']['output']>;
  saved: Scalars['Boolean']['output'];
};

export type UserFeedbackRequest = {
  fields: Scalars['String']['input'];
};

export type UserFlagDetails = {
  __typename?: 'UserFlagDetails';
  /** #logged-out #soft-logged-in */
  action?: Maybe<UserFlagDetailsAction>;
  /** #logged-out #soft-logged-in */
  body?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #logged-out #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
};

export type UserFlagDetailsAction = {
  __typename?: 'UserFlagDetailsAction';
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  trackingId?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  urn?: Maybe<Scalars['GlobalID']['output']>;
};

export type UserFlags = {
  __typename?: 'UserFlags';
  /** #logged-out #soft-logged-in */
  displayFlag?: Maybe<UserFlagsDisplayFlag>;
  /** #logged-out #soft-logged-in */
  userFlags?: Maybe<Array<Maybe<UserFlagsDisplayFlag>>>;
  /** #logged-out #soft-logged-in */
  userId?: Maybe<Scalars['ID']['output']>;
};

/** Available User Flags Display Flags */
export const enum UserFlagsDisplayFlag {
  /** A user with ambassador role */
  Ambassador = 'AMBASSADOR',
  /** Default user role and membership */
  Basic = 'BASIC',
  /** A user with executive membership */
  Executive = 'EXECUTIVE',
  /** A user with insider role */
  Insider = 'INSIDER',
  /** A user with moderator role */
  Moderator = 'MODERATOR',
  /** A user with premium membership */
  Premium = 'PREMIUM',
  /** A user with ProCoach membership */
  ProCoach = 'PRO_COACH',
  /** A user with ProTrainer membership */
  ProTrainer = 'PRO_TRAINER',
}

export type UserInfoJs = {
  __typename?: 'UserInfoJS';
  userId: Scalars['String']['output'];
  userIdType: Scalars['String']['output'];
};

export type UserJobApplicationCv = {
  __typename?: 'UserJobApplicationCv';
  key: Scalars['String']['output'];
  lastUsedAt: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  url: Scalars['URL']['output'];
};

export type UserLiker = {
  __typename?: 'UserLiker';
  /** #rest(batched) #virtual */
  profile?: Maybe<XingId>;
  socialType?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['GlobalID']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type UserRemindApplicationError = {
  __typename?: 'UserRemindApplicationError';
  /** #logged-out #soft-logged-in */
  error?: Maybe<Scalars['String']['output']>;
};

export type UserRemindMutationInput = {
  email: Scalars['Email']['input'];
  locale: Scalars['String']['input'];
  postingId: Scalars['ID']['input'];
  postingUrl: Scalars['URL']['input'];
};

export type UserSearchOccurrences = {
  __typename?: 'UserSearchOccurrences';
  total?: Maybe<Scalars['Int']['output']>;
};

export type UserSettingsResponse =
  | SearchAlertErrorResponse
  | UserSettingsSuccessResponse;

export type UserSettingsSuccessResponse = {
  __typename?: 'UserSettingsSuccessResponse';
  alertEnabled: Scalars['Boolean']['output'];
  alertFrequency: EmailFrequency;
};

export type UserSettingsUpdateInput = {
  emailEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  emailFrequency?: InputMaybe<EmailFrequency>;
};

export type UserType = {
  __typename?: 'UserType';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VCard = {
  __typename?: 'VCard';
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstname?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<MymkGenderEstimate>;
  isOwn?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['ID']['output'];
  jobtitle?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
};

/** Visitor of my Profile */
export type Vomp = {
  __typename?: 'VOMP';
  /**
   * #rest(batched) #virtual
   *
   *  Distance between the user and the profile visitor
   */
  contactDistance?: Maybe<ContactDistance>;
  /** ID of the profile visit. */
  id: Scalars['ID']['output'];
  /**
   * #rest(batched) #virtual
   *
   *  XingId representation of the profile visitor
   */
  visitor?: Maybe<XingId>;
  /** ID of the user who visited the profile. 0 for logged-out visits. */
  visitorId?: Maybe<Scalars['ID']['output']>;
};

export type VompConnection = HasCursorPagination & {
  __typename?: 'VOMPConnection';
  edges: Array<VompEdge>;
  /** #virtual */
  pageInfo: PageInfo;
  /**
   * #virtual
   *
   *  Total number of visits
   */
  total: Scalars['Int']['output'];
};

export type VompEdge = PaginationEdge & {
  __typename?: 'VOMPEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node: Vomp;
};

/** VOMP Module aka VisibilityInsights */
export type VompModule = ModuleInterface & {
  __typename?: 'VOMPModule';
  active?: Maybe<Scalars['Boolean']['output']>;
  globalId: Scalars['GlobalID']['output'];
  /** #rest #virtual */
  numberOfNewVisits?: Maybe<NumberOfNewProfileVisits>;
  order: Scalars['Int']['output'];
  /** #rest #virtual */
  statistics?: Maybe<VompStatistics>;
  title: Scalars['String']['output'];
  /** #paginated #rest #virtual */
  visitorsOfMyProfile?: Maybe<VompConnection>;
};

/** VOMP Module aka VisibilityInsights */
export type VompModuleVisitorsOfMyProfileArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type VerticalNavigationMutationError = {
  __typename?: 'VerticalNavigationMutationError';
  message?: Maybe<Scalars['String']['output']>;
};

export type Video = {
  __typename?: 'Video';
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  ownerGlobalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  sources?: Maybe<VideoSources>;
  /** #logged-out #soft-logged-in */
  sprites?: Maybe<Array<VideoImage>>;
  /** #logged-out #soft-logged-in */
  status: VideoStatus;
  /** #logged-out #soft-logged-in */
  subtitles?: Maybe<Array<VideoSubtitle>>;
  /** #logged-out #soft-logged-in */
  thumbnails?: Maybe<Array<VideoImage>>;
  /** #logged-out #soft-logged-in */
  title: Scalars['String']['output'];
};

export type VideoAdImage = {
  __typename?: 'VideoAdImage';
  /** #logged-out #soft-logged-in */
  height: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  src: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  width: Scalars['Int']['output'];
};

export type VideoAttachment = {
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  type?: Maybe<Scalars['String']['output']>;
};

export type VideoCreationInput = {
  apiKey: Scalars['String']['input'];
  encodingTriggerMode?: InputMaybe<VideoEncodingTriggerMode>;
  mute?: InputMaybe<Scalars['Boolean']['input']>;
  ownerGlobalId: Scalars['GlobalID']['input'];
};

export type VideoCustomThumbnailCreationInput = {
  apiKey: Scalars['String']['input'];
  uploadId: Scalars['UploadId']['input'];
  videoRef: Scalars['String']['input'];
};

export type VideoCustomThumbnailDeletionInput = {
  apiKey: Scalars['String']['input'];
  customThumbnailId: Scalars['ID']['input'];
  videoRef: Scalars['String']['input'];
};

export type VideoCustomThumbnailUpdateInput = {
  apiKey: Scalars['String']['input'];
  customThumbnailId: Scalars['ID']['input'];
  uploadId: Scalars['UploadId']['input'];
  videoRef: Scalars['String']['input'];
};

export type VideoDataInput = {
  filename: Scalars['String']['input'];
  ownerUrn: Scalars['GlobalID']['input'];
};

export type VideoDeletionInput = {
  apiKey: Scalars['String']['input'];
  videoRef: Scalars['String']['input'];
};

export type VideoEmptyResult = {
  __typename?: 'VideoEmptyResult';
  error?: Maybe<VideoMutationError>;
};

export const enum VideoEncodingTriggerMode {
  /** Encoding will start when the client explicitly starts it */
  Explicit = 'EXPLICIT',
  /** Encoding will start as soon as the video upload is done */
  Immediate = 'IMMEDIATE',
}

export type VideoError = {
  __typename?: 'VideoError';
  /** #logged-out #soft-logged-in */
  errorCode: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  errorType: Scalars['String']['output'];
};

export type VideoImage = {
  __typename?: 'VideoImage';
  /** #logged-out #soft-logged-in */
  height: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  source: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  width: Scalars['Int']['output'];
};

export type VideoImageAttachment = VideoAttachment & {
  __typename?: 'VideoImageAttachment';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  links?: Maybe<VideoImageAttachmentLinks>;
  /** #logged-out #soft-logged-in */
  type?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  unit: VideoImageAttachmentUnit;
  /** #logged-out #soft-logged-in */
  x: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  y: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  zIndex: Scalars['Int']['output'];
};

export type VideoImageAttachmentCreationInput = {
  apiKey: Scalars['String']['input'];
  resourceId: Scalars['ID']['input'];
  unit?: InputMaybe<VideoImageAttachmentUnit>;
  videoRef: Scalars['String']['input'];
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
  zIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type VideoImageAttachmentCreationResult = {
  __typename?: 'VideoImageAttachmentCreationResult';
  error?: Maybe<VideoMutationError>;
  success?: Maybe<VideoImageAttachment>;
};

export type VideoImageAttachmentLinks = {
  __typename?: 'VideoImageAttachmentLinks';
  /** #logged-out #soft-logged-in */
  self: Scalars['URL']['output'];
};

export const enum VideoImageAttachmentUnit {
  Percentage = 'PERCENTAGE',
  Pixels = 'PIXELS',
}

export type VideoImageSource = {
  __typename?: 'VideoImageSource';
  /** #logged-out #soft-logged-in */
  height: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  source: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  width: Scalars['Int']['output'];
};

export type VideoLinks = {
  __typename?: 'VideoLinks';
  /** #logged-out #soft-logged-in */
  self: Scalars['URL']['output'];
  /** Only available when the video is created or updated (and a reupload is requested) */
  uploadUrl?: Maybe<Scalars['URL']['output']>;
};

/** Generic video mutation error */
export type VideoMutationError = {
  __typename?: 'VideoMutationError';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type VideoMutationResult = {
  __typename?: 'VideoMutationResult';
  error?: Maybe<VideoMutationError>;
  success?: Maybe<VideoV2>;
};

export type VideoPreviewMetadata = PreviewMetadata & {
  __typename?: 'VideoPreviewMetadata';
  embedUrl?: Maybe<Scalars['URL']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  sourceActor?: Maybe<LinkPreviewSourceActor>;
  width?: Maybe<Scalars['String']['output']>;
};

export type VideoResource = {
  __typename?: 'VideoResource';
  format: VideoResourceFormat;
  global: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  links?: Maybe<VideoResourceLinks>;
  ownerGlobalId: Scalars['GlobalID']['output'];
};

export type VideoResourceCreationInput = {
  apiKey: Scalars['String']['input'];
  format: VideoResourceFormat;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  ownerGlobalId: Scalars['GlobalID']['input'];
};

export type VideoResourceCreationResult = {
  __typename?: 'VideoResourceCreationResult';
  error?: Maybe<VideoMutationError>;
  success?: Maybe<VideoResource>;
};

export const enum VideoResourceFormat {
  Png = 'PNG',
}

export type VideoResourceLinks = {
  __typename?: 'VideoResourceLinks';
  self: Scalars['URL']['output'];
  /** Only available when the resource is created or updated (and a reupload is requested) */
  uploadUrl?: Maybe<Scalars['URL']['output']>;
};

export type VideoSource = {
  __typename?: 'VideoSource';
  /** #logged-out #soft-logged-in */
  format: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  source: Scalars['URL']['output'];
};

export type VideoSources = {
  __typename?: 'VideoSources';
  /** #logged-out #soft-logged-in */
  dash?: Maybe<Scalars['URL']['output']>;
  /** #logged-out #soft-logged-in */
  hls: Scalars['URL']['output'];
};

export type VideoSprite = VideoV2Image & {
  __typename?: 'VideoSprite';
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  sources?: Maybe<Array<VideoImageSource>>;
};

export type VideoStartEncodingInput = {
  apiKey: Scalars['String']['input'];
  videoRef: Scalars['String']['input'];
};

export const enum VideoStatus {
  Available = 'AVAILABLE',
  Encoding = 'ENCODING',
  Enqueued = 'ENQUEUED',
  Failed = 'FAILED',
}

export type VideoSubtitle = {
  __typename?: 'VideoSubtitle';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  label: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  language: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  source: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  type: VideoSubtitleType;
};

export type VideoSubtitleCreationInput = {
  apiKey: Scalars['String']['input'];
  label: Scalars['String']['input'];
  language: Scalars['String']['input'];
  uploadId: Scalars['UploadId']['input'];
  videoRef: Scalars['String']['input'];
};

export type VideoSubtitleDeletionInput = {
  apiKey: Scalars['String']['input'];
  subtitleId: Scalars['ID']['input'];
  videoRef: Scalars['String']['input'];
};

export type VideoSubtitleInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  subtitleId?: InputMaybe<Scalars['ID']['input']>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

export const enum VideoSubtitleType {
  Srt = 'SRT',
  Webvtt = 'WEBVTT',
}

export type VideoSubtitleUpdateInput = {
  apiKey: Scalars['String']['input'];
  label: Scalars['String']['input'];
  language: Scalars['String']['input'];
  subtitleId: Scalars['ID']['input'];
  uploadId: Scalars['UploadId']['input'];
  videoRef: Scalars['String']['input'];
};

export type VideoThumbnail = VideoV2Image & {
  __typename?: 'VideoThumbnail';
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  sources?: Maybe<Array<VideoImageSource>>;
};

export type VideoTrackingEvent = {
  clientTimestamp: Scalars['String']['input'];
  extraFields?: InputMaybe<Scalars['JSON']['input']>;
  siteSection: Scalars['String']['input'];
  type: VideoTrackingEventType;
  videoRef: Scalars['SlugOrID']['input'];
};

export type VideoTrackingEventInput = {
  collection: Array<VideoTrackingEvent>;
};

export const enum VideoTrackingEventType {
  ActiveView = 'ACTIVE_VIEW',
  FiftyPercent = 'FIFTY_PERCENT',
  FullscreenExit = 'FULLSCREEN_EXIT',
  FullscreenStart = 'FULLSCREEN_START',
  Mute = 'MUTE',
  NinetyFivePercent = 'NINETY_FIVE_PERCENT',
  OneHundredPercent = 'ONE_HUNDRED_PERCENT',
  PlayerError = 'PLAYER_ERROR',
  Ready = 'READY',
  SetupError = 'SETUP_ERROR',
  SeventyFivePercent = 'SEVENTY_FIVE_PERCENT',
  Start = 'START',
  TenSeconds = 'TEN_SECONDS',
  ThreeSeconds = 'THREE_SECONDS',
  TwentyFivePercent = 'TWENTY_FIVE_PERCENT',
  Unmute = 'UNMUTE',
}

export type VideoTrackingMutationResult = {
  __typename?: 'VideoTrackingMutationResult';
  error?: Maybe<VideoError>;
};

export type VideoUpdateDataInput = {
  englishSubtitle?: InputMaybe<VideoSubtitleInput>;
  germanSubtitle?: InputMaybe<VideoSubtitleInput>;
  thumbnailUploadId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type VideoUpdateInput = {
  apiKey: Scalars['String']['input'];
  encodingTriggerMode?: InputMaybe<VideoEncodingTriggerMode>;
  mute?: InputMaybe<Scalars['Boolean']['input']>;
  reupload?: InputMaybe<Scalars['Boolean']['input']>;
  videoRef: Scalars['String']['input'];
};

export type VideoUploadDetails = {
  __typename?: 'VideoUploadDetails';
  globalId: Scalars['GlobalID']['output'];
  /** @deprecated UUID content should not be under `id`. In the future use `ref` */
  id?: Maybe<Scalars['SlugOrID']['output']>;
  links: VideoUploadLinks;
  ref?: Maybe<Scalars['SlugOrID']['output']>;
};

export type VideoUploadLinks = {
  __typename?: 'VideoUploadLinks';
  uploadUrl: Scalars['URL']['output'];
};

export type VideoUploadResponse = {
  videoDetails?: Maybe<VideoUploadDetails>;
};

export type VideoV2 = {
  __typename?: 'VideoV2';
  /** #logged-out #soft-logged-in */
  attachments?: Maybe<Array<VideoAttachment>>;
  /** #logged-out #soft-logged-in */
  customThumbnails?: Maybe<Array<VideoThumbnail>>;
  /** #logged-out #soft-logged-in */
  defaultThumbnails?: Maybe<Array<VideoThumbnail>>;
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  links?: Maybe<VideoLinks>;
  /**
   * #logged-out #soft-logged-in
   *
   *  The mute flag used on creation. Only available for new videos. This isn't 100% reliable, so use with caution
   */
  mute?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  ownerGlobalId: Scalars['GlobalID']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  This will be a String or an ID until everything is migrated
   */
  ref?: Maybe<Scalars['SlugOrID']['output']>;
  /** #logged-out #soft-logged-in */
  sources?: Maybe<Array<VideoSource>>;
  /** #logged-out #soft-logged-in */
  sprites?: Maybe<Array<VideoSprite>>;
  /** #logged-out #soft-logged-in */
  status: VideoV2Status;
  /** #logged-out #soft-logged-in */
  subtitles?: Maybe<Array<VideoV2Subtitle>>;
};

export type VideoV2Image = {
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  sources?: Maybe<Array<VideoImageSource>>;
};

export const enum VideoV2Status {
  Available = 'AVAILABLE',
  Created = 'CREATED',
  Encoding = 'ENCODING',
  Failed = 'FAILED',
}

export type VideoV2Subtitle = {
  __typename?: 'VideoV2Subtitle';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  label: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  language: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  sources: Array<VideoV2SubtitleSource>;
};

export type VideoV2SubtitleSource = {
  __typename?: 'VideoV2SubtitleSource';
  /** #logged-out #soft-logged-in */
  source: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  type: VideoSubtitleType;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type Viewer = {
  __typename?: 'Viewer';
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Account related settings
   */
  accountSettings?: Maybe<AccountSettings>;
  /**
   * #rest #virtual
   *
   *  User settings from activities
   */
  activitiesSettings?: Maybe<ActivitiesSettings>;
  /** #rest #virtual */
  advertisingScrambledId?: Maybe<Scalars['String']['output']>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  counter and stats from different apps
   */
  appStats?: Maybe<AppStats>;
  /**
   * #rest #virtual
   *
   *  B2B Profile Settings data
   */
  b2bProfileSettings?: Maybe<B2BProfileSettings>;
  /**
   * get insights banner
   *
   * ```
   * query bannerProperty {
   *   viewer {
   *     bannerProperty(propertyName: DISPLAY_INSIGHTS_BANNER) {
   *       value
   *     }
   *   }
   * }
   * ```
   */
  bannerProperty?: Maybe<BannerProperty>;
  /** #rest #virtual */
  blockedCompanies?: Maybe<BlockedCompanies>;
  /** List blocked entities by the viewer user */
  blockedEntitiesPage?: Maybe<BlockedEntitiesPage>;
  /**
   * #rest #virtual
   *
   *  List blocked users by the viewer user
   */
  blockedUsers?: Maybe<Array<Maybe<NetworkBlockedUsersBlockedUser>>>;
  /**
   * #rest #virtual
   *
   *  Returns client credit/discount balance data
   */
  bpayClientBalance?: Maybe<BpayClientBalanceResult>;
  /**
   * #rest #virtual
   *
   *  Credit card url to initialise credit card PopUp
   */
  bpayCreditCardUrl?: Maybe<BpayCreditCardUrl>;
  /**
   * #rest #virtual
   *
   *  Returns Client Payment method data
   */
  bpayPaymentMethodData?: Maybe<BpayPaymentMethodDataResult>;
  /**
   * #rest #virtual
   *
   *  Returns data/table for Product Group running Contracts
   */
  bpayProductGroupRunningContracts?: Maybe<BpayProductGroupRunningContractsResult>;
  /** #logged-out #soft-logged-in #rest #virtual */
  careerLevelPreference?: Maybe<CareerLevelPreference>;
  /** #rest(batched) #virtual */
  chat?: Maybe<MessengerChat>;
  /**
   * #rest(batched) #virtual
   *
   *  Query a chat without a scrambled-id. This is necessary to be backwards compatible with the messenger-classic after a redirect to the Armstrong messenger.
   * @deprecated Use `viewer.chat` it will be removed after full Armstrong rollout mobile and desktop web.
   */
  chatLegacy?: Maybe<MessengerChat>;
  /** #paginated #rest #virtual */
  chatMessages?: Maybe<MessengerChatMessagesConnection>;
  /** #rest #virtual */
  chatQuickActions?: Maybe<MessengerQuickActions>;
  /** #paginated #rest #virtual */
  chats?: Maybe<MessengerChatsConnection>;
  /** #rest #virtual */
  chatsSearch?: Maybe<ChatsSearch>;
  /** #paginated #rest #virtual */
  chatsUpdates?: Maybe<MessengerChatsConnection>;
  /** #rest #virtual */
  circuitBreakerStatus?: Maybe<CircuitBreaker>;
  /**
   * #logged-out #soft-logged-in
   *
   *  The client IP of the request
   */
  clientIp: Scalars['String']['output'];
  /**
   * #rest #virtual
   *
   *  Get collapsed modules from user
   */
  collapsedModules?: Maybe<Array<Maybe<CollapsedModule>>>;
  /** #paginated #rest #virtual */
  companiesRecommendations?: Maybe<CompanyRecommendationConnection>;
  /**
   * #rest #virtual
   *
   *  Get company anniversary story
   */
  companyAnniversaryStory?: Maybe<CompanyAnniversaryStory>;
  /**
   * #rest(batched) #virtual
   *
   *  Get company anniversary story batch
   */
  companyAnniversaryStoryBatch?: Maybe<CompanyAnniversaryStory>;
  /**
   * #paginated #rest #virtual
   *
   *  Universal Search Company is the api for company search to be used by company FE instead of the search-frontend one.
   * As search-proxy REST API pagination is using limit+offset instead of the required standard we need to adapt the query.
   */
  companySearch?: Maybe<CompanySearchConnection>;
  /** #soft-logged-in #rest #virtual */
  connectionLevel?: Maybe<ConnectionLevelResponse>;
  /**
   * #soft-logged-in
   * @deprecated This field was needed prior to full Armstrong roll-out. The corresponding connection-level endpoint has very low usage and will be removed.
   */
  connectionLevelSnapDefencing?: Maybe<ConnectionLevelResponse>;
  /** #paginated #rest #virtual */
  contactCompaniesRecommendations?: Maybe<JobRecommenderConnection>;
  /** #soft-logged-in #rest(batched) #virtual */
  contactDistance?: Maybe<Array<ContactDistance>>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Decrypt a one-click Contact Request token
   */
  contactRequestsOneClickTokenPayload?: Maybe<ContactRequestsOneClickToken>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  List of received contact requests
   */
  contactRequestsReceived?: Maybe<ContactRequestsReceived>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  List of sent contact requests
   */
  contactRequestsSent?: Maybe<ContactRequestsSent>;
  /**
   * #paginated #rest #virtual
   *
   *  List of sent contact requests using cursor pagination
   */
  contactRequestsSentByCursor?: Maybe<ContactRequestsSentConnection>;
  /** #paginated #rest #virtual */
  contactsBirthdays?: Maybe<ContactWithBirthdayConnection>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Count of contacts
   */
  contactsCount?: Maybe<ContactsCount>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  List of contacts with support for multiple filters
   */
  contactsNetwork?: Maybe<ContactsNetwork>;
  /**
   * #paginated #soft-logged-in #rest #virtual
   *
   *  List of contacts using relay cursor pagination.
   */
  contactsNetworkByCursor?: Maybe<ContactsNetworkByCursorConnection>;
  /**
   * #rest #virtual
   *
   *  Count of same city contacts
   */
  contactsSameCityCount?: Maybe<ContactsSameCityCount>;
  /**
   * #paginated #rest #virtual
   *
   *  search contacts
   *
   * ```
   * query ContactsSearch {
   *   viewer {
   *     contactsSearch(consumer: "test", keywords: "Gregor Gysi") {
   *       edges {
   *         node {
   *           id
   *           displayName
   *           lastName
   *           pageName
   *           profileImage(size: SQUARE_192) {
   *             __typename
   *             url
   *           }
   *           occupations {
   *             __typename
   *             subline
   *             headline
   *           }
   *           userFlags {
   *             displayFlag
   *           }
   *         }
   *       }
   *     }
   *   }
   * }
   * ```
   */
  contactsSearch?: Maybe<ContactsSearchConnection>;
  /** #paginated #rest #virtual */
  contentBookmarks?: Maybe<ContentBookmarksConnection>;
  /** #rest #virtual */
  contentEmailSubscriptions?: Maybe<EmailSubscriptions>;
  /**
   * #rest #virtual
   *
   *  Get managed insider page infos
   */
  contentInsiderPage?: Maybe<ContentManagedInsiderPage>;
  /** #rest #virtual */
  contentManagedPages?: Maybe<ContentPageList>;
  /** #paginated #rest #virtual */
  contentPageRecommendations?: Maybe<ContentPageRecommenderConnection>;
  /** #rest #virtual */
  contentPurchases?: Maybe<ContentPurchaseList>;
  /**
   * #paginated
   * @deprecated Replaced by: networkUpdatesCollection
   */
  conversationStarters?: Maybe<ConversationStartersConnection>;
  /**
   * #rest #virtual
   *
   *  User settings from core
   */
  coreSettings?: Maybe<CoreSettings>;
  /**
   * #rest #virtual
   *
   *  Get Cover Image Update
   */
  coverImageUpdate?: Maybe<CoverImageUpdate>;
  /**
   * #rest(batched) #virtual
   *
   *  Get Cover Image Update batch
   */
  coverImageUpdateBatch?: Maybe<CoverImageUpdate>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Credit card url to initialise credit card PopUp
   */
  creditCardUrl?: Maybe<CreditCardUrl>;
  /**
   * #rest #virtual
   *
   *  Settings for sharing data to other users
   */
  dataSharingSettings?: Maybe<DataSharingSettings>;
  /** #rest #virtual */
  disciplinesPreference?: Maybe<DisciplinesPreference>;
  /**
   * #rest(batched) #virtual
   *
   *  Load one activity with id
   */
  discoActivity?: Maybe<DiscoActivity>;
  /** Entry point for entity stream feed page */
  discoEntityStreamPage?: Maybe<DiscoEntityStreamPage>;
  /** Entry point for me feed page */
  discoMeFeedPage?: Maybe<DiscoMeFeedPage>;
  /** Entry point for network update page */
  discoNetworkUpdatePage?: Maybe<DiscoNetworkUpdatePage>;
  /**
   * Provides data for network update widget integrated in SUPI
   * @deprecated feature was removed from network-home
   */
  discoNetworkUpdateWidget?: Maybe<DiscoNetworkUpdateWidget>;
  /** #rest #virtual */
  discoProfileCompletion?: Maybe<DiscoProfileCompletion>;
  /** #rest #virtual */
  discoProfileMissingFields?: Maybe<MissingFieldsCollection>;
  /** #rest #virtual */
  editedCompanies?: Maybe<CompanyCollection>;
  /**
   * #rest #virtual
   *
   *  Get an array of company ids representing employer preferences
   */
  employersPreference?: Maybe<EmployersPreference>;
  /**
   * Invitation of the current user for a specific event
   * @deprecated Events is being shutdown on the 31-12-2022. Do not remove calls before this day
   */
  eventInvitation?: Maybe<EventUserInvitation>;
  /**
   * List of invitations of the current user
   * @deprecated Events is being shutdown on the 31-12-2022. Do not remove calls before this day
   */
  eventInvitations?: Maybe<EventUserInvitations>;
  /** #rest #virtual */
  eventSettings?: Maybe<EventSettings>;
  /**
   * List of events of the current user
   * @deprecated Events is being shutdown on the 31-12-2022. Do not remove calls before this day
   */
  events?: Maybe<EventUserEvents>;
  /**
   * #rest #virtual #soft-logged-in
   *
   *  Generic feature switches
   */
  featureSwitches?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  List of feature switches experiments for the currently logged in user.
   */
  featureSwitchesExperiments?: Maybe<FeatureSwitchesExperimentsCollection>;
  /**
   * #paginated
   * @deprecated Groups are going to be shutdown at the end of 2022. This field will stop working on 01/01/2023.
   */
  featuredCommunities?: Maybe<CommunitiesConnection>;
  /**
   * #rest #virtual #soft-logged-in
   *
   *  Platform features for the requesting user
   */
  features?: Maybe<Feature>;
  /**
   * #paginated #rest #virtual
   *
   *  List Companies followed by the user
   */
  followedCompanies?: Maybe<FollowedCompaniesConnection>;
  /**
   * #paginated
   *
   *  Universal Search Group is the api for group (communities) search to be used by group FE instead of search-frontend one.
   * As search-proxy REST API pagination is using limit+offset instead of the required standard we need to adapt de query.
   */
  groupSearch?: Maybe<GroupSearchConnection>;
  /**
   * #paginated
   * @deprecated Groups are going to be shutdown at the end of 2022. Checked 20.02.2023, still used by XING-WEB.UNKNOWNVERSION - 120k rq/week
   */
  groupsMemberships?: Maybe<GroupsMembershipsConnection>;
  /** #rest #virtual */
  groupsSettings?: Maybe<GroupsSettings>;
  /**
   * #rest #virtual
   *
   *  Check if the viewer has seen given URN
   */
  hasSeen?: Maybe<HasSeenResponse>;
  /**
   * #rest #virtual
   *
   *  Hiring data for a profile
   */
  hiringProfile?: Maybe<HiringProfile>;
  /**
   * #logged-out #soft-logged-in
   *
   *  The id of the requesting user
   */
  id: Scalars['ID']['output'];
  /** #rest #virtual */
  industriesPreference?: Maybe<IndustriesPreference>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Returns `true` if the caller is logged-in and has been successfully authenticated. For logged-out and soft-logged-in states returns `false`
   * @deprecated Login state is not longer boolean. Since soft login introduction there are three possible states, please use `Viewer.loginState` instead.
   */
  isLoggedIn?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #rest #virtual
   *
   *  Get if the job is bookable and the booking url
   */
  jobBooking?: Maybe<JobBooking>;
  /** #paginated #logged-out #soft-logged-in #rest #virtual */
  jobBookmarks?: Maybe<JobBookmarksConnection>;
  /**
   * #paginated #soft-logged-in #rest #virtual
   *
   *  Get direct contacts for the job posting's details page. The returned contacts will work either currently or previously in posting's company.
   * In order to specify so, the ```companyMembership``` param is used.
   */
  jobDetailsFirstDegreeContacts?: Maybe<JobDetailsConnection>;
  /**
   * #paginated #soft-logged-in #rest #virtual
   *
   *  Get future colleagues for the job posting's details page. The returned contacts will be potential colleagues that the user would
   * work together in case he/she would start working in the job role that is advertised in the job posting the user is looking at.
   */
  jobDetailsFutureColleagues?: Maybe<JobDetailsFutureColleaguesConnection>;
  /**
   * #paginated #soft-logged-in #rest #virtual
   *
   *  Get second degree contacts (contacts from your direct contacts) for the job posting's details page. The returned contacts will currently work in the posting's company.
   */
  jobDetailsSecondDegreeContacts?: Maybe<JobDetailsSecondDegreeConnection>;
  /** #rest #virtual */
  jobPreferenceUserFlags?: Maybe<JobPreferenceUserFlags>;
  /**
   * #rest #virtual
   * @deprecated Use jobPreferencesDataStateInfo instead
   */
  jobPreferencesDataState?: Maybe<JobPreferencesDataState>;
  /** #rest #virtual */
  jobPreferencesDataStateInfo?: Maybe<JobPreferencesDataStateInfo>;
  /** #rest #virtual */
  jobPreferencesJobSeekerStatus?: Maybe<JobPreferencesJobSeekerStatus>;
  /**
   * #rest #virtual
   *
   *  Return job recent searches consuming the DS recent_searches endpoint and the jobSearchResult resolver.
   */
  jobRecentSearches?: Maybe<JobRecentSearchCollection>;
  /**
   * #rest #virtual
   *
   *  Return job recent searches consuming the DS recent_searches endpoint and the jobSearchResult resolver.
   */
  jobRecentSearchesJS?: Maybe<JobRecentSearchCollectionJs>;
  /**
   * #rest #virtual
   *
   *  Query for recommendation categories
   */
  jobRecoCategories?: Maybe<JobRecommendationsCategories>;
  /** #paginated #rest #virtual */
  jobRecommendations?: Maybe<JobRecommenderConnection>;
  /** #paginated #rest #virtual */
  jobRecommendationsUserTopics?: Maybe<JobRecommendationsUserTopicsConnection>;
  /** #rest #virtual */
  jobRoles?: Maybe<JobRolesCollection>;
  /**
   * #rest #virtual
   *
   *  Retrieves the new id associated for an old search alert id
   */
  jobSearchAlertNewId?: Maybe<JobSearchAlertNewIdResponse>;
  /** #rest #virtual */
  jobSearchDoesItNeedRefinement?: Maybe<JobSearchNeedsRefinementResponse>;
  /**
   * #rest #virtual
   *
   *  Returns the job search inspiration for the user
   */
  jobSearchInspiration?: Maybe<JobSearchInspirationResponse>;
  /** #rest #virtual */
  jobSeekerGuidance?: Maybe<JobSeekerGuidance>;
  /** #rest #virtual */
  jobTitleSuggestionsPreference?: Maybe<JobTitleSuggestionsPreference>;
  /** #rest #virtual */
  jobTitlesPreference?: Maybe<JobTitlesPreference>;
  /**
   * #paginated #logged-out #soft-logged-in #rest #virtual
   *
   *  Returns the current user's most recent job detail page views.
   * Ordered by most-recent view first and uses native cursor pagination.
   * The `onlyActivePostings` parameter is still here for legacy reasons but it shouldn't
   */
  jobVisits?: Maybe<JobVisitsConnection>;
  /** #rest #virtual */
  jobWishesPreference?: Maybe<JobWishesPreference>;
  /** #rest #virtual */
  linkPreview?: Maybe<LinkPreviewResult>;
  loProfilePreview?: Maybe<LoProfile>;
  /** #rest #virtual */
  loProfilePreviewLegalNotice?: Maybe<ProfileLegalInformation>;
  /** #logged-out #soft-logged-in #rest #virtual */
  locationsPreference?: Maybe<LocationsPreference>;
  /**
   * #rest #virtual
   *
   *  Web sessions
   */
  loginSessions?: Maybe<Array<Maybe<LoginSession>>>;
  /**
   * #rest #virtual
   *
   *  Login related settings
   */
  loginSettings?: Maybe<LoginSettings>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Returns login state of the current session
   */
  loginState: LoginState;
  /**
   * #paginated #rest #virtual
   *
   *  List Companies managed by the user
   */
  managedCompanies?: Maybe<ManagedCompaniesConnection>;
  /** #paginated #soft-logged-in #rest #virtual */
  membersSearch?: Maybe<MembersSearchConnection>;
  /** #rest #virtual */
  messagesSearch?: Maybe<MessagesSearch>;
  /**
   * #rest #virtual
   *
   *  Get messenger banner info
   */
  messengerBanner?: Maybe<MessengerBanner>;
  messengerChatsFilter?: Maybe<MessengerChatsFilterTypes>;
  /** #paginated #rest #virtual */
  messengerContacts?: Maybe<MessengerContactsConnection>;
  /** #rest #virtual */
  messengerControls?: Maybe<MessengerControls>;
  /** #paginated #rest #virtual */
  messengerMessageSearch?: Maybe<MessengerMessageSearchConnection>;
  /**
   * #rest #virtual
   *
   *  Read a template
   */
  messengerMessageTemplate?: Maybe<MessengerMessageTemplatesInterface>;
  /**
   * #rest #virtual
   *
   *  List of Templates
   */
  messengerMessageTemplates?: Maybe<MessengerMessageTemplates>;
  /** #rest #virtual */
  messengerSettings?: Maybe<MessengerSettings>;
  /**
   * #rest #virtual
   *
   *  Get article content and social interaction information base on users culture preferences
   */
  moveonArticlesByCulturePreferences?: Maybe<MoveonArticlesResult>;
  /** #rest #virtual */
  moveonCompanyCulturePreferencesAssessmentData?: Maybe<MoveonCompanyCulturePreferencesAssessmentDataResult>;
  /** #paginated #rest #virtual */
  moveonCompanyRecommendations?: Maybe<MoveonCompanyRecommendationsConnection>;
  /**
   * #rest #virtual
   * @deprecated It will be replaced with moveonCompanyRecommendationsAndJobSearchResolver
   */
  moveonCompanyRecommendationsAllIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** #rest #virtual */
  moveonCompanyRecommendationsJobSearch?: Maybe<MoveonCompanyRecommendationsJobSearch>;
  /** The user's story collection */
  myStoryCollection?: Maybe<StoryCollection>;
  /** #paginated #rest #virtual */
  mymkBucketCollectionsRecommendations?: Maybe<MymkBucketCollectionsRecommenderConnection>;
  /** #paginated #rest #virtual */
  mymkBucketRecommendations?: Maybe<MymkCombinedRecommenderConnection>;
  /**
   * #paginated #rest #virtual
   * @deprecated Use mymkBucketCollectionsRecommendations and mymkBucketRecommendations instead. WILL BE REMOVED ...
   */
  mymkBucketedRecommendations?: Maybe<MymkBucketedRecommenderConnection>;
  /** #paginated #rest #virtual */
  mymkCombinedRecommendations?: Maybe<MymkCombinedRecommenderConnection>;
  /**
   * #rest(batched) #virtual
   *
   *  Get mymk recommendation story
   */
  mymkRecommendationStory?: Maybe<PeopleStoryGeneratorMymkRecommendation>;
  /** #paginated #soft-logged-in #rest #virtual */
  mymkRecommendations?: Maybe<MymkRecommenderConnection>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Request persisted client settings for network apps
   */
  networkClientSettings?: Maybe<Array<NetworkClientSetting>>;
  /**
   * #rest #virtual
   *
   *  List of aggregated cities
   */
  networkContactsCities?: Maybe<NetworkContactsCities>;
  /**
   * #rest #virtual
   *
   *  List of aggregated companies
   */
  networkContactsCompanies?: Maybe<NetworkContactsCompanies>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Returns neffi score
   */
  networkEfficiencyIndicator?: Maybe<NetworkEfficiencyIndicator>;
  /** #logged-out #soft-logged-in #rest #virtual */
  networkHomeWebsocketAuthToken?: Maybe<NetworkHomeWebsocketAuthToken>;
  /**
   * #rest #virtual
   *
   *  Returns users for a given list of proximity (Around You) uuid's
   */
  networkProximityUsers?: Maybe<NetworkProximityUsers>;
  /** #soft-logged-in #rest(batched) #virtual */
  networkRelationship?: Maybe<Array<NetworkRelationship>>;
  /**
   * #rest #virtual
   *
   *  Privacy settings
   */
  networkSettings?: Maybe<NetworkSettings>;
  /**
   * #rest #virtual
   *
   *  List of available tags
   */
  networkTags?: Maybe<NetworkTags>;
  /**
   * #paginated
   * @deprecated Section is not longer going to show all types of objects and query was splited in chats and signalsStacksCollection
   */
  networkUpdatesCollection?: Maybe<NetworkUpdatesConnection>;
  /**
   * #paginated #rest #virtual
   *
   *  Universal Search News is the api for News (content) search to be used by News FE instead of search-frontend one.
   * As search-proxy REST API pagination is using limit+offset instead of the required standard we need to adapt de query.
   */
  newsSearch?: Maybe<NewsSearchConnection>;
  /** #rest #virtual */
  notification?: Maybe<Notification>;
  /**
   * #rest #virtual
   *
   *  A users notification settings
   */
  notificationSettings?: Maybe<NotificationSettingsCollection>;
  /** #paginated #rest #virtual */
  notifications?: Maybe<NotificationConnection>;
  /** #rest #virtual */
  numberOfNewProfileVisits?: Maybe<NumberOfNewProfileVisits>;
  nwhChatsFilter?: Maybe<MessengerChatsFilterTypes>;
  /**
   * #rest #virtual
   *
   *  Gets an onboarding journey
   */
  onboardingJourney?: Maybe<OnboardingJourneyType>;
  /** @deprecated Profile-wizard is decommissioned. Values are hardcoded in order to support not deprecated app versions. This field will be removed soon */
  onboardingProfile?: Maybe<OnboardingProfile>;
  /**
   * #rest #virtual
   *
   *  Get redirect profile (the profile that user will be redirected after the onboarding flow) of onboarding user
   *
   * ```
   * query onboardingRedirectProfile {
   *   viewer {
   *     onboardingRedirectProfile {
   *       profile {
   *         id
   *         firstName
   *         lastName
   *         profileImage(size: SQUARE_32){
   *           url
   *         }
   *         profileOccupation {
   *           occupationOrg
   *           occupationTitle
   *         }
   *       }
   *     }
   *   }
   * }
   * ```
   */
  onboardingRedirectProfile?: Maybe<OnboardingRedirectProfile>;
  /** #logged-out #soft-logged-in #rest #virtual */
  otherUserFlagDetails?: Maybe<UserFlagDetails>;
  /** #soft-logged-in #rest #virtual */
  partner?: Maybe<Partner>;
  /** #soft-logged-in #rest #virtual */
  partnerDetails?: Maybe<PartnerDetails>;
  /** #soft-logged-in #rest #virtual */
  partnerHighlight?: Maybe<PartnerHighlight>;
  /** #soft-logged-in #rest #virtual */
  partners?: Maybe<Partners>;
  /** #soft-logged-in #rest #virtual */
  partnersCategories?: Maybe<PartnersCategories>;
  /** #rest #virtual */
  payBilClient?: Maybe<PayBilClientResponse>;
  /** #soft-logged-in #rest #virtual */
  paymentAccount?: Maybe<PaymentAccount>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Payment account details including payment method. Currently only one per user
   */
  paymentAccounts?: Maybe<PaymentAccounts>;
  /**
   * #rest #virtual
   *
   *  List of invoices optionally filtered by year and/or product group and/or regenerability
   */
  paymentInvoices?: Maybe<PaymentInvoices>;
  /**
   * #rest #virtual
   *
   *  Data to support showing notifications in the app such as expired credit card
   */
  paymentNotifications?: Maybe<PaymentNotifications>;
  /**
   * #rest #virtual
   *
   *  Summary of single subscriptions for the user. Returns `null` if the user does not have a subscription for this product group.
   */
  paymentSubscription?: Maybe<PaymentSubscription>;
  /**
   * #rest #virtual
   *
   *  Summary of subscriptions for the user
   */
  paymentSubscriptions?: Maybe<PaymentSubscriptions>;
  /**
   * #rest #virtual
   *
   *  Returns client billing address
   */
  paymentsClientBillingAddress?: Maybe<ClientBillingAddress>;
  /**
   * #rest #virtual
   *
   *  List of invoices optionally filtered by product group and/or year
   */
  paymentsClientInvoices?: Maybe<ClientPaymentInvoices>;
  /**
   * #rest #virtual
   *
   *  Returns client overview data like client and product groups
   */
  paymentsClientOverview?: Maybe<ClientOverview>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Paypal url to initialise paypal form
   */
  paypalUrl?: Maybe<PaypalUrl>;
  /** #preview #rest(batched) #virtual */
  personalDetails?: Maybe<PersonalDetails>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  DEPRECATED
   * Predictive search is going to be shutdown as right now it's one used for mobile search always with kinds=members.
   * This will call incremental members search is search-backend.
   */
  predictiveSearch?: Maybe<PredictiveSearch>;
  /**
   * #rest #virtual
   *
   *  Retrieve the current user preferences
   */
  preferenceSettings?: Maybe<PreferencesSettings>;
  /**
   * #paginated #soft-logged-in #rest #virtual
   *
   *  List of premium contacts
   */
  premiumContacts?: Maybe<PremiumContactsCursorConnection>;
  /** #soft-logged-in #rest #virtual */
  premiumFeaturesOverview?: Maybe<PremiumFeaturesOverview>;
  /** #soft-logged-in #rest #virtual */
  premiumNews?: Maybe<PremiumNews>;
  /**
   * #soft-logged-in #rest #virtual
   * @deprecated Replaced by partner query
   */
  premiumPartner?: Maybe<PremiumPartner>;
  /**
   * #soft-logged-in #rest #virtual
   * @deprecated Replaced by partnerDetails query
   */
  premiumPartnerDetails?: Maybe<PremiumPartnerDetails>;
  /** #soft-logged-in #rest #virtual */
  premiumPartnerHighlight?: Maybe<PremiumPartnerHighlight>;
  /**
   * #soft-logged-in #rest #virtual
   * @deprecated Replaced by partners query
   */
  premiumPartners?: Maybe<PremiumPartners>;
  /**
   * #soft-logged-in #rest #virtual
   * @deprecated Replaced by partnersCategories query
   */
  premiumPartnersCategories?: Maybe<PremiumPartnersCategories>;
  /** #soft-logged-in */
  premiumSelfDevelopment?: Maybe<PremiumSelfDevelopment>;
  /** #soft-logged-in */
  premiumStartpage?: Maybe<PremiumStartpage>;
  /** #soft-logged-in #rest #virtual */
  premiumStartpageInfo?: Maybe<PremiumStartpageInfo>;
  /** #rest #virtual */
  premiumStatistics?: Maybe<PremiumStatistics>;
  premiumSwitchSettings?: Maybe<PremiumSwitchSettings>;
  /** #soft-logged-in #rest #virtual */
  premiumVisibility?: Maybe<PremiumVisibility>;
  /**
   * #rest #virtual
   *
   *  Get profile academic title update
   */
  profileAcademicTitleUpdate?: Maybe<ProfileAcademicTitleUpdate>;
  /**
   * #rest #virtual
   *
   *  Get profile business contact update
   */
  profileBusinessContactUpdate?: Maybe<ProfileBusinessContactUpdate>;
  /**
   * #rest #virtual
   *
   *  Get profile haves update
   */
  profileHavesUpdate?: Maybe<ProfileHavesUpdate>;
  /**
   * #soft-logged-in #rest #virtual
   *
   *  Ordered list of available modules on Profiles platform
   */
  profileModuleStoreItems?: Maybe<Array<Maybe<ProfileModuleStoreItem>>>;
  /**
   * #rest #virtual
   *
   *  Return the neffi for the profile. Resolved by profile-backend, adding some profile specific business logic.
   * @deprecated Use viewer.networkEfficiencyIndicator instead
   */
  profileNeffi?: Maybe<NeffiCard>;
  /**
   * #rest #virtual
   *
   *  List of status options that are available for a user. The selection is based on the types of occupations.
   */
  profilePersonalStatusOptions?: Maybe<ProfilePersonalStatusOptions>;
  /**
   * #rest #virtual
   *
   *  Profile pages related Settings
   */
  profileSettings?: Maybe<ProfileSettings>;
  profileSkillsModuleRecommendation?: Maybe<ProfileSkillsModuleRecommendation>;
  /** #rest #virtual */
  profileSkillsPerformance?: Maybe<ProfileSkillsModuleSkillsPerformace>;
  /** #rest #virtual */
  profileSkillsPerformanceWithCurrentSkills?: Maybe<ProfileSkillsModuleSkillsPerformaceWithCurrentSkills>;
  /**
   * #rest #virtual
   *
   *  Get profile status Update
   */
  profileStatusUpdate?: Maybe<ProfileUpdateStatus>;
  /** #rest #virtual */
  profileTimelineForm?: Maybe<ProfileTimelineForm>;
  /**
   * #rest #virtual
   *
   *  Showing single VOMP
   */
  profileVisit?: Maybe<ProfileVisit>;
  /**
   * #rest #virtual
   *
   *  Get profile wants update
   */
  profileWantsUpdate?: Maybe<ProfileWantsUpdate>;
  /**
   * #rest #virtual
   *
   *  Get profile work experience update
   */
  profileWorkExperienceUpdate?: Maybe<ProfileWorkExperienceUpdate>;
  /**
   * #rest #virtual
   *
   *  Logged-Out visibility related Settings
   */
  profilesLoggedoutSettings?: Maybe<ProfilesLoggedoutSettings>;
  /**
   * #rest #virtual
   * @deprecated Will be removed at the end of Q4
   */
  projobsContactOptions?: Maybe<ProjobsContactOptions>;
  /** #rest #virtual */
  projobsDocuments?: Maybe<ProjobsDocuments>;
  /**
   * #rest #virtual
   *
   *  Profiles search for the current projobs user
   */
  projobsProfileSearch?: Maybe<ProjobsProfileSearchResults>;
  /** #rest #virtual */
  projobsRecruiterInsights?: Maybe<ProjobsRecruiterInsights>;
  /** #rest #virtual */
  projobsSettings?: Maybe<ProjobsSettings>;
  /**
   * #rest #virtual
   * @deprecated Will be removed at the end of Q4
   */
  projobsWishes?: Maybe<ProjobsWishes>;
  /** #rest #virtual */
  purchaseLegacyMobileOffer?: Maybe<PurchaseLegacyMobileOffer>;
  /**
   * #rest #virtual
   *
   *  Recent searches is the replacement for old recent searches api inside search-app it returns the recent searches information storages in Cassandra (when performing the search this info should be sent to a kafka topic by user_id, search type and query).
   * It's possible to ask for recent searches by userId and search type and response size is managed by size param. Default size is 10.
   */
  recentSearch?: Maybe<RecentSearch>;
  /**
   * #paginated #rest #virtual
   *
   *  Recruiters that get recommended to the user,
   * based on their hiring areas and the user's
   * job role, industry and discipline
   */
  recruiterRecommendations?: Maybe<RecruiterRecommendationsConnection>;
  /** #logged-out #soft-logged-in #rest #virtual */
  salaryExpectationsPreference?: Maybe<SalaryExpectationsPreference>;
  /**
   * #rest #virtual
   *
   *  Get salary insights
   */
  salaryInsights?: Maybe<SalaryInsights>;
  /** #rest #virtual */
  scheduledMessages?: Maybe<ScheduledMessages>;
  /**
   * #rest #virtual
   *
   *  Search alerts for the current user but selecting them by query.
   */
  searchAlertByQuery?: Maybe<SingleSearchAlertResponse>;
  /**
   * #rest #virtual
   *
   *  Get the search alerts settings for the current user.
   */
  searchAlertSettings?: Maybe<UserSettingsResponse>;
  /**
   * #rest #virtual
   *
   *  Retrieves search alerts for the logged in user.
   * If the only thing you need is the query id (guid), you can just ask for queryId field. It is more performant.
   */
  searchAlerts?: Maybe<SearchAlertResponse>;
  /**
   * #rest(batched) #virtual
   *
   *  Search alerts for the current user but selecting them by id.
   */
  searchAlertsByIds?: Maybe<Array<Maybe<SearchAlert>>>;
  /** #logged-out #soft-logged-in #rest #virtual */
  selfUserFlagDetails?: Maybe<UserFlagDetails>;
  /** #paginated #rest #virtual */
  sharedContacts?: Maybe<SharedContactsConnection>;
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signalsChangesCollection?: Maybe<SignalsCollectionConnection>;
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signalsCollection?: Maybe<SignalsCollectionConnection>;
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signalsStacksCollection?: Maybe<SignalsStacksConnection>;
  /** #rest #virtual */
  similarCandidates?: Maybe<SimilarCandidates>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Query for similar jobs
   */
  similarJobs?: Maybe<JobRecommendationsSimilarJobs>;
  /** #paginated #rest #virtual */
  similarJobsForUserRecommendations?: Maybe<JobRecommenderConnection>;
  /** #rest #virtual */
  singleUserCommonalities?: Maybe<Commonalities>;
  /**
   * #rest #virtual
   *
   *  Get skills insights
   */
  skillsInsights?: Maybe<SkillsInsights>;
  /**
   * #rest #virtual
   *
   *  Get skill recommendations for user
   */
  skillsRecommendations?: Maybe<SkillRecommendationResponse>;
  /**
   * #rest #virtual
   *
   *  Get a maximum of 40 skill recommendations for user
   */
  skillsRecommendationsWithFixedLimit?: Maybe<SkillRecommendationResponse>;
  /**
   * #rest #virtual
   *
   *  Get a single comment by id
   */
  socialComment?: Maybe<SocialCommentResult>;
  /**
   * #paginated #rest #virtual
   *
   *  Get comments for URN
   */
  socialComments?: Maybe<SocialCommentsConnection>;
  /**
   * #logged-out #soft-logged-in #rest #virtual
   *
   *  Get social external link by URN
   */
  socialExternalLink?: Maybe<SocialExternalLinkResult>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  socialInteractionTarget?: Maybe<SocialInteractionTarget>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  socialInteractionTargets?: Maybe<Array<Maybe<SocialInteractionTarget>>>;
  /**
   * #rest #virtual
   *
   *  Get the social proof (top reactions) for URN
   */
  socialProof?: Maybe<SocialProofResult>;
  /**
   * #rest(batched) #virtual
   *
   *  Get the social proof (top reactions) for multiple URNs
   */
  socialProofs?: Maybe<Array<Maybe<SocialProofCollection>>>;
  /**
   * #paginated #rest #virtual
   *
   *  Get reactions for URN
   */
  socialReactions?: Maybe<SocialReactionsConnection>;
  /** #rest #virtual */
  socialRecentChats?: Maybe<SocialMessengerRecentChats>;
  /**
   * #rest #virtual
   *
   *  social settings (e. g. mentionable)
   */
  socialSettings?: Maybe<SocialSettings>;
  /**
   * #rest #virtual
   *
   *  Get a single share by id
   */
  socialShare?: Maybe<SocialShareResult>;
  /**
   * #rest(batched) #virtual
   *
   *  Get a single share via URN
   */
  socialShareByURN?: Maybe<SocialShareResult>;
  /**
   * #rest(batched) #virtual
   *
   *  Get a list of shares via URNs
   */
  socialShareByURNs?: Maybe<Array<Maybe<SocialShareResult>>>;
  /**
   * #paginated #rest #virtual
   *
   *  Get shares for URN
   */
  socialShares?: Maybe<SocialSharesConnection>;
  /**
   * #rest #virtual
   *
   *  Search proxy to get possible user records for mentions
   */
  socialUserSearch?: Maybe<SocialUserSearchResult>;
  someMadeUpLocalFieldExample?: Maybe<Scalars['String']['output']>;
  /** List of story collections to show to the current user */
  storyCollectionList?: Maybe<StoryCollectionList>;
  /** #rest #virtual */
  targetPositionsPreference?: Maybe<TargetPositionsPreference>;
  teamPermissions?: Maybe<TeamPermissionsViewer>;
  /** #paginated #rest #virtual */
  topEmployerRecommendations?: Maybe<JobRecommenderConnection>;
  /** #rest #virtual */
  tracy?: Maybe<Tracy>;
  /** #rest #virtual */
  tracyUser?: Maybe<Tracy>;
  /**
   * #rest #virtual
   *
   *  TFA settings
   */
  twoFaSettings?: Maybe<TwoFaSettings>;
  /** #rest #virtual */
  unpreferredPositionsPreference?: Maybe<UnpreferredPositionsPreference>;
  /**
   * #rest #virtual
   *
   *  Data Inquiry for the current user
   */
  userDataInquiry?: Maybe<UserDataInquiry>;
  /**
   * #rest #virtual
   *
   *  List all entity pages owned by current user
   */
  userEntityPages?: Maybe<UserEntityPages>;
  /** #soft-logged-in #rest #virtual */
  userFlags?: Maybe<UserFlags>;
  /** #rest #virtual */
  userJobRecoSettings?: Maybe<JobRecoUserSettings>;
  /**
   * #rest #virtual
   *
   *  Get the recent used user cvs from the success job applications
   */
  userRecentCvs?: Maybe<Array<Maybe<UserJobApplicationCv>>>;
  /** #rest #virtual */
  userSearchOccurrences?: Maybe<UserSearchOccurrences>;
  /** #rest #virtual */
  visibilityInsights?: Maybe<VisibilityInsights>;
  /**
   * #paginated #rest #virtual
   *
   *  Listing VOMPs from a user
   */
  visitorsList?: Maybe<ProfileVisitorsConnection>;
  /**
   * #rest #virtual
   *
   *  Retrieve the latest cover letter
   */
  vitaLatestCoverLetter?: Maybe<VitaDocumentResult>;
  /** #rest #virtual */
  vitaLatestDocument?: Maybe<VitaDocumentResult>;
  /** Viewer to retriieve data from Profiles */
  vitaProfileImport?: Maybe<VitaProfileImport>;
  /** #rest #virtual */
  vompHighlights?: Maybe<VompHighlights>;
  /** #rest #virtual */
  vompNotifications?: Maybe<VompNotification>;
  /** #rest #virtual */
  vompStatistics?: Maybe<VompStatistics>;
  /**
   * #rest #virtual
   *
   *  TETRIS tracking properties for a single user
   */
  webTrackingData?: Maybe<WebTrackingData>;
  /** #rest #virtual */
  workExperiences?: Maybe<ProfileWorkExperienceCollection>;
  /** #rest #virtual */
  workingHoursPreference?: Maybe<WorkingHoursPreference>;
  /** #rest #virtual */
  workplacePreference?: Maybe<WorkplacePreference>;
  /** #soft-logged-in #rest(batched) #virtual */
  xingId?: Maybe<XingId>;
  /**
   * #rest #virtual
   *
   *  All occupations the user can choose from. The first Bucket will always be VISIBLE (the occupations which are publicly visible on the user's XingId)
   */
  xingIdOccupationBuckets?: Maybe<Array<MyXingIdOccupationBucket>>;
  /** #rest #virtual */
  xjmJob?: Maybe<XjmJob>;
  /**
   * #paginated #rest #virtual
   *
   *  Fetch a paginated collection of own and shared message templates for the current user
   */
  xtmMessageTemplatesList?: Maybe<XtmMessageTemplateConnection>;
  /**
   * #rest #virtual
   *
   *  App sessions (a.k.a. permissions)
   */
  xwsSessions?: Maybe<Array<Maybe<XwsSessions>>>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerBannerPropertyArgs = {
  propertyName?: InputMaybe<BannerPropertyName>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerBpayClientBalanceArgs = {
  clientId: Scalars['ID']['input'];
  currency?: InputMaybe<PaymentCurrency>;
  embeddedBy: EmbeddedBy;
  productGroup?: InputMaybe<PaymentClientProductGroup>;
  testActionAllowed?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerBpayCreditCardUrlArgs = {
  agencyMode?: InputMaybe<Scalars['Boolean']['input']>;
  clientId?: InputMaybe<Scalars['ID']['input']>;
  embeddedBy?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerBpayPaymentMethodDataArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  embeddedBy?: InputMaybe<Scalars['String']['input']>;
  testActionAllowed?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerBpayProductGroupRunningContractsArgs = {
  productGroup: PaymentClientProductGroup;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerChatArgs = {
  id: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerChatLegacyArgs = {
  id: Scalars['Int']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerChatMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  chatId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerChatQuickActionsArgs = {
  chatId: Scalars['ID']['input'];
  contextId?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerChatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MessengerChatsFilterTypes>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerChatsSearchArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerChatsUpdatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MessengerChatsFilterTypes>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerCompaniesRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerCompanyAnniversaryStoryArgs = {
  id: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerCompanyAnniversaryStoryBatchArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerCompanySearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: UniversalSearchCompanyQueryInput;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContactCompaniesRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxNDaysOld?: InputMaybe<Scalars['Int']['input']>;
  projobsDashboardFilter?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContactDistanceArgs = {
  ids: Array<Scalars['SlugOrID']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContactRequestsOneClickTokenPayloadArgs = {
  token: Scalars['String']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContactRequestsReceivedArgs = {
  excludeSeen?: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<OrderDirection>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContactRequestsSentArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<OrderDirection>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContactRequestsSentByCursorArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderDirection>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContactsBirthdaysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  range?: InputMaybe<Range>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContactsNetworkArgs = {
  filters?: InputMaybe<ContactsFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ContactsListOrderBy>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContactsNetworkByCursorArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ContactsFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ContactsListOrderBy>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContactsSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  agg?: InputMaybe<ContactsSearchAggregationsInput>;
  before?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  filters?: InputMaybe<ContactsSearchFiltersInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  highlight?: InputMaybe<Scalars['Boolean']['input']>;
  keywords: Scalars['String']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContentBookmarksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContentInsiderPageArgs = {
  userId: Scalars['SlugOrID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContentPageRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pageTypes?: InputMaybe<Array<InputMaybe<ContentPageType>>>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerContentPurchasesArgs = {
  purchaseIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subscriptionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<ContentPurchaseType>>>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerConversationStartersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeAds?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerCoverImageUpdateArgs = {
  id: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerCoverImageUpdateBatchArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerCreditCardUrlArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerDiscoActivityArgs = {
  id: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerDiscoEntityStreamPageArgs = {
  entityPageId: Scalars['ID']['input'];
  platform?: InputMaybe<DiscoPlatform>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerDiscoMeFeedPageArgs = {
  platform?: InputMaybe<DiscoPlatform>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerDiscoNetworkUpdatePageArgs = {
  platform?: InputMaybe<DiscoPlatform>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerDiscoNetworkUpdateWidgetArgs = {
  platform?: InputMaybe<DiscoPlatform>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerDiscoProfileCompletionArgs = {
  consumer: Scalars['String']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerEventInvitationArgs = {
  eventId: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerEventInvitationsArgs = {
  input: EventUserInvitationsQueryInput;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerEventsArgs = {
  input: EventMyEventsQueryInput;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerFeatureSwitchesExperimentsArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  platform?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerFeaturedCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerFollowedCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<FollowedCompaniesSortField>;
  sortOrder?: InputMaybe<FollowedCompaniesSortOrder>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerGroupSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: UniversalSearchGroupQueryInput;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerGroupsMembershipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groupStates?: InputMaybe<Array<InputMaybe<GroupsGroupState>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  roles?: InputMaybe<Array<InputMaybe<GroupsMembershipRole>>>;
  states?: InputMaybe<Array<InputMaybe<GroupsMembershipState>>>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerHasSeenArgs = {
  source?: InputMaybe<HasSeenSource>;
  urn?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobBookingArgs = {
  jobId: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobBookmarksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyActivePostings?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<JobBookmarkState>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobDetailsFirstDegreeContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyMembership: CompanyMembership;
  consumer: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  postingId: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobDetailsFutureColleaguesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  postingId: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobDetailsSecondDegreeContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  postingId: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobRecentSearchesArgs = {
  consumer: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobRecentSearchesJsArgs = {
  consumer: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobRecoCategoriesArgs = {
  consumer: Scalars['String']['input'];
  limitPerCategory: Scalars['Int']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeNew?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxNDaysOld?: InputMaybe<Scalars['Int']['input']>;
  projobsDashboardFilter?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobRecommendationsUserTopicsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobRolesArgs = {
  consumer: Scalars['String']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobSearchAlertNewIdArgs = {
  oldAlertId: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobSearchDoesItNeedRefinementArgs = {
  input: JobSearchNeedsRefinementInput;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobSearchInspirationArgs = {
  consumer: Scalars['String']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobSeekerGuidanceArgs = {
  platform: JobSeekerPlatform;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobTitleSuggestionsPreferenceArgs = {
  language: Scalars['String']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerJobVisitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyActivePostings?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerLinkPreviewArgs = {
  url: Scalars['URL']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerManagedCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMembersSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: MembersSearchQueryInput;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMessagesSearchArgs = {
  lastSeenId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMessengerBannerArgs = {
  chatId?: InputMaybe<Scalars['ID']['input']>;
  name: MessengerBannerType;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMessengerContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMessengerMessageSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: MessengerMessageSearchQueryInput;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMessengerMessageTemplateArgs = {
  id: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMoveonCompanyRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMymkBucketCollectionsRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  buckets: Scalars['String']['input'];
  bucketsLimit?: InputMaybe<Scalars['Int']['input']>;
  consumer: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMymkBucketRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bucket: Scalars['String']['input'];
  consumer: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMymkBucketedRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  buckets: Scalars['String']['input'];
  consumer: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMymkCombinedRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  details?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeGender?: InputMaybe<Scalars['Boolean']['input']>;
  includeNonOwnedVcards?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMymkRecommendationStoryArgs = {
  id: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerMymkRecommendationsArgs = {
  adhocCoworker?: InputMaybe<Scalars['Boolean']['input']>;
  adhocSchoolmate?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  boostInactive?: InputMaybe<Scalars['Boolean']['input']>;
  consumer: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerNetworkEfficiencyIndicatorArgs = {
  consumer: Scalars['String']['input'];
  scoreVersion?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerNetworkProximityUsersArgs = {
  uuids: Array<Scalars['UUID']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerNetworkRelationshipArgs = {
  ids: Array<Scalars['ID']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerNetworkUpdatesCollectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: NetworkUpdatesFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeAds?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sectionFilter?: InputMaybe<NetworkUpdatesSectionFilterInput>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerNewsSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: UniversalSearchNewsQueryInput;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerNotificationArgs = {
  id: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerOtherUserFlagDetailsArgs = {
  displayFlag?: InputMaybe<UserFlagsDisplayFlag>;
  otherDisplayFlag?: InputMaybe<UserFlagsDisplayFlag>;
  otherUserId?: InputMaybe<Scalars['ID']['input']>;
  platform?: InputMaybe<PremiumPlatform>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPartnerArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPartnerDetailsArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPartnerHighlightArgs = {
  membership?: InputMaybe<PartnerMemberships>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPartnersArgs = {
  categories?: InputMaybe<Scalars['String']['input']>;
  membership?: InputMaybe<PartnerMemberships>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPartnersCategoriesArgs = {
  membership?: InputMaybe<PartnerMemberships>;
  sendAll?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPayBilClientArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  embeddedBy?: InputMaybe<Scalars['String']['input']>;
  testActionAllowed?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPaymentInvoicesArgs = {
  isRegeneratable?: InputMaybe<Scalars['Boolean']['input']>;
  productGroup?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPaymentSubscriptionArgs = {
  productGroup?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPaymentsClientBillingAddressArgs = {
  productGroup: PaymentClientProductGroup;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPaymentsClientInvoicesArgs = {
  productGroup: PaymentClientProductGroup;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPredictiveSearchArgs = {
  consumer: Scalars['String']['input'];
  kinds?: InputMaybe<Scalars['String']['input']>;
  query: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPremiumContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPremiumFeaturesOverviewArgs = {
  platform?: InputMaybe<PremiumPlatform>;
  product?: InputMaybe<PremiumFeaturesOverviewProducts>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPremiumNewsArgs = {
  platform?: InputMaybe<PremiumPlatform>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPremiumPartnerArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPremiumPartnerDetailsArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPremiumPartnersArgs = {
  categories?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPremiumSelfDevelopmentArgs = {
  platform?: InputMaybe<PremiumPlatform>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPremiumStartpageInfoArgs = {
  platform?: InputMaybe<PremiumPlatform>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPremiumStatisticsArgs = {
  statisticId?: InputMaybe<Scalars['ID']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPremiumVisibilityArgs = {
  platform?: InputMaybe<PremiumPlatform>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerProfileAcademicTitleUpdateArgs = {
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['Long']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerProfileBusinessContactUpdateArgs = {
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['Long']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerProfileHavesUpdateArgs = {
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['Long']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerProfileSkillsPerformanceArgs = {
  consumer: Scalars['String']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerProfileSkillsPerformanceWithCurrentSkillsArgs = {
  consumer: Scalars['String']['input'];
  skills: Array<Scalars['String']['input']>;
  topSkills?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerProfileStatusUpdateArgs = {
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['Long']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerProfileTimelineFormArgs = {
  action: ProfileTimelineAction;
  occupationType?: InputMaybe<ProfileOccupationTypeIdentifier>;
  occupationTypeCategory?: InputMaybe<ProfileOccupationTypeCategoryIdentifier>;
  urn?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerProfileVisitArgs = {
  profileVisitId: Scalars['Int']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerProfileWantsUpdateArgs = {
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['Long']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerProfileWorkExperienceUpdateArgs = {
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['Long']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerProjobsProfileSearchArgs = {
  input: ProjobsProfileSearchQueryInput;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerPurchaseLegacyMobileOfferArgs = {
  platform: PurchaseMobilePlatform;
  product: PurchaseProduct;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerRecentSearchArgs = {
  consumer: Scalars['String']['input'];
  recentSearchType: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerRecruiterRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerScheduledMessagesArgs = {
  contextId?: InputMaybe<Scalars['String']['input']>;
  recipientsIds: Array<Scalars['ID']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSearchAlertByQueryArgs = {
  query: JobSearchQueryInput;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSearchAlertsByIdsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSelfUserFlagDetailsArgs = {
  displayFlag?: InputMaybe<UserFlagsDisplayFlag>;
  platform?: InputMaybe<PremiumPlatform>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSharedContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  otherUserId: Scalars['SlugOrID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSignalsChangesCollectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: SignalsFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSignalsCollectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: SignalsFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSignalsStacksCollectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSimilarCandidatesArgs = {
  candidateId?: InputMaybe<Scalars['String']['input']>;
  consumer?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  timeout?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSimilarJobsArgs = {
  consumer: Scalars['String']['input'];
  jobId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSimilarJobsForUserRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSingleUserCommonalitiesArgs = {
  ignorePrivacySettings?: InputMaybe<Scalars['Boolean']['input']>;
  maxCommonalities?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSkillsRecommendationsArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  onboarding?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSkillsRecommendationsWithFixedLimitArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  onboarding?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialCommentArgs = {
  id: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  urn: Scalars['GlobalID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialExternalLinkArgs = {
  urn: Scalars['GlobalID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialInteractionTargetArgs = {
  urn: Scalars['GlobalID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialInteractionTargetsArgs = {
  urns?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialProofArgs = {
  urn: Scalars['GlobalID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialProofsArgs = {
  urns?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialReactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  urn: Scalars['GlobalID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialRecentChatsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialShareArgs = {
  id: Scalars['ID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialShareByUrnArgs = {
  urn: Scalars['GlobalID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialShareByUrNsArgs = {
  urns?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialSharesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  urn: Scalars['GlobalID']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerSocialUserSearchArgs = {
  query: Scalars['String']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerStoryCollectionListArgs = {
  supportedActors?: Array<StorySupportedActors>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerTopEmployerRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  consumer: Scalars['String']['input'];
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeNew?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxNDaysOld?: InputMaybe<Scalars['Int']['input']>;
  projobsDashboardFilter?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerTracyUserArgs = {
  consumer: Scalars['String']['input'];
  fields?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerUserJobRecoSettingsArgs = {
  consumer: Scalars['String']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerUserSearchOccurrencesArgs = {
  consumer?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  init?: InputMaybe<Scalars['String']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerVisibilityInsightsArgs = {
  timeFrame?: InputMaybe<TimeFrameFilterOptions>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerVisitorsListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isNewVisit?: InputMaybe<NewVisitType>;
  last?: InputMaybe<Scalars['Int']['input']>;
  recruiter?: InputMaybe<RecruiterFilter>;
  timeFrame?: InputMaybe<TimeFrameFilterOptions>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerVompHighlightsArgs = {
  timeFrame?: InputMaybe<TimeFrameFilterOptions>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerVompNotificationsArgs = {
  notificationId?: InputMaybe<Scalars['ID']['input']>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerVompStatisticsArgs = {
  timeFrame?: InputMaybe<TimeFrameFilterOptions>;
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerXjmJobArgs = {
  id: Scalars['Int']['input'];
};

/** Stuff that is implicitly scoped to the identified viewer */
export type ViewerXtmMessageTemplatesListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type VisibilityInsights = {
  __typename?: 'VisibilityInsights';
  /**
   * #virtual
   *
   *  Total number of news feed appearances
   */
  newsFeedAppearances?: Maybe<Scalars['Int']['output']>;
  /**
   * #paginated #rest #virtual
   *
   *  List of VOMPs
   */
  profileVisits?: Maybe<ProfileVisitorsConnection>;
  /**
   * #virtual
   *
   *  Total number of search appearances
   */
  searchAppearances?: Maybe<Scalars['Int']['output']>;
};

export type VisibilityInsightsProfileVisitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  timeFrame?: InputMaybe<TimeFrameFilterOptions>;
};

/** Visibility Setting Type */
export type VisibilitySetting = {
  __typename?: 'VisibilitySetting';
  visibleToColleagues: Scalars['Boolean']['output'];
  visibleToContacts: Scalars['Boolean']['output'];
  visibleToHeadhunters: Scalars['Boolean']['output'];
  visibleToOthers: Scalars['Boolean']['output'];
  visibleToRecruiters: Scalars['Boolean']['output'];
};

export type VisibleJob = HasGlobalId & {
  __typename?: 'VisibleJob';
  /** #logged-out #soft-logged-in */
  activatedAt?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  activeUntil?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  application?: Maybe<JobApplication>;
  /** #logged-out #soft-logged-in */
  banner?: Maybe<JobBanner>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  careerLevel?: Maybe<CareerLevel>;
  /** #logged-out #soft-logged-in */
  companyInfo: JobCompanyInfo;
  /** #logged-out #soft-logged-in */
  description: JobDescription;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  discipline?: Maybe<Discipline>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  employmentType?: Maybe<EmploymentType>;
  /** #soft-logged-in */
  features?: Maybe<JobFeatures>;
  /** #logged-out #soft-logged-in */
  globalId: Scalars['GlobalID']['output'];
  hiringContact?: Maybe<JobHiringContact>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  industry?: Maybe<Industry>;
  /** #logged-out #soft-logged-in */
  jobCode?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  keywords: Array<Maybe<Scalars['String']['output']>>;
  /** #logged-out #soft-logged-in */
  language?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  location?: Maybe<JobLocation>;
  /** #rest(batched) #virtual */
  matchingHighlights?: Maybe<JobMatchingHighlightsV2>;
  /**
   * #soft-logged-in
   *
   *  DEPRECATED! THIS LIST IS ALWAYS EMPTY!
   * Returns the list of skills that the job and the profile of the current user have in common.
   * @deprecated Please use userProfileMatches instead
   */
  matchingSkills?: Maybe<Array<Maybe<OntologySkill>>>;
  /** #logged-out #soft-logged-in */
  paid: Scalars['Boolean']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Defines if the posting is paid and does not come from a third party
   */
  prioritized: Scalars['Boolean']['output'];
  /**
   * #logged-out #soft-logged-in
   * @deprecated Projob was discontinued. This field will be removed on 2024-03-29!
   */
  projob: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  refreshedAt: Scalars['Date']['output'];
  /** #logged-out #soft-logged-in */
  remoteOptions?: Maybe<Array<Maybe<JobRemoteOptionValue>>>;
  /** #logged-out #soft-logged-in */
  salary?: Maybe<JobSalary>;
  /** #logged-out #soft-logged-in */
  seoName: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  serviceOfferingGroup: Scalars['Int']['output'];
  /** #logged-out #soft-logged-in */
  skills?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #logged-out #soft-logged-in */
  slug: Scalars['String']['output'];
  summary?: Maybe<JobSummary>;
  /** #logged-out #soft-logged-in */
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** #logged-out #soft-logged-in */
  title: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  topJob: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  url: Scalars['URL']['output'];
  /** #logged-out #soft-logged-in */
  userInteractions?: Maybe<JobUserInteractions>;
  /**
   * #soft-logged-in #rest(batched) #virtual
   *
   *  Matches the skills and job role of the current visible job with the current user's profile.
   * Returns two lists of entitized skills of the current job (one with matching skills and one with non-matching skills) and two
   * lists of entitized job roles (one with matching job roles and one with non-matching job roles).
   * The labels of each element are localized according to the user's language (based on the "accept-language" header set by NW1).
   */
  userProfileMatches?: Maybe<OntologyUserJobPostingProfileMatchResponse>;
  /** #rest #virtual */
  userScore?: Maybe<JobUserScore>;
  /**
   * #soft-logged-in #rest(batched) #virtual
   *
   *  DEPRECATED!
   * Contains two lists of entitized skills of the current job, one with matching skills and one with non-matching skills.
   * The labels of each element are localized according to the user's language (based on the "accept-language" header set by NW1).
   * @deprecated Please use userProfileMatches instead
   */
  userSkillMatches?: Maybe<OntologyUserJobPostingSkillsMatchResponse>;
  /** #logged-out #soft-logged-in */
  video?: Maybe<JobVideo>;
};

export type VisibleJobMatchingHighlightsArgs = {
  context: JobMatchingHighlightsContext;
};

export type VisibleJobUserScoreArgs = {
  consumer: Scalars['String']['input'];
};

export type VisitPerDay = {
  __typename?: 'VisitPerDay';
  date: Scalars['Date']['output'];
  quantity: Scalars['Int']['output'];
};

export type VisitsGraph = {
  __typename?: 'VisitsGraph';
  endDate: Scalars['Date']['output'];
  sectionHeadline: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  /** When the user is basic, a description is given */
  upsellDescription?: Maybe<Scalars['String']['output']>;
  /** The array of visits for the specified time frame */
  visits: Array<VisitPerDay>;
  /** The trend percentage representing the visitors increase or decrease on the specified time frame */
  visitsTrend: Scalars['Float']['output'];
};

export type VisitsViewError = {
  __typename?: 'VisitsViewError';
  errors?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type VisitsViewInput = {
  viewedAt: Scalars['Date']['input'];
};

export type VisitsViewResult = {
  __typename?: 'VisitsViewResult';
  error?: Maybe<VisitsViewError>;
  success?: Maybe<VisitsViewSuccess>;
};

export type VisitsViewSuccess = {
  __typename?: 'VisitsViewSuccess';
  viewedAt: Scalars['Date']['output'];
};

export type VitaAccountSettingsInput = {
  email?: InputMaybe<VitaEmailSettingInput>;
};

export type VitaAddressInput = {
  country?: InputMaybe<VitaCountryInput>;
};

export type VitaCountryInput = {
  localizationValue: Scalars['String']['input'];
};

/** Types to describe cover letter */
export type VitaCoverLetter = {
  __typename?: 'VitaCoverLetter';
  /** #logged-out #soft-logged-in */
  addressTo?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  content?: Maybe<Array<VitaCoverLetterContent>>;
  /** #logged-out #soft-logged-in */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  educationExperience?: Maybe<Array<Maybe<VitaDocumentCvEducationExperience>>>;
  /** #logged-out #soft-logged-in */
  fileAttachments?: Maybe<VitaDocumentFileAttachments>;
  /** #logged-out #soft-logged-in */
  jobDetails?: Maybe<VitaJobDetails>;
  /** #logged-out #soft-logged-in */
  language?: Maybe<VitaDocumentLanguage>;
  /** #logged-out #soft-logged-in */
  personalInformation?: Maybe<VitaDocumentCvPersonalInformation>;
  /** #logged-out #soft-logged-in */
  professionalExperience?: Maybe<
    Array<Maybe<VitaDocumentCvProfessionalExperience>>
  >;
  /** #logged-out #soft-logged-in */
  signature?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  skills?: Maybe<Array<Maybe<VitaDocumentCvSkill>>>;
  /** #logged-out #soft-logged-in */
  theme?: Maybe<VitaDocumentTheme>;
  /** #logged-out #soft-logged-in */
  token?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type VitaCoverLetterContent =
  | VitaCoverLetterContentParagraph
  | VitaCoverLetterUnorderedList;

export type VitaCoverLetterContentInput = {
  bold?: InputMaybe<Scalars['Boolean']['input']>;
  children?: InputMaybe<Array<VitaCoverLetterContentInput>>;
  italic?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type VitaCoverLetterContentParagraph = {
  __typename?: 'VitaCoverLetterContentParagraph';
  /** #logged-out #soft-logged-in */
  children?: Maybe<Array<VitaCoverLetterText>>;
  /** #logged-out #soft-logged-in */
  type: Scalars['String']['output'];
};

export type VitaCoverLetterListItem = {
  __typename?: 'VitaCoverLetterListItem';
  /** #logged-out #soft-logged-in */
  children?: Maybe<Array<VitaCoverLetterText>>;
  /** #logged-out #soft-logged-in */
  type: Scalars['String']['output'];
};

export type VitaCoverLetterText = {
  __typename?: 'VitaCoverLetterText';
  /** #logged-out #soft-logged-in */
  bold?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  italic?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  text: Scalars['String']['output'];
};

export type VitaCoverLetterUnorderedList = {
  __typename?: 'VitaCoverLetterUnorderedList';
  /** #logged-out #soft-logged-in */
  children?: Maybe<Array<VitaCoverLetterListItem>>;
  /** #logged-out #soft-logged-in */
  type: Scalars['String']['output'];
};

export type VitaDocument = {
  __typename?: 'VitaDocument';
  /** #logged-out #soft-logged-in */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  cv?: Maybe<VitaDocumentCv>;
  /** #logged-out #soft-logged-in */
  fileAttachments?: Maybe<VitaDocumentFileAttachments>;
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['UUID']['output']>;
  /** #logged-out #soft-logged-in */
  language?: Maybe<VitaDocumentLanguage>;
  /** #logged-out #soft-logged-in */
  theme?: Maybe<VitaDocumentTheme>;
  /** #logged-out #soft-logged-in */
  token?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type VitaDocumentCv = {
  __typename?: 'VitaDocumentCV';
  /** #logged-out #soft-logged-in */
  aboutMe?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  educationExperience?: Maybe<Array<Maybe<VitaDocumentCvEducationExperience>>>;
  /** #logged-out #soft-logged-in */
  personalInformation?: Maybe<VitaDocumentCvPersonalInformation>;
  /** #logged-out #soft-logged-in */
  preferences?: Maybe<VitaDocumentCvPreferences>;
  /** #logged-out #soft-logged-in */
  professionalExperience?: Maybe<
    Array<Maybe<VitaDocumentCvProfessionalExperience>>
  >;
  /** #logged-out #soft-logged-in */
  skills?: Maybe<Array<Maybe<VitaDocumentCvSkill>>>;
};

export type VitaDocumentCvEducationExperience = {
  __typename?: 'VitaDocumentCVEducationExperience';
  /** #logged-out #soft-logged-in */
  courseOfStudy?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  degree?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  endDate?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  institution?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  isStillStudy?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type VitaDocumentCvPersonalInformation = {
  __typename?: 'VitaDocumentCVPersonalInformation';
  /** #logged-out #soft-logged-in */
  birthdate?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  birthplace?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  city?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  country?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  email?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  firstName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  lastName?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  phone?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  street?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  zip?: Maybe<Scalars['String']['output']>;
};

export type VitaDocumentCvPreferences = {
  __typename?: 'VitaDocumentCVPreferences';
  /** #logged-out #soft-logged-in */
  homeOffice?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  locations?: Maybe<Array<Maybe<VitaDocumentCvPreferencesLocation>>>;
  /** #logged-out #soft-logged-in */
  otherSeekingReason?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  seekingReason?: Maybe<VitaSeekingReason>;
  /** #logged-out #soft-logged-in */
  seekingStatus?: Maybe<SeekingStatus>;
  /** #logged-out #soft-logged-in */
  targetPosition?: Maybe<Scalars['String']['output']>;
};

export type VitaDocumentCvPreferencesLocation = {
  __typename?: 'VitaDocumentCVPreferencesLocation';
  /** #logged-out #soft-logged-in */
  cityId?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  cityIdWithScrambling?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  cityName?: Maybe<Scalars['String']['output']>;
};

export type VitaDocumentCvProfessionalExperience = {
  __typename?: 'VitaDocumentCVProfessionalExperience';
  /** #logged-out #soft-logged-in */
  company?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  descriptions?: Maybe<
    Array<Maybe<VitaDocumentCvProfessionalExperienceDescription>>
  >;
  /** #logged-out #soft-logged-in */
  discipline?: Maybe<VitaDocumentCvProfessionalExperienceDiscipline>;
  /** #logged-out #soft-logged-in */
  endDate?: Maybe<Scalars['Date']['output']>;
  /** #logged-out #soft-logged-in */
  industry?: Maybe<VitaDocumentCvProfessionalExperienceIndustry>;
  /** #logged-out #soft-logged-in */
  isCurrentEmployer?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in */
  jobLevel?: Maybe<VitaDocumentCvProfessionalExperienceJobLevel>;
  /** #logged-out #soft-logged-in */
  occupation?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type VitaDocumentCvProfessionalExperienceDescription = {
  __typename?: 'VitaDocumentCVProfessionalExperienceDescription';
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
};

export type VitaDocumentCvProfessionalExperienceDiscipline = {
  __typename?: 'VitaDocumentCVProfessionalExperienceDiscipline';
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

export type VitaDocumentCvProfessionalExperienceIndustry = {
  __typename?: 'VitaDocumentCVProfessionalExperienceIndustry';
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

export type VitaDocumentCvProfessionalExperienceJobLevel = {
  __typename?: 'VitaDocumentCVProfessionalExperienceJobLevel';
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

export type VitaDocumentCvSkill = {
  __typename?: 'VitaDocumentCVSkill';
  /** #logged-out #soft-logged-in */
  skill?: Maybe<Scalars['String']['output']>;
};

export type VitaDocumentError = {
  __typename?: 'VitaDocumentError';
  /** #logged-out #soft-logged-in */
  code?: Maybe<Scalars['Int']['output']>;
  /** #logged-out #soft-logged-in */
  message: Scalars['String']['output'];
};

export type VitaDocumentFileAttachment = {
  __typename?: 'VitaDocumentFileAttachment';
  /** #logged-out #soft-logged-in */
  id?: Maybe<Scalars['ID']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['String']['output']>;
};

export type VitaDocumentFileAttachments = {
  __typename?: 'VitaDocumentFileAttachments';
  /** #logged-out #soft-logged-in */
  profileImage?: Maybe<VitaDocumentFileAttachment>;
};

export const enum VitaDocumentKind {
  All = 'ALL',
  Cl = 'CL',
  Cv = 'CV',
}

export const enum VitaDocumentLanguage {
  De = 'DE',
  En = 'EN',
}

export const enum VitaDocumentOrigin {
  Lebenslauf = 'LEBENSLAUF',
  Xing = 'XING',
}

export type VitaDocumentRenderingStatus = {
  __typename?: 'VitaDocumentRenderingStatus';
  /** #logged-out #soft-logged-in */
  status: VitaRenderingStatus;
  /** #logged-out #soft-logged-in */
  token?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['String']['output']>;
};

export type VitaDocumentRenderingStatusError = {
  __typename?: 'VitaDocumentRenderingStatusError';
  /** #logged-out #soft-logged-in */
  message?: Maybe<Scalars['String']['output']>;
};

export type VitaDocumentRenderingStatusResult =
  | VitaDocumentRenderingStatus
  | VitaDocumentRenderingStatusError;

export type VitaDocumentResult =
  | VitaCoverLetter
  | VitaDocument
  | VitaDocumentError;

export type VitaDocumentTheme = {
  __typename?: 'VitaDocumentTheme';
  /** #logged-out #soft-logged-in */
  color?: Maybe<VitaDocumentThemeColor>;
  /** #logged-out #soft-logged-in */
  template?: Maybe<VitaDocumentThemeTemplate>;
};

export const enum VitaDocumentThemeColor {
  Brown = 'BROWN',
  DarkBlue = 'DARK_BLUE',
  DarkGreen = 'DARK_GREEN',
  Grey = 'GREY',
  LightBlue = 'LIGHT_BLUE',
  LightGreen = 'LIGHT_GREEN',
  LightSea = 'LIGHT_SEA',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
}

export const enum VitaDocumentThemeTemplate {
  Business = 'BUSINESS',
  Creative = 'CREATIVE',
  Elegant = 'ELEGANT',
  Frame = 'FRAME',
  Modern = 'MODERN',
  Timeline = 'TIMELINE',
  Traditional = 'TRADITIONAL',
  TraditionalCoverLetter = 'TRADITIONAL_COVER_LETTER',
}

export type VitaEmailSettingInput = {
  active?: InputMaybe<Scalars['Email']['input']>;
};

export type VitaImportDocumentPersonalInformation = {
  __typename?: 'VitaImportDocumentPersonalInformation';
  birthdate?: Maybe<Scalars['Date']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  /** #rest #virtual */
  settings?: Maybe<AccountSettings>;
  street?: Maybe<Scalars['String']['output']>;
  /** #rest #virtual */
  userDetails?: Maybe<XingIdContactDetails>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type VitaImportDocumentPersonalInformationInput = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<VitaAccountSettingsInput>;
  street?: InputMaybe<Scalars['String']['input']>;
  userDetails?: InputMaybe<VitaXingIdContactDetailsInput>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type VitaIndustryDisciplineJobLevelInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  localizationValue?: InputMaybe<Scalars['String']['input']>;
};

export type VitaJobDetails = {
  __typename?: 'VitaJobDetails';
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  title?: Maybe<Scalars['String']['output']>;
};

export type VitaJobDetailsInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type VitaProfileAboutMe = {
  __typename?: 'VitaProfileAboutMe';
  aboutMe?: Maybe<Scalars['String']['output']>;
};

export type VitaProfileAboutMeInput = {
  aboutMe?: InputMaybe<Scalars['String']['input']>;
};

export type VitaProfileEducation = {
  __typename?: 'VitaProfileEducation';
  courseOfStudy?: Maybe<Scalars['String']['output']>;
  degree?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  institution?: Maybe<Scalars['String']['output']>;
  isStillStudy?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type VitaProfileEducationCollection = {
  __typename?: 'VitaProfileEducationCollection';
  education?: Maybe<Array<Maybe<VitaProfileEducation>>>;
};

export type VitaProfileEducationCollectionInput = {
  education?: InputMaybe<Array<InputMaybe<VitaProfileEducationInput>>>;
};

export type VitaProfileEducationInput = {
  courseOfStudy?: InputMaybe<Scalars['String']['input']>;
  degree?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  institution?: InputMaybe<Scalars['String']['input']>;
  isStillStudy?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

/** TYPES to retrieve Profile import data. */
export type VitaProfileImport = {
  __typename?: 'VitaProfileImport';
  /** #rest #virtual */
  aboutMe?: Maybe<VitaProfileAboutMe>;
  /** #rest #virtual */
  educationExperience?: Maybe<VitaProfileEducationCollection>;
  /** #rest(batched) #virtual */
  personalInformation?: Maybe<VitaImportDocumentPersonalInformation>;
  /** #rest #virtual */
  professionalExperience?: Maybe<VitaProfileWorkExperienceCollection>;
  /** #rest #virtual */
  skills?: Maybe<VitaProfileSkills>;
};

export type VitaProfileSkill = {
  __typename?: 'VitaProfileSkill';
  value?: Maybe<Scalars['String']['output']>;
};

export type VitaProfileSkillInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type VitaProfileSkills = {
  __typename?: 'VitaProfileSkills';
  collection?: Maybe<Array<Maybe<VitaProfileSkill>>>;
};

export type VitaProfileSkillsInput = {
  collection?: InputMaybe<Array<InputMaybe<VitaProfileSkillInput>>>;
};

export type VitaProfileWorkExperience = {
  __typename?: 'VitaProfileWorkExperience';
  company?: Maybe<Scalars['String']['output']>;
  descriptions?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  discipline?: Maybe<Discipline>;
  endDate?: Maybe<Scalars['String']['output']>;
  /** #rest(batched) #virtual */
  industries?: Maybe<Array<Maybe<Industry>>>;
  isCurrentEmployer?: Maybe<Scalars['Boolean']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  jobLevel?: Maybe<CareerLevel>;
  occupation?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type VitaProfileWorkExperienceCollection = {
  __typename?: 'VitaProfileWorkExperienceCollection';
  collection?: Maybe<Array<Maybe<VitaProfileWorkExperience>>>;
};

export type VitaProfileWorkExperienceCollectionInput = {
  collection?: InputMaybe<Array<InputMaybe<VitaProfileWorkExperienceInput>>>;
};

export type VitaProfileWorkExperienceInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  descriptions?: InputMaybe<Scalars['String']['input']>;
  discipline?: InputMaybe<VitaIndustryDisciplineJobLevelInput>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  industries?: InputMaybe<
    Array<InputMaybe<VitaIndustryDisciplineJobLevelInput>>
  >;
  isCurrentEmployer?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<VitaIndustryDisciplineJobLevelInput>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export const enum VitaRenderingStatus {
  None = 'NONE',
  Pending = 'PENDING',
  Ready = 'READY',
}

export const enum VitaSeekingReason {
  AlreadyHaveJobToApply = 'ALREADY_HAVE_JOB_TO_APPLY',
  JustTestCvEditor = 'JUST_TEST_CV_EDITOR',
  KeepCvUpToDate = 'KEEP_CV_UP_TO_DATE',
  NotForJobToApply = 'NOT_FOR_JOB_TO_APPLY',
  Other = 'OTHER',
}

export type VitaXingIdContactDetailsDataInput = {
  address?: InputMaybe<VitaAddressInput>;
};

export type VitaXingIdContactDetailsInput = {
  business?: InputMaybe<VitaXingIdContactDetailsDataInput>;
  private?: InputMaybe<VitaXingIdContactDetailsDataInput>;
};

export type VompClickReason = {
  /** Encoded Click Reason data. */
  encoded?: InputMaybe<Scalars['String']['input']>;
  /** Input Click Reason data to be encoded. */
  input?: InputMaybe<ClickReasonInput>;
};

export type VompConversationStarter = ConversationStarter & {
  __typename?: 'VompConversationStarter';
  /** #rest(batched) #virtual */
  actor?: Maybe<QualifiedVompVisitor>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest(batched) #virtual */
  object?: Maybe<QualifiedVomp>;
  /** #rest(batched) #virtual */
  target?: Maybe<QualifiedVompVisitee>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type VompHighlights = {
  __typename?: 'VompHighlights';
  /**
   * #virtual
   *
   *  Total number of recruiters
   */
  recruiters?: Maybe<Scalars['Int']['output']>;
  /**
   * #virtual
   *
   *  Total number of visits
   */
  totalVisits: Scalars['Int']['output'];
  /**
   * #virtual
   *
   *  Total number of unique visitors
   */
  visitors?: Maybe<Scalars['Int']['output']>;
};

/** VOMP Notification Type */
export type VompNotification = {
  __typename?: 'VompNotification';
  /** VOMP Notification ID */
  id: Scalars['ID']['output'];
  /** Unit of time regarding the interval what the visits have been done */
  intervalUnitTime: VompNotificationUnitTime;
  /** Value of the interval what the visits have been done */
  intervalValue: Scalars['Int']['output'];
  /** Collection of visits */
  visits?: Maybe<Array<VompVisit>>;
  /** Trend percentage representing the increase or decrease by the visits */
  visitsTrend?: Maybe<Scalars['Float']['output']>;
};

/** Available unit of times for the interval of time about the visits */
export const enum VompNotificationUnitTime {
  Days = 'DAYS',
  Hours = 'HOURS',
}

export const enum VompPlatformEnum {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
  WebLargeScreen = 'WEB_LARGE_SCREEN',
  WebSmallScreen = 'WEB_SMALL_SCREEN',
}

export type VompStack = SignalsStack & {
  __typename?: 'VompStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type VompStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type VompStatistics = {
  __typename?: 'VompStatistics';
  companyVisitors: StatisticsItem;
  firstTimeVisitors: StatisticsItem;
  /** @deprecated It should be replaced to graphVisitor. */
  graph?: Maybe<Array<StatisticsGraph>>;
  graphVisitor: VisitsGraph;
  industryVisitors: StatisticsItem;
  recruiters: StatisticsItem;
  searchTerms: StatisticsItem;
  visitorTypes: StatisticsItem;
};

export type VompVisit = {
  type: Scalars['String']['output'];
  viewedAt: Scalars['Date']['output'];
  visitorId: Scalars['ID']['output'];
};

export type WebOrderInput = {
  contractId: Scalars['ID']['input'];
};

export type WebPushSubscription = {
  __typename?: 'WebPushSubscription';
  /** #logged-out #soft-logged-in */
  authDetails?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  createdAt?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  ellipticCurve?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  events: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  jobId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  locale?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  notificationEnv: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  publicKey?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  subscriptionStatus: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  updatedAt?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  url?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in */
  visitorId: Scalars['String']['output'];
};

/**
 * [TETRIS](https://new-work.atlassian.net/wiki/spaces/SOLAR/pages/45877297/REST+API+for+TETRIS) tracking properties for the viewer.
 *
 * This specifically uses a different field casing than the normal `camelCase` of the
 * `New Work One` platform, because it's exported to an external tracking system
 */
export type WebTrackingData = {
  __typename?: 'WebTrackingData';
  PropAccountDuration?: Maybe<Scalars['Int']['output']>;
  PropApplicationLanguage?: Maybe<Scalars['String']['output']>;
  PropBrazeAuthToken?: Maybe<Scalars['String']['output']>;
  PropHashedUserId?: Maybe<Scalars['String']['output']>;
  PropLastLogin?: Maybe<Scalars['Int']['output']>;
  PropMembership?: Maybe<Scalars['Int']['output']>;
  PropMemberships?: Maybe<Scalars['String']['output']>;
  PropNumberContacts?: Maybe<Scalars['Int']['output']>;
  PropSuccessfulInvitation?: Maybe<Scalars['Int']['output']>;
  /** #soft-logged-in */
  PropTrackingThirdpartyExternal?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  PropTrackingThirdpartyMarketing?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  PropTrackingThirdpartyStatistical?: Maybe<Scalars['Boolean']['output']>;
  PropUserBusinessCountry?: Maybe<Scalars['String']['output']>;
  PropUserBusinessStatus?: Maybe<Scalars['String']['output']>;
};

export type WillingnessToChangeJob = {
  __typename?: 'WillingnessToChangeJob';
  probability?: Maybe<Scalars['String']['output']>;
  reasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export const enum WillingnessToTravelPreference {
  Frequent = 'FREQUENT',
  None = 'NONE',
  Occasional = 'OCCASIONAL',
}

export const enum WillingnessToTravelPreferenceEnum {
  Frequent = 'FREQUENT',
  None = 'NONE',
  Occasional = 'OCCASIONAL',
}

export type WinningAd = {
  /** #logged-out #soft-logged-in */
  deliveryId: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   * @deprecated Field no longer supported
   */
  id: Scalars['Int']['output'];
  /**
   * #logged-out #soft-logged-in
   * @deprecated ODT shutdown
   */
  odtTrackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  sender: AdSender;
  /** #logged-out #soft-logged-in */
  sid: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  surn: Scalars['GlobalID']['output'];
  /** #logged-out #soft-logged-in */
  trackingToken: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  type: AdTypes;
};

export type WorkExperienceError = {
  __typename?: 'WorkExperienceError';
  description?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WorkExperienceFieldErrors>;
  message: Scalars['String']['output'];
};

export type WorkExperienceFieldErrors = {
  __typename?: 'WorkExperienceFieldErrors';
  beginDate?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  companyId?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  companyName?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  disciplineId?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  endDate?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  industryId?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  jobTitle?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  levelId?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  statusId?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type WorkExperienceStatus = Localizable & {
  __typename?: 'WorkExperienceStatus';
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /** #logged-out #soft-logged-in */
  localizationKey: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  localizationValue: Scalars['String']['output'];
};

export type WorkExperienceUpdateConversationStarter = ConversationStarter & {
  __typename?: 'WorkExperienceUpdateConversationStarter';
  /** #rest(batched) #virtual */
  actor?: Maybe<XingId>;
  createdAt: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** #rest #virtual */
  object?: Maybe<ProfileWorkExperienceUpdate>;
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type WorkExperienceUpdateStack = SignalsStack & {
  __typename?: 'WorkExperienceUpdateStack';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  listedAt: Scalars['Date']['output'];
  newSignals: Scalars['Int']['output'];
  /**
   * #paginated
   * @deprecated Conversation Starters app is decommissioned
   */
  signals?: Maybe<SignalsCollectionConnection>;
  totalSignals: Scalars['Int']['output'];
  trackingToken?: Maybe<Scalars['String']['output']>;
};

export type WorkExperienceUpdateStackSignalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** WorkingHoursPreference Type */
export type WorkingHoursPreference = {
  __typename?: 'WorkingHoursPreference';
  fullTime: Scalars['Boolean']['output'];
  partTime: Scalars['Boolean']['output'];
};

/** WorkplacePreference Type */
export type WorkplacePreference = {
  __typename?: 'WorkplacePreference';
  homeOffice: Scalars['Boolean']['output'];
  mostlyHome: Scalars['Boolean']['output'];
  office: Scalars['Boolean']['output'];
  partlyHome: Scalars['Boolean']['output'];
};

/** Information about a Job for the editor */
export type XjmJob = {
  __typename?: 'XJMJob';
  id: Scalars['ID']['output'];
  jobManagerUrl: Scalars['URL']['output'];
  /** #rest #virtual */
  potentialCandidates?: Maybe<PotentialCandidatesCollection>;
  /** #rest #virtual */
  potentialCandidatesTotals?: Maybe<PotentialCandidatesTotals>;
};

/** Information about a Job for the editor */
export type XjmJobPotentialCandidatesArgs = {
  interactions: Array<PotentialCandidatesInteraction>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  order: PotentialCandidatesOrder;
};

export type XjmProfileAdditionalDetails = {
  __typename?: 'XJMProfileAdditionalDetails';
  businessCity?: Maybe<Scalars['String']['output']>;
  businessCountry?: Maybe<Scalars['String']['output']>;
  education?: Maybe<Array<Maybe<ProfileEducation>>>;
  workExperience?: Maybe<Array<Maybe<ProfileWorkExperience>>>;
};

export type XingId = HasGlobalId & {
  __typename?: 'XingId';
  /** #logged-out #soft-logged-in */
  academicTitle?: Maybe<AcademicTitle>;
  /**
   * #rest(batched) #virtual
   *
   *  B2B Client ID
   */
  b2bClientId?: Maybe<ProfilesB2bClientIdPayload>;
  /** @deprecated Is not part of XingID type anymore. Will be exposed differently. Checked on 2023.03.16 - still used by unknown Web versions */
  birthday?: Maybe<Scalars['Date']['output']>;
  /**
   * #rest(batched) #virtual
   *
   *  Request Click Reason data by its ID.
   */
  clickReasonProfileUrl?: Maybe<ClickReason>;
  /**
   * #rest(batched) #virtual
   *
   *  Request Click Reason data by its ID from a specific Entity.
   * SourceEntityId is basically the ID of the entity where the profile URL was seen and clicked.
   * For instance, if you see a profile link in a group, the sourceEntityId will be the group ID.
   */
  clickReasonProfileUrlFromEntity?: Maybe<ClickReason>;
  /**
   * Level of contact. 1 - in contact, 0 - not a contact, 2 - contact of your contacts etc
   * @deprecated This field is no longer served in XingId node. Checked on 2023.03.16 - still used by active mobile app versions
   */
  contactDegree?: Maybe<Scalars['Int']['output']>;
  /**
   * #logged-out #soft-logged-in #virtual
   *
   *  Combination of memberships and roles to be displayed as flag (UI related). Replaced by userFlags. Removed from the BE, will always return null
   * @deprecated Replaced by userFlags. Removed from the BE, will always return null. Checked on 2023.03.16 - still used by old mobile versions and unknown web ver.
   */
  displayFlag?: Maybe<DisplayFlag>;
  /**
   * #logged-out #soft-logged-in
   *
   *  academic_title + first_name + last_name
   */
  displayName: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  firstName: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  gender?: Maybe<Gender>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Globally unique id for this instance based on typename and id
   */
  globalId: Scalars['GlobalID']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Indicates whether users have a default headerImage for their current subscription.
   */
  hasDefaultHeaderImage: Scalars['Boolean']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Hiring Details Flag
   */
  hasHiringDetails?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Does the user have a profile photo?
   */
  hasNoProfilePhoto: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in #virtual */
  headerImage?: Maybe<Array<HeaderImage>>;
  /** #logged-out #soft-logged-in */
  id: Scalars['ID']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  This XING ID is not accessible to the requester. Will be true if the requester is blocked by the XING ID (requesterIsStalker) OR the requester is blocking this XING ID (requesterIsVictim)
   */
  isBlockedForViewer: Scalars['Boolean']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Hiring Flag
   */
  isHiring?: Maybe<Scalars['Boolean']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Indicates whether headerImage for user must be upselled (fenced) or not
   */
  isUpsellRequiredForHeaderImage: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  lastName: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  location?: Maybe<XingIdLocation>;
  /**
   * #rest(batched) #virtual
   *
   *  Relationship between 2 users
   */
  networkRelationship?: Maybe<NetworkRelationship>;
  /**
   * #logged-out #soft-logged-in
   *
   *  User-defined occupations data
   */
  occupations?: Maybe<Array<Maybe<XingIdOccupation>>>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Used inside urls e.g. /profile/John_Doe
   */
  pageName: Scalars['String']['output'];
  /** #logged-out #soft-logged-in */
  profileImage?: Maybe<Array<Maybe<ProfileImage>>>;
  /**
   * #logged-out #soft-logged-in #rest(batched) #virtual
   * @deprecated Use occupations instead. Checked on 14.11.2023 - still used a lot by Web and Mobile
   */
  profileOccupation?: Maybe<ProfileOccupation>;
  /**
   * Self representational quote of the user
   * @deprecated Is not part of XingID type anymore. See `status` field (in IDL). Checked on 2023.03.16 - still used by old mobile versions
   */
  profileQuote?: Maybe<Scalars['String']['output']>;
  /**
   * #logged-out #soft-logged-in
   *
   *  The requester is blocked by the XING ID. In other words, the requester is the stalker here and the visited XING ID is the victim
   */
  requesterIsStalker: Scalars['Boolean']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  The requester is blocking this XING ID. In other words, the requester is the victim here and the visited XING ID is the stalker
   */
  requesterIsVictim: Scalars['Boolean']['output'];
  /** #logged-out #soft-logged-in */
  status?: Maybe<ProfilePersonalStatus>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  userFlags?: Maybe<UserFlags>;
};

export type XingIdClickReasonProfileUrlArgs = {
  clickReasonId: ClickReasonsEnum;
};

export type XingIdClickReasonProfileUrlFromEntityArgs = {
  clickReasonId: ClickReasonsEnum;
  sourceEntityId: Scalars['SlugOrID']['input'];
};

export type XingIdHeaderImageArgs = {
  options: Array<ImageOptions>;
};

export type XingIdProfileImageArgs = {
  size: Array<ProfileImageSize>;
};

/** XingIdActionInterface */
export type XingIdActionInterface = {
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Action Payload for XING ID Module actions */
export type XingIdActionPayloadInterface = {
  xingIdModule?: Maybe<XingIdModule>;
};

/** Action Payload for XING ID Module actions */
export type XingIdActionPayloadInterfaceXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

/** Add a Contact */
export type XingIdAddAsContactAction = XingIdActionInterface & {
  __typename?: 'XingIdAddAsContactAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

export type XingIdAddContactInput = {
  id: Scalars['ID']['input'];
};

export type XingIdAddContactPayload = XingIdActionPayloadInterface & {
  __typename?: 'XingIdAddContactPayload';
  /** #virtual */
  error?: Maybe<Scalars['DomainError']['output']>;
  /**
   * #rest
   *
   *  XingIdModule of the target user (the contacted user)
   */
  xingIdModule?: Maybe<XingIdModule>;
};

export type XingIdAddContactPayloadXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

/** Add a ProBusiness Lead */
export type XingIdAddLeadAction = XingIdActionInterface & {
  __typename?: 'XingIdAddLeadAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Block a user */
export type XingIdBlockUserAction = XingIdActionInterface & {
  __typename?: 'XingIdBlockUserAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Bookmarking Users Feature was shutdown on 2020-11-23. This value need to be kept for legacy clients. */
export type XingIdBookmarkUserAction = XingIdActionInterface & {
  __typename?: 'XingIdBookmarkUserAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Do a phone call for a profile */
export type XingIdCallAction = XingIdActionInterface & {
  __typename?: 'XingIdCallAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Confirm a contact request */
export type XingIdConfirmContactAction = XingIdActionInterface & {
  __typename?: 'XingIdConfirmContactAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

export type XingIdConfirmContactInput = {
  id: Scalars['ID']['input'];
};

/** XingIdConfirmContact payload */
export type XingIdConfirmContactPayload = XingIdActionPayloadInterface & {
  __typename?: 'XingIdConfirmContactPayload';
  /** #virtual */
  error?: Maybe<Scalars['DomainError']['output']>;
  /**
   * #rest
   *
   *  XingIdModule of the target user (the confirmed user as a contact)
   */
  xingIdModule?: Maybe<XingIdModule>;
};

/** XingIdConfirmContact payload */
export type XingIdConfirmContactPayloadXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

/** Contact details of the user */
export type XingIdContactDetails = {
  __typename?: 'XingIdContactDetails';
  /** Business contact details of the user */
  business?: Maybe<XingIdContactDetailsData>;
  /** Private contact details of the user */
  private?: Maybe<XingIdContactDetailsData>;
};

/** Contact details data of the user */
export type XingIdContactDetailsData = {
  __typename?: 'XingIdContactDetailsData';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['Email']['output']>;
  fax?: Maybe<PhoneNumber>;
  mobile?: Maybe<PhoneNumber>;
  phone?: Maybe<PhoneNumber>;
};

/** Decline contact request */
export type XingIdDeclineContactRequestAction = XingIdActionInterface & {
  __typename?: 'XingIdDeclineContactRequestAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

export type XingIdDeclineContactRequestInput = {
  id: Scalars['ID']['input'];
};

/** XingIdDeclineContactRequest payload */
export type XingIdDeclineContactRequestPayload =
  XingIdActionPayloadInterface & {
    __typename?: 'XingIdDeclineContactRequestPayload';
    /** #virtual */
    error?: Maybe<Scalars['DomainError']['output']>;
    /**
     * #rest
     *
     *  XingIdModule of the target user
     */
    xingIdModule?: Maybe<XingIdModule>;
  };

/** XingIdDeclineContactRequest payload */
export type XingIdDeclineContactRequestPayloadXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

/** Delete contact */
export type XingIdDeleteContactAction = XingIdActionInterface & {
  __typename?: 'XingIdDeleteContactAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

export type XingIdDeleteContactInput = {
  id: Scalars['ID']['input'];
};

/** XingIdDeleteContact payload */
export type XingIdDeleteContactPayload = XingIdActionPayloadInterface & {
  __typename?: 'XingIdDeleteContactPayload';
  /** #virtual */
  error?: Maybe<Scalars['DomainError']['output']>;
  /**
   * #rest
   *
   *  XingIdModule of the target user
   */
  xingIdModule?: Maybe<XingIdModule>;
};

/** XingIdDeleteContact payload */
export type XingIdDeleteContactPayloadXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

/** XingIdDeleteHeaderImage payload */
export type XingIdDeleteHeaderImagePayload = XingIdActionPayloadInterface & {
  __typename?: 'XingIdDeleteHeaderImagePayload';
  /** #virtual */
  error?: Maybe<Scalars['DomainError']['output']>;
  /**
   * #rest
   *
   *  XingIdModule of the logged in user
   */
  xingIdModule?: Maybe<XingIdModule>;
};

/** XingIdDeleteHeaderImage payload */
export type XingIdDeleteHeaderImagePayloadXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

/** XingIdDeleteProfileImage payload */
export type XingIdDeleteProfileImagePayload = XingIdActionPayloadInterface & {
  __typename?: 'XingIdDeleteProfileImagePayload';
  /** #virtual */
  error?: Maybe<Scalars['DomainError']['output']>;
  /**
   * #rest
   *
   *  XingIdModule of the logged in user
   */
  xingIdModule?: Maybe<XingIdModule>;
};

/** XingIdDeleteProfileImage payload */
export type XingIdDeleteProfileImagePayloadXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

/** Edit your XING ID */
export type XingIdEditXingIdAction = XingIdActionInterface & {
  __typename?: 'XingIdEditXingIdAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Follow an insider */
export type XingIdFollowAction = XingIdActionInterface & {
  __typename?: 'XingIdFollowAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

export type XingIdFollowInput = {
  id: Scalars['ID']['input'];
};

/** XingIdFollow payload */
export type XingIdFollowPayload = XingIdActionPayloadInterface & {
  __typename?: 'XingIdFollowPayload';
  /** #virtual */
  error?: Maybe<Scalars['DomainError']['output']>;
  /**
   * #rest
   *
   *  XingIdModule of the target user (the followed user)
   */
  xingIdModule?: Maybe<XingIdModule>;
};

/** XingIdFollow payload */
export type XingIdFollowPayloadXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

/** Import VCard */
export type XingIdImportVcardAction = XingIdActionInterface & {
  __typename?: 'XingIdImportVcardAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Invite user to a group */
export type XingIdInviteGroupAction = XingIdActionInterface & {
  __typename?: 'XingIdInviteGroupAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Launch profile filling wizard for my profile */
export type XingIdLaunchWizardAction = XingIdActionInterface & {
  __typename?: 'XingIdLaunchWizardAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Determines which layout should be used when rendering this module */
export const enum XingIdLayout {
  /** Represents the basic layout */
  Basic = 'BASIC',
  /** Represents the premium layout */
  Premium = 'PREMIUM',
}

/** XING ID Location */
export type XingIdLocation = Addressable & {
  __typename?: 'XingIdLocation';
  /** #logged-out #soft-logged-in */
  city?: Maybe<Scalars['String']['output']>;
  /** #logged-out #soft-logged-in #rest(batched) #virtual */
  country?: Maybe<Country>;
  /** #logged-out #soft-logged-in */
  displayLocation?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  province?: Maybe<Province>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type XingIdModule = ModuleInterface &
  OutdatableModule & {
    __typename?: 'XingIdModule';
    /**
     * #soft-logged-in
     *
     *  Call to Actions
     */
    actions: Array<XingIdActionInterface>;
    active?: Maybe<Scalars['Boolean']['output']>;
    /**
     * #soft-logged-in #rest #virtual
     *
     *  Private and business contact details of the user
     */
    contactDetails?: Maybe<XingIdContactDetails>;
    /** #soft-logged-in */
    globalId: Scalars['GlobalID']['output'];
    /** #soft-logged-in */
    lastModified?: Maybe<Scalars['Date']['output']>;
    /** #soft-logged-in */
    layout: XingIdLayout;
    /** #soft-logged-in */
    order: Scalars['Int']['output'];
    /** #soft-logged-in */
    outdated: Scalars['Boolean']['output'];
    /** #soft-logged-in */
    outdatedFields?: Maybe<Scalars['String']['output']>;
    /** #soft-logged-in */
    title: Scalars['String']['output'];
    /** #rest(batched) #virtual */
    xingId?: Maybe<XingId>;
  };

/** Show my ProJob's career wishes settings */
export type XingIdMyCareerWishesAction = XingIdActionInterface & {
  __typename?: 'XingIdMyCareerWishesAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** XING ID Occupations */
export type XingIdOccupation = {
  __typename?: 'XingIdOccupation';
  /**
   * #logged-out #soft-logged-in
   *
   *  Identifier of Occupation's category
   */
  category: XingIdOccupationCategory;
  /**
   * #logged-out #soft-logged-in
   *
   *  Company name for employees, school name for students, occupation text for insiders, coaches and ambassadors
   */
  headline: Scalars['String']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Fallback links for URN links in the summary
   */
  links?: Maybe<Array<XingIdOccupationLink>>;
  /**
   * #logged-out #soft-logged-in
   *
   *  Job title for employees, subject for students, empty for insiders, coaches and ambassadors
   */
  subline: Scalars['String']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  Markdown-powered occupation text. The text translated using current_user's language settings
   */
  summary: Scalars['Markdown']['output'];
};

/** Describes category of occupation */
export const enum XingIdOccupationCategory {
  /** student, teacher etc */
  Education = 'EDUCATION',
  /** retired, unemployed etc */
  Inactive = 'INACTIVE',
  /** recruiter */
  Other = 'OTHER',
  /** ambassador, insider, etc */
  Role = 'ROLE',
  /** employee, executive etc */
  WorkExperience = 'WORK_EXPERIENCE',
}

/** XING ID Occupation Links */
export type XingIdOccupationLink = UniformResourceNameable & {
  __typename?: 'XingIdOccupationLink';
  /**
   * #logged-out #soft-logged-in
   *
   *  Fallback HTTP link
   */
  url: Scalars['URL']['output'];
  /**
   * #logged-out #soft-logged-in
   *
   *  URN which is linked in the summary
   */
  urn: Scalars['URN']['output'];
};

/** Enum type of the bucket */
export const enum XingIdOccupationsBucketType {
  /** Current occupations */
  Current = 'CURRENT',
  /** Occupations that represent the degrees of a user. */
  Degrees = 'DEGREES',
  /** Past occupations */
  Past = 'PAST',
  /** Roles occupations */
  Roles = 'ROLES',
  /** Publicliy visible occupations */
  Visible = 'VISIBLE',
}

/** Open the insider article collection */
export type XingIdOpenInsiderCollectionAction = XingIdActionInterface & {
  __typename?: 'XingIdOpenInsiderCollectionAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Open LO Profile Preview */
export type XingIdOpenLoPreviewAction = XingIdActionInterface & {
  __typename?: 'XingIdOpenLoPreviewAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Open Recruiter Previewo of XTM */
export type XingIdOpenRecruiterPreviewAction = XingIdActionInterface & {
  __typename?: 'XingIdOpenRecruiterPreviewAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Open settings */
export type XingIdOpenSettingsAction = XingIdActionInterface & {
  __typename?: 'XingIdOpenSettingsAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Open profile in XTM  application */
export type XingIdOpenXtmAction = XingIdActionInterface & {
  __typename?: 'XingIdOpenXtmAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

export type XingIdOrError = ResponseError | XingId;

export const enum XingIdPlatform {
  /** Notify mobile apps */
  Mobile = 'MOBILE',
  /** Do not notify any channel */
  None = 'NONE',
  /** Notify web */
  Web = 'WEB',
}

/** Show a printable version of the profile */
export type XingIdPrintAction = XingIdActionInterface & {
  __typename?: 'XingIdPrintAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Remove a ProBusiness Lead */
export type XingIdRemoveLeadAction = XingIdActionInterface & {
  __typename?: 'XingIdRemoveLeadAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

export type XingIdReorderOccupationsInput = {
  occupationIds: Array<Scalars['ID']['input']>;
};

/** XingIdReorderOccupations response object */
export type XingIdReorderOccupationsPayload = XingIdActionPayloadInterface & {
  __typename?: 'XingIdReorderOccupationsPayload';
  /** #virtual */
  error?: Maybe<Scalars['DomainError']['output']>;
  /**
   * #rest
   *
   *  XingIdModule of the logged in user
   */
  xingIdModule?: Maybe<XingIdModule>;
};

/** XingIdReorderOccupations response object */
export type XingIdReorderOccupationsPayloadXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

/** Report the profile */
export type XingIdReportProfileAction = XingIdActionInterface & {
  __typename?: 'XingIdReportProfileAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Send a message */
export type XingIdSendMessageAction = XingIdActionInterface & {
  __typename?: 'XingIdSendMessageAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Share a profile */
export type XingIdShareAction = XingIdActionInterface & {
  __typename?: 'XingIdShareAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Show my ProJob's career wishes */
export type XingIdShowCareerWishesAction = XingIdActionInterface & {
  __typename?: 'XingIdShowCareerWishesAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Show users contact details */
export type XingIdShowContactDetailsAction = XingIdActionInterface & {
  __typename?: 'XingIdShowContactDetailsAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Unblock a user */
export type XingIdUnblockUserAction = XingIdActionInterface & {
  __typename?: 'XingIdUnblockUserAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Bookmarking Users Feature was shutdown on 2020-11-23. This value need to be kept for legacy clients. */
export type XingIdUnbookmarkUserAction = XingIdActionInterface & {
  __typename?: 'XingIdUnbookmarkUserAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

/** Unfollow an insider */
export type XingIdUnfollowAction = XingIdActionInterface & {
  __typename?: 'XingIdUnfollowAction';
  /** #soft-logged-in */
  isUpsellRequiredForViewer?: Maybe<Scalars['Boolean']['output']>;
  /** #soft-logged-in */
  label: Scalars['String']['output'];
  /** #soft-logged-in */
  order: Scalars['Int']['output'];
  /** #soft-logged-in */
  type: AvailableAction;
};

export type XingIdUnfollowInput = {
  id: Scalars['ID']['input'];
};

/** XingIdUnfollow payload */
export type XingIdUnfollowPayload = XingIdActionPayloadInterface & {
  __typename?: 'XingIdUnfollowPayload';
  /** #virtual */
  error?: Maybe<Scalars['DomainError']['output']>;
  /**
   * #rest
   *
   *  XingIdModule of the target user (the unfollowed user)
   */
  xingIdModule?: Maybe<XingIdModule>;
};

/** XingIdUnfollow payload */
export type XingIdUnfollowPayloadXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

export type XingIdUpdateContactDetails = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<CountryCodesAlpha2>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The canonical name of the province */
  provinceName?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type XingIdUpdateContactDetailsInput = {
  business?: InputMaybe<XingIdUpdateContactDetails>;
  private?: InputMaybe<XingIdUpdateContactDetails>;
};

export type XingIdUpdateContactDetailsPayload = XingIdActionPayloadInterface & {
  __typename?: 'XingIdUpdateContactDetailsPayload';
  /** #virtual */
  error?: Maybe<Scalars['DomainError']['output']>;
  /**
   * #rest
   *
   *  XingId module of the logged-in user
   */
  xingIdModule?: Maybe<XingIdModule>;
};

export type XingIdUpdateContactDetailsPayloadXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

export type XingIdUpdateHeaderImageInput = {
  /** base64 encoded image */
  image64: Scalars['String']['input'];
  publishStreamStory?: InputMaybe<Scalars['Boolean']['input']>;
};

/** XingIdUpdateHeaderImage payload */
export type XingIdUpdateHeaderImagePayload = XingIdActionPayloadInterface & {
  __typename?: 'XingIdUpdateHeaderImagePayload';
  /** #virtual */
  error?: Maybe<Scalars['DomainError']['output']>;
  /**
   * #rest
   *
   *  XingIdModule of the logged in user
   */
  xingIdModule?: Maybe<XingIdModule>;
};

/** XingIdUpdateHeaderImage payload */
export type XingIdUpdateHeaderImagePayloadXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

export type XingIdUpdateProfileImageInput = {
  /** base64 encoded image */
  image64: Scalars['String']['input'];
  notifyPlatforms: Array<XingIdPlatform>;
};

/** XingIdUpdateProfileImage Payload */
export type XingIdUpdateProfileImagePayload = {
  __typename?: 'XingIdUpdateProfileImagePayload';
  /** #virtual */
  error?: Maybe<Scalars['DomainError']['output']>;
};

export type XingIdUpdateStatusError = {
  __typename?: 'XingIdUpdateStatusError';
  errors?: Maybe<XingIdUpdateStatusErrors>;
  message: Scalars['String']['output'];
};

export type XingIdUpdateStatusErrors = {
  __typename?: 'XingIdUpdateStatusErrors';
  internal?: Maybe<Scalars['String']['output']>;
  /** In case something is wrong with the custom status we show a error message */
  text?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type XingIdUpdateStatusInput = {
  /** One element of the ProfilePersonalStatuses */
  identifier?: InputMaybe<ProfilePersonalStatuses>;
  /** The custom status that should be displayed */
  text?: InputMaybe<Scalars['String']['input']>;
};

export type XingIdUpdateStatusPayload = {
  __typename?: 'XingIdUpdateStatusPayload';
  error?: Maybe<XingIdUpdateStatusError>;
  success?: Maybe<XingIdUpdateStatusSuccess>;
};

export type XingIdUpdateStatusSuccess = {
  __typename?: 'XingIdUpdateStatusSuccess';
  /** #rest */
  xingIdModule?: Maybe<XingIdModule>;
};

export type XingIdUpdateStatusSuccessXingIdModuleArgs = {
  actionsFilter?: InputMaybe<Array<AvailableAction>>;
};

export type XingMemberSince = {
  __typename?: 'XingMemberSince';
  /** #soft-logged-in */
  text?: Maybe<Scalars['String']['output']>;
  /** #soft-logged-in */
  value?: Maybe<Scalars['String']['output']>;
};

/**
 * This field only allow users with an active XTM seat to use the candidateSearch.
 * A null value is returned in case the user is not authorized.
 */
export type XtmAuthorizedUser = {
  __typename?: 'XtmAuthorizedUser';
  /** #rest #virtual */
  candidateSearch?: Maybe<CandidateSearchCollection>;
};

/**
 * This field only allow users with an active XTM seat to use the candidateSearch.
 * A null value is returned in case the user is not authorized.
 */
export type XtmAuthorizedUserCandidateSearchArgs = {
  aggregations?: InputMaybe<CandidateSearchAggregationsInput>;
  consumer: Scalars['String']['input'];
  filters?: InputMaybe<CandidateSearchFiltersInput>;
  highlight?: InputMaybe<HighlightInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  queries?: InputMaybe<CandidateSearchQueriesInput>;
  timeout?: InputMaybe<Scalars['Int']['input']>;
  xtmProject?: InputMaybe<Scalars['Int']['input']>;
};

export type XtmConversations = {
  __typename?: 'XtmConversations';
  /** #rest #virtual */
  replied?: Maybe<XtmConversationsReplied>;
  /** #rest #virtual */
  responseRate?: Maybe<XtmConversationsResponseRate>;
  /** #rest #virtual */
  started?: Maybe<XtmConversationsStarted>;
};

export type XtmConversationsReplied = {
  __typename?: 'XtmConversationsReplied';
  histogram?: Maybe<Array<Maybe<XtmHistogramResult>>>;
};

export type XtmConversationsResponseRate = {
  __typename?: 'XtmConversationsResponseRate';
  total?: Maybe<Scalars['Int']['output']>;
};

export type XtmConversationsStarted = {
  __typename?: 'XtmConversationsStarted';
  histogram?: Maybe<Array<Maybe<XtmHistogramResult>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type XtmCounterCollection = {
  __typename?: 'XtmCounterCollection';
  count?: Maybe<Scalars['Int']['output']>;
};

export type XtmForbiddenError = {
  __typename?: 'XtmForbiddenError';
  message?: Maybe<Scalars['String']['output']>;
};

export type XtmGlances = {
  __typename?: 'XtmGlances';
  /** #rest(batched) #virtual */
  xtmMessages?: Maybe<XtmCounterCollection>;
  /** #rest(batched) #virtual */
  xtmNotes?: Maybe<XtmCounterCollection>;
  /** #rest(batched) #virtual */
  xtmPools?: Maybe<XtmCounterCollection>;
  /** #rest(batched) #virtual */
  xtmProjects?: Maybe<XtmCounterCollection>;
  /** #rest(batched) #virtual */
  xtmVisits?: Maybe<XtmCounterCollection>;
};

export type XtmHistogramResult = {
  __typename?: 'XtmHistogramResult';
  timestamp?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Types for XTM Message Templates */
export type XtmMessageTemplate = {
  __typename?: 'XtmMessageTemplate';
  body: Scalars['String']['output'];
  clientId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  creatorId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lastUsedAt?: Maybe<Scalars['Date']['output']>;
  messagesCount: Scalars['Int']['output'];
  responseRate?: Maybe<Scalars['Float']['output']>;
  shared: Scalars['Boolean']['output'];
  subject: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type XtmMessageTemplateConnection = HasCursorPagination & {
  __typename?: 'XtmMessageTemplateConnection';
  edges: Array<Maybe<XtmMessageTemplateListEdge>>;
  /** #virtual */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type XtmMessageTemplateListEdge = PaginationEdge & {
  __typename?: 'XtmMessageTemplateListEdge';
  /** #virtual */
  cursor: Scalars['String']['output'];
  node?: Maybe<XtmMessageTemplateListItem>;
};

export type XtmMessageTemplateListItem = {
  __typename?: 'XtmMessageTemplateListItem';
  clientId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  creatorId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  shared: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type XtmMessageTemplateParserOutputElement = {
  __typename?: 'XtmMessageTemplateParserOutputElement';
  type?: Maybe<XtmTemplateParserOutputElementType>;
  value?: Maybe<Scalars['String']['output']>;
};

export type XtmMessageTemplateResponse = {
  __typename?: 'XtmMessageTemplateResponse';
  messageTemplate: XtmMessageTemplate;
};

export type XtmPool = {
  __typename?: 'XtmPool';
  /** #rest #virtual */
  statistics?: Maybe<XtmProjectStatistics>;
};

export type XtmProject = {
  __typename?: 'XtmProject';
  conversations?: Maybe<XtmConversations>;
  /** #rest #virtual */
  statistics?: Maybe<XtmProjectStatistics>;
  weightFactorsOrder?: Maybe<Array<Maybe<XtmProjectWeightFactor>>>;
};

export type XtmProjectConversationsArgs = {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type XtmProjectStatisticsArgs = {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type XtmProjectCampaign = {
  __typename?: 'XtmProjectCampaign';
  createdAt?: Maybe<Scalars['Date']['output']>;
  creatorId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  projectId?: Maybe<Scalars['ID']['output']>;
  sendReminders?: Maybe<Scalars['Boolean']['output']>;
  shareMessages?: Maybe<Scalars['Boolean']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export const enum XtmProjectCampaignLimitationError {
  TooManyCampaigns = 'TOO_MANY_CAMPAIGNS',
  TooManyMessagesToNonContacts = 'TOO_MANY_MESSAGES_TO_NON_CONTACTS',
  TooManyRecipients = 'TOO_MANY_RECIPIENTS',
}

export type XtmProjectCampaignLimitationsInput = {
  projectId: Scalars['ID']['input'];
  recipientIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type XtmProjectCampaignLimitationsResult = {
  __typename?: 'XtmProjectCampaignLimitationsResult';
  availableMtncQuota?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<Maybe<XtmProjectCampaignLimitationError>>>;
};

export type XtmProjectCreateUpdateCampaignInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  messageTemplateId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  sendReminders?: InputMaybe<Scalars['Boolean']['input']>;
  shareMessages?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
};

export type XtmProjectCreateUpdateCampaignResult =
  | XtmProjectCreateUpdateError
  | XtmProjectCreateUpdateSuccess;

export type XtmProjectCreateUpdateError = {
  __typename?: 'XtmProjectCreateUpdateError';
  message?: Maybe<Scalars['String']['output']>;
};

export type XtmProjectCreateUpdateSuccess = {
  __typename?: 'XtmProjectCreateUpdateSuccess';
  body?: Maybe<Array<Maybe<XtmMessageTemplateParserOutputElement>>>;
  campaign?: Maybe<XtmProjectCampaign>;
  subject?: Maybe<Array<Maybe<XtmMessageTemplateParserOutputElement>>>;
};

export type XtmProjectSendCampaignInput = {
  id: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  recipientIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type XtmProjectSendCampaignResult = {
  __typename?: 'XtmProjectSendCampaignResult';
  sent?: Maybe<Scalars['Boolean']['output']>;
};

export type XtmProjectStatistics = {
  __typename?: 'XtmProjectStatistics';
  candidates?: Maybe<XtmProjectStatisticsCandidates>;
  candidatesPerStatus?: Maybe<Array<Maybe<XtmStatusResult>>>;
  reviews?: Maybe<XtmProjectStatisticsReviews>;
};

export type XtmProjectStatisticsCandidates = {
  __typename?: 'XtmProjectStatisticsCandidates';
  histogram?: Maybe<Array<Maybe<XtmHistogramResult>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type XtmProjectStatisticsReviews = {
  __typename?: 'XtmProjectStatisticsReviews';
  candidateCount?: Maybe<Scalars['Int']['output']>;
  histogram?: Maybe<Array<Maybe<XtmHistogramResult>>>;
  pendingCount?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export const enum XtmProjectWeightFactor {
  CareerLevel = 'CAREER_LEVEL',
  JobTitle = 'JOB_TITLE',
  Languages = 'LANGUAGES',
  Location = 'LOCATION',
  Skills = 'SKILLS',
}

export type XtmSearchResult = {
  __typename?: 'XtmSearchResult';
  collection: Array<XtmSearchResultItem>;
  total: Scalars['Int']['output'];
};

export type XtmSearchResultItem = {
  __typename?: 'XtmSearchResultItem';
  /** Unique identifier of the entity */
  id?: Maybe<Scalars['ID']['output']>;
  /** 0-based position in suggestion list */
  position?: Maybe<Scalars['Int']['output']>;
  /** Tracking token */
  trackingToken?: Maybe<Scalars['String']['output']>;
  /**
   * #rest(batched) #virtual
   *
   *  The profile information for the user
   */
  xingId?: Maybe<XingId>;
};

export type XtmSeatsStats = {
  __typename?: 'XtmSeatsStats';
  assignedSeatsCount?: Maybe<Scalars['Int']['output']>;
  availableSeatsCount?: Maybe<Scalars['Int']['output']>;
  totalSeats?: Maybe<Scalars['Int']['output']>;
  unavailableSeatsCount?: Maybe<Scalars['Int']['output']>;
};

export type XtmSeatsStatsResponse = {
  __typename?: 'XtmSeatsStatsResponse';
  assignedSeatsCount?: Maybe<Scalars['Int']['output']>;
  availableSeatsCount?: Maybe<Scalars['Int']['output']>;
  totalSeats?: Maybe<Scalars['Int']['output']>;
  unavailableSeatsCount?: Maybe<Scalars['Int']['output']>;
  xtm?: Maybe<XtmSeatsStats>;
  xtp?: Maybe<XtmSeatsStats>;
};

export type XtmStatusResult = {
  __typename?: 'XtmStatusResult';
  /** #rest(batched) #virtual */
  candidates?: Maybe<Array<Maybe<XingId>>>;
  count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export const enum XtmTemplateParserOutputElementType {
  Invalid = 'INVALID',
  Text = 'TEXT',
  Valid = 'VALID',
}

export type XtmUpdateProjectInput = {
  id: Scalars['ID']['input'];
  weightFactorsOrder?: InputMaybe<Array<InputMaybe<XtmProjectWeightFactor>>>;
};

export type XtmUpdateProjectResult =
  | XtmForbiddenError
  | XtmUpdateProjectSuccess;

export type XtmUpdateProjectSuccess = {
  __typename?: 'XtmUpdateProjectSuccess';
  updated?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * App sessions (a.k.a. permissions)
 * They are scoper per app (not per device)
 */
export type XwsSessions = {
  __typename?: 'XwsSessions';
  consumerId?: Maybe<Scalars['ID']['output']>;
  consumerName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
};

export type CreateCvUploadParsedCvByUserIdInput = {
  /** A string identifying the conceptual user flow from which a parsed_cv originates. While one job-posting could be the source (See above), there might be different used-flows that lead to the creation of a parsed_cv. */
  flowIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this parsed_cv originates from */
  source?: InputMaybe<Scalars['GlobalID']['input']>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateCvUploadParsedCvInput = {
  /** A string identifying the conceptual user flow from which a parsed_cv originates. While one job-posting could be the source (See above), there might be different used-flows that lead to the creation of a parsed_cv. */
  flowIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** A valid xing URN identifying the thing (think of processes as identifiable things) that this parsed_cv originates from */
  source?: InputMaybe<Scalars['GlobalID']['input']>;
  /** ID the upload app returned for the given upload */
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteCvUploadParsedCvInput = {
  id: Scalars['ID']['input'];
};

export type MymkBucketedRecommenderConnection = HasCursorPagination &
  RecommenderResponse & {
    __typename?: 'mymkBucketedRecommenderConnection';
    edges: Array<MymkCombinedRecommendationEdge>;
    /** #virtual */
    pageInfo: PageInfo;
    requestTrackingToken: Scalars['String']['output'];
    service: Scalars['String']['output'];
    total: Scalars['Int']['output'];
  };

export const enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export const enum VompTypeEnum {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
}
