/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RecommendedCompaniesQueryVariables = Types.Exact<{
  id: Types.Scalars['SlugOrID']['input'];
}>;

export type RecommendedCompaniesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id: string;
    relatedCompanies?: {
      __typename?: 'CompanyRelatedCompaniesCollection';
      collection?: Array<{
        __typename?: 'RelatedCompany';
        company?: {
          __typename?: 'Company';
          id: string;
          companyName?: string | null;
          entityPage?: {
            __typename?: 'EntityPage';
            coverImage?: Array<{
              __typename?: 'ScaledImage';
              url?: string | null;
            }> | null;
          } | null;
          links?: {
            __typename?: 'CompanyLinks';
            public?: string | null;
          } | null;
          logos?: {
            __typename?: 'CompanyLogos';
            logo128px?: string | null;
          } | null;
          kununuData?: {
            __typename?: 'CompanyKununuData';
            ratingCount?: number | null;
            ratingAverage?: number | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export const RecommendedCompaniesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RecommendedCompanies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'relatedCompanies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'companyName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'entityPage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'coverImage',
                                          },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: {
                                                kind: 'Name',
                                                value: 'dimensions',
                                              },
                                              value: {
                                                kind: 'ListValue',
                                                values: [
                                                  {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'height',
                                                        },
                                                        value: {
                                                          kind: 'IntValue',
                                                          value: '500',
                                                        },
                                                      },
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'width',
                                                        },
                                                        value: {
                                                          kind: 'IntValue',
                                                          value: '300',
                                                        },
                                                      },
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'reference',
                                                        },
                                                        value: {
                                                          kind: 'StringValue',
                                                          value: 'medium',
                                                          block: false,
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'links' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'public',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logos' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'logo128px',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kununuData' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ratingCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ratingAverage',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecommendedCompaniesQuery,
  RecommendedCompaniesQueryVariables
>;
