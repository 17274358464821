import styled, { css } from 'styled-components';

import { FileUploadDrop } from '@xing-com/crate-x4e-file-upload';
import { mediaSmallOnly } from '@xing-com/layout-tokens';
import {
  cornerRadiusL,
  scale220,
  spaceL,
  spaceM,
  spaceS,
  spaceXS,
  xdlColorBorderSoft,
  xdlColorText,
  xdlColorTextSecondary,
} from '@xing-com/tokens';

export const FileUpload = styled(FileUploadDrop)<{ $onDrag: boolean }>`
  align-items: center;
  border: dashed 2px ${xdlColorBorderSoft};
  border-radius: ${cornerRadiusL};
  display: flex;
  justify-content: center;
  min-height: 309px;
  width: 100%;

  ${({ $onDrag }) =>
    $onDrag &&
    css`
      background-color: inherit;
      border: 2px solid #27dba8;
    `}

  & *:focus {
    outline: 0;
  }

  & label {
    width: 100%;
  }

  & .dragClassName {
    background-color: inherit;
    width: 100%;
  }
`;

export const UploadArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 36px;
  text-align: center;

  @media ${mediaSmallOnly} {
    padding: 24px 36px;
  }
`;

export const Illustration = styled.img`
  height: ${scale220};
  width: ${scale220};
`;

export const ReadyTitle = styled.div`
  color: ${xdlColorText};
  margin-top: ${spaceS};
`;

export const ReadyText = styled.div`
  color: ${xdlColorText};
  margin-top: ${spaceXS};
`;

export const ReadyCta = styled.div`
  margin-top: ${spaceL};
`;

export const InProgressTitle = styled.div`
  color: ${xdlColorTextSecondary};
  margin-top: ${spaceS};
`;

export const InProgressText = styled.div`
  color: ${xdlColorText};
  margin-top: ${spaceM};
`;

export const InProgressBar = styled.div`
  background-color: ${xdlColorBorderSoft};
  border-radius: 6px;
  height: 4px;
  margin-top: ${spaceM};
  max-width: 90%;
  width: 444px;
`;

export const InProgressBarInner = styled.div`
  background-color: ${xdlColorTextSecondary};
  border-radius: 6px;
  height: 100%;
  transition: width 0.5s;
`;

export const EncodingTitle = styled.div`
  color: ${xdlColorTextSecondary};
  margin-top: ${spaceS};
  max-width: 400px;
  text-align: center;
`;

export const VideoContainer = styled.div`
  min-height: 309px;

  @media ${mediaSmallOnly} {
    min-height: 0;
  }
`;
