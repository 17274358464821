/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateAffiliatesMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  affiliates?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.CompanyCreateAffiliatesInputItem>>
    | Types.InputMaybe<Types.CompanyCreateAffiliatesInputItem>
  >;
}>;

export type UpdateAffiliatesMutation = {
  __typename?: 'Mutation';
  companyCreateAffiliates?: {
    __typename?: 'CompanyAffiliatesMutationResult';
    error?: {
      __typename?: 'CompanyAffiliatesMutationErrorCollection';
      collection?: Array<{
        __typename?: 'CompanyAffiliatesMutationError';
        index?: number | null;
        companyId?: string | null;
        message?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export const UpdateAffiliatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAffiliates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'affiliates' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyCreateAffiliatesInputItem' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyCreateAffiliates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'affiliates' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'affiliates' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'index' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAffiliatesMutation,
  UpdateAffiliatesMutationVariables
>;
