import styled from 'styled-components';

import { FollowContainer } from '@xing-com/crate-companies-entity-pages-header';
import { Flag as InnerFlag } from '@xing-com/flag';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { ProfileImage as OriginalProfileImage } from '@xing-com/profile-image';
import {
  spaceL,
  spaceS,
  scale160,
  scale110,
  scale100,
  scale080,
  scale070,
  scale060,
  xdlColorTextSecondary,
  scale180,
  spaceXS,
  xdlColorText,
} from '@xing-com/tokens';

const colorCopy = 'rgb(51, 51, 51)';

export const Actor = styled.section`
  display: flex;
  justify-content: space-between;
`;

export const ActorAnchor = styled.a`
  margin-right: ${spaceS};
  margin-bottom: ${spaceS};
  flex-shrink: 0;
  line-height: 0;
`;

export const ProfileImage = styled(OriginalProfileImage)`
  width: ${scale160};
`;

export const ActorContent = styled.section`
  margin-right: ${spaceL};
  min-width: 0;
  @media ${mediaSmallOrTiny} {
    margin-right: 0;
  }
`;

export const ActorNameWrap = styled.span`
  display: flex;
  font-size: ${scale080};
  line-height: ${scale110};
  min-width: 0;
  margin-top: 2px;
`;

export const ActorName = styled.a`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  font-weight: bold;
  color: ${xdlColorText};

  @media ${mediaSmallOrTiny} {
    font-size: ${scale070};
    line-height: ${scale100};
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    white-space: normal;
  }
  @media (prefers-color-scheme: light) {
    color: ${colorCopy};
  }
`;

export const Flag = styled(InnerFlag)`
  margin-left: ${spaceS};
`;

export const ActorSubtitle = styled.span`
  font-size: ${scale060};
  line-height: ${scale080};
  color: ${xdlColorTextSecondary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  min-width: 0;
  margin-top: 0;
  @media ${mediaSmallOrTiny} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const FollowButton = styled(FollowContainer)`
  width: 110px;
  margin-left: auto;
  color: ${colorCopy};
  flex-shrink: 0;
  padding: 0;
  margin-top: ${spaceS};
  @media ${mediaSmallOrTiny} {
    width: ${scale180};

    & svg {
      margin-right: 0;
    }

    & .followText {
      display: none;
    }
  }
`;

export const NoActor = styled(Actor)`
  color: ${xdlColorTextSecondary};
  font-size: ${scale060};
  line-height: ${scale080};
  margin-bottom: ${spaceXS};
`;
