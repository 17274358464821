import styled from 'styled-components';

import { Button as InnerButton } from '@xing-com/button';
import { ProfileInfoAdapter as ProfileInfo } from '@xing-com/crate-companies-entity-pages-common';
import type { ProfileInfoAdapterProps } from '@xing-com/crate-companies-entity-pages-common/src/components/profile-info-adapter/profile-info-adapter';
import { mediaSmallOnly, mediaConfinedOnly } from '@xing-com/layout-tokens';
import { spaceL, spaceM, spaceS, spaceXS } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

const cardWidth = '402px';

export const ClampedText = styled.span`
  max-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${mediaSmallOnly} {
    max-width: 180px;
  }

  @media ${mediaConfinedOnly} {
    max-width: 100%;
  }
`;

export const EmployeeDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const EmployeeData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${spaceS};
`;

export const TotalEmployees = styled(BodyCopy)`
  font-weight: bold;
  text-align: right;
  margin-bottom: 20px;
`;

export const Card = styled.div`
  width: ${cardWidth};
  margin-bottom: ${spaceL};

  @media ${mediaSmallOnly} {
    width: 100%;
  }

  @media ${mediaConfinedOnly} {
    width: 100%;
  }
`;

export const ProfileImage = styled.img`
  width: 142px;
  height: 142px;
`;

export const Name = styled.div`
  margin-top: 0;
  margin-bottom: ${spaceXS};
`;

export const CTA = styled.div`
  font-weight: 700;
  font-size: var(--fontSize400);
`;

export const BlurBackground = styled.div`
  justify-content: flex-end;
  background-position: center;
  background-size: cover;
  border: none;
  padding: ${spaceL};
`;

export const SkeletonText = styled.div`
  margin-bottom: var(--space10);
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  width: ${cardWidth};
  margin-bottom: ${spaceL};
`;

export const SkeletonProfileImage = styled.div`
  height: 96px;
  width: 96px;
  flex-shrink: 0;
  margin-right: ${spaceM};
`;

export const SkeletonProfileInfo = styled.div`
  width: 100%;
`;

export const HeadlineClamped = styled.div`
  display: none;
`;

export const EmployeesCard: React.FC<ProfileInfoAdapterProps> = styled(
  ProfileInfo
)`
  > a {
    padding: 2px;
  }
`;

export const Button = styled(InnerButton)`
  padding: 0;
  & span:first-of-type {
    margin-right: 0;
  }
`;

export const HeadlineFlagWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  & > ${ClampedText} {
    margin-right: ${spaceS};
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
