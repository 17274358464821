import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { scale100, scale280, spaceM, spaceS } from '@xing-com/tokens';

// *** Base ***
const baseImageContainer = css`
  display: inline-block;
  margin-top: ${spaceM};
  position: relative;
`;

const baseDropZoneImage = css`
  height: ${scale280};
  margin-right: ${spaceM};
  object-fit: cover;
  width: ${scale280};
`;

const baseStyledButton = css`
  position: absolute;
  top: ${spaceS};
  right: ${scale100};
  }
`;

// *** Components ***
const ImageContainer = styled.section`
  ${baseImageContainer}
`;

const DropZoneImage = styled.img`
  ${baseDropZoneImage}
`;

const StyledButton = styled(Button)`
  ${baseStyledButton}
`;

export const Styled = {
  ImageContainer,
  DropZoneImage,
  Button: StyledButton,
};
