/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from 'react';
import { useSwipeable } from 'react-swipeable';

import * as Styled from './side-sheet.styles';
import type { SideSheetProps } from './side-sheet.types';

export const SideSheetPrimitive = React.forwardRef<
  HTMLDivElement,
  SideSheetProps
>(
  (
    { children, className, onSwipeLeft, ...props },
    forwardedRef
  ): JSX.Element => {
    const config = { delta: 40 };
    const { ref: swipeableRef, ...swipeableHandlers } = useSwipeable({
      onSwipedLeft: () => onSwipeLeft && onSwipeLeft(),
      ...config,
    });

    const combinedRef = React.useCallback(
      (element: HTMLDivElement | null) => {
        if (typeof forwardedRef === 'function') {
          forwardedRef(element);
        } else if (forwardedRef) {
          (
            forwardedRef as React.MutableRefObject<HTMLDivElement | null>
          ).current = element;
        }
        swipeableRef(element);
      },
      [forwardedRef, swipeableRef]
    );

    return (
      <Styled.SideSheet
        className={className}
        data-qa="side-sheet"
        data-testid="side-sheet"
        data-xds="SideSheet"
        ref={combinedRef}
        {...swipeableHandlers}
        {...props}
      >
        {children}
      </Styled.SideSheet>
    );
  }
);

SideSheetPrimitive.displayName = 'SideSheet (Primitive)';
