// EXTERNAL VALUES
export const APPLICATION_TYPES = {
  DISCO: 'DISCO',
  ENTITY_PAGES: 'ENTITY_PAGES',
  MEHUB: 'MEHUB',
  MEHUB_EMPTY: 'MEHUB_EMPTY',
  SUPI: 'SUPI',
};

export const ATTACHMENT_TYPES = {
  BLOCKED: 'BLOCKED',
  EMPTY: 'EMPTY',
  IMAGE: 'IMAGE',
  GIF: 'GIF',
  LINK_PREVIEW: 'LINK_PREVIEW',
  VIDEO: 'VIDEO',
  POLLS: 'POLLS',
};

export const BANNER_TIMEOUT = 5000;
export const COMMBOX_SUCESS_DIALOG_VISIBILITY_TIMEOUT = 7000;

export const COMMBOX_ACTOR_TYPES = {
  PAGE: 'PAGE',
  USER: 'USER',
};

export const COMMBOX_FEATURE_SWITCHES = {
  MENTIONS_TOGGLE: 'enable_social_commbox_mention',
  MULTI_IMAGE_UPLOAD_TOGGLE: 'enable_commbox_multi_image_post',
  POLLS_TOGGLE: 'enable_polls',
};

export const FAKE_VIDEO_URN = 'urn:x-xing:postings:temporary_video';

/// /////////////////////
// INTERNAL CONSTANTS //
/// /////////////////////

// https://jira.xing.hh/browse/NPUB-935

export const ACCEPTED_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif']; //, 'image/webp'];
// defined by upload service.
// We stick to the smaller number
// https://source.xing.com/architects/xingbox/blob/master/vm/docker-compose.yml.tmpl#L7321
export const MAX_FILE_SIZE_MB = 10;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1000000;

export const NO_CHARACTER_LIMIT = -1;
export const MULTI_IMAGE_POST_MAX_IMAGES = 10;

export const NOT_SCHEDULED_POST = 0;
export const SCHEDULED_POST = 1;
export const DEFAULT_SLATE_MESSAGE = [
  { type: 'paragraph', children: [{ text: '' }] },
];
