import {
  PROP_PAGINATION,
  PROP_CONTEXT_DIMENSION3,
  PROP_TRACK_ACTION,
  PROP_SCROLLING,
} from '../constants';
import { trackAction } from '../tracker';

export const trackClickKununuProfile = ({
  isEditor,
}: {
  isEditor: boolean;
}) => {
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_view_kununu_profile_button',
    [PROP_CONTEXT_DIMENSION3]: isEditor ? 'companies_editor' : 'companies_user',
  });
};

export const trackClickKununuCulture = ({
  isEditor,
}: {
  isEditor: boolean;
}) => {
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_view_kununu_culture_button',
    [PROP_CONTEXT_DIMENSION3]: isEditor ? 'companies_editor' : 'companies_user',
  });
};

export const trackClickKununuReview = ({
  page,
  isEditor,
}: {
  isEditor: boolean;
  page: number;
}) => {
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_kununu_carousell_review_card',
    [PROP_PAGINATION]: page,
    [PROP_CONTEXT_DIMENSION3]: isEditor ? 'companies_editor' : 'companies_user',
  });
};

export const trackPageChange = ({
  direction,
  page,
}: {
  direction: string;
  page: number;
}) => {
  trackAction({
    [PROP_SCROLLING]: `entity_page_kununu_carousell_${
      direction === 'right' ? 'next' : 'back'
    }`,
    [PROP_PAGINATION]: page,
  });
};

export const trackAllReviewsClick = ({ isEditor }: { isEditor: boolean }) => {
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_kununu_read_all_reviews_link',
    [PROP_CONTEXT_DIMENSION3]: isEditor ? 'companies_editor' : 'companies_user',
  });
};

export const trackClickEditorProfile = () => {
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_kununu_go_to_profile',
  });
};

export const trackKununuRateCompanyClick = () => {
  trackAction(
    {
      EventCompaniesEmployerRateClick: 1,
    },
    'EventCompaniesEmployerRateClick'
  );
};

export const trackAllBenefitsClick = () => {
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_kununu_read_all_benefits_link',
  });
};
