import type { FC } from 'react';

import type { ContactsContainerProps } from '../../types';
import ContactsList from '../contacts-list';
import SearchInput from '../search-input';

const ContactsContainer: FC<ContactsContainerProps> = ({
  contactUsers,
  setContactUsersList,
  createContact,
  setContactsTouched,
  disablePageActions,
  setDisablePageActions,
}) => (
  <>
    <SearchInput
      createContact={createContact}
      disablePageActions={disablePageActions}
    />
    <ContactsList
      setDisablePageActions={setDisablePageActions}
      userList={contactUsers}
      setContactUsersList={setContactUsersList}
      setContactsTouched={setContactsTouched}
    />
  </>
);

export default ContactsContainer;
