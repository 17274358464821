import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Headline } from '@xing-com/typography';

import { SmallGrid } from '../../small-grid/small-grid';
import * as Styled from './pages-form-skeleton.styles';

export type PagesFormSkeletonProps = {
  headlineKey?: string;
};

export const PagesFormSkeleton: FC<PagesFormSkeletonProps> = ({
  headlineKey,
}) => (
  <>
    <div>
      {headlineKey && (
        <Headline size={'xxlarge'}>
          <FormattedMessage id={headlineKey} />
        </Headline>
      )}
      <SmallGrid>
        {/* @ts-expect-error FIXME: SC6 */}
        <Styled.SkeletonField size="small" />
        {/* @ts-expect-error FIXME: SC6 */}
        <Styled.SkeletonField size="small" />
        {/* @ts-expect-error FIXME: SC6 */}
        <Styled.SkeletonField size="small" />
        {/* @ts-expect-error FIXME: SC6 */}
        <Styled.SkeletonField size="small" />
      </SmallGrid>
    </div>
    <Styled.Actions>
      <Styled.PublishButtonSkeleton size="medium" />
    </Styled.Actions>
  </>
);

export default PagesFormSkeleton;
