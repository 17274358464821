import { createContext } from 'react';

import type { EntityPageHeaderContent } from '@xing-com/crate-common-graphql-types';

type HeaderContextValues = {
  title: string;
  subtitle: string;
  isSubpage?: boolean;
  hasTertiaryArea: boolean;
  ctaType: any;
  pageType?: string;
  headerContent?: Partial<EntityPageHeaderContent>;
};
export const HeaderContext = createContext<HeaderContextValues | undefined>(
  undefined
);
