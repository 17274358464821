import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import * as Styled from './commbox-om-trigger.styles';

type CommboxOmTriggerProps = {
  onClick: () => void;
};
export const CommboxOmTrigger: FC<CommboxOmTriggerProps> = ({ onClick }) => (
  <Styled.CommboxInput onClick={onClick} data-testid="commboxTrigger">
    <Styled.TextInput>
      <FormattedMessage id="COMMBOX_EMPTY_TEXT_FIELD" />
    </Styled.TextInput>
  </Styled.CommboxInput>
);
