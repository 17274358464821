import * as React from 'react';

export type UseDragEventType = () => { isDragging: boolean };

export const useDragEvent: UseDragEventType = () => {
  const [isDragging, setIsDragging] = React.useState(false);

  const handleOnDragEnter = () => setIsDragging(true);
  const handleOnDragLeave = (e: any) => {
    // We check the coordinates to make sure that
    // the cursor is still inside the window
    if (e.screenX === 0 && e.screenY === 0) setIsDragging(false);
  };
  const handleOnDrop = () => setIsDragging(false);

  React.useEffect(() => {
    window.addEventListener('dragenter', handleOnDragEnter);
    window.addEventListener('dragleave', handleOnDragLeave);
    window.addEventListener('drop', handleOnDrop);
    return () => {
      window.removeEventListener('dragenter', handleOnDragEnter);
      window.removeEventListener('dragleave', handleOnDragLeave);
      window.removeEventListener('drop', handleOnDrop);
    };
  }, []);

  return { isDragging };
};
