import styled from 'styled-components';

import { Button as DefaultButton } from '@xing-com/button';
import * as layoutTokens from '@xing-com/layout-tokens';
import * as tokens from '@xing-com/tokens';

export const ButtonWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 18%;
  z-index: 1000;
  transform: rotate(270deg);
  transform-origin: 100% 100%;
  display: none;

  @media ${layoutTokens.mediaWide} {
    display: block;
  }
`;

export const Button = styled(DefaultButton)`
  padding: 0 ${tokens.spaceM};
  margin-bottom: ${tokens.spaceS};
`;
