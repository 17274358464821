import type { FC } from 'react';

import {
  usePageContext,
  useDialogContext,
  useErrorContext,
} from '@xing-com/crate-companies-entity-pages-common';

import * as Styled from './video-upload.styles';

type VideoUploadProps = {
  mediaId?: string;
  videoId?: string;
  title?: string | null;
  onDelete?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
};
export const VideoUpload: FC<VideoUploadProps> = ({
  onDelete = () => undefined,
  onSave = () => undefined,
  onCancel = () => undefined,
  mediaId,
  videoId,
  title,
}) => {
  const { showError } = useErrorContext();
  const { pageContext } = usePageContext() ?? {};
  const { setDataChanged, setDialogConfirmation, dataChanged } =
    useDialogContext();

  const { pageId, globalId } = pageContext ?? {};

  if (!pageId || !globalId) {
    return null;
  }

  const handleOnError = (message: string) => {
    showError({ message });
  };

  const handleOnCancel = () => {
    if (dataChanged) {
      setDialogConfirmation({
        dialogAction: () => {
          onCancel();
          setDialogConfirmation(null);
          setDataChanged(false);
        },
      });
    } else {
      onCancel();
    }
  };

  const handleOnSave = () => {
    if (dataChanged) {
      setDataChanged(false);
    }
    onSave();
  };

  const handleOnDelete = () => {
    onDelete();
  };

  return (
    <>
      <Styled.VideoUploadWrapper>
        <Styled.VideUpload
          mediaId={mediaId}
          videoId={videoId}
          title={title ?? undefined}
          onSave={handleOnSave}
          onDelete={handleOnDelete}
          onCancel={handleOnCancel}
          onError={handleOnError}
          onValueChange={() => setDataChanged(true)}
        />
      </Styled.VideoUploadWrapper>
    </>
  );
};
