import type { VFC } from 'react';
import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { IconArrowLeft } from '@xing-com/icons';
import { mediaConfined } from '@xing-com/layout-tokens';
import { xdlColorBackground, scale190 } from '@xing-com/tokens';

export type BackLinkProps = {
  label?: string;
  onClose?: () => void;
  to?: string;
};

export const Wrapper = styled.div`
  align-items: center;
  background: ${xdlColorBackground};
  display: flex;
  height: inherit;
  height: 52px;
  position: sticky;
  top: 0;
  z-index: 15;

  @media ${mediaConfined} {
    height: ${scale190};
    top: 52px;
  }
`;

const BackLink: VFC<BackLinkProps> = ({
  label,
  onClose,
  to,
}: BackLinkProps) => (
  <Wrapper>
    <TextButton size="small" to={to} onClick={onClose} icon={IconArrowLeft}>
      {label}
    </TextButton>
  </Wrapper>
);

export { BackLink };
