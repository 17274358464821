import {
  useDialogContext,
  EditActions,
} from '@xing-com/crate-companies-entity-pages-common';

import type { ContactUserListType, ContactsContainerProps } from '../../types';
import ContactsSearchList from '../contacts-search-list';
import * as Styled from './contacts-edit.styles';
type ContactsEditProps = {
  isSaving: boolean;
  contactUsers?: ContactUserListType;
  pageSlug?: string;
  contactProps: ContactsContainerProps;
  disablePageActions: boolean;
  dataChanged: boolean | null;
  goBackUrl?: () => void;
};
const ContactsEdit = ({
  contactProps,
  isSaving,
  disablePageActions,
  dataChanged,
  goBackUrl,
}: ContactsEditProps) => {
  const { executeWithDialog } = useDialogContext();

  return (
    <>
      <Styled.EditAreaContainer>
        <ContactsSearchList
          {...contactProps}
          disablePageActions={disablePageActions}
        />
      </Styled.EditAreaContainer>
      <EditActions
        disabled={disablePageActions}
        discardAction={() => executeWithDialog(() => goBackUrl && goBackUrl())}
        noMarginTop
        saveAction={() => {
          if (dataChanged) {
            contactProps.contactsTouched && contactProps.updateContacts();
          } else {
            goBackUrl && goBackUrl();
          }
        }}
        isSaving={isSaving}
      />
    </>
  );
};

export default ContactsEdit;
