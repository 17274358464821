import styled, { css } from 'styled-components';

import { zIndexContentLayer2 } from '@xing-com/layout-tokens';
import {
  motionEasingStandard,
  motionTimeM,
  motionTimeS,
  motionTimeXXS,
  scale080,
  scale110,
  scale150,
  spaceXXS,
  xdlColorBackground,
  xdlColorBorder,
  xdlColorDisabledSoft,
  xdlColorSelected,
  xdlColorError,
  scale010,
} from '@xing-com/tokens';

type ToggleTransientProps = {
  $error?: boolean;
};

const checkedErrorStyle = css`
  background-color: ${xdlColorError};
`;

const unCheckedErrorStyle = css`
  border-color: ${xdlColorError};
  border-style: solid;
  border-width: ${scale010};
`;

// *** Components ***
export const Toggle = styled.input<ToggleTransientProps>`
  appearance: none;
  background-color: ${xdlColorBackground};
  border-color: ${xdlColorBorder};
  border-radius: calc(${scale110} / 2); /* toggle height / 2  */
  border-style: solid;
  border-width: ${scale010};
  cursor: pointer;
  display: inline-flex;
  height: ${scale110};
  margin: 0;
  position: relative;
  transition-duration: ${motionTimeM};
  transition-property: all;
  transition-timing-function: ${motionEasingStandard};
  width: ${scale150};

  ${(props) => css`
    ${props.$error && unCheckedErrorStyle}
  `};

  &::before {
    background-color: ${xdlColorBorder};
    border-radius: 50%;
    box-sizing: border-box;
    content: '';
    height: ${scale080};
    left: ${spaceXXS};
    margin-top: calc(${scale080} / 2 * -1);
    position: absolute;
    top: 50%;
    transition-delay: ${motionTimeXXS};
    transition-duration: ${motionTimeS};
    transition-property: all;
    transition-timing-function: ${motionEasingStandard};
    width: ${scale080};
    z-index: ${zIndexContentLayer2};

    ${(props) => css`
      ${props.$error && checkedErrorStyle}
    `};
  }

  &:checked {
    background-color: ${xdlColorSelected};
    border-color: ${xdlColorSelected};
    ${(props) => css`
      ${props.$error && checkedErrorStyle}
      ${props.$error && unCheckedErrorStyle}
    `};
  }

  &:checked::before {
    background-color: ${xdlColorBackground};
    transform: translateX(calc(${scale110} * 0.75));
  }

  &[disabled] {
    background-color: ${xdlColorBackground};
    border-color: ${xdlColorDisabledSoft};
    cursor: default;
  }

  &[disabled]::before {
    background-color: ${xdlColorDisabledSoft};
  }

  &[disabled]:checked {
    background-color: ${xdlColorDisabledSoft};
  }

  &[disabled]:checked::before {
    background-color: ${xdlColorBackground};
  }
`;
