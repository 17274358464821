/* eslint-disable complexity */
const validation = (type: string, value: string) => {
  const EMPTY_OR_MIN_CHARACTERS_ERROR =
    'EP_FIELD_EMPTY_OR_MIN_CHARACTERS_ERROR';

  switch (type) {
    case 'title': {
      // Characters min: 0 or at least 4
      if (value.length > 0 && value.length < 4) {
        return {
          key: EMPTY_OR_MIN_CHARACTERS_ERROR,
          value: 4,
        };
      }
      return null;
    }
    case 'description': {
      // Characters min: 0 or at least 4
      if (value.length > 0 && value.length < 4) {
        return {
          key: EMPTY_OR_MIN_CHARACTERS_ERROR,
          value: 4,
        };
      }
      return null;
    }
    default: {
      return null;
    }
  }
};

export default validation;
