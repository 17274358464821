import styled, { css } from 'styled-components';

import { EditButton } from '@xing-com/crate-companies-entity-pages-common';
import { mediaSmallOrTiny, mediaConfined } from '@xing-com/layout-tokens';
import { spaceL } from '@xing-com/tokens';

export const GalleryEditButton = styled(EditButton)<{ inFullWidth?: boolean }>`
  display: none;
  position: absolute;
  top: ${spaceL};
  right: ${spaceL};
  z-index: 1;

  @media ${mediaConfined} {
    display: flex;
  }

  ${({ inFullWidth }) =>
    inFullWidth &&
    css`
      @media ${mediaSmallOrTiny} {
        right: 0;
      }
    `}
`;
