// TODO: This file needs a refactoring, it should use the variant object syntax
import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import {
  cornerRadiusM,
  scale005,
  scale050,
  scale060,
  scale070,
  scale080,
  scale150,
  scale160,
  scale170,
  spaceL,
  spaceM,
  spaceS,
  spaceXS,
  spaceXXS,
  xdlColorBackground,
  xdlColorBackgroundTertiary,
  xdlColorBorder,
  xdlColorBorderStrong,
  xdlColorControlBorder,
  xdlColorDisabledSoft,
  xdlColorDisabledStrong,
  xdlColorError,
  xdlColorSuccess,
  xdlColorText,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { Meta } from '@xing-com/typography';

import type { FormFieldSize, FormFieldVariant } from './form-field.types';

type ActionWrapperTProps = {
  $isDisabled?: boolean;
};

type TextareaTProps = {
  $error?: boolean;
  $isDisabled?: boolean;
  $isFilled: boolean;
  $size?: FormFieldSize;
  $sizeConfined?: FormFieldSize;
  $sizeWide?: FormFieldSize;
  $success?: boolean;
  $variant?: FormFieldVariant;
};

type InputTProps = {
  $error?: boolean;
  $hasAction?: boolean;
  $iconSize?: 18 | 24;
  $isDisabled?: boolean;
  $isFilled: boolean;
  $isIconSet?: boolean;
  $size?: FormFieldSize;
  $sizeConfined?: FormFieldSize;
  $sizeWide?: FormFieldSize;
  $success?: boolean;
  $variant?: FormFieldVariant;
};

type LabelTProps = {
  $hasFocus: boolean;
  $isDisabled?: boolean;
  $isFilled: boolean;
  $isIconSet?: boolean;
  $size: FormFieldSize;
  $sizeConfined?: FormFieldSize;
  $sizeWide?: FormFieldSize;
};

type HelperTextTProps = {
  $error?: boolean;
  $isDisabled?: boolean;
};

type IconTProps = {
  $iconSize?: 18 | 24;
  $isDisabled?: boolean;
};

export const Wrapper = styled.div`
  position: relative;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

// *** Base Styles ***
const styles = css`
  background: ${xdlColorBackgroundTertiary};
  border-bottom: ${spaceXXS} solid ${xdlColorBorder};
  border-left-width: 0;
  border-radius: ${cornerRadiusM} ${cornerRadiusM} 0 0;
  border-right-width: 0;
  border-top-width: 0;
  color: ${xdlColorText};
  display: inline-block;
  outline: none;
  padding-left: ${spaceL};
  padding-right: ${spaceM};
  width: 100%;

  &:focus {
    background: none;
    border: ${scale005} solid ${xdlColorControlBorder};
    border-bottom: ${spaceXXS} solid ${xdlColorBorder};
  }

  &:hover {
    border-bottom: ${spaceXXS} solid ${xdlColorBorderStrong};
  }
`;

// *** sizes ***
export const sizes = {
  small: {
    input: css`
      font-size: ${scale070};
      height: ${scale150};
      padding-bottom: ${scale050};
      padding-top: ${scale050};
    `,
    label: css`
      font-size: ${scale070};
    `,
  },
  medium: {
    input: css`
      font-size: ${scale080};
      height: ${scale160};
      padding-bottom: ${scale060};
      padding-top: ${scale060};
    `,
    label: css`
      font-size: ${scale080};
    `,
  },
  large: {
    input: css`
      font-size: ${scale080};
      height: ${scale170};
      padding-bottom: 15px;
      padding-top: 15px;
    `,
    label: css`
      font-size: ${scale080};
    `,
  },
};

// *** State styles ***
const filledStyle = css`
  background: ${xdlColorBackground};
  font-size: ${scale060};
  left: -4px;
  padding: 0 ${spaceXS} 0 ${spaceXS};
  top: -9px;
  transform: none;
`;

const inputFilled = css`
  background: none;
  border-bottom: ${spaceXXS} solid ${xdlColorControlBorder};
  border-left: ${scale005} solid ${xdlColorControlBorder};
  border-right: ${scale005} solid ${xdlColorControlBorder};
  border-top: ${scale005} solid ${xdlColorControlBorder};
`;

const errorStyle = css`
  && {
    border-bottom: ${spaceXXS} solid ${xdlColorError};

    &:hover,
    &:focus {
      border-bottom: ${spaceXXS} solid ${xdlColorBorderStrong};
    }
  }
`;

const successStyle = css`
  && {
    border-bottom: ${spaceXXS} solid ${xdlColorSuccess};

    &:hover,
    &:focus {
      border-bottom: ${spaceXXS} solid ${xdlColorBorderStrong};
    }
  }
`;

const disabledStyle = css`
  border-color: ${xdlColorDisabledStrong};
  color: ${xdlColorDisabledStrong};

  &:hover {
    border-bottom: ${spaceXXS} solid ${xdlColorDisabledStrong};
  }
`;

const plainStyles = css`
  && {
    background-color: ${xdlColorBackground};
    border: ${scale005} solid ${xdlColorControlBorder};
    border-bottom: ${spaceXXS} solid ${xdlColorBorder};

    &:hover,
    &:focus {
      border-bottom-color: ${xdlColorBorderStrong};
    }
  }
`;

const disabledPlainStyle = css`
  &&& {
    border-bottom: ${spaceXXS} solid ${xdlColorDisabledSoft};
    border-left: ${scale005} solid ${xdlColorDisabledSoft};
    border-right: ${scale005} solid ${xdlColorDisabledSoft};
    border-top: ${scale005} solid ${xdlColorDisabledSoft};
    color: ${xdlColorDisabledStrong};
  }

  &:hover {
    border-bottom: ${spaceXXS} solid ${xdlColorDisabledSoft};
  }
`;

export const HelperContainer = styled.div`
  display: flex;
  gap: ${spaceS};
  justify-content: space-between;
`;

export const CharacterCounterArea = styled(Meta)`
  flex-grow: 1;
  text-align: end;
`;

const helperTextError = css`
  && {
    color: ${xdlColorError};
  }
`;

export const HelperText = styled(Meta)<HelperTextTProps>`
  flex-grow: 1;
  margin-top: ${spaceXXS};

  ${({ $error }) => $error && helperTextError};
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      && {
        color: ${xdlColorDisabledStrong};
      }
    `}
`;

export const Input = styled.input<InputTProps>`
  ${styles}

  ${({ $variant }) => $variant === 'plain' && plainStyles};

  ${({ $size }) => $size && sizes[$size].input};
  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined].input};
      }
    `};
  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide].input};
      }
    `};

  ${({ $isIconSet }) =>
    $isIconSet &&
    css`
      padding-left: 35px;
    `};
  ${({ $isIconSet, $size }) =>
    $isIconSet &&
    $size === 'medium' &&
    css`
      padding-left: 40px;
    `};
  ${({ $isIconSet, $size }) =>
    $isIconSet &&
    $size === 'large' &&
    css`
      padding-left: 40px;
    `};

  ${({ $hasAction }) =>
    $hasAction &&
    css`
      padding-right: 40px;
    `};

  ${({ $isFilled }) => $isFilled && inputFilled};

  ${({ $success }) => $success && successStyle};
  ${({ $error }) => $error && errorStyle};

  ${({ $isDisabled }) => $isDisabled && disabledStyle};
  ${({ $isDisabled, $variant }) =>
    $isDisabled && $variant === 'plain' && disabledPlainStyle};

  ${({ $isDisabled, $isFilled }) =>
    $isDisabled &&
    !$isFilled &&
    css`
      && {
        border: 0;
      }
    `}

  &:not(:placeholder-shown) ~ &,
  :-webkit-autofill ~ &,
  :autofill ~ & {
    ${filledStyle};
  }
`;

export const Textarea = styled(TextareaAutosize)<TextareaTProps>`
  ${styles}
  height: auto;
  overflow-y: hidden;
  resize: none;
  width: 100%;

  ${({ $variant }) => $variant === 'plain' && plainStyles};

  ${({ $size }) => $size && sizes[$size].input};
  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined].input};
      }
    `};
  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide].input};
      }
    `};

  ${({ $isFilled }) => $isFilled && inputFilled};
  ${({ $success }) => $success && successStyle};
  ${({ $error }) => $error && errorStyle};

  ${({ $isDisabled }) => $isDisabled && disabledStyle};
  ${({ $isDisabled, $variant }) =>
    $isDisabled && $variant === 'plain' && disabledPlainStyle};

  ${({ $isDisabled, $isFilled }) =>
    $isDisabled &&
    !$isFilled &&
    css`
      && {
        border: 0;
      }
    `}
`;

export const Label = styled.label<LabelTProps>`
  color: ${xdlColorTextSecondary};
  display: inline-block;
  font-size: ${scale070};
  font-weight: 400;
  left: 0;
  margin-left: ${spaceL};
  pointer-events: none;
  position: absolute;
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.$size &&
    props.$size === 'small' &&
    css`
      top: 10px;
    `};

  ${(props) =>
    props.$size &&
    props.$size === 'medium' &&
    css`
      top: 12px;
    `};

  ${(props) =>
    props.$size &&
    props.$size === 'large' &&
    css`
      top: 15px;
    `};

  ${({ $size }) => $size && sizes[$size].label};

  ${({ $isFilled }) => $isFilled && filledStyle};

  ${({ $hasFocus }) => $hasFocus && filledStyle};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: ${xdlColorDisabledStrong};
    `}

  ${({ $isFilled, $isIconSet }) =>
    $isFilled &&
    $isIconSet &&
    css`
      left: -25px;
    `};

  ${({ $hasFocus, $isIconSet }) =>
    $hasFocus &&
    $isIconSet &&
    css`
      left: -25px;
    `};

  ${({ $isIconSet }) =>
    $isIconSet &&
    css`
      margin-left: 35px;
    `};

  ${({ $isIconSet, $size }) =>
    $isIconSet &&
    $size === 'medium' &&
    css`
      margin-left: 39px;
    `};

  ${({ $isIconSet, $size }) =>
    $isIconSet &&
    $size === 'large' &&
    css`
      margin-left: 39px;
    `};

  ${Textarea}:focus ~ & {
    ${({ $isFilled, $hasFocus }) => ($isFilled || $hasFocus) && filledStyle};
  }

  ${({ $sizeConfined, $isFilled, $hasFocus, $isIconSet }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined].label};
        ${$isFilled && filledStyle};
        ${$hasFocus && filledStyle};
        ${$isIconSet && $sizeConfined === 'medium' && 'margin-left: 39px'};
        ${$isIconSet && $sizeConfined === 'large' && 'margin-left: 39px'};
      }
    `};

  ${({ $sizeWide, $isFilled, $hasFocus, $isIconSet }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide].label};
        ${$isFilled && filledStyle};
        ${$hasFocus && filledStyle};
        ${$isFilled && $isIconSet && 'left: -25px'};
        ${$hasFocus && $isIconSet && 'left: -25px'};
      }
    `};
`;

export const ActionWrapper = styled.div<ActionWrapperTProps>`
  position: absolute;
  right: ${spaceM};
  top: 50%;
  transform: translateY(-50%);

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      & > button,
      & > a {
        color: ${xdlColorDisabledStrong};
      }
    `}
`;

// *** Icon ***
export const IconContainer = styled.span<IconTProps>`
  ${({ $iconSize }) =>
    $iconSize &&
    css`
      height: ${$iconSize}px;
      width: ${$iconSize}px;
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      && {
        color: ${xdlColorDisabledStrong};
      }
    `};

  color: ${xdlColorText};
  left: 10px;
  margin-right: ${spaceS};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
