import type {
  AffiliatesReducerState,
  AffiliatesReducerAction,
  AffiliatesReducerRow,
} from './types';
import { AffiliatesReducerActionKind } from './types';

export const EMPTY_ROW: AffiliatesReducerRow = {
  company: { id: '0', companyName: '' },
  category: { id: '0' },
  error: undefined,
};

const affiliatesFormReducer = (
  state: AffiliatesReducerState,
  action: AffiliatesReducerAction
): AffiliatesReducerState => {
  switch (action.type) {
    case AffiliatesReducerActionKind.SET_INITIAL_STATE_ACTION: {
      const rows =
        action.payload.initialOptions.length > 0
          ? action.payload.initialOptions
          : [EMPTY_ROW];
      return {
        waitingForSetup: false,
        rows,
      };
    }

    case AffiliatesReducerActionKind.ADD_ITEM_ACTION: {
      return {
        ...state,
        rows: [...state.rows, { ...EMPTY_ROW }],
      };
    }

    case AffiliatesReducerActionKind.UPDATE_COMPANY_ACTION: {
      const { index, company } = action.payload;
      return {
        ...state,
        rows: [
          ...state.rows.slice(0, index),
          { ...state.rows[index], company, error: undefined },
          ...state.rows.slice(index + 1),
        ],
      };
    }

    case AffiliatesReducerActionKind.UPDATE_AFFILIATION_CATEGORY_ACTION: {
      const { index, category } = action.payload;

      return {
        ...state,
        rows: [
          ...state.rows.slice(0, index),
          { ...state.rows[index], category: { id: category.id } },
          ...state.rows.slice(index + 1),
        ],
      };
    }

    case AffiliatesReducerActionKind.DELETE_ITEM_ACTION: {
      // get index and remove
      state.rows.splice(action.payload.index, 1);

      return {
        ...state,
        rows: [...state.rows],
      };
    }

    case AffiliatesReducerActionKind.UPDATE_AFFILIATES_ORDER_ACTION: {
      const { modules: rows } = action.payload;

      return {
        ...state,
        rows,
      };
    }

    case AffiliatesReducerActionKind.SET_ERROR_MESSAGE: {
      const { errors } = action.payload;

      const updatedAffiliates = state.rows.map((affiliate, affiliateIndex) => {
        const affiliateWithError = errors.collection.find(
          ({ index }) => affiliateIndex === index
        );

        if (affiliateWithError) {
          return {
            ...affiliate,
            error: { message: affiliateWithError.message },
          };
        }

        return affiliate;
      });

      return {
        ...state,
        rows: updatedAffiliates,
      };
    }

    default:
      return state;
  }
};

export { affiliatesFormReducer };
