import { useQuery } from '@apollo/client';
import type { FC } from 'react';

import {
  ModuleHeader,
  usePageContext,
  UpsellBanner,
  WarningMessageBox as Error,
} from '@xing-com/crate-companies-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-companies-entity-pages-common/src/tracking';

import { ContactsDocument } from '../../graphql/contacts-query.gql-types';
import { normalizeData } from '../../helpers';
import { Contacts } from '../contacts/contacts';
import * as Styled from './contacts-container.styles';

type ContactsContainerProps = {
  moduleType: string;
  pageSlug: string;
};
const ContactsContainer: FC<ContactsContainerProps> = ({
  pageSlug,
  moduleType,
}) => {
  const { pageContext } = usePageContext() ?? {};
  const upsellActive = pageContext?.upsellActive;

  const { data, loading, refetch } = useQuery(ContactsDocument, {
    variables: { id: pageSlug, limit: upsellActive ? 1 : 2 },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const { userList, currentUser, totalContacts } = normalizeData(data);

  const showMore =
    totalContacts > 2
      ? {
          to: getTrackingUrl({
            to: `${pageContext?.basePath}/${pageSlug}/contacts`,
            trackingKey: 'viewAllContacts',
          }),
          copyKey: 'EP_ALL_CONTACTS',
          testId: `${moduleType}_subpage_link`,
        }
      : null;

  return (
    <div data-testid="CONTACTS_MODULE">
      <ModuleHeader
        editTestId="EDIT_BUTTON_ON_CONTACTS"
        headlineCopyKey="EP_CONTACTS_MODULE_TITLE"
        editTo={`${pageContext?.basePath}/${pageSlug}/edit/contacts`}
        showMore={showMore}
      />

      <Styled.Layout $upsellActive={upsellActive}>
        {loading ? (
          <Contacts.Skeleton />
        ) : userList.length === 0 ? (
          <div data-testid={'errorContainer'}>
            <Error
              headerText="EP_ERROR_HEADER"
              bodyText="EP_ERROR_BODY"
              buttonText="EP_ERROR_RELOAD_CTA"
              onClick={() => refetch()}
            />
          </div>
        ) : (
          <>
            <Styled.Contacts
              $upsellActive={upsellActive}
              currentUser={currentUser}
              users={userList}
              messageEntryPoint={'entity_pages_main_contact_message'}
              trackingModuleType="Main"
            />
            {upsellActive && (
              <UpsellBanner
                headerCopyKey="EP_UPSELL_CONTACT_HEADER"
                bodyCopyKey="EP_UPSELL_CONTACT_BODY"
                moduleName="contactsmodule"
                column
              />
            )}
          </>
        )}
      </Styled.Layout>
    </div>
  );
};

export default ContactsContainer;
