import type { FC } from 'react';

import * as Styled from './skeleton.styles';

export const GalleryManagerSkeleton: FC = () => (
  <>
    <Styled.Navigation>
      <Styled.NavigationInner>
        <Styled.NavigationItem size="small" />
        <Styled.NavigationItem size="small" />
        <Styled.NavigationItem size="small" />
      </Styled.NavigationInner>
    </Styled.Navigation>
    <Styled.MediaItemWrapperSkeleton>
      {/* @ts-expect-error FIXME: SC6 */}
      <Styled.Image size="small" />
      <Styled.Body amountRows={2} />
      <Styled.ItemActionsWrapper>
        <Styled.ItemActionsButton size="small" />
        <Styled.ItemActionsButton size="small" />
      </Styled.ItemActionsWrapper>
    </Styled.MediaItemWrapperSkeleton>
    <Styled.GalleryActions>
      <Styled.PublishButtonSkeleton size="small" />
    </Styled.GalleryActions>
  </>
);
