import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  scale030,
  scale040,
  scale060,
  spaceL,
  spaceS,
  xdlColorDisabled,
  xdlColorHover,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const MoreMenuTitle = styled(BodyCopy)`
  display: none;

  @media ${mediaSmallOrTiny} {
    color: ${xdlColorTextSecondary};
    text-align: center;
    border-bottom: 1px solid ${xdlColorDisabled};
    padding: ${scale060};
    display: block;
  }
`;

export const ListWrapper = styled.div`
  min-width: 150px;

  > div {
    padding: ${spaceS} ${spaceL};
  }
`;

export const Link = styled(BodyCopy)`
  white-space: nowrap;
  cursor: pointer;
  padding: ${scale030};
  font-weight: 700;
`;

export const ListItem = styled.div`
  cursor: pointer;
  border-bottom: 0;

  &:hover {
    background-color: ${xdlColorHover};
  }

  @media ${mediaSmallOrTiny} {
    &:hover {
      background-color: transparent;
    }
  }
`;

export const List = styled.div`
  @media ${mediaSmallOrTiny} {
    padding: ${scale040} 0;
  }
`;
