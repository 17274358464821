import styled, { css } from 'styled-components';

import { Editor } from '@xing-com/content-editor-core-editor';
import {
  scale000,
  scale005,
  scale070,
  scale100,
  spaceM,
} from '@xing-com/tokens';

// *** Base ***
const baseSlateEditor = css`
  width: inherit;
  overflow: auto;
  word-break: break-word;
  & > p {
    margin: ${scale000} ${scale000} ${spaceM};
    line-height: calc(${scale100} + ${scale005});
    font-size: ${scale070};
  }
  & [data-slate-placeholder='true'] {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:focus {
    outline: none;
  }
`;

// *** Components ***

const SlateEditor = styled(Editor)`
  ${baseSlateEditor}
`;

export const Styled = { SlateEditor };
