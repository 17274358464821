import ttt from '@xing-com/ticktricktrack';

import {
  CHANNEL_ENTITY_PAGES,
  PAGENAME,
  SUBPAGE,
  PROP_SCROLLING,
} from './constants';
import trackingEnvironment from './environment';

ttt.init(trackingEnvironment, {
  fetchTrackingData: true,
  logLevel: 'error',
});

type contextDataType = any;
type baseContextType = any;

export const trackPage = (
  contextData: contextDataType,
  baseContext: baseContextType = {}
) => {
  ttt.pageview(CHANNEL_ENTITY_PAGES, PAGENAME, baseContext, contextData);
};

export const trackCustomPage = (
  pageName: string,
  contextData: contextDataType,
  baseContext: baseContextType = {}
) => {
  ttt.pageview(CHANNEL_ENTITY_PAGES, pageName, baseContext, contextData);
};

export const trackSubpage = (
  subpageModule: string,
  contextData: contextDataType,
  baseContext: baseContextType = {}
) => {
  ttt.pageview(
    CHANNEL_ENTITY_PAGES,
    `${SUBPAGE}/${subpageModule}`,
    baseContext,
    contextData
  );
};

export const trackEvent = (eventName: string, contextData: contextDataType) => {
  ttt.event(eventName, {
    ...contextData,
  });
};

export const trackAction = (
  contextData: contextDataType,
  eventName?: string
) => {
  ttt.event(eventName || 'PropTrackAction', {
    ...contextData,
  });
};

export const trackScrolling = (
  actionName: string,
  contextData: contextDataType
) => {
  ttt.event('PropScrolling', {
    [PROP_SCROLLING]: actionName,
    ...contextData,
  });
};
