import styled, { css } from 'styled-components';

import { spaceM } from '@xing-com/tokens';

import type { ListItemVariant } from './';

type ListItemTransientProps = {
  $variant?: ListItemVariant;
};

// *** Variants ***
export const variants = {
  left: css`
    justify-content: space-between;
  `,
};

// *** Components ***
export const ListItem = styled.li<ListItemTransientProps>`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding-bottom: ${spaceM};
  padding-left: ${spaceM};
  padding-right: ${spaceM};
  padding-top: ${spaceM};
  position: relative;
  text-align: left;

  ${({ $variant }) => $variant && variants[$variant]}
`;
