const hostname =
  (globalThis.location && globalThis.location.hostname) || 'default';

const envs: Record<string, string> = {
  localhost: 'development',
  'www.xing.com': 'production',
  default: 'preview',
};

const trackingEnvironment = envs[hostname] || envs['default'];

export default trackingEnvironment;
