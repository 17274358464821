import type { FC } from 'react';
import { useIntl } from 'react-intl';

import type { ButtonProps } from '@xing-com/button';
// TODO: fix this hops component
// import { Status } from '@xing-com/hops';

import { useEditContext } from '../../hooks/use-edit-context/use-edit-context';
import * as Styled from './info-state.styles';

export type InfoStateProps = {
  icon?: React.ReactNode;
  headlineCopyKey?: string;
  headlineCopy?: string | React.ReactNode;
  headlineClass?: string;
  bodyClass?: string;
  textCopyKey: string;
  className?: string;
  testId?: string;
  additionalInfo?: React.ReactNode;
  buttonProps?: ButtonProps | Array<ButtonProps>;
  customButton?: React.ReactNode;
  statusCode?: 404;
};
export const InfoState: FC<InfoStateProps> = ({
  icon,
  headlineCopyKey,
  headlineCopy,
  headlineClass,
  bodyClass,
  textCopyKey,
  className,
  testId,
  buttonProps = [],
  additionalInfo,
  customButton,
  // statusCode,
}) => {
  const { $t } = useIntl();
  const { isEditing } = useEditContext();
  const hasCopyKey = headlineCopyKey && !headlineCopy;

  const buttonPropsList =
    buttonProps && Array.isArray(buttonProps) ? buttonProps : [buttonProps];

  return (
    <>
      {/* {statusCode && <Status code={Number(statusCode)} />} */}
      <Styled.InfoStateWrapper
        $isEditing={!!isEditing}
        className={className}
        data-testid={testId}
      >
        {icon && (
          <Styled.IconWrapper>
            {typeof icon === 'string' ? (
              <Styled.Icon src={icon} alt={'Icon'} />
            ) : (
              icon
            )}
          </Styled.IconWrapper>
        )}
        <Styled.Info>
          <div>
            <Styled.TextWrapper $noIcon={!icon}>
              <Styled.HeaderText
                fontWeight="bold"
                lineHeight="dense"
                size="xlarge"
                noMargin
                className={headlineClass}
              >
                {hasCopyKey && $t({ id: headlineCopyKey })}
                {!hasCopyKey && headlineCopy}
              </Styled.HeaderText>
              <Styled.Text noMargin size="small" className={bodyClass}>
                {$t({ id: textCopyKey, defaultMessage: textCopyKey })}
              </Styled.Text>
            </Styled.TextWrapper>
          </div>
          {additionalInfo && (
            <Styled.ExtraContent>{additionalInfo}</Styled.ExtraContent>
          )}
        </Styled.Info>
        {buttonPropsList.length > 0 && (
          <Styled.CtaContainer>
            {buttonPropsList.map((props) => (
              <Styled.Button
                size="small"
                sizeConfined="medium"
                variant={isEditing ? 'secondary' : 'tertiary'}
                {...props}
                className={props.className}
                // @ts-expect-error TS(2339) FIXME: Property 'copyKey' does not exist on type 'ButtonP... Remove this comment to see the full error message
                key={props.copyKey}
              >
                {$t({
                  // @ts-expect-error TS(2339) FIXME: Property 'copyKey' does not exist on type 'ButtonP... Remove this comment to see the full error message
                  id: props.copyKey,
                  // @ts-expect-error TS(2339) FIXME: Property 'copyKey' does not exist on type 'ButtonP... Remove this comment to see the full error message
                  defaultMessage: props.copyKey,
                })}
              </Styled.Button>
            ))}
          </Styled.CtaContainer>
        )}
        {customButton !== null && customButton}
      </Styled.InfoStateWrapper>
    </>
  );
};
