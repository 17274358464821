import styled from 'styled-components';

import { Button, TextButton } from '@xing-com/button';
import { EditButton } from '@xing-com/crate-companies-entity-pages-common';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import {
  spaceL,
  spaceM,
  spaceXXL,
  xdlColorBorderSoft,
  xdlColorDisabledStrong,
} from '@xing-com/tokens';

export const EditSloganButton = styled(EditButton)`
  display: none;

  @media ${mediaWideNavless} {
    display: flex;
    margin-left: ${spaceM};
  }
`;

export const FormFieldWrapper = styled.div`
  margin-top: ${spaceXXL};
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
  border-top: 1px solid ${xdlColorBorderSoft};
  margin-top: calc(2 * 32px);
  margin-bottom: 32px;
`;

export const PublishButton = styled(Button)`
  margin-left: ${spaceL};
`;

export const EditLabel = styled(TextButton)`
  color: ${xdlColorDisabledStrong};
  font-weight: 400;
`;
