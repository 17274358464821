import { MULTI_IMAGE_POST_MAX_IMAGES } from '../constants';
import type { CommboxAttachmentType, CommboxImagesType } from '../types';

export type IsImageUploadDisabledType = (props: {
  attachmentType: CommboxAttachmentType;
  enableMultiImage: boolean;
  images: CommboxImagesType[];
  isEditingPosting: boolean;
}) => boolean;

export const isImageUploadDisabled: IsImageUploadDisabledType = ({
  attachmentType,
  enableMultiImage,
  images,
  isEditingPosting,
}) => {
  // can't add images when editing
  if (isEditingPosting) return true;
  if (enableMultiImage)
    return (
      (attachmentType !== 'EMPTY' && attachmentType !== 'IMAGE') ||
      images.length >= MULTI_IMAGE_POST_MAX_IMAGES
    );

  return !!images?.length || attachmentType !== 'EMPTY';
};
