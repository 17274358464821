import styled from 'styled-components';

import { xdlColorText } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const TextButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  color: ${xdlColorText};
  text-align: left;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const AnchorText = styled.a`
  font-size: 14px;
  font-weight: bold;
  color: ${xdlColorText};
  text-align: left;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const NoMarginBodyCopy = styled(BodyCopy)`
  margin: 0;
`;
