import {
  differenceInDays,
  getDate,
  getMonth,
  getYear,
  endOfDay,
} from 'date-fns';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

type FormattedTimestampProps = {
  date: Date;
  todayDate?: Date;
  isShortVersion?: boolean;
  onlyMonthAndYear?: boolean;
  numOfDays?: number;
};

const getDaysAgoMessage = (
  intl: any,
  numOfDays: number,
  isShortVersion?: boolean
) => {
  switch (numOfDays) {
    case 0:
      return intl.formatMessage({ id: 'EP_FORMATTEDTIMESTAMP_TODAY' });
    case 1:
      return intl.formatMessage({ id: 'EP_FORMATTEDTIMESTAMP_YESTERDAY' });
    default:
      return intl.formatMessage(
        {
          id: isShortVersion
            ? 'EP_FORMATTEDTIMESTAMP_DAYSAGO_SHORT'
            : 'EP_FORMATTEDTIMESTAMP_DAYSAGO',
        },
        { number: numOfDays }
      );
  }
};

const getDateFormatted = (
  intl: any,
  date = new Date(),
  todayDate = new Date(),
  isShortVersion?: boolean
) => {
  const formatted = [];
  const day = getDate(date);
  const year = getYear(date);
  const month = intl
    .formatMessage({ id: isShortVersion ? 'MONTH_NAMES_SHORT' : 'MONTH_NAMES' })
    .split(',')[getMonth(date)];

  formatted.push(day + (intl.locale === 'de' ? '.' : ''));
  formatted.push(month + (intl.locale === 'de' && isShortVersion ? '.' : ''));

  if (year !== getYear(todayDate)) {
    formatted.push(isShortVersion ? year % 1000 : year);
  }

  return formatted.join(' ');
};

const getMonthAndYear = (intl: any, date: Date, isShortVersion?: boolean) => {
  const year = isShortVersion ? getYear(date) % 1000 : getYear(date);
  const month = intl
    .formatMessage({ id: isShortVersion ? 'MONTH_NAMES_SHORT' : 'MONTH_NAMES' })
    .split(',')[getMonth(date)];

  return `${month} ${year}`;
};

export const FormattedTimestamp: FC<FormattedTimestampProps> = ({
  date,
  todayDate = new Date(),
  isShortVersion,
  onlyMonthAndYear,
}) => {
  const intl = useIntl();
  const numOfDays = differenceInDays(endOfDay(todayDate), endOfDay(date));

  if (onlyMonthAndYear) {
    return <>{getMonthAndYear(intl, date, isShortVersion)}</>;
  }

  return (
    <>
      {numOfDays < 7
        ? getDaysAgoMessage(intl, numOfDays, isShortVersion)
        : getDateFormatted(intl, date, todayDate, isShortVersion)}
    </>
  );
};
