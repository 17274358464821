import type { EntityPageContactUser } from '@xing-com/crate-common-graphql-types';

import type { ContactsQuery } from '../graphql/contacts-query.gql-types';
import type { XingIdContactsFragment } from '../graphql/xing-id-contacts-fragment.gql-types';

export const normalizeData = (data?: ContactsQuery) => {
  const totalContacts = data?.entityPageContactUsers?.total || 0;

  const contactUsers = data?.entityPageContactUsers?.collection || [];

  const userList = contactUsers.map((user) => ({
    label: user.label,
    id: user.id,
    ...user.xingId,
  })) as (Pick<EntityPageContactUser, 'id' | 'enabled' | 'label'> &
    XingIdContactsFragment)[];

  const currentUser = data?.viewer?.xingId?.id;

  return {
    currentUser,
    userList,
    totalContacts,
  };
};
