import { useNavigate } from '@reach/router';
import type { FC } from 'react';
import { FormattedPlural, FormattedMessage } from 'react-intl';

import { NetworkRelationshipType } from '@xing-com/crate-common-graphql-types';
import {
  capitalizeString,
  redirectToLogin,
} from '@xing-com/crate-companies-entity-pages-common';
import {
  getTrackingUrl,
  trackEmployeesAddContactButtonAction,
  ENTITY_PAGE_SUB_EMPLOYEES,
} from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import type { FlagSize } from '@xing-com/flag';
import { Flag } from '@xing-com/flag';
import { useViewerData } from '@xing-com/hub';
import { IconAddContact, IconMessages } from '@xing-com/icons';
import { SkeletonProfileImage, SkeletonBody } from '@xing-com/skeleton';

import { BASIC_FLAG, EXECUTIVE } from '../employees/config';
import { useNetworkRequestMutation } from '../hooks/use-network-request-mutation';
import {
  addContactButtonDisabled,
  FOLLOW_EMPLOYEE_ACTION,
  FOLLOW_EMPLOYEE_ID_PARAM,
} from '../utils';
import * as Styled from './employees-detail.styles';

export const EmployeesCardHorizontalSkeleton = () => (
  <Styled.SkeletonWrapper>
    <Styled.SkeletonProfileImage>
      <SkeletonProfileImage />
    </Styled.SkeletonProfileImage>
    <Styled.SkeletonProfileInfo>
      <SkeletonBody amountRows={3} />
    </Styled.SkeletonProfileInfo>
  </Styled.SkeletonWrapper>
);

type EmployeesCardHorizontalProps = {
  id: string;
  displayName?: string;
  occupationTitle?: string;
  sharedContacts?: number;
  contactDistance?: number;
  profileUrl?: string;
  pageName?: string;
  trackingModuleType?: string;
  url?: string;
  relationship?: NetworkRelationshipType;
  loggedOut?: boolean;
  flag?: string;
  onAddContact?: () => void;
};
const EmployeesCardHorizontal: FC<EmployeesCardHorizontalProps> = ({
  id,
  displayName,
  occupationTitle,
  sharedContacts = 0,
  contactDistance,
  relationship,
  profileUrl,
  pageName,
  url,
  loggedOut,
  trackingModuleType,
  flag,
  onAddContact = () => undefined,
}) => {
  const { data: viewerData } = useViewerData();
  const currentUser = viewerData?.user;
  const navigate = useNavigate();

  const [executeNetworkRequest, { loading: mutationLoading }] =
    useNetworkRequestMutation({
      onCompleted: onAddContact,
    });

  const getFlag = (
    flag?: string
  ):
    | {
        size: FlagSize;
        variant: $TSFixMe;
        children: string | undefined;
      }
    | undefined => {
    if (!flag || flag === BASIC_FLAG || flag === EXECUTIVE) return;

    return {
      size: 'small',
      variant: flag.toLowerCase(),
      children: capitalizeString(flag),
    };
  };

  const handleOnClick = () => {
    if (loggedOut) {
      redirectToLogin(`${FOLLOW_EMPLOYEE_ACTION}`, {
        [FOLLOW_EMPLOYEE_ID_PARAM]: id,
      });
    } else if (relationship === NetworkRelationshipType.Contact) {
      navigate(
        `/chats/new/${pageName}?contextId=entity_pages&entryPoint=entity_pages_sub_employees_message`
      );
    } else {
      executeNetworkRequest(id);
      trackEmployeesAddContactButtonAction(ENTITY_PAGE_SUB_EMPLOYEES);
    }
  };

  const isCurrentUser = currentUser?.id === id;

  return (
    <Styled.Card>
      <Styled.EmployeesCard
        alignment="horizontal"
        profileUrl={getTrackingUrl({
          to: profileUrl,
          trackingKey: `viewEmployeesProfile${trackingModuleType}`,
        })}
        profileImage={{
          size: 'large',
          src: url,
          profileName: displayName,
          connectionDegree: !loggedOut ? contactDistance : 0,
        }}
        headline={{
          size: 'medium',
          noMargin: true,
          children: (
            <Styled.HeadlineFlagWrapper>
              <Styled.ClampedText>{displayName}</Styled.ClampedText>
              {getFlag(flag) && (
                <Flag
                  variant={getFlag(flag)?.variant}
                  size={getFlag(flag)?.size}
                  {...getFlag(flag)}
                />
              )}
            </Styled.HeadlineFlagWrapper>
          ),
        }}
        textBody={[
          {
            size: 'medium',
            children: (
              <Styled.ClampedText>{occupationTitle}</Styled.ClampedText>
            ),
          },
        ]}
        textMeta={
          !loggedOut && !isCurrentUser && sharedContacts !== 0
            ? [
                {
                  size: 'medium',
                  children: (
                    <FormattedPlural
                      value={sharedContacts}
                      one={
                        <FormattedMessage
                          id="EP_EMPLOYEE_CARD_SHARED_CONTACTS_WEB.one"
                          values={{ value: sharedContacts }}
                        />
                      }
                      other={
                        <FormattedMessage
                          id="EP_EMPLOYEE_CARD_SHARED_CONTACTS_WEB.other"
                          values={{ value: sharedContacts }}
                        />
                      }
                    />
                  ),
                },
              ]
            : []
        }
        buttons={
          isCurrentUser
            ? []
            : [
                {
                  size: 'medium',
                  variant: 'tertiary',
                  disabled: addContactButtonDisabled(String(relationship)),
                  as: Styled.Button,
                  loading: mutationLoading,
                  icon:
                    !loggedOut &&
                    relationship === NetworkRelationshipType.Contact
                      ? IconMessages
                      : IconAddContact,
                  onClick: handleOnClick,
                },
              ]
        }
      />
    </Styled.Card>
  );
};

export default EmployeesCardHorizontal;
