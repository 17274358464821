import styled from 'styled-components';

import {
  spaceXS,
  spaceM,
  spaceL,
  xdlColorDisabledSoft,
  xdlColorText,
} from '@xing-com/tokens';

export const ExternalIcon = styled.div`
  height: 64px !important;
  width: 64px !important;
  z-index: 10;
  background-color: ${xdlColorDisabledSoft};
  opacity: 0.9;
  border-radius: 64px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${xdlColorText};
  top: calc(50% - 32px);
  left: calc(50% - 32px);
`;

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  position: absolute;
  max-width: 100%;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media (max-width: 450px) {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-height: 133vw;
  }
`;

export const CarouselWrapper = styled.div`
  & > div > button {
    visibility: visible !important;
  }
`;

export const SliderItemWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;

  @media (max-width: 450px) {
    position: relative;
    padding-top: 0;
    width: 100%;
  }
`;

export const SliderItem = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: -1px;
  bottom: 0;
  right: -1px;

  @media (max-width: 450px) {
    width: 100%;
    position: relative;
  }
`;

export const InfoWrapper = styled.div`
  @media (max-width: 450px) {
    position: absolute;
    bottom: ${spaceM};
    left: 20px;
    right: 20px;
  }
`;

export const Pagination = styled.div`
  font-weight: bold;
  margin: ${spaceL} 0;

  @media (max-width: 450px) {
    margin: 0;
  }
`;

export const Description = styled.div`
  margin-bottom: ${spaceL};

  @media (max-width: 450px) {
    margin-bottom: ${spaceXS};
    min-height: 60px;
  }
`;
