import styled, { css } from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL } from '@xing-com/tokens';

import { Contacts as DefaultContacts } from '../contacts/contacts';

export const Layout = styled.div<{ $upsellActive?: boolean }>`
  display: grid;
  grid-template-columns: 100%;

  gap: ${spaceL};

  ${({ $upsellActive }) =>
    $upsellActive &&
    css`
      @media ${mediaConfined} {
        grid-template-columns: 50% 50%;
      }
    `}
`;

export const Contacts = styled(DefaultContacts)<{ $upsellActive?: boolean }>`
  ${({ $upsellActive }) =>
    $upsellActive &&
    css`
      @media ${mediaConfined} {
        grid-template-columns: 100%;
      }
    `}
`;
