import type { ChangeEvent, Dispatch, RefObject, SetStateAction } from 'react';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { IconSearch } from '@xing-com/icons';
import { BodyCopy } from '@xing-com/typography';

import * as Styled from './search-input.styles';

type SearchInputProps = {
  query?: string;
  setQuery: Dispatch<SetStateAction<string>>;
  innerRef: RefObject<HTMLButtonElement>;
  onFocus: () => void;
  disablePageActions?: boolean;
};

const SearchInput = ({
  disablePageActions,
  onFocus,
  innerRef,
  query,
  setQuery,
}: SearchInputProps) => {
  const searchRef = useRef(null);
  const intl = useIntl();
  const inputRef = innerRef as unknown as RefObject<HTMLDivElement>;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };
  return (
    <Styled.InputSectionWrapper>
      <Styled.LabelWrapper>
        <BodyCopy fontWeight={'bold'} noMargin size={'medium'}>
          {intl.formatMessage({
            id: 'EP_ADD_CONTACTS_SEARCH_LABEL',
            defaultMessage: 'EP_ADD_CONTACTS_SEARCH_LABEL',
          })}
        </BodyCopy>
      </Styled.LabelWrapper>
      <Styled.InputWrapper ref={inputRef}>
        <Styled.InputBar
          ref={searchRef}
          icon={IconSearch}
          placeholder={intl.formatMessage({
            id: 'EP_CONTACTS_SEARCH_PLACEHOLDER',
            defaultMessage: 'EP_CONTACTS_SEARCH_PLACEHOLDER',
          })}
          data-cy="SEARCH_CONTACT_PERSON"
          value={query || ''}
          // @ts-expect-error FIXME: SC6
          onChange={handleChange}
          onFocus={onFocus}
          disabled={disablePageActions}
        />
      </Styled.InputWrapper>
    </Styled.InputSectionWrapper>
  );
};

export default SearchInput;
