import * as React from 'react';

import { StarList } from './star-list';
import * as Styled from './star-rating.styles';
import type { StarRatingProps } from './star-rating.types';

export const StarRating = React.forwardRef<HTMLDivElement, StarRatingProps>(
  ({ className, noValue, rating, ...props }, forwardedRef): JSX.Element => (
    <Styled.Rating
      className={className}
      data-xds="StarRating"
      ref={forwardedRef}
      {...props}
    >
      {rating && !noValue ? (
        <Styled.Text forwardedAs="span" size="small" noMargin>
          {Number(rating).toFixed(1)}
        </Styled.Text>
      ) : null}
      <StarList rating={rating} emptyState={typeof rating === 'undefined'} />
    </Styled.Rating>
  )
);

StarRating.displayName = 'StarRating';
