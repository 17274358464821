import styled from 'styled-components';

import { Button as DefaultButton } from '@xing-com/button';
import * as layoutTokens from '@xing-com/layout-tokens';

export const Button = styled(DefaultButton)`
  .isArmstrong {
    display: none;

    @media ${layoutTokens.mediaWide} {
      display: flex;
    }
  }
`;
