import styled from 'styled-components';

import { Button as InnerButton } from '@xing-com/button';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { spaceS } from '@xing-com/tokens';

export const Wrapper = styled.div`
  @media ${mediaSmallOrTiny} {
    min-height: 0;
  }
`;

export const Button = styled(InnerButton)`
  display: block;
  margin-left: ${spaceS};
  padding: 0;
`;
