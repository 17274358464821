import type React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useState } from 'react';

import { EntityPageFocusType } from '@xing-com/crate-common-graphql-types';

import { usePageContext } from '../../hooks/use-page-context/use-page-context';
import type { EditItemMapTrackingProps } from '../../typings/types';

export type EditContextType = {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isEditor: boolean;
  setIsEditor: React.Dispatch<React.SetStateAction<boolean>>;
  isAdmin: boolean;
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  trackingData: EditItemMapTrackingProps | null;
  setTrackingData: React.Dispatch<
    React.SetStateAction<EditItemMapTrackingProps | null>
  >;
};

export const EditContext = createContext<EditContextType | undefined>(
  undefined
);

export const EditContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { pageContext } = usePageContext() ?? {};
  const isEditable = pageContext?.focusType === EntityPageFocusType.Company;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isEditor, setIsEditor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [trackingData, setTrackingData] =
    useState<EditItemMapTrackingProps | null>(null);

  const updateIsEditing = useCallback(
    (value: boolean | ((prev: boolean) => boolean)) => {
      if (isEditable) {
        setIsEditing(value);
      }
    },
    [isEditable]
  );

  return (
    <EditContext.Provider
      value={{
        isEditing,
        setIsEditing: updateIsEditing,
        isEditor,
        setIsEditor,
        isAdmin,
        setIsAdmin,
        trackingData,
        setTrackingData,
      }}
    >
      {children}
    </EditContext.Provider>
  );
};
