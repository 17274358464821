import styled from 'styled-components';

import { ListItem as DefaultListItem } from '@xing-com/list';
import { xdlColorBackgroundTertiary } from '@xing-com/tokens';

export const ListWrapper = styled.div`
  position: relative;
`;

export const ListItem = styled(DefaultListItem)`
  cursor: pointer;
  overflow: hidden;

  ${($hovered) =>
    $hovered && `background-color: ${xdlColorBackgroundTertiary};`}
`;
