import { useQuery } from '@apollo/client';
import { useState, useRef, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDebounce } from 'use-debounce';

import { usePageContext } from '@xing-com/crate-companies-entity-pages-common';
import { Flag, type FlagProps, type FlagVariant } from '@xing-com/flag';
import { IconWarning } from '@xing-com/icons';
import { OmView } from '@xing-com/platform-layout-om';
import { usePopOver } from '@xing-com/pop-over';
import { BodyCopy } from '@xing-com/typography';

import { MembersSearchDocument } from '../../graphql/members-search-query.gql-types';
import type { XingIdContactsFragment } from '../../graphql/xing-id-contacts-fragment.gql-types';
import SearchInput from './search-input';
import * as Styled from './search-input.styles';
import ShowMoreResults from './show-more-results';

type SearchInputContainerProps = {
  createContact: (param: XingIdContactsFragment) => void;
  disablePageActions?: boolean;
};

const SearchInputContainer = ({
  createContact,
  disablePageActions,
}: SearchInputContainerProps) => {
  const openOmView = useRef<() => void | null>();
  const closeOmView = useRef<() => void | null>();
  const [query, setQuery] = useState('');
  const [text] = useDebounce(query, 100);
  const lastCommitedText = useRef<string | null>(null);
  const popOver = usePopOver();
  const { pageContext } = usePageContext() ?? {};
  const [contactIdAdded, setContactIdAdded] = useState<string>('');
  const intl = useIntl();

  const { data, loading, error } = useQuery(MembersSearchDocument, {
    variables: {
      first: 6,
      query: {
        consumer: 'loggedin.web.entitypages.search.react.search-results',
        queries: {
          name: text,
        },
      },
    },
    fetchPolicy: 'no-cache',
    skip: !text && text !== lastCommitedText.current,
  });

  const users = data?.viewer?.membersSearch?.edges
    ?.map((edge) => edge.node)
    .filter(
      (user) =>
        user?.__typename === 'MembersSearchUnfencedItem' && !!user.xingId
    );

  const slicedUsers = users?.slice(0, 5);

  useEffect(() => {
    lastCommitedText.current = text;

    if (users && lastCommitedText.current) {
      popOver.handleShow();
    }
  }, [data]);

  useEffect(() => {
    if (!text) {
      popOver.handleHide();
    }
  }, [text]);

  const clearInput = () => {
    setQuery('');
  };

  const handleAddContact = async (user: XingIdContactsFragment) => {
    setContactIdAdded(user.id);

    await createContact(user);

    setContactIdAdded('');
    clearInput();
    closeOmView?.current && closeOmView.current();
  };

  const handleShowMore = () => {
    popOver.handleHide();
    openOmView.current && openOmView.current();
  };

  return (
    <>
      <Styled.NoticeWrapper>
        {pageContext?.contractType === 'FREE' ? (
          <BodyCopy size={'small'} noMargin>
            <FormattedMessage
              id={'EP_EDIT_CONTACTS_FREE_INFO'}
              defaultMessage={'EP_EDIT_CONTACTS_FREE_INFO'}
            />
          </BodyCopy>
        ) : (
          <BodyCopy size={'small'} noMargin>
            <FormattedMessage
              id={'EP_EDIT_CONTACTS_PAID_INFO'}
              defaultMessage={'EP_EDIT_CONTACTS_PAID_INFO'}
            />
          </BodyCopy>
        )}
      </Styled.NoticeWrapper>
      <SearchInput
        onFocus={() => text && popOver.handleShow()}
        innerRef={popOver.triggerRef}
        query={query}
        setQuery={setQuery}
        disablePageActions={disablePageActions}
      />
      <Styled.PopOver
        triggerRef={popOver.triggerRef}
        show={popOver.show}
        onOutsideClick={() => popOver.handleHide()}
        // @ts-expect-error FIXME: SC6
        isNotCloseableByTriggerRef={true}
      >
        <div style={{ width: popOver.triggerRef?.current?.clientWidth }}>
          {slicedUsers && slicedUsers?.length > 0 ? (
            <>
              {slicedUsers.map((user) => {
                if (user?.__typename !== 'MembersSearchUnfencedItem') {
                  return;
                }

                const flag: FlagProps | undefined =
                  user.xingId?.userFlags?.displayFlag &&
                  user.xingId?.userFlags?.displayFlag !== 'BASIC' &&
                  user.xingId?.userFlags?.displayFlag !== 'EXECUTIVE'
                    ? {
                        size: 'small',
                        variant:
                          user.xingId.userFlags.displayFlag?.toLowerCase() as FlagVariant,
                      }
                    : undefined;

                return (
                  <Styled.ProfileInfo
                    key={user.xingId?.id}
                    profileUrl={`/profile/${user.xingId?.pageName}`}
                    profileImage={{
                      size: 'small',
                      src: user.xingId?.profileImage?.[0]
                        ? user.xingId?.profileImage[0].url
                        : undefined,
                      profileName: 'user profile image',
                    }}
                    headline={{
                      size: 'small',
                      children: (
                        <div>
                          <strong>{user.xingId?.displayName}</strong>
                          {flag && <Flag {...flag} />}
                        </div>
                      ),
                    }}
                    textBody={[
                      {
                        size: 'xsmall',
                        children: user.xingId?.occupations?.[0]?.headline,
                      },
                    ]}
                    buttons={[
                      {
                        variant: 'primary',
                        size: 'small',
                        // @ts-expect-error FIXME: SC6
                        'data-testid': 'ADD_CONTACT_BUTTON',
                        children: intl.formatMessage({
                          id: 'EP_ADD_CTA',
                          defaultMessage: 'EP_ADD_CTA',
                        }),
                        loading:
                          !!contactIdAdded &&
                          contactIdAdded === user.xingId?.id,
                        disabled:
                          !!contactIdAdded &&
                          contactIdAdded !== user.xingId?.id,
                        onClick: () =>
                          user.xingId && handleAddContact(user.xingId),
                      },
                    ]}
                  />
                );
              })}
              {users && users?.length > 5 && (
                <Styled.Button
                  variant={'secondary'}
                  size={'small'}
                  onClick={handleShowMore}
                  data-testid={'SHOW_MORE_CONTACTS_BUTTON'}
                >
                  <FormattedMessage
                    id={'EP_SHOW_MORE_RESULTS'}
                    defaultMessage={'EP_SHOW_MORE_RESULTS'}
                  />
                </Styled.Button>
              )}
            </>
          ) : text && !loading && !error ? (
            <Styled.SearchEmptyState>
              <BodyCopy noMargin size={'small'}>
                {/* <IconWarning width={16} height={16} />  */}
                <FormattedMessage
                  id={'EP_CONTACTS_SEARCH_NO_RESULTS'}
                  defaultMessage={'EP_CONTACTS_SEARCH_NO_RESULTS'}
                />
              </BodyCopy>
            </Styled.SearchEmptyState>
          ) : (
            !loading &&
            error && (
              <Styled.SearchErrorContainer>
                <Styled.IconWarning>
                  <IconWarning width={16} height={16} />
                </Styled.IconWarning>
                <BodyCopy noMargin size={'small'}>
                  <FormattedMessage
                    id={'EP_CONTACTS_SEARCH_INPUT_ERROR'}
                    defaultMessage={'EP_CONTACTS_SEARCH_INPUT_ERROR'}
                  />
                </BodyCopy>
              </Styled.SearchErrorContainer>
            )
          )}
        </div>
      </Styled.PopOver>
      {users && users?.length > 5 && (
        <OmView
          onClose={() => clearInput()}
          trigger={(activateOmView) => {
            openOmView.current = activateOmView;
            return undefined;
          }}
        >
          {({ handleClose }: { handleClose: () => void }) => {
            closeOmView.current = handleClose;
            return (
              <ShowMoreResults
                text={text}
                handleAddContact={handleAddContact}
                contactIdAdded={contactIdAdded}
                setContactIdAdded={setContactIdAdded}
              />
            );
          }}
        </OmView>
      )}
    </>
  );
};

export default SearchInputContainer;
