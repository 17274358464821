import {
  GalleryEdit,
  AboutUsEditDescription,
  AffiliatesEditor,
  SummaryEdit,
  FactsEdit,
  DocumentsEditor,
} from '@xing-com/crate-companies-entity-pages-about-us';
import { ContactsEdit } from '@xing-com/crate-companies-entity-pages-contacts';
import { EditPageInfo } from '@xing-com/crate-companies-entity-pages-header';
import { LocationsEditContainer } from '@xing-com/crate-companies-entity-pages-location';

const EP_BACK_COPY_KEY = 'BUTTON_BACK';

type EditItemsMapProps = {
  [item: string]: {
    edit: any;
    backLink: {
      labelKey: string;
      to: string;
    };
    tracking: {
      module: string;
      part: string;
    };
  };
};

const editItemsMap: EditItemsMapProps = {
  pageInfo: {
    edit: EditPageInfo,
    backLink: {
      labelKey: EP_BACK_COPY_KEY,
      to: '',
    },
    tracking: {
      module: 'header',
      part: 'title',
    },
  },
  documents: {
    edit: DocumentsEditor,
    backLink: {
      labelKey: EP_BACK_COPY_KEY,
      to: 'about_us',
    },
    tracking: {
      module: 'about_us',
      part: 'documents',
    },
  },
  gallery: {
    edit: GalleryEdit,
    backLink: {
      labelKey: EP_BACK_COPY_KEY,
      to: 'about_us',
    },
    tracking: {
      module: 'about_us',
      part: 'gallery',
    },
  },
  aboutUsDescription: {
    edit: AboutUsEditDescription,
    backLink: {
      labelKey: EP_BACK_COPY_KEY,
      to: 'about_us',
    },
    tracking: {
      module: 'about_us',
      part: 'description',
    },
  },
  contacts: {
    edit: ContactsEdit,
    backLink: {
      labelKey: EP_BACK_COPY_KEY,
      to: 'contacts',
    },
    tracking: {
      module: 'about_us',
      part: 'contacts',
    },
  },
  locations: {
    edit: LocationsEditContainer,
    backLink: {
      labelKey: EP_BACK_COPY_KEY,
      to: '',
    },
    tracking: {
      module: 'about_us',
      part: 'locations',
    },
  },
  affiliates: {
    edit: AffiliatesEditor,
    backLink: {
      labelKey: EP_BACK_COPY_KEY,
      to: 'about_us',
    },
    tracking: {
      module: 'about_us',
      part: 'affiliates',
    },
  },
  aboutUsSummary: {
    edit: SummaryEdit,
    backLink: {
      labelKey: EP_BACK_COPY_KEY,
      to: 'about_us',
    },
    tracking: {
      module: 'about_us',
      part: 'summary',
    },
  },
  facts: {
    edit: FactsEdit,
    backLink: {
      labelKey: EP_BACK_COPY_KEY,
      to: 'about_us',
    },
    tracking: {
      module: 'about_us',
      part: 'facts_industry_category',
    },
  },
};

export default editItemsMap;
