import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { createContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { Carousel } from '@xing-com/carousel';
import {
  ModuleHeader,
  useEditContext,
  usePageContext,
  WarningMessageBox as Error,
} from '@xing-com/crate-companies-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { EmployeesDocument } from '../graphql/queries/employees-query.gql-types';
import { useNetworkRequestLoggedOutAction } from '../hooks/use-network-request-logged-out-action';
import { useNetworkRequestMutation } from '../hooks/use-network-request-mutation';
import type { EmployeeNode } from '../types';
import { QUERY_CONSUMER_CAROUSEL, EMPLOYEES_SORTING } from '../utils';
import {
  NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_IN,
  NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT,
  EMPLOYEES_OFFSET_LOGGED_OUT,
  EMPLOYEES_OFFSET_LOGGED_IN,
} from './config';
import { EmployeeCardSkeleton } from './employees-card-vertical/employees-card-skeleton';
import EmployeesCarousel from './employees-carousel';
import * as Styled from './employees-container.styles';

const DEFAULT_ERROR_POLICY = 'all';
const MINIMUM_EMPLOYEES_FOR_DETAIL_LINK = 4;

export const trackingKey = 'viewAllEmployees';

type EmployeesContainerProps = EmployeesProps;

export const EmployeesContext = createContext<{ companyId?: string }>({});

type RenderErrorProps = {
  refetch: () => void;
  isEditing: boolean;
};
const RenderError: FC<RenderErrorProps> = ({ refetch, isEditing }) => (
  <div data-testid="errorContainer">
    <ModuleHeader
      headlineCopyKey="EP_EMPLOYEES_HEADLINE"
      externalEditInfo={
        isEditing
          ? {
              copyKey: 'EP_EMPLOYEES_EDIT_HINT',
              cyTestId: 'EDIT_HINT_EMPLOYEES',
            }
          : null
      }
    />
    <Error
      headerText="EP_ERROR_HEADER"
      bodyText="EP_ERROR_BODY"
      buttonText="EP_ERROR_RELOAD_CTA"
      onClick={() => refetch()}
    />
  </div>
);

type EmployeesProps = {
  companyId: string;
  pageSlug: string;
};
export const Employees: FC<EmployeesProps> = ({ pageSlug, companyId }) => {
  const { pageContext } = usePageContext() ?? {};
  const { isEditing } = useEditContext();
  const { isLoggedIn } = useLoginState();

  const numberOfEmployeesToShow = isLoggedIn
    ? NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_IN * EMPLOYEES_OFFSET_LOGGED_IN
    : NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT * EMPLOYEES_OFFSET_LOGGED_OUT;

  const {
    data: employeesWithProfilePicture,
    loading: loadingEmployeesWithProfilePicture,
    refetch: refetchEmployeesWithProfilePicture,
  } = useQuery(EmployeesDocument, {
    variables: {
      id: companyId,
      first: numberOfEmployeesToShow,
      includeTotalQuery: true,
      consumer: QUERY_CONSUMER_CAROUSEL,
      query: {
        consumer: QUERY_CONSUMER_CAROUSEL,
        sort: EMPLOYEES_SORTING,
        filters: {
          hasPhoto: true,
        },
      },
    },
    errorPolicy: DEFAULT_ERROR_POLICY,
  });

  const {
    data: employeesWithoutProfilePicture,
    loading: loadingEmployeesWithoutProfilePicture,
    refetch: refetchEmployeesWithoutProfilePicture,
  } = useQuery(EmployeesDocument, {
    skip:
      loadingEmployeesWithProfilePicture ||
      (employeesWithProfilePicture?.company?.employees?.total ?? 0) >= // hotfix if undefined
        numberOfEmployeesToShow,
    variables: {
      id: companyId,
      first:
        numberOfEmployeesToShow -
        (employeesWithProfilePicture?.company?.employees?.edges?.length || 0),
      query: {
        consumer: QUERY_CONSUMER_CAROUSEL,
        sort: EMPLOYEES_SORTING,
        filters: {
          hasPhoto: false,
        },
      },
    },
    errorPolicy: DEFAULT_ERROR_POLICY,
  });

  const [executeNetworkRequest] = useNetworkRequestMutation();

  const loading =
    loadingEmployeesWithProfilePicture || loadingEmployeesWithoutProfilePicture;

  const error =
    !employeesWithProfilePicture &&
    !loadingEmployeesWithProfilePicture &&
    !employeesWithoutProfilePicture &&
    !loadingEmployeesWithoutProfilePicture;

  const totalEmployees =
    employeesWithProfilePicture?.company?.totalEmployees?.total;

  const addHasPhoto = (employee: EmployeeNode, hasPhoto: boolean) => ({
    ...employee,
    node: {
      hasPhoto,
      ...employee.node,
    },
  });

  const employeesListWithProfilePicture =
    employeesWithProfilePicture?.company?.employees?.edges?.map(
      (employee: $TSFixMe) => addHasPhoto(employee, true)
    ) || [];
  const employeesListWithoutProfilePicture =
    employeesWithoutProfilePicture?.company?.employees?.edges?.map(
      (employee: $TSFixMe) => addHasPhoto(employee, false)
    ) || [];

  const employeesList = [
    ...employeesListWithProfilePicture,
    ...employeesListWithoutProfilePicture,
  ];

  useNetworkRequestLoggedOutAction({
    authenticated: isLoggedIn,
    employees: employeesList,
    executeNetworkRequest,
    loading,
  });

  if (loading) {
    return (
      <>
        <ModuleHeader
          headlineCopyKey="EP_EMPLOYEES_HEADLINE"
          showMore={{
            isLoading: true,
          }}
        />
        <Styled.CarouselWrapper>
          <Carousel>
            <EmployeeCardSkeleton />
            <EmployeeCardSkeleton />
            <EmployeeCardSkeleton />
            <EmployeeCardSkeleton />
            <EmployeeCardSkeleton />
          </Carousel>
        </Styled.CarouselWrapper>
      </>
    );
  }

  if (error) {
    return (
      <RenderError
        refetch={() => {
          refetchEmployeesWithoutProfilePicture();
          refetchEmployeesWithProfilePicture();
        }}
        isEditing={isEditing}
      />
    );
  }

  if (totalEmployees === 0) {
    return (
      <>
        <ModuleHeader headlineCopyKey="EP_EMPLOYEES_HEADLINE" />
        <Styled.EmptyState>
          <FormattedMessage id="EP_EMPLOYEES_EMPTY_STATE" />
        </Styled.EmptyState>
      </>
    );
  }

  if (totalEmployees === undefined || employeesList === undefined) {
    return (
      <RenderError
        refetch={() => {
          refetchEmployeesWithProfilePicture();
          refetchEmployeesWithoutProfilePicture();
        }}
        isEditing={isEditing}
      />
    );
  }

  const link = `${pageContext?.basePath}/${pageSlug}/employees`;
  const trackingLink = getTrackingUrl({
    to: link,
    trackingKey,
  });

  const showMore =
    totalEmployees > MINIMUM_EMPLOYEES_FOR_DETAIL_LINK
      ? {
          'data-testid': 'EMPLOYEES_SHOWMORE_LINK',
          to: link,
          onClick: (ev: MouseEvent) => {
            ev.preventDefault();
            window.open(trackingLink);
          },
          copyKey: 'EP_EMPLOYEES_LINK',
          number: totalEmployees,
        }
      : null;

  return (
    <div data-testid={'EMPLOYEES_MODULE'}>
      <ModuleHeader
        headlineCopyKey="EP_EMPLOYEES_HEADLINE"
        // @ts-expect-error FIXME: SC6
        showMore={{
          ...showMore,
          isLoading:
            loadingEmployeesWithProfilePicture ||
            loadingEmployeesWithoutProfilePicture,
        }}
        externalEditInfo={
          !(
            loadingEmployeesWithProfilePicture ||
            loadingEmployeesWithoutProfilePicture
          ) && isEditing
            ? {
                copyKey: 'EP_EMPLOYEES_EDIT_HINT',
                cyTestId: 'EDIT_HINT_EMPLOYEES',
              }
            : null
        }
      />
      <EmployeesCarousel
        employeesList={employeesList}
        totalEmployees={totalEmployees}
        onAddContact={() => {
          refetchEmployeesWithProfilePicture();
          refetchEmployeesWithoutProfilePicture();
        }}
      />
    </div>
  );
};

const EmployeesContainer = ({
  companyId,
  ...rest
}: EmployeesContainerProps) => (
  <EmployeesContext.Provider value={{ companyId }}>
    <Employees companyId={companyId} {...rest} />
  </EmployeesContext.Provider>
);

export default EmployeesContainer;
