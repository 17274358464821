/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconShow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconShow"
    {...props}
  >
    <path
      fill="currentColor"
      d="m19.184 13.427 1.64 1.146c-2.015 2.884-5.275 4.636-8.824 4.636-3.545 0-6.801-1.747-8.817-4.626l1.638-1.147c1.647 2.351 4.296 3.773 7.179 3.773 2.886 0 5.538-1.425 7.184-3.782M12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6m0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2m.007-6c3.545 0 6.801 1.747 8.817 4.626l-1.639 1.147C17.54 8.421 14.89 7 12.008 7 9.121 7 6.47 8.425 4.823 10.782l-1.64-1.146C5.198 6.752 8.458 5 12.007 5"
    />
  </svg>
);
export default IconShow;
