import { Slider as SliderBase } from '@mui/material';
import styled, { css } from 'styled-components';

import {
  scale005,
  scale020,
  scale030,
  scale070,
  scale110,
  scale160,
  spaceM,
  xdlColorBackground,
  xdlColorControlFillTertiary,
  xdlColorDisabled,
  xdlColorDisabledSoft,
  xdlColorSelected,
  xdlColorText,
  xdlColorTextInvert,
  xdlColorSliderTickMark,
  xdlColorDisabledStrong,
} from '@xing-com/tokens';

type SliderTransitionalProps = {
  $marksLength?: number;
};

// *** Components ***
export const Slider = styled(SliderBase)<SliderTransitionalProps>`
  &.MuiSlider-root {
    border-radius: 0;
    color: ${xdlColorSelected};
    height: ${scale030};
    padding-bottom: ${spaceM};
    padding-top: ${spaceM};
  }

  /** Rail */
  .MuiSlider-rail {
    background-color: ${xdlColorControlFillTertiary};
  }

  /** Track */
  .MuiSlider-track {
    background-color: ${xdlColorSelected};
    border-color: ${xdlColorSelected};
  }

  .MuiSlider-thumb {
    box-shadow: none;
    height: ${scale110};
    outline: none;
    width: ${scale110};

    /** Thumb */
    &::before {
      box-shadow: none;
    }

    /** Focus Area */
    &::after {
      height: ${scale160};
      width: ${scale160};
    }

    &.Mui-focusVisible,
    &:active {
      box-shadow:
        0 0 0 ${scale020} ${xdlColorBackground},
        0 0 0 ${scale030} ${xdlColorSelected};
    }
  }

  /** Optional label over thumb */
  .MuiSlider-valueLabel {
    background-color: ${xdlColorSelected};
    color: ${xdlColorTextInvert};
    font-family:
      XING Sans,
      Trebuchet MS,
      Arial,
      Helvetica Neue,
      sans-serif;
    top: calc(${scale070} * -1);
  }

  /** Optional Label */
  .MuiSlider-markLabel {
    color: ${xdlColorText};
    font-family:
      XING Sans,
      Trebuchet MS,
      Arial,
      Helvetica Neue,
      sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.25;
    padding: 0;
    top: calc(${spaceM} + ${scale030} + ${spaceM});
  }

  /** Change alignment for first and last mark */
  [class*='MuiSlider-markLabel'][data-index='0'] {
    transform: none;
  }

  ${({ $marksLength }) =>
    $marksLength &&
    css`
      [class*='MuiSlider-markLabel'][data-index='${$marksLength}'] {
        transform: translateX(-100%);
      }
    `};

  /** Marks */
  .MuiSlider-mark {
    border-radius: 50%;
    color: ${xdlColorSliderTickMark};
    background-color: ${xdlColorSliderTickMark};
    height: ${scale020};
    width: ${scale020};
  }

  /** Change alignment for first and last mark (hack) */
  [class$='MuiSlider-mark'][data-index='0'] {
    left: calc(0% + ${scale005} + ${scale005}) !important;
  }

  ${({ $marksLength }) =>
    $marksLength &&
    css`
      [class$='MuiSlider-mark'][data-index='${$marksLength}'] {
        left: calc(100% - ${scale020} - ${scale005}) !important;
      }
    `};

  /** Disabled Styles */
  &.MuiSlider-root.Mui-disabled {
    color: ${xdlColorDisabledSoft};

    .MuiSlider-rail {
      background-color: ${xdlColorDisabledSoft};
      opacity: 1;
    }

    .MuiSlider-track {
      background-color: ${xdlColorDisabledSoft};
      border-color: ${xdlColorDisabledSoft};
    }

    .MuiSlider-thumb {
      background-color: ${xdlColorDisabledSoft};
      border-color: ${xdlColorDisabledSoft};
    }

    .MuiSlider-markLabel {
      color: ${xdlColorDisabled};
    }

    .MuiSlider-mark {
      background-color: ${xdlColorDisabledStrong};
      color: ${xdlColorDisabledStrong};
    }
  }
`;
