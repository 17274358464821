import type { FC } from 'react';

import { SkeletonBody } from '@xing-com/skeleton';

import * as Styled from './skeleton.styles';

export const AboutUsSkeleton: FC = () => (
  <>
    <Styled.ImageWrapper>
      {/* @ts-expect-error FIXME: SC6 */}
      <Styled.Image size="medium" />
    </Styled.ImageWrapper>
    <Styled.Pagination>
      <SkeletonBody amountRows={1} />
    </Styled.Pagination>
    <Styled.Description>
      <SkeletonBody amountRows={3} />
    </Styled.Description>
  </>
);
