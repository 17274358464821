import { useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { UploadContainer } from '@xing-com/crate-companies-entity-pages-common';
import { spaceM } from '@xing-com/tokens';

import { PostingsCreateVideoDocument } from '../../graphql/create-video-mutation.gql-types';
import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxFormContext } from '../../hooks/use-commbox-form-context';
import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';
import { trackAction } from '../../tracking';
import { CommboxCloseButton } from '../base-components/commbox-close-button';

export const CommboxVideoUpload: FC = () => {
  const {
    pageId,
    posting,
    setIsVideoUploadOpen,
    setAttachmentType,
    setIsAllowedPost,
  } = useCommboxContext();
  const { isEditingPosting } = useCommboxPermissions();
  const { setErrorMessage, setVideoState } = useCommboxFormContext();

  const [videoId, setVideoId] = useState<string | undefined>(
    posting?.attachments?.[0]?.video?.id ?? undefined
  );

  const [postigsCreateVideo] = useMutation(PostingsCreateVideoDocument);

  const handleAddVideo = async (file: File) => {
    if (!pageId) {
      throw new Error('PAGE_ID_NOT_PROVIDED');
    }

    const { data, errors } = await postigsCreateVideo({
      variables: {
        actorGlobalId: pageId,
        fileName: file.name,
        ownerUrn: 'urn:x-xing:postings:temporary_video',
      },
    });

    const error = data?.entityPageCreateVideoMedia?.error ?? errors;

    if (error) {
      setVideoState({ id: null, status: null });
      setErrorMessage('GENERIC_ERROR');
      throw error;
    }

    const successData = data?.entityPageCreateVideoMedia?.success;
    const uploadUrl = successData?.videoDetails?.links.uploadUrl;
    const videoId =
      successData?.videoDetails?.id ?? successData?.videoDetails?.ref;

    if (!uploadUrl || !videoId) {
      const error = new Error('VIDEO_UPLOADING_ERROR');
      setVideoState({ id: null, status: null });
      setErrorMessage('GENERIC_ERROR');
      throw error;
    }

    setVideoId(videoId);
    return { uploadUrl, videoId };
  };

  const handleOnClickCloseButton = () => {
    setVideoState({ id: null, status: null });
    setIsVideoUploadOpen(false);
    setAttachmentType('EMPTY');
  };
  const handleOnVideoUploadComplete = () => {
    setVideoState({ id: null, status: 'encodingInProgress' });
  };
  const handleOnVideoEncodingComplete = (videoId: string) => {
    setIsAllowedPost(true);
    setErrorMessage(null);
    setVideoState({ id: videoId, status: null });
    setAttachmentType('VIDEO');
  };

  useEffect(() => {
    if (posting?.attachments?.[0]?.video?.id)
      setVideoId(posting.attachments[0].video.id);
  }, [posting]);
  useEffect(() => {
    const PropTrackAction = 'social_share_commbox_video_source_open';
    trackAction({ PropTrackAction });
  }, []);

  return (
    <StyledVideoUploadSection>
      <UploadContainer
        onError={(error) => setErrorMessage(error?.message ?? error)}
        addVideo={handleAddVideo}
        onEncodingComplete={handleOnVideoEncodingComplete}
        onUploadVideoComplete={handleOnVideoUploadComplete}
        videoId={videoId}
      />
      <CommboxCloseButton
        onClick={handleOnClickCloseButton}
        isEditingPosting={isEditingPosting}
      />
    </StyledVideoUploadSection>
  );
};

const StyledVideoUploadSection = styled.section`
  margin-top: ${spaceM};
  position: relative;
`;
