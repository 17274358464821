if (typeof Element !== 'undefined' && !('remove' in Element.prototype)) {
  // @ts-expect-error FIXME: SC6
  Element.prototype.remove = function () {
    // @ts-expect-error FIXME: SC6
    if (this.parentNode) {
      // @ts-expect-error FIXME: SC6
      this.parentNode.removeChild(this);
    }
  };
}
