import styled from 'styled-components';

import { Button as OriginalButton } from '@xing-com/button';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  spaceXL,
  spaceXXL,
  xdlColorProBusinessTertiary,
  cornerRadiusL,
  scale100,
  spaceS,
  xdlColorProBusiness,
  xdlColorTextInvert,
} from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

export const PremiumWrapper = styled.div`
  background-color: ${xdlColorProBusinessTertiary};
  border-radius: ${cornerRadiusL};
  padding: ${spaceXL};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spaceXXL};

  @media ${mediaSmallOrTiny} {
    flex-direction: column;
    align-items: initial;
  }
`;

export const TextWrapper = styled.div`
  flex: 1;
  max-width: 600px;
`;

export const HeaderText = styled(Headline)`
  margin-top: ${spaceXL};
`;

export const Flag = styled.span`
  align-items: center;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  border: 2px solid transparent;
  border-radius: 2px;
  height: ${scale100};
  padding: ${spaceS};
  background-color: ${xdlColorProBusiness};
  color: ${xdlColorTextInvert};
  font-weight: bold;
  font-size: 12px;
`;

export const Button = styled(OriginalButton)`
  margin-left: ${spaceXL};
  @media ${mediaSmallOrTiny} {
    margin-top: ${spaceXL};
    margin-left: 0;
  }
`;
