import type * as React from 'react';

import { TopBar as TopBarOld } from './old/top-bar';
import type { TopBarProps as TopBarPropsOld } from './old/top-bar';
import { TopBar as TopBarCurrent } from './top-bar';
import type { TopBarProps as TopBarPropsCurrent } from './top-bar';
import type { TopBarSearchResultsProps } from './top-bar-search-results';
import { TopBarSearchResults } from './top-bar-search-results';
import { topBarTypes } from './top-bar-types';

export type TopBarProps =
  | TopBarPropsCurrent
  | TopBarPropsOld
  | TopBarSearchResultsProps;

const isSearchTopBarProps = (
  topBarProps: TopBarProps
): topBarProps is TopBarSearchResultsProps =>
  topBarProps.type === 'subheaderSearchResults';

const isCurrentTopBarProps = (
  topBarProps: TopBarProps
): topBarProps is TopBarPropsCurrent =>
  topBarProps.type
    ? (topBarTypes as ReadonlyArray<string>).includes(topBarProps.type)
    : false;

export const TopBar: React.FC<TopBarProps> = (props) => {
  if (isSearchTopBarProps(props)) {
    return <TopBarSearchResults {...props} />;
  } else if (isCurrentTopBarProps(props)) {
    return <TopBarCurrent {...props} />;
  } else {
    return <TopBarOld {...props} />;
  }
};
