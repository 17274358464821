import type { FC } from 'react';

import { renderer as boldRenderer } from '@xing-com/content-editor-plugin-bold';
import { renderer as headingRenderer } from '@xing-com/content-editor-plugin-heading';
import { linkifyDocument } from '@xing-com/content-editor-plugin-helpers';
import { renderer as italicRenderer } from '@xing-com/content-editor-plugin-italic';
import { renderer as hyperlinkRenderer } from '@xing-com/content-editor-plugin-link';
import { renderer as listItemRenderer } from '@xing-com/content-editor-plugin-list-item';
import { renderer as orderedListRenderer } from '@xing-com/content-editor-plugin-ordered-list';
import { renderer as paragraphRenderer } from '@xing-com/content-editor-plugin-paragraph';
import { renderer as unorderedListRenderer } from '@xing-com/content-editor-plugin-unordered-list';
import type { CompanyAboutUsFacts } from '@xing-com/crate-common-graphql-types';
import {
  ReadMore,
  EditButton,
  useEditContext,
  usePageContext,
} from '@xing-com/crate-companies-entity-pages-common';
import { trackAboutusDescriptionReadMoreAction } from '@xing-com/crate-companies-entity-pages-common/src/tracking';

import Facts from '../facts';
import * as Styled from './description.styles';

const customParagraphRenderer = (props: any) => paragraphRenderer({ ...props });

type DescriptionProps = {
  descriptionHeadline: string;
  descriptionArticle: string[];
  facts: CompanyAboutUsFacts;
};

export const Description: FC<DescriptionProps> = ({
  descriptionHeadline,
  descriptionArticle,
  facts,
}) => {
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};
  const pageSlug = pageContext?.pageSlug;

  return (
    <>
      <Styled.Content data-testid="aboutus-description">
        <Styled.ViewerContainer>
          <Styled.HeaderText
            fontWeight="bold"
            lineHeight="dense"
            size={'xlarge'}
            noMargin
          >
            <>
              {descriptionHeadline}
              {isEditing && pageSlug && (
                <EditButton
                  size={'small'}
                  showIcon={true}
                  to={`${pageContext?.basePath}/${pageSlug}/edit/aboutUsDescription?type=detailed`}
                  data-testid="edit-description"
                />
              )}
            </>
          </Styled.HeaderText>
          <ReadMore
            maxHeight={330}
            trackingAction={() => trackAboutusDescriptionReadMoreAction()}
          >
            <Styled.Viewer
              document={linkifyDocument(descriptionArticle)}
              renderers={[
                customParagraphRenderer,
                headingRenderer,
                boldRenderer,
                italicRenderer,
                hyperlinkRenderer,
                listItemRenderer,
                orderedListRenderer,
                unorderedListRenderer,
              ]}
            />
          </ReadMore>
        </Styled.ViewerContainer>
        <Facts facts={facts} />
      </Styled.Content>
    </>
  );
};
