/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconVideo"
    {...props}
  >
    <path
      fill="currentColor"
      d="M22 5v14H2V5zM6.068 15H4v2h2.068zm10-8h-8v10h8zM20 15h-1.932v2H20zm0-4h-1.932v2H20zM6.068 11H4v2h2.068zM20 7h-1.932v2H20zM6.068 7H4v2h2.068z"
    />
  </svg>
);
export default IconVideo;
