import type { FC } from 'react';
import { useIntl } from 'react-intl';

import * as Types from '@xing-com/crate-common-graphql-types';
import { usePageContext } from '@xing-com/crate-companies-entity-pages-common';
import { NewsCommbox } from '@xing-com/crate-companies-entity-pages-news';
import { IconNewChat } from '@xing-com/icons';
import { Menu } from '@xing-com/menu';
import { usePopOver } from '@xing-com/pop-over';

import * as Styled from './create-button.styles';

export const CreateButton: FC = () => {
  const { formatMessage } = useIntl();
  const popOver = usePopOver();

  const moreButtonClick = () => {
    popOver.handleShow();
  };

  const { pageContext } = usePageContext() ?? {};

  if (!pageContext?.pageId) {
    return null;
  }

  const feedQueryVars = {
    id: pageContext?.pageId,
    limit: 3,
    filter: Types.PostingPublicationState.Published,
  };
  const canEditLinkPreview =
    pageContext?.focusType === Types.EntityPageFocusType.Publisher;

  return (
    <>
      <NewsCommbox
        canEditLinkPreview={canEditLinkPreview}
        feedQueryVars={feedQueryVars}
        setLazyLoadingIsAllowed={() => undefined}
      >
        {(onClick: () => void) => (
          <>
            <Styled.MoreButton
              onClick={moreButtonClick}
              innerRef={popOver.triggerRef}
              size="small"
              aria-label="New Chat"
            >
              <IconNewChat width={20} height={20} />
            </Styled.MoreButton>
            <Menu
              onOutsideClick={popOver.handleHide}
              triggerRef={popOver.triggerRef}
              show={popOver.show}
            >
              <>
                <Styled.MoreMenuTitle noMargin size="small">
                  {formatMessage({
                    id: 'EP_ARMSTRONG_CREATE',
                    defaultMessage: 'EP_ARMSTRONG_CREATE',
                  })}
                </Styled.MoreMenuTitle>
                <Styled.ListWrapper>
                  <Styled.ListItem
                    onClick={() => {
                      popOver.handleHide();
                      // return body to normal overflow
                      document.body.style.overflow = 'visible';
                      onClick();
                    }}
                  >
                    <Styled.Link noMargin size={'small'}>
                      {formatMessage({ id: 'EP_ARMSTRONG_CREATE_POST' })}
                    </Styled.Link>
                  </Styled.ListItem>
                </Styled.ListWrapper>
              </>
            </Menu>
          </>
        )}
      </NewsCommbox>
    </>
  );
};
