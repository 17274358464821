import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { BodyCopy, Headline } from '@xing-com/typography';

import VideoInProgressIcon from './assets/upload.svg';
import * as Styled from './upload.styles';

type UploadInProgressProps = {
  progress: number;
};
export const UploadInProgress: FC<UploadInProgressProps> = ({ progress }) => (
  <div className={'styles.uploadContainer'}>
    <Styled.UploadArea>
      <Styled.Illustration src={VideoInProgressIcon} />
      <Styled.InProgressTitle>
        <Headline size="large" noMargin>
          <FormattedMessage id="VIDEO_UPLOAD_INPROGRESS_TITLE" />
        </Headline>
      </Styled.InProgressTitle>
      <Styled.InProgressBar>
        <Styled.InProgressBarInner style={{ width: progress * 100 + '%' }} />
      </Styled.InProgressBar>
      <Styled.InProgressText>
        <BodyCopy size="small" noMargin>
          <FormattedMessage
            id="VIDEO_UPLOAD_INPROGRESS_DESCRIPTION"
            values={{ percentage: Math.round(progress * 100) }}
          />
        </BodyCopy>
      </Styled.InProgressText>
    </Styled.UploadArea>
  </div>
);
