const LOGIN_PREVIEW_LINK = 'https://login.preview.xing.com';
const PREVIEW_HOSTNAME = 'preview.xing.com';
const LOGIN_PRODUCTION_LINK = 'https://login.xing.com';
const PRODUCTION_HOSTNAME = 'www.xing.com';
const K8S_XINGBOX_IDENTIFIER = '.kenv.xing.com';
const XINGBOX_IDENTIFIER = '.oenv.xing.com';

const getK8sXingboxName = (hostname: string): string => {
  const partsBeforeXingboxIdentifier = hostname
    .split(K8S_XINGBOX_IDENTIFIER)[0]
    .split('.');
  const lastItemIndex = partsBeforeXingboxIdentifier.length - 1;
  return partsBeforeXingboxIdentifier[lastItemIndex];
};

const getXingboxName = (hostname: string): string => {
  const partsBeforeXingboxIdentifier = hostname
    .split(XINGBOX_IDENTIFIER)[0]
    .split('.');
  const lastItemIndex = partsBeforeXingboxIdentifier.length - 1;
  return partsBeforeXingboxIdentifier[lastItemIndex];
};

const getFullLoginLink = (): string => {
  const hostname = (global.location && global.location.hostname) || '';

  if (hostname.includes('localhost')) {
    return LOGIN_PREVIEW_LINK;
  }

  if (hostname === PRODUCTION_HOSTNAME) {
    return LOGIN_PRODUCTION_LINK;
  }

  if (hostname === PREVIEW_HOSTNAME) {
    return LOGIN_PREVIEW_LINK;
  }

  if (hostname.includes(K8S_XINGBOX_IDENTIFIER)) {
    const xingboxName = getK8sXingboxName(hostname);
    return `https://login.${xingboxName}.kenv.xing.com`;
  }

  if (hostname.includes(XINGBOX_IDENTIFIER)) {
    const xingboxName = getXingboxName(hostname);
    return `https://login.${xingboxName}.oenv.xing.com`;
  }

  return LOGIN_PRODUCTION_LINK;
};

export { getFullLoginLink };
