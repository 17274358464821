import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { spaceM, spaceL, spaceXXL, xdlColorBorderSoft } from '@xing-com/tokens';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${spaceL} 0;
  border-top: 2px solid ${xdlColorBorderSoft};
  margin-top: calc(2 * ${spaceXXL});
`;

export const DiscardButton = styled(TextButton)`
  margin-right: ${spaceM};
`;
