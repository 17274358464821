import styled from 'styled-components';

import { TextButton as DefaultTextButton } from '@xing-com/button';
import * as layoutTokens from '@xing-com/layout-tokens';

export const TextButton = styled(DefaultTextButton)`
  .isArmstrong {
    display: none;

    @media ${layoutTokens.mediaWide} {
      display: flex;
    }
  }
`;
