import styled from 'styled-components';

import {
  space3XL,
  xdlColorBackgroundTertiary,
  spaceL,
  xdlColorHoverSecondary,
} from '@xing-com/tokens';

const colorCopyWeak = 'rgb(128, 128, 128)';

export const CommboxInput = styled.button`
  display: flex;
  margin-bottom: ${space3XL};
  cursor: pointer;
  position: relative;
  border: 0;
  background: transparent;
  width: 100%;
  text-align: inherit;
  padding: 0;
`;

export const TextInput = styled.div`
  border-radius: 50px;
  border: none;
  background-color: ${xdlColorBackgroundTertiary};
  outline: 0;
  padding: ${spaceL};
  flex-grow: 1;
  cursor: pointer;
  color: ${colorCopyWeak};
  &:hover {
    background-color: ${xdlColorHoverSecondary};
  }
`;
