import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  SkeletonButton,
  SkeletonImage,
  SkeletonBody,
} from '@xing-com/skeleton';
import {
  spaceM,
  spaceL,
  spaceXL,
  spaceXXL,
  scale060,
  xdlColorBorderSoft,
  xdlColorControlFillSecondary,
} from '@xing-com/tokens';

export const Navigation = styled.div`
  overflow: hidden;
`;

export const NavigationInner = styled.div`
  display: flex;
  padding-bottom: ${spaceXL};
  align-items: center;
  border-bottom: 1px solid ${xdlColorBorderSoft};
  width: 1000px;
`;

export const NavigationItem = styled(SkeletonButton)`
  width: 125px;
  margin-right: ${scale060};
`;

export const MediaItemWrapperSkeleton = styled.div`
  padding: ${spaceL};
  margin-top: ${spaceXL};
  display: flex;
  align-items: flex-start;
  background-color: ${xdlColorControlFillSecondary};

  @media ${mediaSmallOrTiny} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Image = styled(SkeletonImage)`
  width: 110px;
  height: 83px;
  margin-right: ${spaceL};
  flex-shrink: 0;
`;

export const Body = styled(SkeletonBody)`
  width: 50%;
  flex-shrink: 0;

  @media ${mediaSmallOrTiny} {
    order: 2;
    flex-basis: 100%;
    width: auto;
    margin-top: ${spaceM};
  }
`;

export const ItemActionsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media ${mediaSmallOrTiny} {
    width: auto;
    flex: 1;
  }
`;

export const ItemActionsButton = styled(SkeletonButton)`
  width: 32px;

  &:first-child {
    margin-right: ${spaceL};
  }
`;

export const GalleryActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${spaceXXL} 0 calc(2 * ${spaceXXL}) 0;
`;

export const PublishButtonSkeleton = styled(SkeletonButton)`
  width: 125px;
`;
