import type * as React from 'react';
import useInView from 'react-cool-inview';
import 'intersection-observer';

import * as Styled from './item.styles';
const { itemSnapPositions } = Styled;

export type ItemSnapPosition = keyof typeof itemSnapPositions;

export type ItemProps = {
  /** Content of the carousel item */
  children: React.ReactNode;
  /** Maximum width of the carousel items */
  itemIndex: number;
  /** Maximum width of the carousel items */
  itemMaxWidth?: string;
  /** Minimum width of the carousel items */
  itemMinWidth?: string;
  /** Activate carousel item snapping and select snap position */
  itemSnapPosition?: ItemSnapPosition;
  /** Spacing between carousel items */
  itemSpacing?: string;
  /** Spacing between carousel items on tablet screens or larger */
  itemSpacingConfined?: string;
  /** Spacing between carousel items on desktop screens or larger */
  itemSpacingWide?: string;
  /** Width of the carousel items */
  itemWidth?: string;
  /** Width of the carousel items on tablet screens or larger */
  itemWidthConfined?: string;
  /** Width of the carousel items on desktop screens or larger */
  itemWidthWide?: string;
  /** @ignore */
  lastItemSpace?: string;
  /** @ignore */
  lastItemSpaceConfined?: string;
  /** @ignore */
  lastItemSpaceWide?: string;
  /** Allow Item to be focusable */
  tabbable?: boolean;
  onEnter: (direction: string | undefined, itemIndex: number) => void;
};

export const Item: React.FC<ItemProps> = ({
  children,
  itemIndex,
  itemMaxWidth,
  itemMinWidth,
  itemSnapPosition,
  itemSpacing,
  itemSpacingConfined,
  itemSpacingWide,
  itemWidth,
  itemWidthConfined,
  itemWidthWide,
  lastItemSpace,
  lastItemSpaceConfined,
  lastItemSpaceWide,
  tabbable = false,
  onEnter,
}) => {
  const { observe } = useInView<HTMLDivElement>({
    threshold: 0.5,
    onEnter: ({ scrollDirection }) => {
      onEnter(scrollDirection.horizontal, itemIndex);
    },
  });

  return (
    <Styled.Item
      $itemMaxWidth={itemMaxWidth}
      $itemMinWidth={itemMinWidth}
      $itemSnapPosition={itemSnapPosition}
      $itemSpacing={itemSpacing}
      $itemSpacingConfined={itemSpacingConfined}
      $itemSpacingWide={itemSpacingWide}
      $itemWidth={itemWidth}
      $itemWidthConfined={itemWidthConfined}
      $itemWidthWide={itemWidthWide}
      $lastItemSpace={lastItemSpace}
      $lastItemSpaceConfined={lastItemSpaceConfined}
      $lastItemSpaceWide={lastItemSpaceWide}
      ref={observe}
      tabIndex={tabbable ? 0 : -1}
    >
      {children}
    </Styled.Item>
  );
};

Item.displayName = 'Item';
