import type { MSW, Xinglet } from '@xing-com/crate-xinglet';

import { App } from './app';

export default class CompaniesFrontend implements Xinglet {
  async registerMocks(msw: MSW): Promise<void> {
    (await import('./mocks')).setupMocking(msw);
  }
  public getComponent() {
    return (): JSX.Element => <App />;
  }
}
