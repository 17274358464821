import { SkeletonProfileImage, SkeletonBody } from '@xing-com/skeleton';

import * as Styled from './affiliates.styles';

const AffiliatesItemSkeleton = () => (
  <Styled.SkeletonWrapper>
    <Styled.SkeletonProfileImage>
      <SkeletonProfileImage size="medium" />
    </Styled.SkeletonProfileImage>
    <Styled.SkeletonProfileInfo>
      <SkeletonBody amountRows={2} />
    </Styled.SkeletonProfileInfo>
    <Styled.SkeletonButton />
  </Styled.SkeletonWrapper>
);

export default AffiliatesItemSkeleton;
