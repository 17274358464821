import styled from 'styled-components';

import {
  FormField as DefaultFormField,
  Select as DefaultSelect,
} from '@xing-com/text-field';
import { spaceXXL } from '@xing-com/tokens';

export const Select = styled(DefaultSelect)`
  margin-top: ${spaceXXL};
`;
export const FormField = styled(DefaultFormField)`
  margin-top: ${spaceXXL};
`;
