import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';

import { IconCross } from '@xing-com/icons';
import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import {
  scale005,
  scale040,
  scale070,
  scale080,
  scale090,
  scale100,
  scale130,
  scale150,
  scale160,
  scale180,
  spaceL,
  spaceS,
  xdlColorBackground,
  xdlColorBackgroundTertiary,
  xdlColorControlBorder,
  xdlColorDisabledSoft,
  xdlColorDisabledStrong,
  xdlColorHoverSecondary,
  xdlColorText,
  xdlColorTextTertiary,
  xdlColorBorderStrong,
} from '@xing-com/tokens';

import type { InputBarSize, InputBarVariant } from './input-bar.types';

type TextareaTProps = {
  $isFilled: boolean;
  $size?: InputBarSize;
  $sizeConfined?: InputBarSize;
  $sizeWide?: InputBarSize;
  $variant?: InputBarVariant;
};

type InputTProps = {
  $iconSize: number;
  $isFilled: boolean;
  $isIconSet?: boolean;
  $showClearButton: boolean;
  $size?: InputBarSize;
  $sizeConfined?: InputBarSize;
  $sizeWide?: InputBarSize;
  $variant?: InputBarVariant;
};

type IconContainerTProps = {
  $iconSize?: 18 | 24;
  $size?: InputBarSize;
  $sizeConfined?: InputBarSize;
  $sizeWide?: InputBarSize;
  $variant?: InputBarVariant;
};

type ClearButtonTProps = {
  $variant?: InputBarVariant;
};

export const Wrapper = styled.div`
  position: relative;
`;

// *** Base Styles ***
const baseStyles = css`
  appearance: none;
  display: block;
  line-height: 1.5;
  resize: none;
  width: 100%;
`;

// *** Variants ***
export const variants = {
  default: {
    base: css`
      background-color: ${xdlColorBackgroundTertiary};
      border-color: transparent;
      border-style: solid;
      border-width: ${scale005};
      color: ${xdlColorTextTertiary};
      outline: none;

      &:focus {
        background-color: ${xdlColorBackground};
        border-color: ${xdlColorControlBorder};
      }

      &:hover {
        background-color: ${xdlColorHoverSecondary};
        border-color: ${xdlColorHoverSecondary};
      }

      &::placeholder {
        color: ${xdlColorTextTertiary};
        font-size: ${scale070};
      }

      &:-ms-input-placeholder {
        color: ${xdlColorTextTertiary};
        font-size: ${scale070};
      }

      &[disabled] {
        background-color: ${xdlColorDisabledSoft};
        color: ${xdlColorDisabledStrong};

        &::placeholder {
          color: ${xdlColorDisabledStrong};
        }

        &:-ms-input-placeholder {
          color: ${xdlColorDisabledStrong};
        }
      }
    `,
    filled: css`
      background-color: ${xdlColorBackground};
      border-color: ${xdlColorControlBorder};
      border-style: solid;
      border-width: ${scale005};
      color: ${xdlColorText};

      &[disabled] {
        background-color: ${xdlColorBackground};
        border-color: ${xdlColorDisabledSoft};
        color: ${xdlColorDisabledStrong};
      }
    `,
  },
  plain: {
    base: css`
      background-color: ${xdlColorBackground};
      border-color: transparent;
      border-style: solid;
      border-width: ${scale005};
      color: ${xdlColorTextTertiary};
      outline: none;

      &:focus {
        background-color: ${xdlColorBackground};
        border-color: ${xdlColorBorderStrong};
        border-width: ${scale005};
        box-shadow: inset 0 0 0 ${scale005} ${xdlColorBorderStrong};
        color: ${xdlColorText};
      }

      &:hover {
        background-color: ${xdlColorBackground};
        border-color: ${xdlColorBorderStrong};
        border-width: ${scale005};
        color: ${xdlColorText};
      }

      &::placeholder {
        color: ${xdlColorTextTertiary};
        font-size: ${scale070};
      }

      &:-ms-input-placeholder {
        color: ${xdlColorTextTertiary};
        font-size: ${scale070};
      }

      &[disabled] {
        background-color: ${xdlColorDisabledSoft};
        color: ${xdlColorDisabledStrong};

        &::placeholder {
          color: ${xdlColorDisabledStrong};
        }

        &:-ms-input-placeholder {
          color: ${xdlColorDisabledStrong};
        }
      }
    `,
    filled: css`
      background-color: ${xdlColorBackground};
      color: ${xdlColorText};

      &[disabled] {
        background-color: ${xdlColorBackground};
        color: ${xdlColorDisabledStrong};
      }
    `,
  },
};

/** Icons */
export const icons = {
  default: {
    leadingIcon: css`
      color: ${xdlColorText};

      [disabled] + & {
        color: ${xdlColorDisabledStrong};
      }
    `,
    trailingIcon: css`
      color: ${xdlColorText};

      &[disabled] {
        color: ${xdlColorDisabledStrong};
      }
    `,
  },
  plain: {
    leadingIcon: css`
      color: ${xdlColorText};

      [disabled] + & {
        color: ${xdlColorDisabledStrong};
      }
    `,
    trailingIcon: css`
      color: ${xdlColorText};

      &[disabled] {
        color: ${xdlColorDisabledStrong};
      }
    `,
  },
};

// *** Sizes ***
/** Fake paddings
 *  Fixed pixel values are used on each to keep the height
 *  on the textarea multiline which can’t have a fixed height. */
export const sizes = {
  small: {
    input: css`
      border-radius: calc(${scale130} / 2);
      font-size: ${scale070};
      height: ${scale130};
      padding-block-end: 5px;
      padding-block-start: 5px;
      padding-inline-end: ${spaceL};
      padding-inline-start: ${spaceL};

      &::placeholder {
        font-size: ${scale070};
        font-weight: 500;
      }
    `,
    iconContainer: css`
      inset-inline-start: ${scale080};
    `,
    isIconSet: css`
      padding-inline-start: calc(${spaceL} + 18px + ${spaceS});
    `,
    showClearButton: css`
      padding-inline-end: calc(32px + ${spaceS});
    `,
  },
  medium: {
    input: css`
      border-radius: calc(${scale150} / 2);
      font-size: ${scale080};
      height: ${scale150};
      padding-block-end: 10px;
      padding-block-start: 10px;
      padding-inline-end: ${scale090};
      padding-inline-start: ${scale090};

      &::placeholder {
        font-size: ${scale080};
        font-weight: 500;
      }
    `,
    iconContainer: css`
      inset-inline-start: ${scale090};
    `,
    isIconSet: css`
      padding-inline-start: calc(${scale090} + 24px + ${spaceS});
    `,
    showClearButton: css`
      padding-inline-end: calc(42px + ${spaceS});
    `,
  },
  large: {
    input: css`
      border-radius: calc(${scale160} / 2);
      font-size: ${scale080};
      height: ${scale160};
      padding-block-end: 13px;
      padding-block-start: 13px;
      padding-inline-end: ${scale100};
      padding-inline-start: ${scale100};

      &::placeholder {
        font-size: ${scale080};
        font-weight: 500;
      }
    `,
    iconContainer: css`
      inset-inline-start: ${scale100};
    `,
    isIconSet: css`
      padding-inline-start: calc(${scale100} + 24px + ${spaceS});
    `,
    showClearButton: css`
      padding-inline-end: calc(48px + ${spaceS});
    `,
  },
  xlarge: {
    input: css`
      border-radius: calc(${scale180} / 2);
      font-size: ${scale090};
      height: ${scale180};
      padding-block-start: 19px;
      padding-block-end: 19px;
      padding-inline-end: ${scale100};
      padding-inline-start: ${scale100};

      &::placeholder {
        font-size: ${scale090};
        font-weight: 500;
      }
    `,
    iconContainer: css`
      inset-inline-start: ${scale100};
    `,
    isIconSet: css`
      padding-inline-start: calc(${scale100} + 24px + ${spaceS});
    `,
    showClearButton: css`
      padding-inline-end: calc(48px + ${spaceS});
    `,
  },
};

// *** Dynamic Styles ***
const commonPropsStyles = css<InputTProps | TextareaTProps>`
  ${(props) => css`
    ${props.$variant &&
    css`
      ${variants[props.$variant].base};
      ${props.$isFilled && variants[props.$variant].filled};
    `};

    ${props.$size &&
    css`
      ${sizes[props.$size].input};
    `};

    ${props.$sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[props.$sizeConfined].input};
      }
    `};

    ${props.$sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[props.$sizeWide].input};
      }
    `};
  `};
`;

const inputPropsStyles = css<InputTProps>`
  ${(props) => css`
    ${props.$size &&
    css`
      ${props.$isIconSet && sizes[props.$size].isIconSet};
      ${props.$showClearButton && sizes[props.$size].showClearButton};
    `};

    ${props.$sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${props.$isIconSet && sizes[props.$sizeConfined].isIconSet};
      }
    `};

    ${props.$sizeWide &&
    css`
      @media ${mediaWide} {
        ${props.$isIconSet && sizes[props.$sizeWide].isIconSet};
      }
    `};
  `};
`;

export const Input = styled.input<InputTProps>`
  ${baseStyles};
  ${commonPropsStyles};
  ${inputPropsStyles};
`;

export const Textarea = styled(TextareaAutosize)<TextareaTProps>`
  ${baseStyles};
  ${commonPropsStyles};
  height: auto;
  resize: none;
  width: 100%;
`;

// *** Leading Icon ***
export const IconContainer = styled.span<IconContainerTProps>`
  color: ${xdlColorText};
  inset-block-start: 50%;
  margin-inline-end: ${scale040};
  position: absolute;
  transform: translateY(-50%);

  ${(props) => css`
    ${props.$iconSize &&
    css`
      height: ${props.$iconSize}px;
      width: ${props.$iconSize}px;
    `};

    ${props.$variant && icons[props.$variant].leadingIcon};

    ${props.$size && sizes[props.$size].iconContainer};

    ${props.$sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[props.$sizeConfined].iconContainer};
      }
    `};

    ${props.$sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[props.$sizeWide].iconContainer};
      }
    `};
  `};
`;

// *** Trailing Icon (Clear) ***
export const ClearButton = styled.button<ClearButtonTProps>`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  inset-block-start: 0;
  inset-inline-end: ${spaceS};
  justify-content: center;
  padding: calc(${spaceS} - 1px);
  position: absolute;
  ${(props) => props.$variant && icons[props.$variant].trailingIcon};
`;

export const Cross = styled(IconCross)`
  align-items: center;
  display: flex;
  justify-content: center;
`;
