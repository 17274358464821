import styles from 'styled-components';

import * as layoutTokens from '@xing-com/layout-tokens';
import * as tokens from '@xing-com/tokens';
import {
  Row as DefaultRow,
  Column as DefaultColumn,
} from '@xing-com/xing-grid';

export const PageWrapper = styles.div`
  padding-bottom: ${tokens.scale220};
  position: relative;
`;

export const GridWrapper = styles.div.attrs({
  className: 'grid',
})`
  position: relative;

  @media ${layoutTokens.mediaConfined} {
    &:not(.isWebview) {
      & .row {
        padding: 0;
      }
      & .column {
        padding: 0;
      }
    }
  }
`;
export const Row = styles(DefaultRow).attrs({
  className: 'row',
})``;
type ColumnProps = {
  isModuleContainer?: boolean;
};
export const Column = styles(DefaultColumn).attrs({
  className: 'column',
})<ColumnProps>`
 min-height: ${(props) => (props.isModuleContainer ? '60vh' : 'auto')}
`;

export const HeaderWrapper = styles.div.attrs({
  className: 'headerWrapper',
})`
  position: relative;
  margin-bottom: ${tokens.spaceXXL};
`;

export const EditBarSubpage = styles.div.attrs({
  className: 'editBarSubpage',
})`
  @media ${layoutTokens.mediaConfined} {
    padding-bottom: ${tokens.spaceL};
  }
`;
