import { useQuery } from '@apollo/client';
import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { StatusBanner } from '@xing-com/banner';
import { WarningMessageBox as Error } from '@xing-com/crate-companies-entity-pages-common';
import type { FlagProps, FlagVariant } from '@xing-com/flag';
import { BodyCopy } from '@xing-com/typography';
import { Row } from '@xing-com/xing-grid';

import { MembersSearchDocument } from '../../../graphql/members-search-query.gql-types';
import type { XingIdContactsFragment } from '../../../graphql/xing-id-contacts-fragment.gql-types';
import * as Styled from './show-more-results.styles';
import Skeleton from './skeleton';

type ShowMoreResultsProps = {
  text?: string;
  handleAddContact: (user: XingIdContactsFragment) => void;
  contactIdAdded: string;
  setContactIdAdded?: React.Dispatch<React.SetStateAction<string>>;
};

const ShowMoreResults = ({
  handleAddContact,
  contactIdAdded,
  text,
}: ShowMoreResultsProps) => {
  const intl = useIntl();
  const { data, loading, refetch, error } = useQuery(MembersSearchDocument, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 50,
      query: {
        consumer: 'loggedin.web.entitypages.search.react.search-results',
        queries: {
          name: text,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return (
      <Row>
        <Styled.Column size={12} sizeDesktop={5} sizeTablet={5}>
          <>
            <Styled.Headline size={'large'}>
              <FormattedMessage
                id={'EP_RESULT_LIST_HEADLINE'}
                defaultMessage={'EP_RESULT_LIST_HEADLINE'}
              />
            </Styled.Headline>
            <Skeleton numberOfItems={2} />
          </>
        </Styled.Column>
      </Row>
    );
  }

  const users = data?.viewer?.membersSearch?.edges
    ?.map((edge) => edge.node)
    .filter(
      (user) =>
        user?.__typename === 'MembersSearchUnfencedItem' && !!user.xingId
    );
  const isUserPremium = data?.viewer?.features?.isPremium;

  if (error || !users || users.length === 0) {
    return (
      <Row>
        <Styled.Column size={12} sizeDesktop={5} sizeTablet={5}>
          <>
            <Styled.Headline size={'large'}>
              <FormattedMessage
                id={'EP_RESULT_LIST_HEADLINE'}
                defaultMessage={'EP_RESULT_LIST_HEADLINE'}
              />
            </Styled.Headline>
            <div data-testid={'errorContainer'}>
              <Error
                headerText="EP_ERROR_HEADER"
                bodyText="EP_ERROR_BODY"
                buttonText="EP_ERROR_RELOAD_CTA"
                onClick={() => refetch()}
              />
            </div>
          </>
        </Styled.Column>
      </Row>
    );
  }

  return (
    <Row>
      <Styled.Column size={12} sizeDesktop={5} sizeTablet={5}>
        <Styled.Headline size={'large'}>
          <FormattedMessage
            id={'EP_RESULT_LIST_HEADLINE'}
            defaultMessage={'EP_RESULT_LIST_HEADLINE'}
          />
        </Styled.Headline>
        <Styled.List>
          {users.map((user) => {
            if (user?.__typename !== 'MembersSearchUnfencedItem') {
              return;
            }

            const flag: FlagProps | undefined =
              user.xingId?.userFlags?.displayFlag &&
              user.xingId?.userFlags?.displayFlag !== 'BASIC' &&
              user.xingId?.userFlags?.displayFlag !== 'EXECUTIVE'
                ? {
                    size: 'small',
                    variant:
                      user.xingId.userFlags.displayFlag.toLowerCase() as FlagVariant,
                  }
                : undefined;

            return (
              <Styled.ListItem key={user.xingId?.pageName}>
                <Styled.ProfileInfo
                  key={user.xingId?.id}
                  profileUrl={`/profile/${user.xingId?.pageName}`}
                  profileImage={{
                    size: 'small',
                    src: user.xingId?.profileImage?.[0]
                      ? user.xingId?.profileImage[0].url
                      : undefined,
                    profileName: 'user profile image',
                  }}
                  headline={{
                    size: 'small',
                    children: user.xingId?.displayName,
                    as: 'strong',
                  }}
                  flag={flag}
                  textBody={[
                    {
                      size: 'small',
                      children: user.xingId?.occupations?.[0]?.headline,
                    },
                  ]}
                  buttons={[
                    {
                      variant: 'primary',
                      children: intl.formatMessage({
                        id: 'EP_ADD_CTA',
                        defaultMessage: 'EP_ADD_CTA',
                      }),
                      // @ts-expect-error FIXME: SC6
                      'data-testid': 'ADD_CONTACT_BUTTON',
                      loading:
                        !!contactIdAdded && contactIdAdded === user.xingId?.id,
                      disabled:
                        !!contactIdAdded && contactIdAdded !== user.xingId?.id,
                      size: 'small',
                      onClick: () =>
                        user?.xingId && handleAddContact(user?.xingId),
                    },
                  ]}
                />
              </Styled.ListItem>
            );
          })}
        </Styled.List>
        {!isUserPremium && (
          <StatusBanner variant="info" display="inline">
            <BodyCopy size={'small'}>
              <span>
                <FormattedMessage
                  id={'EP_RESULT_LIST_CONTACT_V2'}
                  defaultMessage={'EP_RESULT_LIST_CONTACT_V2'}
                  values={{
                    a: (b) => <a href={'mailto:' + b}>{b}</a>,
                  }}
                />
              </span>
            </BodyCopy>
          </StatusBanner>
        )}
      </Styled.Column>
    </Row>
  );
};

export default ShowMoreResults;
