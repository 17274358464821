import * as React from 'react';

import devNote from '@xing-com/dev-note';

import { AddTag } from './add-tag';
import { DefaultTag } from './default-tag';
import { DeleteTag } from './delete-tag';
import { InteractiveTag } from './interactive-tag';
import { PlaceholderTag } from './placeholder-tag';
import type { TagProps } from './tag.types';

export const Tag = React.forwardRef<HTMLDivElement, TagProps>(
  (
    {
      badgeProps,
      children,
      className,
      disabled,
      fontWeight,
      hideBorder,
      leadingIcon,
      maxWidth = '330px',
      onClick,
      onDelete,
      profile,
      selected,
      trailingIcon,
      value,
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    // TODO: Remove variant property
    if (variant === 'placeholder') {
      devNote.deprecate(
        "The 'placeholder' variant is deprecated. Please use the PlaceholderTag component instead."
      );
    }

    if (variant === 'add') {
      devNote.deprecate(
        "The 'add' variant is deprecated. Please use the AddTag component instead."
      );
    }

    if (variant === 'delete') {
      devNote.deprecate(
        "The 'delete' variant is deprecated. Please use the DeleteTag component instead."
      );
    }

    const isInteractive = Boolean(onClick);

    const commonProps = {
      children,
      className,
      fontWeight,
      maxWidth,
      profile,
      ref: forwardedRef,
    };

    const borderProps = {
      hideBorder,
    };

    const iconProps = {
      leadingIcon,
      trailingIcon,
    };

    const interactiveProps = {
      disabled,
      onClick,
      selected,
      value,
    };

    return (
      <React.Fragment>
        {variant === 'placeholder' ? (
          <PlaceholderTag {...commonProps} {...iconProps} {...props} />
        ) : variant === 'add' ? (
          <AddTag {...commonProps} {...interactiveProps} {...props} />
        ) : variant === 'delete' ? (
          <DeleteTag
            onDelete={onDelete}
            {...commonProps}
            {...interactiveProps}
            {...props}
          />
        ) : isInteractive ? (
          <InteractiveTag
            badgeProps={badgeProps}
            {...commonProps}
            {...borderProps}
            {...iconProps}
            {...interactiveProps}
            {...props}
          />
        ) : (
          <DefaultTag
            {...commonProps}
            {...borderProps}
            {...iconProps}
            {...props}
          />
        )}
      </React.Fragment>
    );
  }
);

Tag.displayName = 'Tag';
