import type { CompanyAboutUsFacts } from '@xing-com/crate-common-graphql-types';

import Facts from './facts';
import getCommonFactsItem from './get-common-facts-item';

type FactsPublisherProps = {
  facts: CompanyAboutUsFacts;
};

const FactsPublisher = ({ facts }: FactsPublisherProps) => {
  const items: any[] = [];
  const categories: string[] = [];

  if (facts.foundingYear) {
    items.push(getCommonFactsItem('foundingYear', facts.foundingYear));
  }

  return (
    <Facts
      headlineKey="EP_COMPANY_SUMMARY"
      items={items}
      categoriesHeadlineKey="EP_COMPANY_INDUSTRY"
      categories={categories}
      facts={facts}
    />
  );
};

export default FactsPublisher;
