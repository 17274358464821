import * as React from 'react';

import { Styled } from './character-counter.styles';
import type { CharacterCounterProps } from './character-counter.types';

export const CharacterCounter = React.forwardRef<
  HTMLParagraphElement,
  CharacterCounterProps
>(
  ({ currentCount, maxCount }, forwardedRef): JSX.Element => (
    <Styled.CharacterCounter
      $maxCountReached={currentCount > maxCount}
      ref={forwardedRef}
      size="xsmall"
    >
      {currentCount}/{maxCount}
    </Styled.CharacterCounter>
  )
);

CharacterCounter.displayName = 'CharacterCounter';
