import styled from 'styled-components';

import { EditButton } from '@xing-com/crate-companies-entity-pages-common';
import {
  scale030,
  spaceL,
  spaceS,
  xdlColorHover,
  xdlPaletteGrayCool08,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const LogoEditButton = styled(EditButton)`
  display: none;
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 10;

  @media (min-width: 1113px) {
    display: flex;
  }
`;

export const ListWrapper = styled.div`
  min-width: 150px;
  cursor: pointer;

  @media (prefered-color-scheme: light) {
    color: ${xdlPaletteGrayCool08};
  }

  > div {
    padding: ${spaceS} ${spaceL};
  }
`;

export const Link = styled(BodyCopy)`
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  padding: ${scale030};
`;

export const ListItem = styled.div`
  border-bottom: 0;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${xdlColorHover};
  }
`;
