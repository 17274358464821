import { useMemo } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import type { ReactEditor } from 'slate-react';
import { withReact } from 'slate-react';

import { withLinks } from '@xing-com/content-editor-plugin-link';
import { withLists } from '@xing-com/content-editor-plugin-list-item';

import { withArticleLayout } from './with-article-layout';

const useEditor = (useTitle = true) => {
  const editor = useMemo(
    () =>
      withArticleLayout(
        withLists(
          withLinks(withHistory(withReact(createEditor() as ReactEditor)))
        ),
        useTitle
      ),
    []
  );

  return editor;
};

export default useEditor;
