import type * as React from 'react';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { IconCross } from '@xing-com/icons';
import { spaceS } from '@xing-com/tokens';

export type CommboxCloseButtonType = {
  isEditingPosting: boolean;
  onClick: () => void;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

export const CommboxCloseButton: React.FC<CommboxCloseButtonType> = ({
  isEditingPosting,
  onClick,
  icon = IconCross,
}) =>
  !isEditingPosting ? (
    <StyledCloseButton {...{ onClick, icon }} size="small" variant="overlay" />
  ) : null;

const StyledCloseButton = styled(Button)`
  position: absolute;
  right: ${spaceS};
  top: ${spaceS};
`;
