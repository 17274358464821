import type * as React from 'react';

import type { ProfileImageProps } from '@xing-com/profile-image';

import * as Styled from './facepile.styles';
const { sizes } = Styled;

export type FacepileSize = keyof typeof sizes;
export type FacepileVariant = 'condensed' | undefined;

export type FacepileActorProps = {
  id: number | string;
  href?: string;
  to?: string;
} & ProfileImageProps;

export type FacepileProps = {
  /** ProfileImage properties and id (required), href (optional), or to (optional) */
  actors: FacepileActorProps[];
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Add custom CSS */
  className?: string;
  /** Define the number of not shown items */
  counter?: number;
  /** If the component is fenced, it will show the upsell and link the images to the upsell page. Fencing restricts the number of shown users to 5 */
  fenced?: boolean;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLDivElement>;
  /** The maximum of the actors. You have to adjust the number depending on the screen size */
  maxActors?: number;
  /** Add a link for counter */
  moreHref?: string;
  /** Add a router link for the counter */
  moreTo?: string;
  /** The size on mobile screens or larger */
  size: FacepileSize;
  /** The size on tablet screens or larger */
  sizeConfined?: FacepileSize;
  /** The size on desktop screens or larger */
  sizeWide?: FacepileSize;
  /** Add upsell link */
  upsellHref?: string;
  /** Add upsell router link */
  upsellTo?: string;
  /** The variant of facepile */
  variant?: FacepileVariant;
} & React.HTMLAttributes<HTMLDivElement>;
