import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useEffect, useState } from 'react';

import { articleSerializer } from '@xing-com/content-editor-serializer';

import { DEFAULT_SLATE_MESSAGE } from '../constants';
import { useCommboxContext } from '../hooks/use-commbox';
import { useCommboxPermissions } from '../hooks/use-commbox-permissions';
import type {
  CommboxErrorMessageType,
  CommboxImagesType,
  CommboxVideoStateType,
} from '../types';

type CommboxFormContextValues = {
  imageIsUploading: boolean;
  images: CommboxImagesType[];
  isPreviewCompleted: boolean;
  linkShareUrl: string | null;
  plainTextMessage: string;
  selectedActorGlobalId: string;
  slateMessage: any;
  uploadedFileId: string | null;
  videoState: CommboxVideoStateType;
  errorMessage: CommboxErrorMessageType;
  imagePreviewSrc: string;
  infoMessage: string | null;
  setErrorMessage: Dispatch<SetStateAction<CommboxErrorMessageType>>;
  setImageIsUploading: Dispatch<SetStateAction<boolean>>;
  setImagePreviewSrc: Dispatch<SetStateAction<string>>;
  setImages: Dispatch<SetStateAction<CommboxImagesType[]>>;
  setInfoMessage: Dispatch<SetStateAction<string | null>>;
  setIsPreviewCompleted: Dispatch<SetStateAction<boolean>>;
  setLinkShareUrl: Dispatch<SetStateAction<string>>;
  setPlainTextMessage: Dispatch<SetStateAction<string>>;
  setSlateMessage: Dispatch<SetStateAction<any>>;
  setUploadedFileId: Dispatch<SetStateAction<string | null>>;
  setVideoState: Dispatch<SetStateAction<CommboxVideoStateType>>;
  setSelectedActorGlobalId: Dispatch<SetStateAction<string>>;
};
export const CommboxFormContext = createContext<
  CommboxFormContextValues | undefined
>(undefined);

const getDefaultSlateMessage = (isEditingPosting: boolean, posting: any) => {
  if (!isEditingPosting) {
    return DEFAULT_SLATE_MESSAGE;
  } else if (posting?.commentArticleV1?.length) {
    return articleSerializer.deserialize(posting.commentArticleV1);
  } else {
    return [
      {
        type: 'paragraph',
        children: [{ text: posting?.comment || '' }],
      },
    ];
  }
};

export const CommboxFormContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    actors,
    posting,
    setActorType,
    setAttachmentType,
    setIsVideoUploadOpen,
    setPollData,
  } = useCommboxContext();
  const { isEditingPosting } = useCommboxPermissions();

  const [plainTextMessage, setPlainTextMessage] = useState(
    isEditingPosting ? (posting?.comment ?? '') : ''
  );
  const [slateMessage, setSlateMessage] = useState<any>(
    getDefaultSlateMessage(isEditingPosting, posting)
  );
  const [selectedActorGlobalId, setSelectedActorGlobalId] = useState(
    posting?.actorGlobalId || actors[0]?.globalId
  );
  const [linkShareUrl, setLinkShareUrl] = useState<string>('');
  const [errorMessage, setErrorMessage] =
    useState<CommboxErrorMessageType>(null);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [imageIsUploading, setImageIsUploading] = useState(false);
  const [images, setImages] = useState<CommboxImagesType[]>([]);
  const [imagePreviewSrc, setImagePreviewSrc] = useState('');
  const [videoState, setVideoState] = useState<CommboxVideoStateType>({
    id: null,
    status: null,
  });
  const [isPreviewCompleted, setIsPreviewCompleted] = useState(false);
  const [uploadedFileId, setUploadedFileId] = useState<string | null>(null);

  useEffect(() => {
    if (isEditingPosting) {
      if (posting?.attachments?.[0]?.__typename === 'PostingsLinkAttachment') {
        setAttachmentType('LINK_PREVIEW');
        setLinkShareUrl(posting.attachments[0].link.url);
      }
      if (posting?.attachments?.[0]?.__typename === 'PostingsImageAttachment') {
        setAttachmentType('IMAGE');
        setImagePreviewSrc('true');
        // @ts-expect-error Commbox will be removed in Q2
        setImages(posting.attachments.map(({ images }) => images[0]));
      }
      if (posting?.attachments?.[0]?.__typename === 'PostingsVideoAttachment') {
        setAttachmentType('VIDEO');
        setIsVideoUploadOpen(true);
        setVideoState({ ...videoState, id: posting.attachments[0].video.id });
      }
      if (posting?.attachments?.[0]?.__typename === 'PostingsPollAttachment') {
        const { isClosed, options, question, secondsLeft, votes } =
          posting.attachments[0];
        setAttachmentType('POLLS');
        setPollData({ isClosed, options, question, secondsLeft, votes });
      }
    }

    setActorType(actors[0].type);
  }, []);

  return (
    <CommboxFormContext.Provider
      value={{
        errorMessage,
        imageIsUploading,
        imagePreviewSrc,
        images,
        linkShareUrl,
        plainTextMessage,
        slateMessage,
        isPreviewCompleted,
        selectedActorGlobalId,
        uploadedFileId,
        videoState,
        infoMessage,
        setErrorMessage,
        setImageIsUploading,
        setImagePreviewSrc,
        setImages,
        setInfoMessage,
        setIsPreviewCompleted,
        setLinkShareUrl,
        setPlainTextMessage,
        setSlateMessage,
        setUploadedFileId,
        setVideoState,
        setSelectedActorGlobalId,
      }}
    >
      {children}
    </CommboxFormContext.Provider>
  );
};
