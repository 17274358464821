import styled, { css } from 'styled-components';

import { scale050, spaceL, spaceXS } from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

// *** Base ***
const baseStyledHeadline = css`
  margin-bottom: ${spaceXS};
  margin-top: ${scale050};
  text-align: center;
`;

const baseStyledBodyCopy = css`
  margin-bottom: ${spaceL};
  text-align: center;
`;

// *** Components ***
const StyledHeadline = styled(Headline)`
  ${baseStyledHeadline}
`;

const StyledBodyCopy = styled(BodyCopy)`
  ${baseStyledBodyCopy}
`;

export const Styled = {
  Headline: StyledHeadline,
  BodyCopy: StyledBodyCopy,
};
