import styled from 'styled-components';

import { SkeletonImage } from '@xing-com/skeleton';

export const ImageWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;

  @media (max-width: 450px) {
    position: relative;
    padding-top: 0;
    width: 100%;
  }
`;

export const Image = styled(SkeletonImage)`
  height: 100%;
  object-fit: cover;
  position: absolute;
  max-width: 100%;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media (max-width: 450px) {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: calc(100vh - 100px - 68px);
  }
`;

export const Pagination = styled.div`
  display: block;
  margin: 20px 0;
  width: 150px;

  @media (max-width: 450px) {
    display: none;
  }
`;

export const Description = styled.div`
  display: none;

  @media (max-width: 450px) {
    margin-top: 20px;
    display: block;
  }
`;
