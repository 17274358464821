import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { spaceL, spaceXXL, xdlColorBorderSoft } from '@xing-com/tokens';

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${spaceL};
  border-top: 2px solid ${xdlColorBorderSoft};
  margin-top: calc(2 * ${spaceXXL});
  margin-bottom: ${spaceL};
`;
export const PublishButton = styled(Button)`
  margin-left: ${spaceL};
`;
