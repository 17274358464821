import { CompanyEmployeesSort } from '@xing-com/crate-common-graphql-types';

export const RELATIONSHIP = {
  CONTACT: 'CONTACT',
  SENT: 'SENT',
  RECEIVED: 'RECEIVED',
  RECEIVED_DECLINED: 'RECEIVED_DECLINED',
  NONE: 'NONE',
};
export const LIMIT_EMPLOYEES_LIST = 10;
export const LIMIT_EMPLOYEES_LIST_LOGGED_OUT = 50;
export const QUERY_CONSUMER_DETAIL = 'web.entity_pages.employees_subpage';
export const QUERY_CONSUMER_CAROUSEL = 'web.entity_pages.employees_carrousel';
export const EMPLOYEES_SORTING = CompanyEmployeesSort.ConnectionDegree;
export const CONNECTION_DEGREE_SORTING = 'CONNECTION_DEGREE';
export const FOLLOW_EMPLOYEE_ACTION = 'followEmployee';
export const FOLLOW_EMPLOYEE_ID_PARAM = 'followEmployeeId';

const updateCollection = (edges: $TSFixMe[], id: string) => {
  const valuesWithNewRelationhipStatus = edges.map((item) => {
    if (item.node.profileDetails?.id === id) {
      return {
        node: {
          ...item.node,
          networkRelationship: {
            id,
            relationship: RELATIONSHIP.SENT,
            permissions: item.networkRelationship?.permissions || null,
            error: item.networkRelationship?.error || null,
            __typename: 'NetworkRelationship',
          },
          __typename: 'Node',
        },
        __typename: 'ProfileDetails',
      };
    }
    return item;
  });
  return valuesWithNewRelationhipStatus;
};

export const updateCurrentCache = ({
  viewer,
  cache,
  id,
  edges,
  total,
  query,
  pageInfo,
  variables,
}: Record<string, $TSFixMe>) =>
  cache.writeQuery({
    variables,
    query,
    data: {
      viewer,
      company: {
        employees: {
          total,
          edges: updateCollection(edges, id),
          pageInfo: pageInfo || null,
          __typename: 'CompanyEmployeesConnection',
        },
        __typename: 'Company',
      },
    },
  });

export const addContactButtonDisabled = (relationship: string) => {
  const { SENT, RECEIVED, RECEIVED_DECLINED } = RELATIONSHIP;
  if ([SENT, RECEIVED, RECEIVED_DECLINED].includes(relationship)) return true;
  return false;
};

export const getCurrentCache = ({ cache, query, variables }: $TSFixMe) => {
  const { company, viewer } = cache.readQuery({
    query,
    variables,
  });

  return {
    viewer,
    total: company?.employees?.total,
    edges: company?.employees?.edges,
    pageInfo: company?.employees?.pageInfo,
  };
};
