import { useLocation } from '@reach/router';
import type { FC } from 'react';
import React from 'react';

type CommboxOnloadOmTriggerProps = {
  onClick: () => void;
  param?: string;
};

export const CommboxOnloadOmTrigger: FC<CommboxOnloadOmTriggerProps> = ({
  onClick,
  param = 'create',
}) => {
  const urlSearchParams = new URLSearchParams(useLocation().search);

  React.useEffect(() => {
    const create = urlSearchParams.get(param);

    if (create === 'post') {
      onClick();
    }
  }, []);

  return null;
};
