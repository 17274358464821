import type { FC } from 'react';

import * as Styled from './jobs-list-item.styles';

export const JobsListItemSkeleton: FC = () => (
  <Styled.Container data-testid="jobs-list-item-skeleton">
    {/* @ts-expect-error FIXME: SC6 */}
    <Styled.Skeleton size="medium" amountRows={3} />
  </Styled.Container>
);
