import { useLocation } from '@reach/router';
import { useEffect } from 'react';

import * as appcues from './appcues';
import { useQueryAppcuesData } from './useQueryAppcuesData';

export const useAppcues = () => {
  const { pathname } = useLocation();

  const { hasAppcues, appcuesComanyData, user } = useQueryAppcuesData();

  useEffect(() => {
    const element = appcues.getElement();
    const isLoaded = appcues.isLoaded();

    if (hasAppcues && user) {
      const id = String(user?.id);

      if (!element) {
        appcues.createElement(id, appcuesComanyData);
      } else if (isLoaded) {
        appcues.loadResources(id, appcuesComanyData);
      }
    } else {
      if (element) {
        appcues.deleteElement();
        if (isLoaded) appcues.resetResources();
      }
    }

    return () => {
      const isLoaded = appcues.isLoaded();
      if (hasAppcues && isLoaded) {
        appcues.resetResources();
      }
    };
  }, [hasAppcues, user, pathname, appcuesComanyData]);
};
