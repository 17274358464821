/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconStatistics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconStatistics"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 19v2H4v-2zM19 7v10h-2V7zm-4 3v7h-2v-7zm-4-7v14H9V3zm-4 9v5H5v-5z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconStatistics;
