import styled from 'styled-components';

import { Button as InnerButton } from '@xing-com/button';
import { Divider as InnerDivider } from '@xing-com/divider';
import { SingleSizeIllustrationHeroOfferProduct as InnerSingleSizeIllustrationHeroOfferProduct } from '@xing-com/illustrations';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  scale100,
  spaceL,
  spaceM,
  spaceS,
  spaceXL,
  spaceXXL,
  xdlColorBorderSoft,
  xdlColorProBusiness,
  xdlColorProBusinessTertiary,
  xdlColorTextInvert,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const Container = styled.div`
  display: flex;
  margin-top: ${spaceXXL};
  margin-bottom: ${spaceXXL};
  flex-direction: column;
`;

export const PremiumWrapperWithIcon = styled.div`
  background-color: ${xdlColorProBusinessTertiary};
  padding-top: ${spaceL};
  padding-left: ${spaceL};
  display: flex;
  height: 256px;

  @media ${mediaSmallOrTiny} {
    padding-right: ${spaceXL};
  }
`;

export const ContentWrapper = styled.div`
  max-width: 600px;
`;

export const Flag = styled.span`
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  border: 2px solid transparent;
  height: ${scale100};
  padding: ${spaceS};
  background-color: ${xdlColorProBusiness};
  color: ${xdlColorTextInvert};
  font-weight: bold;
  font-size: 12px;
`;

export const HeaderText = styled.div`
  margin: ${spaceM} 0;
`;

export const Text = styled(BodyCopy)`
  text-align: justify;
`;

export const SingleSizeIllustrationHeroOfferProduct = styled(
  InnerSingleSizeIllustrationHeroOfferProduct
)`
  width: 380px;

  @media ${mediaSmallOrTiny} {
    display: none;
  }
`;

export const Divider = styled(InnerDivider)`
  margin-top: 25px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${spaceXL};

  @media ${mediaSmallOrTiny} {
    flex-direction: column;

    > div:first-child {
      border-bottom: solid 1px ${xdlColorBorderSoft};
    }
  }
`;

export const Button = styled(InnerButton)`
  margin: ${spaceL} 0;
  width: 100%;
`;
