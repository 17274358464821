import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { PopOver as DefaultPopOver } from '@xing-com/pop-over';
import {
  scale100,
  spaceL,
  spaceS,
  spaceXXS,
  xdlColorControlFillSecondary,
  dropShadowM,
  cornerRadiusM,
  xdlColorProBusiness,
  xdlColorTextInvert,
  cornerRadiusS,
} from '@xing-com/tokens';

export const EmployeBrandingFlagButton = styled(Button)`
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  border: ${spaceXXS} solid transparent;
  border-radius: ${cornerRadiusS};
  height: ${scale100};
  padding: ${spaceS};
  background-color: ${xdlColorProBusiness};
  color: ${xdlColorTextInvert};
  font-weight: bold;
  font-size: 12px;
`;
export const PopOver = styled(DefaultPopOver)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${spaceL};
  gap: ${spaceS};

  width: 282px;

  background: ${xdlColorControlFillSecondary};

  box-shadow: ${dropShadowM};
  border-radius: ${cornerRadiusM};
`;

export const ContentEmployerBrandingFlagButton = styled(Button)`
  margin-top: 15px;
`;
