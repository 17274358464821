import type { FC } from 'react';

import { SkeletonBody } from '@xing-com/skeleton';

import { ActorAnchor } from '../news-item-actor/news-item-actor.styles';
import { Content } from '../news-item-attachment/news-item-attachment.styles';
import * as Styled from './news-item.styles';

type NewsItemSkeletonProps = {
  smallActor?: boolean;
};
export const NewsItemSkeleton: FC<NewsItemSkeletonProps> = ({
  smallActor = false,
}) => (
  <Styled.Item isItemLoading={false}>
    <Styled.SkeletonActor smallActor={smallActor}>
      <>
        {!smallActor && (
          <ActorAnchor>
            <Styled.SkeletonProfileImage size="small" />
          </ActorAnchor>
        )}
        <Styled.SkeletonActorContent>
          <SkeletonBody amountRows={smallActor ? 1 : 2} />
        </Styled.SkeletonActorContent>
      </>
    </Styled.SkeletonActor>
    <Content>
      <Styled.SkeletonBodyWrapper>
        {/* @ts-expect-error FIXME: SC6 */}
        <Styled.SkeletonBody size="primary" amountRows={4} />
      </Styled.SkeletonBodyWrapper>
      <Styled.SkeletonImage />
    </Content>
  </Styled.Item>
);
