/*
handheld: ratio: 25%
tablet: ratio: 25%
desktop: 936px x 234px
*/

export const NORMAL_DIMENSIONS = [
  { height: 300, reference: 'handheld', width: 1200 },
  { height: 468, reference: 'tablet', width: 1872 },
  { height: 468, reference: 'desktop', width: 1872 },
  { height: 468, reference: 'nav', width: 1872 },
];
