import styled from 'styled-components';

import * as tokens from '@xing-com/tokens';
import { Headline as DefaultHeadline } from '@xing-com/typography';

export const Headline = styled(DefaultHeadline)`
  margin-bottom: ${tokens.spaceXL};
`;

export const HeaderDetail = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: ${tokens.spaceXL};
  border-bottom: 2px solid ${tokens.xdlColorBorderSoft};
`;
export const headerDetailLabel = styled(DefaultHeadline)`
  margin: 0 ${tokens.spaceM} 0 ${tokens.spaceXL};
`;

export const Role = styled.div`
  display: flex;
  align-items: center;
`;

export const PageType = styled.div`
  display: flex;
  align-items: center;
`;

export const Flag = styled.span`
  align-items: center;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  border: 2px solid transparent;
  border-radius: 2px;
  height: ${tokens.scale100};
  padding: ${tokens.spaceS};
  background-color: ${tokens.xdlColorControlFillQuaternary};
  font-weight: bold;
  font-size: 12px;

  @media (prefers-color-scheme: light) {
    color: ${tokens.xdlColorTextInvert};
  }
`;

export const MainArea = styled.div`
  display: flex;
  margin-top: ${tokens.spaceXXL};
`;

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${tokens.xdlColorBackgroundTertiary};
  padding: ${tokens.spaceXL};
  flex: 1;

  &:first-child {
    margin-right: ${tokens.spaceXL};
  }
`;
