import { Helmet } from 'react-helmet-async';

type Config = Record<string, string>;

type Props = {
  companyName: string;
  reviewsCount: number;
  ratingValue: number;
  pageSlug: string;
  config?: Config;
};

const buildStructuredJson = (
  companyName: string,
  reviewsCount: number,
  ratingValue: number,
  pageSlug: string,
  config?: Config
) =>
  JSON.stringify({
    '@context': 'http://schema.org/',
    '@type': 'EmployerAggregateRating',
    itemReviewed: {
      '@type': 'Organization',
      name: companyName,
      sameAs: `${config?.apiHost}${config?.basePath}/${pageSlug}`,
    },
    bestRating: '5',
    worstRating: '1',
    ratingCount: reviewsCount,
    ratingValue,
  });

const SchemaOrg = ({
  companyName,
  reviewsCount,
  ratingValue,
  pageSlug,
  config,
}: Props) => (
  <Helmet>
    <script type="application/ld+json">
      {buildStructuredJson(
        companyName,
        reviewsCount,
        ratingValue,
        pageSlug,
        config
      )}
    </script>
  </Helmet>
);

export default SchemaOrg;
