import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';

import { StatusBanner } from '@xing-com/banner';
import { Button } from '@xing-com/button';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  xdlColorText,
  xdlColorControlFill,
  xdlColorBorderSoft,
  xdlColorBorder,
  xdlColorBackgroundTertiary,
  xdlColorErrorSoft,
  spaceXS,
  spaceM,
  spaceL,
  spaceXL,
} from '@xing-com/tokens';
import { BodyCopy, Meta } from '@xing-com/typography';

export const TextWrapper = styled.div`
  flex: 1;
  word-wrap: break-word;

  @media ${mediaSmallOrTiny} {
    order: 2;
    flex-basis: 100%;
    margin-top: ${spaceM};
  }
`;

export const InfoTitle = styled(Meta)`
  font-weight: 700;
  color: ${xdlColorText};

  & > span > span {
    font-weight: 400;
  }
`;

export const VideoInfoStatusBanner = styled(StatusBanner)`
  margin-bottom: ${spaceXL};
`;

export const GalleryManagerInfo = styled(BodyCopy)`
  margin-bottom: ${spaceXL};
`;

export const SortableContainer = styled(ReactSortable)`
  .ghost {
    visibility: hidden;
  }

  .sortableFallback {
    background-color: white;
    box-shadow: 0 4px 16px 3px rgba(33, 42, 54, 0.3);
  }
`;

export const GalleryActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${spaceM} 0;

  @media ${mediaSmallOrTiny} {
    display: flex;
    justify-content: flex-end;
  }
`;

export const PublishButton = styled(Button)`
  margin-left: ${spaceL};
`;

export const VideoFailureListItemWrapper = styled.div`
  display: flex;
  padding: ${spaceL};
  border-bottom: 1px solid ${xdlColorBorderSoft};
  background-color: ${xdlColorErrorSoft};
  border-bottom: none;
  position: relative;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-of-type(-n + 4) {
    border-bottom: 1px solid ${xdlColorBorder};
    background-color: ${xdlColorBackgroundTertiary};
  }

  @media ${mediaSmallOrTiny} {
    flex-wrap: wrap;
  }
`;

export const DeleteNotificationButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    background-color: inherit;
  }
`;

export const VideoErrorWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 110px;
  height: 83px;
  margin-right: ${spaceL};
  display: flex;
  justify-content: center;
  overflow: hidden;
  display: flex;
  color: ${xdlColorText};
  justify-content: center;
  align-items: center;
  position: relative;

  @media ${mediaSmallOrTiny} {
    margin-right: 0;
  }
`;

export const VideoFailed = styled.span`
  position: absolute;
  background-color: ${xdlColorControlFill};
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const VideoFailedIcon = styled.span`
  z-index: 1;
`;

export const VideoFailureDescription = styled(BodyCopy)`
  margin-top: calc(-1 * ${spaceXS});
  margin-bottom: 0;
  color: ${xdlColorText};
  max-width: 80%;
`;

export const NotificationWrapper = styled.span`
  display: block;
  margin-bottom: ${spaceL};
`;
