import styled, { css } from 'styled-components';

import { Button as DefaultButton } from '@xing-com/button';
import { scale450, spaceM } from '@xing-com/tokens';

// *** Base ***
const baseImageContainer = css`
  display: inline-block;
  margin-top: ${spaceM};
  position: relative;
`;

const baseImageLoadingIndicator = css`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

const baseImage = css`
  max-height: ${scale450};
  max-width: 100%;
`;

// check  border with Bruno
const baseStyledButton = css`
  position: absolute;
  right: ${spaceM};
  top: ${spaceM};
`;

// *** Components ***
export const ImageContainer = styled.section`
  ${baseImageContainer}
`;

export const ImageLoadingIndicator = styled.div`
  ${baseImageLoadingIndicator}
`;

export const Image = styled.img`
  ${baseImage}
`;

export const Button = styled(DefaultButton)`
  ${baseStyledButton}
`;
