import { useNavigate } from '@reach/router';
import type React from 'react';
import type { FC } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import {
  capitalizeString,
  redirectToLogin,
} from '@xing-com/crate-companies-entity-pages-common';
import {
  getTrackingUrl,
  trackEmployeesAddContactButtonAction,
  ENTITY_PAGE_MAIN_EMPLOYEES,
} from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useViewerData } from '@xing-com/hub';

import { useNetworkRequestMutation } from '../../hooks/use-network-request-mutation';
import {
  FOLLOW_EMPLOYEE_ACTION,
  FOLLOW_EMPLOYEE_ID_PARAM,
  addContactButtonDisabled,
} from '../../utils';
import { BASIC_FLAG, MODERATOR_PREMIUM_FLAG, EXECUTIVE } from '../config';
import * as Styled from './employees-card-vertical-container.styles';

type EmployeesCardVerticalProps = {
  id: string;
  pageName: string;
  profileImage: {
    src: string;
    type: string;
    profileName: string;
    alt: string;
    connectionDegree?: number;
  };
  profileUrl: string;
  profileName: string;
  position?: string;
  relationship: string;
  flag?: string;
  className?: string;
  onAddContact?: () => void;
};
export const EmployeesCardVertical: FC<EmployeesCardVerticalProps> = ({
  id,
  pageName,
  profileImage,
  profileName,
  position,
  profileUrl,
  relationship,
  flag,
  className,
  onAddContact = () => undefined,
}) => {
  const { data: viewerData } = useViewerData();
  const currentUser = viewerData?.user;
  const { isLoggedIn } = useLoginState();
  const navigate = useNavigate();

  const [executeNetworkRequest, { loading: loadingNetworkRequest }] =
    useNetworkRequestMutation({
      onCompleted: () => {
        onAddContact();
      },
    });

  const getFlag = (flag?: string) => {
    if (!flag || flag === BASIC_FLAG || flag === EXECUTIVE) return;
    if (flag === MODERATOR_PREMIUM_FLAG)
      return {
        size: 'small',
        children: 'Moderator',
        variant: 'moderator',
      };
    return {
      size: 'small',
      children: flag && capitalizeString(flag),
      variant: flag?.toLowerCase(),
    };
  };

  const handleClickProfile = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const trackingUrl = getTrackingUrl({
      to: profileUrl,
      trackingKey: `viewEmployeesProfileMain`,
    });
    window.location.href = trackingUrl;
  };
  const handleAddContact = () => {
    if (!isLoggedIn) {
      redirectToLogin(`${FOLLOW_EMPLOYEE_ACTION}`, {
        [FOLLOW_EMPLOYEE_ID_PARAM]: id,
      });
    }
    return executeNetworkRequest(id);
  };
  const handleSendMessage = () => {
    navigate(
      `/chats/new/${pageName}?contextId=entity_pages&entryPoint=entity_pages_main_employees_message`
    );
  };

  const Headline = flag !== BASIC_FLAG ? Styled.Headline : Dotdotdot;

  return (
    <Styled.Card className={className}>
      <Styled.ProfileInfo
        alignment={'vertical'}
        profileImage={{
          src: profileImage.src,
          // @ts-expect-error TODO: fix this
          type: profileImage.type,
          profileName: profileImage.profileName,
          alt: `${profileImage.profileName}'s profile picture`,
          connectionDegree: isLoggedIn ? profileImage.connectionDegree : 0,
          style: { fill: 'none' },
          variant: 'responsive',
        }}
        // @ts-expect-error TODO: fix this
        flag={getFlag(flag)}
        profileUrl={profileUrl}
        headline={{
          size: 'small',
          as: 'strong',
          noMargin: true,
          children: (
            <Headline clamp={2} tagName="span">
              {profileName}
            </Headline>
          ),
        }}
        textBody={[
          {
            size: 'small',
            children: (
              <Styled.TextBody tagName="span" clamp={1}>
                {position}
              </Styled.TextBody>
            ),
          },
        ]}
        onClick={handleClickProfile}
      />
      {currentUser && currentUser.id !== id && (
        <Styled.ButtonsContainer>
          {relationship === 'CONTACT' ? (
            <Button
              className="employees-button"
              size="small"
              variant="secondary"
              onClick={handleSendMessage}
            >
              <FormattedMessage id="EP_EMPLOYEES_BUTTON_SEND_MESSAGE" />
            </Button>
          ) : (
            <Button
              className="employees-button"
              size="small"
              variant="secondary"
              loading={loadingNetworkRequest}
              onClick={() => {
                handleAddContact();
                trackEmployeesAddContactButtonAction(
                  ENTITY_PAGE_MAIN_EMPLOYEES
                );
              }}
              disabled={addContactButtonDisabled(relationship)}
              data-testid="add-employee"
            >
              <FormattedMessage id="EP_EMPLOYEES_BUTTON_ADD_CONTACT" />
            </Button>
          )}
        </Styled.ButtonsContainer>
      )}
    </Styled.Card>
  );
};
