import styled, { css } from 'styled-components';

import {
  mediaConfined,
  mediaWide,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import { scale130, scale150 } from '@xing-com/tokens';

import type { ContentSwitcherSize } from '.';

type ContentSwitcherTransientProps = {
  $size?: ContentSwitcherSize;
  $sizeConfined?: ContentSwitcherSize;
  $sizeWide?: ContentSwitcherSize;
};

type ButtonListTransientProps = {
  $noDropdown?: boolean;
  $size?: ContentSwitcherSize;
  $sizeConfined?: ContentSwitcherSize;
  $sizeWide?: ContentSwitcherSize;
};

// *** Sizes ***
export const sizes = {
  small: css`
    height: ${scale130};
  `,
  medium: css`
    height: ${scale150};
  `,
};

// *** Variants ***
export const variants = {
  filled: null,
};

// *** Components ***
export const ContentSwitcher = styled.nav<ContentSwitcherTransientProps>`
  display: flex;
  position: relative;
  ${({ $size }) => $size && sizes[$size]};
  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]}
      }
    `};
`;

export const ButtonList = styled.ul<ButtonListTransientProps>`
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;
  margin: 0;
  ${({ $size }) => $size && sizes[$size]};
  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]}
      }
    `};

  /* Hide horizontal scroll bar */
  margin-bottom: -25px;
  overflow: hidden;

  -webkit-overflow-scrolling: touch;

  /* IE 10+ */
  -ms-overflow-style: none;

  overflow-x: scroll;
  padding: 0;
  padding-bottom: 25px;

  /* FF */
  scrollbar-width: none;

  @media ${mediaWideNavless} {
    flex-wrap: wrap;
  }

  ${({ $size }) => $size && sizes[$size]};
  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]}
      }
    `};

  ${({ $noDropdown }) =>
    $noDropdown &&
    css`
      && {
        flex-wrap: nowrap;
        min-width: 100%;
      }
    `};

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
    height: 0;
    width: 0;
  }
`;
