import styled from 'styled-components';

import { Button } from '@xing-com/button';
import {
  xdlColorXING,
  spaceM,
  scale150,
  spaceXXL,
  spaceS,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Box = styled.div`
  height: 96px;
  border-radius: 4px;
  margin-right: 56px;
  flex-direction: column;
  display: flex;
  flex: 1;
  @media (min-width: 1113px) {
    width: 133px;
    flex: inherit;
  }
`;

export const BoxBackground = styled.div`
  background-color: ${xdlColorXING};
  position: absolute;
  top: 30px;
  height: 21px;
  width: 100%;
  z-index: 0;
  max-width: 180px;
  margin: auto;
  left: 0;
  right: 0;
`;

export const InnerBox = styled.div`
  text-align: center;
  z-index: 1;
  position: relative;
`;

export const BoxValue = styled(BodyCopy)`
  position: relative;
  z-index: 1;
  margin-bottom: ${spaceM};
  font-size: ${scale150};
  font-weight: 700;
  line-height: 1.2;
`;

export const WhatSayContainer = styled.div`
  display: none;
  border-radius: 4px;
  flex-direction: column;
  flex: 1;
  @media (min-width: 1113px) {
    display: flex;
  }
`;

export const WhatSayLabelContainer = styled.div`
  margin-top: ${spaceM};
  margin-bottom: ${spaceM};
  margin-left: ${spaceXXL};
  margin: ${spaceM} ${spaceXXL} ${spaceM} ${spaceXXL};
  flex: 1;
  align-self: center;
`;

export const WhatSayLink = styled.div`
  display: flex;
  align-items: center;
  margin: auto ${spaceS};
`;

export const SecondaryCta = styled(Button)`
  color: inherit;
  margin: ${spaceM};
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited {
    text-decoration: none;
  }
`;
