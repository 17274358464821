import type { FC } from 'react';

import { WarningMessageBox as Error } from '@xing-com/crate-companies-entity-pages-common';

type ModuleErrorProps = {
  refetch: () => void;
};

const ModuleError: FC<ModuleErrorProps> = ({ refetch }) => (
  <div data-testid={'errorContainer'}>
    <Error
      headerText="EP_ERROR_HEADER"
      bodyText="EP_ERROR_BODY"
      buttonText="EP_ERROR_RELOAD_CTA"
      onClick={() => refetch()}
    />
  </div>
);

export default ModuleError;
