import styled, { css } from 'styled-components';

import { Link } from '@xing-com/link';
import {
  cornerRadiusXL,
  dropShadowS,
  scale010,
  scale020,
  scale080,
  xdlColorBackground,
  xdlColorBorderSoft,
  xdlColorModalBorder,
  xdlColorSelected,
} from '@xing-com/tokens';

import type { CardVariant } from './card.types';

type CardTProps = {
  $autoposition?: boolean;
  $maxHeight?: string;
  $maxWidth?: string;
  $minHeight?: string;
  $minWidth?: string;
  $noPadding?: boolean;
  $selected?: boolean;
  $variant: CardVariant;
};

type CardContentTProps = {
  $noPadding?: boolean;
};

// *** Components ***
export const Card = styled.div<CardTProps>`
  background-color: ${xdlColorBackground};
  border-color: ${xdlColorBorderSoft};
  border-radius: ${cornerRadiusXL};
  border-style: solid;
  border-width: ${scale010};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${scale080};
  position: relative;

  ${({ $autoposition }) =>
    $autoposition &&
    css`
      & a,
      & button {
        position: relative;
        z-index: 9999;
      }
    `};

  ${({ $variant }) =>
    $variant === 'elevated' &&
    css`
      background-color: ${xdlColorBackground};
      border-color: ${xdlColorModalBorder};
      border-radius: ${cornerRadiusXL};
      border-width: ${scale010};
      box-shadow: ${dropShadowS};
    `};

  ${({ $selected, $variant }) =>
    $selected &&
    $variant === 'default' &&
    css`
      border-color: ${xdlColorSelected};
    `};

  ${({ $noPadding }) =>
    $noPadding &&
    css`
      padding: 0;
    `};

  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight};
    `};

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `};

  ${({ $minHeight }) =>
    $minHeight &&
    css`
      min-height: ${$minHeight};
    `};

  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth};
    `};
`;

export const CardContent = styled.div<CardContentTProps>`
  display: flex;
  flex-direction: column;

  ${(props) => css`
    ${props.$noPadding &&
    css`
      border-radius: calc(${cornerRadiusXL} - ${scale010});
      overflow: hidden;
    `};
  `};
`;

export const CardLink = styled(Link)`
  border-radius: calc(${cornerRadiusXL} - ${scale010});
  outline-offset: ${scale020};
  bottom: 0;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;

  && {
    position: absolute;
    z-index: 0;
  }
`;
