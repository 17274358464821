import type { FC } from 'react';

import * as Styled from './cover-media.styles';

type CoverMediaProps = {
  src: string;
  noImage?: boolean;
};
export const CoverMedia: FC<CoverMediaProps> = ({ src, noImage }) => {
  return (
    <Styled.CoverImageWrapper $noImage={noImage} data-cy="COVER_IMAGE">
      <Styled.CoverImageInner>
        <>
          <Styled.CoverOverlay />
          <Styled.CoverImage
            data-testid="COVER_IMAGE"
            src={src}
            alt="Cover Image"
          />
        </>
      </Styled.CoverImageInner>
    </Styled.CoverImageWrapper>
  );
};
