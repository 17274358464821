import * as Styled from './skeleton.styles';

const UserCardSkeleton = () => (
  <Styled.Container>
    <Styled.InfoWrapper>
      <Styled.LogoImageContainer />
      <Styled.UserInfo>
        {/* @ts-expect-error FIXME: SC6 */}
        <Styled.TitleSkeleton size="small" amountRows={1} />
        {/* @ts-expect-error FIXME: SC6 */}
        <Styled.BodySkeleton size="small" amountRows={2} />
      </Styled.UserInfo>
    </Styled.InfoWrapper>
  </Styled.Container>
);

export default UserCardSkeleton;
