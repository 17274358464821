import type * as React from 'react';

import * as Styled from './list.styles';
const { variants } = Styled;

export type ListVariant = keyof typeof variants;

export type ListProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Content of the list */
  children?: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLUListElement>;
  /** The variant of the list */
  variant?: ListVariant;
} & React.HTMLAttributes<HTMLUListElement>;
