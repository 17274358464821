import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import type { CompanyAboutUsFacts } from '@xing-com/crate-common-graphql-types';
import {
  DialogContextProvider,
  useEditContext,
  usePageContext,
} from '@xing-com/crate-companies-entity-pages-common';
import { OmView } from '@xing-com/platform-layout-om';

import FactsItem from './facts-item';
import * as Styled from './facts.styles';

type FactsProps = {
  headlineKey: string;
  items: { omViewContent: string }[];
  categoriesHeadlineKey: string;
  categories: string[];
  facts: CompanyAboutUsFacts;
};
const Facts: FC<FactsProps> = ({
  headlineKey,
  items,
  categoriesHeadlineKey,
  categories,
}) => {
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};

  if (!(items?.length > 0 || (categories && categories?.length > 0))) {
    return null;
  }

  return (
    <div>
      <Styled.FactsContainer>
        {items?.length > 0 && (
          <>
            <div>
              <Styled.FactsHeadline size={'large'} sizeWide={'xxlarge'}>
                <FormattedMessage
                  id={headlineKey}
                  defaultMessage={headlineKey}
                />
              </Styled.FactsHeadline>
              {isEditing && (
                <DialogContextProvider>
                  <Styled.FactsEditButton
                    size={'small'}
                    showIcon={true}
                    to={`${pageContext?.basePath}/${pageContext?.pageSlug}/edit/facts`}
                    data-testid="edit-facts"
                  />
                </DialogContextProvider>
              )}
            </div>
            <Styled.FactsList>
              {items.map((item: any, index: number) =>
                item.omViewContent ? (
                  <li key={index}>
                    <OmView
                      trigger={(activateOmView: any) => (
                        <FactsItem {...item} onClick={activateOmView} />
                      )}
                    >
                      {item.omViewContent}
                    </OmView>
                  </li>
                ) : (
                  <li key={index}>
                    <FactsItem {...item} />
                  </li>
                )
              )}
            </Styled.FactsList>
          </>
        )}
        {categories && categories.length > 0 && (
          <>
            <Styled.CategoriesHeadline size={'large'} sizeWide={'xxlarge'}>
              <FormattedMessage
                id={categoriesHeadlineKey}
                defaultMessage={categoriesHeadlineKey}
              />
            </Styled.CategoriesHeadline>
            {isEditing && (
              <DialogContextProvider>
                <Styled.FactsEditButton
                  size={'small'}
                  showIcon={true}
                  to={`${pageContext?.basePath}/${pageContext?.pageSlug}/edit/facts?type=industries`}
                  data-testid="edit-categories"
                />
              </DialogContextProvider>
            )}
          </>
        )}
        <Styled.CategoriesList data-testid="categories-list">
          {categories?.map((item: string) => (
            <li key={item}>
              <Styled.FactsTag>{item}</Styled.FactsTag>
            </li>
          ))}
        </Styled.CategoriesList>
      </Styled.FactsContainer>
    </div>
  );
};

export default Facts;
