import { FormattedMessage } from 'react-intl';

import { IconCross, IconWarning } from '@xing-com/icons';

import * as Styled from './gallery-manager.styles';

type VideoFailureNotificationsProps = {
  failures: {
    videoId: string;
    reference: string;
    title: string;
  }[];
  deleteNotification: (param: string) => void;
};
const VideoFailureNotifications = ({
  failures,
  deleteNotification,
}: VideoFailureNotificationsProps) => (
  <Styled.NotificationWrapper>
    {failures.map((item, index) => (
      <Styled.VideoFailureListItemWrapper key={index}>
        <Styled.DeleteNotificationButton
          onClick={() => deleteNotification(item.reference)}
          icon={IconCross}
          size={'medium'}
          aria-label="Cross"
        />
        <Styled.VideoErrorWrapper>
          <Styled.VideoFailed />
          <Styled.VideoFailedIcon>
            <IconWarning width={30} height={30} />
          </Styled.VideoFailedIcon>
        </Styled.VideoErrorWrapper>
        <Styled.TextWrapper>
          <Styled.InfoTitle size={'small'}>
            <span>
              <FormattedMessage
                id={'EP_GM_VIDEO_ENCODING_FAILED_TITLE'}
                defaultMessage={'EP_GM_VIDEO_ENCODING_FAILED_TITLE'}
                values={{
                  video_name: item?.title,
                  span: (t: any) => <span>{t}</span>,
                }}
              />
            </span>
          </Styled.InfoTitle>
          <Styled.VideoFailureDescription size={'small'}>
            <FormattedMessage
              id="EP_GM_VIDEO_ENCODING_FAILED_INFO"
              defaultMessage="EP_GM_VIDEO_ENCODING_FAILED_INFO"
            />
          </Styled.VideoFailureDescription>
        </Styled.TextWrapper>
      </Styled.VideoFailureListItemWrapper>
    ))}
  </Styled.NotificationWrapper>
);

export default VideoFailureNotifications;
