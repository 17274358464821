import styled from 'styled-components';

import { spaceL, spaceS } from '@xing-com/tokens';

export const Bookmark = styled.img`
  height: ${spaceL};

  @media (prefers-color-scheme: dark) {
    filter: brightness(0) invert(1);
  }
`;

export const BookmarkButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: 0 0 0 ${spaceS};
  padding: 0;
  outline: 0;
  padding: 2px;
`;
