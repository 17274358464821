import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { ImageUploadOnPublishData } from '@xing-com/crate-companies-entity-pages-common';

type UpsellBannerContainerContextType = {
  nextStep: () => void;
  isEditCrop?: boolean;
  upsellCover?: ImageUploadOnPublishData;
  setUpsellCover: Dispatch<
    SetStateAction<ImageUploadOnPublishData | undefined>
  >;
};
export const UpsellBannerContainerContext = createContext<
  UpsellBannerContainerContextType | undefined
>(undefined);
