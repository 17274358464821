import type { FC, PropsWithChildren } from 'react';
import { Children, useState } from 'react';

import type { ImageUploadOnPublishData } from '@xing-com/crate-companies-entity-pages-common';

import { UpsellBannerContainerContext } from '../../../../context/upsell-banner-context/upsell-banner-context';

type UpsellBannerContainerProps = {
  initialStep: number;
  isEditCrop: boolean;
};
export const UpsellBannerContainer: FC<
  PropsWithChildren<UpsellBannerContainerProps>
> = ({ children, initialStep = 0, isEditCrop }) => {
  const [step, setStep] = useState<number>(initialStep);

  const [upsellCover, setUpsellCover] = useState<
    ImageUploadOnPublishData | undefined
  >(undefined);
  const nextStep = () => setStep(step + 1);

  return (
    <UpsellBannerContainerContext.Provider
      value={{
        setUpsellCover,
        upsellCover,
        nextStep,
        isEditCrop,
      }}
    >
      {Children.map(children, (child, index) =>
        index === step ? child : null
      )}
    </UpsellBannerContainerContext.Provider>
  );
};
