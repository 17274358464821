/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateAboutUsDescriptionDetailedMutationVariables = Types.Exact<{
  articleGlobalId: Types.Scalars['GlobalID']['input'];
  header: Types.ArticlesCreateArticleHeaderInput;
  blocks:
    | Array<Types.ArticlesCreateArticleBlocksInput>
    | Types.ArticlesCreateArticleBlocksInput;
  lockVersion?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type UpdateAboutUsDescriptionDetailedMutation = {
  __typename?: 'Mutation';
  articlesUpdateArticle?: {
    __typename?: 'ArticlesSaveArticleResult';
    success?: {
      __typename: 'ArticlesArticle';
      id: string;
      globalId: string;
      contextGlobalId: string;
      publishedAt?: string | null;
      slug: string;
      lockVersion: number;
      header: {
        __typename?: 'ArticleHeader';
        title: { __typename?: 'ArticleTitle'; text: string };
        summary?: { __typename?: 'ArticleSummary'; text: string } | null;
      };
      blocks: Array<
        | { __typename?: 'ArticleBodyImage' }
        | { __typename?: 'ArticleEmphasis' }
        | {
            __typename?: 'ArticleH2';
            text: string;
            markups: Array<
              | { __typename: 'ArticleBoldMarkup'; start: number; end: number }
              | {
                  __typename: 'ArticleItalicMarkup';
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleLinkMarkup';
                  href: string;
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleMentionMarkup';
                  start: number;
                  end: number;
                }
            >;
          }
        | {
            __typename?: 'ArticleH3';
            text: string;
            markups: Array<
              | { __typename: 'ArticleBoldMarkup'; start: number; end: number }
              | {
                  __typename: 'ArticleItalicMarkup';
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleLinkMarkup';
                  href: string;
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleMentionMarkup';
                  start: number;
                  end: number;
                }
            >;
          }
        | {
            __typename?: 'ArticleH4';
            text: string;
            markups: Array<
              | { __typename: 'ArticleBoldMarkup'; start: number; end: number }
              | {
                  __typename: 'ArticleItalicMarkup';
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleLinkMarkup';
                  href: string;
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleMentionMarkup';
                  start: number;
                  end: number;
                }
            >;
          }
        | {
            __typename?: 'ArticleOrderedListItem';
            text: string;
            markups: Array<
              | { __typename: 'ArticleBoldMarkup'; start: number; end: number }
              | {
                  __typename: 'ArticleItalicMarkup';
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleLinkMarkup';
                  href: string;
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleMentionMarkup';
                  start: number;
                  end: number;
                }
            >;
          }
        | {
            __typename?: 'ArticleParagraph';
            text: string;
            markups: Array<
              | { __typename: 'ArticleBoldMarkup'; start: number; end: number }
              | {
                  __typename: 'ArticleItalicMarkup';
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleLinkMarkup';
                  href: string;
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleMentionMarkup';
                  start: number;
                  end: number;
                }
            >;
          }
        | { __typename?: 'ArticleQuote' }
        | {
            __typename?: 'ArticleUnorderedListItem';
            text: string;
            markups: Array<
              | { __typename: 'ArticleBoldMarkup'; start: number; end: number }
              | {
                  __typename: 'ArticleItalicMarkup';
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleLinkMarkup';
                  href: string;
                  start: number;
                  end: number;
                }
              | {
                  __typename: 'ArticleMentionMarkup';
                  start: number;
                  end: number;
                }
            >;
          }
      >;
    } | null;
    error?: {
      __typename?: 'ArticlesError';
      message?: string | null;
      details?: Array<string> | null;
    } | null;
  } | null;
};

export const UpdateAboutUsDescriptionDetailedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAboutUsDescriptionDetailed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'articleGlobalId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GlobalID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'header' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticlesCreateArticleHeaderInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'blocks' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'ArticlesCreateArticleBlocksInput',
                  },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lockVersion' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesUpdateArticle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lockVersion' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lockVersion' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'articleGlobalId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'articleGlobalId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'header' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'header' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'blocks' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'blocks' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'supportedBlockTypes' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'ARTICLE_H2' },
                          { kind: 'EnumValue', value: 'ARTICLE_H3' },
                          { kind: 'EnumValue', value: 'ARTICLE_H4' },
                          { kind: 'EnumValue', value: 'ARTICLE_PARAGRAPH' },
                          {
                            kind: 'EnumValue',
                            value: 'ARTICLE_UNORDERED_LIST_ITEM',
                          },
                          {
                            kind: 'EnumValue',
                            value: 'ARTICLE_ORDERED_LIST_ITEM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ArticleFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'details' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EPArticleBlockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ArticleBlock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleParagraph' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleH2' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleH3' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleH4' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleUnorderedListItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleOrderedListItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArticleFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ArticlesArticle' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'globalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contextGlobalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockVersion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'header' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'title' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blocks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EPArticleBlockFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAboutUsDescriptionDetailedMutation,
  UpdateAboutUsDescriptionDetailedMutationVariables
>;
