export const UPSELL_URL_EN = 'https://recruiting.xing.com/en/products/xing-360';
export const UPSELL_URL_DE = 'https://recruiting.xing.com/de/products/xing-360';

type GetMarketingPageUrl = {
  locale: string;
  moduleName?: string;
  focusType?: string;
  component?: string;
  isProductPage?: boolean;
};

export const getMarketingPageUrl = ({
  locale,
  moduleName,
  focusType,
  component = '',
  isProductPage,
}: GetMarketingPageUrl) => {
  let url;
  if (isProductPage) {
    const language = locale === 'en' ? 'en' : 'de';
    url = `https://recruiting.xing.com/${language}/products/xing-360`;
  } else {
    url = locale === 'en' ? UPSELL_URL_EN : UPSELL_URL_DE;
  }
  if (focusType === 'COMPANY' && moduleName) {
    url += `?sc_o=entity_page_companies_start_upsell_${moduleName}`;

    if (component.length) {
      url += `_${component}`;
    }
  }
  return url;
};
