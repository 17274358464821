import styled from 'styled-components';

import { ContentSwitcher as DefaultContentSwitcher } from '@xing-com/content-switcher';
import * as layoutTokens from '@xing-com/layout-tokens';
import * as tokens from '@xing-com/tokens';

const navigationHeigth = '80px';
const frameHeight = '68px';
const frameHeightMobile = '50px';
const mobileMargin = '20px';

type NavigationWrapperProps = {
  isEditing?: boolean;
};
export const NavigationWrapper = styled.div<NavigationWrapperProps>`
  ${({ isEditing }) => (!isEditing ? 'position: relative;' : '')}
`;
export const Navigation = styled.div`
  background-color: ${tokens.xdlColorBackground};
  overflow: hidden;

  &.navigationSticky {
    position: fixed;
    top: ${frameHeight};
    z-index: 15;
    padding-top: 0;
    margin: 0;
  }

  @media ${layoutTokens.mediaSmallOrTiny} {
    width: 100% !important;

    &.navigationSticky {
      top: ${frameHeightMobile};
      left: 0;
    }
  }
  @media ${layoutTokens.mediaConfined} {
    margin: 0 0 0 -20px;
    padding: 0 20px;
  }
  @media ${layoutTokens.mediaWide} {
    &.navigationSticky.isEditor {
      position: relative;
      top: 0;
    }

    &.isEditing.notDraft {
      border-bottom: 0;
    }
  }
`;
export const NavigationContainer = styled.div`
  align-items: center;
  height: ${navigationHeigth};
  display: flex;
  width: 100%;
`;
export const NavigationBorder = styled.div`
  border-bottom: 1px solid ${tokens.xdlColorBorderSoft};

  @media ${layoutTokens.mediaSmallOrTiny} {
    margin: 0 ${mobileMargin};
  }
`;
export const ContentSwitcherContainer = styled.div`
  flex: 1;
  overflow: hidden;

  @media (--mediaL) {
    flex: auto;
    width: 100%;
    overflow: auto;
  }
`;
export const ContentSwitcher = styled(DefaultContentSwitcher)`
  ul li:first-child button {
    margin-left: 0;
  }

  ul li:last-child button {
    margin-right: 0;
  }
  @media ${layoutTokens.mediaSmallOrTiny} {
    ul li:first-child button {
      margin-left: ${mobileMargin};
    }

    ul li:last-child button {
      margin-right: ${mobileMargin};
    }
  }
`;
export const InfoBanner = styled.div`
  @media ${layoutTokens.mediaSmallOrTiny} {
    margin: 0 ${mobileMargin};
  }
`;
