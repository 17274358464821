import type { FC, ReactNode } from 'react';

import { HeaderLoggedIn } from '@xing-com/platform-layout-header-logged-in';
import { ViewWrapper } from '@xing-com/platform-layout-view-wrapper';

type ZenViewGridlessProps = {
  children?: ReactNode;
  className?: string;
  contentClassName?: string;
  helpLink?: string;
  testId?: string;
  withSubNav?: boolean;
};

export const ZenViewGridLess: FC<ZenViewGridlessProps> = ({
  children,
  className,
  contentClassName,
  helpLink,
  testId,
  withSubNav,
}) => (
  <ViewWrapper
    className={className}
    contentClassName={contentClassName}
    testId={testId}
    Header={withSubNav ? undefined : <HeaderLoggedIn homeLink={helpLink} />}
  >
    {children}
  </ViewWrapper>
);
