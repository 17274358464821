import { useIntl } from 'react-intl';

import * as Styled from './image-upload.styles';

type DeleteDialogProps = {
  onConfirm: () => void;
  onCancel: () => void;
  loading: boolean;
  showDialog: boolean;
};
const DeleteDialog = ({
  onConfirm,
  onCancel,
  loading,
  showDialog,
}: DeleteDialogProps) => {
  const intl = useIntl();

  return (
    <Styled.DeleteDialog
      show={showDialog}
      isDestructive={true}
      headline={intl.formatMessage({
        id: 'EP_DELETE_IMAGE_GM_DIALOG_HEADLINE',
        defaultMessage: 'EP_DELETE_IMAGE_GM_DIALOG_HEADLINE',
      })}
      text={intl.formatMessage({
        id: 'EP_DELETE_IMAGE_GM_DIALOG',
        defaultMessage: 'EP_DELETE_IMAGE_GM_DIALOG',
      })}
      cancelLabel={intl.formatMessage({
        id: 'EP_DELETE_IMAGE_GM_DIALOG_CANCEL',
        defaultMessage: 'EP_DELETE_IMAGE_GM_DIALOG_CANCEL',
      })}
      confirmLabel={intl.formatMessage({
        id: 'EP_DELETE_IMAGE_GM_DIALOG_CONFIRM',
        defaultMessage: 'EP_DELETE_IMAGE_GM_DIALOG_CONFIRM',
      })}
      loading={loading}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

export default DeleteDialog;
