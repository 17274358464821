import type { GraphQLError } from 'graphql';
import type { IntlShape } from 'react-intl';

import type * as Types from '@xing-com/crate-common-graphql-types';

import EyeIcon from '../assets/emptystate.svg'; // eslint-disable-line
import PaperIcon from '../assets/emptystate_submodule.svg'; // eslint-disable-line
import {
  CONTENT_FEED_TYPE_ARTICLE,
  CONTENT_FEED_TYPE_NEW_ARTICLE,
  CONTENT_FEED_TYPE_GENERIC,
  CONTENT_FEED_TYPE_IMAGE,
  CONTENT_FEED_TYPE_LINK_SHARE,
  CONTENT_FEED_TYPE_LINK_SHARE_EXTERNAL,
  CONTENT_FEED_TYPE_COMPANY_ARTICLE,
  CONTENT_FEED_TYPE_VIDEO,
  FREE_PAGES_BANNER_LINK_DE,
  FREE_PAGES_BANNER_LINK_EN,
  SCHEDULED,
  DRAFT,
  HIDDEN,
  PUBLISHED,
} from '../config/constants';
import type { EntityPageFeedCollectionFragment } from '../graphql/fragments/entity-page-feed-collection-fragment.gql-types';
import type {
  EntityPageFeedQuery,
  EntityPageFeedQueryVariables,
} from '../graphql/queries/feed-query.gql-types';

export const getActionTextIdByType = (type?: string) => {
  let actionTextId = null;
  switch (type) {
    case CONTENT_FEED_TYPE_GENERIC:
      actionTextId = 'FEEDY_CARD_VERB_STATUS_POST';
      break;
    case CONTENT_FEED_TYPE_LINK_SHARE_EXTERNAL:
    case CONTENT_FEED_TYPE_LINK_SHARE:
      actionTextId = 'FEEDY_CARD_VERB_LINK_POST';
      break;
    case CONTENT_FEED_TYPE_IMAGE:
      actionTextId = 'FEEDY_CARD_VERB_IMAGE_POST';
      break;
    case CONTENT_FEED_TYPE_ARTICLE:
    case CONTENT_FEED_TYPE_NEW_ARTICLE:
    case CONTENT_FEED_TYPE_COMPANY_ARTICLE:
      actionTextId = 'FEEDY_CARD_VERB_CONTENT_PAGE_ARTICLE_POST';
      break;
    case CONTENT_FEED_TYPE_VIDEO:
      actionTextId = 'EP_NEWS_ACTION_TYPE_VIDEO';
      break;
    default:
      break;
  }
  return actionTextId;
};

const audienceTrackingValueMap = {
  PUBLIC: 'public',
  PRIVATE: 'my_contacts',
};
export const mapAudienceTrackingValue = (id: 'PUBLIC' | 'PRIVATE') =>
  audienceTrackingValueMap[id];

export const getPostingElementsWithError = (
  error: ReadonlyArray<GraphQLError>,
  offset = 0
) => {
  const errorIndex: (string | number)[] = [];
  error.map((errorItem) => {
    const { path = [] } = errorItem;
    if (
      path.includes('entityPageContentFeeds') &&
      path.includes('collection')
    ) {
      errorIndex.push(
        path.filter((item) => offset + (Number.isInteger(item) ? 1 : 0))[0]
      );
    }
  });

  return errorIndex;
};

export const newsPath = (basePath: string, slug: string) =>
  `${basePath}/${slug}/news`;

export const getPinnedArticle = (
  articles: EntityPageFeedCollectionFragment[] = []
) => articles.find((item) => item.isPinned);

export const publicationStateIndex = (
  publicationState: Types.PostingPublicationState | null | undefined,
  hasFeatureSwitch = false
) => {
  let index = 0;
  switch (publicationState) {
    case PUBLISHED:
      index = 0;
      break;
    case SCHEDULED:
      index = 1;
      break;
    case DRAFT:
      index = 2;
      break;
    case HIDDEN:
      index = hasFeatureSwitch ? 3 : 1;
      break;
    default:
      break;
  }

  return index;
};

export const getSubPageKey = (isEditing: boolean) =>
  isEditing ? 'NEWS_MODULE_EDIT_ALL' : 'EP_NEWS_SHOW_ALL';

export const searchForFatalErrorInModule = (
  error: ReadonlyArray<GraphQLError> = [],
  data: EntityPageFeedQuery = {}
) => {
  let postingsWithErrors = [];
  if (error.length > 0) {
    postingsWithErrors = getPostingElementsWithError(error);
  }

  // Return error module if is a different error
  return (
    (error.length > 0 && postingsWithErrors.length === 0) ||
    data === null ||
    data.entityPageContentFeeds === null
  );
};

// we need the following keys to exist in metadata, because they all represent
// possible posting states - https://jira.xing.hh/browse/NPUB-1122
export const feedMetadataHasElements = (
  metadata?: Pick<
    Types.EntityPageContentFeedCollectionMetadata,
    'draftTotal' | 'scheduledTotal' | 'hiddenTotal'
  > | null
) => {
  if (!metadata) {
    return false;
  }

  const metadataKeys = Object.keys(metadata);

  if (metadataKeys.length === 0) {
    return false;
  }

  return (
    metadataKeys.includes('draftTotal') &&
    metadataKeys.includes('hiddenTotal') &&
    metadataKeys.includes('scheduledTotal')
  );
};

export const getBannerTextResource = (intl: IntlShape) =>
  intl.locale === 'de' ? FREE_PAGES_BANNER_LINK_DE : FREE_PAGES_BANNER_LINK_EN;

export const getEmptyStateTextResources = (
  showEmptyState: boolean | undefined,
  isDetailPage: boolean,
  metadataHasElements: boolean
) => {
  let emptyStateCopy;

  if (showEmptyState && isDetailPage) {
    emptyStateCopy = {
      headlineCopyKey: 'EP_UPDATES_EDIT_EMPTY_STATE_HEADLINE',
      textCopyKey: 'EP_UPDATES_EDIT_EMPTY_STATE_DESCRIPTION',
      icon: PaperIcon,
    };
  } else {
    if (metadataHasElements) {
      emptyStateCopy = {
        headlineCopyKey: 'NEWS_MODULE_PUBLISH_FIRST_ARTICLE_TITLE',
        textCopyKey: 'NEWS_MODULE_PUBLISH_FIRST_ARTICLE_BODY',
        icon: EyeIcon,
      };
    } else {
      emptyStateCopy = {
        headlineCopyKey: 'EP_UPDATES_EDIT_EMPTY_STATE_HEADLINE',
        textCopyKey: 'EP_UPDATES_EDIT_EMPTY_STATE_DESCRIPTION',
        icon: PaperIcon,
      };
    }
  }

  return emptyStateCopy;
};

export const getFatalErrorTextResources = () => ({
  headlineCopyKey: 'EP_UPDATES_PUBLIC_EMPTY_STATE_HEADLINE',
  textCopyKey: 'EP_UPDATES_PUBLIC_EMPTY_STATE_DESCRIPTION',
  icon: PaperIcon,
});

export const getEmptyTopAreaTextResources = () => ({
  headlineCopyKey: 'NEWS_MODULE_TOP_AREA_HEADLINE',
  textCopyKey: 'NEWS_MODULE_TOP_AREA_SUB',
  buttonText: 'NEWS_MODULE_TOP_AREA_CTA',
  icon: EyeIcon,
});

export const isValidFeedItem = (
  feedItem: EntityPageFeedCollectionFragment,
  feedQueryVars: EntityPageFeedQueryVariables
) =>
  feedItem.object &&
  !feedItem.isPinned &&
  ((feedItem.object.__typename === 'EntityPageContentFeedPostingPost' &&
    feedItem.object.publicationState === feedQueryVars.filter) ||
    feedItem.object.__typename !== 'EntityPageContentFeedPostingPost');
