import { buttonConfig as boldButton } from '@xing-com/content-editor-plugin-bold';
import {
  buttonConfig as headingButton,
  H2,
  H3,
  H4,
  DEFINITIONS as HEADING_DEFINITIONS,
} from '@xing-com/content-editor-plugin-heading';
import { buttonConfig as italicButton } from '@xing-com/content-editor-plugin-italic';
import { buttonConfig as linkButton } from '@xing-com/content-editor-plugin-link';
import { buttonConfig as orderedListButton } from '@xing-com/content-editor-plugin-ordered-list';
import { DEFINITIONS as PARAGRAPH_DEFINITIONS } from '@xing-com/content-editor-plugin-paragraph';
import { DEFINITIONS as TITLE_DEFINITIONS } from '@xing-com/content-editor-plugin-title';
import { buttonConfig as unorderedListButton } from '@xing-com/content-editor-plugin-unordered-list';
import type {
  ArticleOrderedListItem,
  ArticleUnorderedListItem,
} from '@xing-com/crate-common-graphql-types';

export type AllowedProperties = {
  title: boolean;
  bold: boolean;
  italic: boolean;
  links: boolean;
  headings: {
    h2: boolean;
    h3: boolean;
    h4: boolean;
  };
  orderedList: ArticleOrderedListItem | boolean;
  unorderedList: ArticleUnorderedListItem | boolean;
};

const headingConfiguration = {
  disabledForBlockTypes: [TITLE_DEFINITIONS.type],
  defaultBlockDefinitions: { ...PARAGRAPH_DEFINITIONS, data: {} },
};

const defaultDisabledForBlockTypes = {
  disabledForBlockTypes: [HEADING_DEFINITIONS.type, TITLE_DEFINITIONS.type],
};

const getButtons = (allowedProperties: AllowedProperties) => {
  const buttons = [
    [
      allowedProperties.bold &&
        boldButton({
          ...defaultDisabledForBlockTypes,
        }),
      allowedProperties.italic &&
        italicButton({
          ...defaultDisabledForBlockTypes,
        }),
      allowedProperties.links &&
        linkButton({
          ...defaultDisabledForBlockTypes,
        }),
    ],
    [
      allowedProperties.headings?.h2 &&
        headingButton({ level: H2, ...headingConfiguration }),
      allowedProperties.headings?.h3 &&
        headingButton({ level: H3, ...headingConfiguration }),
      allowedProperties.headings?.h4 &&
        headingButton({ level: H4, ...headingConfiguration }),
    ],
    [
      allowedProperties.orderedList &&
        orderedListButton({
          ...defaultDisabledForBlockTypes,
        }),
      allowedProperties.unorderedList &&
        unorderedListButton({
          ...defaultDisabledForBlockTypes,
        }),
    ],
  ];

  return buttons.map((button) =>
    button.filter((x) => x !== false && x !== undefined)
  );
};

const buttons = [
  [
    boldButton({
      ...defaultDisabledForBlockTypes,
    }),
    italicButton({
      ...defaultDisabledForBlockTypes,
    }),
    linkButton({
      ...defaultDisabledForBlockTypes,
    }),
  ],
  [
    headingButton({ level: H2, ...headingConfiguration }),
    headingButton({ level: H3, ...headingConfiguration }),
    headingButton({ level: H4, ...headingConfiguration }),
  ],
  [
    orderedListButton({
      ...defaultDisabledForBlockTypes,
    }),
    unorderedListButton({
      ...defaultDisabledForBlockTypes,
    }),
  ],
];

const dropdown: any[] = [];

export { getButtons, buttons, dropdown };
