import styled from 'styled-components';

import { Card } from '@xing-com/card';
import { spaceM, spaceS, spaceXS } from '@xing-com/tokens';
import { Headline, Meta } from '@xing-com/typography';

export const JobCard = styled(Card)`
  height: 156px;
  padding: 12px;

  & > div {
    height: 100%;
  }

  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spaceM};
`;

export const CompanyName = styled(Meta)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* color: #333333; */
  font-size: 12px;
  margin-left: ${spaceS};
  margin-bottom: 0;
`;

export const CardTitle = styled(Headline).attrs({ as: 'strong' })`
  margin-bottom: ${spaceXS};
  /* color: #333333; */
`;
export const Location = styled(Meta)`
  font-size: 12px;
  /* color: #5e737d; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const MatchContainer = styled.div`
  display: flex;
`;

export const MatchIcon = styled.img`
  margin-right: ${spaceXS};
`;
export const Body = styled.div`
  flex-grow: 1;
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FooterText = styled(Meta)`
  margin-top: auto;
  font-size: 10px;
  /* color: #5e737d; */
`;

// .title {
//     margin-bottom: 0;
//   }

// @media ${mediaSmallOrTiny} {
//   .allJobs {
//     align-self: flex-start;
//     margin-top: ${spaceL};
//   }

//   .headline {
//     margin-bottom: 0;
//   }
// }
