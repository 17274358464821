import * as React from 'react';

import * as Styled from './list.styles';
import type { ListProps } from './list.types';

export const List = React.forwardRef<HTMLLIElement, ListProps>(
  ({ children, variant, className, ...props }, forwardedRef): JSX.Element => (
    <Styled.List
      $variant={variant}
      className={className}
      data-xds="List"
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Styled.List>
  )
);

List.displayName = 'List';
