import styled from 'styled-components';

import { scale010, spaceXXL } from '@xing-com/tokens';
import { Meta } from '@xing-com/typography';

export const TitleContainer = styled.div`
  margin-top: ${spaceXXL};
`;

export const Counter = styled(Meta)`
  margin-top: ${scale010};
`;
