import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { Column as DefaultColumn } from '@xing-com/xing-grid';

export const Column = styled(DefaultColumn)`
  @media ${mediaConfined} {
    padding-left: 0;
    padding-right: 0;
  }
`;
