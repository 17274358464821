import { useEffect, useState, useCallback } from 'react';

type FrameSizeProps = {
  width: number;
  height: number;
};
export const useNavigationOffset = () => {
  const [frameSize, setFrameSize] = useState<FrameSizeProps>({
    width: 0,
    height: 0,
  });
  const [navigationBarHeight, setNavigationBarHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  const mainPageRef = useCallback(
    (node: any) => {
      if (node !== null) {
        const bounds = node.getBoundingClientRect();
        setFrameSize({
          width: bounds.width,
          height: window.pageYOffset + bounds.top,
        });
      }
    },
    [windowWidth]
  );

  const navigationRef = useCallback(
    (node: any) => {
      if (node !== null) {
        setNavigationBarHeight(node.getBoundingClientRect().height + 1); // + 1 for the border
      }
    },
    [windowWidth]
  );

  const updateWindowWidth = () => {
    setWindowWidth(window?.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return [
    mainPageRef,
    navigationRef,
    frameSize.height + navigationBarHeight,
    frameSize,
    navigationBarHeight,
  ] as const;
};
