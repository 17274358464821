import styled from 'styled-components';

import { Viewer as InternalViewer } from '@xing-com/content-editor-core-viewer';
import {
  mediaSmallOrTiny,
  mediaConfinedNavlessOnly,
} from '@xing-com/layout-tokens';
import { spaceM, spaceXL, scale110 } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const Content = styled.div`
  display: flex;
  margin-top: ${spaceM};

  @media ${mediaSmallOrTiny} {
    min-height: 0;
  }
`;

export const ViewerContainer = styled.div`
  flex: 1;
`;

export const HeaderText = styled(BodyCopy)`
  display: flex;
  flex: 1;
  align-self: center;
  max-height: 120px;
  overflow: hidden;
  margin: 0 0 ${spaceXL} 0 !important;
  font-size: ${scale110};

  @media ${mediaSmallOrTiny} {
    padding-right: 0;
  }

  @media ${mediaConfinedNavlessOnly} {
    max-height: 120px;
  }

  @media (min-width: 1113px) {
    max-height: 155px;
    font-size: 32px;
    line-height: 1.2;
  }
`;

export const Viewer = styled(InternalViewer)`
  p {
    text-align: justify;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }
`;
