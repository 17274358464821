const NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_IN = 20;
const EMPLOYEES_OFFSET_LOGGED_OUT = 3;
const EMPLOYEES_OFFSET_LOGGED_IN = 2;
const NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT = 4;
const BASIC_FLAG = 'BASIC';
const EXECUTIVE = 'EXECUTIVE';
const MODERATOR_PREMIUM_FLAG = 'MODERATOR_PREMIUM';

export {
  NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_IN,
  NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT,
  EMPLOYEES_OFFSET_LOGGED_OUT,
  EMPLOYEES_OFFSET_LOGGED_IN,
  BASIC_FLAG,
  EXECUTIVE,
  MODERATOR_PREMIUM_FLAG,
};
