import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaConfined, zIndexElevatedLayer4 } from '@xing-com/layout-tokens';
import {
  cornerRadiusXL,
  dropShadowS,
  motionEasingEnter,
  motionEasingLeave,
  motionTimeM,
  scale005,
  scale150,
  spaceL,
  spaceXL,
  xdlColorBackground,
  xdlColorBackgroundTertiary,
  xdlColorModalBorder,
  xdlColorPremiumTertiary,
  xdlColorProBusinessTertiary,
  xdlColorProJobsTertiary,
} from '@xing-com/tokens';

import type {
  ContentBannerDisplay,
  ContentBannerVariant,
} from './content-banner.types';

type ContentBannerProps = {
  $closingIndicator: boolean;
  $display: ContentBannerDisplay;
  $noPadding: boolean;
  $variant: ContentBannerVariant;
};

export const contentBannerDuration = motionTimeM;
export const contentBannerTransitionName = 'slide';

// *** Variants ***
export const variants = {
  default: css`
    background-color: ${xdlColorBackgroundTertiary};
  `,
  soft: css`
    background-color: ${xdlColorBackground};
    box-shadow: ${dropShadowS};
  `,
  premium: css`
    background-color: ${xdlColorPremiumTertiary};
  `,
  proJobs: css`
    background-color: ${xdlColorProJobsTertiary};
  `,
  proBusiness: css`
    background-color: ${xdlColorProBusinessTertiary};
  `,
};

// *** Display ***
export const displays = {
  inline: {
    banner: css`
      border-radius: ${cornerRadiusXL};
      position: relative;
      width: 100%;
    `,
    button: css`
      top: 0;
    `,
  },
  fixed: {
    banner: css`
      position: relative;
      width: 100%;
      z-index: ${zIndexElevatedLayer4};
    `,
    button: css`
      top: 0;

      @media ${mediaConfined} {
        margin-right: ${spaceL};
        top: auto;
      }
    `,
  },
};

// *** Components ***
export const ContentBanner = styled.div<ContentBannerProps>`
  align-content: center;
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-bottom: ${spaceXL};
  padding-left: ${spaceL};
  padding-right: ${spaceL};
  padding-top: ${spaceXL};
  position: relative;

  ${(props) => css`
    ${props.$variant &&
    css`
      ${variants[props.$variant]};
    `};

    ${props.$display &&
    css`
      ${displays[props.$display].banner};

      ${props.$display === 'fixed' &&
      css`
        bottom: 0;
        left: 0;
        position: fixed;
        transform: translate3d(0, 100%, 0);
        z-index: ${zIndexElevatedLayer4};

        /* Animation */
        &.${contentBannerTransitionName}-enter {
          transform: translate3d(0, 99.9%, 0);
        }

        &.${contentBannerTransitionName}-enter-active {
          transform: translate3d(0, 0%, 0);
          transition-duration: ${contentBannerDuration};
          transition-property: transform;
          transition-timing-function: ${motionEasingEnter};
        }

        &.${contentBannerTransitionName}-enter-done {
          transform: translate3d(0, 0%, 0);
          transition-duration: ${contentBannerDuration};
          transition-property: transform;
          transition-timing-function: ${motionEasingEnter};
        }

        &.${contentBannerTransitionName}-exit {
          transform: translate3d(0, 0%, 0);
        }

        &.${contentBannerTransitionName}-exit-active {
          transform: translate3d(0, 99.9%, 0);
          transition-duration: ${contentBannerDuration};
          transition-property: transform;
          transition-timing-function: ${motionEasingLeave};
        }

        &.${contentBannerTransitionName}-exit-done {
          transform: translate3d(0, 100%, 0);
          transition-duration: ${contentBannerDuration};
          transition-property: transform;
          transition-timing-function: ${motionEasingLeave};
        }

        ${props.$closingIndicator &&
        css`
          /* Keep extra space of icon size + 2x space for fixed version */
          padding-right: calc(${spaceL} + ${scale150} + ${spaceL});
        `};

        ${props.$variant === 'soft' &&
        css`
          border-top-color: ${xdlColorModalBorder};
          border-top-width: ${scale005};
          border-top-style: solid;
        `};
      `};
    `};

    ${props.$noPadding &&
    css`
      padding: 0;
    `};
  `};
`;

export const Close = styled(Button)<ContentBannerProps>`
  position: absolute;
  right: 0;

  ${(props) => css`
    ${props.$display &&
    css`
      ${displays[props.$display].button};
    `};
  `};
`;
