/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationPremiumSpotWeb = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      width={width || presetWidth}
      height={height || presetHeight}
      data-xds="IllustrationPremiumSpotWeb"
      {...props}
    >
      <path
        fill="#FFFFFE"
        fillRule="evenodd"
        d="m2.85 5.51.157 57.003h60.779l.153-57.026z"
        clipRule="evenodd"
      />
      <path
        fill="#06A7AC"
        fillRule="evenodd"
        d="M3.755 63.26h61.762v-6.898H3.755z"
        clipRule="evenodd"
      />
      <path
        fill="#27DBA8"
        fillRule="evenodd"
        d="M10.973 44.549h16.444V23.231H10.973z"
        clipRule="evenodd"
      />
      <path
        fill="var(--xdlColorIllustrationOutlinePrimary)"
        d="m66.152 4.487-.157 59.026H2.009L1.85 4.487zM3.878 15.762l.124 45.751H64l.12-45.744H3.99zm41.848 22.129v2H33.663v-2zm10.076-5.706v2H33.663v-2zm0-5.707v2H33.663v-2zm8.344-19.99H3.853l.02 7.288h.001l.117-.007h60.136zM8.77 8.985a1.24 1.24 0 1 1 0 2.479 1.24 1.24 0 0 1 0-2.479m5.704 0a1.24 1.24 0 1 1-.001 2.479 1.24 1.24 0 0 1 0-2.479"
      />
    </svg>
  );
};
export default IllustrationPremiumSpotWeb;
