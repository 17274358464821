import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Portal } from '@xing-com/portal';

import { StatusBannerPrimitive } from './status-banner.primitive';
import {
  statusBannerDuration,
  statusBannerTransitionName,
} from './status-banner.styles';
import type { StatusBannerProps } from './status-banner.types';

export const StatusBanner = React.forwardRef<HTMLDivElement, StatusBannerProps>(
  (
    {
      children,
      className,
      closeButtonProps,
      display = 'fixed',
      handleOnClose,
      onExited,
      show = true,
      showCloseButton = true,
      variant,
      ...props
    },
    forwaredRef
  ): JSX.Element => {
    const timeout = display === 'fixed' ? parseInt(statusBannerDuration) : 0;

    const statusBanner = (
      <StatusBannerPrimitive
        closeButtonProps={closeButtonProps}
        display={display}
        handleOnClose={handleOnClose}
        ref={forwaredRef}
        showCloseButton={showCloseButton}
        variant={variant}
        {...props}
      >
        {children}
      </StatusBannerPrimitive>
    );

    const StatusBannerTransition = (
      <CSSTransition
        appear={show}
        className={className}
        classNames={statusBannerTransitionName}
        in={show}
        key="statusbanner"
        mountOnEnter
        onExited={onExited}
        timeout={timeout}
        unmountOnExit
      >
        {statusBanner}
      </CSSTransition>
    );

    if (display === 'fixed') {
      return <Portal>{StatusBannerTransition}</Portal>;
    }

    return StatusBannerTransition;
  }
);

StatusBanner.displayName = 'StatusBanner';
