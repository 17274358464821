import styled, { css } from 'styled-components';

import {
  motionTimeL,
  scale020,
  scale030,
  scale040,
  scale080,
  xdlColorDisabled,
  xdlColorText,
} from '@xing-com/tokens';

import type { DotSize } from './pagination-dots.types';

type DotTransientProps = {
  $size: DotSize;
  $isSelected: boolean;
  $paginationDotColor?: string;
};

type TrackTransientProps = {
  $centered: boolean;
  $index: number;
};

// *** Dot Sizes ***
export const dotSize = {
  big: css`
    height: ${scale040};
    width: ${scale040};
  `,
  medium: css`
    height: ${scale030};
    width: ${scale030};
  `,
  small: css`
    height: ${scale020};
    width: ${scale020};
  `,
  none: css`
    height: ${scale020};
    opacity: 0;
    width: ${scale020};
  `,
};

// *** Components ***
export const PaginationDots = styled.div`
  align-items: center;
  display: flex;
  height: ${scale080};
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: calc(${scale080} * 7);
`;

export const Track = styled.div<TrackTransientProps>`
  display: flex;
  position: absolute;
  top: 0;
  transition-duration: ${motionTimeL};
  transition-property: left;

  ${({ $centered }) =>
    $centered &&
    css`
      position: relative;
    `};

  ${({ $index }) =>
    $index
      ? css`
          left: calc(-1 * ${$index} * ${scale080});
        `
      : css`
          left: 0;
        `}
`;

export const DotContainer = styled.div`
  display: flex;
  height: ${scale080};
  width: ${scale080};
`;

export const Dot = styled.div<DotTransientProps>`
  background-color: ${xdlColorDisabled};
  border-radius: ${scale040};
  margin: auto;
  opacity: 1;
  transition-duration: ${motionTimeL};
  transition-property: opacity;

  ${({ $size }) => dotSize[$size]};

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${xdlColorText};
    `};

  ${({ $isSelected, $paginationDotColor }) =>
    $isSelected &&
    $paginationDotColor &&
    css`
      background-color: ${$paginationDotColor};
    `};
`;
