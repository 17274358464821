import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { IconHamburgerMenu } from '@xing-com/icons';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { SkeletonImage } from '@xing-com/skeleton';
import {
  scale090,
  scale160,
  spaceS,
  spaceL,
  spaceXL,
  xdlColorTextSecondary,
  xdlColorBorderSoft,
} from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

export const FormLoadingRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${spaceXL} ${spaceXL} ${spaceXL};
  margin-bottom: ${spaceXL};
  border-bottom: 1px solid ${xdlColorBorderSoft};
  flex: 1;
  width: 100%;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    width: 100%;
  }

  @media ${mediaSmallOrTiny} {
    display: block;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const FormLoadingRow = styled(SkeletonImage)`
  height: 48px;
  margin-right: ${spaceXL};
  flex: 1;

  &:last-child {
    margin-right: 0;
  }

  @media ${mediaSmallOrTiny} {
    margin-bottom: ${spaceL};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const EmptyBannerContent = styled.div`
  text-align: center;
`;

export const HeaderText = styled(Headline)`
  font-size: 26px;
  color: ${xdlColorTextSecondary};
`;

export const BodyText = styled(BodyCopy)`
  font-size: ${scale090};
  color: ${xdlColorTextSecondary};
`;

export const Container = styled.div`
  padding-bottom: ${spaceS};
`;

export const Form = styled.div`
  padding: 0;
`;
export const SortableContainer = styled(ReactSortable)`
  .placeholder {
    opacity: 0;
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${spaceXL} ${spaceXL} ${spaceXL};
  margin-bottom: ${spaceXL};
  border-bottom: 1px solid ${xdlColorBorderSoft};
  flex: 1;
  width: 100%;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    width: 100%;
  }

  @media ${mediaSmallOrTiny} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const FormRowFieldCompanyName = styled.div`
  padding-right: ${spaceXL};
  flex: 1;

  & select {
    height: ${scale160};
  }

  @media ${mediaSmallOrTiny} {
    padding-right: 0;
    margin-bottom: ${spaceL};
    flex-basis: 100%;
  }
`;

export const FormRowFieldCategory = styled.div`
  padding-right: ${spaceXL};
  flex: 1;

  & select {
    height: ${scale160};
  }

  @media ${mediaSmallOrTiny} {
    padding-right: 0;
    margin-right: ${spaceL};
  }
`;

export const FormRowButton = styled.div`
  align-items: center;
  justify-content: space-around;
  display: flex;
`;

export const DeleteButton = styled(Button)`
  margin-right: ${spaceXL};

  @media ${mediaSmallOrTiny} {
    padding-right: 0;
    margin-right: ${spaceL};
  }
`;

export const DragIcon = styled(IconHamburgerMenu)`
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  user-select: none;

  &:active {
    cursor: grabbing;
    background-color: inherit;
    outline: none;
    color: inherit;
  }
`;
