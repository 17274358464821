import type { FC } from 'react';

import { PollPreview } from '@xing-com/poll';

import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';
import { CommboxView } from '../../types';
import { CommboxEditor } from '../commbox-editor/commbox-editor';
import { CommboxLinkPreview } from '../commbox-link-preview/commbox-link-preview';
import { CommboxVideoUpload } from '../commbox-video-upload/commbox-video-upload';
import { ImageHandler } from '../drop-zone/image-handler';

export const CommboxBody: FC = () => {
  const {
    attachmentType,
    isVideoUploadOpen,
    pollData,
    setAttachmentType,
    setCurrentView,
    setPollData,
  } = useCommboxContext();
  const { enableVideo, isEditingPosting } = useCommboxPermissions();

  const showImageHandler =
    attachmentType === 'EMPTY' || attachmentType === 'IMAGE';
  const showLinkPreview = attachmentType === 'LINK_PREVIEW';
  const showVideoUpload = enableVideo && isVideoUploadOpen;

  return (
    <>
      <CommboxEditor />
      {showImageHandler && <ImageHandler />}
      {showLinkPreview && <CommboxLinkPreview />}
      {showVideoUpload && <CommboxVideoUpload />}
      {pollData && (
        <PollPreview
          {...pollData}
          showActionButtons={!isEditingPosting}
          onEdit={() => setCurrentView(CommboxView.POLL_FORM)}
          onDelete={() => {
            setPollData(null);
            setAttachmentType('EMPTY');
          }}
        />
      )}
    </>
  );
};
