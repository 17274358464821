import styled, { css } from 'styled-components';

import { NotificationBadge } from '@xing-com/badge';
import { ProfileImage } from '@xing-com/profile-image';
import {
  dropShadowS,
  scale005,
  scale070,
  scale080,
  scale090,
  scale110,
  scale130,
  spaceM,
  spaceS,
  spaceXS,
  xdlColorBackground,
  xdlColorControlBorder,
  xdlColorDisabledSoft,
  xdlColorHoverQuaternary,
  xdlColorHoverTertiary,
  xdlColorSelected,
  xdlColorText,
  xdlColorTextInvert,
  xdlColorTextSecondary,
  xdlColorTextTertiary,
} from '@xing-com/tokens';

import type { TagFontWeight } from './tag.types';

type BaseTagTProps = {
  $fontWeight?: TagFontWeight;
  $hasLeadingContent?: boolean;
  $hasTrailingContent?: boolean;
  $hideBorder?: boolean;
};

type DefaultTagTProps = {
  $fontWeight?: TagFontWeight;
  $hasLeadingContent?: boolean;
  $hasTrailingContent?: boolean;
  $hideBorder?: boolean;
  $profile?: boolean;
};

type PlaceholderTagTProps = DefaultTagTProps;

type InteractiveTagTProps = {
  $disabled?: boolean;
  $dragging?: boolean;
  $fontWeight?: TagFontWeight;
  $hasLeadingContent?: boolean;
  $hasTrailingContent?: boolean;
  $hideBorder?: boolean;
  $profile?: boolean;
  $selected?: boolean;
};

type DeleteButtonTProps = {
  $hasLeadingContent?: boolean;
};

type ProfileTProps = {
  $disabled?: boolean;
};

type TextTruncationTProps = {
  $maxWidth?: string;
};

// *** Base Styles ***
const baseTag = css<BaseTagTProps>`
  align-items: center;
  border-radius: ${scale130};
  border-style: solid;
  border-width: ${scale005};
  display: flex;
  font-size: ${scale070};
  gap: ${spaceXS};
  height: ${scale130};
  line-height: ${scale080};

  ${(props) => css`
    ${props.$hideBorder &&
    css`
      border: none;
    `};

    ${props.$fontWeight === 'bold' &&
    css`
      font-weight: bold;
    `};

    ${props.$hasLeadingContent
      ? css`
          padding-inline-start: ${spaceS};
        `
      : css`
          padding-inline-start: ${spaceM};
        `};

    ${props.$hasTrailingContent
      ? css`
          padding-inline-end: ${spaceS};
        `
      : css`
          padding-inline-end: ${spaceM};
        `};
  `};
`;

const defaultTag = css`
  background-color: ${xdlColorBackground};
  border-color: ${xdlColorControlBorder};
  color: ${xdlColorText};
`;

// *** Components ***
export const DefaultTag = styled.div<DefaultTagTProps>`
  ${baseTag};
  ${defaultTag};
`;

export const InteractiveTag = styled.div.attrs({
  tabIndex: 0,
})<InteractiveTagTProps>`
  ${baseTag};
  ${defaultTag};
  cursor: pointer;

  &:hover {
    background-color: ${xdlColorHoverTertiary};
    border-color: ${xdlColorHoverTertiary};
    color: ${xdlColorText};
  }

  ${(props) => css`
    ${props.$selected &&
    css`
      background-color: ${xdlColorSelected};
      border-color: ${xdlColorSelected};
      color: ${xdlColorTextInvert};

      &:hover {
        background-color: ${xdlColorHoverQuaternary};
        border-color: ${xdlColorHoverQuaternary};
        color: ${xdlColorTextInvert};
      }
    `};

    ${props.$dragging &&
    css`
      box-shadow: ${dropShadowS};
    `};

    ${props.$profile &&
    css`
      &:active {
        background-color: ${xdlColorSelected};
        color: ${xdlColorBackground};
      }
    `};

    ${props.$disabled &&
    css`
      &&,
      &&:focus,
      &&:hover {
        background-color: ${xdlColorDisabledSoft};
        border-color: ${xdlColorDisabledSoft};
        color: ${xdlColorTextTertiary};
        cursor: default;
        overflow: hidden;
        user-select: none;
      }
    `};
  `};
`;

export const PlaceholderTag = styled.div<PlaceholderTagTProps>`
  ${baseTag};

  &,
  &:focus,
  &:hover {
    background-color: ${xdlColorBackground};
    border-color: ${xdlColorControlBorder};
    border-style: dashed;
    border-width: ${scale005};
    color: ${xdlColorTextSecondary};
  }
`;

export const IconContainer = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${scale090};
  justify-content: center;
  width: ${scale090};

  & > svg {
    height: 100%;
    width: 100%;
  }
`;

export const ProfileImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
`;

export const BadgeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
`;

export const TextTruncationWrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${scale130};
  overflow: hidden;
`;

export const TextTruncation = styled.span<TextTruncationTProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props) => css`
    ${props.$maxWidth &&
    css`
      max-width: ${props.$maxWidth};
    `};
  `};
`;

export const Badge = styled(NotificationBadge)`
  color: ${xdlColorText};
  background-color: ${xdlColorTextInvert};
  border-color: ${xdlColorTextInvert};
`;

export const Profile = styled(ProfileImage)<ProfileTProps>`
  height: ${scale110};
  width: ${scale110};

  ${(props) => css`
    ${props.$disabled &&
    css`
      opacity: 0.4;
    `};
  `};
`;

export const DeleteButtonContainer = styled(InteractiveTag)`
  gap: 0;
  height: ${scale130};
  line-height: ${scale080};
  padding-inline-end: 0;
  padding-inline-start: 0;
`;

// The right clickable area for selecting the tag
export const DeleteButton = styled.button<DeleteButtonTProps>`
  align-items: center;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  gap: ${spaceXS};
  height: ${scale130};
  line-height: ${scale080};
  padding: 0;

  &[disabled] {
    cursor: default;
  }

  ${(props) => css`
    ${props.$hasLeadingContent
      ? css`
          padding-inline-start: ${spaceS};
        `
      : css`
          padding-inline-start: ${spaceM};
        `};
  `}
`;

export const DeleteButtonAction = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: ${scale130};
  line-height: ${scale080};
  padding-inline-end: ${spaceS};

  &[disabled] {
    cursor: default;
  }
`;
