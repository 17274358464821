import { useContext } from 'react';

import { EditContext } from '../../context/edit-context/edit-context';

export const useEditContext = () => {
  const context = useContext(EditContext);

  if (!context) {
    throw new Error('No host Provider found. Please setup the EditContext');
  }

  return context;
};
