import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';

type MarkIdealEmployerProps = {
  isIdealEmployer: boolean;
  toggleIdealEmployer: (param: boolean) => void;
  loading?: boolean;
};
const MarkIdealEmployer = ({
  isIdealEmployer,
  toggleIdealEmployer,
  loading,
}: MarkIdealEmployerProps) => {
  const copyKey = (removeIdealEmployer: boolean) =>
    removeIdealEmployer ? 'EP_IDEAL_EMPLOYER_REMOVE' : 'EP_IDEAL_EMPLOYER_ADD';

  return (
    <Button
      variant={isIdealEmployer ? 'tertiary' : 'primary'}
      size={'small'}
      onClick={(e) => {
        e.preventDefault();
        if (!loading) {
          toggleIdealEmployer(isIdealEmployer);
        }
      }}
      data-testid={isIdealEmployer ? 'removeIdealEmployer' : 'addIdealEmployer'}
    >
      <FormattedMessage
        id={copyKey(isIdealEmployer)}
        defaultMessage={copyKey(isIdealEmployer)}
      />
    </Button>
  );
};

export default MarkIdealEmployer;
