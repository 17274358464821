import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import {
  scale020,
  scale030,
  scale060,
  scale080,
  scale130,
  scale160,
} from '@xing-com/tokens';

import type { FacepileSize, FacepileVariant } from './';

type FacepileTransientProps = {
  $size: FacepileSize;
  $variant: FacepileVariant;
};

type CounterButtonTransientProps = {
  $size: FacepileSize;
};

// *** Sizes ***
export const sizes = {
  small: css`
    margin-right: ${scale020};
  `,
  medium: css`
    margin-right: ${scale030};
  `,
};

// *** Components ***
export const FacepileContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const Facepile = styled.div<FacepileTransientProps>`
  display: flex;
  ${({ $size }) => $size && sizes[$size]}

  ${({ $size, $variant }) =>
    $size === 'small' &&
    $variant === 'condensed' &&
    css`
      &:nth-child(n + 2) {
        margin-left: calc(calc(${scale060} * -1) + calc(${scale020} * -1));
      }
    `};

  ${({ $size, $variant }) =>
    $size === 'medium' &&
    $variant === 'condensed' &&
    css`
      &:nth-child(n + 2) {
        margin-left: calc(calc(${scale080} * -1) + calc(${scale030}) * -1);
      }
    `};

  &:nth-last-of-type(1) {
    margin-right: 0;
  }
`;

export const CounterButton = styled(Button)<CounterButtonTransientProps>`
  min-width: 0;
  padding: 0;

  ${({ $size }) =>
    $size === 'small' &&
    css`
      && {
        height: ${scale130};
        margin-left: ${scale060};
      }
    `};

  ${({ $size }) =>
    $size === 'medium' &&
    css`
      && {
        font-size: ${scale080};
        height: ${scale160};
        margin-left: ${scale080};
      }
    `};
`;

export const FacepileButton = styled(Button)`
  min-width: auto;
  overflow: visible;
  padding: 0;
`;
