import styled, { css } from 'styled-components';

import { FileUploadDrop } from '@xing-com/crate-x4e-file-upload';
import { spaceL, spaceS, spaceXXS } from '@xing-com/tokens';

// *** Base ***
const baseDropZone = css`
  border-radius: ${spaceS};
  border-width: ${spaceXXS};
  display: flex;
  flex-direction: column;
  padding: ${spaceL};
  width: 100%;
  align-items: center;
  justify-content: center;
`;

// *** Components ***
// const DropZone = styled.section<{
export const DropZone = styled(FileUploadDrop)<{
  $borderColor: string;
  $borderStyle: string;
  $minHeight: string;
}>`
  ${baseDropZone};
  border-style: ${({ $borderStyle }) => $borderStyle};
  border-color: ${({ $borderColor }) => $borderColor};
  min-height: ${({ $minHeight }) => $minHeight};
`;
