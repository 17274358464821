import styled from 'styled-components';

import { SkeletonBody } from '@xing-com/skeleton';
import {
  spaceM,
  spaceXS,
  xdlColorBackgroundTertiary,
  xdlColorBorderSoft,
} from '@xing-com/tokens';
import { BodyCopy, Meta as DefaultMeta } from '@xing-com/typography';

export const Container = styled.div`
  border-bottom: 1px solid ${xdlColorBorderSoft};
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${spaceM} 0;

  &:hover {
    background-color: ${xdlColorBackgroundTertiary};
    cursor: pointer;
  }
`;
export const InfoWrapper = styled.a`
  color: inherit;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`;

export const Skeleton = styled(SkeletonBody)`
  width: 75%;
`;

export const Headline = styled(BodyCopy)`
  font-weight: bold;
  margin-bottom: ${spaceXS};
`;
export const CompanyInfo = styled(BodyCopy)`
  line-height: var(--lineHeight300);
`;

export const Description = styled(BodyCopy)`
  margin: ${spaceXS} 0 0 0;
  line-height: var(--lineHeight300);
`;
export const Meta = styled(DefaultMeta)`
  color: var(--colorCopyWeak);
  line-height: var(--lineHeight200);
`;

// .button {
//   color: ${colorCopy};
// }
