/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconReaction = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconReaction"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5 13 15 2v8h5L10 22v-9zm7-2v5.476L15.73 12H13V7.173L9.521 11z"
    />
  </svg>
);
export default IconReaction;
