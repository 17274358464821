import styled from 'styled-components';

import { SkeletonImage, SkeletonButton } from '@xing-com/skeleton';
import {
  scale160,
  spaceL,
  spaceXXL,
  xdlColorBorderSoft,
} from '@xing-com/tokens';

export const SkeletonField = styled(SkeletonImage)`
  width: 100%;
  height: 38px;
  margin-top: ${scale160};
`;
export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${spaceL};
  border-top: 2px solid ${xdlColorBorderSoft};
  margin-top: calc(2 * ${spaceXXL});
`;

export const PublishButtonSkeleton = styled(SkeletonButton)`
  width: 125px;
`;
