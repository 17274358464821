import styled from 'styled-components';

import {
  mediaConfined,
  mediaSmallOrTiny,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import { spaceL, spaceM } from '@xing-com/tokens';

export const SecondaryArea = styled.div`
  @media ${mediaSmallOrTiny} {
    margin-top: ${spaceM};
  }

  @media (min-width: 740px) and (max-width: 1024px) {
    margin-top: ${spaceL};
  }

  @media ${mediaConfined} {
    display: flex;
    align-items: center;
    .secondaryMainArea {
      flex: 1;
    }
  }

  @media ${mediaWideNavless} {
    height: 80px;
  }
`;
export const HeaderActionsArea = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;

  @media ${mediaSmallOrTiny} {
    margin-top: ${spaceM};
  }
`;
