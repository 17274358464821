/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconStarFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconStarFilled"
    {...props}
  >
    <path
      fill="currentColor"
      d="m6.05 21.19.91-6.552-4.588-4.766 6.513-1.16L12 2.877l3.115 5.837 6.514 1.159-4.589 4.766.91 6.553L12 18.299z"
    />
  </svg>
);
export default IconStarFilled;
