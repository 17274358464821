import styled, { css } from 'styled-components';

import { StatusBanner as InternalStatusBanner } from '@xing-com/banner';
import { Carousel as InnerCarousel } from '@xing-com/crate-companies-entity-pages-common';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  xdlColorDisabledSoft,
  xdlColorText,
  spaceM,
  spaceXXL,
} from '@xing-com/tokens';

export const ExternalIcon = styled.div`
  height: 52px !important;
  width: 52px !important;
  z-index: 10;
  background-color: ${xdlColorDisabledSoft};
  opacity: 0.9;
  border-radius: 64px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${xdlColorText};
  top: calc(50% - 26px);
  left: calc(50% - 26px);
`;

export const Carousel = styled(InnerCarousel)`
  > div > div {
    & > * {
      width: calc(100% / 3);
      margin-right: 0;
    }

    & > *:nth-child(2n) {
      width: calc(100% / 3 + 3px);
      max-height: 246px;
      margin-right: 0;

      & > div {
        padding-top: 55.7%;
      }
    }

    @media (min-width: 740px) and (max-width: 1024px) {
      & > *:nth-child(n) {
        width: calc(100% / 2);
        margin-right: 0;
        max-height: auto;
      }

      & > *:nth-child(2n) {
        width: calc(100% / 2);
        max-height: auto;
        & > div {
          padding-top: 56.25%;
        }
      }
    }

    @media ${mediaSmallOrTiny} {
      & > * {
        width: calc(100% + 2px);
        max-height: auto;
      }

      & > *:nth-child(2n) {
        width: 100%;
        max-height: auto;
      }
    }
  }
`;

export const BlurredImageWrapper = styled.div`
  width: 100%;
`;

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  background-image: linear-gradient(
    90deg,
    #7c7c7c 0%,
    #000000 30%,
    #000000 70%,
    #7c7c7c 100%
  );
`;

export const BlurredImage = styled.div`
  background-size: 100% auto;
  background-position: center center;
  width: 100%;
  height: 100%;
`;

export const Image = styled.img<{ firstRender?: boolean }>`
  height: 100%;
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ firstRender }) =>
    firstRender &&
    css`
      width: 100%;
      visibility: hidden;
    `};
`;

export const SliderPreviewMediaWrapper = styled.div`
  display: block;
  margin-bottom: ${spaceM};

  @media ${mediaSmallOrTiny} {
    margin: 0 calc(-1 * 20px) ${spaceM};
  }
`;

export const SliderItemWrapper = styled.div`
  padding-top: 56.25%;
  position: relative;

  & > * {
    height: 100%;
  }
`;

export const SliderItem = styled.div`
  cursor: pointer;
  width: 100%;
  background-color: ${xdlColorDisabledSoft};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;

  & > * {
    height: 100%;
  }
`;

export const StatusBanner = styled(InternalStatusBanner)`
  margin-bottom: ${spaceXXL};
`;

export const GalleryPreviewContainer = styled.div`
  position: relative;
`;

export const OmViewSpan = styled.span<{ hideContent?: boolean }>`
  ${({ hideContent }) => hideContent && `display: none;`}
`;
