import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { zIndexElevatedLayer4 } from '@xing-com/layout-tokens';
import {
  motionEasingEnter,
  motionEasingLeave,
  motionTimeM,
  scale110,
  spaceL,
  spaceM,
  spaceS,
  spaceXXS,
  xdlColorError,
  xdlColorErrorSoft,
  xdlColorInfoSoft,
  xdlColorSuccessSoft,
  xdlColorText,
  xdlColorTextOnLight,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import type {
  StatusBannerDisplay,
  StatusBannerVariant,
} from './status-banner.types';

type StatusBannerProps = {
  $variant: StatusBannerVariant;
  $display: StatusBannerDisplay;
};

type CloseProps = {
  $variant: StatusBannerVariant;
};

export const statusBannerDuration = motionTimeM;
export const statusBannerTransitionName = 'slide';

// *** Variants ***
export const variants = {
  error: {
    banner: css`
      background-color: ${xdlColorErrorSoft};
      color: ${xdlColorError};
    `,
    button: css`
      color: ${xdlColorTextOnLight};
    `,
  },
  info: {
    banner: css`
      background-color: ${xdlColorInfoSoft};
      color: ${xdlColorText};
    `,
    button: css`
      color: ${xdlColorText};
    `,
  },
  success: {
    banner: css`
      background-color: ${xdlColorSuccessSoft};
      color: ${xdlColorText};
    `,
    button: css`
      color: ${xdlColorText};
    `,
  },
};

// *** Components ***
export const StatusBanner = styled.div<StatusBannerProps>`
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
  display: flex;
  padding-left: ${spaceM};
  padding-right: ${spaceM};
  width: 100%;

  ${(props) => css`
    ${props.$variant &&
    css`
      ${variants[props.$variant].banner};
    `};

    ${props.$display === 'fixed' &&
    css`
      bottom: 0;
      left: 0;
      position: fixed;
      transform: translate3d(0, 100%, 0);
      z-index: ${zIndexElevatedLayer4};

      /* Animation */
      &.${statusBannerTransitionName}-enter {
        transform: translate3d(0, 99.9%, 0);
      }

      &.${statusBannerTransitionName}-enter-active {
        transform: translate3d(0, 0%, 0);
        transition-duration: ${statusBannerDuration};
        transition-property: transform;
        transition-timing-function: ${motionEasingEnter};
      }

      &.${statusBannerTransitionName}-enter-done {
        transform: translate3d(0, 0%, 0);
        transition-duration: ${statusBannerDuration};
        transition-property: transform;
        transition-timing-function: ${motionEasingEnter};
      }

      &.${statusBannerTransitionName}-exit {
        transform: translate3d(0, 0%, 0);
      }

      &.${statusBannerTransitionName}-exit-active {
        transform: translate3d(0, 99.9%, 0);
        transition-duration: ${statusBannerDuration};
        transition-property: transform;
        transition-timing-function: ${motionEasingLeave};
      }

      &.${statusBannerTransitionName}-exit-done {
        transform: translate3d(0, 100%, 0);
        transition-duration: ${statusBannerDuration};
        transition-property: transform;
        transition-timing-function: ${motionEasingLeave};
      }
    `};
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding-bottom: ${spaceL};
  padding-top: ${spaceL};
`;

export const Icon = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${scale110};
  justify-content: center;
  width: ${scale110};

  & > svg {
    height: ${scale110};
    width: ${scale110};
  }
`;

export const Text = styled(BodyCopy)`
  margin-bottom: 0;
  margin-top: ${spaceXXS};
  max-width: inherit;
  padding-left: ${spaceM};
`;

export const Close = styled(Button)<CloseProps>`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-left: ${spaceM};
  margin-top: ${spaceS};

  ${(props) => css`
    ${props.$variant && variants[props.$variant].button}
  `};
`;
