import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { SkeletonImage as InternalSkeletonImage } from '@xing-com/skeleton';
import { scale130, scale160 } from '@xing-com/tokens';

export const AboutUsWrapper = styled.div`
  display: flex;

  ${mediaSmallOrTiny} {
    display: block;
  }
`;

export const SkeletonImage = styled(InternalSkeletonImage)`
  align-self: flex-start;
  margin-right: ${scale160};
  width: 327px;
  height: 205px;

  ${mediaSmallOrTiny} {
    width: 100%;
    margin-bottom: ${scale130};
  }
`;

export const Summary = styled.div`
  flex: 1;
`;
