import type { FC } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FileUploadButton } from '@xing-com/crate-x4e-file-upload';
import { IconVideo } from '@xing-com/icons';
import { Headline, BodyCopy } from '@xing-com/typography';

import VideoIcon from './assets/video.svg';
import * as Styled from './upload.styles';

type UploadReadyProps = {
  handleFileSelect?: (file: File) => void;
};
export const UploadReady: FC<UploadReadyProps> = ({
  handleFileSelect = () => undefined,
}) => {
  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <Styled.FileUpload
      $onDrag={!!hovering}
      // onPreview={(files) => handleFileSelect(files[0])}
      onHover={(hovering) => setHovering(hovering)}
      onFileSelected={handleFileSelect}
      accept="video/mp4, video/x-m4v, video/*"
      multiple={false}
    >
      <Styled.UploadArea>
        <Styled.Illustration src={VideoIcon} />
        <Styled.ReadyTitle>
          <Headline size="large" noMargin>
            <FormattedMessage
              id={
                hovering
                  ? 'VIDEO_UPLOAD_READY_TITLE_DRAGGING'
                  : 'VIDEO_UPLOAD_READY_TITLE'
              }
            />
          </Headline>
        </Styled.ReadyTitle>
        <Styled.ReadyText>
          <BodyCopy size="small" noMargin>
            <FormattedMessage id="VIDEO_UPLOAD_READY_DESCRIPTION" />
          </BodyCopy>
        </Styled.ReadyText>
        <Styled.ReadyCta>
          <FileUploadButton
            onFileSelected={handleFileSelect}
            accept="video/mp4, video/x-m4v, video/*"
            multiple={false}
            variant="primary"
            size="medium"
            icon={IconVideo}
          >
            <FormattedMessage id="VIDEO_UPLOAD_READY_CTA" />
          </FileUploadButton>
        </Styled.ReadyCta>
      </Styled.UploadArea>
    </Styled.FileUpload>
  );
};
