import type {
  JobItemResult,
  VisibleJob,
} from '@xing-com/crate-common-graphql-types';

import { checkBookmarkState } from '../utils';

export const createJobsCards = (jobs: Partial<JobItemResult>[]) => {
  return jobs
    .filter((job) => job?.jobDetail?.__typename === 'VisibleJob')
    .map((job) => {
      const jobDetail = job.jobDetail as VisibleJob;

      return {
        id: jobDetail.id,
        companyName: jobDetail?.companyInfo?.company?.companyName || '',
        companyLogo: jobDetail?.companyInfo?.company?.logos?.logo96px ?? '',
        bookmarked: checkBookmarkState(
          jobDetail?.userInteractions?.bookmark?.state
        ),
        title: jobDetail?.title,
        activatedAt: jobDetail?.activatedAt
          ? new Date(jobDetail?.activatedAt)
          : new Date(),
        match: jobDetail?.userScore?.scoreRounded
          ? Math.round(Number(jobDetail?.userScore?.scoreRounded) * 100)
          : 0,
        location: `${jobDetail?.location?.city}, ${jobDetail?.location?.country?.localizationValue}`,
        link: jobDetail?.url,
      };
    });
};
