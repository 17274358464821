import type { DragEvent, FC, PropsWithChildren } from 'react';

import { checkFileType, dataURLtoBlob } from '../../utils';

type FileUploadDropProps = {
  onError?: (error: string | Error | ProgressEvent<FileReader>) => void;
  onFileSelected: (file: File) => void;
  onHover: (hovering: boolean) => void;
  multiple?: boolean;
  accept: string;
};
export const FileUploadDrop: FC<PropsWithChildren<FileUploadDropProps>> = ({
  onError = () => undefined,
  onFileSelected,
  onHover,
  accept,
  multiple = false,
  ...rest
}) => {
  const handleDragOver = (ev: DragEvent<HTMLDivElement>): void => {
    ev.stopPropagation();
    ev.preventDefault();

    onHover(ev.type === 'dragover');
  };

  const handlePreview = (files: File[]): void => {
    if (multiple && files.length > 1) {
      onError('MULTIPLE_FILES_NOT_SUPPORTED');
      return;
    }

    const file = files?.[0];

    if (file) {
      const mimeType = file.type;
      const fileName = file.name;

      if (checkFileType(accept, mimeType)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (reader.result) {
            const fileBlob = dataURLtoBlob(reader.result);
            const file = new File([fileBlob], fileName, {
              type: fileBlob.type,
            });

            onFileSelected(file);
          }
        };
        reader.onerror = (error) => {
          onError(error);
        };
      } else {
        onError('INVALID_FILE_TYPE');
      }
    }
  };

  const handleOnDrop = (ev: DragEvent<HTMLDivElement>): void => {
    ev.stopPropagation();
    ev.preventDefault();

    const files = ev.dataTransfer.files;
    const fileList = Array.from(files);

    if (!files) {
      return;
    }

    handlePreview(fileList);
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragOver}
      onDrop={handleOnDrop}
      {...rest}
    />
  );
};
