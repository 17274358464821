import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

type MetaDataProps = {
  setDefault?: boolean;
  title?: string;
  url?: string;
  image?: string;
  description?: string | null;
  index?: boolean;
};

const renderTitle = (title: string) => (
  <Helmet>
    <title>{title}</title>
    <meta property="og:title" content={title} />
    <meta name="twitter:title" content={title} />
    <meta property="og:site_name" content="XING" />
    <meta property="og:type" content="website" />
  </Helmet>
);

const renderDescription = (description: string) => (
  <Helmet>
    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
    <meta name="twitter:description" content={description} />
  </Helmet>
);

const renderImage = (image: string) => (
  <Helmet>
    <meta property="og:image" content={image} />
    <meta name="twitter:image" content={image} />
  </Helmet>
);

const renderUrl = (url: string) => (
  <Helmet>
    <link rel="canonical" href={url} />
    <meta property="og:url" content={url} />
  </Helmet>
);

const renderDefault = (index: boolean) => (
  <Helmet>
    <meta name="robots" content={index ? 'index, follow' : 'noindex, follow'} />
    <meta property="og:site_name" content="XING" />
    <meta property="og:type" content="website" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content={'@XING_de'} />
  </Helmet>
);

export const MetaData: FC<MetaDataProps> = ({
  title,
  url,
  image,
  description,
  setDefault,
  index = true,
}) => (
  <>
    {setDefault ? renderDefault(index) : null}
    {title ? renderTitle(title) : null}
    {image ? renderImage(image) : null}
    {url ? renderUrl(url) : null}
    {description ? renderDescription(description) : null}
  </>
);
