import { useContext } from 'react';

import { CommboxContext } from '../contexts/commbox-context';

export const useCommboxContext = () => {
  const context = useContext(CommboxContext);

  if (!context) {
    throw new Error('useCommboxContext must be used within a CommboxProvider');
  }

  return context;
};
