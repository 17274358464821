import styled from 'styled-components';

import { Button as DefaultButton } from '@xing-com/button';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  scale110,
  space4XL,
  space5XL,
  spaceL,
  spaceM,
  spaceS,
  spaceXL,
  spaceXS,
  xdlColorBackgroundSecondary,
  xdlColorBorder,
  xdlColorInfoSoft,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const InfoStateWrapper = styled.div<{ $isEditing: boolean }>`
  background-color: ${xdlColorInfoSoft};
  padding: ${spaceXL};
  display: flex;
  align-items: center;
  border-radius: ${spaceS};
  flex-direction: column;

  ${({ $isEditing }) =>
    $isEditing
      ? `background-color: ${xdlColorInfoSoft};`
      : `
      color: ${xdlColorTextSecondary};
      background-color: ${xdlColorBackgroundSecondary};
    `}

  @media ${mediaConfined} {
    flex-direction: row;
  }
`;

export const TextWrapper = styled.div<{ $noIcon: boolean }>`
  margin-top: ${spaceL};
  flex-grow: 1;
  word-break: break-word;

  ${({ $noIcon }) =>
    $noIcon &&
    `
      margin-top: 0;
      word-break: break-word;
      @media (min-width: 740px) and (max-width: 1024px) {
        margin-top: 0;
      }
  `}

  @media (min-width: 740px) and (max-width: 1024px) {
    margin-top: ${spaceS};
  }
  @media ${mediaConfined} {
    margin-top: 0;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  @media ${mediaConfined} {
    align-self: flex-start;
    margin-right: ${spaceXL};
  }
`;

export const Icon = styled.img`
  width: ${space4XL};
  height: ${space4XL};
  @media (--mediaXL) {
    width: ${space5XL};
    height: ${space5XL};
  }
`;
export const HeaderText = styled(BodyCopy)`
  margin-bottom: ${spaceXS};
  @media (max-width: 739px) {
    text-align: center;
  }
  @media (--mediaL) {
    font-size: ${scale110};
  }
`;

export const Text = styled(BodyCopy)`
  padding-top: ${spaceXS};
  @media (max-width: 739px) {
    text-align: center;
  }
`;

export const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spaceL};
  flex-shrink: 0;
  @media ${mediaConfined} {
    flex-direction: row;
    margin-top: 0;
    align-self: center;
  }
`;

export const Button = styled(DefaultButton)`
  margin-top: ${spaceM};
  &:first-child {
    margin-top: 0;
  }
  @media ${mediaConfined} {
    margin-left: ${spaceL};
    margin-top: 0;
  }
`;

export const ExtraContent = styled.div`
  border-top: 1px solid ${xdlColorBorder};
  margin-top: ${spaceL};
  padding-top: ${spaceL};
`;

export const Info = styled.div`
  flex-grow: 1;
  flex-basis: 100%;
`;
