import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  scale080,
  spaceL,
  spaceXL,
  xdlColorBorderSoft,
} from '@xing-com/tokens';

export const CtaContainer = styled.div`
  border-top: 1px solid ${xdlColorBorderSoft};
  display: flex;
  gap: ${scale080};

  margin-top: ${spaceXL};
  padding: ${spaceL} 0;
  justify-content: flex-end;
`;

export const DeleteVideButton = styled(TextButton)`
  @media ${mediaSmallOrTiny} {
    display: none;
  }
`;
