import styled, { css } from 'styled-components';

import {
  cornerRadius3XL,
  cornerRadiusXL,
  cornerRadiusXXL,
  space3XL,
  spaceL,
  spaceXL,
  spaceXXL,
  xdlColorCardInspire01,
  xdlColorCardInspire02,
  xdlColorCardInspire03,
  xdlColorCardInspire04,
  xdlColorCardInspire05,
  xdlColorCardInspire06,
  xdlColorCardInspire07,
  xdlColorTextOnLight,
} from '@xing-com/tokens';

import { Card } from '..';
import type {
  CardInspireSizes,
  CardInspireVariants,
} from './card-inspire.types';

type CardInspireTProps = {
  $size: CardInspireSizes;
  $variant: CardInspireVariants;
};

// *** Sizes ***
export const sizes = {
  auto: css`
    // FIXME: Move helmet styles here after upgrading to Styled Components v6
  `,
  small: css`
    border-radius: ${cornerRadiusXL};
    padding-block: ${spaceL};
    padding-inline: ${spaceXL};
  `,
  medium: css`
    border-radius: ${cornerRadiusXXL};
    padding-block: ${spaceXL};
    padding-inline: ${spaceXXL};
  `,
  large: css`
    border-radius: ${cornerRadius3XL};
    padding-block: ${spaceXXL};
    padding-inline: ${space3XL};
  `,
};

// *** Variants ***
export const variants = {
  1: css`
    background-color: ${xdlColorCardInspire01};
  `,
  2: css`
    background-color: ${xdlColorCardInspire02};
  `,
  3: css`
    background-color: ${xdlColorCardInspire03};
  `,
  4: css`
    background-color: ${xdlColorCardInspire04};
  `,
  5: css`
    background-color: ${xdlColorCardInspire05};
  `,
  6: css`
    background-color: ${xdlColorCardInspire06};
  `,
  7: css`
    background-color: ${xdlColorCardInspire07};
  `,
};

// *** Components ***
export const CardContainer = styled.div`
  // FIXME: Move helmet styles here after upgrading to Styled Components v6
`;

export const CardInspire = styled(Card)<CardInspireTProps>`
  border-width: 0;
  color: ${xdlColorTextOnLight};

  ${(props) => css`
    ${props.$size && sizes[props.$size]};
    ${props.$variant && variants[props.$variant]};
  `};
`;
