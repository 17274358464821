export const XBM_STATISTICS_URL = (pageSlug: string) =>
  `/brandmanager${pageSlug ? `/${pageSlug}/page-reach` : ''}`;

export const PROMOTE_URL = (globalId: string) =>
  `/xam/aac/?entity_urn=${globalId}&asset_urn=${globalId}`;

export const XBM_URL = (pageSlug: string) =>
  `/brandmanager${pageSlug ? `/${pageSlug}/admin` : ''}`;

export const PAGE_INFO_HELP_URL = () => 'https://community.xing.com/de/s/';

export const BRANDMANAGER_URL = (slug?: string) => `/brandmanager/${slug}`;

export const EP_URL = (slug: string) => `/pages/${slug}`;
