import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { scale060, scale080, scale110 } from '@xing-com/tokens';

export const RemoveScrollBar = styled.div`
  overflow: hidden;
`;

export const CarouselMain = styled.div`
  display: flex;
  flex-wrap: nowrap;

  /* Hide horizontal scroll bar */
  margin-bottom: -25px;

  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  padding-bottom: 25px;

  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
    height: 0;
    width: 0;
  }
  -ms-overflow-style: none; /* IE 10+ */
  padding-left: 1px;
  padding-right: 1px;
  scrollbar-width: none; /* FF */

  & > * {
    flex: 0 0 auto;
    margin-right: ${scale080};
  }

  & > *:last-child {
    margin-right: 0;
    scroll-snap-align: end;
  }

  & > *:first-child {
    scroll-snap-align: start;
  }

  @media (min-width: 740px) and (max-width: 1024px) {
    & > * {
      /* width for each item is 100% - totalMargins / numOfITems */
      width: calc((100% - 2 * ${scale080}) / 3);
    }
  }

  @media (min-width: 740px) and (max-width: 1024px) {
    & > *:nth-child(n) {
      width: calc((100% - ${scale080}) / 2);
    }
  }
  @media ${mediaSmallOrTiny} {
    & > * {
      scroll-snap-align: start;
      width: 80%;
    }
  }
`;
export const Carousel = styled.div`
  position: relative;
`;

const arrow = css`
  height: ${scale110};
  min-width: ${scale110};
  position: absolute;
  top: calc(50% - ${scale060});
  width: ${scale110};

  @media ${mediaSmallOrTiny} {
    display: none;
  }
`;
export const ArrowLeft = styled(Button)`
  ${arrow}
  left: calc(-1 * ${scale060});
  & span:first-child {
    height: ${scale080};
    width: ${scale080};
  }
`;
export const ArrowRight = styled(Button)`
  ${arrow}

  right: calc(-1 * ${scale060});
  & span:first-child {
    height: ${scale080};
    width: ${scale080};
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${scale080};
`;

const snapPosition = {
  start: css`
    scroll-snap-align: start;
  `,
  center: css`
    scroll-snap-align: center;
  `,
};
export const CarouselChild = styled.div<{
  $snapStop: 'start' | 'center' | null;
}>`
  ${({ $snapStop }) => $snapStop && snapPosition[$snapStop]}
`;

export const CarouselWrapper = styled.span<{ $firstRender: boolean }>`
  ${({ $firstRender }) =>
    $firstRender &&
    `
    @media (min-width: 1025px) {
    visibility: hidden;
  }

  `}
`;
