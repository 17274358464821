import type { ErrorInfo } from 'react';
import React from 'react';

import { PageError } from '../page-error/page-error';

type ErrorBoundaryProps = {
  onError?: (_: Error, errorInfo: ErrorInfo) => void;
  children: any;
  type?: 500 | 404 | 503 | undefined;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(_: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // console.log({ _, errorInfo });
    if (this.props.onError) this.props.onError(_, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      <PageError type={this.props.type} />;
    }

    return this.props.children;
  }
}
