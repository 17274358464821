import type { FC } from 'react';

import { SkeletonBody, SkeletonImage } from '@xing-com/skeleton';

import * as Styled from './locations-edit.skeleton.styles';

export const EditDescriptionSkeleton: FC = () => (
  <div>
    <SkeletonBody amountRows={2} />
    <Styled.LoadingIntroTitle height="38" />
    <SkeletonImage height="116" />
  </div>
);
