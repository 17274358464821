import { FormattedMessage } from 'react-intl';

import { IconArrowRight, IconExternalLink } from '@xing-com/icons';

import * as Styled from './facts.styles';

type FactsItemTextProps = {
  translationKey: string;
  values: { [key: string]: string };
};

type FactsItemProps = {
  type?: string;
  values: { [key: string]: string };
  illustration: string;
  translationKey: string;
  link?: {
    url: string;
    target: string;
    rel: string;
  };
  onClick?: () => void;
  showEmptyState: boolean;
  omViewContent: string;
  trackingFunction: () => void;
};

const FactsItemText = ({ translationKey, values }: FactsItemTextProps) => {
  if (translationKey) {
    return (
      <FormattedMessage
        id={translationKey}
        defaultMessage={translationKey}
        values={values}
      />
    );
  }
  return <>{values}</>;
};

const FactsItem = ({
  type,
  values,
  illustration,
  translationKey,
  link,
  onClick,
  showEmptyState,
  trackingFunction,
}: FactsItemProps) => {
  const Tag = onClick || link ? 'a' : 'div';
  const IconLink =
    link?.target === '_blank' ? IconExternalLink : IconArrowRight;

  return (
    <Styled.FactsItemContainer
      as={Tag}
      isLink={!!(link || onClick)}
      isEmpty={showEmptyState}
      onClick={onClick}
      data-testid={type && 'FACTS_ITEM_' + type}
      {...link}
    >
      <Styled.FactsIcon src={illustration} loading="lazy" alt="Facts" />
      <Styled.FactsItemText
        noMargin
        size={'small'}
        sizeWide={'medium'}
        onClick={trackingFunction}
      >
        {Tag === 'a' ? (
          <>
            <FactsItemText translationKey={translationKey} values={values} />
            <Styled.FactsItemTextIcon
              as={IconLink}
              height={17}
              width={17}
              data-testid="aboutus-factsitem-external"
            />
          </>
        ) : (
          <FactsItemText translationKey={translationKey} values={values} />
        )}
      </Styled.FactsItemText>
    </Styled.FactsItemContainer>
  );
};

export default FactsItem;
