/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconReturn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconReturn"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8 15.707 2.293 10 8 4.293l1.414 1.414L5.122 10l4.292 4.293z"
    />
    <path
      fill="currentColor"
      d="M16 9a5 5 0 0 1 .217 9.996L16 19H13.98v-2H16a3 3 0 0 0 .176-5.995L16 11H4V9z"
    />
  </svg>
);
export default IconReturn;
