import styled from 'styled-components';

import {
  mediaSmallOrTiny,
  mediaWide,
  mediaConfined,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import {
  SkeletonImage,
  SkeletonHeadline,
  SkeletonButton,
} from '@xing-com/skeleton';
import { spaceL, spaceS, spaceXL } from '@xing-com/tokens';
import { Column } from '@xing-com/xing-grid';

export const HeaderSkeletonWrapper = styled.div`
  min-height: 100vh;
`;

export const Cover = styled.div`
  position: relative;
  padding-top: 25%; /* 8:2 ratio */
  height: 0;

  @media ${mediaSmallOrTiny} {
    padding-top: 40%; /* 5:2 ratio */
  }
`;

export const CoverSkeleton = styled(SkeletonImage)`
  position: absolute;
  top: 0;
  animation: none;
`;

export const MainArea = styled(Column)`
  position: relative;
  margin-top: -62px;
  align-items: flex-end;

  @media (min-width: 740px) and (max-width: 1024px) {
    margin-top: -82px;
  }

  @media ${mediaWide} {
    display: flex;
    margin-top: -95px;
  }
`;

export const LogoArea = styled.div`
  height: 92px;
  width: 92px;

  & svg {
    width: 100%;
    height: auto;
  }

  @media (min-width: 740px) and (max-width: 1024px) {
    height: 120px;
    width: 120px;

    & svg {
      width: 120px;
      height: 120px;
    }
  }

  @media ${mediaWideNavless} {
    height: 156px;
    width: 156px;

    & svg {
      width: 156px;
      height: 156px;
    }
  }
`;

export const SideArea = styled.div`
  flex: 1;
  @media ${mediaWideNavless} {
    margin-left: ${spaceXL};
  }
`;

export const PrimaryArea = styled.div`
  width: 30%;

  @media ${mediaSmallOrTiny} {
    margin-top: ${spaceL};
    width: 80%;
    overflow: hidden;
  }

  @media ${mediaWideNavless} {
    margin-bottom: ${spaceXL};
  }
`;

export const SecondaryArea = styled.div`
  align-items: center;
  justify-content: space-between;

  @media ${mediaSmallOrTiny} {
    margin-top: ${spaceL};
    overflow: hidden;
  }

  @media ${mediaConfined} {
    display: flex;
  }

  @media ${mediaWideNavless} {
    height: 60px;
  }
`;

export const HeadlinePrimary = styled(SkeletonHeadline)`
  @media ${mediaSmallOrTiny} {
    div {
      margin-bottom: 0;
    }
  }
`;

export const HeadlineSecondary = styled(SkeletonHeadline)`
  width: 40%;

  & > div {
    margin-bottom: 0;
  }

  @media ${mediaSmallOrTiny} {
    width: 50%;
  }
`;

export const Actions = styled.div`
  display: flex;

  @media ${mediaSmallOrTiny} {
    margin-top: ${spaceXL};
  }
`;

export const FollowButton = styled(SkeletonButton)`
  width: 120px;
  margin-right: ${spaceS};

  @media ${mediaSmallOrTiny} {
    flex: 1;
  }
`;

export const MoreButton = styled(SkeletonButton)`
  width: 42px;
`;
