import { SkeletonBody, SkeletonHeadline } from '@xing-com/skeleton';

import * as Styled from './kununu-skeleton.styles';

const KununuSkeleton = () => (
  <>
    <Styled.RatingWrapper>
      <RatingBox />
      <RatingBox />
      <Styled.RatingMain>
        <Styled.RatingMainText>
          <SkeletonBody />
        </Styled.RatingMainText>
        <Styled.RatingMainButton size="small" />
      </Styled.RatingMain>
    </Styled.RatingWrapper>
    <div>
      <KununuSubHeader />
      <Styled.CarouselWrapper>
        <Card />
        <Card />
        <Card />
      </Styled.CarouselWrapper>
    </div>
    <div>
      <KununuSubHeader />
      <Styled.TagWrapper>
        {[...Array(5)].map((_, index) => (
          // @ts-expect-error FIXME: SC6
          <Styled.Tag key={index} size="small" />
        ))}
      </Styled.TagWrapper>
    </div>
  </>
);

const RatingBox = () => (
  <Styled.Ratingbox>
    <SkeletonHeadline size="medium" amountRows={1} />
    <Styled.RatingboxBody amountRows={2} />
  </Styled.Ratingbox>
);

const KununuSubHeader = () => (
  <Styled.KununuSubHeader>
    <Styled.HeaderHeadline size="small" amountRows={1} />
  </Styled.KununuSubHeader>
);

const Card = () => (
  <Styled.Card>
    <Styled.CardHeader>
      <Styled.CardHeaderLeft amountRows={1} />
      <Styled.CardHeaderRight amountRows={1} />
    </Styled.CardHeader>
    <Styled.CardBody amountRows={3} />
    <Styled.CardFooter amountRows={1} />
  </Styled.Card>
);

export default KununuSkeleton;
