import styled from 'styled-components';

export const InputFile = styled.input`
  position: absolute;
  top: 0;
  left: -1000em;
  height: 0;
  width: 0;
  opacity: 0;
`;
