import styled from 'styled-components';

import { Button as InternalButton } from '@xing-com/button';
import { ProfileInfoAdapter as InternalProfileInfo } from '@xing-com/crate-companies-entity-pages-common';
import { mediaSmallOnly } from '@xing-com/layout-tokens';
import { PopOver as InternalPopOver } from '@xing-com/pop-over';
import { InputBar as InternalInputBar } from '@xing-com/text-field';
import {
  spaceXS,
  spaceM,
  spaceL,
  spaceXL,
  xdlColorBackgroundTertiary,
  spaceS,
  scale080,
} from '@xing-com/tokens';

export const InputSectionWrapper = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
  margin: ${spaceXL} 0;

  @media ${mediaSmallOnly} {
    flex-direction: column;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-right: ${spaceL};
  align-items: center;

  @media ${mediaSmallOnly} {
    margin-bottom: ${spaceM};
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const InputBar = styled(InternalInputBar)`
  & input {
    &:focus {
      outline: 0;
    }
  }
`;

export const NoticeWrapper = styled.div`
  width: 100%;
`;

export const PopOver = styled(InternalPopOver)`
  padding: 0;
`;

export const ProfileInfo = styled(InternalProfileInfo)`
  width: auto;
  padding: ${spaceM};

  & p {
    margin-bottom: 0;
  }

  & h2 {
    margin-bottom: 0;
  }

  & strong {
    margin-right: ${spaceS};
  }

  &:hover {
    background-color: ${xdlColorBackgroundTertiary};
  }

  &: first-child {
    &:hover {
      border-top-left-radius: ${scale080};
      border-top-right-radius: ${scale080};
    }
  }
`;

export const Button = styled(InternalButton)`
  margin: ${spaceM};
`;

export const SearchEmptyState = styled.div`
  display: flex;
  align-items: center;
  padding: ${spaceL};
`;

export const SearchErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${spaceL};
`;

export const IconWarning = styled.span`
  margin-right: ${spaceXS};
  height: 18px;
`;
