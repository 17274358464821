import { useQuery } from '@apollo/client';
import { Redirect } from '@reach/router';
import type { FC } from 'react';
import { useEffect, useState, useRef, Fragment } from 'react';
import { useIntl } from 'react-intl';

import type {
  BackLinkProps,
  EditItemMapProps,
} from '@xing-com/crate-companies-entity-pages-common';
import {
  useDialogContext,
  NotSavedDialog,
  SmallGrid,
  useEditContext,
  usePageContext,
  ErrorBoundary,
} from '@xing-com/crate-companies-entity-pages-common';
import { trackEditOpening } from '@xing-com/crate-companies-entity-pages-common/src/tracking';
// eslint-disable-next-line monorepo/forbidden-imports
import { ZenViewSubHeader } from '@xing-com/platform-layout-zen';

import { EditContextDocument } from '../../graphql/queries/editContext.gql-types';
import { useUpdateEditContext } from '../../hooks/useUpdateEditContext/useUpdateEditContext';
import { useUpdatePageContext } from '../../hooks/useUpdatePageContext/useUpdatePageContext';
import * as Styled from './edit-container.styles';

type EditContainerProps = {
  editItemProps: EditItemMapProps;
  pageSlug: string;
  isWebview?: boolean;
};
export const EditContainer: FC<EditContainerProps> = ({
  editItemProps,
  pageSlug,
  isWebview = false,
}) => {
  const intl = useIntl();
  const trackingSent = useRef(false);

  const { edit: EditItem, backLink: backLinkDefault, tracking } = editItemProps;

  const { isEditor, setTrackingData } = useEditContext();
  const { dataChanged, setDialogConfirmation, dialogConfirmation } =
    useDialogContext();
  const { pageContext } = usePageContext() ?? {};

  const [backLink, setBackLink] = useState<BackLinkProps | null>(
    backLinkDefault
  );

  const { data, loading, error } = useQuery(EditContextDocument, {
    variables: { id: pageSlug },
    errorPolicy: 'all',
  });

  const entityPage =
    data?.entityPageEX?.__typename === 'EntityPage'
      ? (data.entityPageEX ?? undefined)
      : undefined;
  const isPageContextSet = !!useUpdatePageContext(entityPage);
  const isEditContextSet = useUpdateEditContext(entityPage);

  const backLinkAction = (to?: string, action?: () => void) => {
    if (typeof to === 'string') {
      pageContext?.goBackUrl && pageContext?.goBackUrl();
    } else {
      action && action();
    }
  };

  useEffect(() => {
    setTrackingData(tracking);
  }, []);

  useEffect(() => {
    if (tracking && pageContext?.pageId && !trackingSent.current) {
      trackEditOpening({
        focusType: pageContext?.focusType,
        itemId: pageContext?.pageId,
        isCreate: tracking.isCreate || false,
        module: tracking.module,
        part: tracking.part,
      });

      trackingSent.current = true;
    }
  }, [pageContext]);

  if (
    (!isEditor && loading) ||
    !isEditContextSet ||
    !isPageContextSet ||
    !pageContext?.globalId
  ) {
    return <Fragment />;
  }

  if (
    !isEditor &&
    (data?.entityPageEX?.__typename !== 'EntityPage' ||
      !data?.entityPageEX?.userPageContext?.permissions?.canEdit ||
      error)
  ) {
    return <Redirect to={`${pageContext?.basePath}/${pageSlug}`} />;
  }

  return (
    <SmallGrid inner>
      <Styled.HeaderWrapper>
        {!isWebview && (
          <ZenViewSubHeader
            backLink={
              backLink
                ? {
                    label: intl.formatMessage({
                      id: backLink.labelKey,
                    }),
                    onClose: !backLink.disabled
                      ? dataChanged
                        ? () =>
                            setDialogConfirmation({
                              dialogAction: () => {
                                backLinkAction(backLink?.to, backLink.action);
                              },
                            })
                        : () => backLinkAction(backLink?.to, backLink.action)
                      : () => undefined,
                  }
                : undefined
            }
          />
        )}
      </Styled.HeaderWrapper>
      <ErrorBoundary>
        <EditItem
          pageSlug={pageSlug}
          setBackLink={setBackLink}
          backLinkDefault={backLinkDefault}
        />
      </ErrorBoundary>
      {dataChanged && dialogConfirmation?.dialogAction && <NotSavedDialog />}
    </SmallGrid>
  );
};
