import type { FC } from 'react';

import { ErrorState } from '../error-state/error-state';

type WarningMessageBoxProps = {
  headerText?: string;
  bodyText: string;
  buttonText?: string;
  onClick?: () => void;
  to?: () => void;
  href?: string;
};
export const WarningMessageBox: FC<WarningMessageBoxProps> = ({
  headerText,
  bodyText,
  buttonText,
  onClick,
  to,
  href,
}) => (
  <ErrorState
    headlineCopyKey={headerText}
    textCopyKey={bodyText}
    customButton
    buttonProps={{
      // @ts-expect-error TS(2322) FIXME: Type '{ copyKey: string | undefined; onClick: (() ... Remove this comment to see the full error message
      copyKey: buttonText,
      onClick,
      to,
      href,
    }}
  />
);
