/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationSpotDragnDrop = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      width={width || presetWidth}
      height={height || presetHeight}
      data-xds="IllustrationSpotDragnDrop"
      {...props}
    >
      <path
        fill="#FFFFFE"
        fillRule="evenodd"
        d="m10.699 7.958 5.26 1.022v38.458l32.435-.001-2.457 12.64L2.22 51.58zm49.263-4.959v43h-44V3z"
        clipRule="evenodd"
      />
      <path
        fill="#B7E854"
        fillRule="evenodd"
        d="m58.17 25.04 1.872 1.855.03 20.068-9.423.01-15.244-15.206 3.428-3.416 7.154-2.403 6.459 3.482zM39.326 37.735l8.888 8.868H16.652v-6.426l16.102 2.955z"
        clipRule="evenodd"
      />
      <path
        fill="var(--xdlColorIllustrationOutlinePrimary)"
        d="M61.495 2v9.919h1.52v38.058H50.57l-2.52 12.972L6.42 54.857l.27-1.391-5.642-1.096L9.908 6.786l5.051.982V2zM11.49 9.131 3.391 50.788l41.754 8.116 1.734-8.927H20.604v-1.54h-5.645V9.805zm17.014 18.107L16.958 38.76l.001 7.677h30.784zm21.642-9.388-14.11 14.078 14.54 14.509h8.918V27.161zM59.494 4H16.96v31.935l11.546-11.521 6.115 6.102 15.524-15.49 9.35 9.313z"
      />
      <path
        fill="#27DBA8"
        fillRule="evenodd"
        d="M61.953 54v5h5v2h-5v5h-2v-5h-5v-2h5v-5zM30.44 13.94a4.353 4.353 0 0 1-4.358 4.348 4.353 4.353 0 0 1-4.358-4.348 4.353 4.353 0 0 1 4.358-4.35 4.353 4.353 0 0 1 4.358 4.35"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default IllustrationSpotDragnDrop;
