// Property keys
export const PROP_CHANNEL = 'PropChannel';

// Channels
export const CHANNEL_ENTITY_PAGES = 'wbm/EntityPages';
export const PAGENAME = 'wbm/EntityPages/pages';
export const SUBPAGE = 'wbm/EntityPages/pages/subpage';
// Events
export const PROP_TRACK_ACTION = 'PropTrackAction';
export const PROP_SCROLLING = 'PropScrolling';

// Actions
export const ENTITY_PAGE_HEADER_MORE_ACTIONS =
  'entity_page_header_more_actions';
export const ENTITY_PAGE_NEWS_FOLLOW = 'EventFollow';
export const ENTITY_PAGE_NEWS_UNFOLLOW = 'EventUnfollow';
export const ENTITY_PAGE_CHANGE_ACTOR_TYPE = 'EventChangeActorType';
export const ENTITY_PAGE_EDITING_OPEN = 'EventEntityPageEditingOpen';
export const ENTITY_PAGE_EDITING_SAVE = 'EventEntityPageEditingSave';

export const ENTITY_PAGE_NEWS_ARTICLE_VIEW = 'EventNewsArticleView';
export const ENTITY_PAGE_MODULE = 'entity_page_module';
export const COMMBOX_AUDIENCE_SELECT = 'entity_page_commbox_audience_select';
export const COMMBOX_OPEN_CHARACTER_OVERFLOW =
  'entity_page_commbox_character_limit_reached';
export const COMMBOX_OPEN_AUDIENCE_SELECTION =
  'entity_page_commbox_audience_open';
export const COMMBOX_SWITCH_ACTOR = 'entity_page_commbox_actor_switch_select';
export const COMMBOX_OPEN_ACTOR_SWITCH =
  'entity_page_commbox_actor_switch_open';
export const CONTACT_OPEN_MAP_MAIN = 'entity_page_main_location_open_maps';
export const CONTACT_CLICK_PHONE_MAIN =
  'entity_page_main_contact_open_phonecall';
export const CONTACT_CLICK_PHONE_SUBPAGE =
  'entity_page_sub_contact_open_phonecall';
export const CONTACT_CLICK_EMAIL_MAIN = 'entity_page_main_contact_open_email';
export const CONTACT_CLICK_EMAIL_SUBPAGE = 'entity_page_sub_contact_open_email';
export const CONTACT_CLICK_WEBPAGE_MAIN =
  'entity_page_main_contact_open_webpage';
export const CONTACT_CLICK_WEBPAGE_SUBPAGE =
  'entity_page_sub_contact_open_webpage';
// employees
export const ENTITY_PAGE_EMPLOYEES_ADD_CONTACT =
  'EventContactsContactRequestSent';
export const ENTITY_PAGE_ABOUT_US_WEBSITE = 'entity_page_about_us_website';

// Property keys
export const ENTITY_PAGE_FOLLOW = 'EventFollow';
export const ENTITY_PAGE_UNFOLLOW = 'EventUnfollow';
export const PROP_PAGE_NAME = 'pageName';
export const PROP_MODULES = 'PropModules';
export const PROP_ITEM_ID = 'PropItemId';

export const PROP_ENTITY_PAGE_ID = 'PropEntityPagesId';
export const PROP_CONTEXT_DIMENSION1 = 'PropContextDimension1';
export const PROP_CONTEXT_DIMENSION2 = 'PropContextDimension2';
export const PROP_CONTEXT_DIMENSION3 = 'PropContextDimension3';
export const PROP_CONTEXT_DIMENSION4 = 'PropContextDimension4';
export const PROP_INTERACTION_TYPE = 'PropInteractionType';
export const PROP_PAGINATION = 'PropPagination';
export const PROP_NEWS_PUBLISHER_ID = 'PropNewsPublisherId';
export const PROP_ACTION_ORIGIN = 'PropActionOrigin';
export const PROP_SEARCH_LIST_FILTER = 'PropSearchListFilter';
export const PROP_FORM_LIST = 'PropFormList';
export const PROP_ACTOR_TYPE = 'PropActorType';

// Types
export const TYPE_EVENT = 'event';
export const TYPE_PAGEVIEW = 'pageview';
