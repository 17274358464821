/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconPublic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconPublic"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.895 2.79c-5.08-2.15-10.944.223-13.1 5.303-2.158 5.083.214 10.954 5.298 13.112s10.954-.214 13.112-5.298c2.156-5.08-.21-10.945-5.285-13.107l-.012-.005zm-.774 1.844.005.002.006.002c.207.09.434.296.624.734.194.45.32 1.084.334 1.892.024 1.382-.286 3.128-.961 4.978L9.653 9.917c.862-1.77 1.902-3.206 2.913-4.15.59-.55 1.135-.9 1.593-1.073.447-.168.753-.147.961-.06m2.952 2.156q.015.218.017.44c.029 1.698-.352 3.725-1.118 5.794l2.726 1.157a8 8 0 0 0-1.625-7.391m.843 9.232-2.725-1.157c-.957 1.989-2.15 3.67-3.391 4.829q-.163.152-.33.294a8 8 0 0 0 6.446-3.966M5.927 17.21a10 10 0 0 1-.017-.44c-.028-1.699.352-3.725 1.118-5.794L4.302 9.819a8 8 0 0 0 1.625 7.391m-.843-9.232L7.81 9.135c.956-1.989 2.15-3.67 3.39-4.83q.164-.151.33-.293a8 8 0 0 0-6.446 3.966m6.35 10.254c1.011-.943 2.051-2.379 2.913-4.15l-5.475-2.324c-.675 1.85-.985 3.596-.962 4.978.013.807.14 1.443.334 1.892.192.442.421.647.63.736s.516.111.968-.058c.458-.172 1.002-.523 1.593-1.074"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPublic;
