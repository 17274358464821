import { Text } from 'slate';

export const getNodeChildrenText = (children: any[]) =>
  children.map(({ text }) => text);

const serialize = (node: any) => {
  if (Text.isText(node)) return node.text;
  if (node.type === 'mention') return getNodeChildrenText(node.children);

  const children = node.children.map((n: any) => serialize(n)).join('');
  return children;
};

export const serializeInput = (nodes: any[]) =>
  nodes.map((n) => serialize(n)).join('\n');
