import {
  PROP_ACTION_ORIGIN,
  PROP_TRACK_ACTION,
  PROP_INTERACTION_TYPE,
  ENTITY_PAGE_FOLLOW,
  ENTITY_PAGE_UNFOLLOW,
  ENTITY_PAGE_ABOUT_US_WEBSITE,
} from '../constants';
import { trackAction } from '../tracker';

export const trackAboutUsWebsite = () =>
  trackAction({ [PROP_TRACK_ACTION]: ENTITY_PAGE_ABOUT_US_WEBSITE });

export const trackOpenGallery = (origin: string) =>
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_about_us_open_gallery',
    [PROP_ACTION_ORIGIN]: origin,
  });

export const trackAffiliatesFollowAction = (hasFollowed?: boolean) =>
  trackAction({
    [hasFollowed ? ENTITY_PAGE_FOLLOW : ENTITY_PAGE_UNFOLLOW]: 1,
    [PROP_INTERACTION_TYPE]: hasFollowed
      ? 'entity_page_affiliate_follow'
      : 'entity_page_affiliate_unfollow',
  });

export const trackAwardAction = (origin: string) =>
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_about_us_award',
    [PROP_ACTION_ORIGIN]: origin,
  });

export const trackAboutusDescriptionReadMoreAction = () =>
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_about_us_read_more',
  });

export const trackDownloadDocument = () =>
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_about_us_download',
  });
