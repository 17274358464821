import styled, { css } from 'styled-components';

import {
  zIndexElevatedLayer5,
  mediaTopBarHidden,
} from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import {
  scale040,
  scale150,
  scale170,
  scale220,
  spaceS,
  spaceXL,
  xdlColorBackground,
  xdlColorBorderSoft,
} from '@xing-com/tokens';
import { Headline, Meta } from '@xing-com/typography';

import type { TopBarType } from './top-bar-types';

export const TopBarContainer = styled.div<{
  $showOnLargerScreens?: boolean;
  $type?: TopBarType;
  $isScrolling?: boolean;
}>`
  align-items: center;
  background: ${xdlColorBackground};
  border-bottom: 1px solid
    ${({ $isScrolling }) => ($isScrolling ? 'transparent' : xdlColorBorderSoft)};
  display: flex;
  height: ${scale170};
  justify-content: space-between;
  left: 0;
  padding: 0 ${scale040};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: calc(${zIndexElevatedLayer5} - 50);

  @media ${mediaTopBarHidden} {
    display: ${({ $showOnLargerScreens }) =>
      $showOnLargerScreens ? 'flex' : 'none'};
    margin-top: ${spaceXL};
    position: static;
  }
`;

export const PrimaryActions = styled.div<{ $type?: TopBarType }>`
  display: flex;
  justify-content: ${({ $type }) =>
    $type === 'subheaderL' || $type === 'subheaderFlex'
      ? 'flex-end'
      : 'center'};
  align-items: right;
  max-width: ${({ $type }) => $type === 'subheaderFlex' && 'scale150'};
  width: ${({ $type }) =>
    $type === 'subheaderS'
      ? scale150
      : $type === 'subheaderL'
        ? scale220
        : $type === 'subheaderFlex'
          ? 'min-content'
          : spaceS};
  min-height: ${scale170};
  margin-left: ${scale040};
  padding: ${({ $type }) =>
    $type === 'subheaderL' || $type === 'subheaderFlex' ? spaceS : '0'};
  > * {
    margin-left: ${({ $type }) => $type === 'subheaderFlex' && spaceS};
  }
`;

export const SecondaryActions = styled.div<{ $type?: TopBarType }>`
  display: flex;
  justify-content: ${({ $type }) =>
    $type === 'subheaderL' ? 'flex-start' : 'center'};
  align-items: center;
  width: ${({ $type }) =>
    $type === 'subheaderS' || $type === 'subheaderFlex'
      ? scale150
      : $type === 'subheaderL'
        ? scale220
        : spaceS};
  min-height: ${scale170};
  margin-right: ${scale040};
`;

export const H2 = styled(Headline)`
  margin: 0;
  overflow-x: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const H3 = styled(Meta)`
  margin: 0;
  overflow-x: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SubHeaderContainer = styled.div`
  display: grid;
`;

export const SubHeaderButton = styled.button`
  background-color: transparent;
  border: 0 none;
  color: inherit;
  cursor: pointer;
  display: grid;
  padding: 0;
  text-decoration: none;
`;

export const SubHeaderLink = styled(Link)<{
  to: string;
}>`
  color: inherit;
  display: grid;

  &:hover {
    text-decoration: none;
  }
`;

export const Placebo = styled.div<{
  $showOnLargerScreens?: boolean;
}>`
  height: ${scale170};
  background: transparent;

  ${({ $showOnLargerScreens }) =>
    $showOnLargerScreens
      ? css``
      : css`
          @media ${mediaTopBarHidden} {
            display: none;
          }
        `};
`;
