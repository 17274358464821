import { FormattedMessage } from 'react-intl';

import { Expandable } from '@xing-com/crate-companies-entity-pages-common';
import {
  IconNotificationCenter,
  IconHide,
  IconBusinessSolutions,
} from '@xing-com/icons';

import * as Styled from './ideal-employer.styles';

const RenderList = () => (
  <Styled.OuterWrapper data-testid={'IDEAL_EMPLOYER_DROPDOWN_CONTENT'}>
    <Styled.ListWrapper>
      <Styled.ListItem className="listItem">
        <Styled.Icon>
          <IconNotificationCenter width={24} height={24} />
        </Styled.Icon>
        <Styled.ListText noMargin size={'xsmall'}>
          <FormattedMessage
            id={'EP_IDEAL_EMPLOYER_EI_NON_COMMITTAL'}
            defaultMessage={'EP_IDEAL_EMPLOYER_EI_NON_COMMITTAL'}
          />
        </Styled.ListText>
      </Styled.ListItem>
      <Styled.ListItem className="listItem">
        <Styled.Icon>
          <IconBusinessSolutions width={24} height={24} />
        </Styled.Icon>
        <Styled.ListText noMargin size={'xsmall'}>
          <FormattedMessage
            id={'EP_IDEAL_EMPLOYER_EI_POTENTIAL'}
            defaultMessage={'EP_IDEAL_EMPLOYER_EI_POTENTIAL'}
          />
        </Styled.ListText>
      </Styled.ListItem>
      <Styled.ListItem className="listItem">
        <Styled.Icon>
          <IconHide width={24} height={24} />
        </Styled.Icon>
        <Styled.ListText noMargin size={'xsmall'}>
          <FormattedMessage
            id={'EP_IDEAL_EMPLOYER_EI_CONFIDENTIAL'}
            defaultMessage={'EP_IDEAL_EMPLOYER_EI_CONFIDENTIAL'}
          />
        </Styled.ListText>
      </Styled.ListItem>
    </Styled.ListWrapper>
  </Styled.OuterWrapper>
);

const IdealEmployerExtraInfo = () => (
  <>
    <Styled.ListDesktop>
      <RenderList />
    </Styled.ListDesktop>
    <Styled.DropdownWrapper>
      <Expandable
        data-testid={'IDEAL_EMPLOYER_DROPDOWN'}
        className="dropdown"
        headerContent={
          <div>
            <Styled.DropdownText noMargin size={'xsmall'}>
              <FormattedMessage
                id={'EP_IDEAL_EMPLOYER_EI_HOW'}
                defaultMessage={'EP_IDEAL_EMPLOYER_EI_HOW'}
              />
            </Styled.DropdownText>
          </div>
        }
      >
        <RenderList />
      </Expandable>
    </Styled.DropdownWrapper>
  </>
);

export default IdealEmployerExtraInfo;
