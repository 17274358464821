import styled from 'styled-components';

import { mediaWideNavless } from '@xing-com/layout-tokens';
import { ProfileImage } from '@xing-com/profile-image';
import { scale110 } from '@xing-com/tokens';

export const LogoArea = styled.div`
  position: relative;
  width: 90px;
  height: 90px;
  margin-right: ${scale110};

  @media (min-width: 740px) and (max-width: 1024px) {
    width: 120px;
    height: 120px;
  }

  @media ${mediaWideNavless} {
    width: 156px;
    height: 156px;
  }
`;

export const LogoImageContainer = styled(ProfileImage)`
  display: block;
`;
