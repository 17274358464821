/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from 'react';

import * as Styled from './slider.styles';
import type { SliderProps } from './slider.types';

export const Slider = React.forwardRef<HTMLSpanElement, SliderProps>(
  (
    { className, min = 0, max = 100, step, marks, ...props },
    forwardedRef
  ): JSX.Element => {
    const internalRef = React.useRef<HTMLElement>(null);

    React.useImperativeHandle(forwardedRef, () => {
      if (internalRef.current) {
        return internalRef.current;
      }

      // Return a minimal implementation if internalRef.current is null
      return {
        focus: () => {},
        blur: () => {},
      } as HTMLSpanElement;
    }, []);

    const isInputElement = (
      element: EventTarget | null
    ): element is HTMLInputElement => {
      return element instanceof HTMLInputElement;
    };

    const onThumbClick = React.useCallback((event: Event): void => {
      if (isInputElement(event.target)) {
        event.target.classList.add('Mui-focusVisible');
      }
    }, []);

    /** This is a bugfix for an edgecase on the Mui Slider
     *  https://github.com/mui-org/material-ui/issues/29506
     *
     * In some rare cases (hot reloading, clicking link inside the page) the
     * .Mui-focusVisible class gets removed after dragging. This fix will add
     * it back on the currently active thumb.
     */
    React.useEffect(() => {
      if (internalRef.current === null) return;

      const thumbs = internalRef.current.querySelectorAll('.MuiSlider-thumb');

      thumbs.forEach((thumb) => {
        thumb.addEventListener('click', onThumbClick);
      });

      return () => {
        thumbs.forEach((thumb) => {
          thumb.removeEventListener('click', onThumbClick);
        });
      };
    }, [onThumbClick]);

    const calculateMarksLength = (): number | undefined => {
      if (typeof marks === 'object') {
        return marks.length - 1;
      } else if (typeof marks === 'boolean') {
        return Math.floor((max - min) / (step || 1));
      } else {
        return undefined;
      }
    };

    return (
      <Styled.Slider
        $marksLength={calculateMarksLength()}
        className={className}
        data-xds="Slider"
        marks={marks}
        max={max}
        min={min}
        ref={internalRef}
        step={step}
        {...props}
      />
    );
  }
);

Slider.displayName = 'Slider';
