import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import type { ScaledImage } from '@xing-com/crate-common-graphql-types';

type PreloadCoverProps = {
  covers: ScaledImage[];
};
export const PreloadCover: FC<PreloadCoverProps> = ({ covers }) => {
  if (covers[0]?.url && covers[1]?.url) {
    return (
      <Helmet>
        <link
          rel="preload"
          href={covers[0].url}
          as="image"
          media="(max-width: 600px)"
        />
        <link
          rel="preload"
          href={covers[1].url}
          as="image"
          media="(min-width: 601px)"
        />
      </Helmet>
    );
  }

  return null;
};
