import { useQuery } from '@apollo/client';
import type { FC } from 'react';

import {
  EmptyState,
  ModuleHeader,
  UpsellBannerWithIcon,
  useEditContext,
  usePageContext,
  WarningMessageBox as Error,
} from '@xing-com/crate-companies-entity-pages-common';

import type { MediaFragment } from '../../../graphql/fragment/about-us-media-fragment.gql-types';
import { AboutUsSubpageMediaRefetchQueryDocument } from '../../../graphql/queries/about-us-subpage-media-refetch-query.gql-types';
import EmptyStateIcon from './assets/video-photo-upload.svg';
import { GalleryPreview } from './gallery-preview';

type GalleryPreviewContainerProps = {
  media: MediaFragment;
  pageSlug: string;
  mediaItemsNumber: number;
  upsellActive?: boolean;
};

export const GalleryPreviewContainer: FC<GalleryPreviewContainerProps> = ({
  media: initialMedia,
  pageSlug,
  mediaItemsNumber,
  upsellActive,
}) => {
  const { isEditor } = useEditContext();
  const { pageContext } = usePageContext() ?? {};
  const { data, refetch: refetchMedia } = useQuery(
    AboutUsSubpageMediaRefetchQueryDocument,
    {
      variables: {
        id: pageSlug,
        first: mediaItemsNumber,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    }
  );

  const aboutUsEntityPage =
    data?.pagesAboutUs?.__typename === 'AboutEntity'
      ? data?.pagesAboutUs
      : null;

  const media = aboutUsEntityPage?.media || initialMedia;
  const galleryItemNodes =
    media?.__typename === 'EntityMediaConnection' ? media.edges : [];
  const galleryItemsEdges = galleryItemNodes?.map((edge) => edge?.node);
  const galleryItemsNodes = galleryItemsEdges?.filter((node) => !!node);

  if (!galleryItemsNodes) {
    return (
      <div data-testid="aboutus-detail-media">
        <ModuleHeader headlineCopyKey="EP_MEDIA_HEADLINE" />
        <Error
          headerText="EP_ERROR_HEADER"
          bodyText="EP_ERROR_BODY"
          buttonText="EP_ERROR_RELOAD_CTA"
          onClick={() => refetchMedia()}
        />
      </div>
    );
  }

  if (upsellActive) {
    return (
      <div data-testid="aboutus-detail-media">
        <ModuleHeader headlineCopyKey="EP_MEDIA_HEADLINE" />
        <UpsellBannerWithIcon
          headerCopyKey="EP_UPSELL_GALLERY_ABOUT_US_HEADER"
          bodyCopyKey="EP_UPSELL_GALLERY_ABOUT_US"
          moduleName="aboutusmodule"
        />
      </div>
    );
  }

  if (galleryItemsNodes?.length === 0 && isEditor) {
    return (
      <div data-testid="aboutus-detail-media">
        <ModuleHeader headlineCopyKey="EP_MEDIA_HEADLINE" />
        <EmptyState
          headlineCopyKey="EP_EDIT_GALLERY_EMPTY_STATE_HEADER"
          textCopyKey="EP_EDIT_GALLERY_EMPTY_STATE_BODY"
          buttonProps={[
            {
              // @ts-expect-error FIXME: SC6
              copyKey: 'EP_EDIT_GALLERY_EMPTY_STATE_CTA_IMAGE',
              to: `${pageContext?.basePath}/${pageSlug}/edit/gallery?type=image`,
            },
            {
              // @ts-expect-error FIXME: SC6
              copyKey: 'EP_EDIT_GALLERY_EMPTY_STATE_CTA_VIDEO',
              to: `${pageContext?.basePath}/${pageSlug}/edit/gallery?type=video`,
            },
          ]}
          icon={EmptyStateIcon}
        />
      </div>
    );
  }

  if (galleryItemsNodes?.length) {
    return (
      <div data-testid="aboutus-detail-media">
        <ModuleHeader headlineCopyKey="EP_MEDIA_HEADLINE" />
        <GalleryPreview
          pageSlug={pageSlug}
          // @ts-expect-error TODO: fix this type
          galleryItems={galleryItemsNodes}
        />
      </div>
    );
  }

  return null;
};
