import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { redirectToRegister } from '@xing-com/crate-companies-entity-pages-common';

import * as Styled from './employees-card-vertical-container.styles';

type EmployeeCardFencingProps = {
  employeesNumber?: number;
};
export const EmployeeCardFencing: FC<EmployeeCardFencingProps> = ({
  employeesNumber = 100,
}) => (
  <Styled.CardBlurBackground>
    <Styled.RegisterText fontWeight="bold" lineHeight="dense" size={'medium'}>
      <FormattedMessage
        values={{ employeesNumber }}
        id="EP_EMPLOYEES_TEXT_CTA_REGISTER_TO_SEE"
      />
    </Styled.RegisterText>
    <Button
      variant={'primary'}
      size={'small'}
      data-testid="register-button"
      onClick={() => redirectToRegister()}
    >
      <FormattedMessage id="EP_EMPLOYEES_BUTTON_REGISTER_NOW" />
    </Button>
  </Styled.CardBlurBackground>
);
