import { useLocation } from '@reach/router';
import type { FC } from 'react';

import {
  redirectToLogin,
  useLoggedoutAction,
} from '@xing-com/crate-companies-entity-pages-common';
import { trackDownloadDocument } from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { IconFilePDF } from '@xing-com/icons';

import * as Styled from './documents.styles';

const onDocumentClick = (isLoggedIn: boolean, documentUrl: string) =>
  !isLoggedIn
    ? redirectToLogin('openDocument', {
        documentUrl: encodeURIComponent(documentUrl),
      })
    : window.open(documentUrl, '_blank') && trackDownloadDocument();

type Document = {
  description?: string;
  documentUrl: string;
  filename: string;
};
type DocumentsProps = {
  documents: Document[];
};
export const Documents: FC<DocumentsProps> = ({ documents }) => {
  const { isLoggedIn } = useLoginState();
  const location = useLocation();

  useLoggedoutAction(() => {
    const encodedUrl = location?.search?.split('documentUrl=')[1];
    if (encodedUrl) {
      window.open(decodeURIComponent(encodedUrl), '_blank');
      trackDownloadDocument();
    }
  }, 'openDocument');

  return (
    <>
      {documents.map(
        ({ description, filename, documentUrl }, index: number) => (
          <Styled.Button
            key={index}
            variant={'tertiary'}
            size={'small'}
            icon={IconFilePDF}
            onClick={() => onDocumentClick(isLoggedIn, documentUrl)}
            // @ts-expect-error FIXME: SC6
            target={'_blank'}
            data-testid="documents-item"
          >
            <Styled.TruncatedText>
              {description ?? filename}
            </Styled.TruncatedText>
          </Styled.Button>
        )
      )}
    </>
  );
};
