import type { PropsWithChildren } from 'react';
import type React from 'react';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { IconArrowDown, IconArrowUp } from '@xing-com/icons';

import * as Styled from './expandable.styles';

type ExpandableProps = {
  className?: string;
  headerContent?: React.ReactNode;
};

export const Expandable: React.FC<PropsWithChildren<ExpandableProps>> = ({
  children,
  className,
  headerContent,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandableElement = useRef<HTMLDivElement>(null);

  const scrollToContent = useCallback(() => {
    if (expandableElement.current) {
      // Scroll until all expandable content is visible on viewport
      expandableElement.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExpanded]);

  const toggle = () => {
    setIsExpanded((value) => !value);
  };

  const handleKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      toggle();
    }
  };

  useLayoutEffect(() => {
    if (isExpanded) {
      scrollToContent();
    }
  }, [isExpanded, scrollToContent]);

  const arrowIcon = isExpanded ? IconArrowUp : IconArrowDown;

  return (
    <Styled.ExpandableWrapper
      ref={expandableElement}
      className={className}
      {...props}
    >
      <Styled.TitleWrapper
        role="tab"
        aria-expanded={isExpanded}
        tabIndex={0}
        onClick={toggle}
        onKeyUp={handleKey}
      >
        {headerContent}
        <Styled.IconButton icon={arrowIcon} size="medium" />
      </Styled.TitleWrapper>
      <Styled.ExpandableContent
        aria-hidden={isExpanded}
        isExpanded={isExpanded}
      >
        {children}
      </Styled.ExpandableContent>
    </Styled.ExpandableWrapper>
  );
};
