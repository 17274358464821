import type { FC } from 'react';

import { SkeletonHeadline, SkeletonBody } from '@xing-com/skeleton';

import * as Styled from './skeleton.styles';

export const AboutUsSkeleton: FC = () => (
  <Styled.Container data-testid="aboutus-detail-skeleton">
    <Styled.Summary>
      <SkeletonHeadline amountRows={2} />
      <SkeletonBody amountRows={6} />
    </Styled.Summary>
    <Styled.Facts>
      <Styled.FactsItem size="small" amountRows={2} />
      <Styled.FactsItem size="small" amountRows={2} />
      <Styled.FactsItem size="small" amountRows={2} />
    </Styled.Facts>
  </Styled.Container>
);
