import { useQuery, NetworkStatus } from '@apollo/client';
import type React from 'react';

import type { RenderErrorProps } from '@xing-com/crate-companies-entity-pages-common';
import {
  LazyLoading,
  LAZY_LOADING_TYPE_BUTTON,
  ModuleHeader,
  useEditContext,
  usePageContext,
  EmptyState,
  UpsellBanner,
  WarningMessageBox as Error,
} from '@xing-com/crate-companies-entity-pages-common';

import { LIMIT_AFFILIATES_LIST } from '../../config';
import { AffiliatesDocument } from '../../graphql/queries/affiliates-query.gql-types';
import { AffiliatesItemContainer } from './affiliates-item-container';
import AffiliatesItemSkeleton from './affiliates-item-skeleton';
import * as Styled from './affiliates.styles';

type AffiliatesProps = {
  upsellActive?: boolean;
  pageSlug: string;
  companyId?: string;
};
const RenderError: React.FC<RenderErrorProps> = ({ refetch }) => (
  <div data-testid="errorContainer">
    <ModuleHeader headlineCopyKey="EP_AFFILIATES" />
    <Error
      headerText="EP_ERROR_HEADER"
      bodyText="EP_ERROR_BODY"
      buttonText="EP_ERROR_RELOAD_CTA"
      onClick={() => refetch()}
    />
  </div>
);

const RenderLoading: React.FC = () => (
  <>
    <ModuleHeader headlineCopyKey="EP_AFFILIATES" />
    <Styled.AboutUsDetailsContainer data-testid="loadingContainer">
      <>
        <AffiliatesItemSkeleton />
        <AffiliatesItemSkeleton />
        <AffiliatesItemSkeleton />
      </>
    </Styled.AboutUsDetailsContainer>
  </>
);

export const Affiliates: React.FC<AffiliatesProps> = ({
  pageSlug,
  upsellActive,
}) => {
  const { isEditor } = useEditContext();
  const { pageContext } = usePageContext() ?? {};
  const isFreePage = pageContext?.contractType === 'FREE';

  const { data, loading, networkStatus, fetchMore, refetch } = useQuery(
    AffiliatesDocument,
    {
      variables: { id: pageSlug, limit: LIMIT_AFFILIATES_LIST },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    }
  );

  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  const queryHasError =
    !loading &&
    !isFreePage &&
    // @ts-expect-error TODO: fix this type
    !data?.pagesAboutUsFacts?.companyData?.affiliates;

  const aboutUsEntityPage =
    data?.pagesAboutUsFacts?.__typename === 'CompanyAboutUsFacts'
      ? data.pagesAboutUsFacts
      : null;

  if (queryHasError) {
    return <RenderError refetch={refetch} />;
  }

  if (loading && !data) {
    return <RenderLoading />;
  }

  const affiliatesData = aboutUsEntityPage?.companyData?.affiliates;
  const isEmpty = affiliatesData?.total === 0;

  if ((isEmpty && !isEditor) || !affiliatesData) return null;

  const { hasNextPage, endCursor } = affiliatesData.pageInfo;

  const handleOnLazyLoad = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          pagesAboutUsFacts: {
            ...prev.pagesAboutUsFacts,
            companyData: {
              // @ts-expect-error TODO: fix this type
              ...prev.pagesAboutUsFacts?.companyData,
              affiliates: {
                // @ts-expect-error TODO: fix this type
                ...prev.pagesAboutUsFacts?.companyData.affiliates,
                edges: [
                  // @ts-expect-error TODO: fix this type
                  ...(prev?.pagesAboutUsFacts?.companyData?.affiliates?.edges ??
                    []),
                  // @ts-expect-error TODO: fix this type
                  ...fetchMoreResult.pagesAboutUsFacts.companyData.affiliates
                    .edges,
                ],
                pageInfo:
                  // @ts-expect-error TODO: fix this type
                  fetchMoreResult.pagesAboutUsFacts.companyData.affiliates
                    .pageInfo,
              },
            },
          },
        };
      },
    });
  };

  return (
    <Styled.Wrapper data-testid="aboutus-affiliates">
      <>
        <ModuleHeader
          headlineCopyKey="EP_AFFILIATES"
          editTo={
            upsellActive
              ? null
              : `${pageContext?.basePath}/${pageSlug}/edit/affiliates?type=short`
          }
          editTestId="edit-affiliates"
        />
        {upsellActive ? (
          <Styled.UpsellLayout>
            <UpsellBanner
              headerCopyKey="EP_AFFILIATES_UPSELL_HEADLINE"
              bodyCopyKey="EP_AFFILIATES_UPSELL_BODY"
              moduleName="affiliates"
            />
          </Styled.UpsellLayout>
        ) : null}

        {!upsellActive && isEmpty ? (
          <EmptyState
            headlineCopyKey="EP_AFFILIATES_NO_DATA_HEADER"
            textCopyKey="EP_AFFILIATES_NO_DATA_DESCRIPTION"
            icon=""
          />
        ) : null}

        {!upsellActive && !isEmpty ? (
          <Styled.AboutUsDetailsContainer
            data-cy="affiliatesModule"
            data-testid="affiliates-item-list"
          >
            <>
              {affiliatesData?.edges
                ?.filter((edge) => edge?.node?.affiliateCompany)
                .map((edge) => {
                  const { affiliateCompany, affiliateCategory } =
                    edge?.node ?? {};

                  return (
                    <AffiliatesItemContainer
                      key={affiliateCompany?.id}
                      // @ts-expect-error TODO: fix this type
                      id={affiliateCompany?.id}
                      // @ts-expect-error TODO: fix this type
                      logo={affiliateCompany?.logos.logo64px}
                      // @ts-expect-error TODO: fix this type
                      url={affiliateCompany?.links.public}
                      // @ts-expect-error TODO: fix this type
                      displayName={affiliateCompany?.companyName}
                      // @ts-expect-error TODO: fix this type
                      category={affiliateCategory.localizationValue}
                      pageSlug={pageSlug}
                      // @ts-expect-error TODO: fix this type
                      followers={affiliateCompany?.followers?.total}
                      // @ts-expect-error TODO: fix this type
                      isFollowing={
                        affiliateCompany?.userContext?.followState?.isFollowing
                      }
                    />
                  );
                })}
            </>
          </Styled.AboutUsDetailsContainer>
        ) : null}

        <LazyLoading
          type={LAZY_LOADING_TYPE_BUTTON}
          isLoading={isFetchingMore}
          enabled={hasNextPage}
          onLazyLoad={handleOnLazyLoad}
        />
      </>
    </Styled.Wrapper>
  );
};
