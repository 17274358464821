import { useQuery } from '@apollo/client';
import type { FC } from 'react';

import {
  ModuleHeader,
  UpsellBanner,
  usePageContext,
  WarningMessageBox as Error,
} from '@xing-com/crate-companies-entity-pages-common';

import { ContactsDocument } from '../../graphql/contacts-query.gql-types';
import { normalizeData } from '../../helpers';
import { Contacts } from '../contacts/contacts';

type ContactsDetailContainerProps = {
  pageSlug: string;
};
const ContactsDetailContainer: FC<ContactsDetailContainerProps> = ({
  pageSlug,
}) => {
  const { pageContext } = usePageContext() ?? {};
  const upsellActive = pageContext?.upsellActive;

  const { data, loading, refetch } = useQuery(ContactsDocument, {
    variables: { id: pageSlug },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const { userList, currentUser } = normalizeData(data);

  if (loading && userList.length === 0) {
    return (
      <>
        <ModuleHeader
          editTestId="EDIT_BUTTON_ON_CONTACTS"
          headlineCopyKey="EP_WHO_TO_CONTACT"
          editTo={`${pageContext?.basePath}/${pageSlug}/edit/contacts`}
          small
        />
        <Contacts.Skeleton />
      </>
    );
  }

  if (!loading && userList.length === 0) {
    return (
      <>
        <ModuleHeader
          editTestId="EDIT_BUTTON_ON_CONTACTS"
          headlineCopyKey="EP_WHO_TO_CONTACT"
          editTo={`${pageContext?.basePath}/${pageSlug}/edit/contacts`}
          small
        />
        <div data-testid={'errorContainer'}>
          <Error
            headerText="EP_ERROR_HEADER"
            bodyText="EP_ERROR_BODY"
            buttonText="EP_ERROR_RELOAD_CTA"
            onClick={refetch}
          />
        </div>
      </>
    );
  }

  return (
    <>
      {upsellActive && (
        <UpsellBanner
          headerCopyKey="EP_UPSELL_CONTACT_HEADER"
          bodyCopyKey="EP_UPSELL_CONTACT_BODY"
          moduleName="contactsmodule"
        />
      )}
      <ModuleHeader
        editTestId="EDIT_BUTTON_ON_CONTACTS"
        headlineCopyKey="EP_WHO_TO_CONTACT"
        editTo={`${pageContext?.basePath}/${pageSlug}/edit/contacts`}
        small
      />

      <Contacts
        currentUser={currentUser}
        users={userList}
        messageEntryPoint={'entity_pages_sub_contact_message'}
        trackingModuleType="Subpage"
      />
    </>
  );
};

export default ContactsDetailContainer;
