import GoogleMaps from 'google-map-react';
import type { FC } from 'react';
import { useState } from 'react';

import MarkerSVG from '../../assets/marker.svg';
import { GOOGLE_MAPS_API_KEY } from '../../config';
import * as Styled from './map.styles';

const options = {
  disableDefaultUI: true,
  draggable: false,
  zoomControl: false,
  scrollwheel: false,
  disableDoubleClickZoom: false,
  draggableCursor: 'default',
  clickableIcons: false,
};

const Marker: FC = () => (
  <Styled.Marker>
    <img src={MarkerSVG} alt="marker" />
  </Styled.Marker>
);

type MapProps = {
  lat: number;
  lng: number;
};
export const Map: FC<MapProps> = ({ lat, lng }) => {
  const [loaded, setLoaded] = useState(false);

  const onGoogleApiLoaded = (maps: any) => {
    if (maps.map) {
      // maps.map.panBy(0, 40);
      setLoaded(true);
    }
  };

  return (
    <Styled.MapWrapper $mapLoaded={loaded} data-testid="maps-container">
      <GoogleMaps
        bootstrapURLKeys={{
          key: GOOGLE_MAPS_API_KEY,
        }}
        defaultCenter={{ lat, lng }}
        defaultZoom={14}
        options={options}
        onGoogleApiLoaded={onGoogleApiLoaded}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {loaded && <Marker />}
      </GoogleMaps>
    </Styled.MapWrapper>
  );
};
