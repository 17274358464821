/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconListNumbers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconListNumbers"
    {...props}
  >
    <path fill="currentColor" d="M20 6H8v2h12zM18 11H8v2h10zM20 16H8v2h12z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m4.27 3.6-1.57.95.612.933.744-.457v2.989h1.29V3.6zM4.721 16.913c.274-.26.49-.479.653-.665.239-.27.402-.506.497-.72a1.7 1.7 0 0 0 .149-.692q0-.367-.193-.678a1.34 1.34 0 0 0-.552-.485h-.001c-.47-.232-1.191-.24-1.705.032a2 2 0 0 0-.662.565l-.107.141.833.663.113-.135q.166-.196.307-.28c.169-.1.427-.105.546.013q.089.088.088.261a.85.85 0 0 1-.112.417 3.4 3.4 0 0 1-.446.581c-.226.246-.585.612-1.068 1.087l-.054.053v.927h2.978l.144-1.085z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconListNumbers;
