import styled from 'styled-components';

import { Button, TextButton } from '@xing-com/button';
import { Card } from '@xing-com/card';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  spaceL,
  spaceS,
  spaceXL,
  spaceXS,
  xdlColorDisabledSoft,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const MapsWrapper = styled.div`
  height: 400px;
  width: 100%;
  background-color: ${xdlColorDisabledSoft};
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  @media ${mediaConfined} {
    height: 274px;
  }
`;

export const MapsButton = styled(Button)`
  position: absolute;
  top: ${spaceL};
  right: ${spaceL};
`;

export const MapHeadline = styled(BodyCopy)`
  margin: ${spaceXS} 0;
`;

export const AddressWrapper = styled(Card)`
  position: absolute;
  bottom: ${spaceXL};
  left: ${spaceL};
  border-radius: 4px;

  display: inline-block;

  & > :not(:first-child) {
    margin-bottom: ${spaceXS};
  }

  @media (max-width: 739px) {
    .addressWrapper {
      right: ${spaceL};
    }
  }
`;

export const ContactNumbers = styled.div`
  display: flex;
  margin-top: ${spaceXS};
  margin-bottom: ${spaceXS};
  align-items: flex-end;

  & p:first-child {
    margin-right: ${spaceS};
  }

  @media (max-width: 739px) {
    .contactNumbers {
      display: block;

      & p:first-child {
        margin-right: 0;
        margin-bottom: ${spaceXS};
      }
    }
  }
`;

export const LinkableContactWrapper = styled.div`
  height: auto;

  & > a {
    border: none;
    padding: 0;
  }
`;
export const WebsiteLink = styled.div`
  height: auto;
  margin-top: ${spaceXS};

  & > a {
    border: none;
    padding: 0;
  }
`;

export const Address = styled(BodyCopy)`
  margin: ${spaceXS} 0;
`;

export const PhoneButton = styled(Button)`
  margin-right: ${spaceS};
  font-weight: 400;
  height: auto;
`;

export const WebContactsButton = styled(TextButton)`
  height: auto;
`;
