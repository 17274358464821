import * as Styled from './skeleton.styles';

const IdealEmployerSkeleton = () => (
  <Styled.SkeletonContainer>
    <Styled.Box>
      <Styled.HeaderBody>
        <Styled.SkeletonTitle amountRows={1} />

        <Styled.SkeletonContent amountRows={2} />
      </Styled.HeaderBody>

      <Styled.SkeletonHeaderButton size="medium" />
    </Styled.Box>
    <Styled.ListContainer>
      <Styled.SkeletonListText amountRows={2} />
      <Styled.SkeletonListText amountRows={2} />
      <Styled.SkeletonListText amountRows={2} />
    </Styled.ListContainer>
  </Styled.SkeletonContainer>
);

export default IdealEmployerSkeleton;
