import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useEffect, useState } from 'react';

export type ModuleContextType = {
  moduleProperties?: any;
  intersected?: boolean | null;
  moduleType: string;
  moduleGlobalId: any;
  moduleTitle?: string;
  isWebview?: boolean;
};

type ModuleContextValues = {
  moduleContext: ModuleContextType | undefined;
  setModuleContext: Dispatch<SetStateAction<ModuleContextType | undefined>>;
};
export const ModuleContext = createContext<ModuleContextValues | undefined>(
  undefined
);

type ModuleContextProviderProps = {
  value: ModuleContextType;
};
export const ModuleContextProvider: FC<
  PropsWithChildren<ModuleContextProviderProps>
> = ({ value, children }) => {
  const [moduleContext, setModuleContext] = useState<
    ModuleContextType | undefined
  >(value);

  useEffect(() => {
    setModuleContext(value);
  }, [value]);

  return (
    <ModuleContext.Provider
      value={{
        moduleContext,
        setModuleContext,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};
