import styled from 'styled-components';

import { spaceM } from '@xing-com/tokens';
import { Headline as InnerHeadline } from '@xing-com/typography';

export const EditAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Headline = styled(InnerHeadline)`
  margin-bottom: ${spaceM};
`;
