import { Transforms, Node } from 'slate';

import { DEFINITIONS as PARAGRAPH_DEFINITIONS } from '@xing-com/content-editor-plugin-paragraph';
import { DEFINITIONS as TITLE_DEFINITIONS } from '@xing-com/content-editor-plugin-title';

const withArticleLayout = (editor: any, useTitle: boolean) => {
  const { normalizeNode } = editor;
  editor.normalizeNode = ([node, path]: any) => {
    if (path.length === 0) {
      if (useTitle && editor.children.length < 1) {
        const title = {
          type: TITLE_DEFINITIONS.type,
          children: [{ text: '' }],
        };
        Transforms.insertNodes(editor, title, { at: path.concat(0) });
      }
      if (useTitle && editor.children.length < 2) {
        const paragraph = {
          type: PARAGRAPH_DEFINITIONS.type,
          children: [{ text: '' }],
        };
        Transforms.insertNodes(editor, paragraph, { at: path.concat(1) });
      }
      // fix double title
      if (useTitle && editor.children[1].type === TITLE_DEFINITIONS.type) {
        if (editor.selection.anchor.path[0] === 0) {
          Transforms.removeNodes(editor, { at: [1] });
        }
        if (editor.selection.anchor.path[0] === 1) {
          Transforms.removeNodes(editor, { at: [1] });
          Transforms.move(editor, { distance: 1 });
        }
      }
      for (const [child, childPath] of Node.children(editor, path)) {
        const type =
          childPath[0] === 0 && useTitle
            ? TITLE_DEFINITIONS.type
            : PARAGRAPH_DEFINITIONS.type;
        // @ts-expect-error TODO: fix this type
        if (child.type !== type && childPath[0] < 1) {
          // @ts-expect-error TODO: fix this type
          Transforms.setNodes(editor, { type }, { at: childPath });
        }
      }
    }
    return normalizeNode([node, path]);
  };
  return editor;
};
export { withArticleLayout };
