import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { spaceXL } from '@xing-com/tokens';

export const HeaderLogo = styled.img`
  height: ${spaceXL};
  margin-top: 5px;

  @media (prefers-color-scheme: dark) {
    filter: invert(1);
  }

  @media ${mediaSmallOrTiny} {
    height: 18px;
    margin-top: 0;
  }
`;
