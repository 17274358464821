import type { FC, PropsWithChildren } from 'react';
import { createContext } from 'react';

import { useFeatureSwitches } from '@xing-com/hub';

import { COMMBOX_FEATURE_SWITCHES } from '../constants';
import { useCommboxContext } from '../hooks/use-commbox';

export type CommboxPermissionsContextType = {
  enableLeftToolbar: boolean;
  enableLinkPreviewEdit: boolean;
  enableMentions: boolean;
  enableMultiImage: boolean;
  enablePolls: boolean;
  enableScheduling: boolean;
  enableSchedulingLock: boolean;
  enableVideo: boolean;
  isAllowedToPost: boolean;
  isCreatingPosting: boolean;
  isEditingPosting: boolean;
};

export const CommboxPermissionsContext =
  createContext<CommboxPermissionsContextType>({
    enableLeftToolbar: false,
    enableLinkPreviewEdit: false,
    enableMentions: false,
    enableMultiImage: false,
    enablePolls: false,
    enableScheduling: false,
    enableSchedulingLock: false,
    enableVideo: false,
    isAllowedToPost: false,
    isCreatingPosting: false,
    isEditingPosting: false,
  });

export const CommboxPermissionsContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    attachmentType,
    featurePermissions,
    isAllowedToPost,
    isCreatingPosting,
    posting,
  } = useCommboxContext();
  const { data: featureSwitches } = useFeatureSwitches();

  const isEditingPosting = posting !== undefined && posting !== null;

  const enablePolls = !!(
    featureSwitches?.includes(COMMBOX_FEATURE_SWITCHES['POLLS_TOGGLE']) &&
    featurePermissions.enablePolls
  );

  const enableMultiImage = !!(
    featureSwitches?.includes(
      COMMBOX_FEATURE_SWITCHES['MULTI_IMAGE_UPLOAD_TOGGLE']
    ) && featurePermissions.enableMultiImage
  );

  const enableMentions = !!(
    featureSwitches?.includes(COMMBOX_FEATURE_SWITCHES['MENTIONS_TOGGLE']) &&
    featurePermissions.enableMentions
  );

  const permissions = {
    enableLeftToolbar: attachmentType === 'EMPTY' && !isEditingPosting,
    enableLinkPreviewEdit: !!featurePermissions.enableLinkPreviewEdit,
    enableMentions,
    enableMultiImage,
    enablePolls,
    enableScheduling: !!featurePermissions.enableScheduling,
    enableSchedulingLock: !!featurePermissions.enableSchedulingLock,
    enableVideo: !!featurePermissions.enableVideo,
    isEditingPosting,
  };

  return (
    <CommboxPermissionsContext.Provider
      value={{ ...permissions, isAllowedToPost, isCreatingPosting }}
    >
      {children}
    </CommboxPermissionsContext.Provider>
  );
};
