import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  SkeletonHeadline,
  SkeletonBody,
  SkeletonButton,
} from '@xing-com/skeleton';
import {
  xdlColorBorder,
  xdlColorBorderSoft,
  scale040,
  scale080,
  scale100,
  scale130,
  scale150,
  scale200,
} from '@xing-com/tokens';

export const SkeletonContainer = styled.div`
  border-bottom: 1px solid ${xdlColorBorderSoft};

  @media ${mediaSmallOrTiny} {
    border-bottom: none;
  }
`;

export const Box = styled.div`
  background-color: ${xdlColorBorder};
  padding: ${scale080} ${scale080} ${scale040} ${scale080};
  display: flex;
  align-items: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  @media ${mediaSmallOrTiny} {
    display: block;
  }
`;

export const HeaderBody = styled.div`
  flex: 1;
`;

export const SkeletonTitle = styled(SkeletonHeadline)`
  width: 80%;

  @media ${mediaSmallOrTiny} {
    width: 100%;
  }
`;

export const SkeletonContent = styled(SkeletonBody)`
  @media ${mediaSmallOrTiny} {
    margin-top: ${scale040};
  }
`;

export const SkeletonHeaderButton = styled(SkeletonButton)`
  width: 270px;
  margin-left: ${scale150};

  @media ${mediaSmallOrTiny} {
    width: 100%;
    margin-left: 0;
    margin: ${scale100} 0 ${scale080} 0;
  }
`;

export const SkeletonListText = styled(SkeletonBody)`
  width: 100%;
  padding: ${scale100} ${scale200} ${scale130} ${scale130};
`;

export const ListContainer = styled.div`
  display: flex;

  @media ${mediaSmallOrTiny} {
    display: none;
  }
`;
