/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AboutUsSubpageQueryVariables = Types.Exact<{
  id: Types.Scalars['SlugOrID']['input'];
  first: Types.Scalars['Int']['input'];
}>;

export type AboutUsSubpageQuery = {
  __typename?: 'Query';
  pagesAboutUs?:
    | {
        __typename?: 'AboutEntity';
        headline?: string | null;
        summary?: string | null;
        aboutArticle?: {
          __typename?: 'ContentServiceContent';
          globalId: string;
          header?: {
            __typename?: 'ArticleHeader';
            title: { __typename?: 'ArticleTitle'; text: string };
          } | null;
          blocks: Array<
            | { __typename?: 'ArticleBodyImage' }
            | { __typename?: 'ArticleEmphasis' }
            | {
                __typename?: 'ArticleH2';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
            | {
                __typename?: 'ArticleH3';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
            | {
                __typename?: 'ArticleH4';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
            | {
                __typename?: 'ArticleOrderedListItem';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
            | {
                __typename?: 'ArticleParagraph';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
            | { __typename?: 'ArticleQuote' }
            | {
                __typename?: 'ArticleUnorderedListItem';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
          >;
        } | null;
        documents?:
          | {
              __typename?: 'EntityDocumentConnection';
              edges?: Array<{
                __typename?: 'EntityDocumentEdge';
                node: {
                  __typename?: 'EntityDocument';
                  id: string;
                  description: string;
                  documentUrl: string;
                  filename: string;
                };
              } | null> | null;
            }
          | {
              __typename?: 'EntityPageError';
              errorType: string;
              errorCode: number;
            }
          | null;
        media?:
          | {
              __typename?: 'EntityMediaConnection';
              edges?: Array<{
                __typename?: 'EntityMediaEdge';
                node: {
                  __typename?: 'EntityMedia';
                  id: string;
                  media?:
                    | {
                        __typename?: 'EntityExternalVideo';
                        url?: string | null;
                        externalLink: string;
                      }
                    | { __typename?: 'EntityVideo'; videoReferenceV2: string }
                    | { __typename?: 'ScaledImage'; url?: string | null }
                    | null;
                };
              } | null> | null;
            }
          | {
              __typename?: 'EntityPageError';
              errorType: string;
              errorCode: number;
            }
          | null;
      }
    | { __typename?: 'EntityPageError'; errorType: string; errorCode: number }
    | null;
  facts?:
    | {
        __typename: 'CompanyAboutUsFacts';
        imprint?: string | null;
        websiteUrl?: string | null;
        foundingYear?: number | null;
        companyData?: {
          __typename?: 'Company';
          id: string;
          companySizeId?: string | null;
          industry?: {
            __typename?: 'Industry';
            id: string;
            localizationKey: string;
            segments: Array<{ __typename?: 'IndustrySegment'; id: string }>;
          } | null;
          companySizeRange?: {
            __typename?: 'CompanySizeRange';
            min: number;
            max?: number | null;
          } | null;
          kununuData?: {
            __typename?: 'CompanyKununuData';
            badges?: Array<{
              __typename?: 'CompanyKununuBadge';
              awardUrl?: string | null;
              image?: Array<{
                __typename?: 'ScaledImage';
                url?: string | null;
              }> | null;
            } | null> | null;
          } | null;
        } | null;
      }
    | { __typename: 'EntityPageError'; errorType: string; errorCode: number }
    | { __typename: 'FallbackAboutUsFacts'; message: string }
    | { __typename: 'GroupsAboutUsFacts' }
    | {
        __typename: 'PublisherAboutUsFacts';
        imprint?: string | null;
        websiteUrl?: string | null;
        foundingYear?: number | null;
      }
    | null;
};

export const AboutUsSubpageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AboutUsSubpage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pagesAboutUs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AboutEntity' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'headline' },
                        name: { kind: 'Name', value: 'aboutHeadline' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'summary' },
                        name: { kind: 'Name', value: 'aboutSummary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aboutArticle' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'supportedBlockTypes',
                            },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'EnumValue',
                                  value: 'ARTICLE_PARAGRAPH',
                                },
                                { kind: 'EnumValue', value: 'ARTICLE_H2' },
                                { kind: 'EnumValue', value: 'ARTICLE_H3' },
                                { kind: 'EnumValue', value: 'ARTICLE_H4' },
                                {
                                  kind: 'EnumValue',
                                  value: 'ARTICLE_UNORDERED_LIST_ITEM',
                                },
                                {
                                  kind: 'EnumValue',
                                  value: 'ARTICLE_ORDERED_LIST_ITEM',
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'globalId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'header' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'blocks' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'EPArticleBlockFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Media' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'EntityPageError',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'errorType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'errorCode' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'EntityDocumentConnection',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'description',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'documentUrl',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'filename',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'facts' },
            name: { kind: 'Name', value: 'pagesAboutUsFacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CompanyAboutUsFacts' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imprint' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'websiteUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'foundingYear' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'industry' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'segments' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'localizationKey',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companySizeId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companySizeRange' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'min' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'max' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kununuData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'badges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'awardUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'image',
                                          },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: {
                                                kind: 'Name',
                                                value: 'dimensions',
                                              },
                                              value: {
                                                kind: 'ListValue',
                                                values: [
                                                  {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'height',
                                                        },
                                                        value: {
                                                          kind: 'IntValue',
                                                          value: '300',
                                                        },
                                                      },
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'width',
                                                        },
                                                        value: {
                                                          kind: 'IntValue',
                                                          value: '210',
                                                        },
                                                      },
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'reference',
                                                        },
                                                        value: {
                                                          kind: 'StringValue',
                                                          value: 'medium',
                                                          block: false,
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PublisherAboutUsFacts' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imprint' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'websiteUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'foundingYear' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FallbackAboutUsFacts' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EPArticleBlockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ArticleBlock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleParagraph' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleH2' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleH3' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleH4' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleUnorderedListItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleOrderedListItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Media' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AboutEntity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityMediaConnection' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'EntityMedia',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'media',
                                          },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: {
                                                kind: 'Name',
                                                value: 'maxWidth',
                                              },
                                              value: {
                                                kind: 'IntValue',
                                                value: '465',
                                              },
                                            },
                                            {
                                              kind: 'Argument',
                                              name: {
                                                kind: 'Name',
                                                value: 'maxHeight',
                                              },
                                              value: {
                                                kind: 'IntValue',
                                                value: '365',
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'ScaledImage',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'EntityVideo',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'videoReferenceV2',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'EntityExternalVideo',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'externalLink',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AboutUsSubpageQuery, AboutUsSubpageQueryVariables>;
