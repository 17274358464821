import { useMutation } from '@apollo/client';
import type { ApolloError } from '@apollo/client';

import {
  redirectToLogin,
  useLoggedoutAction,
  useErrorContext,
} from '@xing-com/crate-companies-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { IdealEmployerMarkDocument } from '../../graphql/mutations/ideal-employer-mark-mutation.gql-types';
import { IdealEmployerUnmarkDocument } from '../../graphql/mutations/ideal-employer-unmark-mutation.gql-types';
import MarkIdealEmployer from './mark-ideal-employer';

import { trackEntityPageInterestAction } from '@xing-com/crate-companies-entity-pages-common/src/tracking'; // eslint-disable-line

type MarkIdealEmployerContainerProps = {
  companyId: string;
  isIdealEmployer: boolean;
  changeModuleState: (param: boolean) => void;
};

const MarkIdealEmployerContainer = ({
  companyId,
  isIdealEmployer,
  changeModuleState,
}: MarkIdealEmployerContainerProps) => {
  const { isLoggedIn } = useLoginState();
  const { showError } = useErrorContext();
  const [markAsIdealEmployer, { loading: markAsIdealEmployerLoading }] =
    useMutation(IdealEmployerMarkDocument, {
      onCompleted: () => trackEntityPageInterestAction({ hasInterest: true }),
      onError: (error) => toggleIdealEmployer(isIdealEmployer, error),
    });

  const [unmarkAsIdealEmployer, { loading: unmarkAsIdealEmployerLoading }] =
    useMutation(IdealEmployerUnmarkDocument, {
      onCompleted: () => trackEntityPageInterestAction({ hasInterest: false }),
      onError: (error) => toggleIdealEmployer(isIdealEmployer, error),
    });

  const toggleIdealEmployer: any = (
    isIdealEmployer: boolean,
    error: ApolloError
  ) => {
    if (!isLoggedIn) {
      redirectToLogin('markIdealEmployer');
    } else {
      changeModuleState(!isIdealEmployer);
      if (error) {
        trackEntityPageInterestAction({
          hasInterest: isIdealEmployer,
          error: true,
        });
        showError({
          message: 'EP_JOBS_FEEDBACK_ERROR',
          error,
        });
      } else {
        isIdealEmployer
          ? unmarkAsIdealEmployer({ variables: { companyId } })
          : markAsIdealEmployer({ variables: { companyId } });
      }
    }
  };

  useLoggedoutAction(toggleIdealEmployer, 'markIdealEmployer', isIdealEmployer);

  return (
    <MarkIdealEmployer
      loading={Boolean(
        markAsIdealEmployerLoading || unmarkAsIdealEmployerLoading
      )}
      isIdealEmployer={isIdealEmployer}
      toggleIdealEmployer={toggleIdealEmployer}
    />
  );
};

export default MarkIdealEmployerContainer;
