import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { DotLoadingIndicator } from '@xing-com/dot-loading-indicator';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  xdlColorText,
  xdlColorDisabledSoft,
  xdlColorControlFill,
  xdlColorBorderSoft,
  xdlColorInfoSoft,
  xdlColorDisabledStrong,
  xdlColorBorder,
  xdlColorBackgroundTertiary,
  scale080,
  spaceXS,
  spaceM,
  spaceL,
} from '@xing-com/tokens';
import { BodyCopy, Meta } from '@xing-com/typography';

export const Tag = styled.div<{ $deleted: boolean; $encoding: boolean }>`
  display: flex;
  padding: ${spaceL};
  border-bottom: 1px solid ${xdlColorBorderSoft};

  &:nth-of-type(-n + 4) {
    border-bottom: 1px solid ${xdlColorBorder};
    background-color: ${xdlColorBackgroundTertiary};
  }

  ${({ $deleted }) =>
    $deleted &&
    css`
      background-color: ${xdlColorInfoSoft};
    `}

  ${({ $encoding }) =>
    $encoding &&
    css`
      background-color: ${xdlColorInfoSoft};
    `}

  @media ${mediaSmallOrTiny} {
    flex-wrap: wrap;
  }
`;

export const MediaWrapper = styled.div<{ $videoWrapper?: boolean }>`
  position: relative;
  flex-shrink: 0;
  width: 110px;
  height: 83px;
  margin-right: ${spaceL};
  display: flex;
  justify-content: center;
  overflow: hidden;

  ${({ $videoWrapper }) =>
    $videoWrapper &&
    css`
      align-items: center;
      overflow: hidden;
      position: relative;

      & > button {
        padding-top: 75%;
      }
    `}

  @media ${mediaSmallOrTiny} {
    margin-right: 0;
  }
`;

export const PreviewModeDimmer = styled.span`
  position: absolute;
  background-color: ${xdlColorControlFill};
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
`;

export const LoadingIndicator = styled(DotLoadingIndicator)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1;
`;

export const VideoPreviewIcon = styled.img`
  position: absolute;
  z-index: 1;
  width: 32px;
  height: 32px;
`;

export const ItemActionsWrapper = styled.div`
  margin-left: ${spaceL};
  display: flex;
  flex-shrink: 0;
  min-width: 128px;
  justify-content: flex-end;

  @media ${mediaSmallOrTiny} {
    flex: 1;
  }
`;

export const Description = styled(BodyCopy)<{ $deleted?: boolean }>`
  margin-top: calc(-1 * ${spaceXS});
  margin-bottom: 0;

  ${({ $deleted }) =>
    $deleted &&
    css`
      color: ${xdlColorDisabledStrong};
      margin-top: calc(-1 * ${spaceM});
    `}
`;

export const Media = styled.img`
  max-height: 100%;
`;

export const DeletedActionsButtons = styled(Button)`
  margin-right: 0;
  padding-right: 6px;
`;

export const ItemActionButton = styled(Button)`
  margin-right: ${spaceL};

  &:last-child {
    margin-right: 0;
  }

  @media ${mediaSmallOrTiny} {
    margin-right: ${spaceM};

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const InfoWrapper = styled.span`
  display: flex;
  align-items: flex-start;
  margin: -3px 0 3px 0;
`;

export const IconWrapper = styled.span`
  margin-top: 1px;
  height: ${scale080};
  margin-right: ${spaceXS};
`;

export const InfoTitle = styled(Meta)`
  font-weight: 700;
  color: ${xdlColorText};

  & > span > span {
    font-weight: 400;
  }
`;

export const DeletedInfo = styled.span`
  font-weight: 400;
`;

export const TextWrapper = styled.div`
  flex: 1;
  word-wrap: break-word;

  @media ${mediaSmallOrTiny} {
    order: 2;
    flex-basis: 100%;
    margin-top: ${spaceM};
  }
`;

export const ExternalIcon = styled.div`
  height: 32px !important;
  width: 32px !important;
  z-index: 10;
  background-color: ${xdlColorDisabledSoft};
  opacity: 0.9;
  border-radius: 32px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${xdlColorText};
  top: calc(50% - 16px);
  left: calc(50% - 16px);
`;
