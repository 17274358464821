import { type FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFeatureSwitch } from '@xing-com/hub';
import { IconInfo } from '@xing-com/icons';

import * as Styled from './external-edit-info-banner.styles';

type ExternalEditInfoBannerProps = {
  copyKey: string;
  ctaCopyKey?: string;
  ctaUrl?: string;
  cyTestId?: string;
  ctaAvailableInMobile?: boolean;
};

export const ExternalEditInfoBanner: FC<ExternalEditInfoBannerProps> = ({
  copyKey,
  ctaCopyKey,
  ctaUrl,
  cyTestId,
  ctaAvailableInMobile = true,
  ...rest
}) => {
  const { $t } = useIntl();

  const canEditMobile = useFeatureSwitch('ep_mobileEdit');

  return (
    <Styled.Banner
      $canEditMobile={!!canEditMobile}
      data-cy={cyTestId}
      {...rest}
    >
      <Styled.TextContent>
        <Styled.IconContainer>
          <IconInfo width={24} height={24} />
        </Styled.IconContainer>
        <Styled.Text size="small">
          {$t({ id: copyKey, defaultMessage: copyKey })}
        </Styled.Text>
      </Styled.TextContent>
      {ctaCopyKey && ctaUrl && (
        <Styled.BannerButton
          $ctaAvailableInMobile={ctaAvailableInMobile}
          variant="secondary"
          size="small"
          href={ctaUrl}
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          target="_blank"
          data-cy={`${cyTestId}_CTA`}
        >
          <FormattedMessage id={ctaCopyKey} defaultMessage={ctaCopyKey} />
        </Styled.BannerButton>
      )}
    </Styled.Banner>
  );
};
