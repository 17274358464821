/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconComment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconComment"
    {...props}
  >
    <path
      fill="currentColor"
      d="m10 22.164 6.648-5.657c.393-.289 1.024-.878 1.65-1.71C19.359 13.39 20 11.781 20 10a8 8 0 1 0-16 0 7.996 7.996 0 0 0 6 7.739zm2-6.11-.858-.124A5.995 5.995 0 0 1 12 4a6 6 0 0 1 6 6c0 1.302-.482 2.509-1.299 3.593a8 8 0 0 1-.915 1.02c-.182.17-.317.28-.38.328L12 17.837z"
    />
  </svg>
);
export default IconComment;
