export const getEntityPageQueryVariables = (pageSlug?: string) => ({
  id: pageSlug,
  socialProofClickReasonsKey: 'CR_WEB_PUBLISHER_SOCIAL_PROOF_HEADER',
});

export const getEntitySubpageQueryVariables = (
  pageSlug?: string,
  moduleType?: string
) => ({
  id: pageSlug,
  socialProofClickReasonsKey: 'CR_WEB_PUBLISHER_SOCIAL_PROOF_HEADER',
  moduleType,
});
