import styled from 'styled-components';

import { scale100, scale130, spaceL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const PreviewImageContainer = styled.div`
  margin-top: ${scale130};
`;

export const PreviewImageButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${spaceL};
`;

export const FileName = styled(BodyCopy)`
  margin: 0 ${scale100};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UploadCtaContainer = styled.div`
  flex-shrink: 0;
`;

export const FileAddedContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

export const CropperContainer = styled.div`
  height: 300px;
  position: relative;
  width: 740px;
`;

// .cropCtaContainer {
//   border-top: 2px solid var(--xdlColorBorderSoft);
//   display: flex;
//   justify-content: flex-end;
//   margin-top: var(--scale260);
//   padding-top: var(--scale110);
// }

// .cancelCta {
//   margin-right: var(--scale080);
// }
