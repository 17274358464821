import * as React from 'react';

import { Styled } from './profile-info.styles';
import type { ProfileInfoProps } from './profile-info.types';

export const ProfileInfo = React.forwardRef<HTMLDivElement, ProfileInfoProps>(
  (
    {
      actions,
      alignment = 'horizontal',
      children,
      className,
      noSpacing,
      profileImage,
      ...props
    },
    forwardedRef
  ): JSX.Element => (
    <Styled.ProfileInfo
      $alignment={alignment}
      $noSpacing={noSpacing}
      className={className}
      data-xds="ProfileInfo"
      ref={forwardedRef}
      {...props}
    >
      <Styled.ProfileImageContainer>
        {profileImage}
      </Styled.ProfileImageContainer>
      <Styled.ContentContainer $alignment={alignment}>
        {children}
      </Styled.ContentContainer>
      {actions ? (
        <Styled.ActionsContainer>{actions}</Styled.ActionsContainer>
      ) : null}
    </Styled.ProfileInfo>
  )
);

ProfileInfo.displayName = 'ProfileInfo';
