import type { FC } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ActorSwitch } from '@xing-com/actor-switch';
import type { ActorType } from '@xing-com/actor-switch';
import { Dialog } from '@xing-com/dialog';

import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxFormContext } from '../../hooks/use-commbox-form-context';
import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';

export const CommboxHeader: FC = () => {
  const { formatMessage } = useIntl();
  const {
    actors,
    attachmentType,
    onOpenActorSwitch,
    setPollData,
    setActorType,
    setAttachmentType,
  } = useCommboxContext();
  const { isEditingPosting } = useCommboxPermissions();
  const {
    selectedActorGlobalId,
    setImagePreviewSrc,
    setImages,
    setSelectedActorGlobalId,
  } = useCommboxFormContext();

  const [isConfirmingActor, setIsConfirmingActor] = useState(false);
  const [newActor, setNewActor] = useState<ActorType | null>(null);

  const hasAttachments = attachmentType !== 'EMPTY';

  const handleActorChange = (actor: ActorType) => {
    // reset attachments
    setImagePreviewSrc('');
    setImages([]);
    setPollData(null);
    setAttachmentType('EMPTY');
    // Switch to actor
    setActorType(actor.type);
    setSelectedActorGlobalId(actor.globalId);
  };

  return (
    <>
      <ActorSwitch
        actors={actors}
        isDisabled={isEditingPosting}
        onOpen={onOpenActorSwitch}
        selectedActorGlobalId={selectedActorGlobalId}
        onChange={(actor: ActorType) => {
          if (hasAttachments) {
            setNewActor(actor);
            setIsConfirmingActor(true);
          } else {
            handleActorChange(actor);
          }
        }}
      />
      <Dialog
        show={isConfirmingActor}
        isDestructive
        headline={formatMessage({ id: 'COMMBOX_ACTOR_SWITCH_CONFIRM' })}
        text={formatMessage({
          id: 'COMMBOX_ACTOR_SWITCH_PROMPT_IMAGE',
        })}
        cancelLabel={formatMessage({
          id: 'COMMBOX_ACTOR_SWITCH_CANCEL',
        })}
        confirmLabel={formatMessage({
          id: 'COMMBOX_ACTOR_SWITCH_CONFIRM',
        })}
        onCancel={() => {
          setIsConfirmingActor(false);
          setNewActor(null);
        }}
        onConfirm={() => {
          newActor && handleActorChange(newActor);
          setIsConfirmingActor(false);
        }}
      />
    </>
  );
};
