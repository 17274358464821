import { trackingURL } from '@xing-com/xing-tracking';

type getTrackingUrlHOFProps = {
  conditions?: any;
  to?: any;
  options?: any;
  trackingKey?: any;
};
const getTrackingUrlHOF =
  (trackings: any) =>
  ({ conditions, to, options, trackingKey }: getTrackingUrlHOFProps) =>
    trackingURL(to, trackingKey, conditions, options)(trackings);

const nav = {
  viewPagePromote: { sc_o: 'entity_page_promote' },
  viewXBM: { sc_o: 'entity_page_analyse' },
};

const contacts = {
  viewAllContacts: { sc_o: 'entity_page_view_all_contacts' },
  viewContactProfileMain: {
    sc_o: 'entity_page_profile_other_visit_main_contact',
  },
  viewContactProfileSubpage: {
    sc_o: 'entity_page_profile_other_visit_sub_contact',
  },
  backToOverviewFromContactSubpage: { sc_o: 'entity_page_back_to_overview' },
};

const employees = {
  viewAllEmployees: { sc_o: 'entity_page_view_all_employees' },
  viewEmployeesProfileMain: {
    sc_o: 'entity_page_profile_other_visit_main_employees',
  },
  viewEmployeesProfileSubpage: {
    sc_o: 'entity_page_profile_other_visit_sub_employees',
  },
  backToOverviewFromEmployeesSubpage: { sc_o: 'entity_page_back_to_overview' },
};

const jobs = {
  viewJobProfile: { ijt: 'jb_40' },
  viewAllJobs: {
    sc_o: 'entity_page_view_all_jobs',
  },
  viewJobOffers: {
    sc_o: 'entity_page_job_offers_sub_page ',
  },
};

const companyRecommendations = {
  viewCompanyRecommendation: {
    sc_o: 'entity_page_main_recommended_module_visit',
  },
};

const kununu = {
  viewAllReviews: {
    utm_source: 'xing_pages',
    utm_medium: 'affiliate',
    utm_content: 'cta_view_all_reviews',
    rfr: 'xcp_cta_view_all_reviews',
  },
  viewSingleReviews: {
    utm_source: 'xing_pages',
    utm_medium: 'affiliate',
    utm_content: 'cta_view_single_reviews',
    rfr: 'xcp_cta_view_single_reviews',
  },
  viewKununuProfile: {
    utm_source: 'xing_pages',
    utm_medium: 'affiliate',
    utm_content: 'cta_view_profile',
    rfr: 'xcp_cta_view_profile',
  },
  viewKununuCulture: {
    utm_source: 'xing_pages',
    utm_medium: 'affiliate',
    utm_content: 'cta_view_profile_culture',
    rfr: 'xcp_cta_view_profile_culture',
  },
  viewProfileAsEditor: {
    utm_source: 'xing_pages',
    utm_medium: 'affiliate',
    utm_content: 'cta_view_editor',
    rfr: 'xcp_cta_view_editor',
  },
  rateCompany: {
    utm_source: 'xing_pages',
    utm_medium: 'affiliate',
    utm_content: 'cta_rate_company',
    rfr: 'xcp_cta_rate_company',
  },
  viewKununuBenefits: {
    utm_source: 'xing_pages',
    utm_medium: 'affiliate',
    utm_content: 'cta_view_benefits',
    rfr: 'xcp_cta_view_benefits',
  },
};

const aboutus = {
  visitAffiliate: {
    sc_o: 'entity_page_about_us_affiliate_visit',
  },
  discoverMore: {
    sc_o: 'entity_page_about_us_discover_more_button',
  },
  learnMoreLink: {
    sc_o: 'entity_page_about_us_learn_more_link',
  },
  allAwards: {
    sc_o: 'entity_page_about_us_all_awards_link',
  },
};

const getTrackingUrl = getTrackingUrlHOF({
  ...nav,
  ...contacts,
  ...employees,
  ...jobs,
  ...companyRecommendations,
  ...kununu,
  ...aboutus,
});

export default getTrackingUrl;
