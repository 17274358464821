import type { FC } from 'react';
import { useIntl } from 'react-intl';

import * as Styled from './documents-editor.styles';

type DeleteDialogProps = {
  loading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  showDialog: boolean;
};
export const DeleteDialog: FC<DeleteDialogProps> = ({
  loading,
  onConfirm,
  onCancel,
  showDialog,
}) => {
  const intl = useIntl();

  return (
    <Styled.DeleteDialog
      show={showDialog}
      loading={loading}
      isDestructive={true}
      headline={intl.formatMessage({
        id: 'EP_DELETE_DOCUMENT_DIALOG_HEADLINE',
        defaultMessage: 'EP_DELETE_DOCUMENT_DIALOG_HEADLINE',
      })}
      text={intl.formatMessage({
        id: 'EP_DELETE_DOCUMENT_DIALOG',
        defaultMessage: 'EP_DELETE_DOCUMENT_DIALOG',
      })}
      cancelLabel={intl.formatMessage({
        id: 'EP_DELETE_DOCUMENT_DIALOG_CANCEL',
        defaultMessage: 'EP_DELETE_DOCUMENT_DIALOG_CANCEL',
      })}
      confirmLabel={intl.formatMessage({
        id: 'EP_DELETE_DOCUMENT_DIALOG_CONFIRM',
        defaultMessage: 'EP_DELETE_DOCUMENT_DIALOG_CONFIRM',
      })}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};
