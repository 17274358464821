import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { Dialog } from '@xing-com/dialog';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { spaceS, spaceM, spaceL, xdlColorBorderSoft } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const DeleteDialog = styled(Dialog)`
  text-align: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: ${spaceS};
  flex-shrink: 0;

  & > div {
    width: 100%;
  }

  @media ${mediaSmallOrTiny} {
    width: 100%;
  }
`;

export const ItemActionsWrapper = styled.div`
  margin-left: ${spaceL};
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;

  @media ${mediaSmallOrTiny} {
    margin-top: ${spaceM};
    margin-left: 0;
  }
`;

export const ItemActionButton = styled(Button)`
  margin-right: ${spaceL};

  &:last-child {
    margin-right: 0;
  }
`;

export const TextWrapper = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
`;

export const Icon = styled.span`
  margin-right: ${spaceM};
  height: 24px;
`;

export const Description = styled(BodyCopy)`
  margin-bottom: 0;
`;

interface ListItemWrapperProps {
  $edit?: boolean;
  $disabled?: boolean;
  $beforeEdit?: boolean;
}

export const ListItemsWrapper = styled.div<ListItemWrapperProps>`
  display: flex;
  padding: ${spaceL};
  border-bottom: 1px solid ${xdlColorBorderSoft};
  align-items: center;

  &:first-child {
    border-top: 1px solid ${xdlColorBorderSoft};
  }

  ${({ $edit }) => $edit && `padding: ${spaceL} ${spaceL} ${spaceS} ${spaceL};`}

  ${({ $disabled }) => $disabled && `opacity: 0.5; `}

  ${({ $disabled, $beforeEdit }) =>
    $disabled && $beforeEdit && `border-bottom: none;`}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${spaceL};
  margin-bottom: 32px;

  @media ${mediaSmallOrTiny} {
    border-top: 0;
    padding-top: 0;
    margin-top: ${spaceL};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spaceL};

  @media ${mediaSmallOrTiny} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
`;

export const FileUploadContainer = styled.div`
  align-items: center;
  display: flex;
  height: 56px;

  @media ${mediaSmallOrTiny} {
    height: 80px;
  }
`;

export const FileUploadButton = styled(Button)`
  display: block;

  @media ${mediaSmallOrTiny} {
    margin: ${spaceM} 0;
  }
`;
