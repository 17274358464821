import { useCombobox } from 'downshift';
import type { PropsWithChildren, FC } from 'react';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { AutocompleteProvider } from './autocomplete-context';
import * as Styled from './autocomplete.styles';

// Autocomplete doesn't work even in production
type AutocompleteProps = {
  selectedValue: any;
  items: any;
  onChange: (params: any) => void;
  onBlur?: () => void;
  callback: (params: string) => void;
  selectedPropertyName: string;
  minChars?: number;
  timeout?: number;
  allowFreeText?: boolean;
  isOpen?: boolean;
};
export const Autocomplete: FC<PropsWithChildren<AutocompleteProps>> = ({
  selectedValue,
  items,
  onChange,
  callback,
  selectedPropertyName,
  children,
  minChars = 3,
  timeout = 300,
  onBlur = () => undefined,
  allowFreeText = false,
}) => {
  const {
    getInputProps,
    getToggleButtonProps,
    getItemProps,
    getMenuProps,
    isOpen,
    highlightedIndex,
  } = useCombobox({
    items,
    selectedItem: selectedValue,
    itemToString: (item: any) =>
      item?.company
        ? item.company[selectedPropertyName]
        : (item && item[selectedPropertyName]) || '',
    onStateChange: (changes) => {
      if (
        [
          // @ts-expect-error TS(2339) FIXME: Property 'blurInput' does not exist on type '{ Inp... Remove this comment to see the full error message
          useCombobox.stateChangeTypes.blurInput,
          // @ts-expect-error TS(2339) FIXME: Property 'mouseUp' does not exist on type '{ Input... Remove this comment to see the full error message
          useCombobox.stateChangeTypes.mouseUp,
        ].includes(changes.type)
      ) {
        onBlur();
      }
      if (changes.selectedItem) {
        if (changes.selectedItem) {
          onChange(changes.selectedItem.company);
        }
      } else if (changes.inputValue) {
        if (changes?.inputValue?.length === 0) {
          onChange({});
          setSearchTerm('');
          onBlur();
        }
        if (changes.inputValue && changes.inputValue.length >= minChars) {
          setSearchTerm(changes.inputValue);
        }
        if (allowFreeText) {
          onChange({
            [selectedPropertyName]: changes.inputValue,
          });
        }
      }
    },
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedtext] = useDebounce(searchTerm, timeout);
  useEffect(() => {
    if (debouncedtext) callback(debouncedtext);
  }, [debouncedtext]);

  return (
    <Styled.RootWrapper>
      <AutocompleteProvider
        value={{
          isOpen,
          getInputProps,
          getToggleButtonProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
        }}
      >
        {children}
      </AutocompleteProvider>
    </Styled.RootWrapper>
  );
};
