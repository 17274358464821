import {
  PROP_TRACK_ACTION,
  CONTACT_OPEN_MAP_MAIN,
  CONTACT_CLICK_PHONE_MAIN,
  CONTACT_CLICK_PHONE_SUBPAGE,
  CONTACT_CLICK_EMAIL_MAIN,
  CONTACT_CLICK_EMAIL_SUBPAGE,
  CONTACT_CLICK_WEBPAGE_MAIN,
  CONTACT_CLICK_WEBPAGE_SUBPAGE,
} from './constants';
import { trackAction } from './tracker';

export const trackContactOpenMaps = () => {
  trackAction({
    [PROP_TRACK_ACTION]: CONTACT_OPEN_MAP_MAIN,
  });
};

export const trackContactClickPhoneNumber = (type: string) => {
  trackAction({
    [PROP_TRACK_ACTION]:
      type === 'Main' ? CONTACT_CLICK_PHONE_MAIN : CONTACT_CLICK_PHONE_SUBPAGE,
  });
};

export const trackContactClickEmail = (type: string) => {
  trackAction({
    [PROP_TRACK_ACTION]:
      type === 'Main' ? CONTACT_CLICK_EMAIL_MAIN : CONTACT_CLICK_EMAIL_SUBPAGE,
  });
};

export const trackContactClickWebpage = (type: string) => {
  trackAction({
    [PROP_TRACK_ACTION]:
      type === 'Main'
        ? CONTACT_CLICK_WEBPAGE_MAIN
        : CONTACT_CLICK_WEBPAGE_SUBPAGE,
  });
};
