import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { zIndexStickyLayer3, mediaTopBarHidden } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import {
  scale040,
  scale080,
  scale100,
  scale110,
  scale170,
  scale150,
  spaceXL,
  xdlColorBackground,
  xdlColorBorderSoft,
  xdlColorLink,
} from '@xing-com/tokens';
import { Headline, Meta } from '@xing-com/typography';
import { Butterfly } from '@xing-com/xing-logo';

export const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: ${scale150};

  & > button,
  & > a {
    margin-left: ${scale040};
  }
`;

export const Container = styled.div<{
  $showOnLargerScreens: boolean;
  $type?: 'logo' | 'header' | 'subheader';
  $isScrolling: boolean;
}>`
  align-items: center;
  background: ${xdlColorBackground};
  border-bottom: 1px solid
    ${({ $isScrolling }) => ($isScrolling ? 'transparent' : xdlColorBorderSoft)};
  display: flex;
  height: ${scale170};
  justify-content: space-between;
  left: 0;
  padding: 0 ${scale040} 0 ${scale100};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${zIndexStickyLayer3};

  @media ${mediaTopBarHidden} {
    display: ${({ $showOnLargerScreens, $type }) =>
      $showOnLargerScreens && $type !== 'logo' ? 'flex' : 'none'};
    margin-top: ${spaceXL};
    position: static;
  }
`;

export const Placebo = styled.div`
  height: ${scale170};

  @media ${mediaTopBarHidden} {
    display: none;
  }
`;

export const BackButton = styled(TextButton)`
  left: calc(-1 * ${scale080});
  position: relative;
`;

export const H1 = styled(Headline)`
  font-size: ${scale110};
  margin: 0;
`;

export const SubHeaderContainer = styled.div`
  display: grid;
`;

export const SubHeaderButton = styled.button`
  background-color: transparent;
  border: 0 none;
  color: inherit;
  cursor: pointer;
  display: grid;
  padding: 0;
  text-decoration: none;
`;

export const MessengerLink = styled(Link)`
  @media ${mediaTopBarHidden} {
    display: none;
  }
  display: flex;
  color: inherit;
  cursor: pointer;
`;

export const SubHeaderLink = styled(Link)<{
  to: string;
}>`
  color: inherit;
  display: grid;

  &:hover {
    text-decoration: none;
  }
`;

export const H2 = styled(Headline)`
  margin: 0;
  overflow-x: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const H3 = styled(Meta)`
  margin: 0;
  overflow-x: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProfileImageWrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${scale170};
  justify-content: center;
`;

export const Logo = styled(Butterfly).attrs({
  size: 32,
})`
  color: ${xdlColorLink};
  line-height: 0;
`;
export const StyledTitleSection = styled.div`
  display: flex;
  align-items: center;
`;
