import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { FormField } from '@xing-com/text-field';

import * as Styled from './title.styles';

const MAX_LENGTH = 200;

type TitleProps = {
  title?: string;
  setTitle: (title: string) => void;
  setError: (error: any) => void;
};
export const Title: FC<TitleProps> = ({ title = '', setTitle, setError }) => {
  const intl = useIntl();
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if ((title.length > 0 && title.length < 3) || title.length > MAX_LENGTH) {
      setIsValid(false);
      setError(true);
    } else {
      setIsValid(true);
      setError(false);
    }
  }, [title]);

  return (
    <Styled.TitleContainer>
      <FormField
        id="video-upload-title"
        size="medium"
        value={title}
        onChange={(e: any) => setTitle(e?.target?.value)}
        error={!isValid ? true : false}
        label={intl.formatMessage({ id: 'VIDEO_UPLOAD_TITLE' })}
      />
      <Styled.Counter size="xsmall">{MAX_LENGTH - title.length}</Styled.Counter>
    </Styled.TitleContainer>
  );
};
