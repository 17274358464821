import styled from 'styled-components';

import { mediaSmallOrTiny, mediaConfined } from '@xing-com/layout-tokens';
import {
  SkeletonBody,
  SkeletonButton,
  SkeletonHeadline,
  SkeletonImage,
} from '@xing-com/skeleton';
import {
  scale170,
  scale130,
  scale120,
  xdlColorDisabledStrong,
  scale050,
  scale160,
  scale080,
  scale110,
  xdlColorBorderSoft,
  scale240,
  scale200,
  scale100,
  scale250,
  scale060,
} from '@xing-com/tokens';

export const RatingWrapper = styled.div`
  display: flex;
`;

export const Ratingbox = styled.div`
  flex: 1;
  margin-right: ${scale170};

  @media (min-width: 1113px) {
    width: 133px;
    flex: inherit;
  }
`;

export const RatingboxBody = styled(SkeletonBody)`
  margin-top: ${scale130};
`;

export const RatingMain = styled.div`
  align-items: center;
  background-color: ${xdlColorDisabledStrong};
  border-radius: 2px;
  display: none;
  flex: 1;
  padding: ${scale120};

  @media (min-width: 1113px) {
    display: flex;
  }
`;

export const RatingMainText = styled.div`
  flex: 1;
  margin-right: ${scale050};
`;

export const RatingMainButton = styled(SkeletonButton)`
  width: 240px;
`;

export const KununuSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderHeadline = styled(SkeletonHeadline)`
  width: 250px;
  margin-bottom: ${scale130};
  margin-top: ${scale160};
  & > div {
    margin: 0;
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  position: relative;
  height: 162px;

  @media ${mediaSmallOrTiny} {
    width: calc(100% + ${scale080});
    overflow: hidden;
  }
`;

export const Card = styled.div`
  border: solid 2px ${xdlColorBorderSoft};
  margin-left: ${scale110};
  flex: 1;
  border-radius: 8px;
  padding: ${scale080};
  height: 100%;

  &:first-child {
    margin-left: 0;
  }

  @media ${mediaConfined} {
    &:nth-child(3) {
      display: none;
    }
  }

  @media (min-width: 1113px) {
    &:nth-child(3) {
      display: block;
    }
  }

  @media ${mediaSmallOrTiny} {
    &:nth-child(1) {
      position: absolute;
      left: 0;
      width: 80vw;
    }
    &:nth-child(2) {
      position: absolute;
      left: 80vw;
    }
    &:nth-child(3) {
      display: none;
    }
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardHeaderLeft = styled(SkeletonBody)`
  width: ${scale240};
`;

export const CardHeaderRight = styled(SkeletonBody)`
  width: ${scale200};
`;

export const CardBody = styled(SkeletonBody)`
  margin: ${scale110} 0 ${scale100} 0;
`;

export const CardFooter = styled(SkeletonBody)`
  width: 35%;
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled(SkeletonImage)`
  width: ${scale250};
  height: ${scale130};
  margin-right: ${scale060};

  @media ${mediaSmallOrTiny} {
    margin-top: ${scale060};
  }
`;
