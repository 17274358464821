import styled from 'styled-components';

import { PopOver as InternalPopOver } from '@xing-com/pop-over';
import { FormField as InternalFormField } from '@xing-com/text-field';
import { xdlColorBackgroundTertiary, spaceL } from '@xing-com/tokens';

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const FormField = styled(InternalFormField)`
  &:focus {
    background-color: ${xdlColorBackgroundTertiary};
    outline: 0;
  }
`;

export const PopOver = styled(InternalPopOver)`
  padding: 0;
`;

export const ResultList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ResultItem = styled.li`
  padding: ${spaceL};
`;
