import { useLocation } from '@reach/router';
import type { FC } from 'react';
import { useEffect } from 'react';

export const ScrollToTop: FC = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};
