import type React from 'react';

import type { ButtonProps } from '@xing-com/button';
import { Button } from '@xing-com/button';
import type { FlagProps } from '@xing-com/flag';
import { Flag } from '@xing-com/flag';
import { Link } from '@xing-com/link';
import type { ProfileImageProps } from '@xing-com/profile-image';
import { ProfileImage } from '@xing-com/profile-image';
import { type ProfileInfoAlignment } from '@xing-com/profile-info';
import type {
  HeadlineProps,
  BodyCopyProps,
  MetaProps,
} from '@xing-com/typography';
import { BodyCopy, Headline, Meta } from '@xing-com/typography';

import * as Styled from './profile-info-adapter.styles';

export interface ProfileInfoAdapterProps {
  alignment?: ProfileInfoAlignment;
  profileImage: ProfileImageProps;
  profileUrl?: string;
  headline?: HeadlineProps;
  textBody?: Array<BodyCopyProps>;
  textMeta?: Array<MetaProps>;
  buttons?: Array<ButtonProps>;
  children?: React.ReactNode;
  flag?: FlagProps;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const renderElements = <T,>(
  elements: T[] | undefined,
  Component: React.ComponentType<T>,
  keyPrefix: string
) =>
  elements?.map((props, index) => (
    <Component {...props} key={`${keyPrefix}${index}`} />
  )) || null;

export const ProfileInfoAdapter: React.FC<ProfileInfoAdapterProps> = ({
  profileImage: profileImageProp,
  profileUrl,
  headline: headlineProp,
  textBody: textBodyProp,
  textMeta: textMetaProp,
  buttons: buttonsProp,
  flag: flagProp,
  ...rest
}) => {
  const profileImage = profileImageProp ? (
    <Link to={profileUrl}>
      <ProfileImage {...profileImageProp} />
    </Link>
  ) : null;

  const headline = headlineProp ? <Headline {...headlineProp} /> : null;
  const textBody = renderElements(textBodyProp, BodyCopy, 'textbody');
  const textMeta = renderElements(textMetaProp, Meta, 'textmeta');
  const buttons = renderElements(buttonsProp, Button, 'buttons');
  const flag = flagProp ? <Flag {...flagProp} /> : null;

  const VerticalLayout = () => (
    <>
      {flag}
      {headline}
      {textBody}
      {textMeta}
    </>
  );

  const HorizontalLayout = () => (
    <>
      <Styled.Head>
        {headline}
        {flag}
      </Styled.Head>
      {textBody}
      {textMeta}
    </>
  );

  const Layout =
    rest.alignment === 'vertical' ? VerticalLayout : HorizontalLayout;

  return (
    <Styled.ProfileInfo profileImage={profileImage} actions={buttons} {...rest}>
      <Styled.Link to={profileUrl}>
        <Layout />
      </Styled.Link>
    </Styled.ProfileInfo>
  );
};
