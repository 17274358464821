import type { DataProxy } from '@apollo/client';

import type { JobsDetailQuery } from '../graphql/queries/jobs-detail-query.gql-types';

const updateCollection: (
  jobSearchByQuery: JobsDetailQuery['jobSearchByQuery'] | undefined,
  jobId: string | undefined,
  bookmarked: boolean
) => void = (jobSearchByQuery, jobId, bookmarked) =>
  jobSearchByQuery?.collection.map((item) => {
    if (
      item?.jobDetail?.__typename === 'VisibleJob' &&
      item?.jobDetail?.id === jobId
    ) {
      return {
        ...item,
        jobDetail: {
          ...item.jobDetail,
          userInteractions: {
            ...item.jobDetail.userInteractions,
            bookmark: {
              state: updateBookmarkStateOnCache(bookmarked),
              __typename: 'JobBookmark',
            },
          },
        },
      };
    }
    return {
      ...item,
    };
  });

export const updateCache = ({
  cache,
  query,
  variables,
  bookmarked,
  jobId,
  data,
}: {
  cache: DataProxy;
  query: any;
  variables: any;
  bookmarked: boolean;
  jobId: string;
  data: JobsDetailQuery;
}) => {
  if (!data?.jobSearchByQuery) {
    return;
  }

  const queryData: JobsDetailQuery | null = cache.readQuery({
    query,
    variables,
  });
  if (!queryData) {
    return;
  }
  const { jobSearchByQuery } = queryData;

  cache.writeQuery({
    query,
    variables,
    data: {
      jobSearchByQuery: {
        ...jobSearchByQuery,
        collection: updateCollection(jobSearchByQuery, jobId, bookmarked),
      },
    },
  });
};

const updateBookmarkStateOnCache = (bookmarked: boolean) =>
  bookmarked ? 'SAVED' : 'NOT_BOOKMARKED';

export const checkBookmarkState = (state?: string) =>
  state !== 'NOT_BOOKMARKED';
