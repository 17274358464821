import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useDialogContext,
  EditActions,
  usePageContext,
} from '@xing-com/crate-companies-entity-pages-common';

import { AddNewTopicContainer } from './add-new-topic/add-new-topic-container';
import * as Styled from './summary-edit.styles';

const IndustriesEdit: FC = () => (
  <>
    <Styled.Intro>
      <FormattedMessage
        id="EP_EDIT_ABOUTUS_INDUSTRIES_INTRO"
        defaultMessage="EP_EDIT_ABOUTUS_INDUSTRIES_INTRO"
      />
    </Styled.Intro>
    {/* @ts-expect-error FIXME: SC6 */}
    <Styled.SectionHeader medium bold>
      <FormattedMessage
        id="EP_EDIT_ABOUTUS_INDUSTRIES_ADD_TOPIC"
        defaultMessage="EP_EDIT_ABOUTUS_INDUSTRIES_ADD_TOPIC"
      />
    </Styled.SectionHeader>
    <AddNewTopicContainer />
    {/* @ts-expect-error FIXME: SC6 */}
    <Styled.SectionHeader medium bold>
      <FormattedMessage
        id="EP_EDIT_ABOUTUS_INDUSTRIES_TOPICS"
        defaultMessage="EP_EDIT_ABOUTUS_INDUSTRIES_TOPICS"
      />
    </Styled.SectionHeader>
    <Styled.TagList>
      <Styled.Tag variant="delete">UI</Styled.Tag>
      <Styled.Tag variant="delete">Internet and technology</Styled.Tag>
    </Styled.TagList>
  </>
);

type SummaryEditProps = {
  activeIndex: number;
  isSaving?: boolean;
  disablePageActions?: boolean;
  dataChanged?: boolean;
};
export const SummaryEdit: FC<SummaryEditProps> = ({
  activeIndex,
  isSaving,
  disablePageActions,
}) => {
  const { pageContext } = usePageContext() ?? {};
  const { executeWithDialog } = useDialogContext();

  const goBackUrl = () =>
    pageContext?.goBackUrl ? pageContext?.goBackUrl() : undefined;

  return (
    <>
      {activeIndex === 0 && <div>content</div>}
      {activeIndex === 1 && <IndustriesEdit />}
      <EditActions
        disabled={disablePageActions}
        discardAction={() => executeWithDialog((): void => goBackUrl())}
        saveAction={() => {
          goBackUrl();
        }}
        isSaving={isSaving}
      />
    </>
  );
};
