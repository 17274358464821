import styled from 'styled-components';

import { spaceXL, xdlColorBorderSoft } from '@xing-com/tokens';

export const ContentSwitcher = styled.div`
  border-bottom: 1px solid ${xdlColorBorderSoft};
  padding-bottom: ${spaceXL};
  height: auto;
  margin-bottom: ${spaceXL};
`;
