import type React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { Carousel } from '@xing-com/carousel';
import { trackPageJobsChange } from '@xing-com/crate-companies-entity-pages-common/src/tracking';

import type { JobCardValues } from '../../types/job-card';
import { JobCard } from '../job-card/job-card';
import * as Styled from './jobs-cards-carousel.styles';

type JobsCardsCarouselProps = {
  cards: JobCardValues[];
  onCardBookmarkUpdate?: (cardId: string) => void;
};
export const JobsCardsCarousel: React.FC<JobsCardsCarouselProps> = ({
  cards,
  onCardBookmarkUpdate = () => undefined,
}) => {
  const [selected, setSelected] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const numberOfItemsPerChunk = (windowWidth ?? 0) > 739 ? 3 : 2;

  const copyOfJobs = useMemo(
    () => Array.from({ length: cards.length }),
    [cards]
  );

  const chunk = (arr: any[], size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

  const dots = chunk(copyOfJobs, numberOfItemsPerChunk).length;

  return (
    <Styled.Wrapper data-testid="jobsContainer">
      <Carousel
        itemWidth="50%"
        itemWidthConfined="33%"
        hideScrollbar
        onItemEnter={(ev) => {
          setSelected(
            parseInt((ev.itemIndex / numberOfItemsPerChunk).toString())
          );
          if (ev.direction) {
            trackPageJobsChange({
              direction: ev.direction,
              page: ev.itemIndex,
            });
          }
        }}
      >
        {cards.map((card) => (
          <JobCard
            key={card.id}
            {...card}
            onBookmarkUpdate={() => onCardBookmarkUpdate(card.id)}
          />
        ))}
      </Carousel>
      {dots > 0 && <Styled.PaginationDots length={dots} selected={selected} />}
    </Styled.Wrapper>
  );
};
