import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Headline } from '@xing-com/typography';

import * as Styled from './header-banner-image.styles';

export const MARKETING_URL_EN =
  'https://recruiting.xing.com/en/products/xing-360';
export const MARKETING_URL_DE =
  'https://recruiting.xing.com/de/products/xing-360';

const ILLUSTRATION_SIZE = '380px';

type HeaderBannerImageProps = {
  onClick?: (ev?: MouseEvent) => void;
  backLink: string;
};
export const HeaderBannerImage: FC<HeaderBannerImageProps> = ({
  onClick,
  backLink,
}) => {
  const intl = useIntl();
  const urlMarketing: string =
    intl.locale === 'en' ? MARKETING_URL_EN : MARKETING_URL_DE;

  const handleOnClick = (ev: MouseEvent) => {
    ev.preventDefault();

    onClick && onClick(ev);

    const link =
      urlMarketing +
      '?sc_o= entity_page_companies_start_upsell_cover_image_module&sc_o_PropActionOrigin=entity_page_companies_upgrade_overview';

    window.open(link);
  };

  return (
    <Styled.Container>
      <Styled.PremiumWrapperWithIcon>
        <Styled.ContentWrapper>
          <Styled.Flag>
            <FormattedMessage id="EP_FLAG_EMPLOYER_BRANDING_PROFILE" />
          </Styled.Flag>
          <Styled.HeaderText>
            <Headline size="xlarge">
              <FormattedMessage id="EP_HEADER_IMAGE_BANNER_HEADER" />
            </Headline>
          </Styled.HeaderText>
          {/* @ts-expect-error FIXME: SC6 */}
          <Styled.Text
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'EP_HEADER_IMAGE_BANNER_BODY',
              }),
            }}
          />
        </Styled.ContentWrapper>
        <Styled.SingleSizeIllustrationHeroOfferProduct
          width={ILLUSTRATION_SIZE}
        />
      </Styled.PremiumWrapperWithIcon>
      <Styled.Divider />
      <Styled.Buttons>
        <div>
          {/* @ts-expect-error FIXME: SC6 */}
          <Styled.Button size="small" onClick={onClick} to={backLink}>
            <FormattedMessage id="EP_HEADER_IMAGE_BANNER_DRAFT_BUTTON" />
          </Styled.Button>
        </div>
        <div>
          <Styled.Button
            variant="proBusiness"
            size="small"
            // @ts-expect-error FIXME: SC6
            onClick={handleOnClick}
            to={urlMarketing}
          >
            <FormattedMessage id="EP_HEADER_IMAGE_BANNER_PUBLISH_BUTTON" />
          </Styled.Button>
        </div>
      </Styled.Buttons>
    </Styled.Container>
  );
};
