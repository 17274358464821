import type { FC } from 'react';

import {
  DateTimePicker,
  DateTimePickerFencing,
} from '@xing-com/date-time-picker';

import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';

/* 
The business rule here is as follows (on EP only):
  1. if the page is a paid page , then show the datepicker
  2. otherwise, if the page is not paid, but it is a company 
  page, show the fencing banner for the scheduling feature
*/

export type CommboxDatePickerType = {
  publishAt: any;
  onPublishDateSelect: (ISODateTime: string) => void;
};
export const CommboxDatePicker: FC<CommboxDatePickerType> = ({
  publishAt,
  onPublishDateSelect,
}) => {
  const { enableScheduling, enableSchedulingLock } = useCommboxPermissions();

  if (enableScheduling)
    return (
      <DateTimePicker
        publishingDate={publishAt}
        setPublishAt={(ISODateTime: any) => {
          onPublishDateSelect(ISODateTime);
        }}
      />
    );

  if (enableSchedulingLock) return <DateTimePickerFencing />;

  return null;
};
