import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';

import {
  xdlColorBackground,
  xdlColorTextTertiaryOnDark,
  xdlColorText,
  scale070,
} from '@xing-com/tokens';

export const Wrapper = styled.div`
  position: relative;
`;

const styles = css`
  background-color: ${xdlColorBackground};
  border: none;
  border-radius: 0;
  color: ${xdlColorText};
  outline: none;
  resize: none; /* remove the resize handle on the bottom right */

  &::placeholder {
    color: ${xdlColorTextTertiaryOnDark};
    font-size: ${scale070};
  }
`;

export const Textarea = styled(TextareaAutosize)`
  ${styles};
`;

export const Input = styled.input`
  ${styles};
`;
