import * as React from 'react';

import { IconCross } from '@xing-com/icons';

import * as Styled from './content-banner.styles';
import type { ContentBannerProps } from './content-banner.types';

type ContentBannerPrimitiveProps = Omit<
  ContentBannerProps,
  'onExited' | 'show'
>;

export const ContentBannerPrimitive = React.forwardRef<
  HTMLDivElement,
  ContentBannerPrimitiveProps
>(
  (
    {
      children,
      closeButtonProps,
      display = 'fixed',
      handleOnClose,
      noPadding,
      showCloseButton = true,
      variant = 'default',
      ...props
    },
    forwaredRef
  ): JSX.Element => (
    <Styled.ContentBanner
      $closingIndicator={!!handleOnClose}
      $display={display}
      $noPadding={noPadding}
      $variant={variant}
      data-xds="ContentBanner"
      ref={forwaredRef}
      {...props}
    >
      {children}
      {handleOnClose && showCloseButton ? (
        <Styled.Close
          $display={display}
          data-qa="close"
          data-testid="close"
          icon={IconCross}
          onClick={handleOnClose}
          size="medium"
          {...closeButtonProps}
        />
      ) : null}
    </Styled.ContentBanner>
  )
);

ContentBannerPrimitive.displayName = 'ContentBanner (Primitive)';
