import styled, { css } from 'styled-components';

import {
  mediaSmallOrTiny,
  mediaConfined,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import {
  xdlColorBackgroundSecondary,
  cornerRadiusL,
  spaceM,
  spaceS,
  spaceL,
  spaceXL,
} from '@xing-com/tokens';
import { GridContainer as DefaultGridContainer } from '@xing-com/xing-grid';

const logoOffsetDesktop = '-95px';

export const Container = styled.div`
  overflow: hidden;
  background-color: ${xdlColorBackgroundSecondary};
  padding-bottom: ${spaceXL};

  @media ${mediaConfined} {
    border-radius: ${cornerRadiusL};
  }

  @media ${mediaWideNavless} {
    padding-bottom: 0;
  }
`;

export const GridContainer = styled(DefaultGridContainer)`
  position: relative;
  margin-top: -62px !important;

  @media (min-width: 740px) and (max-width: 1024px) {
    margin-top: -82px !important;
  }

  @media ${mediaWideNavless} {
    margin-top: ${logoOffsetDesktop} !important;
    padding-bottom: ${spaceM} !important;
  }
`;

export const TopArea = styled.div`
  @media ${mediaWideNavless} {
    display: flex;
  }
`;

export const SideArea = styled.div<{
  $isLoggedOut: boolean;
  $isSubpage: boolean;
  $noSocialProof: boolean;
}>`
  flex: 1;

  @media ${mediaSmallOrTiny} {
    margin-top: ${spaceL};
  }
  @media (min-width: 740px) and (max-width: 1024px) {
    ${({ $isLoggedOut, $isSubpage, $noSocialProof }) =>
      (($isLoggedOut && $isSubpage) || ($isSubpage && $noSocialProof)) &&
      css`
        display: flex;
      `}
  }
`;

export const UpsellLayout = styled.div`
  margin-bottom: ${spaceS};
`;
