export const dataURLtoBlob = (dataURL: ArrayBuffer | string): Blob => {
  // @ts-expect-error TODO: fix this type
  const arr = dataURL.split(',');
  const mime = arr[0]?.match(/:(.*?);/)[1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};

export const shortenFileName = (fileName: string): string => {
  const extensionIndex = fileName.lastIndexOf('.');
  const extension = fileName.substr(extensionIndex, fileName.length);
  const nameWoExt = fileName.substr(0, extensionIndex);
  const nameWoExtLength = nameWoExt.length;

  const shortenedFileName =
    nameWoExtLength > 20
      ? `${nameWoExt.substr(0, 10)}...${nameWoExt.substr(
          nameWoExtLength - 4,
          nameWoExtLength
        )}${extension}`
      : fileName;

  return shortenedFileName;
};

export const acceptAllTypes = (accept: string, mimeType: string): boolean => {
  const type = mimeType.split('/')[0];
  return accept.includes('*') && accept.includes(type);
};
export const checkFileType = (accept: string, mimeType: string): boolean => {
  const availableTypes = accept.replace(/\s/g, '').split(',');
  return availableTypes.includes(mimeType) || acceptAllTypes(accept, mimeType);
};
