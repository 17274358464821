import { useEffect, useRef, type FC } from 'react';
import { FormattedMessage } from 'react-intl';

import type * as Types from '@xing-com/crate-common-graphql-types';
import {
  usePageContext,
  useEditContext,
} from '@xing-com/crate-companies-entity-pages-common';
import {
  trackOpenNavInfoBox,
  getTrackingUrl,
} from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import { CommboxButton } from '@xing-com/crate-companies-entity-pages-news';
import { useFeatureSwitch } from '@xing-com/hub';
import { IconInfo, IconStatistics, IconEdit } from '@xing-com/icons';
import { OmView } from '@xing-com/platform-layout-om';

import { XBM_STATISTICS_URL, PROMOTE_URL } from '../../config/urls';
import { useResizeObserver } from '../../hooks/useResizeObserver/useResizeObserver';
import { useStickyBarWithOffset } from '../../hooks/useStickyBarWithOffset/useStickyBarWithOffset';
import { EditBarBack } from './edit-bar-back';
import * as Styled from './edit-bar.styles';
import { mergeRefs } from './helpers';
import { ManageUpdates } from './manage-updates';
import { PageInfo } from './page-info';
import { ToggleEditMode } from './toggle-edit-mode';

type EditBarProps = {
  pageSlug: string;
  goBack?: () => void;
  displayNewsButtons: boolean;
  globalId: string;
  displayBackButton: boolean;
  contractType?: Types.Maybe<Types.EntityPageContractType>;
  canEditOnMobile?: boolean;
  displayManageUpdates?: boolean;
  logo?: string;
  pageId?: string;
  pageTitle?: string;
};
export const EditBar: FC<EditBarProps> = ({
  goBack,
  pageSlug,
  displayNewsButtons,
  contractType,
  globalId,
  displayBackButton,
  canEditOnMobile = false,
  displayManageUpdates = false,
}) => {
  const closeOmViewPageInfo = useRef<any | undefined>();

  const { isEditor, isEditing, setIsEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};

  const hasCanEditMobileFeatureSwitch = useFeatureSwitch(
    'ep_mobileEdit',
    false
  );

  const isArmstrong = useFeatureSwitch('armstrong_v1', false);

  const [ref, entry] = useResizeObserver();
  const [stickyBarRef, stickyBarFixed] = useStickyBarWithOffset();

  useEffect(() => {
    return () => closeOmViewPageInfo.current?.();
  }, []);

  if (!isEditor) {
    return null;
  }

  return (
    <>
      <Styled.EditBarMobile
        $displayCta={!!(canEditOnMobile || displayManageUpdates)}
        data-testid="edit-bar-mobile"
      >
        <Styled.BannerMobileContainer>
          <Styled.BannerMobile>
            {displayBackButton && goBack && (
              <Styled.BackButtonContainer>
                <EditBarBack />
              </Styled.BackButtonContainer>
            )}
            {(canEditOnMobile || (displayManageUpdates && !isArmstrong)) && (
              <div className="ctaContainer">
                {canEditOnMobile && (
                  <ToggleEditMode
                    isEditing={!!isEditing}
                    setIsEditing={setIsEditing}
                  />
                )}
                {displayManageUpdates && (
                  <ManageUpdates
                    basePath={pageContext?.basePath ?? ''}
                    isArmstrong={isArmstrong}
                    pageSlug={pageSlug}
                  />
                )}
              </div>
            )}
            {
              <Styled.LabelContainer>
                {canEditOnMobile && hasCanEditMobileFeatureSwitch ? (
                  <IconInfo height={24} width={24} className="editMobileIcon" />
                ) : (
                  <IconEdit height={24} width={24} className="editMobileIcon" />
                )}
                <Styled.MobileInfo size="small">
                  <span data-testid="edit-bar-mobile-label">
                    <FormattedMessage
                      id={
                        canEditOnMobile && hasCanEditMobileFeatureSwitch
                          ? isEditing
                            ? 'EP_EDIT_INFO_EDIT_VIEW'
                            : 'EP_EDIT_INFO_VISITOR_VIEW'
                          : 'EP_EDIT_INFO_MOBILE'
                      }
                      values={{
                        span: (msg) => <span>{msg}</span>,
                      }}
                    />
                  </span>
                </Styled.MobileInfo>
              </Styled.LabelContainer>
            }
          </Styled.BannerMobile>
        </Styled.BannerMobileContainer>
      </Styled.EditBarMobile>

      <Styled.EditBar
        data-testid="edit-bar-desktop"
        $isSticky={!!(pageContext?.isShowingCompanyBanner && stickyBarFixed)}
        ref={mergeRefs([ref, stickyBarRef])}
      >
        <Styled.EditBarSticky
          $isSticky={!!stickyBarFixed}
          $isEditing={isEditing}
          $isShowingCompanyBanner={
            !!(pageContext?.isShowingCompanyBanner && !stickyBarFixed)
          }
          style={
            typeof entry !== 'undefined' && entry.contentRect.width > 0
              ? { width: entry.contentRect?.width }
              : { visibility: 'hidden' }
          }
        >
          {!displayBackButton && (
            <Styled.InfoContainer>
              <IconInfo height={24} width={24} />
              <Styled.Info size="small">
                <FormattedMessage
                  id={
                    isEditing
                      ? 'EP_EDIT_INFO_EDIT_VIEW'
                      : 'EP_EDIT_INFO_VISITOR_VIEW'
                  }
                />
              </Styled.Info>
            </Styled.InfoContainer>
          )}
          {displayBackButton && goBack && (
            <EditBarBack isArmstrong={isArmstrong} />
          )}
          <Styled.ActionContainer>
            {!isEditing && displayNewsButtons && <CommboxButton />}
            {isEditing && (
              <>
                <OmView
                  trigger={(activateOmView: () => void) => (
                    <Styled.Button
                      icon={IconInfo}
                      size={'small'}
                      variant={'secondary'}
                      data-testid={'EP_EDIT_PAGE_INFO_BUTTON'}
                      onClick={() => {
                        trackOpenNavInfoBox();
                        activateOmView();
                      }}
                      aria-label="Info"
                    />
                  )}
                >
                  {({ handleClose }) => {
                    closeOmViewPageInfo.current = handleClose;

                    return (
                      <PageInfo
                        contractType={contractType}
                        pageSlug={pageSlug}
                      />
                    );
                  }}
                </OmView>
                <Styled.Button
                  icon={IconStatistics}
                  size={'small'}
                  variant={'secondary'}
                  href={getTrackingUrl({
                    to: XBM_STATISTICS_URL(pageSlug),
                    trackingKey: 'viewXBM',
                  })}
                  // @ts-expect-error FIXME: SC6
                  target={'_blank'}
                  aria-label="Statistics"
                />
                <Styled.Button
                  variant={'secondary'}
                  size={'small'}
                  href={PROMOTE_URL(globalId)}
                  onClick={(ev) => {
                    ev.preventDefault();
                    window.open(
                      getTrackingUrl({
                        to: PROMOTE_URL(globalId),
                        trackingKey: 'viewPagePromote',
                      })
                    );
                  }}
                >
                  <FormattedMessage id={'EP_EDIT_ACTION_PROMOTE'} />
                </Styled.Button>
              </>
            )}
            <ToggleEditMode
              isEditing={!!isEditing}
              setIsEditing={setIsEditing}
            />
          </Styled.ActionContainer>
        </Styled.EditBarSticky>
      </Styled.EditBar>
    </>
  );
};
