import styled, { css } from 'styled-components';

import { xdlColorError, xdlColorTextSecondary } from '@xing-com/tokens';
import { Meta } from '@xing-com/typography';

const CharacterCounter = styled(Meta)<{
  $maxCountReached: boolean;
}>`
  ${({ $maxCountReached }) =>
    $maxCountReached
      ? css`
          color: ${xdlColorError};
        `
      : css`
          color: ${xdlColorTextSecondary};
        `};
`;

export const Styled = {
  CharacterCounter,
};
