import styled from 'styled-components';

import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { spaceS, xdlColorBorder } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const EntityInfoBlockWrapper = styled.div<{ condensed: boolean }>`
  width: 100%;
  ${({ condensed }) =>
    condensed &&
    `
      .entityInfoBlockValue {
        display: block;
      }

      .kununuLogo {
        margin-left: 0;
      }

      .hideOnLowRes {
        display: none;
      }

      .hideBorderOnLowRes {
        border-right: none;
      }
    `}
`;

export const EntityInfoBlock = styled.div<{ singleLine: boolean }>`
  border-right: 2px solid ${xdlColorBorder};
  display: inline-block;
  padding: 0 ${spaceS};
  flex: 0;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    border-right: 0;
  }

  ${({ singleLine }) =>
    singleLine &&
    `
      & .entityInfoBlockValue {
        display: inline-block;
      }
    `}
`;

export const InfoBlockText = styled(BodyCopy)`
  margin-bottom: 0;
`;

export const EntityInfoBlockValue = styled.span`
  font-weight: bold;
  display: block;
  white-space: nowrap;

  @media ${mediaConfined} {
    display: inline-block;
  }
`;

export const KununuRatingLabel = styled.span`
  font-weight: normal;
`;

export const Label = styled.span`
  white-space: nowrap;

  @media ${mediaSmallOrTiny} {
    line-height: 15px;
  }
`;

export const KununuLogo = styled.img`
  margin-bottom: -2px;
  margin-left: ${spaceS};

  @media (prefers-color-scheme: dark) {
    filter: invert(1);
  }

  @media (min-width: 740px) and (max-width: 1024px) {
    margin-left: 0;
  }
`;

export const HideOnLowRes = styled.div`
  @media (min-width: 0) and (max-width: 370px) {
    display: none;
  }
`;

export const HideBorderOnLowRes = styled.div`
  @media (min-width: 0) and (max-width: 370px) {
    border-right: none;
  }
`;

export const CondensedEntityInfoBlockValue = styled(EntityInfoBlockValue)`
  @media (min-width: 740px) and (max-width: 1024px) {
    display: block;
  }
`;
