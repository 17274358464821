import type * as React from 'react';

import * as Styled from './dialog.styles';
const { variants } = Styled;

export type DialogVariant = keyof typeof variants;

export type DialogProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** The label of the cancel button */
  cancelLabel?: string;
  /** The label of the confirm button */
  confirmLabel: string;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLDivElement>;
  /** The headline of the dialog */
  headline?: string;
  /** Show a warning variant for a destructive operation. */
  isDestructive?: boolean;
  /** Set the dialog to loading */
  loading?: boolean;
  /** Pass a function to the cancel button */
  onCancel?: () => void;
  /** Pass a function to the confirm button */
  onConfirm: () => void;
  /** Do not mount/unmount the Dialog */
  keepDialogInDom?: boolean;
  /** Active state of the dialog */
  show?: boolean;
  /** The text inside of the dialog */
  text: string;
  /** The variant of the dialog */
  variant?: DialogVariant;
} & React.HTMLAttributes<HTMLDivElement>;

export type DialogPrimitiveProps = {
  showCancelAction?: boolean;
} & Omit<DialogProps, 'keepDialogInDom' | 'show'>;
