import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IllustrationSpotDragnDrop } from '@xing-com/illustrations';

import { MULTI_IMAGE_POST_MAX_IMAGES, MAX_FILE_SIZE_MB } from '../../constants';
import { Styled } from './empty-drop-zone.styles';

export const EmptyDropZone = () => (
  <React.Fragment>
    <IllustrationSpotDragnDrop />
    <Styled.Headline size="large" noMargin>
      <FormattedMessage
        id="COMMBOX_EMPTY_DROPZONE_TITLE"
        values={{ max_images: MULTI_IMAGE_POST_MAX_IMAGES }}
      />
    </Styled.Headline>
    <Styled.BodyCopy size="small" noMargin>
      <FormattedMessage
        id="COMMBOX_EMPTY_DROPZONE_TEXT"
        values={{ max_size: MAX_FILE_SIZE_MB }}
      />
    </Styled.BodyCopy>
  </React.Fragment>
);
