import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useEffect } from 'react';

import { GetCompanyIdDocument } from './GetCompanyId.gql-types';

type RedirectToDemoProps = {
  slug: string;
};
export const RedirectToDemo: FC<RedirectToDemoProps> = ({ slug }) => {
  const { data } = useQuery(GetCompanyIdDocument, {
    variables: {
      id: slug,
    },
    errorPolicy: 'all',
  });

  useEffect(() => {
    const companiesDemoUrl = `${window.location.origin}/companies/demo`;
    const id =
      data?.entityPageEX?.__typename === 'EntityPage'
        ? data.entityPageEX.context?.companyId
        : undefined;

    if (id) {
      window.location.assign(`${companiesDemoUrl}/${id}`);
    }
  }, [data]);

  return <div>Redirecting...</div>;
};
