import styled from 'styled-components';

import { Carousel as DefaultCarousel } from '@xing-com/crate-companies-entity-pages-common';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { PaginationDots as DefaultPaginationDots } from '@xing-com/pagination-dots';
import { spaceXXL, spaceL } from '@xing-com/tokens';

export const Wrapper = styled.div`
  & :global(.slick-list) {
    margin-bottom: ${spaceXXL};
  }

  @media ${mediaSmallOrTiny} {
    min-height: 0;
  }
`;

export const Carousel = styled(DefaultCarousel)`
  @media ${mediaSmallOrTiny} {
    margin-left: -20px;
    margin-right: -20px;

    /* stylelint-disable-next-line */
    .carouselMain > * {
      padding-left: 20px;
      margin-right: 0;
      box-sizing: content-box;
    }

    /* stylelint-disable-next-line */
    .carouselMain > *:last-child {
      padding-right: 20px;
      box-sizing: border-box;
      width: calc(80% + 20px);
    }
  }

  @media (min-width: 1025px) {
    /* stylelint-disable-next-line */
    .carouselMain > * {
      width: calc((100% - 2 * 16px) / 3);
    }
  }
`;

export const PaginationDots = styled(DefaultPaginationDots)`
  margin: ${spaceL} auto 0 auto;
`;
