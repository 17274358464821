import type { ChangeEvent, PropsWithChildren } from 'react';
import { type FC, useRef } from 'react';

import type { ButtonProps as DefaultButtonProps } from '@xing-com/button';
import { Button as DefaultButton } from '@xing-com/button';

import { checkFileType, dataURLtoBlob } from '../../utils';
import * as Styled from './file-upload-button.styles';

type FileUploadButtonProps = DefaultButtonProps & {
  Button?: FC;
  onFileSelected: (file: File) => void;
  onError?: (error: string | Error | ProgressEvent<FileReader>) => void;
  accept: string;
  multiple?: boolean;
};
export const FileUploadButton: FC<PropsWithChildren<FileUploadButtonProps>> = ({
  Button = DefaultButton,
  onError = () => undefined,
  onFileSelected,
  accept,
  multiple = false,
  ...rest
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handlePreview = (files: File[]): void => {
    const file = files?.[0] ?? undefined;

    if (file) {
      const mimeType = file.type;
      const fileName = file.name;

      if (checkFileType(accept, mimeType)) {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (reader.result) {
            const fileBlob = dataURLtoBlob(reader.result);
            const file = new File([fileBlob], fileName, {
              type: fileBlob.type,
            });

            onFileSelected(file);
          }
        };

        reader.onerror = (error) => {
          onError(error);
        };

        reader.readAsDataURL(file);
      } else {
        onError('INVALID_FILE_TYPE');
      }
    }
  };

  const handleInputChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    const files = ev.target.files;

    if (files) {
      const fileList = Array.from(files);
      handlePreview(fileList);
    }

    ev.target.value = '';
  };

  const triggerInputFile = (ev: MouseEvent): void => {
    ev.preventDefault();
    fileInputRef.current?.click();
  };

  return (
    <>
      <Styled.InputFile
        type="file"
        tabIndex={-1}
        ref={fileInputRef}
        name="profileImage"
        accept={accept}
        multiple={multiple}
        onChange={handleInputChange}
      />
      {/* @ts-expect-error FIXME: SC6 */}
      <Button onClick={triggerInputFile} {...rest} />
    </>
  );
};
