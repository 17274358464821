import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Dialog } from '@xing-com/dialog';

import { useCommboxContext } from '../../hooks/use-commbox';
import { trackAction } from '../../tracking';

export type DeleteDialogCommboxType = {
  setShowCloseDialog: Dispatch<SetStateAction<boolean>>;
  showCloseDialog: boolean;
};

export const DeleteDialogCommbox: FC<DeleteDialogCommboxType> = ({
  setShowCloseDialog,
  showCloseDialog,
}) => {
  const { actorType, commboxHasContent, closeDialog } = useCommboxContext();
  const { formatMessage } = useIntl();

  const trackingActorType = actorType === 'PAGE' ? 'entity' : 'profile';

  useEffect(() => {
    if (showCloseDialog && !commboxHasContent) {
      closeDialog();
    }
  }, [showCloseDialog, commboxHasContent]);

  return (
    <Dialog
      show={showCloseDialog && commboxHasContent}
      // @ts-expect-error FIXME: SC6
      testId="COMMBOX_DIALOG"
      isDestructive
      variant="stacked"
      cancelLabel={formatMessage({
        id: 'COMMBOX_CANCEL_POSTING_BUTTON_BACK',
      })}
      confirmLabel={formatMessage({
        id: 'COMMBOX_CANCEL_POSTING_BUTTON_CANCEL',
      })}
      headline={formatMessage({ id: 'COMMBOX_CANCEL_POSTING_HEADLINE' })}
      text={formatMessage({ id: 'COMMBOX_CANCEL_POSTING_TEXT_MESSAGE' })}
      onConfirm={() => {
        closeDialog();
        trackAction({
          PropActionOrigin: `social_share_commbox_actor_${trackingActorType}`,
          PropTrackAction: `social_share_commbox_cancel_dialog_select_discard`,
        });
      }}
      onCancel={() => {
        trackAction({
          PropActionOrigin: `social_share_commbox_actor_${trackingActorType}`,
          PropTrackAction: `social_share_commbox_cancel_dialog_select_continue`,
        });
        setShowCloseDialog(false);
      }}
    />
  );
};
