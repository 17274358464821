import styled from 'styled-components';

import { Facepile } from '@xing-com/facepile';
import { mediaSmallOrTiny, mediaWide } from '@xing-com/layout-tokens';
import { spaceL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const SocialProofWrapper = styled.section`
  display: flex;
  align-items: center;

  @media ${mediaWide} {
    margin-bottom: 0;
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  margin-right: ${spaceL};
  height: 46px;
`;

export const SocialProofImages = styled(Facepile)`
  margin-bottom: 0;

  & > div {
    flex-wrap: nowrap;
  }
`;

export const ContactsWrapper = styled(BodyCopy)`
  margin-bottom: 1px;
`;

export const SocialProofImagesButton = styled.button`
  height: 20px;

  @media ${mediaSmallOrTiny} {
    height: 20px;
  }
`;
