import styled, { css } from 'styled-components';

import { TextButton } from '@xing-com/button';

export const ShowMoreWrapper = styled.span<{
  $customButtons?: boolean;
}>`
  display: flex;
  align-self: center;

  @media (max-width: 739px) {
    align-self: flex-start;
  }

  ${({ $customButtons }) =>
    $customButtons &&
    css`
      @media (max-width: 739px) {
        align-self: flex-start;
      }
      @media (min-width: 740px) and (max-width: 1024px) {
        align-self: flex-start;
      }
    `}
`;

export const ShowMore = styled(TextButton)`
  border: none;
  color: var(--xdlColorCopy);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (prefers-color-scheme: light) {
    &:visited {
      color: black;
    }
  }
`;
