import {
  ENTITY_PAGE_HEADER_MORE_ACTIONS,
  ENTITY_PAGE_FOLLOW,
  ENTITY_PAGE_UNFOLLOW,
  ENTITY_PAGE_MODULE,
  ENTITY_PAGE_CHANGE_ACTOR_TYPE,
  PROP_CONTEXT_DIMENSION3,
  PROP_INTERACTION_TYPE,
  PROP_MODULES,
  PROP_TRACK_ACTION,
  PROP_ACTION_ORIGIN,
  PROP_ACTOR_TYPE,
} from '../constants';
import { modulesOrder } from '../helpers';
import { trackAction, trackScrolling } from '../tracker';

export const trackClickHeaderMoreAction = ({
  isEditor,
}: {
  isEditor: boolean;
}) => {
  trackAction(
    {
      [PROP_CONTEXT_DIMENSION3]: isEditor
        ? 'entity_page_editor'
        : 'entity_page_user',
    },
    ENTITY_PAGE_HEADER_MORE_ACTIONS
  );
};

export const trackEntityPageFollowAction = ({
  hasFollowed,
  error,
}: {
  hasFollowed: boolean;
  error?: boolean;
}) => {
  if (!error) {
    trackAction({
      [hasFollowed ? ENTITY_PAGE_FOLLOW : ENTITY_PAGE_UNFOLLOW]: 1,
      [PROP_INTERACTION_TYPE]: hasFollowed
        ? 'entity_page_follow'
        : 'entity_page_unfollow',
    });
  } else {
    trackAction({
      [PROP_TRACK_ACTION]: hasFollowed
        ? 'entity_page_follow_error'
        : 'entity_page_unfollow_error',
    });
  }
};

export const trackIsEditingToggle = (isEditing: boolean) => {
  trackAction(
    {
      [ENTITY_PAGE_CHANGE_ACTOR_TYPE]: 1,
      [PROP_ACTOR_TYPE]: isEditing ? 'entity_page' : 'user',
    },
    'EventChangeActorType'
  );
};

export const trackModuleVisibleAction = (type: string, position: number) => {
  trackScrolling(ENTITY_PAGE_MODULE, {
    [PROP_MODULES]: modulesOrder(type, position),
  });
};

export const trackBackToMainPage = () =>
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_about_us_back_to_overview',
  });

export const trackContentSwitcherFirstUse = (module: string) =>
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_content_switcher_jump',
    [PROP_ACTION_ORIGIN]: `entity_page_${module}`,
  });

export const trackOpenNavInfoBox = () =>
  trackAction({
    [PROP_TRACK_ACTION]: 'entity_page_info',
  });
