import styled from 'styled-components';

import { Carousel as InnerCarousel } from '@xing-com/crate-companies-entity-pages-common';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  spaceS,
  spaceM,
  spaceL,
  xdlColorDisabledSoft,
  xdlColorText,
  scale160,
} from '@xing-com/tokens';

export const ExternalIcon = styled.div<{ small: boolean }>`
  height: 64px !important;
  width: 64px !important;
  z-index: 10;
  background-color: ${xdlColorDisabledSoft};
  opacity: 0.9;
  border-radius: 64px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${xdlColorText};
  top: calc(50% - 32px);
  left: calc(50% - 32px);

  ${({ small }) =>
    small &&
    `height: 32px !important;
    width: 32px !important;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
  `}
`;

export const BlurredImageWrapper = styled.div`
  width: 100%;
`;

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  background-image: linear-gradient(
    90deg,
    #7c7c7c 0%,
    #000000 30%,
    #000000 70%,
    #7c7c7c 100%
  );
  z-index: 0;
`;

export const BlurredImage = styled.div`
  background-size: 150% auto;
  background-position: center center;
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  height: 100%;
  display: block;
  margin: auto;
  position: absolute;
`;

export const GalleryMediaMainWrapper = styled.div`
  @media ${mediaConfined} {
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    position: relative;
    overflow: hidden;
  }
`;

export const GalleryMediaMain = styled.div`
  @media ${mediaConfined} {
    background-color: ${xdlColorDisabledSoft};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    /* stylelint-disable-next-line */
    & > * {
      height: 100%;
    }
  }
`;

export const SmallItemsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const GalleryMedia = styled.div`
  @media ${mediaConfined} {
    background-color: ${xdlColorDisabledSoft};
    /* force div width while loading images */
    width: 120px;
    margin-right: ${spaceS};
    height: 67px;
    margin-top: ${spaceS};
    /* force div width while loading images */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    /* stylelint-disable-next-line */
    & > * {
      height: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const SliderItemWrapper = styled.div`
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
`;

export const SliderItem = styled.div`
  width: 100%;
  background-color: ${xdlColorDisabledSoft};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  /* stylelint-disable-next-line */
  & > * {
    height: 100%;
  }
`;

export const GalleryPreviewMediaWrapper = styled.div`
  display: none;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 375px;
  margin-right: ${scale160};

  @media ${mediaConfined} {
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

export const SliderPreviewMediaWrapper = styled.div`
  display: block;
  margin: 0 calc(-1 * 20px) ${spaceM};
  margin-bottom: ${spaceM};

  @media ${mediaConfined} {
    display: none;
    position: relative;
  }
`;

export const HideContent = styled.span<{ $hideContent: boolean }>`
  ${({ $hideContent }) => $hideContent && `display: none;`}
`;

export const GalleryFallbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spaceL};
  margin-right: ${scale160};
  position: relative;
  width: 100%;
  & > img {
    width: 100%;
  }

  @media ${mediaConfined} {
    align-self: flex-start;
    margin-right: ${scale160};
    max-width: 327px;
    margin-bottom: 0;
  }
`;

export const Carousel = styled(InnerCarousel)`
  > div > div {
    @media ${mediaSmallOrTiny} {
      & > * {
        width: calc(100% + 2px);
        margin-right: 0;
      }
    }
  }
`;
