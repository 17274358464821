import * as React from 'react';

import { BodyCopy } from '@xing-com/typography';

import * as Styled from './dialog.styles';
import type { DialogPrimitiveProps } from './dialog.types';

export const DialogPrimitive = React.forwardRef<
  HTMLDivElement,
  DialogPrimitiveProps
>(
  (
    {
      cancelLabel,
      confirmLabel,
      headline,
      isDestructive,
      loading,
      onCancel,
      onConfirm,
      showCancelAction,
      text,
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => (
    <Styled.Dialog data-xds="Dialog" ref={forwardedRef} {...props}>
      <Styled.Content>
        {headline && (
          <Styled.Headline size="medium">{headline}</Styled.Headline>
        )}
        <BodyCopy size="medium" noMargin>
          {text}
        </BodyCopy>
      </Styled.Content>
      <Styled.Footer $stacked={variant === 'stacked'}>
        {showCancelAction && (
          <Styled.Action
            $stacked={variant === 'stacked'}
            disabled={loading}
            fontWeight="regular"
            onClick={onCancel}
            size="medium"
          >
            {cancelLabel}
          </Styled.Action>
        )}
        <Styled.Action
          $destructive={isDestructive}
          $onlyOneButton={!showCancelAction}
          $stacked={variant === 'stacked'}
          loading={loading}
          onClick={onConfirm}
          size="medium"
        >
          {confirmLabel}
        </Styled.Action>
      </Styled.Footer>
    </Styled.Dialog>
  )
);

DialogPrimitive.displayName = 'Dialog (Primitive)';
