import * as React from 'react';

import * as Styled from './toggle.styles';
import type { ToggleProps } from './toggle.types';

export const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(
  (
    { className, disabled, error, name, on, onChange, ...props },
    forwardedRef
  ): JSX.Element => (
    <Styled.Toggle
      $error={error}
      className={className}
      data-xds="Toggle"
      disabled={disabled}
      name={name}
      onChange={onChange}
      ref={forwardedRef}
      type="checkbox"
      {...{ [onChange ? 'checked' : 'defaultChecked']: on }}
      {...props}
    />
  )
);

Toggle.displayName = 'Toggle';
