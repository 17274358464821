import styled, { css } from 'styled-components';

import {
  scale110,
  scale130,
  scale160,
  space4XL,
  spaceL,
  spaceM,
  spaceS,
  spaceXL,
} from '@xing-com/tokens';
import { Headline as DefaultHeadline } from '@xing-com/typography';

import { EditButton as DefaultEditButton } from '../edit-button/edit-button';

export const Header = styled.div<{
  $smallHeader?: boolean;
  $customButtons?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: ${space4XL};
  margin-bottom: ${scale160};
  align-items: flex-start;

  @media (--mediaXL) {
    align-items: center;
  }
  @media (max-width: 739px) {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: ${scale160};
    margin-bottom: ${scale130};
  }
  ${({ $smallHeader }) =>
    $smallHeader &&
    css`
      margin-top: ${scale160};
      margin-bottom: ${scale130};

      @media (max-width: 739px) {
        margin-top: ${scale130};
        margin-bottom: ${scale110};
      }
    `}
  ${({ $customButtons }) =>
    $customButtons &&
    css`
      flex-wrap: wrap;

      @media (max-width: 739px) {
        display: block;
      }
      @media (min-width: 740px) and (max-width: 1024px) {
        display: block;
      }
    `}
`;

export const HeaderHeadlineContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Headline = styled(DefaultHeadline)<{
  $headLineSmall?: boolean;
  $headlineWithCustomBtns?: boolean;
}>`
  margin-right: ${spaceS};

  @media (max-width: 739px) {
    margin-bottom: 0;
  }
  ${({ $headLineSmall }) =>
    $headLineSmall &&
    css`
      font-size: 24px;
    `}

  ${({ $headlineWithCustomBtns }) =>
    $headlineWithCustomBtns &&
    css`
      @media (max-width: 739px) {
        margin-bottom: 0;
      }
      @media (min-width: 740px) and (max-width: 1024px) {
        margin-bottom: 0;
      }
    `}
`;

export const EditButton = styled(DefaultEditButton)`
  margin-left: ${spaceM};
`;

export const SkeletonWrapper = styled.div`
  width: 130px;
  align-self: center;

  & p,
  & span {
    margin: 0;
  }

  @media (max-width: 739px) {
    margin-top: ${spaceL};
    align-self: flex-start;
  }
`;

export const ButtonWrapper = styled.div<{ $customButtons?: boolean }>`
  display: flex;
  flex-flow: row;

  @media (max-width: 739px) {
    flex-flow: column-reverse;
  }
  ${({ $customButtons }) =>
    $customButtons &&
    css`
      margin-left: auto;
      @media (max-width: 739px) {
        justify-content: start;
        margin-top: ${spaceL};
      }
      @media (min-width: 740px) and (max-width: 1024px) {
        justify-content: end;
        margin-top: ${spaceL};
        margin-left: auto;
        flex-wrap: wrap;
      }
    `}
`;

export const CustomButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const CustomButton = styled.div`
  margin-left: auto;
  margin-right: ${spaceM};
  align-self: center;

  @media (max-width: 739px) {
    margin: ${spaceXL} 0 0 0;
  }
`;

export const ShowMore = styled.div`
  border: none;
  color: var(--xdlColorCopy);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:visited {
    color: black;
  }
`;
