import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceM, spaceXL } from '@xing-com/tokens';

export const HeaderWrapper = styled.div.attrs({
  className: 'zenViewHeaderWrapper',
})`
  margin-bottom: ${spaceM};

  @media ${mediaConfined} {
    margin-bottom: ${spaceXL};

    .pageWrapper {
      padding-top: ${spaceXL};
      border-radius: 8px;
    }
    .rowColumn,
    .gridColumn {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
