import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import {
  space3XL,
  spaceL,
  spaceM,
  spaceXL,
  spaceXXL,
  spaceXXS,
  xdlColorInfoSoft,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const Banner = styled.div<{ $canEditMobile: boolean }>`
  display: none;
  background-color: ${xdlColorInfoSoft};

  @media (--mediaXL) {
    display: flex;
    margin-bottom: ${spaceXXL};
  }

  ${({ $canEditMobile }) =>
    $canEditMobile &&
    css`
      display: flex;
      margin-bottom: ${spaceXL};

      @media (max-width: 739px) {
        display: block;
      }
    `}
`;

export const TextContent = styled.div`
  display: flex;
  padding: ${spaceL} ${spaceM};
  flex: 1;
`;

export const Text = styled(BodyCopy)`
  margin: ${spaceXXS} 0 0 ${spaceM};
`;

export const BannerButton = styled(Button)<{ $ctaAvailableInMobile: boolean }>`
  display: inline-flex;
  align-self: center;
  margin: ${spaceL} ${spaceM};
  flex-shrink: 0;

  @media (max-width: 739px) {
    display: none;
    margin-top: 0;
    margin-left: ${space3XL};
  }
  ${({ $ctaAvailableInMobile }) =>
    $ctaAvailableInMobile &&
    `
    display: inline-flex;

    `}
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
`;
