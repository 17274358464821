import type { FC } from 'react';
import { Fragment } from 'react';

import { linkRegEx } from '../../utils/utils';
import * as Styled from './link-parser.styles';

type LinkParserProps = {
  children: string;
  completeLink?: string;
  cutLink?: string;
};
export const LinkParser: FC<LinkParserProps> = ({
  children: text = '',
  completeLink,
  cutLink,
}) => {
  const elements = text.split(linkRegEx);
  const returnCorrectLink = (url: string) => {
    if (url === cutLink) {
      return completeLink;
    }
    return url;
  };

  if (elements.length <= 1) {
    return <>{text}</>;
  }

  return (
    <>
      {elements.map((item, index) => {
        const textItem =
          index === elements.length - 1 ? item : item.concat(' ');
        if (!linkRegEx.exec(item)) {
          return <Fragment key={index}>{textItem}</Fragment>;
        }

        const url = item.toLowerCase().includes('http')
          ? item
          : 'https://'.concat(item); // assume that the website has a https domain

        const isExternalUrl = !url.includes('xing.com');
        const externalProps = isExternalUrl
          ? {
              target: '_blank',
              rel: 'noopener nofollow',
            }
          : {};

        return (
          <Styled.LinkContent
            {...externalProps}
            href={returnCorrectLink(url)}
            key={index}
          >
            {textItem}
          </Styled.LinkContent>
        );
      })}
    </>
  );
};
