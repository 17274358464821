import type React from 'react';
import { FormattedMessage } from 'react-intl';

import { MULTI_IMAGE_POST_MAX_IMAGES } from '../../constants';

export type FilledDropZoneMessagesType = {
  imagesNumber: number;
};

export const FilledDropZoneMessages: React.VFC<FilledDropZoneMessagesType> = ({
  imagesNumber,
}) => {
  return imagesNumber >= MULTI_IMAGE_POST_MAX_IMAGES ? (
    <FormattedMessage id="COMMBOX_FULL_FILLED_DROPZONE_HEADER" />
  ) : imagesNumber === MULTI_IMAGE_POST_MAX_IMAGES - 1 ? (
    <FormattedMessage id="COMMBOX_ALMOST_FULL_FILLED_DROPZONE_HEADER" />
  ) : (
    <FormattedMessage
      id="COMMBOX_FILLED_DROPZONE_HEADER"
      values={{
        pictures: MULTI_IMAGE_POST_MAX_IMAGES - imagesNumber,
      }}
    />
  );
};
