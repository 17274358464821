import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { ButtonProps } from '@xing-com/button';
import { TextButton } from '@xing-com/button';
import { ContentSwitcher } from '@xing-com/content-switcher';
import { IconInfo } from '@xing-com/icons';
import { Menu } from '@xing-com/menu';
import { usePopOver } from '@xing-com/pop-over';
import { BodyCopy } from '@xing-com/typography';

import * as Styled from './edit-header.styles';

type EditHeaderContentSwitcherProps = {
  activeIndex: number;
  setActiveIndex: (activeIndex: number) => void;
  disablePageActions?: boolean;
  handleValidations?: any;
  contentKeyList: string[];
};
const EditHeaderContentSwitcher: FC<EditHeaderContentSwitcherProps> = ({
  activeIndex,
  setActiveIndex,
  disablePageActions,
  handleValidations,
  contentKeyList,
}) => {
  const { $t } = useIntl();

  return (
    <Styled.ContentSwitcherWrapper>
      <ContentSwitcher
        activeItemIndex={activeIndex}
        variant="filled"
        data={contentKeyList.map((item, index) => ({
          copy: $t({ id: item }),
          onClick:
            activeIndex === index
              ? () => !disablePageActions && setActiveIndex(index)
              : () => {
                  !disablePageActions &&
                  handleValidations?.[contentKeyList[activeIndex]]
                    ? handleValidations[contentKeyList[activeIndex]](() =>
                        setActiveIndex(index)
                      )
                    : setActiveIndex(index);
                },
        }))}
      />
    </Styled.ContentSwitcherWrapper>
  );
};

type InfoMessage = {
  key: string;
  values: any;
};
type InfoProps = {
  infoMessage: InfoMessage;
};
const Info: FC<InfoProps> = ({ infoMessage }) => {
  const popOver = usePopOver();
  return (
    <>
      <TextButton
        innerRef={popOver.triggerRef}
        onClick={popOver.togglePopOver}
        icon={IconInfo}
        aria-label="Info"
      />

      <Menu
        dimmerTitle="close menu"
        onOutsideClick={popOver.handleHide}
        show={popOver.show}
        triggerRef={popOver.triggerRef}
      >
        <Styled.MenuContainer>
          <FormattedMessage
            id={infoMessage.key}
            defaultMessage={infoMessage.key}
            values={infoMessage.values}
          />
        </Styled.MenuContainer>
      </Menu>
    </>
  );
};

type EditHeaderProps = {
  titleKey: string;
  infoMessage?: InfoMessage;
  descriptionKey?: string;
  contentSwitcher?: EditHeaderContentSwitcherProps;
  cta?: ButtonProps;
  noMarginBottom?: boolean;
  userGuidanceCopy?: string;
};
export const EditHeader: FC<EditHeaderProps> = ({
  titleKey,
  infoMessage,
  userGuidanceCopy,
  descriptionKey,
  cta,
  contentSwitcher,
  noMarginBottom,
}) => (
  <Styled.EditHEaderWrapper $noMarginBottom={noMarginBottom}>
    <Styled.HeadlineContainer>
      <Styled.Headline size="xxlarge" noMargin>
        <FormattedMessage id={titleKey} />
        {infoMessage && <Info infoMessage={infoMessage} />}
      </Styled.Headline>

      {cta && (
        <Styled.ButtonCta variant="secondary" size="small" noMargin {...cta}>
          {cta.children}
        </Styled.ButtonCta>
      )}
    </Styled.HeadlineContainer>
    {userGuidanceCopy && (
      <BodyCopy size={'small'} noMargin>
        <Styled.SloganGuidance>
          <FormattedMessage
            id={userGuidanceCopy}
            defaultMessage={userGuidanceCopy}
          />
        </Styled.SloganGuidance>
      </BodyCopy>
    )}
    {descriptionKey && (
      <Styled.Description size="small">
        <FormattedMessage id={descriptionKey} />
      </Styled.Description>
    )}
    {cta && (
      <Styled.ButtonCtaMobile
        variant="secondary"
        size="small"
        noMargin
        {...cta}
      >
        {cta.children}
      </Styled.ButtonCtaMobile>
    )}
    {contentSwitcher && <EditHeaderContentSwitcher {...contentSwitcher} />}
  </Styled.EditHEaderWrapper>
);
