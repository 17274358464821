import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { ModuleHeader } from '@xing-com/crate-companies-entity-pages-common';
import {
  getTrackingUrl,
  trackKununuRateCompanyClick,
  trackAllBenefitsClick,
} from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import { IconAddContact } from '@xing-com/icons';
import { Headline, BodyCopy } from '@xing-com/typography';

import type { KununuData, KununuBenefits } from '../../types';
import * as Styled from './benefits.styles';

type BenefitsCardProps = {
  type: KununuBenefits['type'];
  approvals: KununuBenefits['approvals'];
  totalReviews: number;
  percentage: number;
};

type BenefitsProps = Pick<
  KununuData,
  | 'benefits'
  | 'employerRatingsCount'
  | 'companyProfileUrl'
  | 'profileUuid'
  | 'evaluateProfileUrl'
>;

const TOP_BENEFITS_AMOUNT = 3;
const MINIMUM_BENEFIT_PERCENTAGE = 1;

const formatPercentage = (percentage: number) => `${Math.round(percentage)}%`;
const getTopBenefits = ({ benefits = [] }: { benefits: KununuBenefits[] }) =>
  [...benefits]
    .filter((benefit) => !!benefit)
    .filter(
      (benefit) => (benefit?.percentage ?? 0) >= MINIMUM_BENEFIT_PERCENTAGE
    )
    .sort((a, b) => (a?.approvals ?? 0) - (b?.approvals ?? 0))
    .splice(-TOP_BENEFITS_AMOUNT)
    .reverse();

const BenefitsCard = ({
  type,
  approvals,
  totalReviews,
  percentage,
}: BenefitsCardProps) => (
  <Styled.BenefitsCard key={type}>
    <div>
      <Headline size="xlarge" as="strong">
        {formatPercentage(percentage)}
      </Headline>
    </div>
    <div>
      <Headline as="strong">
        <FormattedMessage
          id={`EP_BENEFIT_${type}`}
          defaultMessage={`EP_BENEFIT_${type}`}
        />
      </Headline>
      <BodyCopy size="small">
        <FormattedMessage
          id="EP_KUNUNU_BENEFITS_CONFIRMATION"
          defaultMessage="EP_KUNUNU_BENEFITS_CONFIRMATION"
          values={{ total: approvals, totalReviews }}
        />
      </BodyCopy>
    </div>
  </Styled.BenefitsCard>
);

const Benefits = ({
  benefits,
  companyProfileUrl,
  employerRatingsCount,
  profileUuid,
  evaluateProfileUrl,
}: BenefitsProps) => {
  const showMore = {
    href: `${getTrackingUrl({
      to: companyProfileUrl,
      trackingKey: 'viewKununuBenefits',
    })}#summary-benefits-card`,
    target: '_blank',
    copyKey: 'EP_KUNUNU_ALL_BENEFITS',
    number: benefits.length,
    externalLink: true,
    onClick: () => trackAllBenefitsClick(),
  };

  const topBenefits = getTopBenefits({ benefits });

  const showRateCompanyModule = profileUuid && evaluateProfileUrl;
  const showBenefitsModule = topBenefits.length > 0 || showRateCompanyModule;

  if (!showBenefitsModule) return null;

  return (
    <div>
      <ModuleHeader
        headlineCopyKey="EP_KUNUNU_BENEFITS_TITLE"
        showMore={showMore}
        small
      />
      {topBenefits.length > 0 && (
        <Styled.BenefitsSection data-cy="benefits_section">
          {topBenefits.map(({ type, approvals, percentage }) => (
            <BenefitsCard
              key={type}
              type={type}
              approvals={approvals}
              totalReviews={employerRatingsCount}
              percentage={percentage ?? 0}
            />
          ))}
        </Styled.BenefitsSection>
      )}
      {showRateCompanyModule && (
        <Styled.RateThisCompanyContainer>
          <p>
            <FormattedMessage
              id="EP_KUNUNU_RATE_COMPANY"
              defaultMessage="EP_KUNUNU_RATE_COMPANY"
            />
          </p>
          <Button
            onClick={() => trackKununuRateCompanyClick()}
            variant="tertiary"
            size="small"
            href={getTrackingUrl({
              to: evaluateProfileUrl,
              trackingKey: 'rateCompany',
            })}
            // @ts-expect-error FIXME: SC6
            target="_blank"
            icon={IconAddContact}
          >
            <FormattedMessage
              id="EP_KUNUNU_RATE_COMPANY_BUTTON"
              defaultMessage="EP_KUNUNU_RATE_COMPANY_BUTTON"
            />
          </Button>
        </Styled.RateThisCompanyContainer>
      )}
    </div>
  );
};

export default React.memo(Benefits);
