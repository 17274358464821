import type { EntityPageCreateDocumentMutation } from '../../../graphql/mutation/entity-page-create-document.gql-types';
import type { GetDocumentsQuery } from '../../../graphql/queries/documents-query.gql-types';

type Item = {
  node: { id: string };
};

const getEdges = (
  action: string,
  queryData: GetDocumentsQuery,
  mutationData: EntityPageCreateDocumentMutation &
    NonNullable<
      EntityPageCreateDocumentMutation['entityPageCreateDocument']
    >['success']
) => {
  switch (action) {
    case 'UPDATE': {
      let edges: any[] | undefined | null = [];

      if (
        queryData?.pagesAboutUs?.__typename === 'AboutEntity' &&
        queryData?.pagesAboutUs?.documents?.__typename ===
          'EntityDocumentConnection' &&
        queryData?.pagesAboutUs?.documents?.edges
      ) {
        edges = queryData?.pagesAboutUs?.documents?.edges;
        const itemIndex = edges.findIndex(
          (item: Item) => item?.node.id === mutationData?.id
        );
        edges[itemIndex].node.description = mutationData?.description;
      }

      return edges;
    }
    case 'DELETE': {
      const edges =
        queryData?.pagesAboutUs?.__typename === 'AboutEntity' &&
        queryData?.pagesAboutUs?.documents?.__typename ===
          'EntityDocumentConnection' &&
        queryData?.pagesAboutUs?.documents?.edges?.filter(
          (edge: any) => edge.node.id !== mutationData?.id
        );

      return edges;
    }
    default: {
      let edges: any[] | undefined | null = [];

      if (
        queryData?.pagesAboutUs?.__typename === 'AboutEntity' &&
        queryData?.pagesAboutUs?.documents?.__typename ===
          'EntityDocumentConnection' &&
        queryData?.pagesAboutUs?.documents?.edges
      ) {
        edges = queryData?.pagesAboutUs?.documents?.edges;
      }

      const newItem = {
        __typename: 'EntityDocumentEdge',
        node: {
          __typename: 'EntityDocument',
          id: mutationData?.entityPageCreateDocument?.success?.id,
          description:
            mutationData?.entityPageCreateDocument?.success?.description,
          documentUrl:
            mutationData?.entityPageCreateDocument?.success?.documentUrl,
          filename: mutationData?.entityPageCreateDocument?.success?.filename,
        },
      };

      return [newItem, ...edges];
    }
  }
};
export const handleCache = (
  cache: any,
  query: any,
  variables: any,
  action: string,
  mutationData: any
) => {
  const queryMediaData = cache.readQuery({
    query,
    variables,
  });

  cache.writeQuery({
    query,
    variables,
    data: {
      entityPage: {
        ...queryMediaData.entityPage,
      },
      pagesAboutUs: {
        __typename: 'AboutEntity',
        documents: {
          __typename: 'EntityDocumentConnection',
          edges: getEdges(action, queryMediaData, mutationData),
        },
      },
    },
  });
};
