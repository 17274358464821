import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import type * as Types from '@xing-com/crate-common-graphql-types';
import {
  FormattedTimestamp,
  usePageContext,
  useEditContext,
} from '@xing-com/crate-companies-entity-pages-common';

import { PAGES_URL_PREFIX, PINNED } from '../../config/constants';
import { getActionTextIdByType } from '../../utils';
import { DotSeparator } from '../dot-separator/dot-separator';
import { NewsStatusFlag } from '../news-status-flag/news-status-flag';
import type { NewsProps } from '../news/news';
import * as Styled from './news-item-actor.styles';

type NewsItemActorProps = Pick<NewsProps, 'actor' | 'isFollowingPage'> & {
  pageId: string;
  actionType?:
    | 'EntityPageContentFeedArticlePost'
    | 'EntityPageContentFeedCompanyArticlePost'
    | 'EntityPageContentFeedPlainTextPostingPost'
    | 'EntityPageContentFeedPostingPost'
    | 'EntityPageContentFeedArticlesArticlePost';
  publicationState?: Types.PostingPublicationState;
  showActor?: boolean;
  isPinned?: boolean;
  date: string;
};
export const NewsItemActor: FC<NewsItemActorProps> = ({
  actionType,
  actor,
  date,
  isFollowingPage = false,
  isPinned,
  pageId,
  publicationState,
  showActor,
}) => {
  const { pageContext } = usePageContext() ?? {};
  const { isEditing } = useEditContext();
  const { focusType } = pageContext ?? {};

  let urlPath = PAGES_URL_PREFIX;

  switch (focusType) {
    // case EntityPageFocusType.<SOMETHING ELSE>:
    //   urlPath = ANOTHER_PREFIX;
    //   break;
    default:
      urlPath = PAGES_URL_PREFIX;
      break;
  }

  // URLs here are assumed to start with a slash '/'
  const permalink = `${urlPath}/${actor.slug}`;
  const url = `${permalink}${
    permalink.includes('?') ? '&' : '?'
  }sc_o=entity_page_news_module_to_publisher`;

  const actionTextId = getActionTextIdByType(actionType);
  const itemStatusState = isPinned ? PINNED : publicationState;

  // whether to show `X posted this article` legend or not
  let displayAction = false;

  if (isEditing) {
    if (!publicationState && !isPinned) {
      displayAction = true;
    }
  } else {
    if (!isPinned) {
      displayAction = true;
    }
  }

  const showFollowButton = false; // following is currently disabled here
  return (
    <>
      {showActor ? (
        <Styled.Actor>
          <Styled.ActorAnchor href={url}>
            <Styled.ProfileImage
              size="small"
              type="company"
              profileName={actor.title}
              // @ts-expect-error FIXME: SC6
              alt={actor.title}
              src={actor.logo}
            />
          </Styled.ActorAnchor>
          <Styled.ActorContent>
            <Styled.ActorNameWrap>
              <Styled.ActorName href={url}>{actor.title}</Styled.ActorName>
              {actor.isInsider && (
                <Styled.Flag variant="insider" size="small" />
              )}
            </Styled.ActorNameWrap>
            <Styled.ActorSubtitle>
              {((isEditing && publicationState) || isPinned) &&
                itemStatusState && <NewsStatusFlag state={itemStatusState} />}
              {displayAction && (
                <span>
                  {actionTextId && <FormattedMessage id={actionTextId} />}
                </span>
              )}
              <DotSeparator />
              <span>
                <FormattedTimestamp date={new Date(date)} />
              </span>
            </Styled.ActorSubtitle>
          </Styled.ActorContent>
          {showFollowButton && (
            <Styled.FollowButton
              pageId={pageId}
              isFollowingPage={isFollowingPage}
            />
          )}
        </Styled.Actor>
      ) : (
        <Styled.NoActor>
          {isPinned ? (
            <NewsStatusFlag state="PINNED" />
          ) : (
            <FormattedTimestamp date={new Date(date)} />
          )}
        </Styled.NoActor>
      )}
    </>
  );
};
