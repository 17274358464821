import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

import * as Types from '@xing-com/crate-common-graphql-types';
import { SubpageNavigation } from '@xing-com/crate-companies-entity-pages-common';
import { trackNewsPublicationFilter } from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import { useFeatureSwitch } from '@xing-com/hub';

import { FEATURE_SWITCH_ENABLE_DRAFT_SCHEDULE } from '../../config/constants';
import type { EntityPageFeedQueryVariables } from '../../graphql/queries/feed-query.gql-types';
import { EntityPageFeedDocument } from '../../graphql/queries/feed-query.gql-types';
import { publicationStateIndex } from '../../utils';
import * as Styled from './news-navigation.styles';

type NewsNavigationProps = {
  feedQueryVars: EntityPageFeedQueryVariables;
  publicationState?: Types.PostingPublicationState | null;
  setPublicationState: (
    publicationState: Types.PostingPublicationState
  ) => void;
  isLoading: boolean;
  pageId: string;
  metadata: Types.EntityPageContentFeedCollectionMetadata;
};
export const NewsNavigation: FC<NewsNavigationProps> = ({
  setPublicationState,
  publicationState,
  feedQueryVars,
  isLoading,
  pageId,
  metadata,
}) => {
  const intl = useIntl();
  const enableDraftSchedule = useFeatureSwitch(
    FEATURE_SWITCH_ENABLE_DRAFT_SCHEDULE
  );
  const selectPublicationState = (state: Types.PostingPublicationState) => {
    if (state !== publicationState && !isLoading) {
      setPublicationState(state);
      trackNewsPublicationFilter(state, pageId);
    }
  };

  const { refetch } = useQuery(EntityPageFeedDocument, {
    variables: { ...feedQueryVars },
    fetchPolicy: 'network-only',
  });

  const visibleDefaultTabs = [
    {
      copy: intl.formatMessage({
        id: 'NEWS_MODULE_PUBLISHED_TAB',
      }),
      onClick: () => {
        refetch();
        selectPublicationState(Types.PostingPublicationState.Published);
      },
      disabled: isLoading,
      'data-testid': 'PUBLISHED_TAB',
    },
    {
      copy: `${intl.formatMessage({
        id: 'NEWS_MODULE_HIDDEN_TAB',
      })} (${metadata.hiddenTotal})`,
      onClick: () => {
        refetch();
        selectPublicationState(Types.PostingPublicationState.Hidden);
      },
      disabled: isLoading || metadata.hiddenTotal === 0,
      'data-testid': 'HIDDEN_TAB',
    },
  ];

  const featureSwitchTabs = [
    visibleDefaultTabs[0],
    {
      copy: `${intl.formatMessage({
        id: 'NEWS_MODULE_SCHEDULED_TAB',
      })} (${metadata.scheduledTotal})`,
      onClick: () => {
        refetch();
        selectPublicationState(Types.PostingPublicationState.Scheduled);
      },
      disabled: isLoading || metadata.scheduledTotal === 0,
      'data-testid': 'SCHEDULED_TAB',
    },
    {
      copy: `${intl.formatMessage({
        id: 'NEWS_MODULE_DRAFT_TAB',
      })} (${metadata.draftTotal})`,
      onClick: () => {
        refetch();
        selectPublicationState(Types.PostingPublicationState.Draft);
      },
      disabled: isLoading || metadata.draftTotal === 0,
      'data-testid': 'DRAFT_TAB',
    },
    visibleDefaultTabs[1],
  ];

  const filterProps = {
    intl,
    activeItemIndex: publicationStateIndex(publicationState),
    allowDropdown: false,
  };

  return (
    <Styled.NewsNavigation data-testid={'NEWS_NAVIGATION'}>
      <>
        {enableDraftSchedule ? (
          <SubpageNavigation
            {...filterProps}
            variant={'filled'}
            size={'small'}
            data={featureSwitchTabs}
            activeItemIndex={publicationStateIndex(publicationState, true)}
            noPadding
          />
        ) : (
          <SubpageNavigation
            {...filterProps}
            variant={'filled'}
            size={'small'}
            data={visibleDefaultTabs}
            noPadding
          />
        )}
      </>
    </Styled.NewsNavigation>
  );
};
