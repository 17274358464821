import styles from 'styled-components';

import * as tokens from '@xing-com/tokens';

export const BottomBack = styles.div.attrs({
  className: 'bottomBack',
})`
    display: flex;
    justify-content: center;
    margin-top: ${tokens.spaceXXL};
`;
