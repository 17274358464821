import styled from 'styled-components';

import { SkeletonImage } from '@xing-com/skeleton';

export const MapSkeleton = styled(SkeletonImage)`
  width: 100%;
  height: 274px;

  @media (max-width: 739px) {
    height: 400px;
  }
`;
