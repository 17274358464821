import { type FC } from 'react';

import {
  useEditContext,
  usePageContext,
} from '@xing-com/crate-companies-entity-pages-common';

import { useHeaderContext } from '../../hooks/use-header-context/use-header-context';
import { EditLogoMenu } from '../edit/edit-logo/edit-logo-menu';
import * as Styled from './logo-area.styles';

export const LogoArea: FC = () => {
  const { title } = useHeaderContext();
  const { pageContext } = usePageContext() ?? {};
  const { isEditing } = useEditContext();

  return (
    <Styled.LogoArea>
      <Styled.LogoImageContainer
        profileName={title}
        src={pageContext?.logo}
        type="company"
        variant="responsive"
        data-cy="LOGO_IMAGE"
      />
      {isEditing && <EditLogoMenu />}
    </Styled.LogoArea>
  );
};
