import { useMutation } from '@apollo/client';

import { UploadApplication } from '@xing-com/crate-common-graphql-types';
import { useFetch } from '@xing-com/crate-xinglet';

import { ACCEPTED_MIME_TYPES, MAX_FILE_SIZE } from '../constants';
import { CommboxUploadRequestDocument } from '../graphql/upload-file-request.gql-types';
import type { CommboxErrorMessageType } from '../types';

export type UploadFileType = (
  file: File
) => Promise<{ fileId?: string; error?: CommboxErrorMessageType }>;

export type UseUploadFileOptions = {
  crateImageUploader?: (file: File) => Promise<string | void | null>;
  setErrorMessage: React.Dispatch<
    React.SetStateAction<CommboxErrorMessageType>
  >;
};
export const useUploadFile = ({
  crateImageUploader,
  setErrorMessage,
}: UseUploadFileOptions) => {
  const [createUploadRequest] = useMutation(CommboxUploadRequestDocument, {
    onError: () => setErrorMessage('GENERIC_ERROR'),
  });
  const fetch = useFetch();

  const checkFileErrors = (file: File) => {
    if (!file?.size || !file?.type) return null;
    if (file.size <= MAX_FILE_SIZE && ACCEPTED_MIME_TYPES.includes(file.type))
      return null;

    if (file.size > MAX_FILE_SIZE) return 'INVALID_FILE_SIZE';
    if (!ACCEPTED_MIME_TYPES.includes(file.type)) return 'INVALID_FILE_TYPE';

    return 'GENERIC_ERROR';
  };

  const uploadFile = async (file: File) => {
    const fileError = checkFileErrors(file);
    if (fileError) return { error: fileError };

    if (crateImageUploader) {
      const fileId = await crateImageUploader(file);
      if (fileId) return { fileId };
    } else {
      const { data } = await createUploadRequest({
        variables: {
          application: UploadApplication.Postings,
          fileSize: file.size,
          fileType: file.type,
        },
      });

      if (data?.uploadRequest?.success) {
        const { url, authToken, id } = data.uploadRequest.success;

        const response = await fetch(url as unknown as URL, {
          body: file,
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/offset+octet-stream',
            'Tus-Resumable': '1.0.0',
            'Upload-Offset': '0',
          },
          method: 'PATCH',
        });

        if (response.ok) return { fileId: id };
      }
    }

    return { error: 'GENERIC_ERROR' as CommboxErrorMessageType };
  };

  return { uploadFile };
};
