import { useContext } from 'react';

import { DialogContext } from '../../context/dialog-context/dialog-context';

export const useDialogContext = () => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error('No host context found. Please setup the DialogContext');
  }

  return context;
};
