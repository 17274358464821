import type { FC, ReactNode } from 'react';

import { ViewWrapperDefaultGrid } from '@xing-com/platform-layout-view-wrapper';

import type { BackLinkProps } from './back-link/back-link';
import { ZenViewGridLess } from './zen-view-gridless/zen-view-gridless';
import { ZenViewSubHeader } from './zen-view-sub-header';

type ZenViewProps = {
  backLink?: BackLinkProps;
  subHeader?: ReactNode;
  children?: ReactNode;
  className?: string;
  contentClassName?: string;
  helpLink?: string;
  testId?: string;
  withSubNav?: boolean;
};

const ZenView: FC<ZenViewProps> = ({
  backLink,
  children,
  ...props
}: ZenViewProps) => (
  <ZenViewGridLess {...props}>
    <ViewWrapperDefaultGrid>
      {!props.withSubNav && backLink && (
        <ZenViewSubHeader backLink={backLink} />
      )}
      {children}
    </ViewWrapperDefaultGrid>
  </ZenViewGridLess>
);

export { ZenView };
