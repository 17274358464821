import type { FC } from 'react';

import { useErrorContext } from '../../hooks/use-error-context/use-error-context';
import * as Styled from './error-message.styles';

type ErrorMessageProps = {
  onClick: () => void;
};
export const ErrorMessage: FC<ErrorMessageProps> = ({ onClick }) => {
  const { message, show } = useErrorContext();

  return (
    <Styled.Feedback variant="error" show={show} handleOnClose={onClick}>
      {message}
    </Styled.Feedback>
  );
};
