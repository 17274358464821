import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  trackJobsFilterSelect,
  trackJobsFilterDeselect,
  trackJobsFilterClear,
  trackJobsFilterClearAll,
} from '@xing-com/crate-companies-entity-pages-common/src/tracking';

import { transformAggregations } from '../../../helpers/jobs-filter';
import type {
  FilterStateSelectedItemsType,
  AggregationQueryType,
  FiltersItemsTypeKey,
  FilterNameType,
} from '../../../types';
import * as Styled from './check-lists.styles';

const allItemsSelected = (selectedItems: FilterStateSelectedItemsType) =>
  selectedItems.disciplines?.length === 0 &&
  selectedItems.careerLevels?.length === 0 &&
  selectedItems.cities?.length === 0;

const trackingKeysMap: Record<FilterNameType, string> = {
  cities: 'location',
  disciplines: 'disciplines',
  careerLevels: 'career_level',
};

const getTrackingKeys = (key: FilterNameType | FiltersItemsTypeKey) =>
  trackingKeysMap[key] ?? '';

type CheckListsProps = {
  selectedItems: FilterStateSelectedItemsType;
  aggregations?: AggregationQueryType;
  onClickCheckbox: (id: string, filterName: FilterNameType) => void;
  onClearCheckbox: (filterName: FilterNameType) => void;
  onClearAll: () => void;
};
export const CheckLists: FC<CheckListsProps> = ({
  selectedItems,
  aggregations,
  onClickCheckbox,
  onClearCheckbox,
  onClearAll,
}) => {
  const { formatMessage } = useIntl();

  const getActiveFiltersTrackingData = ({
    removeId = '',
    removeFilter = '',
  } = {}) => {
    const selectedItemsKeys = Object.keys(selectedItems) as Array<
      keyof FilterStateSelectedItemsType
    >;
    const activeFilters = selectedItemsKeys
      .filter((filterName) => filterName !== removeFilter)
      .map((filterName) => {
        return (selectedItems[filterName] as string[])
          .filter((selectedItemId) => selectedItemId !== removeId)
          .map(
            (selectedItemId) =>
              `${getTrackingKeys(filterName)}_${
                items[filterName]?.find((item) => item.id === selectedItemId)
                  ?.trackingKey
              }`
          );
      })
      .flat();

    return activeFilters;
  };

  const handleToggleCheckbox = (
    filterName: FilterNameType,
    id: string,
    trackingKey: string
  ) => {
    if (selectedItems[filterName]?.includes(id)) {
      const trackActiveFiltersData = getActiveFiltersTrackingData({
        removeId: id,
      });
      trackJobsFilterDeselect(
        getTrackingKeys(filterName),
        trackingKey,
        trackActiveFiltersData
      );
    } else {
      const trackActiveFiltersData = getActiveFiltersTrackingData();
      trackActiveFiltersData.push(
        `${getTrackingKeys(filterName)}_${trackingKey}`
      );
      trackJobsFilterSelect(
        getTrackingKeys(filterName),
        trackingKey,
        trackActiveFiltersData
      );
    }

    onClickCheckbox(id, filterName);
  };

  const handleOnClearCheckbox = (filterName: FilterNameType) => {
    onClearCheckbox(filterName);
    const trackActiveFiltersData = getActiveFiltersTrackingData({
      removeFilter: filterName,
    });
    trackJobsFilterClear(getTrackingKeys(filterName), trackActiveFiltersData);
  };

  const handleClearAll = () => {
    onClearAll();
    trackJobsFilterClearAll();
  };

  if (!aggregations) return null;

  const items = transformAggregations(aggregations);

  return (
    <Styled.FiltersWrapper>
      <Styled.Inner>
        <Styled.Button
          variant={'tertiary'}
          size={'small'}
          onClick={handleClearAll}
          $selected={allItemsSelected(selectedItems)}
        >
          <FormattedMessage id="EP_JOBS_FILTER_ALL" />
        </Styled.Button>
        <Styled.Filter
          label={formatMessage({
            id: 'EP_JOBS_DISCIPLINE',
          })}
          items={items?.disciplines}
          selectedItems={selectedItems.disciplines || []}
          filterName="disciplines"
          toggleCheckbox={handleToggleCheckbox}
          onClear={handleOnClearCheckbox}
        />
        <Styled.Filter
          label={formatMessage({
            id: 'EP_JOBS_CAREER_LEVEL',
          })}
          items={items?.careerLevels}
          selectedItems={selectedItems.careerLevels || []}
          filterName="careerLevels"
          toggleCheckbox={handleToggleCheckbox}
          onClear={handleOnClearCheckbox}
        />
        <Styled.Filter
          label={formatMessage({
            id: 'EP_JOBS_LOCATIONS',
          })}
          filterName="cities"
          items={items?.cities}
          selectedItems={selectedItems.cities || []}
          toggleCheckbox={handleToggleCheckbox}
          onClear={handleOnClearCheckbox}
        />
      </Styled.Inner>
    </Styled.FiltersWrapper>
  );
};
