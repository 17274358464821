import type { FC } from 'react';

import * as Styled from './skeleton.styles';

export const GalleryManagerSkeleton: FC = () => (
  <>
    <Styled.DocumentsWrapperSkeleton>
      <Styled.DocumentsSkeleton amountRows={2} />
      <Styled.ItemActionsWrapper>
        <Styled.ItemsActionsButton size="small" />
        <Styled.ItemsActionsButton size="small" />
      </Styled.ItemActionsWrapper>
    </Styled.DocumentsWrapperSkeleton>
    <Styled.DocumentsActions>
      <Styled.PublishButton size="small" />
    </Styled.DocumentsActions>
  </>
);
