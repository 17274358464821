import type { CountryCodesAlpha2 } from '@xing-com/crate-common-graphql-types';

import type { EntityPageUpdateLocationMutationVariables } from '../../graphql/mutations/update-location.gql-types';
import type { LocationStateType } from '../../types';

export const addParenthisisOnNumbers = (value: string) =>
  value?.startsWith('0')
    ? (value = `(${value.substring(0, 1)})${value.substring(1, value.length)}`)
    : value;

export const removeParenthisisOnNumbers = (value: string) =>
  value.replace('(0)', '0');

export const removeWhiteSpaces = (value: string) => value.replace(' ', '');

export const addHttpToWebsiteUrl = (value: string) =>
  !value.includes('http') && !value.includes('https') && value !== ''
    ? `http://${value}`
    : value;

export const getLocationMutationInput = (
  locationState: LocationStateType
): EntityPageUpdateLocationMutationVariables => {
  const locationMutationInput: Partial<EntityPageUpdateLocationMutationVariables> =
    {};
  Object.keys(locationState).map((type) => {
    switch (type) {
      case 'country': {
        locationMutationInput['countryIsoCode'] = locationState[type]
          .value as CountryCodesAlpha2;
        break;
      }
      case 'faxNumberCountryCode': {
        locationMutationInput[type] =
          locationState['faxNumber'].value !== ''
            ? parseInt(locationState[type].value)
            : null;
        break;
      }
      case 'phoneNumberCountryCode': {
        locationMutationInput[type] =
          locationState['phoneNumber'].value !== ''
            ? parseInt(locationState[type].value)
            : null;
        break;
      }
      case 'phoneNumber':
      case 'faxNumber': {
        locationMutationInput[type] = removeParenthisisOnNumbers(
          locationState[type].value
        );
        break;
      }
      case 'websiteURL': {
        locationMutationInput.websiteUrl =
          !locationState[type].value.includes('http') &&
          !locationState[type].value.includes('https') &&
          locationState[type].value !== ''
            ? `http://${locationState[type].value}`
            : locationState[type].value;
        break;
      }
      default: {
        locationMutationInput[
          type as keyof EntityPageUpdateLocationMutationVariables
        ] = locationState[type].value;
        break;
      }
    }
  });

  Object.keys(locationMutationInput).map((type) => {
    if (
      locationMutationInput[
        type as keyof EntityPageUpdateLocationMutationVariables
      ] === ''
    ) {
      // @ts-expect-error TODO: fix this type
      locationMutationInput[
        type as keyof EntityPageUpdateLocationMutationVariables
      ] = null;
    }
  });

  return locationMutationInput as EntityPageUpdateLocationMutationVariables;
};
