import type React from 'react';

import {
  EmptyState,
  useEditContext,
} from '@xing-com/crate-companies-entity-pages-common';

import { JOBMANAGER_URL, XING_JOBS_URL } from '../../jobs-module/config';

type JobsEmptyStateProps = { statusCode?: 404 | undefined };

export const JobsEmptyState: React.FC<JobsEmptyStateProps> = ({
  statusCode,
}) => {
  const { isEditor } = useEditContext();
  return (
    <EmptyState
      headlineCopyKey={
        isEditor
          ? 'EP_JOBS_EMPTYSTATE_HEADER'
          : 'EP_JOBS_PUBLIC_EMPTYSTATE_HEADER'
      }
      textCopyKey={
        isEditor ? 'EP_JOBS_EMPTYSTATE_BODY' : 'EP_JOBS_PUBLIC_EMPTYSTATE_BODY'
      }
      statusCode={statusCode}
      buttonProps={{
        // @ts-expect-error FIXME: SC6
        copyKey: isEditor
          ? 'EP_JOBS_EMPTYSTATE_CTA'
          : 'EP_JOBS_PUBLIC_EMPTYSTATE_CTA',
        href: isEditor ? JOBMANAGER_URL : XING_JOBS_URL,
      }}
    />
  );
};
