import * as React from 'react';

import * as Styled from './theme-mode-switcher.styles';
import type { ThemeModeSwitcherProps } from './theme-mode-switcher.types';

export const DarkMode = React.forwardRef<
  HTMLDivElement,
  ThemeModeSwitcherProps
>(
  ({ children, ...props }, forwardedRef): JSX.Element => (
    <Styled.ThemeModeSwitcher
      className="is-dark-theme"
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Styled.ThemeModeSwitcher>
  )
);

DarkMode.displayName = 'DarkMode';

export const LightMode = React.forwardRef<
  HTMLDivElement,
  ThemeModeSwitcherProps
>(
  ({ children, ...props }, forwardedRef): JSX.Element => (
    <Styled.ThemeModeSwitcher
      className="is-light-theme"
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Styled.ThemeModeSwitcher>
  )
);

LightMode.displayName = 'LightMode';
