import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { ExternalEditInfoBanner as DefaultExternalEditInfoBanner } from '@xing-com/crate-companies-entity-pages-common';
import { Dialog } from '@xing-com/dialog';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  spaceM,
  spaceL,
  spaceXL,
  xdlColorBorderSoft,
  space4XL,
} from '@xing-com/tokens';

export const DeleteDialog = styled(Dialog)`
  text-align: center;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  margin-bottom: ${spaceXL};
  justify-content: center;
`;

export const ImageWrapperInner = styled.div`
  position: relative;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 416px;
`;

export const DeleteButton = styled(Button)`
  position: absolute;
  top: ${spaceM};
  right: ${spaceM};
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  padding-top: ${spaceL};
`;

export const GalleryActions = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${xdlColorBorderSoft};
  margin-top: ${spaceXL};
  padding: ${spaceL} 0;
`;

export const PublishButton = styled(Button)`
  margin-left: ${spaceL};
`;

export const ExternalEditInfoBanner = styled(DefaultExternalEditInfoBanner)`
  margin-top: ${spaceXL};

  @media ${mediaConfined} {
    margin-top: ${space4XL};
  }
`;
