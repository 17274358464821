import { useContext } from 'react';

import { CommboxFormContext } from '../contexts/commbox-form-context';

export const useCommboxFormContext = () => {
  const context = useContext(CommboxFormContext);

  if (!context) {
    throw new Error(
      'useCommboxFormContext must be used within a CommboxFormProvider'
    );
  }

  return context;
};
