import type * as React from 'react';

import * as Styled from './dropdown.styles';
const { sizes } = Styled;

export type DropdownSize = keyof typeof sizes;
export type DropdownVariant = 'plain';

export type DropdownProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Content of the dropdown */
  children?: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** Disable the dropdown */
  disabled?: boolean;
  /** Set error state */
  error?: boolean;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLSelectElement>;
  /** Set helper text */
  helperText?: string;
  /** The id attribute of the input or textarea */
  id?: string;
  /** @callback */
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  /** The placeholder will prefill the dropdown */
  placeholder?: string;
  /** The size on mobile screens or larger */
  size?: DropdownSize;
  /** @deprecated Please use the "ref" property instead */
  innerRef?:
    | React.RefObject<HTMLSelectElement>
    | React.MutableRefObject<HTMLSelectElement | null>;
  /** Set success state */
  success?: boolean;
  /** The value attribute of the dropdown */
  value?: string;
  /** The variant of the dropdown */
  variant?: DropdownVariant;
} & React.HTMLAttributes<HTMLSelectElement>;
