import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useEffect } from 'react';

import type { PageContextType } from '@xing-com/crate-companies-entity-pages-common';
import { usePageContext } from '@xing-com/crate-companies-entity-pages-common';

import { CompaniesWorkflowStatusQueryDocument } from './CompaniesWorkflowStatusQuery.gql-types';
import { PageCreatedBanner } from './page-created-banner';
import { RepublishBanner } from './republish-banner';

type CompanyBannersContainerProps = {
  companyId?: string | null;
  isDraft: boolean;
};
export const CompanyBannersContainer: FC<CompanyBannersContainerProps> = ({
  companyId,
  isDraft,
}) => {
  const { setPageContext } = usePageContext() ?? {};
  const { data, loading } = useQuery(CompaniesWorkflowStatusQueryDocument, {
    skip: !companyId,
    variables: {
      // @ts-expect-error TODO: fix this type
      id: companyId,
    },
  });

  const showPageCreatedBanner = isDraft && data?.company?.inPublicationWorkflow;
  const showRepublishBanner = isDraft && !data?.company?.inPublicationWorkflow;

  useEffect(() => {
    if (showPageCreatedBanner || showRepublishBanner) {
      // @ts-expect-error TODO: fix setPageContext type
      setPageContext(
        (prevState) =>
          ({
            ...prevState,
            isShowingCompanyBanner: true,
          }) as PageContextType
      );
    }
  }, [data]);

  if (loading) return null;

  if (showPageCreatedBanner) {
    return <PageCreatedBanner />;
  }

  if (showRepublishBanner) {
    return <RepublishBanner />;
  }

  return null;
};
