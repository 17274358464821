import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import {
  cornerRadius3XL,
  cornerRadiusXL,
  cornerRadiusXXL,
  space3XL,
  spaceL,
  spaceXL,
  spaceXXL,
} from '@xing-com/tokens';

import * as Styled from './card-inspire.styles';
import type { CardInspireProps } from './card-inspire.types';

export const CardInspire = React.forwardRef<HTMLDivElement, CardInspireProps>(
  (
    { children, className, size = 'auto', variant, ...props },
    forwardedRef
  ): JSX.Element => {
    // FIXME: Move these styles to Styled Component file after upgrading to Styled Components v6
    const styles = `
    [data-xds-util="card-container"] {
      container-type: inline-size;
      width: 100%;
    }

    [data-xds-util="card"] {
      border-radius: ${cornerRadiusXL} !important;
      padding-block: ${spaceL} !important;
      padding-inline: ${spaceXL} !important;
      transition: border-radius 0.3s ease;

      @container (min-width: 350px) {
        border-radius: ${cornerRadiusXXL} !important;
        padding-block: ${spaceXL} !important;
        padding-inline: ${spaceXXL} !important;
      }

      @container (min-width: 560px) {
        border-radius: ${cornerRadius3XL} !important;
        padding-block: ${spaceXXL} !important;
        padding-inline: ${space3XL} !important;
      }
    }
  `;

    return (
      <>
        <Helmet>
          <style>{styles}</style>
        </Helmet>
        <Styled.CardContainer
          // FIXME: These data-xds-util are just a temporary solution
          className="is-light-theme"
          data-xds-util={size === 'auto' ? 'card-container' : undefined}
          ref={forwardedRef}
        >
          <Styled.CardInspire
            $size={size}
            $variant={variant}
            className={className}
            // FIXME: These data-xds-util are just a temporary solution
            data-xds-util={size === 'auto' ? 'card' : undefined}
            {...props}
          >
            {children}
          </Styled.CardInspire>
        </Styled.CardContainer>
      </>
    );
  }
);

CardInspire.displayName = 'CardInspire';
