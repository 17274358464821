import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { StatusBannerAutoHide } from '@xing-com/banner';

import { BANNER_TIMEOUT } from '../../constants';
import { getErrorId } from '../../helpers/commbox-form';
import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxFormContext } from '../../hooks/use-commbox-form-context';

export const CommboxStatusBanners: FC = () => {
  const { createPostingError } = useCommboxContext();
  const { infoMessage, errorMessage, setErrorMessage, setInfoMessage } =
    useCommboxFormContext();

  return (
    <>
      <StatusBannerAutoHide
        variant="error"
        timeout={BANNER_TIMEOUT}
        show={Boolean(errorMessage || createPostingError)}
        handleOnClose={() => setErrorMessage(null)}
        data-testid="commbox-banner-error"
      >
        <FormattedMessage
          id={getErrorId(createPostingError ? 'GENERIC_ERROR' : errorMessage)}
        />
      </StatusBannerAutoHide>
      <StatusBannerAutoHide
        variant="info"
        timeout={BANNER_TIMEOUT}
        show={Boolean(infoMessage)}
        handleOnClose={() => setInfoMessage(null)}
        data-testid="commbox-banner-success"
      >
        {infoMessage && <FormattedMessage id={infoMessage} />}
      </StatusBannerAutoHide>
    </>
  );
};
