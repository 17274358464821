import { useRef, useLayoutEffect, useState, useCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useResizeObserver = () => {
  const [entry, setEntry] = useState<ResizeObserverEntry>();
  const [node, setNode] = useState<HTMLElement>();
  const observer = useRef<ResizeObserver>();

  const disconnect = useCallback(() => {
    const { current } = observer;
    current && current.disconnect();
  }, []);

  const observe = useCallback(() => {
    observer.current = new ResizeObserver((entries: ResizeObserverEntry[]) =>
      setEntry(entries[0])
    );
    node && observer.current.observe(node);
  }, [node]);

  useLayoutEffect(() => {
    observe();
    return () => disconnect();
  }, [disconnect, observe]);

  return [setNode, entry] as const;
};
