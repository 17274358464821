import type { CommboxErrorMessageType } from '../types';

export const getErrorId = (errorMessage: CommboxErrorMessageType) => {
  if (errorMessage === 'INVALID_FILE_TYPE')
    return 'COMMBOX_IMAGE_TYPE_UPLOAD_ERROR';

  if (errorMessage === 'INVALID_FILE_SIZE')
    return 'COMMBOX_IMAGE_SIZE_UPLOAD_ERROR';

  if (errorMessage === 'INVALID_DATE')
    return 'COMMBOX_SCHEDULING_DATE_FUTURE_ERROR';

  return 'CB_POSTING_ERROR';
};
