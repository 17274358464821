import type { FC, PropsWithChildren } from 'react';
import { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconArrowDown, IconArrowUp } from '@xing-com/icons';

import * as Styled from './read-more.styles';

type ReadMoreProps = {
  maxHeight: number;
  trackingAction?: () => void;
};
export const ReadMore: FC<PropsWithChildren<ReadMoreProps>> = ({
  children,
  maxHeight,
  trackingAction,
}) => {
  const ref = useRef<any>(null);
  const [isCollapsed, setCollapsed] = useState<boolean>(true);
  const [totalHeight, setTotalHeight] = useState<any>();

  const updateHeight = () => {
    setTotalHeight(ref.current.getBoundingClientRect()?.height);
  };

  const updateHeightDebounced = () => setTimeout(() => updateHeight(), 300);

  useEffect(() => {
    if (ref.current.getBoundingClientRect().height !== 0) {
      updateHeight();
    }
  }, [children]);

  useEffect(() => {
    window.addEventListener('resize', updateHeightDebounced);

    return () => {
      window.removeEventListener('resize', updateHeightDebounced);
    };
  }, []);

  return (
    <>
      <Styled.TextArea
        style={{ maxHeight: isCollapsed ? maxHeight : totalHeight }}
      >
        <Styled.TextAreaInner ref={ref}>{children}</Styled.TextAreaInner>
      </Styled.TextArea>
      {totalHeight > maxHeight && (
        <Styled.ReadMoreButton
          variant={'tertiary'}
          size={'small'}
          icon={isCollapsed ? IconArrowDown : IconArrowUp}
          onClick={() => {
            isCollapsed && trackingAction && trackingAction();
            setCollapsed(!isCollapsed);
          }}
        >
          <FormattedMessage
            id={isCollapsed ? 'EP_READ_MORE' : 'EP_READ_LESS'}
            defaultMessage={isCollapsed ? 'EP_READ_MORE' : 'EP_READ_LESS'}
          />
        </Styled.ReadMoreButton>
      )}
    </>
  );
};
