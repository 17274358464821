import * as React from 'react';

import { StatusBanner } from './status-banner';
import type { StatusBannerAutoHideProps } from './status-banner.types';

export const StatusBannerAutoHide = ({
  handleOnClose,
  show,
  timeout,
  ...props
}: StatusBannerAutoHideProps): JSX.Element => {
  const [hideByTimeout, showWithTimeout] = React.useState(show);
  const handleOnCloseForTimeOut = React.useCallback(() => {
    handleOnClose && handleOnClose();
    showWithTimeout(false);
  }, [handleOnClose]);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (timeout && show) {
      showWithTimeout(true);
      timeoutId = setTimeout(() => {
        handleOnCloseForTimeOut();
      }, timeout);
    } else {
      showWithTimeout(show);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [show, timeout, handleOnCloseForTimeOut]);

  return (
    <StatusBanner
      {...props}
      handleOnClose={handleOnClose}
      onExited={handleOnCloseForTimeOut}
      show={hideByTimeout}
    />
  );
};
