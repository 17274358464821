import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { TextButton } from '@xing-com/button';
import type {
  JobItemResult,
  JobSearchQuery,
} from '@xing-com/crate-common-graphql-types';
import {
  LazyLoading,
  LAZY_LOADING_TYPE_BUTTON,
} from '@xing-com/crate-companies-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { BodyCopy, Meta } from '@xing-com/typography';

import { JobsBookmark } from '../components/jobs-bookmark/jobs-bookmark';
import { JobsEmptyState } from '../components/jobs-empty-state/jobs-empty-state';
import { JobsListItem } from '../components/jobs-list-item/jobs-list-item';
import { checkBookmarkState } from '../utils';
import * as Styled from './jobs-detail.styles';

const XING_JOBBOX_URL = 'https://www.xing.com/jobs/jobbox';

type JobHeaderProps = {
  total: number;
  showJobbox: boolean;
};
const JobHeader: FC<JobHeaderProps> = ({ total, showJobbox }) => (
  <Styled.Header>
    <Meta noMargin size={'small'}>
      <FormattedMessage id="EP_JOB_ADS_FOUND" values={{ total }} />
    </Meta>
    {showJobbox && (
      <Styled.JobBoxLink>
        <JobsBookmark initialBookmark fixedValue />
        <TextButton
          href={getTrackingUrl({
            to: XING_JOBBOX_URL,
            trackingKey: 'viewJobOffers',
          })}
          // @ts-expect-error FIXME: SC6
          target="_blank"
        >
          <BodyCopy
            fontWeight="bold"
            lineHeight="dense"
            size={'small'}
            noMargin
          >
            <FormattedMessage id="EP_JOB_JOBBOX" />
          </BodyCopy>
        </TextButton>
      </Styled.JobBoxLink>
    )}
  </Styled.Header>
);

type JobDetailTypeProps = {
  totalJobs?: number;
  searchQuery?: Partial<JobSearchQuery>;
  jobResults?: Partial<JobItemResult>[];
  loading: boolean;
  onLazyLoad: () => void;
  paginationLoading: boolean;
};
export const JobsDetail: FC<JobDetailTypeProps> = ({
  loading,
  totalJobs,
  searchQuery,
  jobResults,
  onLazyLoad,
  paginationLoading,
}) => {
  const { isLoggedIn } = useLoginState();

  const showDescription = !!searchQuery?.body?.keywords;

  const isPaginationEnabled = () => {
    if (loading) return false;
    if (totalJobs === jobResults?.length) return false;
    return true;
  };

  if (
    (!loading && !jobResults) ||
    (!loading && jobResults && jobResults.length === 0)
  ) {
    return (
      <Styled.EmptyStateContainer>
        <JobsEmptyState statusCode={isLoggedIn ? undefined : 404} />
      </Styled.EmptyStateContainer>
    );
  }

  return (
    <Styled.Wrapper>
      {!loading && <JobHeader total={totalJobs || 0} showJobbox={isLoggedIn} />}
      {loading && (
        <>
          <JobsListItem.Skeleton />
          <JobsListItem.Skeleton />
        </>
      )}
      {jobResults
        ?.filter(({ jobDetail }) => !!jobDetail?.id)
        .map(({ jobDetail: jobQuery, descriptionHighlight }) => {
          const jobDetail =
            jobQuery?.__typename === 'VisibleJob' ? jobQuery : null;

          return (
            <JobsListItem
              key={jobDetail?.id}
              // @ts-expect-error TODO: fix this type
              title={jobDetail?.title}
              // @ts-expect-error TODO: fix this type
              companyName={jobDetail?.companyInfo?.company?.companyName}
              // @ts-expect-error TODO: fix this type
              city={jobDetail?.location?.city}
              // @ts-expect-error TODO: fix this type
              activatedAt={new Date(jobDetail?.activatedAt)}
              url={getTrackingUrl({
                to: jobDetail?.url,
                trackingKey: 'viewJobProfile',
              })}
              // @ts-expect-error TODO: fix this type
              id={jobDetail?.id}
              bookmarkState={checkBookmarkState(
                jobDetail?.userInteractions?.bookmark?.state
              )}
              showBookmark={isLoggedIn}
              // @ts-expect-error TODO: fix this type
              descriptionHighlight={descriptionHighlight}
              showDescription={showDescription}
            />
          );
        })}
      <Styled.Pagination>
        <LazyLoading
          type={LAZY_LOADING_TYPE_BUTTON}
          isLoading={paginationLoading}
          enabled={isPaginationEnabled()}
          onLazyLoad={onLazyLoad}
        />
      </Styled.Pagination>
    </Styled.Wrapper>
  );
};

export default JobsDetail;
