import cx from 'classnames';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import * as Styled from './manage-updates.styles';

type ManageUpdatesProps = {
  basePath: string;
  pageSlug: string;
  isArmstrong?: boolean;
};
export const ManageUpdates: FC<ManageUpdatesProps> = ({
  isArmstrong,
  basePath,
  pageSlug,
}) => (
  <Styled.Button
    className={cx({
      isArmstrong,
    })}
    variant={'secondary'}
    size={'small'}
    to={`${basePath}/${pageSlug}/news`}
  >
    <FormattedMessage id="EP_EDITBAR_MANAGE_UPDATES" />
  </Styled.Button>
);
