import styled from 'styled-components';

import { ProfileInfoAdapter as InnerProfileInfo } from '@xing-com/crate-companies-entity-pages-common';
import { FormField } from '@xing-com/text-field';
import { spaceL, xdlColorBackground } from '@xing-com/tokens';

export const NoSuggestionItem = styled.div`
  margin-top: ${spaceL};
  text-align: center;
`;

export const Input = styled(FormField)`
  background-color: ${xdlColorBackground};
`;

export const ProfileInfo = styled(InnerProfileInfo)`
  & h2 {
    margin-bottom: 0;
  }
`;
