/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconLink"
    {...props}
  >
    <path
      fill="currentColor"
      d="m13.326 9.349 1.325 1.326-3.976 3.976-1.326-1.325zm0 5.302 1.325 1.325L12 18.627A4.686 4.686 0 0 1 5.373 12l2.65-2.651 1.326 1.326-2.65 2.65a2.812 2.812 0 1 0 3.976 3.977zm-2.652-5.302L9.35 8.024 12 5.373A4.686 4.686 0 1 1 18.627 12l-2.65 2.651-1.326-1.325 2.65-2.652a2.812 2.812 0 1 0-3.975-3.976z"
    />
  </svg>
);
export default IconLink;
