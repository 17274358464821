import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';

import {
  EntityPageFocusType,
  PostingPublicationState,
  PostingsVisibility,
} from '@xing-com/crate-common-graphql-types';
import type { LAZY_LOADING_TYPE_SCROLL } from '@xing-com/crate-companies-entity-pages-common';
import {
  LAZY_LOADING_TYPE_BUTTON,
  useEditContext,
  ModuleHeader,
  usePageContext,
  useMainPageLoadModule,
} from '@xing-com/crate-companies-entity-pages-common';

import type { EntityPageFeedCollectionFragment } from '../../graphql/fragments/entity-page-feed-collection-fragment.gql-types';
import { usePageSize } from '../../hooks/use-page-size/use-page-size';
import { getSubPageKey, newsPath } from '../../utils';
import { NewsCommbox } from '../news-commbox/news-commbox';
import { News } from '../news/news';

type NewsContainerProps = {
  moduleProperties?: any;
  isDetailPage?: boolean;
  lazyLoadingType?:
    | typeof LAZY_LOADING_TYPE_BUTTON
    | typeof LAZY_LOADING_TYPE_SCROLL
    | null;
  loadingSkeletonCount?: number;
  showHeadline?: boolean;
  showSubpageLinks?: boolean;
};
export const NewsContainer: FC<NewsContainerProps> = ({
  moduleProperties: { hasSubpage = true } = {},
  isDetailPage = false,
  lazyLoadingType = !hasSubpage ? LAZY_LOADING_TYPE_BUTTON : null,
  loadingSkeletonCount = 3,
  showHeadline = hasSubpage,
  showSubpageLinks = hasSubpage,
  ...props
}) => {
  useMainPageLoadModule();
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};

  const activeNewsCommbox = useRef<() => void>(() => undefined);
  const [lazyLoadingIsAllowed, setLazyLoadingIsAllowed] = useState(true);
  const [selectedPosting, setSelectedPosting] = useState<
    EntityPageFeedCollectionFragment['object'] | null
  >();
  const [currentPublicationState, setCurrentPublicationState] =
    useState<PostingPublicationState>(PostingPublicationState.Published);
  const [hasErrorOnSubpageLabel, setHasErrorOnSubpageLabel] = useState(false);
  const { focusType, globalId, pageId, pageSlug, pageTitle, logo } =
    pageContext ?? {};

  useEffect(() => {
    setLazyLoadingIsAllowed(true);
  }, [currentPublicationState]);

  const clearPosting = () => {
    if (selectedPosting !== null || selectedPosting !== undefined) {
      setSelectedPosting(null);
    }
  };

  useEffect(() => {
    if (!isEditing) setSelectedPosting(null);
  }, [isEditing]);

  const actor = {
    logo,
    title: pageTitle ?? '',
    slug: pageSlug as string, // TODO: fix pageContext
    isInsider: false,
  };

  showHeadline = !isDetailPage && hasSubpage;
  showSubpageLinks = !isDetailPage && hasSubpage;

  const limit = usePageSize(hasSubpage);
  const canEditLinkPreview =
    focusType === EntityPageFocusType.Publisher && isEditing;
  const defaultPostVisibility = PostingsVisibility.Public;
  const feedQueryVars = {
    id: pageId as string, // TODO: fix pageContext
    limit,
    filter: currentPublicationState,
  };

  const subPageKey = getSubPageKey(!!isEditing);

  return (
    <>
      {showHeadline && (
        <ModuleHeader
          headlineCopyKey="EP_NEWS_HEADLINE"
          showMore={
            showSubpageLinks && !hasErrorOnSubpageLabel
              ? {
                  to: newsPath(pageContext?.basePath ?? '', actor.slug ?? ''),
                  copyKey: `${subPageKey}`,
                }
              : null
          }
        />
      )}
      <News
        actor={actor}
        feedQueryVars={feedQueryVars}
        focusType={focusType ?? ''}
        globalId={globalId ?? ''}
        isDetailPage={isDetailPage}
        lazyLoadingIsAllowed={lazyLoadingIsAllowed}
        lazyLoadingType={lazyLoadingType}
        loadingSkeletonCount={loadingSkeletonCount}
        setCurrentPublicationState={setCurrentPublicationState}
        setLazyLoadingIsAllowed={setLazyLoadingIsAllowed}
        showActors={hasSubpage}
        showSubpageLinks={showSubpageLinks}
        setHasErrorOnSubpageLabel={setHasErrorOnSubpageLabel}
        onCommboxTrigger={() => {
          activeNewsCommbox.current();
        }}
        onEditButtonClick={(posting) => {
          activeNewsCommbox.current();
          // @ts-expect-error TODO: fix this type
          setSelectedPosting(posting);
        }}
        {...props}
      />
      {isEditing && (
        <NewsCommbox
          canEditLinkPreview={canEditLinkPreview}
          clearPosting={clearPosting}
          defaultPostVisibility={defaultPostVisibility}
          feedQueryVars={feedQueryVars}
          selectedPosting={selectedPosting}
          setLazyLoadingIsAllowed={setLazyLoadingIsAllowed}
          setSelectedPosting={setSelectedPosting}
        >
          {(activateOnView: () => void) => {
            activeNewsCommbox.current = activateOnView;
            return <></>;
          }}
        </NewsCommbox>
      )}
    </>
  );
};
