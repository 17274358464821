import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { redirectToLogin } from '../../utils/utils';
import { useLoggedoutAction } from '../useLoggedoutAction/useLoggedoutAction';

export const useLoggedinAction = (
  toggle: () => void,
  action: string,
  currentValue = false,
  skip?: boolean
) => {
  const { isLoggedOut } = useLoginState();

  const loggedinToggle = () => {
    if (isLoggedOut) {
      redirectToLogin(action);
    } else {
      toggle();
    }
  };

  return useLoggedoutAction(loggedinToggle, action, currentValue, skip);
};
