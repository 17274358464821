import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Headline } from '@xing-com/typography';
// eslint-disable-next-line monorepo/forbidden-imports
import Video from '@xing-com/video';

import VideoEncodingIcon from './assets/upload-success.svg';
import * as Styled from './upload.styles';

const Encoding: FC = () => {
  return (
    <div className={'styles.uploadContainer'}>
      <Styled.UploadArea>
        <Styled.Illustration src={VideoEncodingIcon} />
        <Styled.EncodingTitle>
          <Headline size="large" noMargin>
            <FormattedMessage id={'VIDEO_UPLOAD_ENCODING_TITLE'} />
          </Headline>
        </Styled.EncodingTitle>
      </Styled.UploadArea>
    </div>
  );
};

type UploadFinishedProps = {
  videoId?: string;
  previewImage?: File;
  onEncodingComplete?: () => void;
  onError?: (error: any) => void;
};
export const UploadFinished: FC<UploadFinishedProps> = ({
  videoId,
  previewImage,
  onEncodingComplete,
  onError,
}) => {
  const [poster, setPoster] = useState<any | null>();

  useEffect(() => {
    setPoster(previewImage ? URL.createObjectURL(previewImage) : null);
  }, [previewImage]);

  return (
    <Styled.VideoContainer>
      <Video
        id={videoId}
        section="videoupload"
        encodingFallback={Encoding}
        previewModeAction={(disablePreviewMode: any) => disablePreviewMode()}
        posterImage={poster}
        onError={onError}
        onEncodingComplete={onEncodingComplete}
      />
    </Styled.VideoContainer>
  );
};
