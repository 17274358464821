import styled from 'styled-components';

import { scale040 } from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

// *** Components ***
export const Rating = styled.div`
  align-items: center;
  display: flex;
`;

export const Text = styled(Headline)`
  margin-right: ${scale040};
`;
