import styled, { css } from 'styled-components';

import type { ListVariant } from './';

type ListTransientProps = {
  $variant?: ListVariant;
};

// *** Variants ***
export const variants = {
  row: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
};

// *** Components ***
export const List = styled.ul<ListTransientProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;

  ${({ $variant }) => $variant && variants[$variant]}
`;
