import { useQuery } from '@apollo/client';
import { Redirect, useLocation } from '@reach/router';
import cx from 'classnames';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import * as Types from '@xing-com/crate-common-graphql-types';
import type { MetaDataType } from '@xing-com/crate-companies-entity-pages-common';
import {
  PageContext,
  EntitySubpageDocument,
  MetaData,
  ModuleContextProvider,
  usePageContext,
  PageError,
} from '@xing-com/crate-companies-entity-pages-common';
import { trackPageDetail } from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import { Header } from '@xing-com/crate-companies-entity-pages-header';
import { useFeatureSwitch } from '@xing-com/hub';
import { GridContainer } from '@xing-com/xing-grid';

import { CompanyBannersContainer } from '../../components/banners/company-banners-container/company-banners-container';
import { CustomerFeedBackButton } from '../../components/customer-feedback-button/customer-feedback-button';
import { EditBar } from '../../components/edit-bar/edit-bar';
import { modulesMap } from '../../config/modules_map';
import { useAppcues } from '../../hooks/useAppcues/useAppcues';
import { useUpdateEditContext } from '../../hooks/useUpdateEditContext/useUpdateEditContext';
import { useUpdatePageContext } from '../../hooks/useUpdatePageContext/useUpdatePageContext';
import { getPathByFocusType } from '../../utils/getPathByFocusType';
import { SubpageBottomBack, SubpageTopBack } from './back-to-overview';
import * as Styled from './subpage.styles';

interface SubpageContainerProps {
  pageSlug: string;
  pageType: string;
  param?: string;
  isWebview?: boolean;
}
export const SubpageContainer: FC<SubpageContainerProps> = ({
  pageSlug,
  pageType,
  param,
  isWebview,
}: SubpageContainerProps) => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const canEditMobile = useFeatureSwitch('ep_mobileEdit', false);
  const trackSentRef = useRef(false);

  const modulesMapItem = modulesMap[pageType];

  const Module =
    (param ? modulesMapItem?.tabs?.[param] : modulesMapItem?.subpageModule) ??
    null;

  const seoIndexSubpage = modulesMap[pageType]?.seoIndexSubpage ?? true;

  const path = location.pathname?.split('/')[1];

  const { data, loading } = useQuery(EntitySubpageDocument, {
    variables: {
      id: pageSlug,
      socialProofClickReasonsKey:
        Types.ClickReasonsEnum.CrWebPublisherSocialProofHeader,
      moduleType: pageType,
    },
    errorPolicy: 'all',
  });

  const { setPageContext } = usePageContext() ?? {};

  let metaData: MetaDataType = {
    title: 'XING',
  };
  let isEditor = false;
  let isCompany = false;
  let isCompanyDraft = false;

  const entityPage =
    data?.entityPageEX?.__typename === 'EntityPage'
      ? (data.entityPageEX ?? undefined)
      : undefined;

  const pageContext = useUpdatePageContext(entityPage, {
    moduleTitle: formatMessage({
      id: `EP_${pageType.toUpperCase()}_HEADLINE`,
      defaultMessage: `EP_${pageType.toUpperCase()}_HEADLINE`,
    }),
    isWebview,
  });
  const isPageContextSet = !!pageContext;

  const isEditContextSet = useUpdateEditContext(entityPage);

  useEffect(() => {
    if (
      data?.entityPageEX?.__typename === 'EntityPage' &&
      data.entityPageEX.publicationStatus === 'CREATED'
    ) {
      window.location.assign(`/pages/new/company`);
    }
  }, [data]);

  useEffect(() => {
    if (
      !trackSentRef.current &&
      pageContext?.pageId &&
      data?.entityPageEX?.__typename === 'EntityPage'
    ) {
      const trackIsFollower: boolean =
        data.entityPageEX.userPageContext?.userInteractions?.__typename ===
        'EntityPageUserInteractionFollow'
          ? Boolean(
              data.entityPageEX.userPageContext?.userInteractions.followState
                ?.isFollowing
            )
          : false;

      if (pageContext.contractType)
        trackPageDetail({
          pageId: pageContext.pageId.split('.')[0],
          subpage: pageType,
          isEditor,
          focusType: pageContext.focusType,
          contractType: pageContext.contractType,
          isFollowing: trackIsFollower,
        });

      trackSentRef.current = true;
    }
  }, [pageContext]);

  useAppcues();

  const moduleContext = {
    moduleType: pageType,
    moduleGlobalId:
      data?.moduleProperties?.__typename === 'EntityPageGenericModule' &&
      data?.moduleProperties?.globalId,
    isWebview,
  };

  if (!Module) {
    return (
      <div>
        <PageError type={404} />
      </div>
    );
  }

  if (!loading && !data?.entityPageEX) {
    return (
      <div>
        <PageError />
      </div>
    );
  }

  if (data?.entityPageEX?.__typename === 'EntityPageMoved') {
    return <Redirect to={`/${path}/${data.entityPageEX.slug}`} />;
  }

  if (data?.entityPageEX?.__typename === 'EntityPageError') {
    const type = data.entityPageEX.errorCode === 10404 ? 404 : 503;

    return (
      <div>
        <PageError type={type} />
      </div>
    );
  }

  if (
    loading ||
    !isPageContextSet ||
    !isEditContextSet ||
    (data?.entityPageEX?.__typename === 'EntityPage' &&
      data?.entityPageEX?.publicationStatus === 'CREATED')
  ) {
    return (
      <Styled.PageWrapper>
        <MetaData setDefault={true} title={'XING'} index={seoIndexSubpage} />
        <Header.Skeleton />
      </Styled.PageWrapper>
    );
  }

  if (!data || data.entityPageEX?.__typename !== 'EntityPage') {
    return null;
  }
  isEditor = data.entityPageEX.userPageContext?.permissions.canEdit ?? false;

  isCompany = data.entityPageEX.focusType === Types.EntityPageFocusType.Company;
  isCompanyDraft = isCompany && data.entityPageEX.publicationStatus === 'DRAFT';

  metaData = {
    title: `${formatMessage(
      {
        id: `EP_META_${pageType.toUpperCase()}_TITLE`,
      },
      { pageTitle: data.entityPageEX.title }
    )}`,
    url: `${data.entityPageEX.links.self}/${pageType}`,
    image: data?.entityPageEX.logoImage?.[0].url ?? undefined,
    description:
      pageType !== 'about_us'
        ? formatMessage(
            {
              id: `EP_META_${pageType.toUpperCase()}_DESCRIPTION`,
            },
            { pageTitle: data.entityPageEX.title }
          )
        : null,
  };

  const canEditOnMobile = isEditor && pageType === 'news';

  if (getPathByFocusType() !== `/${path}`) {
    return (
      <div>
        <PageError type={404} />
      </div>
    );
  }

  return (
    // PageContext.Provider is important here for SSR. It avoids to wait for the useEffect that executes on client side only
    <PageContext.Provider
      value={{
        pageContext: pageContext,
        // @ts-expect-error TODO: fix this type, I don't even know how this app even works
        setPageContext: setPageContext,
      }}
    >
      <Styled.PageWrapper>
        <MetaData setDefault={true} index={seoIndexSubpage} {...metaData} />
        {isEditor &&
          data.entityPageEX.focusType === Types.EntityPageFocusType.Company && (
            <CompanyBannersContainer
              companyId={pageContext?.companyId}
              isDraft={isCompanyDraft}
            />
          )}
        {isEditor && <CustomerFeedBackButton module={pageType} />}
        <div className={cx({ isWebview })}>
          {!isWebview && isEditor && (
            <Styled.EditBarSubpage>
              <EditBar
                goBack={pageContext?.goBackUrl}
                pageSlug={pageSlug}
                globalId={data!.entityPageEX.globalId}
                displayNewsButtons={false}
                canEditOnMobile={canEditOnMobile || canEditMobile}
                displayBackButton={true}
                contractType={data!.entityPageEX.contract?.type}
                logo={metaData.image}
                pageTitle={metaData.title}
                pageId={metaData.pageId}
              />
            </Styled.EditBarSubpage>
          )}
          <Styled.GridWrapper>
            {!isEditor && !isWebview && (
              <GridContainer>
                <Styled.Row>
                  <Styled.Column size={12}>
                    <SubpageTopBack />{' '}
                  </Styled.Column>
                </Styled.Row>
              </GridContainer>
            )}
          </Styled.GridWrapper>
          <Styled.HeaderWrapper>
            <Header
              pageType={pageType}
              moduleType={pageType}
              isSubpage={true}
            />
          </Styled.HeaderWrapper>
          <GridContainer>
            <Styled.Row>
              <Styled.Column isModuleContainer size={12}>
                <ModuleContextProvider value={moduleContext}>
                  <Module
                    isEditor={isEditor}
                    {...pageContext}
                    {...moduleContext}
                  />
                </ModuleContextProvider>
              </Styled.Column>
            </Styled.Row>
          </GridContainer>
          {!isWebview && <SubpageBottomBack />}
        </div>
      </Styled.PageWrapper>
    </PageContext.Provider>
  );
};
