import * as React from 'react';

import * as Styled from './list-item.styles';
import type { ListItemProps } from './list-item.types';

export const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  ({ children, className, variant, ...props }, forwardedRef): JSX.Element => (
    <Styled.ListItem
      $variant={variant}
      className={className}
      data-xds="ListItem"
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Styled.ListItem>
  )
);

ListItem.displayName = 'ListItem';
