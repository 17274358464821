import type { FC } from 'react';

import { SkeletonProfileImage } from '@xing-com/skeleton';
import { GridContainer, Row } from '@xing-com/xing-grid';

import * as Styled from './header-skeleton.styles';

export const HeaderSkeleton: FC = () => (
  <Styled.HeaderSkeletonWrapper>
    <Styled.Cover>
      <Styled.CoverSkeleton />
    </Styled.Cover>
    <GridContainer>
      <Row>
        <Styled.MainArea size={12}>
          <Styled.LogoArea>
            <SkeletonProfileImage size="xxlarge" />
          </Styled.LogoArea>
          <Styled.SideArea>
            <Styled.PrimaryArea>
              <Styled.HeadlinePrimary size="medium" amountRows={1} />
            </Styled.PrimaryArea>
            <Styled.SecondaryArea>
              <Styled.HeadlineSecondary size="small" amountRows={1} />
              <Styled.Actions>
                <Styled.FollowButton size="medium" />
                <Styled.MoreButton size="medium" />
              </Styled.Actions>
            </Styled.SecondaryArea>
          </Styled.SideArea>
        </Styled.MainArea>
      </Row>
    </GridContainer>
  </Styled.HeaderSkeletonWrapper>
);
