import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { PopOver as DefaultPopOver } from '@xing-com/pop-over';
import {
  cornerRadiusL,
  scale100,
  spaceM,
  spaceS,
  spaceXL,
  spaceXXL,
  xdlColorProBusiness,
  xdlColorProBusinessTertiary,
  xdlColorTextInvert,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const HeaderText = styled.div`
  margin: ${spaceM} 0 ${spaceM} 0;
`;

export const TextWrapper = styled.div`
  max-width: 600px;
`;

export const PremiumButtonMargin = styled.div`
  margin-bottom: ${spaceXL};
`;

export const PremiumButton = styled(Button)`
  width: fit-content;
`;

export const Text = styled(BodyCopy)`
  text-align: justify;
`;

export const Icon = styled.img`
  width: 160px;
  height: 190px;

  @media ${mediaSmallOrTiny} {
    display: none;
  }
`;

export const PremiumWrapper = styled.div<{ $column?: boolean }>`
  background-color: ${xdlColorProBusinessTertiary};
  border-radius: ${cornerRadiusL};
  padding-top: ${spaceXL};
  padding-left: ${spaceXL};
  padding-right: ${spaceXL};
  display: flex;
  justify-content: space-between;

  @media ${mediaSmallOrTiny} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${({ $column }) =>
    $column
      ? css`
          align-items: flex-start;
          flex-direction: column;
        `
      : css`
          align-items: center;
        `}
`;
export const PremiumWrapperVertical = styled.div`
  background-color: ${xdlColorProBusinessTertiary};
  border-radius: ${cornerRadiusL};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 380px;
  margin-right: ${spaceXL};

  @media ${mediaSmallOrTiny} {
    margin-right: 0;
  }
`;

export const IconVertical = styled.img`
  width: 160px;
  height: 190px;

  @media ${mediaSmallOrTiny} {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${spaceXL} ${spaceXL} 0 ${spaceXL};
`;

export const PremiumWrapperWithIcon = styled.div`
  background-color: ${xdlColorProBusinessTertiary};
  border-radius: ${cornerRadiusL};
  padding-top: ${spaceXL};
  padding-left: ${spaceXL};
  display: flex;
  justify-content: space-between;

  @media ${mediaSmallOrTiny} {
    padding-right: ${spaceXL};
  }
`;

export const PopOver = styled(DefaultPopOver)`
  background-color: ${xdlColorProBusinessTertiary};
  padding-left: ${spaceXL};
  padding-right: ${spaceXL};
  padding-bottom: ${spaceXL};
  top: calc(${spaceXXL} + ${spaceS});
`;

export const Flag = styled.span`
  align-items: center;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  border: 2px solid transparent;
  border-radius: 2px;
  height: ${scale100};
  padding: ${spaceS};
  background-color: ${xdlColorProBusiness};
  color: ${xdlColorTextInvert};
  font-weight: bold;
  font-size: 12px;
`;
