import type { ChangeEvent, FC } from 'react';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { TextButton } from '@xing-com/button';
import { IconFileImage } from '@xing-com/icons';

import { isImageUploadDisabled } from '../../helpers/image-upload';
import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxFormContext } from '../../hooks/use-commbox-form-context';
import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';
import { useOnInputFiles } from '../../hooks/use-on-input-files';
import { trackAction } from '../../tracking';
import * as Styled from './image-upload-button.styles';

export type ImageUploadButtonProps = {
  multiple?: boolean;
};
export const ImageUploadButton: FC<ImageUploadButtonProps> = () => {
  const { attachmentType } = useCommboxContext();
  const { images } = useCommboxFormContext();
  const { onInputFiles } = useOnInputFiles();

  const { formatMessage } = useIntl();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isEditingPosting, enableMultiImage } = useCommboxPermissions();

  const imageUploadDisabled = isImageUploadDisabled({
    attachmentType,
    enableMultiImage,
    images,
    isEditingPosting,
  });

  const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const files = ev.target.files;

    if (files) {
      trackAction({
        PropActionOrigin: 'social_share_commbox_image_source_library',
        PropTrackAction: 'social_share_commbox_image_source_select',
      });

      onInputFiles(Array.from(files));
    }

    ev.target.value = '';
  };

  const handleClickButton = () => {
    trackAction({
      PropTrackAction: 'social_share_commbox_image_source_open',
    });
    fileInputRef.current?.click();
  };

  return (
    <>
      <Styled.HiddenInput
        multiple={enableMultiImage}
        accept="image/jpeg,image/png,image/gif"
        onChange={handleOnChange}
        ref={fileInputRef}
        type="file"
      />

      <TextButton
        disabled={imageUploadDisabled}
        onClick={handleClickButton}
        aria-label={formatMessage({ id: 'COMMBOX_BUTTON_OPEN_FILES_LABEL' })}
        data-testid="uploadImageBtn"
        size="medium"
        icon={IconFileImage}
      />
    </>
  );
};
