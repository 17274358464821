import styled from 'styled-components';

import { spaceL, spaceXXL, xdlColorBorderSoft } from '@xing-com/tokens';

export const Wrapper = styled.div`
  min-height: var(--space24);
  padding: ${spaceL} 0 ${spaceXXL} 0;

  @media (--mediaSOnly) {
    min-height: 0;
  }
`;
export const Pagination = styled.div`
  margin-top: ${spaceXXL};
`;

// .meta {
//   color: var(--colorCopyWeak);
//   line-height: var(--lineHeight200);
// }

export const JobBoxLink = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${xdlColorBorderSoft};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const EmptyStateContainer = styled.div`
  margin-top: ${spaceXXL};
`;
