import styled from 'styled-components';

import { ProfileInfoAdapter } from '@xing-com/crate-companies-entity-pages-common';
import { scale110, scale130 } from '@xing-com/tokens';
import { Headline as InternalHeadline } from '@xing-com/typography';
import { Column as InternalColumn } from '@xing-com/xing-grid';

export const Column = styled(InternalColumn)`
  margin: 0 auto;
`;
export const Headline = styled(InternalHeadline)`
  margin-bottom: ${scale130};
`;

export const List = styled.ul`
  margin: ${scale110} 0;
  padding-left: 0;
`;

export const ListItem = styled.li`
  display: block;
  width: 100%;
  margin-bottom: ${scale130};
`;

export const ProfileInfo = styled(ProfileInfoAdapter)`
  padding: 0;

  &:hover {
    background-color: transparent;
  }
`;
