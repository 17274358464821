import styled from 'styled-components';

import { SkeletonImage } from '@xing-com/skeleton';
import { spaceXL, spaceXXL } from '@xing-com/tokens';

export const Container = styled.div`
  margin-top: ${spaceXL};
`;

export const SkeletonIntroTitle = styled(SkeletonImage)`
  margin: ${spaceXXL} 0;
`;
