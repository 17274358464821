import type {
  FilterStateType,
  ActionType,
  FilterStateSelectedItemsType,
  FiltersItemsTypeKey,
  FiltersItemsType,
  FilterNameType,
  Aggregation,
} from '../../types';
import { JobsReducerActionKind } from '../../types';

const checkboxFilterReducer = (
  state: FilterStateType,
  action: ActionType
): FilterStateType => {
  switch (action.type) {
    case JobsReducerActionKind.TOGGLE_CHECKBOX_ACTION: {
      const selectedItems = state.selectedItems[action.payload.filterName];
      const idx = selectedItems?.indexOf(action.payload.id);

      if (idx === undefined || idx === -1) {
        selectedItems?.push(action.payload.id);
      } else {
        selectedItems?.splice(idx, 1);
      }

      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          [action.payload.filterName]: selectedItems,
        },
      };
    }

    case JobsReducerActionKind.CLEAR_ACTION: {
      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          [action.payload.filterName]: [],
        },
      };
    }

    case JobsReducerActionKind.SET_INITIAL_OPTIONS_ACTION: {
      const { items: oldItems, waitingForSetup, ...rest } = state;
      const items: FiltersItemsType = {};
      Object.keys(oldItems).forEach((key) => {
        const parsedKey = key as FiltersItemsTypeKey;
        if (action.payload.initialOptions[parsedKey]) {
          items[parsedKey] = action.payload.initialOptions[parsedKey];
        }
      });

      return {
        items,
        waitingForSetup: false,
        ...rest,
      };
    }

    case JobsReducerActionKind.CLEAR_ALL_ACTION: {
      const { selectedItems, ...rest } = state;
      const newSelectedItems: FilterStateSelectedItemsType = {};
      Object.keys(selectedItems).forEach((key) => {
        newSelectedItems[key as FilterNameType] = [];
      });

      return {
        ...rest,
        selectedItems: newSelectedItems,
      };
    }
    case JobsReducerActionKind.UPDATE_ITEM_COUNTS_ACTION: {
      const { items: oldItems, ...rest } = state;
      const items: FiltersItemsType = {};
      Object.keys(oldItems).forEach((key) => {
        const parsedKey = key as FiltersItemsTypeKey;
        if (action.payload.aggregations[parsedKey]) {
          items[parsedKey] = oldItems[parsedKey]?.map(
            ({ id, name, trackingKey }) => {
              const item = action.payload.aggregations[parsedKey]?.find(
                (item) => item.id === id
              );
              return {
                id,
                name,
                count: item ? item.count : 0,
                trackingKey,
              } as Aggregation;
            }
          );
        }
      });
      return {
        ...rest,
        items,
      };
    }
    case JobsReducerActionKind.UPDATE_SEARCH_ACTION: {
      return {
        ...state,
        keywords: action.payload.keywords,
      };
    }
    case JobsReducerActionKind.SET_OFFSET_ACTION: {
      return {
        ...state,
        offset: action.payload.offset,
      };
    }
    default:
      return state;
  }
};

export { checkboxFilterReducer };
