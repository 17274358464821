import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import {
  zIndexElevatedLayer4,
  mediaTopBarHidden,
  mediaTopBarVisible,
} from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import {
  scale040,
  scale180,
  scale190,
  spaceXXL,
  xdlColorBorderSoft,
  xdlColorXING,
  xdlColorButtonStrong,
  xdlColorButtonStrongHover,
  xdlColorTextInvert,
  xdlColorTextOnDark,
  xdlPaletteGrayPlain11,
  xdlColorText,
  scale170,
} from '@xing-com/tokens';
import { Headline, Meta } from '@xing-com/typography';
import { XingLogo, Butterfly } from '@xing-com/xing-logo';

import type { TopBarType } from './top-bar-types';

export const StyledLogo = styled(XingLogo)`
  line-height: 0;

  @media (max-width: 1025px) {
    display: none;
  }
`;

export const StyledButterfly = styled(Butterfly)`
  line-height: 0;
  margin-left: ${scale040};

  @media ${mediaTopBarHidden} {
    display: none;
  }
`;

export const NavItemContainer = styled.div<{ isLoggedOut?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isLoggedOut }) => {
    return (
      isLoggedOut &&
      css`
        padding-right: ${spaceXXL};
      `
    );
  }}
`;

export const SignInButton = styled(Button)`
  grid-area: but;
  color: ${xdlColorTextInvert};
  flex-shrink: 0;
  height: ${scale180};

  &:after {
    background-color: ${xdlColorButtonStrong};
  }

  &:hover:after {
    background-color: ${xdlColorButtonStrongHover};
  }

  &:active {
    color: ${xdlColorTextOnDark};

    &:after {
      background-color: ${xdlPaletteGrayPlain11};
    }
  }
`;

export const TopBarContainer = styled.div<{
  $showOnLargerScreens?: boolean;
  $type?: TopBarType;
  $isScrolling?: boolean;
  $isSticky?: boolean;
}>`
  align-items: center;
  background-color: ${({ $isScrolling, $isSticky }) =>
    $isScrolling && !$isSticky ? 'transparent' : xdlColorXING};
  border-bottom: 1px solid
    ${({ $isScrolling }) => ($isScrolling ? 'transparent' : xdlColorBorderSoft)};
  display: flex;
  height: ${scale170};
  justify-content: space-between;
  left: 0;
  position: ${({ $isSticky }) => ($isSticky ? 'fixed' : 'absolute')};
  top: 0;
  width: 100%;
  z-index: ${zIndexElevatedLayer4 - 50};

  @media ${mediaTopBarHidden} {
    height: ${scale190};
  }
`;

export const StyledPrimaryActions = styled.div<{
  $type?: TopBarType;
}>`
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: right;
  min-height: ${scale170};
  margin-right: ${scale040};

  @media ${mediaTopBarHidden} {
    min-height: ${scale190};
    margin-right: ${spaceXXL};
  }
`;

export const StyledSecondaryActions = styled.div<{ $type?: TopBarType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${scale170};
  margin-left: ${scale040};

  @media ${mediaTopBarHidden} {
    min-height: ${scale190};
    margin-left: ${spaceXXL};
  }
`;

export const H2 = styled(Headline)`
  margin: 0;
  overflow-x: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${xdlColorText};
`;

export const H3 = styled(Meta)`
  margin: 0;
  overflow-x: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SubHeaderContainer = styled.div`
  display: grid;
`;

export const SubHeaderButton = styled.button`
  background-color: transparent;
  border: 0 none;
  color: inherit;
  cursor: pointer;
  display: grid;
  padding: 0;
  text-decoration: none;
`;

export const SubHeaderLink = styled(Link)<{
  to: string;
}>`
  color: inherit;
  display: grid;

  &:hover {
    text-decoration: none;
  }
`;

export const SubHeaderContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

export const Placebo = styled.div<{
  $showOnLargerScreens?: boolean;
}>`
  height: ${scale170};

  @media ${mediaTopBarHidden} {
    height: ${scale190};
  }

  ${({ $showOnLargerScreens }) =>
    $showOnLargerScreens
      ? css``
      : css`
          @media ${mediaTopBarHidden} {
            display: none;
          }
        `};
`;
