import styled from 'styled-components';

import { spaceL, scale030, spaceS, spaceM, spaceXS } from '@xing-com/tokens';

const colorCopy = 'rgb(51, 51, 51)';
export const Wrapper = styled.section`
  margin-top: ${spaceL};

  .social-bar-animation-enter-done {
    height: auto !important;
  }
`;

export const Actions = styled.div`
  color: ${colorCopy};
  display: inline-flex;
  min-width: 0;
  align-items: center;
  padding: ${scale030} ${spaceS} 0;
  &:hover {
    text-decoration: none;
  }
  & a {
    color: ${colorCopy};
    display: inline-flex;
    min-width: 0;
    align-items: center;
    &:hover {
      text-decoration: none;
    }
  }
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    margin-left: auto;
    padding-right: 0;
  }
  @media (max-width: 375px) {
    padding: ${spaceM} ${spaceXS} 0;
  }
`;
