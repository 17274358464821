import type { FC } from 'react';

import {
  EditContextProvider,
  PageContextProvider,
  ErrorContextProvider,
} from '@xing-com/crate-companies-entity-pages-common';

import { ScrollToTop } from './components/scroll-to-top/scroll-to-top';
import { Router } from './router/router';

export const App: FC = () => (
  <ErrorContextProvider>
    <PageContextProvider>
      <EditContextProvider>
        <ScrollToTop />
        <Router />
      </EditContextProvider>
    </PageContextProvider>
  </ErrorContextProvider>
);
