import styled from 'styled-components';

import { Link as OriginalLink } from '@xing-com/link';
import { ProfileInfo as InnerProfileInfo } from '@xing-com/profile-info';
import { spaceS } from '@xing-com/tokens';

export const Link = styled(OriginalLink)`
  color: inherit;
  display: block;
  overflow: hidden;
  text-decoration: none;
  & > * {
    margin-bottom: 0;
  }
  &:hover {
    text-decoration: none;
  }
`;

export const ProfileInfo = styled(InnerProfileInfo)`
  & > div:nth-child(2) {
    overflow: hidden;
  }
`;

export const Head = styled.div`
  display: flex;
  gap: ${spaceS};
  align-items: center;
`;
