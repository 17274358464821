import type { IntlShape } from 'react-intl';

import type * as Types from '@xing-com/crate-common-graphql-types';

import getTrackingUrl from '../tracking/get-tracking-url';

type ParamsType = { [key: string]: string };

export const getLoginUrl = ({
  location,
  action,
  params,
}: {
  location: string;
  action?: string;
  params?: ParamsType;
}) => {
  const urlParams = params
    ? Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('%26')
    : '';

  const actionUrl = action
    ? `%3Faction=${action}${urlParams ? `%26${urlParams}` : ''}`
    : '';

  if (location.includes('action=' + action)) {
    return `/login/go?dest_url=${encodeURI(location)}`;
  }

  return `/login/go?dest_url=${encodeURI(location)}${actionUrl}`;
};

export const redirectToLogin = (
  action?: string,
  params?: ParamsType,
  trackingKey?: string
) => {
  const addTracking = (url: string) =>
    trackingKey
      ? getTrackingUrl({
          to: url,
          trackingKey,
        })
      : url;

  const loginUrl = addTracking(
    getLoginUrl({
      action,
      params,
      location: window.location.href,
    })
  );

  window.location.assign(loginUrl);
};

export const redirectToRegister = (trackingKey?: string) => {
  const addTracking = (url: string) =>
    trackingKey
      ? getTrackingUrl({
          to: url,
          trackingKey,
        })
      : url;
  const registerUrl = addTracking('/start/signup?registration=1');
  window.location.assign(registerUrl);
};

export const linkRegEx =
  /(https?:\/\/[\w][-äÄÉéöÖüÜß\w+&@#\/%?=~|!:,.;]*[-\w*+&@#\/%=~|]|www[-\w+&@#\/%?=~|!:,.;]*[-\w+&@#\/%=~|])/gm; // eslint-disable-line

export const getImageGender = (gender?: Types.Gender | null) => {
  switch (gender) {
    case 'FEMALE':
      return 'female-user';
    case 'MALE':
      return 'male-user';
    default:
      return 'neutral-user';
  }
};

export const formatEmployeesNumber = (
  employeesNumber: { min: number; max?: number | null },
  intl: IntlShape
): string | undefined =>
  employeesNumber?.min < 501
    ? employeesNumber.max
      ? `${intl.formatNumber(employeesNumber.min)}-${intl.formatNumber(
          employeesNumber?.max
        )}`
      : undefined
    : `>${intl.formatNumber(Math.round(employeesNumber.min / 10) * 10)}`;

export const formatAmount = ({
  value,
  intl,
}: {
  value: number;
  intl: IntlShape;
}) => {
  if (value < 1000000) {
    return intl.formatNumber(value);
  }

  const SUFFIX = {
    de: ' Mio.',
    en: 'M',
  };
  const suffix = SUFFIX[intl.locale.toLocaleLowerCase() === 'de' ? 'de' : 'en'];

  // transform in decimal (e.g. 1840000 -> 1.84), formats and adds suffix
  return `${intl.formatNumber(Math.round(value / 10000) / 100)}${suffix}`;
};

export const capitalizeString = (text?: string) =>
  text && text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const getActivityId = (activityGlobalId: string) =>
  activityGlobalId.split(':').pop()?.split('.')[0];
