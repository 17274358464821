import type { FC, PropsWithChildren } from 'react';
import { useRef, useState, useEffect } from 'react';

import type { ButtonProps } from '@xing-com/button';
import { Button } from '@xing-com/button';

import * as Styled from './file-upload.styles';

type FileUploadProps = ButtonProps & {
  errorHandler?: () => void;
  accept?: string;
  onChange: (file: File) => void;
  maxSize?: number;
  onError: (error: any) => void;
};
export const FileUpload: FC<PropsWithChildren<FileUploadProps>> = ({
  children,
  accept,
  onChange,
  maxSize = 0,
  onError,
  ...rest
}) => {
  const [file, setFile] = useState<File>();
  const inputEl = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    if (inputEl?.current) inputEl?.current?.click();
  };

  useEffect(() => {
    if (!file) return;

    if (file?.size > maxSize) {
      return onError('Max size');
    }
    onChange(file);
  }, [file]);

  return (
    <>
      <Button {...rest} onClick={handleClick}>
        {children}
      </Button>
      <Styled.FileUploadInput
        type="file"
        style={{ display: 'none' }}
        ref={inputEl}
        accept={accept}
        onChange={(e) => {
          if (e.target.files?.[0]) {
            setFile(e.target.files?.[0]);
          }

          e.target.value = '';
        }}
      />
    </>
  );
};
