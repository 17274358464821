import type { FC } from 'react';
import styled from 'styled-components';

import type { ButtonProps } from '@xing-com/button';
import { Button } from '@xing-com/button';
import type { IconSearch } from '@xing-com/icons';
import { mediaTopBarHidden, mediaTopBarVisible } from '@xing-com/layout-tokens';
import { scale150, spaceM, spaceS, spaceXL } from '@xing-com/tokens';

export const ActionSlot: FC<
  {
    icon?: typeof IconSearch;
    text: string;
    showLabel?: boolean;
  } & ButtonProps
> = ({ icon, text, showLabel, ...props }) => {
  return (
    <ActionButton $showLabel={showLabel} icon={icon} {...props}>
      {text}
    </ActionButton>
  );
};

export const ActionButton = styled(Button)<{
  $showLabel?: boolean;
}>`
  border-radius: ${scale150};
  padding: 3px ${spaceM};
  height: 56px;

  @media ${mediaTopBarVisible} {
    margin-left: ${spaceS};
    height: ${scale150};
    width: ${scale150};
  }

  &:hover {
    background: #161c2415;
  }

  & > div {
    flex-direction: column;
    font-weight: normal;

    & > span {
      /* Icon */
      &:nth-child(1) {
        width: ${spaceXL};
        height: ${spaceXL};
        margin-right: 0;
        & > svg {
          width: ${spaceXL};
          height: ${spaceXL};
        }
      }
      /* Text */
      &:nth-child(2) {
        display: none;
        @media ${mediaTopBarHidden} {
          display: ${({ $showLabel }) => ($showLabel ? 'block' : 'none')};
          white-space: nowrap;
        }
      }
    }
  }
`;
