import { useContext } from 'react';

import { ModuleContext } from '../../context/module-context/module-context';

export const useModuleContext = () => {
  const context = useContext(ModuleContext);

  if (!context || !context.moduleContext) {
    throw new Error('No host context found. Please setup the ModuleContext');
  }

  return context;
};
