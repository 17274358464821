import type { FiltersItemsType, AggregationQueryType } from '../types';

export const transformAggregations: (
  aggregations: AggregationQueryType
) => FiltersItemsType = (aggregations) => {
  const filterItems = {
    disciplines: aggregations.disciplines
      ? aggregations.disciplines.map(
          ({
            id,
            count,
            discipline: { localizationValue, localizationKey },
          }) => ({
            id,
            count,
            name: localizationValue,
            trackingKey: localizationKey,
          })
        )
      : [],
    careerLevels: aggregations.careerLevels
      ? aggregations.careerLevels.map(
          ({
            id,
            count,
            careerLevel: { localizationValue, localizationKey },
          }) => ({
            id,
            count,
            name: localizationValue,
            trackingKey: localizationKey,
          })
        )
      : [],

    cities: aggregations.cities
      ? aggregations.cities.map(({ id, count, city }) => ({
          id,
          count,
          name: city?.name,
          trackingKey: city?.name
            ? city.name.replace(/ /g, '_').toLowerCase().normalize()
            : '',
        }))
      : [],
  };
  return filterItems;
};
