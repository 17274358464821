import styled from 'styled-components';

import { PaginationDots as DefaultPaginationDots } from '@xing-com/pagination-dots';
import {
  spaceL,
  spaceXS,
  spaceXXL,
  xdlColorBackgroundSecondary,
} from '@xing-com/tokens';

export const Wrapper = styled.div`
  > div {
    margin-right: ${spaceL};
  }
`;

export const CarouselWrapper = styled.div`
  & > div > button {
    visibility: visible !important;
    z-index: 2;
  }
  & > div > button {
    visibility: visible !important;
    z-index: 2;
  }
`;

export const Card = styled.div`
  width: 100%;
  padding: ${spaceXS};
`;

export const EmptyState = styled.div`
  background-color: ${xdlColorBackgroundSecondary};
  padding: 56px ${spaceXXL};
  font-weight: bold;
`;

export const PaginationDots = styled(DefaultPaginationDots)`
  margin: ${spaceL} auto 0 auto;
`;
