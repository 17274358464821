import * as Styled from './skeleton.styles';

type UserCardSkeletonProps = {
  numberOfItems: number;
};

const UserCardSkeleton = ({ numberOfItems = 2 }: UserCardSkeletonProps) => {
  const skeletons = [];

  for (let i = 0; i < numberOfItems; i++) {
    skeletons.push(
      <Styled.Container>
        <Styled.InfoWrapper>
          <Styled.LogoImageContainer />
          <Styled.UserInfo>
            {/* @ts-expect-error FIXME: SC6 */}
            <Styled.TitleSkeleton size="small" amountRows={1} />
            {/* @ts-expect-error FIXME: SC6 */}
            <Styled.BodySkeleton size="small" amountRows={2} />
          </Styled.UserInfo>
        </Styled.InfoWrapper>
      </Styled.Container>
    );
  }

  return <>{skeletons}</>;
};

export default UserCardSkeleton;
