import { useQuery } from '@apollo/client';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useRef, useEffect, useState, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import {
  EntityPageContractType,
  PostingPublicationState,
  type EntityPageContentFeedArticlePost,
} from '@xing-com/crate-common-graphql-types';
import {
  LazyLoading,
  useEditContext,
  usePageContext,
} from '@xing-com/crate-companies-entity-pages-common';
import type {
  LAZY_LOADING_TYPE_BUTTON,
  LAZY_LOADING_TYPE_SCROLL,
} from '@xing-com/crate-companies-entity-pages-common';
import { SocialCountersProvider } from '@xing-com/social-bar';

import { EntityPageFeedDocument } from '../../graphql/queries/feed-query.gql-types';
import type { EntityPageFeedQueryVariables } from '../../graphql/queries/feed-query.gql-types';
import {
  feedMetadataHasElements,
  getBannerTextResource,
  getEmptyStateTextResources,
  getEmptyTopAreaTextResources,
  getFatalErrorTextResources,
  getPinnedArticle,
  getSubPageKey,
  isValidFeedItem,
  newsPath,
  searchForFatalErrorInModule,
} from '../../utils';
import { CommboxOmTrigger } from '../commbox-om-trigger/commbox-om-trigger';
import { CommboxOnloadOmTrigger } from '../commbox-om-trigger/commbox-on-load-om-trigger';
import ModuleError from '../module-error/module-error';
import { NewsItem } from '../news-item/news-item';
import { NewsNavigation } from '../news-navigation/news-navigation';
import { NewsPremiumBanner } from '../news-premium-banner/news-premium-banner';
import * as Styled from './news.style';

type SkeletonLoaderProps = {
  loadingSkeletonCount: number;
  showActors: boolean;
};
const SkeletonLoader: FC<SkeletonLoaderProps> = ({
  loadingSkeletonCount,
  showActors,
}) => (
  <div data-testid={'NEWS_MODULE_SKELETON'}>
    {[...Array(loadingSkeletonCount)].map((_, i) => (
      <NewsItem.Skeleton key={i} smallActor={!showActors} />
    ))}
  </div>
);

export type NewsProps = {
  actor: {
    title: string;
    slug: string;
    logo?: string;
    isInsider: boolean;
  };
  focusType: string;
  globalId: string | null;
  isDetailPage?: boolean;
  isFollowingPage?: boolean;
  lazyLoadingIsAllowed: boolean;
  lazyLoadingType:
    | typeof LAZY_LOADING_TYPE_BUTTON
    | typeof LAZY_LOADING_TYPE_SCROLL
    | null;
  loadingSkeletonCount?: number;
  setLazyLoadingIsAllowed: Dispatch<SetStateAction<boolean>>;
  setHasErrorOnSubpageLabel: Dispatch<SetStateAction<boolean>>;
  showActors?: boolean;
  showSubpageLinks?: boolean;
  setCurrentPublicationState: (currentPublicationState: any) => void;
  feedQueryVars: EntityPageFeedQueryVariables;
  onCommboxTrigger?: () => void;
  onEditButtonClick?: (
    posting: Partial<EntityPageContentFeedArticlePost>
  ) => void;
};
export const News: FC<NewsProps> = ({
  actor,
  focusType,
  isDetailPage = false,
  isFollowingPage = false,
  lazyLoadingIsAllowed,
  lazyLoadingType,
  loadingSkeletonCount = 1,
  setHasErrorOnSubpageLabel,
  setLazyLoadingIsAllowed,
  showActors = true,
  showSubpageLinks = true,
  setCurrentPublicationState,
  feedQueryVars,
  onCommboxTrigger = () => undefined,
  onEditButtonClick = () => undefined,
}) => {
  const intl = useIntl();
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};
  const { contractType } = pageContext ?? {};
  const isPaidPage =
    contractType === EntityPageContractType.PaidPlus ||
    contractType === EntityPageContractType.Paid;

  const videoPlaying = useRef();
  const [isRefetching, setIsRefetching] = useState(false);

  const { data, loading, error, refetch, fetchMore } = useQuery(
    EntityPageFeedDocument,
    {
      variables: feedQueryVars,
      notifyOnNetworkStatusChange: false, // true is creating a 2nd request on refetch without the after variable
      errorPolicy: 'all',
    }
  );

  const feedItems = data?.entityPageContentFeeds?.collection || [];
  const hasFatalError =
    error && searchForFatalErrorInModule(error.graphQLErrors, data);

  const linkTextResource = getBannerTextResource(intl);
  const metadata = data?.entityPageContentFeeds?.metadata;
  const metadataHasElements = feedMetadataHasElements(metadata);
  const subPageKey = getSubPageKey(!!isEditing);
  const topNewsItem = isPaidPage ? getPinnedArticle(feedItems) : null;
  const showNewsNavigation =
    isDetailPage &&
    isEditing &&
    (metadataHasElements ||
      feedQueryVars.filter !== PostingPublicationState.Published);
  const showTopItem =
    isPaidPage &&
    topNewsItem &&
    feedQueryVars.filter === PostingPublicationState.Published;
  const showTopNewsEmptyState =
    isPaidPage &&
    !topNewsItem &&
    isEditing &&
    feedQueryVars.filter === PostingPublicationState.Published;
  const showFatalErrorEmptyState =
    feedItems.length === 0 &&
    !loading &&
    !isEditing &&
    data &&
    data.entityPageContentFeeds;
  const loadingFirstRender = loading && !isRefetching;

  const handleLazyLoad = () => {
    if (feedItems.length === 0) {
      return;
    }

    const object = feedItems[feedItems.length - 1].object;
    const limit = feedQueryVars.limit;
    const until =
      feedQueryVars.filter === PostingPublicationState.Published
        ? feedItems[feedItems.length - 1].publishedAt
        : object?.__typename === 'EntityPageContentFeedPostingPost'
          ? object?.createdAt
          : undefined;

    setIsRefetching(true);
    fetchMore({
      variables: { limit, until },
      updateQuery: (prev: any, { fetchMoreResult }) => {
        setIsRefetching(false);
        if (!fetchMoreResult) {
          setLazyLoadingIsAllowed(false);
          return prev;
        } else {
          const newItems =
            fetchMoreResult?.entityPageContentFeeds?.collection || [];
          setLazyLoadingIsAllowed(newItems.length > 0);
          return {
            entityPageContentFeeds: {
              ...prev.entityPageContentFeeds,
              collection: [
                ...prev.entityPageContentFeeds.collection,
                ...newItems,
              ],
            },
          };
        }
      },
    });
  };

  // gather all activity urns for providing social counter data below
  const allUrns = feedItems.map((f) => f.globalId);
  const wrapperProps =
    feedQueryVars.filter === PostingPublicationState.Published && !loading
      ? { urns: allUrns, children: Fragment }
      : undefined;
  const Wrapper = wrapperProps ? SocialCountersProvider : Fragment;

  const showEmptyState =
    !hasFatalError &&
    feedItems.length === 0 &&
    !loading &&
    isEditing &&
    data &&
    !!data.entityPageContentFeeds;

  const emptyStateCopy = getEmptyStateTextResources(
    showEmptyState,
    isDetailPage,
    metadataHasElements
  );

  const fatalErrorCopy = getFatalErrorTextResources();
  const topAreaCopy = getEmptyTopAreaTextResources();

  useEffect(() => {
    typeof hasFatalError !== 'undefined' &&
      setHasErrorOnSubpageLabel(hasFatalError);
  }, [data, error]);

  return (
    <Wrapper {...wrapperProps}>
      <Styled.NewsContainer
        showActors={showActors}
        data-testid={'NEWS_MODULE_CONTAINER'}
      >
        {showEmptyState && (
          <div data-testid={'NEWS_MODULE'}>
            <Styled.EmptyTopAreaWrapper {...emptyStateCopy} />
          </div>
        )}

        {isEditing && (
          <>
            <CommboxOmTrigger onClick={onCommboxTrigger} />
            {!isDetailPage && (
              <CommboxOnloadOmTrigger onClick={onCommboxTrigger} />
            )}
          </>
        )}

        {showNewsNavigation && metadata && (
          <NewsNavigation
            isLoading={loading}
            metadata={metadata}
            pageId={feedQueryVars.id}
            feedQueryVars={feedQueryVars}
            publicationState={feedQueryVars.filter}
            setPublicationState={setCurrentPublicationState}
          />
        )}

        {loadingFirstRender && (
          <SkeletonLoader
            loadingSkeletonCount={loadingSkeletonCount}
            showActors={showActors}
          />
        )}

        {!loadingFirstRender && hasFatalError && (
          <div data-testid={'NEWS_MODULE'}>
            <ModuleError refetch={refetch} />
          </div>
        )}

        {!hasFatalError && (
          <>
            {showFatalErrorEmptyState && (
              <div data-testid={'NEWS_MODULE'}>
                <Styled.EmptyTopAreaWrapper {...fatalErrorCopy} />
              </div>
            )}

            {feedItems.length > 0 && data && data.entityPageContentFeeds && (
              <div data-testid={'NEWS_MODULE'}>
                {!isPaidPage && isEditing && (
                  <NewsPremiumBanner
                    buttonTextKey="TOP_ARTICLE_PAID_CTA"
                    flagTextKey="TOP_ARTICLE_PAID_FLAG"
                    focusType={focusType}
                    headlineCopyKey="TOP_ARTICLE_PAID_HEADLINE"
                    href={linkTextResource}
                    locale={intl.locale}
                    moduleName="newsmodule"
                    testId={'newsPremiumBanner'}
                    textCopyKey="TOP_ARTICLE_PAID_COPY"
                  />
                )}
                {showTopNewsEmptyState && (
                  <div>
                    <Styled.EmptyTopAreaWrapper
                      {...topAreaCopy}
                      testId={'topEmptyArea'}
                    />
                  </div>
                )}
                {showTopItem && topNewsItem && (
                  <NewsItem
                    actor={actor}
                    feedQueryVars={feedQueryVars}
                    isDetailPage={isDetailPage}
                    isFollowingPage={isFollowingPage}
                    isPaidPage={isPaidPage}
                    feedUpdate={topNewsItem}
                    setLazyLoadingIsAllowed={setLazyLoadingIsAllowed}
                    showActor={showActors}
                    videoPlaying={videoPlaying}
                    onEditButtonClick={() =>
                      topNewsItem.object &&
                      // @ts-expect-error TODO: fix this type
                      onEditButtonClick(topNewsItem.object)
                    }
                    onItemActionCompleted={() => {
                      refetch();
                    }}
                  />
                )}
              </div>
            )}

            {feedItems
              .filter((feedItem) => isValidFeedItem(feedItem, feedQueryVars))
              .map((feedItem) => (
                <NewsItem
                  // @ts-expect-error TODO: fix this type
                  key={feedItem?.object?.globalId}
                  actor={actor}
                  feedQueryVars={feedQueryVars}
                  isDetailPage={isDetailPage}
                  isFollowingPage={isFollowingPage}
                  feedUpdate={feedItem}
                  isPaidPage={isPaidPage}
                  setLazyLoadingIsAllowed={setLazyLoadingIsAllowed}
                  showActor={showActors}
                  videoPlaying={videoPlaying}
                  onEditButtonClick={() =>
                    // @ts-expect-error TODO: fix this type
                    feedItem.object && onEditButtonClick(feedItem.object)
                  }
                  onItemActionCompleted={() => {
                    refetch();
                  }}
                />
              ))}
          </>
        )}

        <LazyLoading
          type={lazyLoadingType}
          isLoading={isRefetching}
          enabled={lazyLoadingIsAllowed}
          onLazyLoad={handleLazyLoad}
          dotAnimation={true}
        />

        {showSubpageLinks && feedItems.length > 0 && (
          <Styled.LazyloadContainer>
            <Button
              variant={'secondary'}
              size={'small'}
              to={newsPath(pageContext?.basePath ?? '', actor.slug)}
            >
              <FormattedMessage id={subPageKey} />
            </Button>
          </Styled.LazyloadContainer>
        )}
      </Styled.NewsContainer>
    </Wrapper>
  );
};
