import type { ApolloError } from '@apollo/client';
import type { FC } from 'react';

import {
  UserFlagsDisplayFlag,
  type EntityPageSocialProof,
} from '@xing-com/crate-common-graphql-types';
import {
  capitalizeString,
  WarningMessageBox as Error,
} from '@xing-com/crate-companies-entity-pages-common';
import { Flag } from '@xing-com/flag';
import { Row } from '@xing-com/xing-grid';

import UserCardSkeleton from './skeleton';
import * as Styled from './social-proof-list.styles';

type SocialProofListProps = {
  title?: string;
  users: Partial<EntityPageSocialProof>[];
  loading?: boolean;
  error?: ApolloError;
  refetch: () => void;
  fetchMore: () => void;
  fetchMoreError: boolean;
};

const RenderSkeleton = ({ numberOfItems }: { numberOfItems: number }) => {
  const skeletons = [];

  for (let i = 0; i < numberOfItems; i++) {
    skeletons.push(
      <Styled.ListItem>
        <UserCardSkeleton />
      </Styled.ListItem>
    );
  }

  return <>{skeletons.join()}</>;
};

const SocialProofList: FC<SocialProofListProps> = ({
  title,
  users,
  loading,
  error,
  refetch,
  fetchMore,
  fetchMoreError,
}) => {
  return (
    <Row>
      <Styled.Container size={12} sizeDesktop={5} sizeTablet={5}>
        <>
          {title && (
            <Styled.Title lineHeight="dense" fontWeight="bold" size="large">
              {title}
            </Styled.Title>
          )}
          {users.length === 0 && loading && (
            <Styled.List>
              <RenderSkeleton numberOfItems={2} />
            </Styled.List>
          )}
          {users.length !== 0 && (
            <Styled.List data-testid={'SOCIAL_PROOF_LIST'}>
              <>
                {users.map((user, index) => {
                  const displayFlag = user.xingId?.userFlags?.displayFlag;
                  const showFlag =
                    displayFlag !== UserFlagsDisplayFlag.Basic &&
                    displayFlag !== UserFlagsDisplayFlag.Executive;

                  return (
                    <Styled.ListItem
                      key={index}
                      data-cy={'social-proof-contact'}
                    >
                      <Styled.ProfileInfo
                        key={user.xingId?.id}
                        profileUrl={
                          user?.xingId?.clickReasonProfileUrl?.profileUrl ||
                          `/profile/${user.xingId?.pageName}`
                        }
                        profileImage={{
                          size: 'small',
                          src: user.xingId?.profileImage?.[0]
                            ? user.xingId?.profileImage[0].url
                            : undefined,
                          profileName: 'user profile image',
                        }}
                        headline={{
                          size: 'small',
                          children: (
                            <div>
                              <strong>{user.xingId?.displayName}</strong>
                              {displayFlag && showFlag && (
                                <Flag
                                  size="small"
                                  // @ts-expect-error displayFlag in lowerCase
                                  variant={displayFlag.toLowerCase()}
                                >
                                  {capitalizeString(displayFlag)}
                                </Flag>
                              )}
                            </div>
                          ),
                        }}
                        textBody={[
                          {
                            size: 'small',
                            children: user.xingId?.occupations?.[0]?.headline,
                          },
                        ]}
                        textMeta={[
                          {
                            size: 'small',
                            children: user.xingId?.occupations?.[0]?.subline,
                          },
                        ]}
                      />
                    </Styled.ListItem>
                  );
                })}
              </>
            </Styled.List>
          )}
          {(error || fetchMoreError) && (
            <div data-testid={'errorContainer'}>
              <Error
                headerText="EP_ERROR_HEADER"
                bodyText="EP_ERROR_BODY"
                buttonText="EP_ERROR_RELOAD_CTA"
                onClick={fetchMoreError ? () => fetchMore() : () => refetch()}
              />
            </div>
          )}
        </>
      </Styled.Container>
    </Row>
  );
};

export default SocialProofList;
