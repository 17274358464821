import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { formatAmount } from '@xing-com/crate-companies-entity-pages-common';

import { useHeaderContext } from '../../hooks/use-header-context/use-header-context';
import { EntityInfo } from './entity-info';

export const EntityInfoPublisherPage: FC = () => {
  const { headerContent } = useHeaderContext();
  const intl = useIntl();

  if (headerContent?.__typename !== 'EntityPagePublisherHeaderContent') {
    return null;
  }

  const entityInfoData = [];
  const followersTotal = headerContent.followers?.total;

  if (followersTotal && followersTotal > 0) {
    entityInfoData.push({
      value: formatAmount({ value: followersTotal, intl }),
      label: ` ${intl.formatMessage({
        id: followersTotal > 1 ? 'EP_FOLLOWERS.other' : 'EP_FOLLOWERS.one',
      })}`,
      testId: 'HEADER_FOLLOWERS_TOTAL',
    });
  }

  return <EntityInfo data={entityInfoData} />;
};
