/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type NewsCommboxUpdatePostingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  commentArticleV1?: Types.InputMaybe<
    | Array<Types.ArticlesCreateArticleBlocksInput>
    | Types.ArticlesCreateArticleBlocksInput
  >;
  publishAt?: Types.InputMaybe<Types.Scalars['Date']['input']>;
}>;

export type NewsCommboxUpdatePostingMutation = {
  __typename?: 'Mutation';
  postingsUpdatePosting?: {
    __typename?: 'PostingsPostingMutationResult';
    success?: {
      __typename?: 'PostingsPosting';
      id: string;
      comment: string;
    } | null;
    error?: {
      __typename?: 'PostingsError';
      message?: string | null;
      details?: Array<string> | null;
    } | null;
  } | null;
};

export const NewsCommboxUpdatePostingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NewsCommboxUpdatePosting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'commentArticleV1' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'ArticlesCreateArticleBlocksInput',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publishAt' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postingsUpdatePosting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'commentArticleV1' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'commentArticleV1' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publishAt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'publishAt' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'details' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewsCommboxUpdatePostingMutation,
  NewsCommboxUpdatePostingMutationVariables
>;
