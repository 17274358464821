import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  SkeletonImage as BaseSkeletonImage,
  SkeletonBody as BaseSkeletonBody,
  SkeletonButton as BaseSkeletonButton,
} from '@xing-com/skeleton';
import { spaceL, xdlColorBorderSoft } from '@xing-com/tokens';

export const SkeletonWrapper = styled.div`
  width: 740px;

  @media ${mediaSmallOrTiny} {
    width: 100%;
  }
`;

export const SkeletonImage = styled(BaseSkeletonImage)`
  width: 740px;
  height: 314px;
  margin-bottom: ${spaceL};

  @media ${mediaSmallOrTiny} {
    width: 100%;
    height: 160px;
  }
`;

export const PublishWrapper = styled.div`
  margin-top: 110px;
  display: flex;
  justify-content: flex-end;
  padding-top: ${spaceL};
  border-top: 1px solid ${xdlColorBorderSoft};
`;

export const SkeletonButton = styled(BaseSkeletonButton)`
  width: 125px;
`;

export const SkeletonBody = styled(BaseSkeletonBody)`
  margin-bottom: ${spaceL};
`;
