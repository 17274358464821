import styled, { css } from 'styled-components';

import { Dimmer as XdlDimmer } from '@xing-com/dimmer';
import {
  cornerRadiusXL,
  motionEasingEnter,
  motionEasingLeave,
  motionTimeL,
  motionTimeM,
  scale100,
  spaceXL,
  spaceXXL,
  xdlColorBackground,
} from '@xing-com/tokens';

type DimmerTransientProps = {
  $isNotClickable: boolean;
};

export const dimmerDurationEnter = motionTimeL;
export const dimmerDurationLeave = motionTimeM;
export const dimmerTransitionName = 'fade';
export const sideSheetDurationEnter = motionTimeL;
export const sideSheetDurationLeave = motionTimeM;
export const sideSheetTransitionName = 'slide';

// *** Components ***
export const SideSheet = styled.div`
  background-color: ${xdlColorBackground};
  border-end-end-radius: ${cornerRadiusXL};
  border-start-end-radius: ${cornerRadiusXL};
  height: 100dvh;
  inset-block-end: 0;
  inset-block-start: 0;
  inset-inline-start: 0;
  margin-inline-end: ${spaceXXL};
  max-width: 360px;
  overflow-y: auto;
  padding-block-end: calc(env(safe-area-inset-bottom) + ${spaceXL});
  padding-block-start: calc(env(safe-area-inset-top) + ${spaceXL});
  padding-inline-end: ${scale100};
  padding-inline-start: ${scale100};
  position: fixed;
  width: 200px;
  width: 85vw;
  z-index: 10000; /* @TODO: use token when decided */

  /* SideSheet Animation */
  &.${sideSheetTransitionName}-enter {
    transform: translate3d(-99.9%, 0, 0);
  }

  &.${sideSheetTransitionName}-enter-active {
    transform: translate3d(0%, 0, 0);
    transition-duration: ${sideSheetDurationEnter};
    transition-property: transform;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${sideSheetTransitionName}-enter-done {
    transform: translate3d(0%, 0, 0);
    transition-duration: ${sideSheetDurationEnter};
    transition-property: transform;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${sideSheetTransitionName}-exit {
    transform: translate3d(0%, 0, 0);
  }

  &.${sideSheetTransitionName}-exit-active {
    transform: translate3d(-99.9%, 0, 0);
    transition-duration: ${sideSheetDurationLeave};
    transition-property: transform;
    transition-timing-function: ${motionEasingLeave};
  }

  &.${sideSheetTransitionName}-exit-done {
    transform: translate3d(-100%, 0, 0);
    transition-duration: ${sideSheetDurationLeave};
    transition-property: transform;
    transition-timing-function: ${motionEasingLeave};
  }
`;

export const Dimmer = styled(XdlDimmer)<DimmerTransientProps>`
  overflow-y: hidden;

  ${({ $isNotClickable }) =>
    $isNotClickable &&
    css`
      cursor: default;
    `};

  /* Dimmer Animation */
  &.${dimmerTransitionName}-enter {
    opacity: 0;
  }

  &.${dimmerTransitionName}-appear {
    opacity: 0;
  }

  &.${dimmerTransitionName}-enter-active {
    opacity: 60%;
    transition-duration: ${dimmerDurationEnter};
    transition-property: opacity;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${dimmerTransitionName}-appear-active {
    opacity: 60%;
    transition-duration: ${dimmerDurationEnter};
    transition-property: opacity;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${dimmerTransitionName}-enter-done {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-appear-done {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-exit {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-exit-active {
    opacity: 0;
    transition-duration: ${dimmerDurationLeave};
    transition-property: opacity;
    transition-timing-function: ${motionEasingLeave};
  }

  &.${dimmerTransitionName}-exit-done {
    opacity: 0;
  }
`;
