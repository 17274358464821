import { SkeletonBody, SkeletonImage } from '@xing-com/skeleton';

import * as Styled from './skeleton.styles';

export const EditDescriptionSkeleton = () => (
  <Styled.Container>
    <SkeletonBody amountRows={2} />
    <Styled.SkeletonIntroTitle height="38px" />
    <SkeletonImage height="116px" />
  </Styled.Container>
);
