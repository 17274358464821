import { useState, useLayoutEffect, useRef } from 'react';

export const useStickyBarWithOffset = (frame_height = 86) => {
  const ref = useRef<HTMLDivElement>(null);
  const [stickBarFixed, setStickyBarFixed] = useState(false);

  const onScroll = () => {
    const top = ref?.current?.getBoundingClientRect().top;

    if (top && top <= frame_height) {
      setStickyBarFixed(true);
    }

    if (top && top > frame_height) {
      setStickyBarFixed(false);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return [ref, stickBarFixed];
};
