import { useMutation, useQuery } from '@apollo/client';
import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { StatusBannerAutoHide } from '@xing-com/banner';
import {
  useDialogContext,
  usePageContext,
  useErrorContext,
  EntityPageUpdateContactUsersCatalogueDocument,
  WarningMessageBox as WarningBox,
} from '@xing-com/crate-companies-entity-pages-common';
import { BodyCopy } from '@xing-com/typography';

import { ContactsDocument } from '../../graphql/contacts-query.gql-types';
import type { ContactsQueryVariables } from '../../graphql/contacts-query.gql-types';
import type { XingIdContactsFragment } from '../../graphql/xing-id-contacts-fragment.gql-types';
import type {
  ContactUsersListForUpdateType,
  ContactsContainerProps,
} from '../../types';
import ContactsEdit from '../contacts-edit/contacts-edit';
import * as Styled from '../contacts-edit/contacts-edit.styles';
import Skeleton from './skeleton';

type ContactsEditContainerProps = {
  pageSlug: string;
};

const ContactsEditContainer = ({ pageSlug }: ContactsEditContainerProps) => {
  const ref = useRef(null);
  const { showError } = useErrorContext();
  const { pageContext } = usePageContext() ?? {};
  const goBackUrl = pageContext?.goBackUrl;

  const intl = useIntl();

  const [showInfoFeedback, setShowInfoFeedback] = useState<boolean | null>(
    null
  );

  const [contactsSuccess, setContactsSuccess] = useState<boolean | null>(null);

  const { setDataChanged, dataChanged } = useDialogContext();

  const [contactsTouched, setContactsTouched] = useState<boolean | null>(null);

  const [disablePageActions, setDisablePageActions] = useState(false);

  const { data, loading, error, refetch } = useQuery(ContactsDocument, {
    variables: { id: pageSlug, includeDisabledContact: true },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  /** ****** CONTACTS ********/
  const [contactUsersList, setContactUsersList] = useState<
    ContactUsersListForUpdateType[] | null
  >(null);

  const contactUsers = data?.entityPageContactUsers?.collection || [];

  const userList = contactUsers.map((user) => ({
    ...user,
    contactId: user.id,
    label: user.label,
    ...user.xingId,
  })) as ContactUsersListForUpdateType[];

  const createContact = (user: XingIdContactsFragment) => {
    setContactsTouched(true);
    setDataChanged(true);

    const userList =
      (pageContext?.contractType === 'FREE'
        ? contactUsersList?.map((x) => ({
            ...x,
            deleted: true,
          }))
        : contactUsersList) || [];

    if (pageContext?.contractType === 'FREE' && showInfoFeedback === null) {
      setShowInfoFeedback(true);

      setTimeout(() => {
        setShowInfoFeedback(false);
      }, 5000);
    }

    setContactUsersList([
      {
        contactId: `temp_${user.id}_${new Date().getTime()}`,
        enabled: true,
        label: null,
        userId: user.id,
        ...user,
        xingId: {
          ...user,
        },
      },
      ...userList,
    ]);
  };

  const handleCache = (
    cache: any,
    variables: ContactsQueryVariables,
    updateValue: any
  ) => {
    const data = cache.readQuery({
      query: ContactsDocument,
      variables,
    });

    cache.writeQuery({
      query: ContactsDocument,
      variables,
      data: {
        ...data,
        entityPageContactUsers: {
          ...data.entityPageContactUsers,
          collection: updateValue,
        },
      },
    });
  };

  const [updateContacts, { loading: updateContactsLoading }] = useMutation(
    EntityPageUpdateContactUsersCatalogueDocument,
    {
      onCompleted: (data) => {
        if (data?.entityPageUpdateContactUsersCatalogue?.error) {
          setContactsSuccess(false);
          showError({
            message: 'EP_GENERAL_FEEDBACK_ERROR',
            error: data.entityPageUpdateContactUsersCatalogue.error,
          });
        } else {
          setContactsSuccess(true);
        }
      },
      onError: (error) => {
        setContactsSuccess(false);
        showError({
          message: 'EP_GENERAL_FEEDBACK_ERROR',
          error,
        });
      },
      update: (cache) => {
        const usersToUpdateCache = contactUsersList
          ? contactUsersList
              .filter((x) => !x.deleted && x.enabled)
              .map((user, index: number) => ({
                id: user.contactId,
                userId: user.userId,
                label: user.label,
                position: index,
                enabled: user.enabled,
                deleted: user.deleted,
                xingId: user.xingId,
                __typename: 'EntityPageContactUser',
              }))
          : [];

        // Mainpage query
        try {
          handleCache(
            cache,
            { id: pageSlug, limit: pageContext?.upsellActive ? 1 : 2 },
            usersToUpdateCache?.slice(0, pageContext?.upsellActive ? 1 : 2)
          );
        } catch (e) {
          // Caused when there is no cache entry
        }

        // subpageQuery
        try {
          handleCache(cache, { id: pageSlug }, usersToUpdateCache);
        } catch (e) {
          // Caused when there is no cache entry
        }
      },
    }
  );

  const getContactsMutationInput = () => ({
    pageId: pageSlug,
    // filter out users that were added and deleted
    ContactUsersCatalogueItemInput: contactUsersList
      ? contactUsersList
          .filter((x) => !(x.contactId?.includes('temp') && x.deleted))
          .map((user, index: number) => ({
            id: user.contactId?.includes('temp') ? null : user.contactId,
            userId: user.userId,
            label: user.label,
            position: index,
            enabled: user.enabled,
            deleted: user.deleted,
          }))
      : [],
  });

  const contactProps: ContactsContainerProps = {
    createContact,
    contactUsers: contactUsersList || userList,
    setContactUsersList,
    contactsTouched,
    setContactsTouched,
    setDisablePageActions,
    updateContacts: () =>
      updateContacts({
        variables: getContactsMutationInput(),
      }),
  };

  useEffect(() => {
    const nonDeletedUsers = contactUsersList?.filter((x) => !x.deleted);
    if (nonDeletedUsers?.length === 1 && nonDeletedUsers[0].enabled === false) {
      const newList = contactUsersList
        ? contactUsersList.map((user) => {
            if (user.default) {
              user.enabled = true;
              return user;
            } else {
              return user;
            }
          })
        : [];
      setContactUsersList(newList);
    }
  }, [contactUsersList]);

  // effect after publish mutations run
  useEffect(() => {
    if (dataChanged && (contactsSuccess || contactsSuccess === null)) {
      goBackUrl && goBackUrl();
    }
  }, [contactsSuccess]);

  if (loading) {
    return (
      <>
        <Styled.Headline size={'xxlarge'}>
          {intl.formatMessage({
            id: 'EP_CONTACTS_EDIT_TITLE',
            defaultMessage: 'EP_CONTACTS_EDIT_TITLE',
          })}
        </Styled.Headline>
        <Skeleton />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Styled.Headline size={'xxlarge'}>
          {/* <Headline size={'xxlarge'} className={styles.headline}> */}
          {intl.formatMessage({
            id: 'EP_CONTACTS_EDIT_TITLE',
            defaultMessage: 'EP_CONTACTS_EDIT_TITLE',
          })}
        </Styled.Headline>
        <WarningBox
          headerText="EP_ERROR_HEADER"
          bodyText="EP_ERROR_BODY"
          buttonText="EP_ERROR_RELOAD_CTA"
          onClick={() => refetch()}
        />
      </>
    );
  }

  return (
    <>
      <Styled.Headline size={'xxlarge'}>
        {intl.formatMessage({
          id: 'EP_CONTACTS_EDIT_TITLE',
          defaultMessage: 'EP_CONTACTS_EDIT_TITLE',
        })}
      </Styled.Headline>
      <div ref={ref}>
        <ContactsEdit
          pageSlug={pageSlug}
          contactProps={contactProps}
          dataChanged={dataChanged}
          disablePageActions={disablePageActions}
          isSaving={updateContactsLoading}
          contactUsers={contactUsers}
          goBackUrl={goBackUrl}
        />
      </div>
      <StatusBannerAutoHide
        variant="info"
        timeout={7000}
        show={!!showInfoFeedback}
        handleOnClose={() => setShowInfoFeedback(false)}
      >
        <BodyCopy noMargin size={'small'}>
          {intl.formatMessage({
            id: 'EP_FREE_ADD_CONTACT_INFO',
            defaultMessage: 'EP_FREE_ADD_CONTACT_INFO',
          })}
        </BodyCopy>
      </StatusBannerAutoHide>
    </>
  );
};

export default ContactsEditContainer;
