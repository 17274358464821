import { Uppy } from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import type { FC } from 'react';
import { useState, useEffect } from 'react';

import { UploadFinished } from './upload-finished';
import { UploadInProgress } from './upload-in-progress';
import { UploadReady } from './upload-ready';

type UploadContainerProps = {
  videoId?: string;
  previewImage?: File;
  addVideo: (file: File) => Promise<{
    uploadUrl: string;
    videoId: string;
  }>;
  onValidFileSelected?: () => void;
  onUploadVideoComplete?: (file: File) => void;
  onUploadInProgress?: () => void;
  onEncodingComplete?: (videoId: string) => void;
  onError?: (error: any) => void;
};
export const UploadContainer: FC<UploadContainerProps> = ({
  videoId: initialVideoId,
  previewImage,
  addVideo,
  onValidFileSelected = () => undefined,
  onUploadVideoComplete = () => undefined,
  onUploadInProgress = () => undefined,
  onEncodingComplete = () => undefined,
  onError = () => undefined,
}) => {
  const [videoId, setVideoId] = useState(initialVideoId);
  const [progress, setProgress] = useState(initialVideoId ? 1 : 0);

  useEffect(() => {
    if (initialVideoId) {
      setVideoId(initialVideoId);
    }
  }, [initialVideoId]);

  const handleFileSelect = async (file: File) => {
    if (file.size > 5000000000) {
      onError('VIDEO_UPLOADING_ERROR');
      return;
    }

    if (file.type.indexOf('video/') < 0) {
      onError('VIDEO_UPLOADING_ERROR');
      return;
    }

    onValidFileSelected();

    const { uploadUrl, videoId } = await addVideo(file);

    setVideoId(videoId);

    try {
      const request = new Uppy({ debug: false, autoProceed: true });

      request.use(XHRUpload, {
        endpoint: uploadUrl,
        method: 'PUT',
        formData: false,
      });

      request.addFile({
        name: file.name,
        type: file.type,
        data: file,
      });

      request.on('error', (error) => {
        onError(error.message);
      });
      request.on('upload-progress', (file, progress) => {
        const newProgress = progress.bytesUploaded / (progress.bytesTotal ?? 1);
        setProgress(newProgress);
        onUploadInProgress();
      });
      request.on('complete', () => {
        setProgress(1);
        onUploadVideoComplete(file);
      });
    } catch (e) {
      onError('VIDEO_UPLOADING_ERROR');
    }
  };

  return (
    <>
      {progress === 0 && <UploadReady handleFileSelect={handleFileSelect} />}
      {progress > 0 && progress < 1 && <UploadInProgress progress={progress} />}
      {progress === 1 && videoId && (
        <UploadFinished
          // onError={onError}
          videoId={videoId}
          previewImage={previewImage}
          onEncodingComplete={() => videoId && onEncodingComplete(videoId)}
        />
      )}
    </>
  );
};
