import styled from 'styled-components';

import { scale100, spaceL, spaceXXL } from '@xing-com/tokens';
import { BodyCopy, Headline as DefaultHeadline } from '@xing-com/typography';

export const Headline = styled(DefaultHeadline)`
  margin: 0;
`;

export const SubtitlesContainer = styled.div`
  margin-top: ${spaceXXL};
`;

export const SubtitlesDescription = styled(BodyCopy)`
  margin-bottom: ${spaceL};
`;

export const SubtitleItem = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${spaceL};
`;

export const SubtitlesLabel = styled(DefaultHeadline)`
  margin: 0;
  min-width: 70px;
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: 0;
  display: flex;
`;

export const UploadCtaContainer = styled.div`
  flex-shrink: 0;
`;

export const FileAddedContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export const FileName = styled(BodyCopy)`
  margin: 0 ${scale100};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
