import styled from 'styled-components';

import { Button as DefaultButton } from '@xing-com/button'; // eslint-disable-line
import {
  spaceS,
  xdlColorDisabledSoft,
  xdlColorDisabledStrong,
  xdlColorInfoSoft,
  xdlColorSelected,
  xdlColorText,
  xdlColorTextInvert,
} from '@xing-com/tokens';

export const Button = styled(DefaultButton)<{ $mobileButton: boolean }>`
  background-color: ${xdlColorInfoSoft};
  border-radius: 50px;
  color: ${xdlColorText};
  display: none;

  &:hover {
    background-color: #c9dcfb; /* custom color */
  }
  &:active {
    background-color: ${xdlColorSelected};
    border-color: ${xdlColorSelected};
    color: ${xdlColorTextInvert};
  }
  &[disabled],
  &[disabled]::after {
    background-color: ${xdlColorDisabledSoft};
    color: ${xdlColorDisabledStrong};
  }
  &[disabled]:active {
    border: 0;
  }

  ${({ $mobileButton }) =>
    $mobileButton &&
    `
    display: inline-flex;
  margin-left: ${spaceS};
`}

  @media (--mediaXL) {
    display: inline-flex;
  }
`;
