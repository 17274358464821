import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import {
  mediaConfined,
  mediaSmallOrTiny,
  mediaWide,
} from '@xing-com/layout-tokens';
import {
  cornerRadiusM,
  scale020,
  xdlColorBorderSoft,
  xdlColorBorderStrong,
} from '@xing-com/tokens';

import type { VerticalAlignment } from '.';

type CarouselTProps = {
  $hideScrollbar?: boolean;
  $horizontalPadding?: string;
  $horizontalPaddingConfined?: string;
  $horizontalPaddingWide?: string;
  $paddingBottom?: string;
  $paddingBottomConfined?: string;
  $paddingBottomWide?: string;
  $paddingTop?: string;
  $paddingTopConfined?: string;
  $paddingTopWide?: string;
  $verticalAlignment: VerticalAlignment;
};

type ButtonTProps = {
  $paddingBottom: string;
  $paddingBottomConfined: string;
  $paddingBottomWide: string;
  $paddingTop: string;
  $paddingTopConfined?: string;
  $paddingTopWide?: string;
};

// *** Vertical Alignment ***
export const verticalAlignments = {
  stretch: css`
    align-items: stretch;
  `,
  top: css`
    align-items: flex-start;
  `,
  center: css`
    align-items: center;
  `,
  bottom: css`
    align-items: flex-end;
  `,
};

/* Custom scrollbar for Desktop */
const customScrollbar = css`
  --trackColor: ${xdlColorBorderSoft};
  --thumbColor: ${xdlColorBorderStrong};

  @media ${mediaWide} {
    scrollbar-color: var(--thumbColor) var(--trackColor);
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: ${scale020};
    }

    &::-webkit-scrollbar-track {
      background-color: var(--trackColor);
      border-radius: ${cornerRadiusM};
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--thumbColor);
      border-radius: ${cornerRadiusM};
    }
  }
`;

// *** Components ***
export const Container = styled.div`
  position: relative;

  & > button {
    visibility: hidden;

    @media ${mediaSmallOrTiny} {
      /* completely remove so that viewport is not extended */
      display: none;
    }
  }

  @media ${mediaWide} {
    &:hover > button {
      visibility: visible;
    }
  }
`;

export const Carousel = styled.div<CarouselTProps>`
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;

  @media ${mediaWide} {
    scroll-snap-type: x proximity;
  }

  ${(props) => css`
    ${props.$hideScrollbar
      ? css`
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
            height: 0;
            width: 0;
          }
        `
      : css`
          ${customScrollbar};
        `};

    ${props.$verticalAlignment && verticalAlignments[props.$verticalAlignment]};

    ${props.$paddingTop &&
    css`
      padding-block-start: ${props.$paddingTop};
    `};

    ${props.$paddingTopConfined &&
    css`
      @media ${mediaConfined} {
        padding-block-start: ${props.$paddingTopConfined};
      }
    `};

    ${props.$paddingTopWide &&
    css`
      @media ${mediaWide} {
        padding-block-start: ${props.$paddingTopWide};
      }
    `};

    ${props.$paddingBottom &&
    css`
      padding-block-end: ${props.$paddingBottom};
    `};

    ${props.$paddingBottomConfined &&
    css`
      @media ${mediaConfined} {
        padding-block-end: ${props.$paddingBottomConfined};
      }
    `};

    ${props.$paddingBottomWide &&
    css`
      @media ${mediaWide} {
        padding-block-end: ${props.$paddingBottomWide};
      }
    `};

    ${props.$horizontalPadding &&
    css`
      padding-inline-start: ${props.$horizontalPadding};
    `};

    ${props.$horizontalPaddingConfined &&
    css`
      @media ${mediaConfined} {
        padding-inline-start: ${props.$horizontalPaddingConfined};
      }
    `};

    ${props.$horizontalPaddingWide &&
    css`
      @media ${mediaWide} {
        padding-inline-start: ${props.$horizontalPaddingWide};
      }
    `};
  `};
`;

const pageButton = css<ButtonTProps>`
  && {
    position: absolute;
  }

  ${(props) => css`
    ${props.$paddingTop &&
    props.$paddingBottom &&
    css`
      inset-block-start: calc(
        50% - 21px - calc(${props.$paddingBottom} / 2) +
          calc(${props.$paddingTop} / 2)
      );
    `};

    ${props.$paddingTopConfined &&
    props.$paddingBottomConfined &&
    css`
      @media ${mediaConfined} {
        inset-block-start: calc(
          50% - 21px - calc(${props.$paddingBottomConfined} / 2) +
            calc(${props.$paddingTopConfined} / 2)
        );
      }
    `};

    ${props.$paddingTopWide &&
    props.$paddingBottomWide &&
    css`
      @media ${mediaWide} {
        inset-block-start: calc(
          50% - 21px - calc(${props.$paddingBottomWide} / 2) +
            calc(${props.$paddingTopWide} / 2)
        );
      }
    `};
  `};
`;

export const NextPageButton = styled(Button)<ButtonTProps>`
  ${pageButton};
  inset-inline-end: -21px; /* Half medium button */
`;

export const PreviousPageButton = styled(Button)<ButtonTProps>`
  ${pageButton};
  inset-inline-start: -21px; /* Half medium button */
`;
