import styled from 'styled-components';

import { SkeletonBody, SkeletonButton } from '@xing-com/skeleton';
import {
  spaceL,
  spaceXL,
  spaceXXL,
  xdlColorControlFillSecondary,
} from '@xing-com/tokens';

export const DocumentsWrapperSkeleton = styled.div`
  padding: ${spaceL};
  margin-top: ${spaceXL};
  display: flex;
  align-items: center;
  background-color: ${xdlColorControlFillSecondary};
`;

export const DocumentsSkeleton = styled(SkeletonBody)`
  width: 50%;
  flex-shrink: 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ItemActionsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const ItemsActionsButton = styled(SkeletonButton)`
  width: 32px;

  &:first-child {
    margin-right: ${spaceL};
  }
`;

export const PublishButton = styled(SkeletonButton)`
  width: 125px;
`;

export const DocumentsActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${spaceXXL} 0 calc(2 * ${spaceXXL}) 0;
`;
