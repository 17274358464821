import type { FC } from 'react';

import { useHeaderContext } from '../../hooks/use-header-context/use-header-context';
import { EntityInfoCompany } from './entity-info-company';
import { EntityInfoPublisherPage } from './entity-info-publisher-page';
import { EntityInfoTopicPage } from './entity-info-topic-page';

export const EntityInfoContainer: FC = () => {
  const { headerContent } = useHeaderContext();

  if (headerContent?.__typename === 'EntityPageTopicPageHeaderContent') {
    return <EntityInfoTopicPage />;
  }
  if (headerContent?.__typename === 'EntityPageCompanyHeaderContent') {
    return <EntityInfoCompany />;
  }
  if (headerContent?.__typename === 'EntityPagePublisherHeaderContent') {
    return <EntityInfoPublisherPage />;
  }
  return null;
};
