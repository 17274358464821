import { useEffect, useMemo, useState, type FC } from 'react';

import { Carousel } from '@xing-com/carousel';
import { getImageGender } from '@xing-com/crate-companies-entity-pages-common';
import { trackPageEmployeesChange } from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import { spaceXL } from '@xing-com/tokens';

import type { Employee, EmployeeNode } from '../../types';
import {
  NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_IN,
  NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT,
} from '../config';
import { EmployeeCardFencing } from '../employees-card-vertical/employees-card-fencing';
import { EmployeesCardVertical } from '../employees-card-vertical/employees-card-vertical';
import * as Styled from '../employees-container.styles';

const filterEmployeesList = ({
  employeesList = [],
  numberOfEmployees = 10,
}: {
  employeesList: EmployeeNode[];
  numberOfEmployees?: number;
}): Employee[] =>
  employeesList
    .filter(({ node }) => node && node.profileDetails !== null)
    .slice(0, numberOfEmployees)
    .map(({ node }) => node);

type EmployeesCarouselProps = {
  employeesList: EmployeeNode[];
  totalEmployees: number;
  onAddContact?: () => void;
};
const EmployeesCarousel: FC<EmployeesCarouselProps> = ({
  employeesList,
  totalEmployees,
  onAddContact = () => undefined,
}) => {
  const [selected, setSelected] = useState(0);
  const [numberOfItemsPerChunk, setNumberOfItemsPerChunk] = useState(5);

  const { isLoggedIn } = useLoginState();

  const employeesListFiltered = filterEmployeesList({
    employeesList,
    numberOfEmployees: isLoggedIn
      ? NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_IN
      : NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT,
  });

  const showFencing =
    !isLoggedIn && totalEmployees > NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT;

  const copyOfEmployees = useMemo(
    () => Array.from({ length: employeesListFiltered.length }),
    [employeesListFiltered]
  );

  const chunk = (arr: any[], size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

  const dots = chunk(copyOfEmployees, numberOfItemsPerChunk).length;

  useEffect(() => {
    window.matchMedia(mediaConfined).addEventListener('change', (ev) => {
      if (ev.matches) {
        setNumberOfItemsPerChunk(2);
      } else {
        setNumberOfItemsPerChunk(2);
      }
    });
    window.matchMedia(mediaWide).addEventListener('change', (ev) => {
      if (ev.matches) {
        setNumberOfItemsPerChunk(3);
      }
    });
  }, []);

  return (
    <Styled.CarouselWrapper>
      <Carousel
        itemWidth="300px"
        itemWidthConfined="33%"
        itemWidthWide="20%"
        paddingBottom={spaceXL}
        onItemEnter={({ direction, itemIndex }) => {
          setSelected(parseInt((itemIndex / numberOfItemsPerChunk).toString()));
          if (direction) {
            trackPageEmployeesChange({ direction, page: itemIndex });
          }
        }}
      >
        {employeesListFiltered.map((employee) => (
          <EmployeesCardVertical
            key={employee.profileDetails.id}
            id={employee.profileDetails.id}
            profileImage={{
              src: employee.profileDetails.profileImage?.[0]?.url,
              type: getImageGender(employee.profileDetails.gender),
              profileName: employee.profileDetails.displayName,
              alt: `${employee.profileDetails.displayName}'s profile picture`,
              connectionDegree: employee.contactDistance?.distance,
            }}
            pageName={employee.profileDetails.pageName}
            profileUrl={
              employee.profileDetails.clickReasonProfileUrl?.profileUrl ||
              `/profile/${employee.profileDetails.pageName}`
            }
            profileName={employee.profileDetails.displayName}
            position={employee.profileDetails.occupations?.[0]?.subline || ''}
            relationship={employee.networkRelationship?.relationship}
            flag={employee.profileDetails.userFlags?.displayFlag}
            onAddContact={onAddContact}
          />
        ))}
        {showFencing && (
          <EmployeeCardFencing employeesNumber={totalEmployees} />
        )}
      </Carousel>
      {dots > 0 && <Styled.PaginationDots length={dots} selected={selected} />}
    </Styled.CarouselWrapper>
  );
};

export default EmployeesCarousel;
