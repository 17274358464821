import * as React from 'react';

import * as Styled from './image.styles';
import type { ImageProps } from './image.types';

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  ({ className, ...props }, forwardedRef): JSX.Element => (
    <Styled.Image
      className={className}
      data-xds="Image"
      ref={forwardedRef}
      {...props}
    />
  )
);

Image.displayName = 'Image';
