import {
  ENTITY_PAGE_NEWS_ARTICLE_VIEW,
  ENTITY_PAGE_NEWS_FOLLOW,
  PROP_CONTEXT_DIMENSION1,
  PROP_INTERACTION_TYPE,
  ENTITY_PAGE_NEWS_UNFOLLOW,
  COMMBOX_OPEN_AUDIENCE_SELECTION,
  COMMBOX_AUDIENCE_SELECT,
  COMMBOX_OPEN_ACTOR_SWITCH,
  PROP_CONTEXT_DIMENSION3,
  PROP_NEWS_PUBLISHER_ID,
  PROP_TRACK_ACTION,
  PROP_MODULES,
  PROP_ITEM_ID,
} from '../constants';
import { trackAction, trackEvent } from '../tracker';

type trackPinType = {
  pageId: string;
  focusType: string;
  itemId: string;
};
type trackHideType = {
  pageId: string;
  focusType: string;
  itemId: string;
};

type trackDeletedPostingType = {
  pageId: string;
  focusType: string;
  itemId: string;
  publicationState: string;
};

export const trackNewsFollowButtonAction = (hasFollowed: boolean) => {
  const event = hasFollowed
    ? ENTITY_PAGE_NEWS_FOLLOW
    : ENTITY_PAGE_NEWS_UNFOLLOW;
  trackEvent(event, {
    [event]: 1,
    [PROP_INTERACTION_TYPE]: hasFollowed
      ? 'entity_page_news_follow'
      : 'entity_page_news_unfollow',
  });
};

export const trackNewsArticleClickAction = (
  articleId: string,
  publisherId: string
) => {
  trackEvent(ENTITY_PAGE_NEWS_ARTICLE_VIEW, {
    [ENTITY_PAGE_NEWS_ARTICLE_VIEW]: 1,
    [PROP_CONTEXT_DIMENSION1]: 'news_ExternalArticleView',
    PropNewsProduct: 'is_not_klartext', // @todo: make dependant on entity page type
    [PROP_NEWS_PUBLISHER_ID]: publisherId,
    PropNewsArticleId: articleId,
  });
};

export const trackCommboxOpenAudienceSelection = () => {
  trackAction(COMMBOX_OPEN_AUDIENCE_SELECTION);
};

export const trackCommboxAudienceSelect = (id: string) => {
  trackAction(
    {
      PropActionOrigin: `commbox_audience_${id}`,
    },
    COMMBOX_AUDIENCE_SELECT
  );
};

export const trackCommboxOpenActorSwitch = () => {
  trackAction(COMMBOX_OPEN_ACTOR_SWITCH);
};

export const trackPinAction = ({ pageId, focusType, itemId }: trackPinType) => {
  trackAction({
    [PROP_MODULES]: 'News_EditMode',
    [PROP_TRACK_ACTION]: 'entity_page_article_pin_to_top',
    [PROP_CONTEXT_DIMENSION3]: 'entity_page_editor',
    [PROP_NEWS_PUBLISHER_ID]: pageId,
    [PROP_ITEM_ID]: `${focusType}_${itemId}`,
  });
};

export const trackUnpinAction = ({
  pageId,
  focusType,
  itemId,
}: trackPinType) => {
  trackAction({
    [PROP_MODULES]: 'News_EditMode',
    [PROP_TRACK_ACTION]: 'entity_page_article_unpin',
    [PROP_CONTEXT_DIMENSION3]: 'entity_page_editor',
    [PROP_NEWS_PUBLISHER_ID]: pageId,
    [PROP_ITEM_ID]: `${focusType}_${itemId}`,
  });
};

export const trackHideAction = ({
  pageId,
  focusType,
  itemId,
}: trackHideType) => {
  trackAction({
    [PROP_MODULES]: 'News_EditMode',
    [PROP_TRACK_ACTION]: 'entity_page_article_hide',
    [PROP_CONTEXT_DIMENSION3]: 'entity_page_editor',
    [PROP_NEWS_PUBLISHER_ID]: pageId,
    [PROP_ITEM_ID]: `${focusType}_${itemId}`,
  });
};

export const trackUnhideAction = ({
  pageId,
  focusType,
  itemId,
}: trackHideType) => {
  trackAction({
    [PROP_MODULES]: 'News_EditMode',
    [PROP_TRACK_ACTION]: 'entity_page_article_unhide',
    [PROP_CONTEXT_DIMENSION3]: 'entity_page_editor',
    [PROP_NEWS_PUBLISHER_ID]: pageId,
    [PROP_ITEM_ID]: `${focusType}_${itemId}`,
  });
};

export const trackDeletedPosting = ({
  focusType,
  pageId,
  itemId,
  publicationState,
}: trackDeletedPostingType) => [
  trackAction({
    [PROP_MODULES]: 'News_EditMode',
    [PROP_TRACK_ACTION]: `entity_page_article_delete_${publicationState.toLowerCase()}`,
    [PROP_CONTEXT_DIMENSION3]: 'entity_page_editor',
    [PROP_NEWS_PUBLISHER_ID]: pageId,
    [PROP_ITEM_ID]: `${focusType}_${itemId}`,
  }),
];

export const trackNewsPublicationFilter = (filter: string, pageId: string) => {
  trackAction({
    [PROP_MODULES]: 'News_EditMode',
    [PROP_TRACK_ACTION]: 'entity_page_article_filter',
    PropSearchListFilter: `entity_page_article_state_${filter.toLowerCase()}`,
    [PROP_CONTEXT_DIMENSION3]: 'entity_page_editor',
    [PROP_NEWS_PUBLISHER_ID]: pageId,
  });
};
