import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import type {
  ImageUploadCopy,
  ImageUploadOnPublishData,
} from '@xing-com/crate-companies-entity-pages-common';
import {
  ImageUpload,
  usePageContext,
  WarningMessageBox as WarningBox,
} from '@xing-com/crate-companies-entity-pages-common';
import { Headline } from '@xing-com/typography';

import { EntityPageOriginalLogoDocument } from '../../../graphql/queries/original-logo.gql-types';
import { CropSkeleton } from '../../crop-skeleton/crop-skeleton';
import * as Styled from './update-logo.styles';

type UpdateLogoProps = {
  isCover?: boolean;
  descriptionCopyKey?: string;
  aspectRatio?: number;
  imageType: string;
  imageUploadCopy?: ImageUploadCopy;
  onCropReady?: () => void;
  onPublish?: (data: ImageUploadOnPublishData) => void;
  onDragCrop?: () => void;
  onDiscard: () => void;
  onCancel: () => void;
};
export const UpdateLogo: FC<UpdateLogoProps> = ({
  isCover,
  descriptionCopyKey,
  aspectRatio,
  imageType,
  imageUploadCopy,
  onCropReady = () => undefined,
  onPublish = () => undefined,
  onDragCrop = () => undefined,
  onDiscard = () => undefined,
  onCancel = () => undefined,
}) => {
  const { pageContext } = usePageContext() ?? {};

  const { data, loading, error, refetch } = useQuery(
    EntityPageOriginalLogoDocument,
    {
      fetchPolicy: 'no-cache',
      skip: imageType === 'cover',
      variables: {
        id: pageContext?.pageSlug as string,
        dimensions: [{ width: 310, height: 310, reference: imageType }],
      },
    }
  );

  const entityPage =
    data?.entityPageEX?.__typename === 'EntityPage'
      ? data?.entityPageEX
      : undefined;

  const originalImage = entityPage?.originalLogoImage?.[0]?.url;

  if (loading) {
    return (
      <>
        <Headline size={'xxlarge'}>
          <Styled.UploadHeadline>
            <FormattedMessage id="EP_CHOOSE_SECTION" />
          </Styled.UploadHeadline>
        </Headline>
        <CropSkeleton isCover={isCover} />
      </>
    );
  }

  if (!originalImage || error) {
    return (
      <>
        <Headline size={'xxlarge'}>
          <Styled.UploadHeadline>
            <FormattedMessage id="EP_CHOOSE_SECTION" />
          </Styled.UploadHeadline>
        </Headline>
        <WarningBox
          headerText="EP_ERROR_HEADER"
          bodyText="EP_ERROR_BODY"
          buttonText="EP_ERROR_RELOAD_CTA"
          onClick={() => refetch()}
        />
      </>
    );
  }

  return (
    <ImageUpload
      descriptionCopyKey={descriptionCopyKey}
      onCropReady={onCropReady}
      onDragCrop={onDragCrop}
      onDiscard={onDiscard}
      defaultImageCrop={originalImage}
      onPublish={onPublish}
      aspectRatio={aspectRatio}
      onCancel={onCancel}
      imageUploadCopy={imageUploadCopy}
    />
  );
};
