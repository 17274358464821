import type { FC } from 'react';

import { SkeletonProfileImage, SkeletonBody } from '@xing-com/skeleton';

import * as Styled from './contacts.styles';

export const ContactsSkeleton: FC = () => (
  <Styled.SkeletonContactsWrapper>
    <Styled.SkeletonProfileImage>
      <SkeletonProfileImage />
    </Styled.SkeletonProfileImage>
    <Styled.SkeletonProfileInfo>
      <SkeletonBody amountRows={3} />
    </Styled.SkeletonProfileInfo>
  </Styled.SkeletonContactsWrapper>
);
