import * as React from 'react';

import * as Styled from './card.styles';
import type { CardProps } from './card.types';

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      autoposition,
      children,
      className,
      href,
      isFocusable = true,
      linkProps,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      noPadding,
      selected,
      to,
      variant = 'default',
      ...props
    },
    forwardedRef
  ): JSX.Element => (
    <Styled.Card
      $autoposition={autoposition}
      $maxHeight={maxHeight}
      $maxWidth={maxWidth}
      $minHeight={minHeight}
      $minWidth={minWidth}
      $noPadding={noPadding}
      $selected={selected}
      $variant={variant}
      className={className}
      data-xds="Card"
      ref={forwardedRef}
      {...props}
    >
      {(to || href) && (
        <Styled.CardLink
          tabIndex={isFocusable ? 0 : -1}
          {...(to && { to })}
          {...(href && { href })}
          {...linkProps}
        />
      )}
      <Styled.CardContent $noPadding={noPadding}>{children}</Styled.CardContent>
    </Styled.Card>
  )
);

Card.displayName = 'Card';
