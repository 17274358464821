import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';

type FollowProps = {
  isFollowing?: boolean;
  toggleFollow: () => void;
  loading?: boolean;
  className?: string;
  smallOnWide?: boolean;
};
export const Follow: FC<FollowProps> = ({
  isFollowing,
  toggleFollow,
  loading,
  className,
  smallOnWide,
}) => {
  const buttonText = isFollowing ? 'EP_FOLLOWING' : 'EP_FOLLOW';

  return (
    <Button
      data-testid={'followButton'}
      variant={isFollowing ? 'tertiary' : 'primary'}
      sizeWide={smallOnWide ? 'small' : undefined}
      size={'medium'}
      className={className}
      disabled={loading}
      onClick={(e) => {
        e.preventDefault();
        if (!loading) {
          toggleFollow();
        }
      }}
    >
      <FormattedMessage id={buttonText} defaultMessage={buttonText} />
    </Button>
  );
};

export default Follow;
