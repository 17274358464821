import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { IconInfo } from '@xing-com/icons';

import * as Styled from './page-created-banner.styles';

export const PageCreatedBanner: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Styled.InfoContainer data-testid="PAGE_CREATED_BANNER">
      <IconInfo height={24} width={24} />
      {/* @ts-expect-error FIXME: SC6 */}
      <Styled.Text small>
        {formatMessage({ id: 'EP_NEW_PAGE_CREATED_DRAFT' })}
      </Styled.Text>
    </Styled.InfoContainer>
  );
};
