import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  scale160,
  scale080,
  scale110,
  scale040,
  xdlColorTextSecondary,
  scale020,
} from '@xing-com/tokens';

export const BenefitsSection = styled.section`
  display: flex;
  flex-wrap: wrap;
`;

export const RateThisCompanyContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${scale160};
  padding: ${scale080} ${scale110};
  border-radius: ${scale020};

  & p {
    margin: 0;
  }

  @media ${mediaSmallOrTiny} {
    align-items: start;
    flex-direction: column;

    & p {
      margin-bottom: ${scale040};
    }
  }
`;

export const BenefitsCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% / 3);

  & > div:last-child {
    margin-left: ${scale040};
    margin-top: 3px;
  }

  p {
    color: ${xdlColorTextSecondary};
  }
`;

export const Title = styled.h1`
  margin-bottom: 0;
`;
