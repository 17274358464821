/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type EditMediaQueryVariables = Types.Exact<{
  id: Types.Scalars['SlugOrID']['input'];
  first: Types.Scalars['Int']['input'];
}>;

export type EditMediaQuery = {
  __typename?: 'Query';
  pagesAboutUs?:
    | {
        __typename?: 'AboutEntity';
        media?:
          | {
              __typename?: 'EntityMediaConnection';
              edges?: Array<{
                __typename?: 'EntityMediaEdge';
                node: {
                  __typename?: 'EntityMedia';
                  id: string;
                  description?: string | null;
                  media?:
                    | {
                        __typename?: 'EntityExternalVideo';
                        url?: string | null;
                        externalLink: string;
                      }
                    | {
                        __typename?: 'EntityVideo';
                        videoReferenceV2: string;
                        videoDetailsV2?: {
                          __typename?: 'VideoV2';
                          status: Types.VideoV2Status;
                        } | null;
                      }
                    | { __typename?: 'ScaledImage'; url?: string | null }
                    | null;
                };
              } | null> | null;
            }
          | {
              __typename?: 'EntityPageError';
              errorType: string;
              errorCode: number;
            }
          | null;
      }
    | { __typename?: 'EntityPageError' }
    | null;
};

export const EditMediaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EditMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pagesAboutUs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AboutEntity' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'first' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'includeDisabled' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'EntityPageError',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'errorType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'errorCode' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'EntityMediaConnection',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'EntityMedia',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'description',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'media',
                                                      },
                                                      arguments: [
                                                        {
                                                          kind: 'Argument',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'maxWidth',
                                                          },
                                                          value: {
                                                            kind: 'IntValue',
                                                            value: '880',
                                                          },
                                                        },
                                                        {
                                                          kind: 'Argument',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'maxHeight',
                                                          },
                                                          value: {
                                                            kind: 'IntValue',
                                                            value: '664',
                                                          },
                                                        },
                                                      ],
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'ScaledImage',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'url',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'EntityVideo',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'videoReferenceV2',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'videoDetailsV2',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'status',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'EntityExternalVideo',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'url',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'externalLink',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditMediaQuery, EditMediaQueryVariables>;
