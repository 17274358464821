import type React from 'react';
import type { FC } from 'react';
import { useState, useLayoutEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { ReactSortable } from 'react-sortablejs';

import {
  useDialogContext,
  getImageGender,
  usePageContext,
} from '@xing-com/crate-companies-entity-pages-common';
import {
  IconEdit,
  IconTrash,
  IconHamburgerMenu,
  IconHide,
  IconInfo,
  IconCross,
  IconTick,
  IconReturn,
} from '@xing-com/icons';
import { ProfileImage } from '@xing-com/profile-image';
import { Meta } from '@xing-com/typography';
import { useMediaListener, BREAKPOINTS } from '@xing-com/util';

import type { ContactUsersListForUpdateType } from '../../types';
import * as Styled from './contacts-list.style';

type ContactsListProps = {
  userList: ContactUsersListForUpdateType[];
  setContactUsersList: (userList: ContactUsersListForUpdateType[]) => void;
  setContactsTouched: (param: boolean) => void;
  setDisablePageActions: (param: boolean) => void;
};

type RenderEditContactProps = {
  userList: ContactUsersListForUpdateType[];
  contactId: string;
  imageSrc?: string;
  displayName: string;
  label?: string | null;
  setEditItemId: (param: string | null) => void;
  setContactUsersList: (param: ContactUsersListForUpdateType[]) => void;
  setDataChanged: (param: boolean) => void;
  setContactsTouched: (param: boolean) => void;
  setDisablePageActions: (param: boolean) => void;
};

type ActionButtonsProps = {
  pageName: string;
  isDefault?: boolean;
  isEnabled?: boolean | null;
  canBeDisabled?: boolean;
  disableItem: () => void;
  disableAllActions?: boolean;
  setEditItem: () => void;
  deleteItem: () => void;
  deleted?: boolean | null;
  undoDelete: () => void;
  showDragAndDrop?: boolean;
  disableDelete?: boolean;
  id?: string;
};
const ActionButtons = ({
  pageName,
  isDefault,
  isEnabled,
  canBeDisabled,
  disableItem,
  disableAllActions,
  disableDelete,
  setEditItem,
  deleteItem,
  deleted,
  undoDelete,
  showDragAndDrop,
}: ActionButtonsProps) =>
  deleted ? (
    <Styled.DeletedButton
      data-cy="RESTORE_CONTACT_BUTTON"
      size={'small'}
      icon={IconReturn}
      onClick={() => undoDelete()}
    >
      <FormattedMessage id="EP_RESTORE_CTA" defaultMessage="EP_RESTORE_CTA" />
    </Styled.DeletedButton>
  ) : (
    <>
      <Styled.DefaultItemActionButton
        disabled={!isEnabled || disableAllActions}
        data-cy="EDIT_CONTACT_LABEL_BUTTON"
        icon={IconEdit}
        onClick={() => setEditItem()} // eslint-disable-line
        size={'small'}
        variant={'tertiary'}
        aria-label="Edit"
      />
      {isDefault ? (
        <Styled.DefaultUserButton
          // @ts-expect-error FIXME: SC6
          activeEnableButton={isEnabled}
          icon={IconHide}
          disabled={!canBeDisabled || disableAllActions}
          size={'small'}
          variant={'tertiary'}
          onClick={() => disableItem()} // eslint-disable-line
          aria-label="Hide"
        />
      ) : (
        <Styled.DefaultItemActionButton
          data-cy={`DELETE_CONTACT_BUTTON`}
          data-testid={`DELETE_CONTACT_BUTTON_${pageName}`}
          icon={IconTrash}
          disabled={disableAllActions || disableDelete}
          size={'small'}
          variant={'tertiary'}
          onClick={() => deleteItem()}
          aria-label="Trash"
        />
      )}
      {showDragAndDrop && (
        <Styled.DragButton
          // @ts-expect-error FIXME: SC6
          name={disableAllActions ? '' : 'dragButton'}
          icon={IconHamburgerMenu}
          disabled={disableAllActions}
          size={'small'}
          aria-label="Hamburger"
        />
      )}
    </>
  );

const RenderEditContact = ({
  contactId,
  imageSrc,
  displayName,
  label,
  setEditItemId,
  userList,
  setContactUsersList,
  setDataChanged,
  setContactsTouched,
  setDisablePageActions,
}: RenderEditContactProps) => {
  const [userLabel, setUserLabel] = useState<string>(label || '');
  const intl = useIntl();

  const changeLabel = () => {
    setContactUsersList(
      userList?.map((user) => {
        if (user.contactId === contactId) {
          user.label = userLabel?.trim().replace(/\s\s+/g, ' ');
          return user;
        }

        return user;
      })
    );
    setContactsTouched(true);
    setDataChanged(true);
    setEditItemId(null);
    setDisablePageActions(false);
  };

  return (
    <>
      <Styled.EditWrapper>
        <ProfileImage
          size="xxsmall"
          src={imageSrc}
          profileName={'user profile image'}
        />
        <Styled.EditText fontWeight="bold" size={'medium'}>
          {displayName}
        </Styled.EditText>
      </Styled.EditWrapper>
      <Styled.InputGuidance>
        <Meta size="xsmall">
          <FormattedMessage
            id={'EP_EDIT_CONTACTS_DESCRIPTION_INPUT_FIELD'}
            defaultMessage={'EP_EDIT_CONTACTS_DESCRIPTION_INPUT_FIELD'}
          />
        </Meta>
      </Styled.InputGuidance>
      <div>
        <Styled.FormAndActionsWrapper>
          <Styled.FormField
            size="medium"
            value={userLabel}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              e.target.value.length > 50 ? null : setUserLabel(e.target.value)
            }
            label={intl.formatMessage({
              id: 'EP_CONTACT_EDIT_LABEL',
              defaultMessage: 'EP_CONTACT_EDIT_LABEL',
            })}
            helperText={intl.formatMessage(
              {
                id: 'EP_INPUT_CHARS_LEFT',
                defaultMessage: 'EP_INPUT_CHARS_LEFT',
              },
              { charactersNumber: 50 - userLabel.length }
            )}
          />
          <Styled.EditActionsWrapper>
            <Styled.DefaultItemActionButton
              data-cy="ICON_TICK"
              icon={IconTick}
              onClick={() => changeLabel()}
              size={'small'}
              variant={'primary'}
              aria-label="Tick"
            />
            <Styled.DefaultItemActionButton
              data-cy="ICON_CROSS"
              icon={IconCross}
              size={'small'}
              variant={'tertiary'}
              onClick={() => {
                setEditItemId(null);
                setDisablePageActions(false);
              }}
              aria-label="Cross"
            />
          </Styled.EditActionsWrapper>
        </Styled.FormAndActionsWrapper>
      </div>
    </>
  );
};

const ContactsList: FC<ContactsListProps> = ({
  userList,
  setContactUsersList,
  setContactsTouched,
  setDisablePageActions,
}) => {
  const { setDataChanged } = useDialogContext();
  const { pageContext } = usePageContext() ?? {};
  const [editItemId, setEditItemId] = useState<string | null>(null);
  const [itemChoosed, setItemChoosed] = useState(false);

  const breakpoint = useMediaListener();
  const isSmallScreen = breakpoint === BREAKPOINTS.handheld;

  const disableItem = () => {
    setContactUsersList(
      userList?.map((user) => {
        if (user.default) {
          user.enabled = !user.enabled;
          return user;
        }

        return user;
      })
    );
    setContactsTouched(true);
    setDataChanged(true);
  };

  useLayoutEffect(() => {
    setItemChoosed(!itemChoosed);
  }, []);

  return (
    <>
      {/** *** Hack in order to display svgs on ProfileInfo images on re-order components*****/}
      <Styled.LoadableSVGs>
        {userList?.map((user) => (
          <ProfileImage
            key={user.id}
            size="xxsmall"
            src={user.profileImage?.[0]?.url}
            type={getImageGender(user.gender)}
            profileName={'user profile image'}
          />
        ))}
      </Styled.LoadableSVGs>
      {/** *** Hack in order to display svgs on ProfileInfo images on re-order components*****/}
      <Styled.ListContainer>
        <Styled.SortableWrapper>
          <ReactSortable
            list={userList}
            setList={setContactUsersList}
            handle={"[name*='dragButton']"}
            animation={200}
            ghostClass="ghost"
            forceFallback={true}
            fallbackClass="sortableFallback"
            onUpdate={() => {
              setDataChanged(true);
              setContactsTouched(true);
            }}
            onChoose={() => {
              document.body.style.cursor = 'grabbing';
              setItemChoosed(true);
            }}
            onUnchoose={() => {
              document.body.style.cursor = 'default';
              setItemChoosed(false);
            }}
          >
            {userList?.map((user) => {
              const Tag = user.enabled && !user.deleted ? 'div' : 'span';
              const profileImageSrc = user.profileImage?.[0]?.url;
              return (
                <Styled.Tag
                  as={Tag}
                  key={user.contactId}
                  isBeingEdited={
                    !!(editItemId && editItemId === user.contactId)
                  }
                  isInactive={!!(editItemId && editItemId !== user.contactId)}
                  isDeleted={!!user.deleted}
                >
                  <>
                    {editItemId && editItemId === user.contactId ? (
                      <RenderEditContact
                        setDataChanged={setDataChanged}
                        contactId={user.contactId}
                        imageSrc={profileImageSrc}
                        displayName={user.displayName}
                        label={user.label}
                        setEditItemId={setEditItemId}
                        setDisablePageActions={setDisablePageActions}
                        userList={userList}
                        setContactUsersList={setContactUsersList}
                        setContactsTouched={setContactsTouched}
                      />
                    ) : (
                      <Styled.ProfileInfoActionsWrapper>
                        <Styled.ContactsProfileInfo
                          alignment={isSmallScreen ? 'vertical' : 'horizontal'}
                          profileUrl={`/profile/${user.pageName}`}
                          profileImage={{
                            size: isSmallScreen ? 'small' : 'medium',
                            src: profileImageSrc,
                            profileName: 'user profile image',
                            type: getImageGender(user.gender),
                            // @ts-expect-error FIXME: SC6
                            key: user.id,
                          }}
                          headline={{
                            size: 'medium',
                            children: user.displayName,
                            as: 'strong',
                          }}
                          isDisabled={!user.enabled}
                          itemChoosed={itemChoosed}
                          textBody={[
                            {
                              size: 'medium',
                              children: user.occupations?.[0]?.subline,
                            },
                          ]}
                          textMeta={[
                            {
                              size: 'medium',
                              children: user.label,
                            },
                          ]}
                        />
                        <Styled.ItemsActionWrapper>
                          <ActionButtons
                            id={user.id}
                            pageName={user.pageName}
                            isDefault={user.default}
                            canBeDisabled={userList.length > 1}
                            disableAllActions={!!editItemId}
                            disableItem={disableItem}
                            disableDelete={
                              userList.filter((user) => !user.deleted)
                                .length === 1
                            }
                            isEnabled={user.enabled}
                            setEditItem={() => {
                              user.contactId && setEditItemId(user.contactId);
                              setDisablePageActions(true);
                            }}
                            deleteItem={() => {
                              setDataChanged(true);
                              setContactsTouched(true);
                              setContactUsersList(
                                userList.map((mappedUser) => {
                                  if (mappedUser.contactId === user.contactId) {
                                    mappedUser.deleted = true;
                                  }
                                  return mappedUser;
                                })
                              );
                            }}
                            deleted={user.deleted}
                            undoDelete={() => {
                              setContactUsersList(
                                userList.map((mappedUser) => {
                                  if (mappedUser.contactId === user.contactId) {
                                    mappedUser.deleted = false;
                                  } else if (
                                    pageContext?.contractType === 'FREE'
                                  ) {
                                    mappedUser.deleted = true;
                                  }
                                  return mappedUser;
                                })
                              );
                            }}
                            showDragAndDrop={userList.length > 1}
                          />
                        </Styled.ItemsActionWrapper>
                      </Styled.ProfileInfoActionsWrapper>
                    )}
                    {(user.default || user.deleted) && (
                      <Styled.OwnerDisclaimerWrapper isDisabled={!user.enabled}>
                        <IconInfo width={16} height={16} />
                        <Styled.OwnerMeta size={'small'}>
                          {user.deleted ? (
                            <FormattedMessage
                              id={'EP_EDIT_CONTACTS_DELETED'}
                              defaultMessage={'EP_EDIT_CONTACTS_DELETED'}
                            />
                          ) : (
                            <span>
                              <FormattedMessage
                                id={'EP_CONTACTS_CHANGE_OWNER_INFO_V2'}
                                defaultMessage={
                                  'EP_CONTACTS_CHANGE_OWNER_INFO_V2'
                                }
                                values={{
                                  a: () => (
                                    <a
                                      href={'mailto:customer.support@xing.com'}
                                    >
                                      customer.support@xing.com
                                    </a>
                                  ),
                                }}
                              />
                            </span>
                          )}
                        </Styled.OwnerMeta>
                      </Styled.OwnerDisclaimerWrapper>
                    )}
                  </>
                </Styled.Tag>
              );
            })}
          </ReactSortable>
        </Styled.SortableWrapper>
      </Styled.ListContainer>
    </>
  );
};

export default ContactsList;
