/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type HeaderContent_EntityPageCompanyHeaderContent_Fragment = {
  __typename: 'EntityPageCompanyHeaderContent';
  followers?: { __typename?: 'FollowersConnection'; total: number } | null;
  company?: {
    __typename?: 'Company';
    employeesCount?: number | null;
    companySizeRange?: {
      __typename?: 'CompanySizeRange';
      min: number;
      max?: number | null;
    } | null;
    industry?: { __typename?: 'Industry'; id: string } | null;
    kununuData?: {
      __typename?: 'CompanyKununuData';
      ratingCount?: number | null;
      ratingAverage?: number | null;
      companyProfileUrl?: string | null;
      employerRatingsCount?: number | null;
    } | null;
  } | null;
};

export type HeaderContent_EntityPageFallbackHeaderContent_Fragment = {
  __typename: 'EntityPageFallbackHeaderContent';
};

export type HeaderContent_EntityPageGroupPageHeaderContent_Fragment = {
  __typename: 'EntityPageGroupPageHeaderContent';
};

export type HeaderContent_EntityPagePublisherHeaderContent_Fragment = {
  __typename: 'EntityPagePublisherHeaderContent';
  followers?: { __typename?: 'FollowersConnection'; total: number } | null;
};

export type HeaderContent_EntityPageTopicPageHeaderContent_Fragment = {
  __typename: 'EntityPageTopicPageHeaderContent';
  followers?: { __typename?: 'FollowersConnection'; total: number } | null;
};

export type HeaderContentFragment =
  | HeaderContent_EntityPageCompanyHeaderContent_Fragment
  | HeaderContent_EntityPageFallbackHeaderContent_Fragment
  | HeaderContent_EntityPageGroupPageHeaderContent_Fragment
  | HeaderContent_EntityPagePublisherHeaderContent_Fragment
  | HeaderContent_EntityPageTopicPageHeaderContent_Fragment;

export type SocialProofFragment = {
  __typename?: 'EntityPageSocialProofConnection';
  proofType?: Types.EntityPageSocialProofType | null;
  total: number;
  edges: Array<{
    __typename?: 'EntityPageSocialProofEdge';
    node?: {
      __typename?: 'EntityPageSocialProof';
      xingId?: {
        __typename?: 'XingId';
        id: string;
        pageName: string;
        displayName: string;
        gender?: Types.Gender | null;
        profileImage?: Array<{
          __typename?: 'ProfileImage';
          url: string;
        } | null> | null;
        clickReasonProfileUrl?: {
          __typename?: 'ClickReason';
          profileUrl: string;
        } | null;
      } | null;
    } | null;
  } | null>;
};

export type EntityPageModulesFragment = {
  __typename?: 'EntityPageModules';
  collection: Array<
    | { __typename: 'EntityPageAboutUsModule'; type: string }
    | {
        __typename: 'EntityPageGenericModule';
        globalId?: string | null;
        properties?: any | null;
        type: string;
      }
    | {
        __typename: 'EntityPageHeaderModule';
        properties?: any | null;
        globalId?: string | null;
        type: string;
        content?:
          | {
              __typename: 'EntityPageCompanyHeaderContent';
              followers?: {
                __typename?: 'FollowersConnection';
                total: number;
              } | null;
              company?: {
                __typename?: 'Company';
                employeesCount?: number | null;
                companySizeRange?: {
                  __typename?: 'CompanySizeRange';
                  min: number;
                  max?: number | null;
                } | null;
                industry?: { __typename?: 'Industry'; id: string } | null;
                kununuData?: {
                  __typename?: 'CompanyKununuData';
                  ratingCount?: number | null;
                  ratingAverage?: number | null;
                  companyProfileUrl?: string | null;
                  employerRatingsCount?: number | null;
                } | null;
              } | null;
            }
          | { __typename: 'EntityPageFallbackHeaderContent' }
          | { __typename: 'EntityPageGroupPageHeaderContent' }
          | {
              __typename: 'EntityPagePublisherHeaderContent';
              followers?: {
                __typename?: 'FollowersConnection';
                total: number;
              } | null;
            }
          | {
              __typename: 'EntityPageTopicPageHeaderContent';
              followers?: {
                __typename?: 'FollowersConnection';
                total: number;
              } | null;
            }
          | null;
      }
    | null
  >;
};

export type EntityPageQueryVariables = Types.Exact<{
  id: Types.Scalars['SlugOrID']['input'];
  socialProofClickReasonsKey: Types.ClickReasonsEnum;
}>;

export type EntityPageQuery = {
  __typename?: 'Query';
  entityPageEX?:
    | {
        __typename?: 'EntityPage';
        id: string;
        globalId: string;
        title: string;
        slug: string;
        slogan?: string | null;
        focusType?: Types.EntityPageFocusType | null;
        publicationStatus: Types.EntityPagePublicationStatus;
        visibility?: Types.EntityPageVisibility | null;
        contract: {
          __typename?: 'EntityPageContract';
          type: Types.EntityPageContractType;
        };
        context?: {
          __typename?: 'EntityPageContext';
          companyId?: string | null;
          cppId?: string | null;
        } | null;
        properties: {
          __typename?: 'EntityPageProperties';
          parentPage: Types.EntityPageParentPage;
          condensedHeader: boolean;
        };
        logoImage?: Array<{
          __typename?: 'ScaledImage';
          reference?: string | null;
          url?: string | null;
        }> | null;
        userPageContext?: {
          __typename?: 'EntityPageUserContext';
          userInteractions?:
            | { __typename: 'EntityPageUserInteractionFallback' }
            | {
                __typename: 'EntityPageUserInteractionFollow';
                followState?: {
                  __typename?: 'FollowContext';
                  isFollowing: boolean;
                } | null;
              }
            | { __typename: 'EntityPageUserInteractionJoin' }
            | null;
          permissions: {
            __typename?: 'EntityPagePermissions';
            canEdit: boolean;
            canAdmin: boolean;
          };
          socialProof?: {
            __typename?: 'EntityPageSocialProofConnection';
            proofType?: Types.EntityPageSocialProofType | null;
            total: number;
            edges: Array<{
              __typename?: 'EntityPageSocialProofEdge';
              node?: {
                __typename?: 'EntityPageSocialProof';
                xingId?: {
                  __typename?: 'XingId';
                  id: string;
                  pageName: string;
                  displayName: string;
                  gender?: Types.Gender | null;
                  profileImage?: Array<{
                    __typename?: 'ProfileImage';
                    url: string;
                  } | null> | null;
                  clickReasonProfileUrl?: {
                    __typename?: 'ClickReason';
                    profileUrl: string;
                  } | null;
                } | null;
              } | null;
            } | null>;
          } | null;
        } | null;
        links: { __typename?: 'EntityPageLinks'; self: string };
        modules?: {
          __typename?: 'EntityPageModules';
          collection: Array<
            | { __typename: 'EntityPageAboutUsModule'; type: string }
            | {
                __typename: 'EntityPageGenericModule';
                globalId?: string | null;
                properties?: any | null;
                type: string;
              }
            | {
                __typename: 'EntityPageHeaderModule';
                properties?: any | null;
                globalId?: string | null;
                type: string;
                content?:
                  | {
                      __typename: 'EntityPageCompanyHeaderContent';
                      followers?: {
                        __typename?: 'FollowersConnection';
                        total: number;
                      } | null;
                      company?: {
                        __typename?: 'Company';
                        employeesCount?: number | null;
                        companySizeRange?: {
                          __typename?: 'CompanySizeRange';
                          min: number;
                          max?: number | null;
                        } | null;
                        industry?: {
                          __typename?: 'Industry';
                          id: string;
                        } | null;
                        kununuData?: {
                          __typename?: 'CompanyKununuData';
                          ratingCount?: number | null;
                          ratingAverage?: number | null;
                          companyProfileUrl?: string | null;
                          employerRatingsCount?: number | null;
                        } | null;
                      } | null;
                    }
                  | { __typename: 'EntityPageFallbackHeaderContent' }
                  | { __typename: 'EntityPageGroupPageHeaderContent' }
                  | {
                      __typename: 'EntityPagePublisherHeaderContent';
                      followers?: {
                        __typename?: 'FollowersConnection';
                        total: number;
                      } | null;
                    }
                  | {
                      __typename: 'EntityPageTopicPageHeaderContent';
                      followers?: {
                        __typename?: 'FollowersConnection';
                        total: number;
                      } | null;
                    }
                  | null;
              }
            | null
          >;
        } | null;
      }
    | { __typename?: 'EntityPageError'; errorType: string; errorCode: number }
    | { __typename?: 'EntityPageMoved'; slug: string }
    | null;
};

export const SocialProofFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialProof' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityPageSocialProofConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'proofType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'xingId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gender' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profileImage' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'SQUARE_96',
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'clickReasonProfileUrl',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {
                                    kind: 'Name',
                                    value: 'clickReasonId',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'socialProofClickReasonsKey',
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profileUrl' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SocialProofFragment, unknown>;
export const HeaderContentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeaderContent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityPageHeaderContent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EntityPageTopicPageHeaderContent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EntityPagePublisherHeaderContent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EntityPageCompanyHeaderContent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'employeesCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companySizeRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'min' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'max' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kununuData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ratingCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ratingAverage' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'companyProfileUrl',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'employerRatingsCount',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HeaderContentFragment, unknown>;
export const EntityPageModulesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityPageModules' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityPageModules' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageHeaderModule' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'properties' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'HeaderContent' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageGenericModule' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'properties' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeaderContent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityPageHeaderContent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EntityPageTopicPageHeaderContent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EntityPagePublisherHeaderContent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EntityPageCompanyHeaderContent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'employeesCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companySizeRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'min' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'max' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kununuData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ratingCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ratingAverage' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'companyProfileUrl',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'employerRatingsCount',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntityPageModulesFragment, unknown>;
export const EntityPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EntityPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'socialProofClickReasonsKey' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ClickReasonsEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityPageEX' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slogan' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contract' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'context' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cppId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'properties' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentPage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'condensedHeader' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logoImage' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'dimensions' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '320' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '320' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'reference',
                                      },
                                      value: {
                                        kind: 'StringValue',
                                        value: 'medium',
                                        block: false,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reference' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'focusType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicationStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'visibility' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPageContext' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userInteractions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'EntityPageUserInteractionFollow',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'followState',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'isFollowing',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '__typename' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'permissions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canEdit' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canAdmin' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'socialProof' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'first' },
                                  value: { kind: 'IntValue', value: '3' },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'SocialProof',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'self' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modules' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'EntityPageModules',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageMoved' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeaderContent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityPageHeaderContent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EntityPageTopicPageHeaderContent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EntityPagePublisherHeaderContent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EntityPageCompanyHeaderContent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'employeesCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companySizeRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'min' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'max' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kununuData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ratingCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ratingAverage' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'companyProfileUrl',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'employerRatingsCount',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialProof' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityPageSocialProofConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'proofType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'xingId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gender' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profileImage' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'SQUARE_96',
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'clickReasonProfileUrl',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {
                                    kind: 'Name',
                                    value: 'clickReasonId',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'socialProofClickReasonsKey',
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profileUrl' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityPageModules' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityPageModules' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageHeaderModule' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'properties' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'HeaderContent' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageGenericModule' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'properties' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntityPageQuery, EntityPageQueryVariables>;
