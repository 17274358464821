/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconImage"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21 3v18H3V3zm-2 7.319-7.767 7.495-2.264-2.184L5.476 19H19zM19 5H5v11.679l3.969-3.828 2.264 2.184L19 7.54zm-8 2a2 2 0 1 1 0 4 2 2 0 0 1 0-4"
    />
  </svg>
);
export default IconImage;
