import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { PopOver as InnerPopOver } from '@xing-com/pop-over';
import { spaceM, spaceS, xdlColorBackground } from '@xing-com/tokens';

export const Container = styled.div`
  display: inline;
  margin-left: ${spaceM};
  position: relative;
  bottom: ${spaceS};
`;

export const IconWrapper = styled(TextButton)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-color: ${xdlColorBackground};
  border-radius: 50%;

  &:hover {
    background-color: ${xdlColorBackground};
  }
`;

export const PopOverCloseCTA = styled(TextButton)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const NoticeWrapper = styled.span`
  display: block;
  margin-top: ${spaceS};
`;

export const PopOver = styled(InnerPopOver)`
  max-width: 220px;
`;
