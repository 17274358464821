import styled from 'styled-components';

import { Button as DefaultButton } from '@xing-com/button';
import { List as DefaultList } from '@xing-com/list';
import { xdlColorSelected, xdlColorTextInvert } from '@xing-com/tokens';

export const Button = styled(DefaultButton)<{ $active: boolean }>`
  ${({ $active }) =>
    $active &&
    `
  color: ${xdlColorTextInvert};
  &::after {
    background-color: ${xdlColorSelected};
  }
  &.active:hover,
  &.active:focus {
    color: ${xdlColorTextInvert};
  }

`}
`;
export const List = styled(DefaultList)`
  max-height: 300px;
  min-width: 225px;
  max-width: 300px;
  overflow-y: auto;
`;
