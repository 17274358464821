import { useMutation, type FetchPolicy } from '@apollo/client';
import { useState, useEffect, useRef } from 'react';
import type { FC, ReactElement } from 'react';

import * as Types from '@xing-com/crate-common-graphql-types';
import { usePageContext } from '@xing-com/crate-companies-entity-pages-common';
import {
  trackCommboxAudienceSelect,
  trackCommboxOpenActorSwitch,
  trackCommboxOpenAudienceSelection,
} from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import type { CommboxProps } from '@xing-com/crate-companies-entity-pages-news-commbox';
import {
  ATTACHMENT_TYPES,
  Commbox,
  COMMBOX_ACTOR_TYPES,
} from '@xing-com/crate-companies-entity-pages-news-commbox';

import {
  COMMBOX_SELECTABLE_AUDIENCES,
  PAID_PLUS,
  PAID,
} from '../../config/constants';
import type { EntityPageFeedCollectionFragment } from '../../graphql/fragments/entity-page-feed-collection-fragment.gql-types';
import { CreateTextPostingDocument } from '../../graphql/mutations/create-posting-mutation.gql-types';
import type { CreateTextPostingMutationVariables } from '../../graphql/mutations/create-posting-mutation.gql-types';
import type { NewsCommboxUpdatePostingMutationVariables } from '../../graphql/mutations/update-posting-mutation.gql-types';
import { NewsCommboxUpdatePostingDocument } from '../../graphql/mutations/update-posting-mutation.gql-types';
import type { EntityPageFeedQueryVariables } from '../../graphql/queries/feed-query.gql-types';
import { EntityPageFeedDocument } from '../../graphql/queries/feed-query.gql-types';
import { mapAudienceTrackingValue } from '../../utils';
import { CommboxOmTrigger } from '../commbox-om-trigger/commbox-om-trigger';

const COMMBOX_SUCESS_DIALOG_VISIBILITY_TIMEOUT = 7000;

type NewsCommboxProps = {
  canEditLinkPreview: boolean;
  children: ReactElement | ((onClick: () => void) => JSX.Element);
  feedQueryVars: EntityPageFeedQueryVariables;
  clearPosting?: () => void;
  defaultPostVisibility?: Types.PostingsVisibility;
  refetch?: () => void;
  selectedPosting?: EntityPageFeedCollectionFragment['object'] | null;
  setLazyLoadingIsAllowed?: (allowed: boolean) => void;
  setSelectedPosting?: (
    posting: EntityPageFeedCollectionFragment['object'] | null
  ) => void;
};

export const NewsCommbox: FC<NewsCommboxProps> = ({
  canEditLinkPreview,
  children,
  clearPosting,
  defaultPostVisibility,
  feedQueryVars,
  refetch,
  selectedPosting,
  setLazyLoadingIsAllowed,
  setSelectedPosting,
}) => {
  const { pageContext } = usePageContext() ?? {};
  const [isUploadModalTriggered, setUploadModalTrigger] = useState(false);
  const [postWasSent, setPostWasSent] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isAllowedToPost, setIsAllowedPost] = useState(true);
  const [isVideoUploadOpen, setIsVideoUploadOpen] = useState(false);
  // const [attachmentType, setAttachmentType] = useState(ATTACHMENT_TYPES.EMPTY);
  const [attachmentType, setAttachmentType] = useState('EMPTY');
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [successPostHeadLine, setSuccessPostHeadLine] = useState(
    'COMMBOX_SUCCESS_HEADLINE'
  );
  const [successPostHeadLineValues, setSuccessPostHeadLineValues] = useState(
    {}
  );
  const [successPostDescription, setSuccessPostDescription] = useState(
    'COMMBOX_SUCCESS_DESC'
  );
  const [successPostDescriptionValues, setSuccessPostDescriptionValues] =
    useState({});

  const isPostingSelected =
    selectedPosting !== null && selectedPosting !== undefined;

  const feedQuery = {
    query: EntityPageFeedDocument,
    variables: { ...feedQueryVars },
    fetchPolicy: 'network-only' as FetchPolicy,
  };
  const { contractType, focusType, companyId, globalId, logo, pageTitle } =
    pageContext ?? {};
  const isPaidPage = contractType === PAID_PLUS || contractType === PAID;
  const isNewsPage = (type: Types.EntityPageFocusType) =>
    type === 'TOPIC_PAGE' || type === 'PUBLISHER' || type === 'INDUSTRY_PAGE';

  const [
    postingsCreatePosting,
    { loading: isCreatingPosting, error: createPostingError },
  ] = useMutation(CreateTextPostingDocument, {
    onCompleted: (data) => {
      if (data.postingsCreatePosting && !data.postingsCreatePosting.error) {
        setShowSuccessMessage(true);
        setPostWasSent(true);
        setLazyLoadingIsAllowed && setLazyLoadingIsAllowed(true);
        if (clearPosting) clearPosting();
        if (refetch) refetch();
        return data;
      }
      return null;
    },
    onError: () => setIsAllowedPost(true),
    refetchQueries: () => [feedQuery],
  });

  const [
    postingUpdatePosting,
    { loading: isUpdatingPosting, error: updatePostingError },
  ] = useMutation(NewsCommboxUpdatePostingDocument, {
    onCompleted: (data) => {
      if (data.postingsUpdatePosting && !data.postingsUpdatePosting.error) {
        setShowSuccessMessage(true);
        setPostWasSent(true);
        if (clearPosting) clearPosting();
        if (refetch) refetch();
        return data;
      }
      return null;
    },
    onError: () => setIsAllowedPost(true),
    refetchQueries: () => [feedQuery],
  });

  // @ts-expect-error TODO: fix this type
  const setBannerWhenScheduling = (publishAt) => {
    let successMessageValues = {};
    let successMessage = 'COMMBOX_SUCCESS_DESC';
    if (publishAt) {
      setSuccessPostHeadLine('COMMBOX_SUCCESS_HEADLINE');
      setSuccessPostHeadLineValues({});
      successMessage = 'COMMBOX_SUCCESS_SCHEDULED_DESC';
      const publishedDate = new Date(publishAt);
      successMessageValues = {
        date: `${publishedDate.getDate()}/${
          publishedDate.getMonth() + 1
        }/${publishedDate.getFullYear()}`,
        time: `${publishedDate.getHours()}:${
          publishedDate.getMinutes() < 10
            ? `0${publishedDate.getMinutes()}`
            : publishedDate.getMinutes()
        }`,
      };
      setSuccessPostDescription(successMessage);
      setSuccessPostDescriptionValues(successMessageValues);
    }
  };

  const createPosting = async ({
    comment,
    commentArticleV1,
    actorGlobalId,
    images = null,
    links = null,
    publishAt = null,
    publicationStrategy = Types.PostingPublicationStrategy.Now,
    videos = null,
    visibility = defaultPostVisibility,
  }: CreateTextPostingMutationVariables) => {
    setBannerWhenScheduling(publishAt);

    const { data } = await postingsCreatePosting({
      variables: {
        actorGlobalId,
        comment: comment && comment.length ? comment : '',
        commentArticleV1,
        images,
        links,
        publishAt,
        publicationStrategy,
        videos,
        visibility,
      },
    });

    return {
      error: data?.postingsCreatePosting?.error,
      success: data?.postingsCreatePosting?.success,
    };
  };
  const updatePosting = async ({
    id,
    commentArticleV1,
    publishAt,
  }: NewsCommboxUpdatePostingMutationVariables) => {
    setBannerWhenScheduling(publishAt);

    const { data } = await postingUpdatePosting({
      variables: {
        id,
        commentArticleV1,
        publishAt,
      },
    });

    return {
      error: data?.postingsUpdatePosting?.error,
      success: data?.postingsUpdatePosting?.success,
    };
  };

  const actor = {
    logo: logo as string,
    actorName: pageTitle,
    globalId,
    type: COMMBOX_ACTOR_TYPES.PAGE,
    features: [ATTACHMENT_TYPES.IMAGE, ATTACHMENT_TYPES.VIDEO],
  };

  useEffect(() => {
    if (postWasSent) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      timeoutRef.current = setTimeout(() => {
        setShowSuccessMessage(false);
        setSuccessPostDescription('COMMBOX_SUCCESS_DESC');
        setSuccessPostDescriptionValues({});
        timeoutRef.current = null;
      }, COMMBOX_SUCESS_DIALOG_VISIBILITY_TIMEOUT);
      setPostWasSent(false);
    }
  }, [postWasSent]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const commboxSharedProps: CommboxProps = {
    attachmentType,
    canEditLinkPreview,
    // @ts-expect-error Commbox will be removed in Q2
    globalId,
    isAllowedToPost,
    isUploadModalTriggered,
    isVideoUploadOpen,
    setAttachmentType,
    setIsAllowedPost,
    setIsVideoUploadOpen,
    setShowSuccessMessage,
    setUploadModalTrigger,
    showSuccessMessage,
    successPostDescription,
    successPostDescriptionValues,
    successPostHeadLine,
    successPostHeadLineValues,
    application: 'ENTITY_PAGES',
    onOpenAudienceSelection: () => trackCommboxOpenAudienceSelection(),
    onOpenActorSwitch: () => trackCommboxOpenActorSwitch(),
    // @ts-expect-error Commbox will be removed in Q2
    onSelectAudience: (id: 'PUBLIC' | 'PRIVATE') =>
      trackCommboxAudienceSelect(mapAudienceTrackingValue(id)),
    onClose: () => {
      if (clearPosting) clearPosting();
      setIsVideoUploadOpen(false);
    },
    pageId: globalId,
    // @ts-expect-error Commbox will be removed in Q2
    actors: [actor],
    // @ts-expect-error Commbox will be removed in Q2
    selectableAudiences: COMMBOX_SELECTABLE_AUDIENCES,
    successMessage: {
      description: successPostDescription,
      descriptionValues: successPostDescriptionValues,
      headline: successPostHeadLine,
      headlineValues: successPostHeadLineValues,
      setShowSuccessMessage,
      showSuccessMessage,
    },
    featurePermissions: {
      enableMentions: true,
      enableVideo: true,
      enableScheduling: isPaidPage,
      enablePolls: false,
      enableMultiImage: false,
      // @ts-expect-error this will be fixed once entityPage context it's propperly done
      enableSchedulingLock: Boolean(companyId) || isNewsPage(focusType),
    },
  };

  return (
    <>
      {isPostingSelected ? (
        <Commbox
          {...commboxSharedProps}
          posting={selectedPosting}
          tagManagerId="btn_postnews"
          createPostingError={updatePostingError}
          isCreatingPosting={isUpdatingPosting}
          postMutation={updatePosting}
        >
          {children ||
            ((onClick: () => void) => (
              <CommboxOmTrigger
                onClick={() => {
                  setSelectedPosting && setSelectedPosting(null);
                  onClick();
                }}
              />
            ))}
        </Commbox>
      ) : (
        <Commbox
          {...commboxSharedProps}
          tagManagerId="btn_postnews"
          createPostingError={createPostingError}
          isCreatingPosting={isCreatingPosting}
          postMutation={createPosting}
        >
          {children ||
            ((onClick: () => void) => (
              <CommboxOmTrigger
                onClick={() => {
                  setSelectedPosting && setSelectedPosting(null);
                  onClick();
                }}
              />
            ))}
        </Commbox>
      )}
    </>
  );
};
