import type { FC } from 'react';

import {
  useEditContext,
  usePageContext,
} from '@xing-com/crate-companies-entity-pages-common';

import * as Styled from './header-actions.styles';
import HeaderMoreActions from './header-more-actions';

type HeaderActionsProps = {
  ctaType: any;
};
const HeaderActions: FC<HeaderActionsProps> = ({ ctaType }) => {
  const { isEditor } = useEditContext();
  const { pageContext } = usePageContext() ?? {};

  const pageUrl = pageContext?.url;
  const pageId = pageContext?.pageId;
  const globalId = pageContext?.globalId;
  const isFollowingPage = pageContext?.isFollowingPage;

  return (
    <>
      <Styled.FollowContainer
        isFollowingPage={!!isFollowingPage}
        pageId={pageId}
      />
      <HeaderMoreActions
        globalId={globalId}
        pageUrl={pageUrl}
        isEditor={isEditor}
        ctaType={ctaType}
      />
    </>
  );
};

export default HeaderActions;
