import styled from 'styled-components';

import * as tokens from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${tokens.xdlColorInfoSoft};
  padding: ${tokens.spaceM};
  margin: ${tokens.spaceM} 0;
`;

export const Text = styled(BodyCopy)`
  margin: 0 ${tokens.spaceS};
`;
