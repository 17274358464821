import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import IdealEmployerExtraInfo from './ideal-employer-extra-info';
import * as Styled from './ideal-employer.styles';
import MarkIdealEmployer from './mark-ideal-employer';

type IdealEmployerProps = {
  isIdealEmployer: boolean;
  companyId: string;
};

const IdealEmployer = ({ isIdealEmployer, companyId }: IdealEmployerProps) => {
  const [isIdealEmployerState, setIsIdealEmployerState] =
    useState(isIdealEmployer);

  return (
    <Styled.Wrapper data-testid={'IDEAL_EMPLOYER_MODULE'}>
      <Styled.Box data-testid={'idealEmployerContainer'}>
        <Styled.AddCompanyHeadline>
          <Styled.Header>
            <Styled.Title fontWeight="bold" lineHeight="dense" size={'large'}>
              <FormattedMessage
                id={
                  isIdealEmployerState
                    ? 'EP_IDEAL_EMPLOYER_ADDED'
                    : 'EP_IDEAL_EMPLOYER_WORK'
                }
                defaultMessage={
                  isIdealEmployerState
                    ? 'EP_IDEAL_EMPLOYER_ADDED'
                    : 'EP_IDEAL_EMPLOYER_WORK'
                }
              />
            </Styled.Title>
          </Styled.Header>

          <Styled.ContentText noMargin size={'xsmall'}>
            <FormattedMessage
              id={
                isIdealEmployerState
                  ? 'EP_IDEAL_EMPLOYER_CONFIDENTIAL_INFO'
                  : 'EP_IDEAL_EMPLOYER_ADD_CONFIDENTIALLY'
              }
              defaultMessage={
                isIdealEmployerState
                  ? 'EP_IDEAL_EMPLOYER_CONFIDENTIAL_INFO'
                  : 'EP_IDEAL_EMPLOYER_ADD_CONFIDENTIALLY'
              }
            />
          </Styled.ContentText>
        </Styled.AddCompanyHeadline>
        <MarkIdealEmployer
          companyId={companyId}
          changeModuleState={setIsIdealEmployerState}
          isIdealEmployer={isIdealEmployerState}
        />
      </Styled.Box>
      <IdealEmployerExtraInfo />
    </Styled.Wrapper>
  );
};

export default IdealEmployer;
