import {
  PROP_PAGINATION,
  PROP_SCROLLING,
  ENTITY_PAGE_EMPLOYEES_ADD_CONTACT,
} from '../constants';
import { trackAction, trackEvent } from '../tracker';

export const ENTITY_PAGE_SUB_EMPLOYEES = 'entity_page_sub_employees';
export const ENTITY_PAGE_MAIN_EMPLOYEES = 'entity_page_main_employees';
export const ENTITY_PAGE_EMPLOYEES_PAGE_VIEW = 'Employees_subpage';
export const ENTITY_PAGE_MAIN_EMPLOYEES_MESSAGE =
  'entity_page_main_employees_message';
export const ENTITY_PAGE_SUB_EMPLOYEES_MESSAGE =
  'entity_page_sub_employees_message';

export const trackEmployeesAddContactButtonAction = (
  propActionOrigin: string
) => {
  trackEvent(ENTITY_PAGE_EMPLOYEES_ADD_CONTACT, {
    [ENTITY_PAGE_EMPLOYEES_ADD_CONTACT]: 1,
    PropActionOrigin: propActionOrigin,
  });
};

export const trackPageEmployeesChange = ({
  direction,
  page,
}: {
  direction: string;
  page: number;
}) => {
  if (direction === 'right' && page === 1) return;

  trackAction({
    [PROP_SCROLLING]: `entity_page_employees_carousel_${
      direction === 'right' ? 'next' : 'back'
    }`,
    [PROP_PAGINATION]: page,
  });
};
