import styled from 'styled-components';

import { Button as DefaultButton } from '@xing-com/button';
import {
  spaceL,
  spaceXXL,
  xdlColorSelected,
  xdlColorTextInvert,
} from '@xing-com/tokens';

import { CheckboxFilter } from '../checkbox-filter/checkbox-filter';

export const FiltersWrapper = styled.div`
  margin-top: ${spaceL};
  max-width: 100%;
`;
export const Inner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  webkit-overflow-scrolling: touch;
  ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Filter = styled(CheckboxFilter)`
  margin-right: ${spaceL};
  flex: 0 0 auto;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const Button = styled(DefaultButton)<{ $selected: boolean }>`
  ${({ $selected }) =>
    $selected
      ? `
          padding: 0 ${spaceXXL};
          margin-right: ${spaceL};
          flex: 0 0 auto;
          color: ${xdlColorTextInvert};

          &::after {
            background-color: ${xdlColorSelected};
          }
        `
      : `
        padding: 0 ${spaceXXL};
        margin-right: ${spaceL};
        flex: 0 0 auto;
        `}
`;
