import type { FC, PropsWithChildren } from 'react';
import type React from 'react';

import { TextButton } from '@xing-com/button';
import type { TextButtonSizes } from '@xing-com/button';
import { useFetch } from '@xing-com/crate-xinglet';

import { URL_COMPLAINTS_API_REPORTS } from '../../config/urls';

export interface Report {
  id?: string;
}
export interface ResponseProps {
  reponse: string;
  report?: Report;
}

export interface ReportButtonProps {
  authorUrn?: string;
  targetUrn?: string;
  onError?: () => void;
  onSuccess?: () => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  buttonSize?: TextButtonSizes;
}
export const ReportButton: FC<PropsWithChildren<ReportButtonProps>> = ({
  children,
  authorUrn,
  targetUrn,
  onError,
  onSuccess,
  onClick,
  className,
  buttonSize,
}) => {
  const fetchJson = useFetch();

  const click: any = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(event);

    const data = {
      author_urn: authorUrn,
      target_urn: targetUrn,
    };
    try {
      const response = await fetchJson(URL_COMPLAINTS_API_REPORTS, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const redirectLocation = response.headers.get('Location');

      if (redirectLocation) {
        window.open(window.location.origin + redirectLocation);
        onSuccess && onSuccess();
      } else {
        onError && onError();
      }
    } catch (e) {
      onError && onError();
    }
  };
  return (
    <TextButton
      size={buttonSize ?? 'small'}
      onClick={click}
      className={className}
    >
      {children}
    </TextButton>
  );
};
