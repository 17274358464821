import styled from 'styled-components';

import {
  xdlColorTextInvert,
  xdlColorFocussed,
  xdlColorBackgroundTertiary,
  spaceS,
  spaceM,
  spaceL,
  spaceXL,
  spaceXXL,
  scale070,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

/* Dummy wrapper */
export const Wrapper = styled.div`
  min-height: 96px;
`;

export const Box = styled.div`
  /* max on design tokens is borderRadius600 for 3px */
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: ${spaceXL};
  background-color: ${xdlColorFocussed};
  min-height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1113px) {
    /* max on design tokens is borderRadius600 for 3px */
    display: flex;
    flex-direction: row;
    justify-content: unset;
    min-height: auto;
    align-items: center;
  }
`;

export const AddCompanyHeadline = styled.div`
  color: ${xdlColorTextInvert};

  @media (min-width: 1113px) {
    flex: 1;
  }
`;

export const Header = styled.div`
  margin-bottom: ${spaceM};
  display: flex;
  justify-content: space-between;

  @media (min-width: 1113px) {
    justify-content: flex-start;
    margin-bottom: 0;
  }
`;

export const Title = styled(BodyCopy)`
  margin-bottom: 0;

  @media (min-width: 1113px) {
    margin-bottom: 0;
    font-size: 22px;
  }
`;

export const ContentText = styled(BodyCopy)`
  margin-bottom: 12px;
  margin-top: 12px;

  @media (min-width: 1113px) {
    margin: 0;
    font-size: 14px;
  }
`;

export const OuterWrapper = styled.div`
  padding: ${spaceS} 0;

  @media (min-width: 1113px) {
    border-bottom: 1px solid ${xdlColorBackgroundTertiary};
  }
`;

export const ListWrapper = styled.ul`
  list-style: none;
  padding-left: ${spaceL};
  padding-right: ${spaceL};

  @media (min-width: 1113px) {
    display: flex;
    justify-content: space-between;
    padding: 0 ${spaceXL};
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: flex-start;

  & :first-child {
    padding-left: 0;
  }

  @media (min-width: 1113px) {
    padding-left: ${spaceXXL};
  }
`;

export const Icon = styled.span`
  margin-right: ${spaceL};
`;

export const ListText = styled(BodyCopy)`
  font-size: 12px;
`;

export const ListDesktop = styled.span`
  display: none;

  @media (min-width: 1113px) {
    display: block;
  }
`;

export const DropdownWrapper = styled.div`
  & > div {
    border-top: none;
  }

  & .listItem {
    margin-bottom: ${spaceL};
  }

  & .dropdown {
    display: flex;
    align-items: center;
    min-height: 40px;
    padding-top: ${spaceM};
    padding-bottom: ${spaceM};
  }

  @media (min-width: 1113px) {
    display: none;
  }
`;

export const DropdownText = styled(BodyCopy)`
  font-weight: 700;
  font-size: ${scale070};
  margin-bottom: 0;
`;
