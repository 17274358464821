import type { ApolloError } from '@apollo/client';
import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { ActorSwitchType } from '@xing-com/actor-switch';
import type { SelectableAudienceType } from '@xing-com/audience-selection';
import type { PollDataType, PollPreviewType } from '@xing-com/poll';

import type { CommboxSuccessBannerType } from '../components/commbox-success-banner/commbox-success-banner';
import type {
  CommboxApplicationType,
  CommboxAttachmentType,
  CommboxFeaturePermissionsType,
  CommboxView,
} from '../types';

type CommboxContextValues = {
  // Crate & EP
  actors: ActorSwitchType['actors'];
  application: CommboxApplicationType;
  featurePermissions: CommboxFeaturePermissionsType;
  globalId: string;
  isAllowedToPost: boolean;
  isCreatingPosting: boolean;
  isUploadModalTriggered: boolean;
  posting?: any;
  selectableAudiences: SelectableAudienceType[];
  successMessage: CommboxSuccessBannerType;
  postMutation: (data: any) => Promise<{
    error?: any;
    success?: any;
  }>;
  setIsAllowedPost: Dispatch<SetStateAction<boolean>>;

  // Crate
  crateImageUploader?: (file: File) => Promise<string | void | null>;

  // EP
  createPostingError?: ApolloError;
  isVideoUploadOpen?: boolean;
  pageId?: string; // same as globalId
  tagManagerId?: string;
  onClose: () => void;
  onOpenActorSwitch: () => void;
  onOpenAudienceSelection: () => void;
  onSelectAudience: (id: SelectableAudienceType['id']) => void;
  setIsVideoUploadOpen: Dispatch<SetStateAction<boolean>>;
};
type OtherCommboxContextValues = {
  attachmentType: CommboxAttachmentType;
  shouldOpenFilePicker: boolean;
  currentView: CommboxView;
  pollData: PollDataType | PollPreviewType | null;
  actorType: string;
  commboxHasContent: boolean;
  setActorType: Dispatch<SetStateAction<string>>;
  setAttachmentType: Dispatch<SetStateAction<CommboxAttachmentType>>;
  setCommboxHasContent: Dispatch<SetStateAction<boolean>>;
  setShouldOpenFilePicker: Dispatch<SetStateAction<boolean>>;
  setCurrentView: Dispatch<SetStateAction<CommboxView>>;
  onSuccess: () => void;
  setPollData: Dispatch<SetStateAction<PollDataType | PollPreviewType | null>>;
  closeDialog: () => void;
};
export const CommboxContext = createContext<
  (CommboxContextValues & OtherCommboxContextValues) | undefined
>(undefined);
