/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconAddContact = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconAddContact"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.217 15C13.729 15 16 18.416 16 21h-2c0-.46-.344-1.515-.88-2.321C12.404 17.604 11.451 17 10.216 17H7.783c-1.235 0-2.188.604-2.902 1.679C4.344 19.485 4 20.539 4 21H2c0-2.584 2.27-6 5.783-6zM20 10.002v1.999h1.999v2H20V16h-2v-1.999h-1.999v-2H18v-1.999zM9 3a5 5 0 1 1 0 10A5 5 0 0 1 9 3m0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6"
    />
  </svg>
);
export default IconAddContact;
