import styled, { css } from 'styled-components';

import { IconStar, IconStarFilled, IconStarHalfFilled } from '@xing-com/icons';
import { scale010, scale090, xdlColorDisabled } from '@xing-com/tokens';

type StarTransientProps = {
  $emptyState: boolean;
};

// *** Base ***
const baseStar = css`
  height: ${scale090};
  margin-right: ${scale010};
  width: ${scale090};
`;

// *** Components ***
export const StarEmpty = styled(IconStar)<StarTransientProps>`
  ${baseStar};
  ${({ $emptyState }) => $emptyState && `color: ${xdlColorDisabled}`};
`;

export const StarHalfFilled = styled(IconStarHalfFilled)<StarTransientProps>`
  ${baseStar};
  ${({ $emptyState }) => $emptyState && `color: ${xdlColorDisabled}`};
`;

export const StarFilled = styled(IconStarFilled)<StarTransientProps>`
  ${baseStar};
  ${({ $emptyState }) => $emptyState && `color: ${xdlColorDisabled}`};
`;
