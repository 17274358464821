import styled from 'styled-components';

import { List as DefaultList } from '@xing-com/list';
import { xdlColorBackground } from '@xing-com/tokens';

export const ListWrapper = styled.div`
  position: relative;
`;

export const List = styled(DefaultList)`
  position: absolute;
  z-index: 1;
  box-shadow: var(--shadow500);
  top: 0;
  left: 0;
  right: 0;
  background-color: ${xdlColorBackground};
`;
