import styled, { css } from 'styled-components';

import { Button as DefaultButton } from '@xing-com/button';
import * as layoutTokens from '@xing-com/layout-tokens';
import * as tokens from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

const editBarHeight = '56px';

export const EditBarMobile = styled.div.attrs({
  className: 'editBarMobile',
})<{ $displayCta: boolean }>`
  display: flex;
  background-color: ${tokens.xdlColorInfoSoft};
  width: 100%;
  padding: 0 ${tokens.spaceM};
  align-items: middle;
  margin-bottom: ${tokens.spaceM};

  & .ctaContainer {
    display: flex;
    margin-left: ${tokens.spaceM};
  }

  ${($displayCta) =>
    $displayCta &&
    `
      & .labelContainer {
        width: 100%;
        order: 1;
        flex: 1;
      }
      & .ctaContainer {
        order: 2;
      }
    `}

  @media ${layoutTokens.mediaSmallOrTiny} {
    margin-bottom: 0;

    .labelContainer,
    .ctaContainer {
      margin: 0;
    }
  }
  @media ${layoutTokens.mediaWide} {
    display: none;
  }
`;
export const BannerMobileContainer = styled.div.attrs({
  className: 'bannerMobileContainer',
})`
  width: 100%;
  padding: ${tokens.spaceM};
`;
export const BackButtonContainer = styled.div.attrs({
  className: 'backButtonContainer',
})`
  flex: 1;
`;
export const BannerMobile = styled.div.attrs({
  className: 'bannerMobile',
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: ${tokens.spaceM};

  @media ${layoutTokens.mediaSmallOrTiny} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const LabelContainer = styled.div.attrs({
  className: 'labelContainer',
})`
  display: flex;
  align-items: center;
  width: 100%;

  .editMobileIcon {
    margin-right: ${tokens.spaceM};
  }
`;
export const MobileInfo = styled(BodyCopy)`
  margin: 0;
  & span span {
    font-weight: 700;
  }
`;

export const EditBar = styled.div.attrs({
  className: 'editBar',
})<{ $isSticky: boolean }>`
  display: none;

  ${({ $isSticky }) =>
    !$isSticky &&
    css`
      height: ${editBarHeight};
    `}

  @media ${layoutTokens.mediaWide} {
    display: block;

    ${({ $isSticky }) =>
      $isSticky &&
      css`
        padding-bottom: ${editBarHeight};
      `}
  }
`;
export const EditBarSticky = styled.div.attrs({
  className: 'editBarSticky',
})<{
  $isEditing: boolean;
  $isShowingCompanyBanner: boolean;
  $isSticky: boolean;
}>`
  ${({ $isSticky }) =>
    $isSticky &&
    css`
      position: fixed;
      top: 86px;
      z-index: 20;
    `}

  height: ${editBarHeight};
  padding: 0 ${tokens.spaceM};
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${tokens.xdlColorBorderSoft};
  height: ${editBarHeight};
  align-items: center;

  ${({ $isEditing }) =>
    `background-color: ${$isEditing ? tokens.xdlColorInfoSoft : tokens.xdlColorBackground};`}

  ${({ $isShowingCompanyBanner }) =>
    $isShowingCompanyBanner &&
    `
      border-top: 1px solid ${tokens.xdlColorBorderSoft};
    position: relative;
    top: 0;

  `}
`;

export const InfoContainer = styled.div.attrs({
  className: 'infoContainer',
})`
  display: flex;
  align-items: center;
`;
export const ActionContainer = styled.div.attrs({
  className: 'actionContainer',
})`
  display: flex;
  align-items: center;
`;
export const Button = styled(DefaultButton)`
  margin-right: ${tokens.spaceS};

  @media ${layoutTokens.mediaWide} {
    margin-left: ${tokens.spaceS};
    margin-right: 0;
  }

  .isArmstrong {
    display: none;

    @media ${layoutTokens.mediaWide} {
      display: flex;
    }
  }
`;
export const Info = styled(BodyCopy)`
  margin: 0 ${tokens.spaceS};
`;
