import { useLocation, useNavigate } from '@reach/router';
import qs from 'query-string';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';

import type { CompanyKununuBadge } from '@xing-com/crate-common-graphql-types';
import {
  ModuleHeader,
  useEditContext,
} from '@xing-com/crate-companies-entity-pages-common';
import { trackAwardAction } from '@xing-com/crate-companies-entity-pages-common/src/tracking';

import * as Styled from './awards.styles';

const chunk = (arr: any[], chunkSize = 1, cache: any[] = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

const buildAwards = (badges: CompanyKununuBadge[]) => {
  const sections = chunk(badges, 2);

  return sections.map((section: CompanyKununuBadge[], index: number) => (
    <div key={index} data-testid="awards-item">
      {section.map((badge: CompanyKununuBadge, index: number) => (
        <Styled.AwardCard
          target={'_blank'}
          // @ts-expect-error FIXME: SC6
          href={badge.awardUrl}
          onClick={() => trackAwardAction('entity_page_subpage')}
          key={index}
        >
          {badge?.image?.[0].url && (
            <Styled.Image
              src={badge?.image?.[0].url}
              loading="lazy"
              alt="Award"
            />
          )}
        </Styled.AwardCard>
      ))}
    </div>
  ));
};

type AwardsProps = {
  badges: CompanyKununuBadge[];
};
export const Awards: FC<AwardsProps> = ({ badges }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { isEditing } = useEditContext();

  useEffect(() => {
    const locationParsed = qs.parse(decodeURIComponent(location.search));

    const shouldScroll = locationParsed.scroll === 'awards';

    const getBoundingClientRect = ref?.current?.getBoundingClientRect();

    if (shouldScroll) {
      navigate(location.pathname);

      // small buffer for page image loads
      setTimeout(() => {
        window.scrollTo({
          top: getBoundingClientRect && getBoundingClientRect.y - 80,
          behavior: 'smooth',
        });
      }, 500);
    }
  }, []);

  return (
    <div ref={ref} data-testid="aboutus-detail-awards">
      <ModuleHeader
        headlineCopyKey="EP_AWARDS_HEADLINE"
        externalEditInfo={
          isEditing
            ? {
                copyKey: 'EP_AWARDS_EDIT_HINT',
                cyTestId: 'EDIT_HINT_AWARDS',
              }
            : null
        }
      />
      <Styled.Carousel>{buildAwards(badges)}</Styled.Carousel>
    </div>
  );
};
