/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconUpload"
    {...props}
  >
    <path
      fill="currentColor"
      d="m11 16 .001-9.3L8.7 9 7.286 7.586l4.707-4.707L16.7 7.586 15.286 9 13 6.716 13 16zm-5 5v-2h12v2z"
    />
  </svg>
);
export default IconUpload;
