import styled from 'styled-components';

import {
  FileUploadButton as DefaultFileUploadButton,
  FileUploadDrop as DefaultFileUploadDrop,
} from '@xing-com/crate-x4e-file-upload';
import { spaceXXL, xdlColorBorderSoft, xdlColorText } from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

export const UploadHeadline = styled(Headline)`
  margin-bottom: ${spaceXXL};
`;

export const FileUploadDrop = styled(DefaultFileUploadDrop)`
  align-items: center;
  border: 2px dashed ${xdlColorBorderSoft};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 96px 144px;
  margin: 12px 0;

  @media (min-width: 740px) and (max-width: 1024px) {
    padding: 24px 32px;
  }
`;
export const FileUploadButton = styled(DefaultFileUploadButton)`
  border-radius: 50px;
  margin-top: 24px;
`;
export const DropImageHeadline = styled(Headline)`
  color: ${xdlColorText};
`;
