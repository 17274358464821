/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const SingleSizeIllustrationHeroVisibilityPost = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 320 340"
    data-xds="SingleSizeIllustrationHeroVisibilityPost"
    {...props}
  >
    <path
      fill="var(--xdlColorIllustrationBackground)"
      d="M139.32 154.75c-.66-4.47-7.93-36.57-15.72-34.7-3.71.89 3.35 14.33-3.16 16s-20.06-16.24-24.93-10c-2.24 2.88 7.07 9.41 7.07 9.41s-9.31-6.1-12.19-4.27 9.21 11.69 9.21 11.69-8.28-6-10.7-2.52 9.3 12.2 11.26 14.34 6.74 6.39 5.79 10.4zM189.29 162.52l3.29-13.22-16.58-4.2s6.94-41.6 23.59-48.72 28 10.18 28 10.18l4.84 20.78-14.25 41.64z"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="M151.999 10.87a1 1 0 0 1-.84-.47 1 1 0 0 1-.131-.754 1.006 1.006 0 0 1 .441-.626l10.8-6.83a1 1 0 0 1 .783-.194 1 1 0 0 1 .817.834 1 1 0 0 1-.53 1.04l-10.8 6.83a1 1 0 0 1-.54.17M164.69 20.53h-.05l-8.53-.43a1.002 1.002 0 0 1 .05-2l8.49.43a1 1 0 0 1 1 1 1 1 0 0 1-.96 1M171.199 218.65c-70 0-124.83 14.33-124.83 32.62s54.83 32.6 124.83 32.6c3.16 0 3.8-5.88 4.57-15.47.41-5.1 1.1-13.63 2.78-13.68 3.2-.1 8.16-2.33 12.95-4.49 4.23-1.9 8.61-3.88 11.15-4.05 4.38-.29 13.08 6.54 17.85 15.81.56 1.1 4 6.85 6.27 7.06a22 22 0 0 1-.27 4.82c-.41 3.39-.6 5.35.23 6.16.137.138.304.242.488.304.184.063.38.082.572.056 42.09-5.53 68.24-16.7 68.24-29.13 0-18.28-54.83-32.61-124.83-32.61"
    />
    <path
      fill="#B7E854"
      d="M183.76 283c9.64 15.25 59.89 69.94 91.25 42.61 25-21.8 9.68-76.88-1.11-102.39-20.22-47.82-54.74-61-77.81-61-21.23 0-38.66 7.61-52 22.28l-.3.35a68 68 0 0 1-1.45-11.58c-.27-5.12-3-19-3-19l-33.39 10.2c-1.86 34-5.75 62.36-9.12 84.69-1.18 7.76 2.21 28 10 29.22l9 1.46 22.87 1.79 13 .83 24.93.69 9.1-.28"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="M98.41 151.38c-4-3.71-9.54-8.78-8.27-10.58q.143-.18.347-.287t.433-.123c1.52-.24 4.09.88 6.55 2.27 2.74 2 5.2 3.45 5.78 3.8l1.11-1.66a55 55 0 0 0-5.79-3.82c-3.82-2.76-7.43-5.89-7.81-7.78a.73.73 0 0 1 .09-.65 1.261 1.261 0 0 1 .93-.48c1.91-.27 5.23 1.28 8.49 3.2 1.81 1.38 3.86 2.74 5.85 4 .95.61 1.77 1.14 2.15 1.44l1.24-1.57a82 82 0 0 0-8.24-5.64c-2.56-2-4.81-4.14-5-5.84a1.62 1.62 0 0 1 .58-1.39c2.16-2 8.47 2.29 13.54 5.76 4.34 3 8.1 5.56 10.48 5.35a2.49 2.49 0 0 0 1.72-.9c1.34-1.61 1-5 .7-8.7-.19-2.08-.58-6.41.24-6.7a1.83 1.83 0 0 1 1.59.26c3.79 2.24 9.19 14.53 12.18 33.69l2-.31c-2.82-18-8.1-32.12-13.14-35.1a3.73 3.73 0 0 0-3.29-.42c-2.27.82-1.94 4.5-1.55 8.76.25 2.79.56 6.27-.25 7.24a.5.5 0 0 1-.36.19c-1.64.14-5.65-2.59-9.17-5-6.25-4.29-12.71-8.72-16-5.57a3.62 3.62 0 0 0-1.2 3 6.05 6.05 0 0 0 1.21 2.87 8.8 8.8 0 0 0-4-.65 3.21 3.21 0 0 0-2.27 1.27 2.74 2.74 0 0 0-.44 2.23c.29 1.45 1.65 3.16 3.44 4.85a5.6 5.6 0 0 0-1.62 0 3.06 3.06 0 0 0-2.11 1.24c-2.33 3.24 2.69 7.87 8.56 13.24 3.42 3.15 7 6.41 8 8.73l1.81-.84c-1.25-2.7-4.81-5.89-8.51-9.38"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="M113.11 137.67c-.18.1-4.44 2.36-7.94 11.95l1.88.69c3.18-8.74 6.94-10.84 7-10.86l-.47-.88zM201.109 117.46a.84.84 0 0 0-1 .62l-.44 1.83a.833.833 0 0 0 .61 1h.2a.816.816 0 0 0 .81-.63l.45-1.84a.85.85 0 0 0-.63-.98M188.87 118.16h.2a.82.82 0 0 0 .81-.64l.45-1.83a.84.84 0 0 0-.62-1 .9.9 0 0 0-.325-.012.823.823 0 0 0-.675.632l-.45 1.83a.84.84 0 0 0 .313.88q.135.099.297.14M188.76 136.55s1.59 5.47 7.41 4.57c5.48-.84 5.08-6.69 5.08-6.69z"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="m232.04 171.38 4.18-50.17c.88-21.66-18-25.78-22.73-27.26-17-9.46-24.73 8.39-24.73 8.39l2.39.85c-.31.41-.62.84-.93 1.28a18.2 18.2 0 0 0-4.08 0l.2 1.59a18 18 0 0 1 2.87 0 79.8 79.8 0 0 0-8.5 19.13 143 143 0 0 0-4.71 20l-.1.72 15.81 3.84-2.93 12.06h.15a71.9 71.9 0 0 0-18.28 3.89l.53 1.5a73.8 73.8 0 0 1 24.91-4.1 66.6 66.6 0 0 1 11.77 1.1q.258 1.06.67 2.07c6.33 2 16.82 4.64 21.28 5.75a82.4 82.4 0 0 1 11.83 7.85l1-1.25a83 83 0 0 0-10.6-7.24m-7-38.55a5.37 5.37 0 0 1-6.5 4s-2.37-.61-2.94-.71c0 0-9.79 14.63-8.07 26.39a67 67 0 0 0-11.43-1c-1.91 0-3.78.08-5.64.2l2.35-9.69 6.34-2.28-5.74-1.4-.06.13-15.64-3.8c.67-4.12 4.42-25.06 13.3-38.41a6.23 6.23 0 0 1 3.46 1.69c.93 1 1.17 2.44.74 4.33-1 4.57-4.07 7.74-6.08 9.85l-.42.44a4.01 4.01 0 0 0-1.1 3.21 4 4 0 0 0 1.76 2.9l3.41 2.31.9-1.32-3.41-2.31a2.44 2.44 0 0 1-1.05-1.753 2.45 2.45 0 0 1 .65-1.937l.42-.44c2.13-2.23 5.35-5.6 6.48-10.6.55-2.4.17-4.34-1.12-5.76a7.37 7.37 0 0 0-3.66-2c.25-.35.5-.7.77-1l7.14 2.54 9.65 2.64 2.79-8.93s-3.61 13.26 4.16 25.2c.69 1 4.58 1.11 4.58 1.11a5.39 5.39 0 0 1 3.95 6.44z"
    />
    <path
      fill="var(--xdlColorIllustrationBackground)"
      d="M200.311 259.35s-21.11-.4-32 23.92l13.87-.4 3.82-8.24 8.24-11.26z"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="M171.2 283.87c-25.31 0-49.67-1.94-70.44-5.61l.29-1.64c20.68 3.65 44.94 5.58 70.15 5.58 3.29 0 5.5-.09 8.07-.2l4.48-.18.05 1.67c-1.69.05-3.12.12-4.46.17-2.58.13-4.82.21-8.14.21M127.761 208.54c-5.64 12.41-7.85 33.07-7.87 33.28l1.59.16c0-.2 2.2-20.6 7.74-32.78a93 93 0 0 1 15.34-24l-1.18-1.07a94.4 94.4 0 0 0-15.62 24.41"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="m222.769 265.94.2-.07-5.86-17.95-1.52.5 5.44 16.58a122.5 122.5 0 0 0-18.75-8.13 44.2 44.2 0 0 0-20.08 25.14l1.53.47a42.6 42.6 0 0 1 18.71-23.83 123.23 123.23 0 0 1 56.33 39.78l1.26-1a124.2 124.2 0 0 0-37.26-31.49M278.801 158.65h-7v1.85h7zM290.249 158.65h-6.81v1.85h6.81zM281.96 148.28h-1.86v7h1.86zM281.96 164.07h-1.86v6.81h1.86z"
    />
    <path
      fill="#27DBA8"
      d="m84.57 274.35-2.38-1.83c9.91-12.91 8.28-20.29-7.28-32.91-31.94-25.94-24-44.71-20.86-49.85a25.16 25.16 0 0 1 10.72-9.22 63.73 63.73 0 0 1-16.4-41.31c-.58-25.42 14.82-58.41 60.149-67.69 4.93-1 10.75-1.57 16.9-2.17 26.61-2.58 59.71-5.8 75.85-45.13l2.78 1.14c-16.83 41-52.38 44.46-78.34 47-6.07.59-11.81 1.15-16.59 2.12-43.52 8.89-58.31 40.37-57.75 64.66a60.56 60.56 0 0 0 16.38 40.14c10.28-3.72 24.58-3.35 40.71 4 8.6 3.92 13.17 8.93 11.93 13.07-.71 2.37-3.91 6-17.3 3.93-15.41-2.42-27.38-8.88-36.14-17.5a22.6 22.6 0 0 0-10.35 8.54c-9 14.49 4.55 33.23 20.2 45.94 16.9 13.71 18.79 22.72 7.77 37.07m-14.46-92.72c8.25 7.72 19.34 13.49 33.459 15.71 10.1 1.59 13.53-.41 14-1.83.61-2-2.79-6-10.3-9.48-14.74-6.69-27.71-7.36-37.16-4.4"
    />
    <path
      fill="#FFFFFE"
      fillRule="evenodd"
      d="m305.209 192.37-1.23.13-2.12 4.55-1.34.15.81 7.71 5.14-.54a5.052 5.052 0 0 0 4.6-5.68l-.27-2.57-5.14.54zm-6.41 5-1.71.18.81 7.72 1.71-.18zm7.95-6.89.45 4.28 3.42-.36a1.74 1.74 0 0 1 1.9 1.54l.27 2.57a6.8 6.8 0 0 1-1.468 5.084 6.8 6.8 0 0 1-4.672 2.486l-10.29 1.08-1.16-11.14 5.51-.58 2.12-4.55zM36.68 90.22l-.79-.18a5.64 5.64 0 1 1 6.91-5 5.53 5.53 0 0 1-1.52 3.25q-.431.483-.94.88l-.38.28-3.43 2.42zm-2.38 5.55 6.69-4.73a8.5 8.5 0 0 0 1.69-1.47 7.4 7.4 0 0 0 2-4.34 7.509 7.509 0 1 0-10 6.4z"
      clipRule="evenodd"
    />
    <path
      fill="#FFFFFE"
      d="m137.128 1.118-66.665 6.49L80.51 110.82l66.665-6.489z"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="M79.62 111.94 69.38 6.74 138.032 0l10.23 105.2zM71.56 8.53l9.85 101.22 64.701-6.34-9.88-101.22z"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="M112.88 98.38a5.28 5.28 0 0 1-5.25-4.85 5.27 5.27 0 0 1 10.5-.84 5.27 5.27 0 0 1-4.83 5.67zm0-8.53h-.27a3.27 3.27 0 0 0-3 3.51 3.276 3.276 0 0 0 2.187 3.105 3.27 3.27 0 0 0 4.313-3.595 3.266 3.266 0 0 0-3.25-3z"
    />
    <path
      fill="#84D4D5"
      d="m135.019 12.121-60.166 5.857 6.8 69.84 60.165-5.857z"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="M97.04 12.3a1.001 1.001 0 0 1-.09-2l14-1.27a.999.999 0 1 1 .18 1.99l-14 1.27z"
    />
    <path
      fill="var(--xdlColorIllustrationBackground)"
      fillRule="evenodd"
      d="M106.689 40.8a17.08 17.08 0 0 0-14.07 11.32 17.11 17.11 0 0 0 30.49-3.72 17.09 17.09 0 0 0-16.38-7.6"
      clipRule="evenodd"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="M106.74 60.87a18.44 18.44 0 0 1-15.49-8.4l-.27-.41.16-.47a18.58 18.58 0 0 1 33.07-4l.27.41-.16.47a18.49 18.49 0 0 1-15.28 12.3q-1.147.117-2.3.1M93.2 51.79a16.58 16.58 0 0 0 23.785 3.569 16.6 16.6 0 0 0 5.275-7.119 16.49 16.49 0 0 0-15.6-7A16.49 16.49 0 0 0 93.2 51.78z"
    />
    <path
      fill="#27DBA8"
      fillRule="evenodd"
      d="M115.231 49.08a7.559 7.559 0 0 1-12.164 6.868 7.557 7.557 0 0 1-.271-11.685 7.556 7.556 0 0 1 9.589-.222 7.56 7.56 0 0 1 2.846 5.039"
      clipRule="evenodd"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="M107.72 54.52a4.52 4.52 0 0 1-2.77-1 4.47 4.47 0 0 1-1.7-3 4.52 4.52 0 0 1 2.261-4.495 4.519 4.519 0 1 1 2.739 8.425q-.263.055-.53.07m0-7h-.31a2.53 2.53 0 0 0-2.19 2.81 2.52 2.52 0 0 0 1.827 2.11 2.52 2.52 0 0 0 2.653-.87 2.52 2.52 0 0 0 .275-2.661A2.52 2.52 0 0 0 107.7 47.5z"
    />
    <path
      fill="#B7E854"
      d="m106.526 31.907-1.985.243.677 5.539 1.985-.243zM115.704 32.572l-2.183 5.136 1.841.782 2.183-5.135zM95.791 35.015l-1.598 1.203 3.356 4.458 1.598-1.203zM110.224 62.175l-1.986.242.677 5.54 1.986-.244zM117.897 59.203l-1.598 1.203 3.355 4.459 1.598-1.203zM100.083 61.356l-2.185 5.134 1.84.783 2.186-5.134z"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="M171.699 284.02c-70 0-124.83-14.32-124.83-32.61h1.67c0 7.95 13 15.85 35.69 21.66 23.36 6 54.42 9.28 87.47 9.28z"
    />
    <path
      fill="#FFFFFE"
      d="m1.202 162.54 51.763 13.444 8.826-33.982-51.762-13.445z"
    />
    <path
      fill="var(--xdlColorIllustrationOutlinePrimary)"
      d="M9.33 127.34 0 163.26l53.7 14 9.33-35.92zm49.41 14.9-25.88 8.14-19.57-19.95zm-6.47 32.53-49.83-12.9 8.13-31.3 21.73 22.14 28-8.81z"
    />
    <path
      fill="#B7E854"
      d="M10.05 136.54a7.98 7.98 0 1 0 0-15.96 7.98 7.98 0 0 0 0 15.96"
    />
  </svg>
);
export default SingleSizeIllustrationHeroVisibilityPost;
