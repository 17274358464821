import type { FC } from 'react';

import type { SocialProofFragment } from '@xing-com/crate-companies-entity-pages-common/src/graphql/entity-page-query.gql-types';

import SocialProofText from './social-proof-text/social-proof-text';
import * as Styled from './social-proof.styles';

const toUserList = (socialProof: SocialProofFragment) => {
  const userNodes = socialProof.edges;
  const users = userNodes?.map((user) => user?.node?.xingId) ?? undefined;

  return users?.map((user) => ({
    id: user?.id,
    profileName: user?.displayName,
    src: user?.profileImage?.[0]?.url,
    href:
      user?.clickReasonProfileUrl?.profileUrl || `/profile/${user?.pageName}`,
  }));
};

type SocialProofProps = {
  socialProof?: SocialProofFragment | null;
};
export const SocialProof: FC<SocialProofProps> = ({ socialProof }) => {
  if (!(socialProof && socialProof?.total > 0)) {
    return null;
  }

  const socialProofData = socialProof
    ? {
        users: toUserList(socialProof),
        total: socialProof.total,
        proofType: socialProof.proofType,
      }
    : undefined;

  return (
    <div data-cy="socialProof">
      {socialProofData && (
        <Styled.SocialProofWrapper>
          <Styled.ImagesWrapper>
            <Styled.SocialProofImages
              // @ts-expect-error TODO: fix this type
              actors={
                socialProofData.users ? socialProofData.users.slice(0, 3) : []
              }
              maxActors={3}
              size="small"
              sizeWide="medium"
            />
          </Styled.ImagesWrapper>
          <Styled.ContactsWrapper size={'small'}>
            {socialProofData.users && socialProofData.proofType && (
              <SocialProofText
                // @ts-expect-error TODO: fix this type
                users={socialProofData.users}
                total={socialProofData.total}
                proofType={socialProofData.proofType}
              />
            )}
          </Styled.ContactsWrapper>
        </Styled.SocialProofWrapper>
      )}
    </div>
  );
};
