import styled, { css } from 'styled-components';

import { TextButton } from '@xing-com/button';
import { Slider as DefaultSlider } from '@xing-com/slider';
import {
  spaceS,
  spaceL,
  spaceXL,
  spaceXXL,
  scale080,
  scale110,
  xdlColorBorderSoft,
} from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

export const UploadHeadline = styled(Headline)`
  margin-bottom: ${spaceXXL};
`;

export const CropContainer = styled.div`
  max-width: 740px;
  height: 200px;
  position: relative;

  @media (min-width: 1113px) {
    max-width: 740px;
    height: 400px;
    position: relative;
  }
`;

export const Controls = styled.div`
  margin: ${spaceXL} auto 0;
  max-width: 400px;
  display: flex;
  gap: ${spaceXL};

  @media (min-width: 1113px) {
    margin: ${spaceXL} auto 0;
    max-width: auto;
    width: 400px;
    display: flex;
  }
`;

export const SliderText = styled(BodyCopy)`
  @media (min-width: 1113px) {
    margin: 0 ${spaceS};
  }
`;

export const Slider = styled(DefaultSlider)`
  flex: 1;
  height: 22px;
`;

export const ChildrenContainer = styled.div`
  margin: ${spaceL} 0;
`;

export const CropCtaContainer = styled.div<{ $noChildren?: boolean }>`
  display: flex;
  border-top: 2px solid ${xdlColorBorderSoft};
  margin: ${spaceXXL} 0;
  padding-top: ${scale110};
  flex-direction: row;

  ${({ $noChildren }) =>
    $noChildren &&
    css`
      margin: ${spaceXXL} 0;
    `}
`;

export const LeftButton = styled.div`
  flex: 1;
  justify-content: flex-start;
  display: flex;
`;

export const ActionButtons = styled.div`
  flex: 1;
  justify-content: flex-end;
  display: flex;
`;

export const CancelCta = styled(TextButton)`
  margin-right: ${scale080};
`;
