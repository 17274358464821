import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { Option } from '@xing-com/text-field';

import * as Styled from './pages-field.styles';

type ListType = {
  value: string;
  text: string;
}[];

type ValidationResult = null | {
  key: string;
  value?: number;
};

type PagesFormFieldProps = {
  type: string;
  formState?: any;
  setFormTouched?: (isTouched: boolean) => void;
  setFormState?: (state: any) => void;
  optionList?: ListType;
  defaultValue?: string | number;
  applyToValue?: (value: string) => string;
  applyToValueOnFocus?: (value: string) => string;
  applyToValueOnBlur?: (value: string) => string;
  required?: boolean;
  validate?: (type: string, value: string) => ValidationResult;
  copyPrefix?: string;
  maxAllowedChars?: number;
  shouldTrim?: boolean;
  multiline?: boolean;
};
export const PagesFormField: FC<PagesFormFieldProps> = ({
  type,
  formState,
  setFormState = () => undefined,
  setFormTouched = () => undefined,
  optionList,
  defaultValue,
  applyToValue,
  applyToValueOnFocus,
  applyToValueOnBlur,
  required,
  validate,
  shouldTrim,
  copyPrefix,
  maxAllowedChars,
  ...props
}) => {
  const { $t } = useIntl();

  const changeHandler = (
    value: string,
    blurred?: boolean,
    focussed?: boolean
  ) => {
    const val =
      applyToValueOnBlur && blurred
        ? applyToValueOnBlur(value)
        : focussed && applyToValueOnFocus
          ? applyToValueOnFocus(value)
          : maxAllowedChars
            ? value.substring(0, maxAllowedChars)
            : value;
    const error = validate && validate(type, shouldTrim ? val.trim() : val);
    const newValue = shouldTrim && blurred ? val.trim() : val;

    setFormState({
      ...formState,
      [type]: { value: newValue, showError: !!blurred && !!error, error },
    });
    setFormTouched(true);
  };

  if (optionList) {
    return (
      <Styled.Select
        value={formState?.[type]?.value || defaultValue || ''}
        error={formState?.[type]?.error?.key}
        onChange={(e: any) => changeHandler && changeHandler(e.target.value)}
        placeholder={$t({
          id: copyPrefix + type.toUpperCase(),
          defaultMessage: copyPrefix + type.toUpperCase(),
        })}
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        medium
        {...props}
      >
        {optionList?.map((item, index) => (
          <Option key={index} value={item.value}>
            {item.text}
          </Option>
        ))}
      </Styled.Select>
    );
  }

  return (
    <Styled.FormField
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      helperText={
        formState?.[type]?.showError && formState?.[type]?.error?.key
          ? $t(
              {
                id: formState[type].error?.key,
                defaultMessage: formState[type].error?.key,
              },
              { value: formState[type].error?.value }
            )
          : maxAllowedChars
            ? $t(
                {
                  id: 'EP_INPUT_CHARS_LEFT',
                  defaultMessage: 'EP_INPUT_CHARS_LEFT',
                },
                {
                  charactersNumber:
                    maxAllowedChars - formState?.[type]?.value?.length,
                }
              )
            : !required
              ? $t({
                  id: 'EP_OPTIONAL_FIELD_HINT',
                  defaultMessage: 'EP_OPTIONAL_FIELD_HINT',
                })
              : null
      }
      value={
        formState?.[type]?.value
          ? applyToValue
            ? applyToValue(formState?.[type]?.value)
            : formState?.[type]?.value
          : ''
      }
      error={formState?.[type]?.showError}
      onChange={(e: any) => changeHandler(e.target.value)}
      onBlur={(e: any) => changeHandler(e.target.value, true)}
      onFocus={(e: any) =>
        applyToValueOnFocus && changeHandler(e.target.value, false, true)
      }
      label={$t({
        id: copyPrefix + type.toUpperCase(),
        defaultMessage: copyPrefix + type.toUpperCase(),
      })}
      medium
      {...props}
    />
  );
};
