/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AffiliatesCompaniesSuggestionsQueryVariables = Types.Exact<{
  text: Types.Scalars['String']['input'];
}>;

export type AffiliatesCompaniesSuggestionsQuery = {
  __typename?: 'Query';
  autocompletionCompanyName?: {
    __typename?: 'AutocompletionCompanyNameSuggestions';
    collection: Array<{
      __typename?: 'AutocompletionCompanyNameSuggestion';
      company?: {
        __typename?: 'Company';
        id: string;
        companyName?: string | null;
        logos?: {
          __typename?: 'CompanyLogos';
          logo32px?: string | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export const AffiliatesCompaniesSuggestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AffiliatesCompaniesSuggestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autocompletionCompanyName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'consumer' },
                value: {
                  kind: 'StringValue',
                  value: 'loggedin.web.entity_pages.companies_affiliation_edit',
                  block: false,
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'text' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo32px' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AffiliatesCompaniesSuggestionsQuery,
  AffiliatesCompaniesSuggestionsQueryVariables
>;
