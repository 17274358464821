import * as React from 'react';

import type { OptionProps } from './option.types';

export const Option = React.forwardRef<HTMLOptionElement, OptionProps>(
  ({ children, className, value, ...props }, forwardedRef): JSX.Element => (
    <option className={className} ref={forwardedRef} value={value} {...props}>
      {children}
    </option>
  )
);

Option.displayName = 'Option';
