import type {
  AffiliateCompany,
  AffiliatesEdges,
  AffiliatesReducerRow,
  AffiliateReducerErrors,
} from './types';
import { AffiliatesReducerActionKind } from './types';

const setInitialState =
  (dispatch: React.Dispatch<any>) => (affiliates: AffiliatesEdges) =>
    dispatch({
      type: AffiliatesReducerActionKind.SET_INITIAL_STATE_ACTION,
      payload: {
        initialOptions: affiliates.edges
          .filter((affiliate) => affiliate.node.affiliateCompany)
          .map(({ node: { affiliateCompany, affiliateCategory } }) => ({
            company: affiliateCompany,
            category: {
              id: affiliateCategory.id,
            },
          })),
      },
    });

const deleteRow = (dispatch: React.Dispatch<any>) => (index: number) => {
  dispatch({
    type: AffiliatesReducerActionKind.DELETE_ITEM_ACTION,
    payload: {
      index,
    },
  });
};

const addRow = (dispatch: React.Dispatch<any>) => () => {
  dispatch({
    type: AffiliatesReducerActionKind.ADD_ITEM_ACTION,
  });
};

const companyChange =
  (dispatch: React.Dispatch<any>) =>
  (index: number) =>
  ({ companyName, id }: AffiliateCompany) => {
    dispatch({
      type: AffiliatesReducerActionKind.UPDATE_COMPANY_ACTION,
      payload: {
        index,
        company: {
          companyName,
          id,
        },
      },
    });
  };

const affiliateCategoryChange =
  (dispatch: React.Dispatch<any>) => (index: number) => (category: string) => {
    dispatch({
      type: AffiliatesReducerActionKind.UPDATE_AFFILIATION_CATEGORY_ACTION,
      payload: {
        index,
        category: {
          id: category,
        },
      },
    });
  };

const setErrorMessage =
  (dispatch: React.Dispatch<any>) => (errors: AffiliateReducerErrors) =>
    dispatch({
      type: AffiliatesReducerActionKind.SET_ERROR_MESSAGE,
      payload: {
        errors,
      },
    });

const setOrder =
  (dispatch: React.Dispatch<any>) => (modules: Array<AffiliatesReducerRow>) =>
    dispatch({
      type: AffiliatesReducerActionKind.UPDATE_AFFILIATES_ORDER_ACTION,
      payload: {
        modules,
      },
    });

export {
  addRow,
  affiliateCategoryChange,
  companyChange,
  deleteRow,
  setErrorMessage,
  setInitialState,
  setOrder,
};
