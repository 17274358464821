import type * as React from 'react';

import type { ButtonProps } from '@xing-com/button';

import * as Styled from './status-banner.styles';
const { variants } = Styled;

export type StatusBannerVariant = keyof typeof variants;
export type StatusBannerDisplay = 'fixed' | 'inline';

export type StatusBannerProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Add custom CSS */
  className?: string;
  /** Properties for the close button */
  closeButtonProps?: ButtonProps;
  /** Display type of the banner */
  display?: StatusBannerDisplay;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLDivElement>;
  /** @callback */
  handleOnClose?: () => void;
  /** @callback */
  onExited?: () => void;
  /** Show the banner */
  show?: boolean;
  /** Show the close button */
  showCloseButton?: boolean;
  /** The variant of the banner */
  variant: StatusBannerVariant;
} & React.HTMLAttributes<HTMLDivElement>;

export type StatusBannerAutoHideProps = {
  /** @callback */
  handleOnClose?: () => void;
  /** Show the banner */
  show?: boolean;
  /** Timeout until the banner closes */
  timeout?: number;
} & StatusBannerProps;
