import styled, { css } from 'styled-components';

import { ContentBannerAutoHide } from '@xing-com/banner';
import { Button } from '@xing-com/button';
import { IconTick } from '@xing-com/icons';
import { mediaTiny } from '@xing-com/layout-tokens';
import { scale000, spaceS, spaceXS, spaceXXL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

// *** Base ***
const baseSuccessBannerAutoHide = css`
  padding-right: ${spaceXXL};
`;

const baseTickIcon = css`
  margin-right: ${spaceXS};
  vertical-align: bottom;
`;

const baseMessageWrapper = css`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  text-align: center;

  @media ${mediaTiny} {
    flex-direction: column;
  }
`;

const baseMessage = css`
  display: inline;
`;

const baseHeadline = css`
  margin-right: ${spaceXS};
`;

const baseViewPostButton = css`
  margin-left: ${spaceS};

  @media ${mediaTiny} {
    margin: ${spaceS} ${scale000} ${scale000};
  }
`;

// *** Components ***

const SuccessBannerAutoHide = styled(ContentBannerAutoHide)`
  ${baseSuccessBannerAutoHide}
`;

const TickIcon = styled(IconTick)`
  ${baseTickIcon}
`;

const MessageWrapper = styled.div`
  ${baseMessageWrapper}
`;

const Message = styled(BodyCopy)`
  ${baseMessage}
`;

const Headline = styled.strong`
  ${baseHeadline}
`;

const ViewPostButton = styled(Button)`
  ${baseViewPostButton}
`;

export const Styled = {
  SuccessBannerAutoHide,
  TickIcon,
  MessageWrapper,
  Message,
  Headline,
  ViewPostButton,
};
