import { useFormikContext } from 'formik';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { TextButton } from '@xing-com/button';

import { useDialogContext } from '../../hooks/use-dialog-context/use-dialog-context';
import { useEditContext } from '../../hooks/use-edit-context/use-edit-context';
import { usePageContext } from '../../hooks/use-page-context/use-page-context';
import { trackEditSaving } from '../../tracking';
import * as Styled from './pages-form-actions.styles';

type PagesFormActionsProps = {
  loading: boolean;
};
export const PagesFormActions: FC<PagesFormActionsProps> = ({ loading }) => {
  const { $t } = useIntl();
  const { submitForm, dirty, isValid } = useFormikContext();
  const { executeWithDialog } = useDialogContext();
  const { pageContext } = usePageContext() ?? {};
  const { trackingData } = useEditContext();

  const goBackUrl = pageContext?.goBackUrl;

  const publishHandler = () => {
    if (!dirty) {
      goBackUrl && goBackUrl();
    } else {
      if (
        pageContext?.focusType &&
        pageContext?.pageId &&
        trackingData?.module &&
        trackingData?.part
      ) {
        trackEditSaving({
          focusType: pageContext?.focusType,
          itemId: pageContext?.pageId,
          module: trackingData?.module,
          part: trackingData?.part,
        });
      }
      submitForm();
    }
  };

  return (
    <Styled.Actions>
      <TextButton
        size={'medium'}
        disabled={loading}
        onClick={() => goBackUrl && executeWithDialog(goBackUrl)}
      >
        {$t({ id: 'EP_DISCARD_CTA' })}
      </TextButton>
      <Styled.PublishButton
        loading={loading}
        size={'medium'}
        variant={'primary'}
        disabled={!isValid}
        onClick={() => publishHandler()}
        data-testid={'PUBLISH'}
      >
        {$t({ id: 'EP_PUBLISH_CTA' })}
      </Styled.PublishButton>
    </Styled.Actions>
  );
};
