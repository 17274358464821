import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';

import { FollowContainer as InnerFollowContainer } from './follow/follow-container';

export const FollowContainer = styled(InnerFollowContainer)`
  @media ${mediaSmallOrTiny} {
    flex: 1;
  }
`;
