import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { LOGGED_OUT } from '@xing-com/crate-xinglet';

export const usePageSize = (hasSubpage: boolean) => {
  const { loginState } = useLoginState();

  if (hasSubpage) {
    return 3;
  }
  if (loginState === LOGGED_OUT) {
    return 30;
  }
  return 10;
};
