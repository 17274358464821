import { useLocation } from '@reach/router';
import qs from 'query-string';
import type { FC } from 'react';

import type { ButtonProps } from '@xing-com/button';
import type * as Types from '@xing-com/crate-common-graphql-types';
import {
  redirectToLogin,
  useLoggedoutAction,
  getImageGender,
} from '@xing-com/crate-companies-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import type { FlagProps, FlagVariant } from '@xing-com/flag';
import { IconMessages } from '@xing-com/icons';

import type { XingIdContactsFragment } from '../../graphql/xing-id-contacts-fragment.gql-types';
import { ContactsSkeleton } from './contacts.skeleton';
import * as Styled from './contacts.styles';

type User = Pick<Types.EntityPageContactUser, 'id' | 'enabled' | 'label'> &
  XingIdContactsFragment;

type ContactsProps = {
  currentUser?: string;
  messageEntryPoint: string;
  trackingModuleType?: string;
  users: User[];
  className?: string;
};
export const Contacts: FC<ContactsProps> & {
  Skeleton: typeof ContactsSkeleton;
} = ({
  users,
  currentUser,
  messageEntryPoint,
  trackingModuleType,
  className,
}) => {
  const location = useLocation();

  const { isLoggedOut } = useLoginState();

  const sendMessage = (pageName: string, messageEntryPoint: string) => {
    if (isLoggedOut) {
      redirectToLogin('sendMessage', { recipientName: pageName });
    } else {
      window.location.assign(
        `/chats/new/${pageName}?contextId=entity_pages&entryPoint=${messageEntryPoint}`
      );
    }
  };

  useLoggedoutAction(
    () =>
      window.location.assign(
        `/chats/new/${qs.parse(location.search)?.recipientName}?contextId=entity_pages&entryPoint=${messageEntryPoint}`
      ),
    'sendMessage'
  );

  const hasFlag = (user: User) =>
    user.userFlags?.displayFlag &&
    user.userFlags?.displayFlag !== 'BASIC' &&
    user.userFlags?.displayFlag !== 'EXECUTIVE';

  return (
    <Styled.ContactsWrapper data-testid="CONTACTS_MODULE" className={className}>
      {users.map((user, index: number) => {
        const flag: FlagProps | undefined = hasFlag(user)
          ? {
              size: 'small',
              variant:
                user.userFlags?.displayFlag?.toLowerCase() as FlagVariant,
            }
          : undefined;
        return (
          <Styled.ProfileWrapper
            key={index}
            data-cy={'contact'}
            data-testid={`CONTACT_${user.pageName}`}
          >
            <Styled.ContactsProfileInfo
              profileImage={{
                size: 'medium',
                src: user.profileImage?.[0]
                  ? user.profileImage[0].url
                  : undefined,
                type: getImageGender(user.gender),
                profileName: 'user profile image',
              }}
              profileUrl={getTrackingUrl({
                to:
                  user?.clickReasonProfileUrl?.profileUrl ||
                  `/profile/${user.pageName}`,
                trackingKey: 'viewContactProfile' + trackingModuleType,
              })}
              headline={{
                size: 'small',
                sizeWide: 'medium',
                ellipsis: true,
                noMargin: true,
                children: user.displayName,
                as: 'strong',
              }}
              textBody={[
                {
                  size: 'small',
                  sizeWide: 'medium',
                  children: user.occupations && user.occupations[0]?.subline,
                },
              ]}
              flag={flag}
              textMeta={[
                {
                  size: 'small',
                  sizeWide: 'medium',
                  children: user.label,
                },
              ]}
              buttons={
                currentUser !== user.id
                  ? ([
                      {
                        variant: 'tertiary',
                        icon: IconMessages,
                        // @ts-expect-error FIXME: SC6
                        'data-cy': 'message-btn',
                        size: 'medium',
                        'aria-label': 'message',
                        onClick: () =>
                          sendMessage(user.pageName, messageEntryPoint),
                        as: Styled.Button,
                      },
                    ] as ButtonProps[])
                  : undefined
              }
            />
          </Styled.ProfileWrapper>
        );
      })}
    </Styled.ContactsWrapper>
  );
};

Contacts.Skeleton = ContactsSkeleton;
