import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  IconReturn,
  IconEdit,
  IconTrash,
  IconHamburgerMenu,
} from '@xing-com/icons';

import * as Styled from './media-item.styles';

type ActionButtonsProps = {
  id: string;
  encoding?: boolean;
  deleted?: boolean;
  showDragAndDrop?: boolean;
  onDelete: (value: boolean) => void;
  onEdit: () => void;
  isExternalVideo?: boolean;
};
export const ActionButtons: FC<ActionButtonsProps> = ({
  encoding,
  deleted,
  onDelete,
  showDragAndDrop,
  onEdit,
  isExternalVideo,
}) => {
  return deleted ? (
    <Styled.DeletedActionsButtons
      size={'small'}
      icon={IconReturn}
      onClick={() => onDelete(false)}
    >
      <FormattedMessage id="EP_RESTORE_CTA" defaultMessage="EP_RESTORE_CTA" />
    </Styled.DeletedActionsButtons>
  ) : (
    <>
      {!encoding && !isExternalVideo && (
        <Styled.ItemActionButton
          icon={IconEdit}
          onClick={onEdit}
          size={'small'}
          variant={'tertiary'}
          aria-label="Edit"
        />
      )}
      <Styled.ItemActionButton
        icon={IconTrash}
        size={'small'}
        variant={'tertiary'}
        onClick={() => onDelete(true)}
        aria-label="Trash"
      />
      {showDragAndDrop && (
        <Styled.ItemActionButton
          // @ts-expect-error FIXME: SC6
          name={'dragButton'}
          icon={IconHamburgerMenu}
          size={'small'}
          aria-label="Hamburger"
        />
      )}
    </>
  );
};
