import type { FC, PropsWithChildren } from 'react';

import { IconArrowDown, IconArrowUp } from '@xing-com/icons';
import { ListItem } from '@xing-com/list';
import { Menu } from '@xing-com/menu';
import { usePopOver } from '@xing-com/pop-over';

import * as Styled from './dropdown.styles';

export const DropdownOption = ListItem;

export const DropdownList = Styled.List;

type DropdownProps = {
  disabled?: boolean;
  isSelected?: boolean;
  label?: string;
  className?: string;
};
export const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({
  children,
  disabled,
  label,
  className,
  isSelected,
}) => {
  const popOver = usePopOver();

  return (
    <div className={className}>
      <Styled.Button
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        $active={popOver.show || isSelected}
        icon={popOver.show ? IconArrowUp : IconArrowDown}
        size="small"
        variant="tertiary"
        onClick={popOver.show ? popOver.handleHide : popOver.handleShow}
        innerRef={popOver.triggerRef}
        disabled={disabled}
      >
        {label}
      </Styled.Button>
      <Menu
        onOutsideClick={popOver.handleHide}
        triggerRef={popOver.triggerRef}
        show={popOver.show}
      >
        {children}
      </Menu>
    </div>
  );
};
