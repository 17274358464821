import * as React from 'react';

import * as Styled from './input-area.styles';
import type { InputAreaProps } from './input-area.types';

export const InputArea = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputAreaProps
>(
  (
    { className, multiline, onChange, placeholder, value, ...props },
    forwardedRef
  ): JSX.Element => {
    const commonProps = {
      'data-xds': 'InputArea',
      onChange,
      placeholder,
      ref: forwardedRef,
      value,
      ...props,
    };

    if (multiline) {
      return (
        <Styled.Wrapper className={className}>
          <Styled.Textarea {...commonProps} {...props} />
        </Styled.Wrapper>
      );
    }

    return (
      <Styled.Wrapper className={className}>
        <Styled.Input {...commonProps} {...props} />
      </Styled.Wrapper>
    );
  }
);

InputArea.displayName = 'InputArea';
