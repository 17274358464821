import * as React from 'react';

import HoverObserver from '@xing-com/hover-observer';
import { IconCross, IconTick, IconPlus } from '@xing-com/icons';
import TouchObserver from '@xing-com/touch-observer';

import { TagContent } from './tag-content';
import * as Styled from './tag.styles';
import type { AddTagProps } from './tag.types';

export const AddTag = React.forwardRef<HTMLDivElement, AddTagProps>(
  (
    {
      children,
      className,
      disabled,
      fontWeight,
      maxWidth = '330px',
      onClick,
      profile,
      selected,
      value,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const hasChildren = Boolean(children);
    const hasLeadingContent = true;
    const hasTrailingContent = !hasChildren;

    const handleOnClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      if (!disabled && onClick) {
        event.currentTarget.dataset.value = value;
        onClick(event);
      }
    };

    return (
      <HoverObserver>
        {({ hovered }) => (
          <TouchObserver>
            {({ touched }) => (
              <Styled.InteractiveTag
                $disabled={disabled}
                $fontWeight={fontWeight}
                $hasLeadingContent={hasLeadingContent}
                $hasTrailingContent={hasTrailingContent}
                $profile={Boolean(profile)}
                $selected={selected}
                as="button"
                className={className}
                data-xds="AddTag"
                disabled={disabled}
                onClick={handleOnClick}
                ref={forwardedRef}
                type="button"
                value={value}
                {...props}
              >
                <TagContent
                  badgeProps={undefined}
                  disabled={disabled}
                  leadingIcon={
                    selected ? (
                      (hovered || touched) && onClick && !disabled ? (
                        <IconCross
                          data-qa="icon-shape-cross"
                          data-testid="icon-shape-cross"
                        />
                      ) : (
                        <IconTick
                          data-qa="icon-shape-tick"
                          data-testid="icon-shape-tick"
                        />
                      )
                    ) : (
                      <IconPlus
                        data-qa="icon-shape-plus"
                        data-testid="icon-shape-plus"
                      />
                    )
                  }
                  maxWidth={maxWidth}
                  profile={profile}
                  selected={selected}
                  trailingIcon={null}
                >
                  {children}
                </TagContent>
              </Styled.InteractiveTag>
            )}
          </TouchObserver>
        )}
      </HoverObserver>
    );
  }
);

AddTag.displayName = 'AddTag';
