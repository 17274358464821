/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconFilePDF = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconFilePDF"
    {...props}
  >
    <path
      fill="currentColor"
      d="m16 2 4 4v16H4V2zm-.828 2H6v16h12V6.828zM13 8v4h3v2h-3.586l-2.707 2.707-1.414-1.414L11 12.585V8z"
    />
  </svg>
);
export default IconFilePDF;
