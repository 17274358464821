import type { FC, PropsWithChildren } from 'react';
import { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import { BodyCopy } from '@xing-com/typography';

import type { ShowErrorProps } from '../../context/error-context/error-context';
import type { CroppedImage } from '../../typings/upload-files';
import * as Styled from './crop-image.styles';
import type { CropType } from './utils';
import { getCroppedImg } from './utils';

import './remove-polyfill';

type CropImageProps = {
  descriptionCopyKey?: string;
  onCropReady?: () => void;
  noHeadline?: boolean;
  imageUrl: string;
  onDragCrop?: () => void;
  onChange: (croppedImage: CroppedImage) => void;
  onDiscard: () => void;
  isUploading?: boolean;
  aspectRatio?: number;
  leftButton?: React.ReactNode;
  discardCopy?: string;
  publishCopy?: string;
  showError: (props: ShowErrorProps) => void;
};
export const CropImage: FC<PropsWithChildren<CropImageProps>> = ({
  noHeadline,
  descriptionCopyKey,
  isUploading,
  imageUrl,
  onCropReady,
  onChange,
  onDiscard,
  aspectRatio,
  discardCopy = 'EP_DISCARD_CTA',
  publishCopy = 'EP_PUBLISH_CTA',
  children,
  leftButton = null,
}) => {
  const { $t } = useIntl();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropType | null>(
    null
  );

  const onCropComplete = useCallback((_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = useCallback(async () => {
    if (imageUrl) {
      const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels);

      onChange(croppedImage);
    }
  }, [croppedAreaPixels]);

  return (
    <>
      {!noHeadline && (
        <Styled.UploadHeadline size="xxlarge">
          <FormattedMessage
            id="EP_CHOOSE_SECTION"
            defaultMessage="EP_CHOOSE_SECTION"
          />
        </Styled.UploadHeadline>
      )}
      {descriptionCopyKey && (
        <BodyCopy size="small">
          <span>
            <FormattedMessage
              id={descriptionCopyKey}
              defaultMessage={descriptionCopyKey}
              values={{ b: (t: any) => <b>{t}</b> }}
            />
          </span>
        </BodyCopy>
      )}
      <div>
        <Styled.CropContainer data-cy="CROP_IMAGE_SREEN">
          <>
            {imageUrl && (
              <Cropper
                image={imageUrl}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio || 1 / 1}
                zoomWithScroll={false}
                onCropAreaChange={onCropReady ? () => onCropReady() : undefined}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                showGrid={false}
              />
            )}
          </>
        </Styled.CropContainer>
        <Styled.Controls>
          <Styled.SliderText fontWeight="bold" size="small">
            <FormattedMessage id="EP_MIN" defaultMessage="EP_MIN" />
          </Styled.SliderText>
          <Styled.Slider
            value={zoom}
            min={1}
            max={2}
            step={0.01}
            showValueLabel={false}
            onChange={(_: any, value: number) => {
              if (value) {
                setZoom(value);
              }
            }}
            direction="left"
            type="continuous"
          />
          <Styled.SliderText fontWeight="bold" size="small">
            <FormattedMessage id="EP_MAX" defaultMessage="EP_MAX" />
          </Styled.SliderText>
        </Styled.Controls>
        {children && (
          <Styled.ChildrenContainer>{children}</Styled.ChildrenContainer>
        )}
        <Styled.CropCtaContainer $noChildren={!children}>
          {leftButton && <Styled.LeftButton>{leftButton}</Styled.LeftButton>}
          <Styled.ActionButtons>
            <Styled.CancelCta
              size="medium"
              disabled={isUploading}
              onClick={() => onDiscard()}
              data-cy="DISCARD_IMAGE_BUTTON"
            >
              <FormattedMessage
                id={discardCopy}
                defaultMessage={$t({ id: 'EP_DISCARD_CTA' })}
              />
            </Styled.CancelCta>
            <Button
              variant={'primary'}
              size={'medium'}
              loading={isUploading}
              onClick={() => {
                cropImage();
              }}
              data-cy="SAVE_IMAGE_BUTTON"
            >
              <FormattedMessage
                id={publishCopy}
                defaultMessage={$t({ id: 'EP_PUBLISH_CTA' })}
              />
            </Button>
          </Styled.ActionButtons>
        </Styled.CropCtaContainer>
      </div>
    </>
  );
};
