import { useLocation, useNavigate } from '@reach/router';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { useEffect } from 'react';

import type { EmployeeNode } from '../types';
import {
  RELATIONSHIP,
  FOLLOW_EMPLOYEE_ACTION,
  FOLLOW_EMPLOYEE_ID_PARAM,
} from '../utils';

export type UseNetworkRequestLoggedOutActionProps = {
  authenticated: boolean;
  executeNetworkRequest: (id: string) => void;
  employees: EmployeeNode[];
  loading: boolean;
};

const get = (
  obj: $TSFixMe,
  path: string | (string | number)[],
  defaultValue: $TSFixMe = undefined
) => {
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  return (
    pathArray?.reduce((acc, key) => {
      if (acc && acc[key] !== undefined) {
        return acc[key];
      }
      return undefined;
    }, obj) ?? defaultValue
  );
};

const useNetworkRequestLoggedOutAction = ({
  authenticated,
  executeNetworkRequest,
  employees,
  loading,
}: UseNetworkRequestLoggedOutActionProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const hasAction =
    get(qs.parse(decodeURIComponent(location.search)), 'action') ===
    FOLLOW_EMPLOYEE_ACTION;

  const followEmployeeId = get(
    qs.parse(decodeURIComponent(location.search)),
    FOLLOW_EMPLOYEE_ID_PARAM
  );

  useEffect(() => {
    if (loading || !employees?.length || !authenticated || !hasAction) return;

    if (followEmployeeId && employees) {
      const employeeInList = employees.find(
        ({
          node: {
            profileDetails: { id },
          },
        }) => id === followEmployeeId
      );

      const isAlreadyContact =
        employeeInList &&
        employeeInList.node.networkRelationship.relationship !==
          RELATIONSHIP.NONE;

      if ((employeeInList && !isAlreadyContact) || !employeeInList) {
        executeNetworkRequest(followEmployeeId);
      }
    }
    navigate(location.pathname);
  }, [followEmployeeId, hasAction, employees]);

  return null;
};

useNetworkRequestLoggedOutAction.propTypes = {
  toggle: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  currentValue: PropTypes.bool,
};

export { useNetworkRequestLoggedOutAction };
