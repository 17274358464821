/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AffiliatesQueryVariables = Types.Exact<{
  id: Types.Scalars['SlugOrID']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type AffiliatesQuery = {
  __typename?: 'Query';
  pagesAboutUsFacts?:
    | {
        __typename?: 'CompanyAboutUsFacts';
        companyData?: {
          __typename?: 'Company';
          id: string;
          affiliates?: {
            __typename?: 'CompanyAffiliatesConnection';
            total: number;
            edges?: Array<{
              __typename?: 'CompanyAffiliatesEdge';
              node?: {
                __typename?: 'CompanyAffiliate';
                affiliateCategory?: {
                  __typename?: 'CompanyAffiliationCategory';
                  localizationValue?: string | null;
                } | null;
                affiliateCompany?: {
                  __typename?: 'Company';
                  id: string;
                  companyName?: string | null;
                  links?: {
                    __typename?: 'CompanyLinks';
                    default?: string | null;
                    public?: string | null;
                  } | null;
                  logos?: {
                    __typename?: 'CompanyLogos';
                    logo64px?: string | null;
                  } | null;
                  followers?: {
                    __typename?: 'CompanyFollowersCollection';
                    total: number;
                  } | null;
                  userContext?: {
                    __typename?: 'CompanyUserContext';
                    followState?: {
                      __typename?: 'CompanyFollowState';
                      isFollowing?: boolean | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            pageInfo: {
              __typename?: 'PageInfo';
              endCursor?: string | null;
              hasNextPage: boolean;
            };
          } | null;
        } | null;
      }
    | { __typename?: 'EntityPageError' }
    | { __typename?: 'FallbackAboutUsFacts' }
    | { __typename?: 'GroupsAboutUsFacts' }
    | { __typename?: 'PublisherAboutUsFacts' }
    | null;
};

export const AffiliatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Affiliates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pagesAboutUsFacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CompanyAboutUsFacts' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'affiliates' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'first' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'limit' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'after' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'after' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'affiliateCategory',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'localizationValue',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'affiliateCompany',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'companyName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'links',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'default',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'public',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'logos',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'logo64px',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'followers',
                                                      },
                                                      arguments: [
                                                        {
                                                          kind: 'Argument',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'limit',
                                                          },
                                                          value: {
                                                            kind: 'IntValue',
                                                            value: '0',
                                                          },
                                                        },
                                                        {
                                                          kind: 'Argument',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'offset',
                                                          },
                                                          value: {
                                                            kind: 'IntValue',
                                                            value: '0',
                                                          },
                                                        },
                                                      ],
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'total',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'userContext',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'followState',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'isFollowing',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'endCursor',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hasNextPage',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AffiliatesQuery, AffiliatesQueryVariables>;
