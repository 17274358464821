/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconHide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconHide"
    {...props}
  >
    <path
      fill="currentColor"
      d="m18.788 3.94 1.415 1.414-1.786 1.784a11 11 0 0 1 2.407 2.488l-1.639 1.147a9 9 0 0 0-2.2-2.203l-8.07 8.072c.97.37 2.011.567 3.085.567 2.886 0 5.538-1.425 7.184-3.782l1.64 1.146c-2.015 2.884-5.275 4.636-8.824 4.636a10.65 10.65 0 0 1-4.607-1.045l-2.04 2.039-1.414-1.414L5.63 17.1a11 11 0 0 1-2.446-2.517l1.638-1.147a9 9 0 0 0 2.24 2.23l2.026-2.025a3.001 3.001 0 0 1 4.053-4.054l2.002-2.001A8.7 8.7 0 0 0 12.008 7C9.121 7 6.47 8.425 4.823 10.781l-1.64-1.145C5.198 6.751 8.458 5 12.007 5c1.645 0 3.228.376 4.654 1.067z"
    />
  </svg>
);
export default IconHide;
