import { IconPublic } from '@xing-com/icons';

export const CONTENT_FEED_TYPE_ARTICLE = 'EntityPageContentFeedArticlePost';
export const CONTENT_FEED_TYPE_NEW_ARTICLE =
  'EntityPageContentFeedArticlesArticlePost';
export const CONTENT_FEED_TYPE_COMPANY_ARTICLE =
  'EntityPageContentFeedCompanyArticlePost';
export const CONTENT_FEED_TYPE_GENERIC = 'EntityPageContentFeedPostingPost';
export const CONTENT_FEED_TYPE_IMAGE = 'image';
export const CONTENT_FEED_TYPE_VIDEO = 'video';
export const CONTENT_FEED_TYPE_LINK_SHARE = 'link_share';
export const CONTENT_FEED_TYPE_LINK_SHARE_EXTERNAL = 'link_share_external';
export const FEATURE_SWITCH_SHOW_COMMBOX = 'enable_new_commbox';
export const FEATURE_SWITCH_ENABLE_DRAFT_SCHEDULE =
  'enable_draft_and_scheduled_on_EP_newsmodule';
export const PAGES_URL_PREFIX = '/pages';
export const PINNED = 'PINNED';
export const SCHEDULED = 'SCHEDULED';
export const DRAFT = 'DRAFT';
export const HIDDEN = 'HIDDEN';
export const PUBLISHED = 'PUBLISHED';
export const VIDEOPOSTING = 'VIDEOPOSTING';
export const LINKSHAREPOSTING = 'LINKSHAREPOSTING';
export const IMAGEPOSTING = 'IMAGEPOSTING';
export const PAID_PLUS = 'PAID_PLUS';
export const PAID = 'PAID';
export const FREE = 'FREE';

export const FREE_PAGES_BANNER_LINK_DE =
  'https://recruiting.xing.com/de/products/xing-360';

export const FREE_PAGES_BANNER_LINK_EN =
  'https://recruiting.xing.com/en/products/xing-360';

export const COMMBOX_SELECTABLE_AUDIENCES = [
  {
    id: 'PUBLIC',
    Icon: IconPublic,
    audienceName: 'COMMBOX_AUDIENCE_PUBLIC',
    audienceDescription: 'COMMBOX_AUDIENCE_PUBLIC_DESC',
  },
];

export const ENTITY_PAGE_IMAGE_POSTING = 'PostingsImageAttachment';
