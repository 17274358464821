import styled from 'styled-components';

import { TextButton as DefaultTextButton } from '@xing-com/button';
import { xdlColorLink } from '@xing-com/tokens';

export const TextButton = styled(DefaultTextButton)`
  height: auto;
  border-width: 0;
  color: ${xdlColorLink};
`;
