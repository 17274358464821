type JobDetailQueryType = {
  id: string;
  title: string;
  activatedAt: string;
  url: string;
  industry: {
    id: string;
  };
  userScore: {
    scoreRounded: string;
  };
  userInteractions?: {
    bookmark: {
      state: string;
    };
  };
  companyInfo: {
    company: {
      companyName: string;
      id: string;
      logos: {
        logo96px: string;
      };
    };
  };
  location: {
    city: string;
    country: {
      countryCode: string;
      localizationKey: string;
      localizationValue: string;
    };
  };
};

export type FilterNameType = keyof FilterStateSelectedItemsType;
export type FiltersItemsTypeKey = keyof FiltersItemsType;

export type ToggleCheckboxType = (
  filterName: FilterNameType,
  id: string,
  trackingKey: string
) => void;

export type ClearType = (filterName: FilterNameType) => void;

export type ClearAllType = () => void;

export type JobQueryType = {
  position: string;
  trackingToken: string;
  postingId: string;
  descriptionHighlight: string;
  jobDetail: JobDetailQueryType;
};

export type SearchTypeQueryType = {
  body: {
    keywords: string;
  };
};

export type DisciplineAggregationQueryType = {
  id: string;
  count: string;
  discipline: {
    localizationValue: string;
    localizationKey: string;
  };
};

export type CareerAggregationQueryType = {
  id: string;
  count: string;
  careerLevel: {
    localizationValue: string;
    localizationKey: string;
  };
};

export type CitiesAggregationQueryType = {
  id: string;
  count: string;
  city: {
    name: string;
    id: string;
  };
};

export type AggregationQueryType = {
  disciplines: Array<DisciplineAggregationQueryType>;
  careerLevels: Array<CareerAggregationQueryType>;
  cities: Array<CitiesAggregationQueryType>;
};

export type JobSearchType = {
  total?: number;
  aggregations?: AggregationQueryType;
  searchQuery?: SearchTypeQueryType;
  collection: Array<JobQueryType>;
};

export type Aggregation = {
  id: string;
  count: string;
  name: string;
  trackingKey: string;
};

export type FiltersItemsType = {
  disciplines?: Array<Aggregation>;
  careerLevels?: Array<Aggregation>;
  cities?: Array<Aggregation>;
};

export type FilterStateSelectedItemsType = {
  disciplines?: Array<string>;
  careerLevels?: Array<string>;
  cities?: Array<string>;
};

export type FilterStateType = {
  waitingForSetup: boolean;
  keywords: string;
  offset: number;
  selectedItems: FilterStateSelectedItemsType;
  items: FiltersItemsType;
};

export type JobSearchByQueryType = {
  jobSearchByQuery?: JobSearchType;
};

// Actions
export type ActionType =
  | FilterNameActionType
  | OffsetActionType
  | UpdateSearchActionType
  | UpdateItemPayloadType
  | InitialOptionsActionType
  | ClearAllActionType;

export type FilterNameActionType = {
  type:
    | JobsReducerActionKind.TOGGLE_CHECKBOX_ACTION
    | JobsReducerActionKind.CLEAR_ACTION;
  payload: {
    id: string;
    filterName: FilterNameType;
  };
};

export type OffsetActionType = {
  type: JobsReducerActionKind.SET_OFFSET_ACTION;
  payload: {
    offset: number;
  };
};

export type UpdateSearchActionType = {
  type: JobsReducerActionKind.UPDATE_SEARCH_ACTION;
  payload: {
    keywords: string;
  };
};

export type UpdateItemPayloadType = {
  type: JobsReducerActionKind.UPDATE_ITEM_COUNTS_ACTION;
  payload: {
    aggregations: FiltersItemsType;
  };
};

export type InitialOptionsActionType = {
  type: JobsReducerActionKind.SET_INITIAL_OPTIONS_ACTION;
  payload: {
    initialOptions: FiltersItemsType;
  };
};

export type ClearAllActionType = {
  type: JobsReducerActionKind.CLEAR_ALL_ACTION;
};

// Reducer Types
export enum JobsReducerActionKind {
  TOGGLE_CHECKBOX_ACTION = 'TOGGLE_CHECKBOX_ACTION',
  CLEAR_ACTION = 'CLEAR_ACTION',
  SET_INITIAL_OPTIONS_ACTION = 'SET_INITIAL_OPTIONS_ACTION',
  UPDATE_ITEM_COUNTS_ACTION = 'UPDATE_ITEM_COUNTS_ACTION',
  UPDATE_SEARCH_ACTION = 'UPDATE_SEARCH_ACTION',
  CLEAR_ALL_ACTION = 'CLEAR_ALL_ACTION',
  SET_OFFSET_ACTION = 'SET_OFFSET_ACTION',
}
