/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type EntitySubpageQueryVariables = Types.Exact<{
  id: Types.Scalars['SlugOrID']['input'];
  moduleType: Types.Scalars['String']['input'];
  socialProofClickReasonsKey: Types.ClickReasonsEnum;
}>;

export type EntitySubpageQuery = {
  __typename?: 'Query';
  headerProperties?:
    | { __typename?: 'EntityPageAboutUsModule' }
    | { __typename?: 'EntityPageGenericModule' }
    | { __typename?: 'EntityPageHeaderModule'; properties?: any | null }
    | null;
  moduleProperties?:
    | { __typename?: 'EntityPageAboutUsModule' }
    | { __typename?: 'EntityPageGenericModule'; globalId?: string | null }
    | { __typename?: 'EntityPageHeaderModule' }
    | null;
  entityPageEX?:
    | {
        __typename?: 'EntityPage';
        id: string;
        slug: string;
        globalId: string;
        title: string;
        focusType?: Types.EntityPageFocusType | null;
        publicationStatus: Types.EntityPagePublicationStatus;
        visibility?: Types.EntityPageVisibility | null;
        context?: {
          __typename?: 'EntityPageContext';
          companyId?: string | null;
          cppId?: string | null;
        } | null;
        contract: {
          __typename?: 'EntityPageContract';
          type: Types.EntityPageContractType;
        };
        links: { __typename?: 'EntityPageLinks'; self: string };
        logoImage?: Array<{
          __typename?: 'ScaledImage';
          reference?: string | null;
          url?: string | null;
        }> | null;
        userPageContext?: {
          __typename?: 'EntityPageUserContext';
          userInteractions?:
            | { __typename: 'EntityPageUserInteractionFallback' }
            | {
                __typename: 'EntityPageUserInteractionFollow';
                followState?: {
                  __typename?: 'FollowContext';
                  isFollowing: boolean;
                } | null;
              }
            | { __typename: 'EntityPageUserInteractionJoin' }
            | null;
          permissions: {
            __typename?: 'EntityPagePermissions';
            canEdit: boolean;
            canAdmin: boolean;
          };
          socialProof?: {
            __typename?: 'EntityPageSocialProofConnection';
            proofType?: Types.EntityPageSocialProofType | null;
            total: number;
            edges: Array<{
              __typename?: 'EntityPageSocialProofEdge';
              node?: {
                __typename?: 'EntityPageSocialProof';
                xingId?: {
                  __typename?: 'XingId';
                  id: string;
                  pageName: string;
                  displayName: string;
                  gender?: Types.Gender | null;
                  profileImage?: Array<{
                    __typename?: 'ProfileImage';
                    url: string;
                  } | null> | null;
                  clickReasonProfileUrl?: {
                    __typename?: 'ClickReason';
                    profileUrl: string;
                  } | null;
                } | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      }
    | { __typename?: 'EntityPageError'; errorType: string; errorCode: number }
    | { __typename?: 'EntityPageMoved'; slug: string }
    | null;
};

export const EntitySubpageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EntitySubpage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'socialProofClickReasonsKey' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ClickReasonsEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'headerProperties' },
            name: { kind: 'Name', value: 'entityPageShowModule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleType' },
                value: { kind: 'StringValue', value: 'header', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageHeaderModule' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'properties' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'moduleProperties' },
            name: { kind: 'Name', value: 'entityPageShowModule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageGenericModule' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityPageEX' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'context' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cppId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contract' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'self' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logoImage' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'dimensions' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '320' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '320' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'reference',
                                      },
                                      value: {
                                        kind: 'StringValue',
                                        value: 'medium',
                                        block: false,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reference' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'focusType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicationStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'visibility' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPageContext' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userInteractions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'EntityPageUserInteractionFollow',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'followState',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'isFollowing',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '__typename' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'permissions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canEdit' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canAdmin' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'socialProof' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'first' },
                                  value: { kind: 'IntValue', value: '3' },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'proofType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'xingId',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'pageName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'displayName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'gender',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'profileImage',
                                                      },
                                                      arguments: [
                                                        {
                                                          kind: 'Argument',
                                                          name: {
                                                            kind: 'Name',
                                                            value: 'size',
                                                          },
                                                          value: {
                                                            kind: 'EnumValue',
                                                            value: 'SQUARE_96',
                                                          },
                                                        },
                                                      ],
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'url',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'clickReasonProfileUrl',
                                                      },
                                                      arguments: [
                                                        {
                                                          kind: 'Argument',
                                                          name: {
                                                            kind: 'Name',
                                                            value:
                                                              'clickReasonId',
                                                          },
                                                          value: {
                                                            kind: 'Variable',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'socialProofClickReasonsKey',
                                                            },
                                                          },
                                                        },
                                                      ],
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'profileUrl',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageMoved' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntitySubpageQuery, EntitySubpageQueryVariables>;
