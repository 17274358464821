import { useQuery } from '@apollo/client';
import { Redirect, useLocation } from '@reach/router';
import type { FC } from 'react';

import * as Types from '@xing-com/crate-common-graphql-types';
import {
  EntityPageDocument,
  PageError,
} from '@xing-com/crate-companies-entity-pages-common';
import { Header } from '@xing-com/crate-companies-entity-pages-header';
import { useFeatureSwitches } from '@xing-com/hub';

import { modulesMap } from '../../config/modules_map';
import { EP_URL } from '../../config/urls';
import { getPathByFocusType } from '../../utils/getPathByFocusType';
import * as Styled from './main-page.styles';
import MetaDataContainer from './meta-data-container';
import { RealMainPageContainer } from './real-main-page';

export type MainPageContainerProps = {
  pageSlug: string;
};
export const MainPageContainer: FC<MainPageContainerProps> = ({ pageSlug }) => {
  const location = useLocation();
  const path = location.pathname?.split('/')[1];

  const { data, loading, error } = useQuery(EntityPageDocument, {
    variables: {
      id: pageSlug,
      socialProofClickReasonsKey:
        Types.ClickReasonsEnum.CrWebPublisherSocialProofHeader,
    },
    errorPolicy: 'all',
  });
  const { data: featureSwitches } = useFeatureSwitches();

  const isModuleActive = (moduleType: string) => {
    // if the module is not tied to any feature switch return true
    if (!modulesMap[moduleType].featureSwitch) {
      return true;
    }
    const featureSwitch = modulesMap[moduleType].featureSwitch;
    return featureSwitch && featureSwitches?.includes(featureSwitch);
  };

  if (loading) {
    return (
      <Styled.MainPageWrapper>
        <MetaDataContainer setDefault={true} />
        <Header.Skeleton />
      </Styled.MainPageWrapper>
    );
  }

  if (error) {
    return (
      <Styled.MainPageWrapper>
        <PageError type={503} />
      </Styled.MainPageWrapper>
    );
  }

  if (data?.entityPageEX?.__typename === 'EntityPageError') {
    return (
      <Styled.MainPageWrapper>
        <PageError type={data?.entityPageEX?.errorCode === 10404 ? 404 : 503} />
      </Styled.MainPageWrapper>
    );
  }

  if (data?.entityPageEX?.__typename === 'EntityPageMoved') {
    return <Redirect to={EP_URL(data?.entityPageEX?.slug)} />;
  }

  if (
    data?.entityPageEX?.__typename === 'EntityPage' &&
    data?.entityPageEX?.publicationStatus === 'CREATED'
  ) {
    return <Redirect to="/pages/new/company" />;
  }

  const isAWebView = getPathByFocusType() !== `/${path}`;
  if (isAWebView) {
    return (
      <Styled.MainPageWrapper>
        <PageError type={404} />
      </Styled.MainPageWrapper>
    );
  }

  const entityPage =
    data?.entityPageEX?.__typename === 'EntityPage'
      ? (data.entityPageEX ?? undefined)
      : undefined;

  const pageModules = entityPage?.modules?.collection.filter(
    (module) =>
      module?.type !== 'header' &&
      module?.type &&
      modulesMap[module?.type] &&
      isModuleActive(module?.type)
  );

  const hasModulesAvailable = pageModules && pageModules?.length > 0;

  if (!hasModulesAvailable) {
    return (
      <Styled.MainPageWrapper>
        <PageError />
      </Styled.MainPageWrapper>
    );
  }

  return (
    <RealMainPageContainer
      pageSlug={pageSlug}
      pageModules={pageModules}
      data={data}
    />
  );
};
