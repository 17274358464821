import styled from 'styled-components';

import { EmptyState } from '@xing-com/crate-companies-entity-pages-common';
import { scale220, spaceXL } from '@xing-com/tokens';

export const NewsContainer = styled.div<{ showActors: boolean }>`
  padding: 0;
  margin: 0;
  margin-top: ${({ showActors }) => (showActors ? 0 : scale220)};
`;

export const EmptyTopAreaWrapper = styled(EmptyState)`
  margin-bottom: ${spaceXL};
`;

export const LazyloadContainer = styled.div`
  display: flex;
  justify-content: center;
`;
