import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { DropdownOption } from '@xing-com/crate-companies-entity-pages-common';
import { Label as DefaultLabel } from '@xing-com/label';
import { spaceL, spaceS, spaceM, xdlColorBorderSoft } from '@xing-com/tokens';

export const ClearItems = styled.div`
  border-top: var(--borderStyle500) ${xdlColorBorderSoft};
  padding: ${spaceS} ${spaceL};
`;
export const ClearButton = styled(TextButton)`
  font-weight: normal;
  font-size: var(--fontSize200);
`;

export const Item = styled(DropdownOption)`
  width: 100%;
  padding: ${spaceS} ${spaceL};
  box-sizing: border-box;
  &:first-of-type {
    padding-top: ${spaceL};
  }
  &:last-of-type {
    padding-bottom: ${spaceL};
  }
`;

export const Label = styled(DefaultLabel)`
  margin-left: ${spaceM};
`;
