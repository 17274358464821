import { usePageContext } from '@xing-com/crate-companies-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

export type ModulesLoadedState = {
  [key: string]: boolean;
};

type UseModulesLoadedValues = {
  canDisplayModule: (type: string) => boolean;
  setModuleLoaded: (type: string) => void;
};
const useModulesLoaded = (
  pageModulesByType: string[] | null
): UseModulesLoadedValues => {
  const { pageContext, setPageContext } = usePageContext() ?? {};
  const { isLoggedIn } = useLoginState();

  const setModuleLoaded = (type: string) =>
    // @ts-expect-error TODO: fix setPageContext type
    setPageContext((prevState) => {
      const newState = prevState
        ? {
            ...prevState,
            modulesLoaded: {
              ...(prevState.modulesLoaded || {}),
              [type]: true,
            },
          }
        : {
            ...pageContext,
            modulesLoaded: {
              [type]: true,
            },
          };

      return newState;
    });

  const canDisplayModule = (type: string) => {
    if (!isLoggedIn) {
      return true;
    }

    if (!pageModulesByType || !pageContext) {
      return false;
    }

    return pageContext?.modulesLoaded?.[type] ?? false;
  };

  return { canDisplayModule, setModuleLoaded };
};

export { useModulesLoaded };
