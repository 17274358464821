import { SkeletonBody } from '@xing-com/skeleton';

import * as Styled from './skeleton.styles';

export const AboutUsSkeleton = () => (
  <Styled.AboutUsWrapper data-testid="aboutus-skeleton">
    {/* @ts-expect-error FIXME: SC6 */}
    <Styled.SkeletonImage size="medium" />
    <Styled.Summary>
      <SkeletonBody amountRows={4} />
    </Styled.Summary>
  </Styled.AboutUsWrapper>
);
