import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { List } from '@xing-com/list';
import { Menu } from '@xing-com/menu';
import type { PopOverType } from '@xing-com/pop-over';

import * as Styled from './news-item-menu.styles';

type NewsItemMenuProps = {
  activityGlobalId: string;
  authorUrn: string;
  xbmUrl: string;
  popOver: PopOverType;
};
const NewsItemMenu: FC<NewsItemMenuProps> = ({
  activityGlobalId,
  authorUrn,
  popOver,
  xbmUrl,
}) => (
  <Menu
    onOutsideClick={popOver.handleHide}
    triggerRef={popOver.triggerRef}
    show={popOver.show}
  >
    <List>
      <Styled.ListItem>
        <Styled.ReportButton targetUrn={activityGlobalId} authorUrn={authorUrn}>
          <FormattedMessage id="EP_REPORT_NEWS_ITEM" />
        </Styled.ReportButton>
      </Styled.ListItem>
      {xbmUrl && (
        <Styled.ListItem>
          <Styled.TextButton
            size={'small'}
            href={xbmUrl}
            // @ts-expect-error FIXME: SC6
            target={'_blank'}
            rel={'noopener nofollow'}
            data-testid={'xbm-button'}
          >
            <FormattedMessage id="NEWS_MODULE_SHOW_STATISTICS" />
          </Styled.TextButton>
        </Styled.ListItem>
      )}
    </List>
  </Menu>
);

export { NewsItemMenu };
