import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { PostingPublicationState } from '@xing-com/crate-common-graphql-types';
import {
  useEditContext,
  usePageContext,
} from '@xing-com/crate-companies-entity-pages-common';

import { NewsCommbox } from '../news-commbox/news-commbox';
import * as Styled from './commbox-button.style';

export const CommboxButton: FC = () => {
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};
  const { focusType, pageId } = pageContext ?? {};
  const feedQueryVars = {
    id: pageId,
    limit: 3,
    filter: PostingPublicationState.Published,
  };
  const canEditLinkPreview = focusType === 'PUBLISHER' && !!isEditing;

  return (
    <NewsCommbox
      canEditLinkPreview={canEditLinkPreview}
      // @ts-expect-error TODO: fix this type
      feedQueryVars={feedQueryVars}
    >
      {(onClick) => (
        <Styled.Button
          variant={'tertiary'}
          size={'small'}
          onClick={() => onClick()}
          data-testid="EP_EDIT_PAGE_COMMBOX_BUTTON"
        >
          <FormattedMessage id={'EP_EDIT_ACTION_POST'} />
        </Styled.Button>
      )}
    </NewsCommbox>
  );
};
