import type { FC, PropsWithChildren } from 'react';
import React, { useState, useRef, useEffect, Children } from 'react';

import { IconArrowLeft, IconArrowRight } from '@xing-com/icons';
import { PaginationDots } from '@xing-com/pagination-dots';

import * as Styled from './carousel.styles';

type OnEventProps = {
  direction: string;
  page: number;
  total: number;
};

type CarouselProps = {
  /** define starting active card */
  activeCard?: number;
  /** callback function for arrow click. Provides {direction, page, totalPages} */
  onPageChange?: (params: OnEventProps) => void;
  //  /** callback function for arrow click. Provides {direction, page, totalPages} */
  onArrowClick?: (params: OnEventProps) => void;
  /** add your styles as skin={card: styles.yourHeroClassName} */
  skin?: { [key: string]: string };
  className?: string;
};
export const Carousel: FC<PropsWithChildren<CarouselProps>> = ({
  activeCard = 0,
  onArrowClick,
  onPageChange = () => undefined,
  children,
  skin,
  ...props
}) => {
  const [firstRender, setFirstRender] = useState<any>(true);
  const [card, setCard] = useState<number>(activeCard);
  const [dimensions, setDimensions] = useState<any>({});
  const carouselRef = useRef<any>(null);
  const isResizingRef = useRef<any>(false);
  const cardRef = useRef<any>(false);
  const dimensionsRef = useRef<any>(false);
  const scrollingRef = useRef<any>(null);

  const getFirstCardInView = (card: number) => {
    const length = React.Children.count(children);
    const numOfCards = dimensionsRef.current.numOfCards;

    /* we should guarantee that the last card is not alone in the view */
    if (card + numOfCards > length) {
      return length - numOfCards > 0 ? length - numOfCards : 0;
    }
    return card > 0 ? card : 0;
  };

  const canSetCard = (card: number) => {
    const length = React.Children.count(children);

    const isValidStopPoint =
      card % dimensionsRef.current.numOfCards ===
        cardRef.current % dimensionsRef.current.numOfCards ||
      card === 0 ||
      card + dimensionsRef.current.numOfCards === length;

    return (
      isValidStopPoint &&
      !isResizingRef.current &&
      scrollingRef.current === null
    );
  };

  const handleScroll = () =>
    setTimeout(() => {
      if (!carouselRef || !scrollingRef || !dimensionsRef) return;

      const currentScroll = carouselRef.current?.scrollLeft;
      const finalScroll = scrollingRef.current;
      const card = Math.round(currentScroll / dimensionsRef.current.cardWidth);

      if (finalScroll !== null && Math.abs(finalScroll - currentScroll) < 4) {
        scrollingRef.current = null;
      }

      canSetCard(card) && setCard(card);
    }, 50);

  const scroll = (card: number) => {
    if (!carouselRef.current) return;

    const { totalWidth, carouselViewWidth, cardWidth, length } =
      dimensionsRef.current;
    const firstCardInView = getFirstCardInView(card);

    const scrollToPosition =
      card === length - 1
        ? totalWidth - carouselViewWidth // we need this because in mobile we are displaying part of the previous card
        : firstCardInView * cardWidth;

    if (typeof carouselRef.current.scrollTo === 'function') {
      carouselRef.current.scrollTo({
        left: scrollToPosition,
        behavior: isResizingRef.current || firstRender ? 'auto' : 'smooth',
      });
    }
    scrollingRef.current = scrollToPosition;
    handleScroll();
  };

  const updateDimensions = () => {
    if (!carouselRef.current) return;

    const firstCard = carouselRef.current.firstChild;
    const cardsArray = Array.prototype.slice.call(carouselRef.current.children);
    const totalCardsWidth = cardsArray.reduce(
      (a, el) => a + el.getBoundingClientRect().width,
      0
    );
    const offsetWidth = totalCardsWidth / cardsArray.length; // we get an average because some cards can be 1px bigger than others
    const margin = parseInt(window?.getComputedStyle(firstCard).marginRight);
    const cardWidth = offsetWidth + margin;
    const carouselViewWidth =
      carouselRef.current.parentNode.getBoundingClientRect().width;
    const totalWidth = carouselRef.current.scrollWidth;

    const dimensions = {
      cardWidth,
      carouselViewWidth,
      totalWidth,
      length: React.Children.count(children),
      numOfCards: Math.round(carouselViewWidth / cardWidth),
    };

    // update dimensions
    setDimensions(dimensions);
    dimensionsRef.current = dimensions;
  };

  const handleResizeDebounce = (card: number) => {
    updateDimensions();

    // scroll to the original position
    scroll(card);

    isResizingRef.current = null;
  };

  const handleResize = () => {
    if (isResizingRef.current) return;
    isResizingRef.current = setTimeout(
      () => handleResizeDebounce(cardRef.current),
      500
    );
  };

  // we define if the card should be a stoping point or not
  const getSnapPosition = (i: number) => {
    const currentCenter = card + Math.ceil(dimensions.numOfCards / 2) - 1;
    const positionInView = currentCenter - card;
    const isFirstCard = positionInView % dimensions.numOfCards === 0;
    const shouldStop =
      i % dimensions.numOfCards === currentCenter % dimensions.numOfCards;

    return shouldStop ? (isFirstCard ? 'start' : 'center') : null;
  };

  // sets initial active card
  useEffect(() => {
    if (isResizingRef.current) {
      scroll(card);
    }
    setFirstRender(false);
  }, [dimensions]);

  const updateDimensionsAndScroll = async () => {
    await updateDimensions();
    scroll(card);
  };

  useEffect(() => {
    updateDimensionsAndScroll();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // triggers onPageChange()
    cardRef.current !== false &&
      onPageChange({
        direction: cardRef.current > card ? 'left' : 'right',
        page: Math.ceil(card / dimensions.numOfCards) + 1,
        total: Math.ceil(dimensions.length / dimensions.numOfCards),
      });

    cardRef.current = card;
  }, [card]);

  const currentPage = Math.ceil(card / dimensions.numOfCards) + 1;
  const totalPages = Math.ceil(dimensions.length / dimensions.numOfCards);

  const showFirstButton = !!card;
  const showLastButton =
    card + dimensions.numOfCards < React.Children.count(children);

  return (
    <Styled.CarouselWrapper $firstRender={firstRender && activeCard}>
      <Styled.Carousel {...props}>
        <Styled.RemoveScrollBar>
          <Styled.CarouselMain
            className="carouselMain"
            ref={carouselRef}
            onScroll={() => handleScroll()}
          >
            {Children.map(children, (child, i) => (
              <Styled.CarouselChild key={i} $snapStop={getSnapPosition(i)}>
                {child}
              </Styled.CarouselChild>
            ))}
          </Styled.CarouselMain>
        </Styled.RemoveScrollBar>
        {showFirstButton && (
          <Styled.ArrowLeft
            data-qa="carousel-button-left"
            variant="tertiary"
            size="small"
            icon={IconArrowLeft}
            onClick={() => {
              onArrowClick &&
                onArrowClick({
                  direction: 'left',
                  page: currentPage - 1,
                  total: totalPages,
                });
              scroll(card - dimensions.numOfCards);
            }}
            aria-label="Arrow Left"
          />
        )}
        {showLastButton && (
          <Styled.ArrowRight
            data-qa="carousel-button-right"
            variant="tertiary"
            size="small"
            icon={IconArrowRight}
            onClick={() => {
              onArrowClick &&
                onArrowClick({
                  direction: 'right',
                  page: currentPage + 1,
                  total: totalPages,
                });
              scroll(card + dimensions.numOfCards);
            }}
            aria-label="Arrow Right"
          />
        )}
      </Styled.Carousel>
      <Styled.DotsContainer>
        <PaginationDots
          selected={Math.ceil(card / dimensions.numOfCards)}
          length={Math.ceil(
            React.Children.count(children) / dimensions.numOfCards
          )}
        />
      </Styled.DotsContainer>
    </Styled.CarouselWrapper>
  );
};
