import styled, { css } from 'styled-components';

import { EditButton } from '@xing-com/crate-companies-entity-pages-common';
import {
  mediaSmallOrTiny,
  mediaConfined,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import { Tag } from '@xing-com/tag';
import {
  xdlColorText,
  xdlColorDisabled,
  xdlColorBackgroundSecondary,
  spaceXS,
  spaceS,
  spaceM,
  spaceL,
  spaceXL,
  spaceXXL,
  scale070,
  scale100,
  scale110,
  scale130,
  scale150,
} from '@xing-com/tokens';
import { Headline, BodyCopy } from '@xing-com/typography';

export const FactsContainer = styled.div`
  border-radius: 8px;
  background-color: ${xdlColorBackgroundSecondary};
  padding: ${spaceL};
  position: relative;

  @media ${mediaSmallOrTiny} {
    padding: ${spaceXL};
  }

  @media ${mediaConfined} {
    width: 243px;
    margin-left: ${spaceXL};
  }

  @media ${mediaWideNavless} {
    margin-left: ${spaceXXL};
    width: 295px;
    padding: ${spaceXL};
  }
`;

export const FactsHeadline = styled(Headline)`
  margin-bottom: ${spaceM};
  display: inline-block;

  @media ${mediaWideNavless} {
    margin-bottom: ${spaceL};
  }

  @media (min-width: 883px) and (max-width: 1024px) {
    font-size: ${scale100};
  }
`;

export const FactsEditButton = styled(EditButton)`
  margin-left: 6px;
`;

export const FactsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media ${mediaWideNavless} {
    & > li {
      margin-bottom: ${spaceS};
    }
    & > li:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CategoriesHeadline = styled(Headline)`
  margin: ${spaceL} 0 ${spaceM} 0;
  display: inline-block;

  @media ${mediaWideNavless} {
    margin: ${spaceXL} 0 ${spaceL} 0;
  }

  @media (min-width: 883px) and (max-width: 1024px) {
    font-size: ${scale100};
  }
`;

export const CategoriesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 8px);
  list-style-type: none;
  margin: 0;
  padding: 0;

  & > li {
    max-width: 100%;
    padding-bottom: ${spaceS};
  }

  & > li:not(:last-child) {
    padding-bottom: 0;
  }
`;

export const FactsTag = styled(Tag)`
  padding-right: ${spaceS};
`;

export const FactsIcon = styled.img`
  width: ${scale110};
  height: ${scale110};
  margin-right: ${spaceM};

  @media ${mediaWideNavless} {
    width: ${scale130};
    height: ${scale130};
    margin-right: ${spaceL};
  }
`;

export const FactsItemText = styled(BodyCopy)`
  font-weight: bold;
  text-align: start;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 883px) and (max-width: 1024px) {
    font-size: ${scale070};
  }
`;

export const FactsItemTextIcon = styled.div`
  margin-left: ${spaceXS};
  margin-bottom: 1px;
`;

export const FactsItemContainer = styled.a<{
  isLink?: boolean;
  isEmpty?: boolean;
}>`
  display: flex;
  align-items: center;
  height: ${scale150};

  ${({ isLink }) =>
    isLink &&
    css`
      color: ${xdlColorText};
      cursor: pointer;
    `}

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      color: ${xdlColorDisabled};

      & .factsIcon {
        opacity: 0.5;
      }
    `}
`;
