import styled from 'styled-components';

import * as layoutTokens from '@xing-com/layout-tokens';
import * as tokens from '@xing-com/tokens';
import {
  Row as DefaultRow,
  Column as DefaultColumn,
} from '@xing-com/xing-grid';

export const MainPageWrapper = styled.div.attrs({
  className: 'pageWrapper',
})`
  position: relative;
  padding-bottom: ${tokens.scale220};

  @media ${layoutTokens.mediaSmallOnly} {
    padding-top: ${tokens.spaceM};
    border-radius: 8px;
  }

  @media ${layoutTokens.mediaXWide} {
    padding-top: ${tokens.spaceXL};
    border-radius: 8px;
  }
`;

type HeaderWrapperProps = {
  isShowingCompanyBanner: boolean;
};
export const HeaderWrapper = styled.div.attrs({
  className: 'headerWrapper',
})<HeaderWrapperProps>`
  position: relative;

  ${({ isShowingCompanyBanner }) =>
    isShowingCompanyBanner ? `margin-top: ${tokens.spaceXL};` : ''}
`;

export const Row = styled(DefaultRow)`
  @media ${layoutTokens.mediaSmallOnly} {
    padding: 0;
  }
`;
export const Column = styled(DefaultColumn)`
  @media ${layoutTokens.mediaSmallOnly} {
    padding: 0;
  }
`;

type ModuleWrapperProps = {
  $canDisplayModules: boolean;
};
export const ModuleWrapper = styled.div.attrs({
  className: 'moduleWrapper',
})<ModuleWrapperProps>`
  position: relative;
  background-color: ${tokens.xdlColorBackground};
  visibility: ${({ $canDisplayModules }) =>
    $canDisplayModules ? 'visible' : 'hidden'};
`;
