import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { spaceM, spaceXXL } from '@xing-com/tokens';

export const TextArea = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
`;

export const TextAreaInner = styled.div`
  padding-top: 1px;
`;

export const ReadMoreButton = styled(Button)`
  margin-top: ${spaceXXL};
  margin-right: ${spaceM};
`;
