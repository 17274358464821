import styled from 'styled-components';

import { Button as DefaultButton } from '@xing-com/button';
import * as layoutTokens from '@xing-com/layout-tokens';
import * as tokens from '@xing-com/tokens';
import { BodyCopy, BodyCopy as DefaultBodyCopy } from '@xing-com/typography';

export const MoreButton = styled(DefaultButton).attrs({
  className: 'moreButton',
})`
  border-radius: 50px;
  padding: 0;
  border: none;
`;

export const MoreMenuTitle = styled(DefaultBodyCopy).attrs({
  className: 'moreMenuTitle',
})`
  display: none;

  @media ${layoutTokens.mediaSmallOrTiny} {
    color: ${tokens.xdlColorTextSecondary};
    text-align: center;
    border-bottom: 1px solid ${tokens.xdlColorDisabled};
    padding: ${tokens.scale060};
    display: block;
  }
`;

export const ListWrapper = styled.div.attrs({
  className: 'listWrapper',
})`
  color: var(--paletteGrey800);
  min-width: 150px;

  & > div {
    padding: ${tokens.spaceS} ${tokens.spaceL};
  }
`;

export const ListItem = styled.div.attrs({
  className: 'listItem',
})`
  cursor: pointer;
  border-bottom: 0;

  &:hover {
    background-color: ${tokens.xdlColorHover};
  }

  @media ${layoutTokens.mediaSmallOrTiny} {
    &:hover {
      background-color: transparent;
    }
  }
`;

export const Link = styled(BodyCopy).attrs({
  className: 'link',
})`
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  padding: ${tokens.scale030};
`;
