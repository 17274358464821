import styled, { css } from 'styled-components';

import { MiniBadge as MiniMadgeComponent } from '@xing-com/badge';
import { Link } from '@xing-com/link';
import {
  cornerRadiusL,
  scale080,
  spaceL,
  spaceM,
  spaceS,
  xdlColorHover,
  xdlColorText,
} from '@xing-com/tokens';

// *** Base ***
const baseMoreItem = css`
  background-color: transparent;
  border-radius: ${cornerRadiusL};
  border-width: 0;
  color: ${xdlColorText};
  display: flex;
  font-size: ${scale080};
  font-weight: 400;
  height: auto;
  justify-content: flex-start;
  outline: 0;
  padding-bottom: ${spaceM};
  padding-left: ${spaceL};
  padding-right: ${spaceL};
  padding-top: ${spaceM};
  width: 100%;

  &:focus,
  &:hover {
    background: ${xdlColorHover};
    color: ${xdlColorText};
    cursor: pointer;
    text-decoration: none;
  }
`;

// *** Components ***
const MoreListItem = styled.li`
  list-style: none;
`;

const MoreItem = styled.button`
  ${baseMoreItem};
`;

const MoreItemLink = styled(Link)`
  ${baseMoreItem};
`;

const MoreItemInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const MiniBadge = styled(MiniMadgeComponent)`
  margin-right: ${spaceS};
`;

const MoreItemIcon = styled.div`
  line-height: 0;
  margin-right: ${spaceS};
`;

export const Styled = {
  MiniBadge,
  MoreItem,
  MoreItemIcon,
  MoreItemInner,
  MoreItemLink,
  MoreListItem,
};
