import type React from 'react';

import { FormattedTimestamp } from '@xing-com/crate-companies-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-companies-entity-pages-common/src/tracking';

import { JobsBookmarkContainer } from '../../components/jobs-bookmark/jobs-bookmark-container';
import { JobsListItemSkeleton } from './jobs-list-item.skeleton';
import * as Styled from './jobs-list-item.styles';

type JobsListItemProps = {
  title: string;
  companyName: string;
  city: string;
  activatedAt: Date;
  url: string;
  id: string;
  bookmarkState: boolean;
  showBookmark: boolean;
  descriptionHighlight: string;
  showDescription: boolean;
};
export const JobsListItem: React.FC<JobsListItemProps> & {
  Skeleton: typeof JobsListItemSkeleton;
} = ({
  title,
  companyName,
  city,
  activatedAt,
  url,
  id,
  bookmarkState,
  showBookmark,
  descriptionHighlight,
  showDescription,
}) => (
  <Styled.Container>
    <Styled.InfoWrapper
      href={getTrackingUrl({
        to: url,
        trackingKey: 'viewJobProfile',
      })}
      target="_blank"
    >
      <Styled.Headline
        fontWeight="bold"
        lineHeight="dense"
        size="large"
        noMargin
      >
        {title}
      </Styled.Headline>
      {showDescription && (
        // @ts-expect-error FIXME: SC6
        <Styled.Description
          size="small"
          dangerouslySetInnerHTML={{ __html: descriptionHighlight }}
        />
      )}
      <Styled.CompanyInfo noMargin size="medium">
        {`${companyName}, ${city}`}
      </Styled.CompanyInfo>
      <Styled.Meta noMargin size="small">
        <FormattedTimestamp date={activatedAt} />
      </Styled.Meta>
    </Styled.InfoWrapper>
    {showBookmark && (
      <JobsBookmarkContainer
        jobId={id}
        bookmarked={bookmarkState}
        isSubpage={true}
      />
    )}
  </Styled.Container>
);

JobsListItem.Skeleton = JobsListItemSkeleton;
