import { useMutation, useLazyQuery } from '@apollo/client';
import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, TextButton } from '@xing-com/button';
import { UploadApplication } from '@xing-com/crate-common-graphql-types';
import { Dialog } from '@xing-com/dialog';
import { IconTrash } from '@xing-com/icons';

import { GetVideoDocument } from '../../graphql/get-video-query.gql-types';
import { CreateVideoDocument } from '../../graphql/mutations/create-video-mutation.gql-types';
import { DeleteVideoDocument } from '../../graphql/mutations/delete-video-mutation.gql-types';
import type { UpdateVideoMutationVariables } from '../../graphql/mutations/update-video-mutation.gql-types';
import { UpdateVideoDocument } from '../../graphql/mutations/update-video-mutation.gql-types';
import { UploadRequestVideoDocument } from '../../graphql/mutations/upload-request-video-mutation.gql-types';
import { usePageContext } from '../../hooks/use-page-context/use-page-context';
import { PreviewImage } from './components/preview-image/preview-image';
import { Subtitles } from './components/subtitles/subtitles';
import { Title } from './components/title/title';
import { UploadContainer } from './components/upload/upload-container';
import * as Styled from './video-upload.styles';

type UpdateParams = {
  title?: string;
  previewImage?: File;
  englishSubtitle?: File;
  germanSubtitle?: File;
};

type VideoUploadProps = {
  videoId?: string;
  title?: string;
  mediaId?: string;
  onError?: (error: any) => void;
  onDelete: () => void;
  onSave: (id: string) => void;
  onCancel?: () => void;
  onValueChange?: () => void;
};
export const VideoUpload: FC<VideoUploadProps> = ({
  mediaId: propsMediaId,
  videoId,
  title,
  onError = () => undefined,
  onDelete = () => undefined,
  onSave = () => undefined,
  onCancel = () => undefined,
  onValueChange = () => undefined,
}) => {
  const { $t } = useIntl();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { pageContext } = usePageContext() ?? {};
  const { pageId, globalId } = pageContext ?? {};

  const [displayValues, setDisplayValue] = useState<any>({});
  const [updates, setUpdate] = useState<UpdateParams>({});
  const [mediaId, setMediaId] = useState<string | undefined>();
  const [subtitlesId, setSubtitleId] = useState<any>({});
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const [getVideo] = useLazyQuery(GetVideoDocument);
  const [createVideo] = useMutation(CreateVideoDocument);
  const [uploadRequest] = useMutation(UploadRequestVideoDocument);
  const [updateVideo] = useMutation(UpdateVideoDocument);
  const [deleteVideo, { loading: isDeleting }] =
    useMutation(DeleteVideoDocument);

  const updateValue = (type: string, value: File | string | undefined) => {
    if (typeof value !== 'undefined') {
      setUpdate({ ...updates, [type]: value });
      setDisplayValue({
        ...displayValues,
        [type]: typeof value === 'string' ? value : value?.name,
      });
      onValueChange();
    }
  };

  useEffect(() => {
    if (videoId) {
      setMediaId(propsMediaId);

      getVideo({
        variables: { videoId },
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          const customThumbnails = data?.videoV2?.customThumbnails ?? [];
          const defaultThumbnails = data?.videoV2?.defaultThumbnails ?? [];

          const thumbnails =
            customThumbnails?.length > 0 ? customThumbnails : defaultThumbnails;

          const en = data?.videoV2?.subtitles?.find(
            (el) => el.language === 'en'
          );
          const de = data?.videoV2?.subtitles?.find(
            (el) => el.language === 'de'
          );

          setSubtitleId({ de: de?.id, en: en?.id });

          setDisplayValue({
            title: title,
            // @ts-expect-error TS(2551) FIXME: Property 'source' does not exist on type '{ __type... Remove this comment to see the full error message
            englishSubtitle: en?.source,
            // @ts-expect-error TS(2551) FIXME: Property 'source' does not exist on type '{ __type... Remove this comment to see the full error message
            germanSubtitle: de?.source,
            previewImage: thumbnails?.[0]?.sources?.[0].source,
          });
        },
      });
    }
  }, [videoId]);

  const isEditing = !!videoId;

  const uploadFile = async (file: File): Promise<string> => {
    const { data } = await uploadRequest({
      variables: {
        application: UploadApplication.EntityPages,
        fileSize: file.size,
      },
    });
    const { id, url, authToken } = data?.uploadRequest?.success ?? {};

    if (!url || !authToken) {
      onError('VIDEO_SAVE_ERROR');
      throw new Error('VIDEO_SAVE_ERROR');
    }

    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Upload-Offset': '0',
        'Tus-Resumable': '1.0.0',
        'Content-Type': 'application/offset+octet-stream',
      },
      body: file,
    });

    if (!response.ok) {
      onError('VIDEO_SAVE_ERROR');
      throw new Error('VIDEO_SAVE_ERROR');
    }

    return id;
  };

  const handleDeleteVideo = async () => {
    if (!mediaId || !pageId) return;

    try {
      await deleteVideo({
        variables: {
          mediaId: mediaId,
          pageId: pageId,
        },
      });

      setShowDeleteDialog(false);
      onDelete();
    } catch (error) {
      onError('VIDEO_DELETE_ERROR');
    }
  };

  const handleOnSave = async () => {
    if (!pageId || !mediaId) return;

    setIsUpdating(true);

    const variables: UpdateVideoMutationVariables = {
      pageId,
      mediaId,
      videoData: {},
    };

    if (Object.keys(updates).length === 0) {
      if (mediaId) {
        onSave(mediaId);
      }
      return;
    }

    try {
      if (typeof updates?.title !== 'undefined') {
        variables.videoData.title = updates?.title.trim();
      }

      // English subtitle
      if (
        typeof updates?.englishSubtitle !== 'undefined' &&
        (updates?.englishSubtitle !== null || subtitlesId.en)
      ) {
        const englishSubtitleUploadId = await uploadFile(
          updates?.englishSubtitle
        );
        variables.videoData.englishSubtitle = {
          ...(subtitlesId.en ? { subtitleId: subtitlesId.en } : {}),
          filename: updates?.englishSubtitle?.name || null,
          uploadId: englishSubtitleUploadId,
        };
      }

      // German subtitle
      if (
        typeof updates?.germanSubtitle !== 'undefined' &&
        (updates?.germanSubtitle !== null || subtitlesId.de)
      ) {
        const germanSubtitleUploadId = await uploadFile(
          updates?.germanSubtitle
        );
        variables.videoData.germanSubtitle = {
          ...(subtitlesId.de ? { subtitleId: subtitlesId.de } : {}),
          filename: updates?.germanSubtitle?.name || null,
          uploadId: germanSubtitleUploadId,
        };
      }

      // Preview Image
      if (
        typeof updates?.previewImage !== 'undefined' &&
        updates?.previewImage
      ) {
        const thumbnailUploadId = await uploadFile(updates?.previewImage);
        variables.videoData.thumbnailUploadId = thumbnailUploadId;
      }

      await updateVideo({ variables });

      if (mediaId) {
        onSave(mediaId);
      }
    } catch (error) {
      onError(error);
    }
    setIsUpdating(false);
  };

  const handleOnCancel = async () => {
    if (!isEditing && mediaId) {
      await handleDeleteVideo();
    }

    onCancel();
  };

  const handleAddVideo = async (file: File) => {
    if (!pageId || !globalId) {
      const error = new Error('Error creating video');
      onError(error);
      throw error;
    }

    const { data, errors } = await createVideo({
      variables: {
        pageId,
        fileName: file.name,
        ownerUrn: globalId,
      },
    });

    const error = data?.entityPageCreateVideoMedia?.error ?? errors;

    if (error) {
      onError(error);
      throw error;
    }
    const successData = data?.entityPageCreateVideoMedia?.success;
    const uploadUrl = successData?.videoDetails?.links.uploadUrl;
    const videoId =
      successData?.videoDetails?.id ?? successData?.videoDetails?.ref;
    const mediaId = successData?.id;

    if (!uploadUrl || !videoId || !mediaId) {
      const error = new Error('Error creating video');
      onError(error);
      throw error;
    }

    setMediaId(mediaId);
    return { uploadUrl, videoId };
  };

  const isSaveButtonDisabled =
    isUpdating ||
    isDeleting ||
    !mediaId ||
    (!isEditing && !isUploaded) ||
    (isEditing && Object.keys(updates).length === 0) ||
    titleError;

  return (
    <>
      <UploadContainer
        videoId={videoId}
        previewImage={updates?.previewImage}
        addVideo={handleAddVideo}
        onError={onError}
        onValidFileSelected={() => setIsUploading(true)}
        onUploadVideoComplete={() => {
          setIsUploaded(true);
          setIsUploading(false);
        }}
      />
      <Title
        title={displayValues.title}
        setTitle={(value) => updateValue('title', value)}
        setError={setTitleError}
      />
      <PreviewImage
        previewImageDisplay={displayValues.previewImage}
        onUpload={(image) => updateValue('previewImage', image)}
        onDelete={() => updateValue('previewImage', undefined)}
        onError={onError}
      />
      <Subtitles
        displayEn={displayValues.englishSubtitle}
        displayDe={displayValues.germanSubtitle}
        setSubtitleEn={(value) =>
          updateValue('englishSubtitle', value ?? undefined)
        }
        setSubtitleDe={(value) =>
          updateValue('germanSubtitle', value ?? undefined)
        }
        onError={onError}
      />

      <Styled.CtaContainer>
        {videoId && (
          <>
            <Styled.DeleteVideButton
              size="medium"
              icon={IconTrash}
              loading={isDeleting}
              onClick={() => setShowDeleteDialog(true)}
            >
              <FormattedMessage id="VIDEO_UPLOAD_DELETE" />
            </Styled.DeleteVideButton>

            <Dialog
              show={showDeleteDialog}
              isDestructive={true}
              loading={isDeleting}
              headline={$t({
                id: 'EP_DELETE_VIDEO_DIALOG_HEADLINE',
                defaultMessage: 'EP_DELETE_VIDEO_DIALOG_HEADLINE',
              })}
              text={$t({
                id: 'EP_DELETE_VIDEO_DIALOG_TEXT',
                defaultMessage: 'EP_DELETE_VIDEO_DIALOG_TEXT',
              })}
              cancelLabel={$t({
                id: 'EP_DELETE_VIDEO_DIALOG_CANCEL',
                defaultMessage: 'EP_DELETE_VIDEO_DIALOG_CANCEL',
              })}
              confirmLabel={$t({
                id: 'EP_DELETE_VIDEO_DIALOG_CONFIRM',
                defaultMessage: 'EP_DELETE_VIDEO_DIALOG_CONFIRM',
              })}
              onCancel={() => setShowDeleteDialog(false)}
              onConfirm={() => {
                handleDeleteVideo();
              }}
            />
          </>
        )}
        <TextButton
          size="medium"
          disabled={isUploading || isUpdating || isDeleting}
          onClick={handleOnCancel}
        >
          <FormattedMessage id="VIDEO_UPLOAD_CANCEL" />
        </TextButton>
        <Button
          variant="primary"
          size="medium"
          onClick={handleOnSave}
          loading={isUpdating}
          disabled={isSaveButtonDisabled}
        >
          <FormattedMessage id="VIDEO_UPLOAD_SAVE" />
        </Button>
      </Styled.CtaContainer>
    </>
  );
};
