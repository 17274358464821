import styled from 'styled-components';

import { mediaSmallOrTiny, mediaConfined } from '@xing-com/layout-tokens';
import { SkeletonBody } from '@xing-com/skeleton';
import { scale080, scale110, xdlColorBorderSoft } from '@xing-com/tokens';

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 156px;

  @media ${mediaSmallOrTiny} {
    width: 170%;
  }
`;

export const Card = styled.div`
  border: solid 2px ${xdlColorBorderSoft};
  margin-left: ${scale110};
  flex: 1;
  border-radius: 8px;
  padding: ${scale080};
  height: 100%;

  &:first-child {
    margin-left: 0;
  }
  @media ${mediaSmallOrTiny} {
    &:nth-child(1) {
      position: absolute;
      left: 0;
      width: 80vw;
    }

    &:nth-child(2) {
      position: absolute;
      left: 80vw;
    }

    &:nth-child(3) {
      display: none;
    }
  }
  @media (min-width: 1113px) {
    &:nth-child(3) {
      display: block;
    }
  }
  @media ${mediaConfined} {
    &:nth-child(3) {
      display: none;
    }
  }
`;
export const CompanyName = styled(SkeletonBody)`
  margin-bottom: ${scale080};

  & > div:last-child {
    width: 80%;
  }
`;
export const Title = styled(SkeletonBody)`
  margin-bottom: ${scale080};
`;
export const Description = styled(SkeletonBody)`
  margin-bottom: ${scale080};

  & > div:last-child {
    width: 50%;
  }
`;
export const Date = styled.span`
  & > div:last-child {
    width: 60px;
  }
`;
