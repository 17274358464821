import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useState } from 'react';

import * as Types from '@xing-com/crate-common-graphql-types';
import {
  LazyLoading,
  usePageContext,
} from '@xing-com/crate-companies-entity-pages-common';

import { SocialProofListDocument } from '../../../graphql/queries/social-proof-list-query.gql-types';
import SocialProofList from './social-proof-list';

type SocialProofListContainerProps = {
  listTitle: string;
};

const SocialProofListContainer: FC<SocialProofListContainerProps> = ({
  listTitle,
}) => {
  const { pageContext } = usePageContext() ?? {};

  const pageId = pageContext?.pageId;

  const [fetchMoreError, setFetchMoreError] = useState(false);
  const [lazyLoadingIsAllowed, setLazyLoadingIsAllowed] = useState(true);

  const { data, loading, error, refetch, fetchMore } = useQuery(
    SocialProofListDocument,
    {
      variables: {
        id: pageId as string,
        limit: 20,
        socialProofClickReasonsKey:
          pageContext?.focusType === 'GROUP_PAGE'
            ? Types.ClickReasonsEnum.CrWebGroupsSocialProofHeaderMembers
            : Types.ClickReasonsEnum.CrWebPublisherSocialProofHeader,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  );

  const getMoreItems = (cursor: string) => {
    fetchMore({
      variables: { limit: 20, after: cursor },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const newItems =
          fetchMoreResult.entityPageEX?.__typename === 'EntityPage' &&
          fetchMoreResult?.entityPageEX?.userPageContext?.socialProof?.edges;
        setLazyLoadingIsAllowed(newItems ? newItems.length > 0 : false);

        setFetchMoreError(false);

        return fetchMoreResult.entityPageEX?.__typename === 'EntityPage' &&
          prev.entityPageEX?.__typename === 'EntityPage' &&
          fetchMoreResult.entityPageEX.userPageContext?.socialProof?.edges &&
          prev.entityPageEX.userPageContext?.socialProof?.edges
          ? {
              ...fetchMoreResult,
              entityPageEX: {
                ...fetchMoreResult.entityPageEX,
                userPageContext: {
                  ...fetchMoreResult.entityPageEX.userPageContext,
                  socialProof: {
                    ...fetchMoreResult.entityPageEX.userPageContext
                      ?.socialProof,
                    edges: {
                      ...prev.entityPageEX.userPageContext?.socialProof?.edges,
                      ...newItems,
                    },
                  },
                },
              },
            }
          : prev;
      },
    }).catch((err) => {
      if (err) {
        setFetchMoreError(true);
      }
    });
  };

  const socialProof =
    data?.entityPageEX?.__typename === 'EntityPage'
      ? data?.entityPageEX?.userPageContext?.socialProof
      : undefined;

  const socialProofEdges = socialProof?.edges;
  const socialProofNodes = socialProofEdges
    ?.map((edge) => edge?.node)
    .filter((e) => !!e);
  const cursor = socialProof?.pageInfo?.hasNextPage
    ? socialProof?.pageInfo?.endCursor
    : undefined;

  return (
    <>
      {socialProofNodes && (
        <SocialProofList
          // @ts-expect-error fix this
          users={socialProofNodes}
          title={listTitle}
          loading={loading}
          error={error}
          refetch={refetch}
          fetchMore={() => cursor && getMoreItems(cursor)}
          fetchMoreError={fetchMoreError}
        />
      )}
      {loading && (
        <LazyLoading
          type={'LAZY_SCROLL'}
          isLoading={loading}
          enabled={lazyLoadingIsAllowed}
          onLazyLoad={() => cursor && getMoreItems(cursor)}
        />
      )}
    </>
  );
};

export default SocialProofListContainer;
