import { useQuery } from '@apollo/client';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';

import {
  LazyLoading,
  LAZY_LOADING_TYPE_BUTTON,
  EmptyState,
  WarningMessageBox as Error,
} from '@xing-com/crate-companies-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { EmployeesDocument as EMPLOYEES_QUERY } from '../graphql/queries/employees-query.gql-types';
import { useNetworkRequestLoggedOutAction } from '../hooks/use-network-request-logged-out-action';
import { useNetworkRequestMutation } from '../hooks/use-network-request-mutation';
import {
  LIMIT_EMPLOYEES_LIST,
  LIMIT_EMPLOYEES_LIST_LOGGED_OUT,
  QUERY_CONSUMER_DETAIL,
  EMPLOYEES_SORTING,
} from '../utils';
import EmployeesCardHorizontal, {
  EmployeesCardHorizontalSkeleton as Skeleton,
} from './employees-card-horizontal';
import * as Styled from './employees-detail.styles';
import EmployeesFencingView from './employees-fencing-view';

const i18nKeys = {
  single: 'EP_EMPLOYEE',
  multiple: 'EP_EMPLOYEES',
};

type RenderErrorProps = {
  refetch: () => void;
};
const RenderError: FC<RenderErrorProps> = ({ refetch }) => (
  <div data-testid={'errorContainer'}>
    <Error
      headerText="EP_ERROR_HEADER"
      bodyText="EP_ERROR_BODY"
      buttonText="EP_ERROR_RELOAD_CTA"
      onClick={() => refetch()}
    />
  </div>
);

type EmployeesDetailProps = {
  companyId: string;
};
const EmployeesDetail: FC<EmployeesDetailProps> = ({ companyId }) => {
  const intl = useIntl();
  const { isLoggedIn } = useLoginState();

  const [page, setPage] = useState(0);

  const [executeNetworkRequest] = useNetworkRequestMutation();

  const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery(
    EMPLOYEES_QUERY,
    {
      variables: {
        id: companyId,
        first: isLoggedIn
          ? LIMIT_EMPLOYEES_LIST
          : LIMIT_EMPLOYEES_LIST_LOGGED_OUT,
        query: { consumer: QUERY_CONSUMER_DETAIL, sort: EMPLOYEES_SORTING },
      },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    }
  );

  if (error) return <RenderError refetch={refetch} />;

  const total = data?.company?.employees?.total;
  const employeesEdged = data?.company?.employees?.edges ?? [];
  const employeesNodes = employeesEdged?.map((edge) => edge?.node) ?? [];
  const loadingFirstRender = loading && networkStatus !== 3;
  const { hasNextPage, endCursor } = data?.company?.employees?.pageInfo || {};

  const totalEmployeeKey = total === 1 ? i18nKeys.single : i18nKeys.multiple;

  const handlePagination = async (cursor: string) => {
    await fetchMore({
      variables: {
        id: companyId,
        first: LIMIT_EMPLOYEES_LIST,
        after: cursor,
        query: {
          consumer: QUERY_CONSUMER_DETAIL,
          sort: EMPLOYEES_SORTING,
        },
      },
      // @ts-expect-error TODO: fix this
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          ...prev,
          company: {
            ...prev.company,
            employees: {
              ...prev.company?.employees,
              edges: [
                ...(prev?.company?.employees?.edges || []),
                ...(fetchMoreResult?.company?.employees?.edges || []),
              ],
              pageInfo: fetchMoreResult?.company?.employees?.pageInfo,
            },
          },
        };
      },
    });

    setPage(page + 1);
  };

  const handleOnAddContact = () => {
    refetch({
      id: companyId,
      first: LIMIT_EMPLOYEES_LIST * (page + 1),
      query: {
        consumer: QUERY_CONSUMER_DETAIL,
        sort: EMPLOYEES_SORTING,
      },
    });
  };

  const isPaginationEnabled = loadingFirstRender
    ? false
    : !hasNextPage
      ? false
      : true;

  const isFenced =
    !isLoggedIn &&
    total &&
    (total > LIMIT_EMPLOYEES_LIST_LOGGED_OUT ||
      employeesNodes?.length > LIMIT_EMPLOYEES_LIST_LOGGED_OUT);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useNetworkRequestLoggedOutAction({
    authenticated: isLoggedIn,
    // @ts-expect-error TODO: fix this
    employees: employeesEdged,
    executeNetworkRequest,
    loading,
  });

  if (total === 0) {
    return (
      <EmptyState
        headlineCopyKey="EP_EMPLOYEES_NUMBER_EMPTY_STATE"
        textCopyKey="EP_EMPLOYEES_EMPTY_STATE"
        statusCode={isLoggedIn ? undefined : 404}
      />
    );
  }

  return (
    <>
      {total && (
        <Styled.TotalEmployees>{`${total} ${intl.formatMessage({
          id: totalEmployeeKey,
        })}`}</Styled.TotalEmployees>
      )}
      <Styled.EmployeeDetailsContainer>
        {loadingFirstRender && (
          <>
            <Skeleton />
            <Skeleton />
          </>
        )}
        {!isFenced &&
          employeesNodes?.map((node) => {
            const {
              networkRelationship,
              sharedContacts,
              contactDistance,
              profileDetails,
            } = node ?? {};

            if (
              !profileDetails ||
              !networkRelationship?.relationship ||
              !profileDetails?.userFlags?.displayFlag
            )
              return null;

            const distance = Number.parseInt(String(contactDistance?.distance));
            const sharedContactNumber = sharedContacts?.total || 0;
            const ocupationTitle =
              profileDetails?.occupations?.[0]?.subline ?? '';
            return (
              <EmployeesCardHorizontal
                key={profileDetails.id}
                id={profileDetails.id}
                displayName={profileDetails.displayName}
                profileUrl={
                  profileDetails?.clickReasonProfileUrl?.profileUrl ??
                  `/profile/${profileDetails.pageName}`
                }
                pageName={profileDetails.pageName}
                occupationTitle={ocupationTitle}
                sharedContacts={sharedContactNumber}
                loggedOut={isLoggedIn === false}
                relationship={networkRelationship?.relationship}
                contactDistance={distance}
                url={profileDetails?.profileImage?.[0]?.url}
                trackingModuleType="Subpage"
                flag={profileDetails?.userFlags?.displayFlag}
                onAddContact={() => {
                  handleOnAddContact();
                }}
              />
            );
          })}
        {isFenced && (
          <EmployeesFencingView
            // @ts-expect-error TODO: fix this
            employees={employeesNodes}
            total={total}
          />
        )}
      </Styled.EmployeeDetailsContainer>
      {isLoggedIn && (
        <LazyLoading
          type={LAZY_LOADING_TYPE_BUTTON}
          isLoading={networkStatus === 3}
          enabled={isPaginationEnabled}
          onLazyLoad={() => handlePagination(String(endCursor))}
          ctaText="EP_EMPLOYEES_LOAD_MORE_CTA"
        />
      )}
    </>
  );
};

export default EmployeesDetail;
