/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CompanyBasicInfoQueryFileUploadQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CompanyBasicInfoQueryFileUploadQuery = {
  __typename?: 'Query';
  industries?: Array<{
    __typename?: 'Industry';
    id: string;
    localizationValue: string;
    localizationKey: string;
    segments: Array<{
      __typename?: 'IndustrySegment';
      id: string;
      localizationValue: string;
    }>;
  }> | null;
  companyEmployeesNumbers?: Array<{
    __typename?: 'CompanyEmployeesNumber';
    id?: string | null;
    localizationValue: string;
  } | null> | null;
};

export const CompanyBasicInfoQueryFileUploadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'companyBasicInfoQueryFileUpload' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationKey' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'segments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyEmployeesNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyBasicInfoQueryFileUploadQuery,
  CompanyBasicInfoQueryFileUploadQueryVariables
>;
