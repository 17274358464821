import styled from 'styled-components';

import { Image } from '@xing-com/image';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceL, spaceM } from '@xing-com/tokens';

export const CoverImageWrapper = styled.div<{ $noImage?: boolean }>`
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: ${({ $noImage }) =>
    $noImage ? '20%' : '40%'}; /* 5:2 ratio - mobile and tablet */

  @media ${mediaWideNavless} {
    padding-top: ${({ $noImage }) =>
      $noImage ? '12.5%' : '25%'}; /* 8:1 ratio */
  }
`;

export const CoverImageInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const FreeCoverSvg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const EditingWrapperCover = styled.div`
  top: ${spaceL};
  right: ${spaceM};
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  background-image: linear-gradient(to top, #293545, rgba(255, 255, 255, 0));
  opacity: 0.75;
`;

export const CoverImage = styled(Image)`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;
