import type { FC } from 'react';

import * as Styled from './crop-skeleton.styles';

type CropSkeletonProps = {
  isCover?: boolean;
};
export const CropSkeleton: FC<CropSkeletonProps> = ({ isCover }) => (
  <Styled.SkeletonWrapper>
    {isCover && (
      <Styled.SkeletonBody
        amountRows={3}
        // @ts-expect-error TODO: fix this type
        size="small"
      />
    )}
    <Styled.SkeletonImage />
    <Styled.PublishWrapper>
      <Styled.SkeletonButton size="small" />
    </Styled.PublishWrapper>
  </Styled.SkeletonWrapper>
);
