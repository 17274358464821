import styled from 'styled-components';

import { xdlColorLink } from '@xing-com/tokens';

export const LinkContent = styled.a`
  color: ${xdlColorLink};
  &:visited,
  &:hover,
  &:active {
    color: ${xdlColorLink};
    text-decoration: none;
  }
`;
