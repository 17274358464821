import type { FC } from 'react';

import type { ButtonProps } from '@xing-com/button';

import type { InfoStateProps } from '../info-state/info-state';
import errorIcon from './assets/error.svg';
import * as Styled from './error-state.styles';

type ErrorStateProps = {
  buttonProps?: ButtonProps;
  testId?: string;
  onReloadClick?: () => void;
  headlineCopyKey?: string;
};
export const ErrorState: FC<ErrorStateProps & InfoStateProps> = ({
  onReloadClick,
  buttonProps,
  ...props
}) => (
  <Styled.InfoState
    icon={errorIcon}
    headlineCopyKey="EP_ERROR"
    buttonProps={{
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      copyKey: 'EP_ERROR_RELOAD_CTA',
      onClick: onReloadClick,
      variant: 'tertiary',
      ...buttonProps,
    }}
    {...props}
  />
);
