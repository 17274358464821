import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconWarning } from '@xing-com/icons';

import * as Styled from './media-item.styles';

type InfoTitleProps = {
  deleted: boolean;
  description?: string;
};
export const InfoTitle: FC<InfoTitleProps> = ({ deleted, description }) => (
  <Styled.InfoWrapper>
    {deleted && (
      <Styled.IconWrapper>
        <IconWarning width={16} height={16} />
      </Styled.IconWrapper>
    )}
    <Styled.InfoTitle>
      {deleted ? (
        <Styled.DeletedInfo>
          <FormattedMessage
            id={
              description
                ? 'EP_GM_VIDEO_WILL_BE_DELETED'
                : 'EP_GM_IMAGE_WILL_BE_DELETED'
            }
            defaultMessage={
              description
                ? 'EP_GM_VIDEO_WILL_BE_DELETED'
                : 'EP_GM_IMAGE_WILL_BE_DELETED'
            }
          />
        </Styled.DeletedInfo>
      ) : (
        <span>
          <FormattedMessage
            id={'EP_GM_VIDEO_ENCODING'}
            defaultMessage={'EP_GM_VIDEO_ENCODING'}
            values={{
              video_name: description,
              span: (t: any) => <span>{t}</span>,
            }}
          />
        </span>
      )}
    </Styled.InfoTitle>
  </Styled.InfoWrapper>
);
