import type { FC, PropsWithChildren } from 'react';

import { useAutocompleteState } from './autocomplete-context';
import * as Styled from './autocomplete-list-item.styles';

type AutocompleteListItemProps = {
  id?: string;
  index?: number;
  item?: any;
};

export const AutocompleteListItem: FC<
  PropsWithChildren<AutocompleteListItemProps>
> = ({ id, index, item, children, ...rest }) => {
  const {
    getItemProps,
    highlightedIndex,
  }: { getItemProps?: any; highlightedIndex?: number } = useAutocompleteState();

  return (
    <Styled.ListItem
      key={`${id}${index}`}
      $hovered={highlightedIndex === index}
      {...getItemProps({
        item,
        index,
      })}
      {...rest}
    >
      {children}
    </Styled.ListItem>
  );
};
