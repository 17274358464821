import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { IconTrash } from '@xing-com/icons';
import { Option, Select } from '@xing-com/text-field';

import * as Styled from './affiliates-editor.styles';
import { CompaniesAutocomplete } from './companies-autocomplete';
import type { AffiliateCompany, AffiliateCategory } from './types';

type AffiliatesFormRowProps = {
  affiliate: AffiliateCompany;
  affiliateCategory: AffiliateCategory;
  onAffiliateCategoryChange(category: string): void;
  onCompanyChange(company: AffiliateCompany): void;
  onDelete(): void;
  affiliationCategories?: AffiliateCategory[];
  error?: string;
};

const AffiliatesFormRow: FC<AffiliatesFormRowProps> = ({
  affiliate,
  affiliateCategory,
  onAffiliateCategoryChange,
  onCompanyChange,
  onDelete,
  affiliationCategories = [],
  error,
}) => {
  const intl = useIntl();

  return (
    <Styled.FormRow>
      <Styled.FormRowFieldCompanyName>
        <CompaniesAutocomplete
          company={{ id: affiliate.id, companyName: affiliate.companyName }}
          onChange={onCompanyChange}
          error={error}
          allowFreeText={false}
        />
      </Styled.FormRowFieldCompanyName>
      <Styled.FormRowFieldCategory>
        <Select
          value={affiliateCategory.id.toString()}
          // @ts-expect-error FIXME: SC6
          onChange={(e) => onAffiliateCategoryChange(e.target.value)}
          placeholder={intl.formatMessage({
            id: `EP_AFFILIATION_TYPE`,
          })}
        >
          {affiliationCategories.map((item) => (
            <Option key={item.id} value={item.id.toString()}>
              {item.localizationValue}
            </Option>
          ))}
        </Select>
      </Styled.FormRowFieldCategory>
      <Styled.FormRowButton>
        <Styled.DeleteButton
          variant={'tertiary'}
          onClick={onDelete}
          size={'small'}
          icon={IconTrash}
          aria-label="Trash"
        />
        <Styled.DragIcon className="handler" width={24} height={24} />
      </Styled.FormRowButton>
    </Styled.FormRow>
  );
};

export default AffiliatesFormRow;
