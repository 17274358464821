import { useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { BottomSheet } from '@xing-com/bottom-sheet';
import { Button, TextButton } from '@xing-com/button';
import type * as Types from '@xing-com/crate-common-graphql-types';
import {
  UpsellPopOver,
  useErrorContext,
  useModuleContext,
  usePageContext,
} from '@xing-com/crate-companies-entity-pages-common';
import {
  getIDfromURN,
  trackPinAction,
  trackUnpinAction,
  trackHideAction,
  trackUnhideAction,
} from '@xing-com/crate-companies-entity-pages-common/src/tracking';
import {
  IconEdit,
  IconStar,
  IconStarFilled,
  IconShow,
  IconTrash,
  IconReaction,
  IconHide,
} from '@xing-com/icons';
import { List, ListItem } from '@xing-com/list';
import { usePopOver } from '@xing-com/pop-over';

import {
  PUBLISHED,
  HIDDEN,
  SCHEDULED,
  DRAFT,
  CONTENT_FEED_TYPE_ARTICLE,
  CONTENT_FEED_TYPE_NEW_ARTICLE,
  CONTENT_FEED_TYPE_GENERIC,
  CONTENT_FEED_TYPE_COMPANY_ARTICLE,
} from '../../config/constants';
import { CompanyUpdatesDeleteDocument } from '../../graphql/mutations/company-delete-posting.gql-types';
import { DeletePostingDocument } from '../../graphql/mutations/delete-posting.gql-types';
import { HidePostingDocument } from '../../graphql/mutations/hide-posting.gql-types';
import { PinDocument } from '../../graphql/mutations/pin-activity.gql-types';
import { UnhidePostingDocument } from '../../graphql/mutations/unhide-posting.gql-types';
import { UnpinDocument } from '../../graphql/mutations/unpin-activity.gql-types';
import type { NewsProps } from '../news/news';
import * as Styled from './news-item-actions.styles';

type actionConfirmDialogType = {
  headline: string;
  text: string;
  cancel: string;
  confirm: string;
  action: () => void;
};

type NewsItemActionsProps = Pick<
  NewsProps,
  'isDetailPage' | 'isFollowingPage'
> & {
  activityId: string | null;
  articleTypename: string;
  isPaidPage: boolean;
  isPinned: boolean;
  pageId: string;
  itemId?: string;
  publicationState?: Types.PostingPublicationState;
  xamUrl: any;
  onEditButtonClick: () => void;
  onItemActionCompleted?: () => void;
  setIsItemLoading: (isLoading: boolean) => void;
  setLazyLoadingIsAllowed: (isAllowed: boolean) => void;
};

export const NewsItemActions: FC<NewsItemActionsProps> = ({
  activityId = null,
  articleTypename,
  isPaidPage,
  isPinned,
  pageId,
  itemId,
  publicationState,
  xamUrl,
  onEditButtonClick = () => undefined,
  onItemActionCompleted = () => undefined,
  setIsItemLoading,
  setLazyLoadingIsAllowed,
}) => {
  const [show, setShow] = useState<boolean>();
  const [actionConfirmDialog, setActionConfirmDialog] =
    useState<actionConfirmDialogType | null>();
  const popOver = usePopOver();
  const { showError } = useErrorContext();
  const { pageContext } = usePageContext() ?? {};
  const { moduleContext } = useModuleContext();
  const { focusType } = pageContext ?? {};
  const intl = useIntl();

  const [deletePosting, { loading: deleteLoading }] = useMutation(
    DeletePostingDocument,
    {
      optimisticResponse: {
        __typename: 'Mutation',
        postingsDeletePosting: {
          error: null,
          __typename: 'PostingsPostingDeleteResult',
        },
      },
      onCompleted: (data) => {
        setLazyLoadingIsAllowed(true);
        const error = data.postingsDeletePosting?.error;

        if (error) {
          showError(error);
        } else {
          onItemActionCompleted();
        }
      },
    }
  );

  const [deleteCompanyPost, { loading: deleteCompanyPostLoading }] =
    useMutation(CompanyUpdatesDeleteDocument, {
      onCompleted: (data) => {
        setLazyLoadingIsAllowed(true);
        const error = data.companyUpdatesDelete?.error;

        if (error) {
          showError(error);
        } else {
          onItemActionCompleted();
        }
      },
    });

  const [pinActivity, { loading: pinLoading }] = useMutation(PinDocument, {
    onCompleted: (data) => {
      setLazyLoadingIsAllowed(true);
      const error = data.postingsPinActivityByTarget?.error;

      if (error) {
        showError(error);
      } else {
        onItemActionCompleted();

        if (itemId && focusType) trackPinAction({ itemId, pageId, focusType });
      }
    },
  });

  // eslint-disable-next-line no-unused-vars
  const [unpinActivity, { loading: unpinLoading }] = useMutation(
    UnpinDocument,
    {
      onCompleted: (data) => {
        setLazyLoadingIsAllowed(true);
        const error = data?.postingsUnpinActivityByTarget?.error;

        if (error) {
          showError(error);
        } else {
          onItemActionCompleted();

          if (itemId && focusType) {
            trackUnpinAction({ itemId, pageId, focusType });
          }
        }
      },
    }
  );

  const [hidePosting, { loading: hideLoading }] = useMutation(
    HidePostingDocument,
    {
      onCompleted: (data) => {
        const error = data?.postingsHidePosting?.error;

        if (error) {
          showError(error);
        } else {
          onItemActionCompleted();
          setLazyLoadingIsAllowed(true);
          if (itemId && focusType)
            trackHideAction({ itemId, pageId, focusType });
        }
      },
    }
  );

  const [unhidePosting, { loading: unhideLoading }] = useMutation(
    UnhidePostingDocument,
    {
      onCompleted: (data) => {
        const error = data?.postingsUnhidePosting?.error;

        if (error) {
          showError(error);
        } else {
          onItemActionCompleted();
          if (itemId && focusType) {
            trackUnhideAction({ itemId, pageId, focusType });
          }
        }
      },
    }
  );

  const actionsDescriptionObj = {
    delete: {
      headline: 'NEWS_MODULE_DIALOG_DELETE_HEADLINE',
      text: 'NEWS_MODULE_DIALOG_DELETE_TEXT',
      cancel: 'NEWS_MODULE_DIALOG_DELETE_NO',
      confirm: 'NEWS_MODULE_DIALOG_DELETE_YES',
      action: () => {
        setIsItemLoading(true);
        if (articleTypename === CONTENT_FEED_TYPE_GENERIC) {
          itemId && deletePosting({ variables: { id: itemId } });
        }
        if (articleTypename === CONTENT_FEED_TYPE_COMPANY_ARTICLE) {
          const companyId = pageContext?.companyId;
          companyId &&
            itemId &&
            deleteCompanyPost({
              variables: { companyId, updateId: itemId },
            });
        }
      },
    },
    pin: {
      action: () => {
        setIsItemLoading(true);
        isPaidPage &&
          pinActivity({
            variables: {
              activityId: getIDfromURN(activityId),
              targetGlobalId: moduleContext?.moduleGlobalId,
            },
          });
      },
    },
    unpin: {
      headline: 'NEWS_MODULE_DIALOG_UNPIN_HEADLINE',
      text: 'NEWS_MODULE_DIALOG_UNPIN_TEXT',
      cancel: 'NEWS_MODULE_DIALOG_UNPIN_NO',
      confirm: 'NEWS_MODULE_DIALOG_UNPIN_YES',
      action: () => {
        setIsItemLoading(true);
        isPaidPage &&
          unpinActivity({
            variables: {
              targetGlobalId: moduleContext?.moduleGlobalId,
            },
          });
      },
    },
    hide: {
      headline: 'NEWS_MODULE_DIALOG_HIDE_HEADLINE',
      text: 'NEWS_MODULE_DIALOG_HIDE_TEXT',
      cancel: 'NEWS_MODULE_DIALOG_HIDE_NO',
      confirm: 'NEWS_MODULE_DIALOG_HIDE_YES',
      action: () => {
        setIsItemLoading(true);
        itemId && hidePosting({ variables: { id: itemId } });
      },
    },
    unhide: {
      headline: 'NEWS_MODULE_DIALOG_UNHIDE_HEADLINE',
      text: 'NEWS_MODULE_DIALOG_UNHIDE_TEXT',
      cancel: 'NEWS_MODULE_DIALOG_UNHIDE_NO',
      confirm: 'NEWS_MODULE_DIALOG_UNHIDE_YES',
      action: () => {
        setIsItemLoading(true);
        itemId && unhidePosting({ variables: { id: itemId } });
      },
    },
    edit: {
      action: () => {
        onEditButtonClick();
      },
    },
  };

  const isLoading =
    deleteLoading ||
    unpinLoading ||
    pinLoading ||
    unhideLoading ||
    hideLoading ||
    deleteCompanyPostLoading;

  const restrictions = {
    pin: !isPaidPage || !activityId || isLoading || publicationState === HIDDEN,
    hide:
      articleTypename !== CONTENT_FEED_TYPE_GENERIC ||
      hideLoading ||
      unhideLoading ||
      publicationState === DRAFT ||
      publicationState === SCHEDULED,
    delete:
      articleTypename === CONTENT_FEED_TYPE_ARTICLE ||
      articleTypename === CONTENT_FEED_TYPE_NEW_ARTICLE ||
      isLoading,
    edit: articleTypename !== CONTENT_FEED_TYPE_GENERIC || isLoading,
  };

  const handlePopOverAction = (action: () => void) => {
    if (restrictions.pin) action();
  };

  return (
    <>
      <Styled.ActorActions
        isDesktop={true}
        data-testid="POSTING_ACTIONS"
        id={`POSTING_${itemId}`}
      >
        <>
          <Styled.EditButton
            size={'small'}
            icon={IconEdit}
            disabled={restrictions.edit}
            data-testid={'EDIT_POSTING'}
            onClick={() => {
              actionsDescriptionObj.edit.action();
            }}
          />
          {publicationState !== SCHEDULED && (
            <Styled.EditButton
              size={'small'}
              icon={isPinned ? IconStarFilled : IconStar}
              ariaLabel={isPinned ? 'Star filled' : 'Star empty'}
              data-testid={'PIN_POSTING'}
              onClick={() => {
                if (isPinned) {
                  setActionConfirmDialog(actionsDescriptionObj.unpin);
                } else {
                  actionsDescriptionObj.pin.action();
                }
              }}
              innerRef={popOver.triggerRef}
              onMouseOver={() => handlePopOverAction(popOver.handleShow)}
              onMouseLeave={() => handlePopOverAction(popOver.handleHide)}
              disabled={restrictions.pin}
            />
          )}
          <Styled.EditButton
            size={'small'}
            data-testid={'HIDE_POSTING'}
            onClick={() => {
              if (publicationState === HIDDEN) {
                actionsDescriptionObj.unhide.action();
              } else {
                setActionConfirmDialog(actionsDescriptionObj.hide);
              }
            }}
            icon={publicationState === PUBLISHED ? IconHide : IconShow}
            ariaLabel={publicationState === PUBLISHED ? 'Hide' : 'Show'}
            disabled={restrictions.edit}
          />
          <Styled.EditButton
            size={'small'}
            icon={IconTrash}
            ariaLabel={'Trash'}
            data-testid={'DELETE_POSTING'}
            onClick={() => {
              setActionConfirmDialog(actionsDescriptionObj.delete);
            }}
            disabled={restrictions.delete}
          />
          <Styled.PromoteButton
            icon={IconReaction}
            ariaLabel={'Reaction'}
            data-testid={'PROMOTE_POSTING'}
            variant={'primary'}
            size={'small'}
            disabled={!xamUrl}
            href={xamUrl}
            // @ts-expect-error FIXME: SC6
            target={'_blank'}
          >
            <>{intl.formatMessage({ id: `NEWS_MODULE_PROMOTE_BUTTON` })}</>
          </Styled.PromoteButton>
          <UpsellPopOver moduleName="newsmodule" popOver={popOver} />
        </>
      </Styled.ActorActions>
      <Styled.ActorActions
        isDesktop={false}
        id={`POSTING_${itemId}`}
        data-testid="POSTING_ACTIONS"
      >
        <Styled.EditButton
          data-testid={'NEWS_ITEM_ACTION_BUTTON'}
          size={'small'}
          icon={IconEdit}
          onClick={() => setShow(true)}
        />
        <Styled.PromoteButton
          icon={IconReaction}
          ariaLabel="Reaction"
          data-testid={'PROMOTE_POSTING'}
          variant={'primary'}
          size={'small'}
          disabled={!xamUrl}
          href={xamUrl}
          // @ts-expect-error FIXME: SC6
          target={'_blank'}
        />
      </Styled.ActorActions>
      {show && (
        <BottomSheet
          dimmerTitle="close"
          show={show}
          onOutsideClick={() => setShow(false)}
          data-testid={'NEWS_ITEM_ACTION_SHEET'}
        >
          <List>
            <ListItem>
              <Button
                size={'medium'}
                icon={IconEdit}
                disabled={restrictions.edit}
                data-testid={'EDIT_POSTING'}
                onClick={() => {
                  setShow(false);
                  actionsDescriptionObj.edit.action();
                }}
              >
                {intl.formatMessage({ id: 'NEWS_MODULE_EDIT_LABEL' })}
              </Button>
            </ListItem>
            <ListItem>
              <TextButton
                size={'medium'}
                icon={isPinned ? IconStarFilled : IconStar}
                // @ts-expect-error FIXME: SC6
                ariaLabel={isPinned ? 'Star filled' : 'Star empty'}
                data-testid={'PIN_POSTING'}
                innerRef={popOver.triggerRef}
                onClick={() => {
                  setShow(false);
                  if (isPinned) {
                    setActionConfirmDialog(actionsDescriptionObj.unpin);
                  } else {
                    actionsDescriptionObj.pin.action();
                  }
                }}
                onMouseOver={() => {
                  handlePopOverAction(popOver.handleShow);
                }}
                onMouseLeave={() => handlePopOverAction(popOver.handleHide)}
                disabled={restrictions.pin}
              >
                {intl.formatMessage({
                  id: isPinned
                    ? 'NEWS_MODULE_UNPIN_BUTTON'
                    : 'NEWS_MODULE_PIN_BUTTON',
                })}
              </TextButton>
            </ListItem>
            <ListItem>
              <TextButton
                size={'medium'}
                icon={IconShow}
                data-testid={'HIDE_POSTING'}
                onClick={() => {
                  setShow(false);
                  if (publicationState === HIDDEN) {
                    actionsDescriptionObj.unhide.action();
                  } else {
                    setActionConfirmDialog(actionsDescriptionObj.hide);
                  }
                }}
                disabled={restrictions.hide}
              >
                {intl.formatMessage({
                  id:
                    publicationState === HIDDEN
                      ? 'NEWS_MODULE_SHOW_BUTTON'
                      : 'NEWS_MODULE_HIDE_BUTTON',
                })}
              </TextButton>
            </ListItem>
            <ListItem>
              <TextButton
                size={'medium'}
                icon={IconTrash}
                data-testid={'DELETE_POSTING'}
                disabled={restrictions.delete}
                onClick={() => {
                  setShow(false);
                  setActionConfirmDialog(actionsDescriptionObj.delete);
                }}
              >
                {intl.formatMessage({ id: 'NEWS_MODULE_DELETE_LABEL' })}
              </TextButton>
            </ListItem>
            <UpsellPopOver moduleName="newsmodule" popOver={popOver} />
          </List>
        </BottomSheet>
      )}
      <Styled.Dialog
        show={Boolean(actionConfirmDialog)}
        // @ts-expect-error FIXME: SC6
        testId={'POSTING_DIALOG'}
        isDestructive
        flavour="xdl"
        headline={
          actionConfirmDialog?.headline
            ? intl.formatMessage({ id: actionConfirmDialog?.headline })
            : ''
        }
        text={
          actionConfirmDialog?.text
            ? intl.formatMessage({ id: actionConfirmDialog?.text })
            : ''
        }
        cancelLabel={
          actionConfirmDialog?.cancel
            ? intl.formatMessage({ id: actionConfirmDialog?.cancel })
            : ''
        }
        confirmLabel={
          actionConfirmDialog?.confirm
            ? intl.formatMessage({
                id: actionConfirmDialog?.confirm,
              })
            : ''
        }
        onCancel={() => {
          setActionConfirmDialog(null);
        }}
        onConfirm={() => {
          actionConfirmDialog?.action();
          setActionConfirmDialog(null);
        }}
      />
    </>
  );
};
