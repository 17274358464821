import type { FC, PropsWithChildren } from 'react';
import { Children } from 'react';

import { useAutocompleteState } from './autocomplete-context';
import * as Styled from './autocomplete-list.styles';

type AutocompleteListProps = {
  skin?: { [key: string]: string };
};
export const AutocompleteList: FC<PropsWithChildren<AutocompleteListProps>> = ({
  children,
  ...rest
}) => {
  const { isOpen, getMenuProps }: { isOpen?: boolean; getMenuProps?: any } =
    useAutocompleteState();

  if (!isOpen || Children.count(children) === 0) return null;

  return (
    <Styled.ListWrapper>
      <Styled.List {...rest} {...getMenuProps()}>
        {children}
      </Styled.List>
    </Styled.ListWrapper>
  );
};
