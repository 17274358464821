import { Children, cloneElement, type FC, type PropsWithChildren } from 'react';

import { useAutocompleteState } from './autocomplete-context';

export const AutocompleteInput: FC<PropsWithChildren> = ({ children }) => {
  const { getInputProps }: { getInputProps?: any } = useAutocompleteState();

  return (
    <>
      {Children.map(children, (child: any) =>
        cloneElement(child, {
          ...getInputProps({
            refKey: 'innerRef',
          }),
        })
      )}
    </>
  );
};
