import { useEffect, useRef } from 'react';

export const useUpdateEffect: typeof useEffect = (callback, deps) => {
  const isFirstTimeMounted = useRef(true);

  useEffect(() => {
    if (!isFirstTimeMounted.current) {
      callback();
    } else {
      isFirstTimeMounted.current = false;
    }
  }, deps);
};
