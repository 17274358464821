import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStatusCode } from '@xing-com/crate-xinglet';

import ERROR_404_IMAGE from './assets/404.svg';
import ERROR_500_IMAGE from './assets/500.svg';
import ERROR_503_IMAGE from './assets/503.svg';
import * as Styled from './page-error.style';

const errors = {
  404: {
    headline: 'EP_404_ERROR',
    subline: 'EP_404_ERROR_SUBLINE',
    image: ERROR_404_IMAGE,
  },
  500: {
    headline: 'EP_500_ERROR',
    subline: 'EP_500_ERROR_SUBLINE',
    image: ERROR_500_IMAGE,
  },
  503: {
    headline: 'EP_503_ERROR',
    subline: 'EP_503_ERROR_SUBLINE',
    image: ERROR_503_IMAGE,
  },
};

type PageErrorProps = {
  type?: keyof typeof errors;
  href?: string;
  ctaCopyKey?: string;
};

export const PageError: FC<PageErrorProps> = ({
  type = 500,
  href = '/',
  ctaCopyKey = 'EP_GO_HOME',
}) => {
  useStatusCode(type);

  return (
    <Styled.Wrapper>
      <Styled.Image src={errors[type].image} alt="Error image" />
      <Styled.Headline size={'medium'}>
        <FormattedMessage
          id={errors[type].headline}
          defaultMessage={errors[type].headline}
        />
      </Styled.Headline>

      <Styled.Text size={'xsmall'} noMargin>
        <FormattedMessage
          id={errors[type].subline}
          defaultMessage={errors[type].subline}
        />
      </Styled.Text>

      <Styled.Button variant={'secondary'} size={'small'} href={href}>
        <FormattedMessage id={ctaCopyKey} defaultMessage={ctaCopyKey} />
      </Styled.Button>
    </Styled.Wrapper>
  );
};
