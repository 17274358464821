import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { Slider as DefaultSlider } from '@xing-com/slider';
import {
  scale080,
  scale110,
  spaceL,
  spaceS,
  spaceXL,
  spaceXXL,
  xdlColorBorderSoft,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const CropContainer = styled.div`
  height: 400px;
  position: relative;
  width: 740px;
`;

export const CropCtaContainer = styled.div<{ $noChildren?: boolean }>`
  border-top: 2px solid ${xdlColorBorderSoft};
  display: flex;
  justify-content: flex-end;
  ${({ $noChildren }) =>
    $noChildren
      ? `margin: ${spaceXXL} 0;`
      : `margin: var(--spaceXXL) 0;
  `}
  padding-top: ${scale110};
`;

export const CancelCta = styled(Button)`
  margin-right: ${scale080};
`;

export const ChildrenContainer = styled.div`
  margin: ${spaceL} 0;
`;

export const SliderText = styled(BodyCopy)`
  margin: 0 ${spaceS};
`;

export const Controls = styled.div`
  display: flex;
  margin: ${spaceXL} auto 0;
  width: 400px;
`;

export const Slider = styled(DefaultSlider)`
  flex: 1;
  height: 22px;
`;
