export const countryCode: { [key: string]: number } = {
  AF: 93,
  AL: 355,
  DZ: 213,
  AS: 1684,
  AD: 376,
  AO: 244,
  AI: 1264,
  AQ: 672,
  BV: 47,
  HM: 0,
  TF: 262,
  UM: 246,
  EH: 212,
  AG: 1268,
  AR: 54,
  AM: 374,
  AW: 297,
  AZ: 994,
  AU: 61,
  BS: 1242,
  BH: 973,
  BD: 8806,
  BB: 1246,
  BE: 32,
  BZ: 501,
  BJ: 229,
  BM: 1441,
  BT: 975,
  BO: 591,
  BA: 387,
  BW: 267,
  BR: 55,
  IO: 246,
  BN: 673,
  BG: 359,
  BF: 226,
  MM: 95,
  BI: 257,
  KY: 345,
  CL: 56,
  CN: 86,
  CK: 682,
  CR: 506,
  CI: 225,
  DE: 49,
  CD: 243,
  DM: 1767,
  DO: 1849,
  DJ: 253,
  DK: 45,
  EC: 593,
  SV: 503,
  ER: 291,
  EE: 372,
  FK: 500,
  FO: 298,
  FJ: 679,
  FI: 358,
  FR: 33,
  GF: 594,
  PF: 689,
  GA: 241,
  GM: 220,
  GE: 995,
  GH: 233,
  GI: 350,
  GD: 1473,
  GR: 30,
  GL: 299,
  GP: 590,
  GU: 1671,
  GT: 502,
  GG: 44,
  GN: 224,
  GW: 245,
  GY: 595,
  HT: 509,
  HN: 504,
  HK: 852,
  IN: 91,
  ID: 62,
  IQ: 964,
  IR: 98,
  IE: 353,
  IS: 354,
  IM: 44,
  IL: 972,
  IT: 39,
  JM: 1876,
  JP: 81,
  YE: 967,
  JE: 44,
  JO: 962,
  VG: 1284,
  VI: 1340,
  KH: 855,
  CM: 237,
  CA: 1,
  CV: 238,
  LC: 1758,
  KZ: 77,
  QA: 974,
  KE: 254,
  KG: 996,
  KI: 686,
  CC: 61,
  CO: 57,
  KM: 269,
  CG: 242,
  HR: 385,
  CU: 53,
  KW: 965,
  LA: 856,
  LS: 266,
  LV: 371,
  LB: 961,
  LR: 231,
  LY: 218,
  LI: 423,
  LT: 370,
  LU: 352,
  MO: 853,
  MG: 261,
  MW: 265,
  MY: 60,
  MV: 960,
  ML: 223,
  MT: 356,
  MA: 212,
  MH: 692,
  MQ: 596,
  MR: 222,
  MU: 230,
  YT: 262,
  MK: 389,
  MX: 52,
  FM: 691,
  MD: 373,
  MC: 377,
  MN: 976,
  ME: 382,
  MS: 1664,
  MZ: 258,
  NA: 264,
  NR: 674,
  NP: 977,
  NC: 687,
  NZ: 64,
  NI: 505,
  NL: 31,
  AN: 599,
  NE: 227,
  NG: 234,
  NU: 683,
  KP: 850,
  NF: 672,
  NO: 47,
  MP: 1670,
  OM: 968,
  TL: 670,
  PK: 92,
  PW: 680,
  PS: 970,
  PA: 507,
  PG: 675,
  PY: 595,
  PE: 51,
  PH: 63,
  PN: 872,
  PL: 48,
  PT: 351,
  PR: 1939,
  RE: 262,
  RW: 250,
  RO: 40,
  RU: 7,
  KN: 1869,
  PM: 508,
  VC: 1784,
  SB: 677,
  WS: 685,
  SM: 378,
  SH: 290,
  ST: 239,
  SA: 966,
  SE: 46,
  CH: 41,
  SN: 221,
  RS: 381,
  SC: 248,
  SL: 232,
  SG: 65,
  SK: 421,
  SI: 386,
  SO: 252,
  ES: 34,
  LK: 94,
  SD: 249,
  SR: 597,
  SJ: 47,
  SZ: 268,
  SY: 963,
  ZA: 27,
  GS: 500,
  KR: 82,
  TJ: 992,
  TW: 886,
  TZ: 255,
  TH: 66,
  TG: 228,
  TK: 690,
  TO: 676,
  TT: 1868,
  TD: 235,
  CZ: 420,
  TN: 216,
  TM: 993,
  TC: 1649,
  TV: 688,
  TR: 90,
  UG: 256,
  UA: 380,
  HU: 36,
  UY: 598,
  UZ: 998,
  VU: 678,
  VA: 379,
  VE: 58,
  AE: 971,
  US: 1,
  GB: 44,
  VN: 84,
  WF: 681,
  CX: 61,
  BY: 375,
  ZM: 260,
  CF: 236,
  ZW: 263,
  CY: 357,
  EG: 20,
  AX: 358,
  GQ: 240,
  ET: 251,
  AT: 43,
};
