import type React from 'react';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { Editor, EditorWrapper } from '@xing-com/content-editor-core-editor';
import { Plugin as BoldPlugin } from '@xing-com/content-editor-plugin-bold';
import {
  H2,
  H3,
  H4,
  Plugin as HeadingPlugin,
} from '@xing-com/content-editor-plugin-heading';
import { Plugin as ItalicPlugin } from '@xing-com/content-editor-plugin-italic';
import { Plugin as LinkPlugin } from '@xing-com/content-editor-plugin-link';
import { Plugin as ListItemPlugin } from '@xing-com/content-editor-plugin-list-item';
import { Plugin as OrderedListPlugin } from '@xing-com/content-editor-plugin-ordered-list';
import { Plugin as ParagraphPlugin } from '@xing-com/content-editor-plugin-paragraph';
import { Plugin as TitlePlugin } from '@xing-com/content-editor-plugin-title';
import { Plugin as UnorderedListPlugin } from '@xing-com/content-editor-plugin-unordered-list';
import type {
  ArticleOrderedListItem,
  ArticleUnorderedListItem,
} from '@xing-com/crate-common-graphql-types';

import * as Styled from './article-editor.styles';
import { getButtons, dropdown } from './toolbar-config';

type ArticleEditorProps = {
  value?: any;
  allowedProperties?: {
    title: boolean;
    bold: boolean;
    italic: boolean;
    links: boolean;
    headings: {
      h2: boolean;
      h3: boolean;
      h4: boolean;
    };
    orderedList: ArticleOrderedListItem | boolean;
    unorderedList: ArticleUnorderedListItem | boolean;
  };
  setValue: (param: React.ReactNode) => void;
  copyKeys?: {
    titlePlaceholder?: string;
    paragraphPlaceholder?: string;
  };
};
export const ArticleEditor: FC<ArticleEditorProps> = ({
  value,
  setValue,
  allowedProperties,
  copyKeys = {
    titlePlaceholder: 'EP_EDITOR_TITLE_PLACEHOLDER',
    paragraphPlaceholder: 'EP_EDITOR_BODY_PLACEHOLDER',
  },
}) => {
  const intl = useIntl();

  const articleEditorProperties = allowedProperties || {
    title: true,
    bold: true,
    italic: true,
    links: true,
    headings: {
      h2: true,
      h3: true,
      h4: true,
    },
    orderedList: true,
    unorderedList: true,
  };

  const buttons = getButtons(articleEditorProperties);

  const PLUGINS = [
    articleEditorProperties.title &&
      TitlePlugin({
        placeholder: intl.formatMessage({
          id: copyKeys.titlePlaceholder,
          defaultMessage: copyKeys.titlePlaceholder,
        }),
      }),
    ParagraphPlugin({
      placeholder: intl.formatMessage({
        id: copyKeys.paragraphPlaceholder,
        defaultMessage: copyKeys.paragraphPlaceholder,
      }),
    }),
    articleEditorProperties.bold && BoldPlugin(),
    articleEditorProperties.links && LinkPlugin(),
    (articleEditorProperties.orderedList ||
      articleEditorProperties.unorderedList) &&
      ListItemPlugin(),
    articleEditorProperties.orderedList && OrderedListPlugin(),
    articleEditorProperties.unorderedList && UnorderedListPlugin(),
    articleEditorProperties.italic && ItalicPlugin(),
    articleEditorProperties.headings?.h2 && HeadingPlugin({ level: H2 }),
    articleEditorProperties.headings?.h3 && HeadingPlugin({ level: H3 }),
    articleEditorProperties.headings?.h4 && HeadingPlugin({ level: H4 }),
  ].filter((plugin) => plugin !== false && plugin !== undefined);

  return (
    <div data-testid="ARTICLE_EDITOR">
      {value?.value && (
        <EditorWrapper
          initialValue={value?.value}
          onChange={(newValue: React.ReactNode) => setValue(newValue)}
          plugins={PLUGINS}
        >
          <Styled.Toolbar
            buttons={buttons}
            // @ts-expect-error TODO: fix this type
            dropdown={dropdown}
          />
          <Editor plugins={PLUGINS} />
        </EditorWrapper>
      )}
    </div>
  );
};
