import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { SkeletonHeadline } from '@xing-com/skeleton';
import { spaceXL, spaceXXL } from '@xing-com/tokens';

export const Container = styled.div`
  display: flex;

  @media (min-width: 740px) and (max-width: 1024px) {
    display: block;
  }

  @media ${mediaSmallOrTiny} {
    display: block;
  }
`;

export const Summary = styled.div`
  flex: 1;
`;

export const Facts = styled.div`
  width: 240px;
  margin-left: 90px;

  @media (min-width: 740px) and (max-width: 1024px) {
    width: 70%;
    margin: ${spaceXXL} 0 0 0;
  }

  @media ${mediaSmallOrTiny} {
    width: 70%;
    margin: ${spaceXXL} 0 0 0;
  }
`;

export const FactsItem = styled(SkeletonHeadline)`
  margin-bottom: ${spaceXL};
`;
