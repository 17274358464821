import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Portal } from '@xing-com/portal';

import { DialogPrimitive } from './dialog.primitive';
import * as Styled from './dialog.styles';
import type { DialogProps } from './dialog.types';
const {
  dialogDuration,
  dialogTransitionName,
  dimmerDuration,
  dimmerTransitionName,
} = Styled;

export const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      cancelLabel,
      confirmLabel,
      headline,
      isDestructive,
      loading,
      onCancel,
      onConfirm,
      keepDialogInDom,
      show = false,
      text,
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    React.useEffect(() => {
      const handleKey = (event: KeyboardEvent): void => {
        if (show && onCancel && event.key === 'Escape') {
          event.preventDefault();
          onCancel();
        }

        if (show && onConfirm && event.key === 'Enter') {
          event.preventDefault();
          onConfirm();
        }
      };

      document.addEventListener('keydown', handleKey);

      return () => {
        document.removeEventListener('keydown', handleKey);
      };
    }, [onCancel, onConfirm, show]);

    const showCancelAction = Boolean(cancelLabel && onCancel);

    const DialogTransition = (
      <CSSTransition
        appear={show}
        classNames={dialogTransitionName}
        in={show}
        key="dialog"
        mountOnEnter={!keepDialogInDom}
        timeout={parseInt(dialogDuration)}
        unmountOnExit={!keepDialogInDom}
      >
        <Styled.Container {...props}>
          <DialogPrimitive
            cancelLabel={cancelLabel}
            confirmLabel={confirmLabel}
            headline={headline}
            isDestructive={isDestructive}
            loading={loading}
            onCancel={onCancel}
            onConfirm={onConfirm}
            ref={forwardedRef}
            showCancelAction={showCancelAction}
            text={text}
            variant={variant}
          />
        </Styled.Container>
      </CSSTransition>
    );

    const DimmerTransition = (
      <CSSTransition
        appear={show}
        classNames={dimmerTransitionName}
        in={show}
        key="dimmer"
        mountOnEnter
        timeout={parseInt(dimmerDuration)}
        unmountOnExit
      >
        <Styled.Dimmer data-testid="dialog-dimmer" data-qa="dialog-dimmer" />
      </CSSTransition>
    );

    return (
      <React.Fragment>
        <Portal>{DialogTransition}</Portal>
        {DimmerTransition}
      </React.Fragment>
    );
  }
);

Dialog.displayName = 'Dialog';
