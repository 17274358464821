import * as React from 'react';

import type { NotificationBadgeProps } from '@xing-com/badge';

import * as Styled from './tag.styles';
import type { ProfileProps } from './tag.types';

type TagContentProps = {
  badgeProps?: NotificationBadgeProps;
  children?: React.ReactNode;
  disabled?: boolean;
  leadingIcon?: React.ReactNode;
  maxWidth?: string;
  profile?: ProfileProps;
  selected?: boolean;
  trailingIcon?: React.ReactNode;
};

export const TagContent = ({
  badgeProps,
  children,
  disabled,
  leadingIcon,
  maxWidth,
  profile,
  selected,
  trailingIcon,
}: TagContentProps): JSX.Element => (
  <React.Fragment>
    {leadingIcon && <Styled.IconContainer>{leadingIcon}</Styled.IconContainer>}
    {profile && (
      <Styled.ProfileImageContainer>
        <Styled.Profile
          $disabled={disabled}
          profileName={profile.name}
          src={profile.image}
          size="xxsmall"
        />
      </Styled.ProfileImageContainer>
    )}
    {children && (
      <Styled.TextTruncationWrapper>
        <Styled.TextTruncation $maxWidth={maxWidth}>
          {children}
        </Styled.TextTruncation>
      </Styled.TextTruncationWrapper>
    )}
    {/* Badge is only possible in selected state */}
    {badgeProps && selected && !disabled && (
      <Styled.BadgeContainer>
        <Styled.Badge {...badgeProps} />
      </Styled.BadgeContainer>
    )}
    {trailingIcon && (
      <Styled.IconContainer>{trailingIcon}</Styled.IconContainer>
    )}
  </React.Fragment>
);
