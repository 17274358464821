/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AboutUsDescriptionQueryVariables = Types.Exact<{
  id: Types.Scalars['SlugOrID']['input'];
}>;

export type AboutUsDescriptionQuery = {
  __typename?: 'Query';
  pagesAboutUs?:
    | {
        __typename?: 'AboutEntity';
        headline?: string | null;
        summary?: string | null;
        aboutArticle?: {
          __typename?: 'ContentServiceContent';
          globalId: string;
          header?: {
            __typename?: 'ArticleHeader';
            title: { __typename?: 'ArticleTitle'; text: string };
          } | null;
          blocks: Array<
            | { __typename?: 'ArticleBodyImage' }
            | { __typename?: 'ArticleEmphasis' }
            | {
                __typename?: 'ArticleH2';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
            | {
                __typename?: 'ArticleH3';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
            | {
                __typename?: 'ArticleH4';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
            | {
                __typename?: 'ArticleOrderedListItem';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
            | {
                __typename?: 'ArticleParagraph';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
            | { __typename?: 'ArticleQuote' }
            | {
                __typename?: 'ArticleUnorderedListItem';
                text: string;
                markups: Array<
                  | {
                      __typename: 'ArticleBoldMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleItalicMarkup';
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleLinkMarkup';
                      href: string;
                      start: number;
                      end: number;
                    }
                  | {
                      __typename: 'ArticleMentionMarkup';
                      start: number;
                      end: number;
                    }
                >;
              }
          >;
        } | null;
      }
    | { __typename?: 'EntityPageError'; errorType: string; errorCode: number }
    | null;
};

export const AboutUsDescriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AboutUsDescription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pagesAboutUs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AboutEntity' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'headline' },
                        name: { kind: 'Name', value: 'aboutHeadline' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'summary' },
                        name: { kind: 'Name', value: 'aboutSummary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aboutArticle' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'supportedBlockTypes',
                            },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'EnumValue',
                                  value: 'ARTICLE_PARAGRAPH',
                                },
                                { kind: 'EnumValue', value: 'ARTICLE_H2' },
                                { kind: 'EnumValue', value: 'ARTICLE_H3' },
                                { kind: 'EnumValue', value: 'ARTICLE_H4' },
                                {
                                  kind: 'EnumValue',
                                  value: 'ARTICLE_UNORDERED_LIST_ITEM',
                                },
                                {
                                  kind: 'EnumValue',
                                  value: 'ARTICLE_ORDERED_LIST_ITEM',
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'globalId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'header' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'blocks' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'EPArticleBlockFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EntityPageError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EPArticleBlockFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ArticleBlock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleParagraph' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleH2' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleH3' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleH4' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleUnorderedListItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticleOrderedListItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ArticleLinkMarkup' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AboutUsDescriptionQuery,
  AboutUsDescriptionQueryVariables
>;
