import type React from 'react';

import { IconSearch } from '@xing-com/icons';

import * as Styled from './search-bar.styles';

type SearchBarProps = {
  value: string;
  onChange: (val: string) => void;
  placeholder: string;
};

export const SearchBar: React.FC<SearchBarProps> = ({
  value,
  onChange,
  placeholder,
}) => (
  <Styled.Wrapper>
    <Styled.SearchIcon>
      <IconSearch width="16" height="16" />
    </Styled.SearchIcon>
    <Styled.InputWrapper>
      {/* @ts-expect-error FIXME: SC6 */}
      <Styled.InputBar
        placeholder={placeholder}
        onKeyDown={(e: KeyboardEvent) => {
          e.stopPropagation();
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          onChange(e.target.value);
        }}
        value={value}
      />
    </Styled.InputWrapper>
  </Styled.Wrapper>
);
