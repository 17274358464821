import type { FC } from 'react';

import { TextButton } from '@xing-com/button';
import { IconVideo } from '@xing-com/icons';

import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';
import { trackAction } from '../../tracking';

export const CommboxVideoButton: FC = () => {
  const { enableLeftToolbar } = useCommboxPermissions();

  const { setAttachmentType, setIsVideoUploadOpen } = useCommboxContext();

  const disabled = !enableLeftToolbar;

  return (
    <TextButton
      {...{ disabled }}
      data-testid="uploadVideoBtn"
      icon={IconVideo}
      onClick={() => {
        setAttachmentType('BLOCKED');
        setIsVideoUploadOpen(true);
        trackAction({
          PropTrackAction: 'social_share_commbox_video_source_open',
        });
      }}
      size="medium"
    />
  );
};
