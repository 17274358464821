import styled, { css } from 'styled-components';

export const Marker = styled.div`
  height: 30px;
  width: 30px;
  margin-top: -15px;
  margin-left: -15px;
`;

export const MapWrapper = styled.div<{ $mapLoaded: boolean }>`
  visibility: hidden;
  height: 100%;
  width: 100%;

  ${({ $mapLoaded }) =>
    $mapLoaded &&
    css`
      visibility: visible;
    `}

  .gm-err-container {
    display: none;
  }
`;
