import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';

import type { ItemSnapPosition } from './item.types';

type ItemTransientProps = {
  $itemMaxWidth?: string;
  $itemMinWidth?: string;
  $itemSnapPosition?: ItemSnapPosition;
  $itemSpacing?: string;
  $itemSpacingConfined?: string;
  $itemSpacingWide?: string;
  $itemWidth?: string;
  $itemWidthConfined?: string;
  $itemWidthWide?: string;
  $lastItemSpace?: string;
  $lastItemSpaceConfined?: string;
  $lastItemSpaceWide?: string;
};

// *** Item Snap Position ***
export const itemSnapPositions = {
  left: css`
    scroll-snap-align: start;
  `,
  center: css`
    scroll-snap-align: center;
  `,
  right: css`
    scroll-snap-align: end;
  `,
};

/**
 * Fake padding on last item
 *
 * To be able to add a right padding to an overflow
 * container, an after element needs to be added that
 * simulates the padding. */
const fakePadding = css`
  content: '';
  display: flex;
  height: 1px;
  position: absolute;
`;

export const Item = styled.div<ItemTransientProps>`
  flex-shrink: 0;
  position: relative;

  &:last-child {
    && {
      margin-inline-end: 0;
    }
  }

  ${(props) => css`
    ${props.$itemSpacing &&
    css`
      margin-inline-end: ${props.$itemSpacing};
    `};

    ${props.$itemSpacingConfined &&
    css`
      @media ${mediaConfined} {
        margin-inline-end: ${props.$itemSpacingConfined};
      }
    `};

    ${props.$itemSpacingWide &&
    css`
      @media ${mediaWide} {
        margin-inline-end: ${props.$itemSpacingWide};
      }
    `};

    ${props.$itemWidth &&
    css`
      width: ${props.$itemWidth};
    `};

    ${props.$itemWidthConfined &&
    css`
      @media ${mediaConfined} {
        width: ${props.$itemWidthConfined};
      }
    `};

    ${props.$itemWidthWide &&
    css`
      @media ${mediaWide} {
        width: ${props.$itemWidthWide};
      }
    `};

    ${props.$itemMaxWidth &&
    css`
      max-width: ${props.$itemMaxWidth};
    `};

    ${props.$itemMinWidth &&
    css`
      min-width: ${props.$itemMinWidth};
    `};

    ${props.$itemSnapPosition && itemSnapPositions[props.$itemSnapPosition]};

    ${props.$lastItemSpace &&
    css`
      &:last-child::after {
        ${fakePadding};
        inset-inline-end: -${props.$lastItemSpace};
        width: ${props.$lastItemSpace};
      }
    `};

    ${props.$lastItemSpaceConfined &&
    css`
      @media ${mediaConfined} {
        &:last-child::after {
          ${fakePadding};
          inset-inline-end: -${props.$lastItemSpaceConfined};
          width: ${props.$lastItemSpaceConfined};
        }
      }
    `};

    ${props.$lastItemSpaceWide &&
    css`
      @media ${mediaWide} {
        &:last-child::after {
          ${fakePadding};
          inset-inline-end: -${props.$lastItemSpaceWide};
          width: ${props.$lastItemSpaceWide};
        }
      }
    `};
  `};
`;
