import type { SelectableAudienceType } from '@xing-com/audience-selection/lib/types';
import { articleSerializer } from '@xing-com/content-editor-serializer';
import type {
  ArticlesCreateArticleBlocksInput,
  InputMaybe,
} from '@xing-com/crate-common-graphql-types';
import type { PollDataType, PollPreviewType } from '@xing-com/poll';

import type {
  CommboxApplicationType,
  CommboxAttachmentType,
  CommboxErrorMessageType,
  CommboxImagesType,
  CommboxOnPostType,
  CommboxPostTypeTrackingType,
} from '../types';

export type AudienceSurnForType = (
  audienceId: SelectableAudienceType['id'],
  userId: string
) => string[];

export const audienceSurnFor: AudienceSurnForType = (audienceId, userId) => {
  if (audienceId === 'FOLLOWER')
    return [`surn:x-xing:content:insider:${userId}:followers`];

  if (audienceId === 'FOLLOWER_CONTACTS')
    return [
      `surn:x-xing:content:insider:${userId}:followers`,
      `surn:x-xing:contacts:network:${userId}`,
    ];

  // else case means it's either 'PUBLIC' or 'PRIVATE'
  return [`surn:x-xing:contacts:network:${userId}`];
};

export type TrimArticleParagraphType = (
  slateMessage: any
) => InputMaybe<
  Array<ArticlesCreateArticleBlocksInput> | ArticlesCreateArticleBlocksInput
>;

export const trimArticleParagraph: TrimArticleParagraphType = (
  slateMessage
) => {
  // commentArticleV1 Field
  const serializedMessage = articleSerializer.serialize(slateMessage);
  // @ts-expect-error Commbox will be removed in Q2
  const unpackNodes = ({ articleParagraph }) => articleParagraph.text;

  const firstElementwithText = serializedMessage.findIndex(unpackNodes);

  // When the text field is empty
  if (firstElementwithText === -1) return serializedMessage;

  // When the text field has text
  const cloneSerializedMessage = [...serializedMessage];
  const lastElementwithText = cloneSerializedMessage
    .reverse()
    .findIndex(unpackNodes);

  return serializedMessage.slice(
    firstElementwithText,
    serializedMessage.length - lastElementwithText
  );
};

type PostTypeTrackingsType = {
  [key in CommboxAttachmentType]: CommboxPostTypeTrackingType;
};

const PostTypeTrackings: PostTypeTrackingsType = {
  EMPTY: 'text',
  LINK_PREVIEW: 'link',
  VIDEO: 'video',
  POLLS: 'poll',
  IMAGE: 'image',
  BLOCKED: 'text',
};

export type GetPostTypeTrackingType = (
  attachmentType: CommboxAttachmentType,
  isGifPost: boolean
) => CommboxPostTypeTrackingType;

export const getPostTypeTracking: GetPostTypeTrackingType = (
  attachmentType,
  isGifPost = false
) => {
  if (isGifPost) return 'gif';
  return PostTypeTrackings[attachmentType] || 'text';
};

export type GetPostTargetType = (
  application: CommboxApplicationType
) =>
  | 'entity_page'
  | 'disco'
  | 'mehub'
  | 'mehub_empty'
  | 'networkhome'
  | 'undefined_host_application';

export const getPostTarget: GetPostTargetType = (application) => {
  if (application === 'ENTITY_PAGES') return 'entity_page';
  if (application === 'DISCO') return 'disco';
  if (application === 'MEHUB') return 'mehub';
  if (application === 'MEHUB_EMPTY') return 'mehub_empty';
  if (application === 'SUPI') return 'networkhome';
  return 'undefined_host_application';
};
export type HandlePostClickType = (props: {
  application: CommboxApplicationType;
  attachmentType: CommboxAttachmentType;
  globalId: string;
  images: CommboxImagesType[];
  isEditingPosting: boolean;
  linkShareUrl: string | null;
  onPost: CommboxOnPostType;
  onSuccess: () => void;
  pollData: PollDataType | PollPreviewType | null;
  posting: any;
  publishAt: string;
  scheduled: 0 | 1;
  selectableAudiences: SelectableAudienceType[];
  selectedActorGlobalId: string;
  selectedAudience: SelectableAudienceType['id'];
  setErrorMessage: React.Dispatch<
    React.SetStateAction<CommboxErrorMessageType>
  >;
  setIsAllowedPost: React.Dispatch<React.SetStateAction<boolean>>;
  slateMessage: any;
  uploadedFileId: string | null;
  videoState: { id: string | null; status: string | null };
}) => void;
