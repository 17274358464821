import styled, { css } from 'styled-components';

import { scale010, spaceXS, spaceS, spaceM, spaceL } from '@xing-com/tokens';

import type { ProfileInfoAlignment } from './profile-info.types';

type ProfileInfoTProps = {
  $alignment: ProfileInfoAlignment;
  $noSpacing?: boolean;
};

type ContentContainerTProps = {
  $alignment: ProfileInfoAlignment;
};

const ProfileInfo = styled.div<ProfileInfoTProps>`
  display: flex;
  gap: ${spaceM};

  ${(props) => css`
    ${!props.$noSpacing &&
    css`
      padding-block-end: ${spaceL};
      padding-block-start: ${spaceL};
    `};

    ${props.$alignment === 'vertical' &&
    css`
      flex-direction: column;
      padding-block-end: 0;
      padding-block-start: 0;
    `};
  `};
`;

const ContentContainer = styled.div<ContentContainerTProps>`
  flex-grow: 1;

  & > * {
    align-items: flex-start;
    display: flex;
  }

  ${(props) => css`
    ${props.$alignment === 'vertical'
      ? css`
          & > * {
            margin-block-end: ${spaceXS};
          }
        `
      : css`
          & > * {
            margin-block-end: ${scale010};
          }
        `};
  `};

  & > *:last-child {
    margin-block-end: 0;
  }

  & [data-xds='Flag'] {
    display: inline-flex;
    vertical-align: top;
  }
`;

const ProfileImageContainer = styled.div`
  flex-shrink: 0;
  justify-content: top;

  & [data-xds='ProfileImage'] {
    display: block;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: ${spaceS};
`;

export const Styled = {
  ActionsContainer,
  ContentContainer,
  ProfileImageContainer,
  ProfileInfo,
};
