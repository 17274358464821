import { useNavigate } from '@reach/router';
import cx from 'classnames';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { usePageContext } from '@xing-com/crate-companies-entity-pages-common';
import { IconArrowLeft } from '@xing-com/icons';

import * as Styled from './edit-bar-back.styles';

type EditBarBackProps = {
  isArmstrong?: boolean;
};
export const EditBarBack: FC<EditBarBackProps> = ({ isArmstrong }) => {
  const navigate = useNavigate();
  const { pageContext } = usePageContext() ?? {};

  return (
    <Styled.TextButton
      size={'medium'}
      icon={IconArrowLeft}
      onClick={() =>
        navigate(pageContext?.basePath + '/' + pageContext?.pageSlug)
      }
      data-cy="back_to_overview"
      className={cx({
        isArmstrong,
      })}
    >
      <FormattedMessage
        id={'EP_SUBPAGE_BACK'}
        defaultMessage={'EP_SUBPAGE_BACK'}
      />
    </Styled.TextButton>
  );
};
