import { useContext } from 'react';

import { UpsellBannerContainerContext } from '../../context/upsell-banner-context/upsell-banner-context';

export const useUpsellBannerContext = () => {
  const context = useContext(UpsellBannerContainerContext);

  if (!context) {
    throw new Error('No UpsellBannerContainerContext provided');
  }

  return context;
};
