import type { DataProxy } from '@apollo/client/cache';

import type { AffiliatesEditQuery, AffiliatesResult } from './types';

export const handleCache = (
  cache: DataProxy,
  affiliatesEditQuery: any,
  affiliatesResult: AffiliatesResult
) => {
  const data: AffiliatesEditQuery | null = cache.readQuery(affiliatesEditQuery);

  const affiliatesEdit = affiliatesResult.map(({ category, company }) => ({
    __typename: 'EntityDocumentEdge',
    node: {
      __typename: 'EntityDocument',
      affiliateCategory: {
        id: category.id,
        __typename: 'AffiliateCategory',
      },
      affiliateCompany: {
        id: company.id,
        companyName: company.companyName,
        __typename: 'AffiliateCompany',
      },
    },
  }));

  cache.writeQuery({
    ...affiliatesEditQuery,
    data: {
      ...data,
      company: {
        ...data?.company,
        affiliates: {
          ...data?.company.affiliates,
          edges: affiliatesEdit,
        },
      },
    },
  });
};
