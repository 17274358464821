import type { FC } from 'react';

import type { ImageUploadOnPublishData } from '@xing-com/crate-companies-entity-pages-common';
import { usePageContext } from '@xing-com/crate-companies-entity-pages-common';
import { trackEditSaving } from '@xing-com/crate-companies-entity-pages-common/src/tracking';

import { useUpsellBannerContext } from '../../../../hooks/use-upsell-banner-context/use-upsell-banner-context';
import { HeaderBannerImage } from './header-banner-image';

type UpsellBannerCompanyHeaderBannerProps = {
  onClickBanner?: (data: ImageUploadOnPublishData) => void;
};
export const UpsellBannerCompanyHeaderBanner: FC<
  UpsellBannerCompanyHeaderBannerProps
> = ({ onClickBanner = () => undefined }) => {
  const { pageContext } = usePageContext() ?? {};
  const { upsellCover } = useUpsellBannerContext();

  const handleOnClickBanner = async () => {
    if (!upsellCover) {
      return;
    }

    if (pageContext?.focusType && pageContext?.pageId) {
      trackEditSaving({
        isFreePage: true,
        focusType: pageContext?.focusType,
        itemId: pageContext?.pageId,
        module: 'header',
        part: 'image',
      });
    }

    onClickBanner(upsellCover);
  };

  return (
    <HeaderBannerImage
      onClick={handleOnClickBanner}
      backLink={`${pageContext?.basePath}/${pageContext?.pageSlug}`}
    />
  );
};
