export interface AffiliateCompany {
  id: string;
  companyName: string;
  logos?: {
    logo32px: string;
  };
}

export interface AffiliateCategory {
  id: string;
  localizationValue?: string;
}

export interface Affiliate {
  affiliateCategory: AffiliateCategory;
  affiliateCompany: AffiliateCompany;
}

export interface AffiliateRow {
  category: AffiliateCategory;
  company: AffiliateCompany;
}

export interface AffiliateNode {
  node: Affiliate;
}

export type AffiliatesResult = Array<AffiliateRow>;

export interface AffiliatesEdges {
  edges: Array<AffiliateNode>;
}

export interface AffiliatesEditQuery {
  company: {
    id: string;
    companyName: string;
    affiliates: AffiliatesEdges;
  };
  companyAffiliationCategories: {
    collection: Array<AffiliateCategory>;
  };
}

type AffiliatesMutationError = {
  index: number;
  companyId: number;
  message: string;
};

export type AffiliatesUpdateMutationResult = {
  __typename: string;
  companyCreateAffiliates: {
    error?: {
      collection: Array<AffiliatesMutationError>;
    };
  };
};

// Reducer Types
export enum AffiliatesReducerActionKind {
  SET_INITIAL_STATE_ACTION = 'SET_INITIAL_STATE_ACTION',
  UPDATE_ITEM_ACTION = 'UPDATE_ITEM_ACTION',
  UPDATE_COMPANY_ACTION = 'UPDATE_COMPANY_ACTION',
  UPDATE_AFFILIATION_CATEGORY_ACTION = 'UPDATE_AFFILIATION_CATEGORY_ACTION',
  DELETE_ITEM_ACTION = 'DELETE_ITEM_ACTION',
  ADD_ITEM_ACTION = 'ADD_ITEM_ACTION',
  UPDATE_AFFILIATES_ORDER_ACTION = 'UPDATE_AFFILIATES_ORDER_ACTION',
  SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',
}

export type AffiliatesReducerRow = {
  company: AffiliateCompany;
  category: AffiliateCategory;
  error?: {
    message: string;
  };
};

export type AffiliatesReducerState = {
  rows: Array<AffiliatesReducerRow>;
  waitingForSetup: boolean;
};

export type ReducerError = {
  index: number;
  message: string;
};

export type AffiliateReducerErrors = {
  collection: Array<ReducerError>;
};

type SetInitialStateAction = {
  type: AffiliatesReducerActionKind.SET_INITIAL_STATE_ACTION;
  payload: {
    initialOptions: Array<AffiliatesReducerRow>;
  };
};

type AddItemAction = {
  type: AffiliatesReducerActionKind.ADD_ITEM_ACTION;
};
type UpdateCompanyAction = {
  type: AffiliatesReducerActionKind.UPDATE_COMPANY_ACTION;
  payload: { index: number; company: AffiliateCompany };
};

type UpdateAffiliationCategoryAction = {
  type: AffiliatesReducerActionKind.UPDATE_AFFILIATION_CATEGORY_ACTION;
  payload: { index: number; category: AffiliateCategory };
};

type DeleteItemAction = {
  type: AffiliatesReducerActionKind.DELETE_ITEM_ACTION;
  payload: { index: number };
};

type UpdateAffiliatesOrderAction = {
  type: AffiliatesReducerActionKind.UPDATE_AFFILIATES_ORDER_ACTION;
  payload: { modules: Array<AffiliatesReducerRow> };
};

type SetErrorMessageAction = {
  type: AffiliatesReducerActionKind.SET_ERROR_MESSAGE;
  payload: {
    errors: AffiliateReducerErrors;
  };
};

export type AffiliatesReducerAction =
  | SetInitialStateAction
  | AddItemAction
  | UpdateCompanyAction
  | UpdateAffiliationCategoryAction
  | DeleteItemAction
  | UpdateAffiliatesOrderAction
  | SetErrorMessageAction;
