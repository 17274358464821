import ttt from '@xing-com/ticktricktrack';

export const trackEvent = (eventName: string, contextData: any) =>
  ttt.event(eventName, {
    ...contextData,
  });

export const trackAction = (contextData: any) =>
  ttt.event('PropTrackAction', {
    ...contextData,
  });
