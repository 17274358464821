import type React from 'react';

// import Jobs from '@xing-com/pages-jobs/jobs-module/jobs-module';
import {
  AboutUsDetail,
  AboutUsContainer,
} from '@xing-com/crate-companies-entity-pages-about-us';
import { CompanyRecommendations } from '@xing-com/crate-companies-entity-pages-company-recommendations';
import {
  Contacts,
  ContactsDetail,
} from '@xing-com/crate-companies-entity-pages-contacts';
import {
  Employees,
  EmployeesDetail,
} from '@xing-com/crate-companies-entity-pages-employees';
import { IdealEmployer } from '@xing-com/crate-companies-entity-pages-ideal-employer';
import {
  JobsDetailContainer,
  JobsModule,
} from '@xing-com/crate-companies-entity-pages-jobs';
import { KununuContainer } from '@xing-com/crate-companies-entity-pages-kununu';
import { LocationsContainer } from '@xing-com/crate-companies-entity-pages-location';
import {
  NewsContainer,
  NewsDetail,
} from '@xing-com/crate-companies-entity-pages-news';
// import Kununu from '@xing-com/pages-kununu/KununuContainer';
// import SampleModule from '@xing-com/pages-sample-module/SampleModule';
// import ContactsDetail from '@xing-com/pages-contacts/ContactsDetail';
// import SampleModuleDetail from '@xing-com/pages-sample-module/SampleModuleDetail';

type modulesMapProps = {
  [name: string]: {
    module: React.ComponentType<any>;
    subpageModule?: React.ComponentType<any>;
    seoIndexSubpage?: boolean;
    featureSwitch?: string;
    tabs?: {
      [tabName: string]: React.ComponentType<any>;
    };
  };
};

export const modulesMap: modulesMapProps = {
  about_us: {
    module: AboutUsContainer,
    subpageModule: AboutUsDetail,
  },
  jobs: {
    module: JobsModule,
    subpageModule: JobsDetailContainer,
  },
  ideal_employer: {
    module: IdealEmployer,
  },
  news: {
    module: NewsContainer,
    subpageModule: NewsDetail,
  },
  contacts: {
    module: Contacts,
    subpageModule: ContactsDetail,
    seoIndexSubpage: false,
  },
  kununu: {
    module: KununuContainer,
  },
  employees: {
    module: Employees,
    subpageModule: EmployeesDetail,
  },
  company_recommendations: {
    module: CompanyRecommendations,
  },
  sampleModule: {
    module: () => <h1>Sample Module</h1>,
    subpageModule: () => <h1>Sample Module Detail</h1>,
    tabs: {
      tab1: () => <h1>tab 1</h1>,
      tab2: () => <h1>tab 2</h1>,
      tab3: () => <h1>tab 3</h1>,
    },
  },
  locations: {
    // module: Locations,
    module: LocationsContainer,
  },
};
