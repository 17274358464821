/* eslint-disable complexity */

import type { ValidationResult } from '../../types';

export const locationValidation = (
  type: string,
  value: string
): ValidationResult => {
  // Sorry your input seems to be a bit long.
  const MAX_CHARACTERS_ERROR = 'EP_FIELD_MAX_CHARACTERS_ERROR';

  switch (type) {
    case 'label': {
      // Numbers, special signs and letters allowed
      // Characters min: 1
      // Characters max: 100
      if (value.length === 0) {
        return { key: 'EP_LOCATION_LABEL_ERROR' };
      }

      if (value.length > 100) {
        return {
          key: MAX_CHARACTERS_ERROR,
          value: 100,
        };
      }

      return null;
    }
    case 'address': {
      // min. amount of characters: 3
      // max. amount of characters: 60
      // must contain min. 1 letter
      // must contain min. 1 number and max. 6
      const numbers = value.replace(/\D/g, '');
      const letters = value.replace(/[0-9]/g, '');

      if (value.length > 60) {
        return {
          key: MAX_CHARACTERS_ERROR,
          value: 60,
        };
      }

      return numbers?.length <= 6 && letters.length > 0 && value.length >= 3
        ? null
        : { key: 'EP_LOCATION_ADDRESS_ERROR' };
    }
    case 'addressSuffix': {
      // max. amount of characters: 30
      if (value.length > 30) {
        return {
          key: MAX_CHARACTERS_ERROR,
          value: 30,
        };
      }

      return null;
    }
    case 'email': {
      // Input needs to consist of valid e-mail structure (including an "@" and .de/com/...)
      // can contain letters, numbers and special characters
      // Characters max: 345
      // Characters min: 6
      if (value.length === 0) {
        return null;
      }

      if (value.length > 345) {
        return {
          key: MAX_CHARACTERS_ERROR,
          value: 345,
        };
      }

      return value.length >= 6 &&
        value.match('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,16}$')
        ? null
        : { key: 'EP_LOCATION_EMAIL_ERROR' };
    }
    case 'phoneNumber': {
      // max. amount of numbers: 15
      // can stay empty
      // min. amount of numbers if not 0 = 7
      // After leaving the input field:
      // When first character in input field is „0“, the „0“ will be displayed in brackets and visually highlighted as not that active. (See concept document in PAGES-1453.
      // When user entered space signs in the input field it will be remove
      if (value.length === 0) {
        return null;
      }

      if (value.length > 15) {
        return {
          key: MAX_CHARACTERS_ERROR,
          value: 15,
        };
      }

      return value.length >= 5 && value.length <= 15 && value.match('^[0-9]*$')
        ? null
        : { key: 'EP_LOCATION_PHONENUMBER_ERROR' };
    }
    case 'faxNumber': {
      // max. amount of numbers: 15
      // can stay empty
      // min. amount of numbers if not 0 = 7
      // After leaving the input field:
      // When first character in input field is „0“, the „0“ will be displayed in brackets and visually highlighted as not that active. (See concept document in PAGES-1453.
      // When user entered space signs in the input field it will be remove
      if (value.length === 0) {
        return null;
      }

      if (value.length > 15) {
        return {
          key: MAX_CHARACTERS_ERROR,
          value: 15,
        };
      }

      return value.length >= 5 && value.length <= 15 && value.match('^[0-9]*$')
        ? null
        : { key: 'EP_LOCATION_FAXNUMBER_ERROR' };
    }
    case 'postcode': {
      // can contain numbers, letters from A-Z and some special characters
      // special characters allowed: - :
      // min. amount of characters: 3
      // max. amount of characters: 10
      // transform lower-case letters into upper-case letters to stick to the post code standard

      if (value.length > 10) {
        return {
          key: MAX_CHARACTERS_ERROR,
          value: 10,
        };
      }

      return value.length >= 2 &&
        value.length <= 10 &&
        value.match('^[ A-Za-z0-9-:]*$')
        ? null
        : { key: 'EP_LOCATION_POSTCODE_ERROR' };
    }
    case 'city': {
      // Only characters allowed
      // Characters min: 2
      // Characters max: 85

      if (value.length > 85) {
        return {
          key: MAX_CHARACTERS_ERROR,
          value: 85,
        };
      }

      return value.length >= 2 &&
        value.length <= 85 &&
        value.match('^([^0-9]*)$')
        ? null
        : { key: 'EP_LOCATION_CITY_ERROR' };
    }
    case 'websiteURL': {
      // Input needs to consist of a website structure ("www." is not necessary, but .de/.com ...)
      // Characters min: 4
      // Characters max: 255
      if (value.length === 0) {
        return null;
      }

      if (value.length > 255) {
        return {
          key: MAX_CHARACTERS_ERROR,
          value: 255,
        };
      }

      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator

      return value.length > 3 && value.length <= 255 && !!pattern.test(value)
        ? null
        : { key: 'EP_LOCATION_WEBSITE_ERROR' };
    }
    default: {
      return null;
    }
  }
};
