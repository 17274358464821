import { useState, useRef } from 'react';

type Ratio = {
  [type: string]: number;
};

export const useNavigationActiveItem = (
  pageModulesByType: Array<string> | null
) => {
  const ratio = useRef<Ratio>({});
  const forcingActiveIndex = useRef<number | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const update = () => {
    setTimeout(() => getModuleActive(), 400);
  };

  const setIntersectionRatio = (type: string, intersectionRatio: number) => {
    ratio.current = { ...ratio.current, [type]: intersectionRatio };
    update();
  };

  const forceActiveIndex = (i: number) => {
    setActiveIndex(i);
    forcingActiveIndex.current = i;
  };

  const getModuleActive = () => {
    if (!pageModulesByType) return;
    const maxValue = Math.max(...Object.values(ratio.current));
    const index =
      maxValue === 0
        ? -1
        : pageModulesByType.findIndex(
            (type) => ratio.current[type] === maxValue
          );

    if (!forcingActiveIndex.current) {
      setActiveIndex(index);
    }

    if (index === forcingActiveIndex.current) {
      forcingActiveIndex.current = null;
    }
  };

  return [activeIndex, forceActiveIndex, setIntersectionRatio] as const;
};
