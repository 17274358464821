/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetVideoQueryVariables = Types.Exact<{
  videoId: Types.Scalars['SlugOrID']['input'];
}>;

export type GetVideoQuery = {
  __typename?: 'Query';
  videoV2?: {
    __typename?: 'VideoV2';
    ref?: string | null;
    subtitles?: Array<{
      __typename?: 'VideoV2Subtitle';
      id: string;
      language: string;
      label: string;
      sources: Array<{
        __typename?: 'VideoV2SubtitleSource';
        type: Types.VideoSubtitleType;
        source: string;
      }>;
    }> | null;
    defaultThumbnails?: Array<{
      __typename?: 'VideoThumbnail';
      sources?: Array<{
        __typename?: 'VideoImageSource';
        source: string;
        width: number;
        height: number;
      }> | null;
    }> | null;
    customThumbnails?: Array<{
      __typename?: 'VideoThumbnail';
      sources?: Array<{
        __typename?: 'VideoImageSource';
        source: string;
        width: number;
        height: number;
      }> | null;
    }> | null;
  } | null;
};

export const GetVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'videoId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ref' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'videoId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ref' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtitles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'language' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sources' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'source' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultThumbnails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sources' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'source' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'width' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'height' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customThumbnails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sources' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'source' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'width' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'height' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetVideoQuery, GetVideoQueryVariables>;
