import { SkeletonBody, SkeletonImage } from '@xing-com/skeleton';

import * as Styled from './skeleton.styles';

const EditDescriptionSkeleton = () => (
  <div>
    <SkeletonBody amountRows={2} />
    <Styled.LoadingIntroTitle height={'38px'} />
    <SkeletonImage height={'116px'} />
  </div>
);
export default EditDescriptionSkeleton;
