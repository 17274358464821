import type { FC } from 'react';

import type { CompanyAboutUsFacts } from '@xing-com/crate-common-graphql-types';

import FactsCompany from './facts-company';
import FactsPublisher from './facts-publisher';

type FactsContainerProps = {
  facts: CompanyAboutUsFacts;
};

const FactsContainer: FC<FactsContainerProps> = ({ facts }) => {
  if (facts.__typename === 'CompanyAboutUsFacts') {
    return <FactsCompany facts={facts} />;
  }
  if (facts.__typename === 'PublisherAboutUsFacts') {
    return <FactsPublisher facts={facts} />;
  }
  return null;
};

export default FactsContainer;
