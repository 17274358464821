import type { MutationResult } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';

import { useErrorContext } from '@xing-com/crate-companies-entity-pages-common';

import { NetworkContactRequestsDocument } from '../graphql/queries/network-contact-request.gql-types';
import type {
  NetworkContactRequestsMutation,
  NetworkRequestResponseUpdateCache,
} from '../types';

type UseNetworkRequestMutationProps = {
  update?: NetworkRequestResponseUpdateCache;
  onCompleted?: () => void;
};

type UseNetworkRequestMutationResponse = [
  (id: string, hasPhoto?: boolean) => void,
  MutationResult<NetworkContactRequestsMutation>,
];

const useNetworkRequestMutation = ({
  update = () => undefined,
  onCompleted = () => undefined,
}: UseNetworkRequestMutationProps = {}): UseNetworkRequestMutationResponse => {
  const { $t } = useIntl();
  const { showError } = useErrorContext();

  const [executeNetworkRequest, response] =
    useMutation<NetworkContactRequestsMutation>(
      NetworkContactRequestsDocument,
      {
        optimisticResponse: {
          __typename: 'Mutation',
          networkContactRequestsSend: {
            error: null,
            __typename: 'CompanyFollow',
          },
        },
        onError: (error) => {
          showError({
            message: 'EP_EMPLOYEES_FEEDBACK_ERROR',
            error,
          });
        },
        onCompleted: (response) => {
          const error = response.networkContactRequestsSend.error;
          if (error) {
            showError({
              message: $t({
                id: error.message,
                defaultMessage: error.message,
              }),
              error,
            });
          } else {
            onCompleted();
          }
        },
      }
    );

  const executeNetworkRequestById = (id: string, hasPhoto?: boolean) => {
    executeNetworkRequest({
      variables: {
        input: {
          recipientId: id,
        },
      },
      update: (data, cache) => update(data, cache, id, hasPhoto),
    });
  };

  return [executeNetworkRequestById, response];
};

export { useNetworkRequestMutation };
