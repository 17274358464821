import type { VFC } from 'react';

import { BackLink } from './back-link';
import type { BackLinkProps } from './back-link/back-link';

type ZenViewSubHeaderProps = {
  backLink?: BackLinkProps;
};

const ZenViewSubHeader: VFC<ZenViewSubHeaderProps> = ({
  backLink,
}: ZenViewSubHeaderProps) => {
  return (backLink?.to && backLink?.label) ||
    (backLink?.onClose && backLink?.label) ? (
    <BackLink
      to={backLink?.to}
      onClose={backLink?.onClose}
      label={backLink?.label}
    />
  ) : null;
};

export { ZenViewSubHeader };
