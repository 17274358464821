import { trackAboutUsWebsite } from '@xing-com/crate-companies-entity-pages-common/src/tracking';

import calendarIllustration from './assets/calendar.svg'; // eslint-disable-line
import websiteIllustration from './assets/website.svg'; // eslint-disable-line
import imprintIllustration from './assets/imprint.svg'; // eslint-disable-line
import mapIllustration from './assets/map.svg'; // eslint-disable-line

import Imprint from './imprint';

const linkRegEx =
  /^(https?:\/\/[\w][-äÄÉéöÖüÜß\w+&@#\/%?=~|!:,.;]*[-\w*+&@#\/%=~|]|www[-\w+&@#\/%?=~|!:,.;]*[-\w+&@#\/%=~|])\S*$/; // eslint-disable-line

const getCommonFactsItem = (
  type: string,
  value: any,
  showEmptyState?: boolean
) => {
  if (type === 'foundingYear') {
    if (showEmptyState) {
      return {
        illustration: calendarIllustration,
        translationKey: 'EP_FOUNDING_YEAR_EMPTY_STATE',
        showEmptyState: true,
      };
    }
    return {
      type,
      values: { foundingYear: value },
      illustration: calendarIllustration,
      translationKey: 'EP_FOUNDING_YEAR',
    };
  }

  if (type === 'location') {
    return {
      type,
      values: value,
      illustration: mapIllustration,
    };
  }

  if (type === 'websiteUrl') {
    if (showEmptyState) {
      return {
        type,
        illustration: websiteIllustration,
        translationKey: 'EP_VISIT_WEBSITE_EMPTY_STATE',
        showEmptyState: true,
      };
    }
    return {
      type,
      link: {
        href: value,
        target: '_blank',
        rel: 'nofollow',
      },
      trackingFunction: trackAboutUsWebsite,
      illustration: websiteIllustration,
      translationKey: 'EP_VISIT_WEBSITE',
      showEmptyState,
    };
  }

  if (type === 'imprint') {
    if (showEmptyState) {
      return {
        type,
        illustration: imprintIllustration,
        translationKey: 'EP_LEGAL_NOTICE_EMPTY_STATE',
        showEmptyState: true,
      };
    }

    const isUrlOnly = !!linkRegEx.test(value);
    return {
      type,
      link: isUrlOnly
        ? {
            href:
              value.includes('http://') || value.includes('https://')
                ? value
                : `http://${value}`,
            target: '_blank',
            rel: 'nofollow',
          }
        : undefined,
      omViewContent: !isUrlOnly && <Imprint imprint={value} />,
      illustration: imprintIllustration,
      translationKey: 'EP_LEGAL_NOTICE',
      showEmptyState,
    };
  }
  return;
};

export default getCommonFactsItem;
