import styled, { css } from 'styled-components';

import { spaceXL, xdlColorBorder } from '@xing-com/tokens';

type SubpageNavigationTransientProps = {
  $noPadding?: boolean;
};

const StyledContainer = styled.div<SubpageNavigationTransientProps>`
  ${({ $noPadding }) =>
    $noPadding
      ? css`
          padding: 0 0 ${spaceXL} 0;
        `
      : css`
          padding: ${spaceXL} 0;
        `};
  border-bottom: 1px solid ${xdlColorBorder};
`;

export const Styled = {
  StyledContainer,
};
