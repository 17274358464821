import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { xdlColorTextSecondary } from '@xing-com/tokens';

// *** Base ***
const baseFilledDropZone = css`
  height: 100%;
  width: 100%;
`;

const baseDropZoneHeader = css`
  color: ${xdlColorTextSecondary};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

const baseStyledButtonCross = css`
  cursor: pointer;
  color: ${xdlColorTextSecondary};
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 8px;
  border-radius: 21px;
`;

const baseDropZoneImageContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

// *** Components ***
const FilledDropZone = styled.section`
  ${baseFilledDropZone}
`;

const DropZoneHeader = styled.header`
  ${baseDropZoneHeader}
`;

const StyledButtonCross = styled(Button)`
  ${baseStyledButtonCross}
`;

const DropZoneImageContainer = styled.section`
  ${baseDropZoneImageContainer}
`;
export const Styled = {
  FilledDropZone,
  DropZoneHeader,
  ButtonCross: StyledButtonCross,
  DropZoneImageContainer,
};
