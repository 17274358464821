import styled from 'styled-components';

import { Card as InternalCard } from '@xing-com/card';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceM } from '@xing-com/tokens';

export const Card = styled(InternalCard)<{ hideContent: boolean }>`
  position: relative;
  display: ${({ hideContent }) => (hideContent ? 'none' : 'inline-block')};
  justify-content: center;
  align-items: center;
  width: 142px;
  height: 110px;

  &:not(:last-child) {
    margin-right: ${spaceM};
  }

  @media ${mediaConfined} {
    display: inline-block;
  }
`;

export const Image = styled.img`
  max-width: calc(100% - 16px);
  max-height: calc(100% - 16px);
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
