import type { FC } from 'react';
import { useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import { useUpdateEffect } from '@xing-com/crate-companies-entity-pages-common';

import type {
  FilterStateSelectedItemsType,
  AggregationQueryType,
  FilterNameType,
  FilterStateType,
} from '../../types';
import { JobsReducerActionKind } from '../../types';
import { CheckLists } from './check-lists/check-lists';
import { checkboxFilterReducer } from './jobs-filter-reducer';
import { SearchBar } from './search-bar/search-bar';

const LIST_LIMIT = 1;

const DEBOUNCE_TIME = 300;

const initialFilterState: FilterStateType = {
  waitingForSetup: true,
  keywords: '',
  offset: LIST_LIMIT,
  selectedItems: {
    disciplines: [],
    careerLevels: [],
    cities: [],
  },
  items: {
    disciplines: [],
    careerLevels: [],
    cities: [],
  },
};

type JobsFilterProps = {
  aggregations: AggregationQueryType | undefined;
  onFiltersChanges: (
    selectedItems: FilterStateSelectedItemsType,
    keywords: string
  ) => void;
};
export const JobsFilter: FC<JobsFilterProps> = ({
  aggregations,
  onFiltersChanges,
}) => {
  const { formatMessage } = useIntl();

  const [{ selectedItems, keywords }, dispatch] = useReducer(
    checkboxFilterReducer,
    initialFilterState
  );
  const [debouncedKeywords] = useDebounce(keywords, DEBOUNCE_TIME);

  useUpdateEffect(() => {
    onFiltersChanges(selectedItems, debouncedKeywords);
  }, [selectedItems, debouncedKeywords]);

  const handleOnChangeSearchbar = (keywords: string) => {
    dispatch({
      type: JobsReducerActionKind.UPDATE_SEARCH_ACTION,
      payload: {
        keywords,
      },
    });
  };
  const handleClearAll = () => {
    dispatch({ type: JobsReducerActionKind.CLEAR_ALL_ACTION });
  };
  const handleOnClickCheckbox = (id: string, filterName: FilterNameType) => {
    dispatch({
      type: JobsReducerActionKind.TOGGLE_CHECKBOX_ACTION,
      payload: {
        id,
        filterName,
      },
    });
  };
  const handleOnClearCheckbox = (filterName: FilterNameType) => {
    dispatch({
      type: JobsReducerActionKind.CLEAR_ACTION,
      payload: { id: '', filterName },
    });
  };

  return (
    <>
      <SearchBar
        onChange={handleOnChangeSearchbar}
        value={keywords}
        placeholder={formatMessage({
          id: 'EP_JOBS_SEARCH_PLACEHOLDER',
        })}
      />
      <CheckLists
        selectedItems={selectedItems}
        onClearAll={handleClearAll}
        onClickCheckbox={handleOnClickCheckbox}
        onClearCheckbox={handleOnClearCheckbox}
        aggregations={aggregations}
      />
    </>
  );
};
