import { useState } from 'react';
import type { FC, PropsWithChildren } from 'react';

import type { UploadRequestError } from '@xing-com/crate-common-graphql-types';
import { FileUploadButton as DefaultFileUploadButton } from '@xing-com/crate-x4e-file-upload';

import { useErrorContext } from '../../hooks/use-error-context/use-error-context';
import { useUploadFile } from '../../hooks/use-upload-file/use-upload-file';
import type { FileUploaded } from '../../typings/upload-files';

const fileType = {
  pdf: 'application/pdf',
};

type FileUploadButtonProps = {
  disableUpload?: boolean;
  onChange: () => void;
  onError: (error?: UploadRequestError) => void;
  onPublish: (fileUploaded: FileUploaded) => void;
};

export const FileUploadButton: FC<PropsWithChildren<FileUploadButtonProps>> = ({
  disableUpload,
  children,
  onPublish,
  onError,
}) => {
  const { showError } = useErrorContext();
  const [resetCompontKey, setResetComponentKey] = useState(0);

  const { uploadFile } = useUploadFile();

  const handleUpload = async (file: File) => {
    const { data, error } = await uploadFile(file);

    if (error) {
      showError({
        message: 'EP_GENERAL_FEEDBACK_ERROR',
        error: error,
      });
      onError();
    } else if (data) {
      onPublish(data);
    }
    setResetComponentKey(resetCompontKey + 1);
  };
  const handleFileSelected = async (file: File) => {
    if (file.size > 100000000 || file.type !== fileType.pdf) {
      showError({
        message: 'EP_FILE_SIZE_FORMAT_ERROR',
      });
      setResetComponentKey(resetCompontKey + 1);
      return;
    }

    handleUpload(file);
  };

  return disableUpload ? (
    children
  ) : (
    <DefaultFileUploadButton
      key={resetCompontKey}
      // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; key: number; name: st... Remove this comment to see the full error message
      name="example-upload"
      maxSize={300000}
      onFileSelected={handleFileSelected}
      accept="application/pdf"
    >
      {children}
    </DefaultFileUploadButton>
  );
};
