import { useContext } from 'react';

import { ErrorContext } from '../../context/error-context/error-context';

export const useErrorContext = () => {
  const context = useContext(ErrorContext);

  if (!context) {
    throw new Error('No provider found for Error Context');
  }

  return context;
};
