import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import {
  spaceM,
  spaceXL,
  spaceXXL,
  xdlColorBorderSoft,
} from '@xing-com/tokens';
import { BodyCopy, Headline as DefaultHeadline } from '@xing-com/typography';

export const HeadlineContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Headline = styled(DefaultHeadline)`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const SloganGuidance = styled.div`
  margin-top: ${spaceM};
`;
export const Description = styled(BodyCopy)`
  margin-top: ${spaceM};
  margin-bottom: 0;
`;

export const ButtonCtaMobile = styled(Button)`
  display: none;
  margin-top: ${spaceXL};

  @media (max-width: 739px) {
    display: flex;
  }
`;

export const ContentSwitcherWrapper = styled.div`
  height: auto;
  margin-top: ${spaceXXL};
`;

export const EditHEaderWrapper = styled.div<{ $noMarginBottom?: boolean }>`
  border-bottom: 1px solid ${xdlColorBorderSoft};
  padding-bottom: ${spaceXL};
  margin-bottom: ${spaceXL};

  ${({ $noMarginBottom }) =>
    $noMarginBottom &&
    css`
      margin-bottom: 0;
    `}
`;

export const MenuContainer = styled.div`
  max-width: 280px;
  padding: ${spaceM};
  white-space: break-spaces;

  @media (max-width: 739px) {
    padding: ${spaceXL};
  }
`;

export const ButtonCta = styled(Button)`
  @media (max-width: 739px) {
    display: none;
  }
`;
