import styled from 'styled-components';

import { Tag as InternalTag } from '@xing-com/tag';
import { spaceM, spaceL, spaceXXL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const Intro = styled(BodyCopy)`
  margin-top: ${spaceL};
`;

export const SectionHeader = styled(BodyCopy)`
  margin-top: ${spaceXXL};
  margin-bottom: ${spaceL};
`;

export const TagList = styled.div`
  display: flex;
`;

export const Tag = styled(InternalTag)`
  margin-right: ${spaceM};
`;
