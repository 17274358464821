import type { FC, PropsWithChildren } from 'react';

import { Grid, Row } from '@xing-com/xing-grid';

import * as Styled from './small-grid.styles';

declare type AllowedColumnSize =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

declare type AllowedColumnOffset =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11;

interface GridProps {
  className?: string;
  offset?: AllowedColumnOffset;
  offsetDesktop?: AllowedColumnOffset;
  size: AllowedColumnSize;
  sizeDesktop?: AllowedColumnSize;
}

type SmallGridProps = {
  inner?: boolean;
};
export const SmallGrid: FC<PropsWithChildren<SmallGridProps>> = ({
  children,
  inner,
  ...props
}) => {
  const gridProps: GridProps = inner
    ? {
        offset: 0,
        size: 12,
        sizeDesktop: 10,
        offsetDesktop: 1,
      }
    : {
        offset: 1,
        size: 10,
      };
  return (
    <Grid
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; type: string; }' is not... Remove this comment to see the full error message
      type="standard"
      {...props}
    >
      <Row>
        <Styled.Column {...gridProps}>{children}</Styled.Column>
      </Row>
    </Grid>
  );
};
