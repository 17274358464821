import styled from 'styled-components';

import { InputBar as DefaultInputBar } from '@xing-com/text-field';
import { spaceL, spaceM, spaceXL, spaceXS } from '@xing-com/tokens';

export const Wrapper = styled.div`
  position: relative;
`;

export const InputWrapper = styled.div`
  flex-grow: 1;
`;
export const InputBar = styled(DefaultInputBar)`
  .inputBar {
    text-indent: ${spaceL};
    padding: ${spaceXS} ${spaceXL};
  }
`;

export const SearchIcon = styled.div`
  align-items: center;
  display: flex;
  pointer-events: none;
  position: absolute;
  left: ${spaceM};
  top: 50%;
  transform: translateY(-50%);
`;

// .resetButton {
//   background: none;
//   border: none;
//   appearance: none;
//   padding: 0;
//   display: flex;
//   position: absolute;
//   right: ${spaceS};
//   top: 50%;
//   cursor: pointer;
//   transform: translateY(-50%);
// }
