import styled from 'styled-components';

import {
  xdlColorBackgroundSecondary,
  xdlColorTextSecondary,
} from '@xing-com/tokens';

import { InfoState as DefaultInfoState } from '../info-state/info-state';

export const InfoState = styled(DefaultInfoState)`
  background-color: ${xdlColorBackgroundSecondary};
  color: ${xdlColorTextSecondary};
`;
