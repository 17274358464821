import {
  PROP_CONTEXT_DIMENSION3,
  PROP_INTERACTION_TYPE,
  PROP_SCROLLING,
  PROP_PAGINATION,
  PROP_ACTION_ORIGIN,
  PROP_TRACK_ACTION,
  PROP_SEARCH_LIST_FILTER,
} from '../constants';
import { trackAction, trackEvent } from '../tracker';

export const ENTITY_PAGE_JOBS_PAGE_VIEW = 'Jobs_subpage';
const ENTITY_PAGE_JOBS_BOOKMARK_EVENT = 'EventBookmark';
const ENTITY_PAGE_JOBS_UNBOOKMARK_EVENT = 'EventUnbookmark';
const ENTITY_PAGE_JOBS_BOOKMARK = 'jobs_bookmark';
const ENTITY_PAGE_JOBS_UNBOOKMARK = 'jobs_unbookmark';
const ENTITY_PAGE_JOBS_ORIGIN = 'entity_page_sub_jobs';
const ENTITY_PAGE_JOBS_DESELECT_FILTER =
  'entity_page_jobs_filter_delete_single';
const ENTITY_PAGE_JOBS_SELECT_FILTER = 'entity_page_jobs_filter_select';
const ENTITY_PAGE_JOBS_CLEAR_FILTER = 'entity_page_jobs_filter_delete';

export const trackJobsBookmarkAction = (
  isEditor: boolean,
  bookmarked: boolean,
  extraParams?: any
) => {
  const event = bookmarked
    ? ENTITY_PAGE_JOBS_BOOKMARK_EVENT
    : ENTITY_PAGE_JOBS_UNBOOKMARK_EVENT;

  trackEvent(event, {
    [event]: 1,
    [PROP_INTERACTION_TYPE]: bookmarked
      ? ENTITY_PAGE_JOBS_BOOKMARK
      : ENTITY_PAGE_JOBS_UNBOOKMARK,
    [PROP_CONTEXT_DIMENSION3]: isEditor
      ? 'entity_page_editor'
      : 'entity_page_user',
    ...extraParams,
  });
};

export const trackJobsSubpageBookmarkAction = (
  isEditor: boolean,
  bookmarked: boolean
) =>
  trackJobsBookmarkAction(isEditor, bookmarked, {
    [PROP_ACTION_ORIGIN]: ENTITY_PAGE_JOBS_ORIGIN,
  });

export const trackJobsFilterSelect = (
  filterName: string,
  filterValue: string,
  activeFilters: any[]
) =>
  trackEvent(PROP_SEARCH_LIST_FILTER, {
    [PROP_TRACK_ACTION]: ENTITY_PAGE_JOBS_SELECT_FILTER,
    [PROP_ACTION_ORIGIN]: `entity_page_sub_select_${filterName}_${filterValue}`,
    [PROP_SEARCH_LIST_FILTER]:
      activeFilters.length > 0 ? activeFilters.join(';') : 'none',
  });

export const trackJobsFilterDeselect = (
  filterName: string,
  filterValue: string,
  activeFilters: any[]
) =>
  trackEvent(PROP_TRACK_ACTION, {
    [PROP_TRACK_ACTION]: ENTITY_PAGE_JOBS_DESELECT_FILTER,
    [PROP_ACTION_ORIGIN]: `entity_page_sub_remove_${filterName}_${filterValue}`,
    [PROP_SEARCH_LIST_FILTER]:
      activeFilters.length > 0 ? activeFilters.join(';') : 'none',
  });

export const trackJobsFilterClear = (
  filterName: string,
  activeFilters: any[]
) => {
  trackEvent(PROP_TRACK_ACTION, {
    [PROP_TRACK_ACTION]: ENTITY_PAGE_JOBS_CLEAR_FILTER,
    [PROP_ACTION_ORIGIN]: `entity_page_sub_remove_${filterName}`,
    [PROP_SEARCH_LIST_FILTER]:
      activeFilters.length > 0 ? activeFilters.join(';') : 'none',
  });
};

export const trackJobsFilterClearAll = () => {
  trackEvent(PROP_TRACK_ACTION, {
    [PROP_TRACK_ACTION]: ENTITY_PAGE_JOBS_CLEAR_FILTER,
    [PROP_ACTION_ORIGIN]: 'entity_page_sub_remove_all',
    [PROP_SEARCH_LIST_FILTER]: 'none',
  });
};

export const trackPageJobsChange = ({
  direction,
  page,
}: {
  direction: string;
  page: number;
}) => {
  trackAction({
    [PROP_SCROLLING]: `entity_page_jobs_carousell_${
      direction === 'right' ? 'next' : 'back'
    }`,
    [PROP_PAGINATION]: page,
  });
};
