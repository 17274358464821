/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconBold = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconBold"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 4a4.517 4.517 0 0 1 4.25 4.5c0 1.01-.335 1.944-.9 2.696a4.49 4.49 0 0 1 1.439 3.294 4.506 4.506 0 0 1-4.5 4.501l-.001-.009H6V4zm-.25 8.991H9v2.991h4.314v.007l.119-.005a1.502 1.502 0 0 0-.082-2.994l-.33.003q-.135.008-.271.008zM12.771 7H9v2.991l3.909.001A1.502 1.502 0 0 0 12.772 7"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBold;
