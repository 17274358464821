/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateCompanyMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID']['input'];
  sizeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  industryId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  companyUpdateInformation?: {
    __typename?: 'CompanyUpdateInformationPayload';
    error?: {
      __typename?: 'CompanyUpdateInformationError';
      type?: Types.CompanyUpdateInformationErrorType | null;
    } | null;
    success?: {
      __typename?: 'Company';
      id: string;
      companySizeId?: string | null;
      companySize?: string | null;
      companySizeRange?: {
        __typename?: 'CompanySizeRange';
        min: number;
        max?: number | null;
      } | null;
    } | null;
  } | null;
};

export const UpdateCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sizeId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyUpdateInformation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sizeId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sizeId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'industryId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'industryId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companySizeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companySize' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companySizeRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'min' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'max' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
