import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useState } from 'react';

import type {
  EntityPageContractType,
  EntityPageFocusType,
  EntityPagePublicationStatus,
  EntityPageSocialProofConnection,
  EntityPageVisibility,
} from '@xing-com/crate-common-graphql-types';

import type { EntityPageModulesFragment } from '../../graphql/entity-page-query.gql-types';

export type CoverMediaContextType = {
  cover?: string;
};

export type PageContextType = {
  modules?: EntityPageModulesFragment;
  socialProof?: EntityPageSocialProofConnection;
  basePath: string;
  contractType?: EntityPageContractType;
  publicationStatus: EntityPagePublicationStatus;
  pageId: string;
  pageSlug: string;
  isFollowingPage?: boolean | null;
  focusType: EntityPageFocusType;
  companyId?: string | null;
  cppId?: string | null;
  industryId?: string | null;
  pageTitle: string;
  pageSlogan: string;
  globalId: string;
  upsellActive?: boolean;
  logo?: string;
  hasNewsModule?: boolean;
  isShowingCompanyBanner?: boolean;
  moduleTitle?: string;
  coverMedia?: CoverMediaContextType;
  isWebview?: boolean;
  goBackUrl?: () => void;
  url?: string;
  visibility?: EntityPageVisibility | null;
  parentPageUrl?: string;
  moduleGlobalId?: string;
  setModuleLoaded?: (type: string) => void;
  modulesLoaded?: {
    [key: string]: boolean;
  };
};

type PageContextValues = {
  pageContext: PageContextType | undefined;
  setPageContext: Dispatch<SetStateAction<PageContextType | undefined>>;
};
export const PageContext = createContext<PageContextValues | undefined>(
  undefined
);

type PageContextProviderProps = {
  initialValue?: PageContextType;
};
export const PageContextProvider: FC<
  PropsWithChildren<PageContextProviderProps>
> = ({ initialValue, children }) => {
  const [pageContext, setPageContext] = useState<PageContextType | undefined>(
    initialValue
  );

  return (
    <PageContext.Provider
      value={{
        pageContext,
        setPageContext,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
