import { PROP_TRACK_ACTION } from '../constants';
import { trackAction } from '../tracker';

type trackEntityPageInterestActionType = {
  hasInterest?: boolean;
  error?: boolean;
};

export const trackEntityPageInterestAction = ({
  hasInterest,
  error,
}: trackEntityPageInterestActionType) => {
  if (!error) {
    trackAction({
      [PROP_TRACK_ACTION]: hasInterest
        ? 'entity_page_add_interest_button'
        : 'entity_page_remove_interest_button',
    });
  } else {
    trackAction({
      [PROP_TRACK_ACTION]: hasInterest
        ? 'entity_page_add_interest_button_error'
        : 'entity_page_remove_interest_button_error',
    });
  }
};
