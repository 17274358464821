import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconArrowRight, IconExternalLink } from '@xing-com/icons';
import { BodyCopy } from '@xing-com/typography';

import type { ShowMoreTypes } from './module-header';
import * as Styled from './module-header-show-more.styles';

type ModuleHeaderShowMoreProps = {
  showMore: ShowMoreTypes;
  hasMultipleCustomButtons?: boolean;
};
export const ModuleHeaderShowMore: FC<ModuleHeaderShowMoreProps> = ({
  showMore,
  hasMultipleCustomButtons,
}) => {
  const { copyKey, number, testId, externalLink, isLoading, ...buttonProps } =
    showMore;

  return (
    <Styled.ShowMoreWrapper
      $customButtons={hasMultipleCustomButtons}
      data-testid={showMore.testId}
      data-cy={showMore.testId}
    >
      <Styled.ShowMore
        icon={showMore.externalLink ? IconExternalLink : IconArrowRight}
        iconAfter={true}
        loading={isLoading}
        {...buttonProps}
      >
        <BodyCopy
          fontWeight="bold"
          lineHeight="dense"
          size="small"
          noMargin
          as={'strong'}
        >
          <FormattedMessage
            id={showMore.copyKey}
            values={{ number: showMore.number }}
          />
        </BodyCopy>
      </Styled.ShowMore>
    </Styled.ShowMoreWrapper>
  );
};
