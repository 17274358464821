import styled, { css } from 'styled-components';

import { ContentSwitcherButton } from '../content-switcher-button';

type MoreContainerTransientProps = {
  $hidden: boolean;
};

// *** Components ***
const MoreContainer = styled.div<MoreContainerTransientProps>`
  ${({ $hidden }) =>
    $hidden
      ? css`
          visibility: hidden;
        `
      : css`
          visibility: visible;
        `};
`;

const MoreList = styled.ul`
  margin: 0;
  padding: 0;
`;

const MoreButton = styled(ContentSwitcherButton)`
  position: relative;
`;

export const Styled = {
  MoreButton,
  MoreContainer,
  MoreList,
};
