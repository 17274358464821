import times from 'lodash/times';
import * as React from 'react';

import * as Styled from './pagination-dots.styles';
import type {
  DotProps,
  PaginationDotProps,
  DotSize,
} from './pagination-dots.types';

const MAX_DOTS_VISIBLE = 7;

const Dot = ({
  size,
  paginationDotColor,
  isSelected,
}: DotProps): JSX.Element => (
  <Styled.DotContainer>
    <Styled.Dot
      $size={size}
      $isSelected={isSelected}
      $paginationDotColor={paginationDotColor}
    />
  </Styled.DotContainer>
);

export const PaginationDots = React.forwardRef<
  HTMLDivElement,
  PaginationDotProps
>(
  (
    { className, length, selected, paginationDotColor },
    forwardedRef
  ): JSX.Element => {
    const [trackPositionIndex, setTrackPositionIndex] =
      React.useState(selected);

    const getDotType = (index: number): DotSize => {
      const diff = Math.abs(index - trackPositionIndex);
      return diff < 2
        ? 'big'
        : diff < 3
          ? 'medium'
          : diff < 4
            ? 'small'
            : 'none';
    };

    React.useEffect(() => {
      const updateTrackPositionIndex = (): void => {
        const diff = selected - trackPositionIndex;
        if (!Number.isInteger(diff)) {
          setTrackPositionIndex(selected);
        } else if (diff > 1) {
          setTrackPositionIndex(trackPositionIndex + diff - 1);
        } else if (diff < -1) {
          setTrackPositionIndex(trackPositionIndex + diff + 1);
        }
      };

      updateTrackPositionIndex();
    }, [selected, trackPositionIndex]);

    if (length < 2) {
      return <Styled.PaginationDots />;
    }

    return (
      <Styled.PaginationDots
        className={className}
        data-xds="PaginationDots"
        ref={forwardedRef}
      >
        <Styled.Track
          $centered={length < MAX_DOTS_VISIBLE}
          $index={Math.max(0, trackPositionIndex - 3)}
        >
          {times(length, (i: number) => (
            <Dot
              isSelected={selected === i}
              key={i}
              paginationDotColor={paginationDotColor}
              size={getDotType(i)}
            />
          ))}
        </Styled.Track>
      </Styled.PaginationDots>
    );
  }
);

PaginationDots.displayName = 'PaginationDots';
